import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown } from 'semantic-ui-react';
import { useStyles } from "./styles";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import axios from "axios";
import { loanthirdpartyschema } from "../../shared/validations";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProgressBar from "react-bootstrap/ProgressBar";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import CloseIcon from '@material-ui/icons/Close';
import CrifResult from '../CrifResult';
import Tooltip from '@material-ui/core/Tooltip';
import grayback from '../../image/buttons/grayback.png';
import Back from '../../image/buttons/back.png';
import OtpTimer from "otp-timer";
import facebook from "../../image/buttons/facebook.png";
import google from "../../image/buttons/google.png";
import linkedin from "../../image/buttons/linkedIn.png";
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Cookies from 'js-cookie';
import CrifLogin from '../CrifLogin';

export default function CrifApiLoanRequestPost(props) {

    React.useEffect(() => {
        statecheck();
        nationalcheck();

    }, []);
    const [stateCode, setStateCode] = React.useState([]);
    const statecheck = async () => {
        
        const statedata = {
            token: "qwerty1234",
        };
        let statearray = [];

        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getAllState`,
                statedata
            )
            .then((res) => {
                res.data.data.map((item, i) => {
                    statearray.push({
                        key: i,
                        value: item.state_name,
                        flag: item.state_name,
                        text: item.state_name,
                    });
                });
                setStateCode(statearray);
                //  //console.log("first statearray", statearray);
            })
            .catch((er) => {

                //console.log("no data  ", er);
            });
    };


    var nationalarray = [];
    const [ctCode, setCtCode] = React.useState([]);


    const nationalcheck = async () => {
        const nationaldata = {
            token: "qwerty1234",
        };

        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getAllnationality`,
                nationaldata
            )
            .then((res) => {
                res.data.data.map((item, i) => {
                    nationalarray.push({
                        key: i,
                        value: item.id,
                        text: item.country,
                    });
                });
                setCtCode(nationalarray);
            })
            .catch((er) => {
                //console.log(er);
            });
    };




    const [area, setArea] = React.useState([]);


    const getPincodeData = async (data) => {
        let getPincode = {
            pincode: data,
            token: "qwerty1234",
        };
        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getDetailsbyPincode`,
                getPincode
            )
            .then((res) => {
                // //console.log("yeh we have pincode", res.data);
                handleAllAreas(res.data.data);
            })
            .catch((er) => {
                //console.log("no pincode sorry ", er);
            });
    };

    const handleAllAreas = (data) => {
        //
        let allArea = [];
        for (let i = 0; i < data.length; i++) {
            allArea.push({
                key: data[i].id,
                value: data[i].area,
                text: data[i].area,
            });
        }
        setArea(allArea);
    };

    // generate random string
    const classes = useStyles();
    const generateString = (char, num) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const number12 = '0123456789';
        let charResult = '';
        let numResult = '';

        const charactersLength = characters.length;
        for (let i = 0; i < char; i++) {
            charResult += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const numLength = number12.length;
        for (let i = 0; i < num; i++) {
            numResult += number12.charAt(Math.floor(Math.random() * numLength));
        }
        charResult = charResult.toUpperCase();
        return charResult + numResult;
    }


    const [initialValue, setinitialValue] = useState({
        fname: "",
        mname: "",
        lname: "",
        dob: "",
        age: "",
        phone1: "",
        email1: "",
        pan: "",
        dl: "",
        voterid: "",
        passport: "",
        ration: "",
        uid: "",
        other_id1: "",
        other_id2: "",
        father_spou_name: "",
        address1: "",
        village1: "",
        city1: "",
        state1: "",
        pin1: "",
        country1: "",
        customer_id: "",
        product_id: "",
        consent: "",

    });
    const [orderId, setOrderId] = useState("");
    const [open, setOpen] = React.useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [loaderFlag, setLoaderFlag] = React.useState(true);
    const [questionsTabOpen, setQuestionsTabOpen] = useState(false);
    const [promoterId, setPromoterId] = useState();
    const [showConMsg, setShowConMsg] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    const [questionTab, setQuestionTab] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [idofuser, setidofuser] = React.useState();
    const [id, setId] = useState(1);

    const handlesubmitform = async (values, { resetForm }) => {
        //  setOrderId(orderId);

        let pdata = {
            first_name: values.fname,
            last_name: values.lname,
            dob: values.dob,
            age: values.age,
            mobile: values.phone1,
            email: values.email1,
            PAN: values.pan,
            dl: values.dl,
            voterid: values.voterid,
            passport: values.passport,
            ration: values.ration,
            uid: values.uid,
            other_id1: values.other_id1,
            other_id2: values.other_id2,
            father_spou_name: values.father_spou_name,
            address_1: values.address1,
            village1: values.village1,
            city1: values.city1,
            state_name: values.state1,
            pin_id: values.pin1,
            national: values.country1
        }

        //console.log(pdata);
        let orderId = generateString(6, 3);
        setOrderId(orderId);
        let national = "india";
      
        let promoterDob = moment(pdata.dob).format('DD-MM-YYYY');
        let id = 1;
       // console.log();
    
        let object = {
            app_name: "stage1",
            in_data: '' + orderId + '|' + pdata.first_name + '||' + pdata.last_name + '||' + promoterDob + '|||' + pdata.mobile + '|||' + pdata.email + '||' + pdata.PAN + '|' + pdata.dl + '|||||||' + pdata.father_spou_name + '|||' + pdata.address_1 + '|' + pdata.village1 + '|' + pdata.city1 + '|' + pdata.state_name + '|' + pdata.pin_id + '|' + national + '|||||||Y  ',
            create: 1,
            modify: 1
        }
        //console.log(object);

       await  axios.post(apiCalling.thirdParty +'server.php/api/apicrif1',object).then((result) => {
               // //console.log(result);
                let orderIdFromStage1 = result.data.orderId;
                if (result.data.status == "S06") {
                    callStage2CrifApi(orderIdFromStage1);
                    setOpen(true);
                    setQuestionsTabOpen(true);
                    setProgressBar(true);
                } else {
                    setOpen(true);
                    setQuestionsTabOpen(true);
                    setErrorMsg(true);
    
                }
            }).catch((error) => {
                //console.log(error)
            })



        // await axios.get(apiCalling.thirdParty + 'server.php/apicrif?app_name=stage1&&in_data=' + orderId + '|chm_bbc_prod@aagey.com|C029A97333026040C1E6EB1FC5A6BAEEF03B5797|' + pdata.first_name + '||' + pdata.last_name + '||' + promoterDob + '|||' + pdata.mobile + '|||' + pdata.email + '||' + pdata.PAN + '|' + pdata.dl + '|||||||' + pdata.father_spou_name + '|||' + pdata.address_1 + '|' + pdata.village1 + '|' + pdata.city1 + '|' + pdata.state_name + '|' + pdata.pin_id + '|' + national + '|||||||' + prdoCode + '|Y&&create=' + id + '&&modify=' + id + ' ').then((result) => {
        //     //console.log(result);
        //     let orderIdFromStage1 = result.data.orderId;
        //     if (result.data.status == "S06") {
        //         callStage2CrifApi(orderIdFromStage1);
        //         setOpen(true);
        //         setQuestionsTabOpen(true);
        //         setProgressBar(true);
        //     } else {
        //         setOpen(true);
        //         setQuestionsTabOpen(true);
        //         setErrorMsg(true);

        //     }
        // }).catch((error) => {
        //     //console.log(error)
        // })
    }

    const [question, setQuestion] = useState("");
    const [ansSelectArr, setAnsSelectArr] = useState([]);

    const callStage2CrifApi = async (orderIdFromStage1) => {

        
        let data = {
            app_name: "stage2",
            orderId: orderIdFromStage1,
            userans: "",
            create: "1",
            modify: "1"

        }
        await axios.post(apiCalling.thirdParty +'server.php/api/apicrif1', data).then(result => {

            setShowConMsg(true);
          //  //console.log(result.data);
            if (result.data.status == "S11") {
                setLoaderFlag(false);
                setOpen(true)
                setQuestion(result.data.question);
                let options = result.data.optionList;
                let res = options.split(" |");
                setAnsSelectArr(res);
                //console.log(res);
                setQuestionsTabOpen(true);
                setProgressBar(false)
                setQuestionTab(true);
            } else if (result.data.status == "S10") {
                //  setProgressBar(true)
                stage3ApiResponse(orderIdFromStage1);

            } else if (result.data.status == "S09") {
                setErrorMsg(true);
            }

        }).catch(error => {
            //console.log(error);
        });

        //  let orderIdFromStage01 = orderIdFromStage1;
        // await axios.get(apiCalling.thirdParty + 'server.php/apicrif?app_name=stage2&&orderId=' + orderIdFromStage1 + '&&userans=&&create=' + id + '&&modify=' + id + ' ').
        //     then((result) => {
        //         //setShowConMsg(true);
        //         //console.log(result.data);
        //         if (result.data.status == "S11") {
        //             setLoaderFlag(false);
        //             setOpen(true)
        //             setQuestion(result.data.question);
        //             let options = result.data.optionList;
        //             let res = options.split(" |");
        //             setAnsSelectArr(res);
        //             //console.log(res);
        //             setQuestionsTabOpen(true);
        //             setProgressBar(false)
        //             setQuestionTab(true);
        //         } else if (result.data.status == "S10") {
        //             //  setProgressBar(true)
        //             stage3ApiResponse(orderIdFromStage1);

        //         } else if (result.data.status == "S09") {
        //             setErrorMsg(true);
        //         }
        //     }).catch(error => {
        //         //console.log(error)
        //     })
    }
    const [selectedValue, setSelectedValue] = React.useState('');
    // criff stage 2 continuation api stage submit the answer url header = orderId and Answer
    const handleChangeLoanAnswer = (event) => {
        ;
        let answer = event.target.value;
        setSelectedValue(answer);
    };

    const submitAnswerAtStage2 = async () => {

        let data = {
            app_name: "stage2",
            orderId: orderId,
            userans: selectedValue,
            create: "1",
            modify: "1"
        }


        await axios.post(apiCalling.thirdParty +'server.php/api/apicrif1', data).then(result => {
          //  //console.log(result);
            setSelectedValue('');
            if (result.data.status == "S01") {
                stage3ApiResponse(orderId);
            } else if (result.data.status == "S11") {
                setQuestion(result.data.question);
                let options = result.data.optionList;
                let res = options.split(" |");
                setAnsSelectArr(res);
            } else if (result.data.status == "S02") {
                setErrorMsg(true);
                setSuccessMsg(false);
                setQuestionTab(false);
            }
        }).catch(error => {
            //console.log(error);
        })


        // await axios.get(apiCalling.thirdParty + 'server.php/apicrif?app_name=stage2&&orderId=' + orderId + '&&userans=' + selectedValue + '&&create=' + id + '&&modify=' + id + ' ').then(result => {
        //     //console.log(result);
        //     setSelectedValue('');
        //     if (result.data.status == "S01") {
        //         stage3ApiResponse(orderId);
        //     } else if (result.data.status == "S11") {
        //         setQuestion(result.data.question);
        //         let options = result.data.optionList;
        //         let res = options.split(" |");
        //         setAnsSelectArr(res);
        //     } else if (result.data.status == "S02") {
        //         setErrorMsg(true);
        //         setSuccessMsg(false);
        //         setQuestionTab(false);
        //     }
        // }).catch(error => {
        //     //console.log(error);
        // })
    }
    const [xmlResult, setXmlResult] = useState({});
    // criff stage 3 api just send orderid 
    const stage3ApiResponse = async (orderIdpar) => {
        

        let data ={
            app_name:"stage3",
            orderId:orderIdpar,
            create:"1",
            modify:"1"
          
          }

      await axios.post(apiCalling.thirdParty +'server.php/api/apicrif1',data).then(result => {
         // //console.log('printed result', result);
            setQuestionTab(false);
            
            if (result.status === 200) {
                setProgressBar(false);
                let resultdata = result.data.json_result;
                let jsonresa = JSON.parse(resultdata);
                let headerdata = jsonresa.HEADER;
                setXmlResult(JSON.stringify(jsonresa))
                   //console.log("headerdata " + JSON.stringify(headerdata))
                // //console.log("ressssssssssss",res);
                //   saveLoan3rdPartyResult(jsonresa);
                // setPropsData(data);
                //   setSuccessMsg(true);
                setShowResult(true);
            }

        }).catch(error => {
            //console.log(error)
        })

        //      await axios.get(apiCalling.thirdParty + 'server.php/apicrif?app_name=stage3&&orderId=' + orderIdpar + '&&create= ' + id + ' &&modify=' +  id + ' ').then(result => {
        //   //console.log('printed result', result);
        //     setQuestionTab(false);
        //     
        //     if (result.status === 200) {
        //         setProgressBar(false);
        //         let resultdata = result.data.json_result;
        //         let jsonresa = JSON.parse(resultdata);
        //         let headerdata = jsonresa.HEADER;
        //         setXmlResult(JSON.stringify(jsonresa))
        //         //   //console.log("headerdata " + JSON.stringify(headerdata))
        //         // //console.log("ressssssssssss",res);
        //         //   saveLoan3rdPartyResult(jsonresa);
        //         // setPropsData(data);
        //         //   setSuccessMsg(true);
        //         setShowResult(true);
        //     }

        // }).catch(error => {
        //     //console.log(error)
        // })
    }

    const saveLoan3rdPartyResult = async (datas) => {
        
        let data = {
            "parent_type_id": 2,
            "parent_id": 1,
            "user_id": 1,
            "result": datas
        }
        //console.log("saveLoan3rdPartyResultsentdata", JSON.stringify(data))
        let api_path = apiCalling.coderscorner + `server.php/api/saveLoan3rdPartyResult`;
        await axios.post(apiCalling.coderscorner + `server.php/api/saveLoan3rdPartyResult`, data).then((res) => {
          //  //console.log("---saveLoan3rdPartyResult---", res.data)
            
        }).catch((er) => {
            ErrorCatch(idofuser, er, api_path, 'post', data, idofuser, idofuser);
            //console.log("Something went Wrong ...!!!", er);
        });
    }

    const handleClose = () => {
        setSelectedValue('');
        setProgressBar(false);
        setQuestionsTabOpen(false);
        setQuestionTab(false);
        setSuccessMsg(false)
        setErrorMsg(false);
        setXmlResult("");
        //   setOpen(false);
    };

    const handleClear = () => {
        //  resetForm();
        setinitialValue({
            fname: "", mname: "", lname: "", dob: "", age: "", phone1: "", email1: "", pan: "", dl: "", voterid: "", passport: "", ration: "", uid: "", other_id1: "", other_id2: "", father_spou_name: "", address1: "", village1: "", city1: "", state1: "", pin1: "", country1: "", customer_id: "", product_id: "", consent: ""
        });
    }

    const [showResult, setShowResult] = useState(false);
    const [propsData, setPropsData] = useState({});
    const [loginPage, setLoginPage] = React.useState(true);





    /// SIGNIN STARTS HERE -------------------------------

    const [showInvalidMessage, setShowInvalidMessage] = useState(false);
    //const [emailOrMobile,setEmailOrMobile] = useState();
    const [userId, setUserId] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const verifyEmail = async (email) => {
        
      //  setEmail(email);
        let rbody = {};
        // setMobile(email)
        if (email != null && email !== undefined) {
            if (email.includes('@')) {
                rbody = {
                    email: email,
                }
            } else {
                rbody = {
                    mobile: email
                }
            }

            let user = {};
           await axios.post(apiCalling.identityManagement + 'server.php/api/AdminSignin', rbody).then((result) => {
                if (result.data !== undefined || result.data !== null) {
                    user = result.data;
                    console.log(user);

                    if (user.status === true) {
                        setOtpPage(true);
                        setSignInBtn(true);
                 //       verifyUser(email);
                        setShowOtp(!showOtp);
                        setShowInvalidMessage(false);
                        localStorage.setItem('userdata', JSON.stringify(user.Data.user_details));
                    } else if (user.status === false) {
                        setShowInvalidMessage(true)
                        setErrorMessage(user.message);
                    }
                }
            }).catch((error) => {
                //console.log(error);
            });
        } else {
            setShowInvalidMessage(true);
            setErrorMessage("Please enter all the feilds");
        }
    }

    /// get otp   and verify
    const verifyUser = (data) => {
        
        let rbody = {};
        if (data.includes('@')) {
            rbody = {
                email: data,
                pan: pan,
            };
        } else {
            rbody = {
                mobile: data,
                pan: pan,
            }
        }
        axios
            .post(apiCalling.identityManagement + `server.php/api/UserSignin`,
                rbody
            )
            .then((res) => {
                if (res.data.status === true) {
                    //console.log(res.data);
                    setUserId(res.data.Data.user_details.id)
                    //getUserDataFromSignUp(res.data.Data.user_details.id);
                    getUserDataFromSignUp(res.data.Data.user_details.id);
                    // geClientRegData(res.data.Data.user_details.id)
                    let data1;
                    if (data.includes('@')) {
                        data1 = res.data.Data.user_details;
                    } else {
                        data1 = res.data.Data.user_details;
                    }
                    //     localStorage.setItem('userdata', JSON.stringify(data1));
                    //   Cookies.set('userdata_cookie', JSON.stringify(data1));
                }
            })
            .catch((error) => {
                //console.log(error);
            });
    };


    // verify email otp
    const [otpErrorMessage, setOtpErrorMessage] = useState(false);
    const [otpMessage, setOtpMessage] = useState();
    const verifyEmailOtp = (e) => {
        
        let eotp = e.target.value;
        let len = eotp.length;
        if (len == 6) {
            let data = {
                value: email,
                form_id: 2,
                otp: eotp,
            };
            localStorage.setItem("otps", JSON.stringify(data));
            Cookies.set('otps_cookie', JSON.stringify(data));


            // const checkotp=localStorage.getItem("otps");
            //const checkotp=Cookies.get("otps");
            let getotpcookie = Cookies.get('otps_cookie');
            //console.log(getotpcookie);
            let otps = JSON.parse(getotpcookie);
            axios
                .post(apiCalling.identityManagement + `server.php/api/verifyOtp`,
                    data
                )
                .then((result) => {
                    if (result.data != undefined || result.data != null) {
                        //  //console.log(result.data);
                        if (result.data.status === true) {
                            setSignInBtn(false);
                            setShowTimer(false);
                            setOtpErrorMessage(false);
                        } else if (result.data.status === false) {
                            setOtpErrorMessage(true);
                            setOtpMessage(result.data.message);
                        }
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    };

    //resend otp for email
    const resendOtpEmail = () => {
        let data = {};
        let val = email;
        if (val.includes('@')) {
            data = {
                type: "email",
                value: val,
                form_id: 2
            };
        } else {
            data = {
                type: "mobile",
                value: val,
                form_id: 2
            }
        }
        axios
            .post(apiCalling.identityManagement + `server.php/api/resendOtp`,
                data
            )
            .then((res) => {
                //console.log(res.data);
            })
            .catch((error) => {
                //console.log(error);
            });
    };

    // user sigin
    var llDate = new Date();
    var lalogindate = llDate.toString();

    const signIn = async (e) => {
        setAuth(true);
        // 
        // e.preventDefault();
        // let data = {
        //     // mobile:mobile,
        // };
        // if (email !== "" || email !== undefined) {
        //     data = {
        //         email: email,
        //         pan: pan,
        //         lastLoginDate: lalogindate
        //     }
        // } else {
        //     data = {
        //         email: "",
        //         mobile: mobile,
        //         pan: pan,
        //         lastLoginDate: lalogindate
        //     }
        // }
        // localStorage.setItem("logindata", JSON.stringify(data));
        // Cookies.set('logindata_cookie', JSON.stringify(data));
        // const checkuserloggeddata = JSON.parse(localStorage.getItem("userdata"));
        // let getUserloggeddata = Cookies.get('userdata_cookie');
        // //console.log(getUserloggeddata);
        // let iduser = JSON.parse(getUserloggeddata);

        // const checkotp = localStorage.getItem("otps");
        // let getotpcookie = Cookies.get('otps_cookie');
        // //console.log(getotpcookie);
        // let otps = JSON.parse(getotpcookie);
        // // //console.log(otps.otp);
        // // //console.log(iduser.id);
        // let user_data_id = iduser.id;
        // let otp_data = otps.otp;
        // let user_post_data = {
        //     user_id: user_data_id,
        //     otp: otp_data
        // }
        // //console.log("save-login" + user_post_data);
        // if (checkuserloggeddata.role_id == 1 || checkuserloggeddata.role_id == 3 || checkuserloggeddata.role_id == 6) {
        //     props.history.push({ pathname: "/ProfileSteper" })
        // } else {

        //     if (checkuserloggeddata.role_id == 4) {
        //         props.history.push({ pathname: "/AdminDashboard" })
        //     } else {
        //         props.history.push({ pathname: "/ProfileSteper" })
        //     }
        //     //session history
        //     await axios.post(apiCalling.identityManagement + 'server.php/api/saveLoginTime', user_post_data).then((result) => {
        //         //console.log(result);
        //         if (result.data.status === true) { }
        //     }).catch(error => {
        //         ErrorCatch(error, 'post', user_post_data);
        //         //console.log(error)
        //     })
        // }

    };

    const [checkUserExist, setCheckUserExist] = useState("");


    //// IDENTITY MANAGEMENT
    const getUserDataFromSignUp = async (ID) => {
        
        let data1 = { id: ID }
        let api_path = apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid';
        await axios.post(apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid', data1).then(result => {
            //    //console.log(result)
            if (result.data.status === true) {
                setCheckUserExist("busnotexist")
                localStorage.setItem("SignUpIdAndData", JSON.stringify(result.data.data));
                Cookies.set('SignUpIdAndData_cookie', JSON.stringify(result.data.data));

                //  //console.log(result.data)
            }
        }).catch(error => {
            ErrorCatch(ID, error, api_path, 'post', data1, ID, ID);
            //console.log(error)
        })
    }
    ///Otp time as per general setting
    const getOtpTimer = () => {
        let data = {
            parameter_name: "OTP TIMER",
        };
        axios
            .post(apiCalling.coderscorner +
                `server.php/api/getGeneralSettingByParameter`,
                data
            )
            .then((result) => {
                if (result.data.data != undefined || result.data.data != null) {
                    //  //console.log(result.data.data[0]);
                    let data = result.data.data[0].parameter_value;
                    //  //console.log("timer", data);
                    convertTimerToInt(data);
                }
            })
            .catch((error) => {
                //console.log(error);
            });
    };

    //conversion for Otp timer into
    const convertTimerToInt = (timer) => {
        let newTimer = parseInt(timer);
        var minutes = Math.floor(newTimer / 60);
        var seconds = newTimer - (60 * minutes);
        setOtpMinutes(minutes);
        setOtpSeconds(seconds);
    };

    const [otppage, setOtpPage] = useState(false);
    const otpPageEnable = () => {
        verifyEmail(email);
    }
    const enableSignInpage = () => {
        setOtpPage(false);
    }

    const [pan, setPan] = React.useState();
    const [email, setEmail] = React.useState();
    const [mobile, setMobile] = React.useState();
    const [showOtp, setShowOtp] = React.useState(false);
    const [signInBtn, setSignInBtn] = React.useState(true);
    const [otpMinutes, setOtpMinutes] = useState();
    const [otpSeconds, setOtpSeconds] = useState();
    const [emailDisable, setEmailDisabled] = useState(true);
    const [showTimer, setShowTimer] = useState(true);

    const [auth, setAuth] = React.useState(false);


    /// SIGNIN END HERE



    return (

        <Container maxWidth="lg">


            {
                auth == false ? (
                    <>
                    
                    <div className="login-form">
                    <Card className="logincard">
                        <CardContent>
                      
                            <form onSubmit={signIn}>
                                {otppage == false && (
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <p className="login_header">Internal User Login</p>
                                        </Col>

                                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                                            <label className="login_txtlabel">Email / Mobile</label>
                                            <TextField
                                                name="email"
                                                label="xyz@mno.com/900000000"
                                                // onBlur={emailBlur}
                                                value={email}
                                                onChange={(e) => {
                                                  ///  verifyEmail(e.target.value);
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                            {showInvalidMessage === true && (<span className="valMsg">{errorMessage}</span>)}
                                        </Col>
                                        {/* {showOtp == true && (
                                    <Col md={{ span: 12 }}>
                                      <Row>
                                        <Col md={{ span: 8 }} style={{ marginTop: '4%' }}>
                                          <InputOtp onChange={verifyEmailOtp} />
                                          {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                                        </Col>
                                        {showTimer === true && (
                                          <Col md={{ span: 2 }} style={{ marginTop: "7%" }}>
                                            <OtpTimer
                                              seconds={otpSeconds}
                                              minutes={otpMinutes} //otpMinutes
                                              style={{
                                                color: "blue",
                                                display: "inline-block", float: "left",
                                                marginTop: '4%'
                                              }}
                                              text=" "
                                              resend={resendOtpEmail}
                                              buttonColor={"white"}
                                              background={"#007bff"}
                                            />
                                          </Col>
                                        )}
                                        <Col md={{ span: 2 }} style={{ marginTop: "7  %" }}>
                                          <Button
                                            onClick={getBackToEmail}
                                            style={{ display: "inline-block", float: "right" }}
                                          >
                                            <EditIcon />
                                          </Button>{" "}
                                        </Col>
                                      </Row>
                                    </Col>
                                  )} */}
                                        <Col md={{ span: 12 }}>
                                            <Button
                                                className="signup_btn"
                                                //type="submit"
                                                variant="contained"
                                                color="primary"
                                                // onSubmit={signIn}
                                                onClick={otpPageEnable}
                                            // disabled={signInBtn}
                                            >
                                                <span className="signup_label">Send OTP</span>
                                            </Button>
                                        </Col>

                                        {/* <Col sm={{ span: 6 }}>
                                <img src={playstore} className="storelink" />
                              </Col>
                              <Col sm={{ span: 6 }}>
                                <img src={appstore} className="storelink" />
                              </Col> */}
                                    </Row>
                                )}
                                {otppage == true && (
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <p className="login_header">Client Login</p>
                                        </Col>
                                        <Col md={{ span: 12 }}>
                                            <p className="login_header">Manage your loan application and more!</p>
                                        </Col>
                                        <Col md={{ span: 12 }}>
                                            <Link onClick={enableSignInpage} className="backpagelink"><span style={{ color: "#5290d", fontSize: "100%" }}>
                                                <i className="fas fa-arrow-left" style={{ width: '7%', fontSize: '18px' }}></i>{" "}Back to Login</span>
                                            </Link>
                                        </Col>
                                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                                            <label className="login_txtlabel">Enter OTP</label>
                                            <TextField
                                                name="otp"
                                                label="Enter OTP"
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                onChange={verifyEmailOtp}
                                            />
                                            {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                                        </Col>
                                        {showTimer === true && (
                                            <Col md={{ span: 12 }} style={{ marginTop: "5px" }}>
                                                <OtpTimer
                                                    seconds={otpSeconds}
                                                    minutes={otpMinutes} //otpMinutes
                                                    style={{
                                                        color: "red",
                                                        display: "inline-block", float: "left",
                                                        marginTop: '4%'
                                                    }}
                                                    text=" "
                                                    resend={resendOtpEmail}
                                                    buttonColor={"white"}
                                                    background={"#007bff"}
                                                    textColor={"red"}
                                                    float={"right"}
                                                />
                                            </Col>
                                        )}
                                        <Col md={{ span: 12 }} style={{ marginTop: '4%' }}>
                                            <Button
                                                className="login_btn signup_btn"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onSubmit={signIn}
                                                // onClick={otpPageEnable}
                                                disabled={signInBtn}>
                                                <span className="signup_label">Sign In</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </form>
                        </CardContent>
                    </Card>
                </div>


                    </>


                ) : (

                    <div className="mini-container mg_btm_set">
                        {showResult == false && (
                            <>
                                <Dialog
                                    disableBackdropClick
                                    open={questionsTabOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <MuiDialogTitle>
                                        {handleClose ? (
                                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                                <CloseIcon />
                                            </IconButton>) : null}
                                    </MuiDialogTitle>
                                    {questionTab && (<DialogTitle id="alert-dialog-title"><p className="modaltext">{question}</p></DialogTitle>)}
                                    {successMsg && (<DialogTitle id="alert-dialog-title" className="modaltext"><p className="modaltext"> Pulled Succesfully</p></DialogTitle>)}
                                    {errorMsg && (<DialogTitle id="alert-dialog-title" className="modaltext"> <p className="modaltextfail">User Authentication Failure </p></DialogTitle>)}
                                    {progressBar && (<div >
                                        <ProgressBar
                                            animated
                                            variant="info"
                                            now={70}
                                            label={`${50}%`}
                                        />
                                    </div>)}
                                    <div>
                                        <DialogContent>


                                            {questionTab && (
                                                <>
                                                    <div className="modalChild">
                                                        <div>
                                                            <FormControlLabel
                                                                value="top"
                                                                label={ansSelectArr[0]}
                                                                labelPlacement="right"
                                                                control={<Radio
                                                                    checked={selectedValue == ansSelectArr[0]}
                                                                    onChange={handleChangeLoanAnswer}
                                                                    value={ansSelectArr[0]}
                                                                    name="radio-button-demo"
                                                                />
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormControlLabel
                                                                value="top"
                                                                label={ansSelectArr[1]}
                                                                labelPlacement="right"
                                                                control={<Radio
                                                                    checked={selectedValue == ansSelectArr[1]}
                                                                    onChange={handleChangeLoanAnswer}
                                                                    value={ansSelectArr[1]}
                                                                    name="radio-button-demo"
                                                                />
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormControlLabel
                                                                value="top"
                                                                label={ansSelectArr[2]}
                                                                labelPlacement="right"
                                                                control={<Radio
                                                                    checked={selectedValue == ansSelectArr[2]}
                                                                    onChange={handleChangeLoanAnswer}
                                                                    value={ansSelectArr[2]}
                                                                    name="radio-button-demo"
                                                                />
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormControlLabel
                                                                value="top"
                                                                label={ansSelectArr[3]}
                                                                labelPlacement="right"
                                                                control={<Radio
                                                                    checked={selectedValue == ansSelectArr[3]}
                                                                    onChange={handleChangeLoanAnswer}
                                                                    value={ansSelectArr[3]}
                                                                    name="radio-button-demo"
                                                                />
                                                                } />
                                                        </div>
                                                    </div>
                                                    <DialogActions>
                                                        <Button className="backBtn"
                                                            variant="outlined"
                                                            color="primary" onClick={submitAnswerAtStage2} >
                                                            Submit
                                                        </Button>
                                                        <Button className="backBtn"
                                                            variant="outlined"
                                                            color="primary" onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                    </DialogActions>
                                                </>)}
                                            {successMsg && (
                                                <>
                                                    {xmlResult}
                                                    <DialogActions>
                                                        <Button onClick={handleClose} className="backBtn"
                                                            variant="outlined"
                                                            color="primary">
                                                            OK</Button>
                                                    </DialogActions>
                                                </>)}
                                            {errorMsg && (
                                                <>
                                                    <DialogActions>
                                                        <Button onClick={handleClose} className="backBtn"
                                                            variant="outlined"
                                                            color="primary">
                                                            OK</Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </DialogContent>
                                    </div>
                                </Dialog>

                                <Formik
                                    initialValues={initialValue}
                                    validationSchema={loanthirdpartyschema}
                                    onSubmit={handlesubmitform}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        resetForm,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldTouched,
                                        getFieldProps
                                        /* and other goodies */
                                    }) => {
                                        return (
                                            <>
                                                <div>
                                                    <Row>
                                                        <Col md={{ span: 12 }}>
                                                            <Row className="content-header">
                                                                <Col md={{ span: 6 }} className="bankingDetailsHeader">
                                                                    <Typography text="Loan Information" styleType="C" />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <form onSubmit={handleSubmit}>
                                                        <Row>
                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="First Name" styleType="B" className="promoter_mobile" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField style={{ marginTop: "10px" }}
                                                                            id="filled-error-helper-text standard-basic " //readonly
                                                                            label="Enter First Name"
                                                                            name="fname"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.fname
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            error={
                                                                                errors.fname &&
                                                                                    touched.fname &&
                                                                                    errors.fname
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.fname}
                                                                            helperText={
                                                                                errors.fname &&
                                                                                touched.fname &&
                                                                                errors.fname
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="Last Name" styleType="B" className="promoter_mobile" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField style={{ marginTop: "10px" }}
                                                                            id="filled-error-helper-text standard-basic " //readonly
                                                                            label="Enter Last Name"
                                                                            name="lname"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.lname
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            error={
                                                                                errors.lname &&
                                                                                    touched.lname &&
                                                                                    errors.lname
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.lname}
                                                                            helperText={
                                                                                errors.lname &&
                                                                                touched.lname &&
                                                                                errors.lname
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }}>
                                                                <Typography text="DOB" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField
                                                                            id="dob"
                                                                            type="date"
                                                                            className={classes.textField}
                                                                            name="dob"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            style={{ marginTop: "9px" }}
                                                                            InputProps={{
                                                                                className: values.date
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}

                                                                            value={values.dob || ""}

                                                                        />
                                                                    </ListItem>

                                                                </List>
                                                            </Col>



                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="Phone Number" styleType="B" className="promoter_mobile" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField style={{ marginTop: "10px" }}
                                                                            id="filled-error-helper-text standard-basic " //readonly
                                                                            label="Enter Mobile No"
                                                                            name="phone1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.phone1
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            error={
                                                                                errors.phone1 &&
                                                                                    touched.phone1 &&
                                                                                    errors.phone1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.phone1}
                                                                            helperText={
                                                                                errors.phone1 &&
                                                                                touched.phone1 &&
                                                                                errors.phone1
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} >
                                                                <Typography text="Email" styleType="B" />
                                                                <List component="div" disablePadding>

                                                                    <ListItem>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic " //readonly
                                                                            label="Enter Email"
                                                                            name="email1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.email1
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            error={
                                                                                errors.email1 &&
                                                                                    touched.email1 &&
                                                                                    errors.email1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.email1}
                                                                            helperText={
                                                                                errors.email1 &&
                                                                                touched.email1 &&
                                                                                errors.email1
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>

                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="PAN Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField
                                                                            name="pan"
                                                                            label="AAAAA9999A"
                                                                            onChange={handleChange}
                                                                            inputProps={{ maxLength: 10 }}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.pan
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            error={
                                                                                errors.pan &&
                                                                                    touched.pan &&
                                                                                    errors.pan
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.pan}
                                                                            helperText={
                                                                                errors.pan &&
                                                                                touched.pan &&
                                                                                errors.pan
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Driving License Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="dl"
                                                                            label="Enter Driving License"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.dl ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.dl && touched.dl &&
                                                                                    errors.dl
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.dl}
                                                                            helperText={
                                                                                errors.dl &&
                                                                                touched.dl &&
                                                                                errors.dl
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="VoterID Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="voterid"
                                                                            label="VoterID Number"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.voterid ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.voterid && touched.voterid &&
                                                                            //         errors.voterid
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.voterid}
                                                                        // helperText={
                                                                        //     errors.voterid &&
                                                                        //     touched.voterid &&
                                                                        //     errors.voterid
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Passport Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="passport"
                                                                            label="Passport Number"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.passport ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.passport && touched.passport &&
                                                                            //         errors.passport
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.passport}
                                                                        // helperText={
                                                                        //     errors.passport &&
                                                                        //     touched.passport &&
                                                                        //     errors.passport
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Ration Card Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="ration"
                                                                            label="Ration Card Number"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.ration ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.ration && touched.ration &&
                                                                            //         errors.ration
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.ration}
                                                                        // helperText={
                                                                        //     errors.ration &&
                                                                        //     touched.ration &&
                                                                        //     errors.ration
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="UID Number" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="uid"
                                                                            label="UID Number"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.uid ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.uid && touched.uid &&
                                                                            //         errors.uid
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.uid}
                                                                        // helperText={
                                                                        //     errors.uid &&
                                                                        //     touched.uid &&
                                                                        //     errors.uid
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Other ID1" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="other_id1"
                                                                            label=" Other ID1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.other_id1 ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.other_id1 && touched.other_id1 &&
                                                                            //         errors.other_id1
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.other_id1}
                                                                        // helperText={
                                                                        //     errors.other_id1 &&
                                                                        //     touched.other_id1 &&
                                                                        //     errors.other_id1
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Other ID2" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="other_id2"
                                                                            label=" Other ID2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.other_id2 ? classes.manorma : null,
                                                                            }}
                                                                            // error={
                                                                            //     errors.other_id2 && touched.other_id2 &&
                                                                            //         errors.other_id2
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            value={values.other_id2}
                                                                        // helperText={
                                                                        //     errors.other_id2 &&
                                                                        //     touched.other_id2 &&
                                                                        //     errors.other_id2
                                                                        // }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Father/Spouse Name" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="father_spou_name"
                                                                            label="father_spou_name"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.father_spou_name ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.father_spou_name && touched.father_spou_name &&
                                                                                    errors.father_spou_name
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.father_spou_name}
                                                                            helperText={
                                                                                errors.father_spou_name &&
                                                                                touched.father_spou_name &&
                                                                                errors.father_spou_name
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="Country" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <Dropdown
                                                                            placeholder="Country"
                                                                            id="country1"
                                                                            name="country1"
                                                                            fluid
                                                                            search
                                                                            selection
                                                                            value={values.country1}
                                                                            onBlur={() => setFieldTouched("country1", true)}
                                                                            onChange={(e, { value }) => setFieldValue("country1", value)}
                                                                            options={ctCode}

                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="State" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <Dropdown
                                                                            placeholder="State"
                                                                            id="state1"
                                                                            name="state1"
                                                                            fluid
                                                                            search
                                                                            selection
                                                                            value={values.state1}
                                                                            onBlur={() => setFieldTouched("state1", true)}
                                                                            onChange={(e, { value }) => setFieldValue("state1", value)}
                                                                            options={stateCode}
                                                                            className={errors.state1 && touched.state1 ? classes.required : values.state1 == '' ? classes.reqired : classes.man}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>

                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Address" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="address1"
                                                                            label="Enter Address"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.address1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.address1 && touched.address1 &&
                                                                                    errors.address1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.address1}
                                                                            helperText={
                                                                                errors.address1 &&
                                                                                touched.address1 &&
                                                                                errors.address1
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="Village" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="village1"
                                                                            label="Enter Village"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.village1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.village1 && touched.village1 &&
                                                                                    errors.village1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.village1}
                                                                            helperText={
                                                                                errors.village1 &&
                                                                                touched.village1 &&
                                                                                errors.village1
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0} >
                                                                <Typography text="Pincode" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="pin1"
                                                                            label="999999"
                                                                            onChange={handleChange}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);
                                                                                if (!errors.pincode) {
                                                                                    getPincodeData(
                                                                                        e.target.value
                                                                                    );
                                                                                }
                                                                            }}
                                                                            error={
                                                                                errors.pin1 &&
                                                                                    touched.pin1 &&
                                                                                    errors.pin1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputProps={{
                                                                                className: values.pin1
                                                                                    ? classes.manorma
                                                                                    : null,
                                                                            }}
                                                                            inputProps={{
                                                                                maxLength: 6,
                                                                            }}
                                                                            value={values.pin1}
                                                                            helperText={
                                                                                errors.pin1 &&
                                                                                touched.pin1 &&
                                                                                errors.pin1
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            <Col md={{ span: 6 }} spacing={0}>
                                                                <Typography text="City" styleType="B" />
                                                                <List component="div" disablePadding>
                                                                    <ListItem >
                                                                        <Dropdown
                                                                            placeholder="City"
                                                                            id="city1"
                                                                            name="city1"
                                                                            fluid
                                                                            search
                                                                            selection
                                                                            value={values.city1}
                                                                            onBlur={() => setFieldTouched("city1", true)}
                                                                            onChange={(e, { value }) => setFieldValue("city1", value)}
                                                                            options={area}
                                                                            className={errors.city1 && touched.city1 ? classes.required : values.city1 == '' ? classes.reqired : classes.man}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>



                                                            <Col md={{ span: 12 }}>
                                                                <Row><Col md={{ span: 8 }}></Col>
                                                                    <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained"
                                                                        type="button"
                                                                        color="primary"
                                                                        onClick={resetForm} >Reset</Button>
                                                                    </Col>
                                                                    <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                                                                    </Col>
                                                                    <Col md={{ span: 5 }}></Col>
                                                                </Row>
                                                            </Col>

                                                        </Row>
                                                    </form>
                                                </div>
                                            </>
                                        )
                                    }}
                                </Formik>

                            </>
                        )}

                        {showResult === true && (
                            <>
                                <span className="image">
                                    <img className='backbutton navbutton' alt="back" style={{ width: "45px", marginBottom: "-70px" }} src={Back} onClick={() => { setShowResult(false) }} />
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                        <img className='backbutton navgraybutton' style={{ width: "45px", marginBottom: "-70px" }} alt="bck" src={grayback} onClick={() => { setShowResult(false);
                                            setProgressBar(false);
                                         }} />
                                    </Tooltip>
                                </span>
                                <CrifResult CrifProps={xmlResult} />
                            </>
                        )}
                    </div>
                )
            }


        </Container>
    );

}
