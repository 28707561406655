import React, { useState } from "react";
import { loadCSS } from "fg-loadcss";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  TextField,
  Button,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { register, registerCpa } from "../../shared/validations";
import "../SignIn/index.css";
import { useStyles } from "./styles";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import moment from 'moment';
import InputOtp from "@onefifteen-z/react-input-otp";
import OtpTimer from "otp-timer";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackPosition } from "../../shared/constants";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import grayback from '../../image/buttons/grayback.png';

import Tooltip from '@material-ui/core/Tooltip';

import Back from '../../image/buttons/back.png';




const mkStyle = makeStyles({
  input: {
    "&:valid": {
      backgroundColor: "white"
    },
    "&:invalid": {
      color: "#9F6000",
      backgroundColor: "#eee"
    }
  }
});


export default function Signin(props) {
  const cls = mkStyle();
  const classes = useStyles();

  const [stillloan, setstillloan] = React.useState([]);
  const [registerdata, setregister] = React.useState([]);
  const [area, setArea] = React.useState([]);
  const [designationEnable, setDesignationEnable] = React.useState(false);
  const [promo, setPromo] = React.useState();
  const [cntCode, setCtCode] = React.useState([]);
  const [otpBtn, setOtpBtn] = useState(false);
  let [initialValues, setInitialValue] = React.useState({});

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    // checkuser();
    checkValidationSchema();
    loanstillcheck();
    getAllCountryCode();
    setDesignationEnable(false);
    getOtpTimer();
    //   getsignupdata();
    getUserID();
    validateincorporationdate();
    validatemaxincorporationdate();
    applyStyle();
  }, []);

  localStorage.setItem("check", JSON.stringify("Loan Application"));


  const [userID, setUserId] = useState();
  const [initialPanValue, setInitialPanValue] = useState("");

  const getUserID = () => {

    

    if (localStorage.getItem("SignUpIdAndData")) {
      let uid = JSON.parse(localStorage.getItem("SignUpIdAndData"));
      setUserId(uid);
      let panvalue = uid.pan;
      let role_id;
      if (localStorage.getItem('userdata')) {
        let Data = JSON.parse(localStorage.getItem('userdata'))
        role_id = Data.role_id;
      }
      if (panvalue !== undefined) {
        //  if (panvalue.charAt(3) == 'P') {
        setInitialPanValue(panvalue);
        setInitialValue({
          fname: "",
          pan: "",
          dob: "",
          pincode: "",
          area: "",
          email: "",
          cntrycode: "",
          mobile: "",
          userMobile: "",
          userEmail: ""


        })
      } else {
        setInitialPanValue("");
      }
      // }
    }
  }

  const profileclick = (values) => {
    console.log("kkkkk", values);
    localStorage.setItem("logindata", JSON.stringify(values));
    checklogin();
    //props.history.push({ pathname: '/ProfileSteper' })
  };

  const checklogin = () => {
    //   const signupdata = localStorage.getItem("logindata"),
    //   console.log("SignupData", SignupData);
    //   /*   setuseremail(SignupData.email) */
    //   console.log("useremail", SignupData.email);
    //   console.log("email", email);
    //   // if (email == SignupData.email) {
    //   //   props.history.push({ pathname: "/ProfileSteper" });
    //   // } else {
    //   //   props.history.push({ pathname: "/loading" });
    //   // }
  };

  const handleNextClick = () => {
    const register = localStorage.getItem("register"),
      registerData = JSON.parse(register);
    setregister(registerData);
    props.history.push({ pathname: "/loading" });
    console.log("registerData", registerData);
  };

  var loanstillarray = [];
  const loanstillcheck = () => {
    const loanstilldata = {
      token: "qwerty1234",
      type: "yes_no",
    };
    axios
      .post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`, loanstilldata)
      .then((res) => {
        res.data.data.map((item) => {
          console.log("item", item);
          loanstillarray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        setstillloan(loanstillarray);
        console.log("loanstillarray", loanstillarray);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  const getPincodeData = (data) => {
    let getPincode = {
      pincode: data,
      token: "qwerty1234",
    };
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
        getPincode
      )
      .then((res) => {
        console.log("yeh we have pincode", res.data);
        return handleAllAreas(res.data.data);
      })
      .catch((er) => {
        console.log("no pincode sorry ", er);
      });
  };

  const [emailid, setemailid] = React.useState();
  const [mobileno, setmobileno] = React.useState();
  const [panid, setpanid] = React.useState();

  const handleAllAreas = (data) => {
    let allArea = [];
    for (let i = 0; i < data.length; i++) {
      allArea.push({
        key: data[i].area,
        value: data[i].id,
        text: data[i].area,
      });
    }
    return setArea(allArea);
  };

  //#region save registeration data
  const [panExistError, setPanExistError] = useState();

  const postUser = async (values) => {
    
    let userdetails = {};
    userdetails = {
      user_id: userID.id,
      first_name: values.fname,
      date_of_incorporation: values.dob,
      pin_id: Number(values.area),
      pan: values.pan,
      mobile: Number(values.mobile),
      email: values.email,
    };

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/customerRegistration`,
        userdetails
      )
      .then((res) => {
        if (res.data.status === false) {
          setPanError(res.data.message)
          //     alert(res.data.message);
          //setsavenextmessage(true)
          //setSnackBarMsg(res.data.message)
        } else {
          console.log(res.data);
          setPanError("");
          handleNextClick();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  const cpaClientRegistration = async (values) => {
    

    // let uid  ;
    // if(userID.primary_user_id != undefined || userID.primary_user_id != null ){
    //     uid = userID.primary_user_id
    // }else{
    //     uid = userID.id
    // }

    let userdata = JSON.parse(localStorage.getItem('userdata'));

    let userdetails = {
      user_id: userdata.id,
      useremail: values.email,
      usermobile: Number(values.mobile),
      first_name: values.fname,
      date_of_incorporation: values.dob,
      pin_id: Number(values.area),
      pan: values.pan,
      mobile: Number(values.mobile),
      email: values.email,
      country_code: "91",
    };
    // http://http://164.52.207.42/Identitymanagement/8082/server/aagey-lending-platform-be-1/coderscorner/server.php/api/customerRegistration
    await axios.post(apiCalling.coderscorner + `server.php/api/customerRegistrationByCpa`, userdetails).then(result => {
      console.log(result);
      if (result.data.status === false) {
        setPanError(result.data.message);
      } else {
        setPanError("");
        localStorage.setItem('userbycpa', result.data.Data.user_id);
        localStorage.setItem('businessflag', 1);
        props.history.push({ pathname: 'ProfileSteper' });
      }
    }).catch(error => {
      console.log(error);
    });
  }


  const regiterUser = (values, { resetForm }) => {
    
    localStorage.setItem("register", JSON.stringify(values));
    if (roleId == 2 || roleId == 7) {
      postUser(values);
    } else {
      cpaClientRegistration(values);
    }
  };

  const setPromoValue = (value) => {
    //setPromo(value);
    console.log("prmosm", promo);
    if (value == 16) {
      setDesignationEnable(!designationEnable);
    } else {
      setDesignationEnable(false);
    }
  };

  const getAllCountryCode = () => {
    const countryCode = {
      startlimit: "1",
      endlimit: "220",
    };
    var countryCodeArray = [];
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAllnationality`,
        countryCode
      )
      .then((result) => {
        //console.log(result.data.data);
        result.data.data.map((data) => {
          countryCodeArray.push({
            key: data.alpha_2_code,
            value: data.id,
            flag: data.alpha_3_code,
            text: data.country,
          });
        });
        setCtCode(countryCodeArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  //#region  pan Validtation

  //#region  Karza's pan Validation Api
  const [panError, setPanError] = useState();
  const [panlabel1, setPanLabel] = useState("");
  const getNameFromPan = async (e) => {
    
    setPanError("");
    let panvalue = e.target.value;
    let panName = "";
    await axios.get(apiCalling.thirdParty + 'public/apikaz?app_name=PAN&in_data=' + panvalue + '&create=Username&modify=username').then(res => {
      console.log(res.data);
      let datas = res.data;
      if (datas.status_code == "102") {
        setPanError("Invalid Pan");
        // setPanLabel("AAAAA9999A")
      } else {
        setPanError("")
        // setInitialValue({
        //   ...initialValues,
        //   fname:datas.result.name
        // });
        // setPanLabel(datas.result.name);
        panName = datas.result.name;;
        //  return datas.result.name;
      }
    }).catch((error) => {
      console.log(error);
    });

    return panName;
  }
  //#endregion


  //#region dynamic otp timer
  const [otpMinutes, setOtpMinutes] = useState();
  const [otpSeconds, setOtpSeconds] = useState();
  const getOtpTimer = () => {
    let data = {
      parameter_name: "OTP TIMER",
    };
    axios
      .post(
        apiCalling.identityManagement + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then((result) => {
        console.log(result.data.data[0]);
        let data = result.data.data[0].parameter_value;
        console.log("timer", data);
        convertTimerToInt(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //#endregion

  // #region convertion of time
  //conversion for Otp timer into
  const convertTimerToInt = (timer) => {
    let newTimer = parseInt(timer);
    var minutes = Math.floor(newTimer / 60);
    var seconds = newTimer - 60 * minutes;
    setOtpMinutes(minutes);
    setOtpSeconds(seconds);
  };

  //#region select countr
  const selectCountry = () => {

  }

  //#region 
  //mobile and email otp form show and hide
  const [promomobile, setPromoMobile] = React.useState();
  const onBlurMobile = (e) => {
    setVerifiedIcon(false);
    setMobileVerifyIcon(false)
      ; let minDigit = 10;
    let maxDigit = 10;
    // console.log(e.target.value);
    let val = e.target.value;
    let len = val.toString().length;
    // console.log(len);
    // if (len == 10) {
    // setOtpBtn(!otpBtn);
    verifyBusinessMobile(val);
    setPromoMobile(val);

    // }
  };
  //#region  onblur verify mobile and get otp 

  const [verifiedIcon, setVerifiedIcon] = useState(false);
  const [mobileError, setMobileError] = useState("");

  const verifyBusinessMobile = (mobilenumber) => {
    setMobileVerifyIcon(false);
    
    let data = {
      type: "mobile",
      value: mobilenumber,
      form_id: 104,
    };
    axios
      .post(apiCalling.coderscorner + `server.php/api/clientRegOtp`, data)
      .then((result) => {
        if (result.data.status === true) {
          setOtpBtn(!otpBtn);
          console.log(result.data);
          setVerifiedIcon(false);
          setMobileError("");

        } else if (result.data.status === false) {
          setMobileError(result.data.message);
        }
        else if (result.data.status === 101) {
          // alert(result.data.message);
          setVerifiedIcon(true)
          setMobileVerifyIcon(true);
          enableButton(dateValidate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeMobileError = () => {
    setMobileError("");
  }

  const [enableRegistration, setEnableRegistration] = React.useState(true);
  const enableButton = (flag) => {
    
    if ((mobileVerifyIcon === true || emailVerifyIcon === true) && flag === true) {
      setEnableRegistration(false);
    } else {
      setEnableRegistration(true);
    }
  }

  //#region  otp verifcation
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true);
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false);
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false);
  const verifyMobileOtp = (e) => {
    let eotp = e;
    let len = eotp.length;
    if (len === 6) {
      let data = {
        otp: eotp,
        value: promomobile,
        form_id: 104,
      };
      axios
        .post(
          apiCalling.coderscorner + `server.php/api/verifyCusOtp`,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            setHideMobileResenBtn(false);
            setMobileVerifyIcon(true);
            setIfMobileOtpInvald(false);
            enableButton(dateValidate);
          } else {
            setIfMobileOtpInvald(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //#endregion

  //#region  resend otp for mobile
  const redendOtpMobile = () => {
    let data = {
      value: promomobile,
      type: "mobile",
      form_id: 104
    };
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/resendOtp`,
        data
      )
      .then((result) => {
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //#region  show and hide mobile inout field
  const getBackToMobile = () => {
    setOtpBtn(false);
    setHideMobileResenBtn(true);
    setMobileVerifyIcon(false);
  };
  //verifying user otp
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false);
  const [hideEmailResenBtn, setHideEmailResenBtn] = useState(true);
  const [ifEmailOtpInvald, setIfEmailOtpInvald] = useState(false);
  const [emailOtp, setEmailOtp] = React.useState(false);
  const [promoEmail, setPromoEmail] = React.useState();

  //#region onblur email get otp
  const onBlurEmail = (e) => {
    setVerifiedIconEmail(false);
    setEmailVerifyIcon(false);
    
    let val1 = e.target.value;
    if (
      (val1.includes("@") && val1.includes(".") && val1.includes("com")) ||
      val1.includes("in") ||
      val1.includes("net") ||
      val1.includes("info") ||
      val1.includes("org") ||
      val1.includes("gov") ||
      val1.includes("edu")
    ) {

      verifyBusinessEmail(val1);
      setPromoEmail(val1);
    }
    let val = e.target.value;
  };

  const [verifiedIconEmail, setVerifiedIconEmail] = React.useState(false);
  const [emailError, setEmailError] = useState("");
  const verifyBusinessEmail = async (getemail) => {
    
    
    let data = {
      type: "email",
      value: getemail,
      form_id: 104,
    };
    await axios
      .post(apiCalling.coderscorner + `server.php/api/clientRegOtp`, data)
      .then((result) => {
        if (result.data.status === true) {
          setEmailError("");
          setEmailOtp(!emailOtp);
          console.log(result.data);
          setVerifiedIconEmail(false);
        } else if (result.data.status === false) {
          setEmailError(result.data.message);
          setVerifiedIconEmail(false);
        } else if (result.data.status === 101) {
          setEmailError("");
          setVerifiedIconEmail(true);
          setEmailVerifyIcon(true);
          enableButton(dateValidate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //#endregion

  //#region  verifying email otp
  const verifyEmialOtp = (e) => {
    let eotp = e;
    let len = eotp.length;
    if (len == 6) {
      let data = {
        value: promoEmail,
        form_id: 104,
        otp: eotp,
      };
      axios
        .post(
          apiCalling.coderscorner + `server.php/api/verifyCusOtp`, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            setHideEmailResenBtn(false);
            setEmailVerifyIcon(true);
            setIfEmailOtpInvald(false);
            enableButton(dateValidate);
          } else {
            setIfEmailOtpInvald(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //#region  resend email otp

  const resendOtpEmail = () => {
    let data = {
      type: "email",
      value: promoEmail,
      form_id: 104
    };
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/resendOtp`,
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //#endregion

  //#region get back to email
  const getBackToEmail = () => {
    setEmailOtp(false);
    setEmailVerifyIcon(false);
    setHideEmailResenBtn(true);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }


  const [maximumdate, setmaximumdate] = React.useState();  //by default today date
  const validateincorporationdate = async () => {
    let data = {
      parameter_name: "Incorporation Max Date"
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/getGeneralSettingByParameter', data).then(res => {
      console.log('date validation results', res.data.data);
      let maxyearparval = res.data.data[0].parameter_value;
      console.log('maxyearparval...........', maxyearparval)
      const now = moment();
      const year = now.subtract(maxyearparval, 'years');
      let substracteddate = moment(year).format('YYYY-MM-DD');
      console.log('letsubstracteddate...........', substracteddate)
      setmaximumdate(substracteddate)
    })
  }

  const [minimumdate, setminimumdate] = React.useState(); //3 years ago
  const validatemaxincorporationdate = async () => {
    let data = {
      parameter_name: "Incorporation Min Date"
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/getGeneralSettingByParameter', data).then(res => {
      console.log('date validation results', res.data.data);
      let maxyearparval = res.data.data[0].parameter_value;
      console.log('maxyearparval...........', maxyearparval)
      const now = moment();
      const year = now.subtract(maxyearparval, 'years');
      let substracteddate = moment(year).format('YYYY-MM-DD');
      console.log('letsubstracteddate...........', substracteddate)
      setminimumdate(substracteddate)
    })
  }

  const [dateError, setDateError] = React.useState("");
  const [dateValidate, setDateValidate] = useState(false);

  const handleDate = (e) => {

    
    var date = new Date(e.target.value);
    if (date > new Date(maximumdate) || date < new Date(minimumdate)) {
      console.log("Date not in range");
      setDateError("Date should be in between " + minimumdate + " and " + maximumdate);
      setEnableRegistration(true)
      setDateValidate(false);
      enableButton(false);

    } else {
      console.log("Date range");
      setDateError("");
      setDateValidate(true);
      enableButton(true);
    }
  }

  let roleId = undefined;

  if (localStorage.getItem('userdata')) {
    let Data = JSON.parse(localStorage.getItem('userdata'))
    roleId = Data.role_id;
  } else {
    let Data = JSON.parse(localStorage.getItem('SignUpIdAndData'));
    roleId = Data.role_id;
  }
  console.log("------------>", roleId);

  let userdata = JSON.parse(localStorage.getItem('userdata'));


  const [userMobileMsg, setUserMobileMsg] = useState("");
  const [userEmailMsg, setUserEmailMsg] = useState("");

  // const checKEmailAndMoBileForCpaReg = async (e) => {
  //   
  //   let data;
  //   let value = e.target.value;
  //   if (value.includes('@')) {
  //     data = {
  //       email: value,
  //     }
  //   } else {
  //     data = {
  //       mobile: value
  //     }
  //   }
  //   await axios.post(`http://localhost:8083/server/aagey-lending-platform-be-1/IdentityManagement/server.php/api/checkEmailAndMobile`, data).then(result => {
  //     if(result.data != ""){
  //     if (result.data.status) {
  //       if (value.includes('@')) {
  //         setUserEmailMsg(result.data.message);
  //       } else {
  //         setUserMobileMsg(result.data.message);
  //       }
  //     }
  //   }else{
  //     if(value.includes('@')){
  //       verifyBusinessEmail(value);
  //     }else{
  //       verifyMobileOtp(value);
  //     }
  //   }
  //     console.log(result);
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }

  const checKMobileForCpaReg = async (e) => {
    let mobdata = {
      type: "mobile",
      value: e.target.value,
      form_id: 1,
    };
    
    await axios
      .post(apiCalling.identityManagement + `server.php/api/otp`, mobdata)
      .then((result) => {
        if (result.data.status === true) {
          setMobileDisabled(false);
          console.log(result)
          setUserMobileMsg("");
        } else {
          setUserMobileMsg(result.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const checKEmailForCpaReg = async (e) => {
    let emaildata = {
      type: "email",
      value: e.target.value,
      form_id: 1,
    }
    
    await axios
      .post(apiCalling.identityManagement + `server.php/api/otp`, emaildata)
      .then((result) => {

        if (result.data.status === true) {
          console.log("email otp", result.data);
          setEmailDisabled(false);
          console.log(result);

          setUserEmailMsg("");
        } else {
          setUserEmailMsg(result.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const [cpaMobileBadge, setCpaMobileBadge] = useState(false);
  const [cpaEmailBadge, setCpaEmailBadge] = useState(false);

  const [loginStyle, setLoginStyle] = useState("login-form");
  const [cardStyle, setCardStyle] = useState("reg-card");

  const applyStyle = () => {
    if (localStorage.getItem("cpa_reg")) {
      setLoginStyle("login_form_cpa ");
      setCardStyle("reg-card-cpa");
    } else {
      setLoginStyle("login-form");
      setCardStyle("reg-card");
    }
  }
  const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[300],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChangeSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });

  const [userMobileOtp, setUserMobileOtp] = useState(false);
  const [userEmailOtp, seUserEmailOtp] = useState(false);
  const getBackToUserMobile = () => {
    // setState({...state,checkedA:false})
    setUserMobileOtp(false)

  }
  const getBackToUserEmail = () => {
    //setState({...state,checkedB:false})
    seUserEmailOtp(false)
  }

  const [vaidationschema, setValidationschema] = useState(register);

  const [rId, setRId] = React.useState("");

  const checkValidationSchema = () => {
    let roll_id;
    if (localStorage.getItem('userdata')) {
      let Data = JSON.parse(localStorage.getItem('userdata'))
      roll_id = Data.role_id;
      setRId(roll_id);
    } else {
      let Data = JSON.parse(localStorage.getItem('SignUpIdAndData'));
      roll_id = Data.role_id;
    }
    if (roll_id == 1) {
      setValidationschema(registerCpa)
    }
  }

  const verifyUserEmailOtp = async (e, values) => {
    
    let motp = e;
    let data = {
      value: values.useremail,
      form_id: 1,
      otp: motp
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/verifyOtp', data).then(result => {
      console.log(result);
      if (result.data.status == true) {
        setIfEmailOtpInvaldUser(false);
        setEmailVerifyIconUser(true);
      } else {
        setIfEmailOtpInvaldUser(true);
        setEmailVerifyIconUser(false)
      }
    }).catch(error => {
      console.log(error);
    });

  }

  const verifyUserMobileOtp = async (e, values) => {
    
    let motp = e;
    let data = {
      value: values.usermobile,
      form_id: 1,
      otp: motp
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/verifyOtp', data).then(result => {
      console.log(result);
      if (result.data.status == true) {
        setmobileVerifyIconUser(true);
        setifmobileOtpInvaldUser(false);
      } else {
        setmobileVerifyIconUser(false);
        setifmobileOtpInvaldUser(true);
      }
    }).catch(error => {
      console.log(error);
    });
  }


  const [ifEmailOtpInvaldUser, setIfEmailOtpInvaldUser] = useState(false);
  const [emailVerifyIconUser, setEmailVerifyIconUser] = useState(false);

  const [ifmobileOtpInvaldUser, setifmobileOtpInvaldUser] = useState(false);
  const [mobileVerifyIconUser, setmobileVerifyIconUser] = useState(false);


  const [emailDisabled, setEmailDisabled] = useState(true);
  const [mobileDisabled, setMobileDisabled] = useState(true);


  const checkEmailFilled = (values) => {
    seUserEmailOtp(true);

  }


  const chechMobileFilled = () => {
    setUserMobileOtp(true);
  }

  const getBackToProfile = () => {
    
  //   window.location = apiCalling.frontCaller+ '/#/ProfileSteper';
   //  window.location = apiCalling.frontCaller + '/#/ProfileSteper';
     window.location = apiCalling.frontCaller + '/#/ProfileSteper';
      // window.location = 'https://platform.aagey.com/app/#/ProfileSteper';
  }


  // const checkPanKarza =async (panname) => {
  //   await axios.get(apiCalling.thirdParty + '/public/apikaz?app_name=PAN&in_data=' + panname + '&create=' + 1 + '&modify=' + 1 + ' ').then(res => {
  //   }).then(error => {
  //     console.log(error);
  //   });
  // }

  return (
    <div className={loginStyle}>
      <div className={cardStyle}>

        <Snackbar open={savenextmessage} style={{ marginTop: "83px", marginRight: "17%" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal
        }}
        >
          <Alert onClose={() => { setsavenextmessage(false) }} severity="error" className="snackBar">
            {snackBarMsg}
          </Alert>
        </Snackbar>
        <Row>
          <Col md={{ span: 12 }}>
            <Row style={{ marginBottom: "2%" }}>
              <Col md={{ span: 6 }}>

                <h1>Client Registration</h1>
                {
                  // <span>
                  //   <img alt="back" className='backbutton navbutton docbackbtn' src={Back} />
                  //   <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                  //     <img alt="back" className='backbutton navgraybutton docbackbtn' src={grayback} />
                  //   </Tooltip>
                  // </span>
                }
              </Col>
              <Col md={{ span: 6 }}>
                {roleId !== undefined && roleId == 1 && (

                  <Button className="backBtn" style={{ height: "30px" }}
                    variant="outlined" onClick={() => { getBackToProfile() }}
                    color="primary">Back</Button>

                )}
              </Col>

            </Row>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={vaidationschema}
              onSubmit={regiterUser}
              disabled={true}
            >
              {({
                values,
                errors,
                touched,
                resetForm,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                isValidating
                /* and other goodies */
              }) => {
                //  console.log("values in formik", values);

                return (
                  <form onSubmit={handleSubmit}>
                    {true && (
                      <Row>
                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Business PAN" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField style={{ marginBottom: "-1.5%" }}
                                id="filled-error-helper-text standard-basic"
                                name="pan"
                                onChange={handleChange}
                                onBlur={async (e) => {
                                  
                                  let panVal = await getNameFromPan(e);
                                  
                                  setFieldValue("fname", panVal);
                                }}
                                InputProps={{
                                  className: values.pan
                                    ? classes.manorma
                                    : null,
                                }}
                                inputProps={{
                                  maxLength: 10,
                                }}
                                value={values.pan}

                                error={
                                  errors.pan &&
                                    touched.pan &&
                                    errors.pan
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.pan &&
                                  touched.pan &&
                                  errors.pan
                                }
                              />
                            </ListItem>
                            <span className="valMsg">{panError}</span>
                          </List>
                        </Col>

                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Date of Incorporation" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem style={{ marginTop: "9px" }}>

                              <TextField
                                id="dob"
                                type="date"
                                className={classes.textField}
                                name="dob"
                                ///defaultValue={values.someDate}
                                onChange={handleChange}
                                onBlur={handleDate}

                                InputProps={{
                                  classes: cls
                                }}


                                inputProps={
                                  { min: minimumdate, max: maximumdate }
                                }

                                value={values.date}
                                error={
                                  errors.dob &&
                                    touched.dob &&
                                    errors.dob
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.dob &&
                                  touched.dob &&
                                  errors.dob
                                }
                              />


                            </ListItem>
                            <p className="datepickerError">{dateError}</p>
                          </List>
                        </Col>

                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <Typography text="Business Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="fname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                //readOnly={true}
                                InputProps={{
                                  //readOnly:true,
                                  className: values.fname
                                    ? classes.manorma
                                    : null,
                                }}
                                error={
                                  errors.fname &&
                                    touched.fname &&
                                    errors.fname
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.fname &&
                                  touched.fname &&
                                  errors.fname
                                }
                                value={values.fname}
                              />
                            </ListItem>
                          </List>
                        </Col>

                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Pincode" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="pincode"
                                label="999999"
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  if (!errors.pincode) {
                                    getPincodeData(values.pincode);
                                  }
                                }}
                                error={
                                  errors.pincode &&
                                    touched.pincode &&
                                    errors.pincode
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.pincode
                                    ? classes.manorma
                                    : null,
                                }}
                                inputProps={{
                                  maxLength: 6,
                                }}
                                value={values.pincode}
                                helperText={
                                  errors.pincode &&
                                  touched.pincode &&
                                  errors.pincode
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>

                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Area" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Area"
                                id="area"
                                name="area"
                                fluid
                                search
                                selection
                                value={values.area}
                                onBlur={() => setFieldTouched("area", true)}
                                onChange={(e, { value }) =>
                                  setFieldValue("area", value)
                                }
                                options={area}
                                className={
                                  errors.area && touched.area
                                    ? classes.required
                                    : values.area === ""
                                      ? classes.reqired
                                      : classes.man
                                }
                              />
                            </ListItem>
                          </List>
                          {errors.area &&
                            touched.area &&
                            values.area === "" && (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: 0,
                                  fontSize: 11,
                                  marginTop: "-11px"
                                }}
                              >
                                {errors.area}
                              </div>
                            )}
                        </Col>

                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Business Mobile" styleType="B" className="promoter_mobile" />
                          {"     "}{" "}
                          {otpBtn && (
                            <span className="hidden_number">
                              You entered: {values.mobile}
                            </span>
                          )}
                          {verifiedIcon === true &&
                            (

                              <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>

                            )
                          }
                          <List component="div" disablePadding>
                            {otpBtn === false && (
                              <>
                                <ListItem>
                                  <Row style={{ width: "104%", padding: "unset" }}>
                                    <Col md={{ span: 4 }}>
                                      <PhoneInput country="in"
                                        enableSearch={true} onChange={(val) => selectCountry(val)} />
                                    </Col>
                                    <Col md={{ span: 8 }} style={{ padding: "unset" }}>
                                      <TextField style={{ marginTop: "10px", marginBottom: "-1.5%" }}
                                        id="filled-error-helper-text standard-basic " readonly
                                        // label="Enter Mobile No"
                                        name="mobile"
                                        onChange={handleChange}
                                        onBlur={onBlurMobile}
                                        onKeyUp={changeMobileError}
                                        InputProps={{
                                          className: values.mobile
                                            ? classes.manorma
                                            : null,
                                        }}
                                        error={
                                          errors.mobile &&
                                            touched.mobile &&
                                            errors.mobile
                                            ? true
                                            : false
                                        }
                                        value={values.mobile}
                                        helperText={
                                          errors.mobile &&
                                          touched.mobile &&
                                          errors.mobile
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </ListItem>
                                <span style={{
                                  color: "red",
                                  marginLeft: "31%",
                                  fontSize: 11,
                                  marginTop: "-11px"
                                }}>
                                  {mobileError}
                                </span>
                              </>)}
                            {otpBtn == true && (
                              <>
                                <p className="otpsent">Enter OTP</p>
                                <ListItem className="otpfieldsReg" style={{ marginTop: "-3px" }}>
                                  <InputOtp onChange={verifyMobileOtp} />
                                  {hideMobileResenBtn === true && (
                                    <div style={{ marginTop: "-5px" }}>
                                      <OtpTimer
                                        seconds={otpSeconds}
                                        minutes={otpMinutes} //otpMinutes
                                        className="otptimerReg"
                                        resend={redendOtpMobile}
                                        text=" "
                                        buttonColor={"white"}
                                        background={"#007bff"}
                                      />
                                    </div>

                                  )}
                                  <Button onClick={getBackToMobile}>
                                    <i className="far fa-edit"
                                      style={{ fontSize: "15px", marginTop: "-6px" }}></i>
                                  </Button>

                                  {mobileVerifyIcon === true && (
                                    <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green', marginTop: '-1%' }} ></i>
                                  )}
                                  {ifMobileOtpInvald === true && (
                                    <span style={{ color: "red" }}>
                                      Invalid Otp{" "}
                                    </span>
                                  )}
                                </ListItem>
                              </>
                            )}
                          </List>
                        </Col>

                        <Col md={{ span: 6 }} className="textfeild_txtbox">
                          <Typography text="Business Email" styleType="B" />
                          {"     "}{" "}
                          {emailOtp && (
                            <span className="hidden_number">
                              You entered: {values.email}
                            </span>
                          )}

                          {verifiedIconEmail === true &&
                            (

                              <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>

                            )
                          }
                          <List component="div" disablePadding>
                            {emailOtp == false && (
                              <>
                                <ListItem>
                                  <TextField style={{ marginTop: "10px", marginBottom: "-1.5%" }}
                                    id="filled-error-helper-text standard-basic " readonly
                                    // label="Enter Email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={onBlurEmail}

                                    InputProps={{
                                      className: values.email
                                        ? classes.manorma
                                        : null,
                                    }}
                                    error={
                                      errors.email &&
                                        touched.email &&
                                        errors.email
                                        ? true
                                        : false
                                    }
                                    value={values.email}
                                    helperText={
                                      errors.email &&
                                      touched.email &&
                                      errors.email
                                    }
                                  />
                                </ListItem>
                                <span style={{
                                  color: "red",
                                  fontSize: "0.75rem",
                                  marginTop: "-11px"
                                }}>
                                  {emailError}
                                </span>
                              </>)}
                            {emailOtp && (
                              <>
                                <p className="otpsent">Enter OTP</p>
                                <ListItem className="otpfieldsReg" style={{ marginTop: "-1px" }}>
                                  <InputOtp onChange={verifyEmialOtp} />
                                  {hideEmailResenBtn === true && (
                                    <div style={{ marginTop: "-5px" }}>
                                      <OtpTimer
                                        seconds={20}
                                        minutes={0} //otpMinutes
                                        style={{ color: "blue", marginTop: "-18px" }}
                                        resend={resendOtpEmail}
                                        text=" "
                                        buttonColor={"white"}
                                        background={"#007bff"}
                                      />
                                    </div>
                                  )}
                                  <Button onClick={getBackToEmail}>
                                    <i
                                      className="far fa-edit"
                                      style={{ fontSize: "15px", marginTop: "-6px" }}
                                    ></i>
                                  </Button>
                                  {emailVerifyIcon === true && (
                                    <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green', marginTop: '-1%' }} ></i>
                                  )}
                                  {ifEmailOtpInvald === true && (
                                    <span style={{ color: "red" }}>
                                      Invalid Otp{" "}
                                    </span>
                                  )}
                                </ListItem>
                              </>
                            )}
                          </List>

                        </Col>

                        {(roleId == 1 || roleId == 6) ? (
                          <Col md={{ span: 6 }} className="textfeild_txtbox">
                            <Typography text="Login User Mobile" styleType="B" className="promoter_mobile" />
                            {"     "}{" "}
                            <Button color="primary" onClick={chechMobileFilled} disabled={mobileDisabled} style={{ marginLeft: "2px" }}>Verify</Button>

                            {cpaMobileBadge === true &&
                              (
                                <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>
                              )
                            }
                            <List component="div" disablePadding>

                              {userMobileOtp == false ? (<>
                                <ListItem style={{ marginBottom: "-1%" }}>
                                  <Row style={{ width: "104%", padding: "unset" }}>
                                    <Col md={{ span: 4 }}>
                                      <PhoneInput country="in"
                                        enableSearch={true} onChange={(val) => selectCountry(val)} />
                                    </Col>
                                    <Col md={{ span: 8 }} style={{ padding: "unset" }}>
                                      <TextField style={{ marginTop: "10px", marginBottom: "-1.5%" }}
                                        id="filled-error-helper-text standard-basic " readonly
                                        // label="Enter Mobile No"
                                        name="usermobile"
                                        onChange={handleChange}
                                        onBlur={checKMobileForCpaReg}

                                        InputProps={{
                                          className: values.usermobile
                                            ? classes.manorma
                                            : null,
                                        }}
                                        error={
                                          errors.usermobile &&
                                            touched.usermobile &&
                                            errors.usermobile
                                            ? true
                                            : false
                                        }
                                        value={values.usermobile}
                                        helperText={
                                          errors.usermobile &&
                                          touched.usermobile &&
                                          errors.usermobile
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </ListItem>
                                <span style={{
                                  color: "red",
                                  marginLeft: "31%",
                                  fontSize: "0.75rem",
                                  marginTop: "-11px"
                                }}>
                                  {userMobileMsg}
                                </span>
                              </>) : (
                                <>
                                  <p className="otpsent">Enter OTP</p>
                                  <ListItem className="otpfieldsReg" style={{ marginTop: "-1px" }}>
                                    <InputOtp onChange={(e) => { verifyUserMobileOtp(e, values) }} />
                                    {hideEmailResenBtn === true && (
                                      <div style={{ marginTop: "-5px" }}>
                                        <OtpTimer
                                          seconds={30}
                                          minutes={1} //otpMinutes
                                          style={{ color: "blue", marginTop: "-18px" }}
                                          resend={resendOtpEmail}
                                          text=" "
                                          buttonColor={"white"}
                                          background={"#007bff"}
                                        />
                                      </div>
                                    )}
                                    <Button onClick={getBackToUserMobile}>
                                      <i
                                        className="far fa-edit"
                                        style={{ fontSize: "15px", marginTop: "-6px" }}
                                      ></i>
                                    </Button>
                                    {mobileVerifyIconUser === true && (
                                      <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green', marginTop: '-1%' }} ></i>
                                    )}
                                    {ifmobileOtpInvaldUser === true && (
                                      <span style={{ color: "red" }}>
                                        Invalid Otp{" "}
                                      </span>
                                    )}
                                  </ListItem>
                                </>)}
                            </List>
                          </Col>
                        ) : null}


                        {(rId == 1 || rId == 6) ? (

                          <Col md={{ span: 6 }} className="textfeild_txtbox">
                            <Typography text="Login User Email" styleType="B" />
                            <Button color="primary" style={{ marginLeft: "2px" }} disabled={emailDisabled} onClick={() => { checkEmailFilled(values) }} >Verify</Button>
                            {cpaEmailBadge === true &&
                              (
                                <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>
                              )
                            }
                            <List component="div" disablePadding>
                              {userEmailOtp == false ? (
                                <>
                                  <ListItem style={{ marginBottom: "-1%" }}>
                                    <TextField style={{ marginTop: "10px", marginBottom: "-1.5%" }}
                                      id="filled-error-helper-text standard-basic " readonly
                                      // label="Enter Email"
                                      name="useremail"
                                      onChange={handleChange}
                                      onBlur={checKEmailForCpaReg}
                                      InputProps={{
                                        className: values.useremail
                                          ? classes.manorma
                                          : null,
                                      }}
                                      error={
                                        errors.useremail &&
                                          touched.useremail &&
                                          errors.useremail
                                          ? true
                                          : false
                                      }
                                      value={values.useremail}
                                      helperText={
                                        errors.useremail &&
                                        touched.useremail &&
                                        errors.useremail
                                      }
                                    />

                                  </ListItem>
                                  <ListItem>

                                  </ListItem>
                                  <span style={{
                                    color: "red",
                                    fontSize: "0.75rem",
                                    marginTop: "-11px"
                                  }}>
                                    {userEmailMsg}
                                  </span>
                                </>

                              ) : (
                                <>
                                  <p className="otpsent">Enter OTP</p>
                                  <ListItem className="otpfieldsReg" style={{ marginTop: "-1px" }}>
                                    <InputOtp onChange={(e) => { verifyUserEmailOtp(e, values) }} />
                                    {hideEmailResenBtn === true && (
                                      <div style={{ marginTop: "-5px" }}>
                                        <OtpTimer
                                          seconds={30}
                                          minutes={1} //otpMinutes
                                          style={{ color: "blue", marginTop: "-18px" }}
                                          resend={resendOtpEmail}
                                          text=" "
                                          buttonColor={"white"}
                                          background={"#007bff"}
                                        />
                                      </div>
                                    )}
                                    <Button onClick={getBackToUserEmail}>
                                      <i
                                        className="far fa-edit"
                                        style={{ fontSize: "15px", marginTop: "-6px" }}
                                      ></i>
                                    </Button>
                                    {emailVerifyIconUser === true && (
                                      <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green', marginTop: '-1%' }} ></i>
                                    )}
                                    {ifEmailOtpInvaldUser === true && (
                                      <span style={{ color: "red" }}>
                                        Invalid Otp{" "}
                                      </span>
                                    )}
                                  </ListItem>
                                </>
                              )}
                            </List>
                          </Col>

                        ) : null}
                      </Row>
                    )}
                    <div className="footer reg-btn">
                      <Button
                        onClick={handleSubmit}
                        // disabled={isSubmitting}
                        disabled={enableRegistration}
                        className="ctm_btn_set footer"
                      >
                        Register
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </div>
  );
}
