import React, { useState } from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { DropzoneArea } from 'material-ui-dropzone'
import { useStyles } from './style.js'

import Typography from '../../base_components/Typography'
import axios from 'axios'
import Tooltip from '@material-ui/core/Tooltip'
import { AttachFile } from '@material-ui/icons'
import addbutton from '../../image/pngicons/plussign.png'
import deleterec from '../../image/pngicons/delete.png'
import imageimg from '../../image/pngicons/image.jpeg'
import excelimg from '../../image/pngicons/excel.jpeg'
import view from '../../image/pngicons/view.jpeg'
import pdfimage from '../../image/pngicons/pdf.jpeg'
//import { useStyled } from "./styles";
import moment from 'moment'
import { ErrorCatch, apiCalling } from '../../shared/constants'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { Dropdown } from 'semantic-ui-react'
import FileViewer from 'react-file-viewer'

import MuiAlert from '@material-ui/lab/Alert'

import Snackbar from '@material-ui/core/Snackbar'

import { SnackPosition } from '../../shared/constants'

import { CustomErrorComponent } from 'custom-error'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'

import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'

const DocumentUpload = props => {
  //;
  const [gstHeaderId, setGstHeaderId] = React.useState(0)
  const [bankHeaderId, setBankHeaderId] = React.useState(0)
  const [gstPeriod, setGstPeriod] = React.useState('12')
  const classes = useStyles()

  React.useEffect(() => {
    const getLatFileByParentId = async () => {
      setLoaderFlag(true)
      
      let dint = ''
      let data = {
        parent_id: props.formdetails.parentId
      }
      // let urlLocal = apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getLatFileByParentId';

      let url = apiCalling.coderscorner + 'server.php/api/getLatFileByParentId'

      let response = await axios
        .post(url, data)
        .then(result => {
          console.log(result)
          if (result.data.status) {
            setGstPeriod(result.data.data)
            dint = result.data.data
          }
          getUploadeDocOfUserV2(dint)
        })
        .then(error => {
          console.log(error)
          setLoaderFlag(false)
        })
      setLoaderFlag(false)
      console.log(response)
    }

    getLatFileByParentId()
    getValidationConfiguration();
    // getUploadeDocOfUser();
    

    getGstInterval()
    // detailsDocument();
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (
      props.formdetails.parentId != undefined ||
      props.formdetails.parentId != ''
    ) {
      setGstHeaderId(props.formdetails.parentId)
      setBankHeaderId(props.formdetails.parentId)
      //getManualDataByGstHeaderIdV2(props.formdetails.parentId);
    }
  }, [])

  const getLatFileByParentIdV2 = async () => {
    
    let dint = ''
    let data = {
      parent_id: props.formdetails.parentId
    }
    // let urlLocal = apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getLatFileByParentId';

    let url = apiCalling.coderscorner + 'server.php/api/getLatFileByParentId'

    let response = await axios
      .post(url, data)
      .then(result => {
        console.log(result)
        if (result.data.status) {
          setGstPeriod(result.data.data)
          dint = result.data.data
        }
        getUploadeDocOfUserV2(dint)
      })
      .then(error => {
        console.log(error)
      })
      .catch(error => {
        console.log(error)
      })
    // return dint;
    console.log(response)
  }

  function Alert (props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const [docBase64, setDocBase64] = React.useState()
  const [showmodal, setshowmodal] = React.useState(false)
  const [colorMessage, setcolorMessage] = React.useState()
  const [previewType, setPreviewType] = React.useState('')
  const [previewFile, setPreviewFile] = React.useState('')
  const [fileList, setFilelist] = React.useState([])

  const pdfContentType = 'application/pdf'

  /// base64 to  file code
  const base64toBlob = (data, content_type) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    
    const base64WithoutPrefix = data.substr(
      `data:${content_type};base64,`.length
    )
    const bytes = atob(base64WithoutPrefix)
    let length = bytes.length
    let out = new Uint8Array(length)
    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }
    return new Blob([out], { type: pdfContentType })
  }

  const handleshowhidepdf = (base64, fileType, item) => {
    

    //   var i = base64.indexOf(':');
    //   var j = base64.indexOf(';');
    //   console.log("i:"+i,"-----","j"+j);
    // //  var splits = [dara.slice(0,i), s.slice(i+1)];
    //     let content_type = base64.slice(i+1,j);

    const url = URL.createObjectURL(base64toBlob(base64, fileType))
    console.log(url)
    setDocBase64(base64)
    let fileExt = fileType.split('/')
    // setPreviewFile(item.docPath);
    setPreviewFile(url)
    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setPreviewType('xlsx')
    } else if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setPreviewType('docx')
    } else if (fileType === 'application/msword') {
      setPreviewType('doc')
    } else {
      setPreviewType(fileExt[1])
    }
    //if(fileExt[fileExt.length-1]=="sheet"){
    setshowmodal(true)
    //  }
  }

  const handleclosePdf = () => {
    setshowmodal(false)
  }

  const [maxFileSize, setMaxFileSize] = React.useState([]);
  const [maxFileSizeMsg, setMaxFileSizeMsg] = React.useState([]);
  const [allowedFileType, setAllowedFileType] = React.useState([]);

  const getValidationConfiguration = async () => {
    let data = {
      parameter_name: ['max_upload_size', 'allowed_file_type']
    }

    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGeneralSettingForMultiple',
        data
      )
      .then(result => {
        const res = result.data.data;
        setMaxFileSize(res.max_upload_size[0].parameter_value * 1048576);
        setMaxFileSizeMsg(`File size exceeds maximum limit of 2${res.max_upload_size[0].parameter_value}MB.`)
        setAllowedFileType(res.allowed_file_type[0].parameter_value.split(','));
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [dropFileText, setDropFileText] = React.useState(
    'Drag & Drop file or Browse'
  )

  // for authority wise and pin
  // const getUploadeDocOfUser = async () => {
  //   //;
  //   const docdata = {
  //     user_id: checkuserid.user_id,
  //     parent_id: props.formdetails.parentId,
  //     parent_type_id: props.formdetails.parentTypeId,
  //     form: props.formdetails.formId,
  //     required_phase: "172"
  //   }
  //   let myArr = [];
  //   let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser';
  //   await axios.post(apiCalling.coderscorner + 'server.php/api/findDocsOfUser', docdata).then((result) => {
  //     console.log("rsutl-----------------", result);
  //     if (result.data.data.length > 1) {
  //       result.data.data.map((item) => {
  //         myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
  //       })
  //       console.log("my document array----->", myArr);
  //       setFilelist(myArr);

  //     } else {
  //       detailsDocument();
  //     }
  //   }).catch(error => {
  //     ErrorCatch(userId.id, error, api_path, 'post', docdata, userId, userId.id);
  //     console.log(error)
  //   })
  // }

  // for all pincodes wihtout authority

  const getUploadeDocOfUserV2 = async intPer => {
    
    const docdata = {
      user_id: checkuserid.user_id,
      parent_id: props.formdetails.parentId,
      parent_type_id: props.formdetails.parentTypeId,
      form: props.formdetails.formId,
      required_phase: '172',
      interval_flag: intPer != '' ? intPer : gstPeriod
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'

    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2'

    await axios
      .post(apiPath2, docdata)
      .then(result => {
        console.log('rsutl-----------------', result)
        

        let interval = intPer != '' ? intPer : gstPeriod

        if (result.data.data.length >= 1) {
          result.data.data.map(item => {
            myArr.push({
              id: item.id,
              name: item.name,
              documentArray: item.documentArray
            })

            if (
              item.documentArray[0].interval_flag != null &&
              item.documentArray[0].interval_flag != ''
            ) {
              interval =
                intPer != '' ? intPer : item.documentArray[0].interval_flag
              setGstPeriod(interval)
            }
          })

          console.log('my document array----->', myArr)

          //setFilelist(myArr);
          if (
            props.formdetails.formId == '1.1.4' ||
            props.formdetails.formId == '1.1.7'
          ) {
            bankHeaderV2(props.formdetails.parentId, interval, myArr, true)
          }
          // else if (props.formdetails.formId == "1.1.7") {
          //   getManualDataByGstHeaderIdV2(props.formdetails.parentId, '', myArr, true);
          // }
          else {
            setFilelist(myArr)
          }
        } else {
          detailsDocumentV2()
        }
      })
      .catch(error => {
        ErrorCatch(
          userId.id,
          error,
          api_path,
          'post',
          docdata,
          userId,
          userId.id
        )
        console.log(error)
      })
  }

  const checkuserloggeddata = localStorage.getItem('')
  let userId = JSON.parse(checkuserloggeddata)
  // for doc with pin and authority
  // const detailsDocument = async () => {
  //   //;
  //   const docdata = {
  //     user_id: checkuserid.user_id,
  //     parent_id: props.formdetails.parentId,
  //     parent_type_id: props.formdetails.parentTypeId,
  //     form: props.formdetails.formId,
  //     required_phase: "172"
  //   }
  //   let api_path = apiCalling.coderscorner + `server.php/api/getDetailsDocument`;
  //   await axios.post(apiCalling.coderscorner + `server.php/api/getDetailsDocument`, docdata).then((result) => {
  //     console.log("Document data...!!!", result.data);
  //     let myArr = [];
  //     console.log(result.data)
  //     result.data.data.map((item) => {
  //       myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
  //     })
  //     setFilelist(myArr);
  //   }).catch(error => {
  //     ErrorCatch(userId.id, error, api_path, 'post', docdata, userId.id, userId.id);
  //     console.log(error)
  //   })
  // }

  // for all pincodes wihtout authority
  const detailsDocumentV2 = async () => {
    
    const docdata = {
      user_id: checkuserid.user_id,
      parent_id: props.formdetails.parentId,
      parent_type_id: props.formdetails.parentTypeId,
      form: props.formdetails.formId,
      required_phase: '172'
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getDetailsDocument`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getDetailsDocumentV2`,
        docdata
      )
      .then(result => {
        console.log('Document data...!!!', result.data)
        let myArr = []
        console.log(result.data)
        result.data.data.map(item => {
          myArr.push({
            id: item.id,
            name: item.name,
            documentArray: item.documentArray
          })
        })
        if (props.formdetails.formId == '1.1.4') {
          bankHeaderV2(props.formdetails.parentId, '', myArr, false)
        } else {
          getManualDataByGstHeaderIdV2(
            props.formdetails.parentId,
            '',
            myArr,
            false
          )
        }
        //  setFilelist(myArr);
      })
      .catch(error => {
        ErrorCatch(
          userId.id,
          error,
          api_path,
          'post',
          docdata,
          userId.id,
          userId.id
        )
        console.log(error)
      })
  }

  const fileSetInArray = async (
    e,
    index,
    kIndex,
    checkListId,
    calendar_id,
    calendar_name
  ) => {
    let temp_fileList = [...fileList]
    if (e.length > 0) {
      let temp_file = { ...temp_fileList[index] }
      //  if (props.formdetails.formId == "1.1.7" && temp_file.name == 'GSTR3B') {
      if (false) {
        var date = new Date()
        var months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
        var modifiedDate =
          date.getDate() +
          ' ' +
          months[date.getMonth()] +
          ' ' +
          date.getFullYear()
        let reader = new FileReader()
        reader.readAsDataURL(e[0])
        if (temp_file.documentArray.length > 0) {
          temp_file.documentArray.map((item, i) => {
            if (item.calendar_id == calendar_id) {
              reader.onload = function () {
                // temp_file.documentArray[kIndex].file_name = e[0].name;
                // temp_file.documentArray[kIndex].file_type = e[0].type;
                // temp_file.documentArray[kIndex].modified_date = modifiedDate;
                // temp_file.documentArray[kIndex].document_upload_id = "";
                // temp_file.documentArray[kIndex].base64String = reader.result;
                item.file_name = e[0].name.length>0 ? e[0].name.slice( 0,10 ):e[0].name;
                item.file_type = e[0].type
                item.modified_date = modifiedDate
                item.document_upload_id = ''
                item.base64String = reader.result
                temp_file.documentArray[i] = item
                console.log(temp_file)
                temp_fileList[index] = temp_file
                setFilelist(temp_fileList)
                uploadDoc(
                  e[0].name,
                  e[0].type,
                  checkListId,
                  reader.result,
                  calendar_id,
                  calendar_name
                )
              }
              reader.onerror = function (error) {
                console.log('Error: ', error)
              }
            }
          })
        }
      } else {
        let temp_file = { ...temp_fileList[index] }
        // var date = new Date();
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var modifiedDate = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        let reader = new FileReader()
        reader.readAsDataURL(e[0])
        reader.onload = function () {
          temp_file.documentArray[kIndex].file_name = e[0].name.length>0 ? e[0].name.slice( 0,10 ):e[0].name;
          temp_file.documentArray[kIndex].file_type = e[0].type
          temp_file.documentArray[kIndex].modified_date = modifiedDate
          temp_file.documentArray[kIndex].document_upload_id = ''
          temp_file.documentArray[kIndex].base64String = reader.result
          temp_fileList[index] = temp_file
          setFilelist(temp_fileList)
          uploadDoc(
            e[0].name,
            e[0].type,
            checkListId,
            reader.result,
            calendar_id,
            calendar_name
          )
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      }
    }

    // else {
    //     let temp_file = { ...temp_fileList[index] };
    //     // var date = new Date();
    //     // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //     // var modifiedDate = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    //     let reader = new FileReader();
    //     reader.readAsDataURL(e[0]);
    //     reader.onload = function () {
    //       temp_file.documentArray[kIndex].file_name = e[0].name;
    //       temp_file.documentArray[kIndex].file_type = e[0].type;
    //       temp_file.documentArray[kIndex].modified_date = modifiedDate;
    //       temp_file.documentArray[kIndex].document_upload_id = "";
    //       temp_file.documentArray[kIndex].base64String = reader.result;
    //       temp_fileList[index] = temp_file;
    //       setFilelist(temp_fileList);
    //       uploadDoc(e[0].name, e[0].type, checkListId, reader.result, calendar_id, calendar_name);

    //     };
    //     reader.onerror = function (error) {
    //       console.log('Error: ', error);
    //     };
    // }
  }

  const callFileDeleteOption = index => {
    let temp_fileList = [...fileList]
    let temp_file = { ...temp_fileList[index] }
    temp_file.file_name = ''
    temp_file.file_type = ''
    temp_file.modified_date = ''
    temp_file.document_upload_id = ''
    temp_fileList[index] = temp_file
    setFilelist(temp_fileList)
  }
  // add doc based on fileList array index value from table

  const addDoc = id => {
    let newArr = [...fileList]
    for (let i = 0; i < newArr.length; i++) {
      if (id == i) {
        fileList[id].documentArray.push({
          file_name: '',
          base64String: '',
          file_type: '',
          modified_date: ''
        })
        break
      }
    }
    setFilelist(newArr)
    console.log("list7", fileList)
  }
  // remove document  where mainId is fileList[] array id and subId is docuMentArray index valuex
  const removeDoc = async (mainId, subId, document_uplaod_id) => {
    let newArr = [...fileList]
    for (let i = 0; i < newArr.length; i++) {
      if (mainId == i) {
        newArr[mainId].documentArray.splice(subId, 1)
        break
      }
    }
    if (subId === 0) {
      newArr[mainId].documentArray.push({
        file_name: '',
        base64String: '',
        file_type: '',
        modified_date: ''
      })
    }
    let data = {
      id: document_uplaod_id
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/deleteDocById'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/deleteDocById', data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        ErrorCatch(
          document_uplaod_id,
          error,
          api_path,
          'post',
          data,
          document_uplaod_id,
          document_uplaod_id
        )
        console.log(error)
      })
    setFilelist(newArr)
    console.log("list8", fileList)
  }
  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

  const uploadDoc = (
    name,
    filetype,
    checkListId,
    docbase64,
    calendar_id,
    calendar_name
  ) => {
    
    let data = {
      user_id: checkuserid.user_id,
      parent_id: props.formdetails.parentId,
      parent_type_id: 1,
      document_checklist_id: checkListId,
      name: name,
      file_type: filetype,
      document: docbase64,
      calender_id: calendar_id,
      calendar_name: calendar_name,
      interval_flag: gstPeriod
    }
    let api_path = apiCalling.coderscorner + `server.php/api/savedocuments`
    console.log(data)
    axios
      .post(api_path, data)
      .then(result => {
        

        console.log(result)
        setsavenextmessage(true)
        getLatFileByParentIdV2()
      })
      .catch(error => {
        //  ErrorCatch(userId.id, error, api_path, 'post', data, userId.id, userId.id);
        console.log(error)
      })
  }

  const [interValArray, setIntervalArray] = React.useState([])
  const getGstInterval = async () => {
    //;
    let data = {
      enable: true
    }
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/getAllIntervalsGST', data)
      .then(result => {
        let tempArr = []
        const { data, message } = result.data
        console.log(result)

        data.map(item => {
          tempArr.push({
            name: item.parameter_name,
            text: item.parameter_name,
            value: item.parameter_value
          })
        })
        setIntervalArray(tempArr)
        console.log(message)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [gstMonthArray, setGstMonthArray] = React.useState([])
  const bankHeaderV2 = async (parentid, interVale, fileArr, dataAvailable) => {
    
    let data = {
      bank_header_id: parentid,
      Token: 'qwerty1234',
      bank_interval_flag: interVale
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBankDetailsByidv2',
        data
      )
      .then(result => {
        let tempFileArr = []
        if (result.data.status == true) {
          const { data, status, message } = result.data
          let gstMonthArray = []
          data.map(item => {
            let lab
            if (interVale == 2 || interVale == 4) {
              lab = item.total_month_value
            } else {
              lab = item.label
            }
            gstMonthArray.push({ calendar_id: item.id, parameter: lab })
          })
          if (fileArr != undefined) {
            tempFileArr = fileArr
          } else {
            tempFileArr = [...fileList]
          }
          setGstMonthArray(gstMonthArray)
          if (tempFileArr.length > 0) {
            
            tempFileArr.map((item, i) => {
              if (
                item.name == 'Latest 6 months bank statement' ||
                item.name == 'GSTR3B'
              ) {
                item.gstMonthArray = gstMonthArray
                if (dataAvailable == false) {
                  item.documentArray = []
                }
                if (
                  item.documentArray.length === 1 &&
                  item.documentArray[0].file_name == ''
                ) {
                  item.documentArray = []
                }
                for (let j = 0; j < gstMonthArray.length; j++) {
                  if (
                    j < item.documentArray.length &&
                    item.documentArray[j].file_name != ''
                  ) {
                    item.documentArray[j].calendar_id =
                      gstMonthArray[j].calendar_id
                    item.documentArray[j].calendar_name =
                      interVale == '1' ? '' : gstMonthArray[j].parameter
                  } else {
                    let calPara =
                      interVale == '1' ? '' : gstMonthArray[j].parameter
                    let docObj = {
                      file_name: '',
                      file_type: '',
                      modified_date: '',
                      base64String: '',
                      calendar_id: gstMonthArray[j].calendar_id,
                      calendar_name: calPara
                    }
                    item.documentArray.push(docObj)
                  }
                  // const cal_ids = item.documentArray.map(i => i.calendar_id);
                  // let found = cal_ids.some(calendar_id => calendar_id === gstMonthArray[j].calendar_id);
                  // if (true) {
                  // }
                }
                /// console.log("original aray", tempFileArr);
              }
            })
          }
          console.log('file array print---------------', tempFileArr)
          setFilelist(tempFileArr)
        }
      })
      .catch(error => {
        // ErrorCatch(checkuserid.user_id, error, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
      })
  }

  const getManualDataByGstHeaderIdV2 = async (
    gstheaderid,
    intervalValues,
    fileArr,
    data_available
  ) => {
    
    let intVal
    if (intervalValues != null || intervalValues != undefined) {
      intVal = intervalValues
    } else {
      intVal = 12
    }
    const data = {
      GST_header_id: props.formdetails.parentId,
      eligibility_flag: 0,
      Token: 'qwerty1234',
      gst_interval_flag: intVal
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGstDetailsByidv2',
        data
      )
      .then(result => {
        let tempFileArr = []
        if (result.data.status == true) {
          const { data, status, message } = result.data
          let gstMonthArray = []
          data.map(item => {
            gstMonthArray.push({
              calendar_id: item.calendar_id,
              parameter: item.total_month_value
            })
          })
          if (fileArr != undefined) {
            tempFileArr = fileArr
          } else {
            // for array immedtaite state update using useState use ... operator
            tempFileArr = [...fileList]
          }
          setGstMonthArray(gstMonthArray)
          if (tempFileArr.length > 0) {
            tempFileArr.map((item, i) => {
              if (
                item.name == 'GSTR3B' ||
                item.name == 'Latest 6 months bank statement'
              ) {
                if (data_available == true) {
                  item.documentArray = []
                  item.gstMonthArray = gstMonthArray
                  for (let j = 0; j < gstMonthArray.length; j++) {
                    let docObj = {
                      file_name: '',
                      file_type: '',
                      modified_date: '',
                      base64String: '',
                      calendar_id: gstMonthArray[j].calendar_id,
                      calendar_name: gstMonthArray[j].parameter
                    }
                    const cal_ids = item.documentArray.map(i => i.calendar_id)
                    let found = cal_ids.some(
                      calendar_id =>
                        calendar_id === gstMonthArray[j].calendar_id
                    )
                    if (!found) {
                      item.documentArray.push(docObj)
                    }
                  }
                  console.log('original aray', tempFileArr)
                } else {
                  //item.documentArray = [];
                  item.gstMonthArray = gstMonthArray
                  for (let j = 0; j < gstMonthArray.length; j++) {
                    // let docObj = { file_name: '', file_type: '', modified_date: '', base64String: '', calendar_id: gstMonthArray[j].calendar_id, calendar_name: gstMonthArray[j].parameter };
                    // const cal_ids = item.documentArray.map(i => i.calendar_id);
                    // let found = cal_ids.some(calendar_id => calendar_id === gstMonthArray[j].calendar_id);
                    // if (!found) {
                    //   item.documentArray.push(docObj);
                    // }
                    item.documentArray[j].calendar_id =
                      gstMonthArray[j].calendar_id
                    item.documentArray[j].calendar_name =
                      gstMonthArray[j].parameter
                  }
                  console.log('original aray', tempFileArr)
                }
              }
            })
          }
        }
        
        console.log('array data map ----->', tempFileArr)
        setFilelist(tempFileArr)
      })
      .catch(error => {
        // ErrorCatch(checkuserid.user_id, error, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
      })
  }

  React.useEffect(() => {
    console.log('fileeeeee', fileList)
  }, [fileList])

  const dropzoneStyle = {
    width: '100%',
    height: '20%',
    border: '1px solid black'
  }
  const [loaderFlag, setLoaderFlag] = React.useState(true);

  // const sliceDocName = (docname)=>{
  //   
  //   let string ='';
  //   if(!docname){
  //     string =  docname.split('.');
  //     string = string[0].slice(20) + string[1];
  //   }
  //       return string;
  // }

  return (
    <Container maxWidth='lg'>
      {/* <div className="mg_btm_set"> */}
      {/* <div className="content-header"> */}

      {loaderFlag == true ? (
        <div style={{ textAlign: 'center', margin: '20%' }}>
          <Loader type={LoaderColor.type} color={LoaderColor.color} />
        </div>
      ) : (
        <>
          <Row>
            <Snackbar
              open={savenextmessage}
              style={{ marginTop: '65px' }}
              autoHideDuration={SnackPosition.duration}
              onClose={() => {
                setsavenextmessage(false)
              }}
              anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}
            >
              <Alert
                onClose={() => {
                  setsavenextmessage(false)
                }}
                severity={colorMessage}
              >
                Uploaded Successfully
              </Alert>
            </Snackbar>
            <Col md={{ span: 8 }} className='documentuploadHeader'>
              <Typography text='Document Upload' styleType='C' />
            </Col>

            {props.formdetails.formId == '1.1.7' ||
            props.formdetails.formId == '1.1.4' ? (
              <Col md={{ span: 4 }}>
                <Typography
                  text={
                    props.formdetails.formId == '1.1.7'
                      ? 'GTS Interval'
                      : 'Bank Interval'
                  }
                  styleType='B'
                />
                <List component='div' disablePadding>
                  <ListItem>
                    <Dropdown
                      placeholder='Select Interval'
                      name='area'
                      fluid
                      search
                      selection
                      value={gstPeriod}
                      //onBlur={() => setFieldTouched("area", true)}
                      onChange={async (e, { value, text }) => {
                        
                        console.log(value, text)
                        setGstPeriod(value)
                        // if (props.formdetails.formId == "1.1.7") {
                        //   await getManualDataByGstHeaderIdV2(gstHeaderId, value);

                        // } else if (props.formdetails.formId == "1.1.4") {
                        // await bankHeaderV2(bankHeaderId, value, undefined, false);
                        getUploadeDocOfUserV2(value)
                        //  }

                        //setFieldValue("area", value)
                      }}
                      options={interValArray}
                    />
                  </ListItem>
                </List>
              </Col>
            ) : null}

            {/* </Row> */}
            {/* </div> */}
            {/* <div> */}
            {/* <Row style={{ border: '1px solid', textAlign: 'center' }}> */}
            <span className='docUplHeader document_name'>
              <Col md={{ span: 4 }}>
                <Typography text='Document Category' />
              </Col>

              {props.formdetails.formId == '1.1.4' ||
              props.formdetails.formId == '1.1.7' ? (
                <Col md={{ span: 2 }}>
                  <Typography text='Months' />
                </Col>
              ) : (
                <Col md={{ span: 2 }}></Col>
              )}

              <Col md={{ span: 6 }} className='document_name1'>
                <Typography text='Document Uploaded' />
              </Col>
            </span>
          </Row>

          {fileList.map((item, k) => (
            <Row className='docUploadRow'>
              <Col
                md={{ span: 4 }}
                style={{ padding: '18px' }}
                className='document_name'
              >
                <Typography
                  className='document_text'
                  text={item.name}
                  styleType='AB'
                />
              </Col>

              {item.documentArray.map((item1, i) => (
                <>
                  {item.name != 'GSTR3B' ||
                  item.name != 'Latest 6 months bank statement' ? (
                    <Col md={{ span: 2 }} style={{ padding: '18px' }}></Col>
                  ) : null}
                  {i >= 1 && <Col md={{ span: 4 }}></Col>}
                  <Col md={{ span: 6 }} style={{ display: 'flex' }}>
                    <>
                      {item1.file_name == '' && (
                        <>
                          <div>
                            {item.name == 'GSTR3B' ||
                            item.name == 'Latest 6 months bank statement' ? (
                              <div
                                style={{
                                  marginLeft: '-260%',
                                  marginTop: '24px'
                                }}
                              >
                                <p>{item1.calendar_name}</p>
                              </div>
                            ) : (
                              <div>{<span>{''}</span>}</div>
                            )}
                          </div>

                          <DropzoneArea
                            key={i}
                            style={dropzoneStyle}
                            onChange={files => {
                              
                              if (
                                (props.formdetails.formId == '1.1.7' &&
                                  item.name == 'GSTR3B') ||
                                (props.formdetails.formId == '1.1.4' &&
                                  item.name == 'Latest 6 months bank statement')
                              ) {
                                fileSetInArray(
                                  files,
                                  k,
                                  i,
                                  item.id,
                                  item1.calendar_id,
                                  item1.calendar_name
                                )
                              } else {
                                fileSetInArray(files, k, i, item.id)
                              }
                            }}
                            Icon={AttachFile}
                            // style={{ margin: "8px 0px" }}
                            dropzoneText={dropFileText}
                            alertSnackbarProps={{
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                              }
                            }}
                            showPreviews={false}
                            filesLimit={1}
                            //acceptedFiles={["image/*,application/pdf,xls,application/xlsx,csv"]}
                            // acceptedfiles= {['image/*','application/pdf']}

                            showPreviewsInDropzone={false}
                            maxFileSize={maxFileSize}
                            acceptedFiles={allowedFileType}
                            // maxFileSize={2000000}
                            // acceptedFiles={[
                            //   'image/*',
                            //   'application/pdf',
                            //   // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            //   // 'text/csv'
                            // ]}
                          />
                        </>
                      )}
                      {item1.file_name != '' && (
                        <>
                          <div>
                            {item.name == 'GSTR3B' ||
                            item.name == 'Latest 6 months bank statement' ? (
                              <div style={{ marginLeft: '-220%' }}>
                                {<span>{item1.calendar_name}</span>}
                              </div>
                            ) : null}
                          </div>

                          <Col md={{ span: 10 }}>
                            <Row>
                              <Col md={{ span: 1 }}>
                                {item1.file_type == 'application/pdf' && (
                                  // <i class="far fa-file-pdf" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                  <img
                                    alt='img'
                                    src={pdfimage}
                                    className='uploadedImageView'
                                  />
                                )}
                                {(item1.file_type === 'image/jpeg' ||
                                  item1.file_type === 'image/png') && (
                                  // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                  <img
                                    alt='img'
                                    src={imageimg}
                                    className='uploadedImageView'
                                  />
                                )}
                                {item1.file_type ==
                                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                                  // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                  <img
                                    alt='img'
                                    src={excelimg}
                                    className='uploadedImageView'
                                  />
                                )}
                              </Col>
                              <Col md={{ span: 9 }}>
                              <p className='docName'>{(item1.file_name)}</p>
                                <p className='docUplDate'>
                                  {moment(item1.modifiedDate).format(
                                    'DD-MMMM-YYYY'
                                  )}
                                </p>
                              </Col>
                              <Col
                                md={{ span: 2 }}
                                className='fileUploadAction'
                              >
                                <div style={{ marginTop: '20px' }}>
                                  {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> &nbsp;
                              <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => removeDoc(k, i, item1.id)}></i> */}
                                  <Tooltip title='View' placement='bottom'>
                                    {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> */}
                                    <img
                                      alt='img'
                                      className='actionBtn svgicon'
                                      src={view}
                                      onClick={() =>
                                        handleshowhidepdf(
                                          item1.base64String,
                                          item1.file_type,
                                          item1
                                        )
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip title='Delete' placement='bottom'>
                                    <img
                                      alt='img'
                                      className='actionBtn svgicon'
                                      src={deleterec}
                                      onClick={() => removeDoc(k, i, item1.id)}
                                    />
                                  </Tooltip>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}
                      <Col md={{ span: 2 }}>
                        {i == item.documentArray.length - 1 && (
                          // <i class="fas fa-plus" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { //; addDoc(k); }} ></i>
                          <Tooltip title='Add' placement='bottom'>
                            <img
                              alt='image1'
                              className='actionBtn svgicon docAddBtn'
                              src={addbutton}
                              onClick={() => {
                                addDoc(k)
                              }}
                            />
                          </Tooltip>
                        )}
                        {item.documentArray.length > 1 &&
                          item1.file_name == '' && (
                            // <i class="fas fa-trash-alt" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { removeDoc(k, i) }} ></i>
                            <Tooltip title='Delete' placement='bottom'>
                              <img
                                alt=' img'
                                className='actionBtn svgicon docDelBtn'
                                src={deleterec}
                                onClick={() => {
                                  removeDoc(k, i)
                                }}
                              />
                            </Tooltip>
                          )}
                      </Col>
                    </>
                  </Col>
                </>
              ))}
            </Row>
          ))}

          {
            //   <Modal show={showmodal} animation={false} fullscreen={true} style={{ height: "95% !important" }}>
            //   <Modal.Header closeButton onClick={handleclosePdf} style={{ color: 'black', borderBottom: '1px solid #7254a3' }}>
            //     {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
            //   </Modal.Header>
            //   <Modal.Body>
            //     <Row>
            //       <Col md={{ span: 12 }} className="fileviewer_type">
            //         {//  <embed src={docBase64} type="application/pdf" width="100%" style={{ height: "460px" }}></embed>
            //         }
            //         { /*       <FileViewer
            //          // fileType={type}
            //           // filePath={file}
            //              fileType={"pdf"}
            //            filePath={"https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf"}
            //           //  errorComponent={CustomErrorComponent}
            //           className="banking_fileviewer"
            //           errorComponent={CustomErrorComponent}
            //          // onError={onError}
            //           style={{
            //             height: "90%",
            //           }}
            //         />
            //           <DocViewer documents={docs}  pluginRenderers={[PDFRenderer, PNGRenderer]}  />;
            //         */
            //         }
            //         <FileViewer
            //           fileType={previewType}
            //           filePath={previewFile}
            //           //  fileType={"pdf"}
            //           // filePath={apiCalling.coderscorner + "/public/images/document_2021DecTue053129.pdf"}
            //           //  errorComponent={CustomErrorComponent}
            //           className="banking_fileviewer"
            //           errorComponent={CustomErrorComponent}
            //           // onError={onError}
            //           style={{
            //             height: "90%",
            //           }}
            //         />
            //       </Col>
            //     </Row>
            //   </Modal.Body>
            // </Modal>
          }

          <Dialog
            disableBackdropClick
            open={showmodal}
            onClose={handleclosePdf}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={handleclosePdf}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Document Preview
              </Modal.Title>
            </Modal.Header>
            <MuiDialogTitle>
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={handleclosePdf}
              >
                {/* <CloseIcon style={{marginTop:"-10px",color:"white",fontWeight:"800",cursor:"pointer"}}/> */}
              </IconButton>
            </MuiDialogTitle>
            <div className='dialogBtn' style={{ height: '100%' }}>
              <DialogContent>
                <FileViewer
                  fileType={previewType}
                  filePath={previewFile}
                  //  errorComponent={CustomErrorComponent}
                  className=' '
                  errorComponent={CustomErrorComponent}
                  // onError={onError}
                  style={{
                    height: '90%'
                  }}
                />
              </DialogContent>
            </div>
          </Dialog>
        </>
      )}
      {/* </div > */}
      {/* </div > */}
    </Container>
  )
}
export default DocumentUpload
