

import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    listitem: {
        color: 'black',
        fontSize: '17px',
        fontWeight: '400',
    },
    Button: {
        showIcon: true,
    },

    resetbtn: {
        margin: '10px',
        padding: '9px',
        //font-size: 14px;
        //text-transform: capitalize;
        backgroundColor: 'transparent',
        color: 'blue',
        boxShadow: 'none',
        border: 'none',
        textAlign: 'right'
    },

    ggg: {
        backgroundColor: "#007bff",
        textTransform: "capitalize",
        margin: "10px",
        fontSize: "14px",
        padding: "9px",
        width: "61%",
    },

    linkdin: {
        margin: '10px',
        padding: '9px',
        //font-size: 14px;
        //text-transform: capitalize;
        backgroundColor: 'transparent',
        color: 'blue',
        boxShadow: 'none',
        border: 'none',
        textAlign: 'right',
        marginLeft: 306

    },
    input: {
        // display: 'none',
        marginBottom: 30,
        // marginLeft:10
    },
    button_div: {
        margin: '10px',
        padding: '9px',
        width: '61%',
    },
}));
