import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, TextArea } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { CityLinkFormSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import { apiCalling } from '../adminConstants';
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import {
  mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from '../../image/buttons/graydraftnext.png';
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

export default function CityLinKForms(props) {

  
  const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [format, setFormat] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const closeSuccessToast = () => {
        setsavenextmessage(false)
      }

  const classes = useStyles();
  const [table, settable] = React.useState(false);
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [showList, setShowList] = React.useState(true);
  const [loaderFlag, setLoaderFlag] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [CityList, setCityList] = React.useState([]);
  


  const data = {
    columns: [
      {
        label: "#",
        field: "slno",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "City",
        field: "city",
        width: 270,
      },
      {
        label: "GST",
        field: "gst",
        width: 200,
      },
      {
        label: "Sort Order",
        field: "sortorder",
        width: 100,
      },
      {
        label: "HRA Classification",
        field: "hraClassification",
        width: 200,
      },
      {
        label: "Tier",
        field: "tier",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      },
    ],

    rows: CityList.map((values, i) => {
      return {
        slno: i + 1,
        city: values.city,
        gst: values.gst,
        sortorder: values.sortorder,
        hraClassification: values.hraClassification,
        tier: values.tier,
        city_id: values.city_id,
        enable: values.enable,
        action: <div style={{ marginLeft: '-20px' }}>
          <Tooltip title="Edit Data" placement="bottom">
            {/* <Button className="actionBtn" onClick={() => handleEdit(values)} style={{ marginRight: "16px" }} >
              <i className="actionBtn" className="fas fa-edit" style={{ fontSize: "15px" }} />
            </Button> */}
            <img className="actionBtn svgicon" alt="" src={manualentry} onClick={() => handleEdit(values)} />
          </Tooltip>

          <Tooltip title="Delete Data" placement="bottom">
            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
              <i className="fas fa-minus" style={{ fontSize: "15px" }} />
            </Button> */}
            <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
          </Tooltip>
        </div>
      }
    })

  }


  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    handleCityList();
    hraClassificationGetAll();
  }, []);


  const [HraType, setHraType] = React.useState([]);

  const hraClassificationGetAll = () => {
    let getAssetTypes = {
      type: 'hra_classification',
    }
    axios.post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`, getAssetTypes)
      .then((res) => {
        let allData = []
        for (let j = 0; j < res.data.data.length; j++) {
          allData.push({ key: res.data.data[j].name, value: res.data.data[j].id, text: res.data.data[j].name })
        }
        console.log("-------", allData)
        setHraType(allData)
      })
      .catch((err) => console.log("error in fetching asset data", err))
  }

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {

    props.handleBack();
  };


  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true
    })
  };

  const SaveasDraft = () => {
    alert('Save As Draft')
    props.handlecheck();
  }

  let [initialValue, setInitialValue] = React.useState({
    city_id: "",
    hraClassification: "",
    city: "",
    gst: "",
    sortorder: "",
    tier: "",
    enable: "",
  })



  const handleCityList = async () => {
    
    const data = {
      enable: true
    };
    await axios
      .post(apiCalling.coderscorner +
        `server.php/api/getAllCity`,
        data
      ).then((result) => {
        let empArray = [];
        result.data.data.map((item) => {
          empArray.push({
            city_id: item.id,
            hraClassification: item.hra_classification,
            city: item.city,
            gst: item.gst_id,
            sortorder: item.sort_order,
            tier: item.tier,
            enable: item.enable
          })
        })
        setShowWCAddForm(false);
        setShowList(true);
        setLoaderFlag(false);
        console.log("EmpArray", empArray);
        setCityList(empArray);
      }).catch((error) => {
        console.log(error);
      });
  };

  const handlesubmitform = (values, { resetForm }) => {
    
    handleNextClick(values);
    resetForm({
      city_id: "",
      hraClassification: "",
      city: "",
      gst: "",
      sortorder: "",
      tier: "",
      enable: ""
    })

  };
  const handleNextClick = (data) => {
  
    setShowWCAddForm(false);
    setLoaderFlag(true);

    let wcData = {
      hra_classification: data.hraClassification,
      city: data.city,
      gst_id: data.gst,
      sort_order: true,
      tier: data.tier,
      enable: data.enable,
      city_id: data.city_id
    };

    axios.post(apiCalling.coderscorner + `server.php/api/cityAddOrEdit`, wcData).then((res) => {
     if(res.data.status){
       setFormat('success')
       setsavenextmessage(true)
       setSnackBarMsg(res.data.message)
      console.log("city details",res.data);
      console.log("saved successfully...!!!");
      setShowWCAddForm(false);
      setShowList(true);
      handleCityList(false);
      setLoaderFlag(false)
     }
     
     
    })
      .catch((er) => {
        setFormat('error')
        setsavenextmessage(true)
        setSnackBarMsg('something went wrong')
        console.log("no data sorry ", er);
      });
  };



  const handleOpenWCAddForm = () => {

    setAddFormButtonValue("Submit");
    setShowWCAddForm(true);
    setShowList(false);

    setInitialValue({
      city_id: "",
      hraClassification: "",
      city: "",
      gst: "",
      sortorder: true,
      tier: "",
      enable: ""
    })
  }

  const handleEdit = (data) => {
    setShowWCAddForm(true);
    setAddFormButtonValue("Update");
    setShowList(false);
    setInitialValue({
      ...data,
      city_id: data.city_id,
      hraClassification: data.hraClassification,
      city: data.city,
      gst: data.gst,
      sortorder: data.sortorder,
      tier: data.tier,
      enable: data.enable ? "true" : "false"
    })
  }

  const handleDelete = (item) => {
      
    let data = {
      city_id: item.city_id
    }
    axios.post(apiCalling.coderscorner + `server.php/api/deleteCityById`, data).then((res) => {
   
     if(res.data.status){
      setFormat("success");
      setSnackBarMsg(res.data.message);
      setsavenextmessage(true);
      setShowWCAddForm(false);
      setShowList(true);
      handleCityList();
     }
    
    })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation ctm_set_div">
      <div className='profile_table'>
        <div className="profile_table_part">
          <table style={{ marginBottom: 30, }}>
            <thead>
              <tr>
                <th><h3>Upload Documents</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Button className='ctm_btn_set footer'>Upload Here!</Button></td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th><h3>Add Appointment</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> <Button className='ctm_btn_set footer'>Book Appointment</Button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );





  return (
    <Container maxWidth="lg">
    
           <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>
          
       <div className="mini-container"  style={{ marginTop: 0, padding: '15px' }}>
        {loaderFlag == true && (
          <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}
        {showList == true && loaderFlag == false && (
          <div>
            <Row className="content-header">
              <Col md={{ span: 10 }}>
                <Typography
                  text="City Details" styleType="C" />
              </Col>
              <Col md={{ span: 2 }} >
                {/* <button className="btn btn-primary" onClick={() => handleOpenWCAddForm()} >
                  Add </button> */}
                <span className="image">
                  <img className='navbutton backbutton' alt="" src={blueadd} onClick={() => handleOpenWCAddForm()} />
                  <Tooltip title="Add" arrow>
                    <img className='navgraybutton backbutton' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                  </Tooltip>
                </span>
              </Col>
            </Row>
            <Row className="datable" style={{ marginTop: '30px', }} >
              <Col md={{ span: 12 }}>
                <div>
                  
              <div>
              <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="City"
                sheet="City"
                buttonText="Export excel"
              />
            </div>
                  <MDBDataTableV5
                   hover 
                  entriesOptions={[5, 10,20, 25]} 
                   entries={5} 
                   pagesAmount={4} 
                    data={data}
                     id = "karzaTbl"

                                        // searchTop 
                                        // searchBottom={false}
                  />
                </div>
              </Col>
            </Row>
            <div className='btn_row_fixd'>
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navbutton' src={Back} onClick={handledata} />
                  </Tooltip>
                </Col>

                <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save as Draft & Next" arrow>
                    <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                  </Tooltip>
                </Col> */}
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={Back} onClick={handledata} />
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img className='navgraybutton' src={grayback} onClick={handledata} />
                    </Tooltip>
                  </span>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                    <Tooltip title="Save as Draft & Next" arrow>
                      <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
          </div>

        )}
        <Row>
          <Col md={{ span: 12 }}>
            {showWCAddForm == true && (
              <Formik
                //initialValues={{ hraClassification: "", city: "", gst: "", sortorder: "", tier: "", enable: "" }}
                enableReinitialize
                initialValues={initialValue}
                validationSchema={CityLinkFormSchema}
                onSubmit={handlesubmitform}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  resetForm,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                  /* and other goodies */
                }) => {
                  //  console.log("values in formik", values);
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="content-header" style={{ position: 'unset' }}>
                        <Row>
                          <Col md={{ span: 10 }}>
                            <Typography
                              text="Cities"
                              styleType="C"
                            />
                          </Col>
                          <Col md={{ span: 2 }}>
                            <div className="content-header-button ">
                              {/* <Button className="backBtn" variant="outlined" color="primary"
                                onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }} >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                            </Button> */}
                              <span className="image">
                                <img className='backbutton navbutton' src={Back} onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }} />
                                <Tooltip title="Back" arrow>
                                  <img className='backbutton navgraybutton' src={grayback} onClick={() => {
                                    resetForm();
                                    setShowWCAddForm(false);
                                    setShowList(true);
                                  }} />
                                </Tooltip>
                              </span>
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '30px' }}>
                          <Col md={{ span: 6 }}>
                            <Typography text="City" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="city"
                                  label="Enter City"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.city &&
                                      touched.city &&
                                      errors.city
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.city ? classes.manorma : null,
                                  }}
                                  value={values.city}
                                  helperText={
                                    errors.city &&
                                    touched.city &&
                                    errors.city
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text="State" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="gst"
                                  label="Enter State"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.gst &&
                                      touched.gst &&
                                      errors.gst
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.gst ? classes.manorma : null,
                                  }}
                                  value={values.gst}
                                  helperText={
                                    errors.gst &&
                                    touched.gst &&
                                    errors.gst
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          {/*   <Col md={{ span: 6 }}>
                                                        <Typography text="Sort order" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Select Sort order"
                                                                    id="sortorder"
                                                                    name="sortorder"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.sortorder}
                                                                    onBlur={() => setFieldTouched("sortorder", true)}
                                                                    //onChange={(e, { value }) => {
                                                                      //  setFieldValue("sortorder", value)
                                                                       // e.persist()
                                                                       // setSortOrder(e.target.textContent)
                                                                    //}}
                                                                    options={entityoption}
                                                                    className={errors.sortorder && touched.sortorder ? classes.required : values.sortorder == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.sortorder && touched.sortorder && values.sortorder == '' && (

                                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.sortorder}</div>

                                                        )}
                                                    </Col> */}

                          <Col md={{ span: 6 }}>
                            <Typography text="Select HRA Classification" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="HRA Classification"
                                  id="hraClassification"
                                  name="hraClassification"
                                  fluid
                                  search
                                  selection
                                  value={values.hraClassification}
                                  onBlur={() => setFieldTouched("hraClassification", true)}
                                  onChange={(e, { value }) => {
                                    setFieldValue("hraClassification", value)
                                    //setHraClassification(e.target.textContent)
                                  }}
                                  options={HraType}
                                  Hra className={errors.hraClassification && touched.hraClassification ? classes.required : values.hraClassification == '' ? classes.reqired : classes.man}
                                />
                              </ListItem>
                            </List>
                            {errors.hraClassification && touched.hraClassification && values.hraClassification == '' && (
                              <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.hraClassification}</div>
                            )}
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text="Tier" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="tier"
                                  label="Enter Tier"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.tier &&
                                      touched.tier &&
                                      errors.tier
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.tier ? classes.manorma : null,
                                  }}
                                  value={values.tier}
                                  helperText={
                                    errors.tier &&
                                    touched.tier &&
                                    errors.tier
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <div style={{ marginTop: '20px' }}>
                              <Typography text="Enable" styleType="B" />
                            </div>
                            <List component="div" disablePadding>
                              <ListItem >
                                <label>
                                  Yes
                                                          <input
                                    name="enable"
                                    type="radio"
                                    value="1"
                                    checked={values.enable == "1"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </label>
                                <div style={{ width: '18px' }}></div>
                                <label>
                                  No
                                                           <input
                                    name="enable"
                                    type="radio"
                                    value="0"
                                    checked={values.enable == "0"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </label>
                              </ListItem>
                              {errors.enable && touched.enable && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.enable}</div>}
                            </List>
                          </Col>
                        </Row>
                        <Col md={{ span: 12 }}>
                          <Row><Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}