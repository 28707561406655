import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SaveIcon from "@material-ui/icons/Save";
import { TextField, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Dropdown } from "semantic-ui-react";
import Typography from "../../base_components/Typography";
import { apiCalling } from "../adminConstants";
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { validationSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from "@material-ui/core/Paper";
import { mdiPlaylistPlus, mdiRefresh } from "@mdi/js";
import DraftNext from "../../image/buttons/draftnext.png";
import Tooltip from "@material-ui/core/Tooltip";
import Back from "../../image/buttons/back.png";
import { LoaderColor } from "../../shared/constants";
import Loader from "react-loader-spinner";
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
//import Modal from "@material-ui/core/Modal";
import Modal from "react-bootstrap/Modal";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { Grid } from "@material-ui/core";
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import grayback from "../../image/buttons/grayback.png";
import manualentry from "../../image/pngicons/manualaddition.png";
import deleterec from "../../image/pngicons/delete.png";
import { TrendingUpRounded } from "@material-ui/icons";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function ThirdPartyReportData(props) {
  const classes = useStyles();

  const [table, settable] = React.useState(false);
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [showList, setShowList] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  // const [initialValue, setInitialValue] = React.useState(DocumentCheckList);
  const [page, setPage] = React.useState(1);
  const [minimumdate, setminimumdate] = React.useState(); //3 years ago
  const [maximumdate, setmaximumdate] = React.useState(); //by default today date

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

  
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
  };

  const table_header = {
    fontWeight: "600",
    fontSize: "100%",
    width: "0%",
  };

  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [apiService, setApiService] = React.useState("karza");
  //setApiService("karza");

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    
   // getServices();
    //handleGetApiReports(apiService);
  }, []);

  React.useEffect(() => {
       
    getServices();
    //setApiService("karza");
    //handleGetApiReports(apiService);
  }, []);

  const [reportList, setReportList] = React.useState([]);

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {
    props.handleBack();
  };

  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true,
    });
  };

  const SaveasDraft = () => {
    alert("Save As Draft");
    props.handlecheck();
  };

  const [dateError, setDateError] = React.useState("");

  const handleDate = (e) => {
    //
    var date = new Date(e.target.value);
    if (date < new Date("01-01-1900") || date > new Date(minimumdate)) {
      console.log("Date not in range");
      setDateError(
        "Date should be in between " + minimumdate + " and " + maximumdate
      );
      //  setEnableRegistration(true)
    } else {
      console.log("Date range");
      setDateError("");
    }
  };

  let [initialValue, setInitialValue] = React.useState({
    startdate: "",
    enddate: "",
    thirdpartyservices: "",
    id: "",
  });

  //not using
  const [StateList, setStateList] = React.useState([]);
  const handleStateList = async () => {
    const data = {
      enable: true,
    };
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getEntityTypes`, data)
      .then((result) => {
        let empArray = [];
        result.data.data.map((item) => {
          empArray.push({
            entity: item.entity,
            type: item.type,
            enable: item.enable.toString(),
            id: item.id,
          });
        });
        setShowWCAddForm(false);
        setShowList(true);
        //setLoaderFlag(false);
        console.log("EmpArray", empArray);
        setStateList(empArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const data = {
    
  };

  let buspage = [
    { key: 1, value: 1, text: "Karza" },
    { key: 2, value: 2, text: "Crif" },
    { key: 3, value: 3, text: "Equifax" },
    { key: 4, value: 4, text: "Perfios" },
  ];

  const handleSubmit=() => {
    //console.log("apiService, startDate, endDate" + apiService + " " + startDate + " " + endDate)
      handleGetApiReports(apiService, startDate, endDate)
  }

  const handlesubmitform = (values, { resetForm }) => {
    
    // handleNextClick(values);
    resetForm({
      startdate: "",
      enddate: "",
      thirdpartyservices: "",
      id: "",
    });
  };

  const reportData = async (datas) => {
    let sdate = moment(datas.startdate).format("YYYY-MM-DD");
    let edate = moment(datas.enddate).format("YYYY-MM-DD");
    const data = {
      startdate: sdate,
      enddate: edate,
    };
    console.log("data", data);
    await axios
      .post(apiCalling.coderscorner + "server.php/api/getReportByDate", data)
      .then((result) => {
        console.log(result.data.data);
        setReportList(result.data.data);
      });
  };

  
  const [services, setServices] = React.useState([]);

  const getServices = async () => {
    
    console.log("hi");
    let url = apiCalling.thirdParty + "/server.php/getServices";
    const data = {
      "is_active":1
    }

    let tempArr = [];

    await axios
      .post(url, data)
      .then((result) => {
        console.log(result);
        result.data.data.map((item) => {
          tempArr.push({
            id: item.id,
            key: item.key,
            value: item.service,
            text: item.service,
          });
        });
        setServices(tempArr);
       // setApiService(tempArr[0].service);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //const[moment1,setMoment1]=React.useState()

  const [reportArray, setReportArray] = React.useState([]);
  const handleGetApiReports = async (data1, startDate, endDate) => {
    
    const data = {
      service: data1,
      startdate: startDate,
      enddate: endDate,
    };

    await axios
      .post(apiCalling.thirdParty + "server.php/apiReports", data)
      .then((result) => {
          if(result.data.status===true) {
            console.log(result.data.data);
            // console.log(m.format('mm-dd-yyyy'));
            setReportArray(result.data.data);
            //setMoment1(m.format('mm-dd-yyyy'));
          } else {
            // alert("No data found");
             setOpen(true);
          }
        
      }).catch(error=>console.log(error));
  };
  // const handleDelete = (values) => {
  //     
  //     console.log(values)

  //     let data = {
  //         "id":values.id
  //     }
  //     axios.post(apiCalling.coderscorner + 'server.php/api/deleteConfiguration', data).then((res) => {
  //         setShowWCAddForm(false);
  //         setShowList(true);
  //         handleAllRecords();
  //     })
  //         .catch((er) => {
  //             console.log("no data sorry ", er);
  //         });
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);  
  const handleClose = () => setOpen(false);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
console.log("report array -> " + reportArray);
  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: 0, padding: "15px" }}>
      
      <Modal show={open} animation={false} centered className="modelPosition modelwdth" style={{ height: "auto" }}>
                <Modal.Header className="modalHead" closeButton onClick={handleClose} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                    <Modal.Title className="modalHeader cpModal">Third Party Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					 <Typography text="No Data Available" styleType="B" />
				</Modal.Body>
            </Modal>
      {/*<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography text="No Data Available" styleType="B" />
              
      */}
            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
         {/*} </Box>
        </Fade>
          </Modal>*/}
        <Row>
          <Col md={{ span: 10 }}>
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validationSchema={validationSchema}
              
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                /* and other goodies */
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div
                      className="content-header"
                      style={{ position: "unset" }}
                    >
                      <Row>
                        <Col md={{ span: 10 }} className="mainheader1">
                          <Typography
                            text="Third Party Reports"
                            styleType="C"
                          />
                        </Col>
                        <Col md={{ span: 2 }} className="mainHeaderBack">
                          {/* <div className="content-header-button ">
                                                            <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                                                        </div> */}
                          <span className="image">
                            <img
                              className="backbutton navbutton"
                              src={Back}
                              onClick={() => {
                                resetForm();
                                setShowWCAddForm(false);
                                setShowList(true);
                              }}
                            />
                            <Tooltip title="Back" arrow>
                              <img
                                className="backbutton navgraybutton"
                                src={grayback}
                                onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }}
                              />
                            </Tooltip>
                          </span>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "30px" }}>
                        <Col xs={4} md={3}>
                          <Typography text="Start Date" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                //id="filled-error-helper-text standard-basic"
                                id="startdate"
                                name="startdate"
                                type="date"
                                className={classes.textField}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  setStartDate(e.target.value);
                                //   handleGetApiReports(
                                //     apiService,
                                //     e.target.value,
                                //     endDate
                                //   );
                                }}
                                style={{ marginTop: "9px" }}
                                InputProps={{
                                  inputProps: {
                                    min: "1900-01-01",
                                    max: minimumdate,
                                  },
                                  className: values.startdate
                                    ? classes.manorma
                                    : null,
                                }}
                                error={
                                   errors.startdate &&
                                   touched.startdate &&
                                   errors.startdate
                                     ? true
                                     : false
                                 }
                                // InputProps={{
                                //   className: values.startdate
                                //     ? classes.manorma
                                //     : null,
                                //}}
                                value={values.startdate}
                                helperText={
                                errors.startdate &&
                                touched.startdate &&
                                errors.startdate
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>

                        <Col xs={4} md={3}>
                          <Typography text="End Date" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                //id="filled-error-helper-text standard-basic"
                                id="enddate"
                                name="enddate"
                                type="date"
                                className={classes.textField}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  console.log(e.target.value);
                                  setEndDate(e.target.value);
                                //  // handleGetApiReports(
                                //     apiService,
                                //     startDate,
                                //     e.target.value
                                //   );
                                }}
                                style={{ marginTop: "9px" }}
                                InputProps={{
                                  inputProps: {
                                    min: "1900-01-01",
                                    max: minimumdate,
                                  },
                                  className: values.enddate
                                    ? classes.manorma
                                    : null,
                                }}
                                error={
                                  errors.enddate &&
                                   touched.enddate &&
                                   errors.enddate
                                     ? true
                                     : false
                                 }
                                // InputProps={{
                                //   className: values.enddate
                                //     ? classes.manorma
                                //     : null,
                                // }}
                                value={values.enddate}
                                 helperText={
                                   errors.enddate &&
                                   touched.enddate &&
                                   errors.enddate
                                 }
                              />
                            </ListItem>
                          </List>
                        </Col>

                        <Row className="datable" style={{ marginTop: "9px" }}>
                          <Col md={{ span: 12 }}>
                            {
                              // <div component={Paper}>
                              //     <MDBDataTableV5
                              //         hover
                              //         entriesOptions={[5, 10,20, 25]}
                              //         entries={5}
                              //         pagesAmount={4}
                              //         data={data}
                              //         // searchTop
                              //         // searchBottom={false}
                              //     />
                              // </div>
                            }
                          </Col>
                        </Row>

                        <Col xs={4} md={3}>
                          <Grid>
                            <Typography
                              text="Third Party Services"
                              styleType="B"
                            />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Type"
                                  id="type"
                                  name="type"
                                  fluid
                                  search
                                  selection
                                  value={apiService}
                                  onBlur={(e, data) => {
                                      console.log(data);
                                     // setApiService(e.target.value)
                                    // 
                                    // console.log("------------>", e.target);
                                    // setPage(e.target);
                                  }}
                                  onChange={(e, { value }) => {
                                    //setvalue(value);
                                    //loanTypeSelection(value) ;
                                    //
                                    console.log("e", e);
                                    console.log("------------>",value);
                                    setApiService(value);
                                    setReportArray([]);
                                   // setApiService(value);
                                   // handleGetApiReports(value);
                                  }}
                                  options={services}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Col>
                      

                      
                          
                          <Col md={{ span: 2 }}>
                            <Button
                              className="updatebtn"
                              variant="contained"
                              color="primary"
                              type="submit"
                              //onClick={handleSubmit}
                              onClick={(e) =>{ console.log("Form submitted " + apiService);}}
                            >
                              {addFormButtonValue}
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        
                      </Row>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
      <div style={{marginTop: "10vh"}}>
      {apiService === "karza" && reportArray.length > 0 ? (
        <>
          <TableContainer className="reporttablecontainer">
            <div>
              <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="KarzaReportExcel"
                sheet="KarzaReportExcel"
                buttonText="Export excel"
              />
            </div>
            <Table id="karzaTbl" aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" style={table_slNo}>#</TableCell>
                  <TableCell style={table_header}>Application Name</TableCell>
                  <TableCell style={table_header}>In Data</TableCell>
                  <TableCell style={table_header}>Request Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportArray.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{item.app_name}</TableCell>
                      <TableCell>{item.in_data}</TableCell>
                      <TableCell>{item.request_id}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={reportList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : null}

      {apiService === "apicrifs" && reportArray.length > 0 ? (
        <>
          <TableContainer className="reporttablecontainer">
            <div>
              <ReactHTMLTableToExcel
                id="apicrifsTblBtn"
                className="btn btn-info exportBtn"
                table="apicrifsTbl"
                filename="ApicrifsReportExcel"
                sheet="ApicrifsReportExcel"
                buttonText="Export excel"
              />
            </div>
            <Table id="apicrifsTbl" aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" style={table_slNo}>#</TableCell>
                  <TableCell style={table_header}>Customer Id</TableCell>
                  <TableCell style={table_header}>First Name</TableCell>
                  <TableCell style={table_header}>Last Name</TableCell>
                  <TableCell style={table_header}>Mobile No.</TableCell>
                  <TableCell style={table_header}>Email</TableCell>
                  <TableCell style={table_header}>PAN</TableCell>
                  <TableCell style={table_header}>DL</TableCell>
                  <TableCell style={table_header}>Address</TableCell>
                  <TableCell style={table_header}>City</TableCell>
                  <TableCell style={table_header}>State</TableCell>
                  <TableCell style={table_header}>Pin</TableCell>
                  <TableCell style={table_header}>Report Id</TableCell>
                  <TableCell style={table_header}>Redirect URL</TableCell>
                  <TableCell style={table_header}>Order Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportArray.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{item.customerId}</TableCell>
                      <TableCell>{item.firstName}</TableCell>
                      <TableCell>{item.lastName}</TableCell>
                      <TableCell>{item.mob1}</TableCell>
                      <TableCell>{item.email1}</TableCell>
                      <TableCell>{item.pan}</TableCell>
                      <TableCell>{item.dl}</TableCell>
                      <TableCell>{item.address1}</TableCell>
                      <TableCell>{item.city1}</TableCell>
                      <TableCell>{item.state1}</TableCell>
                      <TableCell>{item.pin1}</TableCell>
                      <TableCell>{item.reportid}</TableCell>
                      <TableCell>{item.redirecturl}</TableCell>
                      <TableCell>{item.orderId}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={reportList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : null}

      {apiService === "perfios" && reportArray.length > 0 ? (
        <>
          <TableContainer className="reporttablecontainer">
           
              <ReactHTMLTableToExcel
                id="perfiosTblBtn"
                className="btn btn-info exportBtn"
                table="perfiosTbl"
                filename="PerfiosReportExcel"
                sheet="PerfiosReportExcel"
                buttonText="Export excel"
              />
            
            <Table id="perfiosTbl" aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" style={table_slNo}>#</TableCell>
                  <TableCell style={table_header}>Email</TableCell>
                  <TableCell style={table_header}>LAmount</TableCell>
                  <TableCell style={table_header}>LDuration</TableCell>
                  <TableCell style={table_header}>LType</TableCell>
                  <TableCell style={table_header}>Rcall Back</TableCell>
                  <TableCell style={table_header}>R URL</TableCell>
                  <TableCell style={table_header}>Taxation Id</TableCell>
                  <TableCell style={table_header}>Result</TableCell>
                  <TableCell style={table_header}>App Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportArray.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.lAmount}</TableCell>
                      <TableCell>{item.lDuration}</TableCell>
                      <TableCell>{item.lType}</TableCell>
                      <TableCell>{item.rCallback}</TableCell>
                      <TableCell>{item.rUrl}</TableCell>
                      <TableCell>{item.txnId}</TableCell>
                      <TableCell>{item.result}</TableCell>
                      <TableCell>{item.app_name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={reportList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : null}

      {apiService === "equifaxs" && reportArray.length > 0 ? (
        <>
          <TableContainer className="reporttablecontainer">
            <div>
              <ReactHTMLTableToExcel
                id="equifaxsTblBtn"
                className="btn btn-info exportBtn"
                table="equifaxsTbl"
                filename="EquifaxsReportExcel"
                sheet="EquifaxsReportExcel"
                buttonText="Export excel"
              />
            </div>
            <Table id="equifaxsTbl" aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" style={table_slNo}>#</TableCell>
                  <TableCell style={table_header}>Application Name</TableCell>
                  <TableCell style={table_header}>Customer Ref Field</TableCell>
                  <TableCell style={table_header}>First Name</TableCell>
                  <TableCell style={table_header}>Last Name</TableCell>
                  <TableCell style={table_header}>Address</TableCell>
                  <TableCell style={table_header}>Pin</TableCell>
                  <TableCell style={table_header}>Inq Purpose</TableCell>
                  <TableCell style={table_header}>Mobile Number</TableCell>
                  <TableCell style={table_header}>Email</TableCell>
                  <TableCell style={table_header}>Id value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportArray.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{item.app_name}</TableCell>
                      <TableCell>{item.CustRefField}</TableCell>
                      <TableCell>{item.firstName}</TableCell>
                      <TableCell>{item.lastName}</TableCell>
                      <TableCell>{item.address1}</TableCell>
                      <TableCell>{item.pin}</TableCell>
                      <TableCell>{item.inqPurpose}</TableCell>
                      <TableCell>{item.mNumber1}</TableCell>
                      <TableCell>{item.emailid1}</TableCell>
                      <TableCell>{item.Idvalue1}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={reportList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : null}
      </div>
    </Container>
  );
}
