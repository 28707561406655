import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from 'react-bootstrap/Modal';
import Input from '@material-ui/core/Input';
import { Dropdown } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Formik } from "formik";
import { entityoption, gender } from "../../shared/constants";
import { onboardingval } from "../../shared/validations";
import { useStyles } from "./styles";
import axios from 'axios';
import Icon from "@mdi/react";
import {
  mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert, mdiArrowRightBox, mdiArrowLeftBox, mdiPlusBox, mdiCloudUpload
} from "@mdi/js";
import { set } from 'date-fns';
import {ErrorCatch,apiCalling} from "../../shared/constants";

export default function Onboarding(props) {
  const classes = useStyles();
  const [panvalue, setpanvalue] = React.useState();
  const [email, setemail] = React.useState('');
  const [emailotp, setemailotp] = React.useState();
  const [mobile, setmobile] = React.useState();
  const [data, setdata] = React.useState([]);
  const [mobotp, setmobotp] = React.useState();
  const [education, seteducation] = React.useState([]);
  const [statename, setstatename] = React.useState([]);
  const [address, setaddress] = React.useState([]);
  const [show, setshow] = React.useState(false);
  const [open, setopen] = React.useState(false);
  const [display, setdisplay] = React.useState(true);
  const [collapsedata, setcollapsedata] = React.useState(false);
  const [area, setArea] = React.useState([]);
  const [visible, setvisible] = React.useState(false);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const [assettype, setState] = React.useState('');
  const [nationality, setnational] = React.useState([]);

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    nationalcheck();
    educationcheck();
    addresscheck();
  }, []);

  var nationalarray = []

  const nationalcheck = () => {

    const nationaldata = {
      token: "qwerty1234"

    }

    axios.post(apiCalling.coderscorner +`server.php/api/getAllnationality`, nationaldata)
      .then(res => {
        // setdata(loanapplydata)
        // setgender(res.data.data)
				/* var genderobject = {
				  key: "",
				  value: "",
				  flag: "",
				  text: ""
				} */
        res.data.data.map((item) => {
          console.log('item', item)

          nationalarray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setnational(nationalarray)
        console.log("first nationalarray", nationalarray);

      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }

  const handleSubmited = () => {
    setshow(true)
  };



  const handleNextClick = () => {
    const onboarding = localStorage.getItem('onboarding'),
      boarding = JSON.parse(onboarding)
    console.log('datarjjjjjjjjjjje', boarding)
    setdata(boarding)
    setvisible(true)
    setcollapsedata(true)
    setdisplay(false)

  }

  var educationarray = []

  const educationcheck = () => {

    const educationdata = {
      token: "qwerty1234",
      type: "education_qualifications"
    }


    axios.post(apiCalling.identityManagement +`server.php/api/getMastertableDetails`, educationdata)
      .then(res => {
        // setdata(loanapplydata)
        // setgender(res.data.data)
				/* var genderobject = {
				  key: "",
				  value: "",
				  flag: "",
				  text: ""
				} */
        res.data.data.map((item) => {
          console.log('item', item)

          educationarray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        seteducation(educationarray)
        console.log("educationarray", educationarray);

      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }

  var addressarray = []

  const addresscheck = () => {

    const addressdata = {
      token: "qwerty1234",
      type: "address_proof"
    }


    axios.post(apiCalling.identityManagement +`server.php/api/getMastertableDetails`, addressdata)
      .then(res => {
        // setdata(loanapplydata)
        // setgender(res.data.data)
				/* var genderobject = {
				  key: "",
				  value: "",
				  flag: "",
				  text: ""
				} */
        res.data.data.map((item) => {
          console.log('item', item)

          addressarray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setaddress(addressarray)
        console.log("addressarray", addressarray);

      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }


  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {

    props.handleBack();

    /*  const data = {
       panvalue:panvalue,
       email:email,
       otp:otp,           
 
     } */
    /*   localStorage.setItem('formdata', JSON.stringify(data))
      props.handleNext(); */
  };


  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true
    })
  };

  const handleClick1 = () => {
    setopen(!open)
  }

  const handleDSAOnBoarding = (data) => {
    let DSAOnBoardingData = {
      first_name: data.fname,
      last_name: data.lname,
      dob: "01-12-1993",
      gender_id: "1",
      pan: data.pan,
      nationality_id: data.onationality,
      father_name: "Father",
      edu_qual_id: data.edu,
      dsa_profile: "profile",
      primary_id: "1",
      email: data.email,
      mobile: data.mobile,
      pincode: data.pincode,
      address1: data.Address1,
      address2: data.Address2,
      add_proof_id: data.addproof,
      area: data.area,
      city_id: "1",
      state_id: "1",
      parent_type_id: "1",

    };

    console.log("result", DSAOnBoardingData);

    // axios
    //   .post(
    //     `http://164.52.207.42/delta/server.php/api/dsaOnboardingAddOrEdit`,
    //     DSAOnBoardingData
    //   )
    //   .then((res) => {
    //     // setdata(DSAOnBoardingData);
    //     console.log("yeh we have", res.data);
    //   })
    //   .catch((er) => {
    //     console.log("no data sorry ", er);
    //   });
  };

  const getPincodeData = (data) => {

    let getPincode = {
      pincode: data,
      token: "qwerty1234"
    }

    axios
      .post(apiCalling.coderscorner +
        `server.php/api/getDetailsbyPincode`,
        getPincode
      )
      .then((res) => {
        console.log("yeh we have pincode", res.data);
        return handleAllAreas(res.data.data)
      })
      .catch((er) => {
        console.log("no pincode sorry ", er);
      });
  }

  const handleAllAreas = (data) => {
    let allArea = []
    for (let i = 0; i < data.length; i++) {
      allArea.push({ key: data[i].area, value: data[i].area, text: data[i].area })
    }
    return setArea(allArea)
  }



  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: -30, padding: '15px' }}>
        <Row>
          <Col md={{ span: 12 }}>
            {/* <Typography variant="h4" gutterBottom>Business Information</Typography> */}
            <Formik
              initialValues={{
                lname: "", fname: "", pan: "", pincode: "", area: "", email: "", mobile: "", onationality: "", Address1: "",
                Address2: "", city: "", state: "", gender: "", edu: "", addproof: "", lang: "",
              }}
              validationSchema={onboardingval}
              onSubmit={(values) => {
                console.log('kkkkk', values)
                handleDSAOnBoarding(values)
                localStorage.setItem('onboarding', JSON.stringify(values))
                handleNextClick()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
                /* and other goodies */
              }) => {
                //  console.log("values in formik", values);
                return (
                  <form onSubmit={handleSubmit}>

                    <div className="content-header" style={{ position: 'unset' }}>
                      <Row>
                        <Col md={{ span: 6 }}>

                          <Typography
                            text=" On Boarding DSA"
                            styleType="C"
                          />

                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className="content-header-button ">


                            <Button
                              className={classes.linkdin}
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              className={classes.button_others}>
                              <Icon path={mdiPlaylistPlus} title="save" size={1} />
                            </Button>


                            <Button
                              className={classes.button_div}
                              variant="outlined"
                              color="primary"
                              className={classes.button_others}

                            ><Icon path={mdiRefresh} title="reset" size={1} /></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {display == true && (
                      <Row style={{ marginTop: '50px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text="First Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="fname"
                                label="Enter First Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.fname &&
                                    touched.fname &&
                                    errors.fname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.fname ? classes.manorma : null,
                                }}
                                value={values.fname}
                                helperText={
                                  errors.fname &&
                                  touched.fname &&
                                  errors.fname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Last Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="lname"
                                label="Enter Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.lname &&
                                    touched.lname &&
                                    errors.lname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.lname ? classes.manorma : null,
                                }}
                                value={values.lname}
                                helperText={
                                  errors.lname &&
                                  touched.lname &&
                                  errors.lname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="PAN" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="pan"
                                label="AAAAA9999A"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.pan ? classes.manorma : null,
                                }}

                                inputProps={{
                                  maxLength: 10,
                                }}
                                error={
                                  errors.pan &&
                                    touched.pan &&
                                    errors.pan
                                    ? true
                                    : false
                                }
                                value={values.pan}
                                helperText={
                                  errors.pan &&
                                  touched.pan &&
                                  errors.pan
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography
                            text="Nationality"
                            styleType="B"
                          />

                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Nationality"
                                id="onationality"
                                name="onationality"
                                fluid
                                search
                                selection
                                value={values.onationality}
                                onBlur={() => setFieldTouched("onationality", true)}
                                onChange={(e, { value }) => setFieldValue("onationality", value)}
                                options={nationality}
                                className={errors.onationality && touched.onationality ? classes.required : values.onationality == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.onationality && touched.onationality && values.onationality == '' && (

                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.onationality}</div>

                          )}
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Mobile" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Mobile No"
                                name="mobile"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.mobile ? classes.manorma : null,
                                }}

                                inputProps={{
                                  maxLength: 10,
                                }}
                                error={
                                  errors.mobile &&
                                    touched.mobile &&
                                    errors.mobile
                                    ? true
                                    : false
                                }
                                value={values.mobile}
                                helperText={
                                  errors.mobile &&
                                  touched.mobile &&
                                  errors.mobile
                                } />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Email" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.email ? classes.manorma : null,
                                }}
                                error={
                                  errors.email &&
                                    touched.email &&
                                    errors.email
                                    ? true
                                    : false
                                }
                                value={values.email}
                                helperText={
                                  errors.email &&
                                  touched.email &&
                                  errors.email
                                }

                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography
                            text="Address Line 1"
                            styleType="B"
                          />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Address Line 1"
                                name="Address1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.Address1 ? classes.manorma : null,
                                }}
                                error={
                                  errors.Address1 &&
                                    touched.Address1 &&
                                    errors.Address1
                                    ? true
                                    : false
                                }
                                value={values.Address1}
                                helperText={
                                  errors.Address1 &&
                                  touched.Address1 &&
                                  errors.Address1
                                }

                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography
                            text="Address Line 2"
                            styleType="B"
                          /> <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Address Line 2"
                                name="Address2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.Address2 ? classes.manorma : null,
                                }}
                                error={
                                  errors.Address2 &&
                                    touched.Address2 &&
                                    errors.Address2
                                    ? true
                                    : false
                                }
                                value={values.Address2}
                                helperText={
                                  errors.Address2 &&
                                  touched.Address2 &&
                                  errors.Address2
                                } />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Pincode" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="pincode"
                                label="999999"
                                onChange={handleChange}
                                //onBlur={handleBlur}
                                onBlur={e => {
                                  handleBlur(e)
                                  if (!errors.pincode) {
                                    getPincodeData(values.pincode)
                                  }
                                }}
                                error={
                                  errors.pincode &&
                                    touched.pincode &&
                                    errors.pincode
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.pincode ? classes.manorma : null,
                                }}

                                inputProps={{
                                  maxLength: 6,
                                }}

                                value={values.pincode}
                                helperText={
                                  errors.pincode &&
                                  touched.pincode &&
                                  errors.pincode
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Area" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Area"
                                id="area"
                                name="area"
                                fluid
                                search
                                selection
                                value={values.area}
                                onBlur={() => setFieldTouched("area", true)}
                                onChange={(e, { value }) => setFieldValue("area", value)}
                                options={area}
                                className={errors.area && touched.area ? classes.required : values.area == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="City" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="city"
                                label="Enter City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.city &&
                                    touched.city &&
                                    errors.city
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.city ? classes.manorma : null,
                                }}
                                value={values.city}
                                helperText={
                                  errors.city &&
                                  touched.city &&
                                  errors.city
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="State" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="state"
                                label="Enter State"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.state &&
                                    touched.state &&
                                    errors.state
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.state ? classes.manorma : null,
                                }}
                                value={values.state}
                                helperText={
                                  errors.state &&
                                  touched.state &&
                                  errors.state
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Gender" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Gender"
                                id="gender"
                                name="gender"
                                fluid
                                search
                                selection
                                value={values.gender}
                                onBlur={() => setFieldTouched("gender", true)}
                                onChange={(e, { value }) => setFieldValue("gender", value)}
                                options={gender}
                                className={errors.gender && touched.gender ? classes.required : values.gender == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.gender && touched.gender && values.gender == '' && (

                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.gender}</div>
                          )}
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Educational Qualification" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Educational Qualification"
                                id="edu"
                                name="edu"
                                fluid
                                search
                                selection
                                value={values.edu}
                                onBlur={() => setFieldTouched("edu", true)}
                                onChange={(e, { value }) => setFieldValue("edu", value)}
                                options={education}
                                className={errors.edu && touched.edu ? classes.required : values.edu == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.edu && touched.edu && values.edu == '' && (

                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.edu}</div>
                          )}
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Address Proof" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Address Proof"
                                id="addproof"
                                name="addproof"
                                fluid
                                search
                                selection
                                value={values.addproof}
                                onBlur={() => setFieldTouched("addproof", true)}
                                onChange={(e, { value }) => setFieldValue("addproof", value)}
                                options={address}
                                className={errors.addproof && touched.addproof ? classes.required : values.addproof == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.addproof && touched.addproof && values.addproof == '' && (

                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.addproof}</div>
                          )}
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Languages Known" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="lang"
                                label="Enter Languages Known"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.lang &&
                                    touched.lang &&
                                    errors.lang
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.lang ? classes.manorma : null,
                                }}
                                value={values.lang}
                                helperText={
                                  errors.lang &&
                                  touched.lang &&
                                  errors.lang
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Photograph" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <input id="file" name="photograph" type="file" />
                            </ListItem>
                          </List>
                        </Col>
                      </Row>
                    )}
                    {visible == true && (
                      <Row className='information-content' >
                        <ListItem button onClick={handleClick1} className={classes.root}>
                          <ListItemIcon>
                            {open ? <ExpandLess /> : <ChevronRight />}
                          </ListItemIcon>
                          <ListItemText primary="gggg" />
                        </ListItem>
                        <Collapse
                          className={classes.bussi_collapse}
                          in={open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Container maxWidth="lg">
                            {collapsedata == true && (
                              <Formik
                                initialValues={{
                                  lname: data.lname, fname: data.fname, pan: data.pan, pincode: data.pincode, area: data.area, email: data.email, mobile: data.mobile, nationality: data.nationality, Address1: data.Address1,
                                  Address2: data.Address2, city: data.city, state: data.state, gender: data.gender, edu: data.edu, addproof: data.addproof, lang: data.lang,
                                }}
                                validationSchema={onboardingval}
                                onSubmit={(values) => {
                                  console.log('kkkkk', values)
                                  localStorage.setItem('onboarding', JSON.stringify(values))
                                  //  handleNextClick()
                                }}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  setFieldTouched
                                  /* and other goodies */
                                }) => {
                                  //  console.log("values in formik", values);
                                  return (
                                    <form onSubmit={handleSubmit}>

                                      <div className="content-header" style={{ position: 'unset' }}>
                                        <Row>
                                          <Col md={{ span: 6 }}>

                                            <Typography
                                              text=" Onboarding"
                                              styleType="C"
                                            />

                                          </Col>
                                          <Col md={{ span: 6 }}>
                                            <div className="content-header-button ">


                                              <Button
                                                className={classes.linkdin}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                                className={classes.button_others}>
                                                <Icon path={mdiPlaylistPlus} title="save" size={1} />
                                              </Button>


                                              <Button
                                                className={classes.button_div}
                                                variant="outlined"
                                                color="primary"
                                                className={classes.button_others}

                                              ><Icon path={mdiRefresh} title="reset" size={1} /></Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <Row style={{ marginTop: '50px' }}>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="First Name" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="fname"
                                                label="Enter First Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  errors.fname &&
                                                    touched.fname &&
                                                    errors.fname
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.fname ? classes.manorma : null,
                                                }}
                                                value={values.fname}
                                                helperText={
                                                  errors.fname &&
                                                  touched.fname &&
                                                  errors.fname
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Last Name" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="lname"
                                                label="Enter Last Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  errors.lname &&
                                                    touched.lname &&
                                                    errors.lname
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.lname ? classes.manorma : null,
                                                }}
                                                value={values.lname}
                                                helperText={
                                                  errors.lname &&
                                                  touched.lname &&
                                                  errors.lname
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="PAN" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="pan"
                                                label="AAAAA9999A"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.pan ? classes.manorma : null,
                                                }}

                                                inputProps={{
                                                  maxLength: 10,
                                                }}
                                                error={
                                                  errors.pan &&
                                                    touched.pan &&
                                                    errors.pan
                                                    ? true
                                                    : false
                                                }
                                                value={values.pan}
                                                helperText={
                                                  errors.pan &&
                                                  touched.pan &&
                                                  errors.pan
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Nationality"
                                            styleType="B"
                                          />

                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Nationality"
                                                id="onationality"
                                                name="onationality"
                                                fluid
                                                search
                                                selection
                                                value={values.onationality}
                                                onBlur={() => setFieldTouched("onationality", true)}
                                                onChange={(e, { value }) => setFieldValue("onationality", value)}
                                                options={nationality}
                                                className={errors.onationality && touched.onationality ? classes.required : values.onationality == '' ? classes.reqired : classes.man}
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.onationality && touched.onationality && values.onationality == '' && (

                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.onationality}</div>

                                          )}
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Mobile" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                label="Enter Mobile No"
                                                name="mobile"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.mobile ? classes.manorma : null,
                                                }}

                                                inputProps={{
                                                  maxLength: 10,
                                                }}
                                                error={
                                                  errors.mobile &&
                                                    touched.mobile &&
                                                    errors.mobile
                                                    ? true
                                                    : false
                                                }
                                                value={values.mobile}
                                                helperText={
                                                  errors.mobile &&
                                                  touched.mobile &&
                                                  errors.mobile
                                                } />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Email" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                label="Enter Email"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.email ? classes.manorma : null,
                                                }}
                                                error={
                                                  errors.email &&
                                                    touched.email &&
                                                    errors.email
                                                    ? true
                                                    : false
                                                }
                                                value={values.email}
                                                helperText={
                                                  errors.email &&
                                                  touched.email &&
                                                  errors.email
                                                }

                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Address Line 1"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                label="Enter Address Line 1"
                                                name="Address1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.Address1 ? classes.manorma : null,
                                                }}
                                                error={
                                                  errors.Address1 &&
                                                    touched.Address1 &&
                                                    errors.Address1
                                                    ? true
                                                    : false
                                                }
                                                value={values.Address1}
                                                helperText={
                                                  errors.Address1 &&
                                                  touched.Address1 &&
                                                  errors.Address1
                                                }

                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Address Line 2"
                                            styleType="B"
                                          /> <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                label="Enter Address Line 2"
                                                name="Address2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.Address2 ? classes.manorma : null,
                                                }}
                                                error={
                                                  errors.Address2 &&
                                                    touched.Address2 &&
                                                    errors.Address2
                                                    ? true
                                                    : false
                                                }
                                                value={values.Address2}
                                                helperText={
                                                  errors.Address2 &&
                                                  touched.Address2 &&
                                                  errors.Address2
                                                } />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Pincode" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="pincode"
                                                label="999999"
                                                onChange={handleChange}
                                                //onBlur={handleBlur}
                                                onBlur={e => {
                                                  handleBlur(e)
                                                  if (!errors.pincode) {
                                                    getPincodeData(values.pincode)
                                                  }
                                                }}
                                                error={
                                                  errors.pincode &&
                                                    touched.pincode &&
                                                    errors.pincode
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.pincode ? classes.manorma : null,
                                                }}

                                                inputProps={{
                                                  maxLength: 6,
                                                }}

                                                value={values.pincode}
                                                helperText={
                                                  errors.pincode &&
                                                  touched.pincode &&
                                                  errors.pincode
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Area" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Area"
                                                id="area"
                                                name="area"
                                                fluid
                                                search
                                                selection
                                                value={values.area}
                                                onBlur={() => setFieldTouched("area", true)}
                                                onChange={(e, { value }) => setFieldValue("area", value)}
                                                options={area}
                                                className={errors.area && touched.area ? classes.required : values.area == '' ? classes.reqired : classes.man}
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="City" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="city"
                                                label="Enter City"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  errors.city &&
                                                    touched.city &&
                                                    errors.city
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.city ? classes.manorma : null,
                                                }}
                                                value={values.city}
                                                helperText={
                                                  errors.city &&
                                                  touched.city &&
                                                  errors.city
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="State" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="state"
                                                label="Enter State"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  errors.state &&
                                                    touched.state &&
                                                    errors.state
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.state ? classes.manorma : null,
                                                }}
                                                value={values.state}
                                                helperText={
                                                  errors.state &&
                                                  touched.state &&
                                                  errors.state
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Gender" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Gender"
                                                id="gender"
                                                name="gender"
                                                fluid
                                                search
                                                selection
                                                value={values.gender}
                                                onBlur={() => setFieldTouched("gender", true)}
                                                onChange={(e, { value }) => setFieldValue("gender", value)}
                                                options={gender}
                                                className={errors.gender && touched.gender ? classes.required : values.gender == '' ? classes.reqired : classes.man}
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.gender && touched.gender && values.gender == '' && (

                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.gender}</div>
                                          )}
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Educational Qualification" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Educational Qualification"
                                                id="edu"
                                                name="edu"
                                                fluid
                                                search
                                                selection
                                                value={values.edu}
                                                onBlur={() => setFieldTouched("edu", true)}
                                                onChange={(e, { value }) => setFieldValue("edu", value)}
                                                options={education}
                                                className={errors.edu && touched.edu ? classes.required : values.edu == '' ? classes.reqired : classes.man}
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.edu && touched.edu && values.edu == '' && (

                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.edu}</div>
                                          )}
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Address Proof" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Address Proof"
                                                id="addproof"
                                                name="addproof"
                                                fluid
                                                search
                                                selection
                                                value={values.addproof}
                                                onBlur={() => setFieldTouched("addproof", true)}
                                                onChange={(e, { value }) => setFieldValue("addproof", value)}
                                                options={address}
                                                className={errors.addproof && touched.addproof ? classes.required : values.addproof == '' ? classes.reqired : classes.man}
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.addproof && touched.addproof && values.addproof == '' && (

                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.addproof}</div>
                                          )}
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Languages Known" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="lang"
                                                label="Enter Languages Known"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                  errors.lang &&
                                                    touched.lang &&
                                                    errors.lang
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.lang ? classes.manorma : null,
                                                }}
                                                value={values.lang}
                                                helperText={
                                                  errors.lang &&
                                                  touched.lang &&
                                                  errors.lang
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Photograph" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <input id="file" name="photograph" type="file" />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      </Row>

                                    </form>
                                  );
                                }}
                              </Formik>
                            )}
                          </Container>
                        </Collapse>
                      </Row>
                    )}

                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </Container>

  );
}