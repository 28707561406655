import React, { useState, useRef } from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ChevronRight from "@material-ui/icons/ChevronRight";
import { SnackPosition } from "../../shared/constants";
import Snackbar from '@material-ui/core/Snackbar';
import Collapse from "@material-ui/core/Collapse";
import { Dropdown } from 'semantic-ui-react';
import { useStyles } from "./styles";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { loanFormSchema, loanformapplyschema, loanapplyschema, loanSchema } from "../../shared/validations";
import axios from "axios";
import clsx from 'clsx';
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


import { ErrorCatch, apiCalling } from "../../shared/constants";


const CrifResult = (props) => {
    React.useEffect(() => {
        
        saveLoan();

    }, []);
    const [crifParsingData, setCrifParsingData] = useState();
    const [basicData, setBasicData] = useState({});
    const [accountList, setAccountList] = useState([]);
    const [linkedAccountList, setLinkedAccountList] = useState([]);
    const [securityDetails, setSecurityDetails] = useState([]);
    const [activeLoansCount, setActiveLoansCount] = useState();
    const [allCount1, setAllCount] = useState(0);
    const [closeLoansCount, setCloseLoansCount] = useState();
    const [closedAccountArray, setClosedAccountArray] = useState([]);
    const [openAccountArray, setOpenAccountArray] = useState([]);
    const [tempArray, setTempArr] = useState([]);
    const [accountSummary, setAccountSummary] = React.useState({});




    const saveLoan = async () => {
        

        let data = props.CrifProps;

        // //console.log(typeof data);
        // //console.log("normal", data);
        let data1;
        if (data != undefined) {
            data1 = JSON.parse(data);
            console.log(data1);
        }



        

        await axios.post(apiCalling.coderscorner + 'server.php/api/loanDataParsingForStandAlonePage', data1).then(
            (result) => {
                
                console.log(result);
                setBasicData(result.data.data.BASICDATA)
                setCrifParsingData(result.data.data);
                let tempArr = [];
                if (isType('array', result.data.data.ACCOUNTLIST) === true) {
                    setAccountList(result.data.data.ACCOUNTLIST);
                    setTempArr(result.data.data.ACCOUNTLIST);
                } else {

                    tempArr.push(result.data.data.ACCOUNTLIST);
                    setAccountList(tempArr);
                    setTempArr(tempArr);
                }
                let activeCount = 0;
                let closeCount = 0;
                
                let opnArr = [];
                let closedArr = [];
                let allCount = 0;

                if (isType('array', result.data.data.ACCOUNTLIST) === true) {
                    result.data.data.ACCOUNTLIST.map((item) => {
                        if (item.ACCOUNTSTATUS == 'Active') {
                            activeCount++;
                            opnArr.push(item);
                            allCount++;

                        } else {
                            closeCount++;
                            closedArr.push(item);
                            allCount++;
                        }
                    });
                } else {
                    if (result.data.data.ACCOUNTLIST.ACCOUNTSTATUS == 'Active') {
                        activeCount++;
                        allCount++;
                        opnArr.push(result.data.data.ACCOUNTLIST);
                    } else {
                        allCount++;
                        closeCount++;
                        closedArr.push(result.data.data.ACCOUNTLIST);
                    }

                }

                setAllCount(allCount);
                setClosedAccountArray(closedArr);
                setOpenAccountArray(opnArr);
                setActiveLoansCount(activeCount);
                setCloseLoansCount(closeCount);
                setLinkedAccountList(result.data.data.LINKEDACCOUNTLIST);
                setSecurityDetails(result.data.data.SECURITYDETAILSLIST);
                setAccountSummary(result.data.data.ACCOUNTSUMMARY);

            }
        ).catch(error => {
            //console.log(error);
        });
    }

    function isType(type, val) {
        return val.constructor.name.toLowerCase() === type.toLowerCase();
    }

    const handleChange1 = (event, newValue) => {

        // setOpenTabBusPro(false);
        setValue(newValue);
    };


    const [typeFlag, setTypeFlag] = useState('all');
    const handleSearh = (e) => {
        let searchArr = [];
        let value = e.target.value;
        let findArr = [];
        if (typeFlag == 'active') {
            findArr = openAccountArray;
        } else if (typeFlag == 'closed') {
            findArr = closedAccountArray;
        } else {
            findArr = tempArray;
        }
        if (value.length >= 3) {
            ////console.log(value);
            findArr.map((item) => {
                if (item.ACCTNUMBER.includes(value)) {
                    ////console.log(item.ACCTNUMBER);
                    searchArr.push(item);
                } else {
                    //console.log("not found")
                }
            });
            setAccountList(searchArr);
        } else if (value.length <= 3) {
            if (typeFlag == 'active') {
                setAccountList(openAccountArray);
            } else if (typeFlag == 'closed') {
                setAccountList(closedAccountArray);
            } else {
                setAccountList(tempArray);
            }
        }
    }

    const [value, setValue] = React.useState('1');
    const [showAllBtn, setShowAllBtn] = useState(false);
    const textInput = useRef(null);


    const generatePdf = () => {
        
        setLoaderFlag(true);
        // html2canvas(document.getElementById('pdf')).then(canvas => {
        //     //  document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //     const imgData = canvas.toDataURL('image/png');
        //     const pdf = new jsPDF();
        //     const imgProps = pdf.getImageProperties(imgData);
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        //     pdf.save("download.pdf");
        // });

        html2canvas(document.querySelector('#pdf')).then(canvas => {
            //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
            const imgData = canvas.toDataURL('image/png');
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF('p', 'mm');
            var position = 0;
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                heightLeft -= pageHeight;
            }
            doc.save("download");
            setLoaderFlag(false);
        });
    }

    const [loaderFlag, setLoaderFlag] = React.useState(false);


    return (
        <>
            <Container maxWidth="lg" id="pdf"  >
                <div className="mini-container mg_btm_set" >
                    {loaderFlag == true && (
                        <div style={{ textAlign: 'center', marginTop: '19%', position: "absolute", zIndex: "9999", marginLeft: "45%" }}>
                            <Loader type={LoaderColor.type} color={LoaderColor.color} />
                        </div>
                    )}
                    <Row >
                        <Col md={{ span: 12 }} >
                            <Row >
                                <Col md={{ span: 6 }} className="stand_header" >
                                    <Typography text="YOUR EXISITING LOANS" styleType="C" />
                                </Col>

                                <Col md={{ span: 12 }} >
                                    {/* <table className="crif_table" >
                                        <tbody>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1" >Name : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                                <td className="crif_table_data1" >ID(s) : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1">Date of Birth : </td>
                                                <td className="crif_table_data2">&nbsp; {basicData.dob}</td>
                                                <td className="crif_table_data1" >Gender : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1">Email : </td>
                                                <td className="crif_table_data2">&nbsp; {basicData.email}</td>
                                                <td className="crif_table_data1" >Phone Numbers : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1">Address : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                                <td className="crif_table_data1" >Other Address : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1">Father Name : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                                <td className="crif_table_data1" >Mothers Name : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                            <tr className="crif_table_row">
                                                <td className="crif_table_data1">Spouse Name : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                                <td className="crif_table_data1" >CRIF Score : </td>
                                                <td className="crif_table_data2">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    <div className="crifheading">
                                        <p>Inquiry Input Informtion</p>
                                    </div>
                                    <Row style={{ padding: "0% 5% 1% 5%" }}>
                                        <Col md={{ span: 2 }} className="inqHead">Name:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.name}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">DOB/Age:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.dob}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Gender:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.gender}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Father Name:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.father}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Mother Name:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.mother}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Spouse Name:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.spouse}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Phone Number:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.phoneNumber}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Email:</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.email}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">ID(s):</Col>
                                        <Col md={{ span: 2 }} className="inqResult">{basicData.kyc_id}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Address:</Col>
                                        <Col md={{ span: 10 }} className="inqResult">{basicData.address}</Col>
                                        <Col md={{ span: 2 }} className="inqHead">Other Address:</Col>
                                        <Col md={{ span: 10 }} className="inqResult">{basicData.otherAddress}</Col>
                                    </Row>
                                    <div className="crifhighheading">
                                        <p>CRIF HIGHMARK SCORE(S)</p>
                                    </div>
                                    <div className="crifscoreinfo">
                                        <p>Note: A-D: Very Low Risk; E-G: Low Risk; H-I: Medium Risk; J-K: High Risk;L-M: Very High Risk</p>
                                    </div>
                                    {/* <Row style={{ width:"100%" }}>
                                        <Col md={{ span: 3 }} className="inqHead">Score Name</Col>
                                        <Col md={{ span: 3 }} className="inqHead">Score Range</Col>
                                        <Col md={{ span: 3 }} className="inqHead">Description</Col>
                                        <Col md={{ span: 3 }} className="inqHead"></Col>
                                    </Row>
                                    <Row style={{ width:"100%", backgroundColor: "#dbeefe" }}>
                                        <Col md={{ span: 3 }} className="inqResult">Perform Score2.0</Col>
                                        <Col md={{ span: 3 }} className="inqResult">300-900</Col>
                                        <Col md={{ span: 3 }} className="inqResult"><b>K</b>-High Risk</Col>
                                        <Col md={{ span: 3 }} className="inqResult"><p>487 Score</p></Col>
                                    </Row> */}
                                    <table style={{ width: "100%", margin: "3% 0%" }}>
                                        <thead>
                                            <th style={{ fontWeight: "600", color: "#282866" }}>Score Name</th>
                                            <th style={{ fontWeight: "600", color: "#282866" }}>Score Range</th>
                                            <th style={{ fontWeight: "600", color: "#282866" }}>Description</th>
                                            <th></th>
                                        </thead>
                                        <tbody style={{ backgroundColor: "#dbeefe" }}>
                                            <td style={{ padding: "0px 8px", width: "45%" }}>{basicData.scoreName}</td>
                                            <td style={{ padding: "0px 8px" }}>{""}</td>
                                            <td style={{ padding: "0px 8px" }}>{basicData.scoreComments}</td>
                                            <span className="crifscore">
                                                <td>
                                                    <p style={{ fontSize: "28px" }}>{basicData.scoreValue}</p>
                                                </td>
                                            </span>
                                        </tbody>
                                    </table>
                                </Col>
                                <br></br>
                                <Col md={{ span: 12 }} style={{ marginTop: "2%" }}>
                                    <TabContext value={value}>
                                        <AppBar position="static">
                                            <TabList aria-label="simple tabs example" onChange={handleChange1}>
                                                <Tab label="ACCOUNT LIST" value="1" className="tabSize" />
                                                <Tab label="Linked Account List" value="2" className="tabSize" />
                                                <Tab label="Security Details" value="3" className="tabSize" />
                                            </TabList>
                                        </AppBar>
                                        <TabPanel value="1">

                                        {
                                            /*
                                            <Row>
                                          

                                                <Col md={{ span: 4 }}>
                                                    <h3 style={{ color: "rgb(114, 84, 163)" }}>Primary Accounts Summary</h3>
                                                    <table className="" border="1">

                                                        <tr>
                                                            <th className="headersecond"   >Primary number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond" >Primary active number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_active_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond" >Primary secured number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_secured_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond" >Primary unsecured number of Accounts:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_unsecured_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary untagged number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_untagged_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary current balance:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_current_balance : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary sanctioned account:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_sanctioned_amount : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary disbursed amount:</th>
                                                            <td className="crif_td">{accountSummary.primary_accounts_summary !== undefined ? accountSummary.primary_accounts_summary.primary_disbursed_amount : ""}</td>
                                                        </tr>
                                                    </table>
                                                </Col>

                                                <Col md={{ span: 4 }}>
                                                    <h3 style={{ color: "rgb(114, 84, 163)" }}>Secondary Accounts Summary</h3>
                                                    <table className="" border="1">

                                                        <tr>
                                                            <th className="headersecond" >Primary number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond"  >Primary active number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_active_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond"  >Primary secured number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_secured_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond" >Primary unsecured number of Accounts:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_unsecured_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary untagged number of accounts:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_untagged_number_of_accounts : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary current balance:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_current_balance : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary sanctioned account:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_sanctioned_amount : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond" >Primary disbursed amount:</th>
                                                            <td className="crif_td">{accountSummary.secondary_accounts_summary !== undefined ? accountSummary.secondary_accounts_summary.secondary_disbursed_amount : ""}</td>
                                                        </tr>
                                                    </table>

                                                </Col>

                                                <Col md={{ span: 4 }}>
                                                    <h3 style={{ color: "rgb(114, 84, 163)" }}>Derived Attributes</h3>
                                                    <table className="" border="1">


                                                        <tr>
                                                            <th className="headersecond" >Inquries in last six months:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes !== undefined ? accountSummary.derived_attributes.inquries_in_last_six_months : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond" >Length of credit history month:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ? accountSummary.derived_attributes.length_of_credit_history_month : ""}</td>
                                                        </tr>
                                                        <tr >
                                                            <th className="headersecond"  >Length of credit history year:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ? accountSummary.derived_attributes.length_of_credit_history_year : ""}</td>
                                                        </tr>

                                                        <tr>
                                                            <th className="headersecond"  >Average account age month:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ? accountSummary.derived_attributes.average_account_age_month : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond"  >Average account age year:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ?
                                                                accountSummary.derived_attributes.average_account_age_year : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="headersecond"  >New account sin last six months:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ?
                                                                accountSummary.derived_attributes.new_account_sin_last_six_months : ""}</td>
                                                        </tr>
                                                        <tr rowspan="2">
                                                            <th className="headersecond"  >New delinq account in last six months:</th>
                                                            <td className="crif_td">{accountSummary.derived_attributes != undefined ?
                                                                accountSummary.derived_attributes.new_delinq_account_in_last_six_months : ""}</td>
                                                        </tr>
                                                    </table>
                                                </Col>
                                            </Row>
                                            */
                                        }
                                            

                                            <Row>
                                                <Col md={{ span: 6 }} style={{ marginTop: "3%" }}>
                                                    <button class="btn btn-danger" onClick={() => {
                                                        setAccountList(openAccountArray);
                                                        setShowAllBtn(true);
                                                        setTypeFlag('active');
                                                        textInput.current.value = '';
                                                    }}>
                                                        Active Loans <span class="badge badge-light">{activeLoansCount}</span>
                                                    </button>
                                                    <button class="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => {
                                                        setAccountList(closedAccountArray);
                                                        setShowAllBtn(true)
                                                        setTypeFlag('closed');
                                                        textInput.current.value = '';
                                                    }}>
                                                        Closed Loans <span class="badge badge-light">{closeLoansCount}</span>
                                                    </button>
                                                    {showAllBtn && (
                                                        <button class="btn btn-warning" style={{ marginLeft: "5px" }} onClick={() => {
                                                            
                                                            setAccountList(tempArray);
                                                            setShowAllBtn(false);
                                                            textInput.current.value = '';
                                                        }}>
                                                            All Loans {"  "}<span class="badge badge-light">{allCount1}</span>
                                                        </button>
                                                    )}
                                                    <button class="btn btn-warning" style={{ marginLeft: "5px" }} onClick={() => {
                                                        
                                                        generatePdf();
                                                    }}>
                                                        Download Pdf
                                                    </button>
                                                </Col>
                                                <Col md={{ span: 12 }}style={{maginTop:"-2%"}} >
                                                    <input type="text" placeholder="Account No" name="search" ref={textInput} onChange={(e) => { handleSearh(e) }} className="stand_alone_search" />
                                                    {accountList.map((item, i) => (

                                                        <table className="crif_table" border="1" style={{ marginTop: "1.5%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    {item.ACCOUNTSTATUS == 'Active' ? (
                                                                        <td rowspan={11 + item.YEARLIST.length} style={{ textAlign: "center", width: "25px", backgroundColor: "rgb(238,125,17)", fontWeight: "bold" }}>{i + 1}</td>
                                                                    ) : (
                                                                        <td rowspan={11 + item.YEARLIST.length} style={{ textAlign: "center", width: "25px", backgroundColor: "#007bff", fontWeight: "bold" }}>{i + 1}</td>
                                                                    )}

                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Credit Grantor</td>
                                                                    <td colspan="2" style={{ width: "100px" }}>{item.CREDITGUARANTOR}</td>
                                                                    <td className="crif_header" colspan="2">Account Number</td>
                                                                    <td colspan="2">{item.ACCTNUMBER}</td>
                                                                    <td className="crif_header" colspan="2">Account Type</td>
                                                                    <td colspan="3">{item.ACCTTYPE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Account Active Status</td>
                                                                    {item.ACCOUNTSTATUS == 'Active' ? (<td colspan="2" style={{ backgroundColor: "rgb(238,125,17)" }}>{item.ACCOUNTSTATUS}</td>) :
                                                                        (<td colspan="2" style={{ backgroundColor: "#007bff" }}>{item.ACCOUNTSTATUS}</td>)}
                                                                    <td className="crif_header" colspan="2">Ownership</td>
                                                                    <td colspan="2">{item.OWNERSHIPIND}</td>
                                                                    <td className="crif_header" colspan="2">Disbursed Date</td>
                                                                    <td colspan="3">{item.DISBURSEDDT}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Disbursed Amount</td>
                                                                    <td colspan="2">{item.DISBURSEDAMT}</td>
                                                                    <td className="crif_header" colspan="2">Credit Limit</td>
                                                                    <td colspan="2">{item.CREDITLIMIT}</td>
                                                                    <td className="crif_header" colspan="2">Last Payment Date</td>
                                                                    <td colspan="3">{item.LASTPAYMENTDATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Current Balance</td>
                                                                    <td colspan="2">{item.CURRENTBAL}</td>
                                                                    <td className="crif_header" colspan="2">Cash Limit</td>
                                                                    <td colspan="2">{item.CASHLIMIT}</td>
                                                                    <td className="crif_header" colspan="2">Closed Date</td>
                                                                    <td colspan="3">{item.CLOSEDDATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Last Paid Amount</td>
                                                                    <td colspan="2">{"missing"}</td>
                                                                    <td className="crif_header" colspan="2">Installment Amount</td>
                                                                    <td colspan="2">{item.INSTALLMENTAMT}</td>
                                                                    <td className="crif_header" colspan="2">Interest Rate</td>
                                                                    <td colspan="3">{item.INTERESTRATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Tenure(Months)</td>
                                                                    <td colspan="2">{item.TENURE}</td>
                                                                    <td className="crif_header" colspan="2">Overdue Amount</td>
                                                                    <td colspan="2">{item.OVERDUEAMT}</td>
                                                                    <td className="crif_header" colspan="2">Principle Write Off Amount</td>
                                                                    <td colspan="3">{item.WRITEOFFAMT}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="crif_header" colspan="2">Total Write Off Amount</td>
                                                                    <td colspan="2">{item.WRITEOFFAMT}</td>
                                                                    <td className="crif_header" colspan="2">Settlement Amount</td>
                                                                    <td colspan="2">{item.SETTLEMENTAMOUNT}</td>
                                                                    <td className="crif_header" colspan="2">Account Status</td>
                                                                    <td colspan="3">{item.ACCOUNTSTATUSLAST}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="13" className="crif_header" style={{ backgroundColor: "#FFEFCB" }}>Payment History</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                    <td className="crif_header month_header">January</td>
                                                                    <td className="crif_header  month_header">Febraury</td>
                                                                    <td className="crif_header  month_header">Marh</td>
                                                                    <td className="crif_header  month_header">April</td>
                                                                    <td className="crif_header  month_header">May</td>
                                                                    <td className="crif_header  month_header">June</td>
                                                                    <td className="crif_header  month_header">July</td>
                                                                    <td className="crif_header  month_header">August</td>
                                                                    <td className="crif_header  month_header">September</td>
                                                                    <td className="crif_header  month_header">October</td>
                                                                    <td className="crif_header  month_header">November</td>
                                                                    <td className="crif_header  month_header">December</td>
                                                                </tr>
                                                                {item.YEARLIST.map((yitem, j) => (
                                                                    <tr>
                                                                        <td className="crif_year">{yitem}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Jan}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Feb}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Mar}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Apr}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].May}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Jun}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Jul}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Aug}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Sep}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Oct}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Nov}</td>
                                                                        <td>{item.PAYMENTHISTORY[yitem].Dec}</td>
                                                                    </tr>

                                                                ))}


                                                            </tbody>
                                                        </table>

                                                    ))}

                                                </Col>

                                            </Row>

                                        </TabPanel>




                                        <TabPanel value="2">
                                            <Row>
                                                <Col md={{ span: 12 }}>

                                                    <table className="" border="1">
                                                        <thead>
                                                            <tr>
                                                                <th className="crif_table_sl headersecond">#</th>
                                                                <th className="headersecond crif_header2" >Account Number</th>
                                                                <th className="headersecond crif_header2">Linked Account Number  </th>
                                                                <th className="headersecond crif_header2" >Credit Guarantor</th>
                                                                <th className="headersecond crif_header2">Account Type</th>
                                                                <th className="headersecond crif_header2">Credit Limit</th>
                                                                <th className="headersecond crif_header2">High Credit</th>
                                                                <th className="headersecond crif_header2">Amount OverDue</th>
                                                                <th className="headersecond crif_header2">Disbursed Date</th>
                                                                <th className="headersecond crif_header2">Closed Date</th>
                                                                <th className="headersecond crif_header2">Last Payment Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {linkedAccountList.map((item, i) => (
                                                                <tr className="crif_tr">
                                                                    <td className="crif_td">{i + 1}</td>
                                                                    <td className="crif_td" >{item.ACCOUNTNUMBER}</td>
                                                                    <td className="crif_td">{item.LINKEDACCOUNTNUMBER}</td>
                                                                    <td className="crif_td">{item.CREDITGUARANTOR}</td>
                                                                    <td className="crif_td">{item.ACCOUNTTYPE}</td>
                                                                    <td className="crif_td">{item.CREDITLIMIT}</td>
                                                                    <td className="crif_td">{item.HIGHCREDIT}</td>
                                                                    <td className="crif_td">{item.AMOUNTOVERDUE}</td>
                                                                    <td className="crif_td">{item.DISBURSEDDATE}</td>
                                                                    <td className="crif_td">{item.CLOSEDDATE}</td>
                                                                    <td className="crif_td">{item.LASTPAYMENTDATE}</td>

                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </Col>

                                            </Row>
                                        </TabPanel>

                                        <TabPanel value="3">
                                            <Row>
                                                <Col md={{ span: 12 }}>

                                                    <table className="" border="1">
                                                        <thead>
                                                            <tr>
                                                                <th className="crif_table_sl headersecond" >#</th>
                                                                <th className="crif_header2 headersecond" >Account Number</th>
                                                                <th className="crif_header2 headersecond">Security Type</th>
                                                                <th className="crif_header2 headersecond" >Owner Name</th>
                                                                <th className="crif_header2 headersecond">Type of Charge</th>
                                                                <th className="crif_header2 headersecond">Security Value</th>
                                                                <th className="crif_header2 headersecond">Date of Value</th>
                                                                <th className="crif_header2 headersecond">Property Address</th>
                                                                <th className="crif_header2 headersecond">Automobile Type</th>
                                                                <th className="crif_header2 headersecond">Manfacture Year</th>
                                                                <th className="crif_header2 headersecond">Registration Number</th>
                                                                <th className="crif_header2 headersecond">Engine Number</th>
                                                                <th className="crif_header2 headersecond">Chassis Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {securityDetails.map((item, i) => (
                                                                <tr className="crif_tr">
                                                                    <td className="crif_td">{i + 1}</td>
                                                                    <td className="crif_td" >{item.ACCOUNTNUMBER}</td>
                                                                    <td className="crif_td">{item.SECURITYTYPE}</td>
                                                                    <td className="crif_td" >{item.OWNERNAME}</td>
                                                                    <td className="crif_td">{item.TYPEOFCHARGE}</td>
                                                                    <td className="crif_td">{item.SECURITYVALUE}</td>
                                                                    <td className="crif_td">{item.DATEOFVALUE}</td>
                                                                    <td className="crif_td">{item.PROPERTYADDRESS}</td>
                                                                    <td className="crif_td">{item.AUTOMOBILETYPE}</td>
                                                                    <td className="crif_td">{item.YEAROFMANUFACTURE}</td>
                                                                    <td className="crif_td">{item.REGISTRATIONNUMBER}</td>
                                                                    <td className="crif_td">{item.ENGINENUMBER}</td>
                                                                    <td className="crif_td">{item.CHASSISNUMBER}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </TabPanel>
                                    </TabContext>
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                </div>

            </Container>
        </>

    )
}
export default CrifResult;