import React, { useState } from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { DropzoneArea } from 'material-ui-dropzone'
import { useStyles } from './styles'
import { useStylesDropzone } from './styles'
import Typography from '../../base_components/Typography'
import axios from 'axios'
import Tooltip from '@material-ui/core/Tooltip'
import { AttachFile } from '@material-ui/icons'
import addbutton from '../../image/pngicons/plussign.png'
import deleterec from '../../image/pngicons/delete.png'
import imageimg from '../../image/pngicons/image.jpeg'
import calender from '../../image/pngicons/thisyear.png'
import excelimg from '../../image/pngicons/excel.jpeg'
import view from '../../image/pngicons/view.jpeg'
import pdfimage from '../../image/pngicons/pdf.jpeg'
import moment from 'moment'
import { ErrorCatch, apiCalling } from '../../shared/constants'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { SnackPosition } from '../../shared/constants'
import Back from '../../image/buttons/back.png'
import grayback from '../../image/buttons/grayback.png'
import DraftNext from '../../image/buttons/draftnext.png'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import FileViewer from 'react-file-viewer'
import { CustomErrorComponent } from 'custom-error'
import IconButton from '@material-ui/core/IconButton';
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'

const DocsCollected = props => {
  const classes = useStyles()
  const classesDropzone = useStylesDropzone()
  React.useEffect(() => {
    console.log('loaded this page')
    //
    getUploadeDocOfUserV2()
    // detailsDocument();
    checkCall()
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    getValidationConfiguration()
  }, [])
  function Alert (props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const checkCall = () => {
    console.log('---------------------')
  }

  const [docBase64, setDocBase64] = React.useState()
  const [showmodal, setshowmodal] = React.useState(false)
  const [colorMessage, setcolorMessage] = React.useState()

  //   const handleshowhidepdf = (base64, fileType) => {
  //     setDocBase64(
  //       'https://interoperability.blob.core.windows.net/files/MS-DOC/%5bMS-DOC%5d-150316.docx'
  //     )
  //     let fileExt = fileType.split('.')
  //     //if(fileExt[fileExt.length-1]=="sheet"){
  //     setshowmodal(true)
  //     //  }
  //   }

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

  const handleclosePdf = () => {
    setshowmodal(false)
  }

  const handledata = () => {
    props.handleBack()
  }
  const SaveasDraft = () => {
    props.handleNext()
  }
  const handleSubmiting = () => {
    props.handleNext()
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [dropFileText, setDropFileText] = React.useState(
    'Drag & Drop file or Browse'
  )

  // for old authority wise pincodes
  const getUploadeDocOfUser = async () => {
    //
    // const docdata = {
    //     user_id: userId.id,
    //     parent_id: props.formdetails.parentId,
    //     parent_type_id: props.formdetails.parentTypeId,
    //     form: props.formdetails.formId,
    //     required_phase: "172"
    // }

    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

    //
   // setLoaderFlag(true);

    let data = {
      user_id: checkuserid.user_id,
      parent_id: checkuserid.business_id,
      parent_type_id: 1
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getAllDocumentsByUser',
        data
      )
      .then(result => {
        //

        if (result.data.data.length > 1) {
          result.data.data.map(item => {
            myArr.push({
              id: item.id,
              name: item.name,
              documentArray: item.documentArray
            })
          })
          setFilelist(myArr)
        } else {
          detailsDocument()
        }
      })
      .catch(error => {
        ErrorCatch(userId.id, error, api_path, 'post', data, userId, userId.id)
       // console.log(error);
       //// setLoaderFlag(false);
      });
      
     //// setLoaderFlag(false);
  }

  //for all pincodes
  const [group, setGroup] = React.useState({});
  const getUploadeDocOfUserV2 = async () => {
    //
    // const docdata = {
    //     user_id: userId.id,
    //     parent_id: props.formdetails.parentId,
    //     parent_type_id: props.formdetails.parentTypeId,
    //     form: props.formdetails.formId,
    //     required_phase: "172"
    // }

    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

    //
setLoaderFlag(true);
    let data = {
      user_id: checkuserid.user_id,
      parent_id: checkuserid.business_id,
      parent_type_id: 1,
      business_name: checkuserid.business_name
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getAllDocumentsByUserV2',
        data
      )
      .then(result => {
        if (result.data.data.length > 1) {
          const groups = result.data.data.reduce((groups, item) => {
              const group = (groups[item.doc_type] || []);
              group.push(item);
              groups[item.doc_type] = group;
              return groups;
          }, {});
          Object.keys(groups).forEach(key => {
            console.log(key)
            if (key === 'null') {
              console.log('ahioa')
              delete groups[key];
            }
          });
          console.log("\n \n groupgroup", groups)
          setGroup(groups)
          result.data.data.map(item => {
            myArr.push({
              id: item.id,
              name: item.name,
              documentArray: item.documentArray
            })
          })
          setFilelist(myArr)
        } else {
          detailsDocument()
        }
      })
      .catch(error => {
        ErrorCatch(userId.id, error, api_path, 'post', data, userId, userId.id)
        setLoaderFlag(false);
      });
      setLoaderFlag(false);
  }

  const [maxFileSize, setMaxFileSize] = React.useState([]);
  const [maxFileSizeMsg, setMaxFileSizeMsg] = React.useState([]);
  const [allowedFileType, setAllowedFileType] = React.useState([]);

  const getValidationConfiguration = async () => {
    let data = {
      parameter_name: ['max_upload_size', 'allowed_file_type']
    }

    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGeneralSettingForMultiple',
        data
      )
      .then(result => {
        const res = result.data.data;
        setMaxFileSize(res.max_upload_size[0].parameter_value * 1048576);
        setMaxFileSizeMsg(`File size exceeds maximum limit of 2${res.max_upload_size[0].parameter_value}MB.`)
        setAllowedFileType(res.allowed_file_type[0].parameter_value.split(','));
      })
      .catch(error => {
        console.log(error)
      })
  }

  // const getUploadSize = async () => {
  //   let docdata = {
  //     "key": ["max_upload_size", "allowed_file_type"]
  //   }
  //   console.log(JSON.stringify(docdata))
  //   let api_path = apiCalling.coderscorner + `server.php/api/getGeneralSettingByKeys`
  //   await axios
  //     .get(
  //       api_path,
  //       JSON.stringify(docdata)
  //     )
  //     .then(result => {
        // result.data.data.map(item => {
        //   if(item.parameter_name == 'max_upload_size'){
        //     setMaxFileSize(item.parameter_value * 1000000);
        //   }
        //   if(item.parameter_name == 'allowed_file_type'){
        //     setAllowedFileType(item.parameter_value.split(','));
        //   }
        // })
  //     })
  //     .catch(error => {
  //       ErrorCatch(
  //         userId.id,
  //         error,
  //         api_path,
  //         'post',
  //         docdata,
  //         userId.id,
  //         userId.id
  //       )
  //       console.log(error)
  //     })
  // }
  
  const [fileList, setFilelist] = React.useState([])
  const checkuserloggeddata = localStorage.getItem('userdata')
  let userId = JSON.parse(checkuserloggeddata)
  const detailsDocument = async () => {
    const docdata = {
      user_id: userId.id,
      parent_id: props.formdetails.parentId,
      parent_type_id: props.formdetails.parentTypeId,
      form: props.formdetails.formId,
      required_phase: '172'
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getDetailsDocument`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getDetailsDocumentV2`,
        docdata
      )
      .then(result => {
        console.log('Document data...!!!', result.data)
        let myArr = []
        console.log(result.data)
        result.data.data.map(item => {
          myArr.push({
            id: item.id,
            name: item.name,
            documentArray: item.documentArray
          })
        })
        setFilelist(myArr)
      })
      .catch(error => {
        ErrorCatch(
          userId.id,
          error,
          api_path,
          'post',
          docdata,
          userId.id,
          userId.id
        )
        console.log(error)
      })
  }

  const fileSetInArray = async (e, index, kIndex, checkListId) => {
    let temp_fileList = [...fileList]
    if (e.length > 0) {
      let temp_file = { ...temp_fileList[index] }
      var date = new Date()
      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      var modifiedDate =
        date.getDate() +
        ' ' +
        months[date.getMonth()] +
        ' ' +
        date.getFullYear()
      let reader = new FileReader()
      reader.readAsDataURL(e[0])
      reader.onload = function () {
        temp_file.documentArray[kIndex].file_name = e[0].name
        temp_file.documentArray[kIndex].file_type = e[0].type
        temp_file.documentArray[kIndex].modified_date = modifiedDate
        temp_file.documentArray[kIndex].document_upload_id = ''
        temp_file.documentArray[kIndex].base64String = reader.result
        temp_fileList[index] = temp_file
        setFilelist(temp_fileList)
        uploadDoc(e[0].name, e[0].type, checkListId, reader.result)
      }

      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }

  const callFileDeleteOption = index => {
    let temp_fileList = [...fileList]
    let temp_file = { ...temp_fileList[index] }
    temp_file.file_name = ''
    temp_file.file_type = ''
    temp_file.modified_date = ''
    temp_file.document_upload_id = ''
    temp_fileList[index] = temp_file
    setFilelist(temp_fileList)
  }
  // add doc based on fileList array index value from table
  const [arrOne, setArrOne] = useState([1])
  const [checkList, setCheckList] = useState()
  const addDoc = id => {
    let newArr = [...fileList]
    for (let i = 0; i < newArr.length; i++) {
      if (id == i) {
        fileList[id].documentArray.push({
          file_name: '',
          base64String: '',
          file_type: '',
          modified_date: ''
        })
        break
      }
    }
    setFilelist(newArr)
    console.log("list5", fileList)
  }
  // remove document  where mainId is fileList[] array id and subId is docuMentArray index valuex
  const removeDoc = async (mainId, subId, document_uplaod_id) => {
    let aaaa
    let newArr = [...fileList]
    for (let i = 0; i < newArr.length; i++) {
      if (mainId == i) {
        newArr[mainId].documentArray.splice(subId, 1)
        break
      }
    }
    if (subId === 0) {
      newArr[mainId].documentArray.push({
        file_name: '',
        base64String: '',
        file_type: '',
        modified_date: ''
      })
    }
    let data = {
      id: document_uplaod_id
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/deleteDocById'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/deleteDocById', data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        ErrorCatch(
          document_uplaod_id,
          error,
          api_path,
          'post',
          data,
          document_uplaod_id,
          document_uplaod_id
        )
        console.log(error)
      })

    setFilelist(newArr)
    // const list = [...arrOne];
    // list.splice(index, 1);
    // setArrOne(list);
  }

  const uploadDoc = (name, filetype, checkListId, docbase64) => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let data = {
      user_id: checkuserid.user_id,
      parent_id: checkuserid.business_id,
      parent_type_id: 1,
      document_checklist_id: checkListId,
      name: name,
      file_type: filetype,
      document: docbase64
    }
    let api_path = apiCalling.coderscorner + `server.php/api/savedocuments`
    axios
      .post(apiCalling.coderscorner + `server.php/api/savedocuments`, data)
      .then(result => {
        console.log(result)
        setsavenextmessage(true)
      })
      .catch(error => {
        ErrorCatch(
          userId.id,
          error,
          api_path,
          'post',
          data,
          userId.id,
          userId.id
        )
        console.log(error)
      })
  }

  const [previewType, setPreviewType] = React.useState('')
  const [previewFile, setPreviewFile] = React.useState('')

  const pdfContentType = 'application/pdf'

  const base64toBlob = (data, content_type) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      `data:${content_type};base64,`.length
    )
    const bytes = atob(base64WithoutPrefix)
    let length = bytes.length
    let out = new Uint8Array(length)
    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }
    return new Blob([out], { type: pdfContentType })
  }

  const handleshowhidepdf = (base64, fileType, item) => {
    const url = URL.createObjectURL(base64toBlob(base64, fileType))
    setDocBase64(base64)
    let fileExt = fileType.split('/')
    setPreviewFile(url)
    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setPreviewType('xlsx')
    } else if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setPreviewType('docx')
    } else if (fileType === 'application/msword') {
      setPreviewType('doc')
    } else {
      setPreviewType(fileExt[1])
    }
    setshowmodal(true)
  }

  // const handleshowhidepdf = (base64, fileType, item) => {
  //   console.log("Ahous", item.docPath);
  //   let fileExt = fileType.split('/')
  //   setPreviewFile(item.docPath)
  //   setPreviewType(fileExt[1])
  //   //if(fileExt[fileExt.length-1]=="sheet"){
  //   setshowmodal(true)
  //   //  }
  // }

  const [loaderFlag, setLoaderFlag] = React.useState(true);

  const sliceDocName = (docname)=>{
    let string ='';
    if(!docname){
      string =  docname.split('.');
      string = string[0].slice(20) + string[1];
    }
        return string;
  }


  return (

    <Container maxWidth='lg' style={{ padding: '50px' }}>
    {loaderFlag == true ? (
      <div style={{ textAlign: 'center', margin: '20%' }}>
        <Loader type={LoaderColor.type} color={LoaderColor.color} />
      </div>
    ) :

    (<>
      <Row>
        <Snackbar
          open={savenextmessage}
          style={{ marginTop: '65px' }}
          autoHideDuration={SnackPosition.duration}
          onClose={() => {
            setsavenextmessage(false)
          }}
          anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}
        >
          <Alert
            onClose={() => {
              setsavenextmessage(false)
            }}
            severity={colorMessage}
          >
            Uploaded Successfully
          </Alert>
        </Snackbar>
        <Col md={{ span: 12 }} className='documentuploadHeader'>
          <Typography text='Document Upload' styleType='C' />
        </Col>
        <Col md={{ span: 12 }} className='subHeader1'>
          <table className='applicationTableDetails1'>
            <tr>
              {/* <td>Type</td>
                                <td>:</td> */}
              {localData.request_type_id === 0 && <td>Loan Application</td>}
              {localData.request_type_id === 1 && <td>Check Eligibility</td>}
              <td>&nbsp;|&nbsp;</td>
              <td>Application Number</td>
              <td>:</td>
              <td>{localData.reference_id}</td>
              <td>&nbsp;|&nbsp;</td>
              <td>Loan Type</td>
              <td>:</td>
              <td>{localData.loan_type}</td>
            </tr>
          </table>
        </Col>
        {/* </Row> */}
        {/* </div> */}
        {/* <div> */}
        {/* <Row style={{ border: '1px solid', textAlign: 'center' }}> */}
        <span className='docUplHeader'>
          <Col md={{ span: 4 }}>
            <Typography text='Document Category' />
          </Col>
          <Col md={{ span: 10 }}>
            <Typography text='Document Uploaded' />
          </Col>
        </span>
      </Row>

      {Object.keys(group).map((doc_key, index) => (
        doc_key != null ? (<div key={index}>
          <Typography text={doc_key} />
          {group[doc_key].map((item, k) => (
            <Row className='docUploadRow'>
              <Col md={{ span: 4 }} style={{ padding: '18px' }}  className='document_name'>
                <Typography text={item.name} styleType='AB' />
              </Col>
              {item.documentArray.map((item1, i) => (
                <>
                  {
                    // <Col md={{ span: 4 }}></Col>
                    i >= 1 && <Col md={{ span: 4 }}></Col>
                }
    
                <Col md={{ span: 2 }}></Col>
    
                  <Col md={{ span:  6 }} style={{ display: 'flex' }}>
                    <>
                      {item1.file_name == '' && (
                        <DropzoneArea
                          key={i}
                          onChange={files => {
                            fileSetInArray(files, k, i, item.id)
                          }}
                          Icon={AttachFile}
                          style={{ margin: '5px 0px' }}
                          dropzoneText={dropFileText}
                          maxFileSize={maxFileSize}
                          // labelMaxFileSizeExceeded={"Helo"}
                          acceptedFiles={allowedFileType}
                          // [
                          //   'image/*',
                          //   'application/pdf',
                          //   // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          //   // 'text/csv'
                          // ]
                          // getDropRejectMessage={(rejectedFile,
                          //   acceptedFiles,
                          //   maxFileSize) => {console.log(maxFileSize)}}
                          alertSnackbarProps={{
                            anchorOrigin: { vertical: 'top', horizontal: 'right' }
                          }}
                          showPreviews={false}
                          showPreviewsInDropzone={false}
                        />
                      )}
                      {item1.file_name !== '' && (
                        <Col md={{ span: 10 }}>
                          <Row>
                            <Col md={{ span: 1 }}>
                              {item1.file_type == 'application/pdf' && (
                                // <i class="far fa-file-pdf" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                <img
                                  src={pdfimage}
                                  alt='img'
                                  className='uploadedImageView'
                                />
                              )}
                              {item1.file_type == 'image/jpeg' && (
                                // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                <img
                                  alt='img'
                                  src={imageimg}
                                  className='uploadedImageView'
                                />
                              )}
                              {item1.file_type ==
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                                // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                <img
                                  alt='img'
                                  src={excelimg}
                                  className='uploadedImageView'
                                />
                              )}
                            </Col>
                            <Col md={{ span: 9 }}>
                              
                              <p className='docUplDate'>
                                {moment(item1.modifiedDate).format(
                                  'DD-MMMM-YYYY'
                                )}
                              </p>
                            </Col>
                            <Col md={{ span: 2 }} className='fileUploadAction'>
                              <div style={{ marginTop: '20px' }}>
                                {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> &nbsp;
                            <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => removeDoc(k, i, item1.id)}></i> */}
                                <Tooltip title='View' placement='bottom'>
                                  {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> */}
                                  <img
                                    alt='img'
                                    className='actionBtn svgicon'
                                    src={view}
                                    onClick={() =>
                                      //   handleshowhidepdf(
                                      //     item1.base64String,
                                      //     item1.file_type
                                      //   )
                                      {
                                        handleshowhidepdf(
                                          item1.base64String,
                                          item1.file_type,
                                          item1
                                        )
                                      }
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title='Delete' placement='bottom'>
                                  <img
                                    alt='img'
                                    className='actionBtn svgicon'
                                    src={deleterec}
                                    onClick={() => removeDoc(k, i, item1.id)}
                                  />
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col md={{ span: 2 }}>
                        {i == item.documentArray.length - 1 && (
                          // <i class="fas fa-plus" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { // addDoc(k); }} ></i>
                          <Tooltip title='Add' placement='bottom'>
                            <img
                              alt='img'
                              className='actionBtn svgicon docAddBtn'
                              src={addbutton}
                              onClick={() => {
                                addDoc(k)
                              }}
                            />
                          </Tooltip>
                        )}
                        {item.documentArray.length > 1 && item1.file_name == '' && (
                          // <i class="fas fa-trash-alt" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { removeDoc(k, i) }} ></i>
                          <Tooltip title='Delete' placement='bottom'>
                            <img
                              alt='img'
                              className='actionBtn svgicon docDelBtn'
                              src={deleterec}
                              onClick={() => {
                                removeDoc(k, i)
                              }}
                            />
                          </Tooltip>
                        )}
                      </Col>
                    </>
                  </Col>
                </>
              ))}
            </Row>
          ))}
          </div>): null
      ))}
      
      <Modal
        show={showmodal}
        animation={false}
        centered
        style={{ height: '550px' }}
      >
        <Modal.Header
          closeButton
          onClick={handleclosePdf}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <IconButton
            style={{ color: 'black', backgroundColor: 'black' }}
            aria-label='close'
            className={classes.closeButton}
            onClick={handleclosePdf}
          >
            {/* <CloseIcon style={{marginTop:"-10px",color:"white",fontWeight:"800",cursor:"pointer"}}/> */}
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={{ span: 12 }}>
              <FileViewer
                fileType={previewType}
                filePath={previewFile}
                //  errorComponent={CustomErrorComponent}
                className=' '
                errorComponent={CustomErrorComponent}
                // onError={onError}
                style={{
                  height: '90%',
                  width: '90%'
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* </div > */}
      {/* </div > */}
      <div className='btn_row_fixd'>
        <Row>
          <Col md={{ span: 10 }}></Col>
          <Col style={{ padding: '0%' }}></Col>
          <Col style={{ padding: '0%' }}></Col>
          <Col style={{ padding: '0%' }}>
            <span className='image'>
              <img
                alt='nimage'
                className='navbutton'
                src={Back}
                onClick={handledata}
              />
              <Tooltip
                title='Back'
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow
                }}
                arrow
              >
                <img
                  alt='nimage'
                  className='navgraybutton'
                  src={grayback}
                  onClick={handledata}
                />
              </Tooltip>
            </span>
          </Col>
          <Col style={{ padding: '0%' }}>
            <span className='image'>
              <img
                alt='nimage'
                className='navbutton'
                src={DraftNext}
                onClick={SaveasDraft}
              />
              <Tooltip title='Save & Next' arrow>
                <img
                  alt='nimage'
                  className='navgraybutton'
                  src={graydraftnext}
                  onClick={SaveasDraft}
                />
              </Tooltip>
            </span>
          </Col>
        </Row>
      </div>
    </>)
              }
      
    </Container>
  )
}
export default DocsCollected
