import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { stateLinkFormSchema } from "../../shared/validations";
import axios from "axios";
import { apiCalling } from '../adminConstants';
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import {
  mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from '../../image/buttons/graydraftnext.png';
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

export default function State(props) {

  const classes = useStyles();
  const [table, settable] = React.useState(false);
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [showList, setShowList] = React.useState(true);
  const [loaderFlag, setLoaderFlag] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    handleStateList();
  }, []);

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {
    props.handleBack();
  };


  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true
    })
  };

  const SaveasDraft = () => {
    alert('Save As Draft')
    props.handlecheck();
  }




  let [initialValue, setInitialValue] = React.useState({
    state_id: "",
    name: "",
    enable: "",
    id: ""
  })


  const [StateList, setStateList] = React.useState([]);
  const handleStateList = async () => {
    const data = {
      enable: true
    };
    
    await axios
      .post(apiCalling.coderscorner +
        `server.php/api/getAllState`,
        data
      ).then((result) => {
        let empArray = [];
        result.data.data.map((item) => {
          empArray.push({
            state_id: item.state_id,
            name: item.state_name,
            enable: item.enable.toString(),
            id: item.id,
          })
        })
        setShowWCAddForm(false);
        setShowList(true);
        setLoaderFlag(false);
        setStateList(empArray);
      }).catch((error) => {
        console.log(error);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "slno",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "State Name",
        field: "name",
        width: 270,
      },
      {
        label: "State ID",
        field: "state_id",
        width: 270,
      },

      {
        label: "Enable",
        field: "enable",
        width: 200,
      },

      {

        label: "Action",
        field: "action",
        width: 200,
      },
    ],

    rows: StateList.map((values, i) => {
      return {
        slno: i + 1,
        name: values.name,
        state_id: values.state_id,
        enable: values.enable ? "true" : "false",
        action:
          <div key={i} style={{ marginLeft: '-5px' }}>
            <Tooltip title="Edit Data" placement="bottom">
              {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
              </Button> */}
              <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />
            </Tooltip>

            <Tooltip title="Delete Data" placement="bottom">
              {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
              </Button> */}
              <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
            </Tooltip>
          </div>
      }
    })
  }


  const handlesubmitform = (values, { resetForm }) => {
    handleNextClick(values);
    resetForm({
      state_id: "",
      name: "",
      enable: "",
      id: ""
    })

  };
  const handleNextClick = (data) => {
    setShowWCAddForm(false);
    setLoaderFlag(true);

    let wcData = {
      state_id: data.state_id,
      name: data.name,
      id: data.id,
      enable: data.enable
    };

    axios.post(apiCalling.coderscorner + `server.php/api/saveStates`, wcData).then((res) => {
    if(res.data.status){
      setFormat('success')
      setsavenextmessage(true)
      setSnackBarMsg(res.data.message)
      console.log(res.data);
      console.log("saved successfully...!!!");
      setShowWCAddForm(false);
      setShowList(true);
      handleStateList();
    }
    })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  };



  const handleOpenWCAddForm = () => {
    setAddFormButtonValue("Submit");
    setShowWCAddForm(true);
    setShowList(false);
    setInitialValue({
      state_id: "",
      name: "",
      id: "",
      enable: ""
    })
  }

  const handleEdit = (data) => {
    setShowWCAddForm(true);
    setAddFormButtonValue("Update");
    setShowList(false);
    setInitialValue({
      ...data,
      state_id: data.state_id,
      name: data.name,
      id: data.id,
      enable: data.enable
    })
  }

  const handleDelete = (item) => {
    
    let data = {
      id: item.id
    }
    axios.post(apiCalling.coderscorner + `server.php/api/deletestates`, data).then((res) => {
      if(res.data.status){
        setFormat("success");
        console.log(res.data)
        setSnackBarMsg(res.data.message);
        setsavenextmessage(true);
        setShowWCAddForm(false);
        setShowList(true);
        handleStateList();
    }
    })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [format, setFormat] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const closeSuccessToast = () => {
      setsavenextmessage(false)
    }



  return (
    <Container maxWidth="lg">

<Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>

      <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>
        {loaderFlag == true && (
          <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}
        {showList == true && loaderFlag == false && (
          <div>
            <div className="content-header">
              <Row>
                <Col md={{ span: 10 }}>
                  <Typography
                    text="State Details"
                    styleType="C"
                  />
                </Col>
                <Col md={{ span: 2 }} >
                  {/* <button className="btn btn-primary" onClick={() => handleOpenWCAddForm()} >
                    Add
                                                 </button> */}
                  <span className="image">
                    <img className='navbutton backbutton' src={blueadd} onClick={() => handleOpenWCAddForm()} />
                    <Tooltip title="Add" arrow>
                      <img className='navgraybutton backbutton' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
            <Row className="datable" style={{ marginTop: '30px', }} >

              <Col md={{ span: 12 }}>
                <div component={Paper}>
                <div>
              <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="StateLinkForm"
                sheet="StateLinkForm"
                buttonText="Export excel"
              />
            </div>
                  <MDBDataTableV5
                    hover 
                                        entriesOptions={[5, 10,20, 25]} 
                                        entries={5} 
                                        pagesAmount={4} 
                                        data={data}
                                        id = "karzaTbl"

                                        // searchTop 
                                        // searchBottom={false}
                  />
                </div>
              </Col>
            </Row>


            <div className='btn_row_fixd'>
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navbutton' src={Back} onClick={handledata} />
                  </Tooltip>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save as Draft & Next" arrow>
                    <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                  </Tooltip>
                </Col> */}
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={Back} onClick={handledata} />
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img className='navgraybutton' src={grayback} onClick={handledata} />
                    </Tooltip>
                  </span>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                    <Tooltip title="Save as Draft & Next" arrow>
                      <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <Row>
          <Col md={{ span: 12 }}>
            {showWCAddForm == true && (
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={stateLinkFormSchema}
                onSubmit={handlesubmitform}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                  /* and other goodies */
                }) => {
                  //  console.log("values in formik", values);
                  return (
                    <form onSubmit={handleSubmit}>

                      <div className="content-header" style={{ position: 'unset' }}>
                        <Row>
                          <Col md={{ span: 10 }}>
                            <Typography
                              text="State"
                              styleType="C"
                            />
                          </Col>

                          <Col md={{ span: 2 }}>
                            <div className="content-header-button ">
                              {/* <Button className="backBtn" variant="outlined" color="primary"
                                onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }} >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                            </Button> */}
                              <span className="image">
                                <img className='backbutton navbutton' src={Back} onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }}/>
                                <Tooltip title="Back" arrow>
                                  <img className='backbutton navgraybutton' src={grayback} onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);
                                }} />
                                </Tooltip>
                              </span>
                            </div>
                          </Col>
                        </Row>


                        <Row style={{ marginTop: '30px' }}>
                          <Col md={{ span: 6 }}>
                            <Typography text="State" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="name"
                                  label="Enter State"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.name &&
                                      touched.name &&
                                      errors.name
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.name ? classes.manorma : null,
                                  }}
                                  value={values.name}
                                  helperText={
                                    errors.name &&
                                    touched.name &&
                                    errors.name
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text="State ID" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="state_id"
                                  label="Enter State ID"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.state_id &&
                                      touched.state_id &&
                                      errors.state_id
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.state_id ? classes.manorma : null,
                                  }}
                                  value={values.state_id}
                                  helperText={
                                    errors.state_id &&
                                    touched.state_id &&
                                    errors.state_id
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <div style={{ marginTop: '20px' }}>
                              <Typography text="Enable" styleType="B" />
                            </div>
                            <List component="div" disablePadding>
                              <ListItem >
                                <label>
                                  Yes
                                                          <input
                                    name="enable"
                                    type="radio"
                                    value="1"
                                    checked={values.enable === '1'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />

                                </label>
                                <div style={{ width: '18px' }}></div>
                                <label>
                                  No
                                                           <input
                                    name="enable"
                                    type="radio"
                                    value="0"
                                    checked={values.enable === '0'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </label>
                              </ListItem>
                              {errors.enable && touched.enable && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.enable}</div>}
                            </List>
                          </Col>
                        </Row>
                        <Col md={{ span: 12 }}>
                          <Row><Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    </Container>

  );
}