import React, { useState } from 'react'
import { loadCSS } from 'fg-loadcss'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TextField, Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Dropdown } from 'semantic-ui-react'
import { useStyle } from './styles'
import Typography from '../../base_components/Typography'
import { Formik, FieldArray } from 'formik'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { bankFormschema, bankschema } from '../../shared/validations'
import axios from 'axios'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import DraftNext from '../../image/buttons/draftnext.png'
import Back from '../../image/buttons/back.png'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { FormikTextField } from 'formik-material-fields'
// Added for opening dialog box
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiAlert from '@material-ui/lab/Alert'
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import grayback from '../../image/buttons/grayback.png'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import addbutton from '../../image/pngicons/plussign.png'
import deleterec from '../../image/pngicons/delete.png'
import manualentry from '../../image/pngicons/manualaddition.png'
import uploaddoc from '../../image/pngicons/upload.png'
import up from '../../image/pngicons/up.png'
import down from '../../image/pngicons/down.png'
import edit from '../../image/pngicons/edit.png'
import digitalpull from '../../image/pngicons/digitalpull.png'
import { AlertTitle } from '@material-ui/lab'
import DocumentUpload from '../../components/DocumentUpload'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import { ErrorCatch, apiCalling } from '../../shared/constants'
import SearchBar from 'material-ui-search-bar'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { FormikTextareaAutosize } from 'formik-material-fields'
import Modal from 'react-bootstrap/Modal'
import FileViewer from 'react-file-viewer'
//import { CustomErrorComponent } from 'custom-error';

import { useField, Field } from 'formik'
import NumberFormat from 'react-number-format'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputBase from '@material-ui/core/InputBase'

import OtpTimer from 'otp-timer'
import InputOtp from '@onefifteen-z/react-input-otp'

const CheckboxWithGreenCheck = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "lightgreen",
        zIndex: -1
      }
    }
  },
  checked: {}
})(Checkbox);

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Banking(props) {
  const file =
    'https://file-examples-com.github.io/uploads/2017/02/file_example_XLSX_10.xlsx'
  const type = 'pdf'

  const classes = useStyle()
  const [header, setHeader] = React.useState(true)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const [bank, setbank] = React.useState([])
  const [nature, setnature] = React.useState([])
  const [checkvalue, setchecked] = React.useState()
  const [checkval, setchecktable] = React.useState('')
  // Add soumen for set business data and promoter data as on 27-11-2020
  const [showPromoterTable, setShowPromoterTable] = React.useState(false)
  const [businessList, setBusinessList] = React.useState([])
  const [promoterList, setPromoterList] = React.useState([])
  const [
    showBusinessPromoterList,
    setShowBusinessPromoterList
  ] = React.useState(true)
  const [showBankAddForm, setShowBankAddForm] = React.useState(false)
  const [
    backBusinessPromoterList,
    setBackBusinessPromoterList
  ] = React.useState(true)
  const [parentInfoData, setParentInfoData] = React.useState()
  const [
    openBusinessBankingHeader,
    setOpenBusinessBankingHeader
  ] = React.useState([])
  const [
    openPromoterBankingHeader,
    setOpenPromoterBankingHeader
  ] = React.useState([])
  const [verifiedIcon, setVerifiedIcon] = useState(false)
  const [editBankData, setEditBankData] = React.useState({
    id: '',
    bankname: '',
    account: '',
    natureacc: '',
    color: '' /* ,
    "accounttype": "" */
  })
  const [headerValue, setHeaderValue] = React.useState('')
  const [
    showParentBankListDetails,
    setShowParentBankListDetails
  ] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const handleChangeAccro = panel => (event, isExpanded) => {
    //
    setExpanded(isExpanded ? panel : false)
  }
  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: '10%'
  }
  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%',
    width: '1%'
  }
  const table_Action = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center'
  }
  const table_noborder = {
    borderBottom: '1px solid #fff'
  }

  const comment = {
    color: 'mediumblue',
    fontSize: '12px',
    paddingBottom: '3px',
    fontWeight: 600,
    fontStyle: 'italic',
    textAlign: 'right'
  }
  // For dynamic form build
  const [noOfColumn, setNoOfColumn] = React.useState([])
  const [noOfColumn2, setNoOfColumn2] = React.useState([])
  const [
    bankingDetailsDynamicInitialValue,
    setBankingDetailsDynamicInitialValue
  ] = React.useState({})
  const [bankingParameterHead, setBankingParameterHead] = React.useState([])
  //Open pop up model theme and variable declare
  const [openDialogBox1, setOpenDialogBox1] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClickOpenDialogBox1 = () => {
    setOpenDialogBox1(true)
  }
  const handleCloseOpenDialogBox1 = () => {
    setOpenDialogBox1(false)
  }

  const [openHDDialogBox1, setOpenHDDialogBox1] = React.useState(false)
  const handleClickOpenHDDialogBox1 = () => {
    setOpenHDDialogBox1(true)
  }
  const handleCloseOpenHDDialogBox1 = () => {
    setOpenHDDialogBox1(false)
  }

  const [loaderFlag, setLoaderFlag] = React.useState(true)
  const [addFormButtonValue, setAddFormButtonValue] = React.useState('Submit')

  const [formDisabled, setFormDisabled] = React.useState(false)
  const role_id = JSON.parse(localStorage.getItem('userdata'))

  const [openCredentialForm, setOpenCredentialForm] = React.useState(false)
  const handleOpenCredentialForm = async val => {
    //
    // setOpenCredentialForm(true);
    ////
    let data = {
      mobile: val
    }
    let api_path =
      apiCalling.identityManagement + `server.php/api/UserSigninForDigitalPull`
    //let api_path2 = "http://164.52.207.42/Identitymanagement/server.php/api/UserSigninForDigitalPull";
    await axios
      .post(api_path, data)
      .then(result => {
        console.log(result.data)
        // //
        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message)
          // //
          // setOtpBtn(true);
          // //
        } else if (result.data.status === 101) {
          setVerifiedIcon(true)
        } else {
          setOtpBtn(true)
          //
          setMobileExistMmsg('')
        }
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }

  const handleCloseOpenCredentialForm = () => {
    setOpenCredentialForm(false)
  }
  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (role_id.role_id == 5) {
      setFormDisabled(true)
    }
    getStartAndEndDate()
    getBankingMonthNo()
    getBankingParameterHead()
    getBusinessAndPromoterListByUser()
    checkapply()
    checktableinfo()
    bankcheck()
    naturecheck()
    checkIfRequest()
    console.clear()
    getBankingPreviewValues()
  }, [])

  localStorage.removeItem('perfiosreturn')

  const [requestDetails, setRequestDetails] = React.useState()
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem('rquestIdDetails')))
  }

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
  let logindata = JSON.parse(localStorage.getItem('userdata'))

  // Add soumen for set business data and promoter data as on 27-11-2020

  /* const getBankingMonthNo = async () => {
    let body = {
      parameter_name: "Banking Month No"
    };
    await axios.post(apiCalling.coderscorner + 'server.php/api/getGeneralSettingByParameter', body).then((result) => {
      let arr = [];
      for (let i = 0; i < result.data.data[0].parameter_value; i++) {
        arr.push(i + 1);
      }
      setNoOfColumn(arr);
    })
  } */

  const [disable, setDisable] = React.useState({
    disabled: true
  })
  const [showConMsg, setShowConMsg] = useState(true)
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true)
  const [promomobile, setPromoMobile] = React.useState()
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false)
  const [shareEnable, setShareEnable] = useState(true)
  const [otpBtn, setOtpBtn] = useState(false)
  const [otpMinutes, setOtpMinutes] = useState()
  const [otpSeconds, setOtpSeconds] = useState()
  const [mobileExistsMsg, setMobileExistMmsg] = useState()
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false)
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false)
  const [mobileNum, setMobileNum] = useState(Number)
  const [mobile, setMobile] = useState()

  /// verifying promoters Mobile number
  const onBlurMobile = e => {
    ////
    let val = e.target.value

    let len = val.toString().length
    // console.log(len);

    if (len == 10) {
      val = e.target.value
      setMobileNum(val)
      // verifyPromoterMobile(val);
      // setPromoMobile(val);
      // remove
      setMobileNum(val)

      handleOpenCredentialForm(val)

      // verifyPromoterMobile(val);
      // setPromoMobile(val);
      // remove
      setHideMobileResenBtn(false)
      setDisable(false)
      setIfMobileOtpInvald(false)
      setOtpBtn(true)
    }
  }
  const verifyPromoterMobile = async mobilenum => {
    //
    let data = {
      type: 'mobile',
      value: mobilenum,
      form_id: 113
    }
    let api_path = apiCalling.coderscorner + `server.php/api/promoterOtp`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/promoterOtp`, data)
      .then(result => {
        console.log(result.data)
        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message)
        } else if (result.data.status == 101) {
          setVerifiedIcon(true)
        } else {
          setOtpBtn(!otpBtn)
          setMobileExistMmsg('')
        }
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }
  /// verifying mobile otp for promoter
  const verifyMobileOtp = e => {
    //
    let eotp = e
    let len = eotp.length
    if (len == 6) {
      let data = {
        value: mobileNum,
        form_id: 2,
        otp: eotp
      }
      localStorage.setItem('otps', JSON.stringify(data))
      //Cookies.set('otps_cookie', JSON.stringify(data));

      //const checkotp = localStorage.getItem("otps");
      //const checkotp=Cookies.get("otps");
      //let getotpcookie = Cookies.get('otps_cookie');
      //console.log(getotpcookie);
      // let otps = JSON.parse(getotpcookie);
      axios
        .post(apiCalling.identityManagement + `server.php/api/verifyOtp`, data)
        .then(result => {
          if (result.data != undefined || result.data != null) {
            //  console.log(result.data);
            if (result.data.status === true) {
              setMobileVerifyIcon(true)
              // setShowTimer(false);
              //setOtpErrorMessage(false);
              setIfMobileOtpInvald(false)
              setHideMobileResenBtn(false)
              setMobileVerifyIcon(true)
              setDisable(false)
              setOpenCredentialForm(true)
              setOpen(false)
              setOtpBtn(false)
            } else if (result.data.status === false) {
              //setOtpErrorMessage(true);
              // setOtpMessage(result.data.message);
              setIfMobileOtpInvald(true)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  //resend otp for email
  const resendOtpEmail = () => {
    let data = {}
    let val = mobile
    data = {
      type: 'mobile',
      value: val,
      form_id: 2
    }
    axios
      .post(apiCalling.identityManagement + `server.php/api/resendOtp`, data)
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getBackToMobile = () => {
    setOtpBtn(false)
    setHideMobileResenBtn(true)
    setMobileVerifyIcon(false)
  }

  const enableShare = () => {
    if (mobileVerifyIcon || emailVerifyIcon) {
      setShareEnable(false)
    }
  }
  ///Otp time as per general setting
  const getOtpTimer = async () => {
    let data = {
      parameter_name: 'OTP TIMER'
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then(result => {
        console.log(result.data.data[0])
        let data = result.data.data[0].parameter_value
        console.log('timer', data)
        convertTimerToInt(data)
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }
  const convertTimerToInt = timer => {
    let newTimer = parseInt(timer)
    var minutes = Math.floor(newTimer / 60)
    var seconds = newTimer - 60 * minutes
    setOtpMinutes(minutes)
    setOtpSeconds(seconds)
  }

  const getBankingMonthNo = async () => {
    //
    await axios
      .get(apiCalling.coderscorner + 'server.php/api/getcalendargrain')
      .then(result => {
        console.log(result)
        if (result.data.status == true) {
          setNoOfColumn(result.data.data)
          setNoOfColumn2(result.data.data)
        }
      })
      .catch(error => {
        //console.log(error);
      })
  }

  const getBankingParameterHead = async () => {
    //
    await axios
      .get(apiCalling.coderscorner + 'server.php/api/getBankingParameterHead')
      .then(result => {
        if (result.data.status == true) {
          let data = result.data.data
          setBankingParameterHead(data.bankingHeader)
          let initialObj = '{'
          for (let i = 0; i < data.bankingHeader.length; i++) {
            initialObj += '"' + data.bankingHeader[i].parameter_head + '":'
            let parameterHead = data.bankingHeader[i].parameter_head
            initialObj += '"' + parameterHead + '_checkbox' + '":' + false + ','
            let paramData = data[parameterHead]
            let obj = '['
            for (let j = 0; j < paramData.length; j++) {
              obj +=
                '{"id":' +
                paramData[j].id +
                ',"parameter_level_1":"' +
                paramData[j].parameter_level_1 +
                '","display_parameter_level_1":"' +
                paramData[j].display_parameter_level_1 +
                '",'
              for (let k = 0; k < noOfColumn.length; k++) {
                obj += '"month' + paramData[j].id + noOfColumn[k].id + '":"",'
              }
              obj = obj.slice(0, -1)
              obj += '},'
            }
            obj = obj.slice(0, -1)
            obj += '],'
            initialObj += obj
          }
          initialObj = initialObj.slice(0, -1)
          initialObj += '}'
          setBankingDetailsDynamicInitialValue(JSON.parse(initialObj))
        }
      })
      .catch(error => {
        //console.log(error)
      })
  }

  const getBusinessAndPromoterListByUser = async () => {
    //
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: checkuserid.user_id
    }
    let api_path =
      apiCalling.coderscorner +
      'server.php/api/getBankingDetailsBusinessAndPromoterById'
    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getBankingDetailsBusinessAndPromoterById',
        data
      )
      .then(result => {
        //console.log("user data", result);
        setShowBankAddForm(false)
        setShowBusinessPromoterList(true)
        setLoaderFlag(false)
        setBusinessList(result.data.data.businessList)
        setNewBus(result.data.data.businessList)
        let openBusinessBankingHeaderArray = []
        for (let i = 0; i < result.data.data.businessList.length; i++) {
          openBusinessBankingHeaderArray.push(false)
        }
        setOpenBusinessBankingHeader(openBusinessBankingHeaderArray)
        if (result.data.data.promoterList.length > 0) {
          setPromoterList(result.data.data.promoterList)
          setShowPromoterTable(true)
        } else {
          setPromoterList([])
          setShowPromoterTable(false)
        }
        let openPromoterBankingHeaderArray = []
        for (let i = 0; i < result.data.data.promoterList.length; i++) {
          openPromoterBankingHeaderArray.push(false)
        }
        setOpenPromoterBankingHeader(openPromoterBankingHeaderArray)
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
        //console.log(error);
      })
  }

  const checkapply = () => {
    if (localStorage.getItem('check')) {
      const checkdata = localStorage.getItem('RequestType'),
        checkform = JSON.parse(checkdata)
      //	//console.log('lets check', checkform)
      setchecked(checkform)
    } else {
      setchecked(1)
    }
  }

  const checktableinfo = () => {
    const checktabledata = localStorage.getItem('checktable'),
      checktables = JSON.parse(checktabledata)
    setchecktable(checktables)
  }
  var bankarray = []
  const bankcheck = async () => {
    const bankdata = {
      token: 'qwerty1234',
      enable: true
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllbank`, bankdata)
      .then(res => {
        res.data.data.map(item => {
          bankarray.push({
            key: item.short_name,
            value: item.id,
            // flag: item.name,
            text: item.name
          })
        })
        setbank(bankarray)
      })
      .catch(er => {
        //console.log("no data  ", er);
      })
  }

  var naturearray = []
  const naturecheck = async () => {
    const naturedata = {
      token: 'qwerty1234',
      type: 'account_types'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        naturedata
      )
      .then(res => {
        res.data.data.map(item => {
          naturearray.push({
            key: item.short_name,
            value: item.id,
            // flag: item.name,
            text: item.name
          })
        })
        setnature(naturearray)
      })
      .catch(er => {
        //console.log("no data  ", er);
      })
  }

  const handlesubmitBankData = (values, { resetForm }) => {
    //
    handleNextClick(values)
    setHeader(true)
    props.handleCount(requestDetails.user_id)
  }

  const handleNextClick = async data => {
    setShowBankAddForm(false)
    setLoaderFlag(true)
    let eligibilityFlag = 0
    if (checkvalue === 1) {
      eligibilityFlag = 1
    }

    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

    let bankloanapplydata = {
      id: data.id,
      business_id: data.parent_id,
      parent_id: data.parent_id,
      parent_type_id: data.parent_type_id,
      bankname: data.bankname,
      accountno: data.account,
      natureacc: data.natureacc,
      edcOrPg: data.color,
      //accounttype: data.submission,
      Token: 'qwerty1234',
      user_id: checkuserid.user_id,
      eligibility_flag: eligibilityFlag
    }
    localStorage.setItem('bankloanapplydata', JSON.stringify(bankloanapplydata))
    //
    let api_path =
      apiCalling.coderscorner + `server.php/api/businessBankingInfo`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/businessBankingInfo`,
        bankloanapplydata
      )
      .then(res => {
        //console.log(res);
        if (res.data.status == true) {
          getBusinessAndPromoterListByUser()
          if (addFormButtonValue == 'Submit') {
            setBankHeaderDetailsForManuallyAdd(res.data.data[0])
            setOpenHDDialogBox1(true)
            setLoaderFlag(false)
            setSnackBarMsg(res.data.message)
            setsavenextmessage(true)
          } else {
            setBankHeaderDetailsForManuallyAdd(res.data.data[0])
            setOpenHDDialogBox1(true)
            setLoaderFlag(false)
            setSnackBarMsg(res.data.message)
            setsavenextmessage(true)
          }
        } else if (res.data.status == false) {
          setSnackBarMsg(res.data.message)
          setsavenextmessage(true)
          setLoaderFlag(false)
          setShowBankAddForm(true)
          setHeader(false)
        }
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          bankloanapplydata,
          checkuserid.user_id,
          checkuserid.user_id
        )
        //console.log("no data sorry ", er);
      })
  }

  const handleSubmiting = () => {
    props.handlecheck()
  }

  const handledata = () => {
    props.handleBack()
  }

  const handlesubmitBankingParameterData = async values => {
    //
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let bankDetailsData = {
      headerId: bankHeaderDetailsForManuallyAdd.id,
      eligibilityFlag: 0,
      user_id: checkuserid.user_id,
      headerDetails: values
    }

    console.log(bankDetailsData)
    let api_path =
      apiCalling.coderscorner + `server.php/api/saveBankingHeaderDetails`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/saveBankingHeaderDetails`,
        bankDetailsData
      )
      .then(res => {
        if (res.data.status == true) {
          setShowBusinessPromoterList(true)
          setHeader(true)
          setShowParentBankListDetails(false)
          getBusinessAndPromoterListByUser()
          setcolorMessage('success')
          setSnackBarMsg(res.data.message)
          setsavenextmessage(true)
        }
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          bankDetailsData,
          checkuserid.user_id,
          checkuserid.user_id
        )
        //console.log("no data sorry ", er);
      })
  }

  const handleOpenBusinessBankingHeader = (flag, i) => {
    let newArray = []
    for (let j = 0; j < openBusinessBankingHeader.length; j++) {
      if (j == i) {
        newArray.push(flag)
      } else {
        newArray.push(openBusinessBankingHeader[j])
      }
    }
    setOpenBusinessBankingHeader(newArray)
  }

  const handleOpenPromoterBankingHeader = (flag, i) => {
    let newArray = []
    for (let j = 0; j < openPromoterBankingHeader.length; j++) {
      if (j == i) {
        newArray.push(flag)
      } else {
        newArray.push(openPromoterBankingHeader[j])
      }
    }
    setOpenPromoterBankingHeader(newArray)
  }

  const handleOpenBankAddForm = data => {
    setHeader(false)
    let parentData = {
      parent_type_id: 1,
      parent_id: data.id,
      parent_name: data.name
    }
    setParentInfoData(parentData)
    setShowBankAddForm(true)
    setAddFormButtonValue('Submit')
    setHeaderValue('Adding')
    setShowBusinessPromoterList(false)
    setEditBankData({
      id: '',
      bankname: '',
      account: '',
      natureacc: '',
      color: '' /* ,
      "accounttype": "" */
    })
  }

  const handleOpenPromoterBankAddForm = data => {
    setHeader(false)
    let parentData = {
      parent_type_id: 2,
      parent_id: data.id,
      parent_name: data.name
    }
    setParentInfoData(parentData)
    setShowBankAddForm(true)
    setAddFormButtonValue('Submit')
    setHeaderValue('Adding')
    setShowBusinessPromoterList(false)
    setEditBankData({
      id: '',
      bankname: '',
      account: '',
      natureacc: '',
      color: '' /* ,
      "accounttype": "" */
    })
  }

  const handleBankEditData = (parent, item) => {
    setHeader(false)
    let parentData = {
      parent_type_id: 1,
      parent_id: parent.id,
      parent_name: parent.name
    }
    setAddFormButtonValue('Update')
    setHeaderValue('Editing')
    setParentInfoData(parentData)
    setEditBankData({
      ...editBankData,
      id: item.id,
      bankname: item.bank_id,
      account: item.account_no,
      natureacc: item.nature_of_account,
      color: item.edc_pg_id.toString() /* ,
      accounttype: Number(item.account_type) */
    })
    setShowBankAddForm(true)
    setShowBusinessPromoterList(false)
  }

  const handlePromoterBankEditData = (parent, item) => {
    setHeader(false)
    let parentData = {
      parent_type_id: 2,
      parent_id: parent.id,
      parent_name: parent.name
    }
    setAddFormButtonValue('Update')
    setHeaderValue('Editing')
    setParentInfoData(parentData)
    setEditBankData({
      ...editBankData,
      id: item.id,
      bankname: item.bank_id,
      account: item.account_no,
      natureacc: item.nature_of_account,
      color: item.edc_pg_id.toString() /* ,
      accounttype: Number(item.account_type) */
    })
    setShowBankAddForm(true)
    setShowBusinessPromoterList(false)
  }

  const [snackBarMsg, setSnackBarMsg] = React.useState()
  const { vertical, horizontal } = SnackPosition

  const [showMessage, setShowMessage] = React.useState()
  const [colorMessage, setcolorMessage] = React.useState()
  //delete Bank by bankId
  const handleBankDeleteData = async id => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      id: id
    }

    //
    let api_path =
      apiCalling.coderscorner + `server.php/api/deleteBankingHeader`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/deleteBankingHeader`,
        data
      )
      .then(res => {
        //console.log("Deleted bank data ", res.data);
        if (res.data.status == true) {
          getBusinessAndPromoterListByUser()
          setShowBusinessPromoterList(true)
          setHeader(true)
          setcolorMessage('success')
          setSnackBarMsg(res.data.message)
          setsavenextmessage(true)
        }
        //	getuserdetailsoflogin();
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
        //console.log("Something went Wrong While Deleting...!!!", er);
      })
  }

  const [
    bankHeaderDetailsForManuallyAdd,
    setBankHeaderDetailsForManuallyAdd
  ] = React.useState()
  const handleManualBankDetailsData = async (parent, item) => {
    //
    setHeader(false)
    setLoaderFlag(true)
    let parentData = {
      parent_type_id: 1,
      parent_id: parent.id,
      parent_name: parent.name
    }
    setParentInfoData(parentData)
    let data = {
      headerId: item.id
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`,
        data
      )
      .then(res => {
        if (res.data.status == true) {
          setLoaderFlag(false)
          let data = res.data.data
          setBankingParameterHead(data.bankingHeader)
          let initialObj = '{'
          for (let i = 0; i < data.bankingHeader.length; i++) {
            let parameterHead = data.bankingHeader[i].parameter_head
            initialObj +=
              '"' +
              data.bankingHeader[i].parameter_head +
              '":' +
              JSON.stringify(data[parameterHead]) +
              ','
          }
          initialObj = initialObj.slice(0, -1)
          initialObj += '}'
          setBankingDetailsDynamicInitialValue(JSON.parse(initialObj))
          console.log("\n \n json 1", JSON.parse(initialObj))
          if (res.data.message.includes('Update')) {
            setcolorMessage('success')
            setSnackBarMsg(res.data.message)
            setsavenextmessage(true)
          }
        }
      })
      .catch(er => {
        ErrorCatch(item.id, er, api_path, 'post', data, item.id, item.id)
        //console.log("no data sorry ", er);
      })
    setBankHeaderDetailsForManuallyAdd(item)
    setShowParentBankListDetails(true)
    setBackBusinessPromoterList(true)
    setShowBusinessPromoterList(false)
    // getDocForPreview1(1, item.id)
  }

  const [docPath, setDocPath] = React.useState('')
  const [docType, setDocType] = React.useState('')
  const getDocForPreview1 = async (ptypeid, parentId) => {
    //
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let data = {
      id: 23,
      user_id: checkuserid.user_id,
      parent_id: parentId,
      parent_type_id: ptypeid,
      form: '1.1.4',
      required_phase: '172'
    }

    await axios
      .post(apiCalling.coderscorner + 'server.php/api/findDocsforPreview', data)
      .then(result => {
        console.log(result)
        let docPat = result.data.data[0].documentArray[0].docPath
        let fileType = result.data.data[0].documentArray[0].file_name.split('.')
        setDocType(fileType[1])
        setDocPath(docPat)
      })
      .catch(er => {
        // ErrorCatch(item.id, er, api_path, 'post', data, item.id, item.id);
        console.log('no data sorry ', er)
      })
  }

  const handlePromoterManualBankDetailsData = async (parent, item) => {
    setHeader(false)
    setLoaderFlag(true)
    let parentData = {
      parent_type_id: 2,
      parent_id: parent.id,
      parent_name: parent.name
    }
    setParentInfoData(parentData)
    let data = {
      headerId: item.id
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`,
        data
      )
      .then(res => {
        if (res.data.status == true) {
          setLoaderFlag(false)
          let data = res.data.data
          setBankingParameterHead(data.bankingHeader)
          let initialObj = '{'
          for (let i = 0; i < data.bankingHeader.length; i++) {
            let parameterHead = data.bankingHeader[i].parameter_head
            initialObj +=
              '"' +
              data.bankingHeader[i].parameter_head +
              '":' +
              JSON.stringify(data[parameterHead]) +
              ','
          }
          initialObj = initialObj.slice(0, -1)
          initialObj += '}'
          setBankingDetailsDynamicInitialValue(JSON.parse(initialObj))
        }
      })
      .catch(er => {
        ErrorCatch(item.id, er, api_path, 'post', data, item.id, item.id)
        //console.log("no data sorry ", er);
      })
    setBankHeaderDetailsForManuallyAdd(item)
    setShowParentBankListDetails(true)
    setBackBusinessPromoterList(true)
    setShowBusinessPromoterList(false)
    //   getDocForPreview1(2, item.id)
  }

  const handleOpenBankingHeaderDetailsOnSave = () => {
    setHeader(false)
    setShowParentBankListDetails(true)
    setBackBusinessPromoterList(true)
    setShowBusinessPromoterList(false)
    setOpenHDDialogBox1(false)
  }

  const SaveasDraft = () => {
    handleSubmiting()
  }

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const [open, setOpen] = React.useState(false)
  //const [bankingHeader,setbankingHeader] = useState();
  const [parentData1, setParentData1] = React.useState()

  const handleOpen = (parent, item, type) => {
    //
    let parentData = {
      parent_type_id: type,
      parent_id: parent.id,
      parent_name: parent.name,
      headerId: item.id,
      user_id: localData.user_id
    }
    setParentData1(parentData)
    localStorage.setItem('perfiosBankData', JSON.stringify(parentData))

    let eligibilityFlag = 0;
    console.log("\n \n data", item, parent)
    let bankloanapplydata = {
      id: item.id,
      business_id: item.parent_id,
      parent_id: item.parent_id,
      parent_type_id: item.parent_type_id,
      bankname: item.bank_name,
      accountno: item.account_no,
      natureacc: item.nature_of_account,
      edcOrPg: item.edc_pg_id,
      //accounttype: data.submission,
      Token: 'qwerty1234',
      user_id: localData.user_id,
      eligibility_flag: eligibilityFlag
    }
    localStorage.setItem('bankloanapplydata', JSON.stringify(bankloanapplydata))

    //setOpen(true);
    // setOpenCredentialForm(false);
  }
  const handleClose = () => {
    setOpen(false)
  }

  const generateTxnId = () => {
    let r = Math.random()
      .toString(36)
      .substring(5)
    //console.log("random", r);
    return r
  }

  const callPerfiosApiOld = async () => {
    //

    let txtnId = generateTxnId()
    let callBackurlLocal =
      apiCalling.frontCaller+ '/hash/bankDataPullResponse?txnId=' + txtnId
    // let callBackurlDev = apiCalling.frontCaller + '/hash/bankDataPullResponse?txnId=' + txtnId;
    let callBackurlDev =
      apiCalling.frontCaller + '/hash/bankDataPullResponse?txnId=' + txtnId
    // let callBackurlDev = 'https://platform.aagey.com/app/hash/bankDataPullResponse?txnId=' + txtnId;
    //console.lo0g(callBackurlLocal)

    // //console.log('http://164.52.207.42/alpha/server.php/apiperofios?app_name=start&&email=sample@gmail.com&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2020-01&&ymt=2020-06&&rUrl='+callBackurlDev+'&&rCallback='+callBackurlDev+'&&lType=business&&dest=choice&&create=Prem&&modify=Prem');

    //console.log(apiCalling.thirdParty + 'server.php/apiperofios?app_name=start&&email=' + logindata.email + '&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2020-01&&ymt=2020-06&&rUrl=' + callBackurlDev + '&&rCallback=' + callBackurlDev + '&&lType=business&&dest=choice&&create=' + localData.user_id + '&&modify=' + localData.user_id + '');

    await axios
      .get(
        apiCalling.thirdParty +
        'server.php/apiperofios?app_name=start&&email=' +
        logindata.email +
        '&&txnId=' +
        txtnId +
        '&&lAmount=12000&&lDuration=6&&ymf=2021-01&&ymt=2021-06&&rUrl=' +
        callBackurlDev +
        '&&rCallback=' +
        callBackurlDev +
        '&&lType=business&&dest=choice&&create=' +
        localData.user_id +
        '&&modify=' +
        localData.user_id +
        ''
      )
      .then(result => {
        if (result.status === 200) {
          //console.log("result vvvvvvvvv", result);
          //   let domainUrl = "http://164.52.207.42";
          let domainUrl = 'http://164.52.207.42'
          // let domainUrl = "https://platform.aagey.com/app/#/";
          console.log(domainUrl)
          let subDomain = result.data.data.result
          let url = domainUrl + subDomain
          window.open(url, '_blank')
          //console.log(result);
          console.log('result---------------->', result)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [startOfMonth, setStartOfMonth] = React.useState('')
  const [endOfMonth, setEndOfMonth] = React.useState('')

  const getStartAndEndDate = async () => {
    //
    let data = {
      parameter_name: ['ymf', 'ymt']
    }

    await axios
      .post(
        apiCalling.thirdParty + 'server.php/api/getGeneralSettingForMultiple',
        data
      )
      .then(result => {
        var dateObj = new Date();
        dateObj.setDate(0)
        var month = ("0" + (dateObj.getMonth() + 1)).slice(-2)
        // var month = dateObj.getMonth() + 1;
        var year = dateObj.getFullYear();
        var endMonth = year + '-' + month;
        var startDateObj = dateObj.setMonth(dateObj.getMonth() - 5);
        var smonth = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        var syear = dateObj.getFullYear();
        var startMonth = syear + '-' + smonth;
        console.log("\n \n stat", startMonth, endMonth)
        // res.ymf.parameter_value
        // res.ymt.parameter_value
        setStartOfMonth(startMonth)
        setEndOfMonth(endMonth)
      })
      .catch(error => {
        console.log(error)
      })
  }

  /// perfios post

  const callPerfiosApi = async () => {
    //

    let txtnId = generateTxnId()
    //let callBackurlLocal = apiCalling.frontCaller+ '/hash/bankDataPullResponse?txnId=' + txtnId;
    // let callBackurlDev = apiCalling.frontCaller + '/hash/bankDataPullResponse?txnId=' + txtnId;
     let callBackurlDev = apiCalling.frontCaller + '/#/bankDataPullResponse?txnId=' + txtnId
    // let callBackurlDev = 'http://0.0.0.0:3000/#/bankDataPullResponse?txnId=' + txtnId
   /// let callBackurlDev = 'https://platform.aagey.com/app/hash/bankDataPullResponse?txnId=' + txtnId;
   console.log(startOfMonth);
   console.log(endOfMonth);

    let data = {
      app_name: 'start',
      email: logindata.email,
      txnId: txtnId,
      lAmount: '12000',
      lDuration: '12',
      ymf: startOfMonth,
      ymt: endOfMonth,
      rUrl: callBackurlDev,
      rCallback: callBackurlDev,
      lType: 'business',
      dest: 'choice',
      create: localData.user_id,
      modify: localData.user_id
    }

    await axios
      .post(apiCalling.thirdParty + 'server.php/apiperofios1', data)
      .then(result => {
        if (result.status === 200) {
          //console.log("result vvvvvvvvv", result);
            //let domainUrl = "http://164.52.207.42";
        let domainUrl = 'http://164.52.207.42'
          //  let domainUrl = "https://platform.aagey.com";
          console.log(domainUrl)
          let subDomain = result.data.data.result
          let url = domainUrl + subDomain
          window.location.replace(url)

          // window.open(url, '_blank');
          // window.top.close();
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const savePerfiofData = () => {
    console.log()
    let data = {}
    axios
      .post('', data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)

  const [showDocScreen, setShowDocScreen] = React.useState(false)

  const [parentTypeId, setParentTypeId] = React.useState(0)
  const [parentId, setParentId] = React.useState(0)
  ///Document update
  let docDetails = {
    formId: '1.1.4',
    parentId: parentId,
    parentTypeId: parentTypeId
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation ctm_set_div'
    >
      <div className='profile_table'>
        <div className='profile_table_part'>
          <table style={{ marginBottom: 30 }}>
            <thead>
              <tr>
                <th>
                  <h3>Upload Documents</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <Button className='ctm_btn_set footer'>Upload Here!</Button>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Add Appointment</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <Button className='ctm_btn_set footer'>
                    Book Appointment
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  )

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const [searched, setSearched] = React.useState('')
  const [newBus, setNewBus] = React.useState()
  const requestSearch = searchedVal => {
    //
    let busArr = businessList
    let tempArr = []
    busArr.filter(row => {
      if (row.name.toLowerCase().includes(searchedVal.toLowerCase())) {
        // if (tempArr.length >= 1) {
        //   tempArr.map((item2) => {
        //     if (item2.id != row.id)
        //       tempArr.push(row);
        //   })
        // } else {
        //   tempArr.push(row);
        // }
        if (tempArr.length > 0) {
          let found = undefined
          found = tempArr.find(item => item.id == row.id)
          if (found == undefined) {
            tempArr.push(row)
          }
        }
      } else {
        tempArr.push(row)
      }
    })
    // busArr.filter((row) => {
    //   row.bankingHeader.filter((item) => {
    //     if (item.bank_name.toLowerCase().includes(searchedVal.toLowerCase())) {
    //       if (tempArr.length >= 1) {
    //         tempArr.map((item2) => {
    //           if (item2.id != row.id)
    //             tempArr.push(row);
    //         })
    //       } else {
    //         tempArr.push(row);
    //       }
    //     }
    //   })
    // });
    // setRows(filteredRows);
    setBusinessList(tempArr)
  }

  const cancelSearch = () => {
    //
    setSearched('')
    setBusinessList(newBus)
  }

  const [showPreview, setShowPreview] = React.useState(false)

  let month = 'month'

  let nmb = '2320210201'
  const [currencyFormat, setCurrencyFormat] = React.useState('lakh')
  const [decimalScale, setDecimalScale] = React.useState(2)
  const [decimalSeparator, setDecimalSeparator] = React.useState('.')

  function NumberField({ field }) {
    return (
      <NumberFormat
        {...field}
        isNumericString={true}
        decimalScale={decimalScale}
        thousandSeparator={true}
        thousandsGroupStyle={currencyFormat}
        allowNegative={false}
        decimalSeparator={decimalSeparator}
      />
    )
  }

  const [banksIdArray, setBanksIdArray] = React.useState([])

  const getAllTheBanksByBusId = headerId => {
    //
    let banksId = []
    businessList.map(item => {
      if (item.id === headerId) {
        if (item.bankingHeader.length > 0) {
          item.bankingHeader.map(item2 => {
            banksId.push(item2.id)
          })
        }
        setBanksIdArray(banksId)
        console.log(banksId)
        bankingDetailsByBankId(banksId)
      }
    })
  }

  const getAllTheBanksByBusIdForPromo = headerId => {
    //

    let banksId = []
    promoterList.map(item => {
      if (item.id === headerId) {
        if (item.bankingHeader.length > 0) {
          item.bankingHeader.map(item2 => {
            banksId.push(item2.id)
          })
        }
        setBanksIdArray(banksId)
        console.log(banksId)
        bankingDetailsByBankId(banksId)
      }
    })
  }

  const [bankListForCalc, setBankListForCalc] = React.useState([])

  const [loderFlag2, setloderFlag2] = React.useState(false)

  const bankingDetailsByBankId = async bankArr => {
    //
    setShowPreview(true)
    setloderFlag2(true)
    let bankDetailArray = []

    for (let i = 0; i < bankArr.length; i++) {
      let data = await returnBankingDetails(bankArr[i])
      bankDetailArray.push(data)
    }

    console.log(bankDetailArray)

    setBankListForCalc(bankDetailArray)

    babkingCalculations(bankDetailArray)
  }

  // calculation part of banking array

  const babkingCalculations = bankDetailArray => {
    //

    let noOfCol = [...noOfColumn2]

    bankDetailArray.map((bank, i) => {
      //if(i==0){

      noOfCol.map((cal, j) => {
        let avg = 0
        bank.Balance_as_on_dates.map((balOnDateArray, k) => {
          let average = balOnDateArray['month' + balOnDateArray.id + cal.id]
          avg += Number(average)
        })
        cal['averageBalance' + i] = (avg / 12).toFixed(2)
      })

      noOfCol.map((cal, j) => {
        let net_inflow = ''
        let gross_inflow = ''
        let no_credit_transaction = ''
        let no_debit_transaction = ''
        let no_outward_return = ''
        let no_inward_return = ''
        let total_emi_or_loan_payments_amount = ''
        bank.Banking_parameters.map((Banking_parameters_Array, k) => {
          if (Banking_parameters_Array.parameter_level_1 == 'net_inflow') {
            net_inflow =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }
          if (Banking_parameters_Array.parameter_level_1 == 'gross_inflow') {
            gross_inflow =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }

          if (
            Banking_parameters_Array.parameter_level_1 ==
            'no_credit_transaction'
          ) {
            no_credit_transaction =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }
          if (
            Banking_parameters_Array.parameter_level_1 == 'no_debit_transaction'
          ) {
            no_debit_transaction =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }

          if (
            Banking_parameters_Array.parameter_level_1 == 'no_inward_return'
          ) {
            no_inward_return =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }
          if (
            Banking_parameters_Array.parameter_level_1 == 'no_outward_return'
          ) {
            no_outward_return =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }
          if (
            Banking_parameters_Array.parameter_level_1 ==
            'total_emi_or_loan_payments_amount'
          ) {
            total_emi_or_loan_payments_amount =
              Banking_parameters_Array[
              'month' + Banking_parameters_Array.id + cal.id
              ]
          }
        })

        cal['net_inflow' + i] = net_inflow
        cal['gross_inflow' + i] = gross_inflow
        cal['no_credit_transaction' + i] = no_credit_transaction
        cal['no_debit_transaction' + i] = no_debit_transaction
        cal['no_outward_return' + i] = no_outward_return
        cal['no_inward_return' + i] = no_inward_return
        cal[
          'total_emi_or_loan_payments_amount' + i
        ] = total_emi_or_loan_payments_amount
      })
    })

    console.log(noOfCol)
    setloderFlag2(false)

    setNoOfColumn2(noOfCol)
  }

  // get banking array by bus or promoter id

  const returnBankingDetails = async id => {
    let jsonObject = []

    let data = {
      headerId: id
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getBankingDetailsByHeaderId`,
        data
      )
      .then(res => {
        if (res.data.status == true) {
          let data = res.data.data
          let initialObj = '{'
          for (let i = 0; i < data.bankingHeader.length; i++) {
            let parameterHead = data.bankingHeader[i].parameter_head
            initialObj +=
              '"' +
              data.bankingHeader[i].parameter_head +
              '":' +
              JSON.stringify(data[parameterHead]) +
              ','
          }
          initialObj = initialObj.slice(0, -1)
          initialObj += '}'
          // setBankingDetailsDynamicInitialValue(JSON.parse(initialObj));
          jsonObject = JSON.parse(initialObj)
        }
      })
      .catch(er => {
        //  ErrorCatch(item.id, er, api_path, 'post', data, item.id, item.id);
        //console.log("no data sorry ", er);
      })

    return jsonObject
  }

  const [bankPreiview, setBankPreview] = React.useState(false)

  /// masking code

  const maskEmail = memail => {
    if (memail != undefined && memail != "" && memail !== null) {
    let mail = memail
    mail = mail.split('')
    let maskedArray = []
    let length = mail.indexOf('@')
    mail.map((item, i) => {
      i >= 1 && i < length - 1
        ? maskedArray.push('*')
        : maskedArray.push(mail[i])
    })
    let finalEmail = maskedArray.join('')
    //setemail(finalEmail);
    // console.log(email);
    return finalEmail
  }else{
    return "";
  }
  }

  const maskMobile = mmobile => {
    if (mmobile != undefined && mmobile != "" && mmobile != null) {
    let mbl = mmobile
    let mblArray = mbl.split('')
    let maskedArray = []
    let len = mblArray.length
    mblArray.map((item, i) => {
      i == 1 || i == 0 || i == len - 1
        ? maskedArray.push(mblArray[i])
        : maskedArray.push('*')
    })
    let finalMobile = maskedArray.join('')
    // setmobile(finalMobile);
    return finalMobile
  }else{
    return "";
  }
  }


  const maskName = mname => {
    if (mname != undefined && mname != "" && mname != null) {
      let name = mname
      name = name.split('')
      let maskedArray = []
      //  let length = name.indexOf('@');
      let length = name.length
      name.map((item, i) => {
        i >= 1 && i < length - 1
          ? maskedArray.push('*')
          : maskedArray.push(name[i])
      })
      let finalName = maskedArray.join('')
      // setName(finalEmail);
      // console.log(finalName);
      return finalName
    } else {
      return "";
    }
  }

  const maskBpan = mbpan => {
    if (mbpan != undefined && mbpan != "" && mbpan != null) {
      let bpan = mbpan
      bpan = bpan.split('')
      let maskedArray = []
      //let length = bpan.indexOf('@');
      let length = bpan.length
      bpan.map((item, i) => {
        i >= 1 && i < length - 1
          ? maskedArray.push('*')
          : maskedArray.push(bpan[i])
      })
      let finalBpan = maskedArray.join('')
      //setBpan(finalEmail);
      // console.log(finalBpan);
      return finalBpan
    } else {
      return "";
    }
  }
  const maskGst = mgst => {
    
    if (mgst != undefined && mgst != "" && mgst !=null) {
      let gst = mgst
      gst = gst.split('')
      let maskedArray = []
      let len = gst.length
      gst.map((item, i) => {
        i == 1 || i == 0 || i == len - 1
          ? maskedArray.push(gst[i])
          : maskedArray.push('*')
      })
      let finalGst = maskedArray.join('')
      //setGst(finalMobile);
      return finalGst
    } else {
      return "";
    }
  }

  const [bankingPreviewArray, setBankingPreviewArray] = React.useState([])
  const getBankingPreviewValues = async () => {
    //
    setLoaderFlag(true)
    let data = {
      parent_id: localData.business_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getPreviewBankDetails',
        data
      )
      .then(result => {
        const { data, message, status } = result.data
        if (status) {
          setBankingPreviewArray(data.bankList)
          setLoaderFlag(false)
        }
        console.log(message)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getLatFileByParentId = async (busOrPro, ptId) => {
    
    let dint = ''
    let data = {
      parent_id: ptId
    }

    let url = apiCalling.coderscorner + 'server.php/api/getLatFileByParentId'

    let response = await axios
      .post(url, data)
      .then(result => {
        console.log(result)
        if (result.data.status) {
          //  setGstPeriod(result.data.data);
          dint = result.data.data
          getUploadeDocOfUserV2(busOrPro, ptId, dint)
        }
      })
      .then(error => {
        console.log(error)
      })
      .catch(error => {
        console.log(error)
      })
    // return dint;
    console.log(response)
  }

  // const getDocForPreview = (busOrPro, ptId) => {
  //     
  //   let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
  //   let data = {
  //     "id": 2,
  //     "user_id": checkuserid.user_id,
  //     "parent_id": ptId,
  //     "parent_type_id": busOrPro,
  //     "form": "1.1.4",
  //     "required_phase": "172"
  //   }
  //   axios.post(apiCalling.coderscorner + 'server.php/api/findDocsforPreview', data).then(result => {
  //     console.log(result);
  //     let docPat = result.data.data[0].documentArray[0].docPath;
  //     let fileType = result.data.data[0].documentArray[0].file_name.split(".");
  //     setDocType(fileType[1]);

  //     setDocPath(docPat);
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }

  // docs preview
  const [documentArray, setDocumentArray] = React.useState([])
  const getUploadeDocOfUserV2 = async (busOrPro, ptId, dint) => {
    
    const docdata = {
      user_id: localData.user_id,
      parent_id: ptId,
      parent_type_id: busOrPro,
      form: '1.1.4',
      required_phase: '172',
      interval_flag: dint
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'
    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2'

    await axios
      .post(apiPath2, docdata)
      .then(result => {
        console.log('rsutl-----------------', result)
        //
        console.log('my document array----->', myArr)
        if (result.data.status) {
          let tempArr = result.data.data
          let resultArr = []
          if (tempArr.length >= 1) {
            tempArr.map(docchecklist => {
              if (docchecklist.documentArray.length > 0) {
                docchecklist.documentArray.map((docs, i) => {
                  if (
                    docs.file_name != '' &&
                    docs.file_name != undefined &&
                    docs.file_name != null
                  ) {
                    let obj = {
                      text: docs.file_name,
                      value: docs.docPath,
                      file_type: docs.file_type
                    }
                    resultArr.push(obj)
                  }
                })
              }
            })
          }
          //
          setDocumentArray(resultArr)
          let fileType = resultArr[0].file_type;
          let fileExt = fileType.split('/');
          if (fileExt[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setDocType('xlsx');
          } else {
            setDocType(fileExt[1]);
          }
          setDocPath(resultArr[0].value);
        }
      })
      .catch(error => {
        ErrorCatch(1, error, api_path, 'post', docdata, 1, 1)
        console.log(error)
      })
  }


  const setPrieviewValues = (value, fileType) => {
    
    let fileExt = fileType.split('/');
    setDocPath(value);
    if (fileExt[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setDocType('xlsx');
    } else {
      setDocType(fileExt[1]);
    }

  };

  return (
    <Container maxWidth='lg'>
      {bankPreiview === false ? (
        <div className='mini-container mg_btm_set'>
          <div>
            <Snackbar
              open={savenextmessage}
              style={{ marginTop: '65px' }}
              autoHideDuration={SnackPosition.duration}
              onClose={closeSuccessToast}
              anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}
            >
              <Alert
                onClose={() => {
                  setsavenextmessage(false)
                }}
                severity='success'
                className='snackBar'
              >
                {snackBarMsg}
              </Alert>
            </Snackbar>
          </div>

          <Row>
            <Col md={{ span: 12 }}>
              {header == true && (
                <div className='content-header'>
                  <Row>
                    {/* { requestDetails && (
                    <Col md={{ span: 12 }} style={{ marginBottom: "3%", textAlign: "center" }}>
                      <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                    </Col>
                  )} */}
                    <Col md={{ span: 12 }} className='mainheader1'>
                      <Typography text='YOUR BANKING DETAILS' styleType='C' />
                    </Col>
                    <Col md={{ span: 12 }} className='subHeader1'>
                      <table className='applicationTableDetails1'>
                        <tr>
                          {/* <td>Type</td>
                          <td>:</td> */}
                          {localData.request_type_id === 0 && (
                            <td>Loan Application</td>
                          )}
                          {localData.request_type_id === 1 && (
                            <td>Check Eligibility</td>
                          )}
                          <td>&nbsp;|&nbsp;</td>
                          <td>Application Number</td>
                          <td>:</td>
                          <td>{localData.reference_id}</td>
                          <td>&nbsp;|&nbsp;</td>
                          <td>Loan Type</td>
                          <td>:</td>
                          <td>{localData.loan_type}</td>
                        </tr>
                      </table>
                    </Col>

                    {showDocScreen === true && (
                      <>
                        <Col nd={{ span: 10 }}></Col>
                        <Col md={{ span: 2 }} className='mainHeaderBack'>
                          {/* <Tooltip title="Back" arrow>
                                <img alt ="pick" style={{ float: "right", width: "25%", height: "83%", margin: "auto" }} src={Back}
                                  onClick={() => { resetForm(); setShowBankAddForm(false); setShowBusinessPromoterList(true); setHeader(true); setExpanded(!expanded); }} />
                              </Tooltip> */}
                          <span className='image'>
                            <img
                              alt='pick'
                              className='backbutton navbutton docbackbtn'
                              src={Back}
                              onClick={() => {
                                setShowBankAddForm(false)
                                setShowBusinessPromoterList(true)
                                setHeader(true)
                                setExpanded(!expanded)
                                setShowDocScreen(false)
                              }}
                            />
                            <Tooltip title='Back' arrow>
                              <img
                                alt='pick'
                                className='backbutton navgraybutton docbackbtn'
                                src={grayback}
                                onClick={() => {
                                  setShowBankAddForm(false)
                                  setShowBusinessPromoterList(true)
                                  setHeader(true)
                                  setExpanded(!expanded)
                                  setShowDocScreen(false)
                                }}
                              />
                            </Tooltip>
                          </span>
                        </Col>
                      </>
                    )}
                    {showDocScreen && (
                      <DocumentUpload formdetails={docDetails} />
                    )}
                  </Row>
                </div>
              )}
            </Col>
          </Row>

          {loaderFlag == true && (
            <div style={{ textAlign: 'center', marginTop: '15%' }}>
              <Loader type={LoaderColor.type} color={LoaderColor.color} />
            </div>
          )}

          {showBusinessPromoterList == true && loaderFlag == false && (
            <div>
              <Typography styleType='SH' text='BUSINESS BANKING DETAILS' />
              <Paper>
              <Row>
                <Col md={{ span: 9 }}></Col>
                <Col md={{ span: 3 }}>
                  <SearchBar
                    className='search_bar'
                    value={searched}
                    onChange={searchVal => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
                </Col>
              </Row>
                
                <TableContainer component={Paper}>
                  <Table
                    aria-label='collapsible table'
                    size='small'
                    style={{ marginBottom: '50px' }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className='tableSLNoHeading'>#</TableCell>
                        <TableCell className='tableBusinessNameHeading'>
                          Business Name
                        </TableCell>
                        <TableCell className='tableGSTHeading'>GST</TableCell>
                        <TableCell className='tablePANHeading'>PAN</TableCell>
                        <TableCell className='tableEntityTypeHeading'>
                          Entity Type
                        </TableCell>
                        <TableCell className='tableStatusHeading'></TableCell>
                        <TableCell className='tableActionHeading'>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {businessList.map((item, i) => (
                        <>
                          <TableRow hover className='datable' key={i + 1}>
                            <TableCell scope='item'>{i + 1}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{maskGst(item.gst)}</TableCell>
                            <TableCell>{maskBpan(item.bpan)}</TableCell>
                            <TableCell>{item.entity_type}</TableCell>
                            {item.bankingHeader.length > 0 && (
                              <Tooltip
                                title={
                                  item.bankingHeader.length +
                                  ' ' +
                                  'Bank Accounts Added'
                                }
                                placement='top'
                              >
                                <TableCell className='tableStatusHeading'>
                                  <span className='countRowNumberNew'>
                                    {item.bankingHeader.length}
                                  </span>
                                </TableCell>
                              </Tooltip>
                            )}
                            {item.bankingHeader.length == 0 && (
                              <Tooltip
                                title='No Bank Accounts Added'
                                placement='top'
                              >
                                <TableCell className='tableStatusHeading'>
                                  <p className='countRowNumberForRed'>
                                    {item.bankingHeader.length}
                                  </p>
                                </TableCell>
                              </Tooltip>
                            )}

                            <TableCell className='tableActionHeading'>
                              {item.bankingHeader.length > 0 && (
                                <Tooltip
                                  title='Open Bank Details'
                                  placement='bottom'
                                >
                                  <Button
                                    aria-label='expand item'
                                    className='actionBtn'
                                    onClick={() => {
                                      handleOpenBusinessBankingHeader(
                                        !openBusinessBankingHeader[i],
                                        i
                                      )
                                      /// getAddedBanksByBusinessId();
                                    }}
                                  >
                                    {openBusinessBankingHeader[i] ? (
                                      <img
                                        alt='pick'
                                        className='actionBtn svgicon'
                                        src={up}
                                      />
                                    ) : (
                                      <img
                                        alt='pick'
                                        className='actionBtn svgicon'
                                        src={down}
                                      />
                                    )}
                                  </Button>
                                </Tooltip>
                              )}
                              <Tooltip title='Add Bank' placement='bottom'>
                                <img
                                  alt='pick'
                                  className='actionBtn svgicon'
                                  src={addbutton}
                                  onClick={() => {
                                    handleOpenBankAddForm(item)
                                  }}
                                />
                              </Tooltip>

                              <Tooltip title='Preview' placement='bottom'>
                                <img
                                  alt='pick'
                                  className='actionBtn svgicon'
                                  src={edit}
                                  onClick={() => {
                                    getAllTheBanksByBusId(item.id)
                                    setBankPreview(true)
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={7}
                            >
                              <Collapse
                                in={openBusinessBankingHeader[i]}
                                timeout='auto'
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  <Table size='small' aria-label='bankList'>
                                    <TableHead
                                      style={{ backgroundColor: '#eee' }}
                                    >
                                      <TableRow>
                                        <TableCell className='tableSLNoHeading'>
                                          #
                                        </TableCell>
                                        <TableCell className='tableBusinessNameHeading'>
                                          Bank Name
                                        </TableCell>
                                        <TableCell className='tableGSTHeading'>
                                          Account Number
                                        </TableCell>
                                        <TableCell className='tablePANHeading'>
                                          Nature Of Account
                                        </TableCell>
                                        <TableCell className='tableEntityTypeHeading'>
                                          EDC/PG
                                        </TableCell>
                                        <TableCell className='tableStatusHeading'>
                                          Status
                                        </TableCell>
                                        <TableCell className='tableActionHeading'>
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item.bankingHeader.map(
                                        (bankingHeader, j) => (
                                          <TableRow
                                            hover
                                            className='datable'
                                            key={j + 1}
                                          >
                                            <TableCell scope='item'>
                                              {j + 1}
                                            </TableCell>
                                            <TableCell>
                                              {bankingHeader.bank_name}
                                            </TableCell>
                                            <TableCell>
                                              {bankingHeader.account_no}
                                            </TableCell>
                                            <TableCell>
                                              {bankingHeader.account_type_name}
                                            </TableCell>
                                            {bankingHeader.edc_pg_id === 1 && (
                                              <TableCell>EDC</TableCell>
                                            )}
                                            {bankingHeader.edc_pg_id === 2 && (
                                              <TableCell>PG</TableCell>
                                            )}
                                            {bankingHeader.edc_pg_id === 3 && (
                                              <TableCell>Both</TableCell>
                                            )}
                                            {bankingHeader.edc_pg_id === 4 && (
                                              <TableCell>None</TableCell>
                                            )}
                                            {bankingHeader.countrow == 0 && (
                                              <TableCell>
                                                <i
                                                  class='far fa-times-circle'
                                                  style={{
                                                    color: 'red',
                                                    fontsize: '12px'
                                                  }}
                                                ></i>
                                              </TableCell>
                                            )}
                                            {bankingHeader.countrow > 0 && (
                                              <TableCell>
                                                <i
                                                  class='fa fa-check-circle'
                                                  style={{
                                                    color: 'green',
                                                    fontsize: '12px'
                                                  }}
                                                ></i>
                                              </TableCell>
                                            )}
                                            <TableCell className='tableActionHeading'>
                                              <Tooltip
                                                title='Edit Bank Data'
                                                placement='bottom'
                                              >
                                                {/* <Button className="actionBtn" onClick={() => { handleBankEditData(item, bankingHeader) }}>
                                            <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                <img
                                                  alt='pick'
                                                  className='actionBtn svgicon'
                                                  src={edit}
                                                  onClick={() => {
                                                    handleBankEditData(
                                                      item,
                                                      bankingHeader
                                                    )
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title='Manual Entry'
                                                placement='bottom'
                                              >
                                                {/* <Button className="actionBtn" onClick={() => { handleManualBankDetailsData(item, bankingHeader) }}>
                                            <i className="fas fa-user-edit"
                                              style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                <img
                                                  alt='pick'
                                                  className='actionBtn svgicon'
                                                  src={manualentry}
                                                  onClick={() => {
                                                    handleManualBankDetailsData(
                                                      item,
                                                      bankingHeader
                                                    )
                                                    // getDocForPreview(1,bankingHeader.id)
                                                    // getUploadeDocOfUserV2(1,bankingHeader.id);
                                                    getLatFileByParentId(
                                                      1,
                                                      bankingHeader.id
                                                    )
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title='Digital Pull'
                                                placement='bottom'
                                              >
                                                {/* <Button className="actionBtn" onClick={() => { handleOpen() }}>
                                            <i className="fas fa-download" style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                <img
                                                  alt=' jh'
                                                  className='actionBtn svgicon'
                                                  src={digitalpull}
                                                  onClick={() => {
                                                    handleOpen(
                                                      item,
                                                      bankingHeader,
                                                      1
                                                    )
                                                    setOpen(true)
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title='Upload Document'
                                                placement='bottom'
                                              >
                                                <img
                                                  alt='pick'
                                                  className='actionBtn svgicon'
                                                  src={uploaddoc}
                                                  onClick={() => {
                                                    setShowBankAddForm(false)
                                                    setShowBusinessPromoterList(
                                                      false
                                                    )
                                                    setShowDocScreen(true)
                                                    setParentTypeId(1)
                                                    setParentId(
                                                      bankingHeader.id
                                                    )
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title='Delete Bank'
                                                placement='bottom'
                                              >
                                                {/* <Button className="actionBtn" onClick={() => { handleBankDeleteData(bankingHeader) }} >
                                            <i className="fas fa-minus"
                                              style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                <img
                                                  alt='pick'
                                                  className='actionBtn svgicon'
                                                  src={deleterec}
                                                  onClick={() => {
                                                    handleBankDeleteData(
                                                      bankingHeader.id
                                                    )
                                                  }}
                                                />
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Typography styleType='SH' text='PROMOTER BANKING DETAILS' />
              {showPromoterTable == false && (
                <Alert severity='warning' className='warningMsg1'>
                  <AlertTitle>
                    <p style={{ fontSize: '16px', color: '#663C00' }}>
                      No Promoters have been Added to this Business. Click here
                      to Add Promoter
                      Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </AlertTitle>
                  <Button
                    size='small'
                    className='choicebtn'
                    onClick={() => {
                      props.handleBack(3)
                    }}
                  >
                    Add Promoter
                  </Button>
                  &nbsp;&nbsp;
                </Alert>
              )}
              {showPromoterTable == true && (
                <Paper>
                  <SearchBar
                    className='search_bar'
                    value={searched}
                    onChange={searchVal => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <TableContainer>
                    <Table aria-label='collapsible table' size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell className='tableSLNoHeading'>#</TableCell>
                          <TableCell className='tableBusinessNameHeading'>
                            Promoter Name
                          </TableCell>
                          {/* <TableCell style={table_header}>Business Name</TableCell> */}
                          <TableCell className='tableGSTHeading'>
                            Email
                          </TableCell>
                          <TableCell className='tablePANHeading'>
                            Mobile
                          </TableCell>
                          <TableCell className='tableStatusHeading'></TableCell>
                          <TableCell className='tableActionHeading'>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {promoterList.map((item, i) => (
                          <>
                            <TableRow hover className='datable' key={i + 1}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>
                                {maskName(item.first_name) +
                                  ' ' +
                                  maskName(item.last_name)}
                              </TableCell>
                              {/* <TableCell>{item.business_name}</TableCell> */}
                              <TableCell>{maskEmail(item.email)}</TableCell>
                              <TableCell>{maskMobile(item.mobile)}</TableCell>
                              {item.bankingHeader.length > 0 && (
                                <Tooltip
                                  title={
                                    item.bankingHeader.length +
                                    ' ' +
                                    'Bank Accounts Added'
                                  }
                                  placement='top'
                                >
                                  <TableCell className='tableStatusHeading'>
                                    <p className='countRowNumberNew'>
                                      {item.bankingHeader.length}
                                    </p>
                                  </TableCell>
                                </Tooltip>
                              )}
                              {item.bankingHeader.length == 0 && (
                                <Tooltip
                                  title='No Bank Accounts Added'
                                  placement='top'
                                >
                                  <TableCell className='tableStatusHeading'>
                                    <p className='countRowNumberForRed'>
                                      {item.bankingHeader.length}
                                    </p>
                                  </TableCell>
                                </Tooltip>
                              )}
                              <TableCell className='tableActionHeading'>
                                {item.bankingHeader.length > 0 && (
                                  <Tooltip
                                    title='Open Bank Details'
                                    placement='bottom'
                                  >
                                    <Button
                                      aria-label='expand item'
                                      className='actionBtn'
                                      onClick={() =>
                                        handleOpenPromoterBankingHeader(
                                          !openPromoterBankingHeader[i],
                                          i
                                        )
                                      }
                                    >
                                      {openPromoterBankingHeader[i] ? (
                                        <img
                                          alt='pick'
                                          className='actionBtn svgicon'
                                          src={up}
                                        />
                                      ) : (
                                        <img
                                          alt='pick'
                                          className='actionBtn svgicon'
                                          src={down}
                                        />
                                      )}
                                    </Button>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  title='Add Bank Details'
                                  placement='bottom'
                                >
                                  {/* <Button className="actionBtn" onClick={() => handleOpenPromoterBankAddForm(item)}>
                              <i className="fas fa-plus" style={{ fontSize: "15px" }} />
                            </Button> */}
                                  <img
                                    alt='pick'
                                    className='actionBtn svgicon'
                                    src={addbutton}
                                    onClick={() => {
                                      handleOpenPromoterBankAddForm(item)
                                    }}
                                  />
                                </Tooltip>

                                <Tooltip title='Add Bank' placement='bottom'>
                                  <img
                                    alt='pick'
                                    className='actionBtn svgicon'
                                    src={addbutton}
                                    onClick={() => {
                                      getAllTheBanksByBusIdForPromo(item.id)
                                      setBankPreview(true)
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={7}
                              >
                                <Collapse
                                  in={openPromoterBankingHeader[i]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Table size='small' aria-label='bankList'>
                                      <TableHead
                                        style={{ backgroundColor: '#eee' }}
                                      >
                                        <TableRow>
                                          <TableCell className='tableSLNoHeading'>
                                            #
                                          </TableCell>
                                          <TableCell className='tableBusinessNameHeading'>
                                            Bank Name
                                          </TableCell>
                                          <TableCell className='tableGSTHeading'>
                                            Account Number
                                          </TableCell>
                                          <TableCell className='tablePANHeading'>
                                            Nature Of Account
                                          </TableCell>
                                          <TableCell className='tableEntityTypeHeading'>
                                            EDC/PG
                                          </TableCell>
                                          <TableCell className='tableStatusHeading'>
                                            Status
                                          </TableCell>
                                          <TableCell className='tableActionHeading'>
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {item.bankingHeader.map(
                                          (bankingHeader, j) => (
                                            <TableRow
                                              hover
                                              className='datable'
                                              key={j + 1}
                                            >
                                              <TableCell>{j + 1}</TableCell>
                                              <TableCell>
                                                {bankingHeader.bank_name}
                                              </TableCell>
                                              <TableCell>
                                                {bankingHeader.account_no}
                                              </TableCell>
                                              <TableCell>
                                                {
                                                  bankingHeader.account_type_name
                                                }
                                              </TableCell>
                                              {bankingHeader.edc_pg_id == 1 && (
                                                <TableCell className='tableStatusHeading'>
                                                  EDC
                                                </TableCell>
                                              )}
                                              {bankingHeader.edc_pg_id == 2 && (
                                                <TableCell className='tableStatusHeading'>
                                                  PG
                                                </TableCell>
                                              )}
                                              {bankingHeader.edc_pg_id == 3 && (
                                                <TableCell className='tableStatusHeading'>
                                                  Both
                                                </TableCell>
                                              )}
                                              {bankingHeader.edc_pg_id == 4 && (
                                                <TableCell className='tableStatusHeading'>
                                                  None
                                                </TableCell>
                                              )}
                                              {bankingHeader.countrow == 0 && (
                                                <TableCell className='tableStatusHeading'>
                                                  <i
                                                    class='far fa-times-circle'
                                                    style={{
                                                      color: 'red',
                                                      fontSize: '12px'
                                                    }}
                                                  ></i>
                                                </TableCell>
                                              )}
                                              {bankingHeader.countrow > 0 && (
                                                <TableCell className='tableStatusHeading'>
                                                  <i
                                                    class='fa fa-check-circle'
                                                    style={{
                                                      color: 'green',
                                                      fontSize: '12px'
                                                    }}
                                                  ></i>
                                                </TableCell>
                                              )}
                                              <TableCell className='tableActionHeading'>
                                                <Tooltip
                                                  title='Edit Bank Data'
                                                  placement='bottom'
                                                >
                                                  {/* <Button className="actionBtn" onClick={() => { handlePromoterBankEditData(item, bankingHeader) }}>
                                            <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                  <img
                                                    alt='pick'
                                                    className='actionBtn svgicon'
                                                    src={edit}
                                                    onClick={() => {
                                                      handlePromoterBankEditData(
                                                        item,
                                                        bankingHeader
                                                      )
                                                      // getDocForPreview(2,bankingHeader.id)
                                                      // getUploadeDocOfUserV2(2,bankingHeader.id);
                                                      getLatFileByParentId(
                                                        2,
                                                        bankingHeader.id
                                                      )
                                                    }}
                                                  />
                                                </Tooltip>
                                                <Tooltip
                                                  title='Manual Entry'
                                                  placement='bottom'
                                                >
                                                  {/* <Button className="actionBtn" onClick={() => { handlePromoterManualBankDetailsData(item, bankingHeader) }}>
                                            <i className="fas fa-user-edit"
                                              style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                  <img
                                                    alt='pick'
                                                    className='actionBtn svgicon'
                                                    src={manualentry}
                                                    onClick={() => {
                                                      handlePromoterManualBankDetailsData(
                                                        item,
                                                        bankingHeader
                                                      )
                                                    }}
                                                  />
                                                </Tooltip>
                                                <Tooltip
                                                  title='Digital Pull'
                                                  placement='bottom'
                                                >
                                                  {/* <Button className="actionBtn" onClick={() => { handleOpen() }}>
                                            <i className="fas fa-download" style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                  <img
                                                    alt=' jh'
                                                    className='actionBtn svgicon'
                                                    src={digitalpull}
                                                    onClick={() => {
                                                      handleOpen(
                                                        item,
                                                        bankingHeader,
                                                        2
                                                      )
                                                      setOpen(true)
                                                    }}
                                                  />
                                                  {/* onClick={() => { handleOpen() }}  */}
                                                </Tooltip>
                                                <Tooltip
                                                  title='Upload Document'
                                                  placement='bottom'
                                                >
                                                  <img
                                                    alt='pick'
                                                    className='actionBtn svgicon'
                                                    src={uploaddoc}
                                                    onClick={() => {
                                                      setHeader(true)
                                                      setShowBankAddForm(false)
                                                      setShowBusinessPromoterList(
                                                        false
                                                      )
                                                      setShowDocScreen(true)
                                                      setParentTypeId(1)
                                                      setParentId(
                                                        bankingHeader.id
                                                      )
                                                    }}
                                                  />
                                                </Tooltip>
                                                <Tooltip
                                                  title='Delete Bank'
                                                  placement='bottom'
                                                >
                                                  {/* <Button className="actionBtn" >
                                            <i className="fas fa-minus"
                                              style={{ fontSize: "15px" }} />
                                          </Button> */}
                                                  <img
                                                    alt='pick'
                                                    className='actionBtn svgicon'
                                                    src={deleterec}
                                                    onClick={() => {
                                                      handleBankDeleteData(
                                                        bankingHeader.id
                                                      )
                                                    }}
                                                  />
                                                </Tooltip>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
              <div className='btn_row_fixd'>
                <Row>
                  <Col md={{ span: 10 }}></Col>
                  {/* className="btnspace" */}
                  <Col style={{ padding: '0%' }}></Col>
                  <Col style={{ padding: '0%' }}></Col>
                  {/* <Col style={{ padding: "0%" }}>
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img alt ="pick" className='navbutton' src={Back} onClick={handledata} />
                    </Tooltip>
                  </Col> */}
                  <Col style={{ padding: '0%' }}>
                    <span className='image'>
                      <img
                        alt='pick'
                        className='navbutton'
                        src={Back}
                        onClick={handledata}
                      />
                      <Tooltip
                        title='Back'
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.customArrow
                        }}
                        arrow
                      >
                        <img
                          alt='pick'
                          className='navgraybutton'
                          src={grayback}
                          onClick={handledata}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                  {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Reset" arrow>
                    <img alt ="pick" className='navbutton' src={Reset} />
                  </Tooltip>
                </Col> */}
                  {/*  <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save & Next" arrow>
                    <img alt ="pick" className='navbutton' src={SaveNext} />
                  </Tooltip>
                </Col> */}
                  {/* <Col style={{ padding: "0%" }}>
                    <Tooltip title="Save as Draft & Next" arrow>
                      <img alt ="pick" className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                    </Tooltip>
                  </Col> */}
                  <Col style={{ padding: '0%' }}>
                    <span className='image'>
                      <img
                        alt='pick'
                        className='navbutton'
                        src={DraftNext}
                        onClick={SaveasDraft}
                      />
                      <Tooltip title='Save & Next' arrow>
                        <img
                          alt='pick'
                          className='navgraybutton'
                          src={graydraftnext}
                          onClick={SaveasDraft}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {showBankAddForm == true && (
            <Formik
              enableReinitialize
              initialValues={
                checkval !== 'Business'
                  ? {
                    id: editBankData.id,
                    bankname: editBankData.bankname,
                    account: editBankData.account,
                    natureacc: editBankData.natureacc,
                      /* submission: editBankData.accounttype, */ color:
                      editBankData.color,
                    parent_type_id: parentInfoData.parent_type_id,
                    parent_id: parentInfoData.parent_id
                  }
                  : {
                    id: editBankData.id,
                    bankname: editBankData.bankname,
                    account: editBankData.account,
                    natureacc: editBankData.natureacc,
                      /* submission: editBankData.accounttype,  */ color:
                      editBankData.color,
                    parent_type_id: parentInfoData.parent_type_id,
                    parent_id: parentInfoData.parent_id
                  }
              }
              validationSchema={
                checkval == 'Business' ? bankFormschema : bankschema
              }
              onSubmit={handlesubmitBankData}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                resetForm,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{ span: 10 }} className='mainheader1'>
                        {parentInfoData.parent_type_id == 1 && (
                          <Typography
                            text={
                              headerValue +
                              ' Bank Account for Business: ' +
                              parentInfoData.parent_name
                            }
                            styleType='C'
                          />
                        )}
                        {parentInfoData.parent_type_id == 2 && (
                          <Typography
                            text={
                              headerValue +
                              ' Bank Account for Promoter : ' +
                              parentInfoData.parent_name
                            }
                            styleType='C'
                          />
                        )}
                      </Col>
                      {backBusinessPromoterList == true && (
                        <Col md={{ span: 2 }} className='mainHeaderBack'>
                          {/* <Tooltip title="Back" arrow>
                                <img alt ="pick" style={{ float: "right", width: "25%", height: "83%", margin: "auto" }} src={Back}
                                  onClick={() => { resetForm(); setShowBankAddForm(false); setShowBusinessPromoterList(true); setHeader(true); setExpanded(!expanded); }} />
                              </Tooltip> */}
                          <span className='image'>
                            <img
                              alt='pick'
                              className='backbutton navbutton'
                              src={Back}
                              onClick={() => {
                                resetForm()
                                setShowBankAddForm(false)
                                setShowBusinessPromoterList(true)
                                setHeader(true)
                                setExpanded(!expanded)
                              }}
                            />
                            <Tooltip title='Back' arrow>
                              <img
                                alt='pick'
                                className='backbutton navgraybutton'
                                src={grayback}
                                onClick={() => {
                                  resetForm()
                                  setShowBankAddForm(false)
                                  setShowBusinessPromoterList(true)
                                  setHeader(true)
                                  setExpanded(!expanded)
                                }}
                              />
                            </Tooltip>
                          </span>
                        </Col>
                      )}
                      <Col md={{ span: 12 }} className='subHeader1'>
                        <table className='applicationTableDetails1'>
                          <tr>
                            {/* <td>Type</td>
                                  <td>:</td> */}
                            {localData.request_type_id === 0 && (
                              <td>Loan Application</td>
                            )}
                            {localData.request_type_id === 1 && (
                              <td>Check Eligibility</td>
                            )}
                            <td>&nbsp;|&nbsp;</td>
                            <td>Application Number</td>
                            <td>:</td>
                            <td>{localData.reference_id}</td>
                            <td>&nbsp;|&nbsp;</td>
                            <td>Loan Type</td>
                            <td>:</td>
                            <td>{localData.loan_type}</td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                    <div>
                      {
                        <div>
                          <Row>
                            <Col md={{ span: 6 }}>
                              <Typography text=' Bank Name' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder='Select Bank Name'
                                    id='bankname'
                                    name='bankname'
                                    fluid
                                    search
                                    selection
                                    value={values.bankname}
                                    onBlur={() =>
                                      setFieldTouched('bankname', true)
                                    }
                                    onChange={(e, { value }) =>
                                      setFieldValue('bankname', value)
                                    }
                                    options={bank}
                                    className={
                                      errors.bankname && touched.bankname
                                        ? classes.required
                                        : values.bankname == ''
                                          ? classes.reqired
                                          : classes.man
                                    }
                                    disabled={formDisabled}
                                  />
                                </ListItem>
                              </List>
                              {errors.bankname &&
                                touched.bankname &&
                                values.bankname == '' && (
                                  <div className='banfFormValid'>
                                    {errors.bankname}
                                  </div>
                                )}
                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography text='Account Number' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Account Number'
                                    name='account'
                                    onChange={handleChange}
                                    //onBlur={handleBlur}
                                    onBlur={handleBlur}
                                    inputProps={{ maxLength: 20 }}
                                    InputProps={{
                                      className: values.account
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.account &&
                                        touched.account &&
                                        errors.account
                                        ? true
                                        : false
                                    }
                                    value={values.account}
                                    helperText={
                                      errors.account &&
                                      touched.account &&
                                      errors.account
                                    }
                                    disabled={formDisabled}
                                  />
                                </ListItem>
                              </List>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography
                                text='Nature of Account'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder='Select Nature of Account'
                                    id='natureacc'
                                    name='natureacc'
                                    fluid
                                    search
                                    selection
                                    value={values.natureacc}
                                    onBlur={() =>
                                      setFieldTouched('natureacc', true)
                                    }
                                    onChange={(e, { value }) =>
                                      setFieldValue('natureacc', value)
                                    }
                                    options={nature}
                                    className={
                                      errors.natureacc && touched.natureacc
                                        ? classes.required
                                        : values.natureacc == ''
                                          ? classes.reqired
                                          : classes.man
                                    }
                                    disabled={formDisabled}
                                  />
                                </ListItem>
                              </List>
                              {errors.natureacc &&
                                touched.natureacc &&
                                values.natureacc == '' && (
                                  <div className='banfFormValid'>
                                    {errors.natureacc}
                                  </div>
                                )}
                            </Col>
                            <Col md={{ span: 6 }}>
                              <div style={{ marginTop: '20px' }}>
                                <Typography text='EDC / PG' styleType='B' />
                              </div>
                              <List component='div' disablePadding>
                                <ListItem>
                                  <label>
                                    Both
                                    <input
                                      name='color'
                                      type='radio'
                                      value='3'
                                      checked={values.color === '3'}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </label>
                                  <div style={{ width: '18px' }}></div>
                                  <label>
                                    EDC
                                    <input
                                      name='color'
                                      type='radio'
                                      value='1'
                                      checked={values.color === '1'}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </label>
                                  <div style={{ width: '18px' }}></div>
                                  <label>
                                    PG
                                    <input
                                      name='color'
                                      type='radio'
                                      value='2'
                                      checked={values.color === '2'}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </label>
                                  <div style={{ width: '18px' }}></div>
                                  <label>
                                    None
                                    <input
                                      name='color'
                                      type='radio'
                                      value='4'
                                      checked={values.color === '4'}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </label>
                                </ListItem>
                                {errors.color && touched.color && (
                                  <div className='banfFormValid'>
                                    {errors.color}
                                  </div>
                                )}
                              </List>
                            </Col>

                            {/* <Col md={{ span: 6 }}>
                          <Typography text="Account Type" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Account Type"
                                id="submission"
                                name="submission"
                                fluid
                                search
                                selection
                                value={values.submission}
                                onBlur={() => setFieldTouched("submission", true)}
                                onChange={(e, { value }) => setFieldValue("submission", value)}
                                options={nature}
                                className={errors.submission && touched.submission ? classes.required : values.submission == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.submission && touched.submission && values.submission == '' && (
                            <div className="banfFormValid">{errors.submission}</div>
                          )}
                        </Col> */}
                            <Col md={{ span: 6 }}></Col>
                            <Col md={{ span: 12 }}>
                              <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col md={{ span: 2 }}>
                                  <Button
                                    className='updatebtn'
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    onClick={handleSubmit}
                                  >
                                    {addFormButtonValue}
                                  </Button>
                                </Col>
                                <Col md={{ span: 5 }}></Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      }
                    </div>
                  </form>
                )
              }}
            </Formik>
          )}

          {showParentBankListDetails == true && (
            <>
              <Formik
                enableReinitialize
                initialValues={bankingDetailsDynamicInitialValue}
                onSubmit={handlesubmitBankingParameterData}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  resetForm,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={{ span: 12 }}>
                          <div className='content-headers'>
                            <Row>
                              <Col md={{ span: 10 }} className='mainheader1'>
                                {parentInfoData.parent_type_id == 1 && (
                                  <Typography
                                    text={
                                      'Editing / Adding Bank Account Details for Business - ' +
                                      parentInfoData.parent_name
                                    }
                                    styleType='C'
                                  />
                                )}
                                {parentInfoData.parent_type_id == 2 && (
                                  <Typography
                                    text={
                                      'Editing / Adding Bank Account Details for Promoter - ' +
                                      parentInfoData.parent_name
                                    }
                                    styleType='C'
                                  />
                                )}
                              </Col>
                              <Col md={{ span: 2 }} className='mainHeaderBack'>
                                {backBusinessPromoterList === true && (
                                  // <Button className="backBtn" variant="outlined" color="primary"
                                  //   onClick={() => {
                                  //     //
                                  //     resetForm();
                                  //     setShowBusinessPromoterList(true);
                                  //     setShowParentBankListDetails(false);
                                  //     setHeader(true);
                                  //     setExpanded(false);
                                  //   }} >
                                  //   <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                  // </Button>
                                  <span className='image'>
                                    <img
                                      alt='pick'
                                      className='backbutton navbutton'
                                      src={Back}
                                      onClick={() => {
                                        resetForm()
                                        setShowBusinessPromoterList(true)
                                        setShowParentBankListDetails(false)
                                        setHeader(true)
                                        setExpanded(false)
                                      }}
                                    />
                                    <Tooltip title='Back' arrow>
                                      <img
                                        alt='pick'
                                        className='backbutton navgraybutton'
                                        src={grayback}
                                        onClick={() => {
                                          resetForm()
                                          setShowBusinessPromoterList(true)
                                          setShowParentBankListDetails(false)
                                          setHeader(true)
                                          setExpanded(false)
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                              </Col>
                              <Col md={{ span: 12 }} className='subHeader1'>
                                <table className='applicationTableDetails1'>
                                  <tr>
                                    {/* <td>Type</td>
                            <td>:</td> */}
                                    {localData.request_type_id === 0 && (
                                      <td>Loan Application</td>
                                    )}
                                    {localData.request_type_id === 1 && (
                                      <td>Check Eligibility</td>
                                    )}
                                    <td>&nbsp;|&nbsp;</td>
                                    <td>Application Number</td>
                                    <td>:</td>
                                    <td>{localData.reference_id}</td>
                                    <td>&nbsp;|&nbsp;</td>
                                    <td>Loan Type</td>
                                    <td>:</td>
                                    <td>{localData.loan_type}</td>
                                  </tr>
                                </table>
                              </Col>
                            </Row>
                            <Row className='subheader2'>
                              <Col md={{ span: 5 }}>
                                <Typography text='Bank Name : ' />
                                &nbsp;&nbsp;
                                <Typography
                                  text={
                                    bankHeaderDetailsForManuallyAdd.bank_name
                                  }
                                  styleType='B'
                                />
                              </Col>
                              <Col md={{ span: 5 }}>
                                <Typography text='Account Number : ' />
                                &nbsp;&nbsp;
                                <Typography
                                  text={
                                    bankHeaderDetailsForManuallyAdd.account_no
                                  }
                                  styleType='B'
                                />
                              </Col>

                              <Col
                                md={{ span: 2 }}
                                style={{ marginTop: '01px' }}
                              >
                                <Typography
                                  text={
                                    <i
                                      class='fas fa-eye'
                                      onClick={() => {
                                        //
                                        setShowPreview(!showPreview)
                                      }}
                                    ></i>
                                  }
                                />
                                &nbsp;&nbsp;
                              </Col>
                              {/* <Col md={{ span: 3 }}>
                              <Typography text="Nature Of Account : " styleType="B" />&nbsp;&nbsp;
                              <Typography text={bankHeaderDetailsForManuallyAdd.account_type_name} styleType="B" />
                            </Col>
                            <Col md={{ span: 3 }}><Typography text="EDC/PG : " styleType="B" />&nbsp;&nbsp;
                              {bankHeaderDetailsForManuallyAdd.edc_pg_i == 1 && (
                                <Typography text="EDC" styleType="B" />
                              )}
                              {bankHeaderDetailsForManuallyAdd.edc_pg_id == 2 && (
                                <Typography text="PG" styleType="B" />
                              )}
                              {bankHeaderDetailsForManuallyAdd.edc_pg_id == 3 && (
                                <Typography text="Both" styleType="B" />
                              )}
                              {bankHeaderDetailsForManuallyAdd.edc_pg_id == 4 && (
                                <Typography text="None" styleType="B" />
                              )}
                            </Col> */}
                            </Row>
                          </div>

                          {showPreview === false ? (
                            <>
                              <div style={{ width: '100px' }}>
                                {false && (
                                  <FormControl className={classes.margin}>
                                    <Select
                                      labelId='demo-customized-select-label'
                                      id='demo-customized-select'
                                      value={currencyFormat}
                                      onChange={e => {
                                        setCurrencyFormat(e.target.value)
                                      }}
                                      input={<BootstrapInput />}
                                      className='select_lakh'
                                    >
                                      <MenuItem value={'lakh'}>Lakh</MenuItem>
                                      <MenuItem value={'thousand'}>
                                        Thousand
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </div>
                              <div style={comment}>
                                Please enter as on date account balances and
                                relevant banking parameters from the list below
                              </div>
                              <div id="addBank">
                                {
                                  <div
                                    className={classes.root}
                                    style={{ marginTop: '-0%' }}
                                  >
                                    {bankingParameterHead.map((item, i) => (
                                      <Accordion
                                        expanded={
                                          expanded === item.parameter_head
                                        }
                                        onChange={handleChangeAccro(
                                          item.parameter_head
                                        )}
                                      >
                                        <AccordionSummary
                                          //style={{ backgroundColor: '#eee' }}
                                          className='accordian_head'
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls='panel1bh-content'
                                          id='panel1bh-header'
                                        >
                                          <Typography
                                            styleType='B'
                                            text={item.display_parameter_head}
                                            //styleType='accordianTitle'
                                          />
                                        </AccordionSummary>
                                        <AccordionDetails
                                          style={{ margin: '1px 0' }}
                                        >
                                          <FieldArray
                                            name={item.parameter_head}
                                          >
                                            <TableContainer>
                                              <Table
                                                aria-label='collapsible table'
                                                size='small'
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell
                                                      style={table_header}
                                                    >
                                                      Month Day
                                                    </TableCell>
                                                    {noOfColumn.map(
                                                      (item, i) => (
                                                        <TableCell
                                                          style={table_header}
                                                        >
                                                          {item.label}
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {values[
                                                    item.parameter_head
                                                  ].map((item1, k) => (
                                                    <TableRow key={k}>
                                                      <TableCell
                                                        style={table_noborder}
                                                      >
                                                        
                                                        {
                                                          item1.display_parameter_level_1
                                                        }
                                                        {role_id.role_id == 3 && (item1.display_parameter_level_1.includes('comment') || item1.display_parameter_level_1.includes('Comment'))  ? (
                                                          
                                                          <FormControlLabel
                                                            control={
                                                              <CheckboxWithGreenCheck
                                                                color="primary"
                                                                defaultChecked
                                                              />
                                                            }
                                                          />
                                                        ) : null}
                                                      </TableCell>
                                                      {/* <input type="hidden" name={`parameterData.${i}.paramId`} /> */}
                                                      {
                                                        //item1.display_flag == "n" ? ():()
                                                      }

                                                      {noOfColumn.map(
                                                        (item2, j) => (
                                                          <TableCell
                                                            style={
                                                              table_noborder
                                                            }
                                                          >
                                                            {item1.display_flag ==
                                                              'n' && (
                                                                <FormikTextField
                                                                  id='filled-error-helper-text standard-basic' /*  label={"Month " + (j + 1)} */
                                                                  name={`${item.parameter_head}.${k}.month${item1.id}${item2.id}`}
                                                                  margin='normal'
                                                                  inputProps={{
                                                                    maxLength: 50
                                                                  }}
                                                                  className='bankTxt'
                                                                  onChange={(e) => {
                                                                    
                                                                    console.log(e);
                                                                  }}
                                                                  disabled={
                                                                    formDisabled
                                                                  }
                                                                  type='number'
                                                                />
                                                              )}

                                                            {item1.display_flag ==
                                                              'd' && (
                                                                <FormikTextField
                                                                  id='filled-error-helper-text standard-basic' /*  label={"Month " + (j + 1)} */
                                                                  name={`${item.parameter_head}.${k}.month${item1.id}${item2.id}`}
                                                                  margin='normal'
                                                                  inputProps={{
                                                                    maxLength: 50
                                                                  }}
                                                                  className='bankTxt'
                                                                  disabled={
                                                                    formDisabled
                                                                  }
                                                                  type='date'
                                                                />
                                                              )}

                                                            {item1.display_flag ==
                                                              'c' && (
                                                                <FormikTextField
                                                                  aria-label='empty textarea'
                                                                  name={`${item.parameter_head}.${k}.month${item1.id}${item2.id}`}
                                                                  margin='normal'
                                                                  inputProps={{
                                                                    maxLength: 50
                                                                  }}
                                                                  className='bankTxt1'
                                                                  disabled={
                                                                    formDisabled
                                                                  }
                                                                  component='textarea'
                                                                  rows='2'
                                                                />
                                                              )}
                                                          </TableCell>
                                                        )
                                                      )}
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </FieldArray>
                                        </AccordionDetails>
                                      </Accordion>
                                    ))}
                                  </div>
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col md={{ span: 6 }}>
                                  <div className='text-left'>
                                    <div className='wrapper'>
                                      <table
                                        border='1'
                                        className='styled-table-bank styled-table2'
                                      >
                                        <tr className='tr_header'>
                                          <th className='bank_preview_header sticky-col first-col second_header'>
                                            No of Months
                                          </th>
                                          {noOfColumn.map(item => (
                                            <th className='bank_preview_header second_header'>
                                              {item.label}
                                            </th>
                                          ))}
                                        </tr>
                                        <tbody>
                                          {values[
                                            bankingParameterHead[0]
                                              .parameter_head
                                          ].map((item1, k) => (
                                            <>
                                              <tr>
                                                <td className='bank_preview_header sticky-col first-col2 second_header'>
                                                  {
                                                    item1.display_parameter_level_1
                                                  }
                                                </td>
                                                {noOfColumn.map((item2, j) => (
                                                  <td>
                                                    {item1.display_flag ==
                                                      'n' && (
                                                        <FormikTextField
                                                          id='filled-error-helper-text standard-basic'
                                                          name={`Balance_as_on_dates.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt'
                                                          disabled={formDisabled}
                                                          type='number'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}

                                                    {item1.display_flag ==
                                                      'd' && (
                                                        <FormikTextField
                                                          id='filled-error-helper-text standard-basic'
                                                          name={`Balance_as_on_dates.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt'
                                                          disabled={formDisabled}
                                                          type='date'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}

                                                    {item1.display_flag ==
                                                      'c' && (
                                                        <FormikTextField
                                                          aria-label='empty textarea'
                                                          name={`Balance_as_on_dates.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt1'
                                                          disabled={formDisabled}
                                                          component='textarea'
                                                          rows='2'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}
                                                  </td>
                                                ))}
                                              </tr>
                                            </>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  md={{ span: 6 }}

                                >
                                  <Dropdown
                                    placeholder='Select Document'
                                    name='area'
                                    fluid
                                    search



                                    selection
                                    //onBlur={() => setFieldTouched("area", true)}
                                    onChange={async (
                                      e,
                                      { value, text, file_type, options }
                                    ) => {
                                      //
                                      console.log(options)
                                      if (options.length > 1) {
                                        options.map(item => {
                                          if (item.value === value) {
                                            setPrieviewValues(
                                              value,
                                              item.file_type
                                            )
                                          }
                                        })
                                      }
                                    }}
                                    options={documentArray}
                                  />

                                  <FileViewer
                                    fileType={docType}
                                    filePath={docPath}
                                    //  errorComponent={CustomErrorComponent}
                                    className='banking_fileviewer'
                                    style={{
                                      height: '90% ',

                                      width: '40%'

                                    }}
                                  />
                                </Col>

                                <Col
                                  md={{ span: 7 }}
                                  style={{ marginTop: '5%' }}
                                >
                                  {/*
                                    <TableContainer component={Paper}>
                                      <Table border="1" sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell style={table_header}>Month Day</TableCell>
                                            {noOfColumn.map((item, i) => (
                                              <TableCell style={table_header}>{item.label}</TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {values['Banking_parameters'].map((item1, k) => (
                                            <TableRow key={k}>
                                              <TableCell style={table_noborder}>{item1.display_parameter_level_1}</TableCell>
                                           

                                              {noOfColumn.map((item2, j) => (

                                                <TableCell style={table_noborder}>

                                                  {item1.display_flag == "n" && (


                                                    <FormikTextField
                                                      id="filled-error-helper-text standard-basic" 
                                                      name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                      margin="normal"
                                                      inputProps={{ maxLength: 50 }}
                                                      className="bankTxt"
                                                      disabled={formDisabled}
                                                      type="number"
                                                    />
                                                  )
                                                  }


                                                  {item1.display_flag == "d" && (
                                                    <FormikTextField id="filled-error-helper-text standard-basic" 
                                                      name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                      margin="normal"
                                                      inputProps={{ maxLength: 50 }}
                                                      className="bankTxt"
                                                      disabled={formDisabled}
                                                      type="date"
                                                    />
                                                  )}

                                                  {item1.display_flag == "c" && (
                                                    <FormikTextField aria-label="empty textarea"

                                                      name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                      margin="normal"
                                                      inputProps={{ maxLength: 50 }}
                                                      className="bankTxt1"
                                                      disabled={formDisabled}
                                                      component="textarea"
                                                      rows="2"
                                                    />
                                                  )}

                                                </TableCell>
                                              ))}
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>

                                                  */}
                                  <div className='text-left'>
                                    <div className='wrapper'>
                                      <table
                                        border='1'
                                        className='styled-table-bank styled-table2'
                                      >
                                        <tr className='tr_header'>
                                          <th className='bank_preview_header sticky-col first-col second_header'>
                                            No of Months
                                          </th>
                                          {noOfColumn.map(item => (
                                            <th className='bank_preview_header second_header '>
                                              {item.label}
                                            </th>
                                          ))}
                                        </tr>
                                        <tbody>
                                          {values[
                                            bankingParameterHead[1]
                                              .parameter_head
                                          ].map((item1, k) => (
                                            <>
                                              <tr>
                                                <td className='bank_preview_header sticky-col first-col2 second_header'>
                                                  <div
                                                    style={{
                                                      wordWrap: 'break-word'
                                                    }}
                                                  >
                                                    {
                                                      item1.display_parameter_level_1
                                                    }
                                                  </div>
                                                </td>
                                                {noOfColumn.map((item2, j) => (
                                                  <td>
                                                    {item1.display_flag ==
                                                      'n' && (
                                                        <FormikTextField
                                                          id='filled-error-helper-text standard-basic' /*  label={"Month " + (j + 1)} */
                                                          name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt'
                                                          disabled={formDisabled}
                                                          type='number'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}

                                                    {item1.display_flag ==
                                                      'd' && (
                                                        <FormikTextField
                                                          id='filled-error-helper-text standard-basic' /*  label={"Month " + (j + 1)} */
                                                          name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt'
                                                          disabled={formDisabled}
                                                          type='date'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}

                                                    {item1.display_flag ==
                                                      'c' && (
                                                        <FormikTextField
                                                          aria-label='empty textarea'
                                                          name={`Banking_parameters.${k}.month${item1.id}${item2.id}`}
                                                          margin='normal'
                                                          inputProps={{
                                                            maxLength: 50
                                                          }}
                                                          className='bankTxt1'
                                                          disabled={formDisabled}
                                                          component='textarea'
                                                          rows='2'
                                                          InputProps={{
                                                            disableUnderline: true
                                                          }}
                                                        />
                                                      )}
                                                  </td>
                                                ))}
                                              </tr>
                                            </>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  )
                }}
              </Formik>
            </>
          )}

          <Dialog
            disableBackdropClick
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={() => {
                handleClose()
                setOtpBtn(false)
              }}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Digital Pull
              </Modal.Title>
            </Modal.Header>
            <DialogTitle id='alert-dialog-title'>
              {otpBtn === false ? (
                <p className='modaltexts'>
                  {' '}
                  Please Enter Mobile Number to continue ?{' '}
                </p>
              ) : (
                <p className='modaltexts'>Please Enter OTP to continue </p>
              )}
            </DialogTitle>
            <div>
              <DialogContent>
                {showConMsg && (
                  <DialogActions style={{ padding: '0px' }}>
                    <Col md={{ span: 6 }}>
                      <Typography styleType='B' className='promoter_mobile' />{' '}
                      {otpBtn && (
                        <span className='hidden_number'>
                          {/* You entered : {values.mobile} */}
                        </span>
                      )}
                      {verifiedIcon === true && (
                        <i
                          className='fas fa-check-circle'
                          style={{ fontSize: '15px', color: 'green' }}
                        ></i>
                      )}
                      <List
                        component='div'
                        disablePadding
                        style={{ marginTop: '-20px', marginLeft: '-10rem' }}
                      >
                        {otpBtn == false && (
                          <>
                            <ListItem style={{ marginLeft: '-20%' }}>
                              {/* <PhoneInput country="in"
                                        enableSearch={false} onChange={(val) => selectCountry(val)} /> */}
                              <TextField
                                id='filled-error-helper-text standard-basic '
                                style={{ width: '30%' }}
                                label='Enter your Mobile No'
                                name='mobile'
                                type='number'
                                onChange={onBlurMobile}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <span style={{ color: 'red', fontSize: '10px' }}>
                                {mobileExistsMsg}
                              </span>
                            </ListItem>
                          </>
                        )}

                        {otpBtn === true && (
                          <>
                            <ListItem>
                              <InputOtp
                                onChange={verifyMobileOtp}
                                style={{ marginLeft: '-15px' }}
                              />
                              {hideMobileResenBtn == true && (
                                <OtpTimer
                                  seconds={otpSeconds}
                                  minutes={otpMinutes} //otpMinutes
                                  style={{ color: 'blue' }}
                                  resend={resendOtpEmail}
                                  text=' '
                                  buttonColor={'white'}
                                  background={'#007bff'}
                                />
                              )}
                              <Button
                                onClick={getBackToMobile}
                                style={{ marginTop: '-1%' }}
                              >
                                <i
                                  className='far fa-edit'
                                  style={{ fontSize: '15px' }}
                                ></i>
                              </Button>

                              {mobileVerifyIcon === true && (
                                <i
                                  className='fas fa-check-circle'
                                  style={{ fontSize: '17px', color: 'green' }}
                                ></i>
                              )}
                              {ifMobileOtpInvald === true && (
                                <span
                                  style={{
                                    color: 'red',
                                    marginLeft: '-28em',
                                    marginTop: '38px'
                                  }}
                                >
                                  Invalid Otp{' '}
                                </span>
                              )}
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Col>
                    {
                      // 1 == true && (
                      //   <Button onClick={() => {
                      //     setOtpBtn(true);
                      //     setHideMobileResenBtn(true);
                      //     setDisable(true);
                      //  //  handleOpenCredentialForm();
                      //    setShowConMsg(false);
                      //  }}
                      //    className="backBtn"
                      //    variant="outlined"
                      //    disabled={disable}
                      //    color="primary" style={{ marginRight: "2%", cursor: "pointer" }}>Continue</Button>
                      // )
                    }
                  </DialogActions>
                )}
              </DialogContent>
            </div>
          </Dialog>

          <Dialog
            disableBackdropClick
            open={openCredentialForm}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={handleClose}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Digital Pull
              </Modal.Title>
            </Modal.Header>
            <MuiDialogTitle>
              {handleCloseOpenCredentialForm ? (
                <IconButton
                  aria-label='close'
                  className={classes.closeButton}
                  onClick={handleCloseOpenCredentialForm}
                >
                  {/* <CloseIcon style={{marginTop:"-10px",color:"white",fontWeight:"800",cursor:"pointer"}}/> */}
                </IconButton>
              ) : null}
            </MuiDialogTitle>
            <DialogTitle id='alert-dialog-title'>
              <p className='modaltext'>
                {' '}
                Please click Continue to be redirected to your bank portal
              </p>
            </DialogTitle>
            <div className='dialogBtn'>
              <DialogContent>
                <div style={{ marginBottom: '2%' }}>
                  <Button
                    onClick={handleCloseOpenCredentialForm}
                    className='backBtn backBtn3'
                    variant='outlined'
                    color='primary'
                  >
                    No
                  </Button>

                  <Button
                    onClick={() => callPerfiosApi()}
                    className='backBtn backBtn3'
                    variant='outlined'
                    color='primary'
                    style={{ marginRight: '2%' }}
                  >
                    Continue
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          <Dialog
            disableBackdropClick
            open={openHDDialogBox1}
            onClose={handleCloseOpenHDDialogBox1}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            PaperProps={{
              style: {
                border: '1px solid'
              }
            }}
          >
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={handleClose}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Banking Details
              </Modal.Title>
            </Modal.Header>
            <DialogContent>
              <DialogContentText
                id='alert-dialog-description'
                style={{ fontSize: '16px', margin: '1% -1%' }}
              >
                Do you want to enter the banking details for this account?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleCloseOpenHDDialogBox1}
                className='backBtn'
                variant='outlined'
                color='primary'
              >
                No
              </Button>

              <Button
                onClick={handleOpenBankingHeaderDetailsOnSave}
                className='backBtn'
                variant='outlined'
                color='primary'
                style={{ marginRight: '2%' }}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div className='mini-container mg_btm_set'>
          <Row>
            <Col md={{ span: 12 }}>
              <span className='image'>
                <img
                  alt='pick'
                  style={{ width: '4%' }}
                  className='backbutton navbutton'
                  src={Back}
                  onClick={() => {
                    setBankPreview(false)
                  }}
                />
                <Tooltip title='Back' arrow>
                  <img
                    alt='pick'
                    style={{ width: '4%' }}
                    className='backbutton navgraybutton'
                    src={grayback}
                    onClick={() => {
                      setBankPreview(false)
                    }}
                  />
                </Tooltip>
              </span>
            </Col>

            {loderFlag2 == true && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '15%',
                  marginLeft: '49%'
                }}
              >
                <Loader type={LoaderColor.type} color={LoaderColor.color} />
              </div>
            )}

            {bankingPreviewArray.map((item, i) => (
              <Col md={{ span: 12 }}>
                <table border='1' className='styled-table bank-table banklist'>
                  {i != bankingPreviewArray.length - 1 ? (
                    <caption className='bank_caption'>
                      Bank Name : {item.name} , Account Number :{' '}
                      {item.account_no}
                    </caption>
                  ) : (
                    <caption className='bank_caption'>Total Banking</caption>
                  )}

                  <tr>
                    <th style={{ fontWeight: '900' }}> Months</th>

                    {i != bankingPreviewArray.length - 1
                      ? item.monthly_details[0].map(days => (
                        <th style={{ fontWeight: '900' }}>
                          {days.display_parameter_level_1}
                        </th>
                      ))
                      : item.total[0].map(days => (
                        <th style={{ fontWeight: '900' }}>
                          {days.parameter_level_1}
                        </th>
                      ))}
                  </tr>

                  <tbody>
                    {i != bankingPreviewArray.length - 1
                      ? item.monthly_details.map((item2, j) => (
                        <tr>
                          <td style={{ fontWeight: '900' }}>
                            {item2[0].label}
                          </td>

                          {item2.length > 0
                            ? item2.map((item3, k) => (
                              <>
                                <td>{item3.values}</td>
                              </>
                            ))
                            : null}
                        </tr>
                      ))
                      : item.total.map((item2, j) => (
                        <tr>
                          <td style={{ fontWeight: '900' }}>
                            {item2[0].month_name}
                          </td>

                          {item2.length > 0
                            ? item2.map((item3, k) => (
                              <>
                                <td>{item3.total_value}</td>
                              </>
                            ))
                            : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  )
}
