
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  listitem: {
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
  },
  listitems: {
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: 20,

  },
  button_up: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 230,
    width: 10
  },
  Button: {
    showIcon: true,
  },

  textfield: {
    width: '100%'
  },

  linkdin: {
    backgroundColor: "#007bff",
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_div: {
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_others: {
    textTransform: "capitalize",
    fontSize: "14px",
    margin: "10px",
    width: "100%"
  },

  uploadB: {
    backgroundColor: "#ffb307f2",
    textTransform: "uppercase",
    boxShadow: "1px 3px 7px 4px #b0dbeb6b",
  },
  manorma: {
    '&:before': {
      borderBottom: "1.5px solid #56B537 !important",
    }
  },
  required: {
    border: "1.5px solid red !important",
  },
  man: {
    border: "1.5px solid #56B537 !important",
  },
  selectError: {
    color: "#f44336",
    marginBottom: "0",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "-5px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em"
    }
}));


export const useStylesDropzone = makeStyles(theme => createStyles({
  root: {
    minHeight: "150%"
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));
