import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listitem: {
    color: "black",
    fontSize: "14px",
    fontWeight: "500",
  },
  listitems: {
    color: "black",
    fontSize: "18px",
    fontWeight: "500",
    marginLeft: 20,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 230,
    width: 10,
  },
  Button: {
    showIcon: true,
  },
  textfield: {
    width: "100%",
  },
  linkdin: {
    backgroundColor: "#007bff",
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_div: {
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },

  uploadB: {
    backgroundColor: "#ffb307f2",
    textTransform: "uppercase",
    boxShadow: "1px 3px 7px 4px #b0dbeb6b",
  },
  button_up: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
  },
  button_others: {
    textTransform: "capitalize",
    fontSize: "14px",
    // margin: "10px",
    width: "100%"
  },
  button_other: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
    marginRight: "-30px"
  },
  button_ups: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
    display: "none",
  },
  input: {
    // display: 'none',
    marginBottom: 25,
  },
  inputs: {
    padding: "7px",
  },
  manorma: {
    '&:before': {
      borderBottom: "1.5px solid #56B537 !important",
    }
  },

  shub: {
    '&:before': {
      borderBottom: "1.5px solid #cccccc !important",
    }
  },
  required: {
    border: "1.5px solid red !important",
  },
  man: {
    border: "1.5px solid #56B537 !important",
  },
  mano: {
    border: "1.5px solid #56B537 !important",
    color: '#777777'
  },
  reqired: {
    border: "1.5px solid #cccccc !important",
  },
  selectError: {
    color: "#f44336",
    marginBottom: "0",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "-5px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em"
    }
}));

