import React, { useState, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import {Field} from 'formik';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FormControlLabel, Switch} from '@material-ui/core';
import moment from 'moment';

import Typography from '../../base_components/Typography';

import logo from "../../image/logo.png";

import { apiCalling } from '../../shared/constants';

const AppSummaryPreview = () => {

    const [localData1, setLocaData1] = React.useState({});
    const checkuserloggeddata = localStorage.getItem("rquestIdDetails");
    let iduser = JSON.parse(checkuserloggeddata);

    React.useEffect(() => {

        setLocaData1(JSON.parse(localStorage.getItem('rquestIdDetails')));
        financeRatios();
        handleManualDataAdd();
        getNoOfColumnOrYear();
        getAllPromoter();
        getAllBusinessLoan();
        getBusinessbybusinessId();
        getFinRatios();

    }, []);

    // get details of business by id
    const [businessData, setBusinessData] = React.useState({});
    const getBusinessbybusinessId = async (bussinessid) => {
        //
        let getbusinesid = {
            "bus_id": iduser.business_id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`, getbusinesid).then((res) => {
            if (res.data.status) {
                
                setBusinessData(res.data.data);

            }



        })
            .catch((er) => {
                //	ErrorCatch(idofuser, er, api_path, 'post', getbusinesid, idofuser, idofuser);
                ////console.log("no pincode sorry ", er);
            });
    }



    // get finance data 
    const [plAndBl, setPlAndBl] = React.useState(false);
    const [financeParameterDetails, setFinanceParameterDetails] = React.useState([]);
    const handleManualDataAdd = async (item, promoOrBus) => {
        

        let parentData = {
            'parent_type_id': 1,
            "loanType": 1,
            'parent_id': iduser.business_id,
            'parent_name': iduser.business_name
        }

        await axios.post(apiCalling.coderscorner + `server.php/api/getFinanceHeaderParameterDetailsByParentId`, parentData).then(result => {
            console.log(result);
            if (result.data.status == true) {
                setFinanceParameterDetails(result.data.data);
                console.log("test", financeParameterDetails);
                setPlAndBl(true)
            }
        })
    }

    // get no of month bases on the loan
    let fyear = "year";
    let fval = "value";
    const [noOfColumn, setNoOfColumn] = React.useState([]);
    let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
    const getNoOfColumnOrYear = async () => {
        // 

        let data = {
            'loanType': localData.business_channel_id
        }
        await axios.post(apiCalling.coderscorner + 'server.php/api/getLast3FinanceYear', data).then((result) => {
            if (result.data.status == true) {
                console.log(result);
                setNoOfColumn(result.data.data);
            }
        })
    }

    
    const [promoters, setPromoters] = React.useState(false);
    const financeRatios = async () => {

        // 
        let data = [
            {
                "parameter_head": "1PnL",
                "display_parameter_head": "Profit Loss Accountsss",
                "headerDetails": [
                    {
                        "parameter_level_1": "10TaxPaid",
                        "display_parameter_level_1": "Tax Paid",
                        "label1Details": [
                            {
                                "value": 22,
                                "label": "Tax Paid"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 22,
                                "label": "Tax Paid"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 22,
                                "display_parameter_level_2": "Tax Paid",
                                "year1": "2021-2022",
                                "year1value": "81",
                                "year2": "2020-2021",
                                "year2value": "81"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "1TotalIncome",
                        "display_parameter_level_1": "Total Income",
                        "label1Details": [
                            {
                                "value": 41,
                                "label": "Commission & Brokerage"
                            },
                            {
                                "value": 45,
                                "label": "Freight charges"
                            },
                            {
                                "value": 43,
                                "label": "Job work charges"
                            },
                            {
                                "value": 46,
                                "label": "Other Operating Income"
                            },
                            {
                                "value": 44,
                                "label": "Professional Fees"
                            },
                            {
                                "value": 40,
                                "label": "Sales - Deemed Exports"
                            },
                            {
                                "value": 39,
                                "label": "Sales - Direct Exports"
                            },
                            {
                                "value": 38,
                                "label": "Sales Local"
                            },
                            {
                                "value": 42,
                                "label": "Service Charges"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 38,
                                "label": "Sales Local"
                            },
                            {
                                "value": 39,
                                "label": "Sales - Direct Exports"
                            },
                            {
                                "value": 40,
                                "label": "Sales - Deemed Exports"
                            },
                            {
                                "value": 41,
                                "label": "Commission & Brokerage"
                            },
                            {
                                "value": 42,
                                "label": "Service Charges"
                            },
                            {
                                "value": 43,
                                "label": "Job work charges"
                            },
                            {
                                "value": 44,
                                "label": "Professional Fees"
                            },
                            {
                                "value": 45,
                                "label": "Freight charges"
                            },
                            {
                                "value": 46,
                                "label": "Other Operating Income"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 38,
                                "display_parameter_level_2": "Sales Local",
                                "year1": "2021-2022",
                                "year1value": "68",
                                "year2": "2020-2021",
                                "year2value": "6999999"
                            },
                            {
                                "id": 39,
                                "display_parameter_level_2": "Sales - Direct Exports",
                                "year1": "2021-2022",
                                "year1value": "9",
                                "year2": "2020-2021",
                                "year2value": "9"
                            },
                            {
                                "id": 40,
                                "display_parameter_level_2": "Sales - Deemed Exports",
                                "year1": "2021-2022",
                                "year1value": "5",
                                "year2": "2020-2021",
                                "year2value": "68"
                            },
                            {
                                "id": 41,
                                "display_parameter_level_2": "Commission & Brokerage",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 42,
                                "display_parameter_level_2": "Service Charges",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "89"
                            },
                            {
                                "id": 43,
                                "display_parameter_level_2": "Job work charges",
                                "year1": "2021-2022",
                                "year1value": "99",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 44,
                                "display_parameter_level_2": "Professional Fees",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "799"
                            },
                            {
                                "id": 45,
                                "display_parameter_level_2": "Freight charges",
                                "year1": "2021-2022",
                                "year1value": "9",
                                "year2": "2020-2021",
                                "year2value": "9"
                            },
                            {
                                "id": 46,
                                "display_parameter_level_2": "Other Operating Income",
                                "year1": "2021-2022",
                                "year1value": "9",
                                "year2": "2020-2021",
                                "year2value": "99"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "2DirectCost",
                        "display_parameter_level_1": "Direct Cost",
                        "label1Details": [
                            {
                                "value": 105,
                                "label": "Closing Stock"
                            },
                            {
                                "value": 107,
                                "label": "Consumable Stores & Spares"
                            },
                            {
                                "value": 47,
                                "label": "Cost of Goods Sold"
                            },
                            {
                                "value": 49,
                                "label": "Direct manufacturing Cost"
                            },
                            {
                                "value": 109,
                                "label": "Factory Rent"
                            },
                            {
                                "value": 104,
                                "label": "Opening Stock"
                            },
                            {
                                "value": 111,
                                "label": "Other Manufacturing Expenses"
                            },
                            {
                                "value": 108,
                                "label": "Power & Fuel"
                            },
                            {
                                "value": 106,
                                "label": "Purchases"
                            },
                            {
                                "value": 110,
                                "label": "Repairs & Maintenance"
                            },
                            {
                                "value": 48,
                                "label": "Wages & Salary"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 47,
                                "label": "Cost of Goods Sold"
                            },
                            {
                                "value": 48,
                                "label": "Wages & Salary"
                            },
                            {
                                "value": 49,
                                "label": "Direct manufacturing Cost"
                            },
                            {
                                "value": 104,
                                "label": "Opening Stock"
                            },
                            {
                                "value": 105,
                                "label": "Closing Stock"
                            },
                            {
                                "value": 106,
                                "label": "Purchases"
                            },
                            {
                                "value": 107,
                                "label": "Consumable Stores & Spares"
                            },
                            {
                                "value": 108,
                                "label": "Power & Fuel"
                            },
                            {
                                "value": 109,
                                "label": "Factory Rent"
                            },
                            {
                                "value": 110,
                                "label": "Repairs & Maintenance"
                            },
                            {
                                "value": 111,
                                "label": "Other Manufacturing Expenses"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 47,
                                "display_parameter_level_2": "Cost of Goods Sold",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 48,
                                "display_parameter_level_2": "Wages & Salary",
                                "year1": "2021-2022",
                                "year1value": "7878",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 49,
                                "display_parameter_level_2": "Direct manufacturing Cost",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "87"
                            },
                            {
                                "id": 104,
                                "display_parameter_level_2": "Opening Stock",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 105,
                                "display_parameter_level_2": "Closing Stock",
                                "year1": "2021-2022",
                                "year1value": "87",
                                "year2": "2020-2021",
                                "year2value": "878"
                            },
                            {
                                "id": 106,
                                "display_parameter_level_2": "Purchases",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 107,
                                "display_parameter_level_2": "Consumable Stores & Spares",
                                "year1": "2021-2022",
                                "year1value": "78",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 108,
                                "display_parameter_level_2": "Power & Fuel",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 109,
                                "display_parameter_level_2": "Factory Rent",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 110,
                                "display_parameter_level_2": "Repairs & Maintenance",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 111,
                                "display_parameter_level_2": "Other Manufacturing Expenses",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "8"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "3IndirectCost",
                        "display_parameter_level_1": "Indirect Cost",
                        "label1Details": [
                            {
                                "value": 56,
                                "label": "Administrative Expenses"
                            },
                            {
                                "value": 54,
                                "label": "Electricity Expenses"
                            },
                            {
                                "value": 51,
                                "label": "Insurance Cost"
                            },
                            {
                                "value": 103,
                                "label": "Office Rent"
                            },
                            {
                                "value": 53,
                                "label": "Printing & Stationery"
                            },
                            {
                                "value": 50,
                                "label": "Salary & Employee Benefits"
                            },
                            {
                                "value": 55,
                                "label": "Selling and distribution expenses"
                            },
                            {
                                "value": 52,
                                "label": "Travelling & Conveyance"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 50,
                                "label": "Salary & Employee Benefits"
                            },
                            {
                                "value": 51,
                                "label": "Insurance Cost"
                            },
                            {
                                "value": 54,
                                "label": "Electricity Expenses"
                            },
                            {
                                "value": 56,
                                "label": "Administrative Expenses"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 50,
                                "display_parameter_level_2": "Salary & Employee Benefits",
                                "year1": "2021-2022",
                                "year1value": "48",
                                "year2": "2020-2021",
                                "year2value": "98"
                            },
                            {
                                "id": 51,
                                "display_parameter_level_2": "Insurance Cost",
                                "year1": "2021-2022",
                                "year1value": "70000",
                                "year2": "2020-2021",
                                "year2value": "25000"
                            },
                            {
                                "id": 54,
                                "display_parameter_level_2": "Electricity Expenses",
                                "year1": "2021-2022",
                                "year1value": "45",
                                "year2": "2020-2021",
                                "year2value": "69"
                            },
                            {
                                "id": 56,
                                "display_parameter_level_2": "Administrative Expenses",
                                "year1": "2021-2022",
                                "year1value": "150000",
                                "year2": "2020-2021",
                                "year2value": "300000"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "4Profit",
                        "display_parameter_level_1": "Profit",
                        "label1Details": [
                            {
                                "value": 20,
                                "label": "Profit"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 20,
                                "label": "Profit"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 20,
                                "display_parameter_level_2": "Profit",
                                "year2": "2020-2021",
                                "year2value": "88"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "5ManagementRenumeration",
                        "display_parameter_level_1": "Management Renumeration",
                        "label1Details": [
                            {
                                "value": 58,
                                "label": "Interest on Loans to Directors & family"
                            },
                            {
                                "value": 57,
                                "label": "Interest to Partners"
                            },
                            {
                                "value": 59,
                                "label": "Salary to Partners/Directors"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 57,
                                "label": "Interest to Partners"
                            },
                            {
                                "value": 58,
                                "label": "Interest on Loans to Directors & family"
                            },
                            {
                                "value": 59,
                                "label": "Salary to Partners/Directors"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 57,
                                "display_parameter_level_2": "Interest to Partners",
                                "year1": "2021-2022",
                                "year1value": "97",
                                "year2": "2020-2021",
                                "year2value": "87"
                            },
                            {
                                "id": 58,
                                "display_parameter_level_2": "Interest on Loans to Directors & family",
                                "year1": "2021-2022",
                                "year1value": "78",
                                "year2": "2020-2021",
                                "year2value": "78"
                            },
                            {
                                "id": 59,
                                "display_parameter_level_2": "Salary to Partners/Directors",
                                "year1": "2021-2022",
                                "year1value": "53",
                                "year2": "2020-2021",
                                "year2value": "76"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "6TotalNonOperatingIncome",
                        "display_parameter_level_1": "Total Non Operating Income",
                        "label1Details": [
                            {
                                "value": 65,
                                "label": "Forex Income (Expenses)"
                            },
                            {
                                "value": 60,
                                "label": "Interest and Dividend"
                            },
                            {
                                "value": 66,
                                "label": "Other Income"
                            },
                            {
                                "value": 114,
                                "label": "Other Non-Operating Income"
                            },
                            {
                                "value": 63,
                                "label": "Profit from Sale of Assets"
                            },
                            {
                                "value": 62,
                                "label": "Rental Income"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 60,
                                "label": "Interest and Dividend"
                            },
                            {
                                "value": 62,
                                "label": "Rental Income"
                            },
                            {
                                "value": 63,
                                "label": "Profit from Sale of Assets"
                            },
                            {
                                "value": 65,
                                "label": "Forex Income (Expenses)"
                            },
                            {
                                "value": 66,
                                "label": "Other Income"
                            },
                            {
                                "value": 114,
                                "label": "Other Non-Operating Income"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 60,
                                "display_parameter_level_2": "Interest and Dividend",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 62,
                                "display_parameter_level_2": "Rental Income",
                                "year1": "2021-2022",
                                "year1value": "67",
                                "year2": "2020-2021",
                                "year2value": "67"
                            },
                            {
                                "id": 63,
                                "display_parameter_level_2": "Profit from Sale of Assets",
                                "year1": "2021-2022",
                                "year1value": "67",
                                "year2": "2020-2021",
                                "year2value": "67"
                            },
                            {
                                "id": 65,
                                "display_parameter_level_2": "Forex Income (Expenses)",
                                "year1": "2021-2022",
                                "year1value": "76",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 66,
                                "display_parameter_level_2": "Other Income",
                                "year1": "2021-2022",
                                "year1value": "77887888",
                                "year2": "2020-2021",
                                "year2value": "85"
                            },
                            {
                                "id": 114,
                                "display_parameter_level_2": "Other Non-Operating Income",
                                "year1": "2021-2022",
                                "year1value": "776",
                                "year2": "2020-2021",
                                "year2value": "67"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "7OtherNon-CashExpenses",
                        "display_parameter_level_1": "Other Non-Cash Expenses",
                        "label1Details": [
                            {
                                "value": 68,
                                "label": "Bad debts W/O"
                            },
                            {
                                "value": 67,
                                "label": "Depreciation"
                            },
                            {
                                "value": 69,
                                "label": "Preliminary expenses W/O"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 67,
                                "label": "Depreciation"
                            },
                            {
                                "value": 68,
                                "label": "Bad debts W/O"
                            },
                            {
                                "value": 69,
                                "label": "Preliminary expenses W/O"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 67,
                                "display_parameter_level_2": "Depreciation",
                                "year1": "2021-2022",
                                "year1value": "98",
                                "year2": "2020-2021",
                                "year2value": "34"
                            },
                            {
                                "id": 68,
                                "display_parameter_level_2": "Bad debts W/O",
                                "year1": "2021-2022",
                                "year1value": "67",
                                "year2": "2020-2021",
                                "year2value": "64"
                            },
                            {
                                "id": 69,
                                "display_parameter_level_2": "Preliminary expenses W/O",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "89"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "8TotalInterestPaid",
                        "display_parameter_level_1": "Total Interest Paid",
                        "label1Details": [
                            {
                                "value": 70,
                                "label": "Interest on Bank Finance"
                            },
                            {
                                "value": 71,
                                "label": "Interest on Other Loans"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 70,
                                "label": "Interest on Bank Finance"
                            },
                            {
                                "value": 71,
                                "label": "Interest on Other Loans"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 70,
                                "display_parameter_level_2": "Interest on Bank Finance",
                                "year1": "2021-2022",
                                "year1value": "127",
                                "year2": "2020-2021",
                                "year2value": "727"
                            },
                            {
                                "id": 71,
                                "display_parameter_level_2": "Interest on Other Loans",
                                "year1": "2021-2022",
                                "year1value": "7272",
                                "year2": "2020-2021",
                                "year2value": "77"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "9PBT",
                        "display_parameter_level_1": "PBT",
                        "label1Details": [
                            {
                                "value": 115,
                                "label": "PBT"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 115,
                                "label": "PBT"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 115,
                                "display_parameter_level_2": "PBT",
                                "year1": "2021-2022",
                                "year1value": "32",
                                "year2": "2020-2021",
                                "year2value": "65"
                            }
                        ]
                    }
                ]
            },

            {
                "parameter_head": "2B/S",
                "display_parameter_head": "Balance Sheet",
                "headerDetails": [
                    {
                        "parameter_level_1": "10TotalOutsideDebts",
                        "display_parameter_level_1": "Total Outside Debts",
                        "label1Details": [
                            {
                                "value": 81,
                                "label": "Bank Debts - LAP"
                            },
                            {
                                "value": 80,
                                "label": "Bank Debts - Term Loans"
                            },
                            {
                                "value": 82,
                                "label": "Bank Debts - Unsecured Loans"
                            },
                            {
                                "value": 84,
                                "label": "Loan from Others"
                            },
                            {
                                "value": 83,
                                "label": "Vehicle Loans"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 80,
                                "label": "Bank Debts - Term Loans"
                            },
                            {
                                "value": 81,
                                "label": "Bank Debts - LAP"
                            },
                            {
                                "value": 82,
                                "label": "Bank Debts - Unsecured Loans"
                            },
                            {
                                "value": 83,
                                "label": "Vehicle Loans"
                            },
                            {
                                "value": 84,
                                "label": "Loan from Others"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 80,
                                "display_parameter_level_2": "Bank Debts - Term Loans",
                                "year1": "2021-2022",
                                "year1value": "9"
                            },
                            {
                                "id": 81,
                                "display_parameter_level_2": "Bank Debts - LAP",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "898"
                            },
                            {
                                "id": 82,
                                "display_parameter_level_2": "Bank Debts - Unsecured Loans",
                                "year1": "2021-2022",
                                "year1value": "5",
                                "year2": "2020-2021",
                                "year2value": "56"
                            },
                            {
                                "id": 83,
                                "display_parameter_level_2": "Vehicle Loans",
                                "year1": "2021-2022",
                                "year1value": "787",
                                "year2": "2020-2021",
                                "year2value": "65"
                            },
                            {
                                "id": 84,
                                "display_parameter_level_2": "Loan from Others",
                                "year1": "2021-2022",
                                "year1value": "56",
                                "year2": "2020-2021",
                                "year2value": "65"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "11Noncurrentassets",
                        "display_parameter_level_1": "Non current assets",
                        "label1Details": [
                            {
                                "value": 85,
                                "label": "Fixed assets"
                            },
                            {
                                "value": 86,
                                "label": "Investments"
                            },
                            {
                                "value": 87,
                                "label": "Long term deposits"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 85,
                                "label": "Fixed assets"
                            },
                            {
                                "value": 86,
                                "label": "Investments"
                            },
                            {
                                "value": 87,
                                "label": "Long term deposits"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 85,
                                "display_parameter_level_2": "Fixed assets",
                                "year1": "2021-2022",
                                "year1value": "45",
                                "year2": "2020-2021",
                                "year2value": "561"
                            },
                            {
                                "id": 86,
                                "display_parameter_level_2": "Investments",
                                "year1": "2021-2022",
                                "year1value": "1",
                                "year2": "2020-2021",
                                "year2value": "4"
                            },
                            {
                                "id": 87,
                                "display_parameter_level_2": "Long term deposits",
                                "year1": "2021-2022",
                                "year1value": "45",
                                "year2": "2020-2021",
                                "year2value": "23"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "12WorkingCapitalAssets",
                        "display_parameter_level_1": "Working Capital Assets",
                        "label1Details": [
                            {
                                "value": 98,
                                "label": "Cash and bank balances"
                            },
                            {
                                "value": 90,
                                "label": "Debtors exceeding 6 mths"
                            },
                            {
                                "value": 89,
                                "label": "Debtors Less 6 mths"
                            },
                            {
                                "value": 97,
                                "label": "Due to Govt Authorities"
                            },
                            {
                                "value": 88,
                                "label": "Inventory"
                            },
                            {
                                "value": 112,
                                "label": "Other Current Assets"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 88,
                                "label": "Inventory"
                            },
                            {
                                "value": 89,
                                "label": "Debtors Less 6 mths"
                            },
                            {
                                "value": 90,
                                "label": "Debtors exceeding 6 mths"
                            },
                            {
                                "value": 97,
                                "label": "Due to Govt Authorities"
                            },
                            {
                                "value": 98,
                                "label": "Cash and bank balances"
                            },
                            {
                                "value": 112,
                                "label": "Other Current Assets"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 88,
                                "display_parameter_level_2": "Inventory",
                                "year1": "2021-2022",
                                "year1value": "45",
                                "year2": "2020-2021",
                                "year2value": "45"
                            },
                            {
                                "id": 89,
                                "display_parameter_level_2": "Debtors Less 6 mths",
                                "year1": "2021-2022",
                                "year1value": "44",
                                "year2": "2020-2021",
                                "year2value": "4"
                            },
                            {
                                "id": 90,
                                "display_parameter_level_2": "Debtors exceeding 6 mths",
                                "year1": "2021-2022",
                                "year1value": "45",
                                "year2": "2020-2021",
                                "year2value": "45"
                            },
                            {
                                "id": 97,
                                "display_parameter_level_2": "Due to Govt Authorities",
                                "year1": "2021-2022",
                                "year1value": "5",
                                "year2": "2020-2021",
                                "year2value": "4"
                            },
                            {
                                "id": 98,
                                "display_parameter_level_2": "Cash and bank balances",
                                "year1": "2021-2022",
                                "year1value": "4",
                                "year2": "2020-2021",
                                "year2value": "45"
                            },
                            {
                                "id": 112,
                                "display_parameter_level_2": "Other Current Assets",
                                "year1": "2021-2022",
                                "year1value": "444",
                                "year2": "2020-2021",
                                "year2value": "99"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "14SourcesofWorkingcapital",
                        "display_parameter_level_1": "Sources of Working capital",
                        "label1Details": [
                            {
                                "value": 93,
                                "label": "CC / Overdraft"
                            },
                            {
                                "value": 95,
                                "label": "Other short term facilities"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 93,
                                "label": "CC / Overdraft"
                            },
                            {
                                "value": 95,
                                "label": "Other short term facilities"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 93,
                                "display_parameter_level_2": "CC / Overdraft",
                                "year1": "2021-2022",
                                "year1value": "98",
                                "year2": "2020-2021",
                                "year2value": "898"
                            },
                            {
                                "id": 95,
                                "display_parameter_level_2": "Other short term facilities",
                                "year1": "2021-2022",
                                "year1value": "6",
                                "year2": "2020-2021",
                                "year2value": "999"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "16WorkingCapitalLiabilities",
                        "display_parameter_level_1": "Working Capital Liabilities",
                        "label1Details": [
                            {
                                "value": 100,
                                "label": "Capital Asset Creditors"
                            },
                            {
                                "value": 92,
                                "label": "Customer Advances"
                            },
                            {
                                "value": 99,
                                "label": "Employee Benefit Payable"
                            },
                            {
                                "value": 113,
                                "label": "Other Current Liabilities"
                            },
                            {
                                "value": 102,
                                "label": "Provisions"
                            },
                            {
                                "value": 91,
                                "label": "Sundry Creditors"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 91,
                                "label": "Sundry Creditors"
                            },
                            {
                                "value": 92,
                                "label": "Customer Advances"
                            },
                            {
                                "value": 99,
                                "label": "Employee Benefit Payable"
                            },
                            {
                                "value": 100,
                                "label": "Capital Asset Creditors"
                            },
                            {
                                "value": 102,
                                "label": "Provisions"
                            },
                            {
                                "value": 113,
                                "label": "Other Current Liabilities"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 91,
                                "display_parameter_level_2": "Sundry Creditors",
                                "year1": "2021-2022",
                                "year1value": "8989",
                                "year2": "2020-2021",
                                "year2value": "8989"
                            },
                            {
                                "id": 92,
                                "display_parameter_level_2": "Customer Advances",
                                "year1": "2021-2022",
                                "year1value": "5679",
                                "year2": "2020-2021",
                                "year2value": "9"
                            },
                            {
                                "id": 99,
                                "display_parameter_level_2": "Employee Benefit Payable",
                                "year1": "2021-2022",
                                "year1value": "88",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 100,
                                "display_parameter_level_2": "Capital Asset Creditors",
                                "year1": "2021-2022",
                                "year1value": "89",
                                "year2": "2020-2021",
                                "year2value": "89"
                            },
                            {
                                "id": 102,
                                "display_parameter_level_2": "Provisions",
                                "year1": "2021-2022",
                                "year1value": "666",
                                "year2": "2020-2021",
                                "year2value": "6689"
                            },
                            {
                                "id": 113,
                                "display_parameter_level_2": "Other Current Liabilities",
                                "year1": "2021-2022",
                                "year1value": "889",
                                "year2": "2020-2021",
                                "year2value": "8777877"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "8Networth",
                        "display_parameter_level_1": "Networth",
                        "label1Details": [
                            {
                                "value": 75,
                                "label": "Deferred Tax Liab (Asset)"
                            },
                            {
                                "value": 73,
                                "label": "Miscellaneous Exp"
                            },
                            {
                                "value": 74,
                                "label": "Res & Surplus"
                            },
                            {
                                "value": 72,
                                "label": "Share Capital"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 72,
                                "label": "Share Capital"
                            },
                            {
                                "value": 73,
                                "label": "Miscellaneous Exp"
                            },
                            {
                                "value": 74,
                                "label": "Res & Surplus"
                            },
                            {
                                "value": 75,
                                "label": "Deferred Tax Liab (Asset)"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 72,
                                "display_parameter_level_2": "Share Capital",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 73,
                                "display_parameter_level_2": "Miscellaneous Exp",
                                "year1": "2021-2022",
                                "year1value": "099",
                                "year2": "2020-2021",
                                "year2value": "877"
                            },
                            {
                                "id": 74,
                                "display_parameter_level_2": "Res & Surplus",
                                "year1": "2021-2022",
                                "year1value": "787",
                                "year2": "2020-2021",
                                "year2value": "78"
                            },
                            {
                                "id": 75,
                                "display_parameter_level_2": "Deferred Tax Liab (Asset)",
                                "year1": "2021-2022",
                                "year1value": "99",
                                "year2": "2020-2021",
                                "year2value": "9"
                            }
                        ]
                    },
                    {
                        "parameter_level_1": "9TotalpromoterFunds",
                        "display_parameter_level_1": "Total promoter Funds",
                        "label1Details": [
                            {
                                "value": 79,
                                "label": "Advance to Related Parties"
                            },
                            {
                                "value": 78,
                                "label": "Investment in Group Companies"
                            },
                            {
                                "value": 77,
                                "label": "Loans from Promoters / relatives"
                            },
                            {
                                "value": 76,
                                "label": "Loan to promoters / relatives"
                            }
                        ],
                        "selectedParameter": [
                            {
                                "value": 76,
                                "label": "Loan to promoters / relatives"
                            },
                            {
                                "value": 77,
                                "label": "Loans from Promoters / relatives"
                            },
                            {
                                "value": 78,
                                "label": "Investment in Group Companies"
                            },
                            {
                                "value": 79,
                                "label": "Advance to Related Parties"
                            }
                        ],
                        "selectedParameterValue": [
                            {
                                "id": 76,
                                "display_parameter_level_2": "Loan to promoters / relatives",
                                "year1": "2021-2022",
                                "year1value": "8",
                                "year2": "2020-2021",
                                "year2value": "8"
                            },
                            {
                                "id": 77,
                                "display_parameter_level_2": "Loans from Promoters / relatives",
                                "year1": "2021-2022",
                                "year1value": "7",
                                "year2": "2020-2021",
                                "year2value": "7"
                            },
                            {
                                "id": 78,
                                "display_parameter_level_2": "Investment in Group Companies",
                                "year1": "2021-2022",
                                "year1value": "78",
                                "year2": "2020-2021",
                                "year2value": "6"
                            },
                            {
                                "id": 79,
                                "display_parameter_level_2": "Advance to Related Parties",
                                "year1": "2021-2022",
                                "year1value": "87",
                                "year2": "2020-2021",
                                "year2value": "7"
                            }
                        ]
                    }
                ]
            },

            {
                "parameter_head": "3Other",
                "display_parameter_head": "Other",
                "headerDetails": [
                    {
                        "parameter_level_1": "17ITRFiliingdate",
                        "display_parameter_level_1": "ITR Filling Date",
                        "label1Details": [
                            {
                                "value": 36,
                                "label": "ITR Filling Date"
                            }
                        ],
                        "selectedParameter": [],
                        "selectedParameterValue": []
                    },
                    {
                        "parameter_level_1": "18PersonalIncomeData",
                        "display_parameter_level_1": "Personal Income Data",
                        "label1Details": [
                            {
                                "value": 121,
                                "label": "Assessed Profit"
                            },
                            {
                                "value": 116,
                                "label": "Agricultural Income"
                            },
                            {
                                "value": 122,
                                "label": "Other Incomes"
                            },
                            {
                                "value": 117,
                                "label": "Rental Income"
                            },
                            {
                                "value": 118,
                                "label": "Salary Income if SEP"
                            },
                            {
                                "value": 119,
                                "label": "Relevant Experience"
                            },
                            {
                                "value": 120,
                                "label": "Assessed TO"
                            }
                        ],
                        "selectedParameter": [],
                        "selectedParameterValue": []
                    }
                ]
            }
        ];


        // // 

        const objects = [
            { pbt1: "", pbt2: "", pbt3: "" },
            { pat1: "", pat2: "", pat3: "" },
            { profit1: "", profit2: "", profit3: "" },
            { depreciation1: "", depreciation2: "", depreciation3: "" },
            { tax1: "", tax2: "", tax3: "" },
            { ibf1: "", ibf2: "", ibf3: "", },
            { ibl1: "", ibl2: "", ibl3: "" },
            { tip1: "", tip2: "", tip3: "", },
            { totalincome1: "", totalincome2: "", totalincome3: "", },
            { totoperev1: "", totoperev2: "", totoperev3: "" },
            { operatingprofit1: "", operatingprofit2: "", operatingprofit3: "" },
            { totnonopin1: "", totnonopin2: "", totnonopin3: "" },
            { paytoman1: "", paytoman2: "", paytoman3: "" },
            { pbdit1: "", pbdit2: "", pbdit3: "", },
            { busmargin1: "", busmargin2: "", busmargin3: "", },
            { cashprofit1: "", cashprofit2: "", cashprofit3: "" }
        ];

        data.map(item => {
            if (item.parameter_head === "1PnL") {
                item.headerDetails.map(item2 => {
                    let display_parameter_level_1 = item2.display_parameter_level_1;
                    switch (display_parameter_level_1) {

                        case "PBT":
                            objects[0].pbt1 = item2.selectedParameterValue[0].year1value ? Number(item2.selectedParameterValue[0].year1value) / 100000 : "";
                            objects[0].pbt2 = item2.selectedParameterValue[0].year2value ? Number(item2.selectedParameterValue[0].year2value) / 100000 : "";
                            item2.selectedParameterValue.year3value !== undefined ? objects[0].pat3 = Number(item2.selectedParameterValue[0].year3value) / 100000 : objects[0].pbt3 = "";


                        // eslint-disable-next-line no-fallthrough
                        case "Profit":
                            objects[2].profit1 = item2.selectedParameterValue[0].year1value ? item2.selectedParameterValue[0].year1value : "";
                            objects[2].profit2 = item2.selectedParameterValue[0].year2value ? item2.selectedParameterValue[0].year2value : "";
                            item2.selectedParameterValue.year3value !== undefined ? objects[2].profit3 = item2.selectedParameterValue[0].year3value : objects[0].profit3 = "";


                        // eslint-disable-next-line no-fallthrough
                        case "Other Non-Cash Expenses":
                            item2.selectedParameterValue.map(item3 => {
                                if (item3.display_parameter_level_2 === "Depreciation") {
                                    objects[3].depreciation1 = item3.year1value ? Number(item3.year1value) / 100000 : "";
                                    objects[3].depreciation2 = item3.year2value ? Number(item3.year2value) / 100000 : "";
                                    objects[3].depreciation3 = item3.year3value ? Number(item3.year3value) / 100000 : "";
                                }
                            });


                        // eslint-disable-next-line no-fallthrough
                        case "Tax Paid":
                            objects[4].tax1 = item2.selectedParameterValue[0].year1value ? Number(item2.selectedParameterValue[0].year1value) / 100000 : "";
                            objects[4].tax2 = item2.selectedParameterValue[0].year2value ? Number(item2.selectedParameterValue[0].year2value) / 100000 : "";
                            item2.selectedParameterValue.year3value !== undefined ? objects[4].tax3 = Number(item2.selectedParameterValue[0].year3value) / 100000 : objects[4].tax3 = "";


                        // eslint-disable-next-line no-fallthrough
                        case "Total Interest Paid":
                            item2.selectedParameterValue.map(item3 => {
                                if (item3.display_parameter_level_2 === "Interest on Bank Finance") {
                                    objects[5].ibf1 = item3.year1value ? Number(item3.year1value) / 100000 : "";
                                    objects[5].ibf2 = item3.year2value ? Number(item3.year2value) / 100000 : "";
                                    objects[5].ibf3 = item3.year3value ? Number(item3.year3value) / 100000 : "";
                                }

                                if (item3.display_parameter_level_2 === "Interest on Other Loans") {
                                    objects[6].ibl1 = item3.year1value ? Number(item3.year1value) : "";
                                    objects[6].ibl2 = item3.year2value ? Number(item3.year2value) : "";
                                    objects[6].ibl3 = item3.year3value ? Number(item3.year3value) : "";
                                }
                            })

                        // eslint-disable-next-line no-fallthrough
                        case "Total Income":
                            let year1 = '';
                            let year2 = '';
                            let year3 = '';
                            item2.selectedParameterValue.map(item3 => {
                                year1 += item3.year1value ? Number(item3.year1value) : '';
                                year2 += item3.year2value ? Number(item3.year2value) : '';
                                year3 += item3.year3value ? Number(item3.year3value) : '';
                            })
                            objects[8].totalincome1 = year1 / 100000;
                            objects[8].totalincome1 = year2 / 100000;
                            objects[8].totalincome1 = year3 / 100000;


                        // eslint-disable-next-line no-fallthrough
                        case "Cost of Goods Sold":
                            let closingStock = { year1: "", year2: "", year3: "" };
                            let grossProfit = { year1: "", year2: "", year3: "" };
                            item2.selectedParameterValue.map(item3 => {
                                if (item3.display_parameter_level_2 != "Closing Stock") {
                                    grossProfit.year1 += item3.year1value ? Number(item3.year1value) : '';
                                    grossProfit.year2 += item3.year2value ? Number(item3.year2value) : '';
                                    grossProfit.year3 += item3.year3value ? Number(item3.year3value) : '';
                                } else {
                                    closingStock.year1 = item3.year1value ? Number(item3.year1value) : "";
                                    closingStock.year2 = item3.year2value ? Number(item3.year2value) : "";
                                    closingStock.year3 = item3.year3value ? Number(item3.year3value) : "";
                                }
                            });

                            // Total Operating Revenue calculations
                            objects[9].totoperev1 = objects[8].totalincome1 - (grossProfit.year1 - closingStock.year1);
                            objects[9].totoperev2 = objects[8].totalincome2 - (grossProfit.year2 - closingStock.year3);
                            objects[9].totoperev3 = objects[8].totalincome3 - (grossProfit.year2 - closingStock.year3);

                            // operating profit
                            objects[10].operatingprofit1 = grossProfit.year1 / 100000;
                            objects[10].operatingprofit2 = grossProfit.year2 / 100000;
                            objects[10].operatingprofit3 = grossProfit.year2 / 100000;



                        // eslint-disable-next-line no-fallthrough
                        case "Total Non Operating Income":
                            item2.selectedParameterValue.map(item3 => {
                                objects[11].totnonopin1 += item3.year1value ? Number(item3.year1value) : '';
                                objects[11].totnonopin2 += item3.year2value ? Number(item3.year2value) : '';
                                objects[11].totnonopin3 += item3.year3value ? Number(item3.year3value) : '';
                            });
                        // let totoperev1Andtotnonopin1 =;
                        // let totoperev2Andtotnonopin2 =;
                        // let totoperev3Andtotnonopin3 =;


                        // eslint-disable-next-line no-fallthrough
                        case "Management Renumeration":
                            item2.selectedParameterValue.map(item3 => {
                                objects[12].paytoman1 += item3.year1value ? Number(item3.year1value) : '';
                                objects[12].paytoman2 += item3.year2value ? Number(item3.year2value) : '';
                                objects[12].paytoman3 += item3.year3value ? Number(item3.year3value) : '';
                            });

                        // eslint-disable-next-line no-fallthrough
                        default: return 4;
                    }
                })
            }
        });

        /// PAT Manual Calculation
        objects[1].pat1 = (Number(objects[0].pbt1 ? objects[0].pbt1 : "") + Number(objects[4].tax1 ? objects[4].tax1 : "")) / 100000;
        objects[1].pat2 = (Number(objects[0].pbt2 ? objects[0].pbt2 : "") + Number(objects[4].tax2 ? objects[4].tax2 : "")) / 100000;
        objects[1].pat3 = (Number(objects[0].pbt3 ? objects[0].pbt3 : "") + Number(objects[4].tax3 ? objects[4].tax3 : "")) / 100000;

        /// Total finance Cost

        objects[7].tip1 = (Number(objects[5].ibf1 ? objects[5].ibf1 : "") + Number(objects[6].ibl1 ? objects[6].ibl1 : "")) / 100000;
        objects[7].tip2 = (Number(objects[5].ibf2 ? objects[5].ibf2 : "") + Number(objects[6].ibf2 ? objects[6].ibf2 : "")) / 100000;
        objects[7].tip3 = (Number(objects[5].ibf3 ? objects[5].ibf3 : "") + Number(objects[6].ibf3 ? objects[6].ibf3 : "")) / 100000;

        // PBDIT
        objects[13].pbdit1 = objects.totnonopin1 + objects[12].paytoman1;
        objects[13].pbdit2 = objects.totnonopin2 + objects[12].paytoman2;
        objects[13].pbdit3 = objects.totnonopin3 + objects[12].paytoman3;

        objects[15].cashprofit1 = objects[1].pat1 + objects[3].depreciation1
        objects[15].cashprofit2 = objects[1].pat2 + objects[3].depreciation2
        objects[15].cashprofit3 = objects[1].pat3 + objects[3].depreciation3
        console.log("object-------", objects);

        setFinanceRatios(objects);
        setAssessedIncome(true);

    }

    const [finRatios, setFinanceRatios] = React.useState([]);


    // get all promoters for the business
    const [userPromoterList, setUserPromoterList] = React.useState([]);
    const [assessedIncome, setAssessedIncome] = React.useState([]);
    const getAllPromoter = async () => {
        // ;
        let promoterArray = [];
        let data = {
            user_id: iduser.user_id
        }

        await axios.post(apiCalling.coderscorner + `server.php/api/getPromoterByUserId`, data).then(
            (result) => {
                if (result.data.data.length > 0) {
                    setUserPromoterList(result.data.data);
                    setPromoters(true);

                }
            }
        ).catch((error) => {

            console.log(error);
        })
    };


    // get all loan of the business
    const [userBusinessList1, setUserBusinessList1] = React.useState([]);
    const [getAllLoan, setAllLoans] = React.useState(false);
    const getAllBusinessLoan = async (item1) => {

        let data = {
            user_id: iduser.user_id
        }

        await axios.post(apiCalling.coderscorner + `server.php/api/getLoansDetailsBusinessAndPromoterById`, data).then(
            (result) => {
                console.log("get loan business");
                console.log(result.data.data.businessList);
                // 
                setUserBusinessList1(result.data.data.businessList);
                setAllLoans(true);
                // let busLoanArr = [];
                // for (let i = 0; i < result.data.data.businessList.length; i++) {
                //   busLoanArr.push(false);
                // }
                // setLoanHeaderOpen(busLoanArr);

            }
        ).catch((error) => {
            /// ErrorCatch(iduser.user_id, error, api_path, 'post', data, iduser.user_id, iduser.user_id);
            console.log(error);
        })
    }


    const [finRatiosValue, setFinRatiosValue] = React.useState([]);
    const getFinRatios = async () => {
        let data = {
            "folder_no": iduser.folder_no
        }


        let obj1 = {
            Current_Ratio: "",
            Creditors_Payment_in_Days: "",
            Debtors_Collection_in_Days: "",
            Tol_Tnw: "",
            Debt_Equity: "",
            ISCR: "",
            DSCR: ""
        }

        let obj2 = {
            Current_Ratio: "",
            Creditors_Payment_in_Days: "",
            Debtors_Collection_in_Days: "",
            Tol_Tnw: "",
            Debt_Equity: "",
            ISCR: "",
            DSCR: ""
        }

        await axios.post(apiCalling.coderscorner + 'server.php/api/getFoldersNumber', data).then(result => {
            //   console.log(result);
            if (result.data.data.length > 0) {

                result.data.data.map(item => {
                    if (item.year == '2022') {
                        if (item.parameter_name == "Current Ratio") {
                            obj1.Current_Ratio = item.parameter_value
                        }
                        if (item.parameter_name == "Creditors Payment in Days") {
                            obj1.Creditors_Payment_in_Days = item.parameter_value
                        }
                        if (item.parameter_name == "Debtors Collection in Days") {
                            obj1.Debtors_Collection_in_Days = item.parameter_value
                        }
                        if (item.parameter_name == "TOL / TNW") {
                            obj1.Tol_tnw = item.parameter_value
                        }
                        if (item.parameter_name == "Debt Equity") {
                            obj1.Debt_Equity = item.parameter_value
                        }
                        if (item.parameter_name == "ISCR") {
                            obj1.ISCR = item.parameter_value
                        }
                        if (item.parameter_name == "DSCR") {
                            obj1.DSCR = item.parameter_value
                        }
                    }

                    if (item.year == '2021') {
                        if (item.parameter_name == "Current Ratio") {
                            obj2.Current_Ratio = item.parameter_value
                        }
                        if (item.parameter_name == "Creditors Payment in Days") {
                            obj2.Creditors_Payment_in_Days = item.parameter_value
                        }
                        if (item.parameter_name == "Debtors Collection in Days") {
                            obj2.Debtors_Collection_in_Days = item.parameter_value
                        }
                        if (item.parameter_name == "TOL / TNW") {
                            obj2.Tol_tnw = item.parameter_value
                        }
                        if (item.parameter_name == "Debt Equity") {
                            obj2.Debt_Equity = item.parameter_value
                        }
                        if (item.parameter_name == "ISCR") {
                            obj2.ISCR = item.parameter_value
                        }
                        if (item.parameter_name == "DSCR") {
                            obj2.DSCR = item.parameter_value
                        }
                    }
                });

                let arr = [];
                arr.push(obj1);
                arr.push(obj2);
                setFinRatiosValue(arr);

            }
        }).catch(error => {
            console.log(error);
        })
    }



    return (
        <>
            <Container maxWidth="lg" id="demo">
                <div className='sum_logo' >
                    <img alt="img" src={logo} width='200px' height="auto" />
                </div>
                <div className="mini-container mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 9 }} className="sum_first_row">
                            <Typography styleType='SH' text='Name Of Business' />
                            <p className="sum_para" style={{ marginTop: "-16px" }}>{businessData.name}</p>
                        </Col>
                        <Col md={{ span: 3 }} className="sum_first_row">
                            <Typography styleType='SH' text='Date Of Incorporation' />
                            <p className="sum_para">{moment(businessData.setup_date).format('DD-MM-YYYY')}</p>
                        </Col>

                        <Col md={{ span: 3 }} className="sum_second_row">
                            <Typography styleType='SH' text='Consitution' />
                            <p className="sum_para">{businessData.entity}</p>
                        </Col>
                        <Col md={{ span: 3 }} className="sum_second_row">
                            <Typography styleType='SH' text='Business PAN' />
                            <p className="sum_para">{businessData.BPAN}</p>
                        </Col>
                        <Col md={{ span: 3 }} className="sum_second_row">
                            <Typography styleType='SH' text='GST Registration' />
                            <p className="sum_para">{businessData.gst}</p>
                        </Col>
                        <Col md={{ span: 3 }} className="sum_second_row">
                            <Typography styleType='SH' text='Industry' />
                            <p className="sum_para">{businessData.industry_type}</p>
                        </Col>
                        <Col md={{ span: 12 }} className="sum_second_row" >
                            <Typography styleType='SH' text='Registered Address' />
                            <span style={{ marginTop: "-16px" }} className="sum_para"> {businessData.pincode}</span>
                        </Col>

                        <Col md={{ span: 12 }} className="sum_second_row"></Col>

                        <Col md={{ span: 12 }}>
                            <Typography styleType='SH' text='Loan Details' className="loan_details" />
                            <Row>
                                <Col md={{ span: 3 }}>
                                    <p className=" lgs_header1">Loan Type</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p>Business Loan</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p className="lgs_header1">End Use Loan</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p>NA</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p className="lgs_header1">Loan Amount</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p></p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p className="lgs_header1">Rate Of Interest</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p></p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p className="lgs_header1">Loan Duration</p>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <p></p>
                                </Col>
                            </Row>

                        </Col>

                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>


            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Promoter Details</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <table border="1" style={{ width: "98%" }} className="styled-table" >
                                <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                    <tr className="header_tr">
                                        <th className="first_column">Promoter Name</th>
                                        <th className="header_tr">Email</th>
                                        <th className="header_tr">Mobile</th>
                                        <th className="header_tr">Email</th>
                                        <th className="header_tr">Share</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userPromoterList.map(item => (
                                            <tr className="sum_row">
                                                <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>{item.first_name}</td>
                                                <td className="sum_pl" style={{ textAlign: "center" }}>{item.email}</td>
                                                <td className="sum_pl" style={{ textAlign: "center" }}>{item.mobile}</td>
                                                <td className="sum_pl" style={{ textAlign: "center" }}>{item.share_percent}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>


            {
                plAndBl && (
                    <Container maxWidth="lg" id="demo1">
                        <Row>
                            <Col md={{ span: 8 }}>
                                <h2 className="sum_headers">Financial - P&L Statement</h2>
                            </Col>
                            <Col d={{ span: 4 }}>
                                <div className='sum_logo' >
                                    <img alt="img" src={logo} width='200px' height="auto" />
                                </div>
                            </Col>
                        </Row>

                        <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>

                            <Row>
                                <Col md={{ span: 12 }}>
                                    <table border="1" style={{ width: "98%" }} className="styled-table" >

                                        {financeParameterDetails.slice(0, 1).map((item, i) => (
                                            <>
                                                <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                                    <tr className="tr1">
                                                        <th className="head1 first_column">{item.display_parameter_head}</th>
                                                        {
                                                            noOfColumn.map(item => (
                                                                <th className="head1 header_tr">{item}</th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.headerDetails.map((iitem, j) => (
                                                        <>
                                                            {iitem.selectedParameterValue.map((iteml1, k) => (
                                                                <tr className="sum_row">
                                                                    <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>{iteml1.display_parameter_level_2}</td>
                                                                    {noOfColumn.map((year, j) => (
                                                                        <td className="sum_pl" style={{ textAlign: "center" }}
                                                                        >{iteml1[fyear + (j + 1) + fval]}</td>
                                                                    ))}

                                                                </tr>
                                                            ))}
                                                        </>


                                                    ))}

                                                </tbody>
                                            </>
                                        ))}
                                    </table>
                                </Col>
                            </Row>

                        </div>


                        <div className='sum_footer'>
                            <p>Report generated by Aagey.com for  Artha Info Systems.
                            </p>
                        </div>
                    </Container>)
            }


            <hr className="hrline"></hr>

            {plAndBl && (
                <Container maxWidth="lg" id="demo2">
                    <Row>
                        <Col md={{ span: 8 }}>
                            <h2 className="sum_headers"> Finance Balance Sheet</h2>
                        </Col>
                        <Col d={{ span: 4 }}>
                            <div className='sum_logo' >
                                <img alt="img" src={logo} width='200px' height="auto" />
                            </div>
                        </Col>
                    </Row>

                    <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >

                                    {financeParameterDetails.slice(1, 2).map((item, i) => (
                                        <>
                                            <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                                <tr className="tr1">
                                                    <th className="head1 first_column">{item.display_parameter_head}</th>
                                                    {
                                                        noOfColumn.map(item => (
                                                            <th className="head1 header_tr">{item}</th>
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.headerDetails.map((iitem, j) => (
                                                    <>
                                                        {iitem.selectedParameterValue.map((iteml1, k) => (
                                                            <tr className="sum_row">
                                                                <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>{iteml1.display_parameter_level_2}</td>
                                                                {noOfColumn.map((year, j) => (
                                                                    <td className="sum_pl" style={{ textAlign: "center" }}
                                                                    >{iteml1[fyear + (j + 1) + fval]}</td>
                                                                ))}

                                                            </tr>
                                                        ))}
                                                    </>
                                                ))}
                                            </tbody>
                                        </>
                                    ))}
                                </table>
                            </Col>
                        </Row>

                    </div>


                    <div className='sum_footer'>
                        <p>Report generated by Aagey.com for  Artha Info Systems.
                        </p>
                    </div>
                </Container>
            )}


            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Financial -Ratios</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>

                    <Row>
                        <Col md={{ span: 12 }}>
                            <table border="1" style={{ width: "98%" }} className="styled-table" >

                                {financeParameterDetails.slice(0, 1).map((item, i) => (
                                    <>
                                        <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                            <tr className="tr1">
                                                <th className="head1 first_column">Ratios</th>
                                                <th className="head1 first_column">Description</th>
                                                {
                                                    noOfColumn.map(item => (
                                                        <th className="head1 header_tr">{item}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr className="sum_row">
                                                <td className="sum_pl1" rowspan="3" style={{ "fontWeight": "bold", fontSize: "16px", textAlign: "center" }} >Profitability</td>
                                                <td className="sum_pl1" >Gross Margin</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>
                                            <tr className="sum_row">

                                                <td className="sum_pl1" >Net Margin</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>
                                            <tr className="sum_row">

                                                <td className="sum_pl1" >EBIDTA Margin</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>





                                            <tr className="sum_row">
                                                <td className="sum_pl1" rowspan="7" style={{ "fontWeight": "bold", backgroundColor: "white", fontSize: "16px", textAlign: "center" }} >Working Capital</td>
                                                <td className="sum_pl1" >Current Ratio (Times)</td>

                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.Current_Ratio}</td>
                                                ))
                                                }

                                            </tr>
                                            <tr className="sum_row">

                                                <td className="sum_pl1" >Quick Ratio (Times)</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>
                                            <tr className="sum_row">
                                                <td className="sum_pl1" >Debtor Days</td>
                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.Debtors_Collection_in_Days}</td>
                                                ))
                                                }
                                            </tr>

                                            <tr className="sum_row">
                                                <td className="sum_pl1" >Debtor Days</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>

                                            <tr className="sum_row">
                                                <td className="sum_pl1" >Creditor Days</td>

                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.Creditors_Payment_in_Days}</td>
                                                ))
                                                }

                                            </tr>
                                            <tr className="sum_row">
                                                <td className="sum_pl1" >Working Capital Cycle Days</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>


                                            <tr className="sum_row">
                                                <td className="sum_pl1" > Working Capital GAP (In Lacs)</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>





                                            <tr className="sum_row">
                                                <td className="sum_pl1" rowspan="5" style={{ "fontWeight": "bold", fontSize: "16px", textAlign: "center" }}>Leverage</td>
                                                <td className="sum_pl1" >TOL/TNW (Times)</td>
                                               
                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.Tol_Tnw}</td>
                                                ))
                                                }
                                            </tr>
                                            <tr className="sum_row">

                                                <td className="sum_pl1" >Debt to Equity (Times)</td>
                                               
                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.Debt_Equity}</td>
                                                ))
                                                }
                                                
                                            </tr>
                                            <tr className="sum_row">
                                                <td className="sum_pl1" >Total Debt/EBIDTA (Times)</td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                                <td className="sum_pl1" ></td>
                                            </tr>

                                            <tr className="sum_row">
                                                <td className="sum_pl1" >ISCR (Times)</td>
                                                
                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.ISCR}</td>
                                                ))
                                                }
                                            </tr>
                                            <tr className="sum_row">
                                                <td className="sum_pl1" >DSCR (Times)</td>
                                                {finRatiosValue.map(item => (
                                                    <td className="sum_pl1" >{item.DSCR}</td>
                                                ))
                                                }
                                            </tr>

                                            {
                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Total Operating Revenue</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev2}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev3}</td>
                                                // </tr>

                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Operating Profit</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit2}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit3}</td>
                                                // </tr>

                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Depreciation</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                                                // </tr>

                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Total Finance Cost</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev2}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev3}</td>
                                                // </tr>

                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PBT</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt2}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt3}</td>
                                                // </tr>

                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PAT</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat2}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat3}</td>
                                                // </tr>
                                                // <tr className="sum_row">
                                                //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>CASH PROFIT</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                                                //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                                                // </tr>
                                            }

                                        </tbody>
                                    </>
                                ))}
                            </table>
                        </Col>
                    </Row>
                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Banking And Gst Growth Analysis</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <table border="1" style={{ width: "98%" }} className="styled-table" >
                                <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                    <tr className='tr1'>
                                        <th className="head1 first_column">Key Financial Indicators</th>
                                        {
                                            noOfColumn.map(item => (
                                                <th className="head1 header_tr">{item}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="sum_row">
                                        <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PAT</td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>
            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Banking And Gst Growth Analysis</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <table border="1" style={{ width: "98%" }} className="styled-table" >
                                <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                    <tr className='tr1'>
                                        <th className="head1 first_column">Key Financial Indicators</th>
                                        {
                                            noOfColumn.map(item => (
                                                <th className="head1 header_tr">{item}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="sum_row">
                                        <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PAT</td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>
            <hr className="hrline"></hr>


            {
                getAllLoan === true && (

                    <Container maxWidth="lg" id="demo">
                        <Row>
                            <Col md={{ span: 8 }}>
                                <h2 className="sum_headers"> Existing Obligation</h2>
                            </Col>
                            <Col d={{ span: 4 }}>
                                <div className='sum_logo' >
                                    <img alt="img" src={logo} width='200px' height="auto" />
                                </div>
                            </Col>
                        </Row>

                        <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <table border="1" style={{ width: "98%" }} className="styled-table" >
                                        <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                            <tr className="tr">
                                                <th className="head1 header_tr">Financier</th>
                                                <th className="head1 header_tr">Loan Type</th>
                                                <th className="head1 header_tr">Account Number</th>
                                                <th className="head1 header_tr">Loan Amount</th>
                                                <th className="head1 header_tr">EMI</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {userBusinessList1.slice(0, 1).map((item, i) => (
                                                <>
                                                    {item.loanForBusiness.map((val, k) => (
                                                        <tr className="sum_row">
                                                            <td className="sum_pl" style={{ textAlign: "center" }}>{val.financer_name}</td>
                                                            <td className="sum_pl" style={{ textAlign: "center" }}>{val.loan_types}</td>
                                                            <td className="sum_pl" style={{ textAlign: "center" }}>{val.Account_no}</td>
                                                            <td className="sum_pl" style={{ textAlign: "center" }}>{val.Loan_amount}</td>
                                                            <td className="sum_pl" style={{ textAlign: "center" }}>{val.EMI}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </div>


                        <div className='sum_footer'>
                            <p>Report generated by Aagey.com for  Artha Info Systems.
                            </p>
                        </div>
                    </Container>
                )
            }

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Repayment History (Last 12 Months)</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <table border="1" style={{ width: "98%" }} className="styled-table" >
                                <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                    <tr>
                                        <th className="first_column">Key Financial Indicators</th>
                                        {
                                            noOfColumn.map(item => (
                                                <th className="head1 header_tr">{item}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="sum_row">
                                        <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>


            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Top 5 Customers Details</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >
                                    <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                        <tr>
                                            <th className=" first_column">Key Financial Indicators</th>
                                            {
                                                noOfColumn.map(item => (
                                                    <th className="head1 header_tr">{item}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sum_row">
                                            <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>

                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Top 5 Suppliers Details</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >
                                    <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                        <tr>
                                            <th className="first_column">Key Financial Indicators</th>
                                            {
                                                noOfColumn.map(item => (
                                                    <th className="head1 header_tr">{item}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sum_row">
                                            <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Propery Details</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >
                                    <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                        <tr>
                                            <th className="first_column">Key Financial Indicators</th>
                                            {
                                                noOfColumn.map(item => (
                                                    <th className="head1 header_tr">{item}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sum_row">
                                            <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Propery Picture</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >
                                    <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                        <tr>
                                            <th className="first_column">Key Financial Indicators</th>
                                            {
                                                noOfColumn.map(item => (
                                                    <th className="head1 header_tr">{item}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sum_row">
                                            <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>

            <hr className="hrline"></hr>

            <Container maxWidth="lg" id="demo">
                <Row>
                    <Col md={{ span: 8 }}>
                        <h2 className="sum_headers">Comments</h2>
                    </Col>
                    <Col d={{ span: 4 }}>
                        <div className='sum_logo' >
                            <img alt="img" src={logo} width='200px' height="auto" />
                        </div>
                    </Col>
                </Row>

                <div className="mini-container1 mg_btm_set1" style={{ marginBottom: '0px !important' }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Col md={{ span: 12 }}>
                                <table border="1" style={{ width: "98%" }} className="styled-table" >
                                    <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                                        <tr>
                                            <th className="first_column">Key Financial Indicators</th>
                                            {
                                                noOfColumn.map(item => (
                                                    <th className="head1 header_tr">{item}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sum_row">
                                            <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                            <td className="sum_pl" style={{ textAlign: "center" }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Col>
                    </Row>

                </div>


                <div className='sum_footer'>
                    <p>Report generated by Aagey.com for  Artha Info Systems.
                    </p>
                </div>
            </Container>




        </>
    );

}

export default AppSummaryPreview;