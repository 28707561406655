import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import { TextArea } from 'semantic-ui-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, } from 'semantic-ui-react'
//import Typography from '../../base_components/Typography';

import Typography1 from "../../base_components/Typography";

import { Typography } from "@material-ui/core";
import { Formik } from "formik";
import { sortOrder1 } from "../../shared/constants";
// import { documentCheckListSchema, documentAuthorityMasterSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import {
    mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import { apiCalling } from '../adminConstants';
// import { documentCheckListSchema, documentAuthorityMasterSchema } from "../../shared/validations";
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from '../../image/buttons/graydraftnext.png';
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Switch from '@material-ui/core/Switch';

import { purple } from "@material-ui/core/colors";

import { withStyles } from '@material-ui/core/styles';

import { Grid } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";


const useStyles1 = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function DocumentMaster(props) {

    const classes = useStyles();
    const classes1 = useStyles1();
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);
    const [sortOrder, setSortOrder] = React.useState("");
    const [ employmentType, setEmploymentType ] = React.useState(0);
    const [ professionalBusinessType, setProfessionalBusinessType ] = React.useState(0);
    const [ financialType, setFinancialType ] = React.useState(0);
    const [showList, setShowList] = React.useState(true);
    const [loaderFlag, setLoaderFlag] = React.useState(true);
    const [ listLoaderFlag, setListLoaderFlag] = React.useState(false);
    const [ showListTwo, setShowListTwo ] = React.useState(false);
    const [ showListThree, setShowListThree ] = React.useState(false);
    const [ showListFour, setShowListFour ] = React.useState(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
    const [CategoryList, setCategoryList] = React.useState([]);



    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        LoanTypes();
       // handleAssetSubType();
        //getAllForms();
        //getDocuments();
        //getAllDocumentAuthority();
        //statecheck();
    }, []);

    const [loanType, setLoanType] = React.useState('');
    const [loanTypes, setLoanTypes] = React.useState([]);
    const LoanTypes = () => {
        let getLoanTypes = {
            enable: true,
        }
        axios.post(apiCalling.coderscorner + `server.php/api/getAllloanType`, getLoanTypes)
            .then((res) => {
                //console.log(res)
               // let allData = [{ key: 0, value: '', text: 'Select Loan Type'}]
                let allData = [];
                res.data.data.map((item, i) => {
                    allData.push({
                        key: i + 1,
                        value: item.short_name,
                        text: item.name,
                    });
                });
                setShowWCAddForm(false);
                setShowList(true);
                setLoaderFlag(false);
                setLoanTypes(allData);
               // console.log(allData);

            })
            .catch((err) => console.log("error in fetching asset data", err))
    }




    const handleSubmiting = () => {
        props.handleNext();
    };

    const handledata = () => {

        props.handleBack();
    };


    const handleselectChange = (event) => {
        event.preventDefault();
        this.setState({
            showOption: true
        })
    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handlecheck();
    }




    // let [initialValue, setInitialValue] = React.useState({
    //     asset: "",
    //     fname: "",
    //     sname: "",
    //     descrip: "",
    //     sortOrder: "",
    //     enable: "",
    //     asset_sub_cat_id: ""
    // })

    const handleDocumentList = async () => {
        let data = {
            user_id: "660",
            form: "1.1.3",
            required_phase: "172"
        }
        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getDetailsDocumentV2`,
                data
            ).then((result) => {
                let empArray = [];
                console.log(result)
                setListLoaderFlag(false);
                result.data.data.map((item) => {
                    empArray.push({
                        id: item.id,
                        name: item.name,
                        short_name: item.short_name,
                        description: item.description,
                        formid: '1.1.3'


                    })
                })
                setShowWCAddForm(false);
                setShowList(true);
                setLoaderFlag(false);
                console.log("EmpArray", empArray);
                setCategoryList(empArray);
            })
            .catch((err) => console.log("error in fetching asset data", err))
    }



    const data = {
        columns: [
            {
                label: "Sl No.",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Name",
                field: "name",
                width: 200,
            },
            {
                label: "Description",
                field: "description",
                width: 200,
            },
            {
                label: "Short Name",
                field: "short_name",
                width: 200,
            },
            {
                label: "Form ID",
                field: "form",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,

            },
        ],

        rows: CategoryList.map((values, i) => {
            return {
                slno: i + 1,
                id: values.id,
                name: values.name,
                short_name: values.short_name,
                description: values.description,
                form: '1.1.3',

                action: <div style={{ marginLeft: '-20px' }}>
                    <Tooltip title="Edit Data" placement="bottom">
                        {/* <Button className="actionBtn" onClick={() => handleEdit(values)} style={{ marginRight: "16px" }} >
                            <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                        </Button> */}
                        <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />
                    </Tooltip>

                    <Tooltip title="Delete Data" placement="bottom">
                        {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                            <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                        </Button> */}
                        <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                    </Tooltip>
                </div>
            }
        })
    };

    const resetForm1 = () => {
        setInitialValue = {
            id: "",
            name: "",
            short_name: "",
            description: "",
            form: "",
            
        }
        setShowWCAddForm(false);
        setShowList(true);
    }

    const handlesubmitform = (values, { resetForm }) => {
        handleNextClick(values);
        resetForm({
            id: "",
            name: "",
            short_name: "",
            description: "",
            form: "",
            
        })

    };
    const handleNextClick = (data) => {
        setShowWCAddForm(false);
        // setLoaderFlag(true);
        //  let eligibilityFlag = 0;
        /*  if (checkvalue === 1) {
           eligibilityFlag = 1;
         }  */

        let wcData = {
            id: data.id,
            name: data.name,
            short_name: data.short_name,
            description: data.description,
            form: '1.1.3'
        };

        console.log("data", data);
        axios.post(apiCalling.coderscorner + `server.php/api/assetSubCategory`, wcData).then((res) => {
            console.log(res.data);
            console.log("saved successfully...!!!");
            setShowWCAddForm(false);
            setShowList(true);
           // handleAssetSubType();
            setLoaderFlag(false);
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    };



    const handleOpenWCAddForm = () => {

        setAddFormButtonValue("Submit");
        setShowWCAddForm(true);
        setShowList(false);

        setInitialValue({
            id: "",
            name: "",
            short_name: "",
            description: "",
            form: ""
        })
    }



    const handleEdit = (values) => {
console.log('In edit')
        console.log(values)
        setShowWCAddForm(true);

        setAddFormButtonValue("Update");

        setShowList(false);

        setInitialValue({
            ...values,


            id: values.id,
            name: values.name,
            short_name: values.short_name === null ? '' : values.short_name,
            description: values.description,
            form: '1.1.3'
        })
    }

    const handleDelete = (item) => {
        let data = {
            id: item.id
        }
      //  
        axios.post(apiCalling.coderscorner + `server.php/api/deleteDoc`, data).then((res) => {

            console.log("Data", res.data);
            setShowWCAddForm(false);
            setShowList(true);
            handleDocumentList();
            setLoaderFlag(false);

        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    }

    const [allForms, setAllForms] = React.useState([]);

    const getAllForms = () => {
        axios.post(apiCalling.coderscorner + 'server.php/api/getAllForms', {
            enable: true
        }).then(result => {
            console.log(result);
            let tempArr = [];
            result.data.data.map((item) => {
                tempArr.push({
                    key: item.id,
                    value: item.form_id,
                    text: item.form_name,
                });
            });
            setAllForms(tempArr);
        }).catch();
    }

    const PurpleSwitch = withStyles({
        switchBase: {
            color: purple[300],
            '&$checked': {
                color: purple[500],
            },
            '&$checked + $track': {
                backgroundColor: purple[500],
            },
        },
        checked: {},
        track: {},
    })(Switch);



    const [masterEnable, setMasterEnable] = React.useState({
        checkeligility: true,
    });
    const [masterVerification, setMasterVerification] = React.useState({
        verified: true,
    });
    const handleRequestType = (event) => {
        setMasterEnable({ ...masterEnable, [event.target.name]: event.target.checked });
    };

    const handleBusinessType = (event) => {
        setMasterVerification({ ...masterVerification, [event.target.name]: event.target.checked });
    };

    let listTwoItems = [{ key: 1, value: 1, text: "Self employed" }, { key: 2, value: 2, text: "Professional" }];
    let listThreeItems = [
        { key: 1, value: 1, text: "Financial" }, 
        { key: 2, value: 2, text: "Bank Accounts" },
        { key: 3, value: 3, text: "Loans" }, 
        { key: 4, value: 4, text: "Working Capital" },
        
    ];
    let listFourItems = [{ key: 1, value: 1, text: "Business" }];
    
    const [iniLoan, setIniLoan] = React.useState();

    const [area, setArea] = React.useState([]);
    const getPincodeData = async (data) => {
        let getPincode = {
            pincode: data,
            token: "qwerty1234",
        };
        await axios
            .post(apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
                getPincode
            )
            .then((res) => {
                // console.log("yeh we have pincode", res.data);
                return handleAllAreas(res.data.data);
            })
            .catch((er) => {
                console.log("no pincode sorry ", er);
            });
    };
    const handleAllAreas = (data) => {
        let allArea = [];
        for (let i = 0; i < data.length; i++) {
            allArea.push({
                key: data[i].area,
                value: data[i].id,
                text: data[i].area,
            });
        }
        return setArea(allArea);
    };

    const [formId, setFormId] = React.useState(1);
    let DocumentCheckList = {
        name: "",
        shortname: "",
        description: "",
        timebound: "",
        sortorder: "",
    }

    let DocumetntAuthority = {
        name: "",
        shortname: "",
        description: "",
        state1: "",
        area: ""
    }

    let AuthorityMapping = {
        docid: "",
        docauthid: ""

    }


    const [initialValue, setInitialValue] = React.useState(DocumentCheckList);



    const saveDocumentMaster = async (values) => {
       
       
           let data = {
                name: values.name,
                shortname: values.short_name,
                description: values.description,
                form: '1.1.3'
                
            }

        
        axios.post(apiCalling.coderscorner + `server.php/api/documentAddAndEdit`, data).then(result => {
            console.log(result);
            setShowWCAddForm(false);
            setShowList(true);            
            handleDocumentList();
            setLoaderFlag(false);
        }).catch(error => {
            console.log(error);
        });

    }

   

    return (
        <Container maxWidth="lg">
            <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>
                {loaderFlag == true && (
                    <div style={{ textAlign: 'center', marginTop: '15%' }}>
                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                    </div>
                )}
                {showList == true && loaderFlag == false && (
                    <div>
                        <div className="content-header">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <Typography1
                                        text="Document Master"
                                        styleType="C"
                                    />
                                </Col>
                                <Col md={{ span: 2 }} >
                                    {/* <button className="btn btn-primary" onClick={() => handleOpenWCAddForm()} >
                                        Add</button> */}
                                    <span className="image">
                                        <img className='navbutton backbutton' alt="dd" src={blueadd} onClick={() => handleOpenWCAddForm()} />
                                        <Tooltip title="Add" arrow>
                                            <img className='navgraybutton backbutton' alt="img" src={grayadd} onClick={() => handleOpenWCAddForm()} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <Row className="datable" style={{ marginTop: '30px', }} >

                            <Col md={{ span: 12 }}>
                                {      // <div component={Paper}>
                                    //     <MDBDataTableV5
                                    //         hover 
                                    //         entriesOptions={[5, 10,20, 25]} 
                                    //         entries={5} 
                                    //         pagesAmount={4} 
                                    //         data={data}
                                    //         // searchTop 
                                    //         // searchBottom={false}
                                    //     />
                                    // </div>
                                }
                            </Col>
                        </Row>


                        <div className='btn_row_fixd'>
                            <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                <Col style={{ padding: "0%" }}></Col>

                                {/* <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                    </Tooltip>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Save as Draft & Next" arrow>
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                    </Tooltip>
                                </Col> */}
                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                        <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                            <img className='navgraybutton' src={grayback} onClick={handledata} />
                                        </Tooltip>
                                    </span>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                        <Tooltip title="Save as Draft & Next" arrow>
                                            <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                <Row>
                    <Col md={{ span: 12 }}>
                    {showWCAddForm === false && (
                        <>
                        <Row>

                            <Col md={{ span: 6 }}>
                                <Grid>
                                    <List component='div' disablePadding>
                                        <ListItem>
                                            <Dropdown
                                                placeholder='Select Loan Type'
                                                id='type'
                                                name='type'
                                                fluid
                                                search
                                                selection
                                                value={loanType}
                                                onBlur={(e, data) => {
                                                    // 

                                                    // console.log("------------>", e.target);
                                                    // setPage(e.target);
                                                }}

                                                onChange={(e, { value }) => { //setvalue(value);
                                                    //loanTypeSelection(value) ;
                                                    
                                                    console.log("------------>", value);
                                                    // console.log(e.target);
                                                    //setPage(value);
                                                    if (value === 'BL') {
                                                        //setInitialValue(DocumentCheckList)
                                                        setLoanType(value)
                                                        setShowListTwo(true);
                                                    } else {
                                                        setShowListTwo(false);
                                                    }

                                                }}
                                                options={loanTypes}

                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Col>
                            <Col md={{ span: 6 }}>
                            { showListTwo && (
                                <List component='div' disablePadding>
                                        <ListItem>
                                            <Dropdown
                                                placeholder='Business Type'
                                                id='busType'
                                                name='busType'
                                                fluid
                                                search
                                                selection
                                                value={employmentType}
                                                onBlur={(e, data) => {
                                                    // 

                                                    // console.log("------------>", e.target);
                                                    // setPage(e.target);
                                                }}

                                                onChange={(e, { value }) => { //setvalue(value);
                                                    //loanTypeSelection(value) ;
                                                   // 
                                                    console.log("------------>", value);
                                                    // console.log(e.target);
                                                    //setPage(value);
                                                    if (value === 2) {
                                                        setEmploymentType(value);
                                                        setShowListThree(true);
                                                    } else {
                                                        setShowListThree(false)
                                                    }

                                                }}
                                                options={listTwoItems}

                                            />
                                            </ListItem>
                                    </List>
                            )}
                            </Col>

                        </Row>
                        <Row>
                            <Col md={{ span: 6 }}>
                            { showListThree && (
                                <List component='div' disablePadding>
                                        <ListItem>
                                            <Dropdown
                                                placeholder='ProfessionalBusiness Type'
                                                id='profBusType'
                                                name='profBusType'
                                                fluid
                                                search
                                                selection
                                                value={professionalBusinessType}
                                                onBlur={(e, data) => {
                                                    // 

                                                    // console.log("------------>", e.target);
                                                    // setPage(e.target);
                                                }}

                                                onChange={(e, { value }) => { //setvalue(value);
                                                    //loanTypeSelection(value) ;
                                                    //
                                                    console.log("------------>", value);
                                                    // console.log(e.target);
                                                    //setPage(value);
                                                    if (value === 1) {
                                                        setProfessionalBusinessType(value);
                                                        setShowListFour(true);
                                                    } else {
                                                        setShowListFour(false)
                                                    }

                                                }}
                                                options={listThreeItems}

                                            />
                                            </ListItem>
                                    </List>
                            )}
                            </Col>
                            <Col md={{ span: 6 }}>
                            { showListFour && (
                                <List component='div' disablePadding>
                                        <ListItem>
                                            <Dropdown
                                                placeholder='Financial Type'
                                                id='finType'
                                                name='finType'
                                                fluid
                                                search
                                                selection
                                                value={financialType}
                                                onBlur={(e, data) => {
                                                    // 

                                                    // console.log("------------>", e.target);
                                                    // setPage(e.target);
                                                }}

                                                onChange={(e, { value }) => { //setvalue(value);
                                                    //loanTypeSelection(value) ;
                                                    //
                                                    console.log("------------>", value);
                                                    // console.log(e.target);
                                                    //setPage(value);
                                                    if (value === 1) {
                                                        setFinancialType(value);
                                                        handleDocumentList();
                                                        setListLoaderFlag(true);
                                                    } else {
                                                        setListLoaderFlag(false)
                                                    }

                                                }}
                                                options={listFourItems}

                                            />
                                            </ListItem>
                                    </List>
                            )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <Container maxWidth="lg">
                                    <div className="mini-container" style={{ marginTop: 0, padding: "15px" }}>
                                    {listLoaderFlag === true && (
                                        <div style={{ textAlign: "center", marginTop: "15%" }}>
                                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                                        </div>
                                    )}
                                    {showList === true && listLoaderFlag === false && (
                                        <div>
                                            <Row className="datable" style={{ marginTop: "30px" }}>
                                                <Col md={{ span: 12 }}>
                                                    <div component={Paper}>
                                                <div>
                                                    <ReactHTMLTableToExcel
                                                    id="karzaTblBtn"
                                                    className="btn btn-info exportBtn"
                                                    table="karzaTbl"
                                                    filename="DocumentMaster"
                                                    sheet="DocumentMaster"
                                                    buttonText="Export excel"
                                                    />
                                                </div>
                                                    <MDBDataTableV5
                                                        hover
                                                        entriesOptions={[5, 10, 20, 25]}
                                                        entries={5}
                                                        pagesAmount={4}
                                                        data={data}
                                                        id = "karzaTbl"
                                                        // searchTop
                                                        // searchBottom={false}
                                                />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    </div>
                                </Container>
                            </Col>
                    
                        </Row>
                        </>
                        )}
                        {showWCAddForm === true && (
                            <>
                                { (<Formik
                                    enableReinitialize
                                    initialValues={initialValue}
                                    // validationSchema={documentCheckListSchema}
                                    onSubmit={saveDocumentMaster}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldTouched
                                        /* and other goodies */
                                    }) => {
                                        //  console.log("values in formik", values);
                                        return (
                                            <form onSubmit={handleSubmit}>

                                                <div className="content-header" style={{ position: 'unset' }}>
                                                    <Row>
                                                        <Col md={{ span: 10 }}>
                                                            <Typography1
                                                                text="Document Master"
                                                                styleType="C"
                                                            />
                                                        </Col>
                                                        <Col md={{ span: 2 }}>
                                                            <div className="content-header-button ">
                                                                {/* <Button className="backBtn" variant="outlined" color="primary"
                                                            onClick={resetForm1} >
                                                            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                                             </Button> */}
                                                                <span className="image">
                                                                    <img className='backbutton navbutton' img="ss" src={Back} onClick={resetForm1} />
                                                                    <Tooltip title="Back" arrow>
                                                                        <img className='backbutton navgraybutton' img="ss" src={grayback} onClick={resetForm1} />
                                                                    </Tooltip>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '3%' }} >
                                                        <Col md={{ span: 6 }}>
                                                            <Typography1 text="Document Name" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="name"
                                                                        label="Enter Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.name &&
                                                                                touched.name &&
                                                                                errors.name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.name ? classes.manorma : null,
                                                                        }}
                                                                        value={values.name}
                                                                        helperText={
                                                                            errors.name &&
                                                                            touched.name &&
                                                                            errors.name
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}>
                                                            <Typography1 text="Short Name" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="short_name"
                                                                        label="Enter Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.short_name &&
                                                                                touched.short_name &&
                                                                                errors.short_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.short_name ? classes.manorma : null,
                                                                        }}
                                                                        value={values.short_name}
                                                                        helperText={
                                                                            errors.short_name &&
                                                                            touched.short_name &&
                                                                            errors.short_name
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}>
                                                            <Typography1 text="Description" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="description"
                                                                        label="Enter description"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.description &&
                                                                                touched.description &&
                                                                                errors.description
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.description ? classes.manorma : null,
                                                                        }}
                                                                        value={values.description}
                                                                        helperText={
                                                                            errors.description &&
                                                                            touched.description &&
                                                                            errors.description
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Col md={{ span: 12 }}>
                                                        <Row><Col md={{ span: 10 }}></Col>
                                                            <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                            </Col>
                                                            <Col md={{ span: 5 }}></Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                                )}

                            </>


                        )}
                    </Col>
                </Row>
            </div>
        </Container >

    );
}













