import React, { useState, useCallback } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from 'react-bootstrap/Modal'
import { useDropzone } from 'react-dropzone'
import { Dropdown, TextArea } from 'semantic-ui-react'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'
import Typography from '../../base_components/Typography'
import { Formik } from 'formik'
import axios from 'axios'
import {
  businessInfoFormSchema,
  businessinfoschema
} from '../../shared/validations'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import DraftNext from '../../image/buttons/draftnext.png'
import Back from '../../image/buttons/back.png'
import Reset from '../../image/buttons/reset.png'

import SaveNext from '../../image/buttons/savenext.png'
import moment from 'moment'
import FileBase64 from 'react-file-base64'
import TablePagination from '@material-ui/core/TablePagination'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import { SnackPosition } from '../../shared/constants'
import { DropzoneArea } from 'material-ui-dropzone'
import { AttachFile } from '@material-ui/icons'
import grayback from '../../image/buttons/grayback.png'
import graysave from '../../image/buttons/graysave.png'
import grayreset from '../../image/buttons/grayreset.png'
import calander from '../../image/pngicons/thisyear.png'
import deleterec from '../../image/pngicons/delete.png'
import DocumentUpload from '../../components/DocumentUpload'
import { ErrorCatch, apiCalling } from '../../shared/constants'

const BusinessInfoForm = props => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const [addButtonFlag, setaddButtonFlag] = React.useState(false)
  const [showbusinesslist, setshowbusinesslist] = React.useState(false)
  const [backToDashboard, setBackToDashboard] = React.useState(false)
  const [showbuttons, setshowbuttons] = React.useState(false)
  const [onSaveHideButton, setOnSaveHideButton] = React.useState(false)
  const [showbusinessheader, setshowbusinessheader] = React.useState(false)
  const [addNewBusinessHeader, setAddNewBusinessHeader] = React.useState(false)

  const [data, setdata] = React.useState([])
  const [entity, setentity] = React.useState([])
  const [business, setbussiness] = React.useState([])
  const [industry, setindustry] = React.useState([])
  const [industryname, setindustryname] = React.useState([])
  const [industrygrp, setindustrygrp] = React.useState([])
  const [show, setshow] = React.useState(false)

  const [showform, setshowform] = React.useState(true)
  const [topform, settopform] = React.useState(false)

  const [visible, setvisible] = React.useState(false)
  const [collapsedata, setcollapse] = React.useState(false)
  const [editData, seteditdata] = React.useState(false)

  const [govt, setgovt] = React.useState([])
  const handleClose = () => setshow(false)

  const [checkvalue, setchecked] = React.useState('')
  const [area, setArea] = React.useState([])
  const [profilebase64, setprofilebase64] = React.useState()
  const [loaderFlag, setLoaderFlag] = React.useState(true)

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onabort = () =>
        ////console.log('file reading was aborted')
        (reader.onerror = () =>
          ////console.log('file reading has failed')
          (reader.onloadend = () => {
            const base64String = reader.result
            setprofilebase64(base64String)
          }))
      //reader.readAsArrayBuffer(file)
      reader.readAsDataURL(file)
    })
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop })
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%'
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: '20%'
  }

  React.useEffect(() => {
    getGstByPan()
    getuserdetailsoflogin()
    entitycheck()
    industrycheck()
    //industrygrpcheck();
    //industrynamecheck();
    businesscheck()
    govtcheck()
    validateincorporationdate()
    validatemaxincorporationdate()
    getBusIDFromMainPage()
    getValidationConfiguration()
    localStorage.removeItem('businessflag')

    //checkNewForm();
    //openEditForm();
  }, [])

  // const checkNewForm = () => {
  // 	//
  // }

  // const openEditForm = () => {
  // 	////console.log("props bus edit id...!!!", props.BusEditId);
  // 	handleEditData(props.BusEditId)
  // }

  const [docForm, setDocForm] = React.useState(false)
  const getBusIDFromMainPage = async () => {
    //
    ////console.log("propsbbbbb------>", props.BusEditId)
    ////console.log("check urlllllllllllllllllll", apiCalling.coderscorner + 'server.php/api/getBussinessByuserid');
    const checkuserloggeddata = localStorage.getItem('userdata')
    let checkuserid = JSON.parse(checkuserloggeddata)
    setLoaderFlag(false)
    if (Boolean(props.BusDoc) === true) {
      setshowbusinessheader(true)
      setcollapse(false)
      setDocForm(true)
    } else {
      if (
        props.BusEditId === undefined ||
        Number(props.BusEditId) === 0 ||
        props.BusEditId === 'bus'
      ) {
        let data = {}
        if (localStorage.getItem('userbycpa')) {
          data = {
            user_id: localStorage.getItem('userbycpa')
          }
        } else {
          data = {
            user_id: checkuserid.id
          }
        }
        await axios
          .post(
            apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
            data
          )
          .then(result => {
            ////console.log(result.data.data)
            if (result.data.status === true) {
              if (result.data.data.length > 0) {
                setBackToDashboard(true)
                checkapply()
                setshowbusinessheader(false)
                setAddNewBusinessHeader(true)
                setcollapse(false)
                settopform(true)
                setvisible(false)
                seteditdata(false)
                setshowbuttons(true)
                setOnSaveHideButton(false)
                setDocForm(false)
              } else {
                if (localStorage.getItem('userbycpa')) {
                  getCustomerRegDataByUser(localStorage.getItem('userbycpa'))
                } else {
                  getCustomerRegDataByUser(checkuserid.id)
                }
              }
            } else {
              if (localStorage.getItem('userbycpa')) {
                getCustomerRegDataByUser(localStorage.getItem('userbycpa'))
              } else {
                getCustomerRegDataByUser(checkuserid.id)
              }
            }
          })
      } else {
        setDocForm(false)
        checkapply()
        setcollapse(true)
        settopform(false)
        setvisible(true)
        seteditdata(true)
        setshowmodal(false)
        setshowpdfview(true)
        sethidepdfview(false)
        setBackToDashboard(true)
        setshowbusinesslist(false)
        setBackToDashboard(false)
        getBusinessbybusinessId(props.BusEditId)
      }
    }
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function Alert (props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  //	const { vertical, horizontal } = SnackPosition;

  //Retriving UserId from logindetails data
  const [idofuser, setidofuser] = React.useState([])
  const getuserdetailsoflogin = () => {
    const checkuserloggeddata = localStorage.getItem('userdata')
    let checkuserid = JSON.parse(checkuserloggeddata)
    //////console.log('user logged data of userid', checkuserid.id);
    //setloguserId(checkuserid.id)
    if (localStorage.getItem('userbycpa')) {
      setidofuser(localStorage.getItem('userbycpa'))
    } else {
      setidofuser(checkuserid.id)
    }

    //getBusinessbyuserData(checkuserid.id);
  }

  //Retriving Business data From logged User
  const [paginationHidden, setPaginationHidden] = useState(false)
  const [businesslistdata, setbusinesslistdata] = React.useState([])
  const getBusinessbyuserData = async checkeduserid => {
    //
    const data = {
      user_id: checkeduserid
    }
    let api_path =
      apiCalling.coderscorner + 'server.php/api/getBussinessByuserid'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
        data
      )
      .then(result => {
        //alert('user')
        ////console.log(result.data.data);
        setLoaderFlag(false)
        if (result.data.status) {
          let businessArray = []
          result.data.data.map(item => {
            businessArray.push({
              businessName: item.name,
              businessid: item.id,
              gst: item.gst,
              entitytype: item.entity_type,
              industryname: item.industry_names
            })
          })
          setbusinesslistdata(businessArray)
          //////console.log("businessAll by user logged Id", businessArray);
          if (businessArray.length > 0) {
            /* setaddButtonFlag(true)
					settopform(false)
					setvisible(true)
					setcollapse(false)
					seteditdata(false)
					setshowbuttons(false)
					setOnSaveHideButton(true)
					setShowIfRegisterData(false)
					setshowbusinessheader(false); */
          } else {
            getCustomerRegDataByUser(checkeduserid)
          }
          if (result.data.data.length > 5) {
            /* setPaginationHidden(true);
					setshowbuttons(false) */
          } else if (
            result.data.data.length <= 5 ||
            result.data.status == false
          ) {
            /* setPaginationHidden(false)
					setshowbuttons(false) */
          }
        } else {
          getCustomerRegDataByUser(checkeduserid)
          /* checkapply();
				settopform(true)
				setvisible(false)
				setcollapse(true)
				seteditdata(false)
				setshowbuttons(true) */
        }
      })
      .catch(error => {
        ErrorCatch(
          checkeduserid,
          error,
          api_path,
          'post',
          data,
          checkeduserid,
          checkeduserid
        )
        ////console.log(error);
      })
  }

  // const getPan = () => {
  // 	const checkPan = localStorage.getItem('logindata'),
  // 		checkformpan = JSON.parse(checkPan)
  // 	setpan(checkformpan.pan);
  // 	//	////console.log('lets check pan', checkformpan.pan)
  // }

  const checkapply = () => {
    if (localStorage.getItem('check')) {
      const checkdata = localStorage.getItem('check'),
        checkform = JSON.parse(checkdata)
      //	////console.log('lets check', checkform)
      setchecked(checkform)
    } else {
      setchecked('Check Eligibility')
    }
  }

  const [busNameStatus, setBusNameStatus] = React.useState()
  // const businessNameValidation = (e) => {
  // 	const namedata = {
  // 		bus_id: "",
  // 		businessName: e.target.value
  // 	}
  // 	axios.post(apiCalling.coderscorner + `server.php/api/checkBusiness`, namedata).then((res) => {
  // 		setBusNameStatus(res.data.status)
  // 		if (res.data.status === false) {
  // 			setcolorMessage("error");
  // 			setShowMessage("Business Name Already Exists...!!!");
  // 			setsavenextmessage(true);
  // 		}
  // 	})
  // }

  const [showIfRegisterData, setShowIfRegisterData] = React.useState(false)

  let initialValue
  // if (checkvalue !== "Loan Application") {
  // 	let copdate = moment(regDate).format('YYYY-MM-DD');
  // 	//	getPincodeData(regPincode);
  // 	initialValue = {
  // 		businessName: "",
  // 		pincode: "",
  // 		date: "",
  // 		area: "",
  // 		gst: "",
  // 		businesspan: "",
  // 		entity: "",
  // 		business: "",
  // 		industry: "",
  // 		industrygrp: "",
  // 		industryname: "",
  // 		grcno: "",
  // 		bparea: "",
  // 		govt: "",
  // 		descrip: ""
  // 	}
  // }
  // else {
  // 	let copdate = moment(regDate).format('YYYY-MM-DD');
  // 	//	getPincodeData(regPincode);
  // 	initialValue = {
  // 		businessName: registrationDataforBusName,
  // 		pincode: regPincode,
  // 		date: copdate,
  // 		area: Number(regArea),
  // 		gst: "",
  // 		businesspan: registrationData,
  // 		entity: "",
  // 		business: "",
  // 		industry: "",
  // 		industrygrp: "",
  // 	}
  // }
  const [initialValue1, setInitialValue1] = useState({
    businessName: '',
    pincode: '',
    date: '',
    area: '',
    gst: '',
    businesspan: '',
    entity: '',
    business: '',
    industry: '',
    industrygrp: '',
    industryname: '',
    grcno: '',
    bparea: '',
    govt: '',
    descrip: ''
  })

  const getCustomerRegDataByUser = async checkeduserid => {
    let custData = {}
    if (localStorage.getItem('userbycpa')) {
      custData = {
        user_id: localStorage.getItem('userbycpa')
      }
    } else {
      custData = {
        user_id: checkeduserid
      }
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getCustomerbyuserid`,
        custData
      )
      .then(res => {
        if (res.data.status) {
          //console.log('getCustomerRegDataByUser', res.data.data[0]);
          //

          getGstFromPan(res.data.data[0].pan);

	

		  let fouth = res.data.data[0].pan;
		  let fouthletter = fouth[3]
		  let entityType = 1;

		  if (fouthletter == 'P') {
			entityType = 1
		  } else if ( fouthletter == 'A') {
			entityType = 6
		  } else if (fouthletter ==  'C') {
			entityType = 3
		  }
		  

          setInitialValue1({
            businessName: res.data.data[0].business_name,
            pincode: res.data.data[0].pincode,
            date: res.data.data[0].date_of_incorporation,
            area: res.data.data[0].pin_id,
            gst: '',
            businesspan: res.data.data[0].pan,
            entity: entityType,
            business: '',
            industry: '',
            industrygrp: '',
            industryname: '',
            grcno: '',
            bparea: '',
            govt: '',
            descrip: ''
          })
          // setRegistrationData(res.data.data[0].pan);
          // setregistrationDataforBusName(res.data.data[0].business_name);
          // getPincodeData(res.data.data[0].pincode)
          // setRegPinCode(res.data.data[0].pincode)
          // setRegArea(res.data.data[0].pin_id);
          // setRegDate(res.data.data[0].date_of_incorporation);

          setShowIfRegisterData(true)
          setBackToDashboard(true)
          checkapply()
          setshowbusinessheader(false)
          setAddNewBusinessHeader(true)
          setcollapse(false)
          settopform(true)
          setvisible(false)
          seteditdata(false)
          setshowbuttons(true)
          setOnSaveHideButton(false)
          setDocForm(false)
        } else {
          setBackToDashboard(true)
          checkapply()
          setshowbusinessheader(false)
          setAddNewBusinessHeader(true)
          setcollapse(false)
          settopform(true)
          setvisible(false)
          seteditdata(false)
          setshowbuttons(true)
          setOnSaveHideButton(false)
          setDocForm(false)
        }
      })
  }

  var entityarray = []
  const entitycheck = async () => {
    //alert('food')
    const entitydata = {
      token: 'qwerty1234'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getEntityTypes`,
        entitydata
      )
      .then(res => {
        res.data.data.map(item => {
          //	////console.log('item', item)
          entityarray.push({
            key: item.short_order,
            value: item.id,
            //flag: item.entity,
            text: item.entity
          })
        })
        setentity(entityarray)
        //	////console.log("entityarra", entityarray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  var industryarray = []
  const industrycheck = async () => {
    const industrydata = {
      token: 'qwerty1234',
      type: 'industry_types'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getIndustryTypeDetails`,
        industrydata
      )
      .then(res => {
        res.data.data.map(item => {
          industryarray.push({
            key: item.short_name,
            value: item.id,
            //flag: item.name,
            text: item.name
          })
        })
        setindustry(industryarray)
        setindustryname([])
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  var industrygrparray = []
  const industrygrpcheck = async typeid => {
    ////
    const industrygrpdata = {
      token: 'qwerty1234',
      industry_type_id: typeid
      //type: "industry_group"
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getindustryByid`,
        industrygrpdata
      )
      .then(res => {
        console.log("data ---- ", res.data.data)
        if(res.data.data){
          res.data.data.map(item => {
            console.log('item', item)
            industrygrparray.push({
              key: item.short_name,
              value: item.id,
              //flag: item.name,
              text: item.name
            })
          })
          setindustrygrp(industrygrparray)
        } else {
          setindustrygrp([])
        }
        
        
        //////console.log("industrygrparray", industrygrparray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  var industrynamearray = []
  const industrynamecheck = async groupid => {
    const industrynamedata = {
      token: 'qwerty1234',
      industry_group_id: groupid
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getIndustryName`,
        industrynamedata
      )
      .then(res => {
        if (res.data.data){
          res.data.data.map(item => {
            //	////console.log('item', item)
            industrynamearray.push({
              key: item.short_name,
              value: item.id,
              //flag: item.name,
              text: item.name
            })
          })
          setindustryname(industrynamearray)
        } else {
          setindustryname([])
        }
        
        //	////console.log("industrynamearrayse", industrynamearray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  var businessarray = []
  const businesscheck = async () => {
    const businessdata = {
      token: 'qwerty1234',
      type: 'business_premisis'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        businessdata
      )
      .then(res => {
        res.data.data.map(item => {
          //	////console.log('item', item)
          businessarray.push({
            key: item.short_name,
            value: item.id,
            //flag: item.name,
            text: item.name
          })
        })
        setbussiness(businessarray)
        //	////console.log("businessarray", businessarray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  var govtarray = []
  const govtcheck = async () => {
    //
    const govtdata = {
      token: 'qwerty1234',
      type: 'registration_certificates'
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        govtdata
      )
      .then(res => {
        res.data.data.map(item => {
          //	////console.log('item', item)
          govtarray.push({
            key: item.short_name,
            value: item.id,
            //flag: item.name,
            text: item.name
          })
        })
        setgovt(govtarray)
        setGvmntReg(data.data.data[0].govt)

        //	////console.log("govtarray", govtarray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  const handledatabase64 = data => {
    ////console.log('base64 arrayyy..........!!!!', data);
    let base64string = data[0].base64
    setprofilebase64(base64string)
  }

  const handledata = () => {
    props.handleBack()
  }

  const handlenew = () => {
    setBackToDashboard(false)
    setshowbusinessheader(false)
    setAddNewBusinessHeader(true)
    setshowbuttons(true)
    setshowbusinesslist(true)
    setaddButtonFlag(false)
    setshowform(true)
    settopform(true)
    setvisible(false)
    checkapply()
    setOnSaveHideButton(false)
  }

  const handlelist = () => {
    setBackToDashboard(false)
    setshowbusinessheader(false)
    setAddNewBusinessHeader(false)
    setshowbuttons(false)
    setOnSaveHideButton(true)
    setaddButtonFlag(true)
    setshowbusinesslist(false)
    settopform(false)
    setvisible(true)
    setcollapse(false)
    seteditdata(false)
  }
  const [showmodal, setshowmodal] = React.useState(false)
  const [showpdfview, setshowpdfview] = React.useState(false)
  const [hidepdfview, sethidepdfview] = React.useState(true)
  const handleshowhidepdf = base64 => {
    setprofilebase64(base64)
    // setshowpdfview(true)
    // sethidepdfview(false)
    setshowmodal(true)
  }

  const handleclosePdf = () => {
    ////
    setshowmodal(false)
    // setshowpdfview(false)
    // sethidepdfview(true)
  }
  const handleEditData = id => {
    //
    setLoaderFlag(false)
    setshowbusinessheader(true)
    setshowbuttons(false)
    settopform(false)
    //setvisible(true)
    seteditdata(true)
    //setOpen(!open);

    setcollapse(true)

    setaddButtonFlag(false)
    setshowbusinesslist(false)
    getBusinessbybusinessId(id)
    setshowpdfview(false)
    sethidepdfview(true)
    setshowmodal(false)
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()
  const [colorMessage, setcolorMessage] = React.useState()
  const SaveasDraft = () => {
    //getuserdetailsoflogin();
    setBackToDashboard(false)
    setshowbusinessheader(false)
    setAddNewBusinessHeader(false)
    if (businesslistdata.length > 0) {
      props.handleNext()
    } else {
      setcolorMessage('error')
      setShowMessage('Please Add AtLeast One Business...!!!')
      setsavenextmessage(true)
      settopform(true)
    }
  }

  const handleloanapply = async data => {
    //
    let user
    let created_by
    if (localStorage.getItem('userbycpa')) {
      user = localStorage.getItem('userbycpa')
      let crtdBy = JSON.parse(localStorage.getItem('userdata'))
      created_by = crtdBy.id
    } else {
      let user1 = JSON.parse(localStorage.getItem('userdata'))
      user = user1.id
      created_by = user
    }

    let panfromgst = ''
    if (data.gst != undefined && data.gst != '' && data.gst != undefined) {
      panfromgst = data.gst.substring(2, 12)
    }

    let gstTempArr = []

    gstArray.map(item => {
      if (item.value != data.gst) {
        gstTempArr.push(item.value)
      }
    })

    console.log(gstTempArr)

    let loanapplydata = {
      businessName: data.businessName,
      date: data.date,
      gst: data.gst,
      businesspan: panfromgst,
      pincode: data.area,
      //area: data.area,
      entity: data.entity,
      business: data.business,
      industry: data.industry,
      industryname: data.industryname,
      industrygrp: data.industrygrp,
      bparea: data.bparea,
      govt: data.govt,
      grcno: data.grcno,
      descrip: data.descrip,
      name1: fileObj.name,
      company_profile1: fileObj.base64String,
      file_type1: fileObj.type,
      name2: fileObj1.name,
      company_profile2: fileObj1.base64String,
      file_type2: fileObj1.type,
      user_id: user,
      is_draft: 1,
      Eligibility_flag: 0,
      created_by: created_by,
      gst_arr: gstTempArr

      // loan apply flag is 0
      // loan apply flag is 0
    }
    ////console.log('result', loanapplydata)
    //localStorage.setItem('businessForm', JSON.stringify(loanapplydata));

    let api_path = apiCalling.coderscorner + `server.php/api/businessDetails`
    // let api_path = apiCalling.coderscorner + `server.php/api/businessDetails`
    let api_path2 =
      apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/businessDetails'

    await axios
      .post(api_path, loanapplydata)
      .then(res => {
        //
        if (res.data.status == true) {
          props.handleCount()
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          handledata()
        } else {
          setLoaderFlag(false)
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
        }
        setdata(loanapplydata)
        //setBackToDashboard(false);

        //	getuserdetailsoflogin();
      })
      .catch(er => {
        ErrorCatch(
          created_by,
          er,
          api_path,
          'post',
          loanapplydata,
          created_by,
          created_by
        )
        ////console.log("no data sorry ", er);
      })
    //	getuserdetailsoflogin();
  }

  const handleEligibility = async data => {
    //
    let user
    let created_by
    if (localStorage.getItem('userbycpa')) {
      user = localStorage.getItem('userbycpa')
      created_by = user
    } else {
      let user1 = JSON.parse(localStorage.getItem('userdata'))
      user = user1.id
      created_by = user
    }
    let elegibilitydata = {
      businessName: data.businessName,
      date: data.date,
      gst: data.gst,
      businesspan: data.businesspan,
      //pincode: parseInt(data.pincode),
      pincode: data.area,
      entity: data.entity,
      business: data.business,
      industry: data.industry,
      industryname: '',
      industrygrp: data.industrygrp,
      bparea: '',
      govt: '',
      grcno: '',
      descrip: '',
      companyprofile: '',
      user_id: user,
      is_draft: 1,
      Eligibility_flag: 1, // check eligibilty falg is 1
      created_by: created_by
    }
    let api_path = apiCalling.coderscorner + `server.php/api/businessDetails`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/businessDetails`,
        elegibilitydata
      )
      .then(res => {
        if (res.data.status === true) {
          localStorage.removeItem('businessflag')
          props.handleCount()
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          handledata()
        } else {
          setLoaderFlag(false)
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
        }
        setdata(elegibilitydata)
        setBackToDashboard(false)

        //getuserdetailsoflogin();
      })
      .catch(er => {
        ErrorCatch(
          created_by,
          er,
          api_path,
          'post',
          elegibilitydata,
          created_by,
          created_by
        )
        ////console.log("no data sorry ", er);
      })
  }

  const Edithandleloanapply = async data => {
    //
    let user
    let created_by
    if (localStorage.getItem('userbycpa')) {
      user = localStorage.getItem('userbycpa')
      created_by = user
    } else {
      let user1 = JSON.parse(localStorage.getItem('userdata'))
      user = user1.id
      created_by = user
    }

    let editloanapplydata = {
      user_id: user,
      bus_id: data.id,
      businessName: data.businessName,
      date: data.date,
      gst: data.gst,
      businesspan: data.businesspan,
      //pincode: parseInt(data.pincode),
      pincode: data.pincode,
      entity: data.entity,
      business: data.business,
      industry: data.industry,
      industryname: data.industryname,
      industrygrp: data.industrygrp,
      bparea: data.bparea,
      govt: data.govt,
      grcno: data.grcno,
      descrip: data.descrip,
      name1: fileObj.name,
      company_profile1: fileObj.base64String,
      file_type1: fileObj.type,
      name2: fileObj1.name,
      company_profile2: fileObj1.base64String,
      file_type2: fileObj1.type,
      is_draft: 1,
      Eligibility_flag: 0,
      created_by: created_by
      //gst_arr: gstTempArr

      // check eligibilty falg is 1
    }

    //////console.log('editloanapplydata', editloanapplydata)
    let api_path = apiCalling.coderscorner + `server.php/api/businessDetails`
    let api_path2 =
      apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/businessDetails'
    await axios
      .post(api_path, editloanapplydata)
      .then(res => {
        if (res.data.status === true) {
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          handledata()
        } else {
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
        }
        setdata(editloanapplydata)
        setBackToDashboard(false)

        //getuserdetailsoflogin();
        //	////console.log("yeh we editloanapplydata", res.data);
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          editloanapplydata,
          idofuser,
          idofuser
        )
        ////console.log("no data sorry ", er);
      })
  }

  const EdithandleEligibility = async data => {
    //
    let user
    let created_by
    if (localStorage.getItem('userbycpa')) {
      user = localStorage.getItem('userbycpa')
      created_by = user
    } else {
      let user1 = JSON.parse(localStorage.getItem('userdata'))
      user = user1.id
      created_by = user
    }

    let editelegibilitydata = {
      user_id: user,
      bus_id: data.id,
      businessName: data.businessName,
      date: data.date,
      gst: data.gst,
      businesspan: data.businesspan,
      //pincode: parseInt(data.pincode),
      pincode: data.pincode,
      entity: data.entity,
      business: data.business,
      industry: data.industry,
      industryname: null,
      industrygrp: data.industrygrp,
      bparea: null,
      govt: null,
      grcno: null,
      descrip: null,
      companyprofile: null,
      is_draft: 1,
      Eligibility_flag: 1,
      created_by: created_by

      // check eligibilty falg is 1
    }
    localStorage.setItem('business', JSON.stringify(data.businessName))
    //////console.log('editelegibilitydata', editelegibilitydata)
    let api_path = apiCalling.coderscorner + `server.php/api/businessDetails`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/businessDetails`,
        editelegibilitydata
      )
      .then(res => {
        if (res.data.status === true) {
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          handledata()
        } else {
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
        }
        setdata(editelegibilitydata)
        setBackToDashboard(false)

        //getuserdetailsoflogin();
        ////console.log("yeh we editelegibilitydata", res.data.Data);
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          editelegibilitydata,
          idofuser,
          idofuser
        )
        ////console.log("no data sorry ", er);
      })
  }

  const [editinitialValue, setEditInitialValue] = React.useState({
    id: '',
    businessName: '',
    pincode: '',
    date: '',
    area: '',
    gst: '',
    industryname: '',
    grcno: '',
    bparea: '',
    govt: '',
    businesspan: '',
    entity: '',
    business: '',
    industry: '',
    industrygrp: '',
    descrip: '',
    companyprofile: ''
  })

  const getBusinessbybusinessId = async bussinessid => {
    //
    let getbusinesid = {
      bus_id: bussinessid
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`,
        getbusinesid
      )
      .then(res => {
        ////console.log("yeh we have business data by busis", res.data);
        let editdata1 = res.data.data
        getPincodeData(res.data.data.pincode)
        industrygrpcheck(editdata1.industry_type_id)
        industrynamecheck(editdata1.industry_group_id)

        /* let base64 = editdata1.company_profile1;
			let base64ArrayString = base64.split(";")[0].split("/");
			setCompanyProfileType1(base64ArrayString[1]);
			setbase64toimage(base64); */

        if (editdata1.company_profile_name1 == null) {
          setFileObj({
            ...fileObj,
            name: '',
            type: '',
            modifiedDate: '',
            base64String: ''
          })
        } else {
          setFileObj({
            ...fileObj,
            name: editdata1.company_profile_name1,
            type: editdata1.company_profile_type1,
            base64String: editdata1.company_profile1
          })
        }
        if (editdata1.company_profile_name2 == null) {
          setFileObj1({
            ...fileObj1,
            name: '',
            type: '',
            modifiedDate: '',
            base64String: ''
          })
        } else {
          setFileObj1({
            ...fileObj1,
            name: editdata1.company_profile_name2,
            type: editdata1.company_profile_type2,
            base64String: editdata1.company_profile2
          })
        }

        let copdate = moment(editdata1.setup_date).format('YYYY-MM-DD')
        getGstFromPan(editdata1.BPAN)
        //	getBusinessbybusinessId(editdata1.BPAN);
        //

        setEditInitialValue({
          ...editinitialValue,
          id: editdata1.id,
          businessName: editdata1.name,
          pincode: editdata1.pincode,
          date: copdate,
          area: Number(editdata1.pin_id),
          gst: editdata1.gst,
          industryname: editdata1.industry_name_id,
          grcno: editdata1.grc_no,
          bparea: editdata1.bus_premises_area,
          govt: editdata1.grc_type_id,
          businesspan: editdata1.BPAN,
          entity: editdata1.entity_id,
          business: editdata1.bus_premises_id,
          industry: editdata1.industry_type_id,
          industrygrp: editdata1.industry_group_id,
          descrip: editdata1.bus_desc,
          companyprofile: editdata1.company_profile1
          //companyprofile: imagepath
        })
        setGvmntReg(govt)
        if (Number(editdata1.Eligibility_flag) === 1) {
          setchecked('Check Eligibility')
        } else {
          setchecked('Loan Application')
        }
        ////console.log("editinitialValue", editinitialValue);
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          getbusinesid,
          idofuser,
          idofuser
        )
        ////console.log("no pincode sorry ", er);
      })
  }

  //delete Business by businessId
  const deleteBusinessByBusinessId = async bussinessid => {
    let getbusinesid = {
      bus_id: bussinessid
    }
    let api_path = apiCalling.coderscorner + `server.php/api/deletebusinessinfo`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/deletebusinessinfo`,
        getbusinesid
      )
      .then(res => {
        ////console.log("Deleted business data ", res.data);
        if (res.data.status === true) {
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
        }
        //	getuserdetailsoflogin();
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          getbusinesid,
          idofuser,
          idofuser
        )
        ////console.log("Something went Wrong While Deleting...!!!", er);
      })
  }
  const getPincodeData = async data => {
    let getPincode = {
      pincode: data,
      token: 'qwerty1234'
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
        getPincode
      )
      .then(res => {
        ////console.log("yeh we have pincode", res.data);
        return handleAllAreas(res.data.data)
      })
      .catch(er => {
        ////console.log("no pincode sorry ", er);
      })
  }

  const handleAllAreas = data => {
    let allArea = []
    for (let i = 0; i < data.length; i++) {
      allArea.push({ key: data[i].area, value: data[i].id, text: data[i].area })
    }
    return setArea(allArea)
  }

  const handlesubmiteditform = (values, { resetForm }) => {
    //
    setBackToDashboard(false)
    ///	settopform(false);
    setvisible(false)
    //setLoaderFlag(true);
    ////console.log("clicked")
    ////console.log('submitcheck', checkvalue)
    if (checkvalue == 'Loan Application') {
      Edithandleloanapply(values)
    } else {
      EdithandleEligibility(values)
    }
  }

  let editvalidator
  if (checkvalue == 'Loan Application') {
    editvalidator = businessInfoFormSchema
  } else {
    editvalidator = businessinfoschema
  }

  ////console.log('primaryid', primaryid)

  let validator
  if (checkvalue == 'Loan Application') {
    validator = businessInfoFormSchema
  } else {
    validator = businessinfoschema
  }

  const handlesubmitform = async (values, { resetForm }) => {
    setBackToDashboard(false)
    setLoaderFlag(false)
    //settopform(false)
    setvisible(false)
    setaddButtonFlag(false)
    setcollapse(true)
    setshowbusinesslist(false)
    //setLoaderFlag(true);
    ////console.log('submitcheck', checkvalue)
    if (checkvalue == 'Loan Application') {
      await handleloanapply(values)
    } else {
      await handleEligibility(values)
    }

    //props.handleCount();
  }

  const [pressedpan, setpressedpan] = React.useState()

  const [showInvalidMessage, setShowInvalidMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [karzabusinesname, setkarzabusinesname] = useState()
  const gstandpancheck = async pannm => {
    //
    let userid = 904
    setShowInvalidMessage(false)
    await axios
      .get(
        apiCalling.thirdParty +
          'public/apikaz?app_name=PAN&in_data=' +
          pannm +
          '&create=' +
          userid +
          '&modify=' +
          userid +
          ' '
      )
      .then(res => {
        ////console.log('karza pan validation name', res.data.result.name);
        ////console.log('karza pan validation', res.data.result);
        if (res.data.status_code == '101') {
          //setkarzabusinesname(res.data.result.name)
          //setShowInvalidMessage1(false);
          setkarzabusinesname(res.data.result.name)

          setInitialValue1({
            ...initialValue,
            businessName: res.data.result.name
          })
        } else {
          setErrorMessage1('please enter valid PAN ')
          setShowInvalidMessage1(true)
        }
      })
      .catch(error => {
        console.log(error)
      })

    // if (pressedgst == pressedpan) {
    // 	//console.log('pressedpan', pressedpan)
    // 	//console.log('pressedgst', pressedgst)
    // 	setShowInvalidMessage(false)
    // 	await axios.get(apiCalling.thirdParty + 'public/apikaz?app_name=PAN&in_data=' + pannm + '&create=Username&modify=username').then(res => {
    // 		////console.log('karza pan validation name', res.data.result.name);
    // 		////console.log('karza pan validation', res.data.result);
    // 		setkarzabusinesname(res.data.result.name)
    // 		setShowInvalidMessage(false)
    // 	})
    // } else {
    // 	//setcolorMessage("error");
    // 	setErrorMessage("PAN number must Match with Gst Number...!!!");
    // 	setShowInvalidMessage(true);
    // 	//setsavenextmessage(true);
    // }
  }

  const [maximumdate, setmaximumdate] = React.useState() //by default today date
  const validateincorporationdate = async () => {
    let data = {
      parameter_name: 'Incorporation Max Date'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGeneralSettingByParameter',
        data
      )
      .then(res => {
        ////console.log('date validation results', res.data.data);
        let maxyearparval = res.data.data[0].parameter_value
        ////console.log('maxyearparval...........', maxyearparval)
        const now = moment()
        const year = now.subtract(maxyearparval, 'years')
        let substracteddate = moment(year).format('YYYY-MM-DD')
        ////console.log('letsubstracteddate...........', substracteddate)
        setmaximumdate(substracteddate)
      })
  }

  const [minimumdate, setminimumdate] = React.useState() //3 years ago
  const validatemaxincorporationdate = async () => {
    let data = {
      parameter_name: 'Incorporation Min Date'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGeneralSettingByParameter',
        data
      )
      .then(res => {
        ////console.log('date validation results', res.data.data);
        let maxyearparval = res.data.data[0].parameter_value
        ////console.log('maxyearparval...........', maxyearparval)
        const now = moment()
        const year = now.subtract(maxyearparval, 'years')
        let substracteddate = moment(year).format('YYYY-MM-DD')
        ////console.log('letsubstracteddate...........', substracteddate)
        setminimumdate(substracteddate)
      })
  }

  const [maxFileSize, setMaxFileSize] = React.useState([]);
  const [maxFileSizeMsg, setMaxFileSizeMsg] = React.useState([]);
  const [allowedFileType, setAllowedFileType] = React.useState([]);

  const getValidationConfiguration = async () => {
      let data = {
      parameter_name: ['max_upload_size', 'allowed_file_type']
      }

      await axios
      .post(
          apiCalling.coderscorner + 'server.php/api/getGeneralSettingForMultiple',
          data
      )
      .then(result => {
          const res = result.data.data;
          setMaxFileSize(res.max_upload_size[0].parameter_value * 1048576);
          setMaxFileSizeMsg(`File size exceeds maximum limit of 2${res.max_upload_size[0].parameter_value}MB.`)
          setAllowedFileType(res.allowed_file_type[0].parameter_value.split(','));
      })
      .catch(error => {
          console.log(error)
      })
  }

  const [dropFileText, setDropFileText] = React.useState(
    'Drag & Drop file or Browse'
  )

  const [fileObj, setFileObj] = React.useState({
    name: '',
    type: '',
    modifiedDate: '',
    base64String: ''
  })
  const [fileObj1, setFileObj1] = React.useState({
    name: '',
    type: '',
    modifiedDate: '',
    base64String: ''
  })

  const fileSetInArray = async e => {
    //
    if (e.length > 0) {
      var date = new Date()
      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      var modifiedDate =
        date.getDate() +
        ' ' +
        months[date.getMonth()] +
        ' ' +
        date.getFullYear()
      let reader = new FileReader()
      reader.readAsDataURL(e[0])
      reader.onload = function () {
        let fileObj1 = {
          name: e[0].name,
          type: e[0].type,
          modifiedDate: modifiedDate,
          base64String: reader.result
        }
        setFileObj(fileObj1)
      }
      ////console.log(fileObj1)
      reader.onerror = function (error) {
        ////console.log('Error: ', error);
      }
    }
  }

  const fileSetInArray2 = async e => {
    //
    if (e.length > 0) {
      var date = new Date()
      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      var modifiedDate =
        date.getDate() +
        ' ' +
        months[date.getMonth()] +
        ' ' +
        date.getFullYear()
      let reader = new FileReader()
      reader.readAsDataURL(e[0])
      reader.onload = function () {
        let fileObj1 = {
          name: e[0].name,
          type: e[0].type,
          modifiedDate: modifiedDate,
          base64String: reader.result
        }
        setFileObj1(fileObj1)
      }
      reader.onerror = function (error) {
        ////console.log('Error: ', error);
      }
    }
  }

  const callFileDeleteOption = () => {
    setFileObj({
      name: '',
      type: '',
      modifiedDate: '',
      base64String: ''
    })
  }
  const callFileDeleteOption2 = () => {
    setFileObj1({
      name: '',
      type: '',
      modifiedDate: '',
      base64String: ''
    })
  }

  // const keyPress = (e) => {

  // 	////console.log(`Pressed keyCode ${e.target.value.length}`);
  // 	////console.log(`Pressed  ${e.target.value}`);
  // 	const index = 3;
  // 	// const panindexval = (e.target.value).charAt(index);
  // 	// ////console.log('pannnn',panindexval);
  // 	////console.log(`dddddd  ${e.key}`);
  // 	const panvalue = e.target.value;
  // 	////console.log('entered pan value', panvalue.charAt(index));
  // 	getPan();
  // 	// if (e.target.value.length === 10) {
  // 	////console.log('COME IF');
  // 	if (panvalue.charAt(index) === 'P') {
  // 		////console.log('COME P');
  // 		setentity([
  // 			{ key: undefined, value: 1, flag: "Proprietorship", text: "Proprietorship" },
  // 			{ key: undefined, value: 8, flag: "Doctor", text: "Doctor" },
  // 			{ key: undefined, value: 9, flag: "Lawyer", text: "Lawyer" }
  // 		])
  // 	}
  // 	if (panvalue.charAt(index) === 'A') {
  // 		////console.log('COME A');
  // 		setentity([{ key: undefined, value: 2, flag: "Partnership", text: "Partnership" },
  // 		{ key: undefined, value: 7, flag: "Society", text: "Society" }
  // 		])
  // 	}
  // 	if (panvalue.charAt(index) === 'C') {

  // 		setentity([
  // 			{ key: undefined, value: 4, flag: "Private Limited", text: "Private Limited" },
  // 			{ key: undefined, value: 5, flag: "Public Limited", text: "Public Limited" }
  // 		])
  // 	}
  // 	if (panvalue.charAt(index) === 'F') {

  // 		setentity([{ key: undefined, value: 3, flag: "Limited Liability Partnership", text: "Limited Liability Partnership" }])
  // 	}
  // 	if (panvalue.charAt(index) === 'T') {

  // 		setentity([{ key: undefined, value: 6, flag: "Trust", text: "Trust" }])
  // 	}
  // 	if (panvalue.charAt(index) != 'P' && panvalue.charAt(index) != 'A' && panvalue.charAt(index) != 'C' && panvalue.charAt(index) != 'F' && panvalue.charAt(index) != 'T') {
  // 		setentity([{ key: undefined, value: 0, flag: "", text: "" }])
  // 	}
  // 	//e.preventDefault();
  // 	// }
  // }
  let docDetails = {
    formId: '1.1.2',
    parentId: props.BusEditId,
    parentTypeId: 1
  }

  //check if PAN already exists in Add new Business Page
  const [showInvalidMessage1, setShowInvalidMessage1] = useState(false)
  const [errorMessage1, setErrorMessage1] = React.useState()
  const [pressedgst, setpressedgst] = React.useState()

  const checkPanExists = e => {
    //
    console.log(e.target.value)
    ///	let fouthletter = (e.target.value).chartAt(3);

    let fouth = e.target.value.split('')
    console.log(fouth[3])

    let entityType
    let fouthletter = fouth[3]

    if (fouthletter == 'C') {
      entityType = 1
    } else {
      entityType = 2
    }

    // setInitialValue1({
    //   ...initialValue1,
    //   entityType: 2,
    //   businesspan: e.target.value
    // })

    //getting gst value on keypress and getting pan value from gst to match both
    //
    let businesgst = e.target.value
    setpressedgst(businesgst)
    let panval = e.target.value
    gstandpancheck(panval)
    setShowInvalidMessage1(false)
    const checkuserloggeddata = localStorage.getItem('userdata')
    let checkuserid = JSON.parse(checkuserloggeddata)
    //
    let data
    if (localStorage.getItem('userbycpa')) {
      data = {
        user_id: localStorage.getItem('userbycpa')
      }
    } else {
      data = {
        user_id: checkuserid.id
      }
    }
    axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
        data
      )
      .then(result => {
        if (result.data.status) {
          ////console.log(result.data);

          for (var i = 0; i < result.data.data.length; i++) {
            let panval2 = result.data.data[i].BPAN
            if (panval2 == panval) {
              setShowInvalidMessage1(true)
              setErrorMessage1('PAN Number already exists...!!!')
            } else if (panval == '') {
              setShowInvalidMessage1(false)
              setErrorMessage1('')
            }
          }
        }
      })

    getGstFromPan(e.target.value)
  }

  // pan to gst api
  const [gstArray, setGstArray] = React.useState([])
  const getGstFromPan = async PAN => {
    //
    let data = {
      app_name: 'GSTSEARCH',
      in_data: PAN,
      create: 640,
      modify: 640
    }

    let tempArr = []
    axios
      .post(apiCalling.thirdParty + 'server.php/apikarz', data)
      .then(res => {
        console.log(res)
        //
        const { app_requset, result, status_code } = res.data
        if (status_code === '101') {
          if (result.length > 0) {
            result.map(item => {
              tempArr.push({ value: item.gstinId, text: item.gstinId })
            })
            setGstArray(tempArr)
          } else {
            setErrorMessage('Gst not available for this ')
            setShowInvalidMessage(true)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  //check if BusinessName already exists in Add new Business Page
  const [showInvalidMessage2, setShowInvalidMessage2] = useState(false)
  const [errorMessage2, setErrorMessage2] = React.useState()
  const checkBusinessExists = e => {
    let business_name = e.target.value

    setShowInvalidMessage2(false)

    const checkuserloggeddata = localStorage.getItem('userdata')
    let checkuserid = JSON.parse(checkuserloggeddata)
    //

    let data = {}

    if (localStorage.getItem('userbycpa')) {
      data = {
        user_id: localStorage.getItem('userbycpa')
      }
    }
    data = {
      user_id: checkuserid.id
    }
    axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
        data
      )
      .then(result => {
        if (result.data.status) {
          for (var j = 0; j < result.data.data.length; j++) {
            if (
              business_name === result.data.data[j].name &&
              business_name != ''
            ) {
              setShowInvalidMessage2(true)
              setErrorMessage2('Business already exists...!!!')
              break
            } else if (business_name == '') {
              setShowInvalidMessage2(true)
              setErrorMessage2('Name is required')
            }
          }
        }
      })
  }

  const [gvmntReg, setGvmntReg] = useState()
  const getGstByPan = async () => {
    //

    let data = {
      app_name: 'GSTSEARCH',
      in_data: 'AGNPJ0999A',
      create: 640,
      modify: 640
    }
    //

    await axios
      .post(apiCalling.thirdParty + 'server.php/apikarz', data)
      .then(result => {
        console.log(result => {
          console.log(result)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  //#region  All Forms Section start
  return (
    <Container maxWidth='lg'>
      {showform == true && (
        <Formik
          enableReinitialize
          initialValues={initialValue1}
          //	validationSchema={validator}
          onSubmit={handlesubmitform}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched
            /* and other goodies */
          }) => {
            //////console.log("values in formik", values);
            return (
              <form onSubmit={handleSubmit}>
                <div className='mini-container mg_btm_set'>
                  {/* <DocumentUpload  formdetails={docDetails} /> */}
                  <Row>
                    <Col md={{ span: 12 }}>
                      <div className='content-header'>
                        <Row>
                          {showbusinessheader == true && collapsedata == false && (
                            <>
                              <Col md={{ span: 10 }}>
                                <Typography
                                  text='Business Information'
                                  styleType='C'
                                />
                              </Col>
                              <Col md={{ span: 2 }}>
                                <span className='image'>
                                  <img
                                    alt='image1'
                                    className='backbutton navbutton'
                                    src={Back}
                                    onClick={handledata}
                                  />
                                  <Tooltip
                                    title='Back'
                                    classes={{
                                      tooltip: classes.customTooltip,
                                      arrow: classes.customArrow
                                    }}
                                    arrow
                                  >
                                    <img
                                      alt='image1'
                                      className='backbutton navgraybutton'
                                      src={grayback}
                                      onClick={handledata}
                                    />
                                  </Tooltip>
                                </span>
                              </Col>
                            </>
                          )}
                          {addNewBusinessHeader == true &&
                            collapsedata == false && (
                              <Col md={{ span: 6 }}>
                                <Typography
                                  text='Add New Business'
                                  styleType='C'
                                />
                              </Col>
                            )}
                          {addNewBusinessHeader == false && (
                            <Col md={{ span: 6 }}></Col>
                          )}
                          <Col md={{ span: 4 }}></Col>
                          {addButtonFlag == true && (
                            <Col md={{ span: 2 }}>
                              {/* <Button className="backBtn" variant="outlined" color="primary" onClick={handlenew}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Business</Button> */}
                              {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
																<img className='backbutton' src={Back} onClick={handledata} />
															</Tooltip> */}
                              <span className='image'>
                                <img
                                  alt='image1'
                                  className='backbutton navbutton'
                                  src={Back}
                                  onClick={handlenew}
                                />
                                <Tooltip
                                  title='Back'
                                  classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                  }}
                                  arrow
                                >
                                  <img
                                    alt='image1'
                                    className='backbutton navgraybutton'
                                    src={grayback}
                                    onClick={handlenew}
                                  />
                                </Tooltip>
                              </span>
                            </Col>
                          )}
                          {showbusinesslist == true && (
                            // <Col md={{ span: 2 }}>
                            // 	<Button className="backBtn" variant="outlined" color="primary" onClick={handlelist}><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                            // </Col>
                            <span className='image'>
                              <img
                                alt='image1'
                                className='backbutton navbutton'
                                src={Back}
                                onClick={handlelist}
                              />
                              <Tooltip title='Back' arrow>
                                <img
                                  alt='image1'
                                  className='backbutton navgraybutton'
                                  src={grayback}
                                  onClick={handlelist}
                                />
                              </Tooltip>
                            </span>
                          )}
                          {backToDashboard == true && (
                            <>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}>
                                <span className='image'>
                                  <img
                                    alt='image1'
                                    className='navbutton'
                                    src={Back}
                                    onClick={handledata}
                                  />
                                  <Tooltip
                                    title='Back'
                                    classes={{
                                      tooltip: classes.customTooltip,
                                      arrow: classes.customArrow
                                    }}
                                    arrow
                                  >
                                    <img
                                      alt='image1'
                                      className='navgraybutton'
                                      src={grayback}
                                      onClick={handledata}
                                    />
                                  </Tooltip>
                                </span>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                      {/* //#region  Save form */}

                      {loaderFlag == true && (
                        <div style={{ textAlign: 'center', marginTop: '15%' }}>
                          <Loader
                            type={LoaderColor.type}
                            color={LoaderColor.color}
                          />
                        </div>
                      )}
                      {docForm == true && (
                        <DocumentUpload formdetails={docDetails} />
                      )}
                      {topform == true && visible == false && (
                        <Row>
                          {showIfRegisterData == false && (
                            <>
                              <Col md={{ span: 6 }}>
                                <Typography text='Business PAN' styleType='B' />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <TextField
                                      style={{ marginTop: '9px' }}
                                      id='filled-error-helper-text standard-basic'
                                      name='businesspan'
                                      onChange={handleChange}
                                      //onKeyUp={keyPressforGst}
                                      onBlur={e => {
                                        
                                        checkPanExists(e)

                                        let fouth = e.target.value.split('')
                                        let fouthletter = fouth[3]
                                        let entityType = 1

                                        if (fouthletter == 'P') {
                                          entityType = 1
                                        } else if ( fouthletter == 'A') {
                                          entityType = 6
                                        } else if (fouthletter ==  'C') {
                                          entityType = 3
                                        }
                                        setFieldValue('entity', entityType)
                                      }}
                                      InputProps={{
                                        className: values.businesspan
                                          ? classes.manorma
                                          : null
                                      }}
                                      inputProps={{ maxLength: 10 }}
                                      value={values.businesspan}
                                      error={
                                        errors.businesspan &&
                                        touched.businesspan &&
                                        errors.businesspan
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.businesspan &&
                                        touched.businesspan &&
                                        errors.businesspan
                                      }
                                    />
                                  </ListItem>
                                </List>
                                <div>
                                  {showInvalidMessage1 === true && (
                                    <span className='valMsg'>
                                      {' '}
                                      {errorMessage1}
                                    </span>
                                  )}
                                </div>
                              </Col>
                              {/*

																<Col md={{ span: 6 }}>
																<Typography text="GST Number" styleType="B" />
																<List component="div" disablePadding>
																	<ListItem>
																		<TextField
																			id="filled-error-helper-text standard-basic" name="gst"
																			label=" 99AAAAA9999A1A9"
																			onChange={handleChange}
																			//onBlur={handleBlur}
																			onBlur={keypressforPan}
																			InputProps={{ className: values.gst ? classes.manorma : null, }}
																			inputProps={{ maxLength: 15, }}
																			error={errors.gst && touched.gst && errors.gst ? true : false}
																			value={values.gst}
																			helperText={errors.gst && touched.gst && errors.gst} />

																	</ListItem>
																</List>
																<div>
																	{showInvalidMessage === true && (<span className="valMsg"> {errorMessage}</span>)}
																</div>
															</Col>
																 */}

                              <Col md={{ span: 6 }}>
                                <Typography text='GST' styleType='B' />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <Dropdown
                                      placeholder='Select GST'
                                      id='gst'
                                      name='gst'
                                      fluid
                                      search
                                      selection
                                      //	value={values.gst}
                                      onBlur={() =>
                                        setFieldTouched('gst', true)
                                      }
                                      onChange={(e, { value }) =>
                                        setFieldValue('gst', value)
                                      }
                                      options={gstArray}
                                      className={
                                        errors.gst && touched.gst
                                          ? classes.required
                                          : values.gst == ''
                                          ? classes.reqired
                                          : classes.man
                                      }
                                    />
                                  </ListItem>
                                </List>
                                {errors.gst &&
                                  touched.gst &&
                                  values.area == '' && (
                                    <div className={classes.selectError}>
                                      {errors.area}
                                    </div>
                                  )}
                              </Col>

                              <Col md={{ span: 6 }}>
                                <Typography
                                  text='Business Name'
                                  styleType='B'
                                />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <TextField
                                      id='filled-error-helper-text standard-basic'
                                      readOnly={true}
                                      label='Name as per Business PAN'
                                      name='businessName'
                                      onChange={handleChange}
                                      onBlur={checkBusinessExists}
                                      InputProps={{
                                        className: values.businessName
                                          ? classes.manorma
                                          : null
                                      }}
                                      value={values.businessName}
                                      //value={karzabusinesname}
                                    />
                                  </ListItem>
                                </List>
                                <div>
                                  {showInvalidMessage2 === true && (
                                    <span className='valMsg'>
                                      {' '}
                                      {errorMessage2}
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </>
                          )}
                          {showIfRegisterData == true && (
                            <>
                              <Col md={{ span: 6 }}>
                                <Typography text='Business PAN' styleType='B' />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <TextField
                                      style={{ marginTop: '9px' }}
                                      id='filled-error-helper-text standard-basic'
                                      name='businesspan'
                                      onChange={handleChange}
                                      //onKeyUp={keyPressforGst}
                                      onBlur={checkPanExists}
                                      InputProps={{
                                        className: values.businesspan
                                          ? classes.manorma
                                          : null
                                      }}
                                      inputProps={{ maxLength: 10 }}
                                      value={values.businesspan}
                                      error={
                                        errors.businesspan &&
                                        touched.businesspan &&
                                        errors.businesspan
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.businesspan &&
                                        touched.businesspan &&
                                        errors.businesspan
                                      }
                                    />
                                  </ListItem>
                                </List>
                                <div>
                                  {showInvalidMessage1 === true && (
                                    <span className='valMsg'>
                                      {' '}
                                      {errorMessage1}
                                    </span>
                                  )}
                                </div>
                              </Col>
                              <Col md={{ span: 6 }}>
                                <Typography text='GST Number' styleType='B' />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <Dropdown
                                      placeholder='Select GST'
                                      id='gst'
                                      name='gst'
                                      fluid
                                      search
                                      selection
                                      value={values.gst}
                                      onBlur={() =>
                                        setFieldTouched('gst', true)
                                      }
                                      onChange={(e, { value }) =>
                                        setFieldValue('gst', value)
                                      }
                                      options={gstArray}
                                      className={
                                        errors.gst && touched.gst
                                          ? classes.required
                                          : values.gst == ''
                                          ? classes.reqired
                                          : classes.man
                                      }
                                    />

                                    {/* {showInvalidMessage === true && (<span className="valMsg"> {errorMessage}</span>)} */}
                                  </ListItem>
                                </List>
                                <div>
                                  {showInvalidMessage === true && (
                                    <span className='valMsg'>
                                      {' '}
                                      {errorMessage}
                                    </span>
                                  )}
                                </div>
                              </Col>

                              <Col md={{ span: 6 }}>
                                <Typography
                                  text='Business Name'
                                  styleType='B'
                                />
                                <List component='div' disablePadding>
                                  <ListItem>
                                    <TextField
                                      id='filled-error-helper-text standard-basic'
                                      readOnly={true}
                                      label='Name as per Business PAN'
                                      name='businessName'
                                      onChange={handleChange}
                                      onBlur={checkBusinessExists}
                                      InputProps={{
                                        className: values.businessName
                                          ? classes.manorma
                                          : null
                                      }}
                                      value={values.businessName}
                                      //value={karzabusinesname}
                                    />
                                  </ListItem>
                                </List>
                                <div>
                                  {showInvalidMessage2 === true && (
                                    <span className='valMsg'>
                                      {' '}
                                      {errorMessage2}
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </>
                          )}
                          <Col md={{ span: 6 }}>
                            <Typography
                              text='Date of Incorporation / Setup'
                              styleType='B'
                            />
                            <List component='div' disablePadding>
                              <ListItem>
                                <TextField
                                  id='date'
                                  type='date'
                                  className={classes.textField}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ marginTop: '9px' }}
                                  InputProps={{
                                    inputProps: {
                                      min: minimumdate,
                                      max: maximumdate
                                    },
                                    className: values.date
                                      ? classes.manorma
                                      : null
                                  }}
                                  error={
                                    errors.date && touched.date && errors.date
                                      ? true
                                      : false
                                  }
                                  value={values.date}
                                  helperText={
                                    errors.date && touched.date && errors.date
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text='Pincode' styleType='B' />
                            <List component='div' disablePadding>
                              <ListItem>
                                <TextField
                                  id='filled-error-helper-text standard-basic'
                                  name='pincode'
                                  label='999999'
                                  onChange={handleChange}
                                  onBlur={e => {
                                    handleBlur(e)
                                    if (!errors.pincode) {
                                      getPincodeData(values.pincode)
                                    }
                                  }}
                                  error={
                                    errors.pincode &&
                                    touched.pincode &&
                                    errors.pincode
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.pincode
                                      ? classes.manorma
                                      : null
                                  }}
                                  inputProps={{
                                    maxLength: 6
                                  }}
                                  value={values.pincode}
                                  helperText={
                                    errors.pincode &&
                                    touched.pincode &&
                                    errors.pincode
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text='Area' styleType='B' />
                            <List component='div' disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder='Select Area'
                                  id='area'
                                  name='area'
                                  fluid
                                  search
                                  selection
                                  value={values.area}
                                  onBlur={() => setFieldTouched('area', true)}
                                  onChange={(e, { value }) =>
                                    setFieldValue('area', value)
                                  }
                                  options={area}
                                  className={
                                    errors.area && touched.area
                                      ? classes.required
                                      : values.area == ''
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                            </List>
                            {errors.area &&
                              touched.area &&
                              values.area == '' && (
                                <div className={classes.selectError}>
                                  {errors.area}
                                </div>
                              )}
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text=' Entity Type' styleType='B' />
                            <List component='div' disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder='Select Entity Type'
                                  id='entity'
                                  name='entity'
                                  fluid
                                  search
                                  selection
                                  value={values.entity}
                                  onBlur={() => setFieldTouched('entity', true)}
                                  onChange={(e, { value }) =>
                                    setFieldValue('entity', value)
                                  }
                                  options={entity}
                                  className={
                                    errors.entity && touched.entity
                                      ? classes.required
                                      : values.entity == ''
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                            </List>
                            {errors.entity &&
                              touched.entity &&
                              values.entity == '' && (
                                <div className={classes.selectError}>
                                  {errors.entity}
                                </div>
                              )}
                          </Col>

                          <Col md={{ span: 6 }}>
                            <Typography
                              text=' Business Premises'
                              styleType='B'
                            />
                            <List component='div' disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder='Select Business Premises'
                                  id='business'
                                  name='business'
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched('business', true)
                                  }
                                  onChange={(e, { value }) =>
                                    setFieldValue('business', value)
                                  }
                                  value={values.business}
                                  options={business}
                                  className={
                                    errors.business && touched.business
                                      ? classes.required
                                      : values.business == ''
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                              {errors.business &&
                                touched.business &&
                                values.business == '' && (
                                  <div className={classes.selectError}>
                                    {errors.business}
                                  </div>
                                )}
                            </List>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <Typography text=' Industry Type' styleType='B' />
                            <List component='div' disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder='Select Industry Type'
                                  id='industry'
                                  name='industry'
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched('industry', true)
                                  }
                                  onChange={(e, { name, value }) => {
                                    setFieldValue('industry', value)
                                    industrygrpcheck(value)
                                    setFieldValue('industryname', 0)
                                    setindustryname([])
                                    //industrynamecheck(0)
                                  }}
                                  value={values.industry}
                                  options={industry}
                                  className={
                                    errors.industry && touched.industry
                                      ? classes.required
                                      : values.industry == ''
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                              {errors.industry &&
                                touched.industry &&
                                values.industry == '' && (
                                  <div className={classes.selectError}>
                                    {errors.industry}
                                  </div>
                                )}
                            </List>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <Typography text='Industry Group' styleType='B' />
                            <List component='div' disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder='Select Industry Group'
                                  id='industrygrp'
                                  name='industrygrp'
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched('industrygrp', true)
                                  }
                                  onChange={(e, { name, value }) => {
                                    setFieldValue('industrygrp', value)
                                    industrynamecheck(value)
                                  }}
                                  value={values.industrygrp}
                                  options={industrygrp}
                                  className={
                                    errors.industrygrp && touched.industrygrp
                                      ? classes.required
                                      : values.industrygrp == ''
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                              {errors.industrygrp &&
                                touched.industrygrp &&
                                values.industrygrp == '' && (
                                  <div className={classes.selectError}>
                                    {errors.industrygrp}
                                  </div>
                                )}
                            </List>
                          </Col>

                          {checkvalue == 'Loan Application' && (
                            <Col md={{ span: 6 }}>
                              <Typography
                                text='Industry Name'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder='Select Industry Name'
                                    id='industryname'
                                    name='industryname'
                                    fluid
                                    search
                                    selection
                                    onBlur={() =>
                                      setFieldTouched('industryname', true)
                                    }
                                    onChange={(e, { name, value }) =>
                                      setFieldValue('industryname', value)
                                    }
                                    value={values.industryname}
                                    options={industryname}
                                    className={
                                      errors.industryname &&
                                      touched.industryname
                                        ? classes.required
                                        : values.industryname == ''
                                        ? classes.reqired
                                        : classes.man
                                    }
                                  />
                                </ListItem>
                                {errors.industryname &&
                                  touched.industryname &&
                                  values.industryname == '' && (
                                    <div className={classes.selectError}>
                                      {errors.industryname}
                                    </div>
                                  )}
                              </List>
                            </Col>
                          )}

                          {checkvalue == 'Loan Application' && (
                            <Col md={{ span: 6 }}>
                              <Typography
                                text='Business Premises Dimension in sqft'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic'
                                    name='bparea'
                                    label='Enter Business Premises Area in sqft'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.bparea
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.bparea &&
                                      touched.bparea &&
                                      errors.bparea
                                        ? true
                                        : false
                                    }
                                    value={values.bparea}
                                    helperText={
                                      errors.bparea &&
                                      touched.bparea &&
                                      errors.bparea
                                    }
                                  />
                                </ListItem>
                              </List>
                              {errors.bparea &&
                                touched.bparea &&
                                values.bparea == '' && (
                                  <div className={classes.selectError}>
                                    {errors.bparea}
                                  </div>
                                )}
                            </Col>
                          )}
                          {checkvalue == 'Loan Application' && (
                            <Col md={{ span: 6 }}>
                              <Typography
                                text=' Registration Certificate'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder='Select Registration Certificate'
                                    id='govt'
                                    name='govt'
                                    fluid
                                    search
                                    selection
                                    onBlur={() => setFieldTouched('govt', true)}
                                    onChange={(e, { value }) => {
                                      setFieldValue('govt', value)
                                      console.log(value)
                                      setGvmntReg(value)
                                    }}
                                    value={values.govt}
                                    options={govt}
                                    className={
                                      errors.govt && touched.govt
                                        ? classes.required
                                        : values.govt == ''
                                        ? classes.reqired
                                        : classes.man
                                    }
                                  />
                                </ListItem>
                                {errors.govt &&
                                  touched.govt &&
                                  values.govt == '' && (
                                    <div className={classes.selectError}>
                                      {errors.govt}
                                    </div>
                                  )}
                              </List>
                            </Col>
                          )}
                          {checkvalue == 'Loan Application' && gvmntReg != 174 && (
                            <Col md={{ span: 6 }}>
                              <Typography
                                text='Registration Certificate Number'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic'
                                    label='Enter Registration Certificate Number'
                                    name='grcno'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.grcno
                                        ? classes.manorma
                                        : null
                                    }}
                                    value={values.grcno}
                                  />
                                </ListItem>
                              </List>
                            </Col>
                          )}
                          {checkvalue == 'Loan Application' && (
                            <Col md={{ span: 12 }}>
                              <Row>
                                <Col md={{ span: 6 }}>
                                  <Typography
                                    text='Business Description'
                                    styleType='B'
                                  />
                                  <List component='div'>
                                    <ListItem style={{ padding: '5px 0px' }}>
                                      <Col
                                        md={{ span: 12 }}
                                        style={{ padding: '0px 0px' }}
                                      >
                                        <TextArea
                                          id='filled-error-helper-text'
                                          name='descrip'
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            errors.descrip &&
                                            touched.descrip &&
                                            errors.descrip
                                              ? true
                                              : false
                                          }
                                          className={
                                            errors.descrip && touched.descrip
                                              ? classes.required
                                              : values.descrip !== ''
                                              ? classes.mano
                                              : null
                                          }
                                          helperText={
                                            errors.descrip &&
                                            touched.descrip &&
                                            errors.descrip
                                          }
                                          value={values.descrip}
                                        />
                                      </Col>
                                    </ListItem>
                                    {errors.descrip && touched.descrip && (
                                      <div className={classes.selectError}>
                                        {errors.descrip}
                                      </div>
                                    )}
                                  </List>
                                </Col>

                                <Col md={{ span: 6 }}>
                                  <Typography
                                    text='Company Profile'
                                    styleType='B'
                                  />
                                  <List component='div'>
                                    <ListItem
                                      style={{
                                        paddin: '5px 14px 0px 4px',
                                        display: 'block'
                                      }}
                                    >
                                      {/* <Row>
																				<Col md={{ span: 12 }}>
																					<div {...getRootProps({ className: "dropzone" })}>
																						<input {...getInputProps()} />
																						<p>upload Here</p>
																					</div>
																					<aside>
																						<ul>{files}</ul>
																					</aside>
																				</Col>
																			</Row> */}
                                      <Row>
                                        {fileObj.name == '' && (
                                          <Col md={{ span: 6 }}>
                                            <DropzoneArea
                                              onChange={files =>
                                                fileSetInArray(files)
                                              }
                                              Icon={AttachFile}
                                              dropzoneText={dropFileText}
                                              maxFileSize={maxFileSize}
                                              acceptedFiles={allowedFileType}                                  
                                              alertSnackbarProps={{
                                                anchorOrigin: {
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }
                                              }}
                                              showPreviews={false}
                                              showPreviewsInDropzone={false}
                                            />
                                          </Col>
                                        )}
                                        {fileObj.name != '' && (
                                          <Col
                                            md={{ span: 6 }}
                                            style={{ padding: '10px' }}
                                          >
                                            <Row>
                                              <Col md={{ span: 1 }}>
                                                {fileObj.type ==
                                                  'application/pdf' && (
                                                  <i
                                                    class='far fa-file-pdf'
                                                    style={{
                                                      color: '#ff0000',
                                                      fontSize: '50px'
                                                    }}
                                                  ></i>
                                                )}
                                              </Col>
                                              <Col
                                                md={{ span: 8 }}
                                                style={{ left: '4%' }}
                                              >
                                                <p className='uploadedFileName'>
                                                  {fileObj.name}
                                                </p>
                                                <img
                                                  alt='image1'
                                                  src={calander}
                                                  style={{ width: '10%' }}
                                                />
                                                &nbsp;&nbsp;
                                                <span>
                                                  {fileObj.modifiedDate}
                                                </span>
                                              </Col>
                                              <Col
                                                md={{ span: 3 }}
                                                className='fileUploadAction'
                                              >
                                                <div style={{ float: 'right' }}>
                                                  <i
                                                    className='fas fa-eye'
                                                    style={{
                                                      fontSize: '15px',
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                      handleshowhidepdf(
                                                        fileObj.base64String
                                                      )
                                                    }
                                                  ></i>{' '}
                                                  &nbsp;
                                                  {/* <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={callFileDeleteOption}></i> */}
                                                  <img
                                                    src={deleterec}
                                                    alt='imgs'
                                                    className='docuploadicon'
                                                    onClick={
                                                      callFileDeleteOption
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        )}
                                        {fileObj1.name == '' && (
                                          <Col md={{ span: 6 }}>
                                            <DropzoneArea
                                              onChange={files =>
                                                fileSetInArray2(files)
                                              }
                                              maxFileSize={maxFileSize}
                                              acceptedFiles={allowedFileType}                                  
                                              Icon={AttachFile}
                                              dropzoneText={dropFileText}
                                              alertSnackbarProps={{
                                                anchorOrigin: {
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                                }
                                              }}
                                              showPreviews={false}
                                              showPreviewsInDropzone={false}
                                            />
                                          </Col>
                                        )}
                                        {fileObj1.name != '' && (
                                          <Col
                                            md={{ span: 6 }}
                                            style={{ padding: '10px' }}
                                          >
                                            <Row>
                                              <Col md={{ span: 1 }}>
                                                {fileObj1.type ==
                                                  'application/pdf' && (
                                                  <i
                                                    class='far fa-file-pdf'
                                                    style={{
                                                      color: '#ff0000',
                                                      fontSize: '50px'
                                                    }}
                                                  ></i>
                                                )}
                                              </Col>
                                              <Col
                                                md={{ span: 8 }}
                                                style={{ left: '4%' }}
                                              >
                                                {/* <Typography text={fileObj1.name} styleType="B" /><br></br> */}
                                                <p className='uploadedFileName'>
                                                  {fileObj1.name}
                                                </p>
                                                <img
                                                  alt='image1'
                                                  src={calander}
                                                  style={{ width: '10%' }}
                                                />
                                                &nbsp;&nbsp;
                                                <span>
                                                  {fileObj1.modifiedDate}
                                                </span>
                                              </Col>
                                              <Col
                                                md={{ span: 3 }}
                                                className='fileUploadAction'
                                              >
                                                <div style={{ float: 'right' }}>
                                                  <i
                                                    className='fas fa-eye'
                                                    style={{
                                                      fontSize: '15px',
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                      handleshowhidepdf(
                                                        fileObj1.base64String
                                                      )
                                                    }
                                                  ></i>{' '}
                                                  &nbsp;
                                                  {/* <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={callFileDeleteOption2}></i> */}
                                                  <img
                                                    alt='image1'
                                                    src={deleterec}
                                                    className='docuploadicon'
                                                    onClick={
                                                      callFileDeleteOption2
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        )}
                                      </Row>
                                    </ListItem>
                                  </List>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      )}
                      {/* //#endregion end save form */}

                      {/* //#region  edit form */}
                      {topform == false && visible == true && (
                        <div
                          className={
                            editData == true && collapsedata == true
                              ? 'information-change'
                              : 'information-content'
                          }
                        >
                          {/* <Collapse className={classes.bussi_collapse} in={open} timeout="auto" unmountOnExit> */}
                          {/* <Container maxWidth="lg"> */}
                          {collapsedata == true && (
                            <Formik
                              enableReinitialize
                              initialValues={editinitialValue}
                              //	validationSchema={editvalidator}
                              onSubmit={handlesubmiteditform}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched
                              }) => {
                                return (
                                  <form onSubmit={handleSubmit}>
                                    <Row>
                                      <Col md={{ span: 10 }}>
                                        <Typography
                                          text={
                                            `Editing Details for Business : ` +
                                            values.businessName
                                          }
                                          styleType='D'
                                        />
                                      </Col>
                                      <Col md={{ span: 2 }}>
                                        {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
																					<img className='backbutton' src={Back} onClick={handledata} />
																				</Tooltip> */}
                                        <span className='image'>
                                          <img
                                            alt='image1'
                                            className='backbutton navbutton'
                                            src={Back}
                                            onClick={handledata}
                                          />
                                          <Tooltip
                                            title='Back'
                                            classes={{
                                              tooltip: classes.customTooltip,
                                              arrow: classes.customArrow
                                            }}
                                            arrow
                                          >
                                            <img
                                              alt='image1'
                                              className='backbutton navgraybutton'
                                              src={grayback}
                                              onClick={handledata}
                                            />
                                          </Tooltip>
                                        </span>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text=' Business PAN'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              readOnly={true}
                                              name='businesspan'
                                              /* label="AAAAA9999A" */ onChange={
                                                handleChange
                                              }
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.businesspan
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              inputProps={{ maxLength: 10 }}
                                              error={
                                                errors.businesspan &&
                                                touched.businesspan &&
                                                errors.businesspan
                                                  ? true
                                                  : false
                                              }
                                              value={values.businesspan}
                                              helperText={
                                                errors.businesspan &&
                                                touched.businesspan &&
                                                errors.businesspan
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography text='GST' styleType='B' />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select GST'
                                              id='gst'
                                              name='gst'
                                              fluid
                                              search
                                              selection
                                              value={values.gst}
                                              onBlur={() =>
                                                setFieldTouched('gst', true)
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue('gst', value)
                                              }
                                              options={gstArray}
                                              className={
                                                errors.gst && touched.gst
                                                  ? classes.required
                                                  : values.gst == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.gst &&
                                          touched.gst &&
                                          values.area == '' && (
                                            <div
                                              className={classes.selectError}
                                            >
                                              {errors.area}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Business Name'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic '
                                              readOnly={true}
                                              label='Name as per Business PAN'
                                              name='businessName'
                                              onChange={
                                                editData == true
                                                  ? handleChange
                                                  : null
                                              }
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className:
                                                  editData == true
                                                    ? classes.manorma
                                                    : classes.shub
                                              }}
                                              // error={errors.businessName && touched.businessName && errors.businessName ? true : false}
                                              value={values.businessName}
                                              // helperText={errors.businessName && touched.businessName && errors.businessName}
                                            />
                                          </ListItem>
                                        </List>
                                        <div>
                                          {showInvalidMessage2 === true && (
                                            <span className='valMsg'>
                                              {' '}
                                              {errorMessage2}
                                            </span>
                                          )}
                                        </div>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Date of Incorporation / Setup'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='date'
                                              type='date'
                                              className={classes.textField}
                                              name='date'
                                              onChange={
                                                editData == true
                                                  ? handleChange
                                                  : null
                                              }
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className:
                                                  editData == true
                                                    ? classes.manorma
                                                    : classes.shub
                                              }}
                                              error={
                                                errors.date &&
                                                touched.date &&
                                                errors.date
                                                  ? true
                                                  : false
                                              }
                                              value={values.date}
                                              helperText={
                                                errors.date &&
                                                touched.date &&
                                                errors.date
                                              }
                                              style={{ marginTop: '9px' }}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Pincode'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              name='pincode'
                                              label='999999'
                                              onChange={handleChange}
                                              onBlur={e => {
                                                handleBlur(e)
                                                if (!errors.pincode) {
                                                  getPincodeData(values.pincode)
                                                }
                                              }}
                                              error={
                                                errors.pincode &&
                                                touched.pincode &&
                                                errors.pincode
                                                  ? true
                                                  : false
                                              }
                                              InputProps={{
                                                className: values.pincode
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              inputProps={{
                                                maxLength: 6
                                              }}
                                              value={values.pincode}
                                              helperText={
                                                errors.pincode &&
                                                touched.pincode &&
                                                errors.pincode
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography text='Area' styleType='B' />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Area'
                                              id='area'
                                              name='area'
                                              fluid
                                              search
                                              selection
                                              value={values.area}
                                              onBlur={() =>
                                                setFieldTouched('area', true)
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue('area', value)
                                              }
                                              options={area}
                                              className={
                                                errors.area && touched.area
                                                  ? classes.required
                                                  : values.area == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text=' Entity Type'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Entity Type'
                                              id='entity'
                                              name='entity'
                                              fluid
                                              search
                                              selection
                                              value={values.entity}
                                              onBlur={() =>
                                                setFieldTouched('entity', true)
                                              }
                                              onChange={
                                                editData == true
                                                  ? (e, { value }) =>
                                                      setFieldValue(
                                                        'entity',
                                                        value
                                                      )
                                                  : null
                                              } //   onChange={(e, { value }) => setFieldValue("entity", value)}
                                              options={entity}
                                              className={
                                                errors.entity && touched.entity
                                                  ? classes.required
                                                  : editData == false
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.entity &&
                                          touched.entity &&
                                          values.entity == '' && (
                                            <div
                                              className={classes.selectError}
                                            >
                                              {errors.entity}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text=' Business Premises'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Business Premises'
                                              id='business'
                                              name='business'
                                              fluid
                                              search
                                              selection
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'business',
                                                  true
                                                )
                                              }
                                              onChange={
                                                editData == true
                                                  ? (e, { value }) =>
                                                      setFieldValue(
                                                        'business',
                                                        value
                                                      )
                                                  : null
                                              } //      onChange={(e, { value }) => setFieldValue("business", value)}
                                              value={values.business}
                                              options={business}
                                              className={
                                                errors.business &&
                                                touched.business
                                                  ? classes.required
                                                  : editData == false
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                          {errors.business &&
                                            touched.business &&
                                            values.business == '' && (
                                              <div
                                                className={classes.selectError}
                                              >
                                                {errors.business}
                                              </div>
                                            )}
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text=' Industry Type'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Industry Type'
                                              id='industry'
                                              name='industry'
                                              fluid
                                              search
                                              selection
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'industry',
                                                  true
                                                )
                                              }
                                              onChange={(
                                                e,
                                                { name, value }
                                              ) => {
                                                //
                                                setFieldValue('industry', value)
                                                industrygrpcheck(value)
                                                setindustryname([])
                                                //industrynamecheck(0)
                                              }}
                                              value={values.industry}
                                              options={industry}
                                              className={
                                                errors.industry &&
                                                touched.industry
                                                  ? classes.required
                                                  : editData == false
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                          {errors.industry &&
                                            touched.industry &&
                                            values.industry == '' && (
                                              <div
                                                className={classes.selectError}
                                              >
                                                {errors.industry}
                                              </div>
                                            )}
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Industry Group'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Industry Group'
                                              id='industrygrp'
                                              name='industrygrp'
                                              fluid
                                              search
                                              selection
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'industrygrp',
                                                  true
                                                )
                                              }
                                              onChange={(
                                                e,
                                                { name, value }
                                              ) => {
                                                setFieldValue(
                                                  'industrygrp',
                                                  value
                                                )
                                                industrynamecheck(value)
                                              }}
                                              //onKeyUp={industrynamecheck(values.industrygrp)}
                                              value={values.industrygrp}
                                              options={industrygrp}
                                              className={
                                                errors.industrygrp &&
                                                touched.industrygrp
                                                  ? classes.required
                                                  : editData == false
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                          {errors.industrygrp &&
                                            touched.industrygrp &&
                                            values.industrygrp == '' && (
                                              <div
                                                className={classes.selectError}
                                              >
                                                {errors.industrygrp}
                                              </div>
                                            )}
                                        </List>
                                      </Col>
                                      {/*	checkvalue == "Loan Application" && (<Col md={{ span: 12 }}><Typography text="Secondary Information" styleType="D" /></Col>) */}
                                      {checkvalue == 'Loan Application' && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text='Industry Name'
                                            styleType='B'
                                          />
                                          <List component='div' disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder='Select  Industry Name'
                                                id='industryname'
                                                name='industryname'
                                                fluid
                                                search
                                                selection
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    'industryname',
                                                    true
                                                  )
                                                }
                                                onChange={
                                                  editData == true
                                                    ? (e, { value }) =>
                                                        setFieldValue(
                                                          'industryname',
                                                          value
                                                        )
                                                    : null
                                                }
                                                value={values.industryname}
                                                options={industryname}
                                                className={
                                                  errors.industryname &&
                                                  touched.industryname
                                                    ? classes.required
                                                    : editData == false
                                                    ? classes.reqired
                                                    : classes.man
                                                }
                                              />
                                            </ListItem>
                                            {errors.industryname &&
                                              touched.industryname &&
                                              values.industryname == '' && (
                                                <div
                                                  className={
                                                    classes.selectError
                                                  }
                                                >
                                                  {errors.industryname}
                                                </div>
                                              )}
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == 'Loan Application' && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text='Business Premises Dimension in sqft'
                                            styleType='B'
                                          />
                                          <List component='div' disablePadding>
                                            <ListItem>
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='bparea'
                                                label='Enter Business Premises Area'
                                                onChange={
                                                  editData == true
                                                    ? handleChange
                                                    : null
                                                }
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className:
                                                    editData == true
                                                      ? classes.manorma
                                                      : null
                                                }}
                                                error={
                                                  errors.bparea &&
                                                  touched.bparea &&
                                                  errors.bparea
                                                    ? true
                                                    : false
                                                }
                                                value={values.bparea}
                                                helperText={
                                                  errors.bparea &&
                                                  touched.bparea &&
                                                  errors.bparea
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.bparea &&
                                            touched.bparea &&
                                            values.bparea == '' && (
                                              <div
                                                className={classes.selectError}
                                              >
                                                {errors.bparea}
                                              </div>
                                            )}
                                        </Col>
                                      )}
                                      {checkvalue == 'Loan Application' && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text=' Registration Certificate'
                                            styleType='B'
                                          />
                                          <List component='div' disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder='Select Registration Certificate'
                                                id='govt'
                                                name='govt'
                                                fluid
                                                search
                                                selection
                                                onBlur={() =>
                                                  setFieldTouched('govt', true)
                                                }
                                                onChange={
                                                  editData == true
                                                    ? (e, { value }) => {
                                                        setFieldValue(
                                                          'govt',
                                                          value
                                                        )
                                                        console.log(value)
                                                        setGvmntReg(value)
                                                      }
                                                    : null
                                                }
                                                value={values.govt}
                                                options={govt}
                                                className={
                                                  errors.govt && touched.govt
                                                    ? classes.required
                                                    : editData == false
                                                    ? classes.reqired
                                                    : classes.man
                                                }
                                              />
                                            </ListItem>
                                            {errors.govt &&
                                              touched.govt &&
                                              values.govt == '' && (
                                                <div
                                                  className={
                                                    classes.selectError
                                                  }
                                                >
                                                  {errors.govt}
                                                </div>
                                              )}
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == 'Loan Application' &&
                                        gvmntReg != 174 && (
                                          <Col md={{ span: 6 }}>
                                            <Typography
                                              text='Registration Certificate Number'
                                              styleType='B'
                                            />
                                            <List
                                              component='div'
                                              disablePadding
                                            >
                                              <ListItem>
                                                <TextField
                                                  id='filled-error-helper-text standard-basic'
                                                  label='Enter Registration Certificate Number'
                                                  name='grcno'
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  InputProps={{
                                                    className:
                                                      editData == true
                                                        ? classes.manorma
                                                        : null
                                                  }}
                                                  // error={errors.grcno && touched.grcno && errors.grcno ? true : false}
                                                  value={values.grcno}
                                                  helperText={
                                                    errors.grcno &&
                                                    touched.grcno &&
                                                    errors.grcno
                                                  }
                                                />
                                              </ListItem>
                                            </List>
                                          </Col>
                                        )}
                                      {checkvalue == 'Loan Application' && (
                                        <Col md={{ span: 12 }}>
                                          <Row>
                                            <Col md={{ span: 6 }}>
                                              <Typography
                                                text='Business Description'
                                                styleType='B'
                                              />
                                              <List
                                                component='div'
                                                style={{ padding: '2px' }}
                                              >
                                                <ListItem
                                                  style={{ padding: '5px 0px' }}
                                                >
                                                  <Col
                                                    md={{ span: 12 }}
                                                    style={{
                                                      padding: '0px 0px'
                                                    }}
                                                  >
                                                    <TextArea
                                                      id='filled-error-helper-text'
                                                      name='descrip'
                                                      onChange={
                                                        editData == true
                                                          ? handleChange
                                                          : null
                                                      }
                                                      onBlur={handleBlur}
                                                      error={
                                                        errors.descrip &&
                                                        touched.descrip &&
                                                        errors.descrip
                                                          ? true
                                                          : false
                                                      }
                                                      className={
                                                        errors.descrip &&
                                                        touched.descrip
                                                          ? classes.required
                                                          : editData == true
                                                          ? classes.mano
                                                          : classes.shub
                                                      }
                                                      helperText={
                                                        errors.descrip &&
                                                        touched.descrip &&
                                                        errors.descrip
                                                      }
                                                      value={values.descrip}
                                                    />
                                                  </Col>
                                                </ListItem>
                                                {errors.descrip &&
                                                  touched.descrip && (
                                                    <div
                                                      className={
                                                        classes.selectError
                                                      }
                                                    >
                                                      {errors.descrip}
                                                    </div>
                                                  )}
                                              </List>
                                            </Col>

                                            {showpdfview == true && (
                                              <Col md={{ span: 6 }}>
                                                <Typography
                                                  text='Company Profile'
                                                  styleType='B'
                                                />
                                                <List component='div'>
                                                  <ListItem
                                                    style={{
                                                      paddin:
                                                        '5px 14px 0px 4px',
                                                      display: 'block'
                                                    }}
                                                  >
                                                    {/* <Row>
																										<Col md={{ span: 12 }}>
																											<div {...getRootProps({ className: "dropzone" })}>
																												<input {...getInputProps()} />
																												<p>upload Here</p>
																											</div>
																											<aside>
																												<ul>{files}</ul>
																											</aside>
																										</Col>
																									</Row> */}
                                                    <Row>
                                                      {fileObj.name == '' && (
                                                        <Col md={{ span: 6 }}>
                                                          <DropzoneArea
                                                            onChange={files =>
                                                              fileSetInArray(
                                                                files
                                                              )
                                                            }
                                                            Icon={AttachFile}
                                                            dropzoneText={
                                                              dropFileText
                                                            }
                                                            alertSnackbarProps={{
                                                              anchorOrigin: {
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'right'
                                                              }
                                                            }}
                                                            maxFileSize={maxFileSize}
                                                            acceptedFiles={allowedFileType}
                                                            showPreviews={false}
                                                            showPreviewsInDropzone={
                                                              false
                                                            }
                                                          />
                                                        </Col>
                                                      )}
                                                      {fileObj.name != '' && (
                                                        <Col
                                                          md={{ span: 6 }}
                                                          style={{
                                                            padding: '10px'
                                                          }}
                                                        >
                                                          <Row>
                                                            <Col
                                                              md={{ span: 1 }}
                                                            >
                                                              {fileObj.type ==
                                                                'application/pdf' && (
                                                                <i
                                                                  class='far fa-file-pdf'
                                                                  style={{
                                                                    color:
                                                                      '#ff0000',
                                                                    fontSize:
                                                                      '50px'
                                                                  }}
                                                                ></i>
                                                              )}
                                                            </Col>
                                                            <Col
                                                              md={{ span: 8 }}
                                                              style={{
                                                                left: '4%'
                                                              }}
                                                            >
                                                              <p className='uploadedFileName'>
                                                                {fileObj.name}
                                                              </p>
                                                              <img
                                                                alt='image1'
                                                                src={calander}
                                                                style={{
                                                                  width: '10%'
                                                                }}
                                                              />
                                                              &nbsp;&nbsp;
                                                              <span>
                                                                {
                                                                  fileObj.modifiedDate
                                                                }
                                                              </span>
                                                            </Col>
                                                            <Col
                                                              md={{ span: 3 }}
                                                              className='fileUploadAction'
                                                            >
                                                              <div
                                                                style={{
                                                                  float: 'right'
                                                                }}
                                                              >
                                                                <i
                                                                  className='fas fa-eye'
                                                                  style={{
                                                                    fontSize:
                                                                      '15px',
                                                                    cursor:
                                                                      'pointer'
                                                                  }}
                                                                  onClick={() =>
                                                                    handleshowhidepdf(
                                                                      fileObj.base64String
                                                                    )
                                                                  }
                                                                ></i>{' '}
                                                                &nbsp;
                                                                <i
                                                                  className='fas fa-trash-alt'
                                                                  style={{
                                                                    fontSize:
                                                                      '15px',
                                                                    cursor:
                                                                      'pointer'
                                                                  }}
                                                                  onClick={
                                                                    callFileDeleteOption
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      )}
                                                      {fileObj1.name == '' && (
                                                        <Col md={{ span: 6 }}>
                                                          <DropzoneArea
                                                            onChange={files =>
                                                              fileSetInArray2(
                                                                files
                                                              )
                                                            }
                                                            Icon={AttachFile}
                                                            dropzoneText={
                                                              dropFileText
                                                            }
                                                            alertSnackbarProps={{
                                                              anchorOrigin: {
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'right'
                                                              }
                                                            }}
                                                            maxFileSize={maxFileSize}
                                                            acceptedFiles={allowedFileType}                                  
                                                            showPreviews={false}
                                                            showPreviewsInDropzone={
                                                              false
                                                            }
                                                          />
                                                        </Col>
                                                      )}
                                                      {fileObj1.name != '' && (
                                                        <Col
                                                          md={{ span: 6 }}
                                                          style={{
                                                            padding: '10px'
                                                          }}
                                                        >
                                                          <Row>
                                                            <Col
                                                              md={{ span: 1 }}
                                                            >
                                                              {fileObj1.type ==
                                                                'application/pdf' && (
                                                                <i
                                                                  class='far fa-file-pdf'
                                                                  style={{
                                                                    color:
                                                                      '#ff0000',
                                                                    fontSize:
                                                                      '50px'
                                                                  }}
                                                                ></i>
                                                              )}
                                                            </Col>
                                                            <Col
                                                              md={{ span: 8 }}
                                                              style={{
                                                                left: '4%'
                                                              }}
                                                            >
                                                              <Typography
                                                                text={
                                                                  fileObj1.name
                                                                }
                                                                styleType='B'
                                                              />
                                                              <br></br>
                                                              <i
                                                                class='far fa-calendar-alt'
                                                                style={{
                                                                  fontSize:
                                                                    '20px',
                                                                  padding: '2px'
                                                                }}
                                                              ></i>
                                                              &nbsp;&nbsp;
                                                              <span>
                                                                {
                                                                  fileObj1.modifiedDate
                                                                }
                                                              </span>
                                                            </Col>
                                                            <Col
                                                              md={{ span: 3 }}
                                                              className='fileUploadAction'
                                                            >
                                                              <div
                                                                style={{
                                                                  float: 'right'
                                                                }}
                                                              >
                                                                <i
                                                                  className='fas fa-eye'
                                                                  style={{
                                                                    fontSize:
                                                                      '15px',
                                                                    cursor:
                                                                      'pointer'
                                                                  }}
                                                                  onClick={() =>
                                                                    handleshowhidepdf(
                                                                      fileObj1.base64String
                                                                    )
                                                                  }
                                                                ></i>{' '}
                                                                &nbsp;
                                                                {/* <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={callFileDeleteOption2}></i> */}
                                                                <img
                                                                  alt='image1'
                                                                  src={
                                                                    deleterec
                                                                  }
                                                                  className='docuploadicon'
                                                                  onClick={
                                                                    callFileDeleteOption2
                                                                  }
                                                                />
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      )}
                                                    </Row>
                                                  </ListItem>
                                                </List>
                                              </Col>
                                            )}
                                            {showpdfview == false && (
                                              <Col md={{ span: 6 }}>
                                                <FileBase64
                                                  labelText='Select file'
                                                  name='companyprofile'
                                                  onChange={
                                                    editData == true
                                                      ? handleChange
                                                      : null
                                                  }
                                                  onBlur={handleBlur}
                                                  labelStyle={{ fontSize: 14 }}
                                                  multiple={true}
                                                  onDone={file_arr => {
                                                    handledatabase64(file_arr)
                                                  }}
                                                  // callbackFunction={
                                                  // 	(file_arr)=>{handledatabase64(file_arr)}}

                                                  accept='application/msword,application/vnd.ms-powerpoint,
																														text/plain, application/pdf,image/*'
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        </Col>
                                      )}
                                      <Col md={{ span: 10 }}></Col>
                                      <Col md={{ span: 2 }}>
                                        <Button
                                          className='updatebtn'
                                          variant='contained'
                                          color='primary'
                                          onClick={handleSubmit}
                                        >
                                          Update
                                        </Button>
                                      </Col>
                                    </Row>
                                  </form>
                                )
                              }}
                            </Formik>
                          )}
                          {/* </Container> */}
                          {/* </Collapse> */}
                        </div>
                      )}
                      {/* //#endregion end edit form */}

                      {/* //#region  table list */}
                      {topform == false &&
                        visible == true &&
                        editData == false &&
                        collapsedata == false && (
                          <TableContainer className='tablecontainer'>
                            <Table aria-label='collapsible table' size='small'>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={table_slNo}>#</TableCell>
                                  <TableCell style={table_header}>
                                    Business Name
                                  </TableCell>
                                  <TableCell style={table_header}>
                                    GST Number
                                  </TableCell>
                                  <TableCell style={table_header}>
                                    Entity Type
                                  </TableCell>
                                  <TableCell style={table_header}>
                                    Industry Name
                                  </TableCell>
                                  <TableCell style={table_header} colSpan={2}>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businesslistdata.map((item, i) => (
                                  <TableRow hover className='datable' key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{item.businessName}</TableCell>
                                    <TableCell>{item.gst}</TableCell>
                                    <TableCell>{item.entitytype}</TableCell>
                                    <TableCell>{item.industryname}</TableCell>
                                    <TableCell>
                                      <Row>
                                        <Col md={{ span: 12 }}>
                                          <Row>
                                            <Tooltip
                                              title='Edit'
                                              placement='bottom'
                                            >
                                              <Button
                                                className='actionBtn'
                                                onClick={() =>
                                                  handleEditData(
                                                    item.businessid
                                                  )
                                                }
                                              >
                                                <i
                                                  className='fas fa-user-edit'
                                                  style={{ fontSize: '15px' }}
                                                />
                                              </Button>
                                            </Tooltip>
                                            <Tooltip
                                              title='delete'
                                              placement='bottom'
                                            >
                                              <Button
                                                className='actionBtn'
                                                onClick={() =>
                                                  deleteBusinessByBusinessId(
                                                    item.businessid
                                                  )
                                                }
                                              >
                                                <i
                                                  class='fas fa-user-minus'
                                                  style={{ fontSize: '13px' }}
                                                ></i>
                                              </Button>
                                            </Tooltip>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              {paginationHidden && (
                                <TableFooter>
                                  <TableRow>
                                    <TablePagination
                                      rowsPerPageOptions={[
                                        5,
                                        10,
                                        25,
                                        { label: 'All', value: -1 }
                                      ]}
                                      colSpan={12}
                                      count={5}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      SelectProps={{
                                        inputProps: {
                                          'aria-label': 'rows per page'
                                        },
                                        native: true
                                      }}
                                      onChangePage={handleChangePage}
                                      onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                      }
                                    />
                                  </TableRow>
                                </TableFooter>
                              )}
                            </Table>
                          </TableContainer>
                        )}
                      {/* //#endregion end table list */}

                      {/*Modal to show Preview Start*/}
                      <Modal
                        show={showmodal}
                        animation={false}
                        centered
                        style={{ height: '550px' }}
                      >
                        <Modal.Header
                          closeButton
                          onClick={handleclosePdf}
                          style={{
                            color: '#212529',
                            borderBottom: '1px solid #7254a3'
                          }}
                        >
                          {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col md={{ span: 12 }}>
                              <embed
                                src={profilebase64}
                                type='application/pdf'
                                width='100%'
                                style={{ height: '460px' }}
                              ></embed>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                      {/*Modal to Close Preview*/}

                      {/* //#region  Bottom Button start */}

                      <div className='btn_row_fixd'>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          {/* className="btnspace" */}
                          {onSaveHideButton == true && (
                            <>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}>
                                <Tooltip
                                  title='Back'
                                  classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                  }}
                                  arrow
                                >
                                  <img
                                    alt='image1'
                                    className='navbutton'
                                    src={Back}
                                    onClick={handledata}
                                  />
                                </Tooltip>
                              </Col>
                              <Col style={{ padding: '0%' }}>
                                <Tooltip title='Save as Draft & Next' arrow>
                                  <img
                                    alt='image1'
                                    className='navbutton'
                                    src={DraftNext}
                                    onClick={SaveasDraft}
                                  />
                                </Tooltip>
                              </Col>
                            </>
                          )}
                          {showbuttons == true && (
                            <>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}></Col>
                              <Col style={{ padding: '0%' }}>
                                {/* <Tooltip title="Reset" arrow>
																	<img className='navbutton' src={Reset} />
																</Tooltip> */}
                                <span className='image'>
                                  <img
                                    alt='image1'
                                    className='navbutton'
                                    src={Reset}
                                  />
                                  <Tooltip title='Reset'>
                                    <img
                                      alt='image1'
                                      className='navgraybutton'
                                      src={grayreset}
                                    />
                                  </Tooltip>
                                </span>
                              </Col>
                              <Col style={{ padding: '0%' }}>
                                {/* <Tooltip title="Save & Next" arrow>
																	<img className='navbutton' src={SaveNext} type="submit" onClick={handleSubmit} />
																</Tooltip> */}
                                <span className='image'>
                                  <img
                                    alt='image1'
                                    className='navbutton'
                                    src={SaveNext}
                                    onClick={handleSubmit}
                                  />
                                  <Tooltip title='Save & Next'>
                                    <img
                                      alt='image1'
                                      className='navgraybutton'
                                      src={graysave}
                                      onClick={handleSubmit}
                                    />
                                  </Tooltip>
                                </span>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>

                      {/* //#endregion Bottom Button end */}
                    </Col>
                    <DocumentUploadModal
                      show={show}
                      handleClose={handleClose}
                      getInputProps={getInputProps}
                      getRootProps={getRootProps}
                      classes={classes}
                      files={files}
                    />
                  </Row>
                </div>
                <Snackbar
                  open={savenextmessage}
                  style={{ marginTop: '65px' }}
                  autoHideDuration={SnackPosition.duration}
                  onClose={() => {
                    setsavenextmessage(false)
                  }}
                  anchorOrigin={{
                    vertical: SnackPosition.vertical,
                    horizontal: SnackPosition.horizontal
                  }}
                >
                  <Alert
                    onClose={() => {
                      setsavenextmessage(false)
                    }}
                    severity={colorMessage}
                  >
                    {showMessage}
                  </Alert>
                </Snackbar>
              </form>
            )
          }}
        </Formik>
      )}
      {/* <div className='float-right open_right_view'>
				{['right'].map((anchor) => (
					<React.Fragment key={anchor}>
						<Button onClick={toggleDrawer(anchor, true)} className='open_right_view_btn'>
							<ChevronLeft />
						</Button>
						<Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
							{list(anchor)}
						</Drawer>
					</React.Fragment>
				))}
			</div> */}
    </Container>
  )
}
export default BusinessInfoForm

export const DocumentUploadModal = ({
  show,
  handleClose,
  getInputProps,
  getRootProps,
  classes,
  files
}) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Document Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ padding: 10, height: 'auto' }}>
          <Col md={{ span: 12 }} spacing={0}>
            <List component='div' disablePadding>
              <ListItem>
                <Col md={{ span: 4 }}>
                  <font className={classes.listitem}> GST</font>
                </Col>
                <Col md={{ span: 8 }}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Upload GST</p>
                  </div>
                  <aside>
                    <ul>{files}</ul>
                  </aside>
                </Col>
              </ListItem>
            </List>
          </Col>
          <Col md={{ span: 12 }} spacing={0}>
            <List component='div' disablePadding>
              <ListItem>
                <Col md={{ span: 4 }}>
                  <font className={classes.listitem}>Business PAN</font>
                </Col>
                <Col md={{ span: 8 }}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Upload Business PAN</p>
                  </div>
                  <aside>
                    <ul>{files}</ul>
                  </aside>
                </Col>
              </ListItem>
            </List>
          </Col>
          <Col md={{ span: 12 }} spacing={0}>
            <List component='div' disablePadding>
              <ListItem>
                <Col md={{ span: 4 }}>
                  <font className={classes.listitem}>GRC </font>
                </Col>
                <Col md={{ span: 8 }}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Upload GRC</p>
                  </div>
                  <aside>
                    <ul>{files}</ul>
                  </aside>
                </Col>
              </ListItem>
            </List>
          </Col>
        </Row>
        <Row style={{ marginTop: 50, height: 'auto' }}>
          <Col md={{ span: 2 }}></Col>
          <Col md={{ span: 5 }}>
            <Button
              className={classes.linkdin}
              variant='contained'
              color='primary'
              onHide={handleClose}
            >
              Save
            </Button>
          </Col>
          <Col md={{ span: 3 }}>
            <Button
              className={classes.button_div}
              variant='outlined'
              color='primary'
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

//#endregion All Forms Section end
