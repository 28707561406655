import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'semantic-ui-css/semantic.min.css';
import * as serviceWorker from './serviceWorker';
import './fonts/Roboto-Light.ttf'
import { connect } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import 'react-quill/dist/quill.snow.css';

ReactDOM.render(
  <React.Fragment >
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

serviceWorker.unregister();
