import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Container from "@material-ui/core/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import interactionPlugin from "@fullcalendar/interaction";
import { Calendar } from '@fullcalendar/core';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import Modal from "react-bootstrap/Modal";
import { TextField, Button, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import Typography from "../../base_components/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import bookapp from '../BookAppointment'
import './bookapp.css';
import DraftNext from '../../image/buttons/draftnext.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
//import Tooltip from "tooltip.js";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MapPicker from 'react-google-map-picker';
import MyLocationIcon from '@material-ui/icons/MyLocation';



const BooksAppoitmentBank = (props) => {
    const DefaultLocation = { lat: 12.96980713559933, lng: 77.59622192382814 };
    const DefaultZoom = 10;
    const [defaultLocation, setDefaultLocation] = React.useState(DefaultLocation);
    const [location, setLocation] = React.useState(defaultLocation);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        console.clear();
       // getLabelsData();
        getAllEvents();
        detailsDocument();// tempprau
        //saveAppointment();
    }, []);

    const handleSubmiting = () => {
        props.handleNext();
    };

    const SaveasDraft = () => {
        handleSubmiting();
    }

    const handledata = () => {
        props.handleBack();
    };

    let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
    let userData = JSON.parse(localStorage.getItem("userdata"));

    const [showModal, setShowModal] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dateSelected, setDateSelected] = React.useState();
    const handleDateClick = (args) => {
        setShow(true);
        setShowModal(true);
        //
        console.log("Date clicked");
        console.log(args.dateStr);
        setDateSelected(args.dateStr);
        console.log(args);
        getByDateAndUserId(args.dateStr);
        //console.log(e.target.value);
        //addEvent();

        //alert(args.dateStr);
    }

    const [labelData, setLabelDataList] = React.useState([]);
    const detailsDocument = async () => {
        
        let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
        const docdata = {
            "user_id":640,
            "required_phase":172,
            "bank_id":["166","174"]
 
        }
        //let api_path=apiCalling.coderscorner +`server.php/api/getDetailsDocument`;
        await axios.post(apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`, docdata).then((result) => {
            console.log("Document data...!!!", result.data);
            let myArr = [];
            console.log(result.data)
            result.data.data.map((item) => {
                myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
            })
            setLabelDataList(myArr);
        }).catch(error => {
            //ErrorCatch(userId.id,error,api_path,'post',docdata,userId.id,userId.id);
            console.log(error)
        })
    }

    const getLabelsData = async () => {
        let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
        const labelData = {
            user_id: localData.user_id,
            parent_id:localData.business_id,
            parent_type_id: 1,
            form: "10",
            required_phase: "172"
        }
        let myArr = [];
            // findDocsOfUser
        await axios.post(apiCalling.coderscorner + 'server.php/api/findDocsOfUserforBank', labelData).then((result) => {
            if (result.data.data.length > 1) {
                result.data.data.map((item) => {
                    myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
                })
                console.log("my document array----->", myArr);
                setLabelDataList(myArr);
            } else {
                detailsDocument();
            }
        })
    }

    const [arrayList, setArrayList] = React.useState([]);
    const checkBoxValue = (id) => {
        
        let tempArr = [...arrayList];
        let isId = false;
        if (tempArr.length > 0) {
            for (let i = 0; i < tempArr.length; i++) {
                if (tempArr[i] == id) {
                    /// tempArr.push(id)
                    isId = true
                    let remv = tempArr.indexOf(id)
                    tempArr.splice(remv, 1);
                    break;
                }
            }
            if (isId == false) {
                tempArr.push(id)
            }
        } else {
            tempArr.push(id)
        }
        setArrayList(tempArr)
    }



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [showTable, setShowTable] = React.useState(false);
    const [myEvents, setMyEvents] = React.useState([]);
    const getAllEvents = async () => {
        
        const data = {
            request_id: localData.id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getAppointmentDetailsByRequestId`, data).then((result) => {
            console.log('data allevents', result.data)
            if (result.data.status) {
                //  renderEventContent(result.data.data)
                let eventArray = [];
                result.data.data.map((item) => {
                    eventArray.push({
                        id: item.id,
                        title: item.title,
                        date: item.start_date,
                        time: item.start_time
                    })
                });
                console.log('pushed eventArray', eventArray)
                setMyEvents(eventArray);
            }

            // if(result.data.data.length>0){
            //    // renderEventContent(result.data.data)
            //     setShow(false);
            // }else{
            //     setShowTable(false);
            // }
        })
    }


    //    const eventArrays = [
    //         { title: 'event 1', date: '2021-05-10' },
    //         { title: 'event 2', date: '2021-05-11' },
    //         {title :'event 3',date:'2021-05-01'}
    //       ]


    const renderEventContent = (data) => {
        console.log(data)
        {
            myEvents.map((item, i) => {
                return (
                    <Container>
                        <Row>
                            <b>{item.title}</b>
                        </Row>
                    </Container>
                )
            })
        }
    }


    const getData = (id) => {
        // 
        setArrayList([...arrayList, id])
        console.log(arrayList);
    }

    const [initialValue, setInitialValue] = React.useState({
        id: "",
        title: "",
        date: "",
        time: "",
        request_id: "",
        created_by: "",
        phone: "",
        address: "",
        pincode: "",
        latitude: location.lat,
        longitude: location.lng,
        reference_document_checklist_id: []
    })

    const saveEvent = async (values) => {
        

        console.log(values)
        const data = {
            id: values.id,
            title: values.title,
            start_date: values.date,
            start_time: values.time,
            request_id: localData.id,
            created_by: userData.id,
            pincode: values.pincode,
            phone: values.phone,
            address: values.address,
            latitude: location.lat,
            longitude: location.lng,
            reference_document_checklist_id: arrayList
        }
        console.log('data to save.........', data)
        await axios.post(apiCalling.coderscorner + `server.php/api/bookAppointment`, data).then((result) => {
            console.log('saved Appointment', result.data);
            setShow(false);
            getAllEvents();
            //setShowTable(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const getByDateAndUserId = async (date) => {
        
        const data = {
            start_date: date,
            created_by: userData.id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getAppointmentByUserIDAndDate`, data).then((result) => {
            console.log(result.data.data);
            if (result.data.status) {
                let editdata = result.data.data.appointmentheader[0];
                // let checklistdata = result.data.data.appointmentdetails[1];
                console.log('editdata', editdata);
                
                setLocation({ lat: parseFloat(editdata.latitude), lng: parseFloat(editdata.longitude) });

                setInitialValue({
                    ...initialValue,
                    id: editdata.id,
                    title: editdata.title,
                    date: editdata.start_date,
                    time: editdata.start_time,
                    request_id: localData.id,
                    created_by: editdata.created_by,
                    address: editdata.address,
                    phone: editdata.phone,
                    pincode: editdata.pincode,
                    reference_document_checklist_id: result.data.data.reference_document_checklist_id
                })
                setArrayList(result.data.data.reference_document_checklist_id)
            } else {
                setArrayList([]);
                setInitialValue({
                    ...initialValue,
                    id: "",
                    title: "",
                    date: "",
                    time: "",
                    reference_document_checklist_id: ['']
                })

            }
        })
    }

    const handleMouseEnter = async (view, myEvent) => {
        
        let time = view.event._def.extendedProps.time;
        let date = moment(view.event._instance.range.start).format('DD-MM-YYYY');
        let date2 = moment(view.event._instance.range.start).format('YYYY-MM-DD');
        let docDetails = await getByDateAndUserIdOnHover(date2);
        console.log(docDetails);
        let listArr = [];
        labelData.map((item, i) => {
            for (let i = 0; i < docDetails.length; i++) {
                if (docDetails[i] == item.id) {
                    listArr.push(item.name);
                }
            }
        });

        let title = `
        <div>
        <h5>${view.event._def.title}</h5>
        <p class ="tippy_para" >Time : ${time}  Location: Bengaluru </p>
        <ul class ="tippy_ul">
            <li>${listArr[0]}</li>
            <li>${listArr[1]}</li>

        </ul>
        <div>
        `;
        tippy(view.el, {
            theme: 'light',
            allowHTML: true,
            content: title
        });
    }


    const getByDateAndUserIdOnHover = async (date) => {
        
        const data = {
            start_date: date,
            created_by: userData.id
        }
        let editData = [];
        await axios.post(apiCalling.coderscorner + `server.php/api/getAppointmentByUserIDAndDate`, data).then((result) => {
            console.log(result.data.data);
            if (result.data.status) {
                editData = result.data.data.reference_document_checklist_id;
                // let checklistdata = result.data.data.appointmentdetails[1];
                console.log('editdata', editData);
            }
        });
        return editData;
    }

    const deleteAppointment = async () => {
        
        const data = {
            start_date: dateSelected,
            created_by: userData.id
        }
        console.log('delete data values', data)
        await axios.post(apiCalling.coderscorner + `server.php/api/deleteAppointmentByUserIDAndstartdate`, data).then((result) => {
            console.log(result.data.data);
            if (result.data.status) {
                setShow(false);
                getAllEvents();
            }
        })
    }

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        
        setAnchorEl(true);
    };

    const handleClose1 = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;




    const [zoom, setZoom] = React.useState(DefaultZoom);
    function handleChangeLocation(lat, lng) {
        
        setLocation({ lat: lat, lng: lng });
    }

    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    function handleResetLocation() {
        setDefaultLocation({ ...DefaultLocation });
        setZoom(DefaultZoom);
    }

    const [showForm, setShowForm] = React.useState(true);


    return (
        <Container maxWidth="lg">
            {showModal == true && (
                <>
                    {/* <Button variant="primary" onClick={handleShow}>
             Launch demo modal
           </Button> */}
                    <Modal show={show} animation={false} centered style={{ height: "100%", padding: "3%" }}>
                        {showForm == true ?


                            (
                                <Formik enableReinitialize initialValues={initialValue} onSubmit={saveEvent}>
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldTouched,
                                    }) => {
                                        return (

                                            <form onSubmit={handleSubmit}>
                                                <div class="container-fluid" style={{ padding: "3%" }}>

                                                    {(
                                                        <div className="content-header">
                                                            <div class="row">
                                                                <Col md={{ span: 10 }} className="mainheader1">
                                                                    <Typography text="NEW REMINDER " styleType="C" />
                                                                </Col>
                                                                {/* <Col md={{span:2}}>
                                                <Button className="actionBtn"  >
                                                    <i class="fa fa-trash" aria-hidden="true" style={{ fontSize: "15px", zIndex : 9999}} onClick={deleteAppointment}></i>
                                                </Button>
                                                </Col> */}
                                                                <Col md={{ span: 2 }}>
                                                                    {/* <i class="fa fa-trash" aria-hidden="true" style={{ fontSize: "15px", zIndex : 9999}} onClick={deleteAppointment}></i> */}
                                                                    {      // <Button className="updatebtn" variant="contained" color="primary" type="submit" 

                                                                        // ></Button>
                                                                    }
                                                                    <CloseIcon onClick={handleClose} type="button" style={{ marginLeft: "80%", fontSize: "30px" }} />
                                                                </Col>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <Typography text="Title" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField id="filled-error-helper-text standard-basic "
                                                                                label="Book Appointment" name="title"
                                                                                onChange={handleChange} onBlur={handleBlur}
                                                                                InputProps={{ className: values.title ? classes.manorma : null, }}
                                                                                error={errors.title && touched.title && errors.title ? true : false}
                                                                                value={values.title || ''}
                                                                                helperText={errors.title && touched.title && errors.title} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>
                                                                <div className="col-md-6"></div>
                                                                <div className="col-md-6">
                                                                    <Typography text="Start Date" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="date"
                                                                                type="date"
                                                                                className={classes.textField}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                style={{ marginTop: "9px" }}
                                                                                // InputProps={{
                                                                                // 	inputProps: { min: minimumdate, max: maximumdate },
                                                                                // 	className: values.date ? classes.manorma : null,
                                                                                // }}
                                                                                error={
                                                                                    errors.date && touched.date && errors.date
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.date}
                                                                                helperText={errors.date && touched.date && errors.date} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Typography text="Start Time" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="time"
                                                                                type="time"
                                                                                defaultValue="12:00 PM"
                                                                                className={classes.textField}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                style={{ marginTop: "9px" }}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                inputProps={{
                                                                                    step: 300, // 5 min
                                                                                }}
                                                                                error={
                                                                                    errors.time && touched.time && errors.time
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.time}
                                                                                helperText={errors.time && touched.time && errors.time} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <Typography text="Phone" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField id="filled-error-helper-text standard-basic "
                                                                                label="Phone" name="phone"
                                                                                onChange={handleChange} onBlur={handleBlur}
                                                                                InputProps={{ className: values.phone ? classes.manorma : null, }}
                                                                                error={errors.phone && touched.phone && errors.phone ? true : false}
                                                                                value={values.phone || ''}
                                                                                helperText={errors.phone && touched.phone && errors.phone} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <Typography text="Pin Code" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField id="filled-error-helper-text standard-basic "
                                                                                label="Pin Code" name="pincode"
                                                                                onChange={handleChange} onBlur={handleBlur}
                                                                                InputProps={{ className: values.pincode ? classes.manorma : null, }}
                                                                                error={errors.pincode && touched.pincode && errors.pincode ? true : false}
                                                                                value={values.pincode || ''}
                                                                                helperText={errors.pincode && touched.pincode && errors.pincode} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Typography text="Address" styleType="B" />
                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextareaAutosize id="filled-error-helper-text standard-basic "
                                                                                label="Address" name="address"
                                                                                rowsMin={2}
                                                                                onChange={handleChange} onBlur={handleBlur}
                                                                                InputProps={{ className: values.address ? classes.manorma : null, }}
                                                                                error={errors.address && touched.address && errors.address ? true : false}
                                                                                value={values.address || ''}
                                                                                helperText={errors.address && touched.address && errors.address} />
                                                                        </ListItem>
                                                                    </List>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <MyLocationIcon onClick={() => {
                                                                        setShowForm(false);
                                                                    }} />
                                                                </div>
                                                                <div class="col-md-12"><Typography text="Unit Details" styleType="A" /> </div>
                                                                <div class="col-md-6">
                                                                    {labelData.map((item, i) => (
                                                                        <div className="label">
                                                                            <FormControlLabel control={<Checkbox name="checkedC" color="primary"
                                                                                // onChange={() => { getData(item.id) }}
                                                                                onChange={() => {
                                                                                    ///setArrayList([...arrayList,item.id])
                                                                                    checkBoxValue(item.id);
                                                                                }}
                                                                                // checked ={(initialValue.reference_document_checklist_id.find((item1)=>item1==item.id))==undefined?false:true} 

                                                                                //  checked ={initialValue.reference_document_checklist_id.length>0 ? initialValue.reference_document_checklist_id.find((item1)=>item1==item.id)==undefined?false:true:arrayList.find((item1)=>item1==item.id) == undefined?false:true}/> }
                                                                                checked={arrayList.find((item1) => item1 == item.id) == undefined ? false : true} />}
                                                                                label={item.name} />
                                                                        </div>))
                                                                    }
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="row"><div class="col-md-8"></div>
                                                                        <div class="col-md-2" style={{ marginTop: "8px" }}>
                                                                            <Button className={classes.button} variant="contained"
                                                                                color="secondary" type="submit" onClick={() => { deleteAppointment() }}
                                                                                startIcon={<DeleteIcon />}
                                                                            >delete</Button>
                                                                        </div>
                                                                        <div class="col-md-2"><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={() => { handleSubmit() }}>Confirm</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <Row>
                                                    {/* <Col>
                                     <Modal.Header closeButton>
                                        <Modal.Title>NEW REMINDER</Modal.Title>
                                    </Modal.Header>
                                     </Col>   
                                    
                                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                                        <Button variant="primary" onClick={handleClose}>Save Changes</Button>
                                    </Modal.Footer> */}
                                                </Row>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            ) : (
                                <>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <button onClick={handleResetLocation}>Reset Location</button>
                                            {
                                                // <label>Latitute:</label><input type='text' value={location.lat} disabled />
                                                // <label>Longitute:</label><input type='text' value={location.lng} disabled />
                                            }
                                            <button onClick={() => {
                                                setShowForm(!showForm);
                                            }}>Submit</button>
                                        </Col>
                                        <Col md={{ span: 12 }}>
                                            <MapPicker defaultLocation={location}
                                                zoom={zoom}
                                                style={{ height: '700px' }}
                                                onChangeLocation={handleChangeLocation}
                                                onChangeZoom={handleChangeZoom}
                                                apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8' />
                                        </Col>
                                    </Row>



                                </>
                            )}

                    </Modal>
                </>
            )}

            {(
                <Row>
                    <div className='demo-app-main'>
                        <div className="content-header">
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <Typography text="BOOK YOUR APPOINTMENT" styleType="C" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 12 }} className="subHeader1">
                                    <table className="applicationTableDetails1">
                                        <tr>
                                            {/* <td>Type</td>
                        <td>:</td> */}
                                            {localData.request_type_id === 0 && (
                                                <td>Loan Application</td>)}
                                            {localData.request_type_id === 1 && (
                                                <td>Check Eligibility</td>)}
                                            <td>&nbsp;|&nbsp;</td>
                                            <td>Application Number</td>
                                            <td>:</td>
                                            <td>{localData.reference_id}</td>
                                            <td>&nbsp;|&nbsp;</td>
                                            <td>Loan Type</td>
                                            <td>:</td>
                                            <td>{localData.loan_type}</td>
                                        </tr>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={{ span: 2 }} className='button-add'><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleDateClick}>Schedule Event</Button></Col>
                        </Row>
                        <FullCalendar
                            Date
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: ''
                            }}
                            // themeSystem = "bootstrap"
                            initialView="dayGridMonth"
                            dateClick={handleDateClick}
                            eventMouseEnter={(view, event) => {
                                //        
                                //        console.log(view.event._def);
                                //        console.log(view.event._instance);
                                // getPopUp(view);
                                handleMouseEnter(view, myEvents);
                            }}

                            events={myEvents}
                        />
                    </div>
                </Row>
            )}
            <div className='btn_row_fixd'>
                <Row>
                    <Col md={{ span: 10 }}></Col>
                    <Col style={{ padding: "0%" }}></Col>
                    <Col style={{ padding: "0%" }}></Col>
                    <Col style={{ padding: "0%" }}>
                        <span className="image">
                            <img className='navbutton' src={Back} onClick={handledata} />
                            <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                <img className='navgraybutton' src={grayback} onClick={handledata} />
                            </Tooltip>
                        </span>
                    </Col>
                    <Col style={{ padding: "0%" }}>
                        <span className="image">
                            <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                            <Tooltip title="Save & Next" arrow>
                                <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                            </Tooltip>
                        </span>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default BooksAppoitmentBank;
