//Om Namah Shivay
import React, { useState } from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TextField, Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Dropdown } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik, FieldArray, Field } from 'formik'
import { FormikTextField } from 'formik-material-fields'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Collapse from '@material-ui/core/Collapse'
import { GstFormSchema, gstPull } from '../../shared/validations'
import { useStylegst } from './styles'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { SnackPosition } from '../../shared/constants'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import DraftNext from '../../image/buttons/draftnext.png'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import Back from '../../image/buttons/back.png'
import grayback from '../../image/buttons/grayback.png'
import Paper from '@material-ui/core/Paper'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import manualentry from '../../image/pngicons/manualaddition.png'
import uploaddoc from '../../image/pngicons/upload.png'
import deleterec from '../../image/pngicons/delete.png'
import up from '../../image/pngicons/up.png'
import down from '../../image/pngicons/down.png'
import edit from '../../image/pngicons/edit.png'
import addbutton from '../../image/pngicons/plussign.png'
import digitalpull from '../../image/pngicons/digitalpull.png'
import DocumentUpload from '../../components/DocumentUpload'
import { ErrorCatch, apiCalling } from '../../shared/constants'
import { CommentSection } from 'react-comments-section'
// import 'react-comments-section/dist/index.css';
import SearchBar from 'material-ui-search-bar'
import Modal from 'react-bootstrap/Modal'
import FileViewer from 'react-file-viewer'
import OtpTimer from 'otp-timer'
import InputOtp from '@onefifteen-z/react-input-otp'

export default function GST (props) {
  const file =
    'https://file-examples-com.github.io/uploads/2017/02/file_example_XLSX_10.xlsx'
  const type = 'xlsx'
  const classes = useStylegst()
  const [statename, setstatename] = React.useState([])
  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()
  const [colorMessage, setcolorMessage] = React.useState('success')
  const [addFormButtonValue, setAddFormButtonValue] = React.useState('Submit')
  const [headerValue, setHeaderValue] = React.useState('')
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const [formDisabled, setFormDisabled] = React.useState(false)
  const role_id = JSON.parse(localStorage.getItem('userdata'))

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (role_id.role_id == 5) {
      setFormDisabled(true)
    }
    getBusinessAndPromoterListByUser()
    checkapply()
    statecheck()
    checkIfRequest()
  }, [])
  const [requestDetails, setRequestDetails] = React.useState()
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem('rquestIdDetails')))
  }

  const [searched, setSearched] = React.useState('')
  const [newBus, setNewBus] = React.useState()
  const requestSearch = searchedVal => {
    //
    //
    let busArr = businessList
    if (searchedVal.length != 0 && searchedVal != '') {
      const filteredRows = busArr.filter(row => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase())
      })
      setBusinessList(filteredRows)
    } else {
      setBusinessList(newBus)
    }
  }
  const cancelSearch = () => {
    //
    setSearched('')
    //setBusinessList(newBus)
  }

  const [name, setName] = React.useState('')
  const [bpan, setBpan] = React.useState()
  const [gst, setGst] = React.useState('')

  const maskName = mname => {
    if (mname != null) {
    let name = mname
    name = name.split('')
    let maskedArray = []
    //  let length = name.indexOf('@');
    let length = name.length
    name.map((item, i) => {
      i >= 1 && i < length - 1
        ? maskedArray.push('*')
        : maskedArray.push(name[i])
    })
    let finalName = maskedArray.join('')
    // setName(finalEmail);
    //console.log(finalName);
    return finalName;
  }else{
    return "";
    }
  }
  const maskBpan = mbpan => {
    if (mbpan != null) {
    let bpan = mbpan
    bpan = bpan.split('')
    let maskedArray = []
    //let length = bpan.indexOf('@');
    let length = bpan.length
    bpan.map((item, i) => {
      i >= 1 && i < length - 1
        ? maskedArray.push('*')
        : maskedArray.push(bpan[i])
    })
    let finalBpan = maskedArray.join('')
    //setBpan(finalEmail);
    //console.log(finalBpan);
    return finalBpan;
  }else{
    return "";
  }
  }

  const maskGst = mgst => {
    if (mgst != null) {
    let gst = mgst
    gst = gst.split('')
    let maskedArray = []
    let len = gst.length
    gst.map((item, i) => {
      i == 1 || i == 0 || i == len - 1
        ? maskedArray.push(gst[i])
        : maskedArray.push('*')
    })
    let finalGst = maskedArray.join('')
    //setGst(finalMobile);
    return finalGst;
  }else{
    return "";
  }
  }

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

  function Alert (props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />
  }

  const { vertical, horizontal } = SnackPosition

  var statearray = []
  const statecheck = () => {
    const statedata = {
      token: 'qwerty1234'
    }

    axios
      .post(apiCalling.coderscorner + `server.php/api/getAllState`, statedata)
      .then(res => {
        res.data.data.map(item => {
          statearray.push({
            key: item.state_name,
            value: item.state_id,
            flag: item.state_name,
            text: item.state_name
          })
        })
        setstatename(statearray)
      })
      .catch(er => {
        //console.log("no data  ", er);
      })
  }

  const SaveasDraft = () => {
    props.handleNext()
  }

  const handledata = () => {
    props.handleBack()
  }

  const names = ['James', 'Paul', 'John', 'George', 'Ringo']

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const table_header1 = {
    fontWeight: '600',
    fontSize: '18px',
    width: '1%'
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '18px',
    width: '3%',
    textAlign: 'center'
  }

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%',
    width: '1%'
  }
  const table_Action = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center'
  }
  const table_noborder = {
    borderBottom: '1px solid #fff',
    fontSize: '16px'
  }

  const [
    showBusinessPromoterList,
    setShowBusinessPromoterList
  ] = React.useState(true)
  const [businessList, setBusinessList] = React.useState([])
  const [openGSTBankingHeader, setOpenGSTBankingHeader] = React.useState([])
  const [loaderFlag, setLoaderFlag] = React.useState(true)
  const [parentInfoData, setParentInfoData] = React.useState()
  const [showGSTAddForm, setShowGSTAddForm] = React.useState(false)
  const [checkvalue, setchecked] = React.useState(0)
  const [header, setHeaders] = React.useState(true)
  const [stateDisable, setStateDisable] = React.useState(true)
  const [otpBtn, setOtpBtn] = useState(false)
  const [otpMinutes, setOtpMinutes] = useState()
  const [otpSeconds, setOtpSeconds] = useState()
  const [mobileExistsMsg, setMobileExistMmsg] = useState()
  // Manually Add variable declaration
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false)
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false)
  // Manually Add variable declaration

  const [manualInitialValue, setManualInitialValue] = React.useState({
    header_id: '',
    detailsData: []
  })

  const [showManuallyAdd, setShowManuallyAdd] = React.useState(false)
  const handleManuallyAdd = (parent, item) => {
    //
    setHeaders(false)
    setShowBusinessPromoterList(false)
    let parentData = {
      parent_type_id: 1,
      parent_id: parent.id,
      parent_name: parent.name,
      GST: item.gst_no
    }
    setParentInfoData(parentData)
    setShowGSTAddForm(false)
    setShowBusinessPromoterList(false)

    //setGstDetailsArray(item.id);
    getManualDataByGstHeaderId(item.id)
    //setShowGSTAddForm(true);
  }

  const deletegst = async gstid => {
    const data = {
      id: gstid,
      Token: 'qwerty1234'
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/deleteGstHeader'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/deleteGstHeader', data)
      .then(result => {
        //console.log('saved Sucessfully...!!!', result.data.status)
        if (result.data.status == true) {
          getBusinessAndPromoterListByUser()
          setShowBusinessPromoterList(true)
          setcolorMessage('success')
          setShowMessage(result.data.message)
          setsavenextmessage(true)
        } else {
          setcolorMessage('success')
          setShowMessage(result.data.message)
          setsavenextmessage(true)
        }
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
      })
  }

  const getBusinessAndPromoterListByUser = async () => {
    //
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: checkuserid.user_id
    }
    let api_path =
      apiCalling.coderscorner +
      'server.php/api/getGstDetailsBusinessAndPromoterById'
    await axios
      .post(
        apiCalling.coderscorner +
          'server.php/api/getGstDetailsBusinessAndPromoterById',
        data
      )
      .then(result => {
        setShowGSTAddForm(false)
        setShowBusinessPromoterList(true)
        setLoaderFlag(false)
        setBusinessList(result.data.data.businessList)
        setNewBus(result.data.data.businessList)

        let openBusinessGSTHeaderArray = []
        for (let i = 0; i < result.data.data.businessList.length; i++) {
          openBusinessGSTHeaderArray.push(false)
        }
        setOpenGSTBankingHeader(openBusinessGSTHeaderArray)
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
        console.log(error)
      })
  }
  const handleOpenGSTBankingHeader = (flag, i) => {
    let newArray = []
    for (let j = 0; j < openGSTBankingHeader.length; j++) {
      if (j == i) {
        newArray.push(flag)
      } else {
        newArray.push(openGSTBankingHeader[j])
      }
    }
    setOpenGSTBankingHeader(newArray)
  }

  const checkapply = () => {
    if (localStorage.getItem('RequestType')) {
      const checkdata = localStorage.getItem('RequestType'),
        checkform = JSON.parse(checkdata)
      //	//console.log('lets check', checkform)
      setchecked(checkform)
    } else {
      setchecked('1')
    }
  }

  const handleBusinessPromoterList = () => {
    setHeaders(true)
    setShowGSTAddForm(false)
    setShowBusinessPromoterList(true)
    setShowManuallyAdd(false)
  }

  const [editGSTData, setEditGSTData] = React.useState({
    id: '',
    GST: '',
    State: ''
  })

  const [showInvalidMessage, setShowInvalidMessage] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState()
  const returnStateOnGST = e => {
    //
    ////console.log(`Pressed GST ${e.target.value}`);
    const pressedgst = e.target.value
    if (
      pressedgst.substring(0, 2) > 38 ||
      pressedgst.substring(0, 2) === '00'
    ) {
      setErrorMessage('In Valid GST Number')
      setShowInvalidMessage(true)
    } else {
      if (pressedgst.length == 2 || pressedgst.length > 2) {
        const stateCode = pressedgst.substring(0, 2)
        setEditGSTData({
          ...setEditGSTData,
          id: gstid,
          GST: pressedgst,
          State: Number(stateCode)
        })
      }
      setShowInvalidMessage(false)
      setsavenextmessage(false)
    }
  }

  const handleOpenGSTAddForm = data => {
    setAddFormButtonValue('Submit')
    setHeaderValue('Adding')
    setHeaders(false)
    setShowGSTAddForm(true)
    setShowBusinessPromoterList(false)
    let parentData = {
      parent_type_id: 1,
      parent_id: data.id,
      parent_name: data.name
    }
    setParentInfoData(parentData)
    setEditGSTData({
      id: '',
      GST: '',
      State: ''
    })
  }

  const handlesubmitform = (values, { resetForm }) => {
    handleNextClick(values)
    resetForm({ Gst: '', State: '' })
    props.handleCount(requestDetails.user_id)
  }
  const handleNextClick = async data => {
    //
    setShowGSTAddForm(false)
    setLoaderFlag(true)
    let eligibilityFlag = 0
    if (checkvalue === 1) {
      eligibilityFlag = 1
    }

    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

    let gstloandata = {
      id: data.id,
      parent_id: data.parent_id,
      parent_type_id: data.parent_type_id,
      gst_in: data.Gst,
      state: data.State,
      user_id: checkuserid.user_id,
      eligibility_flag: eligibilityFlag
    }
    let api_path = apiCalling.coderscorner + `server.php/api/businessgst`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/businessgst`, gstloandata)
      .then(res => {
        setLoaderFlag(false)
        setcolorMessage('success')
        setShowMessage(res.data.message)
        setsavenextmessage(true)
        setShowGSTAddForm(false)
        setHeaders(true)
        setShowBusinessPromoterList(true)
        getBusinessAndPromoterListByUser()
      })
      .catch(er => {
        ErrorCatch(data.id, er, api_path, 'post', gstloandata, data.id, data.id)
        console.log('no data sorry ', er)
      })
  }
  // ---------------------------------------------otp verification---------------------//
  const [disable, setDisable] = React.useState({
    disabled: true
  })
  const [showConMsg, setShowConMsg] = useState(false)
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true)
  const [promomobile, setPromoMobile] = React.useState()
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false)
  const [shareEnable, setShareEnable] = useState(true)
  /// verifying promoters Mobile number
  const onBlurMobile = e => {
    let val = e.target.value
    let len = val.toString().length
    // console.log(len);
    if (len == 10) {
      verifyPromoterMobile(val)
      setPromoMobile(val)
    }
  }
  const verifyPromoterMobile = async mobilenumber => {
    //
    let data = {
      type: 'mobile',
      value: mobilenumber,
      form_id: 113
    }
    let api_path = apiCalling.coderscorner + `server.php/api/promoterOtp`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/promoterOtp`, data)
      .then(result => {
        console.log(result.data)
        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message)
        } else if (result.data.status == 101) {
          setVerifiedIcon(true)
        } else {
          setOtpBtn(!otpBtn)
          setMobileExistMmsg('')
        }
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }

  const redendOtpMobile = async () => {
    let data = {
      value: promomobile,
      type: 'mobile',
      form_id: 113
    }
    let api_path = apiCalling.coderscorner + `server.php/api/resendPromoterOtp`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/resendPromoterOtp`, data)
      .then(result => {
        console.log(result.data)
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }
  const getBackToMobile = () => {
    setOtpBtn(false)
    setHideMobileResenBtn(true)
    setMobileVerifyIcon(false)
  }
  const enableShare = () => {
    if (mobileVerifyIcon || emailVerifyIcon) {
      setShareEnable(false)
    }
  }
  ///Otp time as per general setting
  const getOtpTimer = async () => {
    let data = {
      parameter_name: 'OTP TIMER'
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then(result => {
        console.log(result.data.data[0])
        let data = result.data.data[0].parameter_value
        console.log('timer', data)
        convertTimerToInt(data)
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }
  const convertTimerToInt = timer => {
    let newTimer = parseInt(timer)
    var minutes = Math.floor(newTimer / 60)
    var seconds = newTimer - 60 * minutes
    setOtpMinutes(minutes)
    setOtpSeconds(seconds)
  }

  const [gstid, setgstid] = React.useState('')
  const handleGSTEditData = (parent, item) => {
    //
    setAddFormButtonValue('Update')
    setHeaderValue('Editing')
    setHeaders(false)
    setShowGSTAddForm(true)
    setShowBusinessPromoterList(false)
    let parentData = {
      parent_type_id: 1,
      parent_id: parent.id,
      parent_name: parent.name
    }
    setParentInfoData(parentData)
    setEditGSTData({
      id: item.id,
      GST: item.gst_no,
      State: item.state_id
    })
    setgstid(item.id)
  }

  const saveDetailsDataSubmit = async values => {
    //  let checkuserid = JSON.parse(localStorage.getItem('userdata'));
    //
    const data = {
      GST_header_id: values.header_id,
      user_id: checkuserid.id,
      gst_data: values.detailsData
    }
    console.log(data)
    let api_path = apiCalling.coderscorner + 'server.php/api/saveGstDetails'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/saveGstDetails', data)
      .then(result => {
        //console.log('saved Sucessfully...!!!', result.data.Data)
        setShowManuallyAdd(false)
        setShowBusinessPromoterList(true)
        setHeaders(true)
        setcolorMessage('success')
        setShowMessage(result.data.message)
        setsavenextmessage(true)
        getBusinessAndPromoterListByUser()
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
      })
  }

  const getManualDataByGstHeaderId = async gstheaderid => {
    //
    const data = {
      GST_header_id: gstheaderid,
      eligibility_flag: checkvalue,
      Token: 'qwerty1234'
    }
    setManualInitialValue({
      header_id: gstheaderid,
      detailsData: []
    })
    setLoaderFlag(true)
    let api_path = apiCalling.coderscorner + 'server.php/api/getGstDetailsByid'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/getGstDetailsByid', data)
      .then(result => {
        setLoaderFlag(false)
        if (result.data.status == true) {
          setShowManuallyAdd(true)
          setManualInitialValue({
            header_id: gstheaderid,
            detailsData: result.data.data
          })
          setAddFormButtonValue('Update')
        } else {
          setShowManuallyAdd(true)
          setAddFormButtonValue('Submit')
        }
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
      })
  }

  //Soumen END

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation ctm_set_div'
      //  onClick={toggleDrawer(anchor, false)}
      //  onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className='profile_table'>
        <div className='profile_table_part'>
          <table style={{ marginBottom: 30 }}>
            <thead>
              <tr>
                <th>
                  <h3>Upload Documents</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Button className='ctm_btn_set footer'>Upload Here!</Button>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th>
                  <h3>Add Appointment</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <Button className='ctm_btn_set footer'>
                    Book Appointment
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  )

  const [open, setOpen] = React.useState(false)
  const [verifiedIcon, setVerifiedIcon] = useState(false)
  const [gstDetailsData, setGstDetailsData] = React.useState({
    gstNo: '',
    userName: '',
    password: '',
    GSTIN: '',
    type: '1'
  })

  const [gstDetailsDataOtp, setGstDetailsDataOtp] = React.useState({
    userName: '',
    GSTIN1: ''
  })

  const handleOpen = item => {
    ////
    setGstDetailsData({
      gst_header_id: item.id,
      gstNo: item.gst_no,
      userName: '',
      password: '',
      GSTIN: '',
      type: '1'
    })
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  //3rd dialog box
  const [openOtpFormCredential, setOpenOtpFormCredential] = React.useState(
    false
  )
  const handleOpenOtpFormCredential = () => {
    setOpenOtpFormCredential(true)
  }
  const handleCloseOtpCredentialForm = () => {
    setOpenOtpFormCredential(false)
  }
  //2nd dialog
  const [openFormCredential, setOpenFormCredential] = React.useState(false)
  const handleOpenFormCredential = () => {
    setOpenFormCredential(true)
  }
  const handleCloseCredentialForm = () => {
    setOpenFormCredential(false)
  }
  //1st dialog ----

  const [openCredentialForm, setOpenCredentialForm] = React.useState(false)
  const handleOpenCredentialForm = () => {
    setOpenCredentialForm(true)
  }
  const handleCloseOpenCredentialForm = () => {
    setOpenCredentialForm(false)
  }

  const [credLoader, setCredLoader] = React.useState(false)
  const handleCallGstAPI = async values => {
    //
    setCredLoader(true)

    if (values.type == 1) {
      let data = {
        app_name: 'GSTAPILOG',
        in_data: `${values.userName}|${btoa(values.password)}|${values.gstNo}`,
        create: checkuserid.user_id,
        modify: checkuserid.user_id
      }

      await axios
        .post(apiCalling.thirdParty + 'server.php/apikarz', data)
        .then(result => {
          //
          console.log(result)
          if (result.data.result.length == 0) {
            setShowMessage('Invalid Credentials')
            setcolorMessage('error')
            setsavenextmessage(true)
            setCredLoader(false)
          } else {
            let storeData = {
              gst_header_id: gstDetailsData.gst_header_id,
              data: result.data.result
            }
            console.log('result-----', result.data.result)
            setCredLoader(false)
            saveGstPull(storeData)

            handleCloseOpenCredentialForm()
            handleCloseCredentialForm()
            handleClose()
            handleCloseOtpCredentialForm()
          }
        })
        .catch(error => {
          setCredLoader(false)
          setShowMessage('Something Went Wrong')
          setcolorMessage('error')
          setsavenextmessage(true)
          console.log(error)
        })
    } else {
      let url =
        apiCalling.thirdParty +
        'server.php/apikaz?app_name=GSTAPIOTP&&in_data=' +
        values.userName +
        '|' +
        values.gstNo +
        '&&create=' +
        checkuserid.user_id +
        '&&modify=' +
        checkuserid.user_id +
        ' '
    }
  }

  const [trans_id, setTrans_id] = React.useState('')

  const handleCallGstAPIOtp = async values => {
    //
    setOpenOtpFormCredential(true)
    setOpen(false)
    if (true) {
      let data = {
        app_name: 'GSTAPIOTP',
        in_data: `${values.userName}|${values.GSTIN1}`,
        create: checkuserid.user_id,
        modify: checkuserid.user_id
      }
      await axios
        .post(apiCalling.thirdParty + 'server.php/apikarz', data)
        .then(result => {
          //
          console.log('-*-*-*-*--', result)
          if (result.data.status_code == 101) {
            console.log(result)
            console.log('result-----', result)
            console.log('result-----> data', result.data)
            setTrans_id(result.data.requestid)
          } else {
            setShowMessage('Something Went Wrong')
            setsavenextmessage(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      let url =
        apiCalling.thirdParty +
        'server.php/apikaz?app_name=GSTAPIOTP&&in_data=' +
        values.userName +
        '|' +
        values.gstNo +
        '&&create=' +
        checkuserid.user_id +
        '&&modify=' +
        checkuserid.user_id +
        ' '
    }
  }

  const [otpLoader, setOtpLoader] = React.useState(false)
  const verifyMobileOtp = async e => {
    //

    let eotp = e
    let len = eotp.length
    if (len == 6) {
      let data = {
        app_name: 'GSTAPIOTPVERIFY',
        in_data: `${trans_id}|${eotp}`,
        create: checkuserid.user_id ? checkuserid.user_id : 0,
        modify: checkuserid.user_id ? checkuserid.user_id : 0
      }
      setOtpLoader(true)

      await axios
        .post(apiCalling.thirdParty + 'server.php/apikarz', data)
        .then(res => {
          //
          if (res.data) {
            const { result, status_code } = res.data
            if (result.length == 0) {
              setsavenextmessage(true)
              setShowMessage('Invalid Otp')
              setOtpLoader(false)
            } else {
              callAsyncFuntion()
              setOpenOtpFormCredential(false)
            }
          }
        })
        .catch(error => {
          // ErrorCatch(error, api_path, 'post', data);
          console.log(error)
          setOtpLoader(false)
        })
    }
  }

  let response = ''

  const callAsyncFuntion = () => {
    //
    response = setTimeout(longPollingForGst, 1000)
  }

  let timeout = 0
  const longPollingForGst = async () => {
    //
    let data = {
      request_id: trans_id
    }
    await axios
      .post(apiCalling.thirdParty + 'server.php/getGstPullData', data)
      .then(result => {
        console.log(result)
        if (result.data.status === false && timeout < 4) {
          timeout++
          response = setTimeout(longPollingForGst, 20000)
        } else {
          console.log(result)
          clearTimeout(response)
          if (result.data.status) {
            // setsavenextmessage(true)
            setOpenFormCredential(false)
            // setShowMessage("Pulled Succesfully");
            let data1 = result.data.data[0].result
            let data2 = JSON.parse(data1)
            let storeData = {
              gst_header_id: gstDetailsData.gst_header_id,
              data: data2
            }
            saveGstPull(storeData)
          } else {
            setsavenextmessage(true)
            setOtpLoader(false)
            setShowMessage('Please Try Again')
          }
        }
      })
      .catch(error => {
        setOtpLoader(false)
      })
  }

  /// save gst pull
  const saveGstPull = async storeData => {
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/captureGST3rdPartyResult',
        storeData
      )
      .then(result1 => {
        //
        if (result1) {
          console.log(result1)
          setShowMessage('Pulled Successfully')
          setcolorMessage(' Pulled Successfull')
          setsavenextmessage(true)
          setLoaderFlag(false)
          setOtpLoader(false)
          getBusinessAndPromoterListByUser()
        }
      })
      .catch(error => {
        setOtpLoader(false)
        console.log(error)
      })
  }

  const [showDocScreen, setShowDocScreen] = React.useState(false)
  const [parentTypeId, setParentTypeId] = React.useState(0)
  const [parentId, setParentId] = React.useState(0)
  ///Document update
  let docDetails = {
    formId: '1.1.7',
    parentId: parentId,
    parentTypeId: parentTypeId
  }

  // Comment Section Code

  //const [showDocScreen, setShowDocScreen] = useState(false);
  const [equiOrCriff, setequiOrCriff] = useState(false)
  const [commentArr, setCommentsArr] = useState([])

  const [comments, setComments] = useState('')
  const sendComments = async () => {
    const user_id = JSON.parse(localStorage.getItem('userdata'))
    //
    let data = {
      user_id: user_id.id,
      application_ref_no: localData.reference_id,
      step: 'Loans',
      sequence_no: '1',
      comments: comments,
      commented_by: user_id.id
    }
    let url = ''
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/verificationComments',
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setShowMessage('Comment Saved')
          setsavenextmessage(true)

          setComments('')
          getverficationComments()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getverficationComments = async () => {
    //
    let data = {
      application_ref_no: localData.reference_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        if (result.data.status === true) {
          console.log('a-------------------------------', result)

          setCommentsArr(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const deleteComment = async id => {
    let data = {
      id: id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        console.log(result)
        setCommentsArr(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [commentBox, setCommentBox] = React.useState({
    width: '100%',
    zIndex: '9999',
    marginTop: '-140%',
    position: 'absolute',
    right: '14%'
    // display: "none"
  })

  const [opencommentbox, setOpenComment] = React.useState(false)

  const changeCommentBox = e => {
    e.preventDefault()
    //
    // setCommentBox({
    //   ...commentBox,
    //   // display: "block"
    // })
    if (opencommentbox) {
      setOpenComment(false)
    } else {
      setOpenComment(true)
    }
  }

  /// end comment section

  const [showPreview, setShowPreview] = React.useState(false)
  const [gstCalculatedPreview, setGSTCalculatedPreview] = React.useState(false)

  const [gstMonths, setGstMonth] = React.useState([])

  const calculatGstMonthWise = async gstlist => {
    let tempGstArray = []

    gstlist.GstForBusiness.map(item2 => {
      console.log(item2)
      let obj = { state_name: item2.state_name, id: item2.id }
      tempGstArray.push(obj)
    })

    let calculationArray = []

    for (let i = 0; i < tempGstArray.length; i++) {
      let data = await getManualDataByGstHeaderIdForCal(tempGstArray[i].id)
      tempGstArray[i]['data'] = data

      calculationArray.push(data)
    }

    if (tempGstArray.length > 0) {
      let tempArr = []
      tempGstArray[0].data.map(month => {
        tempArr.push(month.parameter)
      })
      setGstMonth(tempArr)
    }

    console.log(tempGstArray)
    gstCalculation(calculationArray, tempGstArray)
  }

  const [gstCalArray, setGstCalArray] = React.useState([])

  const gstCalculation = (calculationArray, tempGstArray) => {
    //
    let j = 0
    let globArr = []
    while (j < 12) {
      let tempArr = []
      calculationArray.map((item, i) => {
        tempArr[i] = item[j]
      })
      globArr.push(tempArr)
      j++
    }

    console.log(globArr)
    globArr.map(item => {
      let sum = 0
      let obj = {}
      item.map(item2 => {
        sum += Number(item2.gst_turnover_3b)
      })
      obj = { gst_turnover_3b: sum }
      item.push(obj)
    })

    console.log(globArr)
    // setNewCalArray(globArr);

    let k = globArr[0].length
    let c = 0
    let finalArr = []
    let annualArr = []
    let countNotNull = 0
    while (c < k) {
      let sum1 = 0
      globArr.map((item, i) => {
        //   sum + =  item[m].gst_paid_3b;
        sum1 += Number(item[c].gst_turnover_3b)
        if (
          item[c].gst_turnover_3b !== '' ||
          item[c].gst_turnover_3b !== undefined
        ) {
          countNotNull++
        }
      })

      let obj = {}
      obj = { parameter: 'Total', gst_paid_3b: sum1 }

      finalArr.push(obj)

      let annual = sum1 * countNotNull * globArr.length

      obj = { parameter: 'Annualised', gst_turnover_3b: annual }
      annualArr.push(obj)
      c++
    }

    // finalArr[0].parameter = 'total';
    globArr.push(finalArr)
    // annualArr[0].parameter = 'Annualised';
    globArr.push(annualArr)
    setNewCalArray(globArr)
    setGstCalArray(tempGstArray)
    setGSTCalculatedPreview(true)
  }

  const [newCalArray, setNewCalArray] = React.useState([])

  const getManualDataByGstHeaderIdForCal = async gstheaderid => {
    let dataarray = []
    const data = {
      GST_header_id: gstheaderid,
      eligibility_flag: 0,
      Token: 'qwerty1234'
    }
    // setManualInitialValue({
    //   header_id: gstheaderid,
    //   detailsData: []
    // })
    // setLoaderFlag(true);
    let api_path = apiCalling.coderscorner + 'server.php/api/getGstDetailsByid'
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/getGstDetailsByid', data)
      .then(result => {
        //   setLoaderFlag(false);
        if (result.data.status == true) {
          dataarray = result.data.data
        } else {
          dataarray = []
        }
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
      })
    return dataarray
  }

  const [underLine, setUnderLine] = React.useState(true);

  const getLatFileByParentId = async (busOrPro, ptId) => {
    
    let dint = '';
    let data = {
      parent_id: ptId
    }
   
    let url = apiCalling.coderscorner + 'server.php/api/getLatFileByParentId';

    let response = await axios.post(url, data).then(result => {
      console.log(result);
      if (result.data.status) {
      //  setGstPeriod(result.data.data);
        dint = result.data.data;
        getUploadeDocOfUserV2(busOrPro, ptId,dint);
      }
    
    }).then(error => {
      console.log(error);
    }).catch(error => {
      console.log(error);
    });
    // return dint;
    console.log(response);
  }

  const [documentArray, setDocumentArray] = React.useState([]);
  const getUploadeDocOfUserV2 = async (busOrPro, ptId, dint) => {
    
    const docdata = {
      user_id: localData.user_id,
      parent_id: ptId,
      parent_type_id: busOrPro,
      form: '1.1.7',
      required_phase: '172',
      interval_flag: dint
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'
    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2'

    await axios
      .post(apiPath2, docdata)
      .then(result => {
        console.log('rsutl-----------------', result)
        //
        console.log('my document array----->', myArr)
        if (result.data.status) {
          let tempArr = result.data.data
          let resultArr = []
          if (tempArr.length >= 1) {
            tempArr.map(docchecklist => {
              if (docchecklist.documentArray.length > 0) {
                docchecklist.documentArray.map((docs, i) => {
                  if (
                    docs.file_name != '' &&
                    docs.file_name != undefined &&
                    docs.file_name != null
                  ) {
                    let obj = {
                      text: docs.file_name,
                      value: docs.docPath,
                      file_type: docs.file_type
                    }
                    resultArr.push(obj)
                  }
                })
              }
            })
          }
          //
          setDocumentArray(resultArr);
          setDocPath(resultArr[0].docPath);
          setDocType(resultArr[0].file_type);
        }
      })
      .catch(error => {
        ErrorCatch(1, error, api_path, 'post', docdata, 1, 1)
        console.log(error)
      })
  }
  const [docPath, setDocPath] = React.useState('')
  const [docType, setDocType] = React.useState('')
  const setPrieviewValues = (value, fileType) => {
    //
    let fileExt = fileType.split('/')
    setDocPath(value)
    setDocType(fileExt[1])
  }

  return (
    <Container maxWidth='lg'>
      {gstCalculatedPreview == false ? (
        <div className='mini-container mg_btm_set'>
          <Row>
            <Col md={{ span: 12 }}>
              <div className='content-header'>
                {header == true && (
                  <Row>
                    {/* { requestDetails && (
                  <Col md={{ span: 12 }} style={{ marginBottom: "3%", textAlign: "center" }}>
                    <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                  </Col>
                )} */}
                    <Col md={{ span: 10 }} className='mainheader1'>
                      <Typography text='YOUR GST DETAILS' styleType='C' />
                    </Col>

                    {showDocScreen && (
                      <Col md={{ span: 2 }} className='mainHeaderBack'>
                        <span className='image'>
                          <img
                            alt='im'
                            className='backbutton navbutton'
                            src={Back}
                            onClick={() => {
                              setShowGSTAddForm(false)
                              setShowBusinessPromoterList(true)
                              setHeaders(true)
                              setShowDocScreen(false)
                            }}
                          />
                          <Tooltip title='Back' arrow>
                            <img
                              alt='im'
                              className='backbutton navgraybutton'
                              src={grayback}
                              onClick={() => {
                                setShowGSTAddForm(false)
                                setShowBusinessPromoterList(true)
                                setHeaders(true)
                                setShowDocScreen(false)
                              }}
                            />
                          </Tooltip>
                        </span>
                      </Col>
                    )}
                    <Col md={{ span: 12 }} className='subHeader1'>
                      <table className='applicationTableDetails1'>
                        <tr>
                          {/* <td>Type</td>
                      <td>:</td> */}
                          {localData.request_type_id === 0 && (
                            <td>Loan Application</td>
                          )}
                          {localData.request_type_id === 1 && (
                            <td>Check Eligibility</td>
                          )}
                          <td>&nbsp;|&nbsp;</td>
                          <td>Application Number</td>
                          <td>:</td>
                          <td>{localData.reference_id}</td>
                          <td>&nbsp;|&nbsp;</td>
                          <td>Loan Type</td>
                          <td>:</td>
                          <td>{localData.loan_type}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                )}
              </div>

              {showDocScreen && <DocumentUpload formdetails={docDetails} />}
            </Col>
          </Row>
          {loaderFlag == true && (
            <div
              style={{ textAlign: 'center', marginTop: '15%' }}
              className='loader11'
            >
              <Loader
                type={LoaderColor.type}
                color={LoaderColor.color}
                className='loader12'
              />
            </div>
          )}
          {showBusinessPromoterList == true && loaderFlag == false && (
            <div>
              <Snackbar
                open={savenextmessage}
                style={{ marginTop: '65px' }}
                autoHideDuration={SnackPosition.duration}
                onClose={() => {
                  setsavenextmessage(false)
                }}
                anchorOrigin={{
                  vertical: SnackPosition.vertical,
                  horizontal: SnackPosition.horizontal
                }}
              >
                <Alert
                  onClose={() => {
                    setsavenextmessage(false)
                  }}
                  severity={colorMessage}
                >
                  {showMessage}
                </Alert>
              </Snackbar>
              <SearchBar
                className='search_bar'
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer component={Paper}>
                <Table aria-label='collapsible table' size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell className='tableSLNoHeading'>#</TableCell>
                      <TableCell className='tableBusinessNameHeading'>
                        Business Name
                      </TableCell>
                      <TableCell className='tableGSTHeading'>
                        GST Number
                      </TableCell>
                      <TableCell className='tablePANHeading'>
                        Business PAN
                      </TableCell>
                      <TableCell className='tableEntityTypeHeading'>
                        Entity Type
                      </TableCell>
                      <TableCell className='tableStatusHeading'></TableCell>
                      <TableCell className='tableActionHeading'>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessList.map((item, i) => (
                      <>
                        <TableRow hover className='datable'>
                          <TableCell scope='item'>{i + 1}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{maskGst(item.gst)}</TableCell>
                          <TableCell>{maskBpan(item.bpan)}</TableCell>
                          <TableCell>{item.entity_type}</TableCell>
                          {item.GstForBusiness.length > 0 && (
                            <Tooltip
                              title={
                                item.GstForBusiness.length + ' ' + 'GST Added'
                              }
                              placement='top'
                            >
                              <TableCell className='tableStatusHeading'>
                                <p className='countRowNumber'>
                                  {item.GstForBusiness.length}
                                </p>
                              </TableCell>
                            </Tooltip>
                          )}
                          {item.GstForBusiness.length == 0 && (
                            <Tooltip title='No GST Added' placement='top'>
                              <TableCell className='tableStatusHeading'>
                                <p className='countRowNumberForRed'>
                                  {item.GstForBusiness.length}
                                </p>
                              </TableCell>
                            </Tooltip>
                          )}
                          {/* {item.GstForBusiness.length > 0 && (
                        <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.GstForBusiness.length}</p></TableCell>
                      )}
                      {item.GstForBusiness.length == 0 && (
                        <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.GstForBusiness.length}</p></TableCell>
                      )} */}
                          <TableCell className='tableActionHeading'>
                            {item.GstForBusiness.length > 0 && (
                              <Tooltip
                                title='Open GST Details'
                                placement='bottom'
                              >
                                <Button
                                  aria-label='expand item'
                                  className='actionBtn'
                                  onClick={() =>
                                    handleOpenGSTBankingHeader(
                                      !openGSTBankingHeader[i],
                                      i
                                    )
                                  }
                                >
                                  {openGSTBankingHeader[i] ? (
                                    <img
                                      alt='im'
                                      className='actionBtn svgicon'
                                      src={up}
                                    />
                                  ) : (
                                    <img
                                      alt='im'
                                      className='actionBtn svgicon'
                                      src={down}
                                    />
                                  )}
                                </Button>
                              </Tooltip>
                            )}
                            <Tooltip title='Add GST' placement='bottom'>
                              {/* <Button className="actionBtn" onClick={() => handleOpenGSTAddForm(item)} >
                            <i className="fas fa-plus" style={{ fontSize: "15px" }} />
                          </Button> */}
                              <img
                                alt='im'
                                className='actionBtn svgicon'
                                src={addbutton}
                                onClick={() => handleOpenGSTAddForm(item)}
                              />
                            </Tooltip>
                            <Tooltip title='Prview GST' placement='bottom'>
                              <img
                                alt='im'
                                className='actionBtn svgicon'
                                src={edit}
                                onClick={() => {
                                  calculatGstMonthWise(item)
                                  setGSTCalculatedPreview(true)
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={7}
                          >
                            <Collapse
                              in={openGSTBankingHeader[i]}
                              timeout='auto'
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table size='small' aria-label='bankList'>
                                  <TableHead
                                    style={{ backgroundColor: '#eee' }}
                                  >
                                    <TableRow>
                                      <TableCell className='tableSLNoHeading'>
                                        #
                                      </TableCell>
                                      <TableCell className='tableBusinessNameHeading'>
                                        GST Number
                                      </TableCell>
                                      <TableCell className='tableGSTHeading'>
                                        State
                                      </TableCell>
                                      <TableCell className='tableStatusHeading'>
                                        Status
                                      </TableCell>
                                      <TableCell className='tableActionHeading'>
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.GstForBusiness.map(
                                      (GstForBusiness, j) => (
                                        <TableRow
                                          hover
                                          className='datable'
                                          key={j + 1}
                                        >
                                          <TableCell scope='item'>
                                            {j + 1}
                                          </TableCell>
                                          <TableCell>
                                            {GstForBusiness.gst_no}
                                          </TableCell>
                                          <TableCell>
                                            {GstForBusiness.state_name}
                                          </TableCell>
                                          {GstForBusiness.countrow == 0 && (
                                            <TableCell className='tableStatusHeading'>
                                              <i
                                                class='far fa-times-circle'
                                                style={{ color: 'red' }}
                                              ></i>
                                            </TableCell>
                                          )}
                                          {GstForBusiness.countrow > 0 && (
                                            <TableCell className='tableStatusHeading'>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{ color: 'green' }}
                                              ></i>
                                            </TableCell>
                                          )}
                                          <TableCell className='tableActionHeading'>
                                            <Tooltip
                                              title='Edit GST Data'
                                              placement='bottom'
                                            >
                                              {/* <Button className="actionBtn" onClick={() => { handleGSTEditData(item, GstForBusiness) }} >
                                          <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                                        </Button> */}
                                              <img
                                                alt='im'
                                                className='actionBtn svgicon'
                                                src={edit}
                                                onClick={() => {
                                                  handleGSTEditData(
                                                    item,
                                                    GstForBusiness
                                                  )
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              title='Manual Entry'
                                              placement='bottom'
                                            >
                                              {/* <Button className="actionBtn" onClick={() => { handleManuallyAdd(item, GstForBusiness) }}>
                                          <i className="fas fa-user-edit"
                                            style={{ fontSize: "15px" }} />
                                        </Button> */}
                                              <img
                                                alt='ff'
                                                className='actionBtn svgicon'
                                                src={manualentry}
                                                onClick={() => {
                                                  handleManuallyAdd(
                                                    item,
                                                    GstForBusiness
                                                  );
                                                  getLatFileByParentId(1,GstForBusiness.id)
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              title='Digital Pull'
                                              placement='bottom'
                                            >
                                              {/* <Button className="actionBtn" onClick={() => { handleOpen(GstForBusiness) }}>
                                          <i className="fas fa-download" style={{ fontSize: "15px" }} />
                                        </Button> */}
                                              <img
                                                className='actionBtn svgicon'
                                                alt='dd'
                                                src={digitalpull}
                                                onClick={() => {
                                                  handleOpen(GstForBusiness)
                                                }}
                                              />
                                              {/* onClick={() => { handleOpen(GstForBusiness) }}  */}
                                            </Tooltip>
                                            <Tooltip
                                              title='Upload Document'
                                              placement='bottom'
                                            >
                                              <img
                                                className='actionBtn svgicon'
                                                alt='dd'
                                                src={uploaddoc}
                                                onClick={() => {
                                                  //
                                                  setShowGSTAddForm(false)
                                                  setShowBusinessPromoterList(
                                                    false
                                                  )
                                                  setShowDocScreen(true)
                                                  setParentTypeId(1)
                                                  setParentId(GstForBusiness.id)
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              title='Delete GST'
                                              placement='bottom'
                                            >
                                              {/* <Button className="actionBtn" onClick={() => { deletegst(GstForBusiness.id) }} >
                                          <i className="fas fa-minus"
                                            style={{ fontSize: "15px" }} />
                                        </Button> */}
                                              <img
                                                className='actionBtn svgicon'
                                                alt='d'
                                                src={deleterec}
                                                onClick={() => {
                                                  deletegst(GstForBusiness.id)
                                                }}
                                              />
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='btn_row_fixd'>
                <Row>
                  <Col md={{ span: 10 }}></Col>
                  <Col md={{ span: 10 }}></Col>

                  <Col style={{ padding: '0%' }}></Col>
                  <Col style={{ padding: '0%' }}></Col>
                  {/* className="btnspace" */}
                  {/* <Col style={{ padding: "0%" }}>
                <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                  <img className='navbutton' src={Back} onClick={handledata} />
                </Tooltip>
              </Col> */}
                  {/*  <Col style={{ padding: "0%" }}>
                <Tooltip title="Reset" arrow>
                  <img className='navbutton' src={Reset} />
                </Tooltip>
              </Col> */}
                  {/* <Col style={{ padding: "0%" }}>
                <Tooltip title="Save & Next" arrow>
                  <img className='navbutton' src={SaveNext} />
                </Tooltip>
              </Col> */}
                  {/* <Col style={{ padding: "0%" }}>
                <Tooltip title="Save as Draft & Next" arrow>
                  <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                </Tooltip>
              </Col> */}
                  <Col style={{ padding: '0%' }}>
                    <span className='image'>
                      <img alt='im' className='navbutton' src={Back} />
                      <Tooltip
                        title='Back'
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.customArrow
                        }}
                        arrow
                      >
                        <img
                          alt='im'
                          className='navgraybutton'
                          src={grayback}
                          onClick={handledata}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col style={{ padding: '0%' }}>
                    <span className='image'>
                      <img alt='im' className='navbutton' src={DraftNext} />
                      <Tooltip title='Save & Next' arrow>
                        <img
                          alt='im'
                          className='navgraybutton'
                          src={graydraftnext}
                          onClick={SaveasDraft}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {showGSTAddForm == true && (
            <Formik
              enableReinitialize
              initialValues={{
                id: editGSTData.id,
                Gst: editGSTData.GST,
                State: editGSTData.State,
                parent_type_id: parentInfoData.parent_type_id,
                parent_id: parentInfoData.parent_id
              }}
              validationSchema={GstFormSchema}
              onSubmit={handlesubmitform}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                resetForm,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }} className='mainheader1'>
                            {parentInfoData.parent_type_id == 1 && (
                              <Typography
                                text={
                                  headerValue +
                                  ' GST for Business : ' +
                                  parentInfoData.parent_name
                                }
                                styleType='C'
                              />
                            )}
                            {parentInfoData.parent_type_id == 2 && (
                              <Typography
                                text={
                                  headerValue +
                                  ' GST for Promoter : ' +
                                  parentInfoData.parent_name
                                }
                                styleType='C'
                              />
                            )}
                          </Col>
                          {/* <Col md={{ span: 2 }}>
                        <Button className="backBtn" variant="outlined" color="primary"
                          onClick={() => {
                            resetForm();
                            setShowGSTAddForm(false);
                            setShowBusinessPromoterList(true);
                            setHeaders(true);
                          }} >
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                          </Button>
                      </Col> */}

                          <Col md={{ span: 2 }} className='mainHeaderBack'>
                            <span className='image'>
                              <img
                                className='backbutton navbutton'
                                alt='dd'
                                src={Back}
                                onClick={() => {
                                  resetForm()
                                  setShowGSTAddForm(false)
                                  setShowBusinessPromoterList(true)
                                  setHeaders(true)
                                }}
                              />
                              <Tooltip title='Back' arrow>
                                <img
                                  className='backbutton navgraybutton'
                                  alt='dd'
                                  src={grayback}
                                  onClick={() => {
                                    resetForm()
                                    setShowGSTAddForm(false)
                                    setShowBusinessPromoterList(true)
                                    setHeaders(true)
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </Col>
                          <Col md={{ span: 12 }} className='subHeader1'>
                            <table className='applicationTableDetails1'>
                              <tr>
                                {/* <td>Type</td>
                            <td>:</td> */}
                                {localData.request_type_id === 0 && (
                                  <td>Loan Application</td>
                                )}
                                {localData.request_type_id === 1 && (
                                  <td>Check Eligibility</td>
                                )}
                                <td>&nbsp;|&nbsp;</td>
                                <td>Application Number</td>
                                <td>:</td>
                                <td>{localData.reference_id}</td>
                                <td>&nbsp;|&nbsp;</td>
                                <td>Loan Type</td>
                                <td>:</td>
                                <td>{localData.loan_type}</td>
                              </tr>
                            </table>
                          </Col>
                        </Row>
                        <div>
                          <Row>
                            <Col md={{ span: 6 }}>
                              <Typography text='GSTIN' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='99AAAAA9999A1A9'
                                    name='Gst'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyUp={returnStateOnGST}
                                    InputProps={{
                                      className: values.Gst
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.Gst && touched.Gst && errors.Gst
                                        ? true
                                        : false
                                    }
                                    value={values.Gst}
                                    disabled={formDisabled}
                                    helperText={
                                      errors.Gst && touched.Gst && errors.Gst
                                    }
                                  />
                                </ListItem>
                              </List>
                              <div>
                                {showInvalidMessage === true && (
                                  <span className='valMsg'>
                                    {' '}
                                    {errorMessage}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text='State' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder='Select State'
                                    id='State'
                                    name='State'
                                    fluid
                                    search
                                    selection
                                    disabled={stateDisable}
                                    value={values.State}
                                    onBlur={() =>
                                      setFieldTouched('State', true)
                                    }
                                    onChange={(e, { value }) =>
                                      setFieldValue('State', value)
                                    }
                                    options={statename}
                                    className={
                                      errors.State && touched.State
                                        ? classes.required
                                        : values.State == ''
                                        ? classes.reqired
                                        : classes.man
                                    }
                                  />
                                </ListItem>
                              </List>
                              {errors.State &&
                                touched.State &&
                                values.State == '' && (
                                  <div
                                    style={{
                                      color: 'red',
                                      marginLeft: 14,
                                      fontSize: 12,
                                      fontWeight: '600'
                                    }}
                                  >
                                    {errors.State}
                                  </div>
                                )}
                            </Col>

                            <Col md={{ span: 10 }}></Col>

                            <Col md={{ span: 2 }}>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                              >
                                {addFormButtonValue}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </form>
                )
              }}
            </Formik>
          )}

          {showManuallyAdd == true && (
            <Formik
              enableReinitialize
              initialValues={manualInitialValue}
              onSubmit={saveDetailsDataSubmit}
              //validationSchema={GstFormotherSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                resetForm,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                ////console.log("values in formik", values);
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      {/* <Col md={{ span: 12 }} className="bankingDetailsHeader">
                      {parentInfoData.parent_type_id == 1 && (
                        <Typography text={"Business Name - " + parentInfoData.parent_name} styleType="D" />
                      )}
                      {parentInfoData.parent_type_id == 2 && (
                        <Typography text={"Promoter Name - " + parentInfoData.parent_name} styleType="D" />
                      )}
                    </Col> */}
                      <Col md={{ span: 8 }} className='mainheader1'>
                        <Typography
                          text={'Details for GSTIN - ' + parentInfoData.GST}
                          styleType='C'
                        />
                      </Col>
                      {/* <Col md={{ span: 2 }}>
                    <Button className="backBtn" variant="outlined" color="primary" onClick={handleBusinessPromoterList}>
                      <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                  </Col> */}
                      <Col md={{ span: 2 }} style={{ marginTop: '20px' }}>
                        <Typography
                          text={
                            <i
                              class='fas fa-eye'
                              onClick={() => {
                                //
                                setShowPreview(!showPreview)
                              }}
                            ></i>
                          }
                        />
                        &nbsp;&nbsp;
                      </Col>
                      <Col md={{ span: 2 }} className='mainHeaderBack'>
                        <span className='image'>
                          <img
                            alt='im'
                            className='backbutton navbutton'
                            src={Back}
                            onClick={handleBusinessPromoterList}
                          />
                          <Tooltip title='Back' arrow>
                            <img
                              alt='im'
                              className='backbutton navgraybutton'
                              src={grayback}
                              onClick={handleBusinessPromoterList}
                            />
                          </Tooltip>
                        </span>
                      </Col>
                    </Row>
                    <div>
                      {showPreview === false ? (
                        <Row>
                          <FieldArray name='detailsData'>
                            {values['detailsData'].length > 0 && (
                              <TableContainer>
                                <Table
                                  aria-label='collapsible table'
                                  size='small'
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={table_header1}>
                                        Month
                                      </TableCell>
                                      <TableCell style={table_header}>
                                        GST Turn Over as per 3B
                                      </TableCell>
                                      <TableCell style={table_header}>
                                        GST Paid as per 3B
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values['detailsData'].map((item, k) => (
                                      <TableRow key={k}>
                                        <TableCell style={table_noborder}>
                                          {item.parameter}
                                        </TableCell>
                                        <TableCell style={table_noborder}>
                                          <FormikTextField
                                            id='filled-error-helper-text standard-basic'
                                            type='text'
                                            name={`detailsData.${k}.gst_turnover_3b`}
                                            margin='normal'
                                            inputProps={{ maxLength: 10 }}
                                            disabled={formDisabled}
                                          />
                                        </TableCell>
                                        <TableCell style={table_noborder}>
                                          <FormikTextField
                                            id='filled-error-helper-text standard-basic'
                                            type='text'
                                            name={`detailsData.${k}.gst_paid_3b`}
                                            margin='normal'
                                            inputProps={{ maxLength: 10 }}
                                            disabled={formDisabled}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </FieldArray>
                          <Col md={{ span: 12 }}>
                            <Row>
                              <Col md={{ span: 10 }}></Col>
                              {/* <p>tesr</p> */}

                              {
                                /// floating
                              }
                              {role_id.role_id == 1 || role_id.role_id == 1 ? (
                                <div id='mySidenav' class='sidenav'>
                                  <a
                                    href='/#'
                                    id='about'
                                    className='mySidenav'
                                    style={{ marginLeft: '-84px' }}
                                    onClick={changeCommentBox}
                                  >
                                    Comments
                                  </a>
                                </div>
                              ) : null}

                              <Col md={{ span: 2 }}>
                                <Button
                                  className='updatebtn'
                                  variant='contained'
                                  color='primary'
                                  type='submit'
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </Button>
                              </Col>
                              <Col md={{ span: 5 }}></Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row>
                            <Col md={{ span: 6 }}>
                              <Table
                                border='1'
                                style={{
                                  borderCollapse: 'collapse',
                                  fontSize: '0.9em',
                                  position: 'absolute',
                                  width:'50%',
                                  fontFamily: 'sans-serif'
                                }}
                              >
                                <TableHead
                                  className='second_header'
                                  style={{
                                    display: 'table',
                                    width: '423px',
                                    tableLayout: 'fixed'
                                  }}
                                >
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        padding: '20px 15px',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      Months
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '14px 15px',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      GST Turn Over per 3B
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '8px 14px',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      GST Paid per 3B
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody
                                  style={{
                                    display: 'block',
                                    height: '70vh',
                                    overflowY: 'scroll',
                                   
                                  }}
                                >
                                  {values['detailsData'].map((item, k) => (
                                    <TableRow
                                      style={{
                                        display: 'table',
                                        width: '100%',
                                        tableLayout: 'fixed'
                                      }}
                                    >
                                      <TableCell
                                        className='second_header'
                                        style={{
                                          padding: '4px 8px',
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          fontSize: '14px'
                                        }}
                                      >
                                        {item.parameter}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: '20px 15px' }}
                                      >
                                        <FormikTextField
                                          id='filled-error-helper-text standard-basic'
                                          type='text'
                                          name={`detailsData.${k}.gst_turnover_3b`}
                                          margin='normal'
                                          inputProps={{ maxLength: 10 }}
                                          disabled={formDisabled}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: { textAlign: 'center' }
                                            }
                                          }}
                                          // onMouseOver={() => {
                                          //   // alert("hello");
                                          //   setUnderLine(false);
                                          // }}
                                          // onMouseOut={
                                          //   () => {
                                          //     setUnderLine(true);
                                          //   }
                                          // }
                                        />
                                      </TableCell>

                                      <TableCell
                                        style={{ padding: '20px 15px' }}
                                      >
                                        <FormikTextField
                                          id='filled-error-helper-text standard-basic'
                                          type='text'
                                          name={`detailsData.${k}.gst_paid_3b`}
                                          margin='normal'
                                          inputProps={{ maxLength: 10 }}
                                          disabled={formDisabled}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: { textAlign: 'center' }
                                            }
                                          }}
                                          onMouseOver={() => {
                                            // alert("hello");
                                            setUnderLine(false)
                                          }}
                                          onMouseOut={() => {
                                            setUnderLine(true)
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Col>

                            <Col
                              md={{ span: 6 }}
                              style={{
                                height: '80vh',
                                width: '100%',
                              
                                position: 'relative',
                                zIndex: '1'
                              }}
                            >
                            <Dropdown
                            placeholder='Select Document'
                            name='area'
                            fluid
                            search
                            selection
                            //onBlur={() => setFieldTouched("area", true)}
                            onChange={async (
                              e,
                              { value, text, file_type, options }
                            ) => {
                              //
                              console.log(options)
                              if (options.length > 1) {
                                options.map(item => {
                                  if (item.value === value) {
                                    setPrieviewValues(
                                      value,
                                      item.file_type
                                    )
                                  }
                                })
                              }
                            }}
                            options={documentArray}
                          />
                           <FileViewer
                            fileType={
                              docType
                            }
                            filePath={docPath}
                            //  errorComponent={CustomErrorComponent}
                            className='banking_fileviewer'
                          />
                         </Col>
                      </Row>

                          <Row style={{ marginTop: '25%' }}>
                            <Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2  }} style={{marginTop:'-10em'}}>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                              >
                                Update
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {opencommentbox && (
                        <Row>
                          <Col
                            md={{ span: 6 }}
                            style={{ marginTop: '2%' }}
                          ></Col>
                          <Col md={{ span: 6 }} style={{ marginTop: '2%' }}>
                            <div style={commentBox} className='comment-box'>
                              <h2 style={{ color: 'rgb(114, 84, 163)' }}>
                                Verification Comments
                              </h2>
                              <div className='comment-form'>
                                <div className='comment-form-fields'>
                                  <textarea
                                    className='comment_textfield'
                                    placeholder='Comment'
                                    rows='4'
                                    required
                                    value={comments}
                                    onChange={e => {
                                      //
                                      setComments(e.target.value)
                                    }}
                                  ></textarea>
                                </div>
                                <div className='comment-form-actions'>
                                  <Button
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    className='x'
                                    onClick={e => {
                                      sendComments()
                                    }}
                                  >
                                    Post Comment
                                  </Button>
                                </div>
                              </div>

                              {commentArr.map(item => (
                                <div className='comment'>
                                  <p className='comment-header'>{item.email}</p>
                                  <p className='comment-body'>
                                    - {item.comments}
                                  </p>
                                  <div className='comment-footer'>
                                    <a
                                      href='/#'
                                      className='comment-footer-delete'
                                      onClick={() => {
                                        deleteComment(item.id)
                                      }}
                                    >
                                      Delete Comment
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </form>
                )
              }}
            </Formik>
          )}
        </div>
      ) : (
        <div className='mini-container mg_btm_set'>
          <Row>
            <Col md={{ span: 10 }} className='mainHeaderBack'></Col>
            <Col md={{ span: 2 }} className='mainHeaderBack'>
              <span className='image'>
                <img
                  className='backbutton navbutton'
                  src={Back}
                  alt='img'
                  onClick={() => {
                    setGSTCalculatedPreview(false)
                  }}
                />
                <Tooltip title='Back' arrow>
                  <img
                    className='backbutton navgraybutton'
                    src={grayback}
                    alt='img'
                    onClick={() => {
                      setGSTCalculatedPreview(false)
                    }}
                  />
                </Tooltip>
              </span>
            </Col>
            <Col md={{ span: 12 }}>
              <table border='1' className='styled-table'>
                <tr>
                  <th className='gstcalheader'>Location</th>

                  {gstCalArray.map(item => (
                    <th className='gstcalheader'>{item.state_name}</th>
                  ))}

                  <th className='gstcalheader'>Total</th>
                </tr>

                <tr>
                  <th className='gstcalheader'>Details available for</th>
                  {gstCalArray.map(item => (
                    <th className='gstcalheader'>{12}</th>
                  ))}
                  <th className='gstcalheader'>12</th>
                </tr>
                <tbody>
                  {newCalArray.map((item, i) => (
                    <tr>
                      <td className='gstcalheader'>{item[0].parameter}</td>
                      {item.map(item2 => (
                        <td
                          className={
                            item[0].parameter == 'Total' ||
                            item[0].parameter == 'Annualised'
                              ? ' gstcalheader'
                              : ''
                          }
                        >
                          {item2.gst_turnover_3b}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      )}

      <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Modal.Header
          className='modalHead'
          closeButton
          onClick={handleClose}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>
            Digital Pull
          </Modal.Title>
        </Modal.Header>
        <DialogTitle id='alert-dialog-title' style={{ textAlign: 'center' }}>
          <p className='modaltext'>Choose the option to continue</p>
        </DialogTitle>
        <div>
          <DialogContent style={{ textAlign: 'center' }}>
            <div className='dialoBtng'>
              <Button
                onClick={handleOpenCredentialForm}
                className='backBtns'
                variant='outlined'
                style={{ marginTop: '-25px' }}
                color='primary'
              >
                GST
              </Button>

              <Button
                onClick={handleOpenFormCredential}
                className='backBtns'
                variant='outlined'
                color='primary'
              >
                OTP
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        className='gst-modal'
        disableBackdropClick
        open={openCredentialForm}
        onClose={handleCloseOpenCredentialForm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <MuiDialogTitle>
          <p className='dialogHeader'>GST Login</p>
          {handleCloseOpenCredentialForm ? (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={handleCloseOpenCredentialForm}
            >
              <CloseIcon
                style={{
                  marginTop: '-10px',
                  color: 'white',
                  fontWeight: '800',
                  cursor: 'pointer'
                }}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogTitle id='alert-dialog-title'>
          <p className='modalText'>
            Please enter your GST credential Details for fetching the GST data.
          </p>
        </DialogTitle>
        {credLoader == false ? (
          <DialogContent>
            <Formik
              enableReinitialize
              initialValues={gstDetailsData}
              onSubmit={handleCallGstAPI}
              validationSchema={gstPull}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='modelDivPadding'>
                      <Row>
                        <Col md={{ span: 6 }}>
                          <Typography text='User Name' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            label='User Name'
                            name='userName'
                            value={values.userName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={formDisabled}
                          />
                          {touched.userName && errors.userName ? (
                            <span className='error_message_itr'>
                              {errors.userName}
                            </span>
                          ) : (
                            <span> </span>
                          )}
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='Password' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            label='Password'
                            name='password'
                            type='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={formDisabled}
                          />
                          {touched.password && errors.password ? (
                            <span className='error_message_itr'>
                              {errors.password}
                            </span>
                          ) : (
                            <span> </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className='modelDivPadding'>
                      <Button
                        onClick={handleCloseOpenCredentialForm}
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={handleSubmit}
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                        style={{ marginRight: '2%', marginBottom: '3%' }}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </DialogContent>
        ) : (
          <>
            <div style={{ textAlign: 'center', margin: '5%' }}>
              <Loader type={LoaderColor.type} color={LoaderColor.color} />
            </div>
          </>
        )}
      </Dialog>
      {/********************************dialog 2 start here  ************************************************/}
      <Dialog
        disableBackdropClick
        open={openFormCredential}
        onClose={handleCloseCredentialForm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <MuiDialogTitle>
          <p className='dialogHeader'>GST Login</p>
          {handleCloseCredentialForm ? (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={handleCloseCredentialForm}
            >
              <CloseIcon
                style={{
                  marginTop: '-10px',
                  color: 'white',
                  fontWeight: '800',
                  cursor: 'pointer'
                }}
              />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <DialogTitle id='alert-dialog-title'>
          <p className='modalText'>
            Please enter your GSTIN credential Details for fetching the GSTIN
            data.
          </p>
        </DialogTitle>

        {false === otpLoader ? (
          <DialogContent>
            <Formik
              enableReinitialize
              initialValues={gstDetailsDataOtp}
              onSubmit={handleCallGstAPIOtp}

              //onClick={handleOpenFormCredential}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='modelDivPadding'>
                      <Row>
                        <Col md={{ span: 6 }}>
                          <Typography text='User Name' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            label='User Name'
                            name='userName'
                            value={values.userName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={formDisabled}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='GSTIN' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            label='GSTIN1'
                            name='GSTIN1'
                            type='text'
                            value={values.GSTIN1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={formDisabled}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='modelDivPadding'>
                      <Button
                        onClick={handleCloseCredentialForm}
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                      >
                        Cancel
                      </Button>

                      <Button
                        // onClick={handleOpenOtpFormCredential}
                        className='backBtn'
                        variant='outlined'
                        onClick={handleSubmit}
                        color='primary'
                        style={{ marginRight: '2%', marginBottom: '3%' }}
                      >
                        Send
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </DialogContent>
        ) : (
          <div style={{ textAlign: 'center', margin: '5%' }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}
      </Dialog>

      {/* **************************dialog 3 here ********************************************* */}
      <Dialog
        disableBackdropClick
        open={openOtpFormCredential}
        onClose={handleCloseOtpCredentialForm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Modal.Header
          className='modalHead'
          closeButton
          onClick={handleCloseOtpCredentialForm}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>
            Please Enter OTP{' '}
          </Modal.Title>
        </Modal.Header>
        <div>
          <DialogContent className='first_modeel_header'>
            <div className='otp_screen'>
              <InputOtp onChange={verifyMobileOtp} />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </Container>
  )
}
