import React from 'react';
import { loadCSS } from 'fg-loadcss';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Typography from '../../base_components/Typography'
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Paper from '@material-ui/core/Paper';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { useStyles } from "./styles";
// import Typography from "../../base_components/Typography";
import { MDBDataTableV5 } from 'mdbreact';
import Icon from "@mdi/react";
import {
  mdiPlaylistPlus, mdiRefresh
} from "@mdi/js";



function createData(name, calories, fat,) {
  return { name, calories, fat, };
}

const ApprovalWorkflow1 = (props) => {
  const classes = useStyles();
  const [panvalue, setpanvalue] = React.useState();
  const [email, setemail] = React.useState('');
  const [emailotp, setemailotp] = React.useState();
  const [mobile, setmobile] = React.useState();
  const [mobotp, setmobotp] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [role, setrole] = React.useState('');
  const [datatable, setDatatable] = React.useState({

    columns: [
      {
        label: 'Sl No.',
        field: 'slno',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Name',
        field: 'name',
        width: 270,
      },
      {
        label: 'Business Channel',
        field: 'BusinessChannel',
        width: 100,
      },
      {
        label: 'Approval',
        field: 'approval',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: [
      {
        slno: '1',
        name: 'Mr. Bank Representative',
        BusinessChannel: 'ABC Bank, ABCD00056',
        approval: 'Review',
        action: <Select style={{ width: '70%' }}
          value={role}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>
            Select
              </MenuItem>
          <MenuItem value='Admin'>Approved</MenuItem>
          <MenuItem value='Credit'>Rejected</MenuItem>
          <MenuItem value='Doc'>Locked</MenuItem>
        </Select>,
      },
      {
        slno: '2',
        name: 'Mr. Rajesh',
        BusinessChannel: 'DSA - South Bangalore',
        approval: 'Review',
        action: <Select style={{ width: '70%' }}
          value={role}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>
            Select
              </MenuItem>
          <MenuItem value='Admin'>Approved</MenuItem>
          <MenuItem value='Credit'>Rejected</MenuItem>
          <MenuItem value='Doc'>Locked</MenuItem>
        </Select>,
      },
      {
        slno: '3',
        name: 'Mr.Kiran',
        BusinessChannel: 'DSA - North Bangalore',
        approval: 'Review',
        action: <Select style={{ width: '70%' }}
          value={role}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>
            Select
              </MenuItem>
          <MenuItem value='Admin'>Approved</MenuItem>
          <MenuItem value='Credit'>Rejected</MenuItem>
          <MenuItem value='Doc'>Locked</MenuItem>
        </Select>,
      },
    ],
  });
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setrole(event.target.value);
  };

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);

  const handleSubmit = () => {

    props.handleNext();
  };


  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: -30, padding: '15px' }}>
        <Row>
          <Col md={{ span: 12 }}>
            <div className="content-header" style={{ position: 'unset' }}>

              <Row>
                <Col md={{ span: 6 }}>

                  <Typography
                    text="Approval Workflow 1"
                    styleType="C"
                  />

                </Col>
                <Col md={{ span: 6 }}>
                  <div className="content-header-button ">

                    <Button
                      className={classes.linkdin}
                      variant="contained"
                      color="primary"
                      type="submit"
                      //   onClick={handleSubmit}
                      //   disabled={isSubmitting}
                      className={classes.button_others}>
                      <Icon path={mdiPlaylistPlus} title="save" size={1} /></Button>




                    <Button
                      className={classes.button_div}
                      variant="outlined"
                      color="primary"
                      className={classes.button_others}

                    ><Icon path={mdiRefresh} title="reset" size={1} /></Button>

                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              {/* <TableContainer component={Paper}>
                 <Table className={classes.table} aria-label="caption table">
        
        <TableHead>
          <TableRow>
           
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Joining Date</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center">Action</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" align="center">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="center"> <FormControl className={classes.formControl}>
        <Select
          value={role}
          // onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>
            {row.fat}
          </MenuItem>
          <MenuItem value='Admin'>Admin</MenuItem>
          <MenuItem value='Credit'>Credit Reviewer</MenuItem>
          <MenuItem value='Doc'>Doc Boy</MenuItem>
        </Select>
      </FormControl></TableCell>
              <TableCell align="right" style={{color:'#248f24'}}> <Radio
     ///   checked={selectedValue === 'a'}
     //   onChange={handleChange}
        value="a"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />Approved</TableCell>
              <TableCell align="center" style={{color:'red'}}> <Radio
     ///   checked={selectedValue === 'a'}
     //   onChange={handleChange}
        value="a"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />Rejected</TableCell>
              <TableCell align="left" style={{color:'#3cbce3'}}> <Radio
     ///   checked={selectedValue === 'a'}
     //   onChange={handleChange}
        value="a"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />Locked</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
              <MDBDataTableV5
                className={classes.dtBootstrap4}
                hover
                entriesOptions={[5, 10, 20, 30]}
                entries={5}
                pagesAmount={4}
                data={datatable}
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
              />
            </Row>
            <Row style={{ marginTop: 50, height: 'auto' }}>
              <Col md={{ span: 3 }} >
              </Col>
              <Col md={{ span: 4 }} >
                <Button className={classes.linkdin} variant="contained" color="primary" onClick={handleSubmit}><Icon path={mdiPlaylistPlus} title="save" size={1} />Submit</Button>
              </Col>
              <Col md={{ span: 3 }} >
                <Button className={classes.button_div} variant="outlined" color="primary"><Icon path={mdiRefresh} title="reset" size={1} /> Reset</Button>
              </Col>
            </Row>
          </Col>

        </Row>
      </div>
    </Container>

  );
}
export default ApprovalWorkflow1;