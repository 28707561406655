import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({

    listitem: {
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
    },
    root: {
        width: '100%',
        marginTop: 2,
        backgroundColor: "lightgrey",
    },
    listitems: {
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
        marginLeft: 20,

    },
    button_up: {
        textTransform: "capitalize",
        fontSize: "14px",
        padding: "9px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 230,
        width: 10
    },
    Button: {
        showIcon: true,
    },
    input: {
        // display: 'none',
        marginBottom: 25,
        // marginLeft:10
        //padding:'1px'
    },
    textfield: {
        width: '100%'
    },

    linkdin: {
        backgroundColor: "#007bff",
        textTransform: "capitalize",
        margin: "10px",
        fontSize: "14px",
        padding: "9px",
        width: "61%",
    },
    button_div: {
        textTransform: "capitalize",
        margin: "10px",
        fontSize: "14px",
        padding: "9px",
        width: "61%",
    },

    uploadB: {
        backgroundColor: "#ffb307f2",
        textTransform: "uppercase",
        boxShadow: "1px 3px 7px 4px #b0dbeb6b",
    },
    personalBox: {
        display: 'block',
        padding: '50px',
        boxShadow: '-1px 1px 16px 11px #f8f9fe',
        height: 'auto',
        marginTop: '18px',
    },
    manorma: {
        '&:before': {
            borderBottom: "1.5px solid #00ff00 !important",
        }
    },
    required: {
        border: "1.5px solid red !important",
    },
    man: {
        border: "1.5px solid #00ff00 !important",
    },

}));
