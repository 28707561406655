import React, { useState } from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TextField, Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import Collapse from '@material-ui/core/Collapse'
import { Dropdown } from 'semantic-ui-react'
import { useStyles } from './styles'
import Typography from '../../base_components/Typography'
import { Formik, useFormikContext } from 'formik'
import {
  loanFormSchema,
  loanformapplyschema,
  loanapplyschema,
  loanSchema
} from '../../shared/validations'
import axios from 'axios'
import clsx from 'clsx'
import DraftNext from '../../image/buttons/draftnext.png'
import Back from '../../image/buttons/back.png'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ProgressBar from 'react-bootstrap/ProgressBar'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DocumentUpload from '../../components/DocumentUpload'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import grayback from '../../image/buttons/grayback.png'
import manualentry from '../../image/pngicons/manualaddition.png'
import uploaddoc from '../../image/pngicons/upload.png'
import deleterec from '../../image/pngicons/delete.png'
import up from '../../image/pngicons/up.png'
import down from '../../image/pngicons/down.png'
import edit from '../../image/pngicons/edit.png'
import digitalpull from '../../image/pngicons/digitalpull.png'
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogActions from '@material-ui/core/DialogActions'
import { ErrorCatch, apiCalling } from '../../shared/constants'
import ErrorIcon from '@material-ui/icons/Error'
import addbutton from '../../image/pngicons/plussign.png'
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'
import SearchBar from 'material-ui-search-bar'
import Modal from 'react-bootstrap/Modal'

import FileViewer from 'react-file-viewer'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import OtpTimer from 'otp-timer'
import InputOtp from '@onefifteen-z/react-input-otp'
import DialogContentText from '@material-ui/core/DialogContentText'
import Select from 'react-select'

const Loan = props => {
  const classes = useStyles()


  const [loaderFlag, setLoaderFlag] = React.useState(true)
  const [showform, setshowform] = React.useState(true)
  const [topform, settopform] = React.useState(false)
  const [table, settable] = React.useState(false)
  const [tableopen, settableopen] = React.useState(false)
  const [data, setdata] = React.useState([])
  const [financial, setfinancial] = React.useState([])
  const [bank, setbank] = React.useState([])
  const [original, setoriginal] = React.useState([])
  const [loantype, setloantype] = React.useState([])
  const [stillloan, setstillloan] = React.useState([])
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false)
  const [shareEnable, setShareEnable] = useState(true)
  const [checkvalue, setchecked] = React.useState(0)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })
  const [checkval, setchecktable] = React.useState('')
  const [businesstable, setbusinesstable] = useState(false)
  const [promotable, setpromotable] = useState(false)
  const [showUpdateBtn, setshowUpdateBtn] = useState(false)

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: '50%'
  }

  const loan_cell = {
    // fontSize: "16px",
    // textAlign: "center"
  }

  const table_header3 = {
    fontWeight: '600',
    fontSize: '100%',
    width: '50%',
    textAlign: 'center',
    fontSize: '18px'
  }

  const table_header1 = {
    fontWeight: '600',
    width: '10%'
  }
  const table_header2 = {
    fontWeight: '600',
    fontSize: '100%',
    width: '12%',
    textAlign: 'center',
    padding: '0px'
  }

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%',
    width: '5%'
  }
  const table_Action = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center'
    // display: "flex"
  }

  const sub_table_action = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center',
    // display: "flex",
    //padding: "39% 0%!important"
    // padding: "12px 3px 4px 0px",
    width: '100px'
  }
  const table_Action1 = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center',
    display: 'flex',
    marginTop: '20px'
  }
  const table_noborder = {
    borderBottom: '1px solid #fff'
  }

  const table_padding = {
    padding: '0px'
  }
  const styles = theme => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })

  const [formDisabled, setFormDisabled] = useState(false)
  const role_id = JSON.parse(localStorage.getItem('userdata'))

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (role_id.role_id == 5) {
      setFormDisabled(true)
    }
    checkapply()
    checktableinfo()
    financialcheck()
    loantypecheck()
    originalcheck()
    loanstillcheck()
    getPramotorList()
    getAllBusinessbyuser()
    getAllBusinessAndPromoter()
    bankcheck()
    // getAllLoansDetailsByUserId();
    checkIfRequest()
    console.clear()
    getverficationComments()
  }, [])

  const [name, setName] = React.useState('')
  const [bpan, setBpan] = React.useState()
  const [gst, setGst] = React.useState('')
  const [email, setemail] = React.useState('')
  const [mobile, setmobile] = React.useState()

  const maskEmail = memail => {
    if (memail != undefined && memail != "" && memail !== null) {
      let mail = memail
      mail = mail.split('')
      let maskedArray = []
      let length = mail.indexOf('@')
      mail.map((item, i) => {
        i >= 1 && i < length - 1
          ? maskedArray.push('*')
          : maskedArray.push(mail[i])
      })
      let finalEmail = maskedArray.join('')
      //setemail(finalEmail);
      // console.log(email);
      return finalEmail;
    } else {
      return "";
    }
  }
  const maskMobile = mmobile => {
    if (mmobile != undefined && mmobile != "" && mmobile != null) {
      let mbl = mmobile
      let mblArray = mbl.split('')
      let maskedArray = []
      let len = mblArray.length
      mblArray.map((item, i) => {
        i == 1 || i == 0 || i == len - 1
          ? maskedArray.push(mblArray[i])
          : maskedArray.push('*')
      })
      let finalMobile = maskedArray.join('')
      // setmobile(finalMobile);
      return finalMobile;
    } else {
      return "";
    }
  }

  const maskName = mname => {
    if (mname != undefined && mname != "" && mname != null) {
      let name = mname
      name = name.split('')
      let maskedArray = []
      //  let length = name.indexOf('@');
      let length = name.length
      name.map((item, i) => {
        i >= 1 && i < length - 1
          ? maskedArray.push('*')
          : maskedArray.push(name[i])
      })
      let finalName = maskedArray.join('')
      // setName(finalEmail);
      // console.log(finalName);
      return finalName;
    } else {
      return "";
    }
  }
  const maskBpan = mbpan => {
    if (mbpan != undefined && mbpan != "" && mbpan != null) {
      let bpan = mbpan
      bpan = bpan.split('')
      let maskedArray = []
      //let length = bpan.indexOf('@');
      let length = bpan.length
      bpan.map((item, i) => {
        i >= 1 && i < length - 1
          ? maskedArray.push('*')
          : maskedArray.push(bpan[i])
      })
      let finalBpan = maskedArray.join('')
      //setBpan(finalEmail);
      // console.log(finalBpan);
      return finalBpan;
    } else {
      return "";
    }
  }
  const maskGst = mgst => {
    if (mgst != undefined && mgst != "" && mgst != null) {
      let gst = mgst
      gst = gst.split('')
      let maskedArray = []
      let len = gst.length
      gst.map((item, i) => {
        i == 1 || i == 0 || i == len - 1
          ? maskedArray.push(gst[i])
          : maskedArray.push('*')
      })
      let finalGst = maskedArray.join('')
      //setGst(finalMobile);
      return finalGst;
    } else {  
      return "";
    }
  }

  const [requestDetails, setRequestDetails] = React.useState()
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem('rquestIdDetails')))
  }

  const [searched, setSearched] = React.useState('')
  const [newBus, setNewBus] = React.useState()
  const requestSearch = searchedVal => {
    
    let busArr = userBusinessList
    let tempArr = []

    tempArr = busArr.filter(row => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase())
    })
    setUserBusinessList(tempArr)

    // tempArr = [];

    busArr.map(row => {
      row.loanForBusiness.filter(item => {
        // return item.financer_name.toLowerCase().includes(searchedVal.toLowerCase());
        if (
          item.financer_name.toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          if (
            tempArr.find(findArr => findArr.id != row.id) == undefined
              ? true
              : false
          ) {
            tempArr.push(row)
          }
        }
      })
    })
    setUserBusinessList(tempArr)

    // busArr.filter((row) => {
    //   if (row.name.toLowerCase().includes(searchedVal.toLowerCase())) {
    //     // if (tempArr.length >= 1) {
    //     //   tempArr.map((item2) => {
    //     //     if (item2.id != row.id)
    //     //       tempArr.push(row);
    //     //   })
    //     // } else {
    //     //   tempArr.push(row);
    //     // }
    //     if (tempArr.length > 0) {
    //       let found = undefined;
    //       found = tempArr.find(item => item.id == row.id);
    //       if (found == undefined) {
    //         tempArr.push(row);
    //       }
    //     }
    //   } else {
    //     tempArr.push(row);
    //   }
    // });

    //setBusinessList(tempArr);
  }
  const cancelSearch = () => {
    
    setSearched('')
    setUserBusinessList(secondUserBusinessList)
    //setBusinessList(newBus)
  }
  const [searchedPromo, setSearchedPromo] = React.useState('')
  const [newPro, setNewPro] = React.useState()

  const requestSearchPromo = searchedVal => {
    
    let promoArr = promoterList

    if (searchedVal.length != 0 && searchedVal != '') {
      const filteredRows = promoArr.filter(row => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase())
      })
      // setRows(filteredRows);
      setPromoterList(filteredRows)
    } else {
      setPromoterList(newPro)
    }
  }

  const cancelSearchPromo = () => {
    
    setSearchedPromo('')

    setPromoterList(newPro)
  }

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

  //#region  check for elegibility or apply for loan
  const checkapply = () => {
    const checkdata = localStorage.getItem('RequestType'),
      checkform = JSON.parse(checkdata)
    if (checkform == 1) {
      console.log('applicationm', checkform)
      setchecked(checkform)
    } else {
      setchecked(0)
    }
  }
  const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
  let iduser = JSON.parse(checkuserloggeddata)
  //#endregion
  //otp send
  const [otpBtn, setOtpBtn] = useState(false)
  const [otpMinutes, setOtpMinutes] = useState()
  const [otpSeconds, setOtpSeconds] = useState()
  //#region getAll promoter and business of the use
  const [mobileExistsMsg, setMobileExistMmsg] = useState()
  const [counrty, setCountry] = useState()
  const [promomobile, setPromoMobile] = React.useState()
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true)
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false)
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false)
  //#region getAll promoter and business of the use
  const [showPromoterTable, setShowPromoterTable] = React.useState(false)
  const [userPromoterList, setUserPromoterList] = React.useState([])
  const [userBusinessList, setUserBusinessList] = React.useState([])
  const [secondUserBusinessList, setSecondUserBusinessList] = React.useState([])
  //const [useLoanList, setUserLoanList] = React.useState([]);
  const [loanHeaderOpen, setLoanHeaderOpen] = useState([])
  const [loanHeaderOpenPromo, setLoanHeaderOpenPromo] = useState([])
  const [header, setHeader] = React.useState(true)
  const [headerValue, setHeaderValue] = React.useState('')
  const getAllBusinessAndPromoter = async () => {
    
    const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.user_id)
    setidofuser(iduser.user_id)
    let businessArray = []
    let promoterArray = []
    let data = {
      user_id: iduser.user_id
    }
    let api_path =
      apiCalling.coderscorner +
      `server.php/api/getLoansDetailsBusinessAndPromoterById`
    await axios
      .post(
        apiCalling.coderscorner +
        `server.php/api/getLoansDetailsBusinessAndPromoterById`,
        data
      )
      .then(result => {
        setbusinesstable(true)
        setpromotable(true)
        setLoaderFlag(false)

        setUserBusinessList(result.data.data.businessList)
        setSecondUserBusinessList(result.data.data.businessList)
        // result.data.data.businessList.map((item, i) => {
        //   businessArray.push({
        //     id: item.id,
        //     key: item.id,
        //     id: item.id,
        //     name: item.name,
        //     gst: item.gst,
        //     bpan: item.bpan,
        //     entity_type: item.entity_type,
        //     lonsArrays: item.loanForBusiness,
        //   })
        // });
        let busLoanArr = []
        for (let i = 0; i < result.data.data.businessList.length; i++) {
          busLoanArr.push(false)
        }
        setLoanHeaderOpen(busLoanArr)
        let promoLoanArr = []
        for (let i = 0; i < result.data.data.promoterList.length; i++) {
          promoLoanArr.push(false)
        }
        if (result.data.data.promoterList.length > 0) {
          setShowPromoterTable(true)
        } else {
          setShowPromoterTable(false)
        }
        setLoanHeaderOpenPromo(promoLoanArr)
        console.log('promooooooo', result.data.data.promoterList)
        // result.data.data.promoterList.map((item, i) => {
        //   promoterArray.push({
        //     key: item.promoter_id,
        //     promoter_id: item.promoter_id,
        //     first_name: item.first_name,
        //     last_name: item.last_name,
        //     business_name: item.business_name,
        //     email: item.email,
        //     mobile: item.mobile,
        //     lonsArray: item.loanForBusiness,
        //   })
        // })
        setUserPromoterList(result.data.data.promoterList)
        // getAllLoansDetailsByUserId(businessArray[0].id)
        if (result.data.data.promoterList.length > 0) {
          setShowPromoterTable(true)
          setPromoterList(result.data.data.promoterList)
          setNewPro(result.data.data.promoterList)
        } else {
          setShowPromoterTable(false)
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          data,
          iduser.user_id,
          iduser.user_id
        )
        console.log(error)
      })
  }
  //country code selection
  const selectCountry = val => {
    setCountry(val)
  }
  //#region businessload add button
  const [parentTypeId, setParentTypeId] = useState()
  const [businessName, setBusinessName] = useState('')
  const [businessId, setBusinessId] = useState()
  const addBusinessManuaaly = item => {
    
    bankcheck(item.bus_id)
    setshowThreeBtn(false)
    setHeader(false)
    setParentTypeId(1)
    //  setchecked("business")
    // console.log(busid + " " + name);
    setbusinesstable(false)
    setpromotable(false)
    console.log(item)
    setBusinessName(item.name)
    setBusinessId(item.id)
    setchecktable('business')
    settopform(true)
    //  getAllLoansDetailsByUserId(item.id, parentID);
    // setTopForm(true);
    setParentTypeId(1)
    // setLoanTable(false);
  }
  //#endregion

  const addPromoterManually = item => {
    setshowThreeBtn(false)
    setHeader(false)
    setbusinesstable(false)
    setpromotable(false)
    setBusinessName(item.first_name)
    setBusinessId(item.promoter_id)
    bankcheck(item.parent_id)
    setParentTypeId(2)
    //getAllLoansDetailsByUserId(item.promoter_id, parentId);
    //   setTopForm(true);
    settopform(true)
  }

  const checktableinfo = () => {
    const checktabledata = localStorage.getItem('checktable'),
      checktables = JSON.parse(checktabledata)
    console.log('checktables', checktables)
    setchecktable('business')
  }

  //#region getBusiness List  based on userid or pandid
  const [businesslist, setbusinesslist] = React.useState([])
  const [promoterList, setPromoterList] = React.useState([])

  const [idofuser, setidofuser] = React.useState()
  const getAllBusinessbyuser = async () => {
    const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.user_id)
    setidofuser(iduser.user_id)
    const data = {
      user_id: iduser.user_id
    }
    let busArr = []
    let api_path =
      apiCalling.coderscorner + 'server.php/api/getBussinessByuserid'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
        data
      )
      .then(result => {
        //alert('user')
        console.log(result.data.data)
        result.data.data.map(item => {
          busArr.push({
            key: item.id,
            businessName: item.name,
            businessid: item.id,
            value: item.id,
            text: item.name
          })
        })
        setbusinesslist(busArr)
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          data,
          iduser.user_id,
          iduser.user_id
        )
        console.log(error)
      })
  }
  //#endregion

  var financialarray = []
  const financialcheck = async () => {
    const financialdata = {
      token: 'qwerty1234'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAllFinancier`,
        financialdata
      )
      .then(res => {
        res.data.data.map(item => {
          console.log('item', item)
          financialarray.push({
            key: item.id,
            value: item.id,
            text: item.name
          })
        })
        setfinancial(financialarray)
        // console.log("financialarray", financialarray);
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }
  const bankcheck = async parent_id => {
    const bankdata = {
      bank_id: 1,
      token: 'qwerty1234'
    }

    let data2 = {
      parent_id: parent_id
    }

    var bankarray = []
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getBankByParentId`, data2)
      .then(res => {
        res.data.data.map(item => {
          console.log('item', item)
          bankarray.push({
            key: item.bank_id,
            value: item.bank_id,
            text: item.bank_name,
            bank_account_no: item.bank_account_no
          })
        })
        setbank(bankarray)
        console.log('bankarray', bankarray)
      })
      .catch(er => {
        console.log('no data', er)
      })
  }

  var loanstillarray = []
  const loanstillcheck = async () => {
    const loanstilldata = {
      token: 'qwerty1234',
      type: 'yes_no'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        loanstilldata
      )
      .then(res => {
        res.data.data.map(item => {
          console.log('item', item)
          loanstillarray.push({ key: item.id, value: item.id, text: item.name })
        })
        setstillloan(loanstillarray)
        console.log('loanstillarray', loanstillarray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  var loantypearray = []
  const loantypecheck = async () => {
    const loandata = {
      token: 'qwerty1234'
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllloanType`, loandata)
      .then(res => {
        res.data.data.map(item => {
          console.log('item', item)
          loantypearray.push({ key: item.id, value: item.id, text: item.name })
        })
        setloantype(loantypearray)
        console.log('loantypearray', loantypearray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  var originalarray = []
  const originalcheck = async () => {
    const originaldata = {
      token: 'qwerty1234',
      type: 'tenure_types'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        originaldata
      )
      .then(res => {
        res.data.data.map(item => {
          console.log('item', item)
          originalarray.push({ key: item.id, value: item.id, text: item.name })
        })
        setoriginal(originalarray)
        console.log('originalarray', originalarray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }
  //#region  not using this for now
  const [datatable1, setDatatable1] = React.useState([])
  const getPramotorList = async () => {
    const pramoData = {
      bus_id: 2
    }
    let prdata = []

    let api_path =
      apiCalling.coderscorner + 'server.php/api/getAllpromoterinfobybusid'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getAllpromoterinfobybusid',
        pramoData
      )
      .then(result => {
        //console.log(">>------->",result);
        if (result.data.data !== undefined && result.data.data !== null) {
          console.log('>>----->', result.data.data)
          result.data.data.map(item => {
            prdata.push({
              key: item.id,
              id: item.id,
              value: item.id,
              firstName: item.first_name,
              middleName: item.middle_name,
              lastName: item.last_name,
              emailid: item.email
            })
          })
          setDatatable1(prdata)
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          pramoData,
          iduser.user_id,
          iduser.user_id
        )
      })
  }
  const handleNextClick = () => {
    const localStorageData = localStorage.getItem('loan'),
      loandata = JSON.parse(localStorageData)
    setdata(loandata)
    // setshowform(false)
    settopform(false)
    settable(true)
    settableopen(true)
  }

  const handledata = () => {
    setinitialValue({
      companyle: '',
      l_id: '',
      financer: '',
      ltype: '',
      lamount: '',
      emi: '',
      originalte: '',
      remainemi: '',
      accno: '',
      noemi: '',
      edate: '',
      loanopen: '',
      emibounce3: '',
      emibounce6: '',
      obgconsidered: '',
      obgconsidered6: '',
      obgconsidered12: '',
      obgconsidered12gr: '',
      obligation_considered: '',
      obligation_considered_gt6: '',
      obligation_considered_gt12: '',
      obligation_considered_lt12: '',
      emibounce3month: '',
      emibounce6month: '',
      bank: '',
      bank_account_no: ''
    })

    settopform(false)
    setbusinesstable(true)
    setpromotable(true)
    setHeader(true)
    setshowUpdateBtn(false)
    setHeaderValue('Adding')
    setshowThreeBtn(true)
  }
  const SaveasDraft = () => {
    props.handleNext()
  }

  //#region apply for loan
  const handleapplyloan = async data => {
    
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let loanOpen
    if (data.loanopen === 15) {
      loanOpen = 1
    } else if (data.loanopen === 16) {
      loanOpen = 0
    }
    let loandata = {
      // business_id: businessId,
      loan_id: data.l_id,
      parent_type_id: parentTypeId,
      parent_id: businessId,
      financer: data.financer,
      l_type: data.ltype,
      l_amount: data.lamount,
      l_tenture: data.originalte,
      emi: data.emi,
      l_open: loanOpen,
      emi_date: data.edate,
      no_emi: data.noemi,
      acc_no: data.accno,
      rem_emi: data.remainemi,
      emi_bounce_in_3_months: data.emibounce3,
      emi_bounce_in_6_months: data.emibounce6,
      obligation_considered: data.obgconsidered,
      obligation_considered_greater_than_6m: data.obgconsidered6,
      obligation_considered_less_than_12m: data.obgconsidered12,
      obligation_considered_greater_than_12m: data.obgconsidered12gr,
      obligation_considered_bool: data.obligation_considered_bool,
      obligation_considered_gt6: data.obligation_considered_gt6,
      obligation_considered_gt12: data.obligation_considered_gt12,
      obligation_considered_lt12: data.obligation_considered_lt12,
      no_of_emi_bounced_3m: data.emibounce3month,
      no_of_emi_bounced_6m: data.emibounce6month,
      Eligibility_flag: false,
      user_id: checkuserid.user_id,
      bank_id: data.bank,
      bank_account_no: data.bank_account_no,
      Token: 'qwerty1234'
    }
    //  console.log('result ', loandata)
    let api_path = apiCalling.coderscorner + `server.php/api/loanForBusiness`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/loanForBusiness`,
        loandata
      )
      .then(res => {
        if (res.data.status == true) {
          setdata(loandata)
          console.log('yeh we have', res.data)
          // getAllLoansDetailsByUserId(businessId, parentTypeId);
          settopform(false)
          setbusinesstable(true)
          setpromotable(true)
          getAllBusinessAndPromoter()
          setHeader(true)
          setshowThreeBtn(true)
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          props.handleCount(requestDetails.user_id)
        }
        // showLoanTable(true);
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          loandata,
          checkuserid.user_id,
          checkuserid.user_id
        )
        console.log('no data sorry ', er)
      })
  }

  //#region  check elegibilty save
  const handleapplyeligi = async data => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let loanOpen
    if (data.loanopen === 15) {
      loanOpen = 1
    } else if (data.loanopen === 16) {
      loanOpen = 0
    }
    let eligibledata = {
      user_id: checkuserid.user_id,
      loan_id: data.l_id,
      parent_type_id: parentTypeId,
      parent_id: businessId,
      financer: data.financer,
      l_type: data.ltype,
      l_amount: Number(data.lamount),
      l_tenture: data.originalte,
      emi: Number(data.emi),
      emi_bounce_in_3_months: Number(data.emibounce3),
      emi_bounce_in_6_months: Number(data.emibounce6),
      l_open: loanOpen,
      bank_id: data.bank,
      bank_account_no: data.bank_account_no,
      Token: 'qwerty1234',
      Eligibility_flag: true
    }
    console.log('result', eligibledata)
    let api_path = apiCalling.coderscorner + `server.php/api/loanForBusiness`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/loanForBusiness`,
        eligibledata
      )
      .then(res => {
        if (res.data.status == true) {
          setdata(eligibledata)
          console.log('yeh we have', res.data)
          //   getAllLoansDetailsByUserId(businessId, parentTypeId);
          // showLoanTable(true);
          //settopform(false);
          getAllBusinessAndPromoter()
          setHeader(true)
          setshowThreeBtn(true)
          setcolorMessage('success')
          setShowMessage(res.data.message)
          setsavenextmessage(true)
          props.handleCount(requestDetails.user_id)
        }
      })
      .catch(er => {
        ErrorCatch(
          checkuserid.user_id,
          er,
          api_path,
          'post',
          eligibledata,
          checkuserid.user_id,
          checkuserid.user_id
        )
        console.log('no data sorry', er)
      })
    // setshowform(false)
    settopform(false)
    setbusinesstable(true)
    setpromotable(true)
    // settable(true)
    // settableopen(true)
  }
  //#endregion

  //#region upload data based on Apply for loan or check elegibility
  const handlesubmitform = (values, { resetForm }) => {
    
    if (checkvalue === 0) {
      // setHeader(true);
      handleapplyloan(values)
    } else if (checkvalue === 1) {
      //  alert('checkelihmnblity')
      // setHeader(true);
      handleapplyeligi(values)
    }

    resetForm({
      l_id: '',
      financer: '',
      ltype: '',
      lamount: '',
      emi: '',
      originalte: '',
      remainemi: '',
      accno: '',
      noemi: '',
      edate: '',
      loanopen: '',
      companyle: '',
      emibounce3: '',
      emibounce6: '',
      obgconsidered: '',
      obgconsidered6: '',
      obgconsidered12: '',
      obgconsidered12gr: '',
      obligation_considered_bool: '',
      obligation_considered_gt6: '',
      obligation_considered_gt12: '',
      obligation_considered_lt12: '',
      emibounce3month: '',
      emibounce6month: '',
      bank: '',
      bank_account_no: ''
    })

    setinitialValue({
      companyle: '',
      l_id: '',
      financer: '',
      ltype: '',
      lamount: '',
      emi: '',
      originalte: '',
      remainemi: '',
      accno: '',
      noemi: '',
      edate: '',
      loanopen: '',
      emibounce3: '',
      emibounce6: '',
      obgconsidered: '',
      obgconsidered6: '',
      obgconsidered12: '',
      obgconsidered12gr: '',
      obligation_considered_bool: '',
      obligation_considered_gt6: '',
      obligation_considered_gt12: '',
      obligation_considered_lt12: '',
      emibounce3month: '',
      emibounce6month: '',
      bank: '',
      bank_account_no: ''
    })

    props.handleCount(requestDetails.user_id)
  }

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type == 'keydown' &&
      (event.key == 'Tab' || event.key == 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor == 'top' || anchor == 'bottom'
      })}
      role='presentation ctm_set_div'
    >
      <div className='profile_table'>
        <div className='profile_table_part'>
          <table style={{ marginBottom: 30 }}>
            <thead>
              <tr>
                <th>
                  <h3>Upload Documents</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <Button className='ctm_btn_set footer'>Upload Here!</Button>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Add Appointment</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <Button className='ctm_btn_set footer'>
                    Book Appointment
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  )
  console.log('checkvaluebefore', checkvalue)
  // const [initialValue,setinitialValue] = useState(initialValue1);
  let initialValue1
  if (checkvalue !== 0) {
    initialValue1 =
      checkval !== 'Business'
        ? {
          l_id: '',
          financer: '',
          ltype: '',
          lamount: '',
          emi: '',
          originalte: '',
          remainemi: '',
          accno: '',
          noemi: '',
          edate: '',
          loanopen: '',
          companyle: '',
          emibounce3: '',
          emibounce6: '',
          obgconsidered: '',
          obgconsidered6: '',
          obgconsidered12: '',
          obgconsidered12gr: '',
          obligation_considered_bool: '',
          obligation_considered_gt6: '',
          obligation_considered_gt12: '',
          obligation_considered_lt12: '',
          emibounce3month: '',
          emibounce6month: '',
          bank: '',
          bank_account_no: ''
        }
        : {
          l_id: '',
          type: 'business',
          financer: '',
          ltype: '',
          lamount: '',
          emi: '',
          originalte: '',
          remainemi: '',
          accno: '',
          noemi: '',
          edate: '',
          loanopen: '',
          companyle: '',
          emibounce3: '',
          emibounce6: '',
          obgconsidered: '',
          obgconsidered6: '',
          obgconsidered12: '',
          obgconsidered12gr: '',
          obligation_considered_bool: '',
          obligation_considered_gt6: '',
          obligation_considered_gt12: '',
          obligation_considered_lt12: '',
          bank: '',
          bank_account_no: ''
        }
  } else {
    initialValue1 =
      checkval == 'Business'
        ? {
          l_id: '',
          type: 'business',
          financer: '',
          ltype: '',
          lamount: '',
          emi: '',
          originalte: '',
          loanopen: '',
          companyle: '',
          emibounce3: '',
          emibounce6: '',
          obgconsidered: '',
          obgconsidered6: '',
          obgconsidered12: '',
          obgconsidered12gr: '',
          obligation_considered_bool: '',
          obligation_considered_gt6: '',
          obligation_considered_gt12: '',
          obligation_considered_lt12: '',
          bank: '',
          bank_account_no: ''
        }
        : {
          l_id: '',
          type: 'business',
          financer: '',
          ltype: '',
          lamount: '',
          emi: '',
          originalte: '',
          loanopen: '',
          companyle: '',
          emibounce3: '',
          emibounce6: '',
          obgconsidered: '',
          obgconsidered6: '',
          obgconsidered12: '',
          obgconsidered12gr: '',
          obligation_considered_bool: '',
          obligation_considered_gt6: '',
          obligation_considered_gt12: '',
          obligation_considered_lt12: '',
          bank: '',
          bank_account_no: ''
        }
  }
  const [initialValue, setinitialValue] = useState({
    l_id: '',
    financer: '',
    ltype: '',
    lamount: '',
    emi: '',
    originalte: '',
    remainemi: '',
    accno: '',
    noemi: '',
    edate: '',
    loanopen: '',
    companyle: '',
    emibounce3: '',
    emibounce6: '',
    obgconsidered: '',
    obgconsidered6: '',
    obgconsidered12: '',
    obgconsidered12gr: '',
    obligation_considered_bool: '',
    obligation_considered_gt6: '',
    obligation_considered_gt12: '',
    obligation_considered_lt12: '',
    bank: '',
    bank_account_no: ''
  })

  let validator
  if (checkvalue === 0) {
    validator = checkval === 'Business' ? loanFormSchema : loanSchema
  } else {
    validator = checkval === 'Business' ? loanformapplyschema : loanapplyschema
  }

  //#region  getAllLoan details by  id of business or promoter
  const [showLoanTable, setLoanTable] = useState(false)
  const getAllLoansDetailsByUserId = async (busID, ptypeid) => {
    let loadnArr = []
    let data = {
      parent_type_id: ptypeid,
      parent_id: busID
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getLoanDetails`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getLoanDetails`, data)
      .then(result => {
        console.log('load data', result.data)
        if (result.data.status == false) {
          setLoanTable(false)
          // settopform(true);
          //setpromotable(true)
          //   alert("not found")
        }
        if (result.data.data.length > 0) {
          setLoanTable(true)
          settopform(false)
          result.data.data.map((item, i) => {
            loadnArr.push({
              id: item.id,
              b_id: item.b_id,
              no_emi: item.no_emi,
              Financer_id: item.Financer_id,
              Loan_type_id: item.Loan_type_id,
              Loan_amount: item.Loan_amount,
              Original_tenure_id: item.Original_tenure_id,
              financer_name: item.financer_name,
              loan_type_name: item.loan_types,
              Remaining_emi: item.Remaining_emi,
              original_tenure_name: item.original_tenure_name,
              EMI: item.EMI,
              emi_date: item.emi_date,
              Sanction_letter_id: item.Sanction_letter_id,
              Loan_still_open: item.Loan_still_open,
              EMI_start_date: item.EMI_start_date,
              No_of_emi: item.No_of_emi,
              Account_no: item.Account_no,
              emi_bounce_in_3_months: item.emi_bounce_in_3_months,
              emi_bounce_in_6_months: item.emi_bounce_in_6_months,
              obligation_considered: item.obligation_considered,
              obligation_considered_greater_than_6m:
                item.obligation_considered_greater_than_6m,
              obligation_considered_less_than_12m:
                item.obligation_considered_less_than_12m,
              obligation_considered_greater_than_12m:
                item.obligation_considered_greater_than_12m,
              no_of_emi_bounced_3m: item.no_of_emi_bounced_3m,
              no_of_emi_bounced_6m: item.no_of_emi_bounced_6m,
              bank: item.bank_id,
              bank_name: item.bank_name,
              bank_account_no: item.bank_account_no
            })
          })
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          data,
          iduser.user_id,
          iduser.user_id
        )
        console.log(error)
      })
  }
  //#endregion

  //#region get Loan By iD for editing
  const getLoansById = (item, item1) => {
    bankcheck(item.parent_id)
    // handlesubmitform();
    
    console.log(item)
    setHeader(false)
    settopform(true)
    setbusinesstable(false)
    setpromotable(false)
    let lopen =
      item.Loan_still_open !== null
        ? item.Loan_still_open === true
          ? 15
          : 16
        : ''
    let date1 = moment(
      item.EMI_start_date !== null ? item.EMI_start_date : ''
    ).format('YYYY-MM-DD')
    setParentTypeId(1)
    setBusinessId(item.parent_id)
    setBusinessName(item1.name)
    setBusinessId(item1.id)
    setshowUpdateBtn(true)
    setHeaderValue('Editing')
    setshowThreeBtn(false)
    setOriginalTenure(item.Original_tenure_id)
    setinitialValue({
      ...initialValue,
      l_id: item.id,
      financer: item.Financer_id !== null ? item.Financer_id : '',
      ltype: item.Loan_type_id !== null ? item.Loan_type_id : '',
      lamount: item.Loan_amount !== null ? item.Loan_amount : '',
      emi: item.EMI !== null ? item.EMI : '',
      originalte:
        item.Original_tenure_id !== null ? item.Original_tenure_id : '',
      remainemi: item.Remaining_emi !== null ? item.Remaining_emi : '',
      accno: item.Account_no !== null ? item.Account_no : '',
      noemi: item.No_of_emi !== null ? item.No_of_emi : '',
      edate: date1,
      loanopen: parseInt(lopen),
      companyle: '',
      emibounce3:
        item.emi_bounce_in_3_months !== null ? item.emi_bounce_in_3_months : '',
      emibounce6:
        item.emi_bounce_in_6_months !== null ? item.emi_bounce_in_6_months : '',
      obgconsidered:
        item.obligation_considered !== null ? item.obligation_considered : '',
      obgconsidered6:
        item.obligation_considered_greater_than_6m !== null
          ? item.obligation_considered_greater_than_6m
          : '',
      obgconsidered12:
        item.obligation_considered_less_than_12m !== null
          ? item.obligation_considered_less_than_12m
          : '',
      obgconsidered12gr:
        item.obligation_considered_greater_than_12m !== null
          ? item.obligation_considered_greater_than_12m
          : '',
      obligation_considered_bool: item.obligation_considered_bool,
      obligation_considered_gt6: item.obligation_considered_gt6,
      obligation_considered_gt12: item.obligation_considered_gt12,
      obligation_considered_lt12: item.obligation_considered_lt12,
      emibounce3month:
        item.no_of_emi_bounced_3m !== null ? item.no_of_emi_bounced_3m : '',
      emibounce6month:
        item.no_of_emi_bounced_6m !== null ? item.no_of_emi_bounced_6m : '',
      bank: item.bank_id !== null ? item.bank_id : '',
      bank_account_no: item.bank_account_no !== null ? item.bank_account_no : ''
    })
  }

  const formRef = React.useRef()

  const getLoansByIdPromoter = async (item, item1) => {
    
    // handleSubmit1();

    //shandleSubmit();

    bankcheck(item.parent_id)
    setHeader(false)
    setshowThreeBtn(false)
    settopform(true)
    setbusinesstable(false)
    setpromotable(false)
    let lopen =
      item.Loan_still_open !== null
        ? item.Loan_still_open === true
          ? 15
          : 16
        : ''
    let date1 = moment(
      item.EMI_start_date !== null ? item.EMI_start_date : ''
    ).format('YYYY-MM-DD')
    setParentTypeId(2)
    setBusinessId(item1.promoter_id)
    setBusinessName(item1.first_name)
    setshowUpdateBtn(true)
    setHeaderValue('Editing')
    console.log('hi')
    setinitialValue({
      ...initialValue,
      l_id: item.id,
      financer: item.Financer_id !== null ? item.Financer_id : '',
      ltype: item.Loan_type_id !== null ? item.Loan_type_id : '',
      lamount: item.Loan_amount !== null ? item.Loan_amount : '',
      emi: item.EMI !== null ? item.EMI : '',
      originalte:
        item.Original_tenure_id !== null ? item.Original_tenure_id : '',
      remainemi: item.Remaining_emi !== null ? item.Remaining_emi : '',
      accno: item.Account_no !== null ? item.Account_no : '',
      noemi: item.No_of_emi !== null ? item.No_of_emi : '',
      edate: date1,
      loanopen: parseInt(lopen),
      companyle: '',
      emibounce3:
        item.emi_bounce_in_3_months !== null ? item.emi_bounce_in_3_months : '',
      emibounce6:
        item.emi_bounce_in_6_months !== null ? item.emi_bounce_in_6_months : '',
      obgconsidered:
        item.obligation_considered !== null ? item.obligation_considered : '',
      obgconsidered6:
        item.obligation_considered_greater_than_6m !== null
          ? item.obligation_considered_greater_than_6m
          : '',
      obgconsidered12:
        item.obligation_considered_less_than_12m !== null
          ? item.obligation_considered_less_than_12m
          : '',
      obgconsidered12gr:
        item.obligation_considered_greater_than_12m !== null
          ? item.obligation_considered_greater_than_12m
          : '',
      obligation_considered_bool:
        item.obligation_considered_bool,
      obligation_considered_gt6:
        item.obligation_considered_gt6,
      obligation_considered_gt12:
        item.obligation_considered_gt12,
      obligation_considered_lt12:
        item.obligation_considered_lt12,
      emibounce3month:
        item.no_of_emi_bounced_3m !== null ? item.no_of_emi_bounced_3m : '',
      emibounce6month:
        item.no_of_emi_bounced_6m !== null ? item.no_of_emi_bounced_6m : '',
      bank: item.bank_id !== null ? item.bank_id : '',
      bank_account_no: item.bank_account_no !== null ? item.bank_account_no : ''
    })

    // if (formRef.current) {
    //   formRef.current.handleSubmit();
    //   formRef.current.validateForm();
    //   formRef.current.submitForm();
    // }

    // validateEmptyName("emi");
    // if (formRef.current) {
    //   // check that schema was generated based on API response
    //   formRef.current.validateForm()
    // }
  }

  // const formRef = React.useRef();

  const handleLoanHeader = (flag, i) => {
    let newArray = []
    for (let j = 0; j < loanHeaderOpen.length; j++) {
      if (j === i) {
        newArray.push(flag)
        //   break;
      } else {
        newArray.push(loanHeaderOpen[j])
      }
    }
    setLoanHeaderOpen(newArray)
  }

  const handleLoanForPromo = (flag, i) => {
    let newArray = []
    for (let j = 0; j < loanHeaderOpenPromo.length; j++) {
      if (j === i) {
        newArray.push(flag)
        //   break;
      } else {
        newArray.push(loanHeaderOpenPromo[j])
      }
      setLoanHeaderOpenPromo(newArray)
    }
  }

  const [rEMI, setEMI] = useState()
  const resetEMI = item => {
    setEMI(true)
  }

  const { vertical, horizontal } = SnackPosition
  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()
  const [colorMessage, setcolorMessage] = React.useState()
  const deleteLoanById = async (id, busiID, type) => {
    console.log(id)
    let data = {
      id: id,
      Token: 'qwerty'
    }
    let api_path = apiCalling.coderscorner + `server.php/api/deleteLoan`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/deleteLoan`, data)
      .then(result => {
        console.log(result.data)
        getAllBusinessAndPromoter()
        setcolorMessage('success')
        setShowMessage(result.data.message)
        setsavenextmessage(true)
        // if (type == 1) {
        //getAllLoansDetailsByUserId(busiID, 1);

        /// } else {
        ///getAllLoansDetailsByUserId(busiID, 2);
        // }
      })
      .catch(error => {
        ErrorCatch(id, error, api_path, 'post', data, id, id)
        console.log('Something went Wrong While Deleting...!!!', error)
      })
  }

  const generateString = (char, num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const number12 = '0123456789'
    let charResult = ''
    let numResult = ''

    const charactersLength = characters.length
    for (let i = 0; i < char; i++) {
      charResult += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      )
    }
    const numLength = number12.length
    for (let i = 0; i < num; i++) {
      numResult += number12.charAt(Math.floor(Math.random() * numLength))
    }
    charResult = charResult.toUpperCase()
    return charResult + numResult
  }

  // digital pull for business

  const businessDigitalPull = () => {
    let data = {
      bus_id: 445
    }
    axios
      .post(apiCalling.coderscorner + `server.php/api/getAllBusinessbyid`, data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  /// equifax post

  const equiFax = async pdata => {
    let promoterDob = moment(pdata.dob).format('YYYY-MM-DD')

    let data = {
      app_name: 'cirreport',
      CustRefField: 'PKTEST12340000',
      firstName: pdata.first_name,
      middleName: '',
      lastName: pdata.last_name,
      inqPurpose: '00',
      tAmount: '',
      addtype1: '',
      address1: pdata.address_1,
      City1: '',
      state1: 'KA',
      pin1: pdata.pin_id,
      addtype2: '',
      address2: '',
      City2: '',
      state2: '',
      pin2: '',
      addtype3: '',
      address3: '',
      City3: '',
      state3: '',
      pin3: '',
      mNumber1: pdata.mobile,
      mNumber2: '',
      mNumber3: '',
      mNumber4: '',
      mNumber5: '',
      emailid1: pdata.email,
      emailid2: '',
      emailid3: '',
      Idvalue1: '',
      Idvalue2: '',
      Idvalue3: '',
      Idvalue4: '',
      Idvalue5: '503342270223',
      Dob: promoterDob,
      Gender: 'F',
      create: iduser.user_id,
      modify: iduser.user_id
    }

    let data1 = {
      app_name: 'cirreport',
      CustRefField: 'PKTEST12340000',
      firstName: 'Pratibha Periwal',
      middleName: '',
      lastName: '',
      inqPurpose: '00',
      tAmount: '',
      addtype1: 'H',
      address1: '2ND Street,GANDHINAGAR COLONY CHINNA',
      City1: '',
      state1: 'TN',
      pin1: '625515',
      addtype2: '',
      address2: '',
      City2: '',
      state2: '',
      pin2: '',
      addtype3: '',
      address3: '',
      City3: '',
      state3: '',
      pin3: '',
      mNumber1: '9345267895',
      mNumber2: '',
      mNumber3: '',
      mNumber4: '',
      mNumber5: '',
      emailid1: 'abcd@gmail.com',
      emailid2: '',
      emailid3: '',
      Idvalue1: '',
      Idvalue2: '',
      Idvalue3: '',
      Idvalue4: '',
      Idvalue5: '503342270223',
      Dob: '1980-04-07',
      Gender: 'F',
      create: '416',
      modify: '416'
    }

    await axios
      .post(apiCalling.thirdParty + 'server.php/apieqifax1', data)
      .then(result => {
        console.log(result)
        let perfResponse = result.data.result1
        console.log(JSON.stringify(result.data.result1))
        //  let pdata;
        saveEquifax(pdata, perfResponse)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const equiFax1 = async pdata => {
    

    // let url = `http://164.52.207.42/alpha/server.php/apieqifax?app_name=cirreport&CustRefField=PKTEST12340000&firstName=Pratibha%2BPeriwal&middleName&lastName&inqPurpose=00&tAmount&addtype1=H%2B&address1=2ND%2BStreet%252CGANDHINAGAR%2BCOLONY%2BCHINNA&City1&state1=TN&pin1=625515&addtype2&address2&City2&state2&pin2&mNumber1=9345267895&mNumber2&mNumber3&mNumber4&mNumber5&Idvalue1&Idvalue2&Idvalue3&Idvalue4&Idvalue5=503342270223&Dob=1980-04-07&Gender=F&emailid1=abcd%2540gmail.com&emailid2&emailid3&create=416&modify=416`;

    let promoterDob = moment(pdata.dob).format('YYYY-DD-MM')

    let url2 =
      apiCalling.thirdParty +
      `server.php/apieqifax?app_name=cirreport&CustRefField=PKTEST12340000&firstName=${pdata.first_name}
    &middleName&lastName=${pdata.last_name}&inqPurpose=00&tAmount&addtype1=O &address1=${pdata.address_1}
    &City1&state1=KA&pin1=${pdata.pin_id}&addtype2&address2&City2&state2&pin2&mNumber1=${pdata.mobile}&mNumber2&mNumber3&mNumber4
    &mNumber5&Idvalue1&Idvalue2&Idvalue3&Idvalue4&Idvalue5=503342270223&Dob=${promoterDob}&Gender=F&emailid1=${pdata.email}
    &emailid2&emailid3&create=${iduser.user_id}&modify=${iduser.user_id} `

    console.log(url2)

    // let url = apiCalling.thirdParty + 'server.php/apicrif?app_name=stage1&&in_data=' + orderId + '|chm_uat@ladvetaya.com|C905B160DA094069624C32ED6FC5C92D04499AFB|' + pdata.first_name + '||' + pdata.last_name + '||' + promoterDob + '|||' + pdata.mobile + '|||' + pdata.email + '||' + pdata.PAN + '|' + dl + '|||||||' + pdata.father_name + '|||' + pdata.address_1 + '|' + pdata.address_1 + '|' + pdata.address_1 + '|' + pdata.state_name + '|' + pdata.pin_id + '|' + pdata.nationality + '|||||||' + prdoCode + '|Y&&create=' + checkuserid.user_id + '&&modify=' + checkuserid.user_id + ' ';

    await axios
      .get(url2)
      .then(result => {
        console.log(result)
        let perfResponse = result.data.result1
        console.log(JSON.stringify(result.data.result1))

        saveEquifax(pdata, perfResponse)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //SAVE EQUIFAXrESULT

  const saveEquifax = async (promoterData, perfResponse) => {
    let data = {
      parent_type_id: '2',
      parent_id: promoterId,
      user_id: iduser.user_id,
      result: perfResponse
    }

    await axios
      .post(apiCalling.coderscorner + 'server.php/api/saveloanEquifax', data)
      .then(result => {
        console.log(result)
        getAllBusinessAndPromoter()
        setProgressBar(false)
        setSuccessMsg(true)
        if (result.data.status == true) {
          getAllBusinessAndPromoter()
          setProgressBar(false)
          setSuccessMsg(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // criff 3rd party api stage1
  const [orderId, setOrderId] = useState('')
  const promoterLoanDigitalPullCriffOrEquifax = async (pid, flag1, vendor) => {
    
    // setShowConMsg(false);
    //setQuestionTab(true);
    setShowConMsg(false)
    setProgressBar(true)
    //  setLoaderFlag2(true);
    //console.log(data)
    let data
    let url
    if (flag1 === 'pro') {
      data = {
        promo_or_shareholder_id: pid
      }
      url =
        apiCalling.coderscorner +
        'server.php/api/getshareholderorPromotorInfoByid'
    } else if (flag1 === 'bus') {
      data = {
        bus_id: pid
      }
      url = apiCalling.coderscorner + 'server.php/api/getAllBusinessbyid'
    }
    let api_path = url
    await axios
      .post(url, data)
      .then(result => {
        console.log(result.data.data[0])
        if (vendor == 1) {
          equiFax(result.data.data[0])
        } else {
          if (flag1 === 'pro') criffApiFistStage(result.data.data[0])
          else if (flag1 === 'bus')
            criffApiFistStageForBusiness(result.data.data[0])
        }

        //showAllButton(false);
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          data,
          iduser.user_id,
          iduser.user_id
        )
        console.log(error)
      })
  }

  /// criff stage 1

  const criffApiFistStageForBusiness = async pdata => {
    let orderId = generateString(6, 3)
    setOrderId(orderId)

    let national = 'india'
    let prdoCode = 'DTC0000050'
    //let tokeString = Math.random().toString(36).slice(-6).toUpperCase();
    let dl = 'ZNPEKW71699719'
    // let pdata = result.data.data[0];
    let DOI = moment(pdata.date_of_incorporation).format('DD-MM-YYYY')
    let father_name = 'na'
    let url =
      apiCalling.thirdParty +
      'server.php/apicrif?app_name=stage1&&in_data=' +
      orderId +
      '|chm_uat@ladvetaya.com|C905B160DA094069624C32ED6FC5C92D04499AFB|' +
      pdata.business_name +
      '||' +
      pdata.business_name +
      '||' +
      DOI +
      '|||' +
      pdata.mobile +
      '|||' +
      pdata.email +
      '||' +
      pdata.bpan +
      '|' +
      dl +
      '|||||||' +
      father_name +
      '|||' +
      pdata.area +
      '|' +
      pdata.area +
      '|' +
      pdata.city +
      '|' +
      pdata.state +
      '|' +
      pdata.pincode +
      '|' +
      national +
      '|||||||' +
      prdoCode +
      '|Y&&create=username&&modify=%20username'

    console.log('data----', url)

    await axios
      .get(
        apiCalling.thirdParty +
        'server.php/apicrif?app_name=stage1&&in_data=' +
        orderId +
        '|chm_uat@ladvetaya.com|C905B160DA094069624C32ED6FC5C92D04499AFB|' +
        pdata.business_name +
        '||' +
        pdata.business_name +
        '||' +
        DOI +
        '|||' +
        pdata.mobile +
        '|||' +
        pdata.email +
        '||' +
        pdata.PAN +
        '|' +
        dl +
        '|||||||' +
        father_name +
        '|||' +
        pdata.area +
        '|' +
        pdata.area +
        '|' +
        pdata.city +
        '|' +
        pdata.state +
        '|' +
        pdata.pincode +
        '|' +
        national +
        '|||||||' +
        prdoCode +
        '|Y&&create=username&&modify=%20username'
      )
      .then(result => {
        console.log(result)
        let orderIdFromStage1 = result.data.orderId
        if (result.data.status == 'S06') {
          callStage2CrifApi(orderIdFromStage1)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const criffApiFistStage = async pdata => {
    let orderId = generateString(6, 3)
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    setOrderId(orderId)
    let national = 'india'
    let promoterDob = moment(pdata.dob).format('DD-MM-YYYY')
    let id = 1
    // console.log();

    let object = {
      app_name: 'stage1',
      in_data:
        '' +
        orderId +
        '|' +
        pdata.first_name +
        '||' +
        pdata.last_name +
        '||' +
        promoterDob +
        '|||' +
        pdata.mobile +
        '|||' +
        pdata.email +
        '||' +
        pdata.PAN +
        '|' +
        pdata.dl +
        '|||||||' +
        pdata.father_spou_name +
        '|||' +
        pdata.address_1 +
        '|' +
        pdata.village1 +
        '|' +
        pdata.city1 +
        '|' +
        pdata.state_name +
        '|' +
        pdata.pin_id +
        '|' +
        national +
        '|||||||Y  ',
      create: 1,
      modify: 1
    }
    //console.log(object);

    await axios
      .post(apiCalling.thirdParty + 'server.php/api/apicrif1', object)
      .then(result => {
        // //console.log(result);
        let orderIdFromStage1 = result.data.orderId
        if (result.data.status == 'S06') {
          callStage2CrifApi(orderIdFromStage1)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // criff stage 2 api for getting questions
  const [question, setQuestion] = useState('')
  const [ansSelectArr, setAnsSelectArr] = useState([])

  const callStage2CrifApi = async orderIdFromStage1 => {
    
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let orderIdFromStage01 = orderIdFromStage1

    
    let data = {
      app_name: 'stage2',
      orderId: orderIdFromStage1,
      userans: '',
      create: '1',
      modify: '1'
    }
    await axios
      .post(apiCalling.thirdParty + 'server.php/api/apicrif1', data)
      .then(result => {
        //setShowConMsg(true);
        //  //console.log(result.data);
        if (result.data.status == 'S11') {
          setLoaderFlag(false)
          setOpen(true)
          setQuestion(result.data.question)
          let options = result.data.optionList
          let res = options.split(' |')
          setAnsSelectArr(res)
          //console.log(res);
          setQuestionsTabOpen(true)
          setProgressBar(false)
          setQuestionTab(true)
        } else if (result.data.status == 'S10') {
          //  setProgressBar(true)
          stage3ApiResponse(orderIdFromStage1)
        } else if (result.data.status == 'S09') {
          setErrorMsg(true)
        }
      })
      .catch(error => {
        //console.log(error);
      })
  }
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setSelectedValue('')
    setProgressBar(false)
    setQuestionsTabOpen(false)
    setQuestionTab(false)
    setSuccessMsg(false)
    setErrorMsg(false)
    //   setOpen(false);
  }

  const [selectedValue, setSelectedValue] = React.useState('')
  // criff stage 2 continuation api stage submit the answer url header = orderId and Answer
  const handleChangeLoanAnswer = event => {
    let answer = event.target.value
    setSelectedValue(answer)
  }

  const submitAnswerAtStage2 = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let data = {
      app_name: 'stage2',
      orderId: orderId,
      userans: selectedValue,
      create: '1',
      modify: '1'
    }
    await axios
      .post(apiCalling.thirdParty + 'server.php/api/apicrif1', data)
      .then(result => {
        //  //console.log(result);
        setSelectedValue('')
        if (result.data.status == 'S01') {
          stage3ApiResponse(orderId)
        } else if (result.data.status == 'S11') {
          setQuestion(result.data.question)
          let options = result.data.optionList
          let res = options.split(' |')
          setAnsSelectArr(res)
        } else if (result.data.status == 'S02') {
          setErrorMsg(true)
          setSuccessMsg(false)
          setQuestionTab(false)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [xmlResult, setXmlResult] = useState('')
  // criff stage 3 api just send orderid
  const stage3ApiResponse = async orderIdpar => {
    

    let data = {
      app_name: 'stage3',
      orderId: orderIdpar,
      create: '1',
      modify: '1'
    }

    // console.log(orderid1);
    await axios
      .post(apiCalling.thirdParty + 'server.php/api/apicrif1', data)
      .then(result => {
        // //console.log('printed result', result);
        setQuestionTab(false)
        
        if (result.status === 200) {
          setProgressBar(false)
          let resultdata = result.data.json_result
          let jsonresa = JSON.parse(resultdata)
          let headerdata = jsonresa.HEADER
          //console.log("headerdata " + JSON.stringify(headerdata));
          //setXmlResult(JSON.stringify(jsonresa));
          saveLoan3rdPartyResult(jsonresa)
          //setShowResult(true);
          setSuccessMsg(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const saveLoan3rdPartyResult = async datas => {
    
    let data = {
      parent_type_id: 2,
      parent_id: promoterId,
      user_id: idofuser,
      result: datas
    }
    console.log('saveLoan3rdPartyResultsentdata', JSON.stringify(data))
    let api_path =
      apiCalling.coderscorner + `server.php/api/saveLoan3rdPartyResult`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/saveLoan3rdPartyResult`,
        data
      )
      .then(res => {
        console.log('---saveLoan3rdPartyResult---', res.data)
        getAllBusinessAndPromoter()
        
      })
      .catch(er => {
        ErrorCatch(idofuser, er, api_path, 'post', data, idofuser, idofuser)
        console.log('Something went Wrong ...!!!', er)
      })
  }

  const [progress, setProgress] = React.useState(10)
  const [displayProgressBar, setDisplayProgressBar] = useState(true)
  const progressTimer = () => {
    setDisplayProgressBar(true)
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)
    return () => {
      clearInterval(timer)
    }
  }

  const [loaderFlag2, setLoaderFlag2] = useState(false)
  const [questionTab, setQuestionTab] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [progressBar, setProgressBar] = useState(false)
  const [questionsTabOpen, setQuestionsTabOpen] = useState(false)
  const [promoterId, setPromoterId] = useState()
  const [showConMsg, setShowConMsg] = useState(true)
  const [mobileNum, setMobileNum] = useState(Number)
  const [loanCalculatedPreview, setloanCalculatedPreview] = React.useState(true)

  const getConfirmationForDigPull = id => {
    setShowConMsg(true)
    setPromoterId(id)
  }

  // const callApi = () => {
  //   promoterLoanDigitalPull(promoterId);
  // }

  const [addFormButtonValue, setAddFormButtonValue] = React.useState('Submit')
  const [showThreeBtn, setshowThreeBtn] = useState(true)

  const [flag, setFlag] = useState('')

  const [originalTenure, setOriginalTenure] = useState(0)

  const [parentId1, setParentId1] = useState()
  const [parentTypeId1, setParentTypeId1] = useState()

  let docDetails = {
    formId: '1.1.5',
    parentId: parentId1,
    parentTypeId: parentTypeId1
  }

  // Comment Section Code

  const [showDocScreen, setShowDocScreen] = useState(false)
  const [equiOrCriff, setequiOrCriff] = useState(false)
  const [commentArr, setCommentsArr] = useState([])

  const [comments, setComments] = useState('')
  const sendComments = async () => {
    const user_id = JSON.parse(localStorage.getItem('userdata'))
    
    let data = {
      user_id: user_id.id,
      application_ref_no: localData.reference_id,
      step: 'Loans',
      sequence_no: '1',
      comments: comments,
      commented_by: user_id.id
    }
    let url = ''
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/verificationComments',
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setShowMessage('Comment Saved')
          setsavenextmessage(true)

          setComments('')
          getverficationComments()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getverficationComments = async () => {
    
    let data = {
      application_ref_no: localData.reference_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        if (result.data.status === true) {
          console.log('a-------------------------------', result)

          setCommentsArr(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const deleteComment = async id => {
    let data = {
      id: id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        console.log(result)
        setCommentsArr(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [commentBox, setCommentBox] = React.useState({
    width: '100%',
    zIndex: '9999',
    marginTop: '-135%'
    // display: "none"
  })

  const [opencommentbox, setOpenComment] = React.useState(false)

  const changeCommentBox = e => {
    e.preventDefault()
    
    // setCommentBox({
    //   ...commentBox,
    //   // display: "block"
    // })
    if (opencommentbox) {
      setOpenComment(false)
    } else {
      setOpenComment(true)
    }
  }

  /// end comment section
  //mobile and email otp form show and hide
  const onBlurMobile = e => {
    let val = e.target.value
    let len = val.toString().length

    ///setVerifiedIcon(true)
    // console.log(len);
    if (len == 10) {
      // verifyPromoterMobile(val);
      // setPromoMobile(val);
      ///remove later
      setVerifiedIcon(true)
      setHideMobileResenBtn(false)
      setMobileVerifyIcon(true)
      setDisable(false)
      setIfMobileOtpInvald(false)
      enableShare()
      setMobileNum(val)

      verifyPromoterMobile(val)
      setOtpBtn(true)
    }
  }
  const [verifiedIcon, setVerifiedIcon] = useState(false)
  const [disable, setDisable] = React.useState({
    disabled: true
  })

  const verifyPromoterMobile = async val => {
    //
    let data = {
      mobile: val
    }
    let api_path =
      apiCalling.identityManagement + `server.php/api/UserSigninForDigitalPull`
    //let api_path2 = "http://164.52.207.42/Identitymanagement/server.php/api/UserSigninForDigitalPull";
    await axios
      .post(api_path, data)
      .then(result => {
        console.log(result.data)
        // 
        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message)
          // 
          // setOtpBtn(true);
          // 
        } else if (result.data.status === 101) {
          setVerifiedIcon(true)
        } else {
          setOtpBtn(true)
          
          setMobileExistMmsg('')
        }
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }

  /// verifying mobile otp for promoter
  const verifyMobileOtp = e => {
    
    let eotp = e
    let len = eotp.length
    if (len == 6) {
      let data = {
        value: mobile,
        form_id: 2,
        otp: eotp
      }
      localStorage.setItem('otps', JSON.stringify(data))
      //Cookies.set('otps_cookie', JSON.stringify(data));

      //const checkotp = localStorage.getItem("otps");
      //const checkotp=Cookies.get("otps");
      //let getotpcookie = Cookies.get('otps_cookie');
      //console.log(getotpcookie);
      // let otps = JSON.parse(getotpcookie);
      axios
        .post(apiCalling.identityManagement + `server.php/api/verifyOtp`, data)
        .then(result => {
          if (result.data != undefined || result.data != null) {
            //  console.log(result.data);
            if (result.data.status === true) {
              setMobileVerifyIcon(true)
              // setShowTimer(false);
              //setOtpErrorMessage(false);
              setIfMobileOtpInvald(false)
              setHideMobileResenBtn(false)
              setMobileVerifyIcon(true)
              setDisable(false)
              // setOpenCredentialForm(true);
            } else if (result.data.status === false) {
              //setOtpErrorMessage(true);
              // setOtpMessage(result.data.message);
              setIfMobileOtpInvald(true)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  //resend otp for email
  const resendOtpEmail = () => {
    let data = {}
    let val = mobile
    data = {
      type: 'mobile',
      value: val,
      form_id: 2
    }
    axios
      .post(apiCalling.identityManagement + `server.php/api/resendOtp`, data)
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const getBackToMobile = () => {
    setOtpBtn(false)
    setHideMobileResenBtn(true)
    setMobileVerifyIcon(false)
  }

  const enableShare = () => {
    if (mobileVerifyIcon || emailVerifyIcon) {
      setShareEnable(false)
    }
  }
  ///Otp time as per general setting
  const getOtpTimer = async () => {
    let data = {
      parameter_name: 'OTP TIMER'
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then(result => {
        console.log(result.data.data[0])
        let data = result.data.data[0].parameter_value
        console.log('timer', data)
        convertTimerToInt(data)
      })
      .catch(error => {
        ErrorCatch(error, api_path, 'post', data)
        console.log(error)
      })
  }
  //conversion for Otp timer into
  const convertTimerToInt = timer => {
    let newTimer = parseInt(timer)
    var minutes = Math.floor(newTimer / 60)
    var seconds = newTimer - 60 * minutes
    setOtpMinutes(minutes)
    setOtpSeconds(seconds)
  }

  // const role_id = JSON.parse(localStorage.getItem('userdata'));
  const [showPreview, setShowPreview] = React.useState(false)

  const [openHDDialogBox1, setOpenHDDialogBox1] = React.useState(false)

  const handleCloseOpenHDDialogBox1 = () => {
    setOpenHDDialogBox1(false)
  }

  const input_style = {
    textAlign: 'center'
  }

  const loanCalCulation = () => {
    userBusinessList.map()
  }

  const [indexValue, setIndexValue] = React.useState(0)
  const [globalObj, setGlobalObj] = React.useState({})
  const singleLoanCal = index => {
    
    let array = userBusinessList[index]

    let obj = {
      financer_name: 0,
      bank_name: 0,
      Account_no: 0,
      loan_types: 0,
      Loan_amount: 0,
      EMI: 0,
      Original_tenure_id: 0,
      Remaining_emi: 0,
      obligation_considered: 0,
      obligation_considered_greater_than_6m: 0,
      obligation_considered_greater_than_12m: 0,
      obligation_considered_less_than_12m: 0,
      pos: 0
    }

    let loanAmount = 0
    let EMI = 0
    let obligation_considered = 0
    let obligation_considered_greater_than_6m = 0
    let obligation_considered_greater_than_12m = 0
    let obligation_considered_less_than_12m = 0
    let pos = 0

    array.loanForBusiness.map(item => {
      loanAmount += Number(item.Loan_amount)
      EMI += Number(item.EMI)
      obligation_considered += Number(item.obligation_considered)
      obligation_considered_greater_than_6m += Number(
        item.obligation_considered_greater_than_6m
      )
      obligation_considered_greater_than_12m += Number(
        item.obligation_considered_greater_than_12m
      )
      obligation_considered_less_than_12m += Number(
        item.obligation_considered_less_than_12m
      )
      pos +=
        (Number(item.Loan_amount) / Number(item.Original_tenure_id)) *
        Number(item.Remaining_emi)
    })

    obj = {
      EMI: EMI,
      Loan_amount: loanAmount,
      obligation_considered: obligation_considered,
      obligation_considered_greater_than_6m: obligation_considered_greater_than_6m,
      obligation_considered_greater_than_12m: obligation_considered_greater_than_12m,
      obligation_considered_less_than_12m: obligation_considered_less_than_12m,
      pos: pos
    }
    setGlobalObj(obj)
  }

  /// Obligation considered values
  const options = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const options_obli = [
    { value: 'Yes', key: 'Yes', text: 'Yes' },
    { value: 'No', key: 'No', text: 'No' }
  ]

  const changeOblMap = (option, oblCons) => {
    
    let obj = { ...globalObj }

    if (option.value === 'No') {
      let finalValue = obj.obligation_considered - Number(oblCons)
      obj.obligation_considered = finalValue
    } else {
      let finalValue = obj.obligation_considered + Number(oblCons)
      obj.obligation_considered = finalValue
    }
    setGlobalObj(obj)
    console.log(obj)
  }

  const [businessname, setBusinessname] = React.useState('')

  // docs preview
  const [documentArray, setDocumentArray] = React.useState([])
  const getUploadeDocOfUserV2 = async (busOrPro, ptId, dint) => {
    
    const docdata = {
      user_id: localData.user_id,
      parent_id: ptId,
      parent_type_id: busOrPro,
      form: '1.1.5',
      required_phase: '172',
      interval_flag: dint
    }
    let myArr = []
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser'
    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2'

    await axios
      .post(apiPath2, docdata)
      .then(result => {
        console.log('rsutl-----------------', result)
        //
        console.log('my document array----->', myArr)
        if (result.data.status) {
          let tempArr = result.data.data
          let resultArr = []
          if (tempArr.length >= 1) {
            tempArr.map(docchecklist => {
              if (docchecklist.documentArray.length > 0) {
                docchecklist.documentArray.map((docs, i) => {
                  if (
                    docs.file_name != '' &&
                    docs.file_name != undefined &&
                    docs.file_name != null
                  ) {
                    let obj = {
                      text: docs.file_name,
                      value: docs.docPath,
                      file_type: docs.file_type
                    }
                    resultArr.push(obj)
                  }
                })
              }
            })
          }
          //
          setDocumentArray(resultArr);
          setDocPath(resultArr[0].docPath);
          setDocType(resultArr[0].file_type);
        }
      })
      .catch(error => {
        ErrorCatch(1, error, api_path, 'post', docdata, 1, 1)
        console.log(error)
      })
  }
  const [docPath, setDocPath] = React.useState('')
  const [docType, setDocType] = React.useState('')
  const setPrieviewValues = (value, fileType) => {
    //
    let fileExt = fileType.split('/')
    setDocPath(value)
    setDocType(fileExt[1])
  }

  return (
    <Container maxWidth='lg'>
      {loanCalculatedPreview == true ? (
        <div className='mini-container mg_btm_set'>
          <Dialog
            disableBackdropClick
            open={questionsTabOpen}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            {/* <DialogTitle id="alert-dialog-title" className="modal-title" ><span className="title-header">{"Digital Pull"}</span>
              {handleClose ? (
                <IconButton aria-label="close" style={{ marginTop: "-5px" }} className={classes.closeButton} onClick={handleClose} >
                  <CloseIcon />
                </IconButton>) : null}
            </DialogTitle> */}
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={handleClose}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Digital Pull
              </Modal.Title>
            </Modal.Header>

            <div className='dialogBtn'>
              <DialogContent style={{ marginTop: '10px' }}>
                {showConMsg && (
                  <DialogTitle id='alert-dialog-title'>
                    <p className='modaltextt'>
                      Are you sure you want to continue ?
                    </p>
                  </DialogTitle>
                )}
                {questionTab && (
                  <DialogTitle id='alert-dialog-title'>
                    <p className='modaltext'>{question}</p>
                  </DialogTitle>
                )}
                {successMsg && (
                  <DialogTitle
                    id='alert-dialog-title'
                    className='modaltext modaltext2'
                  >
                    <p className='modaltext'> Data Fetched Succesfully</p>
                  </DialogTitle>
                )}
                {errorMsg && (
                  <DialogTitle id='alert-dialog-title' className='modaltext'>
                    {' '}
                    <p className='modaltextfail'>
                      User Authentication Failure{' '}
                    </p>
                  </DialogTitle>
                )}

                {progressBar && (
                  <div>
                    <ProgressBar
                      animated
                      variant='info'
                      now={70}
                      label={`${50}%`}
                    />
                  </div>
                )}
                {questionTab && (
                  <>
                    <div className='modalChild'>
                      <div>
                        <FormControlLabel
                          value='top'
                          label={ansSelectArr[0]}
                          labelPlacement='right'
                          control={
                            <Radio
                              checked={selectedValue == ansSelectArr[0]}
                              onChange={handleChangeLoanAnswer}
                              value={ansSelectArr[0]}
                              name='radio-button-demo'
                            />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value='top'
                          label={ansSelectArr[1]}
                          labelPlacement='right'
                          control={
                            <Radio
                              checked={selectedValue == ansSelectArr[1]}
                              onChange={handleChangeLoanAnswer}
                              value={ansSelectArr[1]}
                              name='radio-button-demo'
                            />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value='top'
                          label={ansSelectArr[2]}
                          labelPlacement='right'
                          control={
                            <Radio
                              checked={selectedValue == ansSelectArr[2]}
                              onChange={handleChangeLoanAnswer}
                              value={ansSelectArr[2]}
                              name='radio-button-demo'
                            />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value='top'
                          label={ansSelectArr[3]}
                          labelPlacement='right'
                          control={
                            <Radio
                              checked={selectedValue == ansSelectArr[3]}
                              onChange={handleChangeLoanAnswer}
                              value={ansSelectArr[3]}
                              name='radio-button-demo'
                            />
                          }
                        />
                      </div>
                    </div>
                    <DialogActions>
                      <Button
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                        onClick={submitAnswerAtStage2}
                      >
                        Submit
                      </Button>
                      <Button
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </>
                )}
                {successMsg && (
                  <>
                    {xmlResult}
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </>
                )}

                {/* {showConMsg && (
                  <DialogActions style={{ padding: "0px" }}>
                    <Button onClick={handleClose} className="backBtn" style={{ height: "36px" }}
                      variant="outlined"
                      color="primary"><i class="far fa-times-circle"></i></Button>
                    <Button onClick={() => {
                      setShowConMsg(false);
                      setequiOrCriff(true);
                      ///promoterLoanDigitalPull(promoterId, flag)
                    }} className="backBtn"
                      variant="outlined"
                      color="primary" style={{ marginRight: "2%" }}>Yes</Button>
                  </DialogActions>
                )} */}
                {showConMsg && (
                  <DialogActions style={{ padding: '0px' }}>
                    <Col md={{ span: 6 }}>
                      <Typography styleType='B' className='promoter_mobile' />{' '}
                      {otpBtn && (
                        <span className='hidden_number'>
                          {/* You entered : {values.mobile} */}
                        </span>
                      )}
                      {verifiedIcon === true && (
                        <i
                          className='fas fa-check-circle'
                          style={{ fontSize: '15px', color: 'green' }}
                        ></i>
                      )}
                      <List
                        component='div'
                        disablePadding
                        style={{ marginTop: '-20px', marginLeft: '-10rem' }}
                      >
                        {otpBtn == false && (
                          <>
                            <ListItem>
                              {/* <PhoneInput country="in"
                                          enableSearch={false} onChange={(val) => selectCountry(val)} /> */}
                              <TextField
                                id='filled-error-helper-text standard-basic '
                                style={{ width: '30%' }}
                                label='Enter your Mobile No'
                                name='mobile'
                                type='number'
                                onBlur={onBlurMobile}
                                // InputProps={{
                                //   className: values.mobile
                                //     ? classes.manorma
                                //     : null,
                                // }}
                                inputProps={{
                                  maxLength: 10
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <span style={{ color: 'red', fontSize: '10px' }}>
                                {mobileExistsMsg}
                              </span>
                            </ListItem>
                          </>
                        )}

                        {otpBtn === true && (
                          <>
                            <p className='otpsent'>Enter OTP</p>
                            <ListItem>
                              <InputOtp onChange={verifyMobileOtp} />
                              {hideMobileResenBtn == true && (
                                <OtpTimer
                                  seconds={otpSeconds}
                                  minutes={otpMinutes} //otpMinutes
                                  style={{ color: 'blue' }}
                                  resend={resendOtpEmail}
                                  text=' '
                                  buttonColor={'white'}
                                  background={'#007bff'}
                                />
                              )}
                              <Button
                                onClick={getBackToMobile}
                                style={{ marginTop: '-1%' }}
                              >
                                <i
                                  className='far fa-edit'
                                  style={{ fontSize: '15px' }}
                                ></i>
                              </Button>

                              {mobileVerifyIcon === true && (
                                <i
                                  className='fas fa-check-circle'
                                  style={{ fontSize: '17px', color: 'green' }}
                                ></i>
                              )}
                              {ifMobileOtpInvald === true && (
                                <span
                                  style={{
                                    color: 'red',
                                    marginLeft: '-28em',
                                    marginTop: '38px'
                                  }}
                                >
                                  Invalid Otp🙄{' '}
                                </span>
                              )}
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Col>

                    <Button
                      onClick={() => {
                        setShowConMsg(false)
                        setequiOrCriff(true)
                        setDisable(true)
                        setOtpBtn(true)
                        setHideMobileResenBtn(true)
                        // setDisable(true);
                        //  handleOpenCredentialForm();
                        setShowConMsg(false)
                      }}
                      className='backBtn'
                      variant='outlined'
                      disabled={disable}
                      color='primary'
                      style={{ marginRight: '2%', cursor: 'pointer' }}
                    >
                      Continue
                    </Button>
                  </DialogActions>
                )}

                {equiOrCriff && (
                  <DialogActions style={{ padding: '0px', marginRight: '29%' }}>
                    <Button
                      onClick={() => {
                        promoterLoanDigitalPullCriffOrEquifax(
                          promoterId,
                          flag,
                          1
                        )
                        setequiOrCriff(false)
                      }}
                      className='backBtn'
                      style={{ height: '36px', color: 'black' }}
                      variant='outlined'
                      color='primary'
                    >
                      Equifax
                    </Button>
                    <Button
                      onClick={() => {
                        promoterLoanDigitalPullCriffOrEquifax(
                          promoterId,
                          flag,
                          2
                        )
                        setequiOrCriff(false)
                      }}
                      className='backBtn'
                      variant='outlined'
                      color='primary'
                      style={{ marginRight: '2%', color: 'black' }}
                    >
                      Criff
                    </Button>
                  </DialogActions>
                )}

                {errorMsg && (
                  <>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        className='backBtn'
                        variant='outlined'
                        color='primary'
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </>
                )}
              </DialogContent>
            </div>
          </Dialog>

          {showform == true && (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValue}
              validationSchema={validator}
              validateOnMount={true}
              // initialErrors={ validator.isValidSync(initialValue) }
              // isInitialValid={validator.isValidSync(initialValue)}
              initialErrors={true}
              isInitialValid={true}
              onSubmit={handlesubmitform}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                resetForm,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                validateForm

                /* and other goodies */
              }) => {
                return (
                  <>
                    <div>
                      <Row>
                        <Col md={{ span: 12 }}>
                          <div className='content-header'>
                            {header == true && (
                              <Row>
                                {/*  { requestDetails && (
                                    <Col md={{ span: 12 }} style={{ marginBottom: "3%", textAlign: "center" }}>
                                      <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                                    </Col>
                                  )} */}
                                <Col md={{ span: 6 }} className='mainheader1'>
                                  <Typography
                                    text='YOUR EXISITING LOANS'
                                    styleType='C'
                                  />
                                </Col>

                                <Col md={{ span: 12 }} className='subHeader1'>
                                  <table className='applicationTableDetails1'>
                                    <tr>
                                      {/* <td>Type</td>
                                        <td>:</td> */}
                                      {localData.request_type_id === 0 && (
                                        <td>Loan Application</td>
                                      )}
                                      {localData.request_type_id === 1 && (
                                        <td>Check Eligibility</td>
                                      )}
                                      <td>&nbsp;|&nbsp;</td>
                                      <td>Application Number</td>
                                      <td>:</td>
                                      <td>{localData.reference_id}</td>
                                      <td>&nbsp;|&nbsp;</td>
                                      <td>Loan Type</td>
                                      <td>:</td>
                                      <td>{localData.loan_type}</td>
                                    </tr>
                                  </table>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </Col>
                        {showDocScreen === true ? (
                          <>
                            <Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}>
                              <span className='image'>
                                <img
                                  className='backbutton navbutton docbackbtn'
                                  src={Back}
                                  onClick={handledata}
                                />
                                <Tooltip
                                  title='Back'
                                  classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                  }}
                                  arrow
                                >
                                  <img
                                    className='backbutton navgraybutton docbackbtn'
                                    src={grayback}
                                    onClick={handledata}
                                  />
                                </Tooltip>
                              </span>
                            </Col>
                          </>
                        ) : null}
                        {/* <Button onClick={() => { setbusinesstable(true); setpromotable(true); }} variant="primary" style={{ float: "right" }}>Back</Button> */}
                        <Col md={{ span: 12 }}>
                          {' '}
                          {showDocScreen === true ? (
                            <DocumentUpload formdetails={docDetails} />
                          ) : null}
                        </Col>
                      </Row>

                      {loaderFlag == true && (
                        <div style={{ textAlign: 'center', margin: '20%' }}>
                          <Loader
                            type={LoaderColor.type}
                            color={LoaderColor.color}
                          />
                        </div>
                      )}
                      {loaderFlag2 == true && (
                        <div style={{ textAlign: 'center', margin: '20%' }}>
                          <Loader
                            type={LoaderColor.type}
                            color={LoaderColor.color}
                          />
                        </div>
                      )}
                      {businesstable == true && (
                        <div>
                          <Snackbar
                            open={savenextmessage}
                            style={{ marginTop: '65px' }}
                            autoHideDuration={SnackPosition.duration}
                            onClose={() => {
                              setsavenextmessage(false)
                            }}
                            anchorOrigin={{
                              vertical: SnackPosition.vertical,
                              horizontal: SnackPosition.horizontal
                            }}
                          >
                            <Alert
                              onClose={() => {
                                setsavenextmessage(false)
                              }}
                              severity={colorMessage}
                            >
                              {showMessage}
                            </Alert>
                          </Snackbar>
                          <Typography
                            styleType='SH'
                            text='LOANS TAKEN BY BUSINESS'
                          />
                          <Row>
                            <Col md={{ span: 9 }}></Col>
                            <Col md={{ span: 3 }}>
                              <SearchBar
                                className='search_bar'
                                value={searched}
                                onChange={searchVal => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                              />
                            </Col>
                          </Row>
                          

                          <TableContainer component={Paper}>
                            <Table
                              aria-label='collapsible table'
                              size='small'
                              style={{ marginBottom: '2%' }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className='tableSLNoHeading'
                                    colSpan={1}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell className='tableBusinessNameHeading'>
                                    Business Name
                                  </TableCell>
                                  <TableCell className='tableGSTHeading'>
                                    GST
                                  </TableCell>
                                  <TableCell className='tablePANHeading'>
                                    PAN
                                  </TableCell>
                                  <TableCell className='tableEntityTypeHeading'>
                                    Entity Type
                                  </TableCell>
                                  <TableCell className='tableStatusHeading'></TableCell>
                                  <TableCell className='tableActionHeading'>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userBusinessList.map((item1, i) => (
                                  <>
                                    <TableRow
                                      key={i + 1}
                                      hover
                                      className='datable'
                                    >
                                      <TableCell>{i + 1}</TableCell>
                                      <TableCell>{item1.name}</TableCell>
                                      <TableCell>
                                        {maskGst(item1.gst)}
                                      </TableCell>
                                      <TableCell>
                                        {maskBpan(item1.bpan)}
                                      </TableCell>
                                      <TableCell>{item1.entity_type}</TableCell>
                                      {item1.loanForBusiness.length > 0 && (
                                        <Tooltip
                                          title={
                                            item1.loanForBusiness.length +
                                            ' ' +
                                            'Loan Added'
                                          }
                                          placement='top'
                                        >
                                          <TableCell className='tableStatusHeading'>
                                            <p className='countRowNumber'>
                                              {item1.loanForBusiness.length}
                                            </p>
                                          </TableCell>
                                        </Tooltip>
                                      )}
                                      {item1.loanForBusiness.length == 0 && (
                                        <Tooltip
                                          title='No Loan Added'
                                          placement='top'
                                        >
                                          <TableCell className='tableStatusHeading'>
                                            <p className='countRowNumberForRed'>
                                              {item1.loanForBusiness.length}
                                            </p>
                                          </TableCell>
                                        </Tooltip>
                                      )}
                                      {/* {item1.loanForBusiness.length > 0 && (
                                          <TableCell className="tableStatusHeading"><p className="countRowNumber">{item1.loanForBusiness.length}</p></TableCell>
                                        )}
                                        {item1.loanForBusiness.length == 0 && (
                                          <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item1.loanForBusiness.length}</p></TableCell>
                                        )} */}
                                      <TableCell className='tableActionHeading'>
                                        {item1.loanForBusiness.length > 0 && (
                                          <Tooltip
                                            title='Open Bank Details'
                                            placement='bottom'
                                          >
                                            <Button
                                              aria-label='expand item'
                                              className='actionBtn'
                                              onClick={() =>
                                                handleLoanHeader(
                                                  !loanHeaderOpen[i],
                                                  i
                                                )
                                              }
                                            >
                                              {loanHeaderOpen[i] ? (
                                                <img
                                                  alt='img1'
                                                  className='actionBtn svgicon'
                                                  src={up}
                                                />
                                              ) : (
                                                <img
                                                  alt='img1'
                                                  className='actionBtn svgicon'
                                                  src={down}
                                                />
                                              )}
                                            </Button>
                                          </Tooltip>
                                        )}

                                        <Tooltip
                                          title='Add Loan'
                                          placement='bottom'
                                        >
                                          <img
                                            alt='img1'
                                            className='actionBtn svgicon'
                                            src={addbutton}
                                            onClick={() =>
                                              addBusinessManuaaly(item1)
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title='Preview Loan'
                                          placement='bottom'
                                        >
                                          <img
                                            className='actionBtn svgicon'
                                            src={edit}
                                            alt='img1'
                                            onClick={() => {
                                              

                                              setBusinessname(item1.name)

                                              setIndexValue(i)
                                              singleLoanCal(i)

                                              setloanCalculatedPreview(false)
                                            }}
                                          />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 0,
                                          paddingTop: 0
                                        }}
                                        colSpan={7}
                                      >
                                        <Collapse
                                          in={loanHeaderOpen[i]}
                                          timeout='auto'
                                          unmountOnExit
                                        >
                                          <Box margin={1}>
                                            <Table
                                              aria-label='collapsible table'
                                              size='small'
                                            >
                                              <TableHead
                                                style={{
                                                  backgroundColor: '#F5F5F5',
                                                  width: '80%'
                                                }}
                                              >
                                                <TableRow key={{ i }}>
                                                  <TableCell className='tableSLNoHeading'>
                                                    #
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                    className="financerHeader"
                                                  >
                                                    Financier
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    Loan Type
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    Account Number
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    Loan Amount
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    EMI <br/>
                                                    Original Tenture
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    Bank <br/>
                                                    Bank Account No
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                  >
                                                    Loan Open?
                                                  </TableCell>

                                                  {false && (
                                                    <TableCell
                                                      style={table_header1}
                                                    >
                                                      Emi StartDate
                                                    </TableCell>
                                                  )}
                                                  <TableCell
                                                    style={table_header2}
                                                  >
                                                    Auto Fetched
                                                  </TableCell>
                                                  <TableCell
                                                    style={table_header1}
                                                    className="actionHeader"
                                                  >
                                                    Action
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody
                                                style={{ margin: '1px 20px' }}
                                              >
                                                {item1.loanForBusiness.map(
                                                  (item, i) => (
                                                    <TableRow key={i + 1}>
                                                      <TableCell>
                                                        {i + 1}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.financer_name}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.loan_types}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.Account_no}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.Loan_amount}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.EMI} <br/>
                                                        {
                                                          item.Original_tenure_id
                                                        }
                                                      </TableCell>
                                                      <TableCell >
                                                        {item.bank_name}<br/>
                                                        {item.bank_account_no}
                                                      </TableCell>
                                                      <TableCell>
                                                        {!item.Loan_still_open ? (
                                                          <i
                                                            class='far fa-times-circle'
                                                            style={{
                                                              marginLeft: '25px'
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          <i
                                                            className='fas fa-check-circle'
                                                            style={{
                                                              fontSize: '16px',
                                                              color: 'green',
                                                              padding:
                                                                '0px 0px 0px 25px'
                                                            }}
                                                          ></i>
                                                        )}
                                                      </TableCell>
                                                      <TableCell>
                                                        {item.data_submit_type ===
                                                          1 ? (
                                                          <i
                                                            class='far fa-times-circle'
                                                            style={{
                                                              marginLeft: '20px'
                                                            }}
                                                          ></i>
                                                        ) : (
                                                          <i
                                                            className='fas fa-check-circle'
                                                            style={{
                                                              fontSize: '16px',
                                                              color: 'green'
                                                            }}
                                                          ></i>
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                        style={sub_table_action}
                                                      >
                                                        <Tooltip
                                                          title='Edit'
                                                          placement='bottom'
                                                        >
                                                          {/* <Button className="actionBtn"
                                                            onClick={() => {
                                                              getLoansById(item, item1);
                                                              // setbusinesstable(false);
                                                              setLoanTable(false);
                                                            }}  >
                                                            <i className="fas fa-edit"
                                                              style={{ fontSize: "15px" }} /></Button> */}
                                                          <img
                                                            className='actionBtn svgicon'
                                                            alt='hi'
                                                            src={edit}
                                                            onClick={() => {
                                                              
                                                              getLoansById(
                                                                item,
                                                                item1
                                                              )
                                                              setLoanTable(
                                                                false
                                                              )
                                                              getUploadeDocOfUserV2(
                                                                1,
                                                                item.id
                                                              )
                                                            }}
                                                          />
                                                        </Tooltip>
                                                        <Tooltip
                                                          title='Upload Document'
                                                          placement='bottom'
                                                        >
                                                          {/* <Button className="actionBtn" onClick={(e) => {
                                                            setShowDocScreen(true);
                                                            setParentId1(1);
                                                            setParentTypeId1(item.id);
                                                            setbusinesstable(false);
                                                            setpromotable(false);
                                                          }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                                          <img
                                                            alt='imag1'
                                                            className='actionBtn svgicon'
                                                            src={uploaddoc}
                                                            onClick={e => {
                                                              setShowDocScreen(
                                                                true
                                                              )
                                                              setParentId1(
                                                                item.id
                                                              )
                                                              setParentTypeId1(
                                                                1
                                                              )
                                                              setbusinesstable(
                                                                false
                                                              )
                                                              setpromotable(
                                                                false
                                                              )
                                                            }}
                                                          />
                                                        </Tooltip>
                                                        <Tooltip
                                                          title='Delete Loan'
                                                          placement='bottom'
                                                        >
                                                          {/* <Button className="actionBtn" onClick={(e) => {
                                                            deleteLoanById(item.id, item1.id, 1);
                                                          }}> <i class="fas fa-minus" style={{ fontSize: "13px" }}></i></Button> */}
                                                          <img
                                                            alt='imag1'
                                                            className='actionBtn svgicon'
                                                            src={deleterec}
                                                            onClick={e => {
                                                              deleteLoanById(
                                                                item.id,
                                                                item1.id,
                                                                1
                                                              )
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      )}
                      {/* {promotable == true && (
                        <Row>
                          <Col md={{ span: 3 }}>
                            <Typography styleType='D' text='Promoter Information' />
                          </Col>
                        </Row>
                      )} */}
                      {promotable == true && (
                        <div>
                          <Typography
                            styleType='SH'
                            text='LOANS TAKEN BY PROMOTER'
                          />
                          {showPromoterTable == false && (
                            <Alert severity='warning' className='warningMsg1'>
                              <AlertTitle>
                                <p
                                  style={{ fontSize: '16px', color: '#663C00' }}
                                >
                                  No Promoters have been Added to this Business.
                                  Click here to Add Promoter
                                  Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </p>
                              </AlertTitle>
                              <Button
                                size='small'
                                className='choicebtn'
                                onClick={() => {
                                  props.handleBack(2)
                                }}
                              >
                                Add Promoter
                              </Button>
                              &nbsp;&nbsp;
                            </Alert>
                          )}
                          <SearchBar
                            className='search_bar'
                            value={searchedPromo}
                            onChange={searchVal =>
                              requestSearchPromo(searchVal)
                            }
                            onCancelSearch={() => cancelSearchPromo()}
                          />
                          {showPromoterTable == true && (
                            <TableContainer component={Paper}>
                              <Table
                                aria-label='collapsible table'
                                size='small'
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell className='tableSLNoHeading'>
                                      #
                                    </TableCell>
                                    <TableCell className='tableBusinessNameHeading'>
                                      Promoter Name
                                    </TableCell>
                                    {/* <TableCell style={table_header}>Business_Name</TableCell> */}
                                    <TableCell className='tableGSTHeading'>
                                      Email
                                    </TableCell>
                                    <TableCell className='tablePANHeading'>
                                      Mobile
                                    </TableCell>
                                    <TableCell className='tableStatusHeading'></TableCell>
                                    <TableCell className='tableActionHeading'>
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {userPromoterList.map((item1, i) => (
                                    <>
                                      <TableRow
                                        key={i + 1}
                                        hover
                                        className='datable'
                                      >
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>
                                          {maskName(item1.first_name) +
                                            ' ' +
                                            maskName(item1.last_name)}
                                        </TableCell>
                                        {/* <TableCell>{item1.business_name}</TableCell> */}
                                        <TableCell>
                                          {maskEmail(item1.email)}
                                        </TableCell>
                                        <TableCell>
                                          {maskMobile(item1.mobile)}
                                        </TableCell>
                                        {item1.loanForBusiness.length > 0 && (
                                          <Tooltip
                                            title={
                                              item1.loanForBusiness.length +
                                              ' ' +
                                              'Loan Added'
                                            }
                                            placement='top'
                                          >
                                            <TableCell className='tableStatusHeading'>
                                              <p className='countRowNumber'>
                                                {item1.loanForBusiness.length}
                                              </p>
                                            </TableCell>
                                          </Tooltip>
                                        )}
                                        {item1.loanForBusiness.length == 0 && (
                                          <Tooltip
                                            title='No Loan Added'
                                            placement='top'
                                          >
                                            <TableCell className='tableStatusHeading'>
                                              <p className='countRowNumberForRed'>
                                                {item1.loanForBusiness.length}
                                              </p>
                                            </TableCell>
                                          </Tooltip>
                                        )}
                                        <TableCell className='tableActionHeading'>
                                          {item1.loanForBusiness.length > 0 && (
                                            <Tooltip
                                              title='Open Bank Details'
                                              placement='bottom'
                                            >
                                              <Button
                                                aria-label='expand item'
                                                className='actionBtn'
                                                onClick={() =>
                                                  handleLoanForPromo(
                                                    !loanHeaderOpenPromo[i],
                                                    i
                                                  )
                                                }
                                              >
                                                {loanHeaderOpenPromo[i] ? (
                                                  <img
                                                    alt='image123'
                                                    className='actionBtn svgicon'
                                                    src={up}
                                                  />
                                                ) : (
                                                  <img
                                                    alt='image123'
                                                    className='actionBtn svgicon'
                                                    src={down}
                                                  />
                                                )}
                                              </Button>
                                            </Tooltip>
                                          )}
                                          <Tooltip
                                            title='Add Loan'
                                            placement='bottom'
                                          >
                                            {/* <Button className="actionBtn"
                                              onClick={() => {
                                                ;
                                                addPromoterManually(item1)
                                              }}>   <i className="fas fa-plus"
                                                style={{ fontSize: "15px" }} /></Button> */}
                                            <img
                                              alt='alt_image'
                                              className='actionBtn svgicon'
                                              src={addbutton}
                                              onClick={() => {
                                                addPromoterManually(item1)
                                              }}
                                            />
                                          </Tooltip>

                                          <Tooltip
                                            title='Digital Pull'
                                            placement='bottom'
                                          >
                                            {/* <Button className="actionBtn" onClick={() => {
                                              setOpen(true);
                                              setQuestionsTabOpen(true);
                                              setFlag("pro")
                                              getConfirmationForDigPull(item1.promoter_id);
                                            }}>
                                              <i className="fas fa-download"
                                                style={{ fontSize: "15px" }} />
                                            </Button> */}
                                            <img
                                              alt='image12'
                                              className='actionBtn svgicon'
                                              src={digitalpull}
                                              onClick={() => {
                                                setOpen(true)

                                                setQuestionsTabOpen(true)
                                                setFlag('pro')
                                                getConfirmationForDigPull(
                                                  item1.promoter_id
                                                )
                                              }}
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            title='Preview Loan'
                                            placement='bottom'
                                          >
                                            <img
                                              className='actionBtn svgicon'
                                              src={edit}
                                              alt='img1'
                                              onClick={() => {
                                                
                                                setBusinessname(
                                                  item1.first_name
                                                )

                                                setIndexValue(i)
                                                singleLoanCal(i)

                                                setloanCalculatedPreview(false)
                                              }}
                                            />
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell colSpan={7}>
                                          <Collapse
                                            in={loanHeaderOpenPromo[i]}
                                            timeout='auto'
                                            unmountOnExit
                                          >
                                            <Box margin={1}>
                                              <Table
                                                aria-label='collapsible table'
                                                size='small'
                                              >
                                                <TableHead
                                                  style={{
                                                    backgroundColor: '#F5F5F5'
                                                  }}
                                                >
                                                  <TableRow key={{ i }}>
                                                    <TableCell
                                                      className='tableSLNoHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      #
                                                    </TableCell>
                                                    <TableCell
                                                      className='tableBusinessNameHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Financier 1
                                                    </TableCell>
                                                    <TableCell
                                                      className='tableApplicationType '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Loan Type
                                                    </TableCell>
                                                    <TableCell
                                                      className='tableApplicationType '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Account Number
                                                    </TableCell>
                                                    <TableCell
                                                      className='tableApplicationType '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Loan Amount
                                                    </TableCell>
                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      EMI
                                                    </TableCell>
                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Original Tenure
                                                    </TableCell>
                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Bank
                                                    </TableCell>
                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{ padding: '0px' }}
                                                    >
                                                      Bank Account No
                                                    </TableCell>
                                                    <TableCell className='tablePANHeading '>
                                                      Loan Open?
                                                    </TableCell>

                                                    {false && (
                                                      <TableCell
                                                        className='tablePANHeading '
                                                        style={{
                                                          padding: '0px'
                                                        }}
                                                      >
                                                        Emi StartDate
                                                      </TableCell>
                                                    )}
                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{
                                                        padding: '10px 0px'
                                                      }}
                                                    >
                                                      Auto Fetched
                                                    </TableCell>

                                                    <TableCell
                                                      className='tablePANHeading '
                                                      style={{ padding: '0px' }}
                                                    ></TableCell>
                                                    <TableCell
                                                      className='tableActionHeadingNewWidth '
                                                      style={{
                                                        padding: '0px',
                                                        width: '16px !important'
                                                      }}
                                                    >
                                                      Action
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {item1.loanForBusiness.map(
                                                    (item, i) => (
                                                      <TableRow key={i + 1}>
                                                        <TableCell>
                                                          {i + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.financer_name}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.loan_types}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.Account_no}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.Loan_amount}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.EMI}
                                                        </TableCell>
                                                        <TableCell>
                                                          {
                                                            item.Original_tenure_id
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.bank_name}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.bank_account_no}
                                                        </TableCell>
                                                        <TableCell>
                                                          {!item.Loan_still_open ? (
                                                            <i class='far fa-times-circle'></i>
                                                          ) : (
                                                            <i
                                                              className='fas fa-check-circle'
                                                              style={{
                                                                fontSize:
                                                                  '16px',
                                                                color: 'green',
                                                                padding:
                                                                  '0px 0px 0px 10px'
                                                              }}
                                                            ></i>
                                                          )}
                                                        </TableCell>
                                                        <TableCell>
                                                          {item.data_submit_type ===
                                                            1 ? (
                                                            <i class='far fa-times-circle'></i>
                                                          ) : (
                                                            <i
                                                              className='fas fa-check-circle'
                                                              style={{
                                                                fontSize:
                                                                  '16px',
                                                                color: 'green'
                                                              }}
                                                            ></i>
                                                          )}
                                                        </TableCell>

                                                        {item.EMI == null ||
                                                          item.Original_tenure_id ==
                                                          null ||
                                                          item.financer_name ==
                                                          null ||
                                                          item.loan_types ==
                                                          null ||
                                                          item.bank_name ==
                                                          null ||
                                                          item.bank_account_no ==
                                                          null ||
                                                          item.Loan_still_open ==
                                                          null ||
                                                          item.Loan_amount ==
                                                          null ? (
                                                          <TableCell>
                                                            <Tooltip
                                                              title='Please Fill all the Data'
                                                              placement='bottom'
                                                            >
                                                              <ErrorIcon
                                                                style={{
                                                                  color: 'red'
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          </TableCell>
                                                        ) : (
                                                          <TableCell></TableCell>
                                                        )}

                                                        <TableCell className='tableActionHeading'>
                                                          <Tooltip
                                                            title='Manually Update'
                                                            placement='bottom'
                                                          >
                                                            {/* <Button className="actionBtn"
                                                              onClick={() => {
                                                                getLoansByIdPromoter(item, item1);
                                                                setLoanTable(false);
                                                              }}  >
                                                              <i className="fas fa-edit"
                                                                style={{ fontSize: "15px" }} /></Button> */}
                                                            <img
                                                              className='actionBtn svgicon'
                                                              alt='j'
                                                              src={manualentry}
                                                              onClick={async va => {
                                                                //  validateEmptyName(values.emi);
                                                                //

                                                                setLoanTable(
                                                                  false
                                                                )
                                                                await getLoansByIdPromoter(
                                                                  item,
                                                                  item1
                                                                )
                                                                if (
                                                                  formRef.current
                                                                ) {
                                                                  formRef.current.validateForm()
                                                                  if (
                                                                    item.EMI ==
                                                                    null ||
                                                                    item.Original_tenure_id ==
                                                                    null ||
                                                                    item.financer_name ==
                                                                    null ||
                                                                    item.loan_types ==
                                                                    null ||
                                                                    item.bank_name ==
                                                                    null ||
                                                                    item.bank_account_no ==
                                                                    null
                                                                  ) {
                                                                    formRef.current.validateField()
                                                                    formRef.current.submitForm()
                                                                  }
                                                                }

                                                                getUploadeDocOfUserV2(
                                                                  2,
                                                                  item.id
                                                                )

                                                                // validateForm();
                                                              }}
                                                            />
                                                          </Tooltip>
                                                          <Tooltip
                                                            title='Upload Document'
                                                            placement='bottom'
                                                          >
                                                            {/* <Button className="actionBtn" onClick={(e) => {
                                                            setShowDocScreen(true);
                                                            setParentId1(2);
                                                            setParentTypeId1(item.id)
                                                          }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                                            <img
                                                              alt='image123'
                                                              className='actionBtn svgicon'
                                                              src={uploaddoc}
                                                              onClick={e => {
                                                                setShowDocScreen(
                                                                  true
                                                                )
                                                                setParentId1(
                                                                  item.id
                                                                )
                                                                setParentTypeId1(
                                                                  2
                                                                )
                                                                setbusinesstable(
                                                                  false
                                                                )
                                                                setpromotable(
                                                                  false
                                                                )
                                                              }}
                                                            />
                                                          </Tooltip>
                                                          <Tooltip
                                                            title='Delete Loan'
                                                            placement='bottom'
                                                          >
                                                            {/* <Button className="actionBtn" onClick={(e) => {
                                                            deleteLoanById(item.id, item1.promoter_id, 2);
                                                          }}> <i class="fas fa-minus" style={{ fontSize: "15px" }}></i></Button> */}
                                                            <img
                                                              alt='image123'
                                                              className='actionBtn svgicon'
                                                              src={deleterec}
                                                              onClick={e => {
                                                                deleteLoanById(
                                                                  item.id,
                                                                  item1.promoter_id,
                                                                  2
                                                                )
                                                              }}
                                                            />
                                                          </Tooltip>
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      )}
                    </div>
                    <form onSubmit={handleSubmit} disabled={true}>
                      <Row>
                        <Col md={{ span: 12 }}>
                          {topform == true && (
                            <div>
                              <Row>
                                <Col md={{ span: 10 }} className='mainheader1'>
                                  {parentTypeId === 1 && (
                                    <Typography
                                      text={
                                        headerValue +
                                        ' Loan Account for Business : ' +
                                        businessName
                                      }
                                      styleType='C'
                                    />
                                  )}
                                  {parentTypeId === 2 && (
                                    <Typography
                                      text={
                                        headerValue +
                                        ' Loan Account for Promoter : ' +
                                        businessName
                                      }
                                      styleType='C'
                                    />
                                  )}
                                </Col>
                                <Col
                                  md={{ span: 1 }}
                                  style={{ marginTop: '20px' }}
                                >
                                  <Typography
                                    text={
                                      <i
                                        class='fas fa-eye'
                                        onClick={() => {
                                          
                                          setShowPreview(!showPreview)
                                        }}
                                      ></i>
                                    }
                                  />
                                  &nbsp;&nbsp;
                                </Col>

                                {
                                  /// floating
                                }

                                {Number(role_id.role_id) === 1 ||
                                  Number(role_id.role_id) === 1 ? (
                                  <div id='mySidenav' class='sidenav'>
                                    <a
                                      href='/#'
                                      id='about'
                                      className='mySidenav'
                                      style={{ marginLeft: '-84px' }}
                                      onClick={changeCommentBox}
                                    >
                                      Comments
                                    </a>
                                  </div>
                                ) : null}

                                <Col
                                  md={{ span: 1 }}
                                  className='mainHeaderBack'
                                >
                                  {/* <Button className="backBtn" variant="outlined" color="primary"
                                      onClick={() => {
                                        resetForm({
                                          companyle: "", l_id: "", financer: "", ltype: "", lamount: "", emi: "", originalte: "", remainemi: "", accno: "", noemi: "", edate: "", loanopen: "", emibounce3: "", emibounce6: "",
                                        })
                                        settopform(false);
                                        setbusinesstable(true);
                                        setpromotable(true);
                                        setHeader(true);
                                        setshowUpdateBtn(false);
                                        setshowThreeBtn(true);
                                      }}><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                      </Button> */}

                                  <span className='image'>
                                    <img
                                      alt='im'
                                      className='backbutton navbutton'
                                      src={Back}
                                      onClick={() => {
                                        setHeader(true)
                                        settopform(false)
                                        setbusinesstable(true)
                                        setpromotable(true)
                                      }}
                                    />
                                    <Tooltip
                                      title='Back1'
                                      classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                      }}
                                      arrow
                                    >
                                      <img
                                        alt='im'
                                        className='backbutton navgraybutton'
                                        src={grayback}
                                        onClick={() => {
                                          setHeader(true)
                                          settopform(false)
                                          setbusinesstable(true)
                                          setpromotable(true)
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </Col>
                                <Col md={{ span: 12 }} className='subHeader1'>
                                  <table className='applicationTableDetails1'>
                                    <tr>
                                      {/* <td>Type</td>
                                        <td>:</td> */}
                                      {localData.request_type_id === 0 && (
                                        <td>Loan Application</td>
                                      )}
                                      {localData.request_type_id === 1 && (
                                        <td>Check Eligibility</td>
                                      )}
                                      <td>&nbsp;|&nbsp;</td>
                                      <td>Application Number</td>
                                      <td>:</td>
                                      <td>{localData.reference_id}</td>
                                      <td>&nbsp;|&nbsp;</td>
                                      <td>Loan Type</td>
                                      <td>:</td>
                                      <td>{localData.loan_type}</td>
                                    </tr>
                                  </table>
                                </Col>
                              </Row>
                              {showPreview === false ? (
                                <Row>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography text='Financer' styleType='B' />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <Dropdown
                                          placeholder='Select Financer'
                                          id='financer'
                                          name='financer'
                                          fluid
                                          search
                                          selection
                                          value={values.financer}
                                          onBlur={() =>
                                            setFieldTouched('financer', true)
                                          }
                                          onChange={(e, { value }) =>
                                            setFieldValue('financer', value)
                                          }
                                          options={financial}
                                          disabled={formDisabled}
                                          className={
                                            errors.financer && touched.financer
                                              ? classes.required
                                              : values.financer == ''
                                                ? classes.reqired
                                                : classes.man
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                    {errors.financer &&
                                      touched.financer &&
                                      values.financer == '' && (
                                        <div
                                          style={{
                                            color: 'red',
                                            fontSize: '0.75rem',
                                            marginTop: '-7px'
                                          }}
                                        >
                                          {errors.financer}
                                        </div>
                                      )}
                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='Loan Type'
                                      styleType='B'
                                    />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <Dropdown
                                          placeholder='Select Loan Type'
                                          id='ltype'
                                          name='ltype'
                                          fluid
                                          search
                                          selection
                                          value={values.ltype}
                                          onBlur={() =>
                                            setFieldTouched('ltype', true)
                                          }
                                          onChange={(e, { value }) =>
                                            setFieldValue('ltype', value)
                                          }
                                          options={loantype}
                                          className={
                                            errors.ltype && touched.ltype
                                              ? classes.required
                                              : values.ltype == ''
                                                ? classes.reqired
                                                : classes.man
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>
                                    {errors.ltype &&
                                      touched.ltype &&
                                      values.ltype == '' && (
                                        <div
                                          style={{
                                            color: 'red',
                                            fontSize: '0.75rem',
                                            marginTop: '-7px'
                                          }}
                                        >
                                          {errors.ltype}
                                        </div>
                                      )}
                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='Loan Amount'
                                      styleType='B'
                                    />

                                    <List component='div' disablePadding>
                                      <ListItem>

                                        <TextField
                                          id='filled-error-helper-text standard-basic'
                                          name='lamount'
                                          label='lamount'
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          InputProps={{
                                            className: values.lamount
                                              ? classes.manorma
                                              : null
                                          }}
                                          error={
                                            errors.lamount &&
                                              touched.lamount &&
                                              errors.lamount
                                              ? true
                                              : false
                                          }
                                          value={values.lamount}
                                          helperText={
                                            errors.lamount &&
                                            touched.lamount &&
                                            errors.lamount
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>

                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='Original Tenure'
                                      styleType='B'
                                    />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <TextField
                                          placeholder='Select Original Tenure'
                                          style={{ marginTop: '8px' }}
                                          id='filled-error-helper-text standard-basic'
                                          name='originalte'
                                          value={values.originalte}
                                          onBlur={() => {
                                            setFieldValue('noemi', '')
                                            setFieldValue('remainemi', '')
                                          }}
                                          InputProps={{
                                            className: values.originalte
                                              ? classes.manorma
                                              : null
                                          }}
                                          onChange={e => {
                                            
                                            console.log(e.target.value)
                                            setFieldValue(
                                              'originalte',
                                              e.target.value
                                            )
                                            setOriginalTenure(
                                              Number(e.target.value)
                                            )
                                          }}
                                          error={
                                            errors.originalte &&
                                              touched.originalte &&
                                              errors.originalte
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            errors.originalte &&
                                            touched.originalte &&
                                            errors.originalte
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>
                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography text='EMI' styleType='B' />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <TextField
                                          id='filled-error-helper-text standard-basic'
                                          name='emi'
                                          label='Enter EMI'
                                          onChange={handleChange}
                                          onBlur={e => {
                                            setFieldValue(
                                              'obgconsidered',
                                              e.target.value
                                            )
                                          }}
                                          InputProps={{
                                            className: values.emi
                                              ? classes.manorma
                                              : null
                                          }}
                                          error={
                                            errors.emi &&
                                              touched.emi &&
                                              errors.emi
                                              ? true
                                              : false
                                          }
                                          value={values.emi}
                                          helperText={
                                            errors.emi &&
                                            touched.emi &&
                                            errors.emi
                                          }
                                          // validate={validateEmptyName}
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>
                                  </Col>

                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='Loan still open'
                                      styleType='B'
                                    />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <Dropdown
                                          placeholder='Select Loan open'
                                          id='loanopen'
                                          name='loanopen'
                                          fluid
                                          search
                                          selection
                                          value={values.loanopen}
                                          onBlur={() =>
                                            setFieldTouched('loanopen', true)
                                          }
                                          onChange={(e, { value }) => {
                                            setFieldValue('loanopen', value)
                                            
                                            if (value === 16) {
                                              setFieldValue('obgconsidered', 0)
                                              setFieldValue(
                                                'obgconsidered12',
                                                0
                                              )
                                              setFieldValue(
                                                'obgconsidered12gr',
                                                0
                                              )
                                              setFieldValue('obgconsidered6', 0)
                                            }
                                          }}
                                          options={stillloan}
                                          className={
                                            errors.loanopen && touched.loanopen
                                              ? classes.required
                                              : values.loanopen == ''
                                                ? classes.reqired
                                                : classes.man
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                      {errors.loanopen &&
                                        touched.loanopen &&
                                        values.loanopen == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: '0.75rem',
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.loanopen}
                                          </div>
                                        )}
                                    </List>
                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='EMI Bounce 3 Months'
                                      styleType='B'
                                    />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <TextField
                                          id='filled-error-helper-text standard-basic'
                                          name='emibounce3'
                                          label='Enter EMI'
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          InputProps={{
                                            className: values.emibounce3
                                              ? classes.manorma
                                              : null
                                          }}
                                          error={
                                            errors.emibounce3 &&
                                              touched.emibounce3 &&
                                              errors.emibounce3
                                              ? true
                                              : false
                                          }
                                          value={values.emibounce3}
                                          helperText={
                                            errors.emibounce3 &&
                                            touched.emibounce3 &&
                                            errors.emibounce3
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>
                                  </Col>
                                  <Col md={{ span: 6 }} spacing={0}>
                                    <Typography
                                      text='EMI Bounce in 6 Months'
                                      styleType='B'
                                    />
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <TextField
                                          id='filled-error-helper-text standard-basic'
                                          name='emibounce6'
                                          label='Enter EMI'
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          InputProps={{
                                            className: values.emibounce6
                                              ? classes.manorma
                                              : null
                                          }}
                                          error={
                                            errors.emibounce6 &&
                                              touched.emibounce6 &&
                                              errors.emibounce6
                                              ? true
                                              : false
                                          }
                                          value={values.emibounce6}
                                          helperText={
                                            errors.emibounce6 &&
                                            touched.emibounce6 &&
                                            errors.emibounce6
                                          }
                                          disabled={formDisabled}
                                        />
                                      </ListItem>
                                    </List>
                                  </Col>

                                  {checkvalue === 0 && (
                                    <Col
                                      md={{ span: 6 }}
                                      spacing={0}
                                      style={{ marginTop: '2px' }}
                                    >
                                      <Typography
                                        text='EMI Start Date'
                                        styleType='B'
                                      />
                                      <List
                                        component='div'
                                        disablePadding
                                        style={{ marginTop: '7px' }}
                                      >
                                        <ListItem>
                                          <TextField
                                            id='edate'
                                            type='date'
                                            className={classes.textField}
                                            name='edate'
                                            onChange={handleChange}
                                            onBlur={value => {
                                              
                                              console.log(value)
                                              let date = value.target.value
                                              let check = moment(
                                                date,
                                                'YYYY/MM/DD'
                                              )
                                              let month = check.format('M')
                                              let start_year = check.format('Y')
                                              let currrent_date = new Date()
                                              let curent_year = new Date().getFullYear()
                                              let today_date = new Date().getMonth()

                                              let yeardiff =
                                                curent_year - start_year
                                              let diffMonth =
                                                yeardiff * 12 +
                                                (today_date - month)
                                              console.log(diffMonth)

                                              let number_of_emi = diffMonth
                                              console.log(number_of_emi)
                                              setFieldValue(
                                                'noemi',
                                                number_of_emi
                                              )
                                              if (
                                                number_of_emi <=
                                                originalTenure &&
                                                number_of_emi >= 0
                                              ) {
                                                setFieldValue(
                                                  'noemi',
                                                  number_of_emi
                                                )
                                              } else {
                                                setFieldValue('noemi', '')
                                              }
                                              if (
                                                originalTenure ==
                                                Number(number_of_emi)
                                              ) {
                                                setFieldValue('remainemi', 0)
                                              } else if (
                                                originalTenure -
                                                Number(number_of_emi) >=
                                                0
                                              ) {
                                                setFieldValue(
                                                  'remainemi',
                                                  originalTenure -
                                                  Number(number_of_emi)
                                                )
                                                let val =
                                                  originalTenure -
                                                  Number(number_of_emi)
                                                if (values.loanopen == 15) {
                                                  if (val < 12) {
                                                    setFieldValue(
                                                      'obgconsidered12',
                                                      values.emi
                                                    )
                                                  } else {
                                                    setFieldValue(
                                                      'obgconsidered12',
                                                      0
                                                    )
                                                  }
                                                  if (val > 6 && val > 12) {
                                                    setFieldValue(
                                                      'obgconsidered12gr',
                                                      values.emi
                                                    )
                                                    setFieldValue(
                                                      'obgconsidered6',
                                                      values.emi
                                                    )
                                                  } else {
                                                    setFieldValue(
                                                      'obgconsidered12gr',
                                                      0
                                                    )
                                                    setFieldValue(
                                                      'obgconsidered6',
                                                      0
                                                    )
                                                  }
                                                }
                                              } else {
                                                setFieldValue('remainemi', '')
                                              }
                                            }}
                                            InputProps={{
                                              className: values.edate
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.edate &&
                                                touched.edate &&
                                                errors.edate
                                                ? true
                                                : false
                                            }
                                            value={values.edate}
                                            helperText={
                                              errors.edate &&
                                              touched.edate &&
                                              errors.edate
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                  )}
                                  {/* <Col md={{ span: 6 }} spacing={0}>
                                    <p>test</p>
                                   </Col> */}
                                  {checkvalue === 0 && (
                                    <Col md={{ span: 6 }} spacing={0}>
                                      <Typography
                                        text='Number of EMI'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='noemi'
                                            label='Enter No of EMI'
                                            onChange={handleChange}
                                            onBlur={e => { }}
                                            InputProps={{
                                              className: values.noemi
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.noemi &&
                                                touched.noemi &&
                                                errors.noemi
                                                ? true
                                                : false
                                            }
                                            value={values.noemi}
                                            helperText={
                                              errors.noemi &&
                                              touched.noemi &&
                                              errors.noemi
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                  )}

                                  {checkvalue === 0 && (
                                    <Col md={{ span: 6 }} spacing={0}>
                                      <Typography
                                        text='Account Number'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='accno'
                                            label='Enter Account Number'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.accno
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.accno &&
                                                touched.accno &&
                                                errors.accno
                                                ? true
                                                : false
                                            }
                                            value={values.accno}
                                            helperText={
                                              errors.accno &&
                                              touched.accno &&
                                              errors.accno
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                  )}

                                  {checkvalue === 0 && (
                                    <Col md={{ span: 6 }} spacing={0}>
                                      <Typography
                                        text='Remaining EMI'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='remainemi'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.remainemi
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.remainemi &&
                                                touched.remainemi &&
                                                errors.remainemi
                                                ? true
                                                : false
                                            }
                                            value={values.remainemi}
                                            helperText={
                                              errors.remainemi &&
                                              touched.remainemi &&
                                              errors.remainemi
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                  )}

                                  {checkvalue === 0 && (
                                    <Col md={{ span: 6 }} spacing={0}>
                                      <Typography
                                        text='Bank Name'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Bank'
                                            id='bank'
                                            name='bank'
                                            fluid
                                            search
                                            selection
                                            value={values.bank}
                                            onBlur={() =>
                                              setFieldTouched('bank', true)
                                            }
                                            onChange={(
                                              e,
                                              { value, bank_account_no }
                                            ) => {
                                              
                                              setFieldValue('bank', value)
                                              let acc_no = ''
                                              bank.map(item => {
                                                if (value == item.value) {
                                                  acc_no = item.bank_account_no
                                                }
                                              })
                                              setFieldValue(
                                                'bank_account_no',
                                                acc_no
                                              )
                                            }}
                                            options={bank}
                                            className={
                                              errors.bank && touched.bank
                                                ? classes.required
                                                : values.bank == ''
                                                  ? classes.reqired
                                                  : classes.man
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.bank &&
                                        touched.bank &&
                                        values.bank == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: '0.75rem',
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.bank}
                                          </div>
                                        )}
                                    </Col>
                                  )}

                                  {checkvalue === 0 && (
                                    <Col md={{ span: 6 }} spacing={0}>
                                      <Typography
                                        text='Bank Account Number'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='bank_account_no'
                                            label='Enter Bank Account Number'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.bank_account_no
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.bank_account_no &&
                                                touched.bank_account_no &&
                                                errors.bank_account_no
                                                ? true
                                                : false
                                            }
                                            value={values.bank_account_no}
                                            helperText={
                                              errors.bank_account_no &&
                                              touched.bank_account_no &&
                                              errors.bank_account_no
                                            }
                                            disabled={formDisabled}
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                  )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                    <Col
                                      md={{ span: 6 }}
                                      className='fileformatted'
                                      style={{ marginTop: '25px' }}
                                    >
                                      <Typography
                                        text='Obligation Considered'
                                        styleType='B'
                                      />
                                      <Dropdown
                                        placeholder='Select Obligation Considered'
                                        name='obligation_considered_bool'
                                        fluid
                                        search
                                        onChange={(e, { value }) =>
                                          setFieldValue('obligation_considered_bool', value)
                                        }
                                        value={
                                          values.obligation_considered_bool ||
                                          ''
                                        }
                                        selection
                                        options={options_obli}
                                      />
                                    </Col>
                                  )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                    <Col
                                      md={{ span: 6 }}
                                      className='fileformatted'
                                      style={{ marginTop: '25px' }}
                                    >
                                      <Typography
                                        text='Obligation Considered(>6)'
                                        styleType='B'
                                      />
                                      <Dropdown
                                        placeholder='Select Obligation Considered(>6)'
                                        name='obligation_considered_gt6'
                                        fluid
                                        search
                                        selection
                                        onChange={(e, { value }) =>
                                          setFieldValue('obligation_considered_gt6', value)
                                        }
                                        value={
                                          values.obligation_considered_gt6 ||
                                          ''
                                        }
                                        options={options_obli}
                                      />
                                    </Col>
                                  )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                      <Col md={{ span: 6 }} spacing={0}>
                                        <Typography
                                          text='Obligation Considered Comment'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              name='obgconsidered'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.obgconsidered
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              error={
                                                errors.obgconsidered &&
                                                  touched.obgconsidered &&
                                                  errors.obgconsidered
                                                  ? true
                                                  : false
                                              }
                                              value={values.obgconsidered}
                                              helperText={
                                                errors.obgconsidered &&
                                                touched.obgconsidered &&
                                                errors.obgconsidered
                                              }
                                              disabled={formDisabled}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                    )}

                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                      <Col md={{ span: 6 }} spacing={0}>
                                        <Typography
                                          text='Obligation Considered(>6) Comment'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              name='obgconsidered6'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.obgconsidered6
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              error={
                                                errors.obgconsidered6 &&
                                                  touched.obgconsidered6 &&
                                                  errors.obgconsidered6
                                                  ? true
                                                  : false
                                              }
                                              value={values.obgconsidered6}
                                              helperText={
                                                errors.obgconsidered6 &&
                                                touched.obgconsidered6 &&
                                                errors.obgconsidered6
                                              }
                                              disabled={formDisabled}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                    )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                    <Col
                                      md={{ span: 6 }}
                                      className='fileformatted'
                                      style={{ marginTop: '25px' }}
                                    >
                                      <Typography
                                        text='Obligation Considered(<12)'
                                        styleType='B'
                                      />
                                      <Dropdown
                                        placeholder='Select Obligation Considered(<12)'
                                        name='obligation_considered_lt12'
                                        fluid
                                        search
                                        selection
                                        onChange={(e, { value }) =>
                                          setFieldValue('obligation_considered_lt12', value)
                                        }
                                        value={
                                          values.obligation_considered_lt12
                                        }
                                        options={options_obli}
                                      />
                                    </Col>
                                  )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                    <Col
                                      md={{ span: 6 }}
                                      className='fileformatted'
                                      style={{ marginTop: '25px' }}
                                    >
                                      <Typography
                                        text='Obligation Considered(>12)'
                                        styleType='B'
                                      />
                                      <Dropdown
                                        placeholder='Select Obligation Considered(>12)'
                                        name='obligation_considered_gt12'
                                        fluid
                                        search
                                        selection
                                        onChange={(e, { value }) =>
                                          setFieldValue('obligation_considered_gt12', value)
                                        }
                                        value={
                                          values.obligation_considered_gt12 ||
                                          ''
                                        }
                                        // onChange={handleChange}
                                        options={options_obli}
                                      />
                                    </Col>
                                  )}
                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                      <Col md={{ span: 6 }} spacing={0}>
                                        <Typography
                                          text='Obligation Considered(<12) Comment'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              name='obgconsidered12'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.obgconsidered12
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              error={
                                                errors.obgconsidered12 &&
                                                  touched.obgconsidered12 &&
                                                  errors.obgconsidered12
                                                  ? true
                                                  : false
                                              }
                                              value={values.obgconsidered12}
                                              helperText={
                                                errors.obgconsidered12 &&
                                                touched.obgconsidered12 &&
                                                errors.obgconsidered12
                                              }
                                              disabled={formDisabled}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                    )}

                                  {checkvalue === 0 &&
                                    (role_id.role_id == 1 ||
                                      role_id.role_id == 3 ||
                                      role_id.role_id == 2) && (
                                      <Col md={{ span: 6 }} spacing={0}>
                                        <Typography
                                          text='Obligation Considered(>12) Comment'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic'
                                              name='obgconsidered12gr'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.obgconsidered12gr
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              error={
                                                errors.obgconsidered12gr &&
                                                  touched.obgconsidered12gr &&
                                                  errors.obgconsidered12gr
                                                  ? true
                                                  : false
                                              }
                                              value={values.obgconsidered12gr}
                                              helperText={
                                                errors.obgconsidered12gr &&
                                                touched.obgconsidered12gr &&
                                                errors.obgconsidered12gr
                                              }
                                              disabled={formDisabled}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                    )}

                                  {!showUpdateBtn && (
                                    <>
                                      <Col md={{ span: 12 }}>
                                        <Row>
                                          <Col md={{ span: 10 }}></Col>
                                          <Col md={{ span: 2 }}>
                                            <Button
                                              className='updatebtn'
                                              variant='contained'
                                              color='primary'
                                              type='submit'
                                              onClick={handleSubmit}
                                            >
                                              {addFormButtonValue}
                                            </Button>
                                          </Col>
                                          <Col md={{ span: 5 }}></Col>
                                        </Row>
                                      </Col>
                                    </>
                                  )}

                                  {showUpdateBtn && (
                                    <>
                                      <Col md={{ span: 10 }}></Col>
                                      <Col md={{ span: 2 }}>
                                        <Button
                                          className='updatebtn'
                                          variant='contained'
                                          color='primary'
                                          onClick={() => {
                                            setshowUpdateBtn(false)
                                            setHeaderValue('Adding')
                                            handleSubmit()
                                          }}
                                        >
                                          Update
                                        </Button>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              ) : (
                                <Row>
                                  <Col md={{ span: 6 }}>
                                    <Table border='1' className='style-table'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='second_header loan_header'
                                            style={table_header3}
                                          >
                                            Parameter
                                          </TableCell>
                                          <TableCell
                                            className='second_header loan_header'
                                            style={table_header3}
                                          >
                                            Value
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <>
                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Financer
                                            </TableCell>
                                            <TableCell>
                                              <Dropdown
                                                placeholder='Select Financer'
                                                id='financer'
                                                name='financer'
                                                fluid
                                                search
                                                style={input_style}
                                                selection
                                                value={values.financer}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    'financer',
                                                    true
                                                  )
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue(
                                                    'financer',
                                                    value
                                                  )
                                                }
                                                options={financial}
                                                InputProps={{
                                                  disableUnderline: true
                                                }}
                                                disabled={formDisabled}
                                                className={
                                                  errors.financer &&
                                                    touched.financer
                                                    ? classes.required
                                                    : values.financer == ''
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Account Number
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='accno'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.accno
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.accno &&
                                                    touched.accno &&
                                                    errors.accno
                                                    ? true
                                                    : false
                                                }
                                                value={values.accno}
                                                helperText={
                                                  errors.accno &&
                                                  touched.accno &&
                                                  errors.accno
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Loan Type
                                            </TableCell>
                                            <TableCell>
                                              <Dropdown
                                                placeholder='Select Loan Type'
                                                id='ltype'
                                                name='ltype'
                                                fluid
                                                search
                                                selection
                                                style={{ textAlign: 'center' }}
                                                value={values.ltype}
                                                onBlur={() =>
                                                  setFieldTouched('ltype', true)
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue('ltype', value)
                                                }
                                                options={loantype}
                                                className={
                                                  errors.ltype && touched.ltype
                                                    ? classes.required
                                                    : values.ltype == ''
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                                disabled={formDisabled}
                                                InputProps={{
                                                  disableUnderline: true
                                                }}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Loan Amount
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='lamount'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.lamount
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.lamount &&
                                                    touched.lamount &&
                                                    errors.lamount
                                                    ? true
                                                    : false
                                                }
                                                value={values.lamount}
                                                helperText={
                                                  errors.lamount &&
                                                  touched.lamount &&
                                                  errors.lamount
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              EMI
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='emi'
                                                onChange={handleChange}
                                                onBlur={e => {
                                                  setFieldValue(
                                                    'obgconsidered',
                                                    e.target.value
                                                  )
                                                }}
                                                InputProps={{
                                                  className: values.emi
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.emi &&
                                                    touched.emi &&
                                                    errors.emi
                                                    ? true
                                                    : false
                                                }
                                                value={values.emi}
                                                helperText={
                                                  errors.emi &&
                                                  touched.emi &&
                                                  errors.emi
                                                }
                                                // validate={validateEmptyName}
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Original Tenure
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                style={{ marginTop: '8px' }}
                                                id='filled-error-helper-text standard-basic'
                                                name='originalte'
                                                value={values.originalte}
                                                onBlur={() => {
                                                  setFieldValue('noemi', '')
                                                  setFieldValue('remainemi', '')
                                                }}
                                                InputProps={{
                                                  className: values.originalte
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                onChange={e => {
                                                  
                                                  console.log(e.target.value)
                                                  setFieldValue(
                                                    'originalte',
                                                    e.target.value
                                                  )
                                                  setOriginalTenure(
                                                    Number(e.target.value)
                                                  )
                                                }}
                                                error={
                                                  errors.originalte &&
                                                    touched.originalte &&
                                                    errors.originalte
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  errors.originalte &&
                                                  touched.originalte &&
                                                  errors.originalte
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Remaining EMI
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='remainemi'
                                                style={{ textAlign: 'center' }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.remainemi
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.remainemi &&
                                                    touched.remainemi &&
                                                    errors.remainemi
                                                    ? true
                                                    : false
                                                }
                                                value={values.remainemi}
                                                helperText={
                                                  errors.remainemi &&
                                                  touched.remainemi &&
                                                  errors.remainemi
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Number of EMI
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='noemi'
                                                onChange={handleChange}
                                                onBlur={e => { }}
                                                InputProps={{
                                                  className: values.noemi
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.noemi &&
                                                    touched.noemi &&
                                                    errors.noemi
                                                    ? true
                                                    : false
                                                }
                                                value={values.noemi}
                                                helperText={
                                                  errors.noemi &&
                                                  touched.noemi &&
                                                  errors.noemi
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              EMI Start Date
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                id='edate'
                                                type='date'
                                                style={{ textAlign: 'center' }}
                                                className={classes.textField}
                                                name='edate'
                                                onChange={handleChange}
                                                onBlur={value => {
                                                  
                                                  console.log(value)
                                                  let date = value.target.value
                                                  let check = moment(
                                                    date,
                                                    'YYYY/MM/DD'
                                                  )
                                                  let month = check.format('M')
                                                  let start_year = check.format(
                                                    'Y'
                                                  )
                                                  let currrent_date = new Date()
                                                  let curent_year = new Date().getFullYear()
                                                  let today_date = new Date().getMonth()

                                                  let yeardiff =
                                                    curent_year - start_year
                                                  let diffMonth =
                                                    yeardiff * 12 +
                                                    (today_date - month)
                                                  console.log(diffMonth)

                                                  let number_of_emi = diffMonth
                                                  console.log(number_of_emi)
                                                  setFieldValue(
                                                    'noemi',
                                                    number_of_emi
                                                  )
                                                  if (
                                                    number_of_emi <=
                                                    originalTenure &&
                                                    number_of_emi >= 0
                                                  ) {
                                                    setFieldValue(
                                                      'noemi',
                                                      number_of_emi
                                                    )
                                                  } else {
                                                    setFieldValue('noemi', '')
                                                  }
                                                  if (
                                                    originalTenure ==
                                                    Number(number_of_emi)
                                                  ) {
                                                    setFieldValue(
                                                      'remainemi',
                                                      0
                                                    )
                                                  } else if (
                                                    originalTenure -
                                                    Number(number_of_emi) >=
                                                    0
                                                  ) {
                                                    setFieldValue(
                                                      'remainemi',
                                                      originalTenure -
                                                      Number(number_of_emi)
                                                    )
                                                    let val =
                                                      originalTenure -
                                                      Number(number_of_emi)
                                                    if (values.loanopen == 15) {
                                                      if (val < 12) {
                                                        setFieldValue(
                                                          'obgconsidered12',
                                                          values.emi
                                                        )
                                                      } else {
                                                        setFieldValue(
                                                          'obgconsidered12',
                                                          0
                                                        )
                                                      }
                                                      if (val > 6 && val > 12) {
                                                        setFieldValue(
                                                          'obgconsidered12gr',
                                                          values.emi
                                                        )
                                                        setFieldValue(
                                                          'obgconsidered6',
                                                          values.emi
                                                        )
                                                      } else {
                                                        setFieldValue(
                                                          'obgconsidered12gr',
                                                          0
                                                        )
                                                        setFieldValue(
                                                          'obgconsidered6',
                                                          0
                                                        )
                                                      }
                                                    }
                                                  } else {
                                                    setFieldValue(
                                                      'remainemi',
                                                      ''
                                                    )
                                                  }
                                                }}
                                                InputProps={{
                                                  className: values.edate
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.edate &&
                                                    touched.edate &&
                                                    errors.edate
                                                    ? true
                                                    : false
                                                }
                                                value={values.edate}
                                                helperText={
                                                  errors.edate &&
                                                  touched.edate &&
                                                  errors.edate
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Loan still open
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <Dropdown
                                                placeholder='Select Loan open'
                                                id='loanopen'
                                                fluid
                                                search
                                                selection
                                                value={values.loanopen}
                                                style={{ textAlign: 'center' }}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    'loanopen',
                                                    true
                                                  )
                                                }
                                                onChange={(e, { value }) => {
                                                  setFieldValue(
                                                    'loanopen',
                                                    value
                                                  )
                                                  
                                                  if (value === 16) {
                                                    setFieldValue(
                                                      'obgconsidered',
                                                      0
                                                    )
                                                    setFieldValue(
                                                      'obgconsidered12',
                                                      0
                                                    )
                                                    setFieldValue(
                                                      'obgconsidered12gr',
                                                      0
                                                    )
                                                    setFieldValue(
                                                      'obgconsidered6',
                                                      0
                                                    )
                                                  }
                                                }}
                                                options={stillloan}
                                                className={
                                                  errors.loanopen &&
                                                    touched.loanopen
                                                    ? classes.required
                                                    : values.loanopen == ''
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              EMI Bounce 3 Months
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='emibounce3'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ textAlign: 'center' }}
                                                InputProps={{
                                                  className: values.emibounce3
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.emibounce3 &&
                                                    touched.emibounce3 &&
                                                    errors.emibounce3
                                                    ? true
                                                    : false
                                                }
                                                value={values.emibounce3}
                                                helperText={
                                                  errors.emibounce3 &&
                                                  touched.emibounce3 &&
                                                  errors.emibounce3
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              EMI Bounce in 6 Months
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='emibounce6'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.emibounce6
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.emibounce6 &&
                                                    touched.emibounce6 &&
                                                    errors.emibounce6
                                                    ? true
                                                    : false
                                                }
                                                value={values.emibounce6}
                                                helperText={
                                                  errors.emibounce6 &&
                                                  touched.emibounce6 &&
                                                  errors.emibounce6
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Bank Name
                                            </TableCell>
                                            <TableCell>
                                              {' '}
                                              <Dropdown
                                                id='bank'
                                                name='bank'
                                                fluid
                                                search
                                                style={{ textAlign: 'center' }}
                                                selection
                                                value={values.bank}
                                                InputProps={{
                                                  className: values.bank_account_no
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true
                                                }}
                                                onBlur={() =>
                                                  setFieldTouched('bank', true)
                                                }
                                                onChange={(
                                                  e,
                                                  { value, bank_account_no }
                                                ) => {
                                                  
                                                  setFieldValue('bank', value)
                                                  let acc_no = ''
                                                  bank.map(item => {
                                                    if (value == item.value) {
                                                      acc_no =
                                                        item.bank_account_no
                                                    }
                                                  })
                                                  setFieldValue(
                                                    'bank_account_no',
                                                    acc_no
                                                  )
                                                }}
                                                options={bank}
                                                className={
                                                  errors.bank && touched.bank
                                                    ? classes.required
                                                    : values.bank == ''
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>

                                          <TableRow>
                                            <TableCell
                                              style={loan_cell}
                                              className='loan_cell'
                                            >
                                              Account Number
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                id='filled-error-helper-text standard-basic'
                                                name='bank_account_no'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.bank_account_no
                                                    ? classes.manorma
                                                    : null,
                                                  disableUnderline: true,
                                                  inputProps: {
                                                    style: {
                                                      textAlign: 'center'
                                                    }
                                                  }
                                                }}
                                                error={
                                                  errors.bank_account_no &&
                                                    touched.bank_account_no &&
                                                    errors.bank_account_no
                                                    ? true
                                                    : false
                                                }
                                                value={values.bank_account_no}
                                                helperText={
                                                  errors.bank_account_no &&
                                                  touched.bank_account_no &&
                                                  errors.bank_account_no
                                                }
                                                disabled={formDisabled}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      </TableBody>
                                    </Table>
                                  </Col>

                                  <Col
                                    md={{ span: 6 }}
                                    className='fileformatted'
                                    style={{ marginTop: '25px' }}
                                  >
                                    <Dropdown
                                      placeholder='Select Document'
                                      name='area'
                                      fluid
                                      search
                                      selection
                                      //onBlur={() => setFieldTouched("area", true)}
                                      onChange={async (
                                        e,
                                        { value, text, file_type, options }
                                      ) => {
                                        //
                                        console.log(options)
                                        if (options.length > 1) {
                                          options.map(item => {
                                            if (item.value === value) {
                                              setPrieviewValues(
                                                value,
                                                item.file_type
                                              )
                                            }
                                          })
                                        }
                                      }}
                                      options={documentArray}
                                    />
                                    <FileViewer
                                      fileType={docType}
                                      filePath={docPath}
                                    //  errorComponent={CustomErrorComponent}
                                    // className="banking_fileviewer"
                                    />
                                  </Col>
                                  <Col md={{ span: 10 }}></Col>
                                  <Col md={{ span: 2 }}>
                                    <Button
                                      className='updatebtn'
                                      variant='contained'
                                      color='primary'
                                      onClick={() => {
                                        setshowUpdateBtn(false)
                                        setHeaderValue('Adding')
                                        handleSubmit()
                                      }}
                                    >
                                      Update
                                    </Button>
                                  </Col>
                                </Row>
                              )}

                              {opencommentbox && (
                                <Row>
                                  <Col
                                    md={{ span: 6 }}
                                    style={{ marginTop: '2%' }}
                                  ></Col>
                                  <Col
                                    md={{ span: 6 }}
                                    style={{ marginTop: '2%' }}
                                  >
                                    <div
                                      style={commentBox}
                                      className='comment-box'
                                    >
                                      <h2
                                        style={{ color: 'rgb(114, 84, 163)' }}
                                      >
                                        Verification Comments
                                      </h2>
                                      <div className='comment-form'>
                                        <div className='comment-form-fields'>
                                          <textarea
                                            className='comment_textfield'
                                            placeholder='Comment'
                                            rows='4'
                                            required
                                            value={comments}
                                            onChange={e => {
                                              
                                              setComments(e.target.value)
                                            }}
                                          ></textarea>
                                        </div>
                                        <div className='comment-form-actions'>
                                          <Button
                                            type='button'
                                            variant='contained'
                                            color='primary'
                                            className='x'
                                            onClick={e => {
                                              sendComments()
                                            }}
                                          >
                                            Post Comment
                                          </Button>
                                        </div>
                                      </div>

                                      {commentArr.map(item => (
                                        <div className='comment'>
                                          <p className='comment-header'>
                                            {item.email}
                                          </p>
                                          <p className='comment-body'>
                                            - {item.comments}
                                          </p>
                                          <div className='comment-footer'>
                                            <a
                                              href='/#'
                                              className='comment-footer-delete'
                                              onClick={() => {
                                                deleteComment(item.id)
                                              }}
                                            >
                                              Delete Comment
                                            </a>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )}

                          {showThreeBtn && (
                            <div className='btn_row_fixd'>
                              <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col style={{ padding: '0%' }}></Col>
                                <Col style={{ padding: '0%' }}></Col>
                                {/* <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                      <img className='navbutton' src={Back} onClick={handledata} />
                                    </Tooltip>
                                  </Col> */}
                                <Col style={{ padding: '0%' }}>
                                  <span className='image'>
                                    <img
                                      alt='im'
                                      className='navbutton'
                                      src={Back}
                                      onClick={
                                        () => {
                                          
                                          props.handleBack()
                                        }
                                      }
                                    />
                                    <Tooltip
                                      title='Back'
                                      classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                      }}
                                      arrow
                                    >
                                      <img
                                        alt='im'
                                        className='navgraybutton'
                                        src={grayback}
                                        onClick={() => {
                                          props.handleBack();
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </Col>
                                {
                                  // <Col style={{ padding: "0%" }}>
                                  //   <Tooltip title="Reset" arrow>
                                  //     <img className='navbutton' src={Reset} onClick={() => { resetForm() }} />
                                  //   </Tooltip>
                                  // </Col>
                                }
                                {
                                  // <Col style={{ padding: "0%" }}>
                                  //   <Tooltip title="Save & Next" arrow>
                                  //     <img className='navbutton' src={SaveNext} onClick={handleSubmit} />
                                  //   </Tooltip>
                                  // </Col>
                                }
                                {/* <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Save as Draft & Next" arrow>
                                      <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                    </Tooltip>
                                  </Col> */}

                                <Col style={{ padding: '0%' }}>
                                  <span className='image'>
                                    <img
                                      alt='im'
                                      className='navbutton'
                                      src={DraftNext}
                                    />
                                    <Tooltip title='Save & Next' arrow>
                                      <img
                                        alt='im'
                                        className='navgraybutton'
                                        src={graydraftnext}
                                        onClick={SaveasDraft}
                                      />
                                    </Tooltip>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {false && (
                        <Row>
                          <Col md={{ span: 3 }}></Col>
                          <Col md={{ span: 3 }}>
                            <Button
                              className={classes.linkdin}
                              variant='contained'
                              color='primary'
                              disabled={true}
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </form>
                  </>
                )
              }}
            </Formik>
          )}

          <Dialog
            disableBackdropClick
            open={openHDDialogBox1}
            onClose={handleCloseOpenHDDialogBox1}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            PaperProps={{
              style: {
                border: '1px solid'
              }
            }}
          >
            <Modal.Header
              className='modalHead'
              closeButton
              onClick={handleClose}
              style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
            >
              <Modal.Title className='modalHeader cpModal'>
                Loan Details
              </Modal.Title>
            </Modal.Header>
            <DialogContent>
              <DialogContentText
                id='alert-dialog-description'
                style={{ fontSize: '16px', margin: '1% -1%' }}
              >
                Please fill all the field?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleCloseOpenHDDialogBox1}
                className='backBtn'
                variant='outlined'
                color='primary'
              >
                No
              </Button>

              <Button
                className='backBtn'
                variant='outlined'
                color='primary'
                style={{ marginRight: '2%' }}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div className='mini-container mg_btm_set'>
          <Row>
            <Col md={{ span: 6 }} className='mainheader1'>
              <Typography text='YOUR EXISITING LOANS' styleType='C' />
            </Col>

            <Col md={{ span: 12 }} style={{ textAlign: 'left' }}>
              <span className='image'>
                <img
                  alt='pick'
                  className='backbutton navbutton'
                  style={{ width: '4%' }}
                  src={Back}
                  onClick={() => {
                    setloanCalculatedPreview(true)
                  }}
                />
                <Tooltip title='Back' arrow>
                  <img
                    alt='pick'
                    className='backbutton navgraybutton'
                    src={grayback}
                    style={{ width: '4%' }}
                    onClick={() => {
                      setloanCalculatedPreview(true)
                    }}
                  />
                </Tooltip>
              </span>
            </Col>
            <Col md={{ span: 6 }} className='mainheader1'>
              <Typography text={businessname} styleType='B' />
            </Col>

            <Col md={{ span: 12 }}>
              <div class='table_wrapper'>
                <table border='1' className='styled-table'>
                  <thead>
                    <tr>
                      <th className='gstcalheader'>Financier Name</th>
                      <th className='gstcalheader'>Bank name</th>
                      <th className='gstcalheader'>Ac no.</th>
                      <th className='gstcalheader'>Loan Type</th>
                      <th className='gstcalheader'>Loan Amount</th>
                      <th className='gstcalheader'>Emi</th>

                      <th className='gstcalheader'>Original Tenure</th>
                      <th className='gstcalheader'>EMI Start Date</th>
                      <th className='gstcalheader'>No. of EMI Paid</th>
                      <th className='gstcalheader'>Remaining EMI</th>
                      <th className='gstcalheader'>Mapper As Obligation</th>
                      <th className='gstcalheader'>Obligation Considered</th>
                      <th className='gstcalheader'>
                        Obligations greater than 6M
                      </th>
                      <th className='gstcalheader'>
                        Obligations greater than 12M
                      </th>
                      <th className='gstcalheader'>Obligations less than 12</th>
                      <th className='gstcalheader'>POS</th>

                      <th className='gstcalheader'>IRR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBusinessList[indexValue].loanForBusiness.map(item2 => (
                      <tr>
                        <td className='cal_row1'>{item2.financer_name}</td>
                        <td className='cal_row1'>{item2.bank_name}</td>
                        <td className='cal_row1'>{item2.Account_no}</td>
                        <td className='cal_row1'>{item2.loan_types}</td>

                        <td className='cal_row1'>{item2.Loan_amount}</td>
                        <td className='cal_row1'>{item2.EMI}</td>

                        <td className='cal_row1'>{item2.Original_tenure_id}</td>
                        <td className='cal_row1'>
                          {moment(item2.EMI_start_date).format('DD-MM-YYYY')}
                        </td>
                        <td className='cal_row1'>
                          {item2.No_of_emi - item2.Remaining_emi}
                        </td>
                        <td className='cal_row1'>{item2.Remaining_emi}</td>
                        <td className='cal_row1'>
                          <Select
                            style={{
                              width: '99px',
                              marginLeft: '12px'
                            }}
                            options={options}
                            defaultValue={{ label: 'Yes', value: 'Yes' }}
                            onChange={value =>
                              changeOblMap(value, item2.obligation_considered)
                            }
                          />
                        </td>
                        <td className='cal_row1'>
                          {item2.obligation_considered}
                        </td>
                        <td className='cal_row1'>
                          {item2.obligation_considered_greater_than_6m}
                        </td>
                        <td className='cal_row1'>
                          {item2.obligation_considered_greater_than_12m}
                        </td>
                        <td className='cal_row1'>
                          {item2.obligation_considered_less_than_12m}
                        </td>
                        <td className=' cal_row1'>
                          {(
                            (Number(item2.Loan_amount) /
                              Number(item2.Original_tenure_id)) *
                            Number(item2.Remaining_emi)
                          ).toFixed(2)}
                        </td>
                        <td className='cal_row1'>&nbsp;</td>
                      </tr>
                    ))}
                    <tr className='calculated_row'>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>

                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.Loan_amount.toFixed(2)}
                      </td>
                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.EMI.toFixed(2)}
                      </td>

                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>

                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.obligation_considered.toFixed(2)}
                      </td>
                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.obligation_considered_greater_than_6m.toFixed(
                          2
                        )}
                      </td>
                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.obligation_considered_greater_than_12m.toFixed(
                          2
                        )}
                      </td>
                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.obligation_considered_less_than_12m.toFixed(
                          2
                        )}
                      </td>
                      <td className='cal_row1 loan_cal_row'>
                        {globalObj.pos.toFixed(2)}
                      </td>
                      <td className='cal_row1 loan_cal_row'>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  )
}
export default Loan
