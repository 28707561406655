import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "../../base_components/Typography";
import BusinessInfo from "../BusinessInfoForm";
import ShareHolder from "../ShareHolderForm/index";
import GST from "../GSTForm";
import Finance from "../FinanceForm";
import Profile from "../MainPage2";
import Banking from "../BankingForm";
import Status from "../Status";
import Loan from "../Loan";
import Working from "../WorkingCapitalForm";
import Asset from "../AssetsForm";
import ApplicationFatCheck from '../ApplicationFatCheck';
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row"; // why bootstrap if material ui is used.
import Col from "react-bootstrap/Col"; // avoid integrating more than one ui library
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { useStyles, useQontoStepIconStyles } from "./styles";
import { makeStyles } from '@material-ui/core/styles';
import logo from "../../image/logo.png";
import LetsGetStarted from "../LetsGetStartedForm/index";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ApplicationFinScoring2 from '../ApplicationFinScoring2';
import ApplicationLoanEligibility from '../ApplicationLoanEligibility';
import DocumentUpload from '../DocumentUpload';
import StepButton from '@material-ui/core/StepButton';
import axios from 'axios';
import profile from '../../image/pngicons/profile.png';
import IconButton from '@material-ui/core/IconButton';
import notification from '../../image/pngicons/notification.png';
import CpaMainPage from '../CpaMainPage';
import FatCheck2 from '../FatCheck2';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import moment from 'moment';
import ApplicationSummaryView from "../ApplicationSummaryView";
import BookCalEve from "../BookCalEve";
import AssetLAP from "../AssetLAP";
import Cookies from 'js-cookie'
import BookAppointment from '../BookAppointment';
import DocsCollected from '../DocsCollected';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DocumentsCollected from '../DocumentsCollected/index';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import MenuIcon from '@material-ui/icons/Menu';
const steps = [
  "Profile",
  "Business Information",
  "Promoter Information",
  "Let's Get Started",
  "Financial",
  "Bank Accounts",
  "Loans",
  "Working Capital",
  "GST Details",
  "Asset",
  "Fat Check",
  "Financial Scoring",
  "Loan Elegibility",
  "Documents Upload",
  "Status",
  "ApplicationSummaryView"
];


const ProfileStepper = props => {
  //////////

  const drawerWidth = 240;
  const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: 36,
    },
    colLeft: {
      marginLeft: '-70px',
    },
    padLeft: {
      paddingLeft: "5em",
    },
    menuButtonHidden: {
      display: 'none',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      // height: '80px !important',
      transition: theme.transitions.create(['width', 'height', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,

      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,

      }),
    },
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 5px',
      ...theme.mixins.toolbar,
    },

  }));
  let initialActiveStep = localStorage.getItem('perfiosreturn') ? 5 : 0;
  // let initialActiveStep = Cookies.get('perfiosreturn_cookie') ? 5 : 0;

  const [activeStep, setActiveStep] = React.useState(initialActiveStep);
  const [email, setemail] = React.useState("");
  const [propan, setPropan] = React.useState("");
  // const [drawopen, setdrawopen] = React.useState(false);
  const [st, setSt] = React.useState(0);

  const handlePromoter = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 2);
    // const [st, setSt] = React.useState(0);
  }
  const classes = useStyles();
  const [busIdd, setbusIdd] = React.useState();
  const [promoId, setPromoId] = React.useState();
  const [newPromo, setNewPromo] = React.useState(false);
  const [openPromo, setOpenPromo] = React.useState(false);
  const [openNewBus, setOpenNeBus] = React.useState(false);
  const [newBus, setNewBus] = React.useState(false);


  const [completed, setCompleted] = React.useState({});
  const steps1 = steps.length;

  const totalSteps = () => {
    return steps.length;
  };
  const [opendrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  React.useEffect(() => {
    checkusers();
    profileDataInfo();
    getAllPagesDataCount();
    // handleNext(undefined,undefined,4);
    getLastLogin();
  }, []);



  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };


  const [promoterDoc, setPromoterDoc] = React.useState("");
  const [busDoc, setBusDoc] = React.useState(false);
  const handleNext = (i, j, k) => {
    //
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    //////////
    handleComplete();
    // if (j === true) {
    //   setOpenPromo(true);
    // }
    if (k === true) {
      setHandleLetsGetsPage(true);
    }
    if (j == true) {
      setBusDoc(true);
    } else {
      setBusDoc(false);
    }

    setPromoterDoc(j);
    setbusIdd(i);
    setPromoId(i)
    if (i === undefined && j === undefined && k === undefined) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + k);
      setNewPromo(true);
    }
  };


  const [handleLetsGetsPage, setHandleLetsGetsPage] = React.useState(false);
  const handleNextForBusinesss = (flag) => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setHandleLetsGetsPage(true);
  }

  const [handleNextLetStartValue, setHandleNextLetStartValue] = React.useState("");
  const handleNextLetStart = () => {
    //////////
    setHandleNextLetStartValue("isRequestAvailable")
    setActiveStep(prev => prev + 3)
  }

  const [busIdForNew, setBusIdForNew] = React.useState(false);

  const handleNexts = (value) => {
    //////
    setBusIdForNew(value);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }


  const handleBack = (i) => {
    if (i === undefined && i === undefined) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - i);
    }
  };

  const [numberOfData, setNumberOfData] = React.useState([]);
  const getAllPagesDataCount = async (item, loanType) => {
    
    let ltp =Number;
    if(loanType == undefined){
      if(JSON.parse((localStorage.getItem("stepperdata")))){
        ltp = JSON.parse((localStorage.getItem("stepperdata"))).loantypeid;
      } else {
        ltp = '';
      }
      
    }else{
      ltp = loanType
    }

    const checkuserloggeddata = localStorage.getItem("userdata");
    //let checkuserloggeddata = Cookies.get('userdata_cookie');
    let iduser = JSON.parse(checkuserloggeddata);
    let id;
    if (item != undefined) {
      id = item;
    } else {
      id = iduser.id
    }
    let data = {
      user_id: id,
      role_id: iduser.role_id,
      loantype: ltp
    }
    let url = '';
    // if (iduser.role_id == 2) {

    //   url = apiCalling.coderscorner + 'server.php/api/getRecordByUseridUser';
    // } else {
    //   url = apiCalling.coderscorner + 'server.php/api/getRecordByUserid';


    // }
    let url1 =apiCalling.coderscorner + 'server.php/api/getsidepanelByrole';

    url = apiCalling.coderscorner + 'server.php/api/getsidepanelByrolebasedOnLoan';
    //

    axios.post(url, data).then((result) => {
      if (result.data.status) {
        console.log("apdu", result.data.data);
        setNumberOfData(result.data.data);
        let nData = [];
        result.data.data.forEach(element => {
          console.log("element.menu", element.count)
          nData.push({ menu: element.menu, count: element.count, status: element.status, id: element.id });
        });
        setNumberOfData(nData);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const [loginDate, setLoginDate] = React.useState("");
  const [profilePan, setProfilePan] = React.useState("");
  const [profileMobile, setProfileMobile] = React.useState("");
  const [profileEmail, setProfileEmail] = React.useState("");
  const profileDataInfo = () => {
    const pdi = localStorage.getItem("userdata"),
      //let pdi = Cookies.get('userdata_cookie'),
      proDataInfo = JSON.parse(pdi);
    const dash = localStorage.getItem("logindata"),
      //let dash = Cookies.get('logindata_cookie'),
      dashdata = JSON.parse(dash);
    setProfilePan(proDataInfo.pan);
    setProfileMobile(proDataInfo.mobile);
    setProfileEmail(proDataInfo.email);
    //  setLoginDate(dashdata.lastLoginDate);
  }

  const getLastLogin = () => {
    ////////
    const pdi = localStorage.getItem("userdata"),
      //let pdi = Cookies.get('userdata_cookie'),
      proDataInfo = JSON.parse(pdi);
    let data = {
      user_id: proDataInfo.id
    }
    axios.post(apiCalling.identityManagement + 'server.php/api/getLastLoginLogoutByUserId', data).then(result => {
      console.log(result);
      let data1 = result.data.data[0];
      setLoginDate(data1.last_access_date_time)


    }).catch(error => {
      console.log(error);
    });

  }


  const checkusers = () => {
    const dash = localStorage.getItem("logindata"),
      // let dash = Cookies.get('logindata_cookie'),
      dashdata = JSON.parse(dash);
    // let len = Object.keys(dashdata).length
    try {
      if (dashdata == null || dashdata === undefined) {
        props.history.push({ pathname: "/" });
      } else {
        console.log()
        setemail(dashdata.email);
        setPropan(dashdata.pan);
        props.history.push({ pathname: "/ProfileSteper" });
      }
    } catch {
      console.log("error")
    }
  };

  const logoutuser = () => {
    //    ////////
    // get pan from logindtata
    const loggedInUserdata = localStorage.getItem("logindata");
    //let loggedInUserdata = Cookies.get('logindata_cookie');
    let userdata = JSON.parse(loggedInUserdata);
    // get otp from otp data
    const checkotp = localStorage.getItem("otps");
    //let checkotp = Cookies.get('otps_cookie');
    let otps = JSON.parse(checkotp);
    const loggedUserData = localStorage.getItem("userdata");
    //let loggedUserData = Cookies.get('userdata_cookie');

    let userdatadetails = JSON.parse(loggedUserData);
    let user_logout_post_data = {
      otp: otps.otp,
      //lastLoginDate: userdata.lastLoginDate, 
    }
    // console.log(userdatadetails.pan);
    // if (userdata.pan == userdatadetails.pan) {
    //   console.log("data match");
    // } else {
    // }

    ////////
    axios.post(apiCalling.identityManagement + 'server.php/api/updateLogoutTime', user_logout_post_data).then((result) => {
      console.log(result);
      if (result.data.status === true) { }
    }).catch(error => {
    //  console.log("hello");
      ErrorCatch(error, 'post', user_logout_post_data);
      console.log(error)
    })
    localStorage.removeItem("logindata");
    localStorage.removeItem("userdata");
    props.history.push({ pathname: "/" });
  };

  // const opendrawer = (open) => {
  //   setdrawopen(open);
  // };

  const loggedUserData = JSON.parse(localStorage.getItem("userdata"));

  // const getStepContent = stepIndex => {
  //   ////////
  //   switch (stepIndex) {
  //     case 0:
  //       return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
  //     case 1:
  //       return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
  //     case 2:
  //       return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
  //     case 3:
  //       return (
  //         <LetsGetStarted handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack}
  //           NewPromo={openPromo} SidePanelByLoan={(step,loantype,userid) => { getStepContentUser(step,loantype,userid) }}  />
  //       );
  //     case 4:
  //       return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
  //     case 5:
  //       return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
  //     case 6:
  //       return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
  //     case 7:
  //       return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
  //     case 8:
  //       return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
  //     // case 9:
  //     //   return <AssetLAP handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
  //     case 9:
  //       return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
  //     case 10:
  //       return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
  //     case 11:
  //       return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
  //     case 12:
  //       return <FatCheck2 handleNext={handleNext} handleBack={handleBack} />;
  //     case 13:
  //       return <ApplicationFinScoring2 handleNext={handleNext} handleBack={handleBack} />;
  //     case 14:
  //       return <ApplicationLoanEligibility handleBack={handleBack} />;
  //     case 15:
  //       return <Status handleBack={handleBack} />;
  //     default:
  //       throw new Error("Unknown step");
  //   }
  // };

  let loantypeLocal = "";
  let useridLocal = '';

 

  const getStepContentUser = (stepIndex, loantype,userid) => {
    //
    console.log("stepper", stepIndex)
    loantypeLocal = loantype;
    useridLocal = userid;
    //  setletgetData({
    //     ...letgetData,
    //     loantypeLocal:loantype,
    //   });

    //////
    //setLoanTypeId(loantype);
    let localData ={
      loanid:"",
      userid:""
    };
    if(localStorage.getItem('stepperdata')){
      let data1 = JSON.parse(localStorage.getItem('stepperdata'));
      localData.loanid = data1.loantypeid;
      localData.userid = data1.id;
    }

    if (loggedUserData.role_id == 1 || loggedUserData.role_id == 3 || loggedUserData.role_id == 6 || loggedUserData.role_id == 5) {
      switch (stepIndex) {
        case 0:
          return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
        case 1:
          return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
        case 2:
          return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
        case 3:
          return (
            <LetsGetStarted handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack}
              NewPromo={openPromo} SidePanelByLoan={(step,loantype,userid) => { getStepContentUser(step,loantype,userid) }} />
          );
        case 4:
          return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
        case 5:
          return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 6:
          return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 7:
          return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 8:
          return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 17:
          return <AssetLAP handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 9:
          return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
        case 10:
          return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
        case 11:
          return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
        case 12:
          return <FatCheck2 handleNext={handleNext} handleBack={handleBack} />;
        case 13:
          return <ApplicationFinScoring2 handleNext={handleNext} handleBack={handleBack} />;
        case 14:
          return <ApplicationLoanEligibility handleBack={handleBack} />;
        case 15:
          return <Status handleBack={handleBack} />;
        default:
          throw new Error("Unknown step");
      }

    } else if (loggedUserData.role_id == 2 && ( localData.loanid  == 12 || localData.loanid  == 7)) {
      //////
     

      switch (stepIndex) {
        case 0:
          return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
        case 1:
          return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
        case 2:
          return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
        case 3:
          return (<LetsGetStarted handleNext={handleNext} handleCount={(item, roleid) => getAllPagesDataCount(item, roleid)} handleBack={handleBack} NewPromo={openPromo} SidePanelByRole={getAllPagesDataCount} SidePanelApi={getAllPagesDataCount} SidePanelByLoan={(step,loantype,userid) => { getStepContentUser(step,loantype,userid) }}  />);
        case 4:
          return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
        case 5:
          return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 6:
          return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 7:
          return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 8:
          return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 9:
          return <AssetLAP handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 10:
          return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
        case 11:
          return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
        case 12:
          return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
        // case 13:
        //   return <FatCheck2 handleNext={handleNext} handleBack={handleBack} />;
        // case 14:
        //   return <ApplicationFinScoring2 handleNext={handleNext} handleBack={handleBack} />;
        case 15:
          return <ApplicationLoanEligibility handleBack={handleBack} />;
        case 16:
          return <Status handleBack={handleBack} />;
        default:
          throw new Error("Unknown step");
      }
    } else if (loggedUserData.role_id == 2) {

      switch (stepIndex) {
        case 0:
          return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
        case 1:
          return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
        case 2:
          return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
        case 3:
          return (<LetsGetStarted handleNext={handleNext} handleCount={(item, roleid) => getAllPagesDataCount(item, roleid)} handleBack={handleBack} NewPromo={openPromo} SidePanelByRole={getAllPagesDataCount} SidePanelApi={getAllPagesDataCount} SidePanelByLoan={(step,loantype,userid) => { getStepContentUser(step,loantype,userid) }}  />);
        case 4:
          return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
        case 5:
          return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 6:
          return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 7:
          return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 8:
          return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
        case 9:
          return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
        case 10:
          return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
        case 11:
          return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
      
        case 12:
          return <ApplicationLoanEligibility handleBack={handleBack} />;
        case 13:
          return <Status handleBack={handleBack} />;
        default:
          throw new Error("Unknown step");

      }
    }

  //////
  getAllPagesDataCount(userid,loantype);

  };

  const getStepContentUserForHlAndLap = stepIndex => {
    ////////
    switch (stepIndex) {
      case 0:
        return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
      case 1:
        return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
      case 2:
        return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
      case 3:
        return (<LetsGetStarted handleNext={handleNext} handleCount={(item, roleid) => getAllPagesDataCount(item, roleid)} handleBack={handleBack} NewPromo={openPromo} SidePanelByRole={getAllPagesDataCount} SidePanelApi={getAllPagesDataCount} />);
      case 4:
        return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
      case 5:
        return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 6:
        return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 7:
        return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 8:
        return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 9:
        return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
      case 10:
        return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
      case 11:
        return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
      case 12:
        return <FatCheck2 handleNext={handleNext} handleBack={handleBack} />;
      case 13:
        return <ApplicationFinScoring2 handleNext={handleNext} handleBack={handleBack} />;
      case 14:
        return <ApplicationLoanEligibility handleBack={handleBack} />;
      case 15:
        return <Status handleBack={handleBack} />;
      default:
        throw new Error("Unknown step");
    }
  };


  const getStepContentDsa = stepIndex => {
    ////////
    switch (stepIndex) {
      case 0:
        return <Profile handleNext={handleNext} handleNextLetStart={handleNextLetStart} />;
      case 1:
        return <BusinessInfo handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleNexts={handleNexts} handleBack={handleBack} handleNextForBusinesss={handleNextForBusinesss} BusEditId={busIdd} NewForm={openNewBus} BusDoc={busDoc} />;
      case 2:
        return <ShareHolder handleNext={handleNext} handleCount={getAllPagesDataCount} handleNexts={handleNexts} handleBack={handleBack} PromoterID={promoId} NewPromo={openPromo} PromoDoc={promoterDoc} />;
      case 3:
        return (<LetsGetStarted handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} NewPromo={openPromo} SidePanelByRole={getAllPagesDataCount} SidePanelApi={getAllPagesDataCount} />);
      case 4:
        return <Finance handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} checkforrequest={handleNextLetStartValue} />;
      case 5:
        return <Banking handlecheck={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 6:
        return <Loan handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 7:
        return <Working handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 8:
        return <GST handleNext={handleNext} handleCount={(item) => getAllPagesDataCount(item)} handleBack={handleBack} />;
      case 9:
        return <DocsCollected handleNext={handleNext} handleBack={handleBack} />;
      case 10:
        return <BookAppointment handleNext={handleNext} handleBack={handleBack} />;
      case 11:
        return <ApplicationSummaryView handleNext={handleNext} handleCount={getAllPagesDataCount} handleBack={handleBack} />;
      case 12:
        return <Status handleBack={handleBack} />;
      default:
        throw new Error("Unknown step");
    }
  };
 // console.log("activeStep ====", activeStep);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  const [letgetData,setletgetData] =React.useState({
    loantypeLocal:"",
    useridLocal:""

  });
  const [loanTypeId,setLoanTypeId] = React.useState();
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const [hsb, setHsb] = React.useState(true);
  const handleSideBar = () => {
    if (hsb == true) {
      setHsb(false);
      console.log(hsb);
    } else {
      setHsb(true);
      console.log(hsb);
    }
  }

  const handleStep = (step) => () => {
    handleComplete();
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    //  handleNext();
  };
  ////////

 
  return (

    <Row>
      {(activeStep > 3 || (activeStep == 0 && loggedUserData.role_id == 7)) == true ? (
        <>
          {/* <Col md={{ span: 2 }}> */}
          <div className={!opendrawer ? "col-md-1" : "col-md-2"} >
            /* <CssBaseline /> */
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: clsx(classes.drawerPaper, !opendrawer && classes.drawerPaperClose),
              }}
              open={opendrawer}
            >

              <div>
                <div className={classes.toolbarIcon} >
                  <img alt ="img" src={logo} width='200px' height="auto" style={{ padding: '10px', marginRight: '70px' }} />
                  <IconButton onClick={handleDrawerClose} style={{ position: "absolute", right: "2px" }}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
              </div>

              <Divider
                style={{ height: 2, backgroundColor: "rgb(114, 84, 1)" }}
              />
              <List>
                <Stepper nonLinear activeStep={activeStep - 2} orientation="vertical">
                  {numberOfData.map((label, index) => (


                    index != 1 && index != 2 && (
                      <Step key={label.menu}>
                        {    // <StepLabel StepIconComponent={QontoStepIcon}>
                          //   {label}
                          // </StepLabel>
                        }
                        {label.menu == 'Colateral Details'? (<StepButton onClick={handleStep(17)} completed={label.status}>
                          <StepLabel StepIconComponent={index == 0 ? homeIcon : QontoStepIcon}>
                            <span style={{ fontSize: "16px" }}>{label.menu}</span>
                          </StepLabel>
                        </StepButton>): (<StepButton onClick={handleStep(index)} completed={label.status}>
                          <StepLabel StepIconComponent={index == 0 ? homeIcon : QontoStepIcon}>
                            <span style={{ fontSize: "16px" }}>{label.menu}</span>
                          </StepLabel>
                        </StepButton>)}
                        
                      </Step>
                    )

                    // index != 1 && index != 2 && (
                    //   <Step key={label.menu}>
                    //     {    // <StepLabel StepIconComponent={QontoStepIcon}>
                    //       //   {label}
                    //       // </StepLabel>
                    //     }
                    //     <StepButton onClick={handleStep(index)} completed={label.status}>
                    //       <StepLabel StepIconComponent={index == 0 ? homeIcon : QontoStepIcon}>
                    //         <span style={{ fontSize: "16px" }}>{label.menu}</span>
                    //       </StepLabel>
                    //     </StepButton>
                    //   </Step>
                    // )

                  ))}
                </Stepper>
              </List>

            </Drawer>
            {/* </Col> */}
          </div>


          {/* <Col md={{ span: 10 }}> */}
          <div className={!opendrawer ? "col-md-11 " + classes.colLeft : "col-md-10 " + classes.padLeft}>
            <div className="header-right-part">
              <div
                style={{
                  backgroundColor: "#FFF",
                  width: "100%",
                  position: "fixed",
                  zIndex: 11,
                  borderBottom: "2px solid #7254a3",
                  left: "0px",
                }}
              >


                <div className="notification_icon_part" >
                  <div className={clsx(classes.appBar, opendrawer && classes.appBarShift)} style={{ position: "absolute", left: "5%" }} >
                    <Toolbar className={classes.toolbar}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, opendrawer && classes.menuButtonHidden)}
                      >
                        <MenuIcon />
                      </IconButton>

                    </Toolbar>
                  </div>
                  <img alt ="img" src={profile} style={{ width: "48px", margin: "0% 3% 0% 1%", padding: "8px 0px" }} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle} />
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} className="profileInfo" id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              {/* <p className="profileDate"><b>Last Login : </b>&nbsp;&nbsp;{moment(loginDate).format('DD MMMM YYYY, h:mm:ss a')}</p> */}
                              <table className="profiletable">
                                <tr>
                                  <td><b>Last Login </b></td>
                                  <td>{moment(loginDate).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                </tr>
                                <tr>
                                  <td><b>PAN </b></td>
                                  <td>{profilePan}</td>
                                </tr>
                                <tr>
                                  <td><b>Mobile </b></td>
                                  <td>{profileMobile}</td>
                                </tr>
                                <tr>
                                  <td style={{ paddingBottom: "10px" }}><b>Email ID </b></td>
                                  <td style={{ paddingBottom: "10px" }}>{profileEmail}</td>
                                </tr>
                              </table>
                              <MenuItem><span style={{ fontSize: "13px", color: "#8e8e8e" }}>Manage Account</span>&nbsp;&nbsp;<span style={{ color: "#ff0000" }}><i class="fa fa-caret-right" aria-hidden="true"></i></span></MenuItem>
                              <p><span style={{ fontSize: "13px", color: "#8e8e8e" }}>Are you done for today</span>&nbsp;&nbsp;<span style={{ color: "#ff0000", fontWeight: "600", cursor: "pointer", padding: "0% 10%" }} onClick={() => logoutuser()}>Logout</span></p>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>

                  {/* <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                  <img src={notification} alt ="img" style={{ width: "30px" }} />

                </div>
              </div>

              <div style={{ paddingTop: "15px", zIndex: 0 }}>
                {activeStep === steps.length ? (
                  <div>
                    <Typography>do what u want to do</Typography>
                  </div>
                ) : (
                  <div style={{ background: "#fff" }}>

                    {
                      (() => {
                        //////
                        console.log("first");
                        let localData ={
                          loanid:"",
                          userid:""
                        };
                        if(localStorage.getItem('stepperdata')){
                          let data1 = JSON.parse(localStorage.getItem('stepperdata'));
                          localData.loanid = data1.loantypeid;
                          localData.userid = data1.id;


                        }
                        console.log(activeStep, localData.loanid, localData.userid)

                        switch (2) {
                          //   case 1: return getStepContent(activeStep);
                          case 2: return getStepContentUser(activeStep,localData.loanid,localData.userid);
                          /// case 3: return getStepContent(activeStep);
                          // case 6: return getStepContentDsa(activeStep);
                          default: return null;
                        }
                      })()
                    }
                    <Container>
                      <Row className="justify-content-md-center pt-3 pb-3">
                        <Col md={{ span: 10 }}></Col>
                      </Row>
                    </Container>
                  </div>
                )}
              </div>
            </div>
            {/* </Col> */}
          </div>
        </>
      ) : null}

      {(activeStep < 4 && (loggedUserData.role_id !== 7)) === true ? (
        <>
          <Col md={{ span: 12 }}>
            <CssBaseline />
            <div className="header-right-part">
              <div
                style={{
                  backgroundColor: "#FFF",
                  width: "100%",
                  position: "fixed",
                  zIndex: 11,
                  borderBottom: "2px solid #7254a3",
                  left: "0px",
                }}
              >
                <Row>
                  <Col md={{ span: 2 }}>
                    <img className="logoImg"
                      src={logo}
                      width="100%"
                      height="auto"
                      alt="imge"
                    />
                  </Col>
                  <Col md={{ span: 10 }}>
                    <div className="notification_icon_part">
                      {/* {email} */}
                      {/* onClick={() => logoutuser()} */}
                      {/* <IconButton ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <Avatar alt="Remy Sharp" src={user} />
                      </IconButton> */}
                      <img src={profile} alt="ime" style={{ width: "5%", margin: "0% 3% 0% 1%", padding: "5px" }} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle} />
                      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: "auto", height: "100%" }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} className="profileInfo" id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {/* <p className="profileDate"><b>Last Login : </b>&nbsp;&nbsp;{moment(loginDate).format('DD MMMM YYYY, h:mm:ss a')}</p> */}
                                  {/* <p>PAN : &nbsp;&nbsp;{profilePan}</p>
                                  <p>Mobile : &nbsp;&nbsp;{profileMobile}</p>
                                  <p style={{ paddingBottom: "10%", borderBottom: "1px solid #949494" }}>Email ID : &nbsp;&nbsp;{profileEmail}</p> */}
                                  <table className="profiletable">
                                    <tr>
                                      <td><b>Last Login </b></td>
                                      <td>{moment(loginDate).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                    </tr>
                                    <tr>
                                      <td><b>PAN </b></td>
                                      <td>{profilePan}</td>
                                    </tr>
                                    <tr>
                                      <td><b>Mobile </b></td>
                                      <td>{profileMobile}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ paddingBottom: "10px" }}><b>Email ID </b></td>
                                      <td style={{ paddingBottom: "10px" }}>{profileEmail}</td>
                                    </tr>
                                  </table>
                                  <MenuItem><span style={{ fontSize: "13px", color: "#8e8e8e" }}>Manage Account</span>&nbsp;&nbsp;<span style={{ color: "#ff0000" }}><i class="fa fa-caret-right" aria-hidden="true"></i></span></MenuItem>
                                  <p><span style={{ fontSize: "13px", color: "#8e8e8e" }}>Are you done for today</span>&nbsp;&nbsp;<span style={{ color: "#ff0000", fontWeight: "600", cursor: "pointer", padding: "0% 10%" }} onClick={() => logoutuser()}>Logout</span></p>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>

                      {/* <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton> */}
                      <img src={notification} alt ="img" style={{ width: "30px" }} />
                    </div>
                  </Col>
                </Row>

              </div>

              {/* <div style={{ borderTop: "32px #fff solid", marginTop: 50, zIndex: 0 }}> */}
              
              <div style={{ paddingTop: "15px", zIndex: 0 }}>
                {activeStep === steps.length ? (
                  <div>
                    <Typography>do what u want to do</Typography>
                  </div>
                ) : (
                  <div style={{ background: "#fff" }}>
                    {
                      (() => {
                        //////
                  //      console.log("sec");
                        let localData ={
                          loanid:"",
                          userid:""
                        };
                        if(localStorage.getItem('stepperdata')){
                          let data1 = JSON.parse(localStorage.getItem('stepperdata'));
                          localData.loanid = data1.loantypeid;
                          localData.userid = data1.id;
                        }
                        switch (2) {

                      ///    case 1: return getStepContent(activeStep);

                          case 2: return getStepContentUser(activeStep,localData.loanid,localData.userid);
                        ///  case 3: return getStepContent(activeStep);
                         // case 6: return getStepContentDsa(activeStep);
                          default: return null;
                        }
                      })()
                    }
                    <Container>
                      <Row className="justify-content-md-center pt-3 pb-3">
                        <Col md={{ span: 10 }}></Col>
                      </Row>
                    </Container>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </>
      ) : null}
    </Row>
  );
};
export default ProfileStepper;

export const QontoStepIcon = props => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

export const homeIcon = () => {
  return (
    <><i class="fas fa-home" style={{ fontSize: "18px" }}></i></>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
