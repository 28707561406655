import React, { useEffect } from "react";
import "./LoaderCss.css";
import "../../index.css";
import Loader from "react-loader-spinner";

import { LoaderColor } from '../../shared/constants';



export default function RegisterationLoader(props) {
  useEffect(() => {

  }, [])

  setTimeout(
    () => {
      props.history.push({ pathname: "/" })
    }
    , 4000);

  return (
    <div className="login-form1">
      <div className="thank-you-pop">
        <h1>Thank You!</h1>
        <p> Thank you for registering with Aagey.com.</p>
        <p>Your credentials have been emailed to your for further reference. Please login to proceed further </p>

        <Loader
          type={LoaderColor.type}
          color={LoaderColor.color}
          height={100}
          width={100}
          timeout={4000} //3 secs
        />




      </div>
    </div>
  );
}
