import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AppsIcon from '@material-ui/icons/Apps'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import LayersIcon from '@material-ui/icons/Layers'
import AssignmentIcon from '@material-ui/icons/Assignment'

import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import EntityType from '../EntityType'
import IndustryGroup from '../IndustryGroup'
import IndustryNames from '../IndustryNames'
import IndustryType from '../IndustryType'
import IndustryMapping from '../IndustryMapping'
import Nationality from '../Nationality'
import AssetCategoryLinkForm from '../AssetCategoryLinkForm'
import AssetUsageTypeLinkForms from '../AssetUsageTypeLinkForms'
import AssetSubTypeLinkForm from '../AssetSubTypeLinkForm'
import StateLinkForm from '../StateLinkForm'
import CityLinkForm from '../CityLinkForm'
import BanksLinkForm from '../BanksLinkForm'
import EmployeeOnboard from '../EmployeeOnboard'
import GeneralSettings from '../GeneralSettings'
import FatQuestions from '../FatQuestions'
import LoanAccounts from '../LoanAccounts'
import BankStaffOnboarding from '../BankStaffOnboarding'

import IdentityManagement from '../IdentityManagement'

import ApplicationReport from '../ApplicationReport'
import PinCodes from '../PinCodes'
import EducationalQualification from '../EducationalQualification'
import AddressProof from '../AddressProof'
import PropertyType from '../PropertyType'
import PropertyUsage from '../PropertyUsage'
import PropertyMapping from '../PropertyMapping'
import TypeOfConstruction from '../TypeOfConstruction'
import TypeOfConstructionAssetMapping from '../TypeOfConstructionAssetMapping'
import FinanceMasterPage from '../FinanceMasterPage'
import UserCreation from '../UserCreation'
import BankerMaster from '../BankerMaster'
import DocumentMaster from '../DocumentMaster'
import DatabaseConfiguration from '../DatabaseConfiguration'
import SMSConfiguration from '../SMSConfiguration'
import EMAILConfiguration from '../EMAILConfiguration'
import KARZAThirdPartyConfiguration from '../KARZAThirdPartyConfiguration'
import EQUIFAXThirdPartyConfiguration from '../EQUIFAXThirdPartyConfiguration'
import CRIFThirdPartyConfiguration from '../CRIFThirdPartyConfiguration'
import PERFIOSThirdPartyConfiguration from '../PERFIOSThirdPartyConfiguration'
import ThirdPartyReportData from '../ThirdPartyReportData'
import BankingAndProductMaster from '../BankingAndProductMaster'
import PreviousMonthConfigration from '../PreviousMonthConfigration';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    //paddingLeft: theme.spacing(4),
  }
}))

export default function AdminMainListItem (props) {
  const showdata = props.checkdata
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const [openReport, setOpenReport] = React.useState(false)

  const handleClickReport = () => {
    setOpenReport(!openReport)
  }
  const [openConfig, setOpenConfig] = React.useState(false)

  const handleClickConfig = () => {
    setOpenConfig(!openConfig)
  }

  const [openThirdParty, setOpenThirdParty] = React.useState(false)

  const handleClickThirdParty = () => {
    setOpenThirdParty(!openThirdParty)
  }

  const [openThirdPartyReport, setOpenThirdPartyReport] = React.useState(false)

  const handleClickThirdPartyReport = () => {
    setOpenThirdPartyReport(!openThirdPartyReport)
  }

  const [openSettings, setOpenSettings] = React.useState(false)

  const handleClickSettings = () => {
    setOpenSettings(!openSettings)
  }

  return (
    <List
      component='nav'
      aria-labelledby='nested-list-subheader1'
      subheader={
        <ListSubheader component='div' id='nested-list-subheader1'>
          Administration Options
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button onClick={() => showdata(<UserCreation />)}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary='User Management' />
      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary='Master Data Management' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List>
          <ListItem
            button
            onClick={() => showdata(<BankingAndProductMaster />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Banking Values Master'
            />
          </ListItem>

          <ListItem button onClick={() => showdata(<EntityType />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Entity Type'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<DocumentMaster />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Document Master'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<UserCreation />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='User Creation'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<IndustryType />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Industry Type'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<IndustryGroup />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>

            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Industry Group'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<IndustryNames />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Industry Names'
            />
          </ListItem>
          {/* <ListItem button onClick={() => showdata(<IndustryMapping />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText style={{paddingLeft: "5px"}} primary="Industry Mapping" />
            </ListItem> */}
          <ListItem button onClick={() => showdata(<Nationality />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Nationality'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<AssetCategoryLinkForm />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Collateral Category Link Form'
            />
          </ListItem>
          <ListItem
            button
            onClick={() => showdata(<AssetUsageTypeLinkForms />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Collateral Usage Type Link Forms'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<AssetSubTypeLinkForm />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Collateral SubType Link Form'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<StateLinkForm />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='State Link Form'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<CityLinkForm />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='City Link Form'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<PinCodes />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText style={{ paddingLeft: '5px' }} primary='PinCodes' />
          </ListItem>
          <ListItem button onClick={() => showdata(<BanksLinkForm />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Banks Link Form'
            />
          </ListItem>
          {/* <ListItem button onClick={() => showdata(<EmployeeOnboard />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText style={{paddingLeft: "5px"}} primary="Employee Onboard" />
            </ListItem> */}
          {/* <ListItem button onClick={() => showdata(<LoanAccounts />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText style={{paddingLeft: "5px"}} primary="Loan Accounts" />
            </ListItem> */}
          {/* <ListItem button onClick={() => showdata(<BankStaffOnboarding />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText style={{paddingLeft: "5px"}} primary="Bank Staff Onboarding" />
            </ListItem> */}

          {/* <ListItem button onClick={() => showdata(<IdentityManagement />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText style={{paddingLeft: "5px"}} primary="Identity Management" />
            </ListItem> */}
          <ListItem
            button
            onClick={() => showdata(<EducationalQualification />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Educational Qualification'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<AddressProof />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Address Proof'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<PropertyType />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Property Type'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<PropertyUsage />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Property Usage'
            />
          </ListItem>
          {/* <ListItem button onClick={() => showdata(<PropertyMapping />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>                   
                <ListItemText style={{paddingLeft: "5px"}} primary=" Property Mapping" />
            </ListItem> */}

            <ListItem button onClick={() => showdata(< PreviousMonthConfigration/>)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>                   
                <ListItemText style={{paddingLeft: "5px"}} primary="Pervious Month Configration" />
            </ListItem>

          <ListItem button onClick={() => showdata(<TypeOfConstruction />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Type Of Construction'
            />
          </ListItem>
          {/* <ListItem button onClick={() => showdata(<TypeOfConstructionAssetMapping />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>                   
                <ListItemText style={{paddingLeft: "5px"}} primary="Type Of Construction Asset Mapping" />
            </ListItem> */}
          <ListItem button onClick={() => showdata(<FinanceMasterPage />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Finance Master Page'
            />
          </ListItem>

          {/* <ListItem button onClick={() => showdata(<UserCreation />)}>
                <ListItemIcon style={{minWidth: "35px", paddingLeft: "20px"}}>
                    <DashboardIcon />
                </ListItemIcon>                
                <ListItemText style={{paddingLeft: "5px"}} primary="User Creation" />
            </ListItem> */}
          <ListItem button onClick={() => showdata(<GeneralSettings />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary='General Settings'
              style={{ paddingLeft: '5px' }}
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<FatQuestions />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='FAT Questions'
            />
          </ListItem>
        </List>
      </Collapse>

      {/* <ListItem button onClick={handleClickReport}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="User Report" />
                      {openReport ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openReport} timeout="auto" unmountOnExit>
                      <List >
                      <ListItem button onClick={() => showdata(<ApplicationReport />)}>
                              <ListItemIcon>
                                  <DashboardIcon />
                              </ListItemIcon>
                              <ListItemText primary="ApplicationReport" />
                          </ListItem>
              
                          </List>
                          </Collapse> */}

      <ListItem button onClick={handleClickThirdPartyReport}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary='Reports' />
        {openThirdPartyReport ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openThirdPartyReport} timeout='auto' unmountOnExit>
        <List>
          <ListItem button onClick={() => showdata(<ThirdPartyReportData />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Third Party Report Data'
            />
          </ListItem>
          <ListItem button onClick={() => showdata(<ApplicationReport />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Application Report'
            />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleClickConfig}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary='Configuration' />
        {openConfig ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openConfig} timeout='auto' unmountOnExit>
        <List>
          <ListItem button onClick={() => showdata(<DatabaseConfiguration />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Database Configuration'
            />
          </ListItem>

          <ListItem button onClick={() => showdata(<EMAILConfiguration />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='Email Configuration'
            />
          </ListItem>

          <ListItem button onClick={() => showdata(<SMSConfiguration />)}>
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='SMS COnfiguration'
            />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleClickThirdParty}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary='ThirdParty Configuration' />
        {openThirdParty ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openThirdParty} timeout='auto' unmountOnExit>
        <List>
          <ListItem
            button
            onClick={() => showdata(<KARZAThirdPartyConfiguration />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='KARZA Third Party'
            />
          </ListItem>

          <ListItem
            button
            onClick={() => showdata(<CRIFThirdPartyConfiguration />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='CRIF ThirdParty Configuration'
            />
          </ListItem>

          <ListItem
            button
            onClick={() => showdata(<EQUIFAXThirdPartyConfiguration />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='EQUIFAX ThirdParty Configuration'
            />
          </ListItem>

          <ListItem
            button
            onClick={() => showdata(<PERFIOSThirdPartyConfiguration />)}
          >
            <ListItemIcon style={{ minWidth: '35px', paddingLeft: '20px' }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: '5px' }}
              primary='PERFIOS Third Party'
            />
          </ListItem>
        </List>
      </Collapse>

      {/*<ListItem button onClick={handleClickSettings}>
                        <ListItemIcon>
                        </ListItemIcon>*/}
      {/*<ListItemText primary="GeneralSettings" />*/}
      {/* {openSettings ? <ExpandLess /> : <ExpandMore />} */}
      {/* </ListItem>*/}
    </List>
  )
}
