import React, { useState } from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStyles } from "./style";
import Typography from "../../base_components/Typography";
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Reset from '../../image/buttons/reset.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import grayback from '../../image/buttons/grayback.png';
import grayreset from '../../image/buttons/grayreset.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';

const ApplicationFinScoring = (props) => {
  const classes = useStyles();

  const table_header = {
    fontWeight: "600",
    fontSize: "100%"
  };
  const table_center_header = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  };
  const table_center_body = {
    textAlign: "center"
  };
  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
    width: "1%"
  }


  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    checkIfRequest();
  }, []);
  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }

  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));

  const handledata = () => {
    props.handleBack();
  };
  const SaveasDraft = () => {
    props.handleNext();
  }

  return (
    <Container maxWidth="lg">
      <div className="mini-container mg_btm_set">
        <Row>
          <Col md={{ span: 12 }}>
            <div className="content-header">
              <Row>
                {/* { requestDetails && (
                <Col md={{ span: 12 }} style ={{marginBottom:"3%",textAlign:"center"}}>
                  <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                </Col>
              )} */}
              </Row>
              <Row>
                <Col md={{ span: 12 }} className="mainheader1">
                  <Typography text="Application Financial Health Check - Part B" styleType="C" />
                </Col>
                <Col md={{ span: 12 }} className="subHeader1">
                  <table className="applicationTableDetails1">
                    <tr>
                      {/* <td style={{ padding: "0px 5px" }}>Type</td>
                            <td>:</td> */}
                      {localData.request_type_id === 0 && (
                        <td>Loan Application</td>)}
                      {localData.request_type_id === 1 && (
                        <td>Check Eligibility</td>)}
                      <td>&nbsp;|&nbsp;</td>
                      <td>Application Number</td>
                      <td>:</td>
                      <td>{localData.reference_id}</td>
                      <td>&nbsp;|&nbsp;</td>
                      <td>Loan Type</td>
                      <td>:</td>
                      <td>{localData.loan_type}</td>
                    </tr>
                  </table>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={table_slNo}>#</TableCell>
                  <TableCell style={table_header}>Financial Scoring Prameters</TableCell>
                  <TableCell style={table_center_header}>Value FY 2020-2021</TableCell>
                  <TableCell style={table_center_header}>Value FY 2019-2020</TableCell>
                  <TableCell style={table_center_header}>Value Change</TableCell>
                  <TableCell style={table_center_header}>Health Indicator&nbsp;<i className="fas fa-exclamation-circle" style={{ fontSize: '15px', color: 'blue' }}></i></TableCell>
                  <TableCell style={table_center_header}>Questionnaire&nbsp;<i className="fas fa-external-link-alt" style={{ fontSize: '13px', color: '#a3a3c4' }}></i></TableCell>
                  <TableCell style={table_header}>Assessed By</TableCell>
                  <TableCell style={table_header}>Assessor Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow hover className="datable">
                  <TableCell style={table_header} colSpan="9">Key Financial Reporting</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover className="datable">
                  <TableCell>1</TableCell>
                  <TableCell>Total Operating Income</TableCell>
                  <TableCell style={table_center_body}>1.54</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'red' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-times-circle" style={{ fontSize: '15px', color: 'red' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>2</TableCell>
                  <TableCell>Gross Profit</TableCell>
                  <TableCell style={table_center_body}>10</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>3</TableCell>
                  <TableCell>.....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-minus-circle" style={{ fontSize: '15px', color: 'black' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>4</TableCell>
                  <TableCell>.....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>5</TableCell>
                  <TableCell>.....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
              <TableHead>
                <TableRow hover className="datable">
                  <TableCell style={table_header} colSpan="9">Growth Prospects</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover className="datable">
                  <TableCell>1</TableCell>
                  <TableCell>Turnover Growth</TableCell>
                  <TableCell style={table_center_body}>1.32</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-times-circle" style={{ fontSize: '15px', color: 'red' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>2</TableCell>
                  <TableCell>Banking to Turnover Growth</TableCell>
                  <TableCell style={table_center_body}>0.54</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'red' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>3</TableCell>
                  <TableCell>VAT to Turnover Growth</TableCell>
                  <TableCell style={table_center_body}>0.56</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-minus-circle" style={{ fontSize: '15px', color: 'black' }}></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>4</TableCell>
                  <TableCell>....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-circle" style={{ fontSize: '15px', color: 'red' }}></i></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow hover className="datable">
                  <TableCell>5</TableCell>
                  <TableCell>.....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }}></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
              <TableHead>
                <TableRow hover className="datable">
                  <TableCell style={table_header} colSpan="9">Profitibility / Efficiency Management</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover className="datable">
                  <TableCell>1</TableCell>
                  <TableCell>.....</TableCell>
                  <TableCell style={table_center_body}>-</TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}></TableCell>
                  <TableCell style={table_center_body}><i className="fas fa-times-circle" style={{ fontSize: '15px', color: 'red' }} ></i></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className='btn_row_fixd'>
            <Row>
              <Col md={{ span: 10 }}></Col>
              <Col style={{ padding: "0%" }}></Col>
              {/* className="btnspace" */}
              <Col style={{ padding: "0%" }}>
                {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                  <img className='navbutton' src={Back} onClick={handledata} />
                </Tooltip> */}
                <span className="image">
                  <img className='navbutton' src={Back} onClick={handledata} />
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navgraybutton' src={grayback} onClick={handledata} />
                  </Tooltip>
                </span>
              </Col>
              <Col style={{ padding: "0%" }}>
                {/* <Tooltip title="Reset" arrow>
                  <img className='navbutton' src={Reset} />
                </Tooltip> */}
                <span className="image">
                  <img className='navbutton' src={Reset} />
                  <Tooltip title="Reset" arrow>
                    <img className='navgraybutton' src={grayreset} />
                  </Tooltip>
                </span>
              </Col>
              {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save & Next" arrow>
                    <img className='navbutton' src={SaveNext} />
                  </Tooltip>
                </Col> */}
              <Col style={{ padding: "0%" }}>
                {/* <Tooltip title="Save as Draft & Next" arrow>
                  <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                </Tooltip> */}
                <span className="image">
                  <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                  <Tooltip title="Save & Next" arrow>
                    <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                  </Tooltip>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default ApplicationFinScoring;