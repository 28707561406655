import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, } from 'semantic-ui-react'
import Typography from '../../base_components/Typography';
import { apiCalling } from '../adminConstants';
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { StateSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import {
    mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import { TrendingUpRounded } from '@material-ui/icons';

export default function DatabaseConfiguration(props) {

    const classes = useStyles();

    const [table, settable] = React.useState(false);
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);
    const [showList, setShowList] = React.useState(true);
    const [loaderFlag, setLoaderFlag] = React.useState(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        //handleStateList();
        handleAllRecords();
        handleGetConfigurationById();
        
    }, []);



    const handleSubmiting = () => {
        props.handleNext();
    };

    const handledata = () => {

        props.handleBack();
    };


    const handleselectChange = (event) => {
        event.preventDefault();
        this.setState({
            showOption: true
        })
    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handlecheck();
    }

    let [initialValue, setInitialValue] = React.useState({
        username: "",
        password: "",
        ipaddress: "",
        id: "",
        
    })

    //not using
    const [StateList, setStateList] = React.useState([]);
    const handleStateList = async () => {
        const data = {
            enable: true
        };
        await axios
            .post(apiCalling.coderscorner + `server.php/api/getEntityTypes`, data).then((result) => {
                let empArray = [];
                result.data.data.map((item) => {
                    empArray.push({
                        entity: item.entity,
                        type: item.type,
                        enable: item.enable.toString(),
                        id: item.id,
                        
                    })
                })
                setShowWCAddForm(false);
                setShowList(true);
                //setLoaderFlag(false);
                console.log("EmpArray", empArray);
                setStateList(empArray);
            }).catch((error) => {
                console.log(error);
            });
    };

    const data = {
        // columns: [
        //     {
        //         label: "#",
        //         field: "slno",
        //         width: 150,
        //         attributes: {
        //             "aria-controls": "DataTable",
        //             "aria-label": "Name",
        //         },
        //     },
        //     {
        //         label: "configuration_name",
        //         field: "configuration_name",
        //         width: 270,
        //     },
            
        //     {
        //         label: "configuration_value",
        //         field: "configuration_value",
        //         width: 270,
        //     },
        //     {
        //         label: "ipaddress",
        //         field: "ipaddress",
        //         width: 270,
        //     },
        //     {
        //         label: "action",
        //         field: "action",
        //         width: 270,
        //     },

           
        // ],

        rows: StateList.map((values, i) => {
            return {
                slno: i + 1,
                configuration_name: values.configuration_name,
                configuration_value: values.configuration_value,
                ipaddress:values.ip_address,
                
            
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            
                         <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

    const handlesubmitform = (values, { resetForm }) => {
        handleNextClick(values);
        resetForm({
            username: "",
            password: "",
            ipaddress: "",
            id: "",
            
        })
    };
       // const checkuserloggeddata = localStorage.getItem("userdata");
    // let iduser = JSON.parse(checkuserloggeddata);
    const handleNextClick = (data) => {
        setShowWCAddForm(false);
       // setLoaderFlag(true);
        
        let wcData = {
            "id":"",
            
            "config": {
                "config_name": ["DataBase username", "DataBase password"],
                "config_value": [data.username, data.password]
                
            },
            "created_by" : 640,
            "ip_address": data.ipaddress,
            "flag":"D"
        }
        
        
        

        axios.post(apiCalling.coderscorner + 'server.php/api/saveDatabaseConfig', wcData).then((res) => {
            console.log(res.data);
           // console.log("saved successfully...!!!");
            setShowWCAddForm(false);
            setShowList(true);
            //handleStateList();
           handleAllRecords();
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    };

    const handleGetConfigurationById = async (date) => {
        
        const data = {
    "ip_address":apiCalling.handleIPConfig
}
        await axios.post(apiCalling.coderscorner + `server.php/api/getConfigurationByIpAddress`, data).then((result) => {
            console.log(result.data.data);
            let data1=result.data.data[0];
            let data2=result.data.data[1];

            setInitialValue({
                
                
                username: data1.configuration_value,
                password: data2.configuration_value,
                id: data1.id,
                ipaddress: data1.ip_address,
                enable: data1.enable ? "1" : "0"
                
            })
       })
    }
    const handleOpenWCAddForm = () => {
        setAddFormButtonValue("Submit");
        setShowWCAddForm(true);
        setShowList(false);
        setInitialValue({
            username: "",
            password: "",
            id: "",
            ipaddress: "",
            
        })
    }

    const handleEdit = (data) => {
        
        console.log(data);
        setShowWCAddForm(true);
        setAddFormButtonValue("Update");
        setShowList(false);
        setInitialValue({
            ...data,
            
            username: data.configuration_name,
            password: data.configuration_value,
            id: data.id,
            ipaddress: data.ip_address,
            enable: data.enable ? "1" : "0"
            
        })
    }

    const handleAllRecords= () =>{
        
        let data= {
                        "is_active":1,
                        "flag":"D"
        }
        axios.post(apiCalling.coderscorner + 'server.php/api/getAllConfiguration',data).then((res) => {
            if(res.data.data==true){
                let empArray = res.data.data;
                
                setShowWCAddForm(false);
                setShowList(true);
                //setLoaderFlag(false);
                console.log("EmpArray", empArray);
                setStateList(empArray);
            console.log(res)
            }
             
        })
    }
        

    const handleDelete = (values) => {
        
        console.log(values)
    
        let data = {
            "id":values.id
        }
        axios.post(apiCalling.coderscorner + 'server.php/api/deleteConfiguration', data).then((res) => {
            setShowWCAddForm(false);
            setShowList(true);
            handleAllRecords();
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    }

    return (
        <Container maxWidth="lg">
            <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>
                
                
                <Row>
                    <Col md={{ span: 12 }}>
                        
                            <Formik
                                enableReinitialize
                                initialValues={initialValue}
                                //validationSchema={StateSchema}
                                onSubmit={handlesubmitform}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    resetForm,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldTouched
                                    /* and other goodies */
                                }) => {

                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="content-header" style={{ position: 'unset' }}>
                                                <Row>
                                                    <Col md={{ span: 10 }} className="mainheader1">
                                                        <Typography text="Database" styleType="C" />
                                                    </Col>
                                                    <Col md={{ span: 2 }} className="mainHeaderBack">
                                                        {/* <div className="content-header-button ">
                                                            <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                                                        </div> */}
                                                        <span className="image">
                                                        <img className='backbutton navbutton' src={Back} onClick={() => {
                                                            resetForm();
                                                            setShowWCAddForm(false);
                                                            setShowList(true);
                                                        }} />
                                                        <Tooltip title="Back" arrow>
                                                            <img className='backbutton navgraybutton' src={grayback} onClick={() => {
                                                                resetForm();
                                                                setShowWCAddForm(false);
                                                                setShowList(true);
                                                            }} />
                                                        </Tooltip>
                                                        </span>
                                                    </Col>
                                                </Row>


                                                <Row style={{ marginTop: '30px' }}>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Username" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="username"
                                                                    label="Username"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.username &&
                                                                            touched.username &&
                                                                            errors.username
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.username ? classes.manorma : null,
                                                                    }}
                                                                    value={values.username}
                                                                    helperText={
                                                                        errors.username &&
                                                                        touched.username &&
                                                                        errors.username
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Password" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="password"
                                                                    label="Password"
                                                                    type="password"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.password &&
                                                                            touched.password &&
                                                                            errors.password
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.password ? classes.manorma : null,
                                                                    }}
                                                                    value={values.password}
                                                                    helperText={
                                                                        errors.password &&
                                                                        touched.password &&
                                                                        errors.password
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="IP Address" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="ipaddress"
                                                                    label="ipaddress"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.ipaddress &&
                                                                            touched.ipaddress &&
                                                                            errors.ipaddress
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.ipaddress ? classes.manorma : null,
                                                                    }}
                                                                    value={values.ipaddress}
                                                                    helperText={
                                                                        errors.ipaddress &&
                                                                        touched.ipaddress &&
                                                                        errors.ipaddress
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    
                                                </Row>
                                                    
                                                <Col md={{ span: 12 }}>
                                                    <Row><Col md={{ span: 10 }}></Col>
                                                        <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                        </Col>
                                                        <Col md={{ span: 5 }}></Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        
                    </Col>
                </Row>
            </div>
        </Container>
    );
}