import React from 'react'
import Typography from '@material-ui/core/Typography'

export default ({ text, styleType }) => (
  <Typography
    style={
      styleType === 'A'
        ? {
            margin: '30px 0px',
            fontSize: '20px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 600,
            borderBottom: '1.5px solid rgb(114, 84, 163)',
            letterSpacing: '1.2px'
          }
        : styleType === 'AB'
        ? {
            fontSize: '16px',
            color: '#7254A3',
            fontWeight: '100',
            letterSpacing: '1.2px',
            display: 'block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'

            //marginLeft: 16
          }
        : styleType === 'B'
        ? {
            fontSize: '18px',
            color: '#7254A3',
            fontWeight: '100',
            letterSpacing: '1.2px'

            //marginLeft: 16
          }
        : styleType === 'C'
        ? {
            // marginBottom: "20px",
            fontSize: '25px',
            color: '#7254A3',
            fontWeight: 600,
            letterSpacing: '1.2px'
            //  marginLeft: 257
          }
        : styleType === 'D'
        ? {
            margin: '0% 0% 2% 0%',
            fontSize: '20px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 600,
            // borderBottom: "1.5px solid rgb(114, 84, 163)",
            letterSpacing: '1.2px'
          }
        : styleType === 'SH'
        ? {
            margin: '0% 0% 2% 0%',
            fontSize: '16px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 600,
            // borderBottom: "1.5px solid rgb(114, 84, 163)",
            letterSpacing: '1.2px',
            backgroundColor: ' #e7e7ef',
            width: '100%',
            padding: '5px'
          }
        : styleType === 'CS'
        ? {
            margin: '0% 0% 2% 0%',
            fontSize: '16px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 600,
            // borderBottom: "1.5px solid rgb(114, 84, 163)",
            letterSpacing: '1.2px',
            backgroundColor: '#FFEFCB',
            width: '100%',
            padding: '5px'
          }
        : styleType === 'E'
        ? {
            // margin: "30px 18px",
            fontSize: '20px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 900,
            // borderBottom: "1.5px solid rgb(114, 84, 163)",
            letterSpacing: '1.2px',
            marginTop: -32,
            marginBottom: 2,
            marginLeft: 18
          }
        : styleType === 'F'
        ? {
            marginBottom: '20px',
            fontSize: '28px',
            color: '#7254A3',
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            marginLeft: 8
          }
        : styleType === 'G'
        ? {
            marginBottom: '20px',
            fontSize: '28px',
            color: '#7254A3',
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            marginLeft: -28
          }
        : styleType === 'h'
        ? {
            // margin: "30px 18px",
            fontSize: '20px',
            color: 'rgb(114, 84, 163)',
            fontWeight: 900,
            // borderBottom: "1.5px solid rgb(114, 84, 163)",
            letterSpacing: '1.2px',
            marginLeft: 13,
            marginBottom: 30,
            marginTop: 30
          }
        : styleType === 'accordianTitle'
        ? {
            fontSize: '18px',
            // color: "#fff",
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            marginLeft: '0px'
          }
        : styleType === 'accordiansubTitle'
        ? {
            fontSize: '15px',
            // color: "#fff",
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            marginLeft: '0px'
          }
        : {
            fontSize: '20px',
            color: '#7254A3',
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            marginLeft: '0',
            marginTop: 5
          }
    }
  >
    {text}
  </Typography>
)
