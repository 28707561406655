import React, { useRef } from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Dropdown } from 'semantic-ui-react'
import { useStyle } from './styles'
import clsx from 'clsx'
import { Formik } from 'formik'
import { bankFormschema } from '../../shared/validations'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '../../base_components/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { Alert, AlertTitle } from '@material-ui/lab'

import editrec from '../../image/pngicons/edit.png'
import uploaddoc from '../../image/pngicons/upload.png'
import addbusiness from '../../image/pngicons/add.png'
import deleterec from '../../image/pngicons/delete.png'
import { withRouter } from 'react-router-dom'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { apiCalling } from '../../shared/constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Tabs from '@material-ui/core/Tabs'
//import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { withStyles } from '@material-ui/core/styles'

import manualentry from '../../image/pngicons/manualaddition.png'
import CloseIcon from '@material-ui/icons/Close'
import ReactQuill from 'react-quill'
import { TextField } from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Snackbar from '@material-ui/core/Snackbar'

import { SnackPosition } from '../../shared/constants'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

function BankerPage (props, { history }) {
  const [open, setOpen] = React.useState(false)
  const [openLoan, setOpenLoan] = React.useState(false)

  const [openDialog, setOpenDialog] = React.useState(false)

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    }
  }))

  const BPOptions = ['Business', 'Promoter']

  const [showmodal, setshowmodal] = React.useState(false)
  const handleclosePdf = () => {
    setshowmodal(false)
  }

  const ApplicationOptions = ['Loan Application', 'Check Eligibility']

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (localStorage.getItem('businessflag')) {
      props.handleNext()
    }
    getAllDocumentList()
    // getUserId();
    // getCPAData();
    localStorage.removeItem('rquestIdDetails')
    localStorage.removeItem('RequestType')
    // localStorage.removeItem("handleFlow");
    getLoanStatusPercentage()
    localStorage.setItem('check', JSON.stringify('Loan Application'))
    localStorage.setItem('RequestType', 0)
    getAllStatus()
    getRequestData()
  }, [])
  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState('Saved Succesfully')
  const [colorMessage, setcolorMessage] = React.useState()

  ///getBusiness and shareholder details based on user detail

  /// Business Information data
  // getting user Id from local storage

  const checkeligibiltyBusAndProm = () => {
    localStorage.setItem('check', JSON.stringify('Check Eligibility'))
    props.handleNext()
  }

  const applyforloanBusAndPromo = () => {
    localStorage.setItem('check', JSON.stringify('Loan Application'))
    props.handleNext()
  }

  const handleNextClick = () => {
    const localStorageData = localStorage.getItem('banking'),
      bankdata = JSON.parse(localStorageData)

    console.log('shareholder', bankdata)
    // setshowform(false)
    console.log('clicked next')
  }

  // const classes1 = useStyles();
  const [value, setValue] = React.useState('1')

  const [value2, setValue2] = React.useState('1')
  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
    setOpenTab(false)
  }

  const [businesslist, setbusinesslist] = React.useState([])

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%'
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: 'auto'
  }

  const [page, setPage] = React.useState(0)

  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [applicationList, setApplicationList] = React.useState([])
  const getRequestData = async arralen => {
    
    const checkuserloggeddata = localStorage.getItem('userdata')
    let bankerdata = JSON.parse(checkuserloggeddata)

    let data = {
      financial_institution_id: bankerdata.bank_id,
      location: bankerdata.city,
      loan_type_id: bankerdata.loan_type_id
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getapplicationforBanker`,
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setApplicationList(result.data.data)
        } else {
          if (arralen > 0) {
            setOpenDialog(true)
          }
          setApplicationList([])
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const goToLetsGetStarted = item => {
    localStorage.setItem('rquestIdDetails', JSON.stringify(item))
    localStorage.setItem('handleFlow', 'letget')
    if (item.request_type_id === 0) {
      localStorage.setItem('RequestType', 0)
    } else {
      localStorage.setItem('RequestType', 1)
    }
    props.handleNextLetStart()
  }

  const options = ['Loan Application', 'Check Eligibility']
  const [openTab, setOpenTab] = React.useState(false)
  const anchorRef = React.useRef(null)
  const anchorRef1 = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const [loanOrCheck, setLoanOrCheck] = React.useState()
  const handleMenuItemClick = value => {
    setLoanOrCheck(value)

    setModalOpen(true)
    setSelectedIndex(value)
  }

  const goToLetsGetStartedFromCpa = data => {
    //////
    localStorage.setItem('bankidforcppa', data)
    //localStorage.removeItem('userdata');
    if (loanOrCheck === 0) {
      localStorage.setItem('check', JSON.stringify('Loan Application'))
      localStorage.setItem('RequestType', 0)
    } else {
      localStorage.setItem('check', JSON.stringify('Check Eligibility'))
      localStorage.setItem('RequestType', 1)
    }
    localStorage.setItem('letgetstarted ', JSON.stringify(data))
    props.handleNextLetStart()
  }

  const handleMenuItemClickBusPro = index => {
    //////
    localStorage.setItem('RequestType', 0)
    if (index == 'Business') {
      localStorage.setItem('cpa_reg', 'cpa_reg')
      //   window.location = apiCalling.frontCaller+ '/#/registration';
   //    window.location = apiCalling.frontCaller + '/#/registration';

      window.location = apiCalling.frontCaller + '/#/registration'
      // window.location = 'https://platform.aagey.com/app/#/registration';
    } else {
      props.handleNext(undefined, undefined, 2)
    }
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleToggle1 = () => {
    setOpenLoan(prevOpen => !prevOpen)
  }

  const handleClose1 = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleClose2 = event => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return
    }
    setOpenLoan(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  function handleListKeyDown1 (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenLoan(false)
    }
  }

  const [statusArr, setStatusArr] = React.useState([])
  const getLoanStatusPercentage = () => {
    //////
    let data = {
      enable: 1
    }
    axios
      .post(apiCalling.coderscorner + 'server.php/api/getRequestByStatus', data)
      .then(result => {
        console.log(result)
        setStatusArr(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [cpaList, setCpaList] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState(false)
  const handleCloseBusiness = () => {
    setModalOpen(false)
  }

  const [openStatus, setOpenStatus] = React.useState(false)
  const handleClose = () => {
    setOpenStatus(false)
  }

  const [modalContent, setModalContent] = React.useState('')
  const editor = useRef(null)

  let modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ]
  }

  let formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]

  const [content, setContent] = React.useState('')
  const [statusList, setStatusLit] = React.useState(true)
  const getAllStatus = async () => {
    let data = {
      type: 'applications_status'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(result => {
        console.log(result)
        let arr = []
        result.data.data.map(item => {
          arr.push({ text: item.name, value: item.id, key: item.id })
        })
        setStatusLit(arr)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [intRate, setIntRate] = React.useState('')
  const [amtApr, setAmtApr] = React.useState('')
  const [bankerComments, setBankerComments] = React.useState('')
  const [requestId, setRequestId] = React.useState('')
  const [reqStatus, setRequestStatus] = React.useState('')
  const [id, setId] = React.useState(Number)

  const checkuserloggeddata = localStorage.getItem('userdata')
  let iduser = JSON.parse(checkuserloggeddata)

  const upadatingStatus = async () => {
    ////

    let data = {
      id: id,
      request_id: requestId,
      status: reqStatus,
      user_id: iduser.id,
      amount_approved: amtApr,
      interest_rate: intRate,
      comments: bankerComments,
      financial_institution_id: iduser.bank_id
    }
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/upadatingStatus', data)
      .then(result => {
        console.log(result)
        setsavenextmessage(true)
        getRequestData()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [allDocs, setAllDoc] = React.useState([])
  const getAllDocumentList = async () => {
    let data = {
      user_id: '640',
      form: '11'
    }

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getDetailsDocument`, data)
      .then(result => {
        console.log(result)

        if (result.data.status === true) {
          setAllDoc(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [openCheckList, setOpenCheckList] = React.useState(false)
  // const [] = React.useState(false);
  const openCheckListFun = () => {
    ////
    setOpenCheckList(true)
  }

  const [isChecked, setIsChecked] = React.useState(allDocs.slice().fill(false))

  const toggleCheckboxValue = index => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)))
  }

  const getapplicationforBankerById = async item => {
    //
    let data = {
      financial_institution_id: iduser.bank_id,
      request_id: item.id
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getStatusByFinancierId`,
        data
      )
      .then(result => {
        console.log(result)
        const item = result.data.data[0]
        setId(item.id)
        setRequestStatus(Number(item.status))
        setAmtApr(item.amount_approved)
        setIntRate(item.interest_rate)
        setBankerComments(item.comments)
      })
      .catch(error => console.log(error))
  }

  return (
    <Container maxWidth='lg'>
      {true == 1 ? (
        <>
          <div
            className='mini-container mg_btm_set'
            style={{ marginTop: '5%' }}
          >
            <Snackbar
              open={savenextmessage}
              style={{ marginTop: '65px' }}
              autoHideDuration={SnackPosition.duration}
              onClose={() => {
                setsavenextmessage(false)
              }}
              anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}
            >
              <Alert
                onClose={() => {
                  setsavenextmessage(false)
                }}
                severity='info'
              >
                {showMessage}
              </Alert>
            </Snackbar>

            <Row>
              <Col md={{ span: 12 }}>
                <Typography
                  text={`Dashboard : ${iduser.name.replace(/_/g, ' ')}`}
                  styleType='C'
                />
              </Col>
            </Row>

            {applicationList.length >= 0 && (
              <>
                <Row>
                  <Col md={{ span: 12 }}>
                    <IconButton
                      ref={anchorRef1}
                      aria-controls={openLoan ? 'menu-list-grow1' : undefined}
                      aria-haspopup='true'
                      onClick={handleToggle1}
                      style={{
                        float: 'right',
                        marginTop: '1%',
                        marginBottom: '-3%',
                        zIndex: '999'
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                    <Popper
                      open={openLoan}
                      anchorEl={anchorRef1.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom'
                                ? 'center top'
                                : 'center bottom'
                          }}
                        >
                          <Paper className='dashboarddd'>
                            <ClickAwayListener onClickAway={handleClose2}>
                              <MenuList
                                autoFocusItem={openLoan}
                                id='menu-list-grow1'
                                onKeyDown={handleListKeyDown1}
                                style={{ padding: '0% 2%', width: '18ch' }}
                              >
                                {ApplicationOptions.map((option, index) => (
                                  <MenuItem
                                    onClick={handleClose2}
                                    key={option}
                                    onClick={() => handleMenuItemClick(index)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Col>
                </Row>
                <TabContext value={value2}>
                  <AppBar position='static'>
                    <TabList
                      onChange={handleChange2}
                      aria-label='simple tabs example'
                    >
                      <Tab label='Applications' value='1' className='tabSize' />
                    </TabList>
                  </AppBar>
                  <TabPanel value='1'>
                    <TableContainer>
                      <Table
                        aria-label='customized table'
                        size='small'
                        style={{ marginTop: '1%', marginBottom: '1% 0% 3%' }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell width='5%' style={table_slNo}>
                              #
                            </TableCell>
                            <TableCell style={table_header}>
                              Reference Number
                            </TableCell>
                            <TableCell style={table_header}>
                              Business Name
                            </TableCell>
                            <TableCell style={table_header}>
                              Business Pan
                            </TableCell>
                            <TableCell style={table_header}>
                              Application Type
                            </TableCell>
                            <TableCell style={table_header}>
                              Loan Product
                            </TableCell>
                            <TableCell style={table_header}>
                              Date Logged
                            </TableCell>
                            <TableCell style={table_header}>
                              Loan Amount
                            </TableCell>
                            <TableCell style={table_header}>Status</TableCell>
                            <TableCell style={table_header}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {applicationList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, i) => {
                              return (
                                <TableRow hover className='datable'>
                                  <TableCell>{i + 1}</TableCell>
                                  <TableCell>{item.reference_id}</TableCell>
                                  <TableCell>{item.business_name}</TableCell>
                                  <TableCell>{item.pan}</TableCell>
                                  {item.request_type_id == 0 && (
                                    <TableCell>Loan Application</TableCell>
                                  )}
                                  {item.request_type_id == 1 && (
                                    <TableCell>Loan Application</TableCell>
                                  )}
                                  <TableCell>{item.loan_type}</TableCell>
                                  <TableCell>
                                    {moment(item.created_on).format(
                                      'DD-MM-YYYY'
                                    )}
                                  </TableCell>
                                  <TableCell>{item.loan_amount}</TableCell>
                                  <TableCell>{item.status}</TableCell>
                                  <TableCell>
                                    {
                                      <>
                                        <Tooltip
                                          title='Status'
                                          placement='bottom'
                                        >
                                          <img
                                            alt='img'
                                            className='actionBtn svgicon'
                                            src={manualentry}
                                            onClick={() => {
                                              //////

                                              getapplicationforBankerById(item)
                                              getAllStatus()
                                              // if (item.amount_approved !== null) {
                                              //     setAmtApr(item.amount_approved);
                                              // }
                                              // if (item.interest_rate !== null) {
                                              //     setIntRate(item.interest_rate);
                                              // }
                                              // if (item.comments !== null) {
                                              //     setBankerComments(item.comments);
                                              // }

                                              // setRequestStatus(item.status);
                                              setContent(1)
                                              setModalContent('Loan Status')
                                              setOpenStatus(true)
                                              setRequestId(item.id)
                                            }}
                                          />
                                        </Tooltip>

                                        {
                                          // <Tooltip title="Delete Loan" placement="bottom">
                                          //     <img alt="img" className="actionBtn svgicon" src={deleterec} o />
                                          // </Tooltip>
                                        }

                                        {
                                          // <Tooltip title="Add Bank" placement="bottom">
                                          //     <img alt="img" className="actionBtn svgicon" src={addbutton} />
                                          // </Tooltip>
                                        }
                                        <Tooltip
                                          title='Documents'
                                          placement='bottom'
                                        >
                                          <img
                                            alt='img'
                                            className='actionBtn svgicon'
                                            src={uploaddoc}
                                            onClick={() => {
                                              openCheckListFun()
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title='Loan'
                                          placement='bottom'
                                        >
                                          <Button
                                            className='actionBtn'
                                            onClick={e => {
                                              goToLetsGetStarted(item)
                                            }}
                                          >
                                            <i
                                              class='fas fa-long-arrow-alt-right'
                                              style={{ fontSize: '15px' }}
                                            ></i>
                                          </Button>
                                        </Tooltip>
                                      </>
                                    }
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component='div'
                      count={applicationList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />

                    {applicationList.length == 0 && (
                      <Alert severity='info' style={{ marginTop: '7px' }}>
                        <AlertTitle>
                          <p style={{ fontSize: '16px', marginTop: '6px' }}>
                            You have not created any appliation or Assigned to
                            any application. Please add new business to start
                          </p>
                        </AlertTitle>
                      </Alert>
                    )}
                  </TabPanel>

                  <TabPanel value='2'>
                    <TableContainer>
                      <Table
                        aria-label='customized table'
                        size='small'
                        style={{ marginTop: '1%', marginBottom: '1% 0% 3%' }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell width='5%' style={table_slNo}>
                              #
                            </TableCell>
                            <TableCell style={table_header}>
                              Reference Number
                            </TableCell>
                            <TableCell style={table_header}>
                              Business Name
                            </TableCell>
                            <TableCell style={table_header}>
                              Business Pan
                            </TableCell>
                            <TableCell style={table_header}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {applicationList.map((item, i) => (
                            <TableRow hover className='datable' key={i + 1}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{item.reference_id}</TableCell>
                              <TableCell>{item.business_name}</TableCell>
                              <TableCell>{item.pan}</TableCell>
                              <TableCell style={{ display: 'flex' }}>
                                {
                                  <Tooltip title='Loan' placement='bottom'>
                                    <Button
                                      className='actionBtn'
                                      onClick={e => {
                                        goToLetsGetStarted(item.request_type_id)
                                      }}
                                    >
                                      <i
                                        class='fas fa-long-arrow-alt-right'
                                        style={{ fontSize: '15px' }}
                                      ></i>
                                    </Button>
                                  </Tooltip>
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </TabContext>
              </>
            )}

            <Row>
              <Col md={{ span: 12 }}>
                <IconButton
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                  style={{
                    float: 'right',
                    marginTop: '1%',
                    marginBottom: '-3%',
                    zIndex: '10'
                  }}
                >
                  <img className='actionBtn svgicon' src={addbusiness} />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper className='dashboarddd'>
                        <ClickAwayListener onClickAway={handleClose1}>
                          <MenuList
                            autoFocusItem={open}
                            id='menu-list-grow'
                            onKeyDown={handleListKeyDown}
                          >
                            {BPOptions.map(option => (
                              <MenuItem
                                onClick={handleClose1}
                                onClick={opetion =>
                                  handleMenuItemClickBusPro(option)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Col>
            </Row>

            {
              //                             <TabContext value={value}>
              //                             <AppBar position="static">
              //                                 <TabList onChange={handleChange1} aria-label="simple tabs example">
              //                                     <Tab label="Business Information" value="1" style={{ width: "200px" }} className="tabSize" />
              //                                     <Tab label="Promoter Information" value="2" className="tabSize" />
              //                                 </TabList>
              //                             </AppBar>
              //                             <TabPanel value="1">
              //                                 <TableContainer>
              //                                     <Table aria-label="collapsible table" size="small" style={{ margin: "0% 0% 0%" }}>
              //                                         <TableHead>
              //                                             <TableRow>
              //                                                 <TableCell className="tableSLNoHeading">#</TableCell>
              //                                                 <TableCell className="tableBusinessNameHeadingMain">Business Name</TableCell>
              //                                                 <TableCell className="tableGSTHeading">GST</TableCell>
              //                                                 <TableCell className="tableEntityTypeHeading">Entity Type</TableCell>
              //                                                 <TableCell className="tablePANHeading">Business PAN</TableCell>
              //                                                 <TableCell className="tablePANHeading">Industry Group</TableCell>
              //                                                 <TableCell className="tablePANHeading">City</TableCell>
              //                                                 <TableCell className="tablePANHeading">State</TableCell>
              //                                                 <TableCell className="tableActionHeading" colSpan={2}>Action</TableCell>
              //                                             </TableRow>
              //                                         </TableHead>
              //                                         <TableBody>
              //                                             {businesslist.slice(buspage * rowsPerPage, buspage * rowsPerPage + rowsPerPage).map((item, i) => (
              //                                                 <TableRow hover className="datable" key={i}>
              //                                                     <TableCell>{i + 1}</TableCell>
              //                                                     <TableCell>{item.name}</TableCell>
              //                                                     <TableCell>{item.gst}</TableCell>
              //                                                     <TableCell>{item.entity_type}</TableCell>
              //                                                     <TableCell>{item.bpan}</TableCell>
              //                                                     <TableCell>{item.industry_group_name}</TableCell>
              //                                                     <TableCell>{item.city_name}</TableCell>
              //                                                     <TableCell>{item.state_name}</TableCell>
              //                                                     <TableCell className="tableActionHeading">
              //                                                         <Tooltip title="Edit" placement="bottom">
              //                                                             {/* <Button className="actionBtn" onClick={() => handleEditData(1, item.id)}>
              //                                           <i className="fas fa-user-edit" style={{ fontSize: "15px" }} />
              //                                         </Button> */}
              //                                                             <img className="actionBtn svgicon" src={editrec} onClick={() => handleEditData(item.id, 1, false)} />
              //                                                         </Tooltip>
              //                                                         <Tooltip title="delete" placement="bottom">
              //                                                             <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
              //                                                                 deleteBusinessByBusinessId(item.id);
              //                                                             }} />
              //                                                         </Tooltip>
              //                                                         <Tooltip title="Upload Document" placement="bottom">
              //                                                             {/* <Button className="actionBtn" onClick={(e) => {
              //                                           props.handleNext(item.id, 1, 1);
              //                                         }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
              //                                                             <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => { props.handleNext(item.id, true, 1); }} />
              //                                                         </Tooltip>
              //                                                     </TableCell>
              //                                                 </TableRow>
              //                                             ))}
              //                                         </TableBody>
              //                                     </Table>
              //                                 </TableContainer>
              //                                 <TablePagination
              //                                     rowsPerPageOptions={[5, 10, 15]}
              //                                     component="div"
              //                                     count={businesslist.length}
              //                                     rowsPerPage={rowsPerPage}
              //                                     page={buspage}
              //                                     onChangePage={busHandleChangePage}
              //                                     onChangeRowsPerPage={handleChangeRowsPerPagebus}
              //                                 />
              //  {/* <TablePagination
              //                                             rowsPerPageOptions={[5, 10, 15]}
              //                                             component="div"
              //                                             count={applicationList.length}
              //                                             rowsPerPage={rowsPerPage}
              //                                             page={page}
              //                                             onChangePage={handleChangePage}
              //                                             onChangeRowsPerPage={handleChangeRowsPerPage} */}
              //                                 {businesslist.length === 0 && (
              //                                     <Alert severity="warning" className="warningMsg1">
              //                                         <AlertTitle><p style={{ fontSize: "16px" }}>You have Not created or assigned to any Application Please click on add button Add new business
              //                             </p></AlertTitle>
              //                                     </Alert>
              //                                 )}
              //                             </TabPanel>
              //                             <TabPanel value="2">
              //                                 <Formik
              //                                     enableReinitialize={true}
              //                                     initialValues={initialValue} >
              //                                     {({
              //                                         values,
              //                                         errors,
              //                                         touched,
              //                                         handleChange,
              //                                         handleBlur,
              //                                         handleSubmit,
              //                                         isSubmitting,
              //                                         setFieldValue,
              //                                         setFieldTouched
              //                                         /* and other goodies */
              //                                     }) => {
              //                                         //console.log("values in formik", values);
              //                                         return (
              //                                             <form onSubmit={handleSubmit} >
              //                                                 <Row>
              //                                                     <Col md={{ span: 4 }}>
              //                                                         <List component="div" disablePadding>
              //                                                             <ListItem>
              //                                                                 <Dropdown
              //                                                                     placeholder="Select Business Name"
              //                                                                     id="company"
              //                                                                     name="company"
              //                                                                     fluid
              //                                                                     search
              //                                                                     selection
              //                                                                     value={values.company}
              //                                                                     onBlur={(e, { name, value }) => {
              //                                                                         setFieldTouched("company", true);
              //                                                                     }}
              //                                                                     onChange={(e, { name, value }) => {
              //                                                                         //////
              //                                                                         setPromoterList([]);
              //                                                                         setFieldValue("company", value);
              //                                                                         setbusIdForDel(value);
              //                                                                         setBusinessIdForPro(value);
              //                                                                     }}
              //                                                                     options={businesslist}
              //                                                                 />
              //                                                             </ListItem>
              //                                                         </List>
              //                                                     </Col>
              //                                                     {/* <Col md={{ span: 5 }}></Col>
              //                                     <Col md={{ span: 3 }}></Col> */}
              //                                                 </Row>
              //                                             </form>
              //                                         );
              //                                     }
              //                                     }
              //                                 </Formik>
              //                                 {showPromoterTable == false && (
              //                                     <Alert severity="warning" className="warningMsg1">
              //                                         <AlertTitle><p style={{ fontSize: "16px" }}>Promoters have not been added to this business. Click here to add Promoter details.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              //                             </p></AlertTitle>
              //                                         <Button size="small" className="choicebtn" onClick={() => { props.handleNext(undefined, undefined, 2) }}>Add Promoter</Button>&nbsp;&nbsp;
              //                                     </Alert>
              //                                 )}
              //                                 {true && (
              //                                     <TableContainer >
              //                                         <Table aria-label="customized table" size="small" style={{ marginBottom: "0% 0% 0%" }}>
              //                                             <TableHead>
              //                                                 <TableRow>
              //                                                     <TableCell style={table_slNo}>#</TableCell>
              //                                                     <TableCell style={table_header}>Promoter Name</TableCell>
              //                                                     <TableCell style={table_header}>Email</TableCell>
              //                                                     <TableCell style={table_header}>Mobile</TableCell>
              //                                                     <TableCell style={table_header}>Type</TableCell>
              //                                                     <TableCell style={table_header}>Share %</TableCell>
              //                                                     <TableCell style={table_header}>Status</TableCell>
              //                                                     <TableCell style={table_header}>Action</TableCell>
              //                                                 </TableRow>
              //                                             </TableHead>
              //                                             <TableBody>
              //                                                 {promoterList.map((item, i) => (
              //                                                     <TableRow hover className="datable" key={i}>
              //                                                         <TableCell>{i + 1}</TableCell>
              //                                                         <TableCell>{item.name}</TableCell>
              //                                                         <TableCell>{item.email}</TableCell>
              //                                                         <TableCell>{item.mobile}</TableCell>
              //                                                         <TableCell>{item.type}</TableCell>
              //                                                         <TableCell>{item.share}</TableCell>
              //                                                         <TableCell> <i class="far fa-times-circle" style={{ color: "red" }}></i> </TableCell>
              //                                                         <TableCell style={{ display: "flex" }}>
              //                                                             <Tooltip title="Edit" placement="bottom">
              //                                                                 {/* <Button className="actionBtn" onClick={(e) => {
              //                                             getPromoterById1(item.promoter_id);
              //                                           }}>
              //                                             <i className="fas fa-user-edit"
              //                                               style={{ fontSize: "15px" }} />
              //                                           </Button> */}
              //                                                                 <img className="actionBtn svgicon" src={editrec} onClick={(e) => {
              //                                                                     getPromoterById1(item.promoter_id);
              //                                                                 }} />
              //                                                             </Tooltip>
              //                                                             <Tooltip title="delete" placement="bottom">
              //                                                                 {/* <Button className="actionBtn" onClick={(e) => {
              //                                       deletePromoterById(item.promoter_id);
              //                                     }}> <i class="fas fa-user-minus" style={{ fontSize: "13px" }}></i></Button> */}
              //                                                                 <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
              //                                                                     deletePromoterById(item.promoter_id);
              //                                                                 }} />
              //                                                             </Tooltip>
              //                                                             <Tooltip title="Upload Document" placement="bottom">
              //                                                                 {/* <Button className="actionBtn" onClick={(e) => {
              //                                         props.handleNext(item.promoter_id, item.eligibility_flag, 2);
              //                                       }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
              //                                                                 <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => {
              //                                                                     props.handleNext(item.promoter_id, item.eligibility_flag, 2);
              //                                                                 }} />
              //                                                             </Tooltip>
              //                                                         </TableCell>
              //                                                     </TableRow>
              //                                                 ))}
              //                                             </TableBody>
              //                                         </Table>
              //                                     </TableContainer>)}
              //                             </TabPanel>
              //                         </TabContext>
            }
          </div>
        </>
      ) : (
        <p>hello</p>
      )}

      <Modal
        show={showmodal}
        animation={false}
        centered
        style={{ height: '550px' }}
      >
        <Modal.Header
          closeButton
          onClick={handleclosePdf}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {
            /* showCpaPage === true && */ <Formik
              initialValues={{
                cpa: ''
              }}
              onSubmit={values => {
                console.log('check cpa data table value', values)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{ span: 2 }}></Col>
                      <Col md={{ span: 8 }}>
                        <Typography text=' CPA' styleType='B' />
                        <List component='div' disablePadding>
                          <ListItem>
                            <Dropdown
                              placeholder='Select CPA'
                              id='cpa'
                              name='cpa'
                              fluid
                              search
                              selection
                              onBlur={() => setFieldTouched('cpa', true)}
                              onChange={(e, { value }) =>
                                setFieldValue('cpa', value)
                              }
                              value={values.cpa}
                              options={cpaList}
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          <Col md={{ span: 2 }}>
                            <Button
                              className='updatebtn'
                              variant='contained'
                              color='primary'
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )
              }}
            </Formik>
          }
        </Modal.Body>
      </Modal>
      <>
        {false && (
          <Formik
            initialValues={{
              bankname: '',
              account: '',
              natureacc: '',
              submission: '',
              color: '',
              companyl: ''
            }}
            validationSchema={bankFormschema}
            onSubmit={values => {
              console.log('kkkkk', values)
              localStorage.setItem('banking', JSON.stringify(values))
              handleNextClick()
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col
                      md={{ span: 8 }}
                      style={{
                        display: 'block',
                        margin: 'auto',
                        marginTop: '15%'
                      }}
                    >
                      <div className='border_set_pro'>
                        <div>
                          <h2>Welcome to Aagey.com !</h2>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <h2>Now get loans</h2>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <h2>Let us get you started with your application</h2>
                        </div>
                        <div style={{ margin: 40 }}>
                          <Button
                            className='choicebtn'
                            onClick={checkeligibiltyBusAndProm}
                          >
                            Check Eligibility
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            className='choicebtn'
                            onClick={applyforloanBusAndPromo}
                          >
                            Loan Application
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )
            }}
          </Formik>
        )}
      </>

      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={openStatus}
        >
          <Modal.Header
            className='modalHead'
            closeButton
            onClick={handleClose}
            style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
          >
            <Modal.Title className='modalHeader cpModal'>
              Status of Loan Application
            </Modal.Title>
          </Modal.Header>
          <DialogContent
            className='custom_modal'
            dividers
            style={{ height: '120px', padding: '2%' }}
          >
            <Typography gutterBottom>{modalContent}</Typography>

            <div>
              <Typography text='Status' styleType='B' />
              <Dropdown
                placeholder='Select Status'
                id='ltype'
                name='ltype'
                fluid
                search
                selection
                value={reqStatus}
                // onBlur={() => setFieldTouched("ltype", true)}
                onChange={(e, { value }) => {
                  //
                  setRequestStatus(value)
                }}
                options={statusList}
                className='bank_modal_type'
                //   className={errors.ltype && touched.ltype ? classes.required : values.ltype == '' ? classes.reqired : classes.man}
                //disabled={formDisabled}
              />
            </div>

            <div className='bank_modal_type'>
              <Typography text='Amount Approved' styleType='B' />
              <TextField
                id='standard-basic'
                className='bank_modal_type'
                value={amtApr}
                onChange={e => {
                  setAmtApr(e.target.value)
                }}
              />
            </div>
            <div className='bank_modal_type'>
              <Typography
                text='Interest Rate'
                className='bank_modal_type'
                styleType='B'
              />
              <TextField
                id='standard-basic'
                className='bank_modal_type'
                value={intRate}
                onChange={e => {
                  ////
                  setIntRate(e.target.value)
                }}
              />
            </div>

            <div className='bank_modal_type'>
              <Typography
                text='Comments'
                className='bank_modal_type'
                styleType='B'
              />
              <ReactQuill
                onRef={editor}
                ////value={bindingArray.arrayBindData['level3' + level.id] || ''}
                onChange={html => {
                  setBankerComments(html)
                }}
                value={bankerComments}
                theme='snow'
                modules={modules}
                formats={formats}
                style={{ width: '100%' }}
                // onFocus={() => {
                //    generateToolArray(level.id);
                //    // showToolBar1();
                //    console.log("hello");
                // }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={upadatingStatus} color='primary'>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          onClose={() => {
            setOpenCheckList(false)
          }}
          aria-labelledby='customized-dialog-title'
          open={openCheckList}
        >
          <DialogTitle
            id='customized-dialog-title'
            onClose={() => {
              setOpenCheckList(false)
            }}
          >
          <Typography gutterBottom>Documents Collected</Typography>
          </DialogTitle>
          <DialogContent
            className='custom_modal'
            dividers
            style={{ height: '120px' }}
          >
           

            {allDocs.map((item, index) => (
              <>
                {item.name != null && (
                  <React.Fragment>
                    <MenuItem
                      value={item.value}
                      selected={item.value === value}
                      key={index}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={index}
                            checked={isChecked[index]}
                            onClick={() => toggleCheckboxValue(index)}
                          ></Checkbox>
                        }
                        label={item.name}
                      />
                    </MenuItem>
                  </React.Fragment>
                )}
              </>
            ))}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={upadatingStatus} color='primary'>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* <Dialog onClose={handleCloseBusiness} aria-labelledby="max-width-dialog-title" open={modalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseBusiness}>
                    Select Business
        </DialogTitle>
                <DialogContent className="cpa_dialog" >
                    <Dropdown
                        placeholder="Select Business Name"
                        id="company"
                        name="company"
                        fluid
                        search
                        selection
                        onChange={(e, { name, value }) => {
                            //////
                            goToLetsGetStartedFromCpa(value);
                        }}
                        options={businesslist}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseBusiness} color="primary">
                        Save changes
          </Button>
                </DialogActions>
            </Dialog> */}
      <Modal
        show={modalOpen}
        animation={false}
        centered
        style={{ height: 'auto' }}
      >
        <Modal.Header
          closeButton
          onClick={handleCloseBusiness}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        ></Modal.Header>
        <Modal.Body>
          <Typography text=' CPA' styleType='B' />
          <Row>
            <Col md={{ span: 2 }}></Col>
            <Col md={{ span: 8 }}>
              <List component='div' disablePadding>
                <ListItem>
                  <Dropdown
                    placeholder='Select Business Name'
                    id='company'
                    name='company'
                    fluid
                    search
                    selection
                    // onBlur={() => setFieldTouched("cpa", true)}
                    onChange={(e, { name, value }) => {
                      goToLetsGetStartedFromCpa(value)
                    }}
                    value={value}
                    options={businesslist}
                  />
                </ListItem>
              </List>
            </Col>
            <Col md={{ span: 2 }}></Col>
            <Col md={{ span: 10 }}></Col>
            <Col md={{ span: 2 }}>
              <Button autoFocus onClick={handleCloseBusiness} color='primary'>
                Submit
              </Button>
            </Col>
            <Col md={{ span: 5 }}></Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default withRouter(BankerPage)
