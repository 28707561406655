import { makeStyles } from "@material-ui/core/styles";

export const useStyled = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },


  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button_div: {
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    padding: "9px",
    width: "61%",
  },
  linkdin: {
    backgroundColor: "#007bff",
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_div: {
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  containerCSS: {
    marginTop: "9%",
    marginBottom: "5%",
  },
  checkButton: {
    backgroundColor: "#7254A3 !important",
    color: "white !important",
  },
  manorma: {
    '&:before': {
      borderBottom: "1.5px solid #56B537 !important",
    }
  },
  man: {
    border: "1.5px solid #56B537 !important",
  },
  //---------For SVG BUTTONS------//
  // button_div: {
  //   textTransform: "capitalize",
  //   margin: theme.spacing(1),
  //   fontSize: "14px",
  //   padding: "9px",
  //   width: "35%",
  // },
  // svg_icon1: {
  //   "& svg": {
  //     "& polygon": {
  //       fill: "rgb(114,84,163) !important",
  //     },
  //   },
  // },
  // svg_icon2: {
  //   "& svg": {
  //     "& g": {
  //       fill: "rgb(252,184,86) !important",
  //     },
  //   },
  // },
  // svg_icon3: {
  //   "& svg": {
  //     "& g": {
  //       fill: "rgb(112,193,111) !important",
  //     },
  //   },
  // },
  // svg_icon4: {
  //   "& svg": {
  //     "& g": {
  //       fill: "rgb(66,181,232) !important",
  //     },
  //   },
  // },
}));
