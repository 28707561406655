import React from 'react';
import { MDBDataTableV5 } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../admin";
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typography from '../../base_components/Typography';
import { Formik } from "formik";
import { GeneralSettingsSchema } from "../../shared/validations";
import axios from "axios";
import Icon from "@mdi/react";
import Tooltip from '@material-ui/core/Tooltip';
import {apiCalling} from '../adminConstants';
import { TextField, Button } from '@material-ui/core';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Grid } from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import Paper from '@material-ui/core/Paper';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function FatQuestions() {

    const classes = useStyles();

    const [services, setServices] = React.useState([]);
    const [apiService, setApiService] = React.useState("");
    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
    const [StateList, setStateList] = React.useState([]);
    const [loaderFlag, setLoaderFlag] = React.useState(true);
    const [showList, setShowList] = React.useState(true);
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
         //handleSettingsList();
    }, []);
    React.useEffect(() => {
       
        getServices();
        handleStateList();
        //setApiService("karza");
        //handleGetApiReports(apiService);
    }, []);

    const data = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Name",
                field: "parameter_name",
                width: 270,
            },
            {
                label: "Fat Question",
                field: "question",
                width: 270,
            },
            {
                label: "Finance Question",
                field: "fin_question",
                width: 270,
            },
            {
                label: "Active",
                field: "is_active",
                width: 200,
            },

            {
                label: "Action",
                field: "action",
                width: 200,
            },
        ],

        rows: StateList.map((values, i) => {
            return {
                slno: i + 1,
                parameter_name: values.parameter_name,
                question: values.question,
                fin_question: values.fin_question,
                is_active: values.is_active ? "true" : "false",
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                         <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                    </div>
            }
        })
    }

    
    const handleStateList = async () => {
        const data = {
            enable: true
        };
        await axios
            .post(apiCalling.coderscorner + `server.php/api/getAllFinanceParam`, data).then((result) => {
                let empArray = [];
                result.data.data.map((item) => {
                    empArray.push({
                        parameter_name: item.parameter_name,
                        question: item.question,
                        fin_question: item.fin_question,
                        is_active: item.is_active.toString(),
                        id: item.id,
                    })
                })
                setShowWCAddForm(false);
                setShowList(true);
                setLoaderFlag(false);
                console.log("EmpArray", empArray);
                setStateList(empArray);
            }).catch((error) => {
                console.log(error);
            });
    };

    const handleOpenWCAddForm = () => {
        setAddFormButtonValue("Submit");
        setShowWCAddForm(true);
        setShowList(false);
        setInitialValue({
            parameter_name: "",
            question: "",
            id: "",
            enable: "",
            is_active: ""
        })
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (apiService === "Delta") {
            handleStateList();
        }

    }


    const handleSubmit=() => {
    //console.log("apiService, startDate, endDate" + apiService + " " + startDate + " " + endDate)
     // handleGetApiReports(apiService)
    }

    const handlesubmitform = (values, { resetForm }) => {
    
        handleNextClick(values);
        resetForm({
            parameter_name: "",
            question: "",
            fin_question:"",
            id: "",
            enable: "",
            is_active: ""
        });
    };

    const handleNextClick = (data) => {
        setShowWCAddForm(false);
        setLoaderFlag(true);
        let wcData = {
            parameter_name: data.parameter_name,
            question: data.question,
            fin_question: data.fin_question,
            is_active: data.is_active,
            id: data.id
        };

        axios.post(apiCalling.coderscorner + `server.php/api/updateQuestion`, wcData).then((res) => {
            console.log(res.data);
            console.log("saved successfully...!!!");
            setShowWCAddForm(false);
            setShowList(true);
            handleStateList();
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    };

    const handleEdit = (data) => {
        setShowWCAddForm(true);
        setAddFormButtonValue("Update");
        setShowList(false);
        setInitialValue({
            ...data,
            parameter_name: data.parameter_name,
            question: data.question,
            fin_question: data.fin_question,
            id: data.id,
            is_active: data.is_active ? "1" : "0"
        })
    }

    const handleDelete = (item) => {
       /* let data = {
            id: item.id
        }
        axios.post(apiCalling.coderscorner + `server.php/api/deleteEntityType`, data).then((res) => {
            setShowWCAddForm(false);
            setShowList(true);
            handleStateList();
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });*/
    }


    
    let [initialValue, setInitialValue] = React.useState({
        generalsettings: "",
        id: "",
    });

    const getServices = () => {
        //let url = apiCalling.thirdParty + "server.php/api/getServices";
        let url = apiCalling.coderscorner + "server.php/api/getAllDatabases";
        let data = {
          is_active: 1,
        };
    
        let tempArr = [];
    
        axios
          .get(apiCalling.coderscorner + `server.php/api/getAllDatabases`)
          .then((result) => {
            console.log(result);
            result.data.data.map((item) => {
              tempArr.push({
                id: item.id,
                key: item.id,
                value: item["DB names"],
                text: item["DB names"],
              });
            });
            setServices(tempArr);
           // setApiService(tempArr[0].service);
          })
          .catch((error) => {
            console.log(error);
          });
      };

    return (
        <div>
            <Container maxWidth="lg">
                <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>

                    {loaderFlag === true && (
                        <div style={{ textAlign: 'center', marginTop: '15%' }}>
                            <Loader type={LoaderColor.type} color={LoaderColor.color} />
                        </div>
                    )}
                    {showList === true && loaderFlag === false && (
                        <div>
                            <Row  className="content-header">
                                <Col md={{ span: 10}}>
                                    <Typography
                                        text="Fat Questions"
                                        styleType="C"
                                    />
                                </Col>
                                <Col md={{ span: 2}} >
                                {/* <span className="image">
                                                <img className='navbutton backbutton' src={blueadd} onClick={() => handleOpenWCAddForm()} />
                                                <Tooltip title="Add" arrow>
                                                    <img className='navgraybutton backbutton' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                                                </Tooltip>
                                            </span> */}
                                        </Col>
                                
                            </Row>
                   
                            <Row className="datable" style={{ marginTop: '30px', }} >
                                <Col md={{ span: 12 }}>
                                    <div component={Paper}>
                                  
<div>
              {/* <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="GeneralSetting"
                sheet="GeneralSetting"
              /> */}
            </div>
                                        <MDBDataTableV5 
                                            hover 
                                            entriesOptions={[5, 10,20, 25]} 
                                            entries={5} 
                                            pagesAmount={4} 
                                            data={data}
                                            id = "karzaTbl"

                                            // searchTop 
                                            // searchBottom={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                     )}
                     <Row>
                    <Col md={{ span: 12 }}>
                    {/* validationSchema={GeneralSettingsSchema} */}

                        {showWCAddForm === true && (
                            <Formik
                                enableReinitialize
                                initialValues={initialValue}
                                onSubmit={handlesubmitform}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    resetForm,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldTouched
                                    /* and other goodies */
                                }) => {

                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="content-header" style={{ position: 'unset' }}>
                                                <Row>
                                                    <Col md={{ span: 10 }} className="mainheader1">
                                                        <Typography text="Fat Questions" styleType="C" />
                                                    </Col>
                                                    <Col md={{ span: 2 }} className="mainHeaderBack">
                                                        {/* <div className="content-header-button ">
                                                            <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                                                        </div> */}
                                                        <span className="image">
                                                        <img className='backbutton navbutton' src={Back} onClick={() => {
                                                            resetForm();
                                                            setShowWCAddForm(false);
                                                            setShowList(true);
                                                        }} />
                                                        <Tooltip title="Back" arrow>
                                                            <img className='backbutton navgraybutton' src={grayback} onClick={() => {
                                                                resetForm();
                                                                setShowWCAddForm(false);
                                                                setShowList(true);
                                                            }} />
                                                        </Tooltip>
                                                        </span>
                                                    </Col>
                                                </Row>


                                                <Row style={{ marginTop: '30px' }}>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Parameter Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="parameter_name"
                                                                    label="Enter Parameter Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.parameter_name &&
                                                                            touched.parameter_name &&
                                                                            errors.parameter_name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.parameter_name ? classes.manorma : null,
                                                                    }}
                                                                    value={values.parameter_name}
                                                                    helperText={
                                                                        errors.parameter_name &&
                                                                        touched.parameter_name &&
                                                                        errors.parameter_name
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Fat Question" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="question"
                                                                    label="Enter Question"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.question &&
                                                                            touched.question &&
                                                                            errors.question
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.question ? classes.manorma : null,
                                                                    }}
                                                                    value={values.question}
                                                                    helperText={
                                                                        errors.question &&
                                                                        touched.question &&
                                                                        errors.question
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Finance Question" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="fin_question"
                                                                    label="Enter Finance Question"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.fin_question &&
                                                                            touched.fin_question &&
                                                                            errors.fin_question
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.fin_question ? classes.manorma : null,
                                                                    }}
                                                                    value={values.fin_question}
                                                                    helperText={
                                                                        errors.fin_question &&
                                                                        touched.fin_question &&
                                                                        errors.fin_question
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <div style={{ marginTop: '20px' }}>
                                                            <Typography text="Active" styleType="B" />

                                                        </div>
                                                        <List component="div" disablePadding>
                                                            <ListItem >

                                                                <label>
                                                                    Yes
                                                          <input
                                                                        name="is_active"
                                                                        type="radio"
                                                                        value="1"
                                                                        checked={values.is_active === '1'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    No
                                                           <input
                                                                        name="is_active"
                                                                        type="radio"
                                                                        value="0"
                                                                        checked={values.is_active === '0'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                            </ListItem>
                                                            {errors.is_active && touched.is_active && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.is_active}</div>}
                                                        </List>
                                                    </Col>
                                                </Row>
                                                <Col md={{ span: 12 }}>
                                                    <Row><Col md={{ span: 10 }}></Col>
                                                        <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                        </Col>
                                                        <Col md={{ span: 5 }}></Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        )}
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
}

export default FatQuestions;
