import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SaveIcon from '@material-ui/icons/Save'
import { TextField, Button } from '@material-ui/core'
import grayback from '../../image/buttons/grayback.png'
import Typography from '../../base_components/Typography'
import { Formik } from 'formik'
import Collapse from '@material-ui/core/Collapse'
import { apiCalling } from '../../shared/constants'
import axios from 'axios'
import { useStyles } from './styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import DraftNext from '../../image/buttons/draftnext.png'
import { MDBDataTableV5 } from 'mdbreact'
import Paper from '@material-ui/core/Paper'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import Tooltip from '@material-ui/core/Tooltip'
import Back from '../../image/buttons/back.png'
import { LoaderColor } from '../../shared/constants'
import Loader from 'react-loader-spinner'
import ChevronRight from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import up from '../../image/pngicons/up.png'
import down from '../../image/pngicons/down.png'
import { Divider, Item } from 'semantic-ui-react'
import ReactQuill from 'react-quill'
import { TextareaAutosize } from '@material-ui/core'
import { ErrorCatch } from '../../shared/constants'
import * as htmlToImage from 'html-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import Snackbar from '@material-ui/core/Snackbar'
import AppSummaryPreview from '../AppSummaryPreview';
import { SnackPosition } from '../../shared/constants'



export default function ApplicationSummaryView(props) {
  // constructor(props) {
  //   super(props);
  //   this.saveApplicationSummary = this.saveApplicationSummary.bind(this);
  // };

  const classes = useStyles()
  const [showWCAddForm, setShowWCAddForm] = React.useState(false)

  const [showList, setShowList] = React.useState(true)
  const [loaderFlag, setLoaderFlag] = React.useState(true)

  const [addFormButtonValue, setAddFormButtonValue] = React.useState('Submit')
  const [MenuList, setMenuList] = React.useState([])

  const editor = useRef(null)
  const [toolState, setToolState] = useState({ toolbind: {} })
  let formats = []

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    // .custom-table {
    //   border: "1px solid",
    // },
    handleManualDataAdd1();
    applicationSubmitOrResubmit()
    getAllParentIdOfMasterInput()

    getApplicatinSummary()
    getAllBusinessAndPromoter()
    getAllPromoter()
    handleState()
    getBusinessAndPromoterListByUser()
    getBusinessAndPromoterListByUserBank()
    getAllBusinessAndPromoterLoan()
    getBusinessAndPromoterListByUserWorkingCapital()
    getBusinessAndPromoterListByUserGST()
    getBusinessAndPromoterListByUserAsset()
    getBusinessAndPromoterListByUserFinance()
    getbusinessbyId()
    getNoOfColumnOrYear()
    getAllBusinessLoan()
    getbusinessbybusid()
    getConfirmation()

    financeRatios()
  }, [])

  const handledata = () => {
    props.handleBack()
  }

  // const SaveasDraft = () => {
  //   //props.handlecheck();
  //   handleSubmiting();
  // }

  const [submitValues, setSubmit] = React.useState()

  const [applicationRefNo, setApplicationRefNo] = React.useState('')
  const [clientBackground, setClientBackground] = React.useState('')
  const [promoterComments, setPromoterComments] = React.useState('')
  const [financialPandLcomments, setFinancialPandLcomments] = React.useState('')
  const [
    financialBalanceSheetcomments,
    setFinancialBalanceSheetcomments
  ] = React.useState('')
  const [financialRatiosComments, setFinancialRatiosComments] = React.useState(
    ''
  )
  const [bankingAnalysiscomments, setBankingAnalysiscomments] = React.useState(
    ''
  )
  const [gstAnalysiscomments, setGstAnalysiscomments] = React.useState('')
  const [assessedIncomecomments, setAssessedIncomecomments] = React.useState('')
  const [
    existingObligationscomments,
    setExistingObligationscomments
  ] = React.useState('')
  const [
    repaymentHistorycomments,
    setRepaymentHistorycomments
  ] = React.useState('')
  const [top5Customercomments, setTop5Customercomments] = React.useState('')
  const [top5Supplierscomments, setTop5Supplierscomments] = React.useState('')
  const [propertyCommets, setPropertyCommets] = React.useState('')
  const [applicationsummary, setApplicationsummary] = React.useState('')

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
  
  const summaryView = async () => {
    //;

    let data = {
      request_id: localData.id
    }
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/summaryView', data)
      .then(res => {
        console.log('data submitted', res.data)
        props.handleNext()
      })
      .catch(error => {
        console.log(error)
      })
  }
  const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
  let iduser = JSON.parse(checkuserloggeddata)
  console.log(iduser)
  const checkuserloggeduser = localStorage.getItem('userdata')
  let iduser1 = JSON.parse(checkuserloggeduser)

  //let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));

  const saveApplicationSummary = async () => {
    ////////;;

    let data = {
      user_id: iduser1.id,
      application_ref_no: iduser.reference_id,
      client_background: clientBackground,
      promoter_comments: promoterComments,
      financial_p_and_l_comments: financialPandLcomments,
      financial_balance_sheet_comments: financialBalanceSheetcomments,
      financial_ratios_comments: financialRatiosComments,
      banking_analysis_comments: bankingAnalysiscomments,
      gst_analysis_comments: gstAnalysiscomments,
      assessed_income_comments: assessedIncomecomments,
      existing_obligations_comments: existingObligationscomments,
      repayment_history_comments: repaymentHistorycomments,
      top_5_customer_comments: top5Customercomments,
      top_5_suppliers_comments: top5Supplierscomments,
      property_commets: propertyCommets,
      parent_id: iduser.business_id,
      parent_type_id: 1
    }
    console.log('save application data', data)
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/saveApplicationSummary',
        data
      )
      .then(result => {
        //;
        console.log("\n \n result", result)
        if (result.data.status == true) {
          console.log(result);
          setcolorMessage('success')
          setShowMessage('Application Submitted Successfully');
          setsavenextmessage(true);
          let arr = []
          setApplicationsummary(arr)
          summaryView();
          //   props.Next();
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getApplicatinSummary = async () => {
    //////////;;
    // const checkuserloggeddata = localStorage.getItem('userdata')
    // let checkuserid = JSON.parse(checkuserloggeddata);

    let data = {
      user_id: iduser.user_id
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getSummaryView`, data)
      .then(result => {
        let empArray = []
        result.data.data.map(item => {
          empArray.push({
            id: item.id,
            menu: item.menu,
            count: item.count,
            status: item.status,
            profile_stepper_flag: item.profile_stepper_flag
          })
        })

        setLoaderFlag(false)
        console.log('EmpArray', empArray)
        setMenuList(empArray)
        handleSubmitButton(empArray)
      })
      .catch(err => console.log('error in fetching  data', err))
  }
  const [businessList2, setBusinessList2] = React.useState([])

  const getBusinessAndPromoterListByUser = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: iduser.user_id
    }
    //////////;;
    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getfinanceMergeBusinessAndPromoter',
        data
      )
      .then(result => {
        setBusinessList2(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [businessList3, setBusinessList3] = React.useState([])

  const getBusinessAndPromoterListByUserBank = async () => {
    ////////;;
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getbankMergeBusinessAndPromoter',
        data
      )
      .then(result => {
        console.log('user data', result)
        if (result.data.data.length > 0) {
          setBusinessList3(result.data.data)
        }

        console.log('test1', result.data.data)
        console.log('test', businessList3)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [userBusinessList4, setUserBusinessList4] = React.useState([])

  const getAllBusinessAndPromoterLoan = async () => {
    const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.user_id)

    let data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        `server.php/api/getLoanMergeBusinessAndPromoter`,
        data
      )
      .then(result => {
        setUserBusinessList4(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [businessList5, setBusinessList5] = React.useState([])
  const getBusinessAndPromoterListByUserWorkingCapital = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getWorkingCapitalMergerBusinessAndPromoter',
        data
      )
      .then(result => {
        setBusinessList5(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [businessList6, setBusinessList6] = React.useState([])

  const getBusinessAndPromoterListByUserGST = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getGstDetailsMergeBusinessAndPromoter',
        data
      )
      .then(result => {
        setBusinessList6(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmitButton = values => {
    //////////;;
    for (var i = 0; i < values.length; i++) {
      console.log(values[i].count)
      if (values[i].count == 0) {
        setSubmit(false)
        break
      } else {
        setSubmit(true)
      }
    }
  }

  const [businessList7, setBusinessList7] = React.useState([])
  const getBusinessAndPromoterListByUserAsset = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

    const data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getAssetMergeBusinessAndPromoter',
        data
      )
      .then(result => {
        console.log('user data', result)
        let res = result.data.data

        setBusinessList7(res)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [businessList8, setBusinessList8] = React.useState([])

  const [newBus, setNewBus] = React.useState()
  const getBusinessAndPromoterListByUserFinance = async () => {
    ////////;;
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    const data = {
      user_id: checkuserid.user_id
    }
    let api_path =
      apiCalling.coderscorner +
      'server.php/api/getFinanceDetailsBusinessAndPromoterById'
    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/getFinanceDetailsBusinessAndPromoterById',
        data
      )
      .then(result => {
        console.log('finance')
        console.log(result)
        // //setShowBusinessPromoterList(true);
        // setLoaderFlag(false);
        setBusinessList8(result.data.data.businessList)
        // setNewBus(result.data.data.businessList);
      })
      .catch(error => {
        ErrorCatch(
          checkuserid.user_id,
          error,
          api_path,
          'post',
          data,
          checkuserid.user_id,
          checkuserid.user_id
        )
        ////////console.log(error);
      })
  }

  //const checkuserloggeddata = localStorage.getItem("userdata");
  const [userBusinessList, setUserBusinessList] = React.useState([])
  //let iduser = JSON.parse(checkuserloggeddata);

  const getAllBusinessAndPromoter = async () => {
    // setidofuser(iduser.id);
    ////////;;
    let businessArray = []
    let promoterArray = []
    let data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner +
        `server.php/api/getBusinessAndPromoterByUserId`,
        data
      )
      .then(result => {
        if (result.data.data.businessList.length > 0) {
          result.data.data.businessList.map((item, i) => {
            businessArray.push({
              key: item.id,
              id: item.id,
              value: item.id,
              text: item.name,
              name: item.name,
              entity_type: item.entity_type,
              bpan: item.bpan,
              gst: item.gst,
              state_name: item.state_name,
              city_name: item.city_name,
              industry_group_name: item.industry_group_name
            })
          })
        }
        // setUserPromoterList(promoterArray);
        setUserBusinessList(businessArray)
        // getAllLoansDetailsByUserId(businessArray[0].id)
        console.log(businessArray)
        console.log(promoterArray)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [userPromoterList, setUserPromoterList] = React.useState([])
  const getAllPromoter = async () => {
    ////////;;
    let promoterArray = []
    let data = {
      user_id: iduser.user_id
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getPromoterByUserId`,
        data
      )
      .then(result => {
        if (result.data.data.length > 0) {
          result.data.data.map((item, i) => {
            promoterArray.push({
              key: item.id,
              id: item.id,
              value: item.id,
              text: item.name,
              name: item.first_name + ' ' + item.last_name,
              email: item.email,
              mobile: item.mobile,
              type: item.type,
              share: item.share_percent
            })
          })
        }
        setUserPromoterList(promoterArray)

        console.log(promoterArray)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const goToPage = i => {
    props.handleBack(i)
  }
  const handleState = () => {
    //////////;;
    for (let i = 0; i < userBusinessList.length; i++) {
      setState([false])
    }
  }
  const [state, setState] = React.useState([])

  const toggle = i => {
    const newArr = [...state]
    newArr[i] = !newArr[i]
    setState(newArr)
    //setState(!state);
  }
  const [regDate, setRegDate] = React.useState('')
  const [idofuser, setidofuser] = React.useState([])
  const [industryname, setindustryname] = React.useState([])

  const [showPreview, setShowPreview] = React.useState(false)
  const getrequestDetail = localStorage.getItem('rquestIdDetails')
  const [editinitialValue, setEditInitialValue] = React.useState({
    id: '',
    businessName: '',
    gst: '',
    bpan: '',
    industry: ''
  })

  var industrynamearray = []
  const industrynamecheck = async groupid => {
    const industrynamedata = {
      token: 'qwerty1234',
      industry_group_id: groupid
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getIndustry`,
        industrynamedata
      )
      .then(res => {
        res.data.data.map(item => {
          //	////console.log('item', item)
          industrynamearray.push({
            key: item.short_name,
            value: item.id,
            //flag: item.name,
            text: item.name
          })
        })
        console.log(industrynamearray)
        setindustryname(industrynamearray)
        //	////console.log("industrynamearrayse", industrynamearray);
      })
      .catch(er => {
        ////console.log("no data  ", er);
      })
  }

  const [getloanbyBusid, setLoanbyBusId] = React.useState([])
  const getbusinessbybusid = async () => {
    let busId = JSON.parse(getrequestDetail)
    let businessid = busId.business_id

    let getbusinesid = {
      parent_id: businessid
    }

    let api_path =
      apiCalling.coderscorner + `server.php/api/getloanByBusinessId`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getloanByBusinessId`,
        getbusinesid
      )
      .then(res => {
        console.log('get loan by business id')
        console.log(res)
        //if (res.data.status == true) {
        let busdata = res.data.data
        setLoanbyBusId(busdata)
        //}
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          getbusinesid,
          idofuser,
          idofuser
        )

        ////console.log("no pincode sorry ", er);
      })
  }

  const getbusinessbyId = async () => {
    // ////////;;
    let busId = JSON.parse(getrequestDetail)
    let businessid = busId.business_id
    let business_name = busId.business_name

    let getbusinesid = {
      bus_id: businessid
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`,
        getbusinesid
      )
      .then(res => {
        console.log('get business by id')
        console.log(res)
        let busdata = res.data.data
        industrynamecheck(busdata.industry_group_id)
        let setup_date = moment(busdata.setup_date).format('YYYY-MM-DD')
        console.log(industryname)
        setEditInitialValue({
          ...editinitialValue,
          id: businessid,
          businessName: business_name,
          businessPan: busdata.BPAN,
          gst: busdata.gst,
          industry: busdata.industry_name,
          entity: busdata.entity,
          date: setup_date,
          premisesArea: busdata.bus_premises_area
        })
      })
      .catch(er => {
        ErrorCatch(
          idofuser,
          er,
          api_path,
          'post',
          getbusinesid,
          idofuser,
          idofuser
        )
        ////console.log("no pincode sorry ", er);
      })
  }
  // const getbusinessbyId = async () => {
  //   ////////;;
  //   let busId = JSON.parse(getrequestDetail);
  //   let businessid = busId.business_id;
  //   let business_name = busId.business_name;

  //   let api_path = apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`;
  //   await axios.post(apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`, getbusinesid).then((res) => {
  //     console.log("get business by id");
  //     console.log(res);
  //     let busdata = res.data.data;
  //     industrynamecheck(busdata.industry_group_id);
  //     let setup_date = moment(busdata.setup_date).format('YYYY-MM-DD');
  //     console.log(industryname);
  //     setEditInitialValue({
  // 			...editinitialValue,
  // 			id: businessid,
  // 			businessName: business_name,
  //       businessPan: busdata.BPAN,
  //       gst: busdata.gst,
  //       industry: busdata.industry_name,
  //       entity:busdata.entity,
  //       date: setup_date,
  //       premisesArea: busdata.bus_premises_area,
  //     });
  //   })
  //   .catch((er) => {
  //     ErrorCatch(idofuser, er, api_path, 'post', getbusinesid, idofuser, idofuser);
  //     ////console.log("no pincode sorry ", er);
  //   });
  // }

  const [showPromoterComment, setPromoterComment] = React.useState(false)
  const handlePromoterComment = () => {
    console.log('clicked')
    if (!showPromoterComment) {
      setPromoterComment(true)
    } else {
      setPromoterComment(false)
    }
  }
  const [showfinPLComment, setfinPLComment] = React.useState(false)
  const handlefinPLComment = () => {
    console.log('clicked')
    if (!showfinPLComment) {
      setfinPLComment(true)
    } else {
      setfinPLComment(false)
    }
  }
  const [showfinratioComment, setfinratioComment] = React.useState(false)
  const handlefinratioComment = () => {
    console.log('clicked')
    if (!showfinratioComment) {
      setfinratioComment(true)
    } else {
      setfinratioComment(false)
    }
  }
  const [showBalsheetComment, setBalsheetComment] = React.useState(false)
  const handleBalsheetComment = () => {
    console.log('clicked')
    if (!showBalsheetComment) {
      setBalsheetComment(true)
    } else {
      setBalsheetComment(false)
    }
  }
  const [showBankComment, setBankComment] = React.useState(false)
  const handleBankComment = () => {
    console.log('clicked')
    if (!showBankComment) {
      setBankComment(true)
    } else {
      setBankComment(false)
    }
  }
  const [showGSTComment, setGSTComment] = React.useState(false)
  const handleGSTComment = () => {
    console.log('clicked')
    if (!showGSTComment) {
      setGSTComment(true)
    } else {
      setGSTComment(false)
    }
  }
  const [showAssesedIncomeComment, setAssesedIncomeComment] = React.useState(
    false
  )
  const handleAssesedIncomeComment = () => {
    console.log('clicked')
    if (!showAssesedIncomeComment) {
      setAssesedIncomeComment(true)
    } else {
      setAssesedIncomeComment(false)
    }
  }
  const [showLoanComment, setLoanComment] = React.useState(false)
  const handleLoanComment = () => {
    console.log('clicked')
    if (!showLoanComment) {
      setLoanComment(true)
    } else {
      setLoanComment(false)
    }
  }
  const [
    showRepaymentHistoryComment,
    setRepaymentHistoryComment
  ] = React.useState(false)
  const handleRepaymentHistoryComment = () => {
    console.log('clicked')
    if (!showRepaymentHistoryComment) {
      setRepaymentHistoryComment(true)
    } else {
      setRepaymentHistoryComment(false)
    }
  }
  const [showTop5CustomerComment, setTop5CustomerComment] = React.useState(
    false
  )
  const handleTop5CustomerComment = () => {
    console.log('clicked')
    if (!showTop5CustomerComment) {
      setTop5CustomerComment(true)
    } else {
      setTop5CustomerComment(false)
    }
  }
  const [showTop5SupplierComment, setTop5SupplierComment] = React.useState(
    false
  )
  const handleTop5SupplierComment = () => {
    console.log('clicked')
    if (!showTop5SupplierComment) {
      setTop5SupplierComment(true)
    } else {
      setTop5SupplierComment(false)
    }
  }
  const [
    showPropertyDetailsComment,
    setPropertyDetailsComment
  ] = React.useState(false)
  const handlePropertyDetailsComment = () => {
    console.log('clicked')
    if (!showPropertyDetailsComment) {
      setPropertyDetailsComment(true)
    } else {
      setPropertyDetailsComment(false)
    }
  }
  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%'
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: 'auto'
  }

  const table_Action = {
    fontWeight: '600',
    fontSize: '100%',
    textAlign: 'center'
  }

  const customTable = {
    border: '1px solid'
    // width: "35%"
  }

  const [promoOrBus, setPromoOrBus] = React.useState('')
  const [parent_id1, setParent_id] = React.useState('')
  const [parent_name, setParent_name] = React.useState('')
  const [financeParameterDetails, setFinanceParameterDetails] = React.useState(
    []
  )

  let fyear = 'year'
  let fval = 'value'
  const [showPreview1, setShowPreview1] = React.useState(false)

  const [noOfColumn, setNoOfColumn] = React.useState([])
  const getNoOfColumnOrYear = async () => {
    let data = {
      loanType: localData.business_channel_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getLast3FinanceYear',
        data
      )
      .then(result => {
        if (result.data.status == true) {
          setNoOfColumn(result.data.data)
        }
      })
  }
  const handleManualDataAdd = async (item, promoOrBus) => {
    ////
    //setPromoOrBus(promoOrBus);
    //setParent_id(item.id);
    //setParent_name(item.name);
    //setLoaderFlag(true);
    //////////;;
    //setHeader(false);
    let ptId = promoOrBus
    let parentData = {
      parent_type_id: ptId,
      parent_id: item.id,
      parent_name: item.name
    }
    // setParentInfoData(parentData);
    await axios
      .post(
        apiCalling.coderscorner +
        `server.php/api/getFinanceHeaderParameterDetailsByParentId`,
        parentData
      )
      .then(result => {
        console.log(result)
        if (result.data.status == true) {
          setFinanceParameterDetails(result.data.data)
          console.log('test', financeParameterDetails)
          // let obj = '{';
          // for (let i = 0; i < result.data.data.length; i++) {
          //   for (let j = 0; j < result.data.data[i].headerDetails.length; j++) {
          //     obj += '"selected' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameter) + ',';
          //     obj += '"' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameterValue) + ',';
          //   }
          // }
          // obj = obj.slice(0, -1);
          // obj += '}';
          // setFinanceDetailsDynamicInitialValue(JSON.parse(obj));
          // setLoaderFlag(false);
          // setShowExtendedForm(true);
          // setHeader(false);
          // setShowBusinessPromoterList(false);
        } else {
          // setLoaderFlag(false);
          // setShowExtendedForm(true);
          // setShowBusinessPromoterList(false);
          // getExtendedFinanceHeader();
        }
      })
  }

  const [loanHeaderOpen, setLoanHeaderOpen] = useState([])
  //const [businesstable, setbusinesstable] = useState(false);

  const [userBusinessList1, setUserBusinessList1] = React.useState([])
  const getAllBusinessLoan = async item1 => {
    ////////;;
    const checkuserloggeddata = localStorage.getItem('rquestIdDetails')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.user_id)
    setidofuser(iduser.user_id)
    let businessArray = []
    let promoterArray = []
    let data = {
      user_id: iduser.user_id
    }
    let api_path =
      apiCalling.coderscorner +
      `server.php/api/getLoansDetailsBusinessAndPromoterById`
    await axios
      .post(
        apiCalling.coderscorner +
        `server.php/api/getLoansDetailsBusinessAndPromoterById`,
        data
      )
      .then(result => {
        console.log('get loan business')
        console.log(result.data.data.businessList)
        setUserBusinessList1(result.data.data.businessList)
        // let busLoanArr = [];
        // for (let i = 0; i < result.data.data.businessList.length; i++) {
        //   busLoanArr.push(false);
        // }
        // setLoanHeaderOpen(busLoanArr);
      })
      .catch(error => {
        ErrorCatch(
          iduser.user_id,
          error,
          api_path,
          'post',
          data,
          iduser.user_id,
          iduser.user_id
        )
        console.log(error)
      })
  }

  /// generate pdf using html2canvas and jsPDf
  const generatePDF = async () => {
    // setReviewDownload(true)
    // return

    setPdfLoader(true)

    // defining varibale for widh and height of each photo on each page
    let img1 = ''
    let imgProps1 = ''
    let pdfWidth1 = ''
    let pdfHeight1 = ''
    let img2 = ''
    let imgProps2 = ''
    let pdfWidth2 = ''
    let pdfHeight2 = ''
    let img3 = ''
    let imgProps3 = ''
    let pdfWidth3 = ''
    let pdfHeight3 = ''
    let img4 = ''
    let imgProps4 = ''
    let pdfWidth4 = ''
    let pdfHeight4 = ''
    let img5 = ''
    let imgProps5 = ''
    let pdfWidth5 = ''
    let pdfHeight5 = ''
    let img6 = ''
    let imgProps6 = ''
    let pdfWidth6 = ''
    let pdfHeight6 = ''
    let img7 = ''
    let imgProps7 = ''
    let pdfWidth7 = ''
    let pdfHeight7 = ''
    let img8 = ''
    let imgProps8 = ''
    let pdfWidth8 = ''
    let pdfHeight8 = ''
    let img9 = ''
    let imgProps9 = ''
    let pdfWidth9 = ''
    let pdfHeight9 = ''
    let img10 = ''
    let imgProps10 = ''
    let pdfWidth10 = ''
    let pdfHeight10 = ''

    //method for converting hmtl page to image
    await html2canvas(document.getElementById('div1')).then(canvas => {
      var pdf1 = new jsPDF('p', 'pt', 'letter')
      img1 = canvas.toDataURL('image/png')
      imgProps1 = pdf1.getImageProperties(img1)
      pdfWidth1 = pdf1.internal.pageSize.getWidth()
      pdfHeight1 = (imgProps1.height * pdfWidth1) / imgProps1.width
    })

    await html2canvas(document.getElementById('div2')).then(canvas => {
      var pdf = new jsPDF('p', 'pt', 'letter')
      img2 = canvas.toDataURL('image/png')
      imgProps2 = pdf.getImageProperties(img2)
      pdfWidth2 = pdf.internal.pageSize.getWidth()
      pdfHeight2 = (imgProps2.height * pdfWidth2) / imgProps2.width
    })

    await html2canvas(document.getElementById('div3')).then(canvas => {
      var pdf3 = new jsPDF('p', 'pt', 'letter')
      img3 = canvas.toDataURL('image/png')
      imgProps3 = pdf3.getImageProperties(img3)
      pdfWidth3 = pdf3.internal.pageSize.getWidth()
      pdfHeight3 = (imgProps3.height * pdfWidth3) / imgProps3.width
    })

    await html2canvas(document.getElementById('div4')).then(canvas => {
      var pdf4 = new jsPDF('p', 'pt', 'letter')
      img4 = canvas.toDataURL('image/png')
      imgProps4 = pdf4.getImageProperties(img4)
      pdfWidth4 = pdf4.internal.pageSize.getWidth()
      pdfHeight4 = (imgProps4.height * pdfWidth4) / imgProps4.width
    })

    await html2canvas(document.getElementById('div5')).then(canvas => {
      var pdf5 = new jsPDF('p', 'pt', 'letter')
      img5 = canvas.toDataURL('image/png')
      imgProps5 = pdf5.getImageProperties(img5)
      pdfWidth5 = pdf5.internal.pageSize.getWidth()
      pdfHeight5 = (imgProps5.height * pdfWidth5) / imgProps5.width
    })

    await html2canvas(document.getElementById('div6')).then(canvas => {
      var pdf6 = new jsPDF('p', 'pt', 'letter')
      img6 = canvas.toDataURL('image/png')
      imgProps6 = pdf6.getImageProperties(img6)
      pdfWidth6 = pdf6.internal.pageSize.getWidth()
      pdfHeight6 = (imgProps6.height * pdfWidth6) / imgProps6.width
    })

    await html2canvas(document.getElementById('div7')).then(canvas => {
      var pdf7 = new jsPDF('p', 'pt', 'letter')
      img7 = canvas.toDataURL('image/png')
      imgProps7 = pdf7.getImageProperties(img7)
      pdfWidth7 = pdf7.internal.pageSize.getWidth()
      pdfHeight7 = (imgProps7.height * pdfWidth7) / imgProps7.width
    })

    await html2canvas(document.getElementById('div8')).then(canvas => {
      var pdf8 = new jsPDF('p', 'pt', 'letter')
      img8 = canvas.toDataURL('image/png')
      imgProps8 = pdf8.getImageProperties(img8)
      pdfWidth8 = pdf8.internal.pageSize.getWidth()
      pdfHeight8 = (imgProps8.height * pdfWidth8) / imgProps8.width
    })

    await html2canvas(document.getElementById('div9')).then(canvas => {
      var pdf9 = new jsPDF('p', 'pt', 'letter')
      img9 = canvas.toDataURL('image/png')

      imgProps9 = pdf9.getImageProperties(img9)
      pdfWidth9 = pdf9.internal.pageSize.getWidth()
      pdfHeight9 = (imgProps9.height * pdfWidth9) / imgProps9.width
    })

    await html2canvas(document.getElementById('div10')).then(canvas => {
      var pdf10 = new jsPDF('p', 'pt', 'letter')
      img10 = canvas.toDataURL('image/png')
      imgProps10 = pdf10.getImageProperties(img10)
      pdfWidth10 = pdf10.internal.pageSize.getWidth()
      pdfHeight10 = (imgProps10.height * pdfWidth10) / imgProps10.width
    })

    // adding image to the doc file (image to pdf convertion)
    ////////;;
    var doc = new jsPDF('p', 'pt', 'letter')
    doc.addImage(img1, 'PNG', 20, 0, pdfWidth1, pdfHeight1) // A4 sizes
    doc.addPage()
    doc.addImage(img2, 'PNG', 20, 0, pdfWidth2, pdfHeight2) //img2
    doc.addPage()
    doc.addImage(img3, 'PNG', 0, 0, pdfWidth3, pdfHeight3) // img3
    doc.addPage()
    doc.addImage(img4, 'PNG', 0, 0, pdfWidth3, pdfHeight4) // img3
    doc.addPage()
    doc.addImage(img5, 'PNG', 0, 0, pdfWidth5, pdfHeight5) // img5
    doc.addPage()
    doc.addImage(img6, 'PNG', 0, 0, pdfWidth6, pdfHeight6) // img6
    doc.addPage()
    doc.addImage(img7, 'PNG', 0, 0, pdfWidth7, pdfHeight7) // img7
    doc.addPage()
    doc.addImage(img8, 'PNG', 0, 0, pdfWidth8 - 10, pdfHeight8) // img8
    doc.addPage()
    doc.addImage(img9, 'PNG', 0, 0, pdfWidth9 - 10, pdfHeight9) // img9
    doc.addPage()
    doc.addImage(img10, 'PNG', 0, 0, pdfWidth10 - 10, pdfHeight10) // img10

    doc.save(localData.reference_id)
    setPdfLoader(false)
  }

  /// not using anywhere
  const getPdf = () => {
    ////////;;
    // htmlToImage.toPng(document.getElementById('demo'), { quality: 1.00 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'my-image-name.jpeg';
    //     const pdf = new jsPDF();
    //     // const imgProps = pdf.getImageProperties(dataUrl);
    //     // const pdfWidth = pdf.internal.pageSize.getWidth();
    //     // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     // pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth,pdfHeight);
    //     // pdf.save(localData.reference_id);
    //   });

    //   html2canvas(document.getElementById('demo')).then(canvas => {
    //     document.body.appendChild(canvas);  // if you want see your screenshot in body.
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     const imgProps= pdf.getImageProperties(imgData);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     pdf.addImage(imgData, 'PNG', 0, 0,pdfWidth,pdfHeight);
    //     pdf.save("download.pdf");
    // });

    //----------------------------------------------
    /* working and perfect code */

    // html2canvas(document.querySelector('.div1')).then(canvas => {
    //   //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
    //   const imgData = canvas.toDataURL('image/png');
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   var doc = new jsPDF('p', 'mm');
    //   var position = 0;
    //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save(localData.reference_id);
    // });

    var pdf = new jsPDF('p', 'mm')

    html2canvas(document.querySelector('.demo')).then(canvas => {
      let arr = ['.div1', '.div2']
      for (let i = 0; i < arr.length; i++) {
        html2canvas(document.querySelector(arr[i])).then(canvas => {
          document.body.appendChild(canvas) // if you want see your screenshot in body.
          const imgData = canvas.toDataURL('image/png')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
          pdf.addPage()
        })
      }
    })

    pdf.save('download.pdf')
  }

  const [pdfLoader, setPdfLoader] = React.useState(false)

  const [disableApplication, setDisableApplication] = React.useState(true)

  const getConfirmation = async () => {
    ////
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let data = {
      user_id: checkuserid.user_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/validationforcustomersubmit',
        data
      )
      .then(result => {
        ////////;;
        console.log(result.data.status)
        setDisableApplication(result.data.status)
      })
      .catch(error => {
        console.log(error)
      })
  }

  /// checking weather resusmission or first time sunbmission

  const [resubmit, setResubmit] = React.useState(true)
  const applicationSubmitOrResubmit = async () => {
    ////////;;

    let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

    let data = {
      ref_no: localData.reference_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/applicationSubmitOrResubmit',
        data
      )
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [resubmit1, setResubmit1] = React.useState(false)
  const [parameter_idArray, setParameter_idArray] = React.useState([])
  const getAllParentIdOfMasterInput = async () => {
    //////;;
    let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
    let data = {
      //  ref_no: localData.user_id
      user_id: localData.id
    }
    //////;;
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getAllParentIdOfMasterInput',
        data
      )
      .then(result => {
        //////;;
        console.log(result)
        if (result.data.status === true) {
          setResubmit1(true)
          setParameter_idArray(result.data.data)
        } else {
          setResubmit(false)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // console.log(JSON.parse(localStorage.getItem('fatcheckArray')));
  // console.log(JSON.parse(localStorage.getItem('fatcheckArray')));

  // let tempParaIdArray= JSON.parse(localStorage.getItem('fatcheckArray')).concat(JSON.parse(localStorage.getItem('finscoreArray')));
  // console.log(tempParaIdArray);

  const submitAnswer = async () => {
    //////;;
    let tempArr = []
    console.log(tempArr)
    let yearArray = []
    let paremeterIdArray = []
    let parameter_override_flagArray = []
    let parameter_evaluation_override_statusArray = []

    yearArray = Array(parameter_idArray.length).fill('2022')
    parameter_override_flagArray = Array(parameter_idArray.length).fill('y')

    parameter_idArray.map(item => {
      //  if (item.answer === true) {
      if (1 == true) {
        if (item != 8) {
          parameter_evaluation_override_statusArray.push('yes')
        } else {
          parameter_evaluation_override_statusArray.push('ok')
        }
      } else {
        if (item != 8) {
          parameter_evaluation_override_statusArray.push('no')
        } else {
          parameter_evaluation_override_statusArray.push('sorry')
        }
      }
    })

    let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

    let data = {
      folder_no: localData.folder_no,
      year: yearArray,
      parameter_id: parameter_idArray,
      parameter_override_flag: parameter_override_flagArray,
      parameter_evaluation_override_status: parameter_evaluation_override_statusArray
    }
    //////;;

    console.log(data)
    axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/calleligibilityForResubmission',
        data
      )
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const submitAnswerTemp = async () => {
    //////;;

    let tempArr = []
    let fatcheckArray = []
    let finscoreArray = []

    if (localStorage.getItem('fatcheckArray')) {
      fatcheckArray = JSON.parse(localStorage.getItem('fatcheckArray'))
    }

    if (localStorage.getItem('finscoreArray')) {
      finscoreArray = JSON.parse(localStorage.getItem('finscoreArray'))
    }

    let parameter_evaluation_override_statusArray = []

    let tempArrparaidarra = []
    fatcheckArray.map(item => {
      tempArrparaidarra.push(item)
      parameter_evaluation_override_statusArray.push('yes')
    })

    finscoreArray.map(item => {
      tempArrparaidarra.push(item)
      parameter_evaluation_override_statusArray.push('ok')
    })

    console.log(tempArr)
    let yearArray = []
    //let paremeterIdArray = [];
    let parameter_override_flagArray = []

    yearArray = Array(tempArrparaidarra.length).fill('2022')
    parameter_override_flagArray = Array(tempArrparaidarra.length).fill('y')
    let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

    let data = {
      folder_no: localData.folder_no,
      year: yearArray,
      parameter_id: tempArrparaidarra,
      parameter_override_flag: parameter_override_flagArray,
      parameter_evaluation_override_status: parameter_evaluation_override_statusArray
    }
    //////;;

    console.log(data)

    await axios
      .post(
        apiCalling.coderscorner +
        'server.php/api/callEligibilityForResubmission',
        data
      )
      .then(result => {
        //////;;
        console.log(result)
        console.log('hi')
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [finRatios, setFinanceRatios] = React.useState([])

  const financeRatios = () => {
    //////;

    let data = [
      {
        parameter_head: '1PnL',
        display_parameter_head: 'Profit Loss Accounts',
        headerDetails: [
          {
            parameter_level_1: '10TaxPaid',
            display_parameter_level_1: 'Tax Paid',
            label1Details: [
              {
                value: 22,
                label: 'Tax Paid'
              }
            ],
            selectedParameter: [
              {
                value: 22,
                label: 'Tax Paid'
              }
            ],
            selectedParameterValue: [
              {
                id: 22,
                display_parameter_level_2: 'Tax Paid',
                year1: '2021-2022',
                year1value: '81',
                year2: '2020-2021',
                year2value: '81'
              }
            ]
          },
          {
            parameter_level_1: '1TotalIncome',
            display_parameter_level_1: 'Total Income',
            label1Details: [
              {
                value: 41,
                label: 'Commission & Brokerage'
              },
              {
                value: 45,
                label: 'Freight charges'
              },
              {
                value: 43,
                label: 'Job work charges'
              },
              {
                value: 46,
                label: 'Other Operating Income'
              },
              {
                value: 44,
                label: 'Professional Fees'
              },
              {
                value: 40,
                label: 'Sales - Deemed Exports'
              },
              {
                value: 39,
                label: 'Sales - Direct Exports'
              },
              {
                value: 38,
                label: 'Sales Local'
              },
              {
                value: 42,
                label: 'Service Charges'
              }
            ],
            selectedParameter: [
              {
                value: 38,
                label: 'Sales Local'
              },
              {
                value: 39,
                label: 'Sales - Direct Exports'
              },
              {
                value: 40,
                label: 'Sales - Deemed Exports'
              },
              {
                value: 41,
                label: 'Commission & Brokerage'
              },
              {
                value: 42,
                label: 'Service Charges'
              },
              {
                value: 43,
                label: 'Job work charges'
              },
              {
                value: 44,
                label: 'Professional Fees'
              },
              {
                value: 45,
                label: 'Freight charges'
              },
              {
                value: 46,
                label: 'Other Operating Income'
              }
            ],
            selectedParameterValue: [
              {
                id: 38,
                display_parameter_level_2: 'Sales Local',
                year1: '2021-2022',
                year1value: '68',
                year2: '2020-2021',
                year2value: '6999999'
              },
              {
                id: 39,
                display_parameter_level_2: 'Sales - Direct Exports',
                year1: '2021-2022',
                year1value: '9',
                year2: '2020-2021',
                year2value: '9'
              },
              {
                id: 40,
                display_parameter_level_2: 'Sales - Deemed Exports',
                year1: '2021-2022',
                year1value: '5',
                year2: '2020-2021',
                year2value: '68'
              },
              {
                id: 41,
                display_parameter_level_2: 'Commission & Brokerage',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 42,
                display_parameter_level_2: 'Service Charges',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '89'
              },
              {
                id: 43,
                display_parameter_level_2: 'Job work charges',
                year1: '2021-2022',
                year1value: '99',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 44,
                display_parameter_level_2: 'Professional Fees',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '799'
              },
              {
                id: 45,
                display_parameter_level_2: 'Freight charges',
                year1: '2021-2022',
                year1value: '9',
                year2: '2020-2021',
                year2value: '9'
              },
              {
                id: 46,
                display_parameter_level_2: 'Other Operating Income',
                year1: '2021-2022',
                year1value: '9',
                year2: '2020-2021',
                year2value: '99'
              }
            ]
          },
          {
            parameter_level_1: '2DirectCost',
            display_parameter_level_1: 'Direct Cost',
            label1Details: [
              {
                value: 105,
                label: 'Closing Stock'
              },
              {
                value: 107,
                label: 'Consumable Stores & Spares'
              },
              {
                value: 47,
                label: 'Cost of Goods Sold'
              },
              {
                value: 49,
                label: 'Direct manufacturing Cost'
              },
              {
                value: 109,
                label: 'Factory Rent'
              },
              {
                value: 104,
                label: 'Opening Stock'
              },
              {
                value: 111,
                label: 'Other Manufacturing Expenses'
              },
              {
                value: 108,
                label: 'Power & Fuel'
              },
              {
                value: 106,
                label: 'Purchases'
              },
              {
                value: 110,
                label: 'Repairs & Maintenance'
              },
              {
                value: 48,
                label: 'Wages & Salary'
              }
            ],
            selectedParameter: [
              {
                value: 47,
                label: 'Cost of Goods Sold'
              },
              {
                value: 48,
                label: 'Wages & Salary'
              },
              {
                value: 49,
                label: 'Direct manufacturing Cost'
              },
              {
                value: 104,
                label: 'Opening Stock'
              },
              {
                value: 105,
                label: 'Closing Stock'
              },
              {
                value: 106,
                label: 'Purchases'
              },
              {
                value: 107,
                label: 'Consumable Stores & Spares'
              },
              {
                value: 108,
                label: 'Power & Fuel'
              },
              {
                value: 109,
                label: 'Factory Rent'
              },
              {
                value: 110,
                label: 'Repairs & Maintenance'
              },
              {
                value: 111,
                label: 'Other Manufacturing Expenses'
              }
            ],
            selectedParameterValue: [
              {
                id: 47,
                display_parameter_level_2: 'Cost of Goods Sold',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 48,
                display_parameter_level_2: 'Wages & Salary',
                year1: '2021-2022',
                year1value: '7878',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 49,
                display_parameter_level_2: 'Direct manufacturing Cost',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '87'
              },
              {
                id: 104,
                display_parameter_level_2: 'Opening Stock',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 105,
                display_parameter_level_2: 'Closing Stock',
                year1: '2021-2022',
                year1value: '87',
                year2: '2020-2021',
                year2value: '878'
              },
              {
                id: 106,
                display_parameter_level_2: 'Purchases',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 107,
                display_parameter_level_2: 'Consumable Stores & Spares',
                year1: '2021-2022',
                year1value: '78',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 108,
                display_parameter_level_2: 'Power & Fuel',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 109,
                display_parameter_level_2: 'Factory Rent',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 110,
                display_parameter_level_2: 'Repairs & Maintenance',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 111,
                display_parameter_level_2: 'Other Manufacturing Expenses',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '8'
              }
            ]
          },
          {
            parameter_level_1: '3IndirectCost',
            display_parameter_level_1: 'Indirect Cost',
            label1Details: [
              {
                value: 56,
                label: 'Administrative Expenses'
              },
              {
                value: 54,
                label: 'Electricity Expenses'
              },
              {
                value: 51,
                label: 'Insurance Cost'
              },
              {
                value: 103,
                label: 'Office Rent'
              },
              {
                value: 53,
                label: 'Printing & Stationery'
              },
              {
                value: 50,
                label: 'Salary & Employee Benefits'
              },
              {
                value: 55,
                label: 'Selling and distribution expenses'
              },
              {
                value: 52,
                label: 'Travelling & Conveyance'
              }
            ],
            selectedParameter: [
              {
                value: 50,
                label: 'Salary & Employee Benefits'
              },
              {
                value: 51,
                label: 'Insurance Cost'
              },
              {
                value: 54,
                label: 'Electricity Expenses'
              },
              {
                value: 56,
                label: 'Administrative Expenses'
              }
            ],
            selectedParameterValue: [
              {
                id: 50,
                display_parameter_level_2: 'Salary & Employee Benefits',
                year1: '2021-2022',
                year1value: '48',
                year2: '2020-2021',
                year2value: '98'
              },
              {
                id: 51,
                display_parameter_level_2: 'Insurance Cost',
                year1: '2021-2022',
                year1value: '70000',
                year2: '2020-2021',
                year2value: '25000'
              },
              {
                id: 54,
                display_parameter_level_2: 'Electricity Expenses',
                year1: '2021-2022',
                year1value: '45',
                year2: '2020-2021',
                year2value: '69'
              },
              {
                id: 56,
                display_parameter_level_2: 'Administrative Expenses',
                year1: '2021-2022',
                year1value: '150000',
                year2: '2020-2021',
                year2value: '300000'
              }
            ]
          },
          {
            parameter_level_1: '4Profit',
            display_parameter_level_1: 'Profit',
            label1Details: [
              {
                value: 20,
                label: 'Profit'
              }
            ],
            selectedParameter: [
              {
                value: 20,
                label: 'Profit'
              }
            ],
            selectedParameterValue: [
              {
                id: 20,
                display_parameter_level_2: 'Profit',
                year2: '2020-2021',
                year2value: '88'
              }
            ]
          },
          {
            parameter_level_1: '5ManagementRenumeration',
            display_parameter_level_1: 'Management Renumeration',
            label1Details: [
              {
                value: 58,
                label: 'Interest on Loans to Directors & family'
              },
              {
                value: 57,
                label: 'Interest to Partners'
              },
              {
                value: 59,
                label: 'Salary to Partners/Directors'
              }
            ],
            selectedParameter: [
              {
                value: 57,
                label: 'Interest to Partners'
              },
              {
                value: 58,
                label: 'Interest on Loans to Directors & family'
              },
              {
                value: 59,
                label: 'Salary to Partners/Directors'
              }
            ],
            selectedParameterValue: [
              {
                id: 57,
                display_parameter_level_2: 'Interest to Partners',
                year1: '2021-2022',
                year1value: '97',
                year2: '2020-2021',
                year2value: '87'
              },
              {
                id: 58,
                display_parameter_level_2:
                  'Interest on Loans to Directors & family',
                year1: '2021-2022',
                year1value: '78',
                year2: '2020-2021',
                year2value: '78'
              },
              {
                id: 59,
                display_parameter_level_2: 'Salary to Partners/Directors',
                year1: '2021-2022',
                year1value: '53',
                year2: '2020-2021',
                year2value: '76'
              }
            ]
          },
          {
            parameter_level_1: '6TotalNonOperatingIncome',
            display_parameter_level_1: 'Total Non Operating Income',
            label1Details: [
              {
                value: 65,
                label: 'Forex Income (Expenses)'
              },
              {
                value: 60,
                label: 'Interest and Dividend'
              },
              {
                value: 66,
                label: 'Other Income'
              },
              {
                value: 114,
                label: 'Other Non-Operating Income'
              },
              {
                value: 63,
                label: 'Profit from Sale of Assets'
              },
              {
                value: 62,
                label: 'Rental Income'
              }
            ],
            selectedParameter: [
              {
                value: 60,
                label: 'Interest and Dividend'
              },
              {
                value: 62,
                label: 'Rental Income'
              },
              {
                value: 63,
                label: 'Profit from Sale of Assets'
              },
              {
                value: 65,
                label: 'Forex Income (Expenses)'
              },
              {
                value: 66,
                label: 'Other Income'
              },
              {
                value: 114,
                label: 'Other Non-Operating Income'
              }
            ],
            selectedParameterValue: [
              {
                id: 60,
                display_parameter_level_2: 'Interest and Dividend',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 62,
                display_parameter_level_2: 'Rental Income',
                year1: '2021-2022',
                year1value: '67',
                year2: '2020-2021',
                year2value: '67'
              },
              {
                id: 63,
                display_parameter_level_2: 'Profit from Sale of Assets',
                year1: '2021-2022',
                year1value: '67',
                year2: '2020-2021',
                year2value: '67'
              },
              {
                id: 65,
                display_parameter_level_2: 'Forex Income (Expenses)',
                year1: '2021-2022',
                year1value: '76',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 66,
                display_parameter_level_2: 'Other Income',
                year1: '2021-2022',
                year1value: '77887888',
                year2: '2020-2021',
                year2value: '85'
              },
              {
                id: 114,
                display_parameter_level_2: 'Other Non-Operating Income',
                year1: '2021-2022',
                year1value: '776',
                year2: '2020-2021',
                year2value: '67'
              }
            ]
          },
          {
            parameter_level_1: '7OtherNon-CashExpenses',
            display_parameter_level_1: 'Other Non-Cash Expenses',
            label1Details: [
              {
                value: 68,
                label: 'Bad debts W/O'
              },
              {
                value: 67,
                label: 'Depreciation'
              },
              {
                value: 69,
                label: 'Preliminary expenses W/O'
              }
            ],
            selectedParameter: [
              {
                value: 67,
                label: 'Depreciation'
              },
              {
                value: 68,
                label: 'Bad debts W/O'
              },
              {
                value: 69,
                label: 'Preliminary expenses W/O'
              }
            ],
            selectedParameterValue: [
              {
                id: 67,
                display_parameter_level_2: 'Depreciation',
                year1: '2021-2022',
                year1value: '98',
                year2: '2020-2021',
                year2value: '34'
              },
              {
                id: 68,
                display_parameter_level_2: 'Bad debts W/O',
                year1: '2021-2022',
                year1value: '67',
                year2: '2020-2021',
                year2value: '64'
              },
              {
                id: 69,
                display_parameter_level_2: 'Preliminary expenses W/O',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '89'
              }
            ]
          },
          {
            parameter_level_1: '8TotalInterestPaid',
            display_parameter_level_1: 'Total Interest Paid',
            label1Details: [
              {
                value: 70,
                label: 'Interest on Bank Finance'
              },
              {
                value: 71,
                label: 'Interest on Other Loans'
              }
            ],
            selectedParameter: [
              {
                value: 70,
                label: 'Interest on Bank Finance'
              },
              {
                value: 71,
                label: 'Interest on Other Loans'
              }
            ],
            selectedParameterValue: [
              {
                id: 70,
                display_parameter_level_2: 'Interest on Bank Finance',
                year1: '2021-2022',
                year1value: '127',
                year2: '2020-2021',
                year2value: '727'
              },
              {
                id: 71,
                display_parameter_level_2: 'Interest on Other Loans',
                year1: '2021-2022',
                year1value: '7272',
                year2: '2020-2021',
                year2value: '77'
              }
            ]
          },
          {
            parameter_level_1: '9PBT',
            display_parameter_level_1: 'PBT',
            label1Details: [
              {
                value: 115,
                label: 'PBT'
              }
            ],
            selectedParameter: [
              {
                value: 115,
                label: 'PBT'
              }
            ],
            selectedParameterValue: [
              {
                id: 115,
                display_parameter_level_2: 'PBT',
                year1: '2021-2022',
                year1value: '32',
                year2: '2020-2021',
                year2value: '65'
              }
            ]
          }
        ]
      },

      {
        parameter_head: '2B/S',
        display_parameter_head: 'Balance Sheet',
        headerDetails: [
          {
            parameter_level_1: '10TotalOutsideDebts',
            display_parameter_level_1: 'Total Outside Debts',
            label1Details: [
              {
                value: 81,
                label: 'Bank Debts - LAP'
              },
              {
                value: 80,
                label: 'Bank Debts - Term Loans'
              },
              {
                value: 82,
                label: 'Bank Debts - Unsecured Loans'
              },
              {
                value: 84,
                label: 'Loan from Others'
              },
              {
                value: 83,
                label: 'Vehicle Loans'
              }
            ],
            selectedParameter: [
              {
                value: 80,
                label: 'Bank Debts - Term Loans'
              },
              {
                value: 81,
                label: 'Bank Debts - LAP'
              },
              {
                value: 82,
                label: 'Bank Debts - Unsecured Loans'
              },
              {
                value: 83,
                label: 'Vehicle Loans'
              },
              {
                value: 84,
                label: 'Loan from Others'
              }
            ],
            selectedParameterValue: [
              {
                id: 80,
                display_parameter_level_2: 'Bank Debts - Term Loans',
                year1: '2021-2022',
                year1value: '9'
              },
              {
                id: 81,
                display_parameter_level_2: 'Bank Debts - LAP',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '898'
              },
              {
                id: 82,
                display_parameter_level_2: 'Bank Debts - Unsecured Loans',
                year1: '2021-2022',
                year1value: '5',
                year2: '2020-2021',
                year2value: '56'
              },
              {
                id: 83,
                display_parameter_level_2: 'Vehicle Loans',
                year1: '2021-2022',
                year1value: '787',
                year2: '2020-2021',
                year2value: '65'
              },
              {
                id: 84,
                display_parameter_level_2: 'Loan from Others',
                year1: '2021-2022',
                year1value: '56',
                year2: '2020-2021',
                year2value: '65'
              }
            ]
          },
          {
            parameter_level_1: '11Noncurrentassets',
            display_parameter_level_1: 'Non current assets',
            label1Details: [
              {
                value: 85,
                label: 'Fixed assets'
              },
              {
                value: 86,
                label: 'Investments'
              },
              {
                value: 87,
                label: 'Long term deposits'
              }
            ],
            selectedParameter: [
              {
                value: 85,
                label: 'Fixed assets'
              },
              {
                value: 86,
                label: 'Investments'
              },
              {
                value: 87,
                label: 'Long term deposits'
              }
            ],
            selectedParameterValue: [
              {
                id: 85,
                display_parameter_level_2: 'Fixed assets',
                year1: '2021-2022',
                year1value: '45',
                year2: '2020-2021',
                year2value: '561'
              },
              {
                id: 86,
                display_parameter_level_2: 'Investments',
                year1: '2021-2022',
                year1value: '1',
                year2: '2020-2021',
                year2value: '4'
              },
              {
                id: 87,
                display_parameter_level_2: 'Long term deposits',
                year1: '2021-2022',
                year1value: '45',
                year2: '2020-2021',
                year2value: '23'
              }
            ]
          },
          {
            parameter_level_1: '12WorkingCapitalAssets',
            display_parameter_level_1: 'Working Capital Assets',
            label1Details: [
              {
                value: 98,
                label: 'Cash and bank balances'
              },
              {
                value: 90,
                label: 'Debtors exceeding 6 mths'
              },
              {
                value: 89,
                label: 'Debtors Less 6 mths'
              },
              {
                value: 97,
                label: 'Due to Govt Authorities'
              },
              {
                value: 88,
                label: 'Inventory'
              },
              {
                value: 112,
                label: 'Other Current Assets'
              }
            ],
            selectedParameter: [
              {
                value: 88,
                label: 'Inventory'
              },
              {
                value: 89,
                label: 'Debtors Less 6 mths'
              },
              {
                value: 90,
                label: 'Debtors exceeding 6 mths'
              },
              {
                value: 97,
                label: 'Due to Govt Authorities'
              },
              {
                value: 98,
                label: 'Cash and bank balances'
              },
              {
                value: 112,
                label: 'Other Current Assets'
              }
            ],
            selectedParameterValue: [
              {
                id: 88,
                display_parameter_level_2: 'Inventory',
                year1: '2021-2022',
                year1value: '45',
                year2: '2020-2021',
                year2value: '45'
              },
              {
                id: 89,
                display_parameter_level_2: 'Debtors Less 6 mths',
                year1: '2021-2022',
                year1value: '44',
                year2: '2020-2021',
                year2value: '4'
              },
              {
                id: 90,
                display_parameter_level_2: 'Debtors exceeding 6 mths',
                year1: '2021-2022',
                year1value: '45',
                year2: '2020-2021',
                year2value: '45'
              },
              {
                id: 97,
                display_parameter_level_2: 'Due to Govt Authorities',
                year1: '2021-2022',
                year1value: '5',
                year2: '2020-2021',
                year2value: '4'
              },
              {
                id: 98,
                display_parameter_level_2: 'Cash and bank balances',
                year1: '2021-2022',
                year1value: '4',
                year2: '2020-2021',
                year2value: '45'
              },
              {
                id: 112,
                display_parameter_level_2: 'Other Current Assets',
                year1: '2021-2022',
                year1value: '444',
                year2: '2020-2021',
                year2value: '99'
              }
            ]
          },
          {
            parameter_level_1: '14SourcesofWorkingcapital',
            display_parameter_level_1: 'Sources of Working capital',
            label1Details: [
              {
                value: 93,
                label: 'CC / Overdraft'
              },
              {
                value: 95,
                label: 'Other short term facilities'
              }
            ],
            selectedParameter: [
              {
                value: 93,
                label: 'CC / Overdraft'
              },
              {
                value: 95,
                label: 'Other short term facilities'
              }
            ],
            selectedParameterValue: [
              {
                id: 93,
                display_parameter_level_2: 'CC / Overdraft',
                year1: '2021-2022',
                year1value: '98',
                year2: '2020-2021',
                year2value: '898'
              },
              {
                id: 95,
                display_parameter_level_2: 'Other short term facilities',
                year1: '2021-2022',
                year1value: '6',
                year2: '2020-2021',
                year2value: '999'
              }
            ]
          },
          {
            parameter_level_1: '16WorkingCapitalLiabilities',
            display_parameter_level_1: 'Working Capital Liabilities',
            label1Details: [
              {
                value: 100,
                label: 'Capital Asset Creditors'
              },
              {
                value: 92,
                label: 'Customer Advances'
              },
              {
                value: 99,
                label: 'Employee Benefit Payable'
              },
              {
                value: 113,
                label: 'Other Current Liabilities'
              },
              {
                value: 102,
                label: 'Provisions'
              },
              {
                value: 91,
                label: 'Sundry Creditors'
              }
            ],
            selectedParameter: [
              {
                value: 91,
                label: 'Sundry Creditors'
              },
              {
                value: 92,
                label: 'Customer Advances'
              },
              {
                value: 99,
                label: 'Employee Benefit Payable'
              },
              {
                value: 100,
                label: 'Capital Asset Creditors'
              },
              {
                value: 102,
                label: 'Provisions'
              },
              {
                value: 113,
                label: 'Other Current Liabilities'
              }
            ],
            selectedParameterValue: [
              {
                id: 91,
                display_parameter_level_2: 'Sundry Creditors',
                year1: '2021-2022',
                year1value: '8989',
                year2: '2020-2021',
                year2value: '8989'
              },
              {
                id: 92,
                display_parameter_level_2: 'Customer Advances',
                year1: '2021-2022',
                year1value: '5679',
                year2: '2020-2021',
                year2value: '9'
              },
              {
                id: 99,
                display_parameter_level_2: 'Employee Benefit Payable',
                year1: '2021-2022',
                year1value: '88',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 100,
                display_parameter_level_2: 'Capital Asset Creditors',
                year1: '2021-2022',
                year1value: '89',
                year2: '2020-2021',
                year2value: '89'
              },
              {
                id: 102,
                display_parameter_level_2: 'Provisions',
                year1: '2021-2022',
                year1value: '666',
                year2: '2020-2021',
                year2value: '6689'
              },
              {
                id: 113,
                display_parameter_level_2: 'Other Current Liabilities',
                year1: '2021-2022',
                year1value: '889',
                year2: '2020-2021',
                year2value: '8777877'
              }
            ]
          },
          {
            parameter_level_1: '8Networth',
            display_parameter_level_1: 'Networth',
            label1Details: [
              {
                value: 75,
                label: 'Deferred Tax Liab (Asset)'
              },
              {
                value: 73,
                label: 'Miscellaneous Exp'
              },
              {
                value: 74,
                label: 'Res & Surplus'
              },
              {
                value: 72,
                label: 'Share Capital'
              }
            ],
            selectedParameter: [
              {
                value: 72,
                label: 'Share Capital'
              },
              {
                value: 73,
                label: 'Miscellaneous Exp'
              },
              {
                value: 74,
                label: 'Res & Surplus'
              },
              {
                value: 75,
                label: 'Deferred Tax Liab (Asset)'
              }
            ],
            selectedParameterValue: [
              {
                id: 72,
                display_parameter_level_2: 'Share Capital',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 73,
                display_parameter_level_2: 'Miscellaneous Exp',
                year1: '2021-2022',
                year1value: '099',
                year2: '2020-2021',
                year2value: '877'
              },
              {
                id: 74,
                display_parameter_level_2: 'Res & Surplus',
                year1: '2021-2022',
                year1value: '787',
                year2: '2020-2021',
                year2value: '78'
              },
              {
                id: 75,
                display_parameter_level_2: 'Deferred Tax Liab (Asset)',
                year1: '2021-2022',
                year1value: '99',
                year2: '2020-2021',
                year2value: '9'
              }
            ]
          },
          {
            parameter_level_1: '9TotalpromoterFunds',
            display_parameter_level_1: 'Total promoter Funds',
            label1Details: [
              {
                value: 79,
                label: 'Advance to Related Parties'
              },
              {
                value: 78,
                label: 'Investment in Group Companies'
              },
              {
                value: 77,
                label: 'Loans from Promoters / relatives'
              },
              {
                value: 76,
                label: 'Loan to promoters / relatives'
              }
            ],
            selectedParameter: [
              {
                value: 76,
                label: 'Loan to promoters / relatives'
              },
              {
                value: 77,
                label: 'Loans from Promoters / relatives'
              },
              {
                value: 78,
                label: 'Investment in Group Companies'
              },
              {
                value: 79,
                label: 'Advance to Related Parties'
              }
            ],
            selectedParameterValue: [
              {
                id: 76,
                display_parameter_level_2: 'Loan to promoters / relatives',
                year1: '2021-2022',
                year1value: '8',
                year2: '2020-2021',
                year2value: '8'
              },
              {
                id: 77,
                display_parameter_level_2: 'Loans from Promoters / relatives',
                year1: '2021-2022',
                year1value: '7',
                year2: '2020-2021',
                year2value: '7'
              },
              {
                id: 78,
                display_parameter_level_2: 'Investment in Group Companies',
                year1: '2021-2022',
                year1value: '78',
                year2: '2020-2021',
                year2value: '6'
              },
              {
                id: 79,
                display_parameter_level_2: 'Advance to Related Parties',
                year1: '2021-2022',
                year1value: '87',
                year2: '2020-2021',
                year2value: '7'
              }
            ]
          }
        ]
      },

      {
        parameter_head: '3Other',
        display_parameter_head: 'Other',
        headerDetails: [
          {
            parameter_level_1: '17ITRFiliingdate',
            display_parameter_level_1: 'ITR Filling Date',
            label1Details: [
              {
                value: 36,
                label: 'ITR Filling Date'
              }
            ],
            selectedParameter: [],
            selectedParameterValue: []
          },
          {
            parameter_level_1: '18PersonalIncomeData',
            display_parameter_level_1: 'Personal Income Data',
            label1Details: [
              {
                value: 121,
                label: 'Assessed Profit'
              },
              {
                value: 116,
                label: 'Agricultural Income'
              },
              {
                value: 122,
                label: 'Other Incomes'
              },
              {
                value: 117,
                label: 'Rental Income'
              },
              {
                value: 118,
                label: 'Salary Income if SEP'
              },
              {
                value: 119,
                label: 'Relevant Experience'
              },
              {
                value: 120,
                label: 'Assessed TO'
              }
            ],
            selectedParameter: [],
            selectedParameterValue: []
          }
        ]
      }
    ]

    ////

    const objects = [
      { pbt1: '', pbt2: '', pbt3: '' },
      { pat1: '', pat2: '', pat3: '' },
      { profit1: '', profit2: '', profit3: '' },
      { depreciation1: '', depreciation2: '', depreciation3: '' },
      { tax1: '', tax2: '', tax3: '' },
      { ibf1: '', ibf2: '', ibf3: '' },
      { ibl1: '', ibl2: '', ibl3: '' },
      { tip1: '', tip2: '', tip3: '' },
      { totalincome1: '', totalincome2: '', totalincome3: '' },
      { totoperev1: '', totoperev2: '', totoperev3: '' },
      { operatingprofit1: '', operatingprofit2: '', operatingprofit3: '' },
      { totnonopin1: '', totnonopin2: '', totnonopin3: '' },
      { paytoman1: '', paytoman2: '', paytoman3: '' },
      { pbdit1: '', pbdit2: '', pbdit3: '' },
      { busmargin1: '', busmargin2: '', busmargin3: '' },
      { cashprofit1: '', cashprofit2: '', cashprofit3: '' }
    ]

    data.map(item => {
      if (item.parameter_head === '1PnL') {
        item.headerDetails.map(item2 => {
          let display_parameter_level_1 = item2.display_parameter_level_1
          switch (display_parameter_level_1) {
            case 'PBT':
              objects[0].pbt1 = item2.selectedParameterValue[0].year1value
                ? Number(item2.selectedParameterValue[0].year1value) / 100000
                : ''
              objects[0].pbt2 = item2.selectedParameterValue[0].year2value
                ? Number(item2.selectedParameterValue[0].year2value) / 100000
                : ''
              item2.selectedParameterValue.year3value !== undefined
                ? (objects[0].pat3 =
                  Number(item2.selectedParameterValue[0].year3value) / 100000)
                : (objects[0].pbt3 = '')

            // eslint-disable-next-line no-fallthrough
            case 'Profit':
              objects[2].profit1 = item2.selectedParameterValue[0].year1value
                ? item2.selectedParameterValue[0].year1value
                : ''
              objects[2].profit2 = item2.selectedParameterValue[0].year2value
                ? item2.selectedParameterValue[0].year2value
                : ''
              item2.selectedParameterValue.year3value !== undefined
                ? (objects[2].profit3 =
                  item2.selectedParameterValue[0].year3value)
                : (objects[0].profit3 = '')

            // eslint-disable-next-line no-fallthrough
            case 'Other Non-Cash Expenses':
              item2.selectedParameterValue.map(item3 => {
                if (item3.display_parameter_level_2 === 'Depreciation') {
                  objects[3].depreciation1 = item3.year1value
                    ? Number(item3.year1value) / 100000
                    : ''
                  objects[3].depreciation2 = item3.year2value
                    ? Number(item3.year2value) / 100000
                    : ''
                  objects[3].depreciation3 = item3.year3value
                    ? Number(item3.year3value) / 100000
                    : ''
                }
              })

            // eslint-disable-next-line no-fallthrough
            case 'Tax Paid':
              objects[4].tax1 = item2.selectedParameterValue[0].year1value
                ? Number(item2.selectedParameterValue[0].year1value) / 100000
                : ''
              objects[4].tax2 = item2.selectedParameterValue[0].year2value
                ? Number(item2.selectedParameterValue[0].year2value) / 100000
                : ''
              item2.selectedParameterValue.year3value !== undefined
                ? (objects[4].tax3 =
                  Number(item2.selectedParameterValue[0].year3value) / 100000)
                : (objects[4].tax3 = '')

            // eslint-disable-next-line no-fallthrough
            case 'Total Interest Paid':
              item2.selectedParameterValue.map(item3 => {
                if (
                  item3.display_parameter_level_2 === 'Interest on Bank Finance'
                ) {
                  objects[5].ibf1 = item3.year1value
                    ? Number(item3.year1value) / 100000
                    : ''
                  objects[5].ibf2 = item3.year2value
                    ? Number(item3.year2value) / 100000
                    : ''
                  objects[5].ibf3 = item3.year3value
                    ? Number(item3.year3value) / 100000
                    : ''
                }

                if (
                  item3.display_parameter_level_2 === 'Interest on Other Loans'
                ) {
                  objects[6].ibl1 = item3.year1value
                    ? Number(item3.year1value)
                    : ''
                  objects[6].ibl2 = item3.year2value
                    ? Number(item3.year2value)
                    : ''
                  objects[6].ibl3 = item3.year3value
                    ? Number(item3.year3value)
                    : ''
                }
              })

            // eslint-disable-next-line no-fallthrough
            case 'Total Income':
              let year1 = ''
              let year2 = ''
              let year3 = ''
              item2.selectedParameterValue.map(item3 => {
                year1 += item3.year1value ? Number(item3.year1value) : ''
                year2 += item3.year2value ? Number(item3.year2value) : ''
                year3 += item3.year3value ? Number(item3.year3value) : ''
              })
              objects[8].totalincome1 = year1 / 100000
              objects[8].totalincome1 = year2 / 100000
              objects[8].totalincome1 = year3 / 100000

            // eslint-disable-next-line no-fallthrough
            case 'Cost of Goods Sold':
              let closingStock = { year1: '', year2: '', year3: '' }
              let grossProfit = { year1: '', year2: '', year3: '' }
              item2.selectedParameterValue.map(item3 => {
                if (item3.display_parameter_level_2 != 'Closing Stock') {
                  grossProfit.year1 += item3.year1value
                    ? Number(item3.year1value)
                    : ''
                  grossProfit.year2 += item3.year2value
                    ? Number(item3.year2value)
                    : ''
                  grossProfit.year3 += item3.year3value
                    ? Number(item3.year3value)
                    : ''
                } else {
                  closingStock.year1 = item3.year1value
                    ? Number(item3.year1value)
                    : ''
                  closingStock.year2 = item3.year2value
                    ? Number(item3.year2value)
                    : ''
                  closingStock.year3 = item3.year3value
                    ? Number(item3.year3value)
                    : ''
                }
              })

              // Total Operating Revenue calculations
              ////
              objects[9].totoperev1 =
                objects[8].totalincome1 -
                (grossProfit.year1 - closingStock.year1)
              objects[9].totoperev2 =
                objects[8].totalincome2 -
                (grossProfit.year2 - closingStock.year3)
              objects[9].totoperev3 =
                objects[8].totalincome3 -
                (grossProfit.year2 - closingStock.year3)

              // operating profit
              objects[10].operatingprofit1 = grossProfit.year1 / 100000
              objects[10].operatingprofit2 = grossProfit.year2 / 100000
              objects[10].operatingprofit3 = grossProfit.year2 / 100000

            // eslint-disable-next-line no-fallthrough
            case 'Total Non Operating Income':
              item2.selectedParameterValue.map(item3 => {
                objects[11].totnonopin1 += item3.year1value
                  ? Number(item3.year1value)
                  : ''
                objects[11].totnonopin2 += item3.year2value
                  ? Number(item3.year2value)
                  : ''
                objects[11].totnonopin3 += item3.year3value
                  ? Number(item3.year3value)
                  : ''
              })
            // let totoperev1Andtotnonopin1 =;
            // let totoperev2Andtotnonopin2 =;
            // let totoperev3Andtotnonopin3 =;

            // eslint-disable-next-line no-fallthrough
            case 'Management Renumeration':
              item2.selectedParameterValue.map(item3 => {
                objects[12].paytoman1 += item3.year1value
                  ? Number(item3.year1value)
                  : ''
                objects[12].paytoman2 += item3.year2value
                  ? Number(item3.year2value)
                  : ''
                objects[12].paytoman3 += item3.year3value
                  ? Number(item3.year3value)
                  : ''
              })

            // eslint-disable-next-line no-fallthrough
            default:
              return 4
          }
        })
      }
    })

    /// PAT Manual Calculation
    objects[1].pat1 =
      (Number(objects[0].pbt1 ? objects[0].pbt1 : '') +
        Number(objects[4].tax1 ? objects[4].tax1 : '')) /
      100000
    objects[1].pat2 =
      (Number(objects[0].pbt2 ? objects[0].pbt2 : '') +
        Number(objects[4].tax2 ? objects[4].tax2 : '')) /
      100000
    objects[1].pat3 =
      (Number(objects[0].pbt3 ? objects[0].pbt3 : '') +
        Number(objects[4].tax3 ? objects[4].tax3 : '')) /
      100000

    /// Total finance Cost

    objects[7].tip1 =
      (Number(objects[5].ibf1 ? objects[5].ibf1 : '') +
        Number(objects[6].ibl1 ? objects[6].ibl1 : '')) /
      100000
    objects[7].tip2 =
      (Number(objects[5].ibf2 ? objects[5].ibf2 : '') +
        Number(objects[6].ibf2 ? objects[6].ibf2 : '')) /
      100000
    objects[7].tip3 =
      (Number(objects[5].ibf3 ? objects[5].ibf3 : '') +
        Number(objects[6].ibf3 ? objects[6].ibf3 : '')) /
      100000

    // PBDIT
    objects[13].pbdit1 = objects.totnonopin1 + objects[12].paytoman1
    objects[13].pbdit2 = objects.totnonopin2 + objects[12].paytoman2
    objects[13].pbdit3 = objects.totnonopin3 + objects[12].paytoman3

    objects[15].cashprofit1 = objects[1].pat1 + objects[3].depreciation1
    objects[15].cashprofit2 = objects[1].pat2 + objects[3].depreciation2
    objects[15].cashprofit3 = objects[1].pat3 + objects[3].depreciation3
    console.log('object-------', objects)

    setFinanceRatios(objects)
  }

  const [reviewDownload, setReviewDownload] = React.useState(false);

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState("");
  const [colorMessage, setcolorMessage] = React.useState();


  const [plAndBl, setPlAndBl] = React.useState(false);
  const [financeParameterDetails1, setFinanceParameterDetails1] = React.useState([]);
  const handleManualDataAdd1 = async (item, promoOrBus) => {
    ;

    let parentData = {
      'parent_type_id': 1,
      "loanType": 1,
      'parent_id': iduser.business_id,
      'parent_name': iduser.business_name
    }

    await axios.post(apiCalling.coderscorner + `server.php/api/getFinanceHeaderParameterDetailsByParentId`, parentData).then(result => {
      console.log(result);
      if (result.data.status == true) {
        setFinanceParameterDetails(result.data.data);
        console.log("test", financeParameterDetails);
        setPlAndBl(true);
        getFinRatios();
      }
    })
  }


  const [finRatiosValue, setFinRatiosValue] = React.useState([]);
  const getFinRatios = async () => {
    
    let data = {
      "folder_no": iduser.folder_no
    }


    let obj1 = {
      Current_Ratio: "",
      Creditors_Payment_in_Days: "",
      Debtors_Collection_in_Days: "",
      Tol_Tnw: "",
      Debt_Equity: "",
      ISCR: "",
      DSCR: ""
    }

    let obj2 = {
      Current_Ratio: "",
      Creditors_Payment_in_Days: "",
      Debtors_Collection_in_Days: "",
      Tol_Tnw: "",
      Debt_Equity: "",
      ISCR: "",
      DSCR: ""
    }

    await axios.post(apiCalling.coderscorner + 'server.php/api/getFoldersNumber', data).then(result => {
      //   console.log(result);
      if (result.data.data.length > 0) {

        result.data.data.map(item => {
          if (item.year == '2022') {
            if (item.parameter_name == "Current Ratio") {
              obj1.Current_Ratio = item.parameter_value
            }
            if (item.parameter_name == "Creditors Payment in Days") {
              obj1.Creditors_Payment_in_Days = item.parameter_value
            }
            if (item.parameter_name == "Debtors Collection in Days") {
              obj1.Debtors_Collection_in_Days = item.parameter_value
            }
            if (item.parameter_name == "TOL / TNW") {
              obj1.Tol_tnw = item.parameter_value
            }
            if (item.parameter_name == "Debt Equity") {
              obj1.Debt_Equity = item.parameter_value
            }
            if (item.parameter_name == "ISCR") {
              obj1.ISCR = item.parameter_value
            }
            if (item.parameter_name == "DSCR") {
              obj1.DSCR = item.parameter_value
            }
          }

          if (item.year == '2021') {
            if (item.parameter_name == "Current Ratio") {
              obj2.Current_Ratio = item.parameter_value
            }
            if (item.parameter_name == "Creditors Payment in Days") {
              obj2.Creditors_Payment_in_Days = item.parameter_value
            }
            if (item.parameter_name == "Debtors Collection in Days") {
              obj2.Debtors_Collection_in_Days = item.parameter_value
            }
            if (item.parameter_name == "TOL / TNW") {
              obj2.Tol_tnw = item.parameter_value
            }
            if (item.parameter_name == "Debt Equity") {
              obj2.Debt_Equity = item.parameter_value
            }
            if (item.parameter_name == "ISCR") {
              obj2.ISCR = item.parameter_value
            }
            if (item.parameter_name == "DSCR") {
              obj2.DSCR = item.parameter_value
            }
          }
        });

        let arr = [];
        arr.push(obj1);
        arr.push(obj2);
        setFinRatiosValue(arr);

      }
    }).catch(error => {
      console.log(error);
    })
  }


  return (
    <>
      {reviewDownload == false ? (
        <Container maxWidth='lg' id='demo'>

          <div
            className='mini-container mg_btm_set1'
            style={{ marginBottom: '0px !important' }}
          >


            <Snackbar
              open={savenextmessage}
              style={{ marginTop: '65px' }}
              autoHideDuration={SnackPosition.duration}
              onClose={() => {
                setsavenextmessage(false)
              }}
              anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}
            >
              <Alert
                onClose={() => {
                  setsavenextmessage(false)
                }}
                severity={colorMessage}
              >
                {showMessage}
              </Alert>
            </Snackbar>




            {pdfLoader == true && (
              <div
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  top: '13%',
                  left: '45%'
                }}
              >
                <Loader type={LoaderColor.type} color={LoaderColor.color} />
              </div>
            )}

            {loaderFlag == true && (
              <div style={{ textAlign: 'center', marginTop: '15%' }}>
                <Loader type={LoaderColor.type} color={LoaderColor.color} />
              </div>
            )}
            {loaderFlag == false && (
              <div className='content-header'>
                <form>
                  <div className='business-form'>
                    <div id='div1'>
                      <Row>
                        <Col md={{ span: 12 }} className='mainheader1'>
                          <Typography
                            text='APPLICATION SUMMARY VIEW'
                            styleType='C'
                          />

                          <i
                            class='fas fa-file-download'
                            style={{
                              float: 'right',
                              fontSize: '24px',
                              marginTop: '15px'
                            }}
                            type='button'
                            onClick={() => {
                              generatePDF()
                            }}
                          ></i>
                        </Col>
                        <Col md={{ span: 12 }} className='subHeader1'>
                          <table className='applicationTableDetails1'>
                            <tr>
                              {/* <td>Type</td>
                       <td>:</td> */}
                              {localData.request_type_id === 0 && (
                                <td>Loan Application</td>
                              )}
                              {localData.request_type_id === 1 && (
                                <td>Check Eligibility</td>
                              )}
                              <td>&nbsp;|&nbsp;</td>
                              <td>Application Number</td>
                              <td>:</td>
                              <td>{localData.reference_id}</td>
                              <td>&nbsp;|&nbsp;</td>
                              <td>Loan Type</td>
                              <td>:</td>
                              <td>{localData.loan_type}</td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text='Business Name' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='business_name'
                            //label="Business Name"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            value={editinitialValue.businessName}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography
                            text='Date of Incorporation'
                            styleType='B'
                          />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='doi'
                            // label="Date of Incorporation"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            // value={}
                            value={editinitialValue.date}
                          />
                        </Col>
                      </Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text='GST Number' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='gst_number'
                            //label="GST Number"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            value={editinitialValue.gst}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='Business PAN' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='business_pan'
                            // label="Business PAN"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            value={editinitialValue.businessPan}
                          />
                        </Col>
                      </Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text='Constitution' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='constitution'
                            //label="Constitution"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            // value={}
                            value={editinitialValue.entity}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='Industry' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='industry'
                            //label="Industry"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            //  value={editinitialValue.industry}
                            value={editinitialValue.industry}
                          />
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 12 }}>
                          <Typography text='Registered Address' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='Registered Address'
                            // label="Registered Address"
                            value={editinitialValue.premisesArea}
                          />
                        </Col>
                      </Row>
                      <Row style={{ padding: '30px 0px 65px 0px' }}>
                        <Col md={{ span: 12 }}>
                          <Typography text='Client background' styleType='B' />
                          <ReactQuill
                            onRef={editor}
                            theme='snow'
                            formats={formats}
                            style={{ height: '100px' }}
                            value={clientBackground}
                            onChange={html => {
                              setClientBackground(html)
                            }}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div id='div2'>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='Promoter Information'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          {/* <Typography
                text="Add Promoter comments"
                styleType="C"
              /> */}
                          <Tooltip
                            title='Add Promoter comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handlePromoterComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setPromoterComments(e.target.value)
                              }}
                            >
                              <col></col>
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                            {/* <img className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenAssetForm(item)} /> */}
                          </Tooltip>
                        </Col>
                      </Row>
                      {showPromoterComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={promoterComments}
                              onChange={html => {
                                setPromoterComments(html)
                                //   console.log("hello");
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 12 }} className=''>
                          <TableContainer component={Paper}>
                            <Table
                              className='custom-table'
                              aria-label='customized table'
                              size='small'
                              style={{ marginBottom: '0% 0% 0%' }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Promoter Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Email
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Mobile
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Share %
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userPromoterList.map((item, i) => (
                                  <TableRow hover className='datable' key={i}>
                                    <TableCell scope='item' style={customTable}>
                                      {i + 1}
                                    </TableCell>
                                    <TableCell style={customTable}>
                                      {item.name}
                                    </TableCell>
                                    <TableCell style={customTable}>
                                      {item.email}
                                    </TableCell>
                                    <TableCell style={customTable}>
                                      {item.mobile}
                                    </TableCell>
                                    <TableCell style={customTable}>
                                      {item.type}
                                    </TableCell>
                                    <TableCell style={customTable}>
                                      {item.share}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    <div id='div3'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='Financials- P&L Statement'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          {/* <Typography
                text="Add Promoter comments"
                styleType="C"
              /> */}
                          <Tooltip
                            title='Add P&L Ananlysis comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handlefinPLComment}
                              style={{ marginLeft: '70%' }}
                              onChange={e => {
                                ////////;;
                                setFinancialPandLcomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                            {/* <img className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenAssetForm(item)} /> */}
                          </Tooltip>
                        </Col>
                      </Row>
                      {showfinPLComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={financialPandLcomments}
                              onChange={html => {
                                setFinancialPandLcomments(html)

                                //console.log("hello");
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 12 }} className=''>
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Business Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    GST
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Business PAN
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Entity Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Status
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessList8.map((item, i) => (
                                  <>
                                    <TableRow hover className='datable'>
                                      <TableCell
                                        scope='item'
                                        style={customTable}
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.name}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.gst}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.bpan}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.entity_type}
                                      </TableCell>
                                      {item.countrow == 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={
                                              'Financial Details not Added for Business' +
                                              ' ' +
                                              item.name
                                            }
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='far fa-times-circle'
                                                style={{
                                                  color: 'red',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      {item.countrow > 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={
                                              'Financial Details updated for Business' +
                                              ' ' +
                                              item.name
                                            }
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{
                                                  color: 'green',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      <TableCell style={customTable}>
                                        <Tooltip
                                          title='To view Financials-Profit & loss statement'
                                          placement='bottom'
                                        >
                                          <Col md={{ span: 2 }}>
                                            {showPreview === false ? (
                                              <Typography
                                                text={
                                                  <i
                                                    class='fas fa-eye'
                                                    onClick={() => {
                                                      handleManualDataAdd(
                                                        item,
                                                        1
                                                      )
                                                      setShowPreview(
                                                        !showPreview
                                                      )
                                                    }}
                                                  ></i>
                                                }
                                              />
                                            ) : (
                                              <Typography
                                                text={
                                                  <i
                                                    class='fas fa-eye-slash'
                                                    onClick={() => {
                                                      setShowPreview(
                                                        !showPreview
                                                      )
                                                    }}
                                                  ></i>
                                                }
                                              />
                                            )}
                                          </Col>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                      {showPreview && (
                        <Row
                          style={{
                            marginTop: '1%',
                            marginLeft: '0%'
                          }}
                        >
                          <Col md={{ spam: 12 }}>
                            <table
                              border='1'
                              style={{ width: '98%' }}
                              className='styled-table'
                            >
                              {financeParameterDetails
                                .slice(0, 1)
                                .map((item, i) => (
                                  <>
                                    <thead
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '20px'
                                      }}
                                    >
                                      <tr colspan='4'>
                                        {item.display_parameter_head}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.headerDetails.map((iitem, j) => (
                                        <>
                                          <tr>
                                            <th
                                              colspan='4'
                                              style={{
                                                fontStyle: 'bold',
                                                fontSize: '16px'
                                              }}
                                            >
                                              {iitem.display_parameter_level_1}
                                            </th>
                                          </tr>
                                          {iitem.selectedParameterValue.map(
                                            (iteml1, k) => (
                                              <tr>
                                                <th
                                                  style={{
                                                    fontSize: '13px',
                                                    fontWeight: '400'
                                                  }}
                                                >
                                                  {
                                                    iteml1.display_parameter_level_2
                                                  }
                                                </th>
                                                {noOfColumn.map((year, j) => (
                                                  <td
                                                    className='finance_preview_td'
                                                    style={{
                                                      width: '40px',
                                                      textAlign: 'center'
                                                    }}
                                                  >
                                                    {
                                                      iteml1[
                                                      fyear + (j + 1) + fval
                                                      ]
                                                    }
                                                  </td>
                                                ))}
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ))}
                                    </tbody>
                                  </>
                                ))}
                            </table>
                          </Col>
                        </Row>
                      )}
                    </div>

                    <div id='div4'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='Financials-Balance Sheet'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add Balance Sheet Analysis comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handleBalsheetComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setFinancialBalanceSheetcomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                            {/* <img className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenAssetForm(item)} /> */}
                          </Tooltip>
                        </Col>
                      </Row>
                      {showBalsheetComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={financialBalanceSheetcomments}
                              onChange={html => {
                                setFinancialBalanceSheetcomments(html)

                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 12 }} className=''>
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Business Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    GST
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Business PAN
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Entity Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Status
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessList8.map((item, i) => (
                                  <>
                                    <TableRow hover className='datable'>
                                      <TableCell
                                        scope='item'
                                        style={customTable}
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.name}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.gst}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.bpan}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.entity_type}
                                      </TableCell>
                                      {item.countrow == 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={
                                              'Financial Details not Added for Business' +
                                              ' ' +
                                              item.name
                                            }
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='far fa-times-circle'
                                                style={{
                                                  color: 'red',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      {item.countrow > 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={
                                              'Financial Details updated for Business' +
                                              ' ' +
                                              item.name
                                            }
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{
                                                  color: 'green',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      <TableCell style={customTable}>
                                        <Tooltip
                                          title='To view Financials-Balance Sheets'
                                          placement='bottom'
                                        >
                                          <Col md={{ span: 2 }}>
                                            {showPreview1 === false ? (
                                              <Typography
                                                text={
                                                  <i
                                                    class='fas fa-eye'
                                                    onClick={() => {
                                                      handleManualDataAdd(
                                                        item,
                                                        1
                                                      )
                                                      setShowPreview1(
                                                        !showPreview1
                                                      )
                                                    }}
                                                  ></i>
                                                }
                                              />
                                            ) : (
                                              <Typography
                                                text={
                                                  <i
                                                    class='fas fa-eye-slash'
                                                    onClick={() => {
                                                      setShowPreview1(
                                                        !showPreview1
                                                      )
                                                    }}
                                                  ></i>
                                                }
                                              />
                                            )}
                                          </Col>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                      {showPreview1 && (
                        <Row>
                          <Col md={{ span: 12 }}>
                            <table
                              border='1'
                              style={{ width: '98%' }}
                              className='styled-table'
                            >
                              {financeParameterDetails
                                .slice(1)
                                .map((item, i) => (
                                  <>
                                    <thead
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '20px'
                                      }}
                                    >
                                      <tr>{item.display_parameter_head}</tr>
                                    </thead>
                                    <tbody>
                                      {item.headerDetails.map((iitem, j) => (
                                        <>
                                          <tr>
                                            <th
                                              colspan='4'
                                              style={{
                                                fontStyle: 'bold',
                                                fontSize: '15px'
                                              }}
                                            >
                                              {iitem.display_parameter_level_1}
                                            </th>
                                          </tr>
                                          {iitem.selectedParameterValue.map(
                                            (iteml1, k) => (
                                              <tr>
                                                <th
                                                  style={{
                                                    fontSize: '13px',
                                                    fontWeight: '400'
                                                  }}
                                                >
                                                  {
                                                    iteml1.display_parameter_level_2
                                                  }
                                                </th>
                                                {noOfColumn.map((year, j) => (
                                                  <td
                                                    className='finance_preview_td'
                                                    style={{
                                                      width: '40px',
                                                      textAlign: 'center'
                                                    }}
                                                  >
                                                    {iteml1[
                                                      fyear + (j + 1) + fval
                                                    ]
                                                      ? iteml1[
                                                      fyear + (j + 1) + fval
                                                      ]
                                                      : ''}
                                                  </td>
                                                ))}
                                              </tr>
                                            )
                                          )}
                                        </>
                                      ))}
                                    </tbody>
                                  </>
                                ))}
                            </table>
                          </Col>
                        </Row>
                      )}
                    </div>




                    <div id='div5'>

                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        {
                        // <Col
                        //   md={{ span: 12 }}
                        //   className=''
                        //   style={{ fontSize: '16px' }}
                        // >
                        //   <Typography text='Financials- Ratios' styleType='C' />



                        //   <table border="1" style={{ width: "98%" }} className="styled-table" >

                        //     {financeParameterDetails.slice(0, 1).map((item, i) => (
                        //       <>
                        //         <thead style={{ "fontWeight": "bold", "fontSize": "20px" }}>
                        //           <tr className="tr1">
                        //             <th className="head1 first_column">Ratios</th>
                        //             <th className="head1 first_column">Description</th>
                        //             {
                        //               noOfColumn.map(item => (
                        //                 <th className="head1 header_tr">{item}</th>
                        //               ))
                        //             }
                        //           </tr>
                        //         </thead>
                        //         <tbody>

                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" rowspan="3" style={{ "fontWeight": "bold", fontSize: "16px", textAlign: "center" }} >Profitability</td>
                        //             <td className="sum_pl1" >Gross Margin</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>
                        //           <tr className="sum_row">

                        //             <td className="sum_pl1" >Net Margin</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>
                        //           <tr className="sum_row">

                        //             <td className="sum_pl1" >EBIDTA Margin</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>





                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" rowspan="7" style={{ "fontWeight": "bold", backgroundColor: "white", fontSize: "16px", textAlign: "center" }} >Working Capital</td>
                        //             <td className="sum_pl1" >Current Ratio (Times)</td>

                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.Current_Ratio}</td>
                        //             ))
                        //             }

                        //           </tr>
                        //           <tr className="sum_row">

                        //             <td className="sum_pl1" >Quick Ratio (Times)</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>
                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >Debtor Days</td>
                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.Debtors_Collection_in_Days}</td>
                        //             ))
                        //             }
                        //           </tr>

                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >Debtor Days</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>

                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >Creditor Days</td>

                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.Creditors_Payment_in_Days}</td>
                        //             ))
                        //             }

                        //           </tr>
                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >Working Capital Cycle Days</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>


                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" > Working Capital GAP (In Lacs)</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>





                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" rowspan="5" style={{ "fontWeight": "bold", fontSize: "16px", textAlign: "center" }}>Leverage</td>
                        //             <td className="sum_pl1" >TOL/TNW (Times)</td>

                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.Tol_Tnw}</td>
                        //             ))
                        //             }
                        //           </tr>
                        //           <tr className="sum_row">

                        //             <td className="sum_pl1" >Debt to Equity (Times)</td>

                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.Debt_Equity}</td>
                        //             ))
                        //             }

                        //           </tr>
                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >Total Debt/EBIDTA (Times)</td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //             <td className="sum_pl1" ></td>
                        //           </tr>

                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >ISCR (Times)</td>

                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.ISCR}</td>
                        //             ))
                        //             }
                        //           </tr>
                        //           <tr className="sum_row">
                        //             <td className="sum_pl1" >DSCR (Times)</td>
                        //             {finRatiosValue.map(item => (
                        //               <td className="sum_pl1" >{item.DSCR}</td>
                        //             ))
                        //             }
                        //           </tr>

                        //           {
                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Total Operating Revenue</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev2}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev3}</td>
                        //             // </tr>

                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Operating Profit</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit2}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[10].operatingprofit3}</td>
                        //             // </tr>

                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Depreciation</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[3].depreciation1}</td>
                        //             // </tr>

                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>Total Finance Cost</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev2}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[9].totoperev3}</td>
                        //             // </tr>

                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PBT</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt2}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[0].pbt3}</td>
                        //             // </tr>

                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>PAT</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat2}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[1].pat3}</td>
                        //             // </tr>
                        //             // <tr className="sum_row">
                        //             //     <td className="sum_pl" style={{ fontSize: "13px", fontWeight: "400" }}>CASH PROFIT</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                        //             //     <td className="sum_pl" style={{ textAlign: "center" }}>{finRatios[15].cashprofit1}</td>
                        //             // </tr>
                        //           }

                        //         </tbody>
                        //       </>
                        //     ))}
                        //   </table>

                        //   {/* <TableContainer component={Paper}>
                        //     <Table className='' aria-label='simple table'>
                        //       <TableHead>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             Key Financial Indicators
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             2019
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             2020
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             2021
                        //           </TableCell>
                        //         </TableRow>
                        //       </TableHead>
                        //       <TableBody>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             Total Operating Revenue
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[9].totoperev1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[9].totoperev2}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[9].totoperev3}
                        //           </TableCell>
                        //         </TableRow>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             Operating Profit
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[10].operatingprofit1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[10].operatingprofit2}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[10].operatingprofit3}
                        //           </TableCell>
                        //         </TableRow>
                        //         {
                        //           // <TableRow>
                        //           //   <TableCell style={table_header, customTable}>PBDIT</TableCell>
                        //           // </TableRow>
                        //         }
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             Depreciation
                        //           </TableCell>

                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[3].depreciation1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[3].depreciation1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[3].depreciation1}
                        //           </TableCell>
                        //         </TableRow>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             Total Finance Cost
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[7].tip1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[7].tip2}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[7].tip3}
                        //           </TableCell>
                        //         </TableRow>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             PBT
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[0].pbt1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[0].pbt2}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[0].pbt3}
                        //           </TableCell>
                        //         </TableRow>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             PAT
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[1].pat1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[1].pat2}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[1].pat3}
                        //           </TableCell>
                        //         </TableRow>
                        //         <TableRow>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             CASH PROFIT
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[15].cashprofit1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[15].cashprofit1}
                        //           </TableCell>
                        //           <TableCell
                        //             style={(table_header, customTable)}
                        //           >
                        //             {finRatios[15].cashprofit1}
                        //           </TableCell>
                        //         </TableRow>
                        //       </TableBody>
                        //     </Table>
                        //   </TableContainer> */}
                        // </Col>
                        // <Col md={{ span: 6 }} className='cpa_comments'>
                        //   <Tooltip
                        //     title='Add Financial ratios comments'
                        //     placement='bottom'
                        //   >
                        //     <Button
                        //       className='actionBtn'
                        //       onClick={handlefinratioComment}
                        //       style={{ marginLeft: '67%' }}
                        //       onChange={e => {
                        //         ////////;;
                        //         setFinancialRatiosComments(e.target.value)
                        //       }}
                        //     >
                        //       <i
                        //         className='fas fa-comment'
                        //         style={{ fontSize: '15px' }}
                        //       />
                        //     </Button>
                        //   </Tooltip>
                        // </Col>
                        }
                      </Row>
                      {showfinratioComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={financialRatiosComments}
                              onChange={html => {
                                setFinancialRatiosComments(html)

                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}

                      {/*
                                  <Row>
                          <Col md={{ span: 12 }} className="">
                            <TableContainer component={Paper}>
                              <Table className="" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="5%" style={table_slNo, customTable}>#</TableCell>
                                    <TableCell style={table_header, customTable}>Promoter Name</TableCell>
                                    <TableCell style={table_header, customTable}>Email</TableCell>
                                    <TableCell style={table_header, customTable}>Mobile</TableCell>
                                    <TableCell style={table_header, customTable}>Type</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                      
        
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Col>
                        </Row>
                          */}
                    </div>

                    <div id='div5'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='Banking Growth Analysis'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add Banking  Ananlysis comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handleBankComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setBankingAnalysiscomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {showBankComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={bankingAnalysiscomments}
                              onChange={html => {
                                setBankingAnalysiscomments(html)
                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 12 }} className=''>
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Bank Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessList3.map((item, i) => (
                                  <>
                                    <TableRow hover className='datable'>
                                      <TableCell
                                        scope='item'
                                        style={customTable}
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.type}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.bank_name}
                                      </TableCell>

                                      {item.countrow == 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={'Bank Details not Added'}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='far fa-times-circle'
                                                style={{
                                                  color: 'red',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      {item.countrow > 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={'Bank Details '}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{
                                                  color: 'green',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    <div id='div6'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='GST Growth Analysis'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add GST Ananlysis comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handleGSTComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setGstAnalysiscomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {showGSTComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={gstAnalysiscomments}
                              onChange={html => {
                                setGstAnalysiscomments(html)

                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col
                          md={{ span: 12 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    GST Number
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    State Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessList6.map((item, i) => (
                                  <>
                                    <TableRow hover className='datable'>
                                      <TableCell
                                        scope='item'
                                        style={customTable}
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.gst_number}
                                      </TableCell>
                                      <TableCell style={customTable}>
                                        {item.state_name}
                                      </TableCell>

                                      {item.countrow == 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={'GST Details not Added'}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='far fa-times-circle'
                                                style={{
                                                  color: 'red',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      {item.countrow > 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={'GST Details'}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{
                                                  color: 'green',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    <div id='div7'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography text='Assessed Income' styleType='C' />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add Assessed Income'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handleAssesedIncomeComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setAssessedIncomecomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {showAssesedIncomeComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={assessedIncomecomments}
                              onChange={html => {
                                setAssessedIncomecomments(html)
                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col
                          md={{ span: 12 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    {' '}
                                    Name
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Email
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Mobile
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Type
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* <TableRow key="1">
                <TableCell component="th" scope="row">
                  1
                </TableCell>
                <TableCell align="right" style={customTable}>2</TableCell>
                <TableCell align="right">3</TableCell>
                <TableCell align="right">4</TableCell>
                <TableCell align="right">5</TableCell>
              </TableRow>
              <TableRow key="2">
                <TableCell component="th" scope="row">
                  1
                </TableCell>
                <TableCell align="right">2</TableCell>
                <TableCell align="right">3</TableCell>
                <TableCell align="right">4</TableCell>
                <TableCell align="right">5</TableCell>
              </TableRow> */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    <div id='div8'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography
                            text='Existing Obligations'
                            styleType='C'
                          />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add Existing Obligations Comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handleLoanComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setExistingObligationscomments(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {showLoanComment && (
                        <Row>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={existingObligationscomments}
                              onChange={html => {
                                setExistingObligationscomments(html)

                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col
                          md={{ span: 12 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Financier
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Loan Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Account Number
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Loan Amount
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    EMI
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Original Tenture
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Bank
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Bank Account No
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Loan Open?
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userBusinessList1.map((item, i) => (
                                  <>
                                    {item.loanForBusiness.map((val, k) => (
                                      <TableRow hover className='datable'>
                                        <TableCell style={customTable}>
                                          {i + 1}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.financer_name}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.loan_types}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.Account_no}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.Loan_amount}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.EMI}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.Original_tenure_id}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.bank_name}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {val.bank_account_no}
                                        </TableCell>
                                        <TableCell style={customTable}>
                                          {!val.Loan_still_open ? (
                                            <i class='far fa-times-circle'></i>
                                          ) : (
                                            <i
                                              className='fas fa-check-circle'
                                              style={{
                                                fontSize: '16px',
                                                color: 'green',
                                                padding: '0px 0px 0px 25px'
                                              }}
                                            ></i>
                                          )}
                                        </TableCell>

                                        {item.countrow == 0 && (
                                          <TableCell
                                            className='tableStatusHeading'
                                            style={customTable}
                                          >
                                            <Tooltip
                                              title={'GST Details not Added'}
                                              placement='top'
                                            >
                                              <span>
                                                <i
                                                  class='far fa-times-circle'
                                                  style={{
                                                    color: 'red',
                                                    customTable
                                                  }}
                                                ></i>
                                              </span>
                                            </Tooltip>
                                          </TableCell>
                                        )}
                                        {item.countrow > 0 && (
                                          <TableCell
                                            className='tableStatusHeading'
                                            style={customTable}
                                          >
                                            <Tooltip
                                              title={'GST Details'}
                                              placement='top'
                                            >
                                              <span>
                                                <i
                                                  class='fa fa-check-circle'
                                                  style={{
                                                    color: 'green',
                                                    customTable
                                                  }}
                                                ></i>
                                              </span>
                                            </Tooltip>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    ))}
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    {/* <Row style ={{ width: '101%', padding: '15px 0px 15px 0px'}}>
            <Col md={{ span: 6 }} className=""  style={{fontSize:'16px'}}>
              <Typography
                text="Repayment History(Last 12 Months)"
                styleType="C"
              />
  
              </Col>
              <Col md={{ span: 6 }} className="">
              <Tooltip title="Add Financial Ratios Comments" placement="bottom">
                                          <Button className="actionBtn" onClick={handleRepaymentHistoryComment} style={{ marginLeft: "67%" }} 
                                          onChange={(e) => {
                                            ////////;;
                                            setRepaymentHistorycomments(e.target.value);  }}>
                                          Add Financial Ratios comments
                                            <i className="fas fa-comment" style={{ fontSize: "15px" }} />
                                          </Button>
                                        </Tooltip>
  
              </Col>
          </Row>
          {showRepaymentHistoryComment && (
          <Row>
          <Col md={{ span: 12}} style= {{padding: '10px 0px 35px 18px'}}>
          <ReactQuill 
            onRef={editor}
            theme="snow"
            
            formats={formats}
            value={repaymentHistorycomments}
            onChange={(html) => {
              setRepaymentHistorycomments(html);
              
          console.log("hello");
          }
        }
           />
          </Col>
          </Row>
          )}
          <Row>
          <Col md={{ span: 12 }} className="">
          <TableContainer component={Paper}>
        <Table className="" aria-label="simple table">
        <TableHead>
            <TableRow>
              <TableCell width="5%" style={table_slNo,customTable}>#</TableCell>
              <TableCell style={table_header,customTable}>Name</TableCell>
              <TableCell style={table_header,customTable}>Email</TableCell>
              <TableCell style={table_header,customTable}>Mobile</TableCell>
              <TableCell style={table_header,customTable}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
             
            
          </TableBody>
        </Table>
      </TableContainer>
          </Col>
          </Row> */}
                    {/* <Row style ={{ width: '101%', padding: '15px 0px 15px 0px'}}>
            <Col md={{ span: 6 }} className=""  style={{fontSize:'16px'}}>
              <Typography
                text="Top 5 Customer Details"
                styleType="C"
              />
  
              </Col>
              <Col md={{ span: 6 }} className="">
              <Tooltip title="Add Top 5 Customer Comments" placement="bottom">
                                          <Button className="actionBtn" onClick={handleTop5CustomerComment} style={{ marginLeft: "67%" }} 
                                           onChange={(e) => {
                                            ////////;;
                                            setTop5Customercomments(e.target.value);  }}>
                                          Add Top 5 Customer comments
                                            <i className="fas fa-comment" style={{ fontSize: "15px" }} />
                                          </Button>
                                        </Tooltip>
  
              </Col>
          </Row>
          {showTop5CustomerComment && (
          <Row>
          <Col md={{ span: 12}} style= {{padding: '10px 0px 35px 18px'}}>
        
          <ReactQuill 
            onRef={editor}
            theme="snow"
          
            formats={formats}
            value={top5Customercomments}
            onChange={(html) => {
              setTop5Customercomments(html);
              
          console.log("hello");
          }
        }
           />
          </Col>
          </Row>
          )}
          <Row>
          <Col md={{ span: 12 }} className="">
          <TableContainer component={Paper}>
        <Table className="" aria-label="simple table">
        <TableHead>
            <TableRow>
              <TableCell width="5%" style={table_slNo,customTable}>#</TableCell>
              <TableCell style={table_header,customTable}>Name</TableCell>
              <TableCell style={table_header,customTable}>Email</TableCell>
              <TableCell style={table_header,customTable}>Mobile</TableCell>
              <TableCell style={table_header,customTable}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            
          </TableBody>
        </Table>
      </TableContainer>
          </Col>
          </Row> */}
                    {/* <Row style={{padding: '15px 0px 15px 0px'}}>
            <Col md={{ span: 6 }} className=""  style={{fontSize:'16px'}}>
              <Typography
                text="Top 5 Supplier comments"
                styleType="C"
              />
  
              </Col>
              <Col md={{ span: 6 }} className="">
              <Tooltip title="Add Top 5 Supplier Comments" placement="bottom">
                                          <Button className="actionBtn" onClick={handleTop5SupplierComment} style={{ marginLeft: "67%" }}
                                           onChange={(e) => {
                                            ////////;;
                                            setTop5Supplierscomments(e.target.value);  }} >
                                          Add Top 5 Supplier comments
                                            <i className="fas fa-comment" style={{ fontSize: "15px" }} />
                                          </Button>
                                        </Tooltip>
  
              </Col>
          </Row>
          {showTop5SupplierComment && (
          <Row>
          <Col md={{ span: 12}} style= {{padding: '10px 0px 35px 18px'}} >
          <ReactQuill 
            onRef={editor}
            theme="snow"
           
            formats={formats}
            value={top5Supplierscomments}
            onChange={(html) => {
              setTop5Supplierscomments(html);
              
          console.log("hello");
          }
        }
           />
          </Col>
          </Row>
          )}
          <Row>
          <Col md={{ span: 12 }} className="">
          <TableContainer component={Paper}>
        <Table className="" aria-label="simple table">
        <TableHead>
            <TableRow>
              <TableCell width="5%" style={table_slNo,customTable}>#</TableCell>
              <TableCell style={table_header,customTable}>Name</TableCell>
              <TableCell style={table_header,customTable}>Email</TableCell>
              <TableCell style={table_header,customTable}>Mobile</TableCell>
              <TableCell style={table_header,customTable}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            
          </TableBody>
        </Table>
      </TableContainer>
          </Col>
          </Row> */}

                    <div id='div9'>
                      {' '}
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col
                          md={{ span: 6 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <Typography text='Property Details' styleType='C' />
                        </Col>
                        <Col md={{ span: 6 }} className='cpa_comments'>
                          <Tooltip
                            title='Add Property Comments'
                            placement='bottom'
                          >
                            <Button
                              className='actionBtn'
                              onClick={handlePropertyDetailsComment}
                              style={{ marginLeft: '67%' }}
                              onChange={e => {
                                ////////;;
                                setPropertyCommets(e.target.value)
                              }}
                            >
                              <i
                                className='fas fa-comment'
                                style={{ fontSize: '15px' }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      {showPropertyDetailsComment && (
                        <Row style={{ width: '101%' }}>
                          <Col
                            md={{ span: 12 }}
                            style={{ padding: '10px 0px 35px 18px' }}
                          >
                            {/* <Typography text="Registered Address" styleType="B" /> */}
                            <ReactQuill
                              onRef={editor}
                              theme='snow'
                              // modules={toolState.toolbind[level3.id]}
                              // label="Client background"
                              formats={formats}
                              value={propertyCommets}
                              onChange={html => {
                                setPropertyCommets(html)

                                console.log('hello')
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={{ span: 12 }} className=''>
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    width='5%'
                                    style={(table_slNo, customTable)}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Asset Type
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Purpose
                                  </TableCell>
                                  <TableCell
                                    style={(table_header, customTable)}
                                  >
                                    Status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={{ span: 12 }}
                          className=''
                          style={{ fontSize: '16px' }}
                        >
                          <TableContainer component={Paper}>
                            <Table className='' aria-label='simple table'>
                              <TableBody>
                                {businessList7.map((item, i) => (
                                  <>
                                    <TableRow hover className='datable'>
                                      <TableCell
                                        scope='item'
                                        style={{
                                          border: '1px solid',
                                          width: '5%'
                                        }}
                                      >
                                        {i + 1}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: '1px solid',
                                          width: '18.9%'
                                        }}
                                      >
                                        {item.type}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: '1px solid',
                                          width: '29.5%'
                                        }}
                                      >
                                        {item.assets_type}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: '1px solid',
                                          width: '43%'
                                        }}
                                      >
                                        {item.purpose_name}
                                      </TableCell>

                                      {item.countrow == 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={' Asset Details not Added'}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='far fa-times-circle'
                                                style={{
                                                  color: 'red',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                      {item.countrow > 0 && (
                                        <TableCell
                                          className='tableStatusHeading'
                                          style={customTable}
                                        >
                                          <Tooltip
                                            title={'Asset Details'}
                                            placement='top'
                                          >
                                            <span>
                                              <i
                                                class='fa fa-check-circle'
                                                style={{
                                                  color: 'green',
                                                  customTable
                                                }}
                                              ></i>
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </div>

                    <div id='div10'>
                      <Row style={{ padding: '15px 0px 15px 0px' }}>
                        <Col md={{ span: 6 }} className=''>
                          <Typography text='Proposal Details' styleType='C' />
                        </Col>
                      </Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text='Loan Type' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='loan_type'
                            //label="Business Name"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            //  value={getloanbyBusid[0].loan_type}
                            value={getloanbyBusid.loan_type}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='End Use of loan' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='use_of_loan'
                          // label="Date of Incorporation"
                          // onChange={handleChange}
                          // onBlur={e => {
                          //   handleBlur(e)
                          //   }}
                          // value={}
                          //value={editinitialValue.date}
                          />
                        </Col>
                      </Row>
                      <Row style={{ padding: '0px 0px 12px 0px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text='Loan Amount' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='loan_amount'
                            //label="GST Number"
                            // onChange={handleChange}
                            // onBlur={e => {
                            //   handleBlur(e)
                            //   }}
                            // value={getloanbyBusid[0]['loan_amount']}
                            value={getloanbyBusid.loan_amount}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text='Rate of Interest' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='interest'
                          // label="Business PAN"
                          // onChange={handleChange}
                          // onBlur={e => {
                          //   handleBlur(e)
                          //   }}
                          // value={editinitialValue.businessPan}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 6 }}>
                          <Typography text='Loan Duration' styleType='B' />
                          <TextField
                            id='filled-error-helper-text standard-basic'
                            name='loan_duration'
                          // label="Business PAN"
                          // onChange={handleChange}
                          // onBlur={e => {
                          //   handleBlur(e)
                          //   }}
                          // value={editinitialValue.businessPan}
                          />
                        </Col>
                      </Row>
                    </div>
                    {resubmit1 == false ? (
                      <Row>
                        <Col md={{ span: 10 }}></Col>
                        <Col md={{ span: 2 }}>
                          <Tooltip
                            title={
                              disableApplication === true
                                ? ''
                                : 'Please fill all the forms and upload all the necessary Documents'
                            }
                            placement='left'
                          >
                            <div>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={saveApplicationSummary}
                              >
                                Submit
                              </Button>
                            </div>

                            {/*
                            <div>
                            <Button className="updatebtn" disabled={disableApplication} variant="contained" color="primary" type="button" onClick={saveApplicationSummary} >Submit</Button>
                          </div>
                          */}
                          </Tooltip>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col md={{ span: 10 }}></Col>
                        <Col md={{ span: 2 }}>
                          <Tooltip
                            title={
                              disableApplication === true
                                ? ''
                                : 'Please fill all the forms and upload all the necessary Documents'
                            }
                            placement='left'
                          >
                            <div>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={submitAnswerTemp}
                              >
                                ReSubmit
                              </Button>
                            </div>
                          </Tooltip>
                        </Col>
                      </Row>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </Container>
      ) : (
        <AppSummaryPreview />
      )}
    </>
  )
}
