import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import { TextArea } from 'semantic-ui-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik } from "formik";
import { sortOrder1 } from "../../shared/constants";
import { AssetSubTypeLinkFormSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import {
    mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import { apiCalling } from '../adminConstants';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from '../../image/buttons/graydraftnext.png';
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

export default function AssetSubTypeLinkForm(props) {

    const classes = useStyles();
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);
    const [sortOrder, setSortOrder] = React.useState("");
    const [showList, setShowList] = React.useState(true);
    const [loaderFlag, setLoaderFlag] = React.useState(true);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
    const [CategoryList, setCategoryList] = React.useState([]);


    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        AssetTypes();
        handleAssetSubType();
    }, []);

    const [assetType, setAssetType] = React.useState([]);
    const AssetTypes = () => {
        let getAssetTypes = {
            type: 'asset_type',
        }
        axios.post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`, getAssetTypes)
            .then((res) => {
                let allData = []
                res.data.data.map((item, i) => {
                    allData.push({
                        key: i,
                        value: item.id,
                        text: item.name,
                    });
                });
                setAssetType(allData);
                console.log(allData);

            })
            .catch((err) => console.log("error in fetching asset data", err))
    }



    const handleSubmiting = () => {
        props.handleNext();
    };

    const handledata = () => {

        props.handleBack();
    };


    const handleselectChange = (event) => {
        event.preventDefault();
        this.setState({
            showOption: true
        })
    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handlecheck();
    }




    let [initialValue, setInitialValue] = React.useState({
        asset: "",
        fname: "",
        sname: "",
        descrip: "",
        sortOrder: "",
        enable: "",
        asset_sub_cat_id: ""
    })

    const handleAssetSubType = async () => {
        
        let data = {
            enable: true,
        }
        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getAllassetsubcategory`,
                data
            ).then((result) => {
                let empArray = [];
                if(result.data.status){
                    result.data.data.map((item) => {
                        empArray.push({
                            asset: item.asset_type_id,
                            fname: item.name,
                            sname: item.short_name,
                            descrip: item.description,
                            sortOrder: item.sort_order,
                            enable: item.enable,
                            asset_sub_cat_id: item.id
    
    
                        })
                    })
                    setShowWCAddForm(false);
                    setShowList(true);
                    
                    console.log("EmpArray", empArray);
                    setCategoryList(empArray);
                }
                setLoaderFlag(false);
            })
            .catch((err) => console.log("error in fetching asset data", err))
    }



    const data = {
        columns: [
            {
                label: "Sl No.",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Asset Type",
                field: "asset",
                width: 270,
            },
            {
                label: "Name",
                field: "fname",
                width: 200,
            },
            {
                label: "Short Name",
                field: "sname",
                width: 200,
            },
            {
                label: "Description",
                field: "descrip",
                width: 200,
            },
            {
                label: "Sort Order",
                field: "sortOrder",
                width: 100,
            },
            {
                label: "Enable",
                field: "enable",
                width: 100,
            },

            {
                label: "Action",
                field: "action",
                width: 200,

            },
        ],

        rows: CategoryList.map((values, i) => {
            return {
                slno: i + 1,
                asset: values.asset,
                fname: values.fname,
                sname: values.sname,
                descrip: values.descrip,
                sortOrder: values.sortOrder.toString(),
                enable: values.enable.toString(),

                action: <div style={{ marginLeft: '-20px' }}>
                    <Tooltip title="Edit Data" placement="bottom">
                        {/* <Button className="actionBtn" onClick={() => handleEdit(values)} style={{ marginRight: "16px" }} >
                            <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                        </Button> */}
                        <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />
                    </Tooltip>

                    <Tooltip title="Delete Data" placement="bottom">
                        {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                            <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                        </Button> */}
                        <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                    </Tooltip>
                </div>
            }
        })
    };

    const resetForm1 = () => {
        setInitialValue = {
            asset: "",
            fname: "",
            sname: "",
            descrip: "",
            sortOrder: "",
            enable: "",
            asset_sub_cat_id: ""
        }
        setShowWCAddForm(false);
        setShowList(true);
    }

    const handlesubmitform = (values, { resetForm }) => {
      
        handleNextClick(values);
        resetForm({
            asset: "",
            fname: "",
            sname: "",
            descrip: "",
            sortOrder: "",
            enable: "",
            asset_sub_cat_id: ""
        })

    };
    const handleNextClick = (data) => {
        setShowWCAddForm(false);
        // setLoaderFlag(true);
        //  let eligibilityFlag = 0;
        /*  if (checkvalue === 1) {
           eligibilityFlag = 1;
         }  */

        let wcData = {
            asset_type_id: data.asset,
            name: data.fname,
            short_name: data.sname,
            description: data.descrip,
            sort_order: data.sortOrder,
            enable: data.enable,
            asset_sub_cat_id: data.asset_sub_cat_id
        };

        console.log("data", data);
        axios.post(apiCalling.coderscorner + `server.php/api/assetSubCategory`, wcData).then((res) => {
         if(res.data.status){
             setFormat('success')
             setsavenextmessage(true)
             setSnackBarMsg(res.data.message)
            console.log(res.data);
            console.log("saved successfully...!!!");
            setShowWCAddForm(false);
            setShowList(true);
            handleAssetSubType();
           
         }
         setLoaderFlag(false);
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    };



    const handleOpenWCAddForm = () => {

        setAddFormButtonValue("Submit");
        setShowWCAddForm(true);
        setShowList(false);

        setInitialValue({
            asset: "",
            fname: "",
            sname: "",
            descrip: "",
            sortOrder: "",
            enable: "",
            asset_sub_cat_id: ""
        })
    }



    const handleEdit = (values) => {
        setShowWCAddForm(true);

        setAddFormButtonValue("Update");

        setShowList(false);

        setInitialValue({
            ...values,


            asset: values.asset,
            fname: values.fname,
            sname: values.sname,
            descrip: values.descrip,
            sortOrder: values.sortOrder,
            enable: values.enable ? "1" : "0",
            asset_sub_cat_id: values.asset_sub_cat_id
        })
    }

    const handleDelete = (item) => {
        let data = {
            asset_sub_cat_id: item.asset_sub_cat_id
        }
        
        axios.post(apiCalling.coderscorner + `server.php/api/deleteassetsubcategory`, data).then((res) => {

        if(res.data.status){
            setFormat('success')
            setsavenextmessage(true)
            setSnackBarMsg(res.data.message)
            console.log("Data", res.data);
            setShowWCAddForm(false);
            setShowList(true);
            handleAssetSubType();
            setLoaderFlag(false);
        }

        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    }

    const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [format, setFormat] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const closeSuccessToast = () => {
        setsavenextmessage(false)
      }



    return (
        <Container maxWidth="lg">

<Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>

            <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>
                {loaderFlag == true && (
                    <div style={{ textAlign: 'center', marginTop: '15%' }}>
                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                    </div>
                )}
                {showList == true && loaderFlag == false && (
                    <div>
                        <div className="content-header">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <Typography
                                        text="Collateral SubType"
                                        styleType="C"
                                    />
                                </Col>
                                <Col md={{ span: 2 }} >
                                    {/* <button className="btn btn-primary" onClick={() => handleOpenWCAddForm()} >
                                        Add</button> */}
                                    <span className="image">
                                        <img className='navbutton backbutton' src={blueadd} onClick={() => handleOpenWCAddForm()} />
                                        <Tooltip title="Add" arrow>
                                            <img className='navgraybutton backbutton' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <Row className="datable" style={{ marginTop: '30px', }} >

                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                <div>
              <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="AssetSubtype"
                sheet="AssetSubtsssypes"
                buttonText="Export excel"
              />
            </div>
                                    <MDBDataTableV5
                                        hover 
                                        entriesOptions={[5, 10,20, 25]} 
                                        entries={5} 
                                        pagesAmount={4} 
                                        data={data}
                                        id = "karzaTbl"
                                        // searchTop 
                                        // searchBottom={false}
                                    />
                                </div>
                            </Col>
                        </Row>


                        <div className='btn_row_fixd'>
                            <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                <Col style={{ padding: "0%" }}></Col>

                                {/* <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                    </Tooltip>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Save as Draft & Next" arrow>
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                    </Tooltip>
                                </Col> */}
                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                        <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                            <img className='navgraybutton' src={grayback} onClick={handledata} />
                                        </Tooltip>
                                    </span>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                        <Tooltip title="Save as Draft & Next" arrow>
                                            <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                <Row>
                    <Col md={{ span: 12 }}>
                        {showWCAddForm == true && (
                            <Formik
                                enableReinitialize
                                initialValues={initialValue}
                                validationSchema={AssetSubTypeLinkFormSchema}
                                onSubmit={handlesubmitform}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldTouched
                                    /* and other goodies */
                                }) => {
                                    //  console.log("values in formik", values);
                                    return (
                                        <form onSubmit={handleSubmit}>

                                            <div className="content-header" style={{ position: 'unset' }}>
                                                <Row>
                                                    <Col md={{ span: 10 }}>
                                                        <Typography
                                                            text="Collaterals SubType"
                                                            styleType="C"
                                                        />
                                                    </Col>
                                                    <Col md={{ span: 2 }}>
                                                        <div className="content-header-button ">
                                                            {/* <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={resetForm1} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                                                 </Button> */}
                                                            <span className="image">
                                                                <img className='backbutton navbutton' src={Back} onClick={resetForm1} />
                                                                <Tooltip title="Back" arrow>
                                                                    <img className='backbutton navgraybutton' src={grayback} onClick={resetForm1} />
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: '30px' }}>

                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Select Asset Type" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Asset Type"
                                                                    id="asset"
                                                                    name="asset"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.asset}
                                                                    onBlur={() => setFieldTouched("asset", true)}
                                                                    onChange={(e, { value }) => {
                                                                        setFieldValue("asset", value);

                                                                    }}
                                                                    options={assetType}
                                                                    className={errors.asset && touched.asset ? classes.required : values.asset == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.asset && touched.asset && values.asset == '' && (

                                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.asset}</div>

                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '3%' }} >

                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="fname"
                                                                    label="Enter Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.fname &&
                                                                            touched.fname &&
                                                                            errors.fname
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.fname ? classes.manorma : null,
                                                                    }}
                                                                    value={values.fname}
                                                                    helperText={
                                                                        errors.fname &&
                                                                        touched.fname &&
                                                                        errors.fname
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Short Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="sname"
                                                                    label="Enter Short Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.sname &&
                                                                            touched.sname &&
                                                                            errors.sname
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.sname ? classes.manorma : null,
                                                                    }}
                                                                    value={values.sname}
                                                                    helperText={
                                                                        errors.sname &&
                                                                        touched.sname &&
                                                                        errors.sname
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Sort order" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Select Sort order"
                                                                    id="sortOrder"
                                                                    name="sortOrder"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.sortOrder}
                                                                    onBlur={() => setFieldTouched("sortOrder", true)}
                                                                    onChange={(e, { value }) => {
                                                                        setFieldValue("sortOrder", value);

                                                                    }}
                                                                    options={sortOrder1}
                                                                    className={errors.sortOrder && touched.sortOrder ? classes.required : values.sortOrder == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.sortOrder && touched.sortOrder && values.sortOrder == '' && (

                                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.sortOrder}</div>

                                                        )}
                                                    </Col>

                                                    <Col md={{ span: 6 }}>
                                                        <div style={{ marginTop: '20px' }}>
                                                            <Typography text="Enable" styleType="B" />

                                                        </div>
                                                        <List component="div" disablePadding>
                                                            <ListItem >

                                                                <label>
                                                                    Yes
                                                          <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="1"
                                                                        checked={values.enable === "1"}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    No
                                                           <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="0"
                                                                        checked={values.enable === "0"}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                            </ListItem>
                                                            {errors.enable && touched.enable && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.enable}</div>}
                                                        </List>
                                                    </Col>

                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Description"
                                                            styleType="B" />
                                                        <List component="div" style={{ padding: "2px" }}>
                                                            <ListItem style={{ padding: "5px 0px" }}>
                                                                <Col md={{ span: 12 }} style={{ padding: "0px 12px" }}>
                                                                    <TextArea
                                                                        id="filled-error-helper-text"
                                                                        name="descrip"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.descrip &&
                                                                                touched.descrip &&
                                                                                errors.descrip
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        className={errors.descrip && touched.descrip ? classes.required : values.descrip !== '' ? classes.mano : null}
                                                                        helperText={
                                                                            errors.descrip &&
                                                                            touched.descrip &&
                                                                            errors.descrip}
                                                                        value={values.descrip}
                                                                    />
                                                                </Col>
                                                            </ListItem>
                                                            {errors.descrip && touched.descrip && (
                                                                <div style={{ color: 'red', marginLeft: 14, fontSize: 11, marginBottom: 5 }}>{errors.descrip}</div>
                                                            )}
                                                        </List>
                                                    </Col>
                                                </Row>
                                                <Col md={{ span: 12 }}>
                                                    <Row><Col md={{ span: 10 }}></Col>
                                                        <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                        </Col>
                                                        <Col md={{ span: 5 }}></Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        )}
                    </Col>
                </Row>
            </div>
        </Container>

    );
}