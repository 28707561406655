import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import axios from 'axios'

//API Services GLOBAL DECLARATION
export const apiCalling = {
  coderscorner: 'http://164.52.208.120:8082/delta/', //'https://platform.aagey.com/delta/' 
  // coderscorner: 'http://127.0.0.1:8003/', 
  
  thirdParty: 'http://164.52.208.120:8082/alpha/', //'https://platform.aagey.com/alpha/' 
  // thirdParty: 'http://127.0.0.1:8001/',
  
  identityManagement: 'http://164.52.208.120:8082/Identitymanagement/', //'https://platform.aagey.com/Identitymanagement/'
  // identityManagement: 'http://127.0.0.1:8002/',
  
  frontCaller: 'http://164.52.208.120:8082/', //'https://platform.aagey.com/'
  // frontCaller: 'http://127.0.0.1:3000/', 
  handleIPConfig: "127.0.0.1", //"164.207.52.120"
}



export const SnackPosition = {
  vertical: 'top',
  horizontal: 'right',
  duration: 3000
}

export const LoaderColor = {
  color: '#7354a2',
  type: 'Oval'
}

export const sortOrder1 = [
  { key: 1, value: 'true', text: 'True' },
  { key: 2, value: 'false', text: 'False' }
]

export const bankoption = [
  { key: 'af', value: 1234, flag: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 1111, flag: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 2345, flag: 'al', text: 'Albania' },
  { key: 'dz', value: 1234, flag: 'dz', text: 'Algeria' }
]

export const countryOptions = [
  { key: 'af', value: 1232, flag: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 1323, flag: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 123, flag: 'al', text: 'Albania' },
  { key: 'dz', value: 1111, flag: 'dz', text: 'Algeria' }
]

export const business = [
  { key: 'af', value: 'mcdonald', flag: 'af', text: 'mcdonald' },
  { key: 'ax', value: 'kfc', flag: 'ax', text: 'kfc' },
  { key: 'al', value: 'mufti', flag: 'al', text: 'mufti' },
  { key: 'dz', value: 'dell', flag: 'dz', text: 'dell' }
]

export const fileUpload = [
  { formid: '1.1.2' },
  { formid: '1.1.3' },
  { formid: '1.1.4' },
  { formid: '1.1.5' },
  { formid: '1.1.6' },
  { formid: '1.1.7' },
  { formid: '1.1.8' },
  { formid: '1.1.9' }
]

export const financebakend = [
  { key: 'af', value: 'Total Income', flag: 'af', text: 'Total Income' },
  { key: 'ax', value: 'Direct Cost', flag: 'ax', text: 'Direct Cost' },
  { key: 'al', value: 'InDirect Cost', flag: 'al', text: 'InDirect Cost' },
  {
    key: 'dz',
    value: 'Management Renumeration',
    flag: 'dz',
    text: 'Management Renumeration'
  },
  {
    key: 'af',
    value: 'Total Non Operating Income',
    flag: 'af',
    text: 'Total Non Operating Income'
  },
  {
    key: 'ax',
    value: 'other Non-Cash Expenses',
    flag: 'ax',
    text: 'other Non-Cash Expenses'
  },
  { key: 'al', value: 'PBT', flag: 'al', text: 'PBT' },
  { key: 'dz', value: 'NewWorth', flag: 'dz', text: 'NewWorth' },
  {
    key: 'af',
    value: 'Total Promoter Funds',
    flag: 'af',
    text: 'Total Promoter Funds'
  },
  {
    key: 'ax',
    value: 'Total Outside Debts',
    flag: 'ax',
    text: 'Total Outside Debts'
  },
  {
    key: 'al',
    value: 'Non Current Assests',
    flag: 'al',
    text: 'Non Current Assests'
  },
  {
    key: 'dz',
    value: 'Working Capital Assests',
    flag: 'dz',
    text: 'Working Capital Assests'
  },
  {
    key: 'af',
    value: 'Working Capital Liabilities',
    flag: 'af',
    text: 'Working Capital Liabilities'
  },
  {
    key: 'ax',
    value: 'Sources of Working Capital',
    flag: 'ax',
    text: 'Sources of Working Capital'
  },
  {
    key: 'al',
    value: 'Other Current Assests',
    flag: 'al',
    text: 'Other Current Assests'
  },
  {
    key: 'dz',
    value: 'Other Current Liabilities',
    flag: 'dz',
    text: 'Other Current Liabilities'
  }
]

export const entityoption = [
  {
    key: 'Proprietorship',
    value: 1234,
    flag: 'Proprietorship',
    text: 'Proprietorship'
  },
  { key: 'Partnership', value: 1111, flag: 'Partnership', text: 'Partnership' },
  {
    key: 'LimitedL',
    value: 1422,
    flag: 'LimitedL',
    text: 'Limited Liability Partnership'
  },
  { key: 'Private', value: 234, flag: 'Private', text: 'Private Limited' },
  { key: 'PublicL', value: 1235, flag: 'PublicL', text: 'Public Limited' },
  { key: 'Trust', value: 1223, flag: 'Trust', text: 'Trust' },
  { key: 'Society', value: 543, flag: 'Society', text: 'Society' },
  { key: 'Doctor', value: 3454, flag: 'Doctor', text: 'Doctor' },
  { key: 'Laywer', value: 2345, flag: 'Laywer', text: 'Laywer' }
]

export const industrynameoption = [
  {
    key: 'AbrasivesAndGrinding',
    value: 1234,
    flag: 'AbrasivesAndGrinding',
    text: 'Abrasives And Grinding'
  },
  { key: 'Animation', value: 1111, flag: 'Animation', text: 'Animation' },
  { key: 'BioFuel', value: 1122, flag: 'BioFuel', text: 'Bio-Fuel' }
]

export const industrytypeoption = [
  { key: 'Trader', value: 3456, flag: 'Trader', text: 'Trader' },
  {
    key: 'manufacturer',
    value: 1111,
    flag: 'manufacturer',
    text: 'Manufacturer'
  }
]

export const education = [
  {
    key: 'Under Graduate',
    value: 1232,
    flag: 'Under Graduate',
    text: 'Under Graduate'
  },
  {
    key: 'Post Graduate',
    value: 1213,
    flag: 'Post Graduate',
    text: 'Post Graduate'
  }
]

export const type = [
  { key: 'Borrower', value: 1233, text: 'Borrower' },
  { key: 'Promoter', value: 1111, text: 'Promoter' }
]

export const assetstypeoption = [
  {
    key: 'Motor Vehicle',
    value: 'Motor Vehicle',
    flag: 'Motor Vehicle',
    text: 'Motor Vehicle'
  },
  { key: 'Property', value: 'Property', flag: 'Property', text: 'Property' }
]

export const gender = [
  { key: 'male', value: 'male', flag: 'male', text: 'male' },
  { key: 'female', value: 'female', flag: 'female', text: 'female' }
]

export const natureaccount = [
  {
    key: 'Current Account',
    value: 1234,
    flag: 'Current Account',
    text: 'Current Account'
  },
  {
    key: 'Overdraft Account',
    value: 1235,
    flag: 'Overdraft Account',
    text: 'Overdraft Account'
  },
  {
    key: 'Cash Credit Account',
    value: 1111,
    flag: 'Cash Credit Account',
    text: 'Cash Credit Account'
  },
  {
    key: 'Savings Account',
    value: 4433,
    flag: 'Savings Account',
    text: 'Savings Account'
  },
  {
    key: 'Packing Credit Account',
    value: 1232,
    flag: 'Packing Credit Account',
    text: 'Packing Credit Account'
  },
  { key: 'Others', value: 'Others', flag: 2343, text: 'Others' }
]

export const tenuretypes = [
  { key: '12', value: '12', flag: '12', text: '12' },
  { key: '18', value: '18', flag: '18', text: '18' },
  { key: '24', value: '24', flag: '24', text: '24' },
  { key: '30', value: '30', flag: '30', text: '30' },
  { key: '36', value: '36', flag: '36', text: '36' }
]

export const industrygroupoption = [
  {
    key: 'AbrasivesAndGrinding',
    value: 1234,
    flag: 'AbrasivesAndGrinding',
    text: 'Abrasives And Grinding'
  },
  {
    key: 'AgriculturalCorp',
    value: 1111,
    flag: 'AgriculturalCorp',
    text: 'Agricultural Corp'
  },
  { key: 'BioFuel', value: 1234, flag: 'BioFuel', text: 'Bio-Fuel' }
]

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}
function createDatas(name, calories, fat, carbs, protein, other) {
  return { name, calories, fat, carbs, protein, other }
}

function createDating(name, calories, fat, carbs) {
  return { name, calories, fat, carbs }
}

export function ErrorCatch(
  user_id,
  error_message,
  api_path,
  method,
  parameters,
  created_by,
  modified_by
) {
  let data = {
    user_id: user_id,
    error_message: error_message,
    api_path: api_path,
    method: method,
    parameters: parameters,
    created_by: created_by,
    modified_by: modified_by
  }
  axios
    .post(apiCalling.coderscorner + `server.php/api/calltoLogErrorStore`, data)
    .then(res => {
      console.log('error saved successfully', res.data.data)
    })
    .catch(error => {
      console.log('Error API not working', error)
      return false
    })
}

export const rows = [
  createData(
    1,
    'FirstName LastName',
    'PDF Upload',
    'Manually Add',
    'Digitally Add'
  ),
  createData(
    2,
    'FirstName LastName',
    'PDF Upload',
    'Manually Add',
    'Digitally Add'
  ),
  createData(
    3,
    'FirstName LastName',
    'PDF Upload',
    'Manually Add',
    'Digitally Add'
  )
]

export const bankrow = [
  createData(1, '12345', 'Canara Bank', 'Saving', 'Action'),
  createData(2, '23456', 'HDFC', 'Saving', 'Action'),
  createData(3, '34567', 'HSBC', 'Saving', 'Action'),
  createData(4, '456789', 'Union Bank', 'Saving', 'Action')
]

export const gstrow = [createDating(1, '29AAAAA9999A', 'Karnataka', 'Action')]

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

export const row = [
  createData(
    1,
    'FirstName LastName',
    'Borrower',
    'Update/Submit',
    'true/false'
  ),
  createData(2, 'FirstName LastName', 'Borrower', 'Update/Submit', 'true/false')
]

export const personrows = [
  createDatas(1, 'FirstName LastName', 'Borrower', 'Update', 'true', 'false'),
  createDatas(2, 'FirstName LastName', 'Borrower', 'Update', 'true', 'false')
]

function createDat(name, Fetched, Financier, Account, LType, Lamount, emi) {
  return { name, Fetched, Financier, Account, LType, Lamount, emi }
}

export const personrow = [
  createDat(1, 'Bajaj', '249', '1234', 'Cash Credit', '4', '4')
]

export const datarow = [
  createData(
    1,
    'FirstName LastName',
    ' update manually',
    'digital pull',
    ' Action'
  ),
  createData(
    2,
    'FirstName LastName',
    ' update manually',
    'digital pull',
    '  Action'
  ),
  createData(
    3,
    'FirstName LastName',
    ' update manually',
    'digital pull',
    '  Action'
  )
]

export const row_new = [
  createData(1, 'ABC Pvt Ltd', ' update manually', 'digital pull', ' Action')
]

export const approvalworkflow = [
  createData('Mr.Kiran', '12-feb', 'Admin'),
  createData('Ms.Keerthi', '12-feb', 'Credit Reviewer'),
  createData('Mr.Ramesh', '12-feb', 'Doc Boy')
]
export const splitButton1 = {
  options: ['Business', 'Promoter']
}
export const splitButton2 = {
  options: ['Check Eligibility', 'Loan Application']
}

export const letStartFormMenuItems = [
  'Personal Loan',
  'Home Loan',
  'Educational Loan'
]

function createDataForTab1(
  slNo,
  businessName,
  type,
  gst,
  businessPan,
  pin,
  action
) {
  return { slNo, businessName, type, gst, businessPan, pin, action }
}
// function createDataForTab2(slNo, promoter, type, mobile, email, share, action) {
//   return { slNo, promoter, type, mobile, email, share, action };
// }

export const navDataTable1 = {
  columns: [
    {
      label: 'Sl No.',
      field: 'slno',
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name'
      }
    },
    {
      label: 'Business Name',
      field: 'businessname'
    },
    {
      label: 'Type',
      field: 'type'
    },
    {
      label: 'GST',
      field: 'gst'
    },
    {
      label: 'Business PAN',
      field: 'businesspan'
    },
    {
      label: 'PIN',
      field: 'pin'
    },
    {
      label: 'Action',
      field: 'action'
    }
  ],
  rows: [
    {
      slno: '1',
      businessname: 'ABC Pvt. Ltd.',
      type: 'Pvt Ltd',
      gst: '29AAAAA99A',
      businesspan: 'AAAPP9999A',
      pin: '560000',
      action: 'View/Update'
    },
    {
      slno: '2',
      businessname: 'XYZ',
      type: 'Professional',
      gst: '29AAAAA9A',
      businesspan: 'AAAPP9999A',
      pin: '5600001',
      action: 'View/Update'
    }
  ]
}

export const navDataTable2 = {
  columns: [
    {
      label: 'Sl No.',
      field: 'slno',
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name'
      }
    },
    {
      label: 'Promoter',
      field: 'promoter'
    },
    {
      label: 'Type',
      field: 'type'
    },
    {
      label: 'Mobile',
      field: 'mobile'
    },
    {
      label: 'Email',
      field: 'email'
    },
    {
      label: 'Share',
      field: 'share'
    },
    {
      label: 'Action',
      field: 'action'
    }
  ],
  rows: [
    {
      slno: '1',
      promoter: 'Firstname Last name',
      type: 'Borrower',
      mobile: '99999999',
      email: 'some@email.com',
      share: '40',
      action: 'Update / Submit'
    },
    {
      slno: '2',
      promoter: 'Firstname Last name',
      type: 'Borrower',
      mobile: '99999999',
      email: 'some@email.com',
      share: '10',
      action: 'Update / Submit'
    }
  ]
}
