import React from 'react';
import { loadCSS } from 'fg-loadcss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Input from '@material-ui/core/Input';
import { Dropdown } from 'semantic-ui-react'
import { useStyle } from "./styles";
import { bankoption, natureaccount } from "../../shared/constants";
import { StyledTableCell } from "../../shared/constants";
import { StyledTableRow } from "../../shared/constants";
import { bankrow, countryOptions } from "../../shared/constants";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { bankFormschema } from "../../shared/validations";
import Modal from 'react-bootstrap/Modal';
import Icon from "@mdi/react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { MDBDataTableV5 } from 'mdbreact';
import {
    mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert, mdiArrowRightBox, mdiArrowLeftBox, mdiPlusBox, mdiCloudUpload
} from "@mdi/js";
import ProgressBar from 'react-bootstrap/ProgressBar'
export default function Banking(props) {
    const classes = useStyle();
    const [panvalue, setpanvalue] = React.useState();
    const [email, setemail] = React.useState('');
    const [emailotp, setemailotp] = React.useState();
    const [mobile, setmobile] = React.useState();
    const [mobotp, setmobotp] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [show, setshow] = React.useState(false);
    const [shows, setdigital] = React.useState(false);
    const [disp, setdisp] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showform, setshowform] = React.useState(true);
    const [topform, settopform] = React.useState(true);
    const [tableopen, settableopen] = React.useState(false);
    const [table, settable] = React.useState(false);
    const [data, setdata] = React.useState([]);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [now, setnow] = React.useState(50);
    const [modelbank, setmodelbank] = React.useState(false);
    const [datatable, setDatatable] = React.useState({

        columns: [
            {
                label: 'Sl No.',
                field: 'slno',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'A/c No.',
                field: 'acno',
                width: 270,
            },
            {
                label: 'Business Name',
                field: 'business',
                width: 200,
            },
            {
                label: 'Type',
                field: 'type',
                width: 100,
            },
            {
                label: 'Action',
                field: 'action',
                width: 150,
            },


        ],

        rows: [
            {
                slno: '1',
                acno: '12345',
                business: 'Canara Bank',
                type: 'Saving',
                action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

            },
            {
                slno: '2',
                acno: '23456',
                business: 'HDFC',
                type: 'Saving',
                action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

            },
            {
                slno: '3',
                acno: '34567',
                business: 'HSBC',
                type: 'Saving',
                action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

            },
            {
                slno: '4',
                acno: '456789',
                business: 'Union Bank',
                type: 'Saving',
                action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

            },
            /* {
              name: 'Garrett Winters',
              position: 'Accountant',
              office: 'Tokyo',
              age: '63',
              date: '2011/07/25',
              salary: '$170',
            },
            {
              name: 'Ashton Cox',
              position: 'Junior Technical Author',
              office: 'San Francisco',
              age: '66',
              date: '2009/01/12',
              salary: '$86',
            },
            {
              name: 'Ashton Cox',
              position: 'Junior Technical Author',
              office: 'San Francisco',
              age: '66',
              date: '2009/01/12',
              salary: '$86',
            },
            {
              name: 'Ashton Cox',
              position: 'Junior Technical Author',
              office: 'San Francisco',
              age: '66',
              date: '2009/01/12',
              salary: '$86',
            },
            {
              name: 'Ashton Cox',
              position: 'Junior Technical Author',
              office: 'San Francisco',
              age: '66',
              date: '2009/01/12',
              salary: '$86',
            },
           */
        ],
    });
    const handleClose = () => setmodelbank(false);
    const handleCalling = () => setmodelbank(true);
    const handleClick = () => {
        setOpen(!open);

    };

    const handleClick1 = () => {
        setOpen1(!open1);

    };

    const handleClick2 = () => {
        setOpen2(!open2);
    };

    const handleClick3 = () => {
        setOpen3(!open3);
    };

    const handleClick4 = () => {
        setOpen4(!open4);
    };

    const handleClick5 = () => {
        setOpen5(!open5);
    };

    const handleClickOpen = () => {
        //	setshow(true);
    };

    const handlenew = () => {
        // setshowform(true)
        settopform(true)
        settable(false)
        // settableopen(false)
    };



    const handleNextClick = () => {
        const localStorageData = localStorage.getItem('banking'),
            bankdata = JSON.parse(localStorageData)
        setdata(bankdata)
        setDatatable({
            columns: [
                {
                    label: 'Sl No.',
                    field: 'slno',
                    width: 150,
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'Name',
                    },
                },
                {
                    label: 'A/c No.',
                    field: 'acno',
                    width: 270,
                },
                {
                    label: 'Business Name',
                    field: 'business',
                    width: 200,
                },
                {
                    label: 'Type',
                    field: 'type',
                    width: 100,
                },
                {
                    label: 'Action',
                    field: 'action',
                    width: 150,
                },


            ],

            rows: [
                {
                    slno: '1',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '2',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '3',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '4',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '5',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '6',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '7',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '8',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '9',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '10',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },
                {
                    slno: '11',
                    acno: bankdata.account,
                    business: bankdata.bankname,
                    type: bankdata.submission,
                    action: <h6 onClick={() => handleCalling()} style={{ color: 'blue' }}>Action</h6>,

                },

            ]
        })
        console.log('shareholder', bankdata)
        settopform(false)
        // setshowform(false)
        settable(true)
        settableopen(true)
        console.log('clicked next')
    }



    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleSubmiting = () => {

        props.handlecheck();

    };

    const handleSubmit = () => {
        setshow(false)
        setdigital(false)
        setdisp(true)

    };
    const handleSubmitdigital = () => {
        setdigital(true)
        setmodelbank(false);
    };

    const handleSubmited = () => {

        setshow(true)
        setmodelbank(false);
        /*  const data = {
           panvalue:panvalue,
           email:email,
           otp:otp,           
     
         } */
        /*   localStorage.setItem('formdata', JSON.stringify(data))
          props.handleNext(); */
    };
    const handledata = () => {

        props.handleBack();


    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handleNext();
    }

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);

    return (
        <Container maxWidth="lg">
            {showform == true && (

                <Formik
                    initialValues={{ bankname: "", account: "", natureacc: "", submission: "", color: "", companyl: "" }}

                    validationSchema={bankFormschema}
                    onSubmit={(values) => {
                        console.log('kkkkk', values)
                        localStorage.setItem('banking', JSON.stringify(values))
                        handleNextClick()
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched
                        /* and other goodies */
                    }) => {
                        console.log("values in formik", values);
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="mini-contain">

                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <div className="content-headers">
                                                <Row >

                                                    <Col md={{ span: 6 }}>
                                                        <Typography
                                                            text="Banking Backend"
                                                            styleType="F"
                                                        />
                                                    </Col>

                                                    <Col md={{ span: 6 }}>

                                                        <div className="content-headers-promoters">
                                                            {/* <Button
                  variant="contained"
                  className={classes.button_up}
                  style={{ marginRight: "10px" }}
                  onClick={handlenew}
                >
                  <Icon path={mdiPlusBox} title="save" size={1} /> Promoters
                              </Button> */}
                                                            <Button
                                                                variant="contained"
                                                                className={showform == true ? classes.button_others : classes.button_other}

                                                                onClick={handlenew}
                                                            ><Icon path={mdiPlusBox} title="save" size={1} /> <span style={{ marginLeft: '1rem' }}>Bank A/C</span>
                                                            </Button>


                                                            <Button
                                                                //	className={classes.linkdin}
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                onClick={handleSubmit}
                                                                disabled={isSubmitting}
                                                                className={classes.button_others}>
                                                                <Icon path={mdiPlaylistPlus} title="save" size={1} /></Button>



                                                            <Button
                                                                //	className={classes.button_div}
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={SaveasDraft}
                                                                className={classes.button_others}>

                                                                <Icon path={mdiContentSaveAlert} title="draft" size={1} /> </Button>

                                                            <Button
                                                                //	className={classes.button_div}
                                                                variant="outlined"
                                                                color="primary"
                                                                className={classes.button_others}>
                                                                <Icon path={mdiRefresh} title="reset" size={1} /></Button>
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>




                                            {/* <Typography text="Manually Add Data" styleType="B" /> */}

                                            <Row>
                                                <Col>
                                                    <Row style={{ marginTop: '40px' }}>
                                                        <Col md={{ span: 4 }}>
                                                            <Typography text="Bank Name" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="bankname"
                                                                        label="Enter Bank Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.bankname &&
                                                                                touched.bankname &&
                                                                                errors.bankname
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.bankname ? classes.manorma : null,
                                                                        }}
                                                                        value={values.bankname}
                                                                        helperText={
                                                                            errors.bankname &&
                                                                            touched.bankname &&
                                                                            errors.bankname
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 4 }}>
                                                            <Typography text="Account No" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="account"
                                                                        label="Enter Account No"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.account &&
                                                                                touched.account &&
                                                                                errors.account
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.account ? classes.manorma : null,
                                                                        }}
                                                                        value={values.account}
                                                                        helperText={
                                                                            errors.account &&
                                                                            touched.account &&
                                                                            errors.account
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 4 }}>
                                                            <Typography text="Nature of Account" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField
                                                                        id="filled-error-helper-text standard-basic"
                                                                        name="natureacc"
                                                                        label="Enter Nature of Account"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.natureacc &&
                                                                                touched.natureacc &&
                                                                                errors.natureacc
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        InputProps={{
                                                                            className: values.natureacc ? classes.manorma : null,
                                                                        }}
                                                                        value={values.natureacc}
                                                                        helperText={
                                                                            errors.natureacc &&
                                                                            touched.natureacc &&
                                                                            errors.natureacc
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Col>

                                                    </Row>
                                                    <ListItem button onClick={handleClick} className={classes.root}>
                                                    <ListItemIcon>{open ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                                    <ListItemText primary="Month 1" />
                                                    </ListItem>
                                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <br></br>
                                                    <Card>
                                                        <CardContent>
                                                        <Row><Col md={{ span: 12 }} style={{ textAlign: 'center' }}><Typography text="Balance as on Dates" styleType="A" /></Col></Row>
                                                        <Row>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                               <Row><Col md={{ span: 4 }}><Typography text="1st" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="3rd" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="5th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="7th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                         value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="9th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="10th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="14th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" //  label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                        value={values.bparea}helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 3 }} className="margin_set_col">
                                                                <Row><Col md={{ span: 4 }}><Typography text="15th" styleType="B" /></Col>
                                                                    <Col md={{ span: 8 }}>
                                                                        <TextField id="filled-error-helper-text standard-basic" name="bparea" //    label="Enter Business Premises Area"
                                                                        onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                        error={errors.bparea && touched.bparea && errors.bparea ? true : false} value={values.bparea}
                                                                        helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="16th" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" //  label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea &&  touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="20th" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" //   label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="21st" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="23rd" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" //  label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="25th" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" //  label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="28th" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea"  //    label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={{ span: 3 }} className="margin_set_col">
                                                            <Row><Col md={{ span: 4 }}><Typography text="30th" styleType="B" /></Col>
                                                                <Col md={{ span: 8 }}>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" //   label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur}InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                     value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardContent>
                                            </Card>
                                            <br></br>
                                            <Card>
                                                <CardContent>
                                                    <Row><Col md={{ span: 12 }} style={{ textAlign: 'center' }}><Typography text="Banking Parameters" styleType="A" /></Col></Row>
                                                    <Row>
                                                        <Col md={{ span: 6 }}><Typography text="Average" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}><Typography text="No of Credit Trx" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ span: 6 }}><Typography text="Net Inflow" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}><Typography text="No of Debit Trx" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ span: 6 }}><Typography text="Gross Inflow" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}><Typography text="No of OW Retn" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ span: 6 }}><Typography text="	OD/CC Interest" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea"// label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                        <Col md={{ span: 6 }}><Typography text="No of IW Retn" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                    onChange={handleChange} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                    error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                    value={values.bparea} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                                </ListItem>
                                                            </List>
                                                        </Col>
                                                    </Row>
                                                </CardContent>
                                            </Card>
                                        </Collapse>
                                        <ListItem button onClick={handleClick1} className={classes.root}>
                                            <ListItemIcon>{open1 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                            <ListItemText primary="Month 2" />
                                        </ListItem>
                                        <Collapse in={open1} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                            <br></br>
                                                <Row>
                                                    <Col md={{ span: 6 }}><Typography text="Average Balance" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField id="filled-error-helper-text standard-basic" name="bparea"// label="Enter Business Premises Area"
                                                                onChange={e => setpanvalue(e.target.value)} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                value={panvalue} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}><Typography text="CNo of Credit Trx" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                onChange={e => setpanvalue(e.target.value)} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                value={panvalue} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                </Row>
                                                            {/* <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Average Balance</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>CNo of redit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem> */}
                                                <Row>
                                                    <Col md={{ span: 6 }}><Typography text="Total Deposits" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                onChange={e => setpanvalue(e.target.value)} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                value={panvalue} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}><Typography text=" No of Dedit Trx" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField id="filled-error-helper-text standard-basic" name="bparea" // label="Enter Business Premises Area"
                                                                onChange={e => setpanvalue(e.target.value)} onBlur={handleBlur} InputProps={{className: values.bparea ? classes.manorma : null,}}
                                                                error={errors.bparea && touched.bparea && errors.bparea ? true : false}
                                                                value={panvalue} helperText={errors.bparea && touched.bparea && errors.bparea}/>
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                </Row>
                                                            {/* <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Total Deposits</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'> No of Dedit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem> */}
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="OD/CC Interest paid on time ?" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of OW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of IW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            {/* <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>OD/CC Interest paid on time ?</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of OW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem> */}

                                                            {/* <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of IW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}></Grid>
                                                                <Grid xs={6}></Grid>
                                                            </ListItem> */}
                                                        </List>
                                                    </Collapse>
                                                    <ListItem button onClick={handleClick2}
                                                        className={classes.root}
                                                    >
                                                        <ListItemIcon>
                                                            {open2 ? <ExpandLess /> : <ChevronRight />}
                                                        </ListItemIcon>
                                                        <ListItemText primary="Month 3" />
                                                    </ListItem>
                                                    <Collapse in={open2} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            <br></br>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Average Balance" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="CNo of Credit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Total Deposits" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text=" No of Dedit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="OD/CC Interest paid on time ?" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of OW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of IW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                        </List>
                                                        {/* <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Average Balance</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of Credit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Total Deposits</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'> No of Dedit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>OD/CC Interest paid on time ?</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of OW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of IW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}></Grid>
                                                                <Grid xs={6}></Grid>
                                                            </ListItem>
                                                        </List> */}
                                                    </Collapse>
                                                    <ListItem button onClick={handleClick3}
                                                        className={classes.root}
                                                    >
                                                        <ListItemIcon>
                                                            {open3 ? <ExpandLess /> : <ChevronRight />}
                                                        </ListItemIcon>
                                                        <ListItemText primary="Month 4" />
                                                    </ListItem>
                                                    <Collapse in={open3} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            <br></br>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Average Balance" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="CNo of Credit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Total Deposits" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text=" No of Dedit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="OD/CC Interest paid on time ?" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of OW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of IW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                        </List>
                                                        {/* <List component="div" disablePadding>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Average Balance</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of Credit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Total Deposits</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'> No of Dedit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>OD/CC Interest paid on time ?</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of OW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of IW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}></Grid>
                                                                <Grid xs={6}></Grid>
                                                            </ListItem>
                                                        </List> */}
                                                    </Collapse>
                                                    <ListItem button onClick={handleClick4}
                                                        className={classes.root}
                                                    >
                                                        <ListItemIcon>
                                                            {open4 ? <ExpandLess /> : <ChevronRight />}
                                                        </ListItemIcon>
                                                        <ListItemText primary="Month 5" />
                                                    </ListItem>
                                                    <Collapse in={open4} timeout="auto" unmountOnExit>
                                                        {/* <List component="div" disablePadding>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Average Balance</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of Credit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Total Deposits</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'> No of Dedit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>OD/CC Interest paid on time ?</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of OW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of IW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}></Grid>
                                                                <Grid xs={6}></Grid>
                                                            </ListItem>
                                                        </List> */}
                                                        <List component="div" disablePadding>
                                                            <br></br>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Average Balance" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="CNo of Credit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Total Deposits" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text=" No of Dedit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="OD/CC Interest paid on time ?" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of OW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of IW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                        </List>
                                                    </Collapse>
                                                    <ListItem button onClick={handleClick5}
                                                        className={classes.root}
                                                    >
                                                        <ListItemIcon>
                                                            {open5 ? <ExpandLess /> : <ChevronRight />}
                                                        </ListItemIcon>
                                                        <ListItemText primary="Month 6" />
                                                    </ListItem>
                                                    <Collapse in={open5} timeout="auto" unmountOnExit>
                                                        {/* <List component="div" disablePadding>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Average Balance</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of Credit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>Total Deposits</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'> No of Dedit Trx</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>OD/CC Interest paid on time ?</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of OW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                            </ListItem>
                                                            <ListItem >
                                                                <Grid xs={4}><font className={classes.listitem} color='black' size='20px'>No of IW Retn</font></Grid>
                                                                <Grid xs={6}><TextField id="standard-secondary" className={classes.textfield} variant="outlined" color="primary" value={panvalue}
                                                                    onChange={e => setpanvalue(e.target.value)} /></Grid>
                                                                <Grid xs={4}></Grid>
                                                                <Grid xs={6}></Grid>
                                                            </ListItem>
                                                        </List> */}
                                                        <List component="div" disablePadding>
                                                            <br></br>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Average Balance" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="CNo of Credit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="Total Deposits" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text=" No of Dedit Trx" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="OD/CC Interest paid on time ?" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of OW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={{ span: 6 }}>
                                                                    <Typography text="No of IW Retn" styleType="B" />

                                                                    <List component="div" disablePadding>
                                                                        <ListItem>
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic"
                                                                                name="bparea"
                                                                                // label="Enter Business Premises Area"
                                                                                onChange={e => setpanvalue(e.target.value)}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.bparea ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.bparea &&
                                                                                        touched.bparea &&
                                                                                        errors.bparea
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={panvalue}
                                                                                helperText={
                                                                                    errors.bparea &&
                                                                                    touched.bparea &&
                                                                                    errors.bparea
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </List>
                                                                </Col>
                                                            </Row>
                                                        </List>
                                                    </Collapse>
                                                    <div className='btn_row_fixd'>
                                                        <Row>
                                                            <Col md={{ span: 12 }}>
                                                                <Row>
                                                                <Col md={{ span: 3}}>
                                                                    <Button className={classes.button_div} variant="outlined" color="primary" style={{ width: '100%'}}  onClick={handledata}> <Icon path={mdiArrowLeftBox} title="reset" size={1}/>Back</Button>
                                                                </Col>
                                                                <Col md={{ span: 3 }}>
                                                                    <Button className={classes.button_div} variant="outlined" color="primary" style={{ width: '100%'}} onClick={handledata}> <Icon path={mdiRefresh} title="reset" size={1} />Reset</Button>
                                                                    </Col>
                                                                <Col md={{ span: 3 }}>
                                                                    <Button className={classes.linkdin} variant="contained" color="primary" style={{ width: '100%'}} onClick={handleSubmit}><Icon path={mdiPlaylistPlus} title="save" size={1} /> Save and upload document</Button>
                                                                    </Col>
                                                                <Col md={{ span: 3 }}>
                                                                    <Button className={classes.linkdin} variant="contained" color="primary" style={{ width: '100%'}} onClick={handleSubmiting}> Next <Icon path={mdiArrowRightBox} title="reset" size={1} /></Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </div>

                            </form>
                        );
                    }}
                </Formik >
            )
            }
        </Container >

    );
}