import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import logo from "../../image/logo.png";
import "../../index.css";
import { Button } from "@material-ui/core";
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { ErrorCatch, apiCalling } from "../../shared/constants";

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
    },
    media: {
        // height: 140,
    },
});


//const [txnId,setTxnId] = React.useState();
const bankDataPullResponse = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        // 
        ///    //console.log(query);
        perfiosStatus();

    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [perfiosId, setPerfiosId] = React.useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [finalMessage,setFinalMessage] = React.useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [greet,setGreet] = React.useState("");
    //Stage 1

    // const perfiosStatusOld = async () => {
    //     

    //     var query = window.location.href;
    //     //console.log('query------------------', query);
    //     let data = query.split('=');

    //     let txnId = data[1];
    //     //console.log(apiCalling.thirdParty + 'server.php/apiperofios?app_name=txnstatus&&txnId=' + txnId + '&&create=Prem&&modify=Prem');
    //     await axios.post(apiCalling.thirdParty + 'server.php/apiperofios?app_name=txnstatus&&txnId=' + txnId + '&&create=Prem&&modify=Prem').then(
    //         (result) => {
    //             //console.log(result);
    //             let data = JSON.parse(result.data.data.result.replace('@', ''));
    //             let dataStringyfy = JSON.stringify(data.Part).replace('@', '');
    //             let dataParse = JSON.parse(dataStringyfy);
    //             //console.log("parse data ", dataStringyfy);
    //             //console.log("stringyData", dataParse);
    //             if (dataParse.attributes.status == "success") {
    //                 setPerfiosId(dataParse.attributes.perfiosTransactionId);
    //                 //console.log("dataParse.attributes.perfiosTransactionId" + dataParse.attributes.perfiosTransactionId);
    //                 bankRetrieveApi(dataParse.attributes.perfiosTransactionId);
    //             }
    //         }
    //     ).catch(
    //         (error) => {
    //             //console.log(error);
    //         }
    //     );
    // }

    const perfiosStatus = async () => {
        
        var query = window.location.href;
        console.log('query------------------', query);
        let data = query.split('=');
        let perfiosdata;
        if (localStorage.getItem('perfiosBankData')) {
            perfiosdata = JSON.parse(localStorage.getItem('perfiosBankData'));
        }
        let txnId = data[1];
        console.log("\n \n perifos", perfiosdata)
        let dataBody = {
            app_name:"txnstatus",
            txnId: txnId,
            create: perfiosdata?perfiosdata.user_id:null,
            modify: perfiosdata?perfiosdata.user_id:null,
        }

        console.log("txnstaus api",dataBody);
        await axios.post(apiCalling.thirdParty + 'server.php/apiperofios1', dataBody).then(
            (result) => {
                console.log("reached stage apiperofios1",dataBody);
                console.log("\n \ndataParsess ", result)

                //console.log("result-->", result);
                //console.log("resut.data.data", result.data.data);
                //console.log("resut.data.data.result", result.data.data.result);
                let data = JSON.parse(result.data.data.result.replace('@', ''));
                let dataStringyfy = JSON.stringify(data.Part).replace('@', '');
                let dataParse = JSON.parse(dataStringyfy);
                //console.log("parse data ", dataStringyfy);
                //console.log("stringyData", dataParse);
                if (dataParse.attributes.status == "success") {
                    console.log("stage 1 success",dataParse.attributes);
                    setPerfiosId(dataParse.attributes.perfiosTransactionId);
                    //console.log("dataParse.attributes.perfiosTransactionId" + dataParse.attributes.perfiosTransactionId);
                    bankRetrieveApi(dataParse.attributes.perfiosTransactionId);
                    setFinalMessage("Your bank data fetched successfully please return to banking page.");
                    setGreet("Thank You");
                }else{
                    setFinalMessage("Something went Wrong Please try Again.");
                    setGreet("Sorry");
                }
            }
        ).catch(
            (error) => {
                //console.log(error);
            }
        );
    }

    //Stage 2

    const bankRetrieveApiOld = async (perfiosTransactionId) => {
        //(XML, PDF, XLS, XLSX, JSON
        var query = window.location.href;
        //console.log("query---->" + query);
        let data = query.split('=');
        let txnId = data[1];
        const list = ['xml'];

        for (let i = 0; i < list.length; i++) {
            await axios.get(apiCalling.thirdParty + 'server.php/apiperofios?app_name=retrieve&&txnId=' + txnId + '&&perfiosTId=' + perfiosTransactionId + '&&reportType=' + list[i] + '&&create=Prem&&modify=Prem').then(result => {
                //console.log("bankRetrieveApi", result);
                //console.log("bankRetrieveApi", result.status);
                if (result.status == 200) {
                    //console.log(",result.data.data", result.data.data);
                    //console.log("result.data", result.data);

                    if (list[i] == 'xml') {
                        //console.log('xml');
                        savePerfiosData(result.data.result1);
                    }
                }
            }
            ).catch(error => {
                //console.log(error);
            });
        }
    }


    const bankRetrieveApi = async (perfiosTransactionId) => {
        //(XML, PDF, XLS, XLSX, JSON

        //console.log("retrive reached");
        var query = window.location.href;
        //console.log("query---->" + query);
        let data = query.split('=');
        let txnId = data[1];
        const list = ['xml'];
        let perfiosdata;
        if (localStorage.getItem('perfiosBankData')) {
            perfiosdata = JSON.parse(localStorage.getItem('perfiosBankData'));
        }
        let data1 = {
        }
        for (let i = 0; i < list.length; i++) {
            data1 = {
                app_name: "retrieve",
                txnId: txnId,
                perfiosTId: perfiosTransactionId,
                reportType: list[i],
                create: perfiosdata.user_id,
                modify: perfiosdata.user_id
            }

            //console.log("retve data",data1);
            await axios.post(apiCalling.thirdParty + 'server.php/apiperofios1', data1).then(result => {
                //console.log("bankRetrieveApi", result);
                //console.log("bankRetrieveApi", result.status);
                if (result.status == 200) {
                    //console.log(",result.data.data", result.data.data);
                    //console.log("result.data", result.data);
                    if (list[i] == 'xml') {
                        //console.log('xml');
                        //console.log(result.data.result1);
                        console.log("stagge 2 success",result.data.result1);
                        savePerfiosData(result.data.result1);
                    }
                }
            }
            ).catch(error => {
                //console.log(error);
            });
        }
    }

    ///Stage 3
    const savePerfiosData = (stage2Result) => {
        console.log("entered save api");
        console.log(stage2Result);
        let perfiosdata;
        if (localStorage.getItem('perfiosBankData')) {
            perfiosdata = JSON.parse(localStorage.getItem('perfiosBankData'));
        }
        let loanData;
        if (localStorage.getItem('bankloanapplydata')) {
            loanData = JSON.parse(localStorage.getItem('bankloanapplydata'));
        }
        let data = {
            parent_type_id: perfiosdata.parent_type_id,
            parent_id: perfiosdata.parent_id,
            headerId: perfiosdata.headerId,
            user_id: perfiosdata.user_id,
            bank_id:loanData.bank_id,
            account_no:loanData.account_no,
            result: stage2Result
        }
        //console.log("result from stage2", stage2Result)

        axios.post(apiCalling.coderscorner + 'server.php/api/capturePerfiosData', data).then(result => {
            console.log("capturePerfiosData");
            console.log(result)
        }).catch(error => {
            //console.log(error);
        });
    }
    // const classes = useStyles();
    //console.log(window.location.pathname);

    const goToProfileSteper = () => {
        localStorage.setItem('perfiosreturn', 0)
        props.history.push({ pathname: "/ProfileSteper" });
    }


    return (
        <>
            <Row>
                {//                 <Col md={{ span: 2 }}>
                    //                     <div >
                    //                         <img src={logo} width='100%' height="auto" style={{ padding: '15px', marginRight: '12px' }} alt="bank" />
                    //                     </div>
                    //                 </Col>
                    //             </Row>
                    //             <Row>
                    //                 <Col md={{ span: 12 }}>
                    //                     <Divider
                    //                         style={{ backgroundColor: "rgb(114, 84, 1)" }}
                    //                     />
                    //                 </Col>
                    //                 <div className="login-form1">
                    //                     <Paper elevation={3} style={{
                    //                         position: 'absolute',
                    //                         left: '50%',
                    //                         top: '50%',
                    //                         transform: 'translate(-50%, -50%)',
                    //                         // border: "1px solid blue",
                    //                         padding: "50px"
                    //                     }}>
                    //                         <p style={{ fontSize: "20px" }}>
                    //                             Thank You!!
                    // </p>
                    //                         <p style={{ fontSize: "15px" }}>
                    //                             You will be redirected to the main page.
                    // </p>
                    //                     </Paper>
                    //                 </div>
                }

                <Card className={classes.root} id="perfios_main_card" style={{
                    position: 'absolute',
                    left: "23%",
                    top: "30%",
                    padding: "15px",
                    width: "55%"
                    //border: "1px solid black",
                }}>
                    {/* <CardActionArea> */}
                    <CardMedia className={classes.media}
                        image={logo}
                        title="Contemplative Reptile"
                        // id="perfiosCard"
                        style={{ width: "22%", height: "30px" }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className="perfios_card_header">
                           {greet}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className="perfios_card_para">
                        {finalMessage}
                            
                        </Typography>
                    </CardContent>
                    {/* </CardActionArea> */}
                    <CardActions style={{ float: "right", width: "6%" }}>
                        {/* <Button color="primary" onClick={() => { goToProfileSteper() }}>
                            Return
                         </Button> */}
                        <span className="image">
                            <img alt =" " className='backbutton navbutton' style={{ width: "150%" }} src={Back} onClick={() => { goToProfileSteper() }} />
                            <Tooltip title="Return to Bank" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                <img alt =" " className='backbutton navgraybutton' style={{ width: "150%" }} src={grayback} onClick={() => { goToProfileSteper() }} />
                            </Tooltip>
                        </span>
                    </CardActions>
                </Card>
            </Row>
        </>
    )
}

export default bankDataPullResponse;