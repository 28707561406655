import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Dropdown, } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik } from "formik";
import { apiCalling } from '../adminConstants';
import { entityoption } from "../../shared/constants";
import { nationalitySchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import {
    mdiPlaylistPlus, mdiRefresh,
} from "@mdi/js";
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from '../../image/buttons/graydraftnext.png';
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

export default function State(props) {

    const classes = useStyles();
    const [table, settable] = React.useState(false);
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);
    const [showList, setShowList] = React.useState(true);
    const [loaderFlag, setLoaderFlag] = React.useState(true);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        handleStateList();
    }, []);

    const handleSubmiting = () => {
        props.handleNext();
    };

    const handledata = () => {
        props.handleBack();
    };

    const handleselectChange = (event) => {
        event.preventDefault();
        this.setState({
            showOption: true
        })
    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handlecheck();
    }

    let [initialValue, setInitialValue] = React.useState({
        country: "",
        enable: "",
        id: "",
        alpha_2_code: "",
        alpha_3_code: "",
        sort_order: ""
    })


    const [StateList, setStateList] = React.useState([]);
    const handleStateList = async () => {
        const data = {
            enable: 1
        };
        await axios
            .post(apiCalling.coderscorner +
                `server.php/api/getAllnationality`,
                data
            ).then((result) => {
                let empArray = [];
                result.data.data.map((item) => {
                    empArray.push({
                        country: item.country,
                        alpha_2_code: item.alpha_2_code,
                        alpha_3_code: item.alpha_3_code,
                        country_code: item.country_code,
                        currency: item.currency,
                        enable: item.enable.toString(),
                        id: item.id,
                        sort_order: item.sort_order

                    })
                })
                setShowWCAddForm(false);
                setShowList(true);
                setLoaderFlag(false);
                console.log("EmpArray", empArray);
                setStateList(empArray);
            }).catch((error) => {
                console.log(error);
            });

    };

    const data = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Nationality",
                field: "country",
                width: 270,
            },
            {

                label: "Alpha2 Code",
                field: "alpha_2_code",
                width: 270,
            },
            {

                label: "Alpha3 Code",
                field: "alpha_3_code",
                width: 270,
            },

            {
                label: "Country Code",
                field: "country_code",
                width: 200,
            },

            {
                label: "Currency",
                field: "currency",
                width: 200,
            },

            {
                label: "Enable",
                field: "enable",
                width: 200,
            },

            {
                label: "Action",
                field: "action",
                width: 200,
            },

        ],

        rows: StateList.map((values, i) => {
            return {
                slno: i + 1,
                country: values.country,
                alpha_2_code: values.alpha_2_code,
                alpha_3_code: values.alpha_3_code,
                country_code: values.country_code,
                currency: values.currency,
                enable: values.enable ? "true" : "false",
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />
                        </Tooltip>

                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }


    const handlesubmitform = (values, { resetForm }) => {
        

        handleNextClick(values);
        resetForm({
            country: "",
            alpha_2_code: "",
            alpha_3_code: "",
            sort_order: "",
            country_code: "",
            currency: "",
            enable: "",
            id: ""
        })

    };
    const handleNextClick = (data) => {
        
        setShowWCAddForm(false);
        setLoaderFlag(true);
        //  let eligibilityFlag = 0;
        /*  if (checkvalue === 1) {
           eligibilityFlag = 1;
         }  */

        let wcData = {
            sort_order: 1,
            country: data.country,
            country_code: data.country_code,
            currency: data.currency,
            alpha_2_code: data.alpha_2_code,
            alpha_3_code: data.alpha_3_code,
            nationality_id: data.id,
            enable: data.enable
        };

        console.log("data", data);
        axios.post(apiCalling.coderscorner + `server.php/api/nationalityAddOrEdit`, wcData).then((res) => {
            if(res.data.status){
                setFormat("success");
                setSnackBarMsg(res.data.message);
                setsavenextmessage(true);
                console.log(res.data);
                console.log("saved successfully...!!!");
                setShowWCAddForm(false);
                setShowList(true);
                handleStateList(false);
            }
        })
            .catch((er) => {
                console.log("no data sorry ", er);
                setFormat('error')
                setSnackBarMsg('something went wrong')
                setsavenextmessage(true)
            });
    };

    const handleOpenWCAddForm = () => {

        setAddFormButtonValue("Submit");
        setShowWCAddForm(true);
        setShowList(false);

        setInitialValue({
            country: "",
            id: "",
            enable: "",
            alpha_2_code: "",
            alpha_3_code: "",
            country_code: "",
            currency: "",
            sort_order: ""
        })
    }

    const handleEdit = (data) => {
        setShowWCAddForm(true);

        setAddFormButtonValue("Update");

        setShowList(false);

        setInitialValue({
            ...data,
            sort_order: 1,
            country: data.country,
            id: data.id,
            alpha_3_code: data.alpha_3_code,
            alpha_2_code: data.alpha_2_code,
            country_code: data.country_code,
            currency: data.currency,
            enable: data.enable ? "1" : "0"
        })
    }

    const handleDelete = (item) => {
        let data = {
            id: item.id
        }
        axios.post(apiCalling.coderscorner + `server.php/api/deleteNationality`, data).then((res) => {
            // setShowWCAddForm(false);
            // setShowList(true);
            // handleStateList();
            if(res.data.status){
                setFormat("success");
                setSnackBarMsg(res.data.message);
                setsavenextmessage(true);
                setShowWCAddForm(false);
                setShowList(true);
                handleStateList();
            }
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    }

    const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [format, setFormat] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const closeSuccessToast = () => {
        setsavenextmessage(false)
      }

    return (
        <Container maxWidth="lg">
        <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>
            <div className="mini-container" style={{ marginTop: 0, padding: '15px' }}>
                {loaderFlag == true && (
                    <div style={{ textAlign: 'center', marginTop: '15%' }}>
                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                    </div>
                )}
                {showList == true && loaderFlag == false && (
                    <div>
                        <div className="content-header">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <Typography
                                        text="Natioanlity"
                                        styleType="C"
                                    />
                                </Col>
                                <Col md={{ span: 2 }} >
                                    {/* <button className="btn btn-primary adminAddBtn" onClick={() => handleOpenWCAddForm()} >
                                        Add
                                                 </button> */}
                                    <span className="image">
                                        <img className='navbutton backbutton' src={blueadd} onClick={() => handleOpenWCAddForm()} />
                                        <Tooltip title="Add" arrow>
                                            <img className='navgraybutton backbutton' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <Row className="datable" style={{ marginTop: '30px', }} >

                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                <div>
              <ReactHTMLTableToExcel
                id="karzaTblBtn"
                className="btn btn-info exportBtn"
                table="karzaTbl"
                filename="StateMaster"
                sheet="StateMaster"
                buttonText="Export excel"
              />
            </div>
                                    <MDBDataTableV5
                                        hover 
                                        entriesOptions={[5, 10,20, 25]} 
                                        entries={5} 
                                        pagesAmount={4} 
                                        data={data}
                                        id = "karzaTbl"

                                        // searchTop 
                                        // searchBottom={false}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className='btn_row_fixd'>
                            <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                {/* <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                    </Tooltip>
                                </Col>
                                <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Save as Draft & Next" arrow>
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                    </Tooltip>
                                </Col> */}
                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                        <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                            <img className='navgraybutton' src={grayback} onClick={handledata} />
                                        </Tooltip>
                                    </span>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <span className="image">
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                        <Tooltip title="Save as Draft & Next" arrow>
                                            <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
                <Row>
                    <Col md={{ span: 12 }}>
                        {showWCAddForm == true && (
                            <Formik
                                enableReinitialize
                                initialValues={initialValue}
                                validationSchema={nationalitySchema}
                                onSubmit={handlesubmitform}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    resetForm,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldTouched
                                    /* and other goodies */
                                }) => {
                                    //  console.log("values in formik", values);
                                    return (
                                        <form onSubmit={handleSubmit}>

                                            <div className="content-header" style={{ position: 'unset' }}>
                                                <Row>
                                                    <Col md={{ span: 10 }}>
                                                        <Typography
                                                            text="Nationality"
                                                            styleType="C" />
                                                    </Col>

                                                    <Col md={{ span: 2 }}>
                                                        <div className="content-header-button ">
                                                            {/* <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                                            </Button> */}
                                                            <span className="image">
                                                                <img className='backbutton navbutton' src={Back} onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} />
                                                                <Tooltip title="Back" arrow>
                                                                    <img className='backbutton navgraybutton' src={grayback} onClick={() => {
                                                                        resetForm();
                                                                        setShowWCAddForm(false);
                                                                        setShowList(true);
                                                                    }} />
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: '30px' }}>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Nationality" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="country"
                                                                    label="Enter Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.country &&
                                                                            touched.country &&
                                                                            errors.country
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.country ? classes.manorma : null,
                                                                    }}
                                                                    value={values.country}
                                                                    helperText={
                                                                        errors.country &&
                                                                        touched.country &&
                                                                        errors.country
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Alpha2 Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="alpha_2_code"
                                                                    label="Enter Alpha Code "
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.alpha_2_code &&
                                                                            touched.alpha_2_code &&
                                                                            errors.alpha_2_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.alpha_2_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.alpha_2_code}
                                                                    helperText={
                                                                        errors.alpha_2_code &&
                                                                        touched.alpha_2_code &&
                                                                        errors.alpha_2_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>

                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Alpha2 Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="alpha_3_code"
                                                                    label="Enter Alpha Code "
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.alpha_3_code &&
                                                                            touched.alpha_3_code &&
                                                                            errors.alpha_3_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.alpha_3_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.alpha_3_code}
                                                                    helperText={
                                                                        errors.alpha_3_code &&
                                                                        touched.alpha_3_code &&
                                                                        errors.alpha_3_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>

                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Country Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="country_code"
                                                                    label="Enter Country code"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.country_code &&
                                                                            touched.country_code &&
                                                                            errors.country_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.country_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.country_code}
                                                                    helperText={
                                                                        errors.country_code &&
                                                                        touched.country_code &&
                                                                        errors.country_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Currency" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="currency"
                                                                    label="Enter Currency"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.currency &&
                                                                            touched.currency &&
                                                                            errors.currency
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.currency ? classes.manorma : null,
                                                                    }}
                                                                    value={values.currency}
                                                                    helperText={
                                                                        errors.currency &&
                                                                        touched.currency &&
                                                                        errors.currency
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>

                                                    <Col md={{ span: 6 }}>
                                                        <div style={{ marginTop: '20px' }}>
                                                            <Typography text="Enable" styleType="B" />

                                                        </div>
                                                        <List component="div" disablePadding>
                                                            <ListItem >
                                                                <label>
                                                                    Yes
                                                          <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="1"
                                                                        checked={values.enable === '1'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    No
                                                           <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="0"
                                                                        checked={values.enable === '0'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                            </ListItem>
                                                            {errors.enable && touched.enable && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.enable}</div>}
                                                        </List>
                                                    </Col>
                                                </Row>
                                                <Col md={{ span: 12 }}>
                                                    <Row><Col md={{ span: 10 }}></Col>
                                                        <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                        </Col>
                                                        <Col md={{ span: 5 }}></Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        )}
                    </Col>
                </Row>
            </div>
        </Container>

    );
}