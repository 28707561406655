


import React from "react";
import { loadCSS } from "fg-loadcss";
import Snackbar from '@material-ui/core/Snackbar';
import { SnackPosition } from "../../shared/constants";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core/";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SaveIcon from "@material-ui/icons/Save";
import { TextField, Button, Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
//import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from '@material-ui/core/TablePagination';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Input from "@material-ui/core/Input";
import { Dropdown } from "semantic-ui-react";
import { useStyle } from "./styles";
import clsx from "clsx";
import { bankoption, natureaccount } from "../../shared/constants";
import { StyledTableCell } from "../../shared/constants";
import { StyledTableRow } from "../../shared/constants";
import { bankrow, countryOptions } from "../../shared/constants";
import { splitButton1, splitButton2 } from "../../shared/constants";
import { Formik } from "formik";
import { bankFormschema } from "../../shared/validations";
import Modal from "react-bootstrap/Modal";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import moment from 'moment';
import { MDBDataTable } from "mdbreact";
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import Tooltip from "@material-ui/core/Tooltip";
import BusinessInfo from '../BusinessInfoForm/index';
import Typography from "../../base_components/Typography";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorCatch, apiCalling } from "../../shared/constants";

import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';


import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
//import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import CpaMainPage from '../CpaMainPage/index';
import ScaMainPage from '../ScaMainPage/index';
import DsaMainPage from '../DsaMainPage/index';
import BankerPage from '../BankerPage';
import AnalystMainPage from '../AnalystMainPage';
import editrec from '../../image/pngicons/edit.png';
import uploaddoc from '../../image/pngicons/upload.png';
import addbusiness from '../../image/pngicons/add.png';
import skip from '../../image/pngicons/skip.png';
import deleterec from '../../image/pngicons/delete.png';
import DocumentsCollector from '../DocumentsCollector/index';
import BankerRoleBookAppointment from '../BankerRoleBookAppointment/index';
// import AdminDashboard from '../../AdminComponents/AdminDashboard';
import SearchBar from "material-ui-search-bar";

export default function Banking(props) {
  const [navTabState, setnavTabState] = React.useState(false);
  const classes = useStyle();
  const [panvalue, setpanvalue] = React.useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [show, setshow] = React.useState(false);
  const [shows, setdigital] = React.useState(false);
  const [disp, setdisp] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLoan, setOpenLoan] = React.useState(false);
  const [showform, setshowform] = React.useState(false);
  const [startTab, setStartTab] = React.useState(false);
  const [topform, settopform] = React.useState(true);
  const [tableopen, settableopen] = React.useState(false);
  const [table, settable] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [now, setnow] = React.useState(50);
  const [modelbank, setmodelbank] = React.useState(false);
  const [busIdForDel, setbusIdForDel] = React.useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [loaderFlag, setLoaderFlag] = React.useState(true);


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const BPOptions = [
    'Business',
    'Promoter'
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBPOptions = Boolean(anchorEl);

  const handleClickBPOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseBPOptions = () => {
    setAnchorEl(null);
  };

  const ApplicationOptions = [
    'Loan Application',
    'Check Eligibility'
  ];

  const [appAnchorEl, setAppAnchorEl] = React.useState(null);

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    getUserId();
    getAllBusinessbyuser();
    //LoanApplicationOfUser();
    localStorage.removeItem("rquestIdDetails");
    localStorage.removeItem("RequestType");
    localStorage.removeItem("handleFlow");
    getLoanStatus();
    getDocuments();
    console.log();
  }, []);




  ///getBusiness and shareholder details based on user detail
  const checkuserloggeddata = localStorage.getItem("userdata");
  const [userBusinessList, setUserBusinessList] = React.useState([]);
  //search bar
  const [searched, setSearched] = React.useState("");
  const [newBus, setNewBus] = React.useState();
  const requestSearch = (searchedVal) => {
    //
    let busArr = userBusinessList;
    if (searchedVal.length != 0 && searchedVal != "") {
      const filteredRows = busArr.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setUserBusinessList(filteredRows);
    } else {
      setUserBusinessList(newBus)
    }
  };

  const cancelSearch = () => {
    //
    setSearched("");
    setUserBusinessList(newBus)
  }



  let iduser = JSON.parse(checkuserloggeddata);
  if (iduser.role_id == 2) {
    localStorage.removeItem('userbycpa');
  }

  console.log("user logged data of userid", iduser.id);
  const getAllBusinessAndPromoter = async () => {

    // setidofuser(iduser.id);
    let businessArray = [];
    let promoterArray = [];
    let data = {
      user_id: iduser.id
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getBusinessAndPromoterByUserId`;
    await axios.post(apiCalling.coderscorner + `server.php/api/getBusinessAndPromoterByUserId`, data).then(
      (result) => {

        if (result.data.data.businessList.length > 0) {
          setshowform(false);
          setStartTab(true);
          setBsId(result.data.data.businessList[0].id);
          result.data.data.businessList.map((item, i) => {
            businessArray.push({
              key: item.id,
              id: item.id,
              value: item.id,
              text: item.name,
              name: item.name,
              entity_type: item.entity_type,
              bpan: item.bpan,
              gst: item.gst,
              state_name: item.state_name,
              city_name: item.city_name,
              industry_group_name: item.industry_group_name

            })
          });
        } else {
          setStartTab(false);
          setshowform(true)
        }
        // setUserPromoterList(promoterArray);
        setUserBusinessList(businessArray);
        // getAllLoansDetailsByUserId(businessArray[0].id)
        console.log(businessArray);
        console.log(promoterArray)
      }
    ).catch((error) => {
      ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
      console.log(error);
    })
  };

  /// Business Information data
  // getting user Id from local storage 
  const getUserId = () => {
    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);
    console.log("main page user details", iduser);
    getAllBusinessAndPromoter(iduser.id);
  }

  //Get Client Loading Dashboard
  const [loansApprovedCount, setLoansApprovedCount] = React.useState();
  const [underProcessCount, setUnderProcessCount] = React.useState();
  const getLoanStatus = async () => {
    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);
    let data = {
      user_id: iduser.id
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getUserAllLoanStatus`;
    await axios.post(apiCalling.coderscorner + `server.php/api/getUserAllLoanStatus`, data).then((result) => {
      console.log("GetLoanStatus................!!!!!!!!!!", result.data.data);
      setLoansApprovedCount(result.data.data[0].count);
      setUnderProcessCount(result.data.data[1].count)
    }).catch((error) => {
      ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
      console.log(error);
    });
  }


  const handleClose = () => setmodelbank(false);


  const checkeligibiltyBusAndProm = () => {
    localStorage.setItem("check", JSON.stringify("Check Eligibility"));
    props.handleNext(0, 0, 1);
  }

  const applyforloanBusAndPromo = () => {
    localStorage.setItem("check", JSON.stringify("Loan Application"));
    props.handleNext(0, 0, 1);
  }

  const checkeligibilty = () => {
    localStorage.setItem("check", JSON.stringify("Check Eligibility"));
    props.handleNext(0, 0, 3);
  };

  const applyforloan = () => {
    localStorage.setItem("check", JSON.stringify("Loan Application"));
    props.handleNext(0, 0, 3);
  };


  const handleNextClick = () => {
    const localStorageData = localStorage.getItem("banking"),
      bankdata = JSON.parse(localStorageData);
    setdata(bankdata);
    console.log("shareholder", bankdata);
    settopform(false);
    // setshowform(false)
    settable(true);
    settableopen(true);
    console.log("clicked next");
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmiting = () => {
    props.handleNext();
  };
  const handleSubmit = () => {
    setshow(false);
    setdigital(false);
    setdisp(true);
  };
  const handleSubmitdigital = () => {
    setdigital(true);
    setmodelbank(false);
  };

  const handleSubmited = () => {
    setshow(true);
    setmodelbank(false);
  };

  const handledata = () => {
    props.handleBack();
  };

  const toggleNavTab = () => {
    setnavTabState(!navTabState);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role='presentation ctm_set_div'
    //  onClick={toggleDrawer(anchor, false)}
    //  onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className='profile_table'>
        <div className='profile_table_part'>
          <table style={{ marginBottom: 30 }}>
            <thead>
              <tr>
                <th>
                  <h3>Our Products</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <h4>Show a first time product primer to the applicant</h4>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th>
                  <h3>Our News Feed</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <h4>
                    Product announcements, new tie-ups, offerings, special
                    offers, informational links etc
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
      {/* <div className='loans_parts'></div> */}
      {/* <div className='loans_parts'>
                <h3>Our News Feed</h3>
                <p>Product announcements, new tie-ups, offerings, special offers, informational links etc</p>
            </div> */}
    </div>
  );

  const SaveasDraft = () => {
    alert("Save As Draft");
    props.handleNext();
  };

  const hedaer1 = () => {
    // return (
    //  <h1>hello</h1>
    //)
  }

  const classes1 = useStyles();
  const [value, setValue] = React.useState('1');

  const handleChange1 = (event, newValue) => {
    setEditInitialValue({
      ...setEditInitialValue,
      company: bsid
    })
    setOpenTabBusPro(false);
    setValue(newValue);
  };

  const [value2, setValue2] = React.useState('1');
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
    setOpenTab(false)
  };

  ///get business of the user based on his id
  // const [bsid, setBsId] = useState();
  const [businesslist, setbusinesslist] = React.useState([]);
  const [idofuser, setidofuser] = React.useState();
  const [bsid, setBsId] = React.useState();
  const [businessIdForDropDown, setBusinessIdForDropDown] = React.useState(0);
  const [businessOfPromoter, setBusinessIdOfPromoter] = React.useState(0);
  const setBusinessIdForPro = (iddd) => {
    //  
    // setBusinessIdOfPromoter(iddd);
    getAllPromoterByBusinessId(iddd);
    getBusinessbybusinessId(iddd);
  }

  const [userRole, setUserRole] = React.useState();
  const getAllBusinessbyuser = async () => {
    
    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);
    console.log("user logged data of userid", iduser.id);
    setidofuser(iduser.id);
    setUserRole(iduser.role_id);
    let arralen;
    const data = {
      user_id: iduser.id,
    };
    let busArr = [];
    let api_path = apiCalling.coderscorner + "server.php/api/getBussinessByuserid";
    await axios
      .post(apiCalling.coderscorner +
        "server.php/api/getBussinessByuserid",
        data
      )
      .then((result) => {
        //alert('user')
        console.log("\n \n result of own", result)
        if (result.data.status && result.data.data.length > 0) {
          arralen = result.data.data.length;
          setBsId(result.data.data[0].id);
          getAllPromoterByBusinessId(result.data.data[0].id);
          setbusIdForDel(result.data.data[0].id);
          //  setBusinessIdForDropDown(result.data.data[0].id);
          result.data.data.map((item) => {
            busArr.push({
              key: item.id,
              businessName: item.name,
              businessid: item.id,
              value: item.id,
              text: item.name,
            });
          });
          console.log(result.data.data[0].id);
          setbusinesslist(busArr);
          setbusinesslist(busArr);
          console.log("businessAll by userId", busArr);
          console.log(businesslist);
          console.log(bsid);
        } else {
          // alert("please add ")
        }
      })
      .catch((error) => {
        ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
        console.log(error);
      });
    LoanApplicationOfUser(arralen);
  };
  // set initialBus id
  const setBusId = (initialBusId) => {
  }

  const [showPromoterTable, setShowPromoterTable] = React.useState(false);
  const [promoterList, setPromoterList] = React.useState([]);
  const getAllPromoterByBusinessId = async (bid) => {
    
    const data = {
      bus_id: bid,
    };
    let promoArr = [];
    let api_path = apiCalling.coderscorner + `server.php/api/getAllpromoterinfobybusid`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllpromoterinfobybusid`, data)
      .then((result) => {
        if (result.data.status) {
          
          settopform(true)
          console.log(result.data);
          result.data.data.map((item) => {
            let typ = "";
            if (item.type === "1111") { typ = "Promoter"; }
            else if (item.type === "1233") { typ = "Borrower"; }
            promoArr.push({
              key: item.promoter_id,
              promoter_id: item.promoter_id,
              name: item.first_name + " " + item.last_name,
              type: typ,
              email: item.email,
              mobile: item.mobile,
              share: item.share_percent,
              value: item.promoter_id,
              text: item.first_name,
              eligibility_flag: item.eligibility_flag
            });
          });
          setPromoterList(promoArr);
          //console.log("businessAll by id", promoArr);
          //console.log(promoterList);
          setShowPromoterTable(true);
        } else {
          setPromoterList([]);
          setShowPromoterTable(false);
        }
      })
      .catch((error) => {
        ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
        console.log(error);
      });
  };

  //get business by businessid
  const getBusinessbybusinessId = (bussinessid) => {
    let getbusinesid = {
      "bus_id": bussinessid
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`;
    axios.post(apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`, getbusinesid).then((res) => {
      console.log("yeh we have business data by busis", res.data);
      let editdata1 = res.data.data;
      let copdate = moment(editdata1.setup_date).format('YYYY-MM-DD');

    })
      .catch((er) => {
        ErrorCatch(iduser.id, er, api_path, 'post', getbusinesid, iduser.id, iduser.id);
        console.log("no pincode sorry ", er);
      });
    getAllPromoterByBusinessId(bussinessid);
  }

  const getPromoterById = async (id) => {

    let data = {
      promo_or_shareholder_id: id,
    };
    let api_path = apiCalling.coderscorner + `server.php/api/getshareholderorPromotorInfoByid`;
    await axios
      .post(apiCalling.coderscorner +
        `server.php/api/getshareholderorPromotorInfoByid`,
        data
      )
      .then((result) => {
        console.log(result.data.data);
        // setArea1(result.data.data[0].landmark)
        //   setloarOrCheck(editdata1.eligibility_flag);
        let editdata1 = result.data.data[0];
        let pdob = moment(editdata1.dob).format('YYYY-MM-DD');
        setPromoterInitialValue({
          ...promoterInitialValue,
          id: editdata1.id,
          firstname: editdata1.first_name,
          lastname: editdata1.last_name,
          mobile: editdata1.mobile,
          email: editdata1.email,
          share: editdata1.share_percent,
          type: parseInt(editdata1.type),
        });
      })
      .catch((error) => {
        ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
        console.log(error);
      });
  };

  const setPromoterForPro = (value) => {
    getPromoterById(value);
  }

  const [initialValue, setEditInitialValue] = React.useState({
    company: bsid,
    businessName: "",
    date: "",
    gst: "",
    businesspan: "",
  });

  const [promoterInitialValue, setPromoterInitialValue] = React.useState({
    promoter: "",
    firstname: "",
    lastname: "",
    middlename: "",
    share: "",
    type: "",
    company: "",
    mobile: "",
    email: "",
  })

  const handleEditData = (businessId1) => {
    // return < BusinessInfo businessId1={businessId1} />;
    let i = 1;
    //  props.handleNext(i,, businessId1);
    props.handleNext(businessId1, false, 1);
  }

  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%"
  }

  const table_header = {
    fontWeight: "600",
    fontSize: "100%",
    width: "auto",
  };

  const getPromoterById1 = (promoterID) => {
    props.handleNext(promoterID, 0, 2)
  }

  const [applicationList, setApplicationList] = React.useState([]);
  const LoanApplicationOfUser = async (arralen) => {

    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);
    console.log("user logged data of userid", iduser.id);
    // setidofuser(iduser.id);
    let data = {
      user_id: iduser.id,
      Token: "qwerty1234"
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`;
    await axios.post(apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`, data).then((result) => {
      console.log("resultsss", result);
      if (result.data.status === true) {
        console.log("\n \n ahiya", result.data.data)
        setApplicationList(result.data.data)
        setLoaderFlag(false);
      } else {
        setLoaderFlag(false);
        if (arralen > 0) {
          setOpenDialog(true);

        }
        setApplicationList([]);

      }
      //  setLoaderFlag(false);
    }).catch((error) => {
      ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
      console.log(error);
    })
  }

  const goToLetsGetStarted = (item) => {
    
    localStorage.setItem("rquestIdDetails", JSON.stringify(item));
    localStorage.setItem("handleFlow", "letget");
    if (item.request_type_id === 0) {
      localStorage.setItem('RequestType', 0);
    } else {
      localStorage.setItem('RequestType', 1);
    }
    props.handleNextLetStart();
  }

  const options = ['Loan Application', 'Check Eligibility'];
  const [openTab, setOpenTab] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef1 = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickTab = () => {
    localStorage.setItem("check", JSON.stringify("Loan Application"));
    localStorage.setItem("RequestType", 0);
    localStorage.setItem("check", 0);
    localStorage.setItem("handleFlow", "letget")
    props.handleNext(3);
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (value) => {
    //const {value} = index.target;
    // console.log(event.target.value)
    setSelectedIndex(value);
    if (value === 0) {
      localStorage.setItem("check", JSON.stringify("Loan Application"));
      localStorage.setItem("RequestType", 0);
    } else {
      localStorage.setItem("check", JSON.stringify("Check Eligibility"));
      localStorage.setItem("RequestType", 1);

    }
    localStorage.setItem("handleFlow", "letget")
    props.handleNext(0, 0, 3);
    setOpenTab(false);
  };

  const handleCloseTab = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const anchorRefBusPro = React.useRef(null);
  const options2 = ['Add Business', 'Add Promoter'];
  const [selectedIndexBusPro, setSelectedIndexBusPro] = React.useState(0);
  const [openTabBusPro, setOpenTabBusPro] = React.useState(false);

  const handleClickTabBusPro = () => {
    props.handleNext(1, "bus");
  }

  const handleToggleBusPro = () => {
    setOpenTabBusPro((prev) => !prev)
  }

  const handleMenuItemClickBusPro = (index) => {
    localStorage.setItem("check", JSON.stringify("Loan Application"));
    setAnchorEl(null);
    setSelectedIndexBusPro(index);
    if (index === "Business") {
      props.handleNext(undefined, undefined, 1);
    } else {
      props.handleNext(undefined, undefined, 2);
    }
  }

  const handleCloseTabBusPro = (event) => {
    if (anchorRefBusPro.current && anchorRefBusPro.current.contains(event.target)) {
      return;
    }
    setOpenTabBusPro(false);
  };


  const handleClickOpeDailog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggle1 = () => {
    setOpenLoan((prevOpen) => !prevOpen);
  };


  const handleClose1 = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }
    setOpenLoan(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleListKeyDown1(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenLoan(false);
    }
  }
  //Page Data
  const [page, setPage] = React.useState(0);
  const [buspage, setBusPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //const [applicationList, setApplicationList] = React.useState([]);


  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const busHandleChangePage = (event, newPage) => {
    setBusPage(newPage);
  };
  const handleChangeRowsPerPagebus = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setBusPage(0);
  };

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState();
  const [colorMessage, setcolorMessage] = React.useState();
  const deleteBusinessByBusinessId = async (bussinessid) => {
    let getbusinesid = {
      "bus_id": bussinessid
    }
    let api_path = apiCalling.coderscorner + `server.php/api/deletebusinessinfo`;
    await axios.post(apiCalling.coderscorner + `server.php/api/deletebusinessinfo`, getbusinesid).then((res) => {
      console.log("Deleted business data ", res.data);
      if (res.data.status == true) {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      getAllBusinessAndPromoter(iduser.id);
    }).catch((er) => {

      ErrorCatch(iduser.id, er, api_path, 'post', getbusinesid, iduser.id, iduser.id);
      console.log("Something went Wrong While Deleting...!!!", er);
    });
  }

  const deletePromoterById = async (deleteId,) => {
    let data = {
      promotor_id: deleteId
    }
    let api_path = apiCalling.coderscorner + `server.php/api/deletepromotorinfo`;
    await axios.post(apiCalling.coderscorner + `server.php/api/deletepromotorinfo`, data).then((result) => {
      setcolorMessage("success");
      setShowMessage(result.data.message);
      setsavenextmessage(true);
      getAllPromoterByBusinessId(busIdForDel);
    }).catch((error) => {
      ErrorCatch(iduser.id, error, api_path, 'post', data, iduser.id, iduser.id);
      console.log(error);
    });
  };

  const [allDocument, setAllDocuments] = React.useState([]);
  const getDocuments = async () => {

    await axios.post(apiCalling.coderscorner + `server.php/api/getDocuments`, data).then((result) => {
      if (result.data.status == true);
      setAllDocuments(result.data.data);

    }).catch();

    
  }

  return (
    <>

      {userRole === 1 && (
        <CpaMainPage handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {userRole === 3 && (
        <ScaMainPage handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {userRole === 5 && (<BankerPage handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {

        userRole === 6 && (<DsaMainPage handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {userRole === 7 && (<DocumentsCollector handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {userRole === 8 && (<AnalystMainPage handleNext={props.handleNext} handleNextLetStart={props.handleNextLetStart} />)}
      {userRole === 2 && (
        <Container maxWidth='lg'>
          {loaderFlag == true ? (
            <div style={{ textAlign: 'center', marginTop: '15%' }}>
              <Loader type={LoaderColor.type} color={LoaderColor.color} />
            </div>
          ) :
            (
              <>
                {userBusinessList.length > 0 && (
                  <div className="mini-container mg_btm_set2" style={{ marginTop: "7%" }}>
                    <Row style={{ marginTop: '-2%' }}>
                      <Col md={{ span: 4 }} style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                        <div style={{ border: '1px solid #eee', padding: '10px' }}>{loansApprovedCount}<br></br>
                          <span>Loans Approved</span>
                        </div>
                      </Col>
                      <Col md={{ span: 4 }} style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                        <div style={{ border: '1px solid #eee', padding: '10px' }}>{underProcessCount}<br></br>
                          Under Process
                        </div>
                      </Col>
                      <Col md={{ span: 4 }} style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                        <div style={{ border: '1px solid #eee', padding: '10px' }}>0 Crore(s)<br></br>
                          Total Value of Loans Approved
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1%', marginBottom: '-2%' }}>
                      <Col md={{ span: 12 }}>
                        <Typography styleType='SH' text='Your Business Profile' />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 12 }}>
                        <IconButton
                          ref={anchorRef}
                          aria-controls={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle}
                          style={{ float: 'right', marginTop: '1%', marginBottom: '-3%', zIndex: '10' }}>


                          <img className="actionBtn svgicon" src={addbusiness} />

                        </IconButton>
                        <Popper open={open} placement='left' anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 9999 }}>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'left' ? 'center top' : 'center bottom' }


                              }
                            >
                              <Paper className="dashboarddd">
                                <ClickAwayListener onClickAway={handleClose1}>
                                  <MenuList autoFocusItem={open} id="menu-list-grow1" onKeyDown={handleListKeyDown}>
                                    {BPOptions.map((option) => (
                                      <MenuItem onClick={handleClose1} onClick={(opetion) => handleMenuItemClickBusPro(option)} >{option}</MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                      </Col>
                    </Row>

                    <TabContext value={value}>
                      <AppBar position="static">
                        <TabList onChange={handleChange1} aria-label="simple tabs example">
                          <Tab label="Business Information" value="1" style={{ width: "200px" }} className="tabSize" />
                          <Tab label="Promoter Information" value="2" className="tabSize" />
                        </TabList>
                      </AppBar>
                      <TabPanel value="1">

                        <TableContainer>
                          <Table aria-label="collapsible table" size="small" style={{ margin: "0% 0% 0%" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableSLNoHeading">#</TableCell>
                                <TableCell className="tableBusinessNameHeadingMain">Business Name</TableCell>
                                <TableCell className="tableGSTHeading">GST</TableCell>
                                <TableCell className="tableEntityTypeHeading">Entity Type</TableCell>
                                <TableCell className="tablePANHeading">Business PAN</TableCell>
                                <TableCell className="tablePANHeading">Industry Group</TableCell>
                                <TableCell className="tablePANHeading">City</TableCell>
                                <TableCell className="tablePANHeading">State</TableCell>

                                <TableCell className="tableActionHeading" colSpan={2}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userBusinessList.slice(buspage * rowsPerPage, buspage * rowsPerPage + rowsPerPage).map((item, i) => (
                                <TableRow hover className="datable" key={i}>
                                  <TableCell>{i + 1}</TableCell>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>{item.gst}</TableCell>
                                  <TableCell>{item.entity_type}</TableCell>
                                  <TableCell>{item.bpan}</TableCell>
                                  <TableCell>{item.industry_group_name}</TableCell>
                                  <TableCell>{item.city_name}</TableCell>
                                  <TableCell>{item.state_name}</TableCell>
                                  <TableCell className="tableActionHeading">
                                    <Tooltip title="Edit" placement="bottom">
                                      {/* <Button className="actionBtn" onClick={() => handleEditData(1, item.id)}>
                          <i className="fas fa-user-edit" style={{ fontSize: "15px" }} />
                        </Button> */}
                                      <img className="actionBtn svgicon" src={editrec} onClick={() => handleEditData(item.id, 1, false)} />
                                    </Tooltip>
                                    <Tooltip title="delete" placement="bottom">
                                      <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
                                        deleteBusinessByBusinessId(item.id);
                                      }} />
                                    </Tooltip>
                                    <Tooltip title="Upload Document" placement="bottom">
                                      {/* <Button className="actionBtn" onClick={(e) => {
                          props.handleNext(item.id, 1, 1);
                        }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                      <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => { props.handleNext(item.id, true, 1); }} />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value="2">
                        <Formik
                          enableReinitialize={true}
                          initialValues={initialValue} >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched
                            /* and other goodies */
                          }) => {
                            //console.log("values in formik", values);
                            return (
                              <form onSubmit={handleSubmit} >
                                <Row>
                                  <Col md={{ span: 4 }}>
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <Dropdown
                                          placeholder="Select Business Name"
                                          id="company"
                                          name="company"
                                          fluid
                                          search
                                          selection
                                          value={values.company}
                                          onBlur={(e, { name, value }) => {
                                            setFieldTouched("company", true);
                                          }}
                                          onChange={(e, { name, value }) => {
                                            //   
                                            setFieldValue("company", value);
                                            setbusIdForDel(value);
                                            setBusinessIdForPro(value);
                                          }}
                                          options={userBusinessList}
                                        />
                                      </ListItem>
                                    </List>
                                  </Col>
                                  {/* <Col md={{ span: 5 }}></Col>
                    <Col md={{ span: 3 }}></Col> */}
                                </Row>
                              </form>
                            );
                          }
                          }
                        </Formik>
                        {showPromoterTable == false && (
                          <Alert severity="warning" className="warningMsg1">
                            <AlertTitle><p style={{ fontSize: "16px" }}>Promoters have not been added to this business. Click here to add Promoter details.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </p></AlertTitle>
                            <Button size="small" className="choicebtn" onClick={() => { props.handleNext(undefined, undefined, 2) }}>Add Promoter</Button>&nbsp;&nbsp;
                          </Alert>
                        )}
                        {showPromoterTable == true && (
                          <TableContainer >
                            <Table aria-label="customized table" size="small" style={{ marginBottom: "0% 0% 0%" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={table_slNo}>#</TableCell>
                                  <TableCell style={table_header}>Promoter Name</TableCell>
                                  <TableCell style={table_header}>Email</TableCell>
                                  <TableCell style={table_header}>Mobile</TableCell>
                                  <TableCell style={table_header}>Type</TableCell>
                                  <TableCell style={table_header}>Share %</TableCell>
                                  <TableCell style={table_header}>Status</TableCell>
                                  <TableCell style={table_header}>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {promoterList.map((item, i) => (
                                  <TableRow hover className="datable" key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.mobile}</TableCell>
                                    <TableCell>{item.type}</TableCell>
                                    <TableCell>{item.share}</TableCell>
                                    <TableCell> <i class="far fa-times-circle" style={{ color: "red" }}></i> </TableCell>
                                    <TableCell style={{ display: "flex", height: "100%" }}>
                                      <Tooltip title="Edit" placement="bottom">
                                        {/* <Button className="actionBtn" onClick={(e) => {
                            getPromoterById1(item.promoter_id);
                          }}>
                            <i className="fas fa-user-edit"
                              style={{ fontSize: "15px" }} />
                          </Button> */}
                                        <img className="actionBtn svgicon" src={editrec} onClick={(e) => {
                                          getPromoterById1(item.promoter_id);
                                        }} />
                                      </Tooltip>
                                      <Tooltip title="delete" placement="bottom">
                                        {/* <Button className="actionBtn" onClick={(e) => {
                      deletePromoterById(item.promoter_id);
                    }}> <i class="fas fa-user-minus" style={{ fontSize: "13px" }}></i></Button> */}
                                        <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
                                          deletePromoterById(item.promoter_id);
                                        }} />
                                      </Tooltip>
                                      <Tooltip title="Upload Document" placement="bottom">
                                        {/* <Button className="actionBtn" onClick={(e) => {
                        props.handleNext(item.promoter_id, item.eligibility_flag, 2);
                      }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                        <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => {
                                          props.handleNext(item.promoter_id, item.eligibility_flag, 2);
                                        }} />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>)}
                      </TabPanel>
                    </TabContext>

                    {(applicationList.length > 0) && (
                      <>
                        <Row style={{ marginTop: '1%', marginBottom: '-2%' }}>
                          <Col md={{ span: 12 }}>
                            <Typography styleType='SH' text='Your Applications' />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={{ span: 12 }}>

                            <IconButton
                              ref={anchorRef1}
                              aria-controls={openLoan ? 'menu-list-grow1' : undefined}
                              aria-haspopup="true"
                              onClick={handleToggle1}
                              style={{ float: 'right', marginTop: '1%', marginBottom: '-3%', zIndex: '10' }}>
                              {/* <AddCircleOutlineIcon /> */}
                              <img className="actionBtn svgicon" src={addbusiness} />
                            </IconButton>
                            <Popper open={openLoan} placement='left' anchorEl={anchorRef1.current} role={undefined} transition disablePortal style={{ zIndex: 9999 }}>
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{ transformOrigin: placement === 'left' ? 'center top' : 'center bottom' }}>
                                  <Paper className="dashboarddd">
                                    <ClickAwayListener onClickAway={handleClose2}>
                                      <MenuList autoFocusItem={openLoan} id="menu-list-grow1" onKeyDown={handleListKeyDown1} style={{ padding: '0% 2%', width: '18ch' }}>
                                        {ApplicationOptions.map((option, index) => (
                                          <MenuItem onClick={handleClose2} key={option} onClick={() => handleMenuItemClick(index)}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </Col>
                        </Row>
                        <TabContext value={value2}>
                          <AppBar position="static">
                            <TabList onChange={handleChange2} aria-label="simple tabs example">
                              <Tab label="Active Applications" value="1" className="tabSize" />
                              <Tab label="History Applications" value="2" className="tabSize" />
                            </TabList>
                          </AppBar>
                          <TabPanel value="1">
                            <SearchBar
                              className="search_bar"
                              value={searched}
                              onChange={(searchVal) => requestSearch(searchVal)}
                              onCancelSearch={() => cancelSearch()}
                            />
                            <TableContainer >
                              <Table aria-label="customized table" size="small" style={{ marginTop: "1%", marginBottom: "1% 0% 3%" }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="5%" style={table_slNo}>#</TableCell>
                                    <TableCell style={table_header}>Reference Number</TableCell>
                                    <TableCell style={table_header}>Business Name</TableCell>
                                    <TableCell style={table_header}>Business Pan</TableCell>
                                    <TableCell style={table_header}>Application Type</TableCell>
                                    <TableCell style={table_header}>Loan Product</TableCell>
                                    <TableCell style={table_header}>Date Logged</TableCell>
                                    <TableCell style={table_header}>Financer</TableCell>
                                    <TableCell style={table_header}>Loan Amount</TableCell>
                                    <TableCell style={table_header}>Status</TableCell>
                                    <TableCell style={table_header}>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {applicationList.map((item, i) => (
                                    <TableRow hover className="datable" key={i + 1}>
                                      <TableCell>{i + 1}</TableCell>
                                      <TableCell>{item.reference_id}</TableCell>
                                      <TableCell>{item.business_name}</TableCell>
                                      <TableCell>{item.pan}</TableCell>
                                      {item.request_type_id == 0 && (
                                        <TableCell>Loan Application</TableCell>
                                      )}
                                      {item.request_type_id == 1 && (
                                        <TableCell>Check Eligibilty</TableCell>
                                      )}
                                      <TableCell>{item.loan_type}</TableCell>
                                      <TableCell>{moment(item.created_on).format('DD-MM-YYYY')}</TableCell>
                                      <TableCell>{item.financier_name}</TableCell>
                                      <TableCell>{item.loan_amount}</TableCell>
                                      <TableCell>{item.status}</TableCell>
                                      <TableCell   >
                                        {<Tooltip title="Application details" placement="bottom">
                                          {/* <Button className="actionBtn" onClick={(e) => {
                                goToLetsGetStarted(item);
                              }}>
                                <i class="fas fa-long-arrow-alt-right" style={{ fontSize: "15px" }}></i>
                              </Button> */}
                                          <img style={{"pointerEvents": "all"}} className="actionBtn svgicon" src={skip} onClick={(e) => {
                                            
                                            
                                            goToLetsGetStarted(item);
                                          }} />
                                        </Tooltip>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 15]}
                              component="div"
                              count={applicationList.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TabPanel>

                          <TabPanel value="2">

                            <TableContainer >
                              <Table aria-label="customized table" size="small" style={{ marginTop: "1%", marginBottom: "1% 0% 3%" }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="5%" style={table_slNo}>#</TableCell>
                                    <TableCell style={table_header}>Reference Number</TableCell>
                                    <TableCell style={table_header}>Business Name</TableCell>
                                    <TableCell style={table_header}>Business Pan</TableCell>
                                    <TableCell style={table_header}>Application Type</TableCell>
                                    <TableCell style={table_header}>Loan Product</TableCell>
                                    <TableCell style={table_header}>Date Logged</TableCell>
                                    <TableCell style={table_header}>Financer</TableCell>
                                    <TableCell style={table_header}>Loan Amount</TableCell>
                                    <TableCell style={table_header}>Status</TableCell>
                                    <TableCell style={table_header}>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {applicationList.map((item, i) => (
                                    <TableRow hover className="datable" key={i + 1}>
                                      <TableCell>{i + 1}</TableCell>
                                      <TableCell>{item.reference_id}</TableCell>
                                      <TableCell>{item.business_name}</TableCell>
                                      <TableCell>{item.pan}</TableCell>
                                      {item.request_type_id == 0 && (
                                        <TableCell>Loan Application</TableCell>
                                      )}
                                      {item.request_type_id == 1 && (
                                        <TableCell>Loan Application</TableCell>
                                      )}
                                      <TableCell>{item.loan_type}</TableCell>
                                      <TableCell>{moment(item.created_on).format('DD-MM-YYYY')}</TableCell>
                                      <TableCell>{item.financier_name}</TableCell>
                                      <TableCell>{item.loan_amount}</TableCell>
                                      <TableCell>{item.status}</TableCell>

                                      <TableCell style={{ display: "flex", width: "100%" }}>
                                        {
                                          <Tooltip title="Application details" placement="bottom">
                                            {/* <Button className="actionBtn" onClick={(e) => {
                                goToLetsGetStarted(item.request_type_id);
                              }}>
                                <i class="fas fa-long-arrow-alt-right" style={{ fontSize: "15px" }}></i>
                              </Button> */}
                                            <img className="actionBtn svgicon" src={skip} onClick={(e) => {
                                              goToLetsGetStarted(item.request_type_id);
                                            }} />
                                          </Tooltip>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 15]}
                              component="div"
                              count={applicationList.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TabPanel>
                        </TabContext>
                      </>
                    )}
                    {(openDialog == false && applicationList.length == 0) && (
                      <Alert severity="warning" className="warningMsg">
                        {/* <AlertTitle><p style={{ fontSize: "16px" }}>You have not created any application. Let us help you create one, choose from  one of the following options to start the application wizard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></AlertTitle> */}
                        <Button size="small" className="choicebtn" onClick={checkeligibilty}>Check Eligibility</Button>&nbsp;&nbsp;
                        <Button size="small" className="choicebtn" onClick={applyforloan}>Loan Application</Button>
                      </Alert>
                    )}

                    <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
                      onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                        vertical: SnackPosition.vertical,
                        horizontal: SnackPosition.horizontal
                      }}>
                      <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                        {showMessage}
                      </Alert>
                    </Snackbar>
                  </div>
                )}

              </>
            )
          }


          <>
            {showform === true && (
              <Formik
                initialValues={{
                  bankname: "",
                  account: "",
                  natureacc: "",
                  submission: "",
                  color: "",
                  companyl: "",
                }}

                validationSchema={bankFormschema}
                onSubmit={(values) => {
                  console.log("kkkkk", values);
                  localStorage.setItem("banking", JSON.stringify(values));
                  handleNextClick();
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={{ span: 8 }} style={{ display: "block", margin: "auto", marginTop: "15%" }}>
                          <div className='border_set_pro'>
                            <div>
                              <h2>Welcome to Aagey.com !</h2>
                            </div>
                            <div style={{ marginTop: 20 }}>
                              <h2>Now get loans</h2>
                            </div>
                            <div style={{ marginTop: 20 }}>
                              <h2>
                                Let us get you started with your application
                              </h2>
                            </div>
                            <div style={{ margin: 40 }}>
                              <Button className="choicebtn" onClick={checkeligibiltyBusAndProm}>Check Eligibility</Button>&nbsp;&nbsp;
                              <Button className="choicebtn" onClick={applyforloanBusAndPromo}>Loan Application</Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            )}
          </>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className='border_set_pro' style={{ padding: "unset !important" }}>
            {  // <DialogTitle id="alert-dialog-slide-title">{"Use Google's location service?"}</DialogTitle>
              // <DialogContent>
              //   <DialogContentText id="alert-dialog-slide-description">
              //     Let Google help apps determine location. This means sending anonymous location data to
              //     Google, even when no apps are running.
              //   </DialogContentText>
              // </DialogContent>
              // <DialogActions>
              //   <Button onClick={handleCloseDialog} color="primary">
              //     Disagree
              //   </Button>
              //   <Button onClick={handleCloseDialog} color="primary">
              //     Agree
              //   </Button>
              // </DialogActions>
            }
            <MuiDialogTitle style={{ padding: "unset !important" }}>
              <DialogTitle style={{ padding: "unset !important", float: "right" }}>
                {handleCloseDialog ? (
                  <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenDialog(false)}>
                    <CloseIcon />
                  </IconButton>) : null}
              </DialogTitle>
            </MuiDialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className="modaltext">
                <p style={{ fontSize: "22px", color: "#000" }}>You do not have any Application </p>
                <p style={{ fontSize: "22px", color: "#000" }}>Choose any one to start the application wizard</p>
              </DialogContentText>
              <div style={{ margin: "2% auto" }}>
                <Button className="choicebtn" onClick={checkeligibilty}>Check Eligibility</Button>&nbsp;&nbsp;
                <Button className="choicebtn" onClick={applyforloan}>Loan Application</Button>
              </div>
            </DialogContent>
          </Dialog>
          {    // <div className='float-right open_right_view'>
            //   {["right"].map((anchor) => (
            //     <React.Fragment key={anchor}>
            //       <Button
            //         onClick={toggleDrawer(anchor, true)}
            //         className='open_right_view_btn'>
            //         <ChevronLeft />
            //       </Button>
            //       <Drawer
            //         anchor={anchor}
            //         open={state[anchor]}
            //         onClose={toggleDrawer(anchor, false)}>
            //         {list(anchor)}
            //       </Drawer>
            //     </React.Fragment>
            //   ))}
            // </div>
          }
        </Container>
      )}
    </>
  );
}



