import React from 'react';
import clsx from 'clsx';
import {Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EntityType from '../EntityType';
import UserCreation from '../UserCreation';
import "../admin.css";

import { apiCalling } from '../adminConstants';

import AdminMainListItem from '../AdminMainListItem';
import logo from '../../image/logo.png';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright Â© '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        border: '1px solid black',
        margin: '10px',
        height: '97vh',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 5px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
            
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        // height: '80px !important',
        transition: theme.transitions.create(['width','height','margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '95vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        // padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: '100%',
    },
    
}));


const getBackToProfile = () => {
    
    localStorage.clear();
    
    // window.location = apiCalling.frontCaller+ '/#/ProfileSteper';
    window.location = apiCalling.frontCaller + '/#/internalLogin';
    //   window.location = apiCalling.frontCaller + '/#/ProfileSteper';
      // window.location = 'https://platform.aagey.com/app/#/ProfileSteper';
      //window.location=apiCalling.frontCaller+ '/#/internalLogin'
  }


export default function AdminDashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [dataa, setdata] = React.useState(<UserCreation />);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const checkdatas = (sees) => {
        
        setdata(sees)
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} style={{top: "1.6vh", right: "12px"}} >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Dashboard
                    </Typography>
                   
                    <IconButton color="inherit">
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Welcome Admin
                    </Typography>
                        {/* <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge> */}
                        
                    </IconButton>
                      <IconButton color="inherit">
                      <Typography color="inherit"  component="h1" variant="h6"
                            onClick={()=>getBackToProfile()}
                        >
                           Logout
                         </Typography>
                      </IconButton>
                </Toolbar>
            </AppBar>
           
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >



                <div className={classes.toolbarIcon} >
                    <img src={logo} width='200px' height="auto" style={{ padding: '10px', marginRight: '25px' }} />
                    <IconButton onClick={handleDrawerClose} style={{ position: "absolute", right: '3px' }}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <tr/>
                    <tr/>
                </div>

                <Divider  />
                <List><AdminMainListItem checkdata={checkdatas} /></List>
              
                {/* <List>{secondaryListItems}</List> */}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} >

                            {dataa}

                        </Grid>
                    </Grid>
                   
                </Container>
            </main>
        </div>
    );
}

