import React, { useRef } from 'react';
import Container from '@material-ui/core/Container';
import { useStyled } from "./styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import axios from "axios";
import { apiCalling } from '../../shared/constants';
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import grayreset from '../../image/buttons/grayreset.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from '@material-ui/core/Paper';
import Typography from "../../base_components/Typography";
import Reset from '../../image/buttons/reset.png';
import AberratonQuastionarrie from '../AberratonQuastionarrie';
import SearchBar from "material-ui-search-bar";

import FileCheck from '../../image/icons/file.png';
import { Checkbox } from '@material-ui/core';

import edit from '../../image/pngicons/edit.png';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Modal from "react-bootstrap/Modal";
import ReactQuill from 'react-quill';

import { TextField, Button } from "@material-ui/core";

export default function FatCheck(props) {
  const classes = useStyled();

  const [cpa, setCpa] = React.useState("");

  React.useEffect(() => {
    getFatCheckData();
    checkIfRequest();
  }, []);

  const [question, setQuestion] = React.useState(null);
  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }
  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));

  const handledata = () => {
    props.handleBack();
  };
  const SaveasDraft = () => {
    props.handleNext();
  }

  const [fatData, setFatData] = React.useState([]);

  const [parametr_id_array, setParameter_id_array] = React.useState([]);
  const getFatCheckData = async () => {
    
    let appReq = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let data = {
      folder_no: appReq.folder_no,
      parameter_category: "Financial Assessment"
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getFinCheckFatCheckDataHeaderWise', data).then(result => {
      if (result.data.status) {
        console.log('fat check data.....!!!!', result.data.data.headerDetails);
        let fatDataArray = [];
        result.data.data.headerDetails.map((item) => {
          fatDataArray.push({
            headerName: item.headerName,
            headerDetails: item.headerDetails
          });
        });
        // console.log(result.data.data):
        setFatData(fatDataArray);
        setOriginalDataList(fatDataArray);

        // temprary array created for resubmssion

        let redStatusArray = [];
        if (fatDataArray.length > 0) {
          fatDataArray.map((item1) => {
            if (item1.headerDetails.length > 0) {
              item1.headerDetails.map(item2 => {
                if (item2.status == "RED") {
                  redStatusArray.push(item2.parameter_id);
                }
              });
            }
          })
        }

        localStorage.setItem('fatcheckArray', JSON.stringify(redStatusArray));

      }
    }).catch(error => {
      console.log(error);
    })
  }

  const table_header = {
    fontWeight: "600",
    fontSize: "100%"
  };

  const table_center_header = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  };

  const table_center_body = {
    textAlign: "center"
  };

  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
    width: "1%"
  }

  const [showquestionaries, setShowquestionaries] = React.useState(false);
  const [perantId, setparentId] = React.useState();

  const [parameter_id, setParameter_id] = React.useState();
  const hideQuestionScreen = () => {
    setShowquestionaries(false);
  }


  // search bar 
  const [searched, setSearched] = React.useState("");

  const [originalDataList, setOriginalDataList] = React.useState([]);

  /// search bar code
  const requestSearch = (searchedVal) => {
    
    // let busArr = businessList
    let tempArr = [];
    if (searchedVal.length != 0 && searchedVal != "") {
      originalDataList.map(item => {
        if (item.headerName.toLowerCase().includes(searchedVal.toLowerCase())) {
          tempArr.push(item);
        }
        item.headerDetails.map(item2 => {
          if (item2.parameter_name.toLowerCase().includes(searchedVal.toLowerCase())) {
            let flag = tempArr.map(item3 => {
              if (item3.headerName == item.headerName) { return true; }
            });
            if (flag == false || flag == undefined) {
              tempArr.push(item);
            }
          }
        })
      });
      let finCheckArray = [];
      tempArr.map(data => {
        finCheckArray.push({
          headerName: data.headerName,
          headerDetails: data.headerDetails
        })
      });
      setFatData(finCheckArray);
    } else {
      setFatData(originalDataList);
    }
  };


  const cancelSearch = () => {
    
    setSearched("");
    //setBusinessList(newBus);
    setFatData(originalDataList);
  }

  const [finacialYear, setFinancialyear] = React.useState("");

  //09/03
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const [openStatus, setOpenStatus] = React.useState(false);
  const handleClose = () => {
    setOpenStatus(false);
  };

  const editor = useRef(null);

  let modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  let formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


  const [comments, setComments] = React.useState("");
  const [parameter, setParameter] = React.useState(Number);
  const [year, setYear] = React.useState("");

  const saveComments = async () => {
    

    let data = {
      "parameter_id": parameter,
      "year":year,
      "folder_no": localData.folder_no,
      "cpa_comments": comments
    }
    await axios.post(apiCalling.coderscorner + `server.php/api/updateSaveComments`, data).then(result => {
      if(result.data.status){
      console.log(result);
      setParameter(Number);
      setComments("");
      handleClose();
      }
    }).catch(error => {

    });
  }

  const getById = async (parameter_id,para_year) => {
    

    let data = {

      "folder_no": localData.folder_no,
      "parameter_id": parameter_id,
      "year":para_year

    }
    await axios.post(apiCalling.coderscorner + `server.php/api/getByParameterIdComments`, data).then(result => {
      console.log(result);
      if (result.data) {
        if (result.data.data.length > 0) {
          setComments(result.data.data[0].cpa_comments);
        }

      }

    }).catch(error => {

    });
  }




  return (
    <>
      {showquestionaries == true && (
        <AberratonQuastionarrie ParameterId={parameter_id} FinancialYear={finacialYear} ScreenFlag={hideQuestionScreen} Screen={"fat"} />
      )}

      {showquestionaries == false && (
        <Container maxWidth="lg">
          <div className="mini-container mg_btm_set">
            <Row>
              <Col md={{ span: 12 }}>
                <div className="content-header">
                  <Row>
                    {/* { requestDetails && (
                    <Col md={{ span: 12 }} style ={{marginBottom:"3%",textAlign:"center"}}>
                      <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                    </Col>
                  )} */}
                  </Row>
                  <Row>
                    <Col md={{ span: 12 }} className="mainheader1">
                      <Typography text="Application Financial Health Check - Part A" styleType="C" />
                    </Col>
                    <Col md={{ span: 12 }} className="subHeader1">
                      <table className="applicationTableDetails1">
                        <tr>
                          {/* <td>Type</td>
                              <td>:</td> */}
                          {localData.request_type_id === 0 && (
                            <td>Loan Application</td>)}
                          {localData.request_type_id === 1 && (
                            <td>Check Eligibility</td>)}
                          <td>&nbsp;|&nbsp;</td>
                          <td>Application Number</td>
                          <td>:</td>
                          <td>{localData.reference_id}</td>
                          <td>&nbsp;|&nbsp;</td>
                          <td>Loan Type</td>
                          <td>:</td>
                          <td>{localData.loan_type}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div>
              <SearchBar
                className="search_bar"
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer component={Paper} className="tableFixHeader">
                <Table aria-label="collapsible table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="sticky_header" style={table_slNo}>#</TableCell>

                      <TableCell className="sticky_header" style={table_header}>Financial Scoring Prameters</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Computed Value</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Year</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Health Indicator&nbsp;<i className="fas fa-exclamation-circle" style={{ fontSize: '15px', color: 'blue' }} ></i></TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Questionnaire&nbsp;<i className="fas fa-external-link-alt" style={{ fontSize: '13px', color: '#a3a3c4' }}
                        onClick={() => {
                          setShowquestionaries(true); setparentId(fatData);
                        }}></i></TableCell>

                      <TableCell className="sticky_header" style={table_header}>Assessed By</TableCell>
                      <TableCell className="sticky_header" style={table_header}>Assessor Remarks</TableCell>
                      {/* <TableCell className="sticky_header" style={table_header}>Check Box</TableCell> */}
                    </TableRow>
                  </TableHead>
                  {fatData.map((item, i) => (
                    <>
                      <TableHead>
                        <TableRow hover className="datable">
                          <TableCell style={table_header} colSpan="7">{item.headerName}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          item.headerDetails.map((item1, i) => (
                            <>
                              <TableRow hover className="datable" >
                                <TableCell>{i + 1}</TableCell>

                                <TableCell>{item1.parameter_name}</TableCell>

                                <TableCell style={table_center_body}>{item1.parameter_value}</TableCell>

                                <TableCell style={table_center_body}>{item1.year}</TableCell>


                                <TableCell style={table_center_body}><i className="fas fa-circle" style={item1.parameter_evaluation_status == "no" ? { fontSize: '15px', color: 'red' } : { fontSize: '15px', color: 'green' }}></i></TableCell>

                                {item1.parameter_evaluation_status == "no" ? (<TableCell style={table_center_body}><i onClick={() => {
                                  
                                  setFinancialyear(item1.year);
                                  setShowquestionaries(true); setParameter_id(item1.parameter_id)
                                }} className="fas fa-times-circle" style={{ fontSize: '15px', color: 'red' }} ></i>
                                </TableCell>
                                ) : (
                                  <TableCell style={table_center_body}>
                                  </TableCell>)}

                                <TableCell>
                                  Cpa
                                </TableCell>
                                <TableCell>
                                  <img alt="img" className="actionBtn svgicon" src={edit} onClick={() => {
                                    console.log("\n \n question", item1.question)
                                    
                                    setYear(item1.year);
                                    getById(item1.parameter_id,item1.year);
                                    setParameter(item1.parameter_id)
                                    setQuestion(item1.question)
                                    setOpenStatus(true);
                                  }} />&nbsp;
                                </TableCell>
                                {/* <TableCell>
                                  {item1.parameter_evaluation_status == "no" ? (
                                    <Checkbox defaultChecked />
                                  ) : (null)}

                                </TableCell> */}
                              </TableRow>
                            </>
                          )
                          )
                        }

                      </TableBody>
                    </>
                  ))}
                </Table>
              </TableContainer>
              <div className='btn_row_fixd'>
                <Row>
                  <Col md={{ span: 10 }}></Col>
                  <Col style={{ padding: "0%" }}></Col>
                  {/* className="btnspace" */}
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img className='navbutton' src={Back} onClick={handledata} />
                    </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' alt="photo1" src={Back} onClick={handledata} />
                      <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}>
                        <img className='navgraybutton' alt="photo1" src={grayback} onClick={handledata} />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Reset" arrow>
                      <img className='navbutton' src={Reset} />
                    </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' alt="photo1" src={Reset} />
                      <Tooltip title="Reset">
                        <img className='navgraybutton' alt="photo1" src={grayreset} />
                      </Tooltip>
                    </span>
                  </Col>
                  {/* <Col style={{ padding: "0%" }}>
                      <Tooltip title="Save & Next" arrow>
                        <img className='navbutton' src={SaveNext} />
                      </Tooltip>
                    </Col> */}
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Save as Draft & Next" arrow>
                      <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                    </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' alt="photo1" src={DraftNext} onClick={SaveasDraft} />
                      <Tooltip title="Save & Next" >
                        <img alt="photo1" className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openStatus}>
              <Modal.Header className="modalHead" closeButton onClick={handleClose} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                <Modal.Title className="modalHeader cpModal">Analyst Comments</Modal.Title>
              </Modal.Header>
              <DialogContent className="custom_modal" dividers style={{ height: "120px" }}>
                <div className="bank_modal_type">
                  
                  <Col md={{ span: 12 }}>
                    <Typography text="Question" styleType="B" />
                    <List component="div" disablePadding>
                        <ListItem>
                            <TextField
                                id="filled-error-helper-text standard-basic"
                                name="question"
                                label="Question"
                                value={question}
                            />
                        </ListItem>
                    </List>
                  </Col>
                  <Typography text="Comments" className="bank_modal_type" styleType="B" />
                  <ReactQuill
                    onRef={editor}
                    ////value={bindingArray.arrayBindData['level3' + level.id] || ''}
                    onChange={(html) => {

                      setComments(html);
                    }
                    }
                    value={comments}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    style={{ width: "100%" }}
                  // onFocus={() => {
                  //    generateToolArray(level.id);
                  //    // showToolBar1();
                  //    console.log("hello");
                  // }}
                  />
                </div>


              </DialogContent>
              <DialogActions>
                <Button color="primary" type="submit" onClick={saveComments}>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Container>)}
    </>
  );
}
