import React, { useState } from "react";
import { loadCSS } from "fg-loadcss";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import {
  TextField,
  Button,
} from "@material-ui/core";

import OtpTimer from "otp-timer";
import axios from "axios";
import facebook from "../../image/buttons/facebook.png";
import google from "../../image/buttons/google.png";
import linkedin from "../../image/buttons/linkedIn.png";

import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import Cookies from 'js-cookie';

export default function CrifLogin(props) {
    // const classes = useStyles();
    const [pan, setPan] = React.useState();
    const [email, setEmail] = React.useState();
    const [mobile, setMobile] = React.useState();
    const [showOtp, setShowOtp] = React.useState(false);
    const [signInBtn, setSignInBtn] = React.useState(true);
    const [otpMinutes, setOtpMinutes] = useState();
    const [otpSeconds, setOtpSeconds] = useState();
    const [emailDisable, setEmailDisabled] = useState(true);
    const [showTimer, setShowTimer] = useState(true);



  React.useEffect(() => {
    localStorage.clear();
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    getOtpTimer();


  }, []);


  const [panErrorMessage, setPanErrorMessage] = useState("");
  const setPanValue = (e) => {
    //console.log( e.target.value);
    let panLen = e.target.value;
    var regpan = /[a-zA-Z]{3}[a-zA-Z]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
    if (panLen.length === 10) {
      if (regpan.test(panLen)) {
        setEmailDisabled(false);
        setPanErrorMessage("");
      } else {
        setEmailDisabled(true);
        setPanErrorMessage("Please enter valid pan");
      }
    } else {
      setEmailDisabled(true);
      setPanErrorMessage("Please enter valid pan");
    }
    setPan(e.target.value);
  };

  //Verifying user PAN And email
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  //const [emailOrMobile,setEmailOrMobile] = useState();
  const [userId, setUserId] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const verifyEmail = (email) => {
    let rbody = {};
    // setMobile(email)
    if (email != null && email !== undefined) {
      if (email.includes('@')) {
        rbody = {
          pan: pan,
          email: email,
          mobile: ""
        }
      } else {
        rbody = {
          pan: pan,
          email: "",
          mobile: email
        }
      }

      let user = {};
      axios.post(apiCalling.identityManagement + 'server.php/api/checkuser', rbody).then((result) => {
        if (result.data !== undefined || result.data !== null) {
          user = result.data;
          if (user.status === true) {
            setOtpPage(true);
            setSignInBtn(true);
            verifyUser(email);
            setShowOtp(!showOtp);
            setShowInvalidMessage(false)
          } else if (user.status === false) {
            setShowInvalidMessage(true)
            setErrorMessage(user.message);
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowInvalidMessage(true);
      setErrorMessage("Please enter all the feilds");
    }
  }

  /// get otp   and verify
  const verifyUser = (data) => {
    
    let rbody = {};
    if (data.includes('@')) {
      rbody = {
        email: data,
        pan: pan,
      };
    } else {
      rbody = {
        mobile: data,
        pan: pan,
      }
    }
    axios
      .post(apiCalling.identityManagement + `server.php/api/UserSignin`,
        rbody
      )
      .then((res) => {
        if (res.data.status === true) {
          console.log(res.data);
          setUserId(res.data.Data.user_details.id)
          //getUserDataFromSignUp(res.data.Data.user_details.id);
          geClientRegData(res.data.Data.user_details.id)
          let data1;
          if (data.includes('@')) {
            data1 = res.data.Data.user_details;
          } else {
            data1 = res.data.Data.user_details;
          }
          localStorage.setItem('userdata', JSON.stringify(data1));
          Cookies.set('userdata_cookie', JSON.stringify(data1));


        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // verify email otp
  const [otpErrorMessage, setOtpErrorMessage] = useState(false);
  const [otpMessage, setOtpMessage] = useState();
  const verifyEmailOtp = (e) => {
    let eotp = e.target.value;
    let len = eotp.length;
    if (len == 6) {
      let data = {
        value: email,
        form_id: 2,
        otp: eotp,
      };
      localStorage.setItem("otps", JSON.stringify(data));
      Cookies.set('otps_cookie', JSON.stringify(data));


      const checkotp = localStorage.getItem("otps");
      //const checkotp=Cookies.get("otps");
      let getotpcookie = Cookies.get('otps_cookie');
      console.log(getotpcookie);
      let otps = JSON.parse(getotpcookie);
      axios
        .post(apiCalling.identityManagement + `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          if (result.data != undefined || result.data != null) {
            //  console.log(result.data);
            if (result.data.status === true) {
              setSignInBtn(false);
              setShowTimer(false);
              setOtpErrorMessage(false);
            } else if (result.data.status === false) {
              setOtpErrorMessage(true);
              setOtpMessage(result.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //resend otp for email
  const resendOtpEmail = () => {
    let data = {};
    let val = email;
    if (val.includes('@')) {
      data = {
        type: "email",
        value: val,
        form_id: 2
      };
    } else {
      data = {
        type: "mobile",
        value: val,
        form_id: 2
      }
    }
    axios
      .post(apiCalling.identityManagement + `server.php/api/resendOtp`,
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // user sigin
  var llDate = new Date();
  var lalogindate = llDate.toString();
  const signIn = async (e) => {
    e.preventDefault();
    let data = {
      // mobile:mobile,
    };
    if (email !== "" || email !== undefined) {
      data = {
        email: email,
        pan: pan,
        lastLoginDate: lalogindate
      }
    } else {
      data = {
        email: "",
        mobile: mobile,
        pan: pan,
        lastLoginDate: lalogindate
      }
    }
    localStorage.setItem("logindata", JSON.stringify(data));
    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);
    console.log(iduser);
    const checkotp = localStorage.getItem("otps");
    let otps = JSON.parse(checkotp);
    console.log(otps.otp);
    //console.log(iduser.id);
    let user_data_id = iduser.id;
    let otp_data = otps.otp;
    let user_post_data = {
      user_id: user_data_id,
      otp: otp_data
    }
    console.log("save-login" + user_post_data);
    if (checkuserloggeddata.role_id == 1 || checkuserloggeddata.role_id == 3 || checkuserloggeddata.role_id == 8) {
      props.history.push({ pathname: "/ProfileSteper" })
    } else {
      if (checkUserExist == "busexist") {
        if (iduser.role_id == 4) {
          props.history.push({ pathname: "/AdminDashboard" })
        } else {
          props.history.push({ pathname: "/ProfileSteper" })
        }
        //session history
        await axios.post(apiCalling.identityManagement + 'server.php/api/saveLoginTime', user_post_data).then((result) => {
          console.log(result);
          if (result.data.status === true) { }
        }).catch(error => {
          ErrorCatch(error, 'post', user_post_data);
          console.log(error)
        })

      } else if (checkUserExist == "busnotexist") {
        props.history.push({ pathname: "/registration" });
      }
    }

  };

  //
  const [checkUserExist, setCheckUserExist] = useState("");
  const geClientRegData = async (id) => {
    let data = {
      user_id: id
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/getCustomerbyuserid';
    await axios.post(apiCalling.coderscorner + 'server.php/api/getCustomerbyuserid', data).then((result) => {
      //  console.log(result);
      if (result.data.status === true) {
        let sData = result.data.data[0];
        localStorage.removeItem("SignUpIdAndData");
        Cookies.remove('SignUpIdAndData');

        localStorage.setItem("SignUpIdAndData", JSON.stringify(sData));
        Cookies.set('SignUpIdAndData_cookie', JSON.stringify(sData));

        setCheckUserExist("busexist")

      } else {
        getUserDataFromSignUp(id);
      }
      //   return result.data.status;
    }).catch(error => {
      ErrorCatch(id, error, api_path, 'post', data, id, id);
      console.log(error)
    })
  }


  //// IDENTITY MANAGEMENT
  const getUserDataFromSignUp = async (ID) => {
    
    let data1 = { id: ID }
    let api_path = apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid';
    await axios.post(apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid', data1).then(result => {
      //    console.log(result)
      if (result.data.status === true) {
        setCheckUserExist("busnotexist")
        localStorage.setItem("SignUpIdAndData", JSON.stringify(result.data.data));
        Cookies.set('SignUpIdAndData_cookie', JSON.stringify(result.data.data));

        //  console.log(result.data)
      }
    }).catch(error => {
      ErrorCatch(ID, error, api_path, 'post', data1, ID, ID);
      console.log(error)
    })
  }
  ///Otp time as per general setting
  const getOtpTimer = () => {
    let data = {
      parameter_name: "OTP TIMER",
    };
    axios
      .post(apiCalling.coderscorner +
        `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then((result) => {
        if (result.data.data != undefined || result.data.data != null) {
          //  console.log(result.data.data[0]);
          let data = result.data.data[0].parameter_value;
          //  console.log("timer", data);
          convertTimerToInt(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //conversion for Otp timer into
  const convertTimerToInt = (timer) => {
    let newTimer = parseInt(timer);
    var minutes = Math.floor(newTimer / 60);
    var seconds = newTimer - (60 * minutes);
    setOtpMinutes(minutes);
    setOtpSeconds(seconds);
  };

  const [otppage, setOtpPage] = useState(false);
  const otpPageEnable = () => {
    verifyEmail(email);
  }
  const enableSignInpage = () => {
    setOtpPage(false);
  }


  return (
    <div className="login-form">
      <Card className="logincard">
        <CardContent>
          <form onSubmit={signIn}>
            {otppage == false && (
              <Row>
                <Col md={{ span: 12 }}>
                  <p className="login_header">Client Login</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <p className="login_subheader">Manage your loan application and more!</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <label className="social_header">Sign in with</label>
                  <img src={facebook} className="social_img" />
                  <img src={google} className="social_img" />
                  <img src={linkedin} className="social_img" />
                </Col>
                <Col md={{ span: 12 }} style={{ display: "flex", padding: "2% 3%" }}>
                  <label className="line"></label>
                  <small className="orr"><label className="orlabel">Or</label></small>
                  <label className="line"></label>
                </Col>
                <Col md={{ span: 12 }} className="textfeild_txtbox">
                  <label className="login_txtlabel">PAN Number</label>
                  <TextField
                    name="pan"
                    label="AAAAA9999A"
                    onChange={setPanValue}
                    value={pan}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: <label style={{ display: "inline-flex", width: "75%", margin: "auto" }}><Link to={{
                        pathname: "/ForgetPass",
                        state: "unlock"
                      }} className="forgotid">Unlock ID?&nbsp;&nbsp;</Link><span>|</span><Link to={{
                        pathname: "/ForgetPass",
                        state: "forgot"
                      }} className="forgotid">&nbsp;&nbsp;Forgot ID?</Link></label>
                    }}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>{panErrorMessage}</span>
                </Col>
                <Col md={{ span: 12 }} className="textfeild_txtbox">
                  <label className="login_txtlabel">Email / Mobile</label>
                  <TextField
                    name="email"
                    label="xyz@mno.com/900000000"
                    // onBlur={emailBlur}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={emailDisable}
                  />
                  {showInvalidMessage === true && (<span className="valMsg">{errorMessage}</span>)}
                </Col>
                {/* {showOtp == true && (
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 8 }} style={{ marginTop: '4%' }}>
                            <InputOtp onChange={verifyEmailOtp} />
                            {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                          </Col>
                          {showTimer === true && (
                            <Col md={{ span: 2 }} style={{ marginTop: "7%" }}>
                              <OtpTimer
                                seconds={otpSeconds}
                                minutes={otpMinutes} //otpMinutes
                                style={{
                                  color: "blue",
                                  display: "inline-block", float: "left",
                                  marginTop: '4%'
                                }}
                                text=" "
                                resend={resendOtpEmail}
                                buttonColor={"white"}
                                background={"#007bff"}
                              />
                            </Col>
                          )}
                          <Col md={{ span: 2 }} style={{ marginTop: "7  %" }}>
                            <Button
                              onClick={getBackToEmail}
                              style={{ display: "inline-block", float: "right" }}
                            >
                              <EditIcon />
                            </Button>{" "}
                          </Col>
                        </Row>
                      </Col>
                    )} */}
                <Col md={{ span: 12 }}>
                  <Button
                    className="signup_btn"
                    //type="submit"
                    variant="contained"
                    color="primary"
                    // onSubmit={signIn}
                    onClick={otpPageEnable}
                  // disabled={signInBtn}
                  >
                    <span className="signup_label">Send OTP</span>
                  </Button>
                </Col>
                <Col md={{ span: 12 }} style={{ textAlign: 'center' }}>
                  <span style={{ display: "inline-flex", fontSize: "100%", fontWeight: "600" }}>
                    Don&apos;t have an account?&nbsp;&nbsp;
                    <Link to="/SignUp" style={{ fontSize: "100%", padding: "0", color: "#ff0000" }}>
                      REGISTER HERE
                    </Link>
                  </span>
                </Col>
                {/* <Col sm={{ span: 6 }}>
                  <img src={playstore} className="storelink" />
                </Col>
                <Col sm={{ span: 6 }}>
                  <img src={appstore} className="storelink" />
                </Col> */}
              </Row>
            )}
            {otppage == true && (
              <Row>
                <Col md={{ span: 12 }}>
                  <p className="login_header">Client Login</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <p className="login_header">Manage your loan application and more!</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <Link onClick={enableSignInpage} className="backpagelink"><span style={{ color: "#5290d", fontSize: "100%" }}>
                    <i className="fas fa-arrow-left" style={{ width: '7%', fontSize: '18px' }}></i>{" "}Back to Login</span>
                  </Link>
                </Col>
                <Col md={{ span: 12 }} className="textfeild_txtbox">
                  <label className="login_txtlabel">Enter OTP</label>
                  <TextField
                    name="otp"
                    label="Enter OTP"
                    inputProps={{
                      maxLength: 6,
                    }}
                    onChange={verifyEmailOtp}
                  />
                  {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                </Col>
                {showTimer === true && (
                  <Col md={{ span: 12 }} style={{ marginTop: "5px" }}>
                    <OtpTimer
                      seconds={otpSeconds}
                      minutes={otpMinutes} //otpMinutes
                      style={{
                        color: "red",
                        display: "inline-block", float: "left",
                        marginTop: '4%'
                      }}
                      text=" "
                      resend={resendOtpEmail}
                      buttonColor={"white"}
                      background={"#007bff"}
                      textColor={"red"}
                      float={"right"}
                    />
                  </Col>
                )}
                <Col md={{ span: 12 }} style={{ marginTop: '4%' }}>
                  <Button
                    className="login_btn signup_btn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onSubmit={signIn}
                    // onClick={otpPageEnable}
                    disabled={signInBtn}>
                    <span className="signup_label">Sign In</span>
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
