import React, { useState, useEffect } from "react";
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextField, Button, StepButton } from '@material-ui/core';
import List from '@material-ui/core/List';
import MuiAlert from '@material-ui/lab/Alert';
import ListItem from '@material-ui/core/ListItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { SnackPosition } from "../../shared/constants";
import Snackbar from '@material-ui/core/Snackbar';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import { Dropdown } from 'semantic-ui-react'
import axios from "axios";
import { useStyleds } from "./styles";
import Typography from "../../base_components/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import { Formik } from "formik";
import { WorkingFormSchema } from "../../shared/validations";
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import DraftNext from '../../image/buttons/draftnext.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';
import deleterec from '../../image/pngicons/delete.png';
import up from '../../image/pngicons/up.png';
import down from '../../image/pngicons/down.png';
import edit from '../../image/pngicons/edit.png';
import addbutton from '../../image/pngicons/plussign.png';
import uploaddoc from '../../image/pngicons/upload.png';
import DocumentUpload from "../../components/DocumentUpload";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import moment from 'moment';
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import SearchBar from "material-ui-search-bar";
import FileViewer from 'react-file-viewer'



export default function WorkingCapital(props) {
  const classes = useStyleds();
  const [checkvalue, setchecked] = React.useState(0);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));

  const [formDisabled, setFormDisabled] = useState(false);
  const role_id = JSON.parse(localStorage.getItem('userdata'));

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    if (role_id.role_id == 5) {
      setFormDisabled(true);
    }
    checktype();
    checkapply();
    // bankcheck();
    getBusinessAndPromoterListByUser();
    validateincorporationdate();
    checkIfRequest();
  }, []);



  const [name, setName] = React.useState("");
  const [bpan, setBpan] = React.useState();
  const [gst, setGst] = React.useState("");

  const maskName = (mname) => {
    if (mname != undefined && mname != "" && mname != null) {
      let name = mname;
      name = name.split('');
      let maskedArray = [];
      //  let length = name.indexOf('@');
      let length = name.length;
      name.map((item, i) => {
        (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(name[i]);
      })
      let finalName = maskedArray.join('');
      // setName(finalEmail);
      console.log(finalName);
      return finalName;
    } else {
      return "";
    }
  }
  const maskBpan = (mbpan) => {
    if (mbpan != undefined && mbpan != "" && mbpan != null) {
      let bpan = mbpan;
      bpan = bpan.split('');
      let maskedArray = [];
      //let length = bpan.indexOf('@');
      let length = bpan.length;
      bpan.map((item, i) => {
        (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(bpan[i]);
      })
      let finalBpan = maskedArray.join('');
      //setBpan(finalEmail);
      console.log(finalBpan);
      return finalBpan;
    } else {
      return "";
    }
  }

  const maskGst = (mgst) => {
    if (mgst != undefined && mgst != "" && mgst != null) {
    let gst = mgst;
    gst = gst.split('');
    let maskedArray = [];
    let len = gst.length;
    gst.map((item, i) => {
      (i == 1 || i == 0 || i == len - 1) ? maskedArray.push(gst[i]) : maskedArray.push('*');
    })
    let finalGst = maskedArray.join('');
    //setGst(finalMobile);
    return finalGst;
  }else{
    return "";
  }
  }

  const [statess, setStatess] = React.useState(false);
  const floatingBtn = () => {
    setStatess(!statess);
  }

  const [bank, setbank] = React.useState([]);

  const bankcheck = async (parent_id) => {
    const bankdata = {
      bank_id: 1,
      token: "qwerty1234",
    }

    let data2 = {

      parent_id: parent_id
    }
    var bankarray = []
    await axios.post(apiCalling.coderscorner + `server.php/api/getBankByParentId`, data2).then(res => {
      res.data.data.map((item) => {
        console.log('item', item)
        bankarray.push({ key: item.bank_id, value: item.bank_id, flag: item.bank_name, text: item.bank_name })
      })
      setbank(bankarray)
      console.log("bankarray", bankarray);
    })
      .catch(er => {

        console.log("no data", er);
      });
  }

  const [Types, setType] = React.useState([]);
  var typearray = []
  const checktype = () => {
    const typedata = {
      token: "qwerty1234",
      type: "working_capital_types"
    }
    axios.post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`, typedata)
      .then(res => {
        res.data.data.map((item) => {
          console.log('item', item)
          typearray.push({ key: item.short_name, value: item.id, flag: item.name, text: item.name })
        })
        setType(typearray)
        console.log("typearray", typearray);
      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }

  const [searched, setSearched] = React.useState("");
  const [newBus, setNewBus] = React.useState();
  const requestSearch = (searchedVal) => {
    
    let busArr = businessList;
    if (searchedVal.length != 0 && searchedVal != "") {
      const filteredRows = busArr.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setBusinessList(filteredRows);
    } else {
      setBusinessList(newBus)
    }
  };

  const cancelSearch = () => {
    
    setSearched("");
    setBusinessList(newBus)
  }

  const handleSubmiting = () => {
    props.handleNext();
  };


  const handledata = () => {
    props.handleBack();
  };

  const SaveasDraft = () => {
    //props.handlecheck();
    handleSubmiting();
  }


  //Added Soumen below this
  const table_header = {
    fontWeight: "600",
    fontSize: "100%",
    //width: "20%"
  };
  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
    width: "1%"
  }
  const table_Action = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  }

  const [showBusinessPromoterList, setShowBusinessPromoterList] = React.useState(true);

  const [businessList, setBusinessList] = React.useState([]);


  const [openWCHeader, setOpenWCHeader] = React.useState([]);
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [loaderFlag, setLoaderFlag] = React.useState(true);
  const [headers, setHeaders] = React.useState(true);
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [headerValue, setHeaderValue] = React.useState("");

  const getBusinessAndPromoterListByUser = async () => {
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    const data = {
      user_id: checkuserid.user_id
    };

    let api_path = apiCalling.coderscorner + 'server.php/api/getWorkingCapitalDetailsBusinessAndPromoterById';
    await axios.post(apiCalling.coderscorner + 'server.php/api/getWorkingCapitalDetailsBusinessAndPromoterById', data).then((result) => {
      setShowWCAddForm(false);
      setShowBusinessPromoterList(true);
      setLoaderFlag(false);
      setBusinessList(result.data.data.businessList);
      setNewBus(result.data.data.businessList);

      let openWCArray = [];
      for (let i = 0; i < result.data.data.businessList.length; i++) {
        openWCArray.push(false);
      }
      setOpenWCHeader(openWCArray);
    }).catch((error) => {
      ErrorCatch(checkuserid.user_id, error, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
      console.log(error);
    });
  }
  const handleOpenWCHeader = (flag, i) => {
    let newArray = [];
    for (let j = 0; j < openWCHeader.length; j++) {
      if (j == i) {
        newArray.push(flag);
      } else {
        newArray.push(openWCHeader[j]);
      }
    }
    setOpenWCHeader(newArray);
  }

  const [parentInfoData, setParentInfoData] = React.useState();

  const checkapply = () => {
    if (localStorage.getItem('RequestType')) {
      const checkdata = localStorage.getItem('RequestType'),
        checkform = JSON.parse(checkdata)
      setchecked(checkform)
    } else {
      setchecked(1);
    }
  }

  let [initialValue, setInitialValue] = React.useState({
    id: "",
    credit: "",
    bank: "",
    type: "",
    roi: "",
    comment: "",
    lastsanction: "",
    parent_id: "",
    parent_type_id: ""
  })

  const handleOpenWCAddForm = (data) => {
    
    bankcheck(data.id);
    setHeaders(false);
    setAddFormButtonValue("Submit");
    setHeaderValue("Adding");
    setShowWCAddForm(true);
    setShowBusinessPromoterList(false);
    let parentData = {
      'parent_type_id': 1,
      'parent_id': data.id,
      'parent_name': data.name
    }
    setParentInfoData(parentData);
    setInitialValue({
      id: "",
      credit: "",
      bank: "",
      type: "",
      roi: "",
      comment: "",
      lastsanction: "",
      parent_id: data.id,
      parent_type_id: 1
    })
  }

  const handlesubmitform = (values, { resetForm }) => {
    handleNextClick(values);
    resetForm({
      id: "",
      credit: "",
      bank: "",
      type: "",
      roi: "",
      comment: "",
      lastsanction: "",
      parent_id: "",
      parent_type_id: ""
    })
  }
  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
  const handleNextClick = (data) => {
    setShowWCAddForm(false);
    setLoaderFlag(true);
    let eligibilityFlag = 0;
    if (checkvalue === 1) {
      eligibilityFlag = 1;
    }
    // let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let wcData = {
      id: data.id,
      parent_id: data.parent_id,
      parent_type_id: data.parent_type_id,
      creditlimit: data.credit,
      bank_id: data.bank,
      rate_of_intrest: data.roi,
      type: data.type,
      comments: data.comment,
      last_sanction: data.lastsanction,
      eligibility_flag: eligibilityFlag,
      user_id: checkuserid.user_id,
      Token: "qwerty1234"
    };
    let api_path = apiCalling.coderscorner + `server.php/api/workingCaptial`;
    axios.post(apiCalling.coderscorner + `server.php/api/workingCaptial`, wcData).then((res) => {
      if (res.data.status == true) {

        setShowWCAddForm(false);
        setShowBusinessPromoterList(true);
        getBusinessAndPromoterListByUser();
        setHeaders(true);
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
        props.handleCount(requestDetails.user_id);
      }
    })
      .catch((er) => {
        ErrorCatch(checkuserid.user_id, er, api_path, 'post', wcData, checkuserid.user_id, checkuserid.user_id);
        console.log("no data sorry ", er);
      });
  };

  const handleWCEditData = (parent, item) => {
    
    bankcheck(parent.id);
    setShowWCAddForm(true);
    setHeaders(false);
    setAddFormButtonValue("Update");
    setHeaderValue("Editing");
    setShowBusinessPromoterList(false);
    let parentData = {
      'parent_type_id': 1,
      'parent_id': parent.id,
      'parent_name': parent.name
    }
    setParentInfoData(parentData);
    setInitialValue({
      id: item.id,
      credit: item.credit_limit,
      bank: item.bank_id,
      type: item.type_id,
      roi: item.rate_of_interest,
      comment: item.comments,
      lastsanction: item.last_sanction,
      parent_id: parent.id,
      parent_type_id: 1
    })
  }
  const { vertical, horizontal } = SnackPosition;
  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState();
  const [colorMessage, setcolorMessage] = React.useState();
  //delete Bank by bankId
  const handleWCDeleteData = async (id) => {
    const data = {
      "id": id
    }
    console.log("id id is", id);
    
    let api_path = apiCalling.coderscorner + `server.php/api/deleteWorkingCapital`;
    await axios.post(apiCalling.coderscorner + `server.php/api/deleteWorkingCapital`, data).then((res) => {
      console.log("Deleted bank data ", res.data);
      if (res.data.status == true) {
        getBusinessAndPromoterListByUser();
        setShowBusinessPromoterList(true);
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      //	getuserdetailsoflogin();
    }).catch((er) => {
      ErrorCatch(checkuserid.user_id, er, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
      console.log("Something went Wrong While Deleting...!!!", er);
    });

  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [showDocScreen, setShowDocScreen] = React.useState(false);
  const [parentTypeId, setParentTypeId] = React.useState(0);
  const [parentId, setParentId] = React.useState(0);
  ///Document update 
  let docDetails = {
    formId: "1.1.6",
    parentId: parentId,
    parentTypeId: parentTypeId,
  }


  //ganashree
  const [maximumdate, setmaximumdate] = React.useState();  //by default today date
  const validateincorporationdate = async () => {
    let data = {
      parameter_name: "Incorporation Max Date"
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/getGeneralSettingByParameter', data).then(res => {
      console.log('date validation results', res.data.data);
      let maxyearparval = res.data.data[0].parameter_value;
      console.log('maxyearparval...........', maxyearparval)
      const now = moment();
      const year = now.subtract(maxyearparval, 'years');
      let substracteddate = moment(year).format('YYYY-MM-DD');
      console.log('letsubstracteddate...........', substracteddate)
      setmaximumdate(substracteddate)
    })
  }

  const [minimumdate, setminimumdate] = React.useState(); //3 years ago
  const validatemaxincorporationdate = async () => {
    let data = {
      parameter_name: "Incorporation Min Date"
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/getGeneralSettingByParameter', data).then(res => {
      console.log('date validation results', res.data.data);
      let maxyearparval = res.data.data[0].parameter_value;
      console.log('maxyearparval...........', maxyearparval)
      const now = moment();
      const year = now.subtract(maxyearparval, 'years');
      let substracteddate = moment(year).format('YYYY-MM-DD');
      console.log('letsubstracteddate...........', substracteddate)
      setminimumdate(substracteddate)
    })
  }

  const [dateError, setDateError] = React.useState("");
  const [dateValidate, setDateValidate] = useState(false);

  const handleDate = (e) => {

    
    var date = new Date(e.target.value);
    if (date > new Date(maximumdate) || date < new Date(minimumdate)) {
      console.log("Date not in range");
      setDateError("Date should be in between " + minimumdate + " and " + maximumdate);
      setDateValidate(false);

    } else {
      console.log("Date range");
      setDateError("");
      setDateValidate(true);
    }
  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation ctm_set_div">
      <div className='profile_table'>
        <div className="profile_table_part">
          <table style={{ marginBottom: 30, }}>
            <thead>
              <tr>
                <th><h3>Upload Documents</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Button className='ctm_btn_set footer'>Upload Here!</Button></td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th><h3>Add Appointment</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Button className='ctm_btn_set footer'>Book Appointment</Button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );


  // Comment Section Code 

  //const [showDocScreen, setShowDocScreen] = useState(false);
  const [equiOrCriff, setequiOrCriff] = useState(false);
  const [commentArr, setCommentsArr] = useState([]);

  const [comments, setComments] = useState("");
  const sendComments = async () => {
    const user_id = JSON.parse(localStorage.getItem('userdata'));
    
    let data = {
      user_id: user_id.id,
      application_ref_no: localData.reference_id,
      step: "Loans",
      sequence_no: "1",
      comments: comments,
      commented_by: user_id.id

    }
    let url = '';
    await axios.post(apiCalling.coderscorner + 'server.php/api/verificationComments', data).then(result => {
      console.log(result);
      if (result.data.status === true) {
        setShowMessage("Comment Saved");
        setsavenextmessage(true);

        setComments("");
        getverficationComments();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getverficationComments = async () => {
    
    let data = {
      "application_ref_no": localData.reference_id
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getverficationComments', data).then(result => {
      if (result.data.status === true) {
        console.log("a-------------------------------", result);

        setCommentsArr(result.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const deleteComment = async (id) => {
    let data = {
      id: id
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getverficationComments', data).then(result => {
      console.log(result);
      setCommentsArr(result.data.data);
    }).catch(error => {
      console.log(error);
    });
  }

  const [commentBox, setCommentBox] = React.useState({
    width: "500px",
    zIndex: "9999",
    marginTop: "-143%",
    // display: "none"
  })
  const [opencommentbox, setOpenComment] = React.useState(false);
  const changeCommentBox = (e) => {
    e.preventDefault();
    
    // setCommentBox({
    //   ...commentBox,
    //   // display: "block"
    // })
    if (opencommentbox) {
      setOpenComment(false);
    } else {
      setOpenComment(true);
    }
  }

  //  const changeCommentBox = (e) => {
  //    e.preventDefault();
  //    
  //    setCommentBox({
  //      ...commentBox,
  //      display: "block"
  //    })
  //  }

  /// end comment section
  const [showPreview, setShowPreview] = React.useState(false);

  // doc preview
  const [docPath, setDocPath] = React.useState('')
  const [docType, setDocType] = React.useState('')
  const [documentArray, setDocumentArray] = React.useState([]);
  const getUploadeDocOfUserV2 = async (busOrPro, ptId) => {
    ;
    const docdata = {
      user_id: checkuserid.user_id,
      parent_id: ptId,
      parent_type_id: busOrPro,
      form: "1.1.6",
      required_phase: "172",
      interval_flag: ''
    }
    let myArr = [];
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser';
    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2';

    await axios.post(apiPath2, docdata).then((result) => {
      console.log("rsutl-----------------", result);
      
      console.log("my document array----->", myArr);
      if (result.data.status) {
        let tempArr = result.data.data;
        let resultArr = [];
        if (tempArr.length >= 1) {
          tempArr.map(docchecklist => {
            if (docchecklist.documentArray.length > 0) {
              docchecklist.documentArray.map((docs, i) => {
                if (docs.file_name != '' && docs.file_name != undefined && docs.file_name != null) {
                  let obj = { text: docs.file_name, value: docs.docPath, file_type: docs.file_type }
                  resultArr.push(obj);
                }
              })
            }
          })
        }
        
        setDocumentArray(resultArr);
        setDocPath(resultArr[0].docPath);
        setDocType(resultArr[0].file_type);
      }

    }).catch(error => {
      ErrorCatch(1, error, api_path, 'post', docdata, 1, 1);
      console.log(error);
    })
  }


  const setPrieviewValues = (value, fileType) => {
    //
    let fileExt = fileType.split('/')
    setDocPath(value)
    setDocType(fileExt[1])
  }



  return (
    <Container maxWidth="lg">
      <div className="mini-container mg_btm_set">


        <Row>
          <Col md={{ span: 12 }}>
            <div className="content-header">
              {headers == true && (
                <Row>
                  {/* { requestDetails && (
                    <Col md={{ span: 12 }} style={{ marginBottom: "3%", textAlign: "center" }}>
                      <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                    </Col>
                  ) */}
                  <Col md={{ span: 12 }} className="mainheader1">
                    <Typography text="YOUR WORKING CAPITAL DETAILS" styleType="C" />
                  </Col>
                  <Col md={{ span: 12 }} className="subHeader1">
                    <table className="applicationTableDetails1">
                      <tr>
                        {/* <td>Type</td>
                          <td>:</td> */}
                        {localData.request_type_id === 0 && (
                          <td>Loan Application</td>)}
                        {localData.request_type_id === 1 && (
                          <td>Check Eligibility</td>)}
                        <td>&nbsp;|&nbsp;</td>
                        <td>Application Number</td>
                        <td>:</td>
                        <td>{localData.reference_id}</td>
                        <td>&nbsp;|&nbsp;</td>
                        <td>Loan Type</td>
                        <td>:</td>
                        <td>{localData.loan_type}</td>
                      </tr>
                    </table>
                  </Col>
                </Row>
              )}
            </div>
          </Col>

          {showDocScreen && (
            <>
              <Col md={{ span: 10 }}></Col>
              <Col md={{ span: 2 }} className="mainHeaderBack">
                <span className="image">
                  <img alt="d" className='backbutton navbutton docbackbtn' src={Back} onClick={() => {
                    setShowWCAddForm(false);
                    setShowBusinessPromoterList(true);
                    setHeaders(true);
                    setShowDocScreen(false);
                  }} />
                  <Tooltip title="Back" arrow>
                    <img alt="d" className='backbutton navgraybutton docbackbtn' src={grayback} onClick={() => {
                      setShowWCAddForm(false);
                      setShowBusinessPromoterList(true);
                      setHeaders(true);
                      setShowDocScreen(false);
                    }} />
                  </Tooltip>
                </span>
              </Col> </>)}
          {showDocScreen && (<DocumentUpload formdetails={docDetails} />)}
        </Row>
        {loaderFlag == true && (
          <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}
        {showBusinessPromoterList == true && loaderFlag == false && (
          <div>
            <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
              onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}>
              <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                {showMessage}
              </Alert>
            </Snackbar>
            <SearchBar
              className="search_bar"
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableSLNoHeading">#</TableCell>
                    <TableCell className="tableBusinessNameHeading">Business Name</TableCell>
                    <TableCell className="tableGSTHeading">GST</TableCell>
                    <TableCell className="tablePANHeading">Business PAN</TableCell>
                    <TableCell className="tableEntityTypeHeading">Entity Type</TableCell>
                    <TableCell className="tableStatusHeading"></TableCell>
                    <TableCell className="tableActionHeading">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessList.map((item, i) => (
                    <>
                      <TableRow hover className="datable">
                        <TableCell scope="item">{i + 1}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{maskGst(item.gst)}</TableCell>
                        <TableCell>{maskBpan(item.bpan)}</TableCell>
                        <TableCell>{item.entity_type}</TableCell>
                        {item.workingCapital.length > 0 && (
                          <Tooltip title={item.workingCapital.length + " " + "Working Capital Added"} placement="top">
                            <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.workingCapital.length}</p></TableCell>
                          </Tooltip>
                        )}
                        {item.workingCapital.length == 0 && (
                          <Tooltip title="No Working Capital Added" placement="top">
                            <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.workingCapital.length}</p></TableCell>
                          </Tooltip>
                        )}
                        {/* {item.workingCapital.length > 0 && (
                          <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.workingCapital.length}</p></TableCell>
                        )}
                        {item.workingCapital.length == 0 && (
                          <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.workingCapital.length}</p></TableCell>
                        )} */}
                        <TableCell className="tableActionHeading">
                          {item.workingCapital.length > 0 && (
                            <Tooltip title="Open Working Capital Details" placement="bottom">
                              <Button aria-label="expand item" className="actionBtn" onClick={() => handleOpenWCHeader(!openWCHeader[i], i)}>
                                {openWCHeader[i] ? <img alt="d" className="actionBtn svgicon" src={up} /> : <img className="actionBtn svgicon" alt="images1" src={down} />}
                              </Button>
                            </Tooltip>
                          )}
                          <Tooltip title="Add Working Capital" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleOpenWCAddForm(item)} >
                              <i className="fas fa-plus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img alt="d" className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenWCAddForm(item)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                          <Collapse in={openWCHeader[i]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Table size="small" aria-label="bankList">
                                <TableHead style={{ backgroundColor: '#eee' }}>
                                  <TableRow>
                                    <TableCell className="tableSLNoHeading">#</TableCell>
                                    <TableCell className="tableBusinessNameHeading">Bank</TableCell>
                                    <TableCell className="tableGSTHeading">Type</TableCell>
                                    <TableCell className="tablePANHeading">Credit Limit</TableCell>
                                    <TableCell className="tablePANHeading">Rate of Interest</TableCell>
                                    <TableCell className="tablePANHeading">Sanction Date</TableCell>
                                    <TableCell className="tableActionHeading">Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item.workingCapital.map((workingCapital, j) => (
                                    <TableRow hover className="datable" key={j + 1}>
                                      <TableCell scope="item">{j + 1}</TableCell>
                                      <TableCell>{workingCapital.bank_name}</TableCell>
                                      <TableCell>{workingCapital.typename}</TableCell>
                                      <TableCell>{workingCapital.credit_limit}</TableCell>
                                      <TableCell>{workingCapital.rate_of_interest}</TableCell>
                                      <TableCell>{workingCapital.last_sanction}</TableCell>
                                      <TableCell className="tableActionHeading">
                                        <Tooltip title="Edit Working Capital" placement="bottom">
                                          {/* <Button className="actionBtn" onClick={() => { handleWCEditData(item, workingCapital) }} >
                                            <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                                          </Button> */}
                                          <img alt="d" className="actionBtn svgicon" src={edit} onClick={() => {
                                            handleWCEditData(item, workingCapital);
                                            getUploadeDocOfUserV2(1, workingCapital.id)
                                          }} />
                                        </Tooltip>
                                        <Tooltip title="Upload Document" placement="bottom">
                                          <img alt="d" className="actionBtn svgicon" src={uploaddoc} onClick={() => {

                                            setShowWCAddForm(false);
                                            setShowBusinessPromoterList(false);
                                            setShowDocScreen(true);
                                            setParentTypeId(1);
                                            setParentId(workingCapital.id);
                                          }} />
                                        </Tooltip>
                                        <Tooltip title="Delete Working Capital" placement="bottom">
                                          {/* <Button className="actionBtn"  onClick={() => { handleBankDeleteData(bankingHeader) }} >
                                            <i className="fas fa-minus"
                                              style={{ fontSize: "15px" }} />
                                          </Button> */}
                                          <img alt="d" className="actionBtn svgicon" src={deleterec} onClick={() => { handleWCDeleteData(workingCapital.id) }} />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='btn_row_fixd'>
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                {/* className="btnspace" */}
                {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navbutton' src={Back} onClick={handledata} />
                  </Tooltip>
                </Col> */}
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img alt="images1" className='navbutton' src={Back} />
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img alt="images1" className='navgraybutton' src={grayback} onClick={handledata} />
                    </Tooltip>
                  </span>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img alt="images1" className='navbutton' src={DraftNext} />
                    <Tooltip title="Save & Next" arrow>
                      <img alt="images1" className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showWCAddForm === true && (
          <Formik
            enableReinitialize
            initialValues={initialValue}
            //   validationSchema={WorkingFormSchema}
            onSubmit={handlesubmitform} >
            {({
              values,
              errors,
              touched,
              handleChange,
              resetForm,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
              /* and other goodies */
            }) => {
              //  console.log("values in formik", values);
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={{ span: 12 }}>
                      <Row >
                        <Col md={{ span: 8 }} className="mainheader1">
                          {parentInfoData.parent_type_id == 1 && (
                            <Typography text={headerValue + " Working Capital for Business : " + parentInfoData.parent_name} styleType="C" />
                          )}
                          {parentInfoData.parent_type_id == 2 && (
                            <Typography text={headerValue + " Working Capital for Promoter : " + parentInfoData.parent_name} styleType="C" />
                          )}
                        </Col>
                        <Col md={{ span: 2 }} style={{ marginTop: "20px" }}>
                          <Typography text={
                            <i class="fas fa-eye" onClick={() => {
                              
                              setShowPreview(!showPreview);
                            }}></i>
                          }
                          />
                        </Col>
                        <Col md={{ span: 2 }} className="mainHeaderBack">
                          {/* <Button className="backBtn" variant="outlined" color="primary"
                            onClick={() => {
                              resetForm();
                              setShowWCAddForm(false);
                              setShowBusinessPromoterList(true);
                              setHeaders(true);
                            }} >
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                            </Button> */}
                          <span className="image">
                            <img alt="images1" className='backbutton navbutton' src={Back} onClick={() => {
                              resetForm();
                              setShowWCAddForm(false);
                              setShowBusinessPromoterList(true);
                              setHeaders(true);
                            }} />
                            <Tooltip title="Back" arrow>
                              <img alt="images1" className='backbutton navgraybutton' src={grayback} onClick={() => {
                                resetForm();
                                setShowWCAddForm(false);
                                setShowBusinessPromoterList(true);
                                setHeaders(true);
                              }} />
                            </Tooltip>
                          </span>
                        </Col>
                        <Col md={{ span: 12 }} className="subHeader1">
                          <table className="applicationTableDetails1">
                            <tr>
                              {/* <td>Type</td>
                              <td>:</td> */}
                              {localData.request_type_id === 0 && (
                                <td>Loan Application</td>)}
                              {localData.request_type_id === 1 && (
                                <td>Check Eligibility</td>)}
                              <td>&nbsp;|&nbsp;</td>
                              <td>Application Number</td>
                              <td>:</td>
                              <td>{localData.reference_id}</td>
                              <td>&nbsp;|&nbsp;</td>
                              <td>Loan Type</td>
                              <td>:</td>
                              <td>{localData.loan_type}</td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                      <div>

                        {showPreview === false ? (<Row>
                          <Col md={{ span: 6 }}>
                            <Typography text="Credit Limit" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="credit"
                                  label="Enter Credit Limit"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.credit &&
                                      touched.credit &&
                                      errors.credit
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.credit ? classes.manorma : null,
                                  }}
                                  value={values.credit}
                                  helperText={
                                    errors.credit &&
                                    touched.credit &&
                                    errors.credit
                                  }
                                  disabled={formDisabled}
                                />
                              </ListItem>
                            </List>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <Typography text="Bank" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Bank"
                                  id="bank"
                                  name="bank"
                                  fluid
                                  search
                                  selection
                                  value={values.bank}
                                  onBlur={() => setFieldTouched("bank", true)}
                                  onChange={(e, { value }) => setFieldValue("bank", value)}
                                  options={bank}
                                  className={errors.bank && touched.bank ? classes.required : values.bank == '' ? classes.reqired : classes.man}
                                  disabled={formDisabled}
                                />
                              </ListItem>
                            </List>
                            {errors.bank && touched.bank && values.bank == '' && (
                              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "-7px" }}>{errors.bank}</div>
                            )}
                          </Col>

                          <Col md={{ span: 6 }}>
                            <Typography text="Type" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Type"
                                  id="type"
                                  name="type"
                                  fluid
                                  search
                                  selection
                                  value={values.type}
                                  onBlur={() => setFieldTouched("type", true)}
                                  onChange={(e, { value }) => setFieldValue("type", value)}
                                  options={Types}
                                  className={errors.type && touched.type ? classes.required : values.type == '' ? classes.reqired : classes.man}
                                  disabled={formDisabled}
                                />
                              </ListItem>
                            </List>
                            {errors.type && touched.type && values.type == '' && (
                              <div style={{ color: "red", fontSize: "0.75rem", marginTop: "-7px" }}>{errors.type}</div>
                            )}
                          </Col>



                          <Col md={{ span: 6 }}>
                            <Typography text="Rate of Interest" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="roi"
                                  label="Rate of Interest"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.roi &&
                                      touched.roi &&
                                      errors.roi
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.roi ? classes.manorma : null,
                                  }}
                                  value={values.roi}
                                  helperText={
                                    errors.roi &&
                                    touched.roi &&
                                    errors.roi
                                  }
                                  disabled={formDisabled}
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography text="Comments" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="comment"
                                  label="Comments"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.comment && touched.comment && errors.comment ? true : false}
                                  InputProps={{ className: values.comment ? classes.manorma : null, }}
                                  value={values.comment}
                                  helperText={errors.comment && touched.comment && errors.comment}
                                  disabled={formDisabled}
                                />
                              </ListItem>
                            </List>
                          </Col>
                        </Row>) : (

                          <>
                            <Row>
                              <Col md={{ span: 6 }}>
                                <Table border="1" style={{ fontSize: "0.9em", position: "absolute", fontFamily: "sans-serif", width: "90%" }}
                                >

                                  <TableHead style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                    <TableRow>
                                      <TableCell style={{ padding: "10px 15px", textAlign: "center", fontSize: "16px" }}>Parameter</TableCell>
                                      <TableCell style={{ padding: "10px 15px", textAlign: "center", fontSize: "16px" }}>Value</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody style={{ display: "block", height: "52vh", overflowY: "scroll" }}>
                                    <>
                                      <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                        <TableCell style={{ padding: "20px 15px", textAlign: "center" }}>Credit Limit</TableCell>
                                        <TableCell style={{ padding: "20px 15px" }}>
                                          <TextField
                                            id="filled-error-helper-text standard-basic"
                                            name="credit"
                                            label="Enter Credit Limit"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                              errors.credit &&
                                                touched.credit &&
                                                errors.credit
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              className: values.credit ? classes.manorma : null,
                                              disableUnderline: true

                                            }}
                                            value={values.credit}
                                            helperText={
                                              errors.credit &&
                                              touched.credit &&
                                              errors.credit
                                            }
                                            disabled={formDisabled}
                                          />
                                        </TableCell>
                                      </TableRow>

                                      <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                        <TableCell style={{ padding: "20px 15px", textAlign: "center" }}>Bank</TableCell>
                                        <TableCell style={{ padding: "20px 15px" }}>
                                          <Dropdown
                                            placeholder="Select Bank"
                                            id="bank"
                                            name="bank"
                                            fluid
                                            search
                                            selection
                                            value={values.bank}
                                            onBlur={() => setFieldTouched("bank", true)}
                                            onChange={(e, { value }) => setFieldValue("bank", value)}
                                            options={bank}
                                            InputProps={{
                                              disableUnderline: true
                                            }}
                                            className={errors.bank && touched.bank ? classes.required : values.bank == '' ? classes.reqired : classes.man}
                                            disabled={formDisabled}
                                          />
                                        </TableCell>
                                      </TableRow>

                                      <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                        <TableCell style={{ padding: "20px 15px", textAlign: "center" }}>Type</TableCell>
                                        <TableCell style={{ padding: "20px 15px" }}>
                                          <Dropdown
                                            placeholder="Select Type"
                                            id="type"
                                            name="type"
                                            fluid
                                            search
                                            selection
                                            value={values.type}
                                            onBlur={() => setFieldTouched("type", true)}
                                            onChange={(e, { value }) => setFieldValue("type", value)}
                                            options={Types}
                                            InputProps={{
                                              disableUnderline: true
                                            }}
                                            className={errors.type && touched.type ? classes.required : values.type == '' ? classes.reqired : classes.man}
                                            disabled={formDisabled}
                                          /></TableCell>
                                      </TableRow>

                                      <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                        <TableCell style={{ padding: "20px 15px", textAlign: "center" }}>Rate Of Interest</TableCell>
                                        <TableCell style={{ padding: "20px 15px" }}>
                                          <TextField
                                            id="filled-error-helper-text standard-basic"
                                            name="roi"
                                            label="Rate of Interest"
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                            error={
                                              errors.roi &&
                                                touched.roi &&
                                                errors.roi
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              className: values.roi ? classes.manorma : null,
                                              disableUnderline: true

                                            }}
                                            value={values.roi}
                                            helperText={
                                              errors.roi &&
                                              touched.roi &&
                                              errors.roi
                                            }
                                            disabled={formDisabled}
                                          /></TableCell>
                                      </TableRow>

                                      <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                        <TableCell style={{ padding: "20px 15px", textAlign: "center" }}>Comments</TableCell>
                                        <TableCell style={{ padding: "20px 15px" }}>
                                          <TextField
                                            id="filled-error-helper-text standard-basic"
                                            name="comment"
                                            label="Comments"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.comment && touched.comment && errors.comment ? true : false}
                                            InputProps={{ className: values.comment ? classes.manorma : null, }}
                                            value={values.comment}
                                            helperText={errors.comment && touched.comment && errors.comment}
                                            disabled={formDisabled}
                                          /></TableCell>
                                      </TableRow>

                                    </>
                                  </TableBody>
                                </Table>
                              </Col>
                              <Col
                                md={{ span: 6 }}
                                className='fileformatted'
                                style={{ marginTop: '25px' }}
                              >
                                <Dropdown
                                  placeholder='Select Document'
                                  name='area'
                                  fluid
                                  search
                                  selection
                                  //onBlur={() => setFieldTouched("area", true)}
                                  onChange={async (
                                    e,
                                    { value, text, file_type, options }
                                  ) => {
                                    //
                                    console.log(options)
                                    if (options.length > 1) {
                                      options.map(item => {
                                        if (item.value === value) {
                                          setPrieviewValues(
                                            value,
                                            item.file_type
                                          )
                                        }
                                      })
                                    }
                                  }}
                                  options={documentArray}
                                />
                                <FileViewer
                                  fileType={docType}
                                  filePath={docPath}
                                //  errorComponent={CustomErrorComponent}
                                // className="banking_fileviewer"
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row>
                          <Col md={{ span: 12 }}>
                            <Row>
                              <Col md={{ span: 10 }}></Col>
                              {
                                /// floating
                              }
                              {
                                role_id.role_id == 1 || role_id.role_id == 1 ? (
                                  <div id="mySidenav" className="sidenav">
                                    <a href="/#" id="about" className="mySidenav" onClick={changeCommentBox}>Comments</a>
                                  </div>) : (null)
                              }
                              <Col md={{ span: 2 }} style={{ marginTop: '40px' }}><Button className="updatebtn"
                                variant="contained" color="primary" type="submit"
                                onClick={handleSubmit}>{addFormButtonValue}</Button>
                              </Col>
                              <Col md={{ span: 5 }}></Col>
                            </Row>
                          </Col>
                          {opencommentbox && (
                            <Row>
                              <Col md={{ span: 6 }} style={{ marginTop: "2%" }}></Col>
                              <Col md={{ span: 6 }} >
                                <div style={commentBox} className="comment-box" >
                                  <h2 style={{ color: "rgb(114, 84, 163)" }}>Verification Comments</h2>
                                  <div className="comment-form" >
                                    <div className="comment-form-fields">
                                      <textarea className="comment_textfield" placeholder="Comment" rows="4" required value={comments} onChange={
                                        (e) => {
                                          
                                          setComments(e.target.value)
                                        }
                                      } ></textarea>
                                    </div>
                                    <div className="comment-form-actions">
                                      <Button type="button" variant="contained"
                                        color="primary" className="x" onClick={(e) => {


                                          sendComments();
                                        }}>Post Comment</Button>
                                    </div>
                                  </div>

                                  {
                                    commentArr.map(item => (
                                      <div className="comment">
                                        <p className="comment-header">{item.email}</p>
                                        <p className="comment-body">- {item.comments}</p>
                                        <div className="comment-footer">
                                          <a href="/#" className="comment-footer-delete" onClick={() => {
                                            deleteComment(item.id);
                                          }}
                                          >Delete Comment</a>
                                        </div>
                                      </div>
                                    ))
                                  }
                                </div>
                              </Col>
                            </Row>
                          )}
                        </Row>

                      </div>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        )}
      </div>

    </Container >
  );
}


