import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

//API CALLING GLOBAL DECLARATION
export const apiCalling = {
  coderscorner : 'http://164.52.208.120:8082/delta/', 
  // coderscorner: 'http://127.0.0.1:8003/', 

  // thirdParty : 'http://164.52.208.120:8082/alpha/',
 
  identityManagement : 'http://164.52.208.120:8082/Identitymanagement/',
  // identityManagement: 'http://127.0.0.1:8002/',
}
