
import React, { useState } from 'react';
import { loadCSS } from "fg-loadcss";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import {
    TextField,
    Button,
} from "@material-ui/core";
import { login } from "../../shared/validations";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { useStyles } from "./styles";
import OtpTimer from "otp-timer";
import axios from "axios";
import facebook from "../../image/buttons/facebook.png";
import google from "../../image/buttons/google.png";
import linkedin from "../../image/buttons/linkedIn.png";
import playstore from "../../image/buttons/signin/playstore.png";
import appstore from "../../image/buttons/signin/appstore.png";
import InputOtp from "@onefifteen-z/react-input-otp";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import signin from '../../image/buttons/signin.png';
import { ErrorCatch, apiCalling } from "../../shared/constants";
//import Cookies from 'js-cookie'



const InternalLogin = (props) => {

    // const classes = useStyles();
    const [pan, setPan] = React.useState();
    const [email, setEmail] = React.useState();
    const [mobile, setMobile] = React.useState();
    const [showOtp, setShowOtp] = React.useState(false);
    const [signInBtn, setSignInBtn] = React.useState(true);
    const [otpMinutes, setOtpMinutes] = useState();
    const [otpSeconds, setOtpSeconds] = useState();
    const [emailDisable, setEmailDisabled] = useState(true);
    const [showTimer, setShowTimer] = useState(true);



    React.useEffect(() => {
        localStorage.clear();
        loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            document.querySelector("#font-awesome-css")
        );
        getOtpTimer();


    }, []);


    //Verifying user PAN And email
    const [showInvalidMessage, setShowInvalidMessage] = useState(false);
    //const [emailOrMobile,setEmailOrMobile] = useState();
    const [userId, setUserId] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const verifyEmailOrMobile = (e) => {
 
        let emailOrMobile = e.target.value;
        // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailRegex = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{1,5}/g;
        const phoneRegex = /^\d{10}$/;
        setEmail(emailOrMobile);  
        if ( !emailRegex.test(emailOrMobile) && !phoneRegex.test(emailOrMobile)) {
          setErrorMessage("Please enter valid email id or mobile number.");
          setShowInvalidMessage(true);
          setSignInBtn(true);
        }
         else {
          setErrorMessage("");
          setShowInvalidMessage(false);
          setSignInBtn(false);
          
        }
    };

    const verifyEmail = (email) => {
        
       // setEmail(email);
        let rbody = {};
        // setMobile(email)
        if (email != null && email !== undefined) {
            if (email.includes('@')) {
                rbody = {
                    email: email,
                }
            } else {
                rbody = {
                    mobile: email
                }
            }

            let user = {};
           // console.log(apiCalling.identityManagement + 'server.php/api/AdminSignin');
            axios.post(apiCalling.identityManagement + 'server.php/api/AdminSignin', rbody).then((result) => {
                if (result.data !== undefined || result.data !== null) {
                    user = result.data;
                    console.log(user);

                    if (user.status === true) {
                        setOtpPage(true);
                        setSignInBtn(true);
                   //     verifyUser(email);
                        setShowOtp(!showOtp);
                        setShowInvalidMessage(false);
                        localStorage.setItem('userdata', JSON.stringify(user.Data.user_details));
                    } else if (user.status === false) {
                        setShowInvalidMessage(true)
                        setErrorMessage(user.message);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setShowInvalidMessage(true);
            setErrorMessage("Please enter all the feilds");
        }
    }

    /// get otp   and verify
    const verifyUser = (data) => {
        
        let rbody = {};
        if (data.includes('@')) {
            rbody = {
                email: data,
                pan: pan,
            };
        } else {
            rbody = {
                mobile: data,
                pan: pan,
            }
        }
        axios
            .post(apiCalling.identityManagement + `server.php/api/UserSignin`,
                rbody
            )
            .then((res) => {
                if (res.data.status === true) {
                    console.log(res.data);
                    setUserId(res.data.Data.user_details.id)
                    //getUserDataFromSignUp(res.data.Data.user_details.id);
                    getUserDataFromSignUp(res.data.Data.user_details.id);
                   // geClientRegData(res.data.Data.user_details.id)
                    let data1;
                    if (data.includes('@')) {
                        data1 = res.data.Data.user_details;
                    } else {
                        data1 = res.data.Data.user_details;
                    }
               //     localStorage.setItem('userdata', JSON.stringify(data1));
                 //   Cookies.set('userdata_cookie', JSON.stringify(data1));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    // verify email otp
    const [otpErrorMessage, setOtpErrorMessage] = useState(false);
    const [otpMessage, setOtpMessage] = useState();
    const verifyEmailOtp = (e) => {
        let eotp = e.target.value;
        let len = eotp.length;
        const otpRegex = /^\d{6}$/;
        // if ( !otpRegex.test(eotp)) {
        // }
        if (len == 6) {
            let data = {
                value: email,
                form_id: 2,
                otp: eotp,
            };
            localStorage.setItem("otps", JSON.stringify(data));
          //  Cookies.set('otps_cookie', JSON.stringify(data));


             const checkotp=localStorage.getItem("otps");
            //const checkotp=Cookies.get("otps");
            //let getotpcookie = Cookies.get('otps_cookie');
            //console.log(getotpcookie);
            let otps = JSON.parse(checkotp);
            axios
                .post(apiCalling.identityManagement + `server.php/api/verifyOtp`,
                    data
                )
                .then((result) => {
                    if (result.data != undefined || result.data != null) {
                        //  console.log(result.data);
                        if (result.data.status === true) {
                            setSignInBtn(false);
                            setShowTimer(false);
                            setOtpErrorMessage(false);
                        } else if (result.data.status === false) {
                            setOtpErrorMessage(true);
                            setOtpMessage(result.data.message);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } 
        // else if(len > 6) {
        //     setSignInBtn(true);
        //     setOtpErrorMessage(true);
        //     setOtpMessage('Enter a valid otp');
        // }
         else {
            setSignInBtn(true);
            setOtpErrorMessage(true);
        }
        setShowOtp(e.target.value);
    };

    //resend otp for email
    const resendOtpEmail = () => {
        let data = {};
        let val = email;
        if (val.includes('@')) {
            data = {
                type: "email",
                value: val,
                form_id: 2
            };
        } else {
            data = {
                type: "mobile",
                value: val,
                form_id: 2
            }
        }
        axios
            .post(apiCalling.identityManagement + `server.php/api/resendOtp`,
                data
            )
            .then((res) => {
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // user sigin
    var llDate = new Date();
    var lalogindate = llDate.toString();

    const signIn = async (e) => {
        
        e.preventDefault();
        let data = {
            // mobile:mobile,
        };
        if (email !== "" || email !== undefined) {
            data = {
                email: email,
                pan: pan,
                lastLoginDate: lalogindate
            }
        } else {
            data = {
                email: "",
                mobile: mobile,
                pan: pan,
                lastLoginDate: lalogindate
            }
        }
        localStorage.setItem("logindata", JSON.stringify(data));
       // Cookies.set('logindata_cookie', JSON.stringify(data));
        const checkuserloggeddata = localStorage.getItem("userdata");
       // let getUserloggeddata = Cookies.get('userdata_cookie');
       // console.log(getUserloggeddata);
        let iduser = JSON.parse(checkuserloggeddata);

        const checkotp = localStorage.getItem("otps");
       // let getotpcookie = Cookies.get('otps_cookie');
       /// console.log(getotpcookie);
        let otps = JSON.parse(checkotp);
        // console.log(otps.otp);
        // console.log(iduser.id);
        let user_data_id = iduser.id;
        let otp_data = otps.otp;
        let user_post_data = {
            user_id: user_data_id,
            otp: otp_data
        }
        console.log("save-login" + user_post_data);
        if (iduser.role_id == 1 || iduser.role_id == 3 || iduser.role_id == 6) {
            props.history.push({ pathname: "/ProfileSteper" })
        } else {

            if (iduser.role_id == 4) {
                props.history.push({ pathname: "/AdminDashboard" })
            }else if (iduser.role_id == 7){
                
                props.history.push({ pathname: "/DocumentsCollector" })
            }
             else {
                props.history.push({ pathname: "/ProfileSteper" })
            }
            //session history
            await axios.post(apiCalling.identityManagement + 'server.php/api/saveLoginTime', user_post_data).then((result) => {
                console.log(result);
                if (result.data.status === true) { }
            }).catch(error => {
                ErrorCatch(error, 'post', user_post_data);
                console.log(error)
            })
        }

    };

    //
    // const [checkUserExist, setCheckUserExist] = useState("");
    // const geClientRegData = async (id) => {
    //        
    //         let data = {
    //             user_id: id
    //         }
    //         let api_path = apiCalling.coderscorner + 'server.php/api/getCustomerbyuserid';
    //         await axios.post(apiCalling.coderscorner + 'server.php/api/getCustomerbyuserid', data).then((result) => {
    //             //  console.log(result);
    //             if (result.data.status === true) {
    //                 let sData = result.data.data[0];
    //                 localStorage.removeItem("SignUpIdAndData");
    //                 Cookies.remove('SignUpIdAndData');

    //                 localStorage.setItem("SignUpIdAndData", JSON.stringify(sData));
    //                 Cookies.set('SignUpIdAndData_cookie', JSON.stringify(sData));

    //                 setCheckUserExist("busexist")

    //             } else {
    //                 getUserDataFromSignUp(id);
    //             }
    //             //   return result.data.status;
    //         }).catch(error => {
    //             ErrorCatch(id, error, api_path, 'post', data, id, id);
    //             console.log(error)
    //         })
    // }
    const [checkUserExist, setCheckUserExist] = useState("");


    //// IDENTITY MANAGEMENT
    const getUserDataFromSignUp = async (ID) => {
        
        let data1 = { id: ID }
        let api_path = apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid';
        await axios.post(apiCalling.identityManagement + 'server.php/api/getUserdetailsbyid', data1).then(result => {
            //    console.log(result)
            if (result.data.status === true) {
                setCheckUserExist("busnotexist")
                localStorage.setItem("SignUpIdAndData", JSON.stringify(result.data.data));
               // Cookies.set('SignUpIdAndData_cookie', JSON.stringify(result.data.data));

                //  console.log(result.data)
            }
        }).catch(error => {
            ErrorCatch(ID, error, api_path, 'post', data1, ID, ID);
            console.log(error)
        })
    }
    ///Otp time as per general setting
    const getOtpTimer = () => {
        let data = {
            parameter_name: "OTP TIMER",
        };
        axios
            .post(apiCalling.coderscorner +
                `server.php/api/getGeneralSettingByParameter`,
                data
            )
            .then((result) => {
                if (result.data.data != undefined || result.data.data != null) {
                    //  console.log(result.data.data[0]);
                    let data = result.data.data[0].parameter_value;
                    //  console.log("timer", data);
                    convertTimerToInt(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //conversion for Otp timer into
    const convertTimerToInt = (timer) => {
        let newTimer = parseInt(timer);
        var minutes = Math.floor(newTimer / 60);
        var seconds = newTimer - (60 * minutes);
        setOtpMinutes(minutes);
        setOtpSeconds(seconds);
    };

    const [otppage, setOtpPage] = useState(false);
    const otpPageEnable = () => {
        verifyEmail(email);
    }
    const enableSignInpage = () => {
        setOtpPage(false);
    }


    return (
        <div className="login-form">
            <Card className="logincard">
                <CardContent>
                    <form onSubmit={signIn}>
                        {otppage == false && (
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <p className="login_header">Internal User Login</p>
                                </Col>

                                <Col md={{ span: 12 }} className="textfeild_txtbox">
                                    <label className="login_txtlabel">Email / Mobile</label>
                                    <TextField
                                        name="email"
                                        label="xyz@mno.com/900000000"
                                        // onBlur={emailBlur}
                                        value={email}
                                        onChange={(e) => {
                                            verifyEmailOrMobile(e)
                                            //verifyEmail(e.target.value);
                                            // setEmail(e.target.value);
                                        }}
                                    />
                                    {showInvalidMessage === true && (<span className="valMsg">{errorMessage}</span>)}
                                </Col>
                                {/* {showOtp == true && (
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col md={{ span: 8 }} style={{ marginTop: '4%' }}>
                              <InputOtp onChange={verifyEmailOtp} />
                              {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                            </Col>
                            {showTimer === true && (
                              <Col md={{ span: 2 }} style={{ marginTop: "7%" }}>
                                <OtpTimer
                                  seconds={otpSeconds}
                                  minutes={otpMinutes} //otpMinutes
                                  style={{
                                    color: "blue",
                                    display: "inline-block", float: "left",
                                    marginTop: '4%'
                                  }}
                                  text=" "
                                  resend={resendOtpEmail}
                                  buttonColor={"white"}
                                  background={"#007bff"}
                                />
                              </Col>
                            )}
                            <Col md={{ span: 2 }} style={{ marginTop: "7  %" }}>
                              <Button
                                onClick={getBackToEmail}
                                style={{ display: "inline-block", float: "right" }}
                              >
                                <EditIcon />
                              </Button>{" "}
                            </Col>
                          </Row>
                        </Col>
                      )} */}
                                <Col md={{ span: 12 }}>
                                    <Button
                                        className="signup_btn"
                                        //type="submit"
                                        variant="contained"
                                        color="primary"
                                        // onSubmit={signIn}
                                        onClick={otpPageEnable}
                                        disabled={signInBtn}
                                    >
                                        <span className="signup_label">Send OTP</span>
                                    </Button>
                                </Col>

                                {/* <Col sm={{ span: 6 }}>
                    <img src={playstore} className="storelink" />
                  </Col>
                  <Col sm={{ span: 6 }}>
                    <img src={appstore} className="storelink" />
                  </Col> */}
                            </Row>
                        )}
                        {otppage == true && (
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <p className="login_header">Internal Login</p>
                                </Col>
                                <Col md={{ span: 12 }}>
                                    <p className="login_header">Manage your loan application and more!</p>
                                </Col>
                                <Col md={{ span: 12 }}>
                                    <Link onClick={enableSignInpage} className="backpagelink"><span style={{ color: "#5290d", fontSize: "100%" }}>
                                        <i className="fas fa-arrow-left" style={{ width: '7%', fontSize: '18px' }}></i>{" "}Back to Login</span>
                                    </Link>
                                </Col>
                                <Col md={{ span: 12 }} className="textfeild_txtbox">
                                    <label className="login_txtlabel">Enter OTP</label>
                                    <TextField
                                        name="otp"
                                        label="Enter OTP"
                                        // type="number"
                                        inputProps={{ maxLength: 6 }}
                                        onChange={verifyEmailOtp}
                                        // value={showOtp}
                                    />
                                    {otpErrorMessage == true && (<p className="valMsg">{otpMessage}</p>)}
                                </Col>
                                {showTimer === true && (
                                    <Col md={{ span: 12 }} style={{ marginTop: "5px" }}>
                                        <OtpTimer
                                            seconds={otpSeconds}
                                            minutes={otpMinutes} //otpMinutes
                                            style={{
                                                color: "red",
                                                display: "inline-block", float: "left",
                                                marginTop: '4%'
                                            }}
                                            text=" "
                                            resend={resendOtpEmail}
                                            buttonColor={"white"}
                                            background={"#007bff"}
                                            textColor={"red"}
                                            float={"right"}
                                        />
                                    </Col>
                                )}
                                <Col md={{ span: 12 }} style={{ marginTop: '4%' }}>
                                    <Button
                                        className="login_btn signup_btn"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onSubmit={signIn}
                                        // onClick={otpPageEnable}
                                        // disabled={!formik.isValid}>
                                        disabled={signInBtn}>
                                        <span className="signup_label">Sign In</span>
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}
export default InternalLogin;
