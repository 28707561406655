
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  listitem: {
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
  },
  listitems: {
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: 20,

  },
  button_up: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 230,
    width: 10
  },
  Button: {
    showIcon: true,
  },

  textfield: {
    width: '100%'
  },
  

  linkdin: {
    backgroundColor: "#007bff",
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_div: {
    textTransform: "capitalize",
    margin: "10px",
    fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_others: {
    textTransform: "capitalize",
    fontSize: "14px",
    margin: "10px",
    width: "100%"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  uploadB: {
    backgroundColor: "#ffb307f2",
    textTransform: "uppercase",
    boxShadow: "1px 3px 7px 4px #b0dbeb6b",
  },
  table: {
    minWidth: 700,
  },
  personalBox: {
    display: 'block',
    padding: '50px',
    boxShadow: '-1px 1px 16px 11px #f8f9fe',
    height: 'auto',
    marginTop: '18px',
  },
  manorma: {
    '&:before': {
      borderBottom: "1.5px solid #56B537 !important",
    }
  },
  required: {
    border: "1.5px solid red !important",
  },
  man: {
    border: "1.5px solid #56B537 !important",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);