
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Typography from '../../base_components/Typography'
import { personrows, bankoption, type, countryOptions, financebakend } from "../../shared/constants";
import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { TextField, Grid } from '@material-ui/core';
import { Formik } from "formik";
import { Dropdown } from 'semantic-ui-react'
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MultiSelect from "react-multi-select-component";
import { async } from 'q';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 2,
        backgroundColor: "lightgrey",
    },
    header: {
        width: '100%',
        height: '80px',
        backgroundColor: "black",
        padding: 15,
        fontSize: 25,
        fontWeight: 'bold'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    linkdin: {
        backgroundColor: '#007bff',
        textTransform: 'capitalize',
        margin: '10px',
        padding: '9px',
        width: '50%',
        fontSize: 18,
        align: 'center'
    },
    button_div: {
        textTransform: 'capitalize',
        margin: '10px',
        padding: '9px',
        width: '61%',
        fontSize: 18
    },
    listitem: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20

    },
    listitems: {
        //marginLeft:20,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(-6),
        // flexDirection:'row',

    },
    hideshow1: {
        //marginLeft:20,
        //padding:15,
        //flexDirection:'row',

    },
    listitems1: {
        //marginLeft:20,
        // marginRight:-20,
        marginTop: theme.spacing(-2),
        marginLeft: theme.spacing(6),


    },

    btn: {
        margin: theme.spacing(2)
    },
    btn1: {
        marginLeft: theme.spacing(5),
        margin: theme.spacing(2)
    },
    textField1: {
        marginLeft: theme.spacing(10),
        // marginRight: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 170,
        // height:20
    },
    textField2: {
        marginRight: theme.spacing(-5)
    },
    btn: {
        // margin:theme.spacing(1),
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(8),
    },

}));

export default function Profit() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);
    const [open9, setOpen9] = React.useState(false);
    const [open10, setOpen10] = React.useState(false);
    const [open11, setOpen11] = React.useState(false);
    const [open12, setOpen12] = React.useState(false);
    const [open13, setOpen13] = React.useState(false);
    const [open14, setOpen14] = React.useState(false);
    const [open15, setOpen15] = React.useState(false);
    const [show, setshow] = React.useState(false);
    const [show1, setshow1] = React.useState(false);
    const [costshow, setcostshow] = React.useState(false);
    const [costhide, setcosthide] = React.useState(false);
    const [salshow, setsalshow] = React.useState(false);
    const [salhide, setsalhide] = React.useState(false);
    const [intshow, setintshow] = React.useState(false);
    const [inthide, setinthide] = React.useState(false);
    const [divishow, setdivishow] = React.useState(false);
    const [divihide, setdivihide] = React.useState(false);
    const [deprishow, setdeprishow] = React.useState(false);
    const [deprihide, setdeprihide] = React.useState(false);
    const [pbtshow, setpbtshow] = React.useState(false);
    const [pbthide, setpbthide] = React.useState(false);
    const [shareshow, setshareshow] = React.useState(false);
    const [sharehide, setsharehide] = React.useState(false);
    const [promshow, setpromshow] = React.useState(false);
    const [promhide, setpromhide] = React.useState(false);
    const [bankshow, setbankshow] = React.useState(false);
    const [bankhide, setbankhide] = React.useState(false);
    const [fixedshow, setfixedshow] = React.useState(false);
    const [fixedhide, setfixedhide] = React.useState(false);
    const [inventshow, setinventshow] = React.useState(false);
    const [inventhide, setinventhide] = React.useState(false);
    const [sundshow, setsundshow] = React.useState(false);
    const [sundhide, setsundhide] = React.useState(false);
    const [ccshow, setccshow] = React.useState(false);
    const [cchide, setcchide] = React.useState(false);
    const [advanshow, setadvanshow] = React.useState(false);
    const [advanhide, setadvanhide] = React.useState(false);
    const [empshow, setempshow] = React.useState(false);
    const [emphide, setemphide] = React.useState(false);
    const [salesl, setsalesl] = React.useState();
    const [sales2, setsales2] = React.useState();
    const [sales3, setsales3] = React.useState();
    const [sales4, setsales4] = React.useState();
    const [sales5, setsales5] = React.useState();
    const [sales6, setsales6] = React.useState();
    const [sales7, setsales7] = React.useState();
    const [sales8, setsales8] = React.useState();
    const [sales9, setsales9] = React.useState();
    const [costl, setcostl] = React.useState();
    const [cost2, setcost2] = React.useState();
    const [cost3, setcost3] = React.useState();
    const [salary1, setsalary1] = React.useState();
    const [salary2, setsalary2] = React.useState();
    const [salary3, setsalary3] = React.useState();
    const [salary4, setsalary4] = React.useState();
    const [salary5, setsalary5] = React.useState();
    const [salary6, setsalary6] = React.useState();
    const [salary7, setsalary7] = React.useState();
    const [interest1, setinterest1] = React.useState();
    const [interest2, setinterest2] = React.useState();
    const [interest3, setinterest3] = React.useState();
    const [divi1, setdevi1] = React.useState();
    const [divi2, setdevi2] = React.useState();
    const [divi3, setdevi3] = React.useState();
    const [divi4, setdevi4] = React.useState();
    const [divi5, setdevi5] = React.useState();
    const [divi6, setdevi6] = React.useState();
    const [data, setdata] = React.useState({});
    const [selected, setSelected] = React.useState([]);
    const [costdirect, setdirectcost] = React.useState([]);
    const [indirectcost, setindirectcost] = React.useState([]);
    const [management, setmanagement] = React.useState([]);
    const [totalnon, settotalnon] = React.useState([]);
    const [othernon, setothernon] = React.useState([]);
    const [pbt, setpbt] = React.useState([]);
    // var data={}



    /*    const checkdata = async () => {
           const formdata = localStorage.getItem('formdata');
           const showdata = JSON.parse(formdata);
           console.log('lllll', showdata)
           await setdata(showdata)
           // data=showdata
           // console.log("uuuu",data.salesl)
   
       }; */

    const options = [
        { label: "Sales Local ", value: "SalesLocal" },
        { label: "Sales Direct Exports ", value: "SalesDirectExports" },
        { label: "Sales Deemed Exports ", value: "SalesDeemedExports" },
        { label: "Commission and Brokerage ", value: "CommissionandBrokerage" },
        { label: "Service Charges ", value: "ServiceCharges" },
        { label: "Job Work Charges ", value: "JobWorkCharges" },
        { label: "Professional Fees ", value: "ProfessionalFees" },
        { label: "Frieght Charges ", value: "FrieghtCharges" },
        { label: "Other Operating Income ", value: "OtherOperatingIncome" },
    ];

    const directcost = [
        { label: "Cost of Goods Sold", value: "CostofGoodsSold" },
        { label: "Wages & Salary", value: "Wages&Salary" },
        { label: "Direct manufacturing Cost", value: "DirectmanufacturingCost" },

    ];

    const indirectcosts = [
        { label: "Salary & Employee Benefits", value: "Salary&EmployeeBenefits" },
        { label: "Insurance Cost", value: "InsuranceCost" },
        { label: "Travelling & Conveyance", value: "Travelling&Conveyance" },
        { label: "Printing & Stationery", value: "Printing&Stationery" },
        { label: "Electricity Expenses", value: "ElectricityExpenses" },
        { label: "Selling and distribution expenses", value: "Sellinganddistributionexpenses" },

        { label: "Admintrative expenses", value: "Admintrativeexpenses" },



    ];

    const managements = [
        { label: "Interest to Partners", value: "InteresttoPartners" },
        { label: "Interest on loan to directors & family", value: "Interestonloantodirectors&family" },
        { label: "Salary to Partners/Directors", value: "SalarytoPartners/Directors" },

    ];

    const totalnons = [
        { label: "interest and Devided", value: "interestandDevided" },
        { label: "Speculation Profit", value: "SpeculationProfit" },
        { label: "Rental Income", value: "RentalIncome" },
        { label: "Profit from sae of Assets", value: "ProfitfromsaeofAssets" },
        { label: "Forex Inome(Expenses)", value: "ForexInome(Expenses)" },
        { label: "Other Income", value: "OtherIncome" },
    ];


    const othernons = [
        { label: "Depreciation", value: "Depreciation" },
        { label: "Bad Debts W/O", value: "BadDebtsW/O" },
        { label: "Preliminary expenses W/O", value: "PreliminaryexpensesW/O" },

    ];

    const pbts = [
        { label: "Interest on bank Finance", value: "InterestonbankFinance" },
        { label: "Interest on Other Loans", value: "InterestonOtherLoans" },
    ];

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClick1 = () => {
        setOpen1(!open1);
    };

    const handleshow = () => {
        if (show) {
            setshow1(true)
        }
        else { setshow(true); }
    };

    const handlehide = () => {
        if (show1)
            setshow1(false);
        else {
            setshow(false)
        }
    };

    const costshows = () => {
        if (costshow) {
            setcosthide(true)
        }
        else { setcostshow(true); }
    };

    const costhides = () => {
        if (costhide)
            setcosthide(false);
        else {
            setcostshow(false)
        }
    };

    const salaryshow = () => {
        if (salshow) {
            setsalhide(true)
        }
        else { setsalshow(true); }
    };

    const salaryhide = () => {
        if (salhide)
            setsalhide(false);
        else {
            setsalshow(false)
        }
    };

    const intshows = () => {
        if (intshow) {
            setinthide(true)
        }
        else { setintshow(true); }
    };

    const inthides = () => {
        if (inthide)
            setinthide(false);
        else {
            setintshow(false)
        }
    };

    const divishows = () => {
        if (divishow) {
            setdivihide(true)
        }
        else { setdivishow(true); }
    };

    const divihides = () => {
        if (divihide)
            setdivihide(false);
        else {
            setdivishow(false)
        }
    };

    const deprishows = () => {
        if (deprishow) {
            setdeprihide(true)
        }
        else { setdeprishow(true); }
    };

    const deprihides = () => {
        if (deprihide)
            setdeprihide(false);
        else {
            setdeprishow(false)
        }
    };

    const pbtshows = () => {
        if (pbtshow) {
            setpbthide(true)
        }
        else { setpbtshow(true); }
    };

    const pbthides = () => {
        if (pbthide)
            setpbthide(false);
        else {
            setpbtshow(false)
        }
    };

    const shareshows = () => {
        if (shareshow) {
            setsharehide(true)
        }
        else { setshareshow(true); }
    };

    const sharehides = () => {
        if (sharehide)
            setsharehide(false);
        else {
            setshareshow(false)
        }
    };

    const promshows = () => {
        if (promshow) {
            setpromhide(true)
        }
        else { setpromshow(true); }
    };

    const promhides = () => {
        if (promhide)
            setpromhide(false);
        else {
            setpromshow(false)
        }
    };

    const bankshows = () => {
        if (bankshow) {
            setbankhide(true)
        }
        else { setbankshow(true); }
    };

    const bankhides = () => {
        if (bankhide)
            setbankhide(false);
        else {
            setbankshow(false)
        }
    };

    const fixedshows = () => {
        if (fixedshow) {
            setfixedhide(true)
        }
        else { setfixedshow(true); }
    };

    const fixedhides = () => {
        if (fixedhide)
            setfixedhide(false);
        else {
            setfixedshow(false)
        }
    };

    const inventshows = () => {
        if (inventshow) {
            setinventhide(true)
        }
        else { setinventshow(true); }
    };

    const inventhides = () => {
        if (inventhide)
            setinventhide(false);
        else {
            setinventshow(false)
        }
    };

    const sundshows = () => {
        if (sundshow) {
            setsundhide(true)
        }
        else { setsundshow(true); }
    };

    const sundhides = () => {
        if (sundhide)
            setsundhide(false);
        else {
            setsundshow(false)
        }
    };

    const ccshows = () => {
        if (ccshow) {
            setcchide(true)
        }
        else { setccshow(true); }
    };

    const cchides = () => {
        if (cchide)
            setcchide(false);
        else {
            setccshow(false)
        }
    };

    const advanshows = () => {
        if (advanshow) {
            setadvanhide(true)
        }
        else { setadvanshow(true); }
    };

    const advanhides = () => {
        if (advanhide)
            setadvanhide(false);
        else {
            setadvanshow(false)
        }
    };

    const empshows = () => {
        if (empshow) {
            setemphide(true)
        }
        else { setempshow(true); }
    };

    const emphides = () => {
        if (emphide)
            setemphide(false);
        else {
            setempshow(false)
        }
    };

    const handleClick2 = () => {
        setOpen2(!open2);
    };

    const handleClick3 = () => {
        setOpen3(!open3);
    };

    const handleClick4 = () => {
        setOpen4(!open4);
    };

    const handleClick5 = () => {
        setOpen5(!open5);
    };

    const handleClick6 = () => {
        setOpen6(!open6);
    };

    const handleClick7 = () => {
        setOpen7(!open7);
    };

    const handleClick8 = () => {
        setOpen8(!open8);
    };

    const handleClick9 = () => {
        setOpen9(!open9);
    };



    const handleClick10 = () => {
        setOpen10(!open10);
    };

    const handleClick11 = () => {
        setOpen11(!open11);
    };

    const handleClick12 = () => {
        setOpen12(!open12);
    };



    const handleClick13 = () => {
        setOpen13(!open13);
    };

    const handleClick14 = () => {
        setOpen14(!open14);
    };

    const handleClick15 = () => {
        setOpen15(!open15);
    };



    return (
        <List component="nav" aria-labelledby="nested-list-subheader" subheader={
            <ListSubheader className={classes.header} component="div" id="nested-list-subheader" ><ExpandMore color='#FFF' />
                <font color='white' size='20px'> Profit And Loss Account</font>
            </ListSubheader>}>
            <Formik initialValues={{
                year1: "", year2: "", year3: "", year4: "", year5: "", year6: "", year7: "",
                year8: "", year9: "", yeara: "", yearb: "", yearc: "", yeard: "", yeare: "", yearf: "", yearg: "", yearh: "", yeari: "", yearj: "", yeark: "",
                years: "", yeart: "", yearu: "", yearv: "", yearw: "", yearx: "", yeary: "", yearz: ""
            }}//    validationSchema={FinanceFormSchema}
                onSubmit={(values) => {
                    console.log('kkkkk', values)
                    localStorage.setItem('finance', JSON.stringify(values))
                    //     handleNextClick()
                }} >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setFieldTouched /* and other goodies */ }) => {
                    console.log("values in formik", values);
                    return (
                        <form onSubmit={handleSubmit} style={{ marginLeft: -12, marginRight: -12, }}>
                            <ListItem button onClick={handleClick} className={classes.root}>
                                <ListItemIcon>{open ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Total Income" />
                            </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Row>
                                    <Col><MultiSelect options={options} value={selected} onChange={setSelected} labelledBy={"Select"} /></Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={handlehide}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={handleshow} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {selected.map((item) => {
                                            return (
                                                item.value == "SalesLocal" && (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set} >
                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Sales Local</font></Grid>
                                                            <Grid xs={9} >
                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year1" onChange={handleChange}
                                                                            onBlur={handleBlur} InputProps={{ className: values.year1 ? classes.manorma : null, }}
                                                                            error={errors.year1 && touched.year1 && errors.year1 ? true : false}
                                                                            value={values.year1}
                                                                            helperText={errors.year1 && touched.year1 && errors.year1}
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2"
                                                                                onChange={handleChange} onBlur={handleBlur} InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                                value={values.year2}
                                                                                helperText={errors.year2 && touched.year2 && errors.year2}
                                                                            //   style={{marginRight:10}}
                                                                            />)}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField id="filled-error-helper-text standard-basic" label="Year 3" name="year01"
                                                                                onChange={handleChange} onBlur={handleBlur} InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={errors.year01 && touched.year01 && errors.year01 ? true : false}
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />)}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                )
                                            )
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "SalesDirectExports" && (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set} >
                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Sales Direct Exports</font></Grid>
                                                            <Grid xs={9} >
                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                )
                                            )
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "SalesDeemedExports" && (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set} >
                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Sales Deemed Exports</font></Grid>
                                                            <Grid xs={9} >
                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                )
                                            )
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "CommissionandBrokerage" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Commission and Brokerage</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "ServiceCharges" && (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set} >
                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'>Service Charges</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}

                                        {selected.map((item) => {
                                            return (
                                                item.value == "JobWorkCharges" && (
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Job Work Charges</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "ProfessionalFees" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Professional Fees</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "FrieghtCharges" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Frieght Charges</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {selected.map((item) => {
                                            return (
                                                item.value == "OtherOperatingIncome" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Other Operating Income</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>
                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {show1 == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year01"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year01 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year01 &&
                                                                                        touched.year01 &&
                                                                                        errors.year01
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year01}
                                                                                helperText={
                                                                                    errors.year01 &&
                                                                                    touched.year01 &&
                                                                                    errors.year01
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                    </Col>
                                </Row>
                            </Collapse>

                            <ListItem button onClick={handleClick1}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open1 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Direct Cost" />
                            </ListItem>
                            <Collapse in={open1} timeout="auto" unmountOnExit>
                                <Row>

                                    <Col>
                                        <MultiSelect
                                            options={directcost}
                                            value={costdirect}
                                            onChange={setdirectcost}
                                            labelledBy={"Select"}
                                        />
                                    </Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={costhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={costshows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {costdirect.map((item) => {

                                            return (
                                                item.value == "CostofGoodsSold" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'>  Cost of Goods Sold</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />

                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {costdirect.map((item) => {

                                            return (
                                                item.value == "Wages&Salary" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'>  Wages & Salary</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />

                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {costdirect.map((item) => {

                                            return (
                                                item.value == "DirectmanufacturingCost" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'>  Direct manufacturing Cost</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />

                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {costhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                    </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick2}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open2 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Indirect Cost" />
                            </ListItem>
                            <Collapse in={open2} timeout="auto" unmountOnExit>
                                <Row>

                                    <Col>
                                        <MultiSelect
                                            options={indirectcosts}
                                            value={indirectcost}
                                            onChange={setindirectcost}
                                            labelledBy={"Select"}
                                        />
                                    </Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={salaryhide}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={salaryshow} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "Salary&EmployeeBenefits" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Salary & Employee Benefits</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "InsuranceCost" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Insurance Cost</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />


                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}

                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "Travelling&Conveyance" && (


                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'> Travelling & Conveyance</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "Printing&Stationery" && (

                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='17px'> Printing & Stationery</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>

                                                ))
                                        })}

                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "ElectricityExpenses" && (


                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='17px'> Electricity Expenses</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />)}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>


                                                ))
                                        })}

                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "Sellinganddistributionexpenses" && (

                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='17px'> Selling and distribution expenses</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "Admintrativeexpenses" && (


                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='17px'> Admintrative expenses</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year2"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year2 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year2}
                                                                            helperText={
                                                                                errors.year2 &&
                                                                                touched.year2 &&
                                                                                errors.year2
                                                                            }
                                                                        //   style={{marginRight:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {salhide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year2"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year2 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year2 &&
                                                                                        touched.year2 &&
                                                                                        errors.year2
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year2}
                                                                                helperText={
                                                                                    errors.year2 &&
                                                                                    touched.year2 &&
                                                                                    errors.year2
                                                                                }
                                                                            //   style={{marginRight:10}}
                                                                            />
                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                    </Col>
                                </Row>

                            </Collapse>

                            <ListItem button onClick={handleClick3}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open3 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Management Renumeration" />
                            </ListItem>
                            <Collapse in={open3} timeout="auto" unmountOnExit>
                                <Row>

                                    <Col>
                                        <MultiSelect
                                            options={managements}
                                            value={management}
                                            onChange={setmanagement}
                                            labelledBy={"Select"}
                                        />
                                    </Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={inthides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={intshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {management.map((item) => {

                                            return (
                                                item.value == "InteresttoPartners" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='#7254A3' size='18px'>Interest to Partners</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {intshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 1"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {inthide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 1"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}

                                        {management.map((item) => {

                                            return (
                                                item.value == "Interestonloantodirectors&family" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Interest on loan to directors & family</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>



                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {intshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {inthide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        }
                                        )}
                                        {management.map((item) => {

                                            return (
                                                item.value == "SalarytoPartners/Directors" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Salary to Partners/Directors</font></Grid>
                                                            <Grid xs={9} >
                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {intshow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 1"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {inthide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 1"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                    </Col>
                                </Row>

                            </Collapse>
                            <ListItem button onClick={handleClick4}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open4 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Total Non Operating Income" />
                            </ListItem>
                            <Collapse in={open4} timeout="auto" unmountOnExit>
                                <Row>

                                    <Col>
                                        <MultiSelect
                                            options={totalnons}
                                            value={totalnon}
                                            onChange={settotalnon}
                                            labelledBy={"Select"}
                                        />
                                    </Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={divihides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={divishows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {totalnon.map((item) => {

                                            return (
                                                item.value == "interestandDevided" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> interest and Devided  </font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {totalnon.map((item) => {

                                            return (
                                                item.value == "SpeculationProfit" && (

                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Speculation Profit</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {totalnon.map((item) => {

                                            return (
                                                item.value == "RentalIncome" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Rental Income</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>


                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}

                                        {totalnon.map((item) => {

                                            return (
                                                item.value == "ProfitfromsaeofAssets" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >


                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='17px'> Profit from sae of Assets</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}

                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "ForexInome(Expenses)" && (


                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Forex Inome(Expenses)</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>

                                                ))
                                        })}
                                        {indirectcost.map((item) => {

                                            return (
                                                item.value == "OtherIncome" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Other Income</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>



                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {divishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {divihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                    </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick5}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open5 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Other Non-Cash Expenses" />
                            </ListItem>
                            <Collapse in={open5} timeout="auto" unmountOnExit>
                                <Row>

                                    <Col>
                                        <MultiSelect
                                            options={othernons}
                                            value={othernon}
                                            onChange={setothernon}
                                            labelledBy={"Select"}
                                        />
                                    </Col>
                                </Row>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={deprihides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={deprishows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        {othernon.map((item) => {

                                            return (
                                                item.value == "Depreciation" && (
                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Depreciation  </font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>


                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {deprishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {deprihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        {othernon.map((item) => {

                                            return (
                                                item.value == "BadDebtsW/O" && (

                                                    <List component="div" disablePadding>

                                                        <ListItem className={classes.pad_set} >

                                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Bad Debts W/O</font></Grid>
                                                            <Grid xs={9} >

                                                                <List component="div" disablePadding>
                                                                    <ListItem className={classes.pad_set}>

                                                                        <TextField
                                                                            id="filled-error-helper-text standard-basic "
                                                                            label="Year 1"
                                                                            name="year1"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            InputProps={{
                                                                                className: values.year1 ? classes.manorma : null,
                                                                            }}
                                                                            error={
                                                                                errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            value={values.year1}
                                                                            helperText={
                                                                                errors.year1 &&
                                                                                touched.year1 &&
                                                                                errors.year1
                                                                            }
                                                                        // style={{marginLeft:10}}
                                                                        />
                                                                        <div style={{ width: '50px' }}></div>
                                                                        {deprishow == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 2"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />
                                                                        )}
                                                                        <div style={{ width: '50px' }}></div>

                                                                        {deprihide == true && (
                                                                            <TextField
                                                                                id="filled-error-helper-text standard-basic "
                                                                                label="Year 3"
                                                                                name="year1"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                InputProps={{
                                                                                    className: values.year1 ? classes.manorma : null,
                                                                                }}
                                                                                error={
                                                                                    errors.year1 &&
                                                                                        touched.year1 &&
                                                                                        errors.year1
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={values.year1}
                                                                                helperText={
                                                                                    errors.year1 &&
                                                                                    touched.year1 &&
                                                                                    errors.year1
                                                                                }
                                                                            // style={{marginLeft:10}}
                                                                            />

                                                                        )}
                                                                    </ListItem>
                                                                </List>

                                                            </Grid>
                                                        </ListItem>
                                                    </List>
                                                ))
                                        })}
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Preliminary expenses W/O</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>

                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year1 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year1 &&
                                                                        touched.year1 &&
                                                                        errors.year1
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year1}
                                                                helperText={
                                                                    errors.year1 &&
                                                                    touched.year1 &&
                                                                    errors.year1
                                                                }
                                                            // style={{marginLeft:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {deprishow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year1"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year1 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year1 &&
                                                                            touched.year1 &&
                                                                            errors.year1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year1}
                                                                    helperText={
                                                                        errors.year1 &&
                                                                        touched.year1 &&
                                                                        errors.year1
                                                                    }
                                                                // style={{marginLeft:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>

                                                            {deprihide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year1"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year1 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year1 &&
                                                                            touched.year1 &&
                                                                            errors.year1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year1}
                                                                    helperText={
                                                                        errors.year1 &&
                                                                        touched.year1 &&
                                                                        errors.year1
                                                                    }
                                                                // style={{marginLeft:10}}
                                                                />
                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick6}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open6 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="PBT" />
                            </ListItem>
                            <Collapse in={open6} timeout="auto" unmountOnExit>

                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={pbthides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={pbtshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Interest on bank Finance</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {pbtshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {pbthide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Interest on Other Loans</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>



                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {pbtshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {pbthide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Col>
                                </Row>
                            </Collapse>

                            <ListSubheader
                                className={classes.header}
                                component="div" id="nested-list-subheader" >
                                <ExpandMore color='#FFF' />
                                <font color='white' size='20px'> Balance Sheet</font>
                            </ListSubheader>


                            <ListItem button onClick={handleClick7}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open7 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="NewWorth" />
                            </ListItem>
                            <Collapse in={open7} timeout="auto" unmountOnExit>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={sharehides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={shareshows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set}>
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Share Capital  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange}
                                                            onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {shareshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sharehide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year3" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>

                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Res & Surplus</font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange}
                                                            onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {shareshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sharehide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year3" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Miscellaneous Exp</font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange}
                                                            onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {shareshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        )}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sharehide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year3" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Deffered Tax Liab (Asset)</font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange}
                                                            onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {shareshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        )}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sharehide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year3" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick8}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open8 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Total Promoter Funds" />
                            </ListItem>
                            <Collapse in={open8} timeout="auto" unmountOnExit>

                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={promhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={promshows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Loan to Promoters / relatives </font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>
                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {promshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {promhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>

                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Loans from Promoers/relatives</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>

                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {promshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {promhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Investment In Group Companies</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>



                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {promshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {promhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Advanced to Related Parties</font></Grid>
                                                <Grid xs={9} >
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {promshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                            <div style={{ width: '50px' }}></div>
                                                            {promhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick9}
                                className={classes.root}
                            >
                                <ListItemIcon>
                                    {open9 ? <ExpandLess /> : <ChevronRight />}
                                </ListItemIcon>
                                <ListItemText primary="Total Outside Debts" />
                            </ListItem>
                            <Collapse in={open9} timeout="auto" unmountOnExit>

                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={bankhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={bankshows} > Show</button>
                                    </Grid>
                                </Grid>

                                <Row>
                                    <Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                        <List component="div" disablePadding>
                                            <ListItem className={classes.pad_set} >
                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Bank Debts-Terms Loans </font></Grid>
                                                <Grid xs={9} >
                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {bankshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>


                                                            {bankhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year3"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>


                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Bank Debts-LAP </font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {bankshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>


                                                            {bankhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year3"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>


                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Bank Debts-Unsecured Loans </font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {bankshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>


                                                            {bankhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year3"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>


                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Vehicle Loans </font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {bankshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>


                                                            {bankhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year3"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>



                                        <List component="div" disablePadding>

                                            <ListItem className={classes.pad_set} >

                                                <Grid xs={3}><font className={classes.listitem} color='black' size='20px'>Loan from Others</font></Grid>
                                                <Grid xs={9} >

                                                    <List component="div" disablePadding>
                                                        <ListItem className={classes.pad_set}>


                                                            <TextField
                                                                id="filled-error-helper-text standard-basic "
                                                                label="Year 1"
                                                                name="year2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                InputProps={{
                                                                    className: values.year2 ? classes.manorma : null,
                                                                }}
                                                                error={
                                                                    errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={values.year2}
                                                                helperText={
                                                                    errors.year2 &&
                                                                    touched.year2 &&
                                                                    errors.year2
                                                                }
                                                            //   style={{marginRight:10}}
                                                            />
                                                            <div style={{ width: '50px' }}></div>
                                                            {bankshow == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 2"
                                                                    name="year2"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />
                                                            )}
                                                            <div style={{ width: '50px' }}></div>


                                                            {bankhide == true && (
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic "
                                                                    label="Year 3"
                                                                    name="year3"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        className: values.year2 ? classes.manorma : null,
                                                                    }}
                                                                    error={
                                                                        errors.year2 &&
                                                                            touched.year2 &&
                                                                            errors.year2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={values.year2}
                                                                    helperText={
                                                                        errors.year2 &&
                                                                        touched.year2 &&
                                                                        errors.year2
                                                                    }
                                                                //   style={{marginRight:10}}
                                                                />

                                                            )}
                                                        </ListItem>
                                                    </List>

                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Col>
                                </Row>

                            </Collapse>
                            <ListItem button onClick={handleClick10} className={classes.root}>
                                <ListItemIcon> {open10 ? <ExpandLess /> : <ChevronRight />} </ListItemIcon>
                                <ListItemText primary="Non-Current Assets" />
                            </ListItem>
                            <Collapse in={open10} timeout="auto" unmountOnExit>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={fixedhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={fixedshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Fixed Assests  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange} onBlur={handleBlur}
                                                                InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        )}
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedhide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year2" onChange={handleChange} onBlur={handleBlur}
                                                                InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        )}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>


                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Investments  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2"
                                                            onChange={handleChange} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2"
                                                                onChange={handleChange} onBlur={handleBlur}
                                                                InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedhide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year2"
                                                                onChange={handleChange} onBlur={handleBlur}
                                                                InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2} />)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Long Term Deposits  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={handleChange}
                                                            onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                            error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2}
                                                            helperText={errors.year2 && touched.year2 && errors.year2}
                                                        //   style={{marginRight:10}}
                                                        />
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedshow == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 2" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2}
                                                            //   style={{marginRight:10}}
                                                            />
                                                        )}
                                                        <div style={{ width: '50px' }}></div>
                                                        {fixedhide == true && (
                                                            <TextField id="filled-error-helper-text standard-basic " label="Year 3" name="year2" onChange={handleChange}
                                                                onBlur={handleBlur} InputProps={{ className: values.year2 ? classes.manorma : null, }}
                                                                error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                                value={values.year2}
                                                                helperText={errors.year2 && touched.year2 && errors.year2}
                                                            //   style={{marginRight:10}}
                                                            />
                                                        )}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick11} className={classes.root}>
                                <ListItemIcon>{open11 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Working Capital Assets" /></ListItem>
                            <Collapse in={open11} timeout="auto" unmountOnExit>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={inventhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={inventshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Inventory  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi1(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventshow == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventhide == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Debtors Less 6 mths  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventshow == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventhide == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Debtors exceeding 6 mths  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventshow == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {inventhide == true && (<TextField id="filled-error-helper-text standard-basic " margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick12} className={classes.root}>
                                <ListItemIcon>{open12 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Working Capital Liabilities" />
                            </ListItem>
                            <Collapse in={open12} timeout="auto" unmountOnExit>
                                <Grid container><Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={sundhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={sundshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Sundry Creditors  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi1(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {sundshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sundhide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Customer Advances  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {sundshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {sundhide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick13} className={classes.root}>
                                <ListItemIcon>{open13 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Sources of Working capital" />
                            </ListItem>
                            <Collapse in={open13} timeout="auto" unmountOnExit>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={cchides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={ccshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> CC / Overdraft  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi1(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {ccshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {cchide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>

                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Bills Discounting  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {ccshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {cchide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Other short term facilities  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {ccshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {cchide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>
                            <ListItem button onClick={handleClick14}
                                className={classes.root}>
                                <ListItemIcon>{open14 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Other Current Assets" />
                            </ListItem>
                            <Collapse in={open14} timeout="auto" unmountOnExit>
                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={advanhides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={advanshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Loans and Advances  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi1(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanhide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>

                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Advance tax and Tax deducted at source  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanhide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>

                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Cash and bank balances  </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {advanhide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>

                            <ListItem button onClick={handleClick15} className={classes.root}>
                                <ListItemIcon> {open15 ? <ExpandLess /> : <ChevronRight />}</ListItemIcon>
                                <ListItemText primary="Other Current Liabilities" />
                            </ListItem>
                            <Collapse in={open15} timeout="auto" unmountOnExit>

                                <Grid container>
                                    <Grid xs={10}></Grid>
                                    <Grid xs={2}>
                                        <button className={classes.listitems} color='black' size='5px' onClick={emphides}> Hide</button>
                                        <button className={classes.listitems1} color='black' size='5px' onClick={empshows} > Show</button>
                                    </Grid>
                                </Grid>
                                <Row><Col md={{ span: 12 }} style={{ background: '#fff' }}>
                                    <List component="div" disablePadding>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Employee Benefit Payable </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi1(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {empshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {emphide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>

                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Capital Assets Creditors </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {empshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {emphide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Other Payables </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {empshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {emphide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                        <ListItem className={classes.pad_set} >
                                            <Grid xs={3}><font className={classes.listitem} color='black' size='20px'> Provision </font></Grid>
                                            <Grid xs={9} >
                                                <List component="div" disablePadding>
                                                    <ListItem className={classes.pad_set}>
                                                        <TextField id="filled-error-helper-text standard-basic " label="Year 1" name="year2" onChange={e => setdevi6(e.target.value)} onBlur={handleBlur}
                                                            InputProps={{ className: values.year2 ? classes.manorma : null, }} error={errors.year2 && touched.year2 && errors.year2 ? true : false}
                                                            value={values.year2} helperText={errors.year2 && touched.year2 && errors.year2} />
                                                        <div style={{ width: '50px' }}></div>
                                                        {empshow == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 2"></TextField>)}
                                                        <div style={{ width: '50px' }}></div>
                                                        {emphide == true && (
                                                            <TextField className={classes.textField} margin="normal" label="year 3"></TextField>)}
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Col>
                                </Row>
                            </Collapse>

                            <div className='btn_row_fixd'>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Row>
                                            <Col md={{ span: 6 }}></Col>
                                            <Col md={{ span: 3 }}><Button className={classes.linkdin} variant="contained" style={{ width: '100%' , padding: '6px'}} color="primary" >Submit</Button></Col>
                                            <Col md={{ span: 3 }}><Button className={classes.button_div} variant="outlined" style={{ width: '100%' , padding: '6px'}} color="primary">Reset</Button></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>   
                        </form>
                    );
                }}
            </Formik >
        </List >
    );
}