import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
    listitem:{
        color:'black',
        fontSize:'18px',
        fontWeight:'500',
    },
    listitems:{
        color:'black',
        fontSize:'18px',
        fontWeight:'500',
        marginLeft:20,
        
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 230,
        width:10
      },
    Button: {
        showIcon:true,
    },

    textfield: {
        width:'100%'
    },

    linkdin:{
        backgroundColor: '#007bff',
        textTransform: 'capitalize',
        margin: '10px',
        padding: '9px',
        width: '50%',
        fontSize:18,
        marginLeft:10
    },
    button_div: {
        textTransform: 'capitalize',
        margin: '11px',
        padding: '9px',
        width: '61%',
        fontSize:18
    },
    manorma: {
        '&:before': {
          borderBottom: "1.5px solid #00ff00 !important",
        }
      },
      required: {
        border: "1.5px solid red !important",
      },
      man: {
        border: "1.5px solid #00ff00 !important",
      },
      mano: {
        border: "1.5px solid #00ff00 !important",
        color:'#777777'
      },
  }));