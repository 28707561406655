import React, { useState } from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typography from "../../base_components/Typography";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import {FormControlLabel, Switch} from '@material-ui/core';
import { TextField, Button, Grid } from '@material-ui/core';
import { useStyled } from "./styles";
import Back from '../../image/buttons/back.png';
import DraftNext from '../../image/buttons/draftnext.png';
import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import { FormikTextField } from 'formik-material-fields';
import MuiAccordion from '@material-ui/core/Accordion';
import { SnackPosition } from "../../shared/constants";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MultiSelect from "react-multi-select-component";
import Loader from 'react-loader-spinner';
import { withStyles } from '@material-ui/core/styles';
import { LoaderColor } from '../../shared/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import InputOtp from "@onefifteen-z/react-input-otp";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogActions from '@material-ui/core/DialogActions';


import grayback from '../../image/buttons/grayback.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import uploaddoc from '../../image/pngicons/upload.png';
import digitalpull from '../../image/pngicons/digitalpull.png';
import { Alert, AlertTitle } from '@material-ui/lab';
import DocumentUpload from "../../components/DocumentUpload";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import Paper from "@material-ui/core/Paper";
import SearchBar from "material-ui-search-bar";
import OtpTimer from "otp-timer";
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css';
import Modal from "react-bootstrap/Modal";

import FileViewer from 'react-file-viewer';
import { itrForm } from "../../shared/validations";
import { Dropdown } from "semantic-ui-react";


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
//const classes1 = useStyled();



export default function PersonalInfo(props) {
  const classes = useStyled();
  const [loaderFlag, setLoaderFlag] = React.useState(true);
  const [header, setHeader] = React.useState(true);
  const [showBusinessPromoterList, setShowBusinessPromoterList] = React.useState(false);
  const [businessList, setBusinessList] = React.useState([]);
  const [promoterList, setPromoterList] = React.useState([]);
  const [showPromoterTable, setShowPromoterTable] = React.useState(false);

  const [snackBarMsg, setSnackBarMsg] = React.useState();

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState();
  const [format, setFormat] = React.useState("success");

  const [colorMessage, setcolorMessage] = React.useState();
  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }

  const table_Action = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  }
  const table_noborder = {
    borderBottom: "1px solid #fff"
  }
  const comment = {
    color: "mediumblue",
    fontSize: "12px",
    paddingBottom: "3px",
    fontWeight: 600,
    fontStyle: "italic",
    textAlign: "right",

  }

  const [showExtendedForm, setShowExtendedForm] = React.useState(false);
  const [parentInfoData, setParentInfoData] = React.useState({});
  const [financeParameterDetails, setFinanceParameterDetails] = React.useState([]);
  const [financeDetailsDynamicInitialValue, setFinanceDetailsDynamicInitialValue] = React.useState({});
  const [noOfColumn, setNoOfColumn] = React.useState([]);
  const [showConMsg, setShowConMsg] = useState(true);
  const [otpBtn, setOtpBtn] = useState(false);
  const [verifiedIcon, setVerifiedIcon] = useState(false);
  const [mobile, setMobile] = useState();
  const [otpMinutes, setOtpMinutes] = useState();
  const [otpSeconds, setOtpSeconds] = useState();
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false);
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true);
  const [mobileExistsMsg, setMobileExistMmsg] = useState();
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false);
  const [shareEnable, setShareEnable] = useState(true);
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false);
  const [disable, setDisable] = React.useState({
    disabled: true,
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleChangeAccro = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const [innerExpanded, setInnerExpanded] = React.useState(false);
  const handleChangeInnerAccro = (panel) => (event, isExpanded) => {
    setInnerExpanded(isExpanded ? panel : false);
    handleManualDataAdd1();
  };

  const [formDisabled, setFormDisabled] = React.useState(false);
  const role_id = JSON.parse(localStorage.getItem('userdata'));

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    if (role_id.role_id == 5) {
      setFormDisabled(true);
    }
    //
    getBusinessAndPromoterListByUser();

    //  detailsDocument();

    getNoOfColumnOrYear();
    getExtendedFinanceHeader();
    checkIfRequest();
  }, []);

  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }
  /// masking code



  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
  let loanType = localData.business_channel_id;

  const getNoOfColumnOrYear = async () => {
    //
    let data = {
      'loanType': localData.business_channel_id
    }
    console.log(loanType);
    await axios.post(apiCalling.coderscorner + 'server.php/api/getLast3FinanceYear', data).then((result) => {
      console.log(result);
      if (result.data.status == true) {
        setNoOfColumn(result.data.data);
      }
    })
  }
  const getBusinessAndPromoterListByUser = async () => {
    //
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    const data = {
      user_id: checkuserid.user_id
    };
    let api_path = apiCalling.coderscorner + 'server.php/api/getFinanceDetailsBusinessAndPromoterById';
    await axios.post(apiCalling.coderscorner + 'server.php/api/getFinanceDetailsBusinessAndPromoterById', data).then((result) => {
      //
      setShowBusinessPromoterList(true);
      setLoaderFlag(false);
      setBusinessList(result.data.data.businessList);
      setNewBus(result.data.data.businessList);
      if (result.data.data.promoterList.length > 0) {
        setShowPromoterTable(true)
        setPromoterList(result.data.data.promoterList);
        setNewPro(result.data.data.promoterList);
      } else {
        setShowPromoterTable(false)
      }
    }).catch((error) => {
      ErrorCatch(checkuserid.user_id, error, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
      ////////console.log(error);
    });
  }


  const maskEmail = (memail) => {
    if (memail != null) {
      let mail = memail;
      mail = mail.split('');
      let maskedArray = [];
      let length = mail.indexOf('@');
      mail.map((item, i) => {
        (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(mail[i]);
      })
      let finalEmail = maskedArray.join('');
      //setemail(finalEmail);
      // console.log(email);
      return finalEmail;
    }

  }

  const maskMobile = (mmobile) => {
    if (mmobile != null) {
      let mbl = mmobile;
      let mblArray = mbl.split('');
      let maskedArray = [];
      let len = mblArray.length;
      mblArray.map((item, i) => {
        (i == 1 || i == 0 || i == len - 1) ? maskedArray.push(mblArray[i]) : maskedArray.push('*');
      })
      let finalMobile = maskedArray.join('');
      // setmobile(finalMobile);
      return finalMobile;
    }
  }

  const maskName = (mname) => {
    if (mname != null) {
      let name = mname;
      name = name.split('');
      let maskedArray = [];
      //  let length = name.indexOf('@');
      let length = name.length;
      name.map((item, i) => {
        (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(name[i]);
      })
      let finalName = maskedArray.join('');
      // setName(finalEmail);
      // console.log(finalName);
      return finalName

    }
  }
  const maskBpan = (mbpan) => {
    //
    if (mbpan != null) {
      let bpan = mbpan;
      bpan = bpan.split('');
      let maskedArray = [];
      //let length = bpan.indexOf('@');
      let length = bpan.length;
      bpan.map((item, i) => {
        (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(bpan[i]);
      })
      let finalBpan = maskedArray.join('');
      //setBpan(finalEmail);
      //console.log(finalBpan);
      return finalBpan;
    }

  }
  const maskGst = (mgst) => {
    if (mgst != null) {
      let gst = mgst;
      gst = gst.split('');
      let maskedArray = [];
      let len = gst.length;
      gst.map((item, i) => {
        (i == 1 || i == 0 || i == len - 1) ? maskedArray.push(gst[i]) : maskedArray.push('*');
      })
      let finalGst = maskedArray.join('');
      //setGst(finalMobile);
      return finalGst;
    }

  }

  const getExtendedFinanceHeader = async () => {
    await axios.get(apiCalling.coderscorner + 'server.php/api/getFinanceHeaderParameterDetails').then((result) => {
      setFinanceParameterDetails(result.data.data);
      //let initialObject = '{';
      let obj = '{';
      for (let i = 0; i < result.data.data.length; i++) {
        for (let j = 0; j < result.data.data[i].headerDetails.length; j++) {
          obj += '"selected' + result.data.data[i].headerDetails[j].parameter_level_1 + '":[],';
          obj += '"' + result.data.data[i].headerDetails[j].parameter_level_1 + '":[],';
        }
      }
      obj = obj.slice(0, -1);
      obj += '}';
      setFinanceDetailsDynamicInitialValue(JSON.parse(obj));
    })
  }



  const [docPath, setDocPath] = React.useState("");
  const [docType, setDocType] = React.useState("");

  const getDocForPreview = (busOrPro, ptId) => {
   
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let data = {
      "id": 2,
      "user_id": checkuserid.user_id,
      "parent_id": ptId,
      "parent_type_id": busOrPro,
      "form": "1.1.3",
      "required_phase": "172"
    }
    axios.post(apiCalling.coderscorner + 'server.php/api/findDocsforPreview', data).then(result => {
      console.log(result);
      let docPat = result.data.data[0].documentArray[0].docPath;
      let fileType = result.data.data[0].documentArray[0].file_name.split(".");
      setDocType(fileType[1]);

      setDocPath(docPat);
    }).catch(error => {
      console.log(error);
    });
  }

  const [documentArray, setDocumentArray] = React.useState([]);
  const getUploadeDocOfUserV2 = async (busOrPro, ptId) => {
    ;
    const docdata = {
      user_id: checkuserid.user_id,
      parent_id: ptId,
      parent_type_id: busOrPro,
      form: "1.1.3",
      required_phase: "172",
      interval_flag: ''
    }
    let myArr = [];
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser';
    let apiPath2 = apiCalling.coderscorner + 'server.php/api/findDocsOfUserV2';

    await axios.post(apiPath2, docdata).then((result) => {
      console.log("rsutl-----------------", result);
      
      console.log("my document array----->", myArr);
      if (result.data.status) {
        let tempArr = result.data.data;
        let resultArr = [];
        if (tempArr.length >= 1) {
          tempArr.map(docchecklist => {
            if (docchecklist.documentArray.length > 0) {
              docchecklist.documentArray.map((docs,i) => {
                if (docs.file_name != '' && docs.file_name != undefined && docs.file_name != null) {
                  let obj ={text:docs.file_name, value:docs.base64String,file_type:docs.file_type}
                  resultArr.push(obj);
                }
              })
            }
          })
        }
        
        setDocumentArray(resultArr);
        let fileType = resultArr[0].file_type;
        let fileExt = fileType.split('/');
        if (fileExt[1] == "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          setDocType('xlsx');
        } else {
          setDocType(fileExt[1]);
        }
        setDocPath(resultArr[0].value);
      }

    }).catch(error => {
      ErrorCatch(1, error, api_path, 'post', docdata, 1, 1);
      console.log(error);
    })
  }






  const [promoOrBus, setPromoOrBus] = React.useState("");
  const [parent_id1, setParent_id] = React.useState('');
  const [parent_name, setParent_name] = React.useState("");

  const handleManualDataAdd = async (item, promoOrBus) => {
    //
    setPromoOrBus(promoOrBus);
    setParent_id(item.id);
    setParent_name(item.name);
    setLoaderFlag(true);
    ////
    setHeader(false);
    let ptId = promoOrBus;
    let parentData = {
      'parent_type_id': ptId,
      'parent_id': item.id,
      'parent_name': item.name
    }
    //
    setParentInfoData(parentData);
    await axios.post(apiCalling.coderscorner + `server.php/api/getFinanceHeaderParameterDetailsByParentId`, parentData).then(result => {
      if (result.data.status == true) {
        console.log(result);
        setFinanceParameterDetails(result.data.data);
        let obj = '{';
        for (let i = 0; i < result.data.data.length; i++) {
          for (let j = 0; j < result.data.data[i].headerDetails.length; j++) {
            obj += '"selected' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameter) + ',';
            obj += '"' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameterValue) + ',';
          }
        }
        obj = obj.slice(0, -1);
        obj += '}';
        setFinanceDetailsDynamicInitialValue(JSON.parse(obj));
        setLoaderFlag(false);
        setShowExtendedForm(true);
        setHeader(false);
        setShowBusinessPromoterList(false);
      }
      else {
        setLoaderFlag(false);
        setShowExtendedForm(true);
        setShowBusinessPromoterList(false);
        getExtendedFinanceHeader();
      }
    });
   // getDocForPreview(promoOrBus, item.id);
    getUploadeDocOfUserV2(promoOrBus, item.id);
  }


  const handleManualDataAdd1 = async (item) => {
    ////
    // setLoaderFlag(true)
    ////
    // setHeader(false);
    // let ptId = promoOrBus;
    let parentData = {
      'parent_type_id': promoOrBus,
      'parent_id': parent_id1,
      'parent_name': parent_name
    }
    setParentInfoData(parentData);
    await axios.post(apiCalling.coderscorner + `server.php/api/getFinanceHeaderParameterDetailsByParentId`, parentData).then(result => {
      if (result.data.status == true) {
        setFinanceParameterDetails(result.data.data);
        let obj = '{';
        for (let i = 0; i < result.data.data.length; i++) {
          for (let j = 0; j < result.data.data[i].headerDetails.length; j++) {
            obj += '"selected' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameter) + ',';
            obj += '"' + result.data.data[i].headerDetails[j].parameter_level_1 + '":' + JSON.stringify(result.data.data[i].headerDetails[j].selectedParameterValue) + ',';
          }
        }
        obj = obj.slice(0, -1);
        obj += '}';
        setFinanceDetailsDynamicInitialValue(JSON.parse(obj));
        setLoaderFlag(false);
        setShowExtendedForm(true);
        setHeader(false);
        setShowBusinessPromoterList(false);


      }
      else {
        setLoaderFlag(false);
        setShowExtendedForm(true);
        setShowBusinessPromoterList(false);
        getExtendedFinanceHeader();
      }
    })
  }


  const handleSetInitialValue = (iitem, data, oldData) => {
    let field = "selected" + iitem.parameter_level_1;
    var arrayData = [];
    for (let i = 0; i < data.length; i++) {
      let obj;
      if (oldData.length > 0) {
        for (let j = 0; j < oldData.length; j++) {
          if (oldData[j].id == data[i].value) {
            obj = oldData[j];
            break;
          } else {
            let objString = '{"id":' + data[i].value + ',"display_parameter_level_2":"' + data[i].label + '",';
            for (let k = 0; k < noOfColumn.length; k++) {
              objString += '"year' + (k + 1) + '":"' + noOfColumn[k] + '",';
              objString += '"year' + (k + 1) + 'value":"",';
            }
            objString = objString.slice(0, -1);
            objString += '}';
            obj = JSON.parse(objString);
          }
        }
      } else {
        let objString = '{"id":' + data[i].value + ',"display_parameter_level_2":"' + data[i].label + '",';
        for (let k = 0; k < noOfColumn.length; k++) {
          objString += '"year' + (k + 1) + '":"' + noOfColumn[k] + '",';
          objString += '"year' + (k + 1) + 'value":"",';
        }
        objString = objString.slice(0, -1);
        objString += '}';
        obj = JSON.parse(objString);
      }

      arrayData.push(obj);
    }
    let field1 = iitem.parameter_level_1;
    setFinanceDetailsDynamicInitialValue({ ...financeDetailsDynamicInitialValue, [field]: data, [field1]: arrayData });
  }


  const handleChangeFinanceValue = (e, iitem, k, yearj, values) => {
    ////
    console.log(e);
    console.log(iitem);
    console.log(k, yearj);



    let field = iitem.parameter_level_1;
    let arr = values[iitem.parameter_level_1][k];
    // let field1 ="";
    // let obj = arr[];

    console.log(field);
    console.log(arr);


    let inti = financeDetailsDynamicInitialValue;

    //  setFinanceDetailsDynamicInitialValue({ ...financeDetailsDynamicInitialValue, [field]: data, [field1]: arrayData );


  }


  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));

  const handleSubmitExtendedForm = async (values) => {
    ////

    let data = {
      'parent_type_id': parentInfoData.parent_type_id,
      'parent_id': parentInfoData.parent_id,
      'eligibility_flag': 0,
      'user_id': checkuserid.user_id,
      'result': values
    }
    //console.log(data);
    let api_path = apiCalling.coderscorner + `server.php/api/saveFinanceDetails`;
    await axios.post(apiCalling.coderscorner + `server.php/api/saveFinanceDetails`, data)
      .then(res => {
        if (res.data.status == true) {
          //  setShowExtendedForm(false);
          //  setShowBusinessPromoterList(true);
          // setHeader(true);
          // getExtendedFinanceHeader();
          // getBusinessAndPromoterListByUser();
          setcolorMessage("success");
          setSnackBarMsg(res.data.message);
          setsavenextmessage(true);
        }
      })
      .catch(er => {
        ErrorCatch(checkuserid.user_id, er, api_path, 'post', data, checkuserid.user_id, checkuserid.user_id);
        ////////console.log("no data sorry ", er);
      });
    props.handleCount(requestDetails.user_id);
  }

  const handledata = () => {
    if (props.checkforrequest === "isRequestAvailable") {
      props.handleBack(1);
    } else {
      props.handleBack();
    }
  };

  const SaveasDraft = () => {
    handleSubmiting();
  }

  const handleSubmiting = () => {
    props.handlecheck();
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setOtpBtn(false);
    setShowConMsg(true);
  }

  const [itrDetailsData, setItrDetailsData] = React.useState({
    userName: '',
    password: ''
  });

  const [openCredentialForm, setOpenCredentialForm] = React.useState(false);
  const handleOpenCredentialForm = () => {
    setOpenCredentialForm(true);
  }
  const handleCloseOpenCredentialForm = () => {
    setOpenCredentialForm(false);
    setOtpBtn(false);
    setOpen(false);
  }

  const [resutestIdItr, setRequestIdItr] = React.useState("");

  const handleCallITRAPI = async (values) => {
    //
    setCredLoader(true);
    let consent = values.userName + "|" + values.password;
    console.log(consent);
    let postUrl = apiCalling.thirdParty + 'server.php/apikarz';
    let data = {
      "app_name": "ITRBUSINESSAPI",
      "in_data": consent,
      "create": checkuserid.user_id,
      "modify": checkuserid.user_id
    }
    await axios.post(postUrl, data).then((result) => {
      //////console.log(result);
      //
      if (result) {
        console.log(result);
        if (result.data.result.length === 0) {
          setCredLoader(false);
          setSnackBarMsg("Invalid Credentials");
          setFormat("error");
          setsavenextmessage(true);
        } else {
          //setRequestIdItr(result.data);
          saveItrResult(result.data.result);
          getBusinessAndPromoterListByUser();
          handleCloseOpenCredentialForm(false);
        }
      }
    }).catch(error => {
      setCredLoader(false);
      snackBarMsg("Something went Wrong");
      savenextmessage(true);
    });
  }

  const [credLoader, setCredLoader] = React.useState(false);


  const saveItrResult = async (json) => {
    let data = {
      "user_id": checkuserid.user_id,
      "parent_id": parentId,
      "eligibility_flag": 0,
      "parent_type_id": parentTypeId,
      "result": json
    }
    let url = apiCalling.coderscorner + 'server.php/api/captureITR3rdPartyResult';
    await axios.post(url, data).then(result => {
      if (result.data.status) {
        console.log(result);
        setCredLoader(false);
        setSnackBarMsg("Pulled Successfully");
        setFormat("success");
        setsavenextmessage(true);
        getBusinessAndPromoterListByUser();
      } else {
        setCredLoader(false);
        snackBarMsg("Something went Wrong");
        savenextmessage(true);
      }
    }).catch(error => {
      setCredLoader(false);
      console.log(error);
    });
  }

  //upload document starts...
  //const [showUploadDoc, setShowUploadDoc] = React.useState(false);
  const [docBase64, setDocBase64] = React.useState();
  const [showmodal, setshowmodal] = React.useState(false);
  const [previewType, setPreviewType] = React.useState('')
  const [previewFile, setPreviewFile] = React.useState('')
  const pdfContentType = 'application/pdf'

  const base64toBlob = (data, content_type) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    
    const base64WithoutPrefix = data.substr(
      `data:${content_type};base64,`.length
    )
    const bytes = atob(base64WithoutPrefix)
    let length = bytes.length
    let out = new Uint8Array(length)
    while (length--) {
      out[length] = bytes.charCodeAt(length)
    }
    return new Blob([out], { type: pdfContentType })
  }

  const handleshowhidepdf = (base64, fileType, item) => {
    console.log("\n \nbase64", fileType)
    //   var i = base64.indexOf(':');
    //   var j = base64.indexOf(';');
    //   console.log("i:"+i,"-----","j"+j);
    // //  var splits = [dara.slice(0,i), s.slice(i+1)];
    //     let content_type = base64.slice(i+1,j);

    const url = URL.createObjectURL(base64toBlob(base64, fileType))
    console.log(url)
    setDocBase64(base64)
    let fileExt = fileType.split('/')
    // setPreviewFile(item.docPath);
    setPreviewFile(url)
    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setPreviewType('xlsx')
    } else if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setPreviewType('docx')
    } else if (fileType === 'application/msword') {
      setPreviewType('doc')
    } else {
      console.log("file Extension----", fileExt[1])
      setPreviewType(fileExt[1])
    }
    //if(fileExt[fileExt.length-1]=="sheet"){
    // setshowmodal(true)
    //  }
  }

  // const handleshowhidepdf = (base64) => {
  //   setDocBase64(base64);
  //   setshowmodal(true);
  // }

  // const handleUploadDoc = () => {
  //   setHeader(false);
  //   setShowUploadDoc(true);
  //   setShowBusinessPromoterList(false);
  // }

  // const handleclosePdf = () => {
  //   setshowmodal(false);

  // }

  //const [dropFileText, setDropFileText] = React.useState("Drag & Drop file or Browse");

  const [fileList, setFilelist] = React.useState([]);

  const detailsDocument = () => {
    const docdata = {
      user_id: 267,
      parent_id: 1,
      parent_type_id: 445,
      form: "1.1.2"
    }
    axios.post(apiCalling.coderscorner + `server.php/api/getDetailsDocument`, docdata).then((result) => {
      //////console.log("Document data...!!!", result.data);
      setFilelist(result.data.data);
    })
  }

  const fileSetInArray = async (e, index) => {
    let fileArray = [];
    let temp_fileList = [...fileList];
    if (e.length > 0) {
      let temp_file = { ...temp_fileList[index] };
      var date = new Date();
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var modifiedDate = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
      let reader = new FileReader();
      reader.readAsDataURL(e[0]);
      reader.onload = function () {

        temp_file.file_name = e[0].name;
        temp_file.file_type = e[0].type;
        temp_file.modified_date = modifiedDate;
        temp_file.document_upload_id = reader.result;
        temp_fileList[index] = temp_file;
        setFilelist(temp_fileList);
      };
      reader.onerror = function (error) {
        //////console.log('Error: ', error);
      };
    }
  }

  const callFileDeleteOption = (index) => {

    let temp_fileList = [...fileList];
    let temp_file = { ...temp_fileList[index] };
    temp_file.file_name = "";
    temp_file.file_type = "";
    temp_file.modified_date = "";
    temp_file.document_upload_id = "";
    temp_fileList[index] = temp_file;
    setFilelist(temp_fileList);
  }

  const [showDocScreen, setShowDocScreen] = React.useState(false);

  const [parentTypeId, setParentTypeId] = React.useState(0);
  const [parentId, setParentId] = React.useState(0);
  ///Document update 
  let docDetails = {
    formId: "1.1.3",
    parentId: parentId,
    parentTypeId: parentTypeId,
  }

  //End upload Document
  //form for upload document


  //end for upload document;
  const [searched, setSearched] = React.useState("");
  const [newBus, setNewBus] = React.useState();
  const requestSearch = (searchedVal) => {
    ////
    let busArr = businessList;
    if (searchedVal.length != 0 && searchedVal != "") {
      const filteredRows = busArr.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setBusinessList(filteredRows);
    } else {
      setBusinessList(newBus)
    }
  };

  const cancelSearch = () => {
    ////
    setSearched("");
    setBusinessList(newBus)
  }

  const [searchedPromo, setSearchedPromo] = React.useState("");
  const [newPro, setNewPro] = React.useState();

  const requestSearchPromo = (searchedVal) => {
    ////
    let promoArr = promoterList;


    if (searchedVal.length != 0 && searchedVal != "") {
      const filteredRows = promoArr.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      // setRows(filteredRows);
      setPromoterList(filteredRows);
    } else {
      setPromoterList(newPro);
    }

  };

  const cancelSearchPromo = () => {
    ////
    setSearchedPromo("");

    setPromoterList(newPro);

  }

  const handleFin = (values) => {
    ////
    console.log(values);
    console.log(values.year1value);
    //   console.log(values['1TotalIncome'][0].year1value);
    console.log("hi");
  }


  let fyear = "year";
  let fval = "value";

  const [showPreview, setShowPreview] = React.useState(false);



  // Comment Section Code 

  //const [showDocScreen, setShowDocScreen] = useState(false);
  const [equiOrCriff, setequiOrCriff] = useState(false);
  const [commentArr, setCommentsArr] = useState([]);

  const [comments, setComments] = useState("");
  const sendComments = async () => {
    const user_id = JSON.parse(localStorage.getItem('userdata'));
    ////
    let data = {
      user_id: user_id.id,
      application_ref_no: localData.reference_id,
      step: "Loans",
      sequence_no: "1",
      comments: comments,
      commented_by: user_id.id

    }
    let url = '';
    await axios.post(apiCalling.coderscorner + 'server.php/api/verificationComments', data).then(result => {
      console.log(result);
      if (result.data.status === true) {
        setShowMessage("Comment Saved");
        setsavenextmessage(true);

        setComments("");
        getverficationComments();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const getverficationComments = async () => {
    ////
    let data = {
      "application_ref_no": localData.reference_id
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getverficationComments', data).then(result => {
      if (result.data.status === true) {
        console.log("a-------------------------------", result);

        setCommentsArr(result.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  const deleteComment = async (id) => {
    let data = {
      id: id
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getverficationComments', data).then(result => {
      console.log(result);
      setCommentsArr(result.data.data);
    }).catch(error => {
      console.log(error);
    });
  }

  const [commentBox, setCommentBox] = React.useState({
    width: "100%",
    zIndex: "9999",
    position: "absolute",
    right: "1%"
  })


  // const changeCommentBox = (e) => {
  //   e.preventDefault();
  //   ////
  //   setCommentBox({
  //     ...commentBox,
  //     display: "block"
  //   })
  // }


  const [opencommentbox, setOpenComment] = React.useState(false);

  const changeCommentBox = (e) => {
    e.preventDefault();
    ////
    // setCommentBox({
    //   ...commentBox,
    //   // display: "block"
    // })
    if (opencommentbox) {
      setOpenComment(false);
    } else {
      setOpenComment(true);
    }
  }
  /// end comment section

  const handleError = (value) => {
    console.log(value);

    ////
    let error;

    try {
      if (!isFinite(value)) {
        return "please enter Digit";
      }
    }
    catch (err) {
      return "";
    }
  }

  //conversion for Otp timer into
  const convertTimerToInt = (timer) => {
    let newTimer = parseInt(timer);
    var minutes = Math.floor(newTimer / 60);
    var seconds = newTimer - 60 * minutes;
    setOtpMinutes(minutes);
    setOtpSeconds(seconds);
  };



  //mobile and email otp form show and hide
  const onBlurMobile = (e) => {

    let val = e.target.value;
    let len = val.toString().length;

    ///setVerifiedIcon(true)
    // console.log(len);
    if (len == 10) {
      // verifyPromoterMobile(val);
      // setPromoMobile(val);
      ///remove later
      setMobile(val)
      setVerifiedIcon(true);
      setHideMobileResenBtn(false);
      setMobileVerifyIcon(true);
      setDisable(false);
      setIfMobileOtpInvald(false);
      enableShare();
    }
  };

  const verifyPromoterMobile = async () => {
    ////
    let data = {
      mobile: mobile,
    };
    let api_path = apiCalling.identityManagement + `server.php/api/UserSigninForDigitalPull`;
    await axios
      .post(api_path, data)
      .then((result) => {
        console.log(result.data);
     //   alert(result.data.Data.mobile_otp);
          console.log(result.data.Data.mobile_otp);

        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message);
        } else if (result.data.status === 101) {
          setVerifiedIcon(true);
        }
        else {
          setOtpBtn(true);
          //
          setMobileExistMmsg("");
        }
      })
      .catch((error) => {
        ErrorCatch(error, api_path, 'post', data);
        console.log(error);
      });
  };


  /// verifying mobile otp for promoter
  const verifyMobileOtp = (e) => {
    //
    let eotp = e;
    let len = eotp.length;
    if (len == 6) {
      let data = {
        value: mobile,
        form_id: 2,
        otp: eotp,
      };
      localStorage.setItem("otps", JSON.stringify(data));
      axios
        .post(apiCalling.identityManagement + `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          if (result.data != undefined || result.data != null) {
            //  console.log(result.data);
            if (result.data.status === true) {
              setMobileVerifyIcon(true);
              // setShowTimer(false);
              //setOtpErrorMessage(false);
              setIfMobileOtpInvald(false);
              setHideMobileResenBtn(false);
              setMobileVerifyIcon(true);
              setDisable(false);
              setOpenCredentialForm(true);
            } else if (result.data.status === false) {
              //setOtpErrorMessage(true);
              // setOtpMessage(result.data.message);
              setIfMobileOtpInvald(true);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //resend otp for email
  const resendOtpEmail = () => {
    let data = {};
    let val = mobile;
    data = {
      type: "mobile",
      value: val,
      form_id: 2
    }
    axios
      .post(apiCalling.identityManagement + `server.php/api/resendOtp`,
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///Otp time as per general setting
  const getOtpTimer = async () => {
    let data = {
      parameter_name: "OTP TIMER",
    };
    let api_path = apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then((result) => {
        console.log(result.data.data[0]);
        let data = result.data.data[0].parameter_value;
        console.log("timer", data);
        convertTimerToInt(data);
      })
      .catch((error) => {
        ErrorCatch(error, api_path, 'post', data);
        console.log(error);
      });
  };



  const enableShare = () => {
    if (mobileVerifyIcon || emailVerifyIcon) {
      setShareEnable(false)
    }
  }

  /// front end calculations jayesh



  // PAT Calculations

  let patValues = { year1value: 0, year2value: 0, year3value: 0 };

  const patCalculations1Year1Value = (values) => {
    let pat = 0;
    if (values['9PBT'].length > 0 && 'year1value' in values['9PBT'][0] && '10TaxPaid' in values['9PBT'][0] ) {
      console.log("\n \n values['9PBT'][0]", values['9PBT'][0])
      pat = values['9PBT'][0].year1value - values['10TaxPaid'][0].year1value;
      patValues.year1value = pat;
    }

    return pat;

  }

  const patCalculations1Year2Value = (values) => {
    let pat = 0;
    console.log("\n \n values----------", values)
    if (values['9PBT'].length > 0 && values['10TaxPaid'].length > 0 && 'year2value' in values['9PBT'][0]&& 'year2value' in values['10TaxPaid'][0]) {

      pat = values['9PBT'][0].year2value - values['10TaxPaid'][0].year2value;
      patValues.year2value = pat;
    }
    return pat;

  }

  const patCalculations1Year3Value = (values) => {
    ////
    let pat = 0;
    if (values['9PBT'].length > 0 && values['10TaxPaid'].length > 0 && 'year3value' in values['9PBT'][0]&& 'year3value' in values['10TaxPaid'][0]) {
      pat = values['9PBT'][0].year3value - values['10TaxPaid'][0].year3value;
      patValues.year3value = pat;
    }
    return pat;

  }

  // Gross profit
  const grossProfitValues = { year1value: 0, year2value: 0, year3value: 0 };

  const grossProfitYear1Value = (values) => {
    //////  
    let totalIncome = 0;
    if (values['1TotalIncome'].length > 0) {
      values['1TotalIncome'].map(item => {
        totalIncome = totalIncome + Number(item.year1value);
      });
    }
    let totalDirectProfit = 0;
    if (values['2DirectCost'].length > 0) {
      values['2DirectCost'].map(item => {
        totalDirectProfit = totalDirectProfit + Number(item.year1value)
      })
    }
    let gPorfit = totalIncome - totalDirectProfit;

    grossProfitValues.year1value = gPorfit;

    return gPorfit;
  }

  const grossProfitYear2Value = (values) => {
    //////
    let totalIncome = 0;
    if (values['1TotalIncome'].length > 0) {
      values['1TotalIncome'].map(item => {
        totalIncome = totalIncome + Number(item.year2value);
      });
    }

    let totalDirectProfit = 0;
    if (values['2DirectCost'].length > 0) {
      values['2DirectCost'].map(item => {
        totalDirectProfit = totalDirectProfit + Number(item.year2value)
      })
    }
    let gPorfit = totalIncome - totalDirectProfit;
    grossProfitValues.year2value = gPorfit
    return gPorfit;
  }

  const grossProfitYear3Value = (values) => {
    // ////
    let totalIncome = 0;
    if (values['1TotalIncome'].length > 0) {
      values['1TotalIncome'].map(item => {
        totalIncome = totalIncome + Number(item.year3value);
      });
    }
    let totalDirectProfit = 0;
    if (values['2DirectCost'].length > 0) {
      values['2DirectCost'].map(item => {
        totalDirectProfit = totalDirectProfit + Number(item.year3value)
      })
    }
    let gPorfit = totalIncome - totalDirectProfit;

    grossProfitValues.year3value = gPorfit
    return gPorfit;
  }

  // business margin Calculations
  let businessMarginValues = { year1value: 0, year2value: 0, year3value: 0 }
  const businessMarginYear1Value = (values) => {
    //

    let salEmpBenefitsyear1value = 0;
    let admExpenseyear1value = 0;

    if (values['3IndirectCost'].length > 2) {
      salEmpBenefitsyear1value = values['3IndirectCost'][0].year1value;
      admExpenseyear1value = values['3IndirectCost'][3].year1value;
    }

    let busMargin = grossProfitValues.year1value - (Number(salEmpBenefitsyear1value) + Number(admExpenseyear1value));
    businessMarginValues.year1value = busMargin;

    return busMargin;

  }

  const businessMarginYear2Value = (values) => {

    let salEmpBenefitsyear2value = 0;
    let admExpenseyear2value = 0;
    if (values['3IndirectCost'].length > 2) {
      salEmpBenefitsyear2value = values['3IndirectCost'][0].year2value;
      admExpenseyear2value = values['3IndirectCost'][3].year2value;
    }
    let busMargin = grossProfitValues.year2value - (Number(salEmpBenefitsyear2value) + Number(admExpenseyear2value));
    businessMarginValues.year2value = busMargin;
    return busMargin;
  }

  const businessMarginYear3Value = (values) => {

    let salEmpBenefitsyear3value = 0;
    let admExpenseyear3value = 0;

    if (values['3IndirectCost'].length > 2) {
      salEmpBenefitsyear3value = values['3IndirectCost'][0].year3value;
      admExpenseyear3value = values['3IndirectCost'][3].year3value;
    }

    let busMargin = grossProfitValues.year3value - (Number(salEmpBenefitsyear3value) + Number(admExpenseyear3value));
    businessMarginValues.year3value = busMargin;
    return busMargin;
  }

  // PBDIT Calculations
  let pbditValues = { year1value: 0, year2value: 0, year3value: "" };
  const pbditYear1Value = (values) => {
    let total5ManagementRenumeration = 0;
    if (values['5ManagementRenumeration'].length > 0) {
      values['5ManagementRenumeration'].map(item => {
        total5ManagementRenumeration = total5ManagementRenumeration + Number(item.year1value);
      });
    }
    let total6TotalNonOperatingIncome = 0;
    if (values['6TotalNonOperatingIncome'].length > 0) {
      values['6TotalNonOperatingIncome'].map(item => {
        total6TotalNonOperatingIncome = total6TotalNonOperatingIncome + Number(item.year1value);
      });
    }
    let pbdit = businessMarginValues.year1value + Number(total5ManagementRenumeration) + Number(total6TotalNonOperatingIncome);
    pbditValues.year1value = pbdit;
    return pbdit;



  }
  const pbditYear2Value = (values) => {
    let total5ManagementRenumeration = 0;

    if (values['5ManagementRenumeration'].length > 0) {
      values['5ManagementRenumeration'].map(item => {
        total5ManagementRenumeration = total5ManagementRenumeration + Number(item.year2value);
      });
    }
    let total6TotalNonOperatingIncome = 0;
    if (values['6TotalNonOperatingIncome'].length > 0) {
      values['6TotalNonOperatingIncome'].map(item => {
        total6TotalNonOperatingIncome = total6TotalNonOperatingIncome + Number(item.year2value);
      });
    }
    let pbdit = businessMarginValues.year2value + Number(total5ManagementRenumeration) + Number(total6TotalNonOperatingIncome);
    pbditValues.year2value = pbdit;
    return pbdit;
  }




  const pbditYear3Value = (values) => {
    let total5ManagementRenumeration = 0;
    if (values['5ManagementRenumeration'].length > 0) {
      values['5ManagementRenumeration'].map(item => {
        total5ManagementRenumeration = total5ManagementRenumeration + Number(item.year3value);
      });
    }

    let total6TotalNonOperatingIncome = 0;
    if (values['6TotalNonOperatingIncome'].length > 0) {
      values['6TotalNonOperatingIncome'].map(item => {
        total6TotalNonOperatingIncome = total6TotalNonOperatingIncome + Number(item.year3value);
      });
    }
    let pbdit = businessMarginValues.year3value + Number(total5ManagementRenumeration) + Number(total6TotalNonOperatingIncome);
    pbditValues.year3value = pbdit;

    return pbdit;
  }

  /// PBIT Calculations

  let pbtValues = { year1value: 0, year2value: 0, year3value: 0 };
  let depreciationValues = { year1value: 0, year2value: 0, year3value: 0 };

  const pbtYear1Value = (values) => {
    //////
    let totalpbtYear1Value = 0;
    if (values['7OtherNon-CashExpenses'].length > 0) {
      depreciationValues.year1value = values['7OtherNon-CashExpenses'][0].year1value;

      values['7OtherNon-CashExpenses'].slice(0, 2).map(item => {
        totalpbtYear1Value = totalpbtYear1Value + Number(item.year1value);
      });
    }
    // pendg calcaultion
    let pbt = pbditValues.year1value - totalpbtYear1Value;
    pbtValues.year1value = pbt;
    return pbt;

  }

  const pbtYear2Value = (values) => {
    let totalpbtYear2Value = 0;

    if (values['7OtherNon-CashExpenses'].length > 0) {
      depreciationValues.year2value = values['7OtherNon-CashExpenses'][0].year2value;
      values['7OtherNon-CashExpenses'].slice(0, 2).map(item => {
        totalpbtYear2Value = totalpbtYear2Value + Number(item.year2value);
      });
    }
    /// pendg calcaultion
    let pbt = pbditValues.year2value - totalpbtYear2Value;
    pbtValues.year2value = pbt;
    return pbt;

  }

  const pbtYear3Value = (values) => {
    let totalpbtYear3Value = 0;
    if (values['7OtherNon-CashExpenses'].length > 0) {
      depreciationValues.year3value = values['7OtherNon-CashExpenses'][0].year3value;
      values['7OtherNon-CashExpenses'].slice(0, 2).map(item => {
        totalpbtYear3Value = totalpbtYear3Value + Number(item.year3value);
      });
    }
    // pendg calcaultion
    let pbt = pbditValues.year3value - totalpbtYear3Value;
    pbtValues.year3value = pbt;
    return pbt;
  }


  const cashProfitYear1Value = (values) => {
    let cashProfit = 0;
    cashProfit = patValues.year1value + depreciationValues.year1value;
    return cashProfit;
  }

  const cashProfitYear2Value = (values) => {
    let cashProfit = 0;
    cashProfit = patValues.year2value + depreciationValues.year2value;
    return cashProfit;
  }

  const cashProfitYear3Value = (value) => {
    let cashProfit = 0;
    cashProfit = patValues.year3value + depreciationValues.year3value;
    return cashProfit;
  }


  // networth value

  let netWorthValues = { year1value: 0, year2value: 0, year3value: 0 };

  const netWorthYear1Value = (values) => {
    ////
    let netWorthYear1Value = 0;
    if (values['8Networth'].length > 0) {
      values['8Networth'].map(item => {
        netWorthYear1Value = netWorthYear1Value + Number(item.year1value);
      });
    }
    netWorthValues.year1value = netWorthYear1Value;

    return netWorthYear1Value;
  }


  const netWorthYear2Value = (values) => {
    let netWorthYear2Value = 0;

    if (values['8Networth'].length > 0) {

      values['8Networth'].map(item => {
        netWorthYear2Value = netWorthYear2Value + Number(item.year2value);
      });

    }
    netWorthValues.year2value = netWorthYear2Value;
    return netWorthYear2Value;

  }


  const netWorthYear3Value = (values) => {
    let netWorthYear3Value = 0;
    if (values['8Networth'].length > 0) {
      values['8Networth'].map(item => {
        netWorthYear3Value = netWorthYear3Value + Number(item.year3value);
      });
    }
    netWorthValues.year3value = netWorthYear3Value;
    return netWorthYear3Value;
  }

  // total promoter funds calculations
  let totalPromoterFundsValue = { year1value: 0, year2value: 0, year3value: 0 };

  const totalPromoterFundsYear1Value = (values) => {
    ////
    let Loantopromotersrelatives = 0;
    let LoansfromPromotersrelatives = 0;
    let InvestmentinGroupCompanies = 0;
    let AdvancetoRelatedParties = 0;

    let totalPromoterFunds = 0;

    if (values['9TotalpromoterFunds'].length > 3) {
      Loantopromotersrelatives = Number(values['9TotalpromoterFunds'][0].year1value);
      LoansfromPromotersrelatives = Number(values['9TotalpromoterFunds'][1].year1value);
      InvestmentinGroupCompanies = Number(values['9TotalpromoterFunds'][2].year1value);
      AdvancetoRelatedParties = Number(values['9TotalpromoterFunds'][3].year1value);

      totalPromoterFunds = netWorthValues.year1value - Loantopromotersrelatives + LoansfromPromotersrelatives - InvestmentinGroupCompanies - AdvancetoRelatedParties;
    }
    totalPromoterFundsValue.year1value = totalPromoterFunds;
    return totalPromoterFunds;

  }

  const totalPromoterFundsYear2Value = (values) => {
    ////
    let Loantopromotersrelatives = 0;
    let LoansfromPromotersrelatives = 0;
    let InvestmentinGroupCompanies = 0;
    let AdvancetoRelatedParties = 0;

    let totalPromoterFunds = 0;

    if (values['9TotalpromoterFunds'].length > 3) {
      Loantopromotersrelatives = Number(values['9TotalpromoterFunds'][0].year2value);
      LoansfromPromotersrelatives = Number(values['9TotalpromoterFunds'][1].year2value);
      InvestmentinGroupCompanies = Number(values['9TotalpromoterFunds'][2].year2value);
      AdvancetoRelatedParties = Number(values['9TotalpromoterFunds'][3].year2value);

      totalPromoterFunds = netWorthValues.year2value - Loantopromotersrelatives + LoansfromPromotersrelatives - InvestmentinGroupCompanies - AdvancetoRelatedParties;
    }
    totalPromoterFundsValue.year2value = totalPromoterFunds;
    return totalPromoterFunds;
  }

  const totalPromoterFundsYear3Value = (values) => {
    ////
    let Loantopromotersrelatives = 0;
    let LoansfromPromotersrelatives = 0;
    let InvestmentinGroupCompanies = 0;
    let AdvancetoRelatedParties = 0;

    let totalPromoterFunds = 0;

    if (values['9TotalpromoterFunds'].length > 3) {
      Loantopromotersrelatives = Number(values['9TotalpromoterFunds'][0].year3value);
      LoansfromPromotersrelatives = Number(values['9TotalpromoterFunds'][1].year3value);
      InvestmentinGroupCompanies = Number(values['9TotalpromoterFunds'][2].year3value);
      AdvancetoRelatedParties = Number(values['9TotalpromoterFunds'][3].year3value);

      totalPromoterFunds = netWorthValues.year2value - Loantopromotersrelatives + LoansfromPromotersrelatives - InvestmentinGroupCompanies - AdvancetoRelatedParties;
    }
    totalPromoterFundsValue.year3value = totalPromoterFunds;
    return totalPromoterFunds;

  }

  // total outside debts calculations

  let TotalOutsideDebtsValues = { year1value: 0, year2value: 0, year3value: 0 };

  const TotalOutsideDebtsYear1Value = (values) => {
    let TotalOutsideDebts = 0;
    if (values['9TotalpromoterFunds'].length > 0) {
      values['9TotalpromoterFunds'].map(item => {
        TotalOutsideDebts = TotalOutsideDebts + Number(item.year1value);
      })
    }
    TotalOutsideDebtsValues.year1value = TotalOutsideDebts;
    return TotalOutsideDebts;
  }

  const TotalOutsideDebtsYear2Value = (values) => {
    let TotalOutsideDebts = 0;
    if (values['9TotalpromoterFunds'].length > 0) {
      values['9TotalpromoterFunds'].map(item => {
        TotalOutsideDebts = TotalOutsideDebts + Number(item.year2value);
      })
    }
    TotalOutsideDebtsValues.year2value = TotalOutsideDebts;
    return TotalOutsideDebts;
  }

  const TotalOutsideDebtsYear3Value = (values) => {
    let TotalOutsideDebts = 0;
    if (values['9TotalpromoterFunds'].length > 0) {
      values['9TotalpromoterFunds'].map(item => {
        TotalOutsideDebts = TotalOutsideDebts + Number(item.year3value);
      })
    }
    TotalOutsideDebtsValues.year3value = TotalOutsideDebts;
    return TotalOutsideDebts;
  }

  /// total FundEmployed  TotalOutsideDebts + totalPromoterFundsYear3Value
  const totalFundEmployedYear1Value = (values) => {
    ////
    let FundEmployed = 0;
    FundEmployed = totalPromoterFundsValue.year1value + TotalOutsideDebtsValues.year1value;
    return FundEmployed;

  }
  const totalFundEmployedYear2Value = (values) => {
    ////
    let FundEmployed = 0;
    FundEmployed = totalPromoterFundsValue.year2value + TotalOutsideDebtsValues.year2value;
    return FundEmployed;


  }

  const totalFundEmployedYear3Value = (values) => {
    return totalPromoterFundsValue.year3value + TotalOutsideDebtsValues.year3value;
  }

  /// Non Current asssets value caluclations

  let nonCurrentAssetsValues = { year1value: 0, year2value: 0, year3value: 0 };

  const totalNoncurrentassetsYear1Value = (values) => {

    let totalNonCurrentAsset = 0;
    if (values['11Noncurrentassets'].length > 0) {
      values['11Noncurrentassets'].map(item => {
        totalNonCurrentAsset += Number(item.year1value)
      });
    }
    nonCurrentAssetsValues.year1value = totalNonCurrentAsset;
    return totalNonCurrentAsset;
  }

  const totalNoncurrentassetsYear2Value = (values) => {
    let totalNonCurrentAsset = 0;
    if (values['11Noncurrentassets'].length > 0) {
      values['11Noncurrentassets'].map(item => {
        totalNonCurrentAsset += Number(item.year2value)
      });
    }
    nonCurrentAssetsValues.year2value = totalNonCurrentAsset;
    return totalNonCurrentAsset;
  }

  const totalNoncurrentassetsYear3Value = (values) => {
    let totalNonCurrentAsset = 0;
    if (values['11Noncurrentassets'].length > 0) {
      values['11Noncurrentassets'].map(item => {
        totalNonCurrentAsset += Number(item.year3value)
      });
    }
    nonCurrentAssetsValues.year3value = totalNonCurrentAsset;
    return totalNonCurrentAsset;
  }

  // current Asset value

  let currentAssetValues = { year1value: 0, year2value: 0, year3value: 0 }
  const totalCurrenAssetYear1Value = (values) => {
    let totalWorkingCapitalAssets = 0;
    if (values['12WorkingCapitalAssets'].length > 0) {
      values['12WorkingCapitalAssets'].map(item => {
        totalWorkingCapitalAssets += Number(item.year1value);
      });
    }
    currentAssetValues.year1value = totalWorkingCapitalAssets;
    return totalWorkingCapitalAssets;

  }

  const totalCurrenAssetYear2Value = (values) => {
    let totalWorkingCapitalAssets = 0;
    if (values['12WorkingCapitalAssets'].length > 0) {
      values['12WorkingCapitalAssets'].map(item => {
        totalWorkingCapitalAssets += Number(item.year2value);
      });
    }
    currentAssetValues.year2value = totalWorkingCapitalAssets;
    return totalWorkingCapitalAssets;

  }

  const totalCurrenAssetYear3Value = (values) => {
    let totalWorkingCapitalAssets = 0;
    if (values['12WorkingCapitalAssets'].length > 0) {
      values['12WorkingCapitalAssets'].map(item => {
        totalWorkingCapitalAssets += Number(item.year3value);
      });
    }
    currentAssetValues.year3value = totalWorkingCapitalAssets;
    return totalWorkingCapitalAssets;

  }


  /// working capital liablities
  let workingCapitalLiablitiesValues = { year1value: 0, year2value: 0, year3value: 0 };

  const totalWorkingCapitalLiabilitiesYear1Value = (values) => {
    let totalWorkingCapitalLiabilities = 0;
    if (values['16WorkingCapitalLiabilities'].length > 0) {

      values['16WorkingCapitalLiabilities'].map(item => {
        totalWorkingCapitalLiabilities += Number(item.year1value);
      });
      workingCapitalLiablitiesValues.year1value = totalWorkingCapitalLiabilities;
      return totalWorkingCapitalLiabilities;

    }
  }

  const totalWorkingCapitalLiabilitiesYear2Value = (values) => {
    let totalWorkingCapitalLiabilities = 0;

    if (values['16WorkingCapitalLiabilities'].length > 0) {

      values['16WorkingCapitalLiabilities'].map(item => {
        totalWorkingCapitalLiabilities += Number(item.year2value);
      });
      workingCapitalLiablitiesValues.year2value = totalWorkingCapitalLiabilities;
      return totalWorkingCapitalLiabilities;

    }

  }

  const totalWorkingCapitalLiabilitiesYear3Value = (values) => {

    let totalWorkingCapitalLiabilities = 0;

    if (values['16WorkingCapitalLiabilities'].length > 0) {

      values['16WorkingCapitalLiabilities'].map(item => {
        totalWorkingCapitalLiabilities += Number(item.year3value);
      });
      workingCapitalLiablitiesValues.year3value = totalWorkingCapitalLiabilities;
      return totalWorkingCapitalLiabilities;

    }
  }

  /// Gross Porfit values
  let gGrossWorkingCapitalValues = { year1value: 0, year2value: 0, year3value: 0 };
  const GrossWorkingCapitalYear1Value = (values) => {
    //
    gGrossWorkingCapitalValues.year1value = Number(currentAssetValues.year1value) - Number(workingCapitalLiablitiesValues.year1value);
    return Number(currentAssetValues.year1value) - Number(workingCapitalLiablitiesValues.year1value);

  }
  const GrossWorkingCapitalYear2Value = (values) => {
    gGrossWorkingCapitalValues.year2value = Number(currentAssetValues.year2value) - Number(workingCapitalLiablitiesValues.year2value);
    return Number(currentAssetValues.year2value) - Number(workingCapitalLiablitiesValues.year2value);

  }
  const GrossWorkingCapitalYear3Value = (values) => {
    gGrossWorkingCapitalValues.year3value = Number(currentAssetValues.year3value) - Number(workingCapitalLiablitiesValues.year3value);

    return currentAssetValues.year3value - workingCapitalLiablitiesValues.year3value;

  }


  // net working capital 
  let netWorkingCapitalValues = { year1value: 0, year2value: 0, year3value: 0 };
  const netWorkingCapitalYear1Value = (values) => {
    let SourcesofWorkingcapital = 0;

    if (values['14SourcesofWorkingcapital'].length > 0) {

      values['14SourcesofWorkingcapital'].map(item => {
        SourcesofWorkingcapital += Number(item.year1value);
      });
    }
    let netval = currentAssetValues.year1value - SourcesofWorkingcapital;
    netWorkingCapitalValues.year1value = netval;
    return netval;

  }

  const netWorkingCapitalYear2Value = (values) => {

    let SourcesofWorkingcapital = 0;

    if (values['14SourcesofWorkingcapital'].length > 0) {

      values['14SourcesofWorkingcapital'].map(item => {
        SourcesofWorkingcapital += Number(item.year2value);
      });
    }
    let netval = currentAssetValues.year2value - SourcesofWorkingcapital;
    netWorkingCapitalValues.year2value = netval;
    return netval;


  }
  const netWorkingCapitalYear3Value = (values) => {
    let SourcesofWorkingcapital = 0;
    if (values['14SourcesofWorkingcapital'].length > 0) {
      values['14SourcesofWorkingcapital'].map(item => {
        SourcesofWorkingcapital += Number(item.year3value);
      });
    }
    let netval = currentAssetValues.year3value - SourcesofWorkingcapital;
    netWorkingCapitalValues.year3value = netval;
    return netval;
  }


  // Application odf funds ---> (networkingcapital + non current assets)
  const applicationOfFundsYear1Value = () => {
    let appFundVal = 0;
    appFundVal = nonCurrentAssetsValues.year1value + + netWorkingCapitalValues.year1value;
    return appFundVal;

  }
  const applicationOfFundsYear2Value = () => {
    let appFundVal = 0;
    appFundVal = nonCurrentAssetsValues.year2value + netWorkingCapitalValues.year2value;
    return appFundVal;

  }
  const applicationOfFundsYear3Value = () => {
    let appFundVal = 0;
    appFundVal = nonCurrentAssetsValues.year3value + netWorkingCapitalValues.year3value;
    return appFundVal;

  }

  //


const  setPrieviewValues =(value,fileType)=>{
  
  let fileExt = fileType.split('/');
  setDocPath(value);
  if (fileExt[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    setDocType('xlsx');
  } else {
    setDocType(fileExt[1]);
  }

};

  const arrvalues = ['Profit Loss Accounts', 'Balance Sheet'];
  const [text, setText] = useState('');
  const [toggle, setToggle] = useState(false);

  const handleInputChange = event => {
      setText(event.target.value);
      console.log('value', event.target.value);
  };

  const handleToggle = () => {
      toggle ? setToggle(false) : setToggle(true);
      console.log('toggle State: ', toggle);
  };




  return (
    <Container maxWidth="lg">
      <div className="mini-container mg_btm_set">

        <div>
          <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>
        </div>

        {header == true && (
          <div className="content-header">
            <Row>
              {/* { requestDetails && (
                    <Col md={{ span: 12 }} style={{ marginBottom: "3%", textAlign: "center" }}>
                      <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                    </Col>
                  )} */}
              <Col md={{ span: 12 }} className="mainheader1">
                <Typography text="YOUR FINANCIAL DETAILS" styleType="C" />
              </Col>
              <Col md={{ span: 12 }} className="subHeader1">
                <table className="applicationTableDetails1">
                  <tr>
                    {/* <td style={{ padding: "0px 5px" }}>Type</td>
                  <td>:</td> */}
                    {localData.request_type_id === 0 && (
                      <td>Loan Application</td>)}
                    {localData.request_type_id === 1 && (
                      <td>Check Eligibility</td>)}
                    <td>&nbsp;|&nbsp;</td>
                    <td>Application Number</td>
                    <td>:</td>
                    <td>{localData.reference_id}</td>
                    <td>&nbsp;|&nbsp;</td>
                    <td>Loan Type</td>
                    <td>:</td>
                    <td>{localData.loan_type}</td>
                  </tr>
                </table>
              </Col>
              {showDocScreen && (<>
                <Col md={{ span: 10 }}></Col>
                <Col md={{ span: 2 }} className="mainHeaderBack">
                  {/* <Tooltip title="Back" arrow>
                <img style={{ float: "right", width: "25%", height: "100%", margin: "auto" }} src={Back} onClick={() => { setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true); }} />
              </Tooltip> */}
                  <span className="image">
                    <img alt="image1" className='backbutton navbutton docbackbtn' src={Back} onClick={() => {
                      setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true);
                      setShowDocScreen(false);
                      setShowDocScreen(false);
                    }} />
                    <Tooltip title="Back" arrow>
                      <img alt="image1" className='backbutton navgraybutton docbackbtn' src={grayback} onClick={() => {
                        setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true);
                        setShowDocScreen(false);
                      }} />
                    </Tooltip>
                  </span>
                </Col></>)}

              {showDocScreen && (<DocumentUpload formdetails={docDetails} />)}

            </Row>
          </div>
        )}

        {loaderFlag == true && (
          <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}

        {showBusinessPromoterList == true && loaderFlag == false && (
          <div>
            <Typography styleType="SH" text='BUSINESS FINANCIALS' className="subHeaderStyle" />
            <Paper>
              <Row>
                <Col md={{ span: 9 }}></Col>
                <Col md={{ span: 3 }}>
                  <SearchBar
                    className="search_bar"
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                </Col>
              </Row>
              <TableContainer>
                <Table aria-label="collapsible table" size="small" style={{ marginBottom: "50px", height: "90%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableSLNoHeading fixedHeader">#</TableCell>
                      <TableCell className="tableBusinessNameHeading fixedHeader">Business Name</TableCell>
                      <TableCell className="tableGSTHeading fixedHeader">GST</TableCell>
                      <TableCell className="tablePANHeading fixedHeader">Business PAN</TableCell>
                      <TableCell className="tableEntityTypeHeading fixedHeader">Entity Type</TableCell>
                      <TableCell className="tableStatusHeading fixedHeader">Status</TableCell>
                      <TableCell className="tableActionHeading fixedHeader">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessList.map((item, i) => (
                      <>
                        <TableRow hover className="datable">
                          <TableCell scope="item">{i + 1}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{maskGst(item.gst)}</TableCell>
                          <TableCell>{maskBpan(item.bpan)}</TableCell>
                          <TableCell>{item.entity_type}</TableCell>
                          {item.countrow == 0 && (
                            <TableCell className="tableStatusHeading">
                              <Tooltip title={"Financial Details not Added for Business" + " " + item.name} placement="top">
                                <span><i class="far fa-times-circle" style={{ color: "red" }}></i></span></Tooltip></TableCell>
                          )}
                          {item.countrow > 0 && (
                            <TableCell className="tableStatusHeading">
                              <Tooltip title={"Financial Details updated for Business" + " " + item.name} placement="top">
                                <span>
                                <svg class="tickwithcircle" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                </svg>
                                </span></Tooltip></TableCell>
                          )}
                          <TableCell className="tableActionHeading">
                            <Tooltip title="Manual Entry" placement="bottom">
                              {/* <Button className="actionBtn" onClick={() => { handleManualDataAdd(item) }}>
                              <i className="fas fa-user-edit"
                                style={{ fontSize: "15px" }} />
                            </Button> */}
                              <img alt="" className="actionBtn svgicon" src={manualentry} onClick={() => {
                                handleManualDataAdd(item, 1);
                                setParentTypeId(1);
                                setParentId(item.id);
                              }} />
                              {/* <svg class="svgicon" fill="#000">
                              <use xlinkHref="../../image/icons/manual addition.svg"></use>
                            </svg> */}
                              {/* <MenuIcon fill="black"/> */}
                            </Tooltip>
                            <Tooltip title="Upload Document" placement="bottom">
                              {/* <Button className="actionBtn" onClick={() => handleUploadDoc(item)}>
                              <i className="fas fa-cloud-upload-alt" style={{ fontSize: "15px" }} />
                            </Button> */}
                              <img alt=" jh" className="actionBtn svgicon" src={uploaddoc} onClick={() => {
                                setShowBusinessPromoterList(false); setShowExtendedForm(false)
                                setShowDocScreen(true);
                                setParentTypeId(1);
                                setParentId(item.id);
                              }} />
                            </Tooltip>
                            <Tooltip title="Digital Pull" placement="bottom">
                              {/* <Button className="actionBtn" onClick={handleOpen} >
                              <i className="fas fa-download" style={{ fontSize: "15px" }} />
                            </Button> */}
                              <img alt=" jh" className="actionBtn svgicon" src={digitalpull} onClick={() => {
                                //
                                setParentId(item.id);
                                setParentTypeId(1);
                                setOpen(true);
                                setShowConMsg(true);

                              }} />
                              {/* onClick={handleOpen}  */}
                            </Tooltip>
                            {/* <Tooltip title="Upload Document" placement="bottom">
                            <img className="actionBtn svgicon" src={pdf_new} onClick={() => handleUploadDoc(item)} />
                          </Tooltip>
                          <Tooltip title="Upload Document" placement="bottom">
                            <img className="actionBtn svgicon" style={{width: "28%"}} src={pdf_old} onClick={() => handleUploadDoc(item)} />
                          </Tooltip> */}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Typography styleType="SH" className="subHeaderStyle" text='PROMOTER FINANCIALS' />
            {showPromoterTable == false && (
              <Alert severity="warning" className="warningMsg1">
                <AlertTitle><p style={{ fontSize: "16px", color: '#663C00' }}>No Promoters have been Added to this Business. Click here to Add Promoter Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p></AlertTitle>
                <Button size="small" className="choicebtn" onClick={() => { props.handleBack(2) }}>Add Promoter</Button>&nbsp;&nbsp;
              </Alert>
            )}
            {showPromoterTable == true && (

              <Paper>
                <SearchBar
                  className="search_bar"
                  value={searchedPromo}
                  onChange={(searchVal) => requestSearchPromo(searchVal)}
                  onCancelSearch={() => cancelSearchPromo()}
                />
                <TableContainer>
                  <Table aria-label="collapsible table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableSLNoHeading">#</TableCell>
                        <TableCell className="tableBusinessNameHeading">Promoter Name</TableCell>
                        {/* <TableCell className="tableGSTHeading">Business Name</TableCell> */}
                        <TableCell className="tablePANHeading">Email</TableCell>
                        <TableCell className="tableEntityTypeHeading">Mobile</TableCell>
                        <TableCell className="tableStatusHeading">Status</TableCell>
                        <TableCell className="tableActionHeading">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {promoterList.map((item, i) => (
                        <>
                          <TableRow hover className="datable">
                            <TableCell>{i + 1}</TableCell>
                            {/* <TableCell>{item.first_name} {item.last_name}</TableCell> */}
                            <TableCell>{maskName(item.first_name) + " " + maskName(item.last_name)}</TableCell>
                            {/* <TableCell>{item.business_name}</TableCell> */}
                            <TableCell>{maskEmail(item.email)}</TableCell>
                            <TableCell>{maskMobile(item.mobile)}</TableCell>
                            {item.countrow == 0 && (
                              <TableCell className="tableStatusHeading">
                                <Tooltip title={"Financial Details not Added for Promoter" + " " + item.name} placement="top">
                                  <span><i class="far fa-times-circle" style={{ color: "red" }}></i></span></Tooltip></TableCell>)}
                            {item.countrow > 0 && (
                              <TableCell className="tableStatusHeading">
                                <Tooltip title={"Financial Details updated for Promoter" + " " + item.name} placement="top">
                                  <span>
                                  <svg class="tickwithcircle" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                  </svg>
                                  </span></Tooltip></TableCell>)}
                            <TableCell className="tableActionHeading" style={table_Action}>
                              <Tooltip title="Manual Entry" placement="bottom">
                                {/* <Button className="actionBtn" onClick={() => { handleManualDataAdd(item) }} >
                              <i className="fas fa-user-edit"
                                style={{ fontSize: "15px" }} />
                            </Button> */}
                                <img alt="img" className="actionBtn svgicon" src={manualentry} onClick={() => { handleManualDataAdd(item, 2) }} />
                              </Tooltip>
                              <Tooltip title="Upload Document" placement="bottom">
                                {/* <Button className="actionBtn" onClick={() => handleUploadDoc(item)}>
                            <i className="fas fa-cloud-upload-alt" style={{ fontSize: "15px" }} />
                          </Button> */}
                                <img alt="done" className="actionBtn svgicon" src={uploaddoc} onClick={() => {
                                  setShowBusinessPromoterList(false); setShowExtendedForm(false)
                                  setShowDocScreen(true);
                                  setParentTypeId(1);
                                  setParentId(item.id);
                                }} />
                              </Tooltip>
                              <Tooltip title="Digital Pull" placement="bottom">
                                {/* <Button className="actionBtn" onClick={handleOpen}>
                              <i className="fas fa-download" style={{ fontSize: "15px" }} />
                            </Button> */}
                                <img alt="done" className="actionBtn svgicon" src={digitalpull} onClick={() => {
                                  setParentTypeId(2);
                                  setParentId(item.id);
                                  setOpen(true);
                                  setShowConMsg(true);

                                }} />
                                {/* onClick={handleOpen}  */}
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>)}
            <div className='btn_row_fixd'>
              <Row>
                <Col md={{ span: 10 }}></Col>
                {/* className="btnspace" */}
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img alt="image1" className='navbutton' src={Back} onClick={handledata} />
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img alt="image1" className='navgraybutton' src={grayback} onClick={handledata} />
                    </Tooltip>
                  </span>
                </Col>
                {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Reset" arrow>
                    <img className='navbutton' src={Reset} />
                  </Tooltip>
                </Col> */}
                {/*  <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save & Next" arrow>
                    <img className='navbutton' src={SaveNext} />
                  </Tooltip>
                </Col> */}
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img alt="image1" className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                    <Tooltip title="Save & Next" arrow>
                      <img alt="image1" className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        )}
        {showExtendedForm === true && (
          <Formik
            enableReinitialize
            initialValues={financeDetailsDynamicInitialValue}
            onSubmit={handleSubmitExtendedForm}>
            {({
              values,
              errors,
              touched,
              handleChange,
              resetForm,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="content-header">
                    <Row>
                      <Col md={{ span: 10 }} className="mainheader1">
                        {parentInfoData.parent_type_id == 1 && (
                          <Typography text={"Adding Financial Details for Business: " + parentInfoData.parent_name} styleType="C" />
                        )}
                        {parentInfoData.parent_type_id == 2 && (
                          <Typography text={"Adding Financial Details for Promoter : " + parentInfoData.parent_name} styleType="C" />
                        )}
                      </Col>
                      <Col md={{ span: 1 }} className="text-right">
                        {showPreview === false ? (
                          <Typography text={<i class="fas fa-eye" style={{ marginTop: "20px" }} onClick={() => {

                            setShowPreview(!showPreview);
                            //getDocForPreview()
                          }}></i>}
                          />
                        ) : (
                          <Typography text={<i class="fas fa-eye-slash" style={{ marginTop: "20px" }} onClick={() => {
                            setShowPreview(!showPreview);
                          }}></i>}
                          />

                        )}

                      </Col>
                      {
                        /// floating
                      }
                      {
                        role_id.role_id == 1 || role_id.role_id == 1 ? (<div id="mySidenav" class="sidenav">
                          <a href="/#" id="about" className="mySidenav" style={{ marginLeft: "-84px" }} onClick={changeCommentBox}>Comments</a>
                        </div>) : (null)
                      }

                      <Col md={{ span: 1 }} className="mainHeaderBack">
                        {/* <Tooltip title="Back" arrow>
                          <img style={{ float: "right", width: "25%", height: "100%", margin: "auto" }} src={Back} onClick={() => { setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true); }} />
                        </Tooltip> */}
                        <span className="image">
                          <img alt="img" className='backbutton navbutton' src={Back} onClick={() => { setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true); }} />
                          <Tooltip title="Back" arrow>
                            <img alt="img" className='backbutton navgraybutton' src={grayback} onClick={() => { setShowBusinessPromoterList(true); setShowExtendedForm(false); setHeader(true); }} />
                          </Tooltip>
                        </span>
                      </Col>

                      <Col md={{ span: 12 }} className="subHeader1">
                        <table className="applicationTableDetails1">
                          <tr>
                            {/* <td>Type</td>
                            <td>:</td> */}
                            {localData.request_type_id === 0 && (
                              <td>Loan Application</td>)}
                            {localData.request_type_id === 1 && (
                              <td>Check Eligibility</td>)}
                            <td>&nbsp;|&nbsp;</td>
                            <td >Application Number</td>
                            <td>:</td>
                            <td >{localData.reference_id}</td>
                            <td>&nbsp;|&nbsp;</td>
                            <td >Loan Type</td>
                            <td>:</td>
                            <td >{localData.loan_type}</td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                  </div>

                  {opencommentbox && (
                    <>
                      <Col md={{ span: 6 }} style={{ marginTop: "2%" }}></Col>
                      <Col md={{ span: 6 }} style={{ marginTop: "2%" }}>
                        <div style={commentBox} className="comment-box" >
                          <h2 style={{ color: "rgb(114, 84, 163)" }}>Verification Comments</h2>
                          <div className="comment-form" >
                            <div className="comment-form-fields">
                              <textarea className="comment_textfield" placeholder="Comment" rows="4" required value={comments} onChange={
                                (e) => {
                                  ////
                                  setComments(e.target.value)
                                }
                              } ></textarea>
                            </div>
                            <div className="comment-form-actions">
                              <Button type="button" variant="contained" color="primary" className="x" onClick={(e) => {


                                sendComments();
                              }}>Post Comment</Button>
                            </div>
                          </div>

                          {
                            commentArr.map(item => (
                              <div className="comment">
                                <p className="comment-header">{item.email}</p>
                                <p className="comment-body">- {item.comments}</p>
                                <div className="comment-footer">
                                  <a href="/#" className="comment-footer-delete" onClick={() => {
                                    deleteComment(item.id);
                                  }}
                                  >Delete Comment</a>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </Col>
                    </>
                  )}
                  {showPreview === false ? (
                    <Row>

                      <Col md={{ span: 12 }}>
                        <div style={comment}>Please enter as on date account balances and relevant banking parameters from the list below</div>
                        <div className={classes.root} id="financial-business-wrap">
                          {promoOrBus == 2 ? (
                            <FormControlLabel
                            control={
                            <Field
                                label="Non-Proprietary income "
                                name="rememberMe"
                                component={Switch}
                                color='primary'
                                onChange={handleToggle}
                                checked={toggle} // can't set/get state here
                                // value={toggle} // or here
                            />
                            }
                            label="Non-Proprietary income"
                          />
                          ) : null}
                          
                          {financeParameterDetails.map((item, i) => (
                            (arrvalues.includes(item.display_parameter_head) && toggle === true || promoOrBus === 1) ? (
                            <Accordion expanded={expanded === item.display_parameter_head} onChange={handleChangeAccro(item.display_parameter_head)}>
                              {/* {(arrvalues.includes(item.display_parameter_head) && toggle === true) ? ( */}
                              <AccordionSummary
                                className="accordian_head"
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <Typography text={item.display_parameter_head} styleType="accordianTitle" />
                              </AccordionSummary>
                              <AccordionDetails>
                                {item.headerDetails.map((iitem, j) => (
                                  <Accordion expanded={innerExpanded === iitem.display_parameter_level_1} onChange={handleChangeInnerAccro(iitem.display_parameter_level_1)}>
                                    <AccordionSummary
                                      className="accordian_subhead"
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header">
                                      <Row style={{ width: "100%" }}>
                                        <Col md={{ span: 10 }}>
                                          <Typography text={iitem.display_parameter_level_1} styleType="accordiansubTitle" />
                                        </Col>
                                        <Col md={{ span: 2 }}>
                                          <Tooltip title={"Value entered for " + values["selected" + iitem.parameter_level_1].length + " out of " + iitem.label1Details.length + " items under " + iitem.display_parameter_level_1} placement="top">

                                            <p>
                                              {values["selected" + iitem.parameter_level_1].length > 0 && (
                                                <span className="countRowNumberFinance">
                                                  {values["selected" + iitem.parameter_level_1].length}
                                                  &nbsp;&nbsp; of &nbsp;&nbsp;
                                                  {iitem.label1Details.length}
                                                </span>
                                              )}
                                              {values["selected" + iitem.parameter_level_1].length == 0 && (
                                                <span className="countRowNumberForRedFinance">
                                                  {values["selected" + iitem.parameter_level_1].length}
                                                  &nbsp;&nbsp; of &nbsp;&nbsp;
                                                  {iitem.label1Details.length}
                                                </span>
                                              )}
                                            </p>
                                          </Tooltip>
                                        </Col>
                                      </Row>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Row>
                                        <Col md={{ span: 12 }}>
                                          <MultiSelect id={j} options={iitem.label1Details} value={values["selected" + iitem.parameter_level_1]} onChange={(e) => {
                                            ////
                                            handleSetInitialValue(iitem, e, values[iitem.parameter_level_1])
                                          }} labelledBy={"Select"} />
                                        </Col>
                                      </Row>
                                      <FieldArray name={iitem.display_parameter_level_1}>
                                        {values[iitem.parameter_level_1].length > 0 && (
                                          <TableContainer>
                                            <Table aria-label="collapsible table" size="small">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell className="tableNormalHeading">Parameter Name</TableCell>
                                                  {noOfColumn.map((year, i) => (
                                                    <TableCell className="tableNormalHeading">Year {year}</TableCell>
                                                  ))}
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {values[iitem.parameter_level_1].map((iteml1, k) => (
                                                  <TableRow key={k}>
                                                    <TableCell style={table_noborder}>{iteml1.display_parameter_level_2}</TableCell>
                                                    {noOfColumn.map((year, j) => (



                                                      <TableCell style={table_noborder}>
                                                        <FormikTextField
                                                          id="filled-error-helper-text standard-basic"
                                                          label={"Year " + (j + 1)}
                                                          name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}
                                                          //value ={values[`${j+1}.${iitem.display_parameter_level_1[k].'year'}`]}
                                                          // value ={values[(j+1)+iteml1[j]].year}
                                                          value={financeDetailsDynamicInitialValue}
                                                          margin="normal"
                                                          inputProps={{ maxLength: 10 }}
                                                          onChange={handleChange}
                                                          //type="number"
                                                          onBlur={() => {
                                                            ////
                                                            // if ((j + 1) == 2) {
                                                            //   handleSubmitExtendedForm(values);
                                                            // }

                                                            if (loanType == 1) {
                                                              if ((j + 1) == 2) {
                                                                handleSubmitExtendedForm(values);
                                                              }
                                                            } else {
                                                              if ((j + 1) == 3) {
                                                                handleSubmitExtendedForm(values);
                                                              }
                                                            }

                                                          }}
                                                          disabled={formDisabled}
                                                        //  validate  ={ (e)=>handleError(e)}
                                                        />
                                                        {     // <ErrorMessage component="span"  name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}  />
                                                        }

                                                      </TableCell>
                                                    ))}
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        )}
                                      </FieldArray>
                                    </AccordionDetails>
                                  </Accordion>
                                  
                                ))}
                              </AccordionDetails>
                              {/* ) : (<div></div>) }  */}
                              
                            </Accordion>
                            ) : !arrvalues.includes(item.display_parameter_head) ? (<Accordion expanded={expanded === item.display_parameter_head} onChange={handleChangeAccro(item.display_parameter_head)}>
                            {/* {(arrvalues.includes(item.display_parameter_head) && toggle === true) ? ( */}
                            <AccordionSummary
                              className="accordian_head"
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header">
                              <Typography text={item.display_parameter_head} styleType="accordianTitle" />
                            </AccordionSummary>
                            <AccordionDetails>
                              {item.headerDetails.map((iitem, j) => (
                                <Accordion expanded={innerExpanded === iitem.display_parameter_level_1} onChange={handleChangeInnerAccro(iitem.display_parameter_level_1)}>
                                  <AccordionSummary
                                    className="accordian_subhead"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header">
                                    <Row style={{ width: "100%" }}>
                                      <Col md={{ span: 10 }}>
                                        <Typography text={iitem.display_parameter_level_1} styleType="accordiansubTitle" />
                                      </Col>
                                      <Col md={{ span: 2 }}>
                                        <Tooltip title={"Value entered for " + values["selected" + iitem.parameter_level_1].length + " out of " + iitem.label1Details.length + " items under " + iitem.display_parameter_level_1} placement="top">

                                          <p>
                                            {values["selected" + iitem.parameter_level_1].length > 0 && (
                                              <span className="countRowNumberFinance">
                                                {values["selected" + iitem.parameter_level_1].length}
                                                &nbsp;&nbsp; of &nbsp;&nbsp;
                                                {iitem.label1Details.length}
                                              </span>
                                            )}
                                            {values["selected" + iitem.parameter_level_1].length == 0 && (
                                              <span className="countRowNumberForRedFinance">
                                                {values["selected" + iitem.parameter_level_1].length}
                                                &nbsp;&nbsp; of &nbsp;&nbsp;
                                                {iitem.label1Details.length}
                                              </span>
                                            )}
                                          </p>
                                        </Tooltip>
                                      </Col>
                                    </Row>

                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Row>
                                      <Col md={{ span: 12 }}>
                                        <MultiSelect id={j} options={iitem.label1Details} value={values["selected" + iitem.parameter_level_1]} onChange={(e) => {
                                          ////
                                          handleSetInitialValue(iitem, e, values[iitem.parameter_level_1])
                                        }} labelledBy={"Select"} />
                                      </Col>
                                    </Row>
                                    <FieldArray name={iitem.display_parameter_level_1}>
                                      {values[iitem.parameter_level_1].length > 0 && (
                                        <TableContainer>
                                          <Table aria-label="collapsible table" size="small">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="tableNormalHeading">Parameter Name</TableCell>
                                                {noOfColumn.map((year, i) => (
                                                  <TableCell className="tableNormalHeading">Year {year}</TableCell>
                                                ))}
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {values[iitem.parameter_level_1].map((iteml1, k) => (
                                                <TableRow key={k}>
                                                  <TableCell style={table_noborder}>{iteml1.display_parameter_level_2}</TableCell>
                                                  {noOfColumn.map((year, j) => (



                                                    <TableCell style={table_noborder}>
                                                      <FormikTextField
                                                        id="filled-error-helper-text standard-basic"
                                                        label={"Year " + (j + 1)}
                                                        name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}
                                                        //value ={values[`${j+1}.${iitem.display_parameter_level_1[k].'year'}`]}
                                                        // value ={values[(j+1)+iteml1[j]].year}
                                                        value={financeDetailsDynamicInitialValue}
                                                        margin="normal"
                                                        inputProps={{ maxLength: 10 }}
                                                        onChange={handleChange}
                                                        //type="number"
                                                        onBlur={() => {
                                                          ////
                                                          // if ((j + 1) == 2) {
                                                          //   handleSubmitExtendedForm(values);
                                                          // }

                                                          if (loanType == 1) {
                                                            if ((j + 1) == 2) {
                                                              handleSubmitExtendedForm(values);
                                                            }
                                                          } else {
                                                            if ((j + 1) == 3) {
                                                              handleSubmitExtendedForm(values);
                                                            }
                                                          }

                                                        }}
                                                        disabled={formDisabled}
                                                      //  validate  ={ (e)=>handleError(e)}
                                                      />
                                                      {     // <ErrorMessage component="span"  name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}  />
                                                      }

                                                    </TableCell>
                                                  ))}
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      )}
                                    </FieldArray>
                                  </AccordionDetails>
                                </Accordion>
                                
                              ))}
                            </AccordionDetails>
                            {/* ) : (<div></div>) }  */}
                            
                          </Accordion>) : null
                          ))}
                        </div>
                      </Col>

                      <Col md={{ span: 10 }}></Col>

                      <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                      </Col>



                    </Row>


                  ) : (<>
                    <Row style={{
                      marginTop: "1%",
                      marginLeft: "0%"
                    }}>
                      <Col md={{ spam: 6 }}>



                        <table border="1" style={{ width: "98%" }} className="styled-table" >

                          {financeParameterDetails.slice(0, 1).map((item, i) => (
                            <>
                              <thead style={{ "fontWeight": "bold", "fontSize": "22px", "color": "rgb(114, 84, 163);" }}>
                                <tr colspan="4" >
                                  <span style={{ padding: "10px", color: "rgb(114, 84, 163)" }}>
                                    {item.display_parameter_head}
                                  </span>
                                </tr>
                              </thead>
                              <tbody >
                                {item.headerDetails.map((iitem, j) => (
                                  <>
                                    <tr>
                                      <th className="second_header" colspan="4" style={{ fontStyle: "bold", fontSize: "16px" }}>{j + 1 + " . " + iitem.display_parameter_level_1}</th>
                                    </tr>
                                    {values[iitem.parameter_level_1].map((iteml1, k) => (
                                      <tr>
                                        <th style={{ fontSize: "13px", fontWeight: "400" }}>{iteml1.display_parameter_level_2}</th>
                                        {noOfColumn.map((year, j) => (
                                          <td className="finance_preview_td" >
                                            <FormikTextField
                                              id="filled-error-helper-text standard-basic"
                                              label={"Year " + (j + 1)}
                                              name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}
                                              //value ={values[`${j+1}.${iitem.display_parameter_level_1[k].'year'}`]}
                                              // value ={values[(j+1)+iteml1[j]].year}
                                              value={financeDetailsDynamicInitialValue}
                                              margin="normal"
                                              inputProps={{ maxLength: 10 }}
                                              onChange={handleChange}
                                              //type="number"
                                              onBlur={() => {
                                                //

                                                // if (loanType == 1) {
                                                //   if ((j + 1) == 2) {
                                                //     handleSubmitExtendedForm(values);
                                                //   }
                                                // } else {
                                                //   if ((j + 1) == 3) {
                                                //     handleSubmitExtendedForm(values);
                                                //   }
                                                // }

                                              }}
                                              disabled={formDisabled}
                                              InputProps={{
                                                disableUnderline: true
                                              }}
                                            //  validate  ={ (e)=>handleError(e)}
                                            />
                                          </td>
                                        ))}

                                      </tr>
                                    ))}
                                  </>


                                ))}

                              </tbody>
                              <tbody >

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>PAT</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {patCalculations1Year1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {patCalculations1Year2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {patCalculations1Year3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Gross Profit</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{grossProfitYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {grossProfitYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {grossProfitYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Business Marging</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{businessMarginYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {businessMarginYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {businessMarginYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>PBDIT</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{pbditYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {pbditYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {pbditYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>PBIT</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{pbtYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {pbtYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {pbtYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Cash Profit</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{cashProfitYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {cashProfitYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {cashProfitYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Cash Profit</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{cashProfitYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {cashProfitYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {cashProfitYear3Value(values)}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))}

                          {financeParameterDetails.slice(1).map((item, i) => (
                            <>
                              <thead style={{ "fontWeight": "bold", "fontSize": "22px", "color": "rgb(114, 84, 163);" }}>
                                <tr ><span style={{ padding: "10px", color: "rgb(114, 84, 163)" }}>
                                  {item.display_parameter_head}
                                </span>
                                </tr>
                              </thead>
                              <tbody>
                                {item.headerDetails.map((iitem, j) => (
                                  <>
                                    <tr>
                                      <th colspan="4" className="second_header" style={{ fontStyle: "bold", fontSize: "15px" }}>{j + 1 + " . " + iitem.display_parameter_level_1}</th>
                                    </tr>
                                    {values[iitem.parameter_level_1].map((iteml1, k) => (
                                      <tr>
                                        <th style={{ fontSize: "13px", fontWeight: "400" }}>{iteml1.display_parameter_level_2}</th>
                                        {noOfColumn.map((year, j) => (
                                          <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>

                                            <FormikTextField
                                              id="filled-error-helper-text standard-basic"
                                              label={"Year " + (j + 1)}
                                              name={`${iitem.parameter_level_1}.${k}.year${j + 1}value`}
                                              //value ={values[`${j+1}.${iitem.display_parameter_level_1[k].'year'}`]}
                                              // value ={values[(j+1)+iteml1[j]].year}
                                              value={financeDetailsDynamicInitialValue}
                                              margin="normal"
                                              inputProps={{ maxLength: 10 }}
                                              onChange={handleChange}
                                              //type="number"
                                              onBlur={() => {
                                                //

                                                if (loanType == 1) {
                                                  if ((j + 1) == 2) {
                                                    handleSubmitExtendedForm(values);
                                                  }
                                                } else {
                                                  if ((j + 1) == 3) {
                                                    handleSubmitExtendedForm(values);
                                                  }
                                                }
                                              }}
                                              disabled={formDisabled}
                                              InputProps={{
                                                disableUnderline: true
                                              }}
                                            //  validate  ={ (e)=>handleError(e)}
                                            />
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </>


                                ))}

                              </tbody>

                              <tbody>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Networth Total</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{netWorthYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {netWorthYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {netWorthYear3Value(values)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Total Promoter Funds</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{totalPromoterFundsYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalPromoterFundsYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalPromoterFundsYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Total Outside Debts</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{TotalOutsideDebtsYear1Value(values) + 786}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {TotalOutsideDebtsYear2Value(values) + 55}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {TotalOutsideDebtsYear3Value(values) + 98}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Total Funds Employed</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{totalFundEmployedYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalFundEmployedYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalFundEmployedYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>Non-Current Assets</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>{totalNoncurrentassetsYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalNoncurrentassetsYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalNoncurrentassetsYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}> Current Assets</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}> Current Liabilities</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalCurrenAssetYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>
                                    Working Capital Liabilities</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>
                                    Gross Working Capital </th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {GrossWorkingCapitalYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {GrossWorkingCapitalYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {GrossWorkingCapitalYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>
                                    Working Capital Liabilities</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {totalWorkingCapitalLiabilitiesYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>
                                    Net Working Capital</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {netWorkingCapitalYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {netWorkingCapitalYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {netWorkingCapitalYear3Value(values)}
                                  </td>
                                </tr>

                                <tr>
                                  <th style={{ "fontWeight": "bold", "fontSize": "16px", "color": "rgb(114, 84, 163);" }}>
                                    Application of funds</th>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {applicationOfFundsYear1Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {applicationOfFundsYear2Value(values)}
                                  </td>
                                  <td className="finance_preview_td" style={{ width: "50px", textAlign: "center" }}>
                                    {applicationOfFundsYear3Value(values)}
                                  </td>
                                </tr>
                              </tbody>

                            </>
                          ))}
                        </table>

                      </Col>
                      <Col md={{ span: 6 }} className="fileviewer">
                        <Dropdown
                          placeholder="Select Document"
                          name="area"
                          fluid
                          search
                          selection
                          //onBlur={() => setFieldTouched("area", true)}
                          onChange={async (e, { value, text,file_type,options }) => {
                            
                            console.log(options, value);
                            // if(options.length>1){
                              options.map(item=>{
                                if(item.value === value){
                                  console.log("\n \n hsshous")
                                  handleshowhidepdf(
                                    item.value,
                                    item.file_type,
                                    item
                                  )
                                  // setPrieviewValues(value,item.file_type);
                                }
                              });
                            // }
                           
                          }}
                          options={documentArray}

                        /><br/><br/><br/><br/><br/><br/>
                        <FileViewer
                          fileType={previewType}
                          filePath={previewFile}
                          //  errorComponent={CustomErrorComponent}
                          className='banking_fileviewer'
                          // errorComponent={CustomErrorComponent}
                          // onError={onError}
                          style={{
                            marginTop: '150px'
                          }}
                        />
                        {/* <FileViewer
                          // fileType={docType}
                          // filePath={docPath}
                          fileType={previewType}
                          filePath={previewFile}
                          // fileType={""}

                          // filePath={""}
                          //  errorComponent={CustomErrorComponent}
                          className="banking_fileviewer"
                        /> */}

                      </Col>
                    </Row>





                  </>)
                  }

                </form>

              );

            }}

          </Formik>
        )}


        <Dialog
          disableBackdropClick
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <Modal.Header className="modalHead" closeButton onClick={handleClose} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
            <Modal.Title className="modalHeader cpModal">Digital Pull</Modal.Title>
          </Modal.Header>
          {/* <MuiDialogTitle >
            <p className="dialogHeader">Digital Pull</p>
            {handleClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>) : null}
          </MuiDialogTitle> */}

          <div className="">
            <DialogContent style={{ marginTop: "10px" }}>
              {showConMsg && (
                <DialogTitle id="alert-dialog-title" className="itr_mod" ><p className="modaltext">Please Enter Mobile Number?</p></DialogTitle>)}

              {showConMsg && (
                <DialogActions style={{ padding: "0px" }}>
                  <Col md={{ span: 6 }}>
                    <Typography

                      styleType="B"
                      className="promoter_mobile"
                    /> {otpBtn && (
                      <span className="hidden_number">
                        {/* You entered : {values.mobile} */}
                      </span>
                    )}
                    <List component="div" disablePadding style={{ marginTop: "-20px", marginLeft: "-10rem" }}>
                      {otpBtn === false && (
                        <>

                          <ListItem>
                            {/* <PhoneInput country="in"
                                         enableSearch={false} onChange={(val) => selectCountry(val)} /> */}
                            <TextField
                              id="filled-error-helper-text standard-basic "
                              style={{ width: "30%" }}

                              name="mobile"
                              type="number"

                              onBlur={onBlurMobile}
                              // InputProps={{
                              //   className: values.mobile
                              //     ? classes.manorma
                              //     : null,
                              // }}
                              inputProps={{
                                maxLength: 10,
                              }}

                            />
                          </ListItem>
                          <ListItem>
                            <span style={{ color: "red", fontSize: "10px" }}>{mobileExistsMsg}</span>
                          </ListItem>
                        </>
                      )}


                    </List>
                  </Col>

                  <Button onClick={() => {
                    setShowConMsg(false);
                    //setequiOrCriff(true);
                    verifyPromoterMobile();
                    setOtpBtn(true);
                    setHideMobileResenBtn(true);
                    setDisable(true);

                  }}
                    className="backBtn backBtn1"
                    variant="outlined"
                    disabled={disable}

                    color="primary" style={{ marginRight: "2%", cursor: "pointer" }}>Continue</Button>

                </DialogActions>

              )}
              {otpBtn === true && (
                <DialogActions style={{ padding: "0px" }}>
                  <Col md={{ span: 6 }}>
                    <List component="div" disablePadding style={{ marginTop: "-15px", marginLeft: "-10rem" }}>
                      <>
                        <p className="otpsent">Enter OTP</p>
                        <ListItem>
                          <InputOtp onChange={verifyMobileOtp} />
                          {hideMobileResenBtn === true && (
                            <OtpTimer
                              seconds={otpSeconds}
                              minutes={otpMinutes} //otpMinutes
                              style={{ color: "blue" }}
                              resend={resendOtpEmail}
                              text=" "
                              buttonColor={"white"}
                              background={"#007bff"}
                            />
                          )}
                          {/*<Button onClick={getBackToMobile} style={{ marginTop: "-1%" }}>
                                         <i
                                           className="far fa-edit"
                                           style={{ fontSize: "15px" }}
                                         ></i>
                                       </Button>*/}


                          {ifMobileOtpInvald === true && (
                            <span style={{ color: "red", marginLeft: "-15em", marginTop: "38px" }}>
                              Invalid Otp{" "}
                            </span>
                          )}
                        </ListItem>
                      </>
                    </List>
                  </Col>

                </DialogActions>

              )}
            </DialogContent>
          </div>
        </Dialog>

        <Dialog
          disableBackdropClick
          open={openCredentialForm}
          onClose={handleCloseOpenCredentialForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <MuiDialogTitle>
            <p className="dialogHeader">ITR Login</p>
            {handleCloseOpenCredentialForm ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseOpenCredentialForm}>
                <CloseIcon style={{ marginTop: "-10px", color: "white", fontWeight: "800", cursor: "pointer" }} />
              </IconButton>) : null}
          </MuiDialogTitle>
          <DialogTitle id="alert-dialog-title" ><p className="modalText">Please enter your ITR credential Details for fetching the ITR data</p></DialogTitle>
          {
            credLoader === false ? (
              <DialogContent>
                <Formik
                  enableReinitialize
                  initialValues={itrDetailsData}
                  validationSchema={itrForm}
                  onSubmit={handleCallITRAPI}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="modelDivPadding">
                          <Row>
                            <Col md={{ span: 6 }}>
                              <Typography text="User Name" styleType="B" />
                              <TextField id="filled-error-helper-text standard-basic"
                                label="User Name"
                                name="userName"
                                value={values.userName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formDisabled}
                              />
                              {touched.userName && errors.userName ? (<span className="error_message_itr">{errors.userName}</span>) :
                                (<span>{" "}</span>)}
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text="Password" styleType="B" />
                              <TextField id="filled-error-helper-text standard-basic"
                                label="Password"
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formDisabled}
                              />

                              {touched.password && errors.password ? (<span className="error_message_itr">{errors.password}</span>) : (<span>
                                {" "} </span>)}
                            </Col>

                          </Row>
                        </div>
                        <div className="modelDivPadding">
                          <Button onClick={handleCloseOpenCredentialForm} className="backBtn"
                            variant="outlined"
                            color="primary">Cancel</Button>

                          <Button onClick={handleSubmit} className="backBtn"
                            variant="outlined"
                            color="primary" style={{ marginRight: "2%", marginBottom: "3%" }}>Submit</Button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </DialogContent>

            ) : (
              <div style={{ textAlign: 'center', margin: '5%' }}>
                <Loader type={LoaderColor.type} color={LoaderColor.color} />
              </div>
            )}

        </Dialog>
      </div>
    </Container >
  );
}
export function TextError(props) {
  return <div >{props.children}</div>;
}