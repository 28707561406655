import React, { useState } from "react";
import './index.css';
import axios from "axios";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import validator from 'validator';


export default function PerfiosPull(props) {

  React.useEffect(() => {

  }, [])

  const [emailError, setEmailError] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const validateEmail = (e) => {
    
    var email = e.target.value

    if (true) {
      setEmailValue(email)
    } else {
      setEmailError('Enter valid Email!')
    }
  }


  const generateTxnId = async () => {
    let r = Math.random().toString(36).substring(5);
    //console.log("random", r);
    return r;
  }

  const callPerfiosApi = async () => {
    
    let txtnId = await generateTxnId();
    let callBackurlLocal = apiCalling.frontCaller+ '/hash/bankDataPullResponse?txnId=' + txtnId;
    // let callBackurlDev = apiCalling.frontCaller + '/hash/bankDataPullResponse?txnId=' + txtnId;
    let callBackurlDev = apiCalling.frontCaller + '/hash/bankDataPullResponse?txnId=' + txtnId;
    //console.log(callBackurlLocal)

    // //console.log('http://164.52.207.42/alpha/server.php/apiperofios?app_name=start&&email=sample@gmail.com&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2020-01&&ymt=2020-06&&rUrl='+callBackurlDev+'&&rCallback='+callBackurlDev+'&&lType=business&&dest=choice&&create=Prem&&modify=Prem');

    //console.log(apiCalling.thirdParty + 'server.php/apiperofios?app_name=start&&email=' + logindata.email + '&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2020-01&&ymt=2020-06&&rUrl=' + callBackurlDev + '&&rCallback=' + callBackurlDev + '&&lType=business&&dest=choice&&create=' + localData.user_id + '&&modify=' + localData.user_id + '');

    // await axios.get(apiCalling.thirdParty + 'server.php/apiperofios?app_name=start&&email=' +1+ '&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2021-01&&ymt=2021-06&&rUrl=' + callBackurlDev + '&&rCallback=' + callBackurlDev + '&&lType=business&&dest=choice&&create=' +1+ '&&modify=' + 1 + '').then((result) => {
    //   if (result.status === 200) {
    //     //console.log("result vvvvvvvvv", result);
    //     //   let domainUrl = "http://164.52.207.42";
    //     let domainUrl = "http://164.52.207.42";
    //     // let domainUrl = "https://platform.aagey.com/app/#/";
    //     console.log(domainUrl);
    //     let subDomain = result.data.data.result;
    //     let url = domainUrl + subDomain;
    //     window.open(url, '_blank');
    //     //console.log(result);
    //     console.log("result---------------->", result);
    //   }
    // }).catch(error => {
    //   console.log(error);
    // })

    let result = await axios.get(apiCalling.thirdParty + 'server.php/apiperofios?app_name=start&&email=' + emailValue + '&&txnId=' + txtnId + '&&lAmount=12000&&lDuration=6&&ymf=2021-01&&ymt=2021-06&&rUrl=' + callBackurlDev + '&&rCallback=' + callBackurlDev + '&&lType=business&&dest=choice&&create=' + 1 + '&&modify=' + 1 + '');
    console.log('---------------------------->', result);
    if (result.status === 200) {
      //console.log("result vvvvvvvvv", result);
      //   let domainUrl = "http://164.52.207.42";
      let domainUrl = "http://164.52.207.42";
      // let domainUrl = "https://platform.aagey.com/app/#/";
      console.log(domainUrl);
      let subDomain = result.data.data.result;
      let url = domainUrl + subDomain;
      window.open(url, '_blank');
      //console.log(result);
      console.log("result---------------->", result);
    }
  }


  return (
    <>
      <div>

        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <a className="navbar-brand" href="/#">Aagey</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ms-auto">

                <li className="nav-item">
                  <a className="nav-link" href="/#" style={{ fontSize: "24px" }} >About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>




        <section className="py-5 container1">

          <div className="container">
            <div style={{}}>

              <input type="text" id="userEmail" className="stand_alone_input"
                value={emailValue} placeholder="enter email"
                onChange={(e) => validateEmail(e)}></input>
              <br />
              <span style={{
                fontWeight: 'bold',
                color: 'red',
              }}>{emailError}</span>

            </div>
            <button className="button_standalone" onClick={() => callPerfiosApi()}>Perfios</button>
          </div>
        </section>


        <div>
          <table class="styled-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dom</td>
                <td>6000</td>
              </tr>
              <tr class="active-row">
                <td>Melissa</td>
                <td>5150</td>
              </tr>

            </tbody>
          </table>
        </div>


      </div>
    </>
  );

}

