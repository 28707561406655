import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss";
import Container from "@material-ui/core/Container";
import { LoaderColor } from "../../shared/constants";
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Loader from "react-loader-spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SaveIcon from "@material-ui/icons/Save";
import { TextField, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Dropdown, TextArea } from "semantic-ui-react";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { bankschema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "./style";
import { apiCalling } from "../adminConstants";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import { mdiPlaylistPlus, mdiRefresh } from "@mdi/js";
import DraftNext from "../../image/buttons/draftnext.png";
import Tooltip from "@material-ui/core/Tooltip";
import Back from "../../image/buttons/back.png";
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import graydraftnext from "../../image/buttons/graydraftnext.png";
import grayback from "../../image/buttons/grayback.png";
import manualentry from "../../image/pngicons/manualaddition.png";
import deleterec from "../../image/pngicons/delete.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { SnackPosition } from "../../shared/constants";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function AssetCategory(props) {
  const [bankList, setBankList] = React.useState([]);
  const classes = useStyles();
  const [AllBankType, setAllBankType] = React.useState([]);
  const [bankType, setAsset] = React.useState("");
  //const [sortOrder, setSortOrder] = React.useState("");
  const [table, settable] = React.useState(false);
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [showList, setShowList] = React.useState(true);
  const [loaderFlag, setLoaderFlag] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [format, setFormat] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const closeSuccessToast = () => {
    setsavenextmessage(false);
  };

  let [initialValue, setInitialValue] = React.useState({
    bank: "",
    banktypes: "",
    fname: "",
    p: "",
    sortorder: "",
    descrip: "",
    enable: 1,
    financier_flag: true,
  });
  const [statess, setStatess] = React.useState(false);
  const floatingBtn = () => {
    setStatess(!statess);
  };

  const handleOpenWCAddForm = () => {
    setAddFormButtonValue("Submit");
    setShowWCAddForm(true);
    setShowList(false);

    setInitialValue({
      bank: "",
      fname: "",
      sname: "",
      sortorder: "",
      descrip: "",
      enable: 1,
      financier_flag: true,
    });
  };

  const handleEdit = (data) => {
    
    setShowWCAddForm(true);

    setAddFormButtonValue("Update");

    setShowList(false);

    setInitialValue({
      ...data,
      // banktypes: ,
      bank: data.bank,
      fname: data.fname,
      sname: data.sname,
      sortorder: data.sortorder,
      descrip: data.descrip,
      enable: data.enable,
      financier_flag: true,
    });
  };

  const handleDelete = (item) => {
    
    let data = {
      bank_id: item.bank,
    };
    
    axios
      .post(apiCalling.coderscorner + `server.php/api/deleteBankById`, data)
      .then((res) => {
        if (res.data.status) {
          setFormat("success");
          setsavenextmessage(true);
          setSnackBarMsg(res.data.message);
          console.log("Data", res.data);
          setShowWCAddForm(false);
          setShowList(true);
          handleBankList();
        }
        
      })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  };

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    handleBankList();
    
  }, []);

  React.useEffect(() => {
    axios
      .post(apiCalling.coderscorner + `server.php/api/getAllbank`)
      .then((res) => {
        
        let allData = [];
        for (let j = 0; j < res.data.data.length; j++) {
          allData.push({
            key: res.data.data[j].name,
            value: res.data.data[j].id,
            text: res.data.data[j].name,
          });
        }
        setAllBankType(allData);
      })
      .catch((err) => console.log("error in fetching bank data", err));
  }, []);

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {
    props.handleBack();
  };

  const SaveasDraft = () => {
    //props.handlecheck();
    handleSubmiting();
  };

  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true,
    });
  };

  const handleBankList = async () => {
    const data = {
      enable: true,
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllbank`, data)
      .then((result) => {
        let empArray = [];
        if (result.data.status) {
          result.data.data.map((item) => {
            empArray.push({
              bank: item.id,
              fname: item.name,
              sname: item.short_name,
              sortorder: item.sort_order,
              descrip: item.description,
              enable: item.enable,
              financier_flag: true,
            });
          });
          setShowWCAddForm(false);
          setShowList(true);
          setLoaderFlag(false);
          console.log("EmpArray", empArray);
          setBankList(empArray);

          console.log("Hello", result.data);
        }
        setLoaderFlag(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlesubmitform = (values, { resetForm }) => {
    

    handleNextClick(values);

    console.log("subi", values);

    resetForm({
      bank: "",

      fname: "",
      sname: "",
      sortorder: "",
      descrip: "",
      enable: "",
      financier_flag: true,
    });
  };
  const handleNextClick = (data) => {
    
    console.log(data);

    setShowWCAddForm(false);
    setLoaderFlag(true);
    //  let eligibilityFlag = 0;
    /*  if (checkvalue === 1) {
       eligibilityFlag = 1;
     }  */

    let wcData = {
      //:data.banktypes,
      bank_id: data.bank,
      name: data.fname,
      short_name: data.sname,
      sort_order: data.sortorder,
      description: data.descrip,
      enable: data.enable,
      financier_flag: true,
    };

    console.log("data", data);
    axios
      .post(apiCalling.coderscorner + `server.php/api/bankAddOrEdit`, wcData)
      .then((res) => {
        if (res.data.status) {
          setFormat("success");
          setsavenextmessage(true);
          setSnackBarMsg(res.data.message);
          console.log(res.data);
          console.log("saved successfully...!!!");
          setShowWCAddForm(false);
          setShowList(true);
          handleBankList();
        }
      })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation ctm_set_div"
    >
      <div className="profile_table">
        <div className="profile_table_part">
          <table style={{ marginBottom: 30 }}>
            <thead>
              <tr>
                <th>
                  <h3>Upload Documents</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Button className="ctm_btn_set footer">Upload Here!</Button>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Add Appointment</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <Button className="ctm_btn_set footer">
                    Book Appointment
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className="close_view" onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );

  const data = {
    columns: [
      {
        label: "#",
        field: "slno",
        width: 150,
        //fontWeight: "600",
        //fontSize: "100%",
        //width: "20%",
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      /*  {
           label: "Asset Type",
           field: "assetType",
           width: 270,
       }, */
      {
        label: "Name",
        field: "fname",

        width: 200,
      },
      {
        label: "Short Name",
        field: "sname",
        width: 200,
      },
      {
        label: "Description",
        field: "descrip",
        width: 200,
      },
      {
        label: "Sort Order",
        field: "sortOrder",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],

    rows: bankList.map((values, i) => {
      return {
        slno: i + 1,
        //bank:values.bank_id,
        fname: values.fname,
        sname: values.sname,
        descrip: values.descrip,
        sortOrder: values.sortorder,
        //action: <Button onClick={handleOpenWCAddForm(values)}>Action</Button>
        action: (
          <div style={{ marginLeft: "-5px" }}>
            <Tooltip title="Edit Data" placement="bottom">
              {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
              <i className="fas fa-edit" style={{ fontSize: "15px" }} />
            </Button> */}
              <img
                className="actionBtn svgicon"
                src={manualentry}
                onClick={() => handleEdit(values)}
              />
            </Tooltip>
            <Tooltip title="Delete Data" placement="bottom">
              {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
              <i className="fas fa-minus" style={{ fontSize: "15px" }} />
            </Button> */}
              <img
                className="actionBtn svgicon"
                src={deleterec}
                onClick={() => handleDelete(values)}
              />
            </Tooltip>
          </div>
        ),
      };
    }),
  };

  return (
    <Container maxWidth="lg">
      <Snackbar
        open={savenextmessage}
        style={{ marginTop: "65px" }}
        autoHideDuration={SnackPosition.duration}
        onClose={closeSuccessToast}
        anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal,
        }}
      >
        <Alert
          onClose={() => {
            setsavenextmessage(false);
          }}
          severity={format}
          className="snackBar"
        >
          {snackBarMsg}
        </Alert>
      </Snackbar>

      <div className="mini-container" style={{ marginTop: 0, padding: "15px" }}>
        {loaderFlag == true && (
          <div style={{ textAlign: "center", marginTop: "15%" }}>
            <Loader type={LoaderColor.type} color={LoaderColor.color} />
          </div>
        )}
        {showList == true && loaderFlag == false && (
          <div>
            <Row className="content-header">
              <Col md={{ span: 10 }}>
                <Typography text="Bank Details" styleType="C" />
              </Col>
              <Col md={{ span: 2 }}>
                {/* <button className="btn btn-primary" onClick={() => handleOpenWCAddForm()} >
                  Add</button> */}
                <span className="image">
                  <img
                    className="navbutton backbutton"
                    src={blueadd}
                    onClick={() => handleOpenWCAddForm()}
                  />
                  <Tooltip title="Add" arrow>
                    <img
                      className="navgraybutton backbutton"
                      src={grayadd}
                      onClick={() => handleOpenWCAddForm()}
                    />
                  </Tooltip>
                </span>
              </Col>
            </Row>
            <Row className="datable" style={{ marginTop: "30px" }}>
              <Col md={{ span: 12 }}>
                <div>
                  <div>
                    <ReactHTMLTableToExcel
                      id="karzaTblBtn"
                      className="btn btn-info exportBtn"
                      table="karzaTbl"
                      filename="BanksLink"
                      sheet="BanksLink"
                      buttonText="Export excel"
                    />
                  </div>
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 10, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    id="karzaTbl"

                    // searchTop
                    // searchBottom={false}
                  />
                </div>
              </Col>
            </Row>
            <div className="btn_row_fixd">
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                {/* <Col style={{ padding: "0%" }}>
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navbutton' src={Back} onClick={handledata} />
                  </Tooltip>
                </Col>

                <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save as Draft & Next" arrow>
                    <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                  </Tooltip>
                </Col> */}
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img
                      className="navbutton"
                      src={Back}
                      onClick={handledata}
                    />
                    <Tooltip
                      title="Back"
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      arrow
                    >
                      <img
                        className="navgraybutton"
                        src={grayback}
                        onClick={handledata}
                      />
                    </Tooltip>
                  </span>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img
                      className="navbutton"
                      src={DraftNext}
                      onClick={SaveasDraft}
                    />
                    <Tooltip title="Save as Draft & Next" arrow>
                      <img
                        className="navgraybutton"
                        src={graydraftnext}
                        onClick={SaveasDraft}
                      />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        )}

        <Row>
          <Col md={{ span: 12 }}>
            {showWCAddForm == true && (
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={bankschema}
                onSubmit={handlesubmitform}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  resetForm,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  //  console.log("values in formik", values);
                  return (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="content-header"
                        style={{ position: "unset" }}
                      >
                        <Row>
                          <Col md={{ span: 10 }}>
                            <Typography text="Banks" styleType="C" />
                          </Col>

                          <Col md={{ span: 2 }}>
                            <div className="content-header-button ">
                              {/* <Button className="backBtn" variant="outlined" color="primary"
                                onClick={() => {
                                  resetForm();
                                  setShowWCAddForm(false);
                                  setShowList(true);

                                }} >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                            </Button> */}
                              <span className="image">
                                <img
                                  className="backbutton navbutton"
                                  src={Back}
                                  onClick={() => {
                                    resetForm();
                                    setShowWCAddForm(false);
                                    setShowList(true);
                                  }}
                                />
                                <Tooltip title="Back" arrow>
                                  <img
                                    className="backbutton navgraybutton"
                                    src={grayback}
                                    onClick={() => {
                                      resetForm();
                                      setShowWCAddForm(false);
                                      setShowList(true);
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <Row style={{ marginTop: "30px" }}>
                        <Col md={{ span: 6 }}>
                          <Typography text="Select Bank Type" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Bank Type"
                                id="banktypes"
                                name="banktypes"
                                fluid
                                search
                                selection
                                value={values.banktypes}
                                onBlur={() =>
                                  setFieldTouched("banktypes", true)
                                }
                                onChange={(e, { value }) => {
                                  setFieldValue("banktypes", value);
                                  // e.persist()
                                  setAsset(e.target.textContent);
                                }}
                                options={AllBankType}
                                className={
                                  errors.banktypes && touched.banktypes
                                    ? classes.required
                                    : values.banktypes == ""
                                    ? classes.reqired
                                    : classes.man
                                }
                              />
                            </ListItem>
                            {errors.banktypes &&
                              touched.banktypes &&
                              values.banktypes == "" && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "0.75rem",
                                    marginTop: "-7px",
                                  }}
                                >
                                  {errors.banktypes}
                                </div>
                              )}
                          </List>
                        </Col>

                        <Col md={{ span: 6 }}>
                          <Typography text="Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="fname"
                                label="Enter Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.fname && touched.fname && errors.fname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.fname
                                    ? classes.manorma
                                    : null,
                                }}
                                value={values.fname}
                                helperText={
                                  errors.fname && touched.fname && errors.fname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "3%" }}>
                        <Col md={{ span: 6 }}>
                          <Typography text="Short Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="sname"
                                label="Enter Short Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.sname && touched.sname && errors.sname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.sname
                                    ? classes.manorma
                                    : null,
                                }}
                                value={values.sname}
                                helperText={
                                  errors.sname && touched.sname && errors.sname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Sort order" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Sort order"
                                id="sortorder"
                                name="sortorder"
                                fluid
                                search
                                selection
                                value={values.sortorder}
                                onBlur={() =>
                                  setFieldTouched("sortorder", true)
                                }
                                onChange={(e, { value }) => {
                                  setFieldValue("sortorder", value);
                                  e.persist();
                                  //setSortOrder(e.target.textContent)
                                }}
                                options={entityoption}
                                className={
                                  errors.sortorder && touched.sortorder
                                    ? classes.required
                                    : values.sortorder == ""
                                    ? classes.reqired
                                    : classes.man
                                }
                              />
                            </ListItem>
                          </List>
                          {errors.sortorder &&
                            touched.sortorder &&
                            values.sortorder == "" && (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: 14,
                                  fontSize: 12,
                                  fontWeight: "600",
                                }}
                              >
                                {errors.sortorder}
                              </div>
                            )}
                        </Col>

                        <Col md={{ span: 6 }}>
                          <div style={{ marginTop: "20px" }}>
                            <Typography text="Enable" styleType="B" />
                          </div>
                          <List component="div" disablePadding>
                            <ListItem>
                              <label>
                                Yes
                                <input
                                  name="enable"
                                  type="radio"
                                  value={1}
                                  checked={values.enable == 1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </label>
                              <div style={{ width: "18px" }}></div>
                              <label>
                                No
                                <input
                                  name="enable"
                                  type="radio"
                                  value={0}
                                  checked={values.enable == 0}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </label>
                            </ListItem>
                            {errors.enable && touched.enable && (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: 14,
                                  fontSize: 12,
                                  fontWeight: "600",
                                }}
                              >
                                {errors.enable}
                              </div>
                            )}
                          </List>
                        </Col>

                        <Col md={{ span: 6 }}>
                          <Typography text="Description" styleType="B" />
                          <List component="div" style={{ padding: "2px" }}>
                            <ListItem style={{ padding: "5px 0px" }}>
                              <Col
                                md={{ span: 12 }}
                                style={{ padding: "0px 12px" }}
                              >
                                <TextArea
                                  id="filled-error-helper-text"
                                  name="descrip"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.descrip &&
                                    touched.descrip &&
                                    errors.descrip
                                      ? true
                                      : false
                                  }
                                  className={
                                    errors.descrip && touched.descrip
                                      ? classes.required
                                      : values.descrip !== ""
                                      ? classes.mano
                                      : null
                                  }
                                  helperText={
                                    errors.descrip &&
                                    touched.descrip &&
                                    errors.descrip
                                  }
                                  value={values.descrip}
                                />
                              </Col>
                            </ListItem>
                            {errors.descrip && touched.descrip && (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: 14,
                                  fontSize: 11,
                                  marginBottom: 5,
                                }}
                              >
                                {errors.descrip}
                              </div>
                            )}
                          </List>
                        </Col>
                      </Row>
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          <Col md={{ span: 2 }}>
                            <Button
                              className="updatebtn"
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              {addFormButtonValue}
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        </Row>
                      </Col>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
