import React, { useState } from "react";
import { loadCSS } from "fg-loadcss";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextField, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import { Formik } from "formik";
import { withStyles } from '@material-ui/core/styles'
import Loader from 'react-loader-spinner';
import {
  personalinfoFormschema,
  shareholderFormschema,
  personalinfoschema,
} from "../../shared/validations";
import Checkbox from '@material-ui/core/Checkbox';
import { useStyled } from "./styles";
import clsx from "clsx";
import Typography from "../../base_components/Typography";
import { type } from "../../shared/constants";
import OtpTimer from "otp-timer";
import InputOtp from "@onefifteen-z/react-input-otp";
import "./promoterindex.css";
import Tooltip from "@material-ui/core/Tooltip";
import Back from "../../image/buttons/back.png";
import TableCell from "@material-ui/core/TableCell";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Reset from '../../image/buttons/reset.png';
import SaveNext from '../../image/buttons/savenext.png';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import moment from 'moment';
import { LoaderColor } from '../../shared/constants';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SnackPosition } from "../../shared/constants";
import DocumentUpload from "../../components/DocumentUpload";
import grayback from '../../image/buttons/grayback.png';
import graysave from '../../image/buttons/graysave.png';
import grayreset from '../../image/buttons/grayreset.png';
import { ErrorCatch, apiCalling } from "../../shared/constants";

export default function PersonalInfo(props) {
  const classes = useStyled();
  const [primaryid, setid] = React.useState();
  const [companybusiness, setcompanyname] = React.useState();
  const [showform, setshowform] = React.useState(true);
  const [topform, settopform] = React.useState(true);
  const [header, setheader] = React.useState(true);
  const [table, settable] = React.useState(false);
  const [editForm, setEditForm] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [tableopen, settableopen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [datas, setdatas] = React.useState([]);
  const [response, setresponse] = React.useState([]);
  const [gender, setgender] = React.useState([]);
  const [nationality, setnational] = React.useState([]);
  const [education, seteducation] = React.useState([]);
  const [statename, setstatename] = React.useState([]);
  const [address, setaddress] = React.useState([]);
  const [area, setArea] = React.useState([]);
  const [govt, setgovt] = React.useState([]);
  const [fname, setfname] = React.useState();
  const [lname, setlname] = React.useState();

  const [checkvalue, setchecked] = React.useState("");
  // const [otp, setOtp] = React.useState(true);
  // const [resendotp, setresendotp] = React.useState(true);
  // const [timer, showTimer] = useState(false);
  const [otpBtn, setOtpBtn] = useState(false);
  const [otpMinutes, setOtpMinutes] = useState();
  const [otpSeconds, setOtpSeconds] = useState();
  //  const [businessId, setBusinessId] = useState();
  const [showTable, setShowTable] = useState(false);
  const [showAllButton, setShowAllButton] = useState(true);
  // const otpSubmit = () => {
  //   setOtpBtn(!otpBtn);
  // };
  // const resendEMOtp = () => { };
  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%"
  }
  const table_header = {
    fontWeight: "600",
    fontSize: "100%",
    width: "20%",
  };
  // const table_action = {
  //   textAlign: "center",
  //   fontWeight: "600",
  //   fontSize: "100%",
  //   width: "20%",
  // };
  const [mobileExistsMsg, setMobileExistMmsg] = useState();
  //const [datatable, setDatatable] = React.useState({});
  const [show, setshow] = React.useState(false);
  const [bsid, setBsId] = React.useState("");
  const [businessIdForDropDown, setBusinessIdForDropDown] = React.useState(0);
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  const [minimumdate, setminimumdate] = React.useState(); //3 years ago
  const [maximumdate, setmaximumdate] = React.useState();  //by default today date
  //const [allBusinessList, setAllBusinessList] = React.useState([]);
  const [counrty, setCountry] = useState();
  const [promomobile, setPromoMobile] = React.useState();
  const [hideMobileResenBtn, setHideMobileResenBtn] = useState(true);
  const [mobileVerifyIcon, setMobileVerifyIcon] = useState(false);
  const [ifMobileOtpInvald, setIfMobileOtpInvald] = useState(false);
  const [emailOtp, setEmailOtp] = React.useState(false);
  const [promoEmail, setPromoEmail] = React.useState();
  const [emaileExistsMsg, setemaileExistsMsg] = useState();
  const [emailVerifyIcon, setEmailVerifyIcon] = useState(false);
  const [hideEmailResenBtn, setHideEmailResenBtn] = useState(true);
  const [ifEmailOtpInvald, setIfEmailOtpInvald] = useState(false);
  // const [busId, setbusId] = React.useState();
  // const [businessname, setbusinessname] = React.useState();
  const [shareEnable, setShareEnable] = useState(true);
  const [businessOfPromoter, setBusinessIdOfPromoter] = useState(0);
  const [paginationHidden, setPaginationHidden] = useState(false);
  const [promoterList, setPromoterList] = React.useState([]);
  // const [promoterId, setPromoterId] = React.useState(0);
  // const [laonForm, setLoanForm] = React.useState(false);
  const [loarOrCheck, setloarOrCheck] = React.useState();
  const [businesslist, setbusinesslist] = React.useState([]);
  const [idofuser, setidofuser] = React.useState();
  const [karzaPanName, setKarzaPanName] = useState();
  const [karzaPanError, setKarzaPanError] = useState()
  const [shareErrorMsg, setShareErrorMsg] = useState("");
  const [shareErrShow, setShareErrShow] = useState(false);
  // const [mainButton, setmainButton] = React.useState(true);
  const [businessDropFlag, setbusinessDropFlag] = React.useState(false);
  const [hideBackBtn, sethideBackBtn] = useState(true);
  //const [formFlag, setFormFlag] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [showDocScreen, setShowDocScreen] = React.useState(false);

  /// formik initial values
  const [ediInitivalue, setEditInitialValue] = React.useState({
    company: "",
    id: "", firstname: "", pincode: "", date: "", area: "", lastname: "", panvalue: "", panname: "", nationality: "", mobile: "", director: "", email: "", address1: "", grcno: "", address2: "", govt: "", gender: "", education: "", fathername: "", mothername: "", spousename: "", addressproof: "", prop_type: ""
  });

  React.useEffect(() => {
    getPropertTypeMaster();
    
    getAllBusinessbyuser();
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    //Get session user Id  & RoleId
    //pass User Id and role Id to get related info.
    // setBsId(0);
    checkapply();
    if (props.PromoterID !== undefined) {
      getPromoterById(props.PromoterID);
      settopform(false);

    } else {
      settopform(true);
      sethideBackBtn(true);

    }

    if (props.PromoDoc === true || props.PromoDoc === false) {

      setShowDocScreen(true);
      setDropDown(false);
    }
    businessinfo();
    gendercheck();
    educationcheck();
    govtcheck();
    addresscheck();
    statecheck();
    nationalcheck();
    //  getAllBusinessById(); //getAllBusinessDetails
    getOtpTimer();
    // getAllPromoterByBusinessId(); ///All ptomoter by bus id
    //  getbusinessData();
    //getAllPromoterByBusinessIdFirst();
    loanOrEleg();
    validateincorporationdate();
    // checkForNewPromo();
  }, []);


  console.log("promoter id----------->", props.PromoterID)

  ///new {promo}

  // const [newPromo, setNewPromo] = React.useState(false)
  // const checkForNewPromo = () => {
  //   
  //   console.log(props.NewPromo);

  //   // return props.NewPromo;
  // }

  /// check which from
  const loanOrEleg = () => {
    if (checkvalue === "Loan Application") {
      settopform(false);
    } else {
      setEditForm(true)
    }
  }

  const [director, setdirector] = React.useState(true);
  // const handleChange = (event) => {
  //   setdirector(event.target.checked);
  // };

  const checkapply = () => {
    if (localStorage.getItem('check')) {
      const checkdata = localStorage.getItem('check'),
        checkform = JSON.parse(checkdata)
      //  console.log('lets check', checkform)
      setchecked(checkform)
    } else {
      setchecked("Check Eligibility");
    }
  }

  const businessinfo = () => {
    const company = localStorage.getItem("business"),
      companyname = JSON.parse(company);
    setcompanyname(companyname);
  };

  var nationalarray = [];

  const nationalcheck = async () => {
    const nationaldata = {
      token: "qwerty1234",
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllnationality`,
        nationaldata
      )
      .then((res) => {
        res.data.data.map((item, i) => {
          nationalarray.push({
            key: i,
            value: item.id,
            text: item.country,
          });
        });
        setnational(nationalarray);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  var statearray = [];
  const statecheck = async () => {
    const statedata = {
      token: "qwerty1234",
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllState`,
        statedata
      )
      .then((res) => {
        res.data.data.map((item, i) => {
          statearray.push({
            key: i,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        setstatename(statearray);
        //  console.log("first statearray", statearray);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  var genderarray = [];
  const gendercheck = async () => {
    const ggg = {
      token: "qwerty1234",
      type: "gender",
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        ggg
      )
      .then((res) => {
        res.data.data.map((item, i) => {
          // console.log("item", item);
          genderarray.push({
            key: i,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        setgender(genderarray);
        console.log("first response", res.data.data);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  var educationarray = [];

  const educationcheck = async () => {
    const educationdata = {
      token: "qwerty1234",
      type: "education_qualifications",
    };

    await axios.post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
      educationdata
    )
      .then((res) => {
        res.data.data.map((item, i) => {
          //console.log("item", item);
          educationarray.push({
            key: i,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        seteducation(educationarray);
        console.log("educationarray", educationarray);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  var addressarray = [];
  const addresscheck = async () => {
    const addressdata = {
      token: "qwerty1234",
      type: "address_proof",
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        addressdata
      )
      .then((res) => {
        res.data.data.map((item, i) => {
          // console.log("item", item);
          addressarray.push({
            key: i,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        setaddress(addressarray);
        console.log("addressarray", addressarray);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  var govtarray = [];
  const govtcheck = async () => {
    const govtdata = {
      token: "qwerty1234",
      type: "registration_certificates",
    };

    await axios
      .post(apiCalling.coderscorner + `server.php/api/getMastertableDetails`,
        govtdata
      )
      .then((res) => {
        res.data.data.map((item, i) => {
          govtarray.push({
            key: i,
            value: item.id,
            flag: item.name,
            text: item.name,
          });
        });
        setgovt(govtarray);
        console.log("govtarray", govtarray);
      })
      .catch((er) => {
        console.log("no data  ", er);
      });
  };

  //country code selection
  const selectCountry = (val) => {
    setCountry(val)

  }

  const handleNew = () => {
    setshowform(true);
    setShowAllButton(true)
    settopform(true);
    setShowTable(false);
    setbusinessDropFlag(true);
    sethideBackBtn(true)
    getBackToMobile();
    getBackToEmail();
    setheader(true);
    setEditInitialValue({
      id: "", firstname: "", director: "", pincode: "", date: "", area: "", lastname: "", panvalue: "", panname: "", nationality: "", mobile: "", email: "", address1: "", grcno: "", address2: "", govt: "", gender: "", education: "", fathername: "", mothername: "", spousename: "", addressproof: "",
    })
  }

  // const handleBack1 = () => {
  //   setShowAllButton(true);
  //   setShowTable(true)
  //   settopform(false);
  //   setEditForm(false);
  //   setEditInitialValue({
  //     id: "", firstname: "", pincode: "", director: "", date: "", area: "", lastname: "", panvalue: "", panname: "", nationality: "", mobile: "", email: "", address1: "", grcno: "", address2: "", govt: "", gender: "", education: "", fathername: "", mothername: "", spousename: "", addressproof: "",
  //   })
  // }

  const handleBack2 = () => {
    props.handleBack(2);
    // setheader(true);
    // setShowAllButton(true);
    // setShowTable(true)
    // settopform(false);
    // setloarOrCheck(false);
    // setbusinessDropFlag(true);
    // sethideBackBtn(true);
  }
  //#region DOB min and max date

  const validateincorporationdate = () => {
    let data = {
      parameter_name: "Date of Birth"
    }
    axios.post(apiCalling.coderscorner + 'server.php/api/getGeneralSettingByParameter', data).then(res => {
      //    console.log('date validation results', res.data.data);
      let maxyearparval = res.data.data[0].parameter_value;
      //  console.log('maxyearparval...........', maxyearparval)
      const now = moment();
      let todaydata = moment(now).format('YYYY-MM-DD');
      //  console.log('moment date...........', todaydata)
      setmaximumdate(todaydata)
      const year = now.subtract(maxyearparval, 'years');
      let substracteddate = moment(year).format('YYYY-MM-DD');
      //  console.log('letsubstracteddate...........', substracteddate)
      setminimumdate(substracteddate)
    })
  }
  //#endregion

  //#region   save first time promoter data
  const handleapplycheck = async (data) => {
    
    setLoaderFlag(true);
    setbusinessDropFlag(false);
    
    let elig_flag;
    if (checkvalue === "Loan Application") {
      elig_flag = 0;
    } else {
      elig_flag = 1;
    }
    let checkshare = {
      eligibility_flag: elig_flag,
      bus_id: businessOfPromoter,
      first_name: data.firstname,
      last_name: data.lastname,
      middle_name: data.middlename,
      share: data.share,
      director: Boolean(data.director),
      type: data.type,
      email: data.email,
      Mobile: data.mobile,
      country_code: 1,
      user_id: userId,
      created_by: created_by,
      property_id: data.prop_type

    };


    console.log(data.company);
    console.log("result", checkshare);
    let api_path = apiCalling.coderscorner + `server.php/api/addPromotorInfo`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/addPromotorInfo`,
        checkshare
      )
      .then((res) => {
        if (res.data.status === true) {
          props.handleCount();
          settopform(false)
          setdata(res.data.Data);
          setfname(res.data.Data.first_name);
          setlname(res.data.Data.last_name);
          setdirector(res.data.Data.director);
          setresponse(checkshare);
          setid(res.data.Data.primary_id);
          //   getAllPromoterByBusinessId(businessOfPromoter);
          console.log(res.data.message);
          setSnackBarMsg(res.data.message);
          setLoaderFlag(false);
          setbusinessDropFlag(true);
          sethideBackBtn(true);
          setsavenextmessage(true);
          props.handleBack(2);
          // if()
          //react Goodies
          return true;
        } else {
          setLoaderFlag(false);
          setSnackBarMsg(res.data.message);
          setsavenextmessage(true)
        }
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', checkshare, userId, userId);
        console.log("no data sorry ", error);
        return false;

      });
    settopform(false);
    settable(true);
    settableopen(true);
  };

  // const handleNextclicked = () => {
  //   console.log("clicked next");
  // };

  // const handleformshow = () => {
  //   setshow(false);
  // };

  // // console.log("click", data);
  // const handleSubmiting = () => {
  //   props.handleNext();
  // };

  // const SaveasDraft = () => {
  //   props.handleNext();
  // };

  // const handleSubmited = () => {
  //   setshow(true);
  // };

  // const handleSubmiteded = () => {
  //   setshow(true);
  //   settable(false);
  //   setheader(false);
  // };

  // const handleClickOpen = () => {
  //   settableopen(!tableopen);
  // };

  // const handlenew = () => {
  //   setshowform(true);
  //   settopform(true);
  //   setinitialValue({
  //     firstname: "",
  //     lastname: "",
  //     middlename: "",
  //     share: "",
  //     type: "",
  //     company: "",
  //     emails: "",
  //     mobiles: "",
  //   });
  // };

  const getPincodeData = async (data) => {
    let getPincode = {
      pincode: data,
      token: "qwerty1234",
    };
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
        getPincode
      )
      .then((res) => {
        // console.log("yeh we have pincode", res.data);
        return handleAllAreas(res.data.data);
      })
      .catch((er) => {
        console.log("no pincode sorry ", er);
      });
  };

  const handleAllAreas = (data) => {
    let allArea = [];
    for (let i = 0; i < data.length; i++) {
      allArea.push({
        key: data[i].area,
        value: data[i].id,
        text: data[i].area,
      });
    }
    return setArea(allArea);
  };

  // const handledata = () => {
  //   
  //   setCheckForNewProm(false);
  //   if (setCheckForNewProm) {
  //     props.handleBack(1)
  //   } else {
  //     props.handleBack();
  //   }
  // };

  const handlesubmitform = (values, { resetForm }) => {
    
    settopform(false);
    let booleanValue = handleapplycheck(values);
    if (booleanValue) {
      resetForm({
        firstname: "",
        lastname: "",
        middlename: "",
        share: "",
        type: "",
        company: bsid,
        mobile: "",
        email: "",
        motp: "",
        director: "",
        eotp: "",
      })
    }
    props.handleCount();

  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // const list = (anchor) => (
  //   <div
  //     className={clsx(classes.list, {
  //       [classes.fullList]: anchor === "top" || anchor === "bottom",
  //     })}
  //     role="presentation ctm_set_div"
  //   >
  //     <div className="profile_table">
  //       <div className="profile_table_part">
  //         <table style={{ marginBottom: 30 }}>
  //           <thead>
  //             <tr>
  //               <th>
  //                 <h3>Upload Documents</h3>
  //               </th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             <tr>
  //               <td>
  //                 <Button className="ctm_btn_set footer">Upload Here!</Button>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>

  //         <table>
  //           <thead>
  //             <tr>
  //               <th>
  //                 <h3>Add Appointment</h3>
  //               </th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             <tr>
  //               <td>
  //                 {" "}
  //                 <Button className="ctm_btn_set footer">
  //                   Book Appointment
  //                 </Button>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //       <Button className="close_view" onClick={toggleDrawer(anchor, false)}>
  //         <ChevronRight />
  //       </Button>
  //     </div>
  //   </div>
  // );

  // let initialValues;
  // if (checkvalue === "Loan Application") {
  //   // console.log("if");
  //   initialValues = {
  //     firstname: fname,
  //     pincode: "",
  //     date: "",
  //     area: "",
  //     lastname: lname,
  //     panvalue: "",
  //     panname: "",
  //     nationality: "",
  //     mobile: "",
  //     email: "",
  //     address1: "",
  //     grcno: "",
  //     address2: "",
  //     govt: "",
  //     gender: "",
  //     education: "",
  //     fathername: "",
  //     mothername: "",
  //     spousename: "",
  //     addressproof: "",
  //   };
  // } else {
  //   // console.log("eelse");
  //   initialValues = {
  //     firstname: lname,
  //     nationality: "",
  //     date: "",
  //     lastname: lname,
  //     panvalue: "",
  //     panname: "",
  //     mobile: "",
  //     email: "",
  //   };
  // }
  // const [initialValue1,ediIntitialValue]= useState(initialValues);

  let validators;
  if (checkvalue == "Loan Application") {
    //  console.log("ifff");
    validators = personalinfoFormschema;
  } else {
    // console.log("elseeeefr");
    validators = personalinfoschema;
  }

  // console.log("lname", lname);
  // console.log("primaryid", primaryid);
  // console.log("fname", fname);
  // console.log("afterlname", lname);
  // console.log("dddd", data.last_name);

  //Notusing anywhere
  //const [bssid,setBssID] = useState();
  let bssid;
  // const getAllBusinessById = async () => {
  //    
  //   let data = {
  //     bus_id: 198,
  //   };
  //  await axios
  //     .post(
  //       `http://164.52.207.42/delta/server.php/api/getAllbusinessInfos`,
  //       data
  //     )
  //     .then((result) => {
  //       console.log(result);
  //       let listData = [];
  //       result.data.data.map((item, i) =>
  //         listData.push({
  //           key: i,
  //           id: item.id,
  //           value: item.id,
  //           text: item.name,
  //         })
  //       );
  //      // setBssID(listData[0].id)
  //       bssid=listData[0].id;
  //       setAllBusinessList(listData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //mobile and email otp form show and hide
  const onBlurMobile = (e) => {

    let val = e.target.value;
    let len = val.toString().length;
    // console.log(len);
    if (len == 10) {
      verifyPromoterMobile(val);
      setPromoMobile(val);
    }
  };
  const [verifiedIcon, setVerifiedIcon] = useState(false);


  /// verifying promoters Mobile number
  const verifyPromoterMobile = async (mobilenumber) => {
    
    let data = {
      type: "mobile",
      value: mobilenumber,
      form_id: 113,
    };
    let api_path = apiCalling.coderscorner + `server.php/api/promoterOtp`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/promoterOtp`, data)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === false) {
          setMobileExistMmsg(result.data.message);
        } else if (result.data.status == 101) {
          setVerifiedIcon(true);
        }
        else {
          setOtpBtn(!otpBtn);
          setMobileExistMmsg("");
        }
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };

  /// verifying mobile otp for promoter
  const verifyMobileOtp = async (e) => {
    let eotp = e;
    let len = eotp.length;
    if (len == 6) {
      let data = {
        otp: eotp,
        value: promomobile,
        form_id: 113,
      };
      let api_path = apiCalling.coderscorner + `server.php/api/verifyOtp`;
      await axios
        .post(apiCalling.coderscorner + `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            setHideMobileResenBtn(false);
            setMobileVerifyIcon(true);
            setIfMobileOtpInvald(false);
            enableShare();
          } else {
            setIfMobileOtpInvald(true);
          }
        })
        .catch((error) => {
          ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
          console.log(error);
        });
    }
  };

  //resend otp for mobile
  const redendOtpMobile = async () => {
    let data = {
      value: promomobile,
      type: "mobile",
      "form_id": 113
    };
    let api_path = apiCalling.coderscorner + `server.php/api/resendPromoterOtp`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/resendPromoterOtp`,
        data
      )
      .then((result) => {
        console.log(result.data);
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };

  const getBackToMobile = () => {
    setOtpBtn(false);
    setHideMobileResenBtn(true);
    setMobileVerifyIcon(false);
  };


  // Verifyying Promoters Email  starts
  const onBlurEmail = (e) => {
    let val1 = e.target.value;
    if (
      (val1.includes("@") && val1.includes(".") && val1.includes("com")) ||
      val1.includes("in") ||
      val1.includes("net") ||
      val1.includes("info") ||
      val1.includes("org") ||
      val1.includes("gov") ||
      val1.includes("edu")
    ) {
      verifyPromoterEmail(val1);
      setPromoEmail(val1);
    }
    let val = e.target.value;
  };

  /// email get otp Api call
  const [verifiedIconEmail, setVerifiedIconEmail] = useState(false);
  const verifyPromoterEmail = async (getemail) => {
    let data = {
      type: "email",
      value: getemail,
      form_id: 113,
    };
    let api_path = apiCalling.coderscorner + `server.php/api/promoterOtp`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/promoterOtp`, data)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === false) {
          setemaileExistsMsg(result.data.message);
        } else if (result.data.status == 101) {
          setVerifiedIconEmail(true);
        } else if (result.data.status === true) {
          setEmailOtp(true);
          setemaileExistsMsg("");
        }
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };
  /// verifying email otp

  //verifying user otp
  const verifyEmialOtp = async (e) => {
    let eotp = e;
    let len = eotp.length;
    if (len == 6) {
      let data = {
        value: promoEmail,
        form_id: 113,
        otp: eotp,
      };
      let api_path = apiCalling.coderscorner + `server.php/api/verifyOtp`;
      await axios
        .post(apiCalling.coderscorner + `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.status) {
            setHideEmailResenBtn(false);
            setEmailVerifyIcon(true);
            setIfEmailOtpInvald(false);
            enableShare();
          } else {
            setIfEmailOtpInvald(true);
          }
        })
        .catch((error) => {
          ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
          console.log(error);
        });
    }
  };

  //get business data for reteiving promoter
  // const getbusinessData = () => {
  //   const getbusinessid = localStorage.getItem("businessId");
  //   var id = JSON.parse(getbusinessid);
  //   setbusId(getbusinessid);
  //   const getbusinessname = localStorage.getItem("businessForm");
  //   var checkbusinessname = JSON.parse(getbusinessname);
  //   setbusinessname(checkbusinessname.businessName);
  // };

  /// resend otp for  email
  const resendOtpEmail = async () => {
    let data = {
      type: "email",
      value: promoEmail,
      form_id: 113
    };
    let api_path = apiCalling.coderscorner + `server.php/api/resendPromoterOtp`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/resendPromoterOtp`,
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };

  const getBackToEmail = () => {
    setEmailOtp(false);
    setEmailVerifyIcon(false);
    setHideEmailResenBtn(true);
  };

  const enableShare = () => {
    if (mobileVerifyIcon || emailVerifyIcon) {
      setShareEnable(false)
    }
  }

  ///Otp time as per general setting
  const getOtpTimer = async () => {
    let data = {
      parameter_name: "OTP TIMER",
    };
    let api_path = apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then((result) => {
        console.log(result.data.data[0]);
        let data = result.data.data[0].parameter_value;
        console.log("timer", data);
        convertTimerToInt(data);
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };

  //conversion for Otp timer into
  const convertTimerToInt = (timer) => {
    let newTimer = parseInt(timer);
    var minutes = Math.floor(newTimer / 60);
    var seconds = newTimer - 60 * minutes;
    setOtpMinutes(minutes);
    setOtpSeconds(seconds);
  };
  const setBusinessIdForPro = (iddd) => {
    //  
    setBusinessIdOfPromoter(iddd);
    getBusinessbybusinessId(iddd)
    //getAllPromoterByBusinessId(iddd);
  };


  //get business by businessid
  const [userId, setUserId] = React.useState();
  const [entiryType, setEntityType] = React.useState("");
  const getBusinessbybusinessId = (bussinessid) => {
    
    let getbusinesid = {
      "bus_id": bussinessid
    }
    let api_path = apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`;
    axios.post(apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`, getbusinesid).then((res) => {
      console.log("yeh we have business data by busis", res.data);
      setUserId(res.data.data.user_id);
      console.log("CPA UserID.............!!!!!!", res.data.data.user_id)
      let editdata1 = res.data.data;
      setEntityType(editdata1.entity_id)

    })
      .catch((er) => {
        ErrorCatch(userId, er, api_path, 'post', data, userId, userId);
        console.log("no pincode sorry ", er);
      });
    // getAllPromoterByBusinessId(bussinessid);
  }

  const [openForm, setOpenForm] = useState(false);
  const [checkForNewProm, setCheckForNewProm] = useState(props.NewPromo)

  // const getAllPromoterByBusinessIdFirst = async (data1) => {
  //   
  //   let arr = [];
  //   const data = {
  //     bus_id: data1,
  //   };
  //   let promoArr = [];
  //   await axios
  //     .post(
  //       `http://164.52.207.42/delta/server.php/api/getAllpromoterinfobybusid`,
  //       data
  //     )
  //     .then((result) => {
  //       console.log(result.data);
  //       setLoaderFlag(false);
  //       setBusinessIdOfPromoter(data1);
  //       if (result.data.status === false) {
  //         setShowTable(false);
  //         settopform(true);
  //         sethideBackBtn(false);
  //         setbusinessDropFlag(true);
  //         //  setCheckForNewProm(false)
  //         checkapply();
  //       } else if (checkForNewProm === true) {
  //         setShowTable(false);
  //         settopform(true);
  //         sethideBackBtn(true);
  //         setbusinessDropFlag(true);
  //         //  setCheckForNewProm(false)
  //         checkapply();
  //       }
  //       else if (result.data.data.length > 0) {
  //         setShowTable(true);
  //         setbusinessDropFlag(true);
  //         setPaginationHidden(true);

  //         //  sethideBackBtn(true);  
  //       }
  //       if (result.data.data.length > 5) {
  //         setPaginationHidden(true);
  //       } else if (result.data.data.length <= 5 || result.data.status == false) {
  //         setPaginationHidden(false)
  //       }
  //       result.data.data.map((item) => {
  //         let typ = "";
  //         if (item.type === "1111") {
  //           typ = "Promoter";
  //         } else if (item.type === "1233") {
  //           typ = "Borrower";
  //         }
  //         let appType;
  //         if (item.eligibility_flag === true) {
  //           appType = "Check Elegibility"
  //         } else if (item.eligibility_flag === false) {
  //           appType = "Loan Appliction"
  //         }
  //         promoArr.push({
  //           business_id: item.business_id,
  //           promoter_id: item.promoter_id,
  //           first_name: item.first_name,
  //           last_name: item.last_name,
  //           email: item.email,
  //           mobile: item.mobile,
  //           share: item.share_percent,
  //           type: typ,
  //           eligibility_flag: appType
  //         });
  //       });
  //       setPromoterList(promoArr);
  //       console.log("businessAll by id", promoArr);
  //       console.log(promoterList);
  //       //  setShowTable(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //getAll Promoters By Business Id
  // const getAllPromoterByBusinessId = async (bid) => {
  //   
  //   const data = {
  //     bus_id: bid,
  //   };
  //   let promoArr = [];
  //   await axios
  //     .post(
  //       `http://164.52.207.42/delta/server.php/api/getAllpromoterinfobybusid`,
  //       data
  //     )
  //     .then((result) => {
  //       settopform(true)
  //       console.log(result.data);
  //       if (result.data.status == false) {
  //         setShowTable(false);
  //         settopform(true);
  //       }
  //       if (result.data.data.length > 0 && (topform === false || showTable === false)) {
  //         setShowTable(true);
  //         settopform(false);
  //       }
  //       if (result.data.data.length > 5) {
  //         setPaginationHidden(true);
  //       } else if (result.data.data.length <= 5 || result.data.status === false) {
  //         setPaginationHidden(false)
  //       }

  //       result.data.data.map((item) => {
  //         let typ = "";
  //         if (item.type == "1111") {
  //           typ = "Promoter";
  //         } else if (item.type == "1233") {
  //           typ = "Borrower";
  //         }

  //         let appType;
  //         if (item.eligibility_flag === true) {
  //           appType = "Check Elegibility"
  //         } else if (item.eligibility_flag === false) {
  //           appType = "Loan Appliction"
  //         }
  //         promoArr.push({

  //           business_id: item.business_id,
  //           promoter_id: item.promoter_id,
  //           first_name: item.first_name,
  //           email: item.email,
  //           mobile: item.mobile,
  //           share: item.share_percent,
  //           type: typ,
  //           eligibility_flag: appType,
  //         });
  //       });
  //       setPromoterList(promoArr);
  //       console.log("businessAll by id", promoArr);
  //       console.log(promoterList);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  /// setting initial value tu autopopulate data in form fields
  /// formik initial value
  // get Promoter by his id
  //const [area1,setArea1] = React.useState();


  const getPromoterById = async (id) => {
    
    if (props.PromoDoc === true || props.PromoDoc === false) {
      return;

    }
    // setLoaderFlag(true);
    setheader(false);
    setShowTable(false);

    sethideBackBtn(false);

    ///  setloarOrCheck();
    //  setPromoterId(id);

    let data = {
      promo_or_shareholder_id: id,
    };
    
    let api_path = apiCalling.coderscorner + `server.php/api/getshareholderorPromotorInfoByid`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getshareholderorPromotorInfoByid`,
        data
      )
      .then((result) => {
        console.log(result.data.data);
        //showAllButton(false);
        setbusinessDropFlag(false);
        settopform(false);
        // setEditForm(true);
        let editdata1 = result.data.data[0];
        if (editdata1.type == "1233") {
          //showShare(false)
          setShowShare(true)
        } else {
          setShowShare(true)
        }
        getPincodeData(Number(result.data.data[0].pincode));
        let area1 = result.data.data[0].landmark;
        // setArea1(result.data.data[0].landmark)
        //   setloarOrCheck(editdata1.eligibility_flag);
        let pdob = moment(editdata1.dob).format('YYYY-MM-DD');
        setBusinessIdForDropDown(editdata1.bus_id);
        setEditInitialValue({
          ...ediInitivalue,
          company: editdata1.bus_id,
          id: editdata1.id,
          firstname: editdata1.first_name,
          pincode: Number(editdata1.pincode),
          date: pdob,
          area: editdata1.pin_id,
          lastname: editdata1.last_name,
          panvalue: editdata1.PAN,
          director: Boolean(editdata1.director),
          panname: editdata1.panname,
          nationality: Number(editdata1.nationality_id),
          mobile: editdata1.mobile,
          email: editdata1.email,
          share: editdata1.share_percent,
          type: Number(editdata1.type),
          address1: editdata1.address_1,
          grcno: editdata1.grc_no,
          address2: editdata1.address_2,
          govt: Number(editdata1.grc_type_id),
          gender: Number(editdata1.gender_id),
          education: editdata1.edu_qual_id,
          fathername: editdata1.father_name,
          mothername: editdata1.mother_name,
          spousename: editdata1.spouse_name,
          addressproof: Number(editdata1.add_proof_id),
          prop_type: Number(editdata1.property_id)
        });
        setloarOrCheck(true)
        setLoaderFlag(false)
        if (localStorage.getItem('check')) {
          const checkdata = localStorage.getItem('check'),
            checkform = JSON.parse(checkdata)
          console.log('lets check', checkform)
          setchecked(checkform)
        } else {
          if (editdata1.eligibility_flag == 1) {
            setchecked("Check Eligibility")
          } else {
            setchecked("Loan Application")
          }
        }
      })
      .catch((error) => {

        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
    setShowAllButton(false);
  }
  const [state1, setState1] = React.useState(false);


  // const handleCheckbox = (event) => {
  //   setState1(!state1);

  // };


  // update the promoter bases apply for loan or check elegibility
  const handlepersonalform = (values, { resetForm }) => {
    // let pid = promoterId;
    console.log("submitcheck", checkvalue);
    if (checkvalue == "Loan Application") {
      personalloanapply(values);
      resetForm({ id: "", firstname: "", pincode: "", date: "", area: "", lastname: "", panvalue: "" });
    } else {
      personaleligible(values);
      resetForm({ id: "", firstname: "", pincode: "", date: "", area: "", lastname: "" });
    }
    setloarOrCheck(false);
    //setbusinessDropFlag(true);
    ///sethideBackBtn(true);
    setShowAllButton(true);
  };
  // apply for loan

  const personalloanapply = async (data) => {
    
    setbusinessDropFlag(false);
    setLoaderFlag(true);
    let elig_flag;
    if (checkvalue === "Loan Application") {
      elig_flag = 0;
    } else {
      elig_flag = 1;
    }


    let loanpersonal = {
      user_id: userId,
      created_by: created_by,
      eligibility_flag: elig_flag,
      promotor_id: data.id,
      bus_id: businessOfPromoter,
      first_name: data.firstname,
      pin_id: data.area,
      dob: data.date,
      share: data.share,
      last_name: data.lastname,
      PAN: data.panvalue,
      panname: data.panname,
      director: Boolean(data.director),
      type: data.type,
      nationality_id: parseInt(data.nationality),
      Mobile: parseInt(data.mobile),
      email: data.email,
      add_line_1: data.address1,
      add_line_2: data.address2,
      grc_type_id: data.govt,
      grc_no: data.grcno,
      gender_id: parseInt(data.gender),
      edu_qual_id: parseInt(data.education),
      father_name: data.fathername,
      mother_name: data.mothername,
      spouse_name: data.spousename,
      add_proof_id: parseInt(data.addressproof),
      country_code: "91",
      addressproof: data.addressproof,
      property_id: data.prop_type
    };



    console.log("loanpersonal", loanpersonal);
    let api_path = apiCalling.coderscorner + `server.php/api/addPromotorInfo`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/addPromotorInfo`,
        loanpersonal
      )
      .then((res) => {
        if (res.data.status === true) {
          setCheckForNewProm(false);
          setdatas(loanpersonal);
          console.log("yeh we loanpersonal", res.data.Data);
          //     getAllPromoterByBusinessId(businessOfPromoter);
          // setShowTable(true);
          setEditForm(false);
          settopform(true);
          setSnackBarMsg(res.data.message);
          setLoaderFlag(false);
          setsavenextmessage(true);
          setbusinessDropFlag(true);
          props.handleBack(2);
          //React Goodies
          setEditInitialValue({
            id: "", firstname: "", pincode: "", date: "", area: "", lastname: "", panvalue: "", director: "", panname: "", nationality: "", mobile: "", email: "", address1: "", grcno: "", address2: "", govt: "", gender: "", education: "", fathername: "", mothername: "", spousename: "", addressproof: "",
          })
        } else {
          setSnackBarMsg(res.data.message);
          setLoaderFlag(false);
          setsavenextmessage(true);

        }

      })
      .catch((error) => {

        ErrorCatch(userId, error, api_path, 'post', loanpersonal, userId, userId);
        console.log("no data sorry ", error);
      });

    setshow(false);
    setheader(true);
    // settopform(true);
  };

  /// check elegibility
  const personaleligible = async (data) => {
    setbusinessDropFlag(false);
    setLoaderFlag(true);
    
    //let promoId = localStorage.getItem('promoId');
    let elig_flag;
    if (checkvalue === "Loan Application") {
      elig_flag = 0;
    } else {
      elig_flag = 1;
    }

    let pereligible = {
      user_id: userId,
      created_by: created_by,
      eligibility_flag: elig_flag,
      bus_id: businessOfPromoter,
      promotor_id: data.id,
      first_name: data.firstname,
      last_name: data.lastname,
      director: Boolean(data.director),
      pin_id: data.area,
      dob: data.date,
      share: data.share,
      PAN: data.panvalue,
      type: data.type,
      panname: data.panname,

      nationality_id: parseInt(data.nationality),
      Mobile: parseInt(data.mobile),
      email: data.email,
      country_code: "91",
      property_id: data.prop_type
    };

    let api_path = apiCalling.coderscorner + `server.php/api/addPromotorInfo`;
    await axios
      .post(apiCalling.coderscorner + `server.php/api/addPromotorInfo`,
        pereligible
      )
      .then((res) => {
        if (res.data.status === true) {
          setdatas(pereligible);
          console.log("yeh we pereligible", res.data.Data);

          setSnackBarMsg(res.data.message);
          // getAllPromoterByBusinessId(businessOfPromoter);
          setShowTable(true);
          settopform(true);
          // setEditForm(false);
          setLoaderFlag(false);
          setbusinessDropFlag(true);
          setsavenextmessage(true);
          sethideBackBtn(true);
          props.handleBack(2);
          //React Goodies
          //  resetForm({ediInitivalue})
          setEditInitialValue({
            id: "", firstname: "", pincode: "", date: "", area: "", lastname: "", panvalue: "", director: "", panname: "", nationality: "", mobile: "", email: "", address1: "", grcno: "", address2: "", govt: "", gender: "", education: "", fathername: "", mothername: "", spousename: "", addressproof: "",
          })
        } else {
          setLoaderFlag(false);
          setSnackBarMsg(res.data.message);
          setsavenextmessage(true);
        }

      }).catch((error) => {

        ErrorCatch(userId, error, api_path, 'post', pereligible, userId, userId);
        console.log("no data sorry ", error);
      });
    setshow(false);
    setheader(true);
    // settopform(true);
  };

  ///get business of the user based on his id
  // const [bsid, setBsId] = useState();





  const [created_by, setCreatedBy] = useState();

  const getAllBusinessbyuser = async () => {
    

    let userIdForBus;
    let user;
    // if (localStorage.getItem('userbycpa')) {
    //   user = localStorage.getItem('userbycpa');
    //   setidofuser(user);
    //   setUserId(user);
    //   let crtdBy = JSON.parse(localStorage.getItem('userdata'));
    //   //created_by = crtdBy.id;
    //   setCreatedBy(crtdBy.id);
    //   userIdForBus = user;
    // } else {
    //user =user1.id;
    const checkuserloggeddata1 = localStorage.getItem("userdata");
    let iduser1 = JSON.parse(checkuserloggeddata1);
    setidofuser(iduser1.id);
    setUserId(iduser1.id)
    //	created_by = iduser.id;
    setCreatedBy(iduser1.id);
    userIdForBus = iduser1.id;
    // }

    const checkuserloggeddata = localStorage.getItem("userdata");
    let iduser = JSON.parse(checkuserloggeddata);

    const data = {
      user_id: userIdForBus,
    };

    let url = '';
    if (iduser.role_id == 1) {
      url = apiCalling.coderscorner + `server.php/api/getBusinessByCPA`;
    } else {
      url = apiCalling.coderscorner + "server.php/api/getBussinessByuserid";
    }



    let busArr = [];
    let api_path = apiCalling.coderscorner + "server.php/api/getBussinessByuserid";
    await axios
      .post(url,
        data
      )
      .then((result) => {
        console.log(result.data.data);
        //setBsId(result.data.data[0].businessid)
        setBsId(result.data.data[0].id);

        if (props.PromoterID === undefined) {
          setBusinessIdOfPromoter(result.data.data[0].id);

          setBusinessIdForDropDown(result.data.data[0].id);
        } else {

        }

        result.data.data.map((item) => {
          busArr.push({
            key: item.id,
            businessName: item.name,
            businessid: item.id,
            value: item.id,
            text: item.name,
          });
        });
        console.log(result.data.data[0].id);
        setbusinesslist(busArr);
        //  getAllPromoterByBusinessIdFirst(busArr[0].businessid);
        setbusinesslist(busArr);
        console.log("businessAll by userId", busArr);
        console.log(businesslist);
        console.log(bsid);
      })
      .catch((error) => {
        ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
        console.log(error);
      });
  };
  // set initialBus id
  const setBusId = (initialBusId) => {

  }

  //#region KArza pan validation api
  const getNameFromPan = (e) => {
    let panvalue = e.target.value;
    axios.get('http://74.208.210.241/api/public/apikaz?app_name=PAN&in_data=' + panvalue + '&create=Username&modify=username').then(res => {
      console.log(res.data.result);
      //  let size = Object.keys(res.data.result).length
      // setEditInitialValue({
      //   ...ediInitivalue,
      //   panname: "hshs"
      // })
      setKarzaPanName(res.data.result.name);
      setKarzaPanError("")
    }).catch((error) => {
      console.log(error);
    })
  }
  //#endregion

  //#region   get the left % of share of particular Business based on his id;
  const [availableShare, setAvailableShare] = useState(0);
  const getSharePerBus = async (e, setFieldValue) => {
    
    let shareper = Number(e.target.value);
    let data = {
      business_id: businessOfPromoter,
      share: shareper,
      Token: "qwerty1234"
    }
    let api_path = apiCalling.coderscorner + `server.php/api/chekSharePercent`;
    await axios.post(apiCalling.coderscorner + `server.php/api/chekSharePercent`, data).then(
      (result) => {
        console.log(result.data)
        if (result.data !== undefined || result.data !== null) {
          if (result.data.status === false) {
            let errormsg = "share available is : ";
            errormsg = errormsg.concat(result.data.Data.availableShare)
            if (shareper <= 100 && shareper > 0 && shareper <= result.data.Data.availableShare) {
              setFieldValue("share", result.data.Data.availableShare);
            } else {
              setFieldValue("share", "");
            }
            setAvailableShare(result.data.Data.availableShare)
            setShareErrorMsg(errormsg)
            setShareErrShow(true);

          } else {
            setShareErrShow(false);
            setAvailableShare(result.data.Data.availableShare)
          }
        }
      }
    ).catch((error) => {
      ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
      console.log(error);
    })
  }
  //#endregion

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const deletePromoterById = async (deleteId, business_id) => {
    
    let data = {
      promotor_id: deleteId
    }
    let api_path = apiCalling.coderscorner + `server.php/api/deletepromotorinfo`;
    await axios.post(apiCalling.coderscorner + `server.php/api/deletepromotorinfo`, data).then((result) => {
      console.log(result.data);
      setSnackBarMsg(result.data.message);

      setsavenextmessage(true);
      //getAllPromoterByBusinessId(deleteId)
      // delete By Id
      //   getAllPromoterByBusinessId(business_id);
    }).catch((error) => {
      ErrorCatch(userId, error, api_path, 'post', data, userId, userId);
      console.log(error);
    })
    setEmailOtp(false);
    setOtpBtn(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false);

  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }


  const [showShare, setShowShare] = React.useState(true);
  const showAndHideShare = (value) => {
    
    console.log(value);
    if (value === 1233) {
      setShowShare(true)
    } else {
      setShowShare(true);
    }
  }

  const [dateError, setDateError] = React.useState("");

  const handleDate = (e) => {
    
    var date = new Date(e.target.value);
    if (date < new Date("01-01-1900") || date > new Date(minimumdate)) {
      console.log("Date not in range");
      setDateError("Date should be in between " + minimumdate + " and " + maximumdate);
      //  setEnableRegistration(true)
    } else {
      console.log("Date range");
      setDateError("");
    }
  }

  let docDetails = {
    formId: "1.1.1",
    parentId: props.PromoterID,
    parentTypeId: 2,

  }

  const [dropdown, setDropDown] = useState(true);


  /// property type value

  const [propTypeArr, setPropArrType] = React.useState([]);

  const getPropertTypeMaster = async () => {
    
    let data = {
      "type": "property_types"
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getMastertableDetails', data).then(result => {
      console.log(result);
      let arr = [];
      result.data.data.map(item => {
        arr.push({ text: item.name, value: item.id, key: item.id });
      });
      setPropArrType(arr);

    }).catch(error => {
      console.log(error);
    })

  }

  return (
    <>
      <Container maxWidth="lg">


        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            middlename: "",
            share: "",
            type: "",
            company: businessIdForDropDown,
            mobile: "",
            email: "",
            director: false,
            motp: "",
            eotp: "",
            prop_type: ""
          }}
          enableReinitialize
          validationSchema={shareholderFormschema}
          onSubmit={handlesubmitform}
        >
          {({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            validateForm,
            validateOnBlur,
            /* and other goodies */
          }) => {
            //  console.log("values in formik", values);
            return (
              <form onSubmit={handleSubmit}>
                <div className="mini-container mg_btm_set">
                  <Row>
                    <Col md={{ span: 12 }}>
                      <div className="content-header">
                        <Row>
                          {(header == true) && (
                            <Col md={{ span: 10 }} className="mainheader1">
                              <Typography text="Promoter Information" styleType="C" />
                            </Col>
                          )}
                          {showTable === true && (
                            <Col md={{ span: 2 }} className="mainHeaderBack">
                              {/* <Button
                                className="backBtn"
                                variant="outlined" 
                                color="primary"
                                onClick={handleNew}>
                                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;Promoter
                                  </Button> */}
                              <span className="image">
                                <img className='backbutton navbutton' src={Back} onClick={handleNew} />
                                <Tooltip title="Back" arrow>
                                  <img className='backbutton navgraybutton' src={grayback} onClick={handleNew} />
                                </Tooltip>
                              </span>
                            </Col>
                          )}
                          {hideBackBtn == true && (
                            <Col md={{ span: 2 }} className="mainHeaderBack">
                              {/* <Button
                                className="backBtn" variant="outlined" color="primary"
                                onClick={handleBack2}>
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                  </Button> */}
                              <span className="image">
                                <img className='backbutton navbutton' src={Back} onClick={handleBack2} />
                                <Tooltip title="Back" arrow>
                                  <img className='backbutton navgraybutton' src={grayback} onClick={handleBack2} />
                                </Tooltip>
                              </span>
                            </Col>
                          )}
                        </Row>
                      </div>

                      <div>
                        <Row>
                          {dropdown === true && (
                            <Col md={{ span: 6 }}>
                              <Typography text="Business Name" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <
                                  
                                  n
                                    placeholder="Select Business Name"
                                    id="company"
                                    name="company"
                                    fluid
                                    search
                                    selection
                                    value={values.company}
                                    onBlur={(e, { name, value }) => {
                                      setFieldTouched("company", true);
                                    }}
                                    onChange={(e, { name, value }) => {
                                      
                                      setFieldValue("company", value);
                                      setBusinessIdForPro(value);
                                      getBusinessbybusinessId(values);
                                      console.log('business dropdown', value)
                                    }}
                                    options={businesslist}
                                    className={
                                      errors.company && touched.company
                                        ? classes.required
                                        : values.company == ""
                                          ? classes.reqired
                                          : classes.man
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Col>
                          )}
                        </Row>
                        {loaderFlag === true && (
                          <div style={{ textAlign: 'center', marginTop: '15%' }}>
                            <Loader type={LoaderColor.type} color={LoaderColor.color} />
                          </div>
                        )}

                        {topform === true && showTable == false && (
                          <Row>
                            <Col md={{ span: 6 }} >
                              <Typography
                                text="Enter Promoter Detail"
                                styleType="D" />
                            </Col>

                            <Col md={{ span: 6 }} style={{ marginBottom: "1%" }}>
                              <Checkbox
                                checked={values.director ? true : false}
                                onChange={(e) => {
                                  setFieldValue("director", !(values.director));
                                }}
                                name="director"
                                color="primary"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                style={{ marginBottom: "5px", marginLeft: "-12px" }} />
                              <Typography text="Director" styleType="B" />
                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography text="First Name" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <TextField
                                    id="filled-error-helper-text standard-basic "
                                    label="Enter First Name"
                                    name="firstname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.firstname
                                        ? classes.manorma
                                        : null,
                                    }}
                                    error={
                                      errors.firstname &&
                                        touched.firstname &&
                                        errors.firstname
                                        ? true
                                        : false
                                    }
                                    value={values.firstname}
                                    helperText={
                                      errors.firstname &&
                                      touched.firstname &&
                                      errors.firstname
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text="Last Name" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <TextField
                                    id="filled-error-helper-text standard-basic "
                                    label="Enter Last Name"
                                    name="lastname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.lastname
                                        ? classes.manorma
                                        : null,
                                    }}
                                    error={
                                      errors.lastname &&
                                        touched.lastname &&
                                        errors.lastname
                                        ? true
                                        : false
                                    }
                                    value={values.lastname}
                                    helperText={
                                      errors.lastname &&
                                      touched.lastname &&
                                      errors.lastname
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text="Middle Name" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <TextField
                                    id="filled-error-helper-text standard-basic"
                                    name="middlename"
                                    label="Middle Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      errors.middlename &&
                                        touched.middlename &&
                                        errors.middlename
                                        ? true
                                        : false
                                    }
                                    InputProps={{
                                      className: values.middlename
                                        ? classes.manorma
                                        : null,
                                    }}
                                    value={values.middlename}
                                    helperText={
                                      errors.middlename &&
                                      touched.middlename &&
                                      errors.middlename
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text="Type" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder="Select Type"
                                    id="type"
                                    name="type"
                                    fluid
                                    search
                                    selection
                                    value={values.type}
                                    onBlur={() => setFieldTouched("type", true)}
                                    onChange={(e, { value }) => {
                                      setFieldValue("type", value);
                                      showAndHideShare(value);
                                    }
                                    }
                                    options={type}
                                    className={
                                      errors.type && touched.type
                                        ? classes.required
                                        : values.type == ""
                                          ? classes.reqired
                                          : classes.man
                                    }
                                  />
                                </ListItem>
                              </List>
                              {errors.type && touched.type && values.type == '' && (
                                <div style={{ color: "red", fontSize: "0.75rem", marginTop: "-7px" }}>{errors.type}</div>
                              )}

                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography
                                text="Mobile"
                                styleType="B"
                                className="promoter_mobile"
                              /> {otpBtn && (
                                <span className="hidden_number">
                                  You entered : {values.mobile}
                                </span>
                              )}
                              {verifiedIcon === true &&
                                (

                                  <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>

                                )
                              }
                              <List component="div" disablePadding style={{ marginTop: "0px" }}>
                                {otpBtn == false && (
                                  <>

                                    <ListItem>
                                      <PhoneInput country="in"
                                        enableSearch={true} onChange={(val) => selectCountry(val)} />
                                      <TextField
                                        id="filled-error-helper-text standard-basic "
                                        label="Enter MobileNo"
                                        name="mobile"
                                        type="number"
                                        onChange={handleChange}
                                        onBlur={onBlurMobile}
                                        InputProps={{
                                          className: values.mobile
                                            ? classes.manorma
                                            : null,
                                        }}
                                        inputProps={{
                                          maxLength: 10,
                                        }}
                                        error={
                                          errors.mobile &&
                                            touched.mobile &&
                                            errors.mobile
                                            ? true
                                            : false
                                        }
                                        value={values.mobile}
                                        helperText={
                                          errors.mobile &&
                                          touched.mobile &&
                                          errors.mobile
                                        }
                                      />
                                    </ListItem>
                                    <ListItem><span style={{ color: "red", marginLeft: " 26%", marginTop: "-15px", fontSize: "10px" }}>{mobileExistsMsg}</span></ListItem>
                                  </>
                                )}

                                {otpBtn === true && (
                                  <>
                                    <p className="otpsent">Enter OTP</p>
                                    <ListItem>
                                      <InputOtp onChange={verifyMobileOtp} />
                                      {hideMobileResenBtn == true && (
                                        <OtpTimer
                                          seconds={otpSeconds}
                                          minutes={otpMinutes} //otpMinutes
                                          style={{ color: "blue" }}
                                          resend={redendOtpMobile}
                                          text=" "
                                          buttonColor={"white"}
                                          background={"#007bff"}
                                        />
                                      )}
                                      <Button onClick={getBackToMobile} style={{ marginTop: "-1%" }}>
                                        <i
                                          className="far fa-edit"
                                          style={{ fontSize: "15px" }}
                                        ></i>
                                      </Button>

                                      {mobileVerifyIcon === true && (
                                        <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green' }} ></i>
                                      )}
                                      {ifMobileOtpInvald === true && (
                                        <span style={{ color: "red" }}>
                                          Invalid Otp{" "}
                                        </span>
                                      )}
                                    </ListItem>
                                  </>)}
                              </List>
                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography
                                text="Email"
                                styleType="B"
                                className="promoter_email"
                              />
                              {emailOtp && (
                                <span className="hidden_number">
                                  You entered : {values.email}
                                </span>
                              )}
                              {verifiedIconEmail === true &&
                                (

                                  <i className="fas fa-check-circle" style={{ fontSize: '15px', color: 'green' }} ></i>

                                )
                              }
                              <List component="div" disablePadding>
                                {emailOtp == false && (
                                  <>
                                    <ListItem>
                                      <TextField
                                        id="filled-error-helper-text standard-basic "
                                        label="Enter Email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={onBlurEmail}
                                        validateOnBlur={true}
                                        InputProps={{
                                          className: values.email
                                            ? classes.manorma
                                            : null,
                                        }}
                                        error={
                                          errors.email &&
                                            touched.email &&
                                            errors.email
                                            ? true
                                            : false
                                        }
                                        value={values.email}
                                        helperText={
                                          errors.email &&
                                          touched.email &&
                                          errors.email
                                        }
                                      />
                                    </ListItem>
                                    <ListItem><span style={{ color: "red", marginTop: "-1.5%", fontSize: "0.75rem" }}>{emaileExistsMsg}</span></ListItem>
                                  </>
                                )}

                                {emailOtp && (
                                  <>
                                    <p className="otpsent">Enter OTP</p>
                                    <ListItem>
                                      <InputOtp onChange={verifyEmialOtp} />
                                      {hideEmailResenBtn === true && (
                                        <OtpTimer
                                          seconds={otpSeconds}
                                          minutes={otpMinutes} //otpMinutes
                                          style={{ color: "blue", marginTop: "-1%" }}
                                          resend={resendOtpEmail}
                                          text=" "
                                          buttonColor={"white"}
                                          background={"#007bff"}
                                        />
                                      )}
                                      <Button onClick={getBackToEmail} style={{ marginTop: "-1%" }}>
                                        <i
                                          className="far fa-edit"
                                          style={{ fontSize: "15px" }}
                                        ></i>
                                      </Button>
                                      {emailVerifyIcon === true && (
                                        <i className="fas fa-check-circle" style={{ fontSize: '17px', color: 'green' }} ></i>
                                      )}
                                      {ifEmailOtpInvald === true && (
                                        <span style={{ color: "red" }}>
                                          Invalid Otp{" "}
                                        </span>
                                      )}
                                    </ListItem>
                                  </>
                                )}
                              </List>
                            </Col>

                            <Col md={{ span: 6 }} style ={{marginTop:"8px"}}>
                              <Typography text="Property Type" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder="Select Property Type"
                                    id="prop_type"
                                    name="prop_type"
                                    fluid
                                    search
                                    selection
                                    value={values.prop_type || ""}
                                    onBlur={() => setFieldTouched("prop_type", true)}
                                    onChange={(e, { value }) => {
                                      setFieldValue("prop_type", value);
                                      showAndHideShare(value)
                                    }
                                    }
                                    style={{ marginTop: "-8px" }}
                                    options={propTypeArr}
                                  // className={
                                  //   errors.type && touched.type
                                  //     ? classes.required
                                  //     : values.type == ""
                                  //       ? classes.reqired
                                  //       : classes.man
                                  // }
                                  />
                                </ListItem>
                              </List>

                            </Col>


                            {showShare === true && (<Col md={{ span: 6 }}>
                              <Typography text="Share%" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <TextField
                                    id="filled-error-helper-text standard-basic"
                                    name="share"
                                    type="number"
                                    label="Share%"
                                    onChange={handleChange}
                                    ///  onKeyUp={getSharePerBus}
                                    onBlur={(e) => {


                                      
                                      e.preventDefault();
                                      getSharePerBus(e, setFieldValue);

                                      // const value = e.target.value;
                                      // if (value <= 100 && value > 0 && value <= availableShare) {
                                      //   setFieldValue("share", value);
                                      // } else {
                                      //   setFieldValue("share", "");
                                      // }
                                    }}
                                    //disabled={shareEnable}
                                    InputProps={{
                                      className: values.share
                                        ? classes.manorma
                                        : null,
                                    }}
                                    inputProps={{ maxLength: 5 }}

                                    error={
                                      errors.share &&
                                        touched.share &&
                                        errors.share
                                        ? true
                                        : false
                                    }
                                    value={values.share || ""}

                                    helperText={
                                      errors.share &&
                                      touched.share &&
                                      errors.share
                                    }

                                  />
                                </ListItem>
                                <ListItem>
                                  {shareErrShow === true && (<span style={{ color: "red", fontSize: "11px" }}>{shareErrorMsg}</span>)}
                                </ListItem>
                              </List>
                            </Col>

                            )}


                          </Row>
                        )}
                      </div>

                      {loarOrCheck === true && (
                        <div>
                          {true == true && (
                            <Formik
                              enableReinitialize
                              initialValues={ediInitivalue}
                              validationSchema={validators}
                              onSubmit={handlepersonalform}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                resetForm,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                                /* and other goodies */
                              }) => {
                                return (
                                  <form onSubmit={handleSubmit}>
                                    <Col md={{ span: 12 }}></Col>
                                    <div>
                                      <Row>
                                        <Col md={{ span: 10 }}>
                                          <Typography
                                            text={`Editing Details for : ` + values.firstname}
                                            styleType="D"
                                          />
                                        </Col>
                                        <Col md={{ span: 2 }}>
                                          {/* <Button
                                            className="backBtn"
                                            variant="outlined" color="primary"
                                            onClick={handleBack2}>
                                            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back
                                          </Button> */}
                                          <span className="image">
                                            <img className='backbutton navbutton' src={Back} onClick={handleBack2} />
                                            <Tooltip title="Back" arrow>
                                              <img className='backbutton navgraybutton' src={grayback} onClick={handleBack2} />
                                            </Tooltip>
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                    <Row>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text="First Name"
                                          styleType="B"
                                        />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              name="firstname"
                                              onChange={handleChange}
                                              placeholder="FirstName"
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.firstname
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              error={
                                                errors.firstname &&
                                                  touched.firstname &&
                                                  errors.firstname
                                                  ? true
                                                  : false
                                              }
                                              value={values.firstname || ""}
                                              helperText={
                                                errors.firstname &&
                                                touched.firstname &&
                                                errors.firstname
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text="Last Name"
                                          styleType="B"
                                        />

                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              name="lastname"
                                              placeholder="LastName"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.lastname
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              error={
                                                errors.lastname &&
                                                  touched.lastname &&
                                                  errors.lastname
                                                  ? true
                                                  : false
                                              }
                                              value={values.lastname || ""}
                                              helperText={
                                                errors.lastname &&
                                                touched.lastname &&
                                                errors.lastname
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography text="PAN" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              placeholder="AAAAA9999A"
                                              name="panvalue"
                                              onChange={handleChange}
                                              onBlur={getNameFromPan} // getNameFromPan
                                              InputProps={{
                                                className: values.panvalue
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              error={
                                                errors.panvalue &&
                                                  touched.panvalue &&
                                                  errors.panvalue
                                                  ? true
                                                  : false
                                              }
                                              value={values.panvalue || ""}
                                              helperText={
                                                errors.panvalue &&
                                                touched.panvalue &&
                                                errors.panvalue
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text="Name as Per PAN"
                                          styleType="B"
                                        />

                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              placeholder="PAN NAME"
                                              name="panname"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.panname
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              error={
                                                errors.panname &&
                                                  touched.panname &&
                                                  errors.panname
                                                  ? true
                                                  : false
                                              }
                                              value={karzaPanName != null || karzaPanName != undefined ? karzaPanName : values.panname}
                                              helperText={
                                                errors.panname &&
                                                touched.panname &&
                                                errors.panname
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography text="DOB" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="date"
                                              type="date"
                                              className={classes.textField}
                                              name="date"
                                              onChange={handleChange}
                                              onBlur={handleDate}
                                              style={{ marginTop: "9px" }}
                                              InputProps={{
                                                inputProps: {
                                                  min: "1900-01-01",
                                                  max: minimumdate,
                                                },
                                                className: values.date
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              error={
                                                errors.date &&
                                                  touched.date &&
                                                  errors.date
                                                  ? true
                                                  : false
                                              }
                                              value={values.date}
                                              helperText={
                                                errors.date &&
                                                touched.date &&
                                                errors.date
                                              }
                                            />
                                          </ListItem>
                                          {errors.date && touched.date && values.date == '' && (
                                            <div style={{ color: "red", fontSize: "0.75rem", marginTop: "-7px" }}>{errors.date}</div>
                                          )}
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text="Country Of Citizenship"
                                          styleType="B"
                                        />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            {/* 	<TextField
																							id="filled-error-helper-text standard-basic "
																							label="Nationality"
																							name="nationality"
																							onChange={handleChange}
																							onBlur={handleBlur}
																							InputProps={{
																								className: values.nationality ? classes.manorma : null,
																							}}
																							error={
																								errors.nationality &&
																									touched.nationality &&
																									errors.nationality
																									? true
																									: false
																							}
																							value={values.nationality}
																							helperText={
																								errors.nationality &&
																								touched.nationality &&
																								errors.nationality
																							}
																						/> */}
                                            <Dropdown
                                              placeholder="Select Nationality"
                                              id="nationality"
                                              name="nationality"
                                              fluid
                                              search
                                              selection
                                              style={{ marginTop: "0px" }}
                                              value={values.nationality || ""}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  "nationality",
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue(
                                                  "nationality",
                                                  value
                                                )
                                              }
                                              options={nationality}
                                              className={
                                                errors.nationality &&
                                                  touched.nationality
                                                  ? classes.required
                                                  : values.nationality == ""
                                                    ? classes.reqired
                                                    : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.nationality &&
                                          touched.nationality &&
                                          values.nationality == "" && (
                                            <div
                                              style={{
                                                color: "red",
                                                marginLeft: 14,
                                                fontSize: 12,
                                                fontWeight: "600",
                                              }}
                                            >
                                              {errors.nationality}
                                            </div>
                                          )}
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography text="Mobile" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              name="mobile"
                                              type="number"
                                              placeholder="9000000000"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.mobile
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              inputProps={{
                                                maxLength: 10,
                                              }}
                                              disabled={true}
                                              error={
                                                errors.mobile &&
                                                  touched.mobile &&
                                                  errors.mobile
                                                  ? true
                                                  : false
                                              }
                                              value={values.mobile || ""}
                                              helperText={
                                                errors.mobile &&
                                                touched.mobile &&
                                                errors.mobile
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography text="Email" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <TextField
                                              id="filled-error-helper-text standard-basic "
                                              name="email"
                                              placeholder="aagey@abc.com"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.email
                                                  ? classes.manorma
                                                  : null,
                                              }}
                                              disabled={true}

                                              error={
                                                errors.email &&
                                                  touched.email &&
                                                  errors.email
                                                  ? true
                                                  : false
                                              }
                                              value={values.email || ""}
                                              helperText={
                                                errors.email &&
                                                touched.email &&
                                                errors.email
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>


                                      <Col md={{ span: 6 }}>
                                        <Typography text="Type" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder="Select Type"
                                              id="type"
                                              name="type"
                                              fluid
                                              search
                                              selection
                                              value={values.type || ""}
                                              onBlur={() => setFieldTouched("type", true)}
                                              onChange={(e, { value }) => {
                                                setFieldValue("type", value);
                                                showAndHideShare(value)
                                              }
                                              }
                                              style={{ marginTop: "-8px" }}
                                              options={type}
                                              className={
                                                errors.type && touched.type
                                                  ? classes.required
                                                  : values.type == ""
                                                    ? classes.reqired
                                                    : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.type &&
                                          touched.type &&
                                          values.type == "" && (
                                            <div
                                              style={{
                                                color: "red",
                                                marginLeft: 14,
                                                fontSize: 12,
                                                fontWeight: "600",
                                              }}
                                            >
                                              {errors.type}
                                            </div>
                                          )}
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography text="Property Type" styleType="B" />
                                        <List component="div" disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder="Select Property Type"
                                              id="prop_type"
                                              name="prop_type"
                                              fluid
                                              search
                                              selection
                                              value={values.prop_type || ""}
                                              onBlur={() => setFieldTouched("prop_type", true)}
                                              onChange={(e, { value }) => {
                                                setFieldValue("prop_type", value);
                                                showAndHideShare(value)
                                              }
                                              }
                                              style={{ marginTop: "-8px" }}
                                              options={propTypeArr}
                                            // className={
                                            //   errors.type && touched.type
                                            //     ? classes.required
                                            //     : values.type == ""
                                            //       ? classes.reqired
                                            //       : classes.man
                                            // }
                                            />
                                          </ListItem>
                                        </List>

                                      </Col>

                                      {showShare && (
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Share%" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="share"
                                                type="number"
                                                placeholder="Share %"
                                                onChange={(e) => {
                                                  e.preventDefault();
                                                  const value = e.target.value;
                                                  if (value <= 100 && value > 0) {
                                                    setFieldValue("share", value);
                                                  } else {
                                                    setFieldValue("share", "");
                                                  }
                                                }}
                                                onBlur={getSharePerBus}
                                                inputProps={{ maxLength: 3 }}
                                                InputProps={{
                                                  className: values.share
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                // error={
                                                //   errors.share &&
                                                //     touched.share &&
                                                //     errors.share
                                                //     ? true
                                                //     : false
                                                // }
                                                value={values.share || ""}
                                              // helperText={
                                              //   errors.share &&
                                              //   touched.share &&
                                              //   errors.share
                                              // }
                                              />
                                            </ListItem>
                                            <ListItem>
                                              {shareErrShow === true && (<span style={{ color: "red", fontSize: "11px" }}>{shareErrorMsg}</span>)}
                                            </ListItem>
                                          </List>
                                        </Col>

                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Address Line 1"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                name="address1"
                                                placeholder="address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.address1
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.address1 &&
                                                    touched.address1 &&
                                                    errors.address1
                                                    ? true
                                                    : false
                                                }
                                                value={values.address1 || ""}
                                                helperText={
                                                  errors.address1 &&
                                                  touched.address1 &&
                                                  errors.address1
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Address Line 2"
                                            styleType="B"
                                          />

                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic "
                                                name="address2"
                                                placeholder="address2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.address2
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.address2 &&
                                                    touched.address2 &&
                                                    errors.address2
                                                    ? true
                                                    : false
                                                }
                                                value={values.address2 || ""}
                                                helperText={
                                                  errors.address2 &&
                                                  touched.address2 &&
                                                  errors.address2
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Pincode"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="pincode"
                                                type="number"
                                                placeholder=""
                                                onChange={handleChange}

                                                onBlur={(e) => {
                                                  handleBlur(e);
                                                  if (!errors.pincode) {
                                                    getPincodeData(
                                                      values.pincode
                                                    );
                                                  }
                                                }}
                                                error={
                                                  errors.pincode &&
                                                    touched.pincode &&
                                                    errors.pincode
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  className: values.pincode
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                inputProps={{
                                                  maxLength: 6,
                                                }}
                                                value={values.pincode}
                                                helperText={
                                                  errors.pincode &&
                                                  touched.pincode &&
                                                  errors.pincode
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography text="Area" styleType="B" />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Area"
                                                id="area"
                                                name="area"
                                                fluid
                                                search
                                                selection
                                                style={{ marginTop: "-8px" }}
                                                value={values.area || ""}
                                                onBlur={() =>
                                                  setFieldTouched("area", true)
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue("area", value)
                                                }
                                                options={area}
                                                className={
                                                  errors.area && touched.area
                                                    ? classes.required
                                                    : values.area == ""
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Gender"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Gender"
                                                id="gender"
                                                name="gender"
                                                fluid
                                                search
                                                selection
                                                style={{ marginTop: "-8px" }}
                                                value={values.gender || ""}
                                                onBlur={() =>
                                                  setFieldTouched("gender", true)
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue("gender", value)
                                                }
                                                options={gender}
                                                className={
                                                  errors.gender && touched.gender
                                                    ? classes.required
                                                    : values.gender == ""
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.gender &&
                                            touched.gender &&
                                            values.gender == "" && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  marginLeft: 14,
                                                  fontSize: 12,
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {errors.gender}
                                              </div>
                                            )}
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Educational Qualification"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Educational Qualification"
                                                id="education"
                                                name="education"
                                                fluid
                                                search
                                                selection
                                                style={{ marginTop: "-8px" }}
                                                value={values.education || ""}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    "education",
                                                    true
                                                  )
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue(
                                                    "education",
                                                    value
                                                  )
                                                }
                                                options={education}
                                                className={
                                                  errors.education &&
                                                    touched.education
                                                    ? classes.required
                                                    : values.education == ""
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.education &&
                                            touched.education &&
                                            values.education == "" && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  marginLeft: 14,
                                                  fontSize: 12,
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {errors.education}
                                              </div>
                                            )}
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Fathers Name"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="fathername"
                                                placeholder="Fathers Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.fathername
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.fathername &&
                                                    touched.fathername &&
                                                    errors.fathername
                                                    ? true
                                                    : false
                                                }
                                                value={values.fathername || ""}
                                                helperText={
                                                  errors.fathername &&
                                                  touched.fathername &&
                                                  errors.fathername
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Mothers Name"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="mothername"
                                                placeholder="Mothers Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.mothername
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.mothername &&
                                                    touched.mothername &&
                                                    errors.mothername
                                                    ? true
                                                    : false
                                                }
                                                value={values.mothername || ""}
                                                helperText={
                                                  errors.mothername &&
                                                  touched.mothername &&
                                                  errors.mothername
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Spouse Name"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                name="spousename"
                                                placeholder="Spouse Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.spousename
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.spousename &&
                                                    touched.spousename &&
                                                    errors.spousename
                                                    ? true
                                                    : false
                                                }
                                                value={values.spousename || ""}
                                                helperText={
                                                  errors.spousename &&
                                                  touched.spousename &&
                                                  errors.spousename
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text="Address Proof "
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder="Select Address Proof"
                                                id="addressproof"
                                                name="addressproof"
                                                fluid
                                                search
                                                selection
                                                style={{ marginTop: "-8px" }}
                                                value={values.addressproof || ""}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    "addressproof",
                                                    true
                                                  )
                                                }
                                                onChange={(e, { value }) =>
                                                  setFieldValue(
                                                    "addressproof",
                                                    value
                                                  )
                                                }
                                                options={address}
                                                className={
                                                  errors.addressproof &&
                                                    touched.addressproof
                                                    ? classes.required
                                                    : values.addressproof == ""
                                                      ? classes.reqired
                                                      : classes.man
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                          {errors.addressproof &&
                                            touched.addressproof &&
                                            values.addressproof == "" && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  marginLeft: 14,
                                                  fontSize: 12,
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {errors.addressproof}
                                              </div>
                                            )}
                                        </Col>
                                      )}

                                      {checkvalue == "Loan Application" && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text=" Government Registration Certificate Number"
                                            styleType="B"
                                          />
                                          <List component="div" disablePadding>
                                            <ListItem>
                                              <TextField
                                                id="filled-error-helper-text standard-basic"
                                                placeholder="Enter Government Registration Certificate Number"
                                                name="grcno"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  className: values.grcno
                                                    ? classes.manorma
                                                    : null,
                                                }}
                                                error={
                                                  errors.grcno &&
                                                    touched.grcno &&
                                                    errors.grcno
                                                    ? true
                                                    : false
                                                }
                                                value={values.grcno || ""}
                                                helperText={
                                                  errors.grcno &&
                                                  touched.grcno &&
                                                  errors.grcno
                                                }
                                              />
                                            </ListItem>
                                          </List>
                                        </Col>
                                      )}
                                    </Row>
                                    <Row>
                                      <Col md={{ span: 10 }}>
                                      </Col>
                                      <Col md={{ span: 2 }}>
                                        <Button className="updatebtn" variant="contained" color="primary"
                                          onClick={handleSubmit}>Update</Button>
                                      </Col>
                                    </Row>
                                  </form>
                                );
                              }}
                            </Formik>
                          )}
                        </div>
                      )}

                      {showAllButton == true && (
                        <div className="btn_row_fixd">
                          <Row>
                            <Col md={{ span: 10 }}></Col>
                            {/* className="btnspace" */}
                            <Col style={{ padding: "0%" }}></Col>
                            <Col style={{ padding: "0%" }}></Col>
                            <Col style={{ padding: "0%" }}>
                              {/* <Tooltip title="Reset" arrow>
                                <img className="navbutton" src={Reset} />
                              </Tooltip> */}
                              <span className="image">
                                <img className='navbutton' src={Reset} />
                                <Tooltip title="Reset">
                                  <img className='navgraybutton' src={grayreset} />
                                </Tooltip>
                              </span>
                            </Col>
                            <Col style={{ padding: "0%" }}>
                              {/* <Tooltip title="Save & Next" arrow>
                                <img className="navbutton" src={SaveNext} onClick={handleSubmit} />
                              </Tooltip> */}
                              <span className="image">
                                <img className='navbutton' src={SaveNext} onClick={handleSubmit} />
                                <Tooltip title="Save & Next">
                                  <img className='navgraybutton' src={graysave} onClick={handleSubmit} />
                                </Tooltip>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                    {showDocScreen && (<DocumentUpload formdetails={docDetails} />)}
                  </Row>
                </div>

                {false && (
                  <div className="mini-container mg_btm_set" style={{ marginTop: "-15px" }}>
                    {showTable == true && topform == false && (
                      <TableContainer>
                        <Table aria-label="customized table" size="small" style={{ tableLayout: 'auto' }} fixedHeader={false}>
                          <TableHead>
                            <TableRow>
                              <TableCell style={table_slNo}>#</TableCell>
                              <TableCell style={table_header}>
                                Promoter Name
                              </TableCell>
                              <TableCell style={table_header}>Email</TableCell>
                              <TableCell style={table_header}>Mobile</TableCell>
                              <TableCell style={table_header}>Type</TableCell>
                              {// <TableCell style={table_header}>Application type</TableCell>
                              }
                              <TableCell style={table_header}>Share</TableCell>
                              <TableCell style={table_header}>Status</TableCell>
                              <TableCell style={table_header}>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {promoterList.map((item, i) => (
                              <TableRow hover className="datable" key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.first_name}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.mobile}</TableCell>
                                <TableCell>{item.type}</TableCell>
                                {   // <TableCell>{item.eligibility_flag}</TableCell>
                                }

                                {item.type === "Borrower" ? (<TableCell>NA</TableCell>) :
                                  (<TableCell>{item.share}</TableCell>)
                                }

                                <TableCell> <i class="far fa-times-circle" style={{ color: "red" }}></i> </TableCell>
                                <TableCell style={{ display: "flex" }}>
                                  <Tooltip title="Edit" placement="bottom">
                                    <Button className="actionBtn" onClick={(e) => {
                                      getPromoterById(item.promoter_id);
                                    }}>
                                      <i className="fas fa-user-edit"
                                        style={{ fontSize: "15px" }} />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="delete" placement="bottom">
                                    <Button className="actionBtn" onClick={(e) => {
                                      deletePromoterById(item.promoter_id, item.business_id);
                                    }}> <i class="fas fa-user-minus" style={{ fontSize: "13px" }}></i></Button>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          {paginationHidden && (
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                  colSpan={12}
                                  count={5}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                  }}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                              </TableRow>
                            </TableFooter>
                          )}
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                )}
              </form>
            );
          }}
        </Formik>

        <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal
        }}
        >
          <Alert onClose={() => { setsavenextmessage(false) }} severity="success" className="snackBar">
            {snackBarMsg}
          </Alert>
        </Snackbar>

        { //  <div className="float-right open_right_view">
          //       {["right"].map((anchor) => (
          //         <React.Fragment key={anchor}>
          //           <Button
          //             onClick={toggleDrawer(anchor, true)}
          //             className="open_right_view_btn"
          //           >
          //             <ChevronLeft />
          //           </Button>
          //           <Drawer
          //             anchor={anchor}
          //             open={state[anchor]}
          //             onClose={toggleDrawer(anchor, false)}
          //           >
          //             {list(anchor)}
          //           </Drawer>
          //         </React.Fragment>
          //       ))}
          //     </div>
        }
      </Container>
    </>
  );
}