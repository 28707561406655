import React, { Component } from "react";
// Table from react-bootstrap
import { Table } from "react-bootstrap";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import axios from 'axios';
import { apiCalling } from '../../shared/constants'
import Container from "@material-ui/core/Container";
import { RadioGroup, RadioButton } from 'react-radio-buttons';

import Row from "react-bootstrap/Row"; // why bootstrap if material ui is used.
import Col from "react-bootstrap/Col"; // avoid integrating more than one ui libra

import addbutton from '../../image/pngicons/plussign.png';



export default function AdminQuestionsAdd(props) {
    const [headerValue, setHeaderValue] = React.useState("Financial Assessment");

    React.useEffect(() => {
        getAllFinAndFatHeaders("Financial Assessment");
        getServices();

    }, []);

    const getServices = async () => {
        
        console.log("hi");
        let url = apiCalling.thirdParty + "/server.php/getServices";
        const data = {
          "is_active":1
        }
    
        let tempArr = [];
    
        await axios
          .post(url, data)
          .then((result) => {
            console.log(result);
            result.data.data.map((item) => {
              tempArr.push({
                id: item.id,
                key: item.key,
                value: item.service,
                text: item.service,
              });
            });
         //   setServices(tempArr);
           // setApiService(tempArr[0].service);
          })
          .catch((error) => {
            console.log(error);
          });
      };



    const [finAndFatHeaders, setFinAndFatHeaders] = React.useState([]);


    // get all parameter header data  fin and fat check page
    const getAllFinAndFatHeaders = async (headerData) => {
        let data = {
            header: headerData
        }
        await axios.post(apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getFinAndFatHeaders', data).then(result => {
            console.log(result);
            if (result.data.status) {
                setFinAndFatHeaders(result.data.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /// get child question based on fin and fat headers;

    const [questionsLevel1, setQuestionsLevel1] = React.useState([]);

    const getAllMasterQuestionByFinAndFatHeaderId = async (rowId) => {

        let data = {
            "headerId": rowId
        }
        await axios.post(apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getAllMasterQuestionByFinAndFatHeaderId', data).then(result => {
            console.log(result);
            if (result.data.status) {
                setQuestionsLevel1(result.data.data);
            }else{
                setQuestionsLevel1([]);
            }
        }).catch(error => {
            console.log(error);
        });

    }

        // gett all questions by parent Id 
    const getQuestionsBtParentId = async(parentId)=>{
        let data ={
            
        }
        await axios.post(apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getAllMasterQuestionByFinAndFatHeaderId', data).then(result => {
            console.log(result);
            if (result.data.status) {
                setQuestionsLevel1(result.data.data);
            }else{
                setQuestionsLevel1([]);
            }
        }).catch(error => {
            console.log(error);
        });

    }


    return (
        <>
            <Container maxWidth="lg">
                <div className="mini-container mg_btm_set">
                    {
                        // <Table striped bordered hover>
                        //     <thead>
                        //         <tr>
                        //             <th>#</th>
                        //             <th>Name</th>
                        //             <th>Email</th>
                        //         </tr>
                        //     </thead>
                        //     <tbody>
                        //         <tr
                        //             data-toggle="collapse"
                        //             data-target=".multi-collapse1"
                        //             aria-controls="multiCollapseExample1"
                        //         >
                        //             <td>1</td>
                        //             <td>TEST 123</td>
                        //             <td>test123@test.com</td>
                        //         </tr>
                        //         <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                        //             <td>Child col 1</td>
                        //             <td>Child col 2</td>
                        //             <td>Child col 3</td>
                        //         </tr>
                        //         <tr
                        //             data-toggle="collapse"
                        //             data-target=".multi-collapse2"
                        //             aria-controls="multiCollapseExample2"
                        //         >
                        //             <td>2</td>
                        //             <td>TEST 456</td>
                        //             <td>test456@test.com</td>
                        //         </tr>
                        //         <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                        //             <td>Child col 1</td>
                        //             <td>Child col 2</td>
                        //             <td>Child col 3</td>
                        //         </tr>
                        //     </tbody>
                        // </Table>
                    }

                    <Row>
                        <Col md={{ span: 6 }}>
                            <RadioGroup onChange={(value) => {
                                
                                console.log(value);
                                setHeaderValue(value);
                                getAllFinAndFatHeaders(value);
                            }} horizontal>
                                <RadioButton value="Financial Assessment">
                                    Financial Assessment
                                </RadioButton>
                                <RadioButton value="Financial Scoring">
                                    Financial Scoring
                                </RadioButton>
                            </RadioGroup>
                        </Col>
                        <Col md={{ span: 6 }}>
                        </Col>
                    </Row>


                    <Table striped bordered hover style={{ marginTop: "2%" }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>id</th>
                                <th>parameter  name</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                finAndFatHeaders.map((row, i) => (
                                    <>
                                        <tr
                                            data-toggle="collapse"
                                            data-target={`.multi-collapse${row.id}`}
                                            aria-controls={`multiCollapseExample${row.id}`}
                                            onClick={() => {
                                                console.log(row.id);
                                                getAllMasterQuestionByFinAndFatHeaderId(row.id);
                                            }}
                                        >
                                            <td>{i + 1}</td>
                                            <td>{row.id}</td>
                                            <td>{row.parameter_name}</td>
                                            <td> <img alt ="ig" className="actionBtn svgicon" src={addbutton}  /></td>
                                        </tr>

                                        <div style ={{overflow:"hidden"}} className={`collapse multi-collapse${row.id}`} id="multiCollapseExample1">
                                            {questionsLevel1.map((row2, j) => (
                                                <Table striped bordered hover style={{ marginTop: "2%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>id</th>
                                                            <th>parameter  Question</th>
                                                            <th>Header id</th>
                                                            <th>action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  
                                                        data-toggle="collapse" data-target={`.multi-collapse${row2.id}`} aria-controls="multiCollapseExample1" onClick={() => { console.log(row.id); getAllMasterQuestionByFinAndFatHeaderId(row.id); }}>
                                                            <td>{j + 1}</td>
                                                            <td>{row2.id}</td>
                                                            <td>{row2.question_description}</td>
                                                            <td>{row2.question_id}</td>
                                                            <td> <img alt ="ig" className="actionBtn svgicon" src={addbutton}  /></td>
                                                        </tr>

                                                        <div style ={{overflow:"hidden"}} className={`collapse multi-collapse${row2.id}`} id="multiCollapseExample1">
                                                        {questionsLevel1.map((row3, j) => (
                                                            <Table striped bordered hover style={{ marginTop: "2%" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>id</th>
                                                                        <th>parameter  Question</th>
                                                                        <th>Header id</th>
                                                                        <th>action</th>
            
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr  
                                                                    data-toggle="collapse" data-target={`.multi-collapse${row3.id}`} aria-controls={`multiCollapseExample${row3.id}`} onClick={() => { console.log(row.id); getAllMasterQuestionByFinAndFatHeaderId(row.id); }}>
                                                                        <td>{j + 1}</td>
                                                                        <td>{row2.id}</td>
                                                                        <td>{row2.question_description}</td>
                                                                        <td>{row2.question_id}</td>
                                                                        <td onClick = {e=>{
                                                                            e.preventDefault();
                                                                            alert("hello");
                                                                        }}> <img alt ="ig" className="actionBtn svgicon" src={addbutton} onClick = {(e)=>{
                                                                            
                                                                            e.preventDefault();
                                                                            alert("hello");
                                                                        }   }  /></td>
                                                                    </tr>
            
            
            
                                                                </tbody>
                                                            </Table>
                                                        ))}
            
                                                    </div>
                                                    </tbody>
                                                </Table>
                                            ))}

                                        </div>

                                    </>
                                ))
                            }


                        </tbody>
                    </Table>


                </div>
            </Container>

        </>
    );




}