import React, { useState, useEffect } from 'react';
import { loadCSS } from "fg-loadcss";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "@material-ui/core/Icon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputLabel, TextField, Button, Checkbox, Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { test } from "../../shared/validations";
import FacebookLogin from "react-facebook-login";
import Modal from "react-bootstrap/Modal";
import GoogleLogin from "react-google-login";
import "../SignIn/index.css";
import { useStyles } from "./styles";
import logo from "../../image/logo.png";
import background_iamge from "../../image/LoginBG1.svg";
import Icons from "@mdi/react";
import { Dropdown } from 'semantic-ui-react';
import { entityoption } from "../../shared/constants";
import { mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert, mdiArrowRightBox, mdiArrowLeftBox, mdiPlusBox, mdiCloudUpload } from "@mdi/js";
import settingsBackupRestore from "material-ui/svg-icons/action/settings-backup-restore";
import axios from "axios";
import { ContentAddBox } from "material-ui/svg-icons";
import { MDBDataTableV5 } from 'mdbreact';

import { apiCalling } from '../../shared/constants'

export default function Test() {

    const [users, setUsers] = useState([]);
    useEffect(() => {
        loadUsers();
    }, []);
  
    const loadUsers = async () => {
        const result = await axios.get("http://dummy.restapiexample.com/api/v1/employees");
        setUsers(result.data.data);
        console.log(result.data.data);
    }
    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: 'Sl No.',
                field: 'slno',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'Name',
                field: 'name',
                width: 270,
            },
            {
                label: 'Email Id',
                field: 'email',
                width: 270,
            },
            {
                label: 'Mobile Number',
                field: 'number',
                width: 200,
            },
            {
                label: 'Action',
                field: 'action',
                width: 100,
            },
        ],
        rows: [
            users.map((user,index)=>(
                {
                    slno: '1',
                    name: 'Nitin',
                    email: 'partner',
                    number: 'MG Road',
                    action: 'Edit'
                }
            ))
        ],
       
    });
    return (
        <Formik initialValues={{
            name: "",
            email: "",
            number: ""
        }}
            validationSchema={test}
            onSubmit={(values) => {
                axios.post(apiCalling.coderscorner + "server.php/api/studentsave", values).then((res) => {
                    if (res != null && res != undefined) {
                        alert(res.data.message);
                    }
                })
            }}
        >{({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => {
            return (
                <Container maxWidth="lg">
                    <div className="mini-container">
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <Typography text="Test Information" styleType="D" />
                                        </Col>
                                        <Col md={{ span: 4 }}>
                                            <Typography text="First Name" styleType="B" />
                                            <List>
                                                <ListItem>
                                                    <TextField name="name" id="filled-error-helper-text standard-basic" label="User Name"
                                                        onChange={handleChange} onBlur={handleBlur} value={values.name}
                                                        error={
                                                            errors.name && touched.name && errors.name ? true : false
                                                        }
                                                        helperText={
                                                            errors.name && touched.name && errors.name
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Col>
                                        <Col md={{ span: 4 }}>
                                            <Typography text="Email Id" styleType="B" />
                                            <List>
                                                <ListItem>
                                                    <TextField name="email" label="Email Id" value={values.email}
                                                        onBlur={handleBlur} onChange={handleChange}
                                                        error={
                                                            errors.email && touched.email && errors.email ? true : false
                                                        }
                                                        helperText={
                                                            errors.email && touched.email && errors.email
                                                        } />
                                                </ListItem>
                                            </List>
                                        </Col>
                                        <Col md={{ span: 4 }}>
                                            <Typography text="Mobile Number" styleType="B" />
                                            <List>
                                                <ListItem>
                                                    <TextField name="number" label="Mobile Number" value={values.number}
                                                        onBlur={handleBlur} onChange={handleChange}
                                                        error={
                                                            errors.number && touched.number && errors.number ? true : false
                                                        }
                                                        helperText={
                                                            errors.number && touched.number && errors.number
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 4 }}>
                                            <Button className="ctm_btn_set footer" onClick={handleSubmit} >Submit</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>

                        <Row style={{ marginBottom: 7, marginTop: 75 }}>
                            <Col md={{ span: 12 }}><Typography text="Details" styleType="E" /></Col>
                        </Row>
                        <MDBDataTableV5
                            hover
                            entriesOptions={[5, 10, 20, 30]}
                            entries={5}
                            pagesAmount={4}
                            data={datatable}
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                        />
                    </div>
                </Container>
            )
        }}
        </Formik>
    )
}