import React from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Modal from 'react-bootstrap/Modal';
//import Input from '@material-ui/core/Input';
import { Dropdown } from 'semantic-ui-react';
import axios from "axios";
import Typography from '../../base_components/Typography';
import { Formik } from "formik";
import { AssetFormPropertyCheckSchema, AssetFormMotorVehicleCheckSchema, AssetFormMotorVehicleLoanSchema, AssetFormPropertyLoanSchema } from "../../shared/validations";
import { useStyletype } from "./styles";
import Icon from "@mdi/react";
import { mdiPlaylistPlus, mdiRefresh, mdiCloudUpload } from "@mdi/js";
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Reset from '../../image/buttons/reset.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackPosition } from "../../shared/constants";
import MuiAlert from '@material-ui/lab/Alert';
import grayback from '../../image/buttons/grayback.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import { Alert, AlertTitle } from '@material-ui/lab';
import deleterec from '../../image/pngicons/delete.png';
import up from '../../image/pngicons/up.png';
import down from '../../image/pngicons/down.png';
import editrec from '../../image/pngicons/edit.png';
import addbutton from '../../image/pngicons/plussign.png';
import DocumentUpload from "../../components/DocumentUpload";
import uploaddoc from '../../image/pngicons/upload.png';
import {ErrorCatch,apiCalling} from "../../shared/constants";


export default function Signup(props) {
  const classes = useStyletype();
  const [show, setshow] = React.useState(false);
  const [topform, settopform] = React.useState(false);
  const [table, settable] = React.useState(false);
  const [checkvalue, setchecked] = React.useState('');
  const [showNextButton, setShowNextButton] = React.useState(true);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const handleClose = () => setshow(false);
  const [area, setArea] = React.useState([]);
  const [assetlistshow, setassetlistshow] = React.useState(false);
  const [newassetshow, setnewassetshow] = React.useState(false);
  const [buspromlistshow, setbuspromlistshow] = React.useState(false);
  const [openBusinessBankingHeader, setOpenBusinessBankingHeader] = React.useState([]);
  const [openPromoterBankingHeader, setOpenPromoterBankingHeader] = React.useState([]);
  const [onAssetTypeId, setOnAssetTypeId] = React.useState();
  const [loanTypeId, setLoanTypeId] = React.useState('');
  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState();
  const [colorMessage, setcolorMessage] = React.useState();
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [headerValue, setHeaderValue] = React.useState("");

  const handleShow = () => setshow(true);
  /* const [assettype, setState] = React.usenpState(''); */
  const [state, setStates] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //for businesslist and promoterlist
  const [showPromoterTable, setShowPromoterTable] = React.useState(false);
  const [businessList, setBusinessList] = React.useState([]);
  const [promoterList, setPromoterList] = React.useState([]);
  const [showBusinessPromoterList, setShowBusinessPromoterList] = React.useState(true);
  //asset details form
  const [showAssetAddForm, setshowAssetAddForm] = React.useState([]);
  const [parentInfoData, setParentInfoData] = React.useState();

  const table_header = {
    fontWeight: "600",
    fontSize: "100%",
    width: "20%"
  };


  const table_Action = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  }

  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
    width: "1%"
  }

  const [loaderFlag, setLoaderFlag] = React.useState(true);

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    checkapply();
    getLoanType();
    checkassettypedata();
    checkassetsubtypedata(); //motorvehicle sub assettype
    checkassetsubtypepropertydata(); //property sub assettype
    checkpurposedata();
    checkcategorydata();
    checkstatedata();
    checkcitydata();
    checkusagetypedata();
    checkusagetypedataForMotor();
    checkmfacingroaddata();
    checkrooftypedata();
    getBusinessAndPromoterListByUser();
    //getAllBusinessbyuser();
    //getassetsbybusiness(); // get all assets by business
    checkIfRequest();
  }, []);

  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
  
  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { vertical, horizontal } = SnackPosition;

  const checkapply = () => {
    if (localStorage.getItem('RequestType')) {
      const checkdata = localStorage.getItem('RequestType'),
        checkform = JSON.parse(checkdata)
      //	//console.log('lets check', checkform)
      setchecked(checkform)
    } else {
      setchecked("1");
    }
  }

  const getLoanType = () => {
    if (localStorage.getItem('loantypeId')) {
      const loandata = JSON.parse(localStorage.getItem('loantypeId'))
      // checkid = JSON.parse(loandata)
      setLoanTypeId(loandata);
      if (loandata == 3) {
        setOnAssetTypeId(22)
      } else {
        setOnAssetTypeId(21)
      }
      setShowAsseType(false);
    }
  }

  const getBusinessAndPromoterListByUser = async () => {
    settopform(false);
    setShowBusinessPromoterList(true);
    
    const data = {
      user_id: checkuserid.user_id
    };
    let api_path= apiCalling.coderscorner +'server.php/api/getAssetsDetailsBusinessAndPromoterById';
    await axios.post(apiCalling.coderscorner +'server.php/api/getAssetsDetailsBusinessAndPromoterById', data).then((result) => {
      //console.log("user data", result);
      setLoaderFlag(false);
      setBusinessList(result.data.data.businessList);
      let openBusinessBankingHeaderArray = [];
      for (let i = 0; i < result.data.data.businessList.length; i++) {
        openBusinessBankingHeaderArray.push(false);
      }
      setOpenBusinessBankingHeader(openBusinessBankingHeaderArray);
      if (result.data.data.promoterList.length) {
        setPromoterList(result.data.data.promoterList);
        setShowPromoterTable(true);
      } else {
        setPromoterList([]);
        setShowPromoterTable(false);
      }

      let openPromoterBankingHeaderArray = [];
      for (let i = 0; i < result.data.data.promoterList.length; i++) {
        openPromoterBankingHeaderArray.push(false);
      }
      setOpenPromoterBankingHeader(openPromoterBankingHeaderArray);
    }).catch((error) => {
      ErrorCatch( checkuserid.user_id,error,api_path,'post',data,checkuserid.user_id,checkuserid.user_id);
      console.log(error);
    });
  }

  const handleOpenBusinessBankingHeader = (flag, i) => {
    let newArray = [];
    for (let j = 0; j < openBusinessBankingHeader.length; j++) {
      if (j == i) {
        newArray.push(flag);
      } else {
        newArray.push(openBusinessBankingHeader[j]);
      }
    }
    setOpenBusinessBankingHeader(newArray);
  }

  const handleOpenPromoterBankingHeader = (flag, i) => {
    let newArray = [];
    for (let j = 0; j < openPromoterBankingHeader.length; j++) {
      if (j == i) {
        newArray.push(flag);
      } else {
        newArray.push(openPromoterBankingHeader[j]);
      }
    }
    setOpenPromoterBankingHeader(newArray);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStates({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation ctm_set_div">

      <div className='profile_table'>
        <div className="profile_table_part">
          <table style={{ marginBottom: 30, }}>
            <thead>
              <tr>
                <th><h3>Our Products</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> <h4>Show a first time product primer to the applicant</h4></td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th><h3>Our News Feed</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> <h4>Product announcements, new tie-ups, offerings, special offers, informational links etc</h4></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button className='close_view' onClick={toggleDrawer(anchor, false)}>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );

  //asset list data from business Id
  const [assetlistData, setassetlistData] = React.useState([]);
  const [assetlist, setassetlist] = React.useState([]);
  const getassetsbybusiness = async (parentData) => {
    
    const data = {
      parent_id: parentData.parent_id,
      parent_type_id: parentData.parent_type_id
    };
    let api_path=apiCalling.coderscorner +'server.php/api/getAssetdetailsbyParentIdAndType';
    await axios.post(apiCalling.coderscorner +'server.php/api/getAssetdetailsbyParentIdAndType', data).then((result) => {
      //console.log(result.data.data);
      let assetarray = [];

      if (result.data.status == true) {
        result.data.data.map((item) => {
          assetarray.push({
            id: item.asset_header_id,
            assettype: item.asset_type_name,
            assetsubtype: item.asset_sub_type_name,
            purpose: item.purpose_name,
            category: item.category_name
          });
        });
        setassetlistData(result.data.data);
        // setshowAssetAddForm(true);
        setassetlistshow(false);
        setnewassetshow(true);
        setbuspromlistshow(true);
        setShowBusinessPromoterList(true)
        settable(true);
        settopform(false);
      } else {
        setShowBusinessPromoterList(false)
        setassetlistshow(false);
        setnewassetshow(true);
        setbuspromlistshow(true);
        setshowAssetAddForm(true);
        settopform(true);
        settable(false);
        checkapply();
      }
      setassetlist(assetarray)
      //console.log('getall Assets by business', assetarray)
    }).catch((error) => {
      ErrorCatch( parentData.parent_id,error,api_path,'post',data,parentData.parent_id,parentData.parent_id);
      //console.log(error);
    });
  };

  //get asset by asset id
  const [edit, setedit] = React.useState(false)
  const deleteassetdatabyassetid = async (item) => {
    //console.log(item.AssetsForBusiness[0].asset_header_id)
    let data = {
      id: item.AssetsForBusiness[0].asset_header_id,
      Token: "qwerty1234"
    };
    let api_path=apiCalling.coderscorner +'server.php/api/deleteAssetsHeader';
    await axios.post(apiCalling.coderscorner +'server.php/api/deleteAssetsHeader', data).then((result) => {
      // //console.log(result.data.data);
      let assetdata = result.data.status;
      if (result.data.status === true) {
        setcolorMessage("success");
        setShowMessage(result.data.message);
        setsavenextmessage(true);
      } else {
        setcolorMessage("success");
        setShowMessage(result.data.message);
        setsavenextmessage(true);
      }
      getBusinessAndPromoterListByUser();
      //console.log(assetdata)
    }).catch((err) => {
      ErrorCatch(checkuserid.user_id,err,api_path,'post',data,checkuserid.user_id,checkuserid.user_id);
      console.log(err);
    })
  };

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {
    props.handleBack();
  };

  const SaveasDraft = () => {
    handleSubmiting();
  }

  const handleOpenAssetForm = (data) => {
    setShowAsseType(true);
    setAddFormButtonValue("Submit");
    setHeaderValue("Adding");
    setShowNextButton(false);
    setShowSaveButton(false);
    let parentData = {
      'parent_type_id': 1,
      'parent_id': data.id,
      'parent_name': data.name
    }
    setParentInfoData(parentData);
    setInitialValue({
      ...initialValue, id: "", assetstype: onAssetTypeId, subasset: "", purpose: "", usagetype: "", category: "", pincode: "", area: "", provalue: "", doorsite: "", city: "",
      state: "", plotsize: "", units: "", measure: "", construct: "", floors: "", khata: "", rooftype: "", value: "", insurance: "", vehicle_no: "", parent_type_id: 1, parent_id: data.id
    })
    settopform(true);
    setShowBusinessPromoterList(false);
  }

  const [onAssetTypeName, setOnAssetTypeName] = React.useState();
  const handleAssetEditData = (parent, item) => {
    setShowNextButton(false);
    setShowSaveButton(false);
    setAddFormButtonValue("Update");
    setHeaderValue("Editing");
    
    let parentData = {
      'parent_type_id': item.parent_type_id,
      'parent_id': parent.id,
      'parent_name': parent.name
    }
    setParentInfoData(parentData);
    getPincodeData(item.pincode)
    setInitialValue({
      ...initialValue,
      // id:assetdata.asset_header_id,
      id: item.asset_header_id,
      assetstype: item.asset_type_id,
      subasset: item.asset_sub_type_id,
      purpose: item.purpose_id,
      usagetype: item.usage_type_id,
      category: item.category_id,
      pincode: item.pincode,
      area: Number(item.pin_id),
      provalue: item.value,
      doorsite: item.door_site_no,
      city: item.city,
      state: item.state_name,
      plotsize: item.size_of_plot_carpet,
      units: item.no_of_units_floor,
      measure: item.measure_facing_road_id,
      construct: item.age_of_construction,
      floors: item.total_no_of_floors,
      khata: item.converted,
      rooftype: item.type_of_floor_id,
      value: item.value,
      insurance: item.insurance,
      vehicle: item.vehicle_no,
      parent_id: parentData.parent_id,
      parent_type_id: parentData.parent_type_id
    });
    settopform(true);
    setOnAssetTypeId(item.asset_type_id);
    if (item.eligibility_flag === true) {
      setchecked("1")   // eligibilty flag true means checeligibilty false means Loan , 1 means check and 2 means loan
    } else {
      setchecked("0")
    }
    setShowBusinessPromoterList(false);
    setShowAsseType(false);
    setAssetTypeDisable(true);
    setOnAssetTypeName(item.asset_type_name);
  }

  const handleOpenAssetFormForPromoter = (data) => {
    setAddFormButtonValue("Submit");
    setHeaderValue("Adding");
    setShowNextButton(false);
    setShowSaveButton(false);
    let parentData = {
      'parent_type_id': 2,
      'parent_id': data.id,
      'parent_name': data.name
    }
    setParentInfoData(parentData);
    setInitialValue({
      ...initialValue, id: "", assetstype: onAssetTypeId, subasset: "", purpose: "", usagetype: "", category: "", pincode: "", area: "", provalue: "", doorsite: "", city: "", state: "", plotsize: "", units: "",
      measure: "", construct: "", floors: "", khata: "", rooftype: "", value: "", insurance: "", vehicle_no: "", parent_type_id: 2, parent_id: data.id
    })
    settopform(true);
    setShowBusinessPromoterList(false);
    checkapply();
  }

  const handleBusinessPromoterList = () => {
    setShowDocScreen(false);
    setShowNextButton(true);
    setShowSaveButton(false);
    setshowAssetAddForm(false);
    setShowBusinessPromoterList(true);
    settopform(false);
    settable(false);
    setShowAsseType(true);
    //setOnAssetTypeId(0);
    setInitialValue({
      ...initialValue, id: "", assetstype: onAssetTypeId, subasset: "", purpose: "", usagetype: "", category: "", pincode: "", area: "", provalue: "", doorsite: "", city: "", state: "",
      plotsize: "", units: "", measure: "", construct: "", floors: "", khata: "", rooftype: "", value: "", insurance: "", vehicle_no: "", parent_type_id: 1, parent_id: data.id
    })
  }

  const [pinstate, setpinstate] = React.useState();
  const [pincity, setpincity] = React.useState();
  const getPincodeData = async (data) => {
    //
    let getPincode = {
      pincode: data,
      token: "qwerty1234"
    }
    
    await axios.post(apiCalling.coderscorner +`server.php/api/getDetailsbyPincode`, getPincode).then((res) => {
      //console.log("yeh we have pincode", res.data);
      //console.log("state", res.data.data[0].state_id);
      //console.log("city", res.data.data[0].city_id);
      setpinstate(res.data.data[0].state_id);
      setpincity(res.data.data[0].city_id);
      return handleAllAreas(res.data.data)
    })
      .catch((er) => {
             console.log("no pincode sorry ", er);
      });
    getcitystate();
  }

  const getcitystate = () => {
    getstatebystateid(pinstate);
    getcitybycityid(pincity);
  }

  const [statename, setstatename] = React.useState();
  const getstatebystateid = async (pinstate) => {
    //
    let statedata = {
      state_id: pinstate,
      Token: "qwerty1234"
    }
    await axios.post(apiCalling.coderscorner +`server.php/api/getAllStateById`, statedata).then((res) => {
      //console.log(res.data.data.state_name)
      setstatename(res.data.data.state_name)
    }).catch((er) => {
      console.log("no State data sorry ", er);
    });
  }

  const [cityname, setcityname] = React.useState();
  const getcitybycityid = async (pincity) => {
    //
    let citydata = {
      city_id: pincity,
      Token: "qwerty1234"
    }
    await axios.post(apiCalling.coderscorner +`server.php/api/getCityById`, citydata).then((res) => {
      //console.log(res.data.data.city)
      setcityname(res.data.data.city)
    }).catch((er) => {
      console.log("no city data sorry ", er);
    });
  }


  const handleAllAreas = (data) => {
    
    let allArea = []
    for (let i = 0; i < data.length; i++) {
      allArea.push({ key: data[i].area, value: data[i].id, text: data[i].area })
    }
    return setArea(allArea)
  }

  //converted dropdown
  const checkConvertedData = [
    { key: 'Yes', value: 1, text: 'Yes' },
    { key: 'No', value: 0, text: 'No' }
  ]

  //Assettype List
  const [assettype, setassettype] = React.useState([]);
  var assettypearray = []
  const checkassettypedata = async () => {
    const assettypedata = {
      token: "qwerty1234",
      type: "asset_type",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', assettypedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        assettypearray.push({
          key: item.short_name,
          value: item.id,
          //flag: item.name,
          text: item.name
        })
      })
      setassettype(assettypearray)
      //console.log('asset type',)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //AssetSubType List for motor
  const [assetsubtype, setassetsubtype] = React.useState([]);
  var assetsubtypearray = []
  const checkassetsubtypedata = async () => {
    const assetsubtypedata = {
      token: "qwerty1234",
      type: "asset_subtype_motor_vehicle",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', assetsubtypedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        assetsubtypearray.push({
          key: item.short_name,
          value: item.id,
          //flag: item.name,
          text: item.name
        })
      })
      setassetsubtype(assetsubtypearray)
      //console.log('asset sub type',)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //AssetSubType List for property
  const [assetsubtypeproperty, setassetsubtypeproperty] = React.useState([]);
  var assetsubtypepropertyarray = []
  const checkassetsubtypepropertydata = async () => {
    const assetsubtypepropertydata = {
      token: "qwerty1234",
      type: "asset_subtype_property",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', assetsubtypepropertydata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        assetsubtypepropertyarray.push({
          key: item.short_name,
          value: item.id,
          //flag: item.name,
          text: item.name
        })
      })
      setassetsubtypeproperty(assetsubtypepropertyarray)
      //console.log('asset sub type',)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }


  //purpose list 
  const [purposevalue, setpurposevalue] = React.useState([]);
  var purposearray = []
  const checkpurposedata = async () => {
    const purposedata = {
      token: "qwerty1234",
      type: "asset_purpose",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', purposedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        purposearray.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setpurposevalue(purposearray)
      //console.log('purpose', purposearray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //category List
  const [categoryvalue, setcategoryvalue] = React.useState([]);
  var categoryarray = []
  const checkcategorydata = async () => {
    const categorydata = {
      token: "qwerty1234",
      type: "asset_category",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', categorydata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        categoryarray.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setcategoryvalue(categoryarray)
      //console.log('purpose', purposearray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //state List
  const [statevalue, setstatevalue] = React.useState([]);
  var statearray = []
  const checkstatedata = async () => {
    const statedata = {
      token: "qwerty1234",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getAllState', statedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        statearray.push({
          key: item.state_name,
          value: item.state_id,
          text: item.state_name
        })
      })
      setstatevalue(statearray)
      //console.log('purpose', statearray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //city List
  const [cityvalue, setcityvalue] = React.useState([]);
  var cityarray = []
  const checkcitydata = async () => {
    const citydata = {
      token: "qwerty1234"
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getAllCity', citydata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        cityarray.push({
          key: item.city,
          value: item.id,
          text: item.city
        })
      })
      setcityvalue(cityarray)
      // //console.log('cityarray',cityarray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //usagetype list
  const [usagetypevalue, setusagetypevalue] = React.useState([]);
  var usagetypearray = []
  const checkusagetypedata = async () => {
    const usagetypedata = {
      token: "qwerty1234",
      type: "asset_usage_type",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', usagetypedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        usagetypearray.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setusagetypevalue(usagetypearray)
      // //console.log('cityarray',cityarray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  const [usagetypevalueForMotor, setusagetypevalueForMotor] = React.useState([]);
  var usagetypearraymotor = []
  const checkusagetypedataForMotor = async () => {
    const usagetypedata = {
      token: "qwerty1234",
      type: "asset_usage_type_motor",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', usagetypedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        usagetypearraymotor.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setusagetypevalueForMotor(usagetypearraymotor)
      // //console.log('cityarray',cityarray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //Measurement of Facing Rod value
  const [mfacingroadvalue, setmfacingroadvalue] = React.useState([]);
  var mfacingroadarray = []
  const checkmfacingroaddata = async () => {
    const mfacingroaddata = {
      token: "qwerty1234",
      type: "measurement_facing_road",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', mfacingroaddata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        mfacingroadarray.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setmfacingroadvalue(mfacingroadarray)
      // //console.log('cityarray',cityarray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }

  //Rooftype List
  const [rooftypevalue, setrooftypevalue] = React.useState([]);
  var rooftypearray = []
  const checkrooftypedata = async () => {
    const rooftypedata = {
      token: "qwerty1234",
      type: "roof_type",
    }
    await axios.post(apiCalling.coderscorner +'server.php/api/getMastertableDetails', rooftypedata).then(res => {
      res.data.data.map((item) => {
        //console.log('item', item)
        rooftypearray.push({
          key: item.short_name,
          value: item.id,
          text: item.name
        })
      })
      setrooftypevalue(rooftypearray)
      // //console.log('cityarray',cityarray)
    }).catch(er => {
      console.log("no data  ", er);
    });
  }


  //initialization of value for saving
  let [initialValue, setInitialValue] = React.useState({
    id: "",
    assetstype: "",
    subasset: "",
    purpose: "",
    usagetype: "",
    category: "",
    pincode: "",
    area: "",
    provalue: "",
    doorsite: "",
    city: "",
    state: "",
    plotsize: "",
    units: "",
    measure: "",
    construct: "",
    floors: "",
    khata: "",
    rooftype: "",
    value: "",
    insurance: "",
    vehicle: "",
    parent_id: "",
    parent_type_id: "",
  });

  let validator;
  if (checkvalue == "0") {
    if (onAssetTypeId == '22') {
      //console.log('motorvehicle validator value')
      validator = AssetFormMotorVehicleLoanSchema
    } else {
      validator = AssetFormPropertyLoanSchema
    }
  } else {
    if (onAssetTypeId == '22') {
      //console.log('motorvehicle else vaidator value')
      validator = AssetFormMotorVehicleCheckSchema
    } else {
      validator = AssetFormPropertyCheckSchema
    }
  }

  const handlesubmitform = (values, { resetForm }) => {
    
    //console.log('submit check', checkvalue)
    setLoaderFlag(true);
    if (checkvalue == "0") {
      if (onAssetTypeId == '22') {
        handleloanapplymotor(values);

      } else {
        handleloanapplyproperty(values)
      }
    } else {
      if (onAssetTypeId == '22') {
        handleEligibilitymotor(values)
      } else {
        handleEligibilityproperty(values)
      }
      setShowBusinessPromoterList(true)
    }
    setLoaderFlag(false);
    resetForm({
      assetstype: "", subasset: "", purpose: "", usagetype: "", category: "", pincode: "", area: "", provalue: "", value: "", insurance: "", vehicle_no: "",
      doorsite: "", city: "", state: "", plotsize: "", units: "", measure: "", construct: "", floors: "", khata: "", rooftype: ""
    })
    setInitialValue({
      // id:assetdata.asset_header_id,
      id: "",
      assetstype: "",
      subasset: "",
      purpose: "",
      usagetype: "",
      category: "",
      pincode: "",
      area: "",
      provalue: "",
      doorsite: "",
      city: "",
      state: "",
      plotsize: "",
      units: "",
      measure: "",
      construct: "",
      floors: "",
      khata: "",
      rooftype: "",
      value: "",
      insurance: "",
      vehicle_no: "",
      parent_type_id: 1,
      parent_id: data.id
    });
    setShowNextButton(true);
    props.handleCount(checkuserid.user_id);
  }

  const handleloanapplymotor = async (data) => {
    
    //let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let loanapplymotordata = {
      assets_id: initialValue.id,
      parent_id: data.parent_id, // BusinessId
      parent_type_id: data.parent_type_id, // parent_type Business
      asset_type_id: 22,
      asset_sub_type_id: data.subasset,
      purpose_id: data.purpose,
      // usage_type_id: data.usagetype,
      category_id: data.category,
      pin_id: parseInt(data.area),
      area: data.area, // pin_id
      value: data.value,
      insurance: data.insurance,
      vehicle_no: data.vehicle,
      user_id: checkuserid.user_id,
      eligibility_flag: 0
    }
    //console.log('result', loanapplymotordata)
    let api_path=apiCalling.coderscorner +`server.php/api/assets`;
   // //console.log(apiCalling.coderscorner +`server.php/api/assets`);
    await axios.post(apiCalling.coderscorner +`server.php/api/assets`, loanapplymotordata).then(res => {
      setdata(loanapplymotordata);
      //console.log('yes we have data', res.data)
      if (res.data.status === true) {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      } else {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      let parentData = {
        'parent_type_id': res.data.Data.assetHeader.parent_type_id,
        'parent_id': res.data.Data.assetHeader.parent_id
      }
      getBusinessAndPromoterListByUser();
      getassetsbybusiness(parentData);

    }).catch(err => {
      ErrorCatch(checkuserid.user_id,err,api_path,'post',loanapplymotordata,checkuserid.user_id,checkuserid.user_id);
      console.log("no data sorry ", err);
    });
    settopform(false);
    settable(true);
  }

  const handleloanapplyproperty = async (data) => {
    
   // let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let loanapplypropertydata = {
      assets_id: initialValue.id,
      parent_id: data.parent_id, // BusinessId
      parent_type_id: data.parent_type_id, // parent_type Business
      asset_type_id: 21,
      asset_sub_type_id: data.subasset,
      purpose_id: data.purpose,
      category_id: data.category,
      pin_id: parseInt(data.area),
      area: data.area, //pinid
      value: data.provalue,
      door_site_no: data.doorsite,
      state: data.state,
      city: data.city,
      size_of_plot_carpet: data.plotsize,
      usage_type_id: data.usagetype,
      no_of_units_floor: data.units,
      measure_facing_road_id: data.measure,
      age_of_construction: data.construct,
      total_no_of_floors: data.floors,
      converted: data.khata,
      type_of_floor_id: data.rooftype,
      user_id: checkuserid.user_id,
      eligibility_flag: 0
    }
    //console.log('result', loanapplypropertydata);
    let api_path=apiCalling.coderscorner +`coderscorner/server.php/api/assets`;
  //  //console.log(api_path);
    await axios.post(apiCalling.coderscorner +`server.php/api/assets`, loanapplypropertydata).then(res => {
      setdata(loanapplypropertydata);
      //console.log('yes we have data for loanapplypropertydata', res.data)
      if (res.data.status === true) {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      } else {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      let parentData = {
        'parent_type_id': res.data.Data.assetHeader.parent_type_id,
        'parent_id': res.data.Data.assetHeader.parent_id
      }
      getBusinessAndPromoterListByUser();
      getassetsbybusiness(parentData);
    }).catch(er => {
      ErrorCatch(checkuserid.user_id,er,api_path,'post',loanapplypropertydata,checkuserid.user_id,checkuserid.user_id);
      console.log("no data sorry ", er);
    });
    settopform(false);
    settable(true);
  }

  const handleEligibilitymotor = async (data) => {
    
   /// let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let eligibilitymotordata = {
      assets_id: initialValue.id,
      // parent_id: businessOfasset, // BusinessId
      // parent_type_id: parentypeid, // parent_type Business
      parent_id: data.parent_id,
      parent_type_id: data.parent_type_id,
      asset_type_id: 22,
      asset_sub_type_id: data.subasset,
      purpose_id: data.purpose,
      // usage_type_id: data.usagetype,
      category_id: data.category,
      pin_id: parseInt(data.area),
      area: data.area, //pin_id
      value: data.value,
      user_id: checkuserid.user_id,
      eligibility_flag: 1
    }
    //console.log('result', eligibilitymotordata)
    let api_path=apiCalling.coderscorner +`server.php/api/assets`;
    await axios.post(apiCalling.coderscorner +`server.php/api/assets`, eligibilitymotordata).then(res => {
      if (res.data.status === true) {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      } else {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      let parentData = {
        'parent_type_id': res.data.Data.assetHeader.parent_type_id,
        'parent_id': res.data.Data.assetHeader.parent_id
      }
      setdata(eligibilitymotordata);
      getBusinessAndPromoterListByUser();
      getassetsbybusiness(parentData);
    }).catch(err => {
      ErrorCatch(checkuserid.user_id,err,api_path,'post',eligibilitymotordata,checkuserid.user_id,checkuserid.user_id);
      console.log("no data sorry ", err);
    });
    settopform(false);
    settable(true);
  }

  const handleEligibilityproperty = async (data) => {
    
    let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let eligibilitypropertydata = {
      assets_id: initialValue.id,
      parent_id: data.parent_id, // BusinessId
      parent_type_id: data.parent_type_id, // parent_type Business
      asset_type_id: 21,
      asset_sub_type_id: data.subasset,
      purpose_id: data.purpose,
      category_id: data.category,
      pin_id: parseInt(data.area),
      area: data.area, //pind_id
      value: data.provalue,
      user_id: checkuserid.user_id,
      eligibility_flag: 1
    };
    //console.log('result', eligibilitypropertydata);
    let api_path=apiCalling.coderscorner +`server.php/api/assets`;
    await axios.post(apiCalling.coderscorner +`server.php/api/assets`, eligibilitypropertydata).then(res => {
      setdata(eligibilitypropertydata);
      //console.log('yes we have data for eligibilitypropertydata', res.data)
      if (res.data.status === true) {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      } else {
        setcolorMessage("success");
        setShowMessage(res.data.message);
        setsavenextmessage(true);
      }
      let parentData = {
        'parent_type_id': res.data.Data.assetHeader.parent_type_id,
        'parent_id': res.data.Data.assetHeader.parent_id
      }
      getBusinessAndPromoterListByUser();
      getassetsbybusiness(parentData);
    }).catch(err => {
      ErrorCatch(checkuserid.user_id,err,api_path,'post',eligibilitypropertydata,checkuserid.user_id,checkuserid.user_id);
      console.log("no data sorry ", err);
    });
    settopform(false);
    settable(true);
  }
  const handlenewsubmit = (values) => {
    //console.log('valuessssssss', values)
  }

  const [assetTypeDisable, setAssetTypeDisable] = React.useState(true);
  const [showAsseType, setShowAsseType] = React.useState(true);


  const [parentTypeId, setParentTypeId] = React.useState(0);
  const [parentId, setParentId] = React.useState(0);
  const [showDocScreen, setShowDocScreen] = React.useState(false);

  ///Document update 
  let docDetails = {
    formId: "1.1.8",
    parentId: parentId,
    parentTypeId: parentTypeId,
  }


  return (
    <Container maxWidth="lg">
      <div className="mini-container mg_btm_set">
        <Row>

          {showDocScreen && (
            <>
              <Col md={{ span: 10 }}></Col>
              <Col md={{ span: 2 }} className="mainHeaderBack">
                {/* <Tooltip title="Back" arrow>
                        <img style={{ float: "right", width: "25%", height: "83%", margin: "auto" }} src={Back} onClick={handleBusinessPromoterList} />
                      </Tooltip> */}
                <span className="image">
                  <img className='backbutton navbutton' src={Back} onClick={handleBusinessPromoterList} />
                  <Tooltip title="Back" arrow>
                    <img className='backbutton navgraybutton' src={grayback} onClick={handleBusinessPromoterList} />
                  </Tooltip>
                </span>
              </Col></>)}


          {showDocScreen && (<DocumentUpload formdetails={docDetails} />)}
        </Row>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validator}
          // onSubmit={(values) => {
          //   //console.log('kkkkk', values)
          //   handlenewsubmit(values);}}
          onSubmit={handlesubmitform}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  {loaderFlag == true && (
                    <>
                      <div className="content-header">
                        <Row>
                          <Col md={{ span: 6 }}>
                            <Typography text="YOUR ASSET DETAILS " styleType="C" />
                          </Col>
                        </Row>
                      </div>
                      <div style={{ textAlign: 'center', marginTop: '15%' }}>
                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                      </div>
                    </>
                  )}
                  {topform == true && (
                    <div className="content-header">
                      <Row>
                        <Col md={{ span: 10 }} className="mainheader1">
                          {parentInfoData.parent_type_id == 1 && (
                            <Typography text={ headerValue + " Asset for Business : " + parentInfoData.parent_name} styleType="C" />
                          )}
                          {parentInfoData.parent_type_id == 2 && (
                            <Typography text={ headerValue + " Asset for Promoter : " + parentInfoData.parent_name} styleType="C" />
                          )}
                        </Col>
                        {/* <Col md={{ span: 2 }}>
                          <div className="content-header-button">
                            <Button style={{ height: '35px' }} className="backBtn" variant="outlined" color="primary"
                              className={classes.button_others} onClick={handleBusinessPromoterList}>
                              <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                          </div>
                        </Col> */}
                        <Col md={{ span: 2 }} className="mainHeaderBack">
                          {/* <Tooltip title="Back" arrow>
                            <img style={{ float: "right", width: "25%", height: "83%", margin: "auto" }} src={Back} onClick={handleBusinessPromoterList} />
                          </Tooltip> */}
                          <span className="image">
                            <img className='backbutton navbutton' src={Back} onClick={handleBusinessPromoterList} />
                            <Tooltip title="Back" arrow>
                              <img className='backbutton navgraybutton' src={grayback} onClick={handleBusinessPromoterList} />
                            </Tooltip>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 12 }} className="subHeader1">
                          <table className="applicationTableDetails1">
                            <tr>
                              {/* <td>Type</td>
                            <td>:</td> */}
                              {requestDetails.request_type_id === 0 && (
                                <td>Loan Application</td>)}
                              {requestDetails.request_type_id === 1 && (
                                <td>Check Eligibility</td>)}
                              <td>&nbsp;|&nbsp;</td>
                              <td>Application Number</td>
                              <td>:</td>
                              <td>{requestDetails.reference_id}</td>
                              <td>&nbsp;|&nbsp;</td>
                              <td>Loan Type</td>
                              <td>:</td>
                              <td>{requestDetails.loan_type}</td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </div>)}
                  {showBusinessPromoterList == true && loaderFlag == false && (
                    <div>
                      <Row>
                        <div className="content-header">
                          <Row>
                            {/*  { requestDetails && (
                            <Col md={{ span: 12 }} style ={{marginBottom:"3%",textAlign:"center"}}>
                              <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
                            </Col>
                          )} */}
                            <Col md={{ span: 12 }} className="mainheader1">
                              <Typography text="YOUR ASSET DETAILS" styleType="C" />
                            </Col>
                            <Col md={{ span: 12 }} className="subHeader1">
                              <table className="applicationTableDetails1">
                                <tr>
                                  {/* <td>Type</td>
                                  <td>:</td> */}
                                  {requestDetails.request_type_id === 0 && (
                                    <td>Loan Application</td>)}
                                  {requestDetails.request_type_id === 1 && (
                                    <td>Check Eligibility</td>)}
                                  <td>&nbsp;|&nbsp;</td>
                                  <td>Application Number</td>
                                  <td>:</td>
                                  <td>{requestDetails.reference_id}</td>
                                  <td>&nbsp;|&nbsp;</td>
                                  <td>Loan Type</td>
                                  <td>:</td>
                                  <td>{requestDetails.loan_type}</td>
                                </tr>
                              </table>
                            </Col>
                          </Row>
                        </div>

                        <Typography styleType='SH' text='BUSINESS ASSETS' />
                        <TableContainer component={Paper}>
                          <Table aria-label="collapsible table" size="small" style={{ marginBottom: "50px" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="tableSLNoHeading">#</TableCell>
                                <TableCell className="tableBusinessNameHeading">Business Name</TableCell>
                                <TableCell className="tableGSTHeading">GST</TableCell>
                                <TableCell className="tablePANHeading">Business PAN</TableCell>
                                <TableCell className="tableEntityTypeHeading">Entity Type</TableCell>
                                <TableCell className="tableStatusHeading"></TableCell>
                                <TableCell className="tableActionHeading">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {businessList.map((item, i) => (
                                <>
                                  <TableRow hover className="datable" key={i}>
                                    <TableCell >{i + 1}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.gst}</TableCell>
                                    <TableCell>{item.bpan}</TableCell>
                                    <TableCell>{item.entity_type}</TableCell>
                                    {item.AssetsForBusiness.length > 0 && (
                                      <Tooltip title={item.AssetsForBusiness.length + " " + "Asset Added"} placement="top">
                                        <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.AssetsForBusiness.length}</p></TableCell>
                                      </Tooltip>
                                    )}
                                    {item.AssetsForBusiness.length == 0 && (
                                      <Tooltip title="No Asset Added" placement="top">
                                        <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.AssetsForBusiness.length}</p></TableCell>
                                      </Tooltip>
                                    )}
                                    {/* {item.AssetsForBusiness.length > 0 && (
                                      <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.AssetsForBusiness.length}</p></TableCell>
                                    )}
                                    {item.AssetsForBusiness.length === 0 && (
                                      <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.AssetsForBusiness.length}</p></TableCell>
                                    )} */}
                                    <TableCell className="tableActionHeading">
                                      {item.AssetsForBusiness.length > 0 && (
                                        <Tooltip title="Open Asset Details" placement="bottom">
                                          <Button aria-label="expand item" className="actionBtn" onClick={() => handleOpenBusinessBankingHeader(!openBusinessBankingHeader[i], i)}>
                                            {openBusinessBankingHeader[i] ? <img className="actionBtn svgicon" src={up} /> : <img className="actionBtn svgicon" src={down} />}
                                          </Button>
                                        </Tooltip>)}
                                      <Tooltip title="Add Asset Details" placement="bottom">
                                        {/* <Button className="actionBtn" onClick={() => handleOpenAssetForm(item)}>
                                          <i className="fas fa-plus" style={{ fontSize: "15px" }} />
                                        </Button> */}
                                        <img className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenAssetForm(item)} />
                                      </Tooltip>
                                      <Tooltip title="Upload Document" placement="bottom">
                                        <img className="actionBtn svgicon" src={uploaddoc} onClick={
                                          () => {
                                            setShowDocScreen(true);
                                            setParentTypeId(1);
                                            setParentId(item.id);
                                            setShowNextButton(true);
                                            setShowSaveButton(false);
                                            setshowAssetAddForm(false);
                                            setShowBusinessPromoterList(false);
                                            settopform(false);
                                            settable(false);
                                            setShowAsseType(true);
                                          }} />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                      <Collapse in={openBusinessBankingHeader[i]} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                          <Table size="small" aria-label="assetList">
                                            <TableHead style={{ backgroundColor: '#eee' }}>
                                              <TableRow>
                                                <TableCell className="tableSLNoHeading">#</TableCell>
                                                <TableCell className="tableBusinessNameHeading">Asset Type</TableCell>
                                                <TableCell className="tableGSTHeading">Asset Sub Type</TableCell>
                                                <TableCell className="tablePANHeading">Purpose</TableCell>
                                                <TableCell className="tableEntityTypeHeading">Category</TableCell>
                                                <TableCell colSpan={2} className="tableActionHeading">Action</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {item.AssetsForBusiness.map((AssetsForBusiness, j) => (
                                                <TableRow hover className="datable" key={j}>
                                                  <TableCell>{j + 1}</TableCell>
                                                  <TableCell>{AssetsForBusiness.asset_type_name}</TableCell>
                                                  <TableCell>{AssetsForBusiness.asset_sub_type_name}</TableCell>
                                                  <TableCell>{AssetsForBusiness.purpose_name}</TableCell>
                                                  <TableCell>{AssetsForBusiness.category_name}</TableCell>
                                                  <TableCell className="tableActionHeading">
                                                    <Tooltip title="Edit Asset Details" placement="bottom">
                                                      {/* <Button className="actionBtn" onClick={() => handleAssetEditData(item, AssetsForBusiness)}>
                                                        <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                                                      </Button> */}
                                                      <img className="actionBtn svgicon" src={editrec} onClick={() => handleAssetEditData(item, AssetsForBusiness)} />
                                                    </Tooltip>
                                                    <Tooltip title="Delete Asset Data" placement="bottom">
                                                      {/* <Button className="actionBtn" onClick={() => { deleteassetdatabyassetid(AssetsForBusiness.id) }}>
                                                        <i className="fas fa-minus"
                                                          style={{ fontSize: "15px" }} />
                                                      </Button> */}
                                                      <img className="actionBtn svgicon" src={deleterec} onClick={() => { deleteassetdatabyassetid(item) }} />
                                                    </Tooltip>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Row>
                      <Row>
                        <Typography styleType='SH' text='PROMOTER ASSETS' />
                        {showPromoterTable == false && (
                          <Alert severity="warning" className="warningMsg1">
                            <AlertTitle><p style={{ fontSize: "16px", color: '#663C00' }}>No Promoters have been Added to this Business. Click here to Add Promoter Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </p></AlertTitle>
                            <Button size="small" className="choicebtn" onClick={() => { props.handleNext(undefined, undefined, -7) }}>Add Promoter</Button>&nbsp;&nbsp;
                          </Alert>
                        )}
                        {showPromoterTable == true && (
                          <TableContainer component={Paper}>
                            <Table aria-label="collapsible table" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="tableSLNoHeading" colSpan={1}>#</TableCell>
                                  <TableCell className="tableBusinessNameHeading">Promoter Name</TableCell>
                                  {/* <TableCell style={table_header}>Business Name</TableCell> */}
                                  <TableCell className="tableGSTHeading">Email</TableCell>
                                  <TableCell className="tablePANHeading">Mobile</TableCell>
                                  <TableCell className="tableStatusHeading"></TableCell>
                                  <TableCell className="tableActionHeading">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {promoterList.map((item, i) => (
                                  <>
                                    <TableRow hover className="datable" key={i}>
                                      <TableCell>{i + 1}</TableCell>
                                      <TableCell>{item.first_name + " " + item.last_name}</TableCell>
                                      {/* <TableCell>{item.business_name}</TableCell> */}
                                      <TableCell>{item.email}</TableCell>
                                      <TableCell>{item.mobile}</TableCell>
                                      {item.AssetsForBusiness.length > 0 && (
                                        <TableCell className="tableStatusHeading"><p className="countRowNumber">{item.AssetsForBusiness.length}</p></TableCell>
                                      )}
                                      {item.AssetsForBusiness.length === 0 && (
                                        <TableCell className="tableStatusHeading"><p className="countRowNumberForRed">{item.AssetsForBusiness.length}</p></TableCell>
                                      )}
                                      <TableCell className="tableActionHeading">
                                        {item.AssetsForBusiness.length > 0 && (
                                          <Tooltip title="Open Asset Details" placement="bottom">
                                            <Button aria-label="expand item" className="actionBtn" onClick={() => handleOpenPromoterBankingHeader(!openPromoterBankingHeader[i], i)}>
                                              {openPromoterBankingHeader[i] ? <img className="actionBtn svgicon" src={up} /> : <img className="actionBtn svgicon" src={down} />}
                                            </Button>
                                          </Tooltip>)}
                                        <Tooltip title="Add Asset Details" placement="bottom">
                                          {/* <Button className="actionBtn" onClick={() => handleOpenAssetFormForPromoter(item)}>
                                          <i className="fas fa-plus" style={{ fontSize: "15px" }} />
                                        </Button> */}
                                          <img className="actionBtn svgicon" src={addbutton} onClick={() => handleOpenAssetFormForPromoter(item)} />
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                        <Collapse in={openPromoterBankingHeader[i]} timeout="auto" unmountOnExit>
                                          <Box margin={1}>
                                            <Table size="small" aria-label="assetList">
                                              <TableHead style={{ backgroundColor: '#eee' }}>
                                                <TableRow>
                                                  <TableCell className="tableSLNoHeading">#</TableCell>
                                                  <TableCell className="tableBusinessNameHeading">Asset Type</TableCell>
                                                  <TableCell className="tableGSTHeading">Asset Sub Type</TableCell>
                                                  <TableCell className="tablePANHeading">Purpose</TableCell>
                                                  <TableCell className="tableEntityTypeHeading">Category</TableCell>
                                                  <TableCell className="tableActionHeading" colSpan={2} >Action</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {item.AssetsForBusiness.map((AssetsForBusiness, j) => (
                                                  <TableRow hover className="datable" key={j}>
                                                    <TableCell>{j + 1}</TableCell>
                                                    <TableCell>{AssetsForBusiness.asset_type_name}</TableCell>
                                                    <TableCell>{AssetsForBusiness.asset_sub_type_name}</TableCell>
                                                    <TableCell>{AssetsForBusiness.purpose_name}</TableCell>
                                                    <TableCell>{AssetsForBusiness.category_name}</TableCell>
                                                    <TableCell className="tableActionHeading">
                                                      <Tooltip title="Edit Asset Details" placement="bottom">
                                                        <img className="actionBtn svgicon" src={editrec} onClick={() => handleAssetEditData(item, AssetsForBusiness)} />
                                                      </Tooltip>
                                                      <Tooltip title="Delete Asset Data" placement="bottom">
                                                        <img className="actionBtn svgicon" src={deleterec} onClick={() => { deleteassetdatabyassetid(item) }} />
                                                      </Tooltip>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>)}
                      </Row>
                    </div>
                  )}

                  <Row>
                    <Col md={{ span: 12 }}>
                      {topform == true && (
                        <div>
                          <Row>
                            {showAsseType == true && (
                              <>
                                <Col md={{ span: 6 }}>
                                  <Typography text="Asset Type" styleType="B" />
                                  <List component="div" disablePadding>
                                    <ListItem>
                                      <Dropdown
                                        placeholder="Select Asset Type"
                                        id="assetstype"
                                        name="assetstype"
                                        fluid
                                        search
                                        selection
                                        value={values.assetstype || ''}
                                        onBlur={(e) => setFieldTouched("assetstype", true)}
                                        onChange={(e, { name, value }) => {
                                          setFieldValue("assetstype", value);
                                          setOnAssetTypeId(value);
                                        }}
                                        // onChange = {(e, { value }) => setFieldValue("assetstype", value)}
                                        options={assettype}
                                        className={errors.assetstype && touched.assetstype ? classes.required : values.assetstype == '' ? classes.reqired : classes.man}
                                      />
                                    </ListItem>
                                  </List>
                                  {errors.assetstype && touched.assetstype && values.assetstype == '' && (
                                    <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.assetstype}</div>)}
                                </Col>
                                <Col md={{ span: 6 }}></Col>
                              </>
                            )}
                            {showAsseType == false && (
                              <>
                                <Col md={{ span: 6 }}>
                                  <Typography text="Asset Type" styleType="B" />
                                  <List component="div" disablePadding>
                                    <ListItem>
                                      <Dropdown
                                        placeholder="Select Asset Type"
                                        id="assetstype"
                                        name="assetstype"
                                        fluid
                                        search
                                        selection
                                        disabled={assetTypeDisable}
                                        value={values.assetstype || ''}
                                        onBlur={(e) => setFieldTouched("assetstype", true)}
                                        onChange={(e, { name, value }) => {
                                          setFieldValue("assetstype", value);
                                          setOnAssetTypeId(value);
                                        }}
                                        // onChange = {(e, { value }) => setFieldValue("assetstype", value)}
                                        options={assettype}
                                        className={errors.assetstype && touched.assetstype ? classes.required : values.assetstype == '' ? classes.reqired : classes.man}
                                      />
                                    </ListItem>
                                  </List>
                                  {errors.assetstype && touched.assetstype && values.assetstype == '' && (
                                    <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.assetstype}</div>)}
                                </Col>
                                <Col md={{ span: 6 }}></Col>
                              </>
                            )}
                            {onAssetTypeId == '22' && (
                              <Col md={{ span: 6 }}>
                                <Typography text="Asset SubType" styleType="B" />
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <Dropdown
                                      placeholder="Select Asset SubType"
                                      id="subasset"
                                      name="subasset"
                                      fluid
                                      search
                                      selection
                                      value={values.subasset || ''}
                                      onBlur={() => setFieldTouched("subasset", true)}
                                      onChange={(e, { value }) => setFieldValue("subasset", value)}
                                      options={assetsubtype}
                                      className={errors.subasset && touched.subasset ? classes.required : values.subasset == '' ? classes.reqired : classes.man}
                                    />
                                  </ListItem>
                                </List>
                                {errors.subasset && touched.subasset && values.subasset == '' && (
                                  <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.subasset}</div>)}
                              </Col>
                            )}
                            {onAssetTypeId == '21' && (
                              <Col md={{ span: 6 }}>
                                <Typography text="Asset SubType" styleType="B" />
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <Dropdown
                                      placeholder="Select Asset SubType"
                                      id="subasset"
                                      name="subasset"
                                      fluid
                                      search
                                      selection
                                      value={values.subasset || ''}
                                      onBlur={() => setFieldTouched("subasset", true)}
                                      onChange={(e, { value }) => setFieldValue("subasset", value)}
                                      options={assetsubtypeproperty}
                                      className={errors.subasset && touched.subasset ? classes.required : values.subasset == '' ? classes.reqired : classes.man}
                                    />
                                  </ListItem>
                                </List>
                                {errors.subasset && touched.subasset && values.subasset == '' && (
                                  <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.subasset}</div>)}
                              </Col>
                            )}
                            <Col md={{ span: 6 }}>
                              <Typography text="Purpose" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder="Select Purpose"
                                    id="purpose"
                                    name="purpose"
                                    fluid
                                    search
                                    selection
                                    value={values.purpose}
                                    onBlur={() => setFieldTouched("purpose", true)}
                                    onChange={(e, { value }) => setFieldValue("purpose", value)}
                                    options={purposevalue}
                                    className={errors.purpose && touched.purpose ? classes.required : values.purpose == '' ? classes.reqired : classes.man}
                                  />
                                </ListItem>
                              </List>
                              {errors.purpose && touched.purpose && values.purpose == '' && (
                                <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.purpose}</div>
                              )}
                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography text="Category" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <Dropdown
                                    placeholder="Select Category"
                                    id="category"
                                    name="category"
                                    fluid
                                    search
                                    selection
                                    value={values.category || ''}
                                    onBlur={() => setFieldTouched("category", true)}
                                    onChange={(e, { value }) => setFieldValue("category", value)}
                                    options={categoryvalue}
                                    className={errors.category && touched.category ? classes.required : values.category == '' ? classes.reqired : classes.man}
                                  />
                                </ListItem>
                              </List>
                              {errors.category && touched.category && values.category == '' && (
                                <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.category}</div>
                              )}
                            </Col>

                            <Col md={{ span: 6 }}>
                              <Typography text="Pincode" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <TextField
                                    id="filled-error-helper-text standard-basic"
                                    name="pincode"
                                    label="999999"
                                    onChange={handleChange}
                                    onBlur={e => {
                                      handleBlur(e)
                                      if (!errors.pincode) { getPincodeData(values.pincode) }
                                    }}
                                    error={errors.pincode && touched.pincode && errors.pincode ? true : false}
                                    InputProps={{ className: values.pincode ? classes.manorma : null, }}
                                    inputProps={{ maxLength: 6, }}
                                    value={values.pincode || ''}
                                    helperText={errors.pincode && touched.pincode && errors.pincode} />
                                </ListItem>
                              </List>
                            </Col>
                            <Col md={{ span: 6 }}>
                              <Typography text="Area" styleType="B" />
                              <List component="div" disablePadding>
                                <ListItem>
                                  <Dropdown placeholder="Select Area" id="area" name="area"
                                    fluid
                                    search
                                    selection
                                    value={values.area || ''}
                                    onBlur={() => setFieldTouched("area", true)}
                                    onChange={(e, { value }) => setFieldValue("area", value)}
                                    options={area}
                                    className={errors.area && touched.area ? classes.required : values.area == "" ? classes.reqired : classes.man} />
                                </ListItem>
                              </List>
                            </Col>
                            {onAssetTypeId == '22' && (
                              <>
                                <Col md={{ span: 6 }}>
                                  <Typography text="Value" styleType="B" />
                                  <List component="div" disablePadding>
                                    <ListItem>
                                      <TextField id="filled-error-helper-text standard-basic " label="Value" name="value"
                                        onChange={handleChange} onBlur={handleBlur} InputProps={{ className: values.value ? classes.manorma : null, }}
                                        error={errors.value && touched.value && errors.value ? true : false}
                                        value={values.value || ''}
                                        helperText={errors.value && touched.value && errors.value} />
                                    </ListItem>
                                  </List>
                                </Col>
                                {checkvalue == "0" && (
                                  <Col md={{ span: 6 }}>
                                    <Typography text="Insurance" styleType="B" />
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <TextField
                                          id="filled-error-helper-text standard-basic " label="Insurance" name="insurance"
                                          onChange={handleChange} onBlur={handleBlur}
                                          InputProps={{ className: values.insurance ? classes.manorma : null, }}
                                          error={errors.insurance && touched.insurance && errors.insurance ? true : false}
                                          value={values.insurance}
                                          helperText={errors.insurance && touched.insurance && errors.insurance} />
                                      </ListItem>
                                    </List>
                                  </Col>)}
                                {checkvalue == "0" && (
                                  <Col md={{ span: 6 }}>
                                    <Typography text="Vehicle Number" styleType="B" />
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <TextField id="filled-error-helper-text standard-basic "
                                          label="Vehicle Number" name="vehicle"
                                          onChange={handleChange} onBlur={handleBlur}
                                          InputProps={{ className: values.vehicle ? classes.manorma : null, }}
                                          error={errors.vehicle && touched.vehicle && errors.vehicle ? true : false}
                                          value={values.vehicle || ''}
                                          helperText={errors.vehicle && touched.vehicle && errors.vehicle} />
                                      </ListItem>
                                    </List>
                                  </Col>)}
                              </>)}
                            {onAssetTypeId == '21' && (
                              <>
                                <Col md={{ span: 6 }}>
                                  <Typography text="Property Value" styleType="B" />
                                  <List component="div" disablePadding>
                                    <ListItem>
                                      <TextField id="filled-error-helper-text standard-basic "
                                        label="Property Value" name="provalue"
                                        onChange={handleChange} onBlur={handleBlur}
                                        InputProps={{ className: values.provalue ? classes.manorma : null, }}
                                        error={errors.provalue && touched.provalue && errors.provalue ? true : false}
                                        value={values.provalue || ''}
                                        helperText={errors.provalue && touched.provalue && errors.provalue} />
                                    </ListItem>
                                  </List>
                                </Col>
                                {checkvalue == "0" && (
                                  <Col md={{ span: 6 }}>
                                    <Typography text="Door/Site No" styleType="B" />
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <TextField id="filled-error-helper-text standard-basic "
                                          label="Door/Site No" name="doorsite" onChange={handleChange} onBlur={handleBlur}
                                          InputProps={{ className: values.doorsite ? classes.manorma : null, }}
                                          error={errors.doorsite && touched.doorsite && errors.doorsite ? true : false}
                                          value={values.doorsite || ''}
                                          helperText={errors.doorsite && touched.doorsite && errors.doorsite} />
                                      </ListItem>
                                    </List>
                                  </Col>
                                )}

                                {checkvalue == "0" && edit == true && (
                                  <Col md={{ span: 6 }}>
                                    <Typography text="State" styleType="B" />
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <TextField id="filled-error-helper-text standard-basic "
                                          label="State" name="state" onChange={handleChange} onBlur={handleBlur}
                                          InputProps={{ className: values.state ? classes.manorma : null, }}
                                          error={errors.state && touched.state && errors.state ? true : false}
                                          value={values.state || ''}
                                          helperText={errors.state && touched.state && errors.state} />
                                      </ListItem>
                                    </List>
                                  </Col>

                                )}
                                {checkvalue == "0" && edit == true && (
                                  <Col md={{ span: 6 }}>
                                    <Typography text="City" styleType="B" />
                                    <List component="div" disablePadding>
                                      <ListItem>
                                        <TextField id="filled-error-helper-text standard-basic "
                                          label="City" name="city" onChange={handleChange} onBlur={handleBlur}
                                          InputProps={{ className: values.city ? classes.manorma : null, }}
                                          error={errors.city && touched.city && errors.city ? true : false}
                                          value={values.city}
                                          helperText={errors.city && touched.city && errors.city} />
                                      </ListItem>
                                    </List>
                                  </Col>
                                )}
                                {checkvalue == "0" && (
                                  <>
                                   {onAssetTypeId == '21' && (
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Usage Type" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder="Select Usage Type"
                                            id="usagetype"
                                            name="usagetype"
                                            fluid
                                            search
                                            selection
                                            value={values.usagetype || ''}
                                            onBlur={() => setFieldTouched("usagetype", true)}
                                            onChange={(e, { value }) => setFieldValue("usagetype", value)}
                                            options={usagetypevalue}
                                            className={errors.usagetype && touched.usagetype ? classes.required : values.usagetype == '' ? classes.reqired : classes.man} />
                                        </ListItem>
                                      </List>
                                      {errors.usagetype && touched.usagetype && values.usagetype == '' && (
                                        <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.usagetype}</div>)}
                                    </Col>)}
                                    {onAssetTypeId == '22' && (
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Usage Type" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder="Select Usage Type"
                                            id="usagetype"
                                            name="usagetype"
                                            fluid
                                            search
                                            selection
                                            value={values.usagetype || ''}
                                            onBlur={() => setFieldTouched("usagetype", true)}
                                            onChange={(e, { value }) => setFieldValue("usagetype", value)}
                                            options={usagetypevalueForMotor}
                                            className={errors.usagetype && touched.usagetype ? classes.required : values.usagetype == '' ? classes.reqired : classes.man} />
                                        </ListItem>
                                      </List>
                                      {errors.usagetype && touched.usagetype && values.usagetype == '' && (
                                        <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.usagetype}</div>)}
                                    </Col>)}
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Size of plot / Carpet" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <TextField id="filled-error-helper-text standard-basic " label="Size of plot / Carpet" name="plotsize"
                                            onChange={handleChange} onBlur={handleBlur}
                                            InputProps={{ className: values.plotsize ? classes.manorma : null, }}
                                            error={errors.plotsize && touched.plotsize && errors.plotsize ? true : false}
                                            value={values.plotsize || ''}
                                            helperText={errors.plotsize && touched.plotsize && errors.plotsize} />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography text="No of Units / Floor" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <TextField
                                            id="filled-error-helper-text standard-basic "
                                            label="No of Units / Floor"
                                            name="units"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{ className: values.units ? classes.manorma : null, }}
                                            error={errors.units && touched.units && errors.units ? true : false}
                                            value={values.units || ''}
                                            helperText={errors.units && touched.units && errors.units} />
                                        </ListItem>
                                      </List>
                                    </Col>

                                    <Col md={{ span: 6 }}>
                                      <Typography text="Measurement-Facing Road" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <Dropdown placeholder="Select Measurement-Facing Road"
                                            id="measure" name="measure"
                                            fluid
                                            search
                                            selection
                                            value={values.measure}
                                            onBlur={() => setFieldTouched("measure", true)}
                                            onChange={(e, { value }) => setFieldValue("measure", value)}
                                            options={mfacingroadvalue || ''}
                                            className={errors.measure && touched.measure ? classes.required : values.measure == '' ? classes.reqired : classes.man} />
                                        </ListItem>
                                      </List>
                                      {errors.measure && touched.measure && values.measure == '' && (
                                        <div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.measure}</div>)}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Age of Construction" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <TextField id="filled-error-helper-text standard-basic "
                                            label="Age of Construction" name="construct"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{ className: values.construct ? classes.manorma : null, }}
                                            error={errors.construct && touched.construct && errors.construct ? true : false}
                                            value={values.construct || ''}
                                            helperText={errors.construct && touched.construct && errors.construct} />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Total no. of floors" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <TextField
                                            id="filled-error-helper-text standard-basic "
                                            label="Total no. of floors"
                                            name="floors"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{ className: values.floors ? classes.manorma : null, }}
                                            error={errors.floors && touched.floors && errors.floors ? true : false}
                                            value={values.floors || ''}
                                            helperText={errors.floors && touched.floors && errors.floors} />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Converted(only for B-khata)" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder="Converted(only for B-khata)"
                                            name="khata"
                                            fluid
                                            search
                                            selection
                                            value={values.khata || ''}
                                            onBlur={() => setFieldTouched("khata", true)}
                                            InputProps={{ className: values.khata ? classes.manorma : null, }}
                                            error={errors.khata && touched.khata && errors.khata ? true : false}
                                            onChange={(e, { value }) => setFieldValue("khata", value)}
                                            options={checkConvertedData}
                                            helperText={errors.khata && touched.khata && errors.khata} />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    {/* <Col md={{ span: 6 }}>
                                      <Typography text="Converted(only for B-khata)" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <TextField
                                            id="filled-error-helper-text standard-basic "
                                            label="Converted(only for B-khata)"
                                            name="khata"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{ className: values.khata ? classes.manorma : null, }}
                                            error={errors.khata && touched.khata && errors.khata ? true : false}
                                            value={values.khata || ''}
                                            helperText={errors.khata && touched.khata && errors.khata} />
                                        </ListItem>
                                      </List>
                                    </Col> */}
                                    <Col md={{ span: 6 }}>
                                      <Typography text="Type of Roof" styleType="B" />
                                      <List component="div" disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder="Select Type of Roof"
                                            id="rooftype"
                                            name="rooftype"
                                            fluid
                                            search
                                            selection
                                            value={values.rooftype || ''}
                                            onBlur={() => setFieldTouched("rooftype", true)}
                                            onChange={(e, { value }) => setFieldValue("rooftype", value)}
                                            options={rooftypevalue}
                                            className={errors.rooftype && touched.rooftype ? classes.required : values.rooftype == '' ? classes.reqired : classes.man}
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.rooftype && touched.rooftype && values.rooftype == '' && (<div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.rooftype}</div>)}
                                    </Col>
                                  </>
                                )}
                              </>
                            )}
                            <Col md={{ span: 12 }}>
                              <Row><Col md={{ span: 10 }}></Col>
                                <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                </Col>
                                <Col md={{ span: 5 }}></Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <div className='btn_row_fixd'>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          {/* className="btnspace" */}
                          {showNextButton == true && (
                            <>
                              <Col style={{ padding: "0%" }}></Col>
                              <Col style={{ padding: "0%" }}></Col>
                              <Col style={{ padding: "0%" }}>
                                {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                  <img className='navbutton' src={Back} onClick={handledata} />
                                </Tooltip> */}
                                <span className="image">
                                  <img className='navbutton' src={Back} onClick={handledata} />
                                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                    <img className='navgraybutton' src={grayback} onClick={handledata} />
                                  </Tooltip>
                                </span>
                              </Col>
                              <Col style={{ padding: "0%" }}>
                                {/* <Tooltip title="Save as Draft & Next" arrow>
                                  <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                </Tooltip> */}
                                <span className="image">
                                  <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                  <Tooltip title="Save & Next" arrow>
                                    <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                                  </Tooltip>
                                </span>
                              </Col>
                            </>
                          )}
                          {showSaveButton == true && (
                            <>
                              <Col style={{ padding: "0%" }}></Col>
                              <Col style={{ padding: "0%" }}></Col>
                              <Col style={{ padding: "0%" }}>
                                <Tooltip title="Reset" arrow>
                                  <img className='navbutton' src={Reset} />
                                </Tooltip>
                              </Col>
                              <Col md={{ span: 6 }}>
                                <Typography text="Total no. of floors" styleType="B" />
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <TextField
                                      id="filled-error-helper-text standard-basic "
                                      label="Total no. of floors"
                                      name="floors"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      InputProps={{ className: values.floors ? classes.manorma : null, }}
                                      error={errors.floors && touched.floors && errors.floors ? true : false}
                                      value={values.floors || ''}
                                      helperText={errors.floors && touched.floors && errors.floors} />
                                  </ListItem>
                                </List>
                              </Col>
                              <Col md={{ span: 6 }}>
                                <Typography text="Converted(only for B-khata)" styleType="B" />
                                <List component="div" disablePadding>
                                  <ListItem>
                                    {/* <TextField
                                      id="filled-error-helper-text standard-basic "
                                      label="Converted(only for B-khata)"
                                      name="khata"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      InputProps={{ className: values.khata ? classes.manorma : null, }}
                                      error={errors.khata && touched.khata && errors.khata ? true : false}
                                      value={values.khata || ''}
                                      helperText={errors.khata && touched.khata && errors.khata} /> */}
                                    <Dropdown
                                      placeholder="Converted(only for B-khata)"
                                      name="khata"
                                      fluid
                                      search
                                      selection
                                      value={values.khata || ''}
                                      onBlur={() => setFieldTouched("khata", true)}
                                      InputProps={{ className: values.khata ? classes.manorma : null, }}
                                      error={errors.khata && touched.khata && errors.khata ? true : false}
                                      onChange={(e, { value }) => setFieldValue("khata", value)}
                                      options={checkConvertedData}
                                      helperText={errors.khata && touched.khata && errors.khata} />
                                  </ListItem>
                                </List>
                              </Col>
                              <Col md={{ span: 6 }}>
                                <Typography text="Type of Roof" styleType="B" />
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <Dropdown
                                      placeholder="Select Type of Roof"
                                      id="rooftype"
                                      name="rooftype"
                                      fluid
                                      search
                                      selection
                                      value={values.rooftype || ''}
                                      onBlur={() => setFieldTouched("rooftype", true)}
                                      onChange={(e, { value }) => setFieldValue("rooftype", value)}
                                      options={rooftypevalue}
                                      className={errors.rooftype && touched.rooftype ? classes.required : values.rooftype == '' ? classes.reqired : classes.man}
                                    />
                                  </ListItem>
                                </List>
                                {errors.rooftype && touched.rooftype && values.rooftype == '' && (<div style={{ color: "red", fontSize: 11,marginTop: "-7px" }}>{errors.rooftype}</div>)}
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Dashboard</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row style={{ padding: 10, height: 'auto' }}>
                          <Col md={{ span: 12 }} spacing={0}>
                            <List component="div" disablePadding>
                              <ListItem >
                                <Col md={{ span: 4 }} ><font className={classes.listitem}> GST</font></Col>
                                <Col md={{ span: 8 }}>
                                  <input accept=".pdf,.doc" className={classes.input} id="contained-button-file" multiple type="file" />
                                  <label htmlFor="contained-button-file"><Button variant="contained" component="span" className={classes.uploadB} style={{ marginTop: '22%', marginLeft: '40%' }}> <Icon path={mdiCloudUpload} title="save" size={1} /> Upload</Button></label>
                                </Col>
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 12 }} spacing={0}>
                            <List component="div" disablePadding>
                              <ListItem >
                                <Col md={{ span: 4 }}><font className={classes.listitem}>Business PAN</font></Col>
                                <Col md={{ span: 8 }}>
                                  <input accept=".pdf,.doc" className={classes.input} id="contained-button-file" multiple type="file" />
                                  <label htmlFor="contained-button-file"><Button variant="contained" component="span" className={classes.uploadB} style={{ marginTop: '22%', marginLeft: '40%' }}>
                                    <Icon path={mdiCloudUpload} title="save" size={1} />
                                       Upload</Button></label>
                                </Col>
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 12 }} spacing={0}>
                            <List component="div" disablePadding>
                              <ListItem >
                                <Col md={{ span: 4 }}><font className={classes.listitem}>GRC </font></Col>
                                <Col md={{ span: 8 }}>
                                  <input accept=".pdf,.doc" className={classes.input} id="contained-button-file" multiple type="file" />
                                  <label htmlFor="contained-button-file"><Button variant="contained" component="span" className={classes.uploadB} style={{ marginTop: '22%', marginLeft: '40%' }}>
                                    <Icon path={mdiCloudUpload} title="save" size={1} />
                                       Upload</Button></label>
                                </Col>
                              </ListItem>
                            </List>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 50, height: 'auto' }}>
                          <Col md={{ span: 2 }}>
                          </Col>
                          <Col md={{ span: 5 }}>
                            <Button className={classes.linkdin} variant="contained" color="primary" onHide={handleClose}> <Icon path={mdiPlaylistPlus} title="save" size={1} /> Save</Button>
                          </Col>
                          <Col md={{ span: 3 }}>
                            <Button className={classes.button_div} variant="outlined" color="primary"><Icon path={mdiRefresh} title="reset" size={1} /> Reset</Button>
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </Row>
                </div>
                <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
                  onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                    vertical: SnackPosition.vertical,
                    horizontal: SnackPosition.horizontal
                  }}>
                  <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                    {showMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        </Formik>

        {   // <div className='float-right open_right_view'>
          //   {['right'].map((anchor) => (
          //     <React.Fragment key={anchor}>
          //       <Button onClick={toggleDrawer(anchor, true)} className='open_right_view_btn'>
          //         <ChevronLeft />
          //       </Button>
          //       <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          //         {list(anchor)}
          //       </Drawer>
          //     </React.Fragment>
          //   ))}
          // </div>
        }
      </div>
    </Container>
  );
}
