import React from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import { MDBDataTableV5, MDBDataTable } from "mdbreact";
//import MenuItem from "@material-ui/core/MenuItem";
import $ from 'jquery';
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Dropdown } from 'semantic-ui-react'
import { useStyle } from './styles'
import clsx from 'clsx'
import { Formik } from 'formik'
import { bankFormschema } from '../../shared/validations'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '../../base_components/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { Alert } from '@material-ui/lab'
import Checkbox from '@material-ui/core/Checkbox'
import { ErrorCatch, apiCalling } from '../../shared/constants'
import { Button, Grid } from '@material-ui/core'
import ProgressBar from 'react-bootstrap/ProgressBar'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
//import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import Snackbar from '@material-ui/core/Snackbar'
import { SnackPosition } from '../../shared/constants'
import Switch from '@material-ui/core/Switch'
import { purple } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'

import uploaddoc from '../../image/pngicons/upload.png'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import 'react-comments-section/dist/index.css'
import SearchBar from 'material-ui-search-bar'
export default function SpaMainPage (props) {
  const [navTabState, setnavTabState] = React.useState(false)
  const classes = useStyle()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const [openLoan, setOpenLoan] = React.useState(false)
  const [showform, setshowform] = React.useState(false)
  const [startTab, setStartTab] = React.useState(true)
  const [topform, settopform] = React.useState(true)
  const [tableopen, settableopen] = React.useState(false)
  const [table, settable] = React.useState(false)
  const [data, setdata] = React.useState([])

  const [busIdForDel, setbusIdForDel] = React.useState()
  //    const [showBusinessTab, setShowBusinessTab] = React.useState(false);
  const [loaderFlag, setLoaderFlag] = React.useState(false)

  const [openDialog, setOpenDialog] = React.useState(false)

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    search_bar: {
      display: 'none'
    }
    // MuiPaper-elevation1: {
    //   display: 'none'
    // }
  }))

  const [showCpaPage, setShowCpaPage] = React.useState(false)
  const [showmodal, setshowmodal] = React.useState(false)
  const handleclosePdf = () => {
    setshowmodal(false)
  }
  const handleshowhidepdf = (status, loan_type, id) => {
    setSelected([id]);
    getCPAData(status, loan_type);
    setshowmodal(true);
  }

  const ApplicationOptions = ['Loan Application', 'Check Eligibility']

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    )
    getUserId()
    getCPAData()
    getAllBusinessbyuser()
    localStorage.removeItem('rquestIdDetails')
    localStorage.removeItem('RequestType')
    localStorage.removeItem('handleFlow')
    getLoanStatusPercentage()
    getAllDocumentCollectors()
    getAllAppoinment()
    getAllAnalyst()
    getValuationAnalyst()
    getAllDisbursedApplication()
    getApplicationCount()
    var trs = $('table');

    //Get all rows from the trs table object.
    // var rows = trs.$("tr");
    console.log("\n \n $('tbody tr').length", document.getElementsByTagName("table")[0].rows)
    // $("table > tr").each(function (i) {
    //   console.log("\n \n yyyyyy========", i)
    // });
    // for(var i=0; i < $('tbody tr').length; i++){
    //   console.log("\n \n yyyyyy========", i)
    // }
  }, [])


  const [applicationCount, setApplicationCount] = React.useState(null);
  const [totalApplicationCount, setTotalApplicationCount] = React.useState(null);
  const [applicationAmount, setApplicationAmount] = React.useState(null);
  const [totalApplicationAmount, setTotalApplicationAmount] = React.useState(null);
  const getApplicationCount = async () => {
    let api_path = apiCalling.coderscorner + `server.php/api/getApplicationCount`;
    setLoaderFlag(true)
    let data = {
      enable: 1,
      loggedin_user: iduser.id,
    }
    await axios
      .post(
        api_path,
        data
      )
      .then(result => {
        console.log("\n \n result", result)
        if (result.data.status === true) {
          let arrr = result.data.data
          let amount = (Math.abs(Number(arrr.applicationAmount)) / 1.0e+6).toFixed(2);
          let allAmount = (Math.abs(Number(arrr.allApplicationAmount)) / 1.0e+6).toFixed(2)
          setApplicationCount(arrr.applicationCount)
          setTotalApplicationCount(arrr.allApplicationCount)
          setApplicationAmount(amount)
          setTotalApplicationAmount(allAmount)
          setLoaderFlag(false)
        } else {
          setLoaderFlag(false)
          setApplicationCount(0)
          setTotalApplicationCount(0)
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        // console.log(error)
      })
  }

  const [disbursedAppList, setDisbursedAppList] = React.useState([])
  const getAllDisbursedApplication = async () => {
    let api_path = apiCalling.coderscorner + `server.php/api/getAllDisbursedApplication`;
    setLoaderFlag(true)
    let data = {
      enable: 1,
      loggedin_user: iduser.id,
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAllDisbursedApplication`,
        data
      )
      .then(result => {
        if (result.data.status === true) {
          let arrr = result.data.data
          setDisbursedAppList(arrr)
          setLoaderFlag(false)
        } else {
          setLoaderFlag(false)
          if (result.data.data.length > 0) {
            setOpenDialog(true)
          }
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        // console.log(error)
      })
  }

  ///getBusiness and shareholder details based on user detail

  const [userBusinessList, setUserBusinessList] = React.useState([])

  const getAllBusinessAndPromoter = async () => {
    
    // setidofuser(iduser.id);
    let businessArray = []
    let promoterArray = []
    let data = {
      user_id: iduser.id
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getBusinessAndPromoterByUserId`
    await axios
      .post(
        apiCalling.coderscorner +
          `server.php/api/getBusinessAndPromoterByUserId`,
        data
      )
      .then(result => {
        if (result.data.data.businessList.length > 0) {
          setshowform(false)
          setStartTab(true)
          setBsId(result.data.data.businessList[0].id)
          result.data.data.businessList.map((item, i) => {
            businessArray.push({
              key: item.id,
              id: item.id,
              value: item.id,
              text: item.name,
              name: item.name,
              entity_type: item.entity_type,
              bpan: item.bpan,
              gst: item.gst
            })
          })
        } else {
          //setStartTab(false);
          setshowform(true)
        }
        // setUserPromoterList(promoterArray);
        setUserBusinessList(businessArray)
        // getAllLoansDetailsByUserId(businessArray[0].id)
        console.log(businessArray)
        console.log(promoterArray)
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
  }

  /// Business Information data
  // getting user Id from local storage
  const getUserId = () => {
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('main page user details', iduser)
    getAllBusinessAndPromoter(iduser.id)
  }

  const handleNextClick = () => {
    const localStorageData = localStorage.getItem('banking'),
      bankdata = JSON.parse(localStorageData)
    setdata(bankdata)
    console.log('shareholder', bankdata)
    settopform(false)
    // setshowform(false)
    settable(true)
    settableopen(true)
    console.log('clicked next')
  }

  // const handleChange = (event) => {
  //     setSelectedValue(event.target.value);
  // };

  // const handleSubmiting = () => {
  //     props.handleNext();
  // };
  // const handleSubmit = () => {
  //     setshow(false);
  //     setdigital(false);
  //     setdisp(true);
  // };
  // const handleSubmitdigital = () => {
  //     setdigital(true);
  //     setmodelbank(false);
  // };

  // const handleSubmited = () => {
  //     setshow(true);
  //     setmodelbank(false);
  // };

  // const handledata = () => {
  //     props.handleBack();
  // };

  // const toggleNavTab = () => {
  //     setnavTabState(!navTabState);
  // };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  // const SaveasDraft = () => {
  //     alert("Save As Draft");
  //     props.handleNext();
  // };

  // const hedaer1 = () => {
  //     return (
  //         <h1>hello</h1>
  //     )
  // }

  const classes1 = useStyles()
  const [value, setValue] = React.useState('1')

  // const handleChange1 = (event, newValue) => {
  //     setEditInitialValue({
  //         ...setEditInitialValue,
  //         company: bsid
  //     })
  //     setOpenTabBusPro(false);
  //     setValue(newValue);
  // };

  const [value2, setValue2] = React.useState('1')
  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
    setOpenTab(false)
  }

  ///get business of the user based on his id
  // const [bsid, setBsId] = useState();
  const [businesslist, setbusinesslist] = React.useState([])
  const [idofuser, setidofuser] = React.useState()
  const [userRole, setUserRole] = React.useState()
  const [bsid, setBsId] = React.useState()

  const [searched, setSearched] = React.useState('')
  const requestSearch = searchedVal => {
    
    let applicationArr = applicationList
    if (searchedVal.length != 0 && searchedVal != '') {
      const filteredRows = applicationArr.filter(row => {
        console.log(row)
        let arr = row.business_name
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
        if (arr) {
          return arr
        } else {
          setApplicationList(applicationListCopy)
        }
      })
      //setbusinesslist(filteredRows);
      setApplicationList(filteredRows)
    } else {
      setApplicationList(applicationListCopy)
    }
  }

  const cancelSearch = () => {
    //
    setSearched('')
    // setbusinesslist(newBus)
    setApplicationList(applicationListCopy)
  }
  // const [businessIdForDropDown, setBusinessIdForDropDown] = React.useState(0);
  // const [businessOfPromoter, setBusinessIdOfPromoter] = React.useState(0);
  const isSelected = name => selected.indexOf(name) !== -1
  const [selected, setSelected] = React.useState([])

  const getAllBusinessbyuser = async () => {
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.id)
    setidofuser(iduser.id)
    setUserRole(iduser.role_id)
    let arralen
    const data = {
      user_id: iduser.id
    }
    let busArr = []
    let api_path =
      apiCalling.coderscorner + 'server.php/api/getBussinessByuserid'
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getBussinessByuserid',
        data
      )
      .then(result => {
        //alert('user')
        // arralen = result.data.data.length
        if (result.data.status) {
          setBsId(result.data.data[0].id)
          getAllPromoterByBusinessId(result.data.data[0].id)
          setbusIdForDel(result.data.data[0].id)
          //  setBusinessIdForDropDown(result.data.data[0].id);
          if (result.data.status){
            result.data.data.map(item => {
              busArr.push({
                key: item.id,
                businessName: item.name,
                businessid: item.id,
                value: item.id,
                text: item.name
              })
            })
          }

          console.log(result.data.data[0].id)
          setbusinesslist(busArr)
          setbusinesslist(busArr)
          console.log('businessAll by userId', busArr)
          console.log(businesslist)
          console.log(bsid)
        } else {
          // alert('please add ')
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
    // business tab hidden if role_id = cpa
    getRequestData(arralen, 0)
  }
  // set initialBus id
  const checkuserloggeddata = localStorage.getItem('userdata')
  let iduser = JSON.parse(checkuserloggeddata)

  const [promoterList, setPromoterList] = React.useState([])
  const getAllPromoterByBusinessId = async bid => {
    const data = {
      bus_id: bid
    }
    let promoArr = []
    let api_path =
      apiCalling.coderscorner + `server.php/api/getAllpromoterinfobybusid`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAllpromoterinfobybusid`,
        data
      )
      .then(result => {
        settopform(true)
        console.log(result.data)
        result.data.data.map(item => {
          let typ = ''
          if (item.type === 1111) {
            typ = 'Promoter'
          } else if (item.type === 1233) {
            typ = 'Borrower'
          }
          promoArr.push({
            key: item.promoter_id,
            promoter_id: item.promoter_id,
            name: item.first_name + ' ' + item.last_name,
            type: typ,
            email: item.email,
            mobile: item.mobile,
            share: item.share_percent,
            value: item.promoter_id,
            text: item.first_name,
            eligibility_flag: item.eligibility_flag
          })
        })
        setPromoterList(promoArr)
        //console.log("businessAll by id", promoArr);
        // console.log(promoterList);
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
  }

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%'
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: 'auto'
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const [applicationList, setApplicationList] = React.useState([])
  const [applicationListCount, setApplicationListCount] = React.useState()
  const handleChangePage = (event, newPage) => {
    console.log('-new page', newPage)
    setPage(newPage)

    getRequestData(10, newPage)
  }

  const handleChangeRowsPerPage = event => {
    
    setRowsPerPage(+event.target.value)
    // setPage(0);
    getRequestData()
  }

  const handleChangeRowsPerPage1 = event => {
    
    setRowsPerPage(+event.target.value)
    // setPage(0);
   // getRequestData()
  }


  const [applicationListCopy, setApplicationListCopy] = React.useState([])

  const getRequestData = async (arralen, page) => {
    
    setLoaderFlag(true)
    console.log('---page', page)
    // let data = {
    //   enable: 1,
    //   offset: parseInt(page + rowsPerPage),
    //   limit: rowsPerPage
    // }

    let data = {
      enable: 1,
      loggedin_user: iduser.id,
      offset:0 ,
      limit: 700
    }
    let api_path =
      apiCalling.coderscorner + `server.php/api/getAllRequestdetails`
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAllRequestdetails`,
        data
      )
      .then(result => {
        console.log(result)
        console.log('loggedin user', localStorage.getItem("logged_in_user"))
        console.log('---- data cpunt', result.data.data_count)
        if (result.data.status === true) {
          let arrr = result.data.data
          console.log(arrr)
          setApplicationListCount(result.data.data_count)
          setApplicationList(arrr)
          setApplicationListCopy(arrr)
          setLoaderFlag(false)
        } else {
          setLoaderFlag(false)
          if (arralen > 0) {
            setOpenDialog(true)
          }
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
  }

  const goToLetsGetStarted = item => {
    localStorage.setItem('rquestIdDetails', JSON.stringify(item))
    localStorage.setItem('handleFlow', 'letget')
    if (item.request_type_id === 0) {
      localStorage.setItem('RequestType', 0)
    } else {
      localStorage.setItem('RequestType', 1)
    }
    props.handleNextLetStart()
  }

  const [openTab, setOpenTab] = React.useState(false)
  const anchorRef1 = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleMenuItemClick = value => {
    //const {value} = index.target;
    // console.log(event.target.value)
    setSelectedIndex(value)
    if (value === 0) {
      localStorage.setItem('check', JSON.stringify('Loan Application'))
      localStorage.setItem('RequestType', 0)
    } else {
      localStorage.setItem('check', JSON.stringify('Check Eligibility'))
      localStorage.setItem('RequestType', 1)
    }
    localStorage.setItem('handleFlow', 'letget')
    props.handleNext(0, 0, 3)
    setOpenTab(false)
  }

  const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[300],
      '&$checked': {
        color: purple[500]
      },
      '&$checked + $track': {
        backgroundColor: purple[500]
      }
    },
    checked: {},
    track: {}
  })(Switch)

  const [state1, setState1] = React.useState({
    checkedA: true
  })
  const handleRequestType = event => {
    setState1({ ...state1, [event.target.name]: event.target.checked })
  }

  const handleToggle1 = () => {
    setOpenLoan(prevOpen => !prevOpen)
  }

  const handleClose2 = event => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return
    }
    setOpenLoan(false)
  }

  function handleListKeyDown1 (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenLoan(false)
    }
  }


  const archiveLoadData = {
    columns: [
        {
            label: "#",
            field: "slno",
            width: 250,
            attributes: {
                "aria-controls": "DataTable",
                "aria-label": "Name",
            },
        },
        {
            label: "Ref Number",
            field: "reference_id",
            width: 300,
        },
        {
            label: "Business Name",
            field: "business_name",
            width: 300,
        },
        {
            label: "Business Pan",
            field: "pan",
            width: 370,
        },
        /*{
            label: "Application Type",
            field: "application_type",
            width: 300,
        },*/
        {
            label: "Loan Product",
            field: "loan_type",
            width: 300,
        },
        {
            label: "Date Logged",
            field: "created_on",
            width: 300,
        },
        {
            label: "Financer",
            field: "financier_name",
            width: 370,
        },
        {
            label: "Status",
            field: "status",
            width: 300,
        },
        {
            label: "Assigned FPA",
            field: "email",
            width: 300,
        },
        {
            label: "Assigned Analyst",
            field: "emailAnalyst",
            width: 300,
        },
        {
            label: "Action",
            field: "action",
            width: 500,
        },
    ],
    
    rows: applicationList.map((values, i) => {
        // getRoleName(values.role_id)
        var startDate = new Date(values.created_on);
        var today = new Date();
        var Difference_In_Days = (today - startDate) / (1000 * 3600 * 24);
        var loan_type = '';
        if(values.loan_type == 'Business Loan'){
          loan_type = 'BL';
        } else if(values.loan_type == 'Housing Loan'){
          loan_type = 'HL'
        } else if(values.loan_type == 'Loan Against Property'){
          loan_type = 'LAP'
        }

        return {
            slno: i + 1,
            reference_id: values.reference_id,
            // employee_code: values.employee_code,
            business_name: values.business_name,
            pan: values.pan,
            application_type: values.request_type_id == 0? 'Loan Application': 'Check Eligibility',
            loan_type: loan_type,
            created_on: values.created_on.split(' ')[0],
            financier_name: values.financier_name,
            status: <span title={parseInt(Difference_In_Days) + ' Days'}>{values.status}</span>,
            email: values.email.split('@')[0],
            emailAnalyst: values.emailAnalyst.split('@')[0],
            action:
                <div key={i} style={{ marginLeft: '-5px' }}>
                  <Tooltip
                    title='Loan'
                    placement='bottom'
                  >
                    <Button
                      className='actionBtn'
                      onClick={e => {
                        goToLetsGetStarted(values)
                      }}
                    >
                      <i className='fas fa-long-arrow-alt-right'></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title='FPA' placement='bottom'>
                    <Button
                      className='actionBtn'
                      onClick={() => handleshowhidepdf(values.status, values.loan_type, values.id)}
                    >
                      <i className='fa fa-user-plus'></i>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title='Analyst'
                    placement='bottom'
                  >
                    <Button
                      className='actionBtn'
                      disabled={values.loan_type == 'Business Loan'? true: false}
                      onClick={() =>
                        setShowmodalAnalyst(true)
                      }
                    >
                      <i
                        className='fas fa-chart-line'
                        style={{ fontSize: '15px' }}
                      ></i>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title='Document Collector'
                    placement='bottom'
                  >
                    <Button
                      className='actionBtn'
                      onClick={() =>
                        setShowDocCollector(true)
                      }
                    >
                      <i className='fas fa-file-upload'></i>
                    </Button>
                  </Tooltip>
                </div>
      }
    }),
  }



  const [statusArr, setStatusArr] = React.useState([])
  const getLoanStatusPercentage = () => {
    let data = {
      enable: 1,
      loggedin_user: iduser.id,
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/getRequestByStatus'
    axios
      .post(apiCalling.coderscorner + 'server.php/api/getRequestByStatus', data)
      .then(result => {
        console.log("Result of array-----", result)
        setStatusArr(result.data.data)
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
  }
  const getrequestTableByStats = data => {
    //
    let rbody = {
      status: data
    }
    let api_path =
      apiCalling.coderscorner + 'server.php/api/getRequestTableByStatus'
    axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getRequestTableByStatus',
        rbody
      )
      .then(result => {
        if (result.data.status === true) {
          setApplicationList(result.data.data)
        } else {
          setApplicationList([])
        }
        console.log(result)
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          rbody,
          iduser.id,
          iduser.id
        )
        console.log(error)
      })
  }

  const [cpaList, setCpaList] = React.useState([])
  const getCPAData = async (status=null, loan_type=null) => {
    let loan = null
    if(loan_type == 'Business Loan'){
      loan = 'BL'
    }
    let analyst_pri = ''
    if(status == 'Submitted'){
      analyst_pri = 'Pre-Login';
    } else if(status == 'Notified') {
      analyst_pri = 'Post-Login';
    }
    let data = {
      role_id: 1,
      analyst_privilege: analyst_pri,
      loan: loan
    }
    let api_path =
      apiCalling.identityManagement + `server.php/api/getUsersByRoleid`
    await axios
      .post(
        apiCalling.identityManagement + `server.php/api/getUsersByRoleid`,
        data
      )
      .then(result => {
        setCpaList(result.data.data)
        let arr = []
        result.data.data.map(item => {
          arr.push({ text: item.email, value: item.id })
        })
        setCpaList(arr)
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
      })
  }

  const handleClicked = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
    console.log('newSelected', newSelected)
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()
  const [colorMessage, setcolorMessage] = React.useState()
  const assignToCPA = async datas => {
    
    setLoaderFlag(true)
    let data = {
      user_id: datas.cpa,
      request_id: selected
    }
    console.log(data)
    let api_path = apiCalling.coderscorner + `server.php/api/assigningCpa`
    await axios
      .post(apiCalling.coderscorner + `server.php/api/assigningCpa`, data)
      .then(result => {
        if (result.data.status) {
          setshowmodal(false)
          setSelected([])
          getAllBusinessbyuser()
          console.log(result.data)
          setcolorMessage('success')
          setShowMessage(result.data.message)
          setShowMessage('Assigned Succesfully')
          setsavenextmessage(true)
          getRequestData()
          getAllAppoinment()
          setLoaderFlag(false)
        } else {
          setshowmodal(false)
          setSelected([])
          getAllBusinessbyuser()
          console.log(result.data)
          setcolorMessage('success')
          setShowMessage(result.data.message)
          setShowMessage('Assigned Succesfully')
          setsavenextmessage(true)
          getRequestData()
          getAllAppoinment()
          setLoaderFlag(false)


          setcolorMessage('Error')
          setShowMessage(result.data.message)
          setsavenextmessage(true)
        }
      })
      .catch(error => {
        ErrorCatch(
          iduser.id,
          error,
          api_path,
          'post',
          data,
          iduser.id,
          iduser.id
        )
      })
  }
  const [showDocCollector, setShowDocCollector] = React.useState(false)

  const handleCloseCollector = () => {
    setShowDocCollector(false)
  }
  const [allCollectors, setAllCollectors] = React.useState([])
  const [appointMentSelected, setAppointmentSelected] = React.useState([])

  const getAllDocumentCollectors = async () => {
    
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('user logged data of userid', iduser.id)
    let data = {
      enable: 1,
      user_id: iduser.id
    }
    let arr = []

    await axios
      .post(
        apiCalling.identityManagement + 'server.php/api/getAllDocumentTeam',
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status){
          result.data.data.map(item => {
            arr.push({ text: item.email, value: item.id })
          })
        }
        
        setAllCollectors(arr)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleClickedApp = (event, name) => {
    const selectedIndex = appointMentSelected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(appointMentSelected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(appointMentSelected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(appointMentSelected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        appointMentSelected.slice(0, selectedIndex),
        appointMentSelected.slice(selectedIndex + 1)
      )
    }
    setAppointmentSelected(newSelected)
    console.log('newSelected', newSelected)
  }

  const assignToCollector = values => {
    
    setLoaderFlag(true)

    let data = {
      user_id: values.collector,
      appointment_id: appointMentSelected
    }
    console.log(data)
    axios
      .post(apiCalling.coderscorner + 'server.php/api/assignDocTeam', data)
      .then(result => {
        console.log(result)
        setcolorMessage('success')
        setsavenextmessage(true)
        setShowMessage(result.data.message)
        getAllAppoinment()
        setShowMessage('Assigned Succesfully')
        setsavenextmessage(true)
        setLoaderFlag(false)
        setShowDocCollector(false)
      })
      .catch(error => {
        console.log(error)
        setLoaderFlag(false)
      })
  }

  const [allAppointment, setAllAppointment] = React.useState([])

  const getAllAppoinment = async () => {
    
    console.log('get All App----')
    let data = {
      enable: true
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllAppointments`, data)
      .then(result => {
        if (result.data.status === true) {
          setAllAppointment(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [showmodalAnalyst, setShowmodalAnalyst] = React.useState(false)
  const handlecloseAnalyst = () => {
    setShowmodalAnalyst(false)
  }

  const [allAnalyst, setAllAnalyst] = React.useState([])
  const getAllAnalyst = async () => {
    let data = {
      enable: 0
    }

    await axios
      .post(
        apiCalling.identityManagement + 'server.php/api/getAllAnalyst',
        data
      )
      .then(result => {
        let tempArr = []
        

        if (result.data.data) {
          result.data.data.map(item => {
            tempArr.push({ name: item.email, text: item.email, value: item.id })
          })
          setAllAnalyst(tempArr)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [valuationAnalyst, setValuationAnalyst] = React.useState([])
  const getValuationAnalyst = async () => {
    let data = {
      enable: 1
    }

    await axios
      .post(
        apiCalling.identityManagement + 'server.php/api/getValuationAnalyst',
        data
      )
      .then(result => {
        let tempArr = []
        

        if (result.data.data) {
          result.data.data.map(item => {
            tempArr.push({ name: item.email, text: item.email, value: item.id })
          })
          setValuationAnalyst(tempArr)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const assignToAnalyst = async analyst => {
    
    //setLoaderFlag(true);
    //  console.log(data1);
    let data = {
      user_id: analyst.analyst,
      valuation_user_id: analyst.valuation_analyst,
      request_id: selected
    }
    await axios
      .post(apiCalling.coderscorner + 'server.php/api/assigningAnalyst', data)
      .then(result => {
        console.log(result)

        setcolorMessage('success')
        setShowmodalAnalyst(false)
        setShowMessage(result.data.message)
        setShowMessage('Assigned Succesfully')
        setsavenextmessage(true)
        setLoaderFlag(false)
      })
      .catch(error => {
        console.log(error)
        setLoaderFlag(false)
      })
  }

  const searchRequestByName = text => {
    console.log(text.target.value)
    let data = {
      keyword: text.target.value
    }
    let api2 = apiCalling.coderscorner + 'server.php/api/searchRequestByName'
    axios
      .post(api2, data)
      .then(res => {
        if (res.data.status) {
          console.log(res.data)
          setApplicationList(res.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Container maxWidth='lg'>
      {loaderFlag == true ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: '15%',
            overflow: 'hidden',
            zIndex: '9999',
            top: '50%',
            left: '10%'
          }}
        >
          <Loader type={LoaderColor.type} color={LoaderColor.color} />
        </div>
      ) : (
        <>
          {/* {applicationList.length > 0 && ( */}
            <>
              <div
                className='mini-container mg_btm_set'
                style={{ marginTop: '7%' }}
              >
                <Row>
                  <Col md={{ span: 8 }}>
                    <Row>
                      <Col md={{ span: 12 }}>
                        <Typography text='Your Dashboard' styleType='C' />
                      </Col>
                      <Col md={{ span: 12 }}>
                        <div style={{ width: 1300 }}>
                          <Grid
                            component='label'
                            container
                            alignItems='center'
                            spacing={1}
                            style={{
                              width: '100%',
                              position: 'absolute',
                              left: '81%',
                              bottom: '104%'
                            }}
                          >
                            <Grid item className='option_name'>
                              Monthly
                            </Grid>
                            <Grid item>
                              <PurpleSwitch
                                checked={state1.checkedA}
                                onChange={handleRequestType}
                                name='checkedA'
                              />
                            </Grid>
                            <Grid item className='option_name'>
                              Yearly
                            </Grid>
                          </Grid>
                          <Container>
                            {statusArr.map(item => (
                              <Row key={item.key}>
                                <Col md={{ span: 12 }}>
                                  <label className='progresslabel'>
                                    {item.count} / 5L
                                  </label>
                                </Col>
                                <Col md={{ span: 3 }}>
                                  {' '}
                                  <label
                                    style={{ fontWeight: 600, float: 'right' }}
                                  >
                                    {item.key
                                      .replaceAll('_', ' ')
                                      .toUpperCase()}
                                  </label>{' '}
                                </Col>
                                <Col md={{ span: 6 }}>
                                  {' '}
                                  <ProgressBar
                                    variant={item.color}
                                    onClick={() =>
                                      getrequestTableByStats(item.key)
                                    }
                                    style={{
                                      color: 'black',
                                      cursor: 'pointer',
                                      border: '1px solid #000',
                                      height: '20px'
                                    }}
                                    now={
                                      item.percentage === 0
                                        ? '000000'
                                        : item.percentage
                                    }
                                    label={item.count}
                                  />
                                </Col>
                              </Row>
                            ))}
                          </Container>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={{ span: 4 }}>
                    <Row>
                      <Col
                        md={{ span: 12 }}
                        style={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginLeft: '4rem'
                        }}
                      >
                        <div className='dashcard'>
                          <p className='dashcardvalue'>{applicationCount}</p>
                          <p className='dashcardlabel'>
                            Application Processed by You
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={{ span: 12 }}
                        style={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginLeft: '4rem'
                        }}
                      >
                        <div className='dashcard'>
                          <p className='dashcardvalue'>{applicationAmount} M</p>
                          <p className='dashcardlabel'>
                            Loan Amount Processed by You
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={{ span: 12 }}
                        style={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginLeft: '4rem'
                        }}
                      >
                        <div className='dashcard'>
                          <p className='dashcardvalue'>{totalApplicationCount}</p>
                          <p className='dashcardlabel'>
                            Company wide Application Processed
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={{ span: 12 }}
                        style={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginLeft: '4rem'
                        }}
                      >
                        <div className='dashcard'>
                          <p className='dashcardvalue'>{totalApplicationAmount} M</p>
                          <p className='dashcardlabel'>
                            Company wide Application Processed
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <>
                  <Row>
                    {selected.length > 1 && (
                      <Col md={{ span: 12 }}>
                        <Button
                          className='actionBtn'
                          onClick={() => handleshowhidepdf()}
                          style={{
                            float: 'right',
                            marginRight: '3%',
                            marginTop: '24px',
                            marginBottom: '-4%'
                          }}
                        >
                          <i
                            class='fa fa-user-plus'
                            style={{ fontSize: '15px', zIndex: 9999 }}
                          ></i>
                        </Button>
                      </Col>
                    )}
                    {false && (
                      <Col md={{ span: 12 }}>
                        <IconButton
                          ref={anchorRef1}
                          aria-controls={
                            openLoan ? 'menu-list-grow1' : undefined
                          }
                          aria-haspopup='true'
                          onClick={handleToggle1}
                          style={{
                            float: 'right',
                            marginTop: '1%',
                            marginBottom: '-3%',
                            zIndex: '999'
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                        <Popper
                          open={openLoan}
                          anchorEl={anchorRef1.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                              }}
                            >
                              <Paper className='dashboarddd'>
                                <ClickAwayListener onClickAway={handleClose2}>
                                  <MenuList
                                    autoFocusItem={openLoan}
                                    id='menu-list-grow1'
                                    onKeyDown={handleListKeyDown1}
                                    style={{ padding: '0% 2%', width: '18ch' }}
                                  >
                                    {ApplicationOptions.map((option, index) => (
                                      <MenuItem
                                        // onClick={handleClose2}
                                        key={option}
                                        onClick={() =>
                                          handleMenuItemClick(index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </Col>
                    )}
                  </Row>
                  <TabContext value={value2}>
                  <Row>
                        <Col md={{ span: 12 }}>
                    <AppBar position='static' className="pt5 pb3" id="scs-main-tab">
                      <TabList
                        onChange={handleChange2}
                        aria-label='simple tabs example'
                      >
                        <Tab
                          label='Active Loan'
                          value='1'
                          className='tabSize'
                        />
                        <Tab
                          label='Archive Loan'
                          value='2'
                          className='tabSize'
                        />
                        <Tab
                          label='Appointments'
                          value='3'
                          className='tabSize'
                        />
                      </TabList>
                    </AppBar>
                    </Col>
                    </Row>
                    <TabPanel value='1' className='pl0 pr0'>
                      <Row>
                        <Col md={{ span: 9 }}>
                        </Col>
                        <Col md={{ span: 3 }}>
                          <SearchBar
                            className='search_bar'
                            value={searched}
                            onBlur={searchVal => searchRequestByName(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                          />
                        </Col>
                        <br/><br/><br/>
                        <Col md={{ span: 12 }}>
                          <div component={Paper} className='activeLoan' id="LoanPagination">
                              <MDBDataTableV5
                                  // rowHeight={100}
                                  // hover={true}
                                  // entriesOptions={[5, 10, 20, 25]}
                                  // entries={10}
                                  // pagesAmount={4}
                                  // data={archiveLoadData}
                                  // // searching={false}
                                  // searchTop={true}
                                  // id = "karzaTbl"

                                  hover
                                  entriesOptions={[5, 10, 20, 30, 40]}
                                  entries={10}
                                  pagesAmount={4}
                                  data={archiveLoadData}
                                  // pagingTop={true}
                                  // searchTop={true}
                                  // pagingTop
                                  // searchBottom={false}
                                  // barReverse
                                  // searchTop={archiveLoadData.rows.length > 0 ? true : false}
                                  // paging={archiveLoadData.rows.length > 0 ? true : false}
                              />
                          </div>
                        </Col>
                      </Row>
                      {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component='div'
                        count={applicationListCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      /> */}
                    </TabPanel>

                    <TabPanel value='2' className='pl0 pr0'>
                      <TableContainer>
                        <Table
                          aria-label='customized table'
                          size='small'
                          style={{ marginTop: '1%', marginBottom: '1% 0% 3%' }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell width='5%' style={table_slNo}>
                                #
                              </TableCell>
                              <TableCell style={table_header}>
                                Ref Number
                              </TableCell>
                              <TableCell style={table_header}>
                                Business Name
                              </TableCell>
                              <TableCell style={table_header}>
                                Business Pan
                              </TableCell>
                              {/*<TableCell style={table_header}>
                                Application Type
                              </TableCell>*/}
                              <TableCell style={table_header}>
                                Loan Product
                              </TableCell>
                              <TableCell style={table_header}>
                                Date Logged
                              </TableCell>
                              <TableCell style={table_header}>
                                Financer
                              </TableCell>
                              <TableCell style={table_header}>
                                Loan Amount
                              </TableCell>
                              <TableCell style={table_header}>Status</TableCell>
                              <TableCell style={table_header}>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {/* applicationList */}
                            {disbursedAppList.map((item, i) => (
                              <TableRow hover className='datable' key={i + 1}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.reference_id}</TableCell>
                                <TableCell>{item.business_name}</TableCell>
                                <TableCell>{item.pan}</TableCell>
                                {item.request_type_id == 0 && (
                                  <TableCell>Loan Application</TableCell>
                                )}
                                {item.request_type_id == 1 && (
                                  <TableCell>Loan Application</TableCell>
                                )}
                                <TableCell>{item.loan_type}</TableCell>
                                <TableCell>
                                  {moment(item.created_on).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell>{item.financier_name}</TableCell>
                                <TableCell>{item.loan_amount}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>
                                  {
                                    <Tooltip title='Loan' placement='bottom'>
                                      <Button
                                        className='actionBtn'
                                        onClick={e => {
                                          goToLetsGetStarted(
                                            item.request_type_id
                                          )
                                        }}
                                      >
                                        <i
                                          class='fas fa-long-arrow-alt-right'
                                          style={{ fontSize: '15px' }}
                                        ></i>
                                      </Button>
                                    </Tooltip>
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value='3' className='pl0 pr0'>
                      <TableContainer>
                        <Table
                          aria-label='customized table'
                          size='small'
                          style={{ marginTop: '1%', marginBottom: '1% 0% 3%' }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell width='5%' style={table_slNo}>
                                #
                              </TableCell>
                              <TableCell style={table_header}>Title</TableCell>
                              <TableCell style={table_header}>
                                Start Date
                              </TableCell>
                              <TableCell style={table_header}>Status</TableCell>
                              <TableCell style={table_header}>
                                Address
                              </TableCell>
                              <TableCell style={table_header}>
                                Phone Number
                              </TableCell>
                              <TableCell style={table_header}>
                                Assaigned To
                              </TableCell>
                              <TableCell style={table_header} width="150px">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allAppointment
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, i) => {
                                const isItemSelected = isSelected(item.id)
                                return (
                                  <TableRow
                                    hover
                                    onClick={event =>
                                      handleClickedApp(event, item.id)
                                    }
                                    role='checkbox'
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={item.id}
                                    appointMentSelected={isItemSelected}
                                    className='datable'
                                  >
                                    <TableCell padding='checkbox'>
                                      <Checkbox
                                        checked={isItemSelected}
                                        style={{ display: 'none' }}
                                      />
                                      {i + 1}
                                    </TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>
                                      {moment(item.start_date).format(
                                        'DD-MM-YYYY'
                                      )}
                                    </TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{item.address}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell
                                      style={{
                                        display: 'flex',
                                        height: '100%'
                                      }}
                                    >
                                      {
                                        <>
                                          <Tooltip
                                            title='Document Collector'
                                            placement='bottom'
                                          >
                                            <Button
                                              className='actionBtn'
                                              onClick={() =>
                                                setShowDocCollector(true)
                                              }
                                            >
                                              <i
                                                class='fas fa-file-upload'
                                                style={{ fontSize: '15px' }}
                                              ></i>
                                            </Button>
                                          </Tooltip>
                                        </>
                                      }
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 15,25,35]}
                        component='div'
                        count={allAppointment.length}
                        rowsPerPage={100}
                        page={page}
                      //  onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage1}
                      /> */}
                    </TabPanel>

                    <Snackbar
                      open={savenextmessage}
                      style={{ marginTop: '65px' }}
                      autoHideDuration={SnackPosition.duration}
                      onClose={() => {
                        setsavenextmessage(false)
                      }}
                      anchorOrigin={{
                        vertical: SnackPosition.vertical,
                        horizontal: SnackPosition.horizontal
                      }}
                    >
                      <Alert
                        onClose={() => {
                          setsavenextmessage(false)
                        }}
                        severity={colorMessage}
                      >
                        {showMessage}
                      </Alert>
                    </Snackbar>
                  </TabContext>
                </>
              </div>
            </>
          {/* )} */}
        </>
      )}

      <Modal
        show={showmodal}
        animation={false}
        centered
        className='modelPosition modelwdth'
        style={{ height: 'auto' }}
      >
        <Modal.Header
          className='modalHead'
          closeButton
          onClick={handleclosePdf}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>
            Assign to FPA
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            /* showCpaPage === true && */ <Formik
              initialValues={{
                cpa: ''
              }}
              onSubmit={assignToCPA}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,

                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Col md={{ span: 12 }}>
                      <Row>
                        <Col md={{ span: 2 }}></Col>
                        <Col md={{ span: 8 }} style={{ textAlign: 'center' }}>
                          <Typography styleType='B' />
                          <List component='div' disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder='Select FPA'
                                id='cpa'
                                name='cpa'
                                fluid
                                search
                                selection
                                onBlur={() => setFieldTouched('cpa', true)}
                                onChange={(e, { value }) =>
                                  setFieldValue('cpa', value)
                                }
                                value={values.cpa}
                                options={cpaList}
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 2 }}></Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}>
                              <Button
                                className='updatebtn'
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </form>
                )
              }}
            </Formik>
          }
        </Modal.Body>
      </Modal>

      <Modal
        show={showmodalAnalyst}
        animation={false}
        centered
        className='modelPosition modelwdth'
        style={{ height: 'auto' }}
      >
        <Modal.Header
          className='modalHead'
          closeButton
          onClick={handlecloseAnalyst}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>
            Assign to Analyst
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            /* showCpaPage === true && */ <Formik
              initialValues={{
                analyst: ''
              }}
              onSubmit={assignToAnalyst}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Col md={{ span: 12 }}>
                      <Row>
                        <Col md={{ span: 6 }} style={{ textAlign: 'center' }}>
                          <Typography styleType='B' />
                          <List component='div' disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder='Select Valuation Analyst'
                                id='valuation_analyst'
                                name='valuation_analyst'
                                fluid
                                search
                                selection
                                onBlur={() => setFieldTouched('valuation_analyst', true)}
                                onChange={(e, { value }) =>
                                  setFieldValue('valuation_analyst', value)
                                }
                                value={values.valuation_analyst}
                                options={valuationAnalyst}
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }} style={{ textAlign: 'center' }}>
                          <Typography styleType='B' />
                          <List component='div' disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder='Select Legal Analyst'
                                id='analyst'
                                name='analyst'
                                fluid
                                search
                                selection
                                onBlur={() => setFieldTouched('analyst', true)}
                                onChange={(e, { value }) =>
                                  setFieldValue('analyst', value)
                                }
                                value={values.analyst}
                                options={allAnalyst}
                              />
                            </ListItem>
                          </List>
                        </Col> 
                      </Row>
                      <Row>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col md={{ span: 10 }}></Col>
                            <Col md={{ span: 2 }}>
                              <Button
                                className='updatebtn'
                                disable={handleSubmit}
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                              >
                                {handleSubmit}
                                Submit
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </form>
                )
              }}
            </Formik>
          }
        </Modal.Body>
      </Modal>

      <Modal
        show={showDocCollector}
        animation={true}
        centered
        className='modelPosition modelwdth'
        style={{ height: 'auto' }}
      >
        <Modal.Header
          className='modalHead'
          closeButton
          onClick={() => {
            setShowDocCollector(false)
          }}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>
            Assign Document Collector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            /* showCpaPage === true && */ <Formik
              initialValues={{
                collector: ''
              }}
              onSubmit={assignToCollector}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{ span: 2 }}></Col>
                      <Col md={{ span: 8 }}>
                        <Typography styleType='B' />
                        <List component='div' disablePadding>
                          <ListItem>
                            <Dropdown
                              placeholder='Select Document Collector'
                              id='collector'
                              name='collector'
                              fluid
                              search
                              selection
                              onBlur={() => setFieldTouched('cpa', true)}
                              onChange={(e, { value }) =>
                                setFieldValue('collector', value)
                              }
                              value={values.collector}
                              options={allCollectors}
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          <Col md={{ span: 2 }}>
                            <Button
                              className='updatebtn'
                              variant='contained'
                              color='primary'
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )
              }}
            </Formik>
          }
        </Modal.Body>
      </Modal>

      <>
        {applicationList.length == 0 && (
          <Formik
            initialValues={{
              bankname: '',
              account: '',
              natureacc: '',
              submission: '',
              color: '',
              companyl: ''
            }}
            validationSchema={bankFormschema}
            onSubmit={values => {
              console.log('kkkkk', values)
              localStorage.setItem('banking', JSON.stringify(values))
              handleNextClick()
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
              /* and other goodies */
            }) => {
              return (
                <>
                  {applicationList.length != 0 && (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col
                          md={{ span: 8 }}
                          style={{
                            display: 'block',
                            margin: 'auto',
                            marginTop: '15%'
                          }}
                        >
                          <div className='border_set_pro'>
                            <div>
                              <h2>Welcome to Aagey.com !</h2>
                            </div>
                            <div style={{ marginTop: 20, marginBottom: '5%' }}>
                              <h3> There Is No Application </h3>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  )}
                </>
              )
            }}
          </Formik>
        )}
      </>
    </Container>
  )
}
