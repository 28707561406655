import React, { Component } from "react";
import "./App.css";
import Navigation, { Navigation1 } from "./Navigation";
import ProfileSteper from "./components/ProfileSteper";
import SignUp from "./components/Dashboard";
import MainDash from "./components/FinancialBackend";
import { Provider } from "react-redux";
import { createStore } from "redux";
import todoApp from "./reducers";
import { connect } from "react-redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Test from "./components/Test";



import AdminNavigation from "./AdminNavigation";
import AdminDashboard from "./AdminComponents/AdminDashboard";
// import ReportingDashboard from "./components/ReportingDashboard";
class App extends Component {
  render() {
    const store = createStore(todoApp);
    return (
      <MuiThemeProvider>
        <Provider store={store}>
          {/* <ReportingDashboard/>  */}
          {/* */}
          <Navigation /> 

      {  //  <AdminNavigation />
          //  <AdminDashboard />
      }
    
          {/* <AdminNavigation /> */}

        </Provider>
      </MuiThemeProvider>

    );
  }
}
export default App;
