import React from 'react'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Grid } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { Dropdown } from 'semantic-ui-react'
import { useStyle } from './styles'

import { Formik } from 'formik'
import { bankFormschema } from '../../shared/validations'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '../../base_components/Typography'

import { Alert, AlertTitle } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import { withRouter } from 'react-router-dom'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { apiCalling } from '../../shared/constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import AppBar from '@material-ui/core/AppBar'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Tabs from '@material-ui/core/Tabs'
//import Typography from '@material-ui/core/Typography';

import Switch from '@material-ui/core/Switch'
import { purple } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'

import { SnackPosition } from '../../shared/constants'
import BankerRoleBookAppointment from '../BankerRoleBookAppointment/index'
//import Dialog from '@mui/material/Dialog';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import ListItemText from '@material-ui/core/ListItemText'
import { styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

function DocumentsCollector (props, { history }) {
  //  //

  const classes = useStyle()

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const [open, setOpen] = React.useState(false)

  const [topform, settopform] = React.useState(true)
  const [tableopen, settableopen] = React.useState(false)
  const [table, settable] = React.useState(false)
  const [data, setdata] = React.useState([])

  const [openDialog, setOpenDialog] = React.useState(false)

  const [showmodal, setshowmodal] = React.useState(false)
  const handleclosePdf = () => {
    setshowmodal(false)
  }

  React.useEffect(() => {
    //
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    if (localStorage.getItem('businessflag')) {
      props.handleNext()
    }
    getUserId()
    // getCPAData();
    // getAllBusinessbyuser();
    // localStorage.removeItem("rquestIdDetails");
    // localStorage.removeItem("RequestType");
    // // localStorage.removeItem("handleFlow");
    // getLoanStatusPercentage();
    getRequestData()
    getAllApplication()
    getAllStatus()
  }, [])

  ///getBusiness and shareholder details based on user detail

  /// Business Information data
  // getting user Id from local storage
  const getUserId = () => {
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    console.log('main page user details', iduser)
  }

  const checkeligibiltyBusAndProm = () => {
    localStorage.setItem('check', JSON.stringify('Check Eligibility'))
    props.handleNext()
  }

  const applyforloanBusAndPromo = () => {
    localStorage.setItem('check', JSON.stringify('Loan Application'))
    props.handleNext()
  }

  const handleNextClick = () => {
    const localStorageData = localStorage.getItem('banking'),
      bankdata = JSON.parse(localStorageData)
    setdata(bankdata)
    console.log('shareholder', bankdata)
    settopform(false)
    // setshowform(false)
    settable(true)
    settableopen(true)
    console.log('clicked next')
  }

  // const handleChange = (event) => {
  //     setSelectedValue(event.target.value);
  // };

  // const handleSubmiting = () => {
  //     props.handleNext();
  // };
  // const handleSubmit = () => {
  //     setshow(false);
  //     setdigital(false);
  //     setdisp(true);
  // };
  // const handleSubmitdigital = () => {
  //     setdigital(true);
  //     setmodelbank(false);
  // };

  // const handleSubmited = () => {
  //     setshow(true);
  //     setmodelbank(false);
  // };

  // const handledata = () => {
  //     props.handleBack();
  // };

  // const toggleNavTab = () => {
  //     setnavTabState(!navTabState);
  // };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  // const SaveasDraft = () => {
  //     alert("Save As Draft");
  //     props.handleNext();
  // };

  // const hedaer1 = () => {
  //     return (
  //         <h1>hello</h1>
  //     )
  // }

  // const classes1 = useStyles();
  const [value, setValue] = React.useState('1')

  ///get business of the user based on his id
  // const [bsid, setBsId] = useState();
  const [businesslist, setbusinesslist] = React.useState([])

  const [bsid, setBsId] = React.useState()

  //get business by businessid

  const [initialValue, setEditInitialValue] = React.useState({
    company: bsid,
    businessName: '',
    date: '',
    gst: '',
    businesspan: ''
  })

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: '0%'
  }

  const getAllStatus = async () => {
    let data = {
      type: 'appointment_status'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(result => {
        let arr = []
        result.data.data.map(item => {
          arr.push({ name: item.name, value: item.name, text: item.name })
        })
        setbusinesslist(arr)
      })
      .catch()
  }

  //Page Data
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [applicationList, setApplicationList] = React.useState([])
  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
  const getRequestData = async arralen => {
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    //
    let data = {
      user_id: iduser.id
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getRequestdetailsByCPA`,
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setApplicationList(result.data.data)
        } else {
          if (arralen > 0) {
            setOpenDialog(true)
          }
          setApplicationList([])
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    //setPage(0);
  }

  const [cpaList, setCpaList] = React.useState([])

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()

  const [modalOpen, setModalOpen] = React.useState(false)
  const handleCloseBusiness = () => {
    setModalOpen(false)
  }

  const [hideTable, setHideTable] = React.useState(false)

  const [allAppDoc, setAllDocApp] = React.useState([])

  const getAllApplication = async arralen => {
    
    const checkuserloggeddata = localStorage.getItem('userdata')
    let iduser = JSON.parse(checkuserloggeddata)
    //
    let data = {
      assaigned_to: iduser.id //checkuserloggeddata.id
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getAppointmentsbyAssignedto`,
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setAllDocApp(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [showAppointment, setShowAppointment] = React.useState(false)
  const [showForm, setShowForm] = React.useState(true)
  const handleClose = () => setShowAppointment(false)
  const [labelData, setLabelDataList] = React.useState([])
  const [arrayList, setArrayList] = React.useState([])
  const [show1, setShow] = React.useState(false)

  const [request_id, setrRequest_id] = React.useState('')
  const [owner_id, setOwnerId] = React.useState('')
  const saveEvent = async values => {
    //
    const checkuserloggeddata = JSON.parse(localStorage.getItem('userdata'))

    console.log(values)
    const data = {
      id: values.id,
      title: values.title,
      start_date: values.date,
      request_id: request_id,
      refno: values.refno,
      applicant: values.applicant,
      created_by: owner_id,
      pincode: values.pincode,
      phone: values.phone,
      address: values.address,
      latitude: '', // location.lat,
      longitude: '', //location.lng,
      reference_document_checklist_id: arrayList,
      doc_collector: checkuserloggeddata.id
    }

    console.log('data to save.........', data)
    await axios
      .post(apiCalling.coderscorner + `server.php/api/bookAppointment`, data)
      .then(result => {
        console.log('saved Appointment', result.data)
        if (result.data.status === true) {
          if (values.id != undefined || values.id != null) {
            setShowMessage('updated successfully')
          } else {
            setShowMessage('created succesfully')
          }
          setsavenextmessage(true)
        }

        //    getAllEvents();
        //setShowTable(false);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const checkBoxValue = (id, collected) => {
    //

    let tempArr = [...documentUl]

    tempArr.map(item =>
      item.id == id ? (item.collected = !collected) : collected
    )
    console.log(tempArr)
    setDocumentUl(tempArr)

    // let tempArr = [...arrayList]
    // let isId = false
    // if (tempArr.length > 0) {
    //   for (let i = 0; i < tempArr.length; i++) {
    //     if (tempArr[i] == id) {
    //       /// tempArr.push(id)
    //       isId = true
    //       let remv = tempArr.indexOf(id);
    //       tempArr.splice(remv, 1)
    //       break
    //     }
    //   }
    //   if (isId == false) {
    //     tempArr.push(id);
    //   }
    // } else {
    //   tempArr.push(id)
    // }
    // setArrayList(tempArr)
  }

  const deleteAppointment = async values => {
    //
    const data = {
      start_date: values.date,
      created_by: values.created_by
    }
    console.log('delete data values', data)
    await axios
      .post(
        apiCalling.coderscorner +
          `server.php/api/deleteAppointmentByUserIDAndstartdate`,
        data
      )
      .then(result => {
        console.log(result.data.data)
        if (result.data.status) {
          setShow(false)
          setShowMessage('Deleted Successfully')
          setsavenextmessage(true)
          // getAllEvents();
        }
      })
  }

  const [formDisable, setFormDisable] = React.useState(false)

  const handleModal = item => {
    //
    setFormDisable(true)
    setShowAppointment(true)
    getAllData(item)
    // detailsDocument();
    // getByDateAndUserId();
  }
  const handleModalUpdate = async item => {
    //
    setFormDisable(false)
    setShowAppointment(true)
    getAllData(item)
    // detailsDocument();
    // getByDateAndUserId();
  }

  const [initialValueDoc, setInitialValueDoc] = React.useState({
    id: '',
    title: '',
    date: '',
    time: '',
    request_id: '',
    created_by: '',
    phone: '',
    address: '',
    pincode: '',
    refno: '',
    applicant: '',
    loantype: '',
    cpaname: '',
    cpaphone: '',
    // latitude: location.lat,
    // longitude: location.lng,
    reference_document_checklist_id: []
  })

  const [documentUl, setDocumentUl] = React.useState([])
  const getAllData = item => {
    //
    const data = {
      appointment_id: item.id
    }
    const docdata = {
      user_id: item.user_id,
      ///   parent_id: localData.business_id,
      parent_type_id: 1,
      form: '1.1.3',
      required_phase: '172'
    }

    let api1 =
      apiCalling.coderscorner + `server.php/api/getAppointmentsbyAppointmentId`
    let api2 =
      apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/getAppointmentsbyAppointmentId'

    axios
      .all([
        axios.post(api1, data),
        axios.post(
          apiCalling.coderscorner + `server.php/api/getDetailsDocumentV2`,
          docdata
        )
      ])
      .then(
        axios.spread((obj1, obj2) => {
          
          // checklist request
          console.log('Document data...!!!', obj2.data)
          let myArr = []
          console.log(obj2.data)
          obj2.data.data.map(item => {
            myArr.push({
              id: item.id,
              name: item.name,
              documentArray: item.documentArray
            })
          })
          setLabelDataList(myArr)

          /// second getById Request
          if (obj1.data.status) {
            let editdata = obj1.data.data[0]
            setDocumentUl(obj1.data.data[0].documents)
            
            //
            //  setOwnerId(editdata.owner_id);
            //     setLocation({ lat: parseFloat(editdata.latitude), lng: parseFloat(editdata.longitude) });
            setInitialValueDoc({
              ...initialValue,
              id: editdata.id,
              title: editdata.title,
              date: editdata.start_date,
              time: editdata.start_time,
              //  request_id: localData.id,
              created_by: editdata.created_by,
              address: editdata.address,
              phone: editdata.phone,
              pincode: editdata.pincode,
              refno: editdata.applicartion_ref,
              applicant: editdata.applicant_name,
              loantype: editdata.loan_type,
              cpaname: editdata.cpa,
              cpaphone: editdata.cpa_mobile,
              reference_document_checklist_id:
                obj1.data.data[0].reference_document_checklist_id
            })
            setArrayList(obj1.data.data[0].reference_document_checklist_id)
            //      detailsDocument();
          } else {
            setArrayList([])
            setInitialValueDoc({
              id: '',
              title: '',
              date: '',
              time: '',
              request_id: '',
              created_by: '',
              phone: '',
              address: '',
              pincode: '',
              refno: '',
              applicant: '',
              loantype: '',
              cpaname: '',
              cpaphone: '',
              // latitude: location.lat,
              // longitude: location.lng,
              reference_document_checklist_id: []
            })
          }

          console.log('respose 1 ', obj1)
          console.log('respose 2 ', obj2)
        })
      )
  }

  // const getByDateAndUserId = (date) => {
  //     //
  //     const data = {
  //         "appointment_id": "212"
  //     }

  //     axios.post(apiCalling.coderscorner+`server.php/api/getAppointmentsbyAppointmentId`, data).then((result) => {
  //         console.log(result.data.data);
  //         if (result.data.status) {
  //             let editdata = result.data.data[0];
  //             // let checklistdata = result.data.data.appointmentdetails[1];
  //             console.log('editdata', editdata);
  //             //
  //             //     setLocation({ lat: parseFloat(editdata.latitude), lng: parseFloat(editdata.longitude) });

  //             setInitialValueDoc({
  //                 ...initialValue,
  //                 id: editdata.id,
  //                 title: editdata.title,
  //                 date: editdata.start_date,
  //                 time: editdata.start_time,
  //                 //  request_id: localData.id,
  //                 created_by: editdata.created_by,
  //                 address: editdata.address,
  //                 phone: editdata.phone,
  //                 pincode: editdata.pincode,
  //                 refno: editdata.applicartion_ref,
  //                 applicant: editdata.applicant_name,
  //                 loantype: editdata.loan_type,
  //                 cpaname: editdata.cpa,
  //                 cpaphone: editdata.cpa_mobile,
  //                 reference_document_checklist_id: result.data.data.reference_document_checklist_id
  //             })
  //             setArrayList(result.data.data.reference_document_checklist_id);
  //             //      detailsDocument();
  //         } else {
  //             setArrayList([]);
  //             setInitialValueDoc
  //                 ({
  //                     id: "",
  //                     title: "",
  //                     date: "",
  //                     time: "",
  //                     request_id: "",
  //                     created_by: "",
  //                     phone: "",
  //                     address: "",
  //                     pincode: "",
  //                     refno: "",
  //                     applicant: "",
  //                     loantype: "",
  //                     cpaname: "",
  //                     cpaphone: "",
  //                     // latitude: location.lat,
  //                     // longitude: location.lng,
  //                     reference_document_checklist_id: []
  //                 })

  //         }
  //     })
  // }

  const [propData, setPropsData] = React.useState()

  const [view, setView] = React.useState(true)

  const [statusValue, setStatusValue] = React.useState()
  const [appointmentId, setAppointmentId] = React.useState()

  const saveStatus = async () => {
    //
    let data = {
      id: appointmentId,
      status: statusValue
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/updateStatusForAppointment',
        data
      )
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setShowMessage('Updated Successfully');
          setsavenextmessage(true);
          setModalOpen(false);
          getAllApplication();
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  function PaperComponent (props) {
    return (
      <Draggable
        handle='#draggable-dialog-title'
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    )
  }

  const [previewModel, setPreviewModel] = React.useState(false)

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper
  }))

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }))

  const checkuserloggeddata = JSON.parse(localStorage.getItem('userdata'))
  /// update the collected status
  const updateDoumentRecievedStatus = async () => {
    console.log(documentUl)

    let data = {
      arr: documentUl,
      collected_by: checkuserloggeddata.id
    }
    console.log(data)
    let apiPath =  apiCalling.coderscorner + 'server.php/api/updateDoumentRecievedStatus'

    await axios
      .post(apiPath, data)
      .then(result => {
        if (result.data.status) {
          setShowMessage('Updated Succesfully')
          setsavenextmessage(true)
          setShowAppointment(false)
        }
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Container maxWidth='lg'>
      <Snackbar
        open={savenextmessage}
        style={{ marginTop: '65px' }}
        autoHideDuration={SnackPosition.duration}
        onClose={() => {
          setsavenextmessage(false)
        }}
        anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal
        }}
      >
        <Alert
          onClose={() => {
            setsavenextmessage(false)
          }}
          severity={'success'}
        >
          {showMessage}
        </Alert>
      </Snackbar>

      {hideTable == false ? (
        <>
          <div
            className='mini-container mg_btm_set'
            style={{ marginTop: '5%' }}
          >
            {/* Process bar code */}
            <div>
              <Row>
                <Col md={{ span: 8 }}>
                  <Row>
                    <Col md={{ span: 12 }}>
                      <Typography text='APPOINTMENT LIST' styleType='C' />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {allAppDoc.length >= 0 && (
              <>
                <Row>
                  <TableContainer>
                    <Table
                      aria-label='customized table'
                      size='small'
                      style={{ marginTop: '1%', marginBottom: '1% 0% 3%' }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={table_header}>#</TableCell>
                          <TableCell style={table_header}>Tittle</TableCell>
                          <TableCell style={table_header}>Applicant</TableCell>
                          <TableCell style={table_header}>
                            Reference Number
                          </TableCell>
                          <TableCell style={table_header}>Loan Type</TableCell>
                          <TableCell style={table_header}>Address</TableCell>
                          <TableCell style={table_header}>
                            Appointment Scheduled Date{' '}
                          </TableCell>
                          <TableCell style={table_header}>
                            Appointment Created Date
                          </TableCell>
                          <TableCell style={table_header}>Status</TableCell>
                          <TableCell style={table_header}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allAppDoc
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, i) => {
                            return (
                              <TableRow hover className='datable' key={i + 1}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.applicant_name}</TableCell>
                                <TableCell>{item.applicartion_ref}</TableCell>
                                <TableCell>{item.loan_type}</TableCell>
                                <TableCell>{item.address}</TableCell>
                                <TableCell>{item.start_date}</TableCell>
                                <TableCell>
                                  {moment(item.created_on).format('YYYY-MM-DD')}
                                </TableCell>
                                <TableCell style={table_header}>
                                  {item.status}
                                </TableCell>
                                <TableCell style={{ height: '100%' }}>
                                  {
                                    <>
                                      {/* 
                                      
                                        <Tooltip title='view' placement='bottom'>
                                        <Button
                                          className='actionBtn'
                                          onClick={() => {
                                            handleModal(item)
                                            setPreviewModel(true)
                                          }}
                                        >
                                          <i
                                            class='fas fa-eye'
                                            style={{ fontSize: '15px' }}
                                          ></i>
                                        </Button>
                                      </Tooltip>F

                                      */}

                                      <Tooltip
                                        title='update'
                                        placement='bottom'
                                      >
                                        <Button
                                          className='actionBtn'
                                          onClick={() => {
                                            handleModalUpdate(item)
                                            setrRequest_id(item.request_id)
                                            setOwnerId(item.created_by)
                                            setView(false)
                                          }}
                                        >
                                          <i
                                            class='far fa-edit'
                                            style={{ fontSize: '15px' }}
                                          ></i>
                                        </Button>
                                      </Tooltip>

                                      {/* 
                                           <Tooltip
                                        title='Create'
                                        placement='bottom'
                                      >
                                        <Button
                                          className='actionBtn'
                                          onClick={e => {
                                            //   goToLetsGetStarted(item);
                                            setHideTable(true)
                                            setPropsData(item)
                                          }}
                                        >
                                          <i
                                            class='fas fa-plus-square'
                                            style={{ fontSize: '15px' }}
                                          ></i>
                                        </Button>
                                      </Tooltip>
                                        */}

                                      <Tooltip
                                        title='Status'
                                        placement='bottom'
                                      >
                                        <Button
                                          className='actionBtn'
                                          onClick={e => {
                                            //   goToLetsGetStarted(item);
                                            setModalOpen(true)
                                            setAppointmentId(item.id)
                                          }}
                                        >
                                          <i
                                            class='fal fa-calendar-check'
                                            style={{ fontSize: '15px' }}
                                          ></i>
                                        </Button>
                                      </Tooltip>
                                    </>
                                  }
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component='div'
                    count={allAppDoc.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Row>
                <Row>
                  {allAppDoc.length == 0 && (
                    <Alert
                      severity='info'
                      style={{ marginTop: '7px', width: '100%' }}
                    >
                      <AlertTitle>
                        <p style={{ fontSize: '16px', marginTop: '6px' }}>
                          You Do not Assigned to any application
                        </p>
                      </AlertTitle>
                    </Alert>
                  )}
                </Row>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col md={{ span: 10 }}></Col>
            <Col md={{ span: 2 }} style={{ padding: '50px 0px 0px 0px' }}>
              <Button
                className='updatebtn'
                variant='contained'
                color='primary'
                type='button'
                onClick={() => {
                  //
                  setHideTable(false)
                }}
                style={{ width: '50%' }}
              >
                Back
              </Button>
            </Col>
            <Col
              md={{ span: 12 }}
              style={{ padding: '0px 215px 0px 65px', marginTop: ' -40px' }}
            >
              <BankerRoleBookAppointment appData={propData} />
            </Col>
          </Row>
        </>
      )}

      <Modal
        show={showmodal}
        animation={false}
        centered
        style={{ height: '550px' }}
      >
        <Modal.Header
          closeButton
          onClick={handleclosePdf}
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {
            /* showCpaPage === true && */ <Formik
              initialValues={{
                cpa: ''
              }}
              onSubmit={values => {
                console.log('check cpa data table value', values)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={{ span: 2 }}></Col>
                      <Col md={{ span: 8 }}>
                        <Typography text='Appointment Status' styleType='B' />
                        <List component='div' disablePadding>
                          <ListItem>
                            <Dropdown
                              placeholder='Select CPA'
                              id='cpa'
                              name='cpa'
                              fluid
                              search
                              selection
                              onBlur={() => setFieldTouched('cpa', true)}
                              onChange={(e, { value }) =>
                                setFieldValue('cpa', value)
                              }
                              value={values.cpa}
                              options={cpaList}
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }}></Col>
                          <Col md={{ span: 2 }}>
                            <Button
                              className='updatebtn'
                              variant='contained'
                              color='primary'
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )
              }}
            </Formik>
          }
        </Modal.Body>
      </Modal>
      <>
        {false && (
          <Formik
            initialValues={{
              bankname: '',
              account: '',
              natureacc: '',
              submission: '',
              color: '',
              companyl: ''
            }}
            validationSchema={bankFormschema}
            onSubmit={values => {
              console.log('kkkkk', values)
              localStorage.setItem('banking', JSON.stringify(values))
              handleNextClick()
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col
                      md={{ span: 8 }}
                      style={{
                        display: 'block',
                        margin: 'auto',
                        marginTop: '15%'
                      }}
                    >
                      <div className='border_set_pro'>
                        <div>
                          <h2>Welcome to Aagey.com !</h2>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <h2>Now get loans</h2>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <h2>Let us get you started with your application</h2>
                        </div>
                        <div style={{ margin: 40 }}>
                          <Button
                            className='choicebtn'
                            onClick={checkeligibiltyBusAndProm}
                          >
                            Check Eligibility
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            className='choicebtn'
                            onClick={applyforloanBusAndPromo}
                          >
                            Loan Application
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              )
            }}
          </Formik>
        )}
      </>

      {/* <Dialog onClose={handleCloseBusiness} aria-labelledby="max-width-dialog-title" open={modalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseBusiness}>
                    Select Business
        </DialogTitle>
                <DialogContent className="cpa_dialog" >
                    <Dropdown
                        placeholder="Select Business Name"
                        id="company"
                        name="company"
                        fluid
                        search
                        selection
                        onChange={(e, { name, value }) => {
                            //
                            goToLetsGetStartedFromCpa(value);
                        }}
                        options={businesslist}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseBusiness} color="primary">
                        Save changes
          </Button>
                </DialogActions>
            </Dialog> */}

      <Modal
        show={showAppointment}
        animation={false}
        centered
        style={{ height: '100%', padding: '3%' }}
      >
        {1 == true ? (
          <>
            <Formik
              enableReinitialize
              initialValues={initialValueDoc}
              onSubmit={updateDoumentRecievedStatus}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div class='container-fluid' style={{ padding: '3%' }}>
                      {
                        <div className='content-header'>
                          <div class='row'>
                            <div class='mainHead'>
                              <Col md={{ span: 10 }} className='mainheader1'>
                                <h5 className='headTxt'>Appointment Details</h5>
                                <CloseIcon
                                  onClick={handleClose}
                                  className='modalHead'
                                  type='button'
                                  style={{
                                    marginLeft: '115%',
                                    fontSize: '30px',
                                    marginTop: '-16%'
                                  }}
                                />
                              </Col>
                            </div>
                            {/* <Col md={{span:2}}>
                                                <Button className="actionBtn"  >
                                                    <i class="fa fa-trash" aria-hidden="true" style={{ fontSize: "15px", zIndex : 9999}} onClick={deleteAppointment}></i>
                                                </Button>
                                                </Col> */}
                            <Col md={{ span: 2 }}>
                              {/* <i class="fa fa-trash" aria-hidden="true" style={{ fontSize: "15px", zIndex : 9999}} onClick={deleteAppointment}></i> */}
                              {
                                // <Button className="updatebtn" variant="contained" color="primary" type="submit"
                                // ></Button>
                              }
                              <CloseIcon
                                onClick={() => {
                                  handleClose()
                                  setArrayList([])
                                  setInitialValueDoc({
                                    id: '',
                                    title: '',
                                    date: '',
                                    time: '',
                                    request_id: '',
                                    created_by: '',
                                    phone: '',
                                    address: '',
                                    pincode: '',
                                    refno: '',
                                    applicant: '',
                                    loantype: '',
                                    cpaname: '',
                                    cpaphone: '',
                                    // latitude: location.lat,
                                    // longitude: location.lng,
                                    reference_document_checklist_id: []
                                  })
                                }}
                                type='button'
                                style={{ marginLeft: '80%', fontSize: '30px' }}
                              />
                            </Col>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <Typography
                                text='Application Ref No'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Book Appointment'
                                    name='refno'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.title
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.title &&
                                      touched.title &&
                                      errors.title
                                        ? true
                                        : false
                                    }
                                    value={values.refno || ''}
                                    disabled={formDisable}
                                    helperText={
                                      errors.title &&
                                      touched.title &&
                                      errors.title
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>
                            <div className='col-md-6'>
                              <Typography text='Applicant Name' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Book Appointment'
                                    name='applicant'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.title
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.title &&
                                      touched.title &&
                                      errors.title
                                        ? true
                                        : false
                                    }
                                    disabled={formDisable}
                                    value={values.applicant || ''}
                                    helperText={
                                      errors.title &&
                                      touched.title &&
                                      errors.title
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>

                            <div className='col-md-6'>
                              <Typography
                                text='Appointment Date'
                                styleType='B'
                              />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='date'
                                    type='date'
                                    className={classes.textField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={formDisable}
                                    style={{ marginTop: '9px' }}
                                    // InputProps={{
                                    // 	inputProps: { min: minimumdate, max: maximumdate },
                                    // 	className: values.date ? classes.manorma : null,
                                    // }}
                                    error={
                                      errors.date && touched.date && errors.date
                                        ? true
                                        : false
                                    }
                                    value={values.date}
                                    helperText={
                                      errors.date && touched.date && errors.date
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>

                            <div className='col-md-6'>
                              <Typography text='Loan Type' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='date'
                                    type='text'
                                    name='loantype'
                                    className={classes.textField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ marginTop: '9px' }}
                                    disabled={formDisable}
                                    // InputProps={{
                                    // 	inputProps: { min: minimumdate, max: maximumdate },
                                    // 	className: values.date ? classes.manorma : null,
                                    // }}
                                    error={
                                      errors.date && touched.date && errors.date
                                        ? true
                                        : false
                                    }
                                    value={values.loantype}
                                    helperText={
                                      errors.date && touched.date && errors.date
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>

                            <div className='col-md-6'>
                              <Typography text=' CPA ' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Cpa Name'
                                    name='cpaname'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.phone
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.phone &&
                                      touched.phone &&
                                      errors.phone
                                        ? true
                                        : false
                                    }
                                    disabled={formDisable}
                                    value={values.cpaname || ''}
                                    helperText={
                                      errors.phone &&
                                      touched.phone &&
                                      errors.phone
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>
                            <div className='col-md-6'>
                              <Typography text=' CPA Phone' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Phone'
                                    name='cpaphone'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.phone
                                        ? classes.manorma
                                        : null
                                    }}
                                    disabled={formDisable}
                                    error={
                                      errors.phone &&
                                      touched.phone &&
                                      errors.phone
                                        ? true
                                        : false
                                    }
                                    value={values.phone || ''}
                                    helperText={
                                      errors.phone &&
                                      touched.phone &&
                                      errors.phone
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>

                            <div className='col-md-6'>
                              <Typography text='Pin Code' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextField
                                    id='filled-error-helper-text standard-basic '
                                    label='Pin Code'
                                    name='pincode'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.pincode
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.pincode &&
                                      touched.pincode &&
                                      errors.pincode
                                        ? true
                                        : false
                                    }
                                    disabled={formDisable}
                                    value={values.pincode || ''}
                                    helperText={
                                      errors.pincode &&
                                      touched.pincode &&
                                      errors.pincode
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>
                            <div className='col-md-6'>
                              <Typography text='Address' styleType='B' />
                              <List component='div' disablePadding>
                                <ListItem>
                                  <TextareaAutosize
                                    id='filled-error-helper-text standard-basic '
                                    label='Address'
                                    name='address'
                                    rowsMin={2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      className: values.address
                                        ? classes.manorma
                                        : null
                                    }}
                                    error={
                                      errors.address &&
                                      touched.address &&
                                      errors.address
                                        ? true
                                        : false
                                    }
                                    disabled={formDisable}
                                    value={values.address || ''}
                                    helperText={
                                      errors.address &&
                                      touched.address &&
                                      errors.address
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>

                            {/*    map integration
                                   <div className='col-md-6'>
                              <MyLocationIcon
                                onClick={() => {
                                  setShowForm(false)
                                }}
                              />
                            </div>
                               */}

                            <div class='col-md-12'>
                              <Typography
                                text='Documents Collected'
                                styleType='A'
                              />{' '}
                            </div>

                            {view === true ? (
                              <div class='col-md-6'>
                                <ul>
                                  {documentUl.map(item => (
                                    <li style={{ fontSize: '18px' }}>{item}</li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <div class='col-md-6'>
                                {documentUl.map((item, i) => (
                                  <div className='label'>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name='checkedC'
                                          color='primary'
                                          onChange={() => {
                                            checkBoxValue(
                                              item.id,
                                              item.collected
                                            )
                                          }}
                                          checked={item.collected}
                                        />
                                      }
                                      label={item.name}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}

                            {formDisable == false ? (
                              <div class='col-md-12'>
                                <div class='row'>
                                  <div class='col-md-8'></div>

                                  {}
                                  <div
                                    class='col-md-2'
                                    style={{ marginTop: '8px' }}
                                  >
                                    <Button
                                      className={classes.button}
                                      variant='contained'
                                      color='secondary'
                                      onClick={() => {
                                        deleteAppointment(values)
                                      }}
                                      startIcon={<DeleteIcon />}
                                    >
                                      delete
                                    </Button>
                                  </div>
                                  <div class='col-md-2'>
                                    <Button
                                      className='updatebtn'
                                      variant='contained'
                                      color='primary'
                                      type='submit'
                                      onClick={handleSubmit}
                                    >
                                      Confirm
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      }
                    </div>
                    <Row>
                      {/* <Col>
                                     <Modal.Header closeButton>
                                        <Modal.Title>NEW REMINDER</Modal.Title>
                                    </Modal.Header>
                                     </Col>   
                                    
                                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                                        <Button variant="primary" onClick={handleClose}>Save Changes</Button>
                                    </Modal.Footer> */}
                    </Row>
                  </form>
                )
              }}
            </Formik>
          </>
        ) : null}
      </Modal>

      {/* <Modal show={modalOpen} animation={false} centered style={{ height: "auto" }}>
                <Modal.Header closeButton onClick={handleCloseBusiness} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                </Modal.Header>
                <Modal.Body> */}
      <Modal
        show={modalOpen}
        animation={false}
        centered
        className='modelPosition modelwdth'
        style={{ height: 'auto' }}
      >
        <Modal.Header
          closeButton
          onClick={handleCloseBusiness}
          className='modalHead'
          style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
        >
          <Modal.Title className='modalHeader cpModal'>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={{ span: 2 }}></Col>
            <Col md={{ span: 8 }}>
              <List component='div' disablePadding>
                <ListItem>
                  <Dropdown
                    placeholder='Select Status'
                    id='company'
                    name='company'
                    fluid
                    search
                    selection
                    // onBlur={() => setFieldTouched("cpa", true)}
                    //  onChange={(e, { name, value }) => { goToLetsGetStartedFromCpa(value); }}
                    onChange={(value, item) => {
                      //
                      setStatusValue(item.value)
                    }}
                    value={statusValue}
                    options={businesslist}
                  />
                </ListItem>
              </List>
            </Col>
            <Col md={{ span: 2 }}></Col>
            <Col md={{ span: 10 }}></Col>
            <Col md={{ span: 2 }}>
              <Button autoFocus onClick={saveStatus} color='primary'>
                Submit
              </Button>
            </Col>
            <Col md={{ span: 5 }}></Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Dialog
        open={previewModel}
        onClose={() => setPreviewModel(false)}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Appointment Details
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Item>{initialValueDoc.title}</Item>
              </Grid>
              <Grid item xs={6} md={4}>
                <Item>{initialValueDoc.time}</Item>
              </Grid>
              <Grid item xs={6} md={4}>
                <Item>{initialValueDoc.date}</Item>
              </Grid>
              <Grid item xs={6} md={8}>
                <Item>{initialValueDoc.phone}</Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <Item>{initialValueDoc.address}</Item>
              </Grid>
              <Grid item xs={6} md={6}>
                <Item>{initialValueDoc.pincode}</Item>
              </Grid>
              <Grid item xs={6} md={6}>
                <Item>{initialValueDoc.loantype}</Item>
              </Grid>
            </Grid>
          </Box>
          <Demo>
            <List dense={true}>
              {documentUl.map(item => (
                <ListItem>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </Demo>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setPreviewModel(false)
            }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default withRouter(DocumentsCollector)
