import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SaveIcon from "@material-ui/icons/Save";
import {
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Dropdown } from "semantic-ui-react";
import Typography from "../../base_components/Typography";
import { apiCalling } from "../adminConstants";
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { entityTypeValidationSchema } from "../../shared/validations";
import axios from "axios";
import { useStyles } from "../admin";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from "mdbreact";
import Paper from "@material-ui/core/Paper";
import { mdiPlaylistPlus, mdiRefresh } from "@mdi/js";
import DraftNext from "../../image/buttons/draftnext.png";
import Tooltip from "@material-ui/core/Tooltip";
import Back from "../../image/buttons/back.png";
import { LoaderColor } from "../../shared/constants";
import Loader from "react-loader-spinner";
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import grayback from "../../image/buttons/grayback.png";
import manualentry from "../../image/pngicons/manualaddition.png";
import deleterec from "../../image/pngicons/delete.png";
import { TrendingUpRounded } from "@material-ui/icons";

export default function FinanceMasterPage(props) {
  const classes = useStyles();

  const [financialList, setFinancialList] = React.useState([]);
  const [showWCAddForm, setShowWCAddForm] = React.useState(false);
  const [showList, setShowList] = React.useState(true);
  const [showHeader, setShowHeader] = React.useState(true);
  const [showHead, setShowHead] = React.useState(true);
  const [showLevel1, setShowLevel1] = React.useState(false);
  const [showLevel2, setShowLevel2] = React.useState(false);
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [checked, setChecked] = React.useState([]);
  const [financialType, setFinancialType] = React.useState("");
  const [parameterHeads, setParameterHeads] = React.useState([]);
  const [parameterLevel1, setParameterLevel1] = React.useState([]);
  const [constantValue, setConstantValue] = React.useState(0);

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    //handleStateList();
  }, []);

  React.useEffect(() => {
    handleFinancialList();
  }, [financialType]);
  React.useEffect(() => {
    getConstantValue();
  }, []);

  const getConstantValue = async () => {
    const url = `incrementParameterHead`;

    await axios
      .get(apiCalling.coderscorner + `server.php/api/${url}`)
      .then((result) => {
        setConstantValue(result.data.data);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let data = {}
  if (financialType === "H1") {
   data = {
    columns: [
      {
        label: "#",
        field: "slno",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Name",
        field: "name",
        width: 270,
      },
    ],

    rows: financialList.map((values, i) => {
      return {
        slno: i + 1,
        name: values.name,
      };
    }),
  };
} else if (financialType === "L1" || financialType === "L2") {
  data = {
    columns: [
      {
        label: "#",
        field: "slno",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "parameter_level_1",
        field: "parameter_level_1",
        width: 270,
      },

      {
        label: "parameter_head",
        field: "parameter_head",
        width: 270,
      },
    ],

    rows: financialList.map((values, i) => {
      return {
        slno: i + 1,
        parameter_head: values.parameter_head,
        parameter_level_1: values.parameter_level_1
      };
    }),
  };

}

  let [initialValue, setInitialValue] = React.useState({
    name: "",

    is_active: "",
    id: "",
  });

  let [initialCheckboxValue, setInitialCheckboxValue] = React.useState({
    head: false,

    L1: false,
    L2: false,
  });

  const handleSelect = (e, value) => {
    console.log("ckecking ", value);
    //if (showWCAddForm !== true) {
    setFinancialType(value);
    //}
  };

  const handleCheckClick = (e, value) => {
    console.log("HI - ", value);
    console.log(checked);
    setChecked(
      checked.includes(value)
        ? checked.filter((c) => c !== value)
        : [...checked, value]
    );
  };

  const handlesubmitform = () => {}

  const handleLevel2Submitform = (values, { resetForm }) => {
    handleClick(values);
    resetForm({
      parameter_head_id: "",
      parameter_level1_id: "",
      name: "",
      display_name: "",
    });
  }

  const handleLevel1Submitform = (values, { resetForm }) => {
    handleClick(values);
    resetForm({
      parameter_head_id: "",
      name: "",
      display_name: "",
    });
  }

  //const handleLevel1Click = async (data) => {}

  const handleHeaderSubmitform = (values, { resetForm }) => {
    handleClick(values);
    resetForm({
      name: "",
      display_name: "",
    });
  };

  const handleClick = async (data) => {
    //setShowWCAddForm(false);
    console.log(data);
    setLoaderFlag(true);
    let url = '';
    let wcData = {};
    if (financialType === "H1") {
      url = "saveParameterHead";
      wcData = {
        incrValue: constantValue,
        parameter_head: data.name,
        display_parameter_head: data.display_name,
      };
    } else if (financialType === "L1") {
      //let selectedParameterHeadObj = parameterHeads.find(item => item.value === data.parameter_head_id)
      url = "saveParameterLevel1";
      wcData = {
        level: 'level1',
        parameter_level_1: data.name,
        display_parameter_level_1: data.display_name,
      };
    } else {
      let selectedParameterHeadObj = parameterHeads.find(item => item.value === data.parameter_head_id);
      let selectedParameterLevel1Obj = parameterLevel1.find(item => item.value === data.parameter_level1_id)
      url = "saveMasterParameterFinance";
      wcData = {
        parameter_head: selectedParameterHeadObj.parameter_head,
        display_parameter_head: selectedParameterHeadObj.text,
        parameter_level_1: selectedParameterLevel1Obj.parameter_level_1,
        display_parameter_level_1: selectedParameterLevel1Obj.text,
        parameter_level_2: data.name,
        display_parameter_level_2: data.display_name,
        level: 'level2'
      };
    }
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/${url}`,
        wcData
      )
      .then((res) => {
        console.log(res);
        console.log("saved successfully...!!!");
        //setShowWCAddForm(false);
        //setShowList(true);
        setShowHeader(true);
        getFinancialList();
      })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  };

  const getAllDisplayHeaderFinance = async () => {
    const data = {
      is_active: 1,
    };
    let url = "getAllDisplayHeaderFinance";
    console.log(apiCalling.coderscorner + `server.php/api/${url}`);
    await axios
      .post(apiCalling.coderscorner + `server.php/api/${url}`, data)
      .then((result) => {
        console.log(result);
        let empArray = [];
        result.data.data.map((item) => {
          empArray.push({
            text: item.display_parameter_head,
            value: item.id,
            key: item.id,
            parameter_head: item.parameter_head
          });
        });
        //setShowWCAddForm(false);
        //setShowList(true);
        setLoaderFlag(false);
        console.log("EmpArray ParameterHead", empArray);
        setParameterHeads(empArray);
      })
      .catch((error) => {
        console.log(error);
        // setShowWCAddForm(false);
        //setShowList(true);
        setLoaderFlag(false);
      });
  };

  const getAllDisplayLevel1 = async () => {
    const data = {
      is_active: 1,
    };
    let url = "getAllDisplayLevel1  ";
    await axios
      .post(apiCalling.coderscorner + `server.php/api/${url}`, data)
      .then((result) => {
        let empArray = [];
        result.data.data.map((item) => {
          empArray.push({
            text: item.display_parameter_level_1,
            value: item.id,
            key: item.id,
            parameter_level_1: item.parameter_level_1
          });
        });
        //setShowWCAddForm(false);
        //setShowList(true);
        setLoaderFlag(false);
        console.log("EmpArray", empArray);
        setParameterLevel1(empArray);
      })
      .catch((error) => {
        console.log(error);
        setShowWCAddForm(false);
        setShowList(true);
        setLoaderFlag(false);
      });
  };

  const getFinancialList = async () => {
    let url = "";
    if (financialType === "H1") {
      url = "getAllDisplayHeaderFinance";
    } else if (financialType === "L1") {
      url = "getAllDisplayLevel1 ";
    } else {
      url = "getAllDisplayLevel2";
    }
    setShowList(false);
    //setLoaderFlag(true);
    const data = {
      is_active: 1,
    };

    console.log(apiCalling.coderscorner + `server.php/api/${url}`);
    await axios
      .post(apiCalling.coderscorner + `server.php/api/${url}`, data)
      .then((result) => {
        console.log(result);
        let empArray = [];
        result.data.data.map((item) => {
          let item_name = item.display_parameter_head ? item.display_parameter_head : item.display_parameter_level_1? item.display_parameter_level_1 : item.display_parameter_level_2;
          if (financialType === "H1") {
            empArray.push({
              key: item.id,
              name: item_name,
              id: item.id,
            });
          } else {
            empArray.push({
              key: item.id,
              parameter_head: item.display_parameter_head,
              parameter_level_1: item.display_parameter_level_1,              
              id: item.id,
            });
          }
         
        });
        setShowWCAddForm(false);
        setShowList(true);
        setLoaderFlag(false);
        console.log("EmpArray", empArray);
        setFinancialList(empArray);
      })
      .catch((error) => {
        console.log(error);
        setShowWCAddForm(false);
        setShowList(true);
        setLoaderFlag(false);
      });
  };

  const handleFinancialList = async () => {
    if (financialType === "") return false;

    if (showWCAddForm !== true) {
      setLoaderFlag(true);
      getFinancialList();
    } else {
      console.log("i am here");
      if (financialType === "L1") {
        setLoaderFlag(true);
        getAllDisplayHeaderFinance();
      } else if (financialType === "L2") {
        setLoaderFlag(true);
        if (parameterHeads.length === 0) {
          getAllDisplayHeaderFinance();
        }
        getAllDisplayLevel1();
      }
    }
  };

  const handleOpenWCAddForm = () => {
    setAddFormButtonValue("Submit");
    setShowWCAddForm(true);
    setShowList(false);
    setShowHeader(false);
  };

  const handledata = () => {
    props.handleBack();
  };

  const SaveasDraft = () => {
    alert("Save As Draft");
    props.handlecheck();
  };

  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: 0, padding: "15px" }}>
        <div>
          <div className="content-header">
            <Row>
              <Col md={{ span: 10 }}>
                <Typography text="Finance Master Details" styleType="C" />
              </Col>
              {showHeader === true && (
                <Col md={{ span: 2 }}>
                  <span className="image">
                    <img
                      className="navbutton backbutton"
                      src={blueadd}
                      onClick={() => handleOpenWCAddForm()}
                    />
                    <Tooltip title="Add" arrow>
                      <img
                        className="navgraybutton backbutton"
                        src={grayadd}
                        onClick={() => handleOpenWCAddForm()}
                      />
                    </Tooltip>
                  </span>
                </Col>
              )}
              {showWCAddForm === true && (
                <Col md={{ span: 2 }} className="mainHeaderBack">
                  <span className="image">
                    <img
                      className="backbutton navbutton"
                      src={Back}
                      onClick={() => {
                        // resetForm();
                        setShowWCAddForm(false);
                        setShowList(true);
                        setShowHeader(true);
                      }}
                    />
                    <Tooltip title="Back" arrow>
                      <img
                        className="backbutton navgraybutton"
                        src={grayback}
                        onClick={() => {
                          // resetForm();
                          setShowWCAddForm(false);
                          setShowList(true);
                          setShowHeader(true);
                        }}
                      />
                    </Tooltip>
                  </span>
                </Col>
              )}
            </Row>
          </div>
          {(showHeader === true || showWCAddForm === true) && (
            <Row className="datable" style={{ marginTop: "30px" }}>
              <Col md={{ span: 12 }}>
                <Formik
                  enableReinitialize
                  initialValues={initialCheckboxValue}
                  //validationSchema={entityTypeValidationSchema}
                  // onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    /* and other goodies */
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <List component="div" disablePadding>
                          <ListItem>
                            <label>
                              Header
                              <input
                                name="financial_type"
                                type="radio"
                                value="H1"
                                checked={values.financial_type === "H1"}
                                onChange={(e) => {
                                  //console.log(e)
                                  setFieldValue("financial_type", "H1");
                                  handleSelect(e, "H1");
                                  setShowHead(true);
                                  setShowLevel1(false);
                                  setShowLevel2(false);
                                }}
                                onBlur={handleBlur}
                              />
                            </label>
                            <div style={{ width: "18px" }}></div>
                            <label>
                              Level 1
                              <input
                                name="financial_type"
                                type="radio"
                                value="L1"
                                checked={values.financial_type === "L1"}
                                onChange={(e) => {
                                  setFieldValue("financial_type", "L1");
                                  handleSelect(e, "L1");
                                  setShowHead(false);
                                  setShowLevel1(true);
                                  setShowLevel2(false);
                                }}
                                onBlur={handleBlur}
                              />
                            </label>
                            <div style={{ width: "18px" }}></div>
                            <label>
                              Level 2
                              <input
                                name="financial_type"
                                type="radio"
                                value="L2"
                                checked={values.financial_type === "L2"}
                                onChange={(e) => {
                                  setFieldValue("financial_type", "L2");
                                  handleSelect(e, "L2");
                                  setShowHead(false);
                                  setShowLevel1(false);
                                  setShowLevel2(true);
                                }}
                                onBlur={handleBlur}
                              />
                            </label>
                          </ListItem>
                          {errors.financial_type && touched.financial_type && (
                            <div
                              style={{
                                color: "red",
                                marginLeft: 14,
                                fontSize: 12,
                                fontWeight: "600",
                              }}
                            >
                              {errors.financial_type}
                            </div>
                          )}
                        </List>
                      </form>
                    );
                  }}
                </Formik>
              </Col>
              <Col md={{ span: 12 }}>
                {loaderFlag == true && (
                  <div style={{ textAlign: "center", marginTop: "15%" }}>
                    <Loader type={LoaderColor.type} color={LoaderColor.color} />
                  </div>
                )}
                {showList === true && loaderFlag === false && (
                  <div component={Paper}>
                    <MDBDataTableV5
                      hover
                      entriesOptions={[5, 10, 20, 25]}
                      entries={5}
                      pagesAmount={4}
                      data={data}
                      // exportToCSV
                      // searchTop
                      // searchBottom={false}
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}
          {showHeader === true && (
            <div className="btn_row_fixd">
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}>
                  <Tooltip
                    title="Back"
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    arrow
                  >
                    <img
                      className="navbutton"
                      src={Back}
                      onClick={handledata}
                    />
                  </Tooltip>
                </Col>

                <Col style={{ padding: "0%" }}>
                  <Tooltip title="Save as Draft & Next" arrow>
                    <img
                      className="navbutton"
                      src={DraftNext}
                      onClick={SaveasDraft}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </div>
          )}
        </div>

        <Row>
          <Col md={{ span: 12 }}>
            {showWCAddForm === true && showHead === true && (
              <Formik
                enableReinitialize
                initialValues={initialValue}
                //validationSchema={entityTypeValidationSchema}
                onSubmit={handleHeaderSubmitform}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="content-header"
                        style={{ position: "unset" }}
                      >
                        <Row style={{ marginTop: "30px" }}>
                          <Col md={{ span: 6 }}>
                            <Typography text="Parameter Head" styleType="B" />
                            <Row>
                              <Col
                                md={{ span: 1 }}
                                style={{ paddingTop: "10px" }}
                              >
                                <List component="div" disablePadding>
                                  <ListItem className="headerField">
                                    <TextField
                                      value={constantValue}
                                      className={classes.textField}
                                      inputProps={{
                                        //style: { paddingTop: "10px" },
                                        readOnly: true,
                                        //disableUnderline: true,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                              </Col>
                              <Col md={{ span: 11 }}>
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <TextField
                                      id="filled-error-helper-text standard-basic"
                                      name="name"
                                      label="Enter Parameter Head"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        className: values.name
                                          ? classes.manorma
                                          : null,
                                      }}
                                      value={values.name}
                                      helperText={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                      }
                                    />
                                  </ListItem>
                                </List>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <div>
                              <Typography
                                text="Parameter Display Head"
                                styleType="B"
                              />
                            </div>
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="display_name"
                                  label="Enter Display Head"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.display_name
                                      ? classes.manorma
                                      : null,
                                  }}
                                  value={values.display_name}
                                  helperText={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                        </Row>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col
                              md={{ span: 2 }}
                              style={{ paddingTop: "10.6vh" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "100%" }}
                                type="submit"
                                onClick={handleSubmit}
                              >
                                {addFormButtonValue}
                              </Button>
                            </Col>
                            <Col
                              md={{ span: 10 }}
                              style={{ textAlign: "left" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "20%" }}
                                type="button"
                                // onClick={handleSubmit}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
            {showWCAddForm === true && showLevel1 === true && (
              <Formik
                enableReinitialize
                initialValues={initialValue}
                //validationSchema={entityTypeValidationSchema}
                onSubmit={handleLevel1Submitform}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="content-header"
                        style={{ position: "unset" }}
                      >
                        <Row>
                          <Col md={{ span: 6 }}>
                            <Typography text="Parameter Head" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Parameter Head"
                                  id="parameter_head_id"
                                  name="parameter_head_id"
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched("parameter_head_id", true)
                                  }
                                  onChange={(e, { name, value }) => {
                                    setFieldValue("parameter_head_id", value);
                                    // industrynamecheck(value);
                                  }}
                                  value={values.parameter_head_id}
                                  options={parameterHeads}
                                  className={
                                    errors.parameter_head_id &&
                                    touched.parameter_head_id
                                      ? classes.required
                                      : values.parameter_head_id == ""
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}></Col>
                        </Row>
                        <Row style={{ marginTop: "30px" }}>
                          <Col md={{ span: 6 }}>
                            <Typography
                              text="Parameter Level 1"
                              styleType="B"
                            />
                            <Row>
                              
                              <Col md={{ span: 12 }}>
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <TextField
                                      id="filled-error-helper-text standard-basic"
                                      name="name"
                                      label="Enter Parameter Level 1"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        className: values.name
                                          ? classes.manorma
                                          : null,
                                      }}
                                      value={values.name}
                                      helperText={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                      }
                                    />
                                  </ListItem>
                                </List>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <div>
                              <Typography
                                text="Parameter Display Level 1"
                                styleType="B"
                              />
                            </div>
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="display_name"
                                  label="Enter Display Level 1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.display_name
                                      ? classes.manorma
                                      : null,
                                  }}
                                  value={values.display_name}
                                  helperText={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                        </Row>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col
                              md={{ span: 2 }}
                              style={{ paddingTop: "10.6vh" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "100%" }}
                                type="submit"
                                onClick={handleSubmit}
                              >
                                {addFormButtonValue}
                              </Button>
                            </Col>
                            <Col
                              md={{ span: 10 }}
                              style={{ textAlign: "left" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "20%" }}
                                type="button"
                                onClick={handleSubmit}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}

            {showWCAddForm === true && showLevel2 === true && (
              <Formik
                enableReinitialize
                initialValues={initialValue}
                //validationSchema={entityTypeValidationSchema}
                onSubmit={handleLevel2Submitform}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="content-header"
                        style={{ position: "unset" }}
                      >
                        <Row>
                          <Col md={{ span: 6 }}>
                            <Typography text="Parameter Head" styleType="B" />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Parameter Head"
                                  id="parameter_head_id"
                                  name="parameter_head_id"
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched("parameter_head_id", true)
                                  }
                                  onChange={(e, { name, value }) => {
                                    setFieldValue("parameter_head_id", value);
                                    // industrynamecheck(value);
                                  }}
                                  value={values.parameter_head_id}
                                  options={parameterHeads}
                                  className={
                                    errors.parameter_head_id &&
                                    touched.parameter_head_id
                                      ? classes.required
                                      : values.parameter_head_id == ""
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                          <Col md={{ span: 6 }}>
                            <Typography
                              text="Parameter Level 1"
                              styleType="B"
                            />
                            <List component="div" disablePadding>
                              <ListItem>
                                <Dropdown
                                  placeholder="Select Parameter Level 1"
                                  id="parameter_level1_id"
                                  name="parameter_level1_id"
                                  fluid
                                  search
                                  selection
                                  onBlur={() =>
                                    setFieldTouched("parameter_level1_id", true)
                                  }
                                  onChange={(e, { name, value }) => {
                                    setFieldValue("parameter_level1_id", value);
                                    // industrynamecheck(value);
                                  }}
                                  value={values.parameter_level1_id}
                                  options={parameterLevel1}
                                  className={
                                    errors.parameter_level1_id &&
                                    touched.parameter_level1_id
                                      ? classes.required
                                      : values.parameter_level1_id == ""
                                      ? classes.reqired
                                      : classes.man
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "30px" }}>
                          <Col md={{ span: 6 }}>
                            <Typography
                              text="Parameter Level 2"
                              styleType="B"
                            />
                            <Row>
                              
                              <Col md={{ span: 12 }}>
                                <List component="div" disablePadding>
                                  <ListItem>
                                    <TextField
                                      id="filled-error-helper-text standard-basic"
                                      name="name"
                                      label="Enter Parameter Level 2"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                          ? true
                                          : false
                                      }
                                      InputProps={{
                                        className: values.name
                                          ? classes.manorma
                                          : null,
                                      }}
                                      value={values.name}
                                      helperText={
                                        errors.name &&
                                        touched.name &&
                                        errors.name
                                      }
                                    />
                                  </ListItem>
                                </List>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={{ span: 6 }}>
                            <div>
                              <Typography
                                text="Parameter Display Level 2"
                                styleType="B"
                              />
                            </div>
                            <List component="div" disablePadding>
                              <ListItem>
                                <TextField
                                  id="filled-error-helper-text standard-basic"
                                  name="display_name"
                                  label="Enter Display Level 2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    className: values.display_name
                                      ? classes.manorma
                                      : null,
                                  }}
                                  value={values.display_name}
                                  helperText={
                                    errors.display_name &&
                                    touched.display_name &&
                                    errors.display_name
                                  }
                                />
                              </ListItem>
                            </List>
                          </Col>
                        </Row>
                        <Col md={{ span: 12 }}>
                          <Row>
                            <Col
                              md={{ span: 2 }}
                              style={{ paddingTop: "10.6vh" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "100%" }}
                                type="submit"
                                onClick={handleSubmit}
                              >
                                {addFormButtonValue}
                              </Button>
                            </Col>
                            <Col
                              md={{ span: 10 }}
                              style={{ textAlign: "left" }}
                            >
                              <Button
                                className="updatebtn"
                                variant="contained"
                                color="primary"
                                style={{ width: "20%" }}
                                type="button"
                                // onClick={handleSubmit}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col md={{ span: 5 }}></Col>
                          </Row>
                        </Col>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
