import React, { useState } from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextField, Button, Checkbox } from "@material-ui/core";
import { Formik } from "formik";
import { regiser } from "../../shared/validations";

import facebook from "../../image/buttons/facebook.png";
import google from "../../image/buttons/google.png";
import linkedin from "../../image/buttons/linkedIn.png";
import "./index.css";
import { useStyles } from "./styles";

import "./styles.js";
import OtpTimer from "otp-timer";
import axios from "axios";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ErrorCatch, apiCalling } from "../../shared/constants";

export default function SignUp(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [showEmailOtp, setShowEmailOtp] = useState(false);
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [otpMinutes, setOtpMinutes] = useState();
  const [otpSeconds, setOtpSeconds] = useState();
  const [showMobileTimer, setShowMobileTimer] = useState(true);
  const [enableSaveBtnMobile, setEnableSaveBtnMobile] = useState(false);
  const [enableSaveBtnEmail, setEnableSaveBtnEmail] = useState(false);

  React.useEffect(() => {
    getOtpTimer();
    //getAllCountryCode();
    getAllCountryCode();
  }, []);

  const termChecked = (e) => {
    setChecked(!checked);
  };



  //#region  Karza's pan Validation Api
  const [panError, setPanError] = useState("");
  const [panlabel, setPanLabel] = useState("AAAAA9999A")
  const getNameFromPan = async (e) => {
    let panLen = e.target.value;
    var regpan = /[A-Z]{3}[A-Z]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
    if (!regpan.test(panLen)) {
      setPanError("Please Enter valid Pan");
    } else {
      if(panLen.charAt(3) != 'P'){
        setPanError("Please Enter Personal Pan");
      }else{
      let data = {
        pan: e.target.value
      }
      let pan = e.target.value;
      await axios.post(apiCalling.identityManagement + `server.php/api/getUserdetailsbyPan`, data).then((result) => {
        console.log(result.data);
        if (result.data.status) {
          setPanError("Pan Already Exist");
        } else {
          setPanError("");
          gstandpancheck(pan);

        }
      }).catch((error) => {
        console.log(error)
      });
    }
    }
   
   // 
    

  }
  //#endregion


  //handling email onBlur
  const handleEmailBlur = (e) => {
    // console.log(e.target.value);
    let val1 = e.target.value;
    if (
      (val1.includes("@") && val1.includes(".") && val1.includes("com")) ||
      val1.includes("in") ||
      val1.includes("net") ||
      val1.includes("info") ||
      val1.includes("org") ||
      val1.includes("gov") ||
      val1.includes("edu")
    ) {
      setEmail(val1);

    }
  };

  // toggling btwn mobile field and otp


  // verifying mobile email and get otp
  const [showEmailExit, setShowEmailExit] = useState(false);
  const [emailExistMessage, setEmailExistMessage] = useState("");
  const [showMobileExit, setshowMobileExit] = useState(false);
  const [mobileExistMessage, setMobileExistMessage] = useState("");

  /// verifying email otp
  const [otpErrorMessage, setOtpErrorMessage] = useState(false);
  const [showEmailOtpMessage, setEmailOtpMessage] = useState(false);
  const [showEmailChecK, setShowEmailCheck] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [eOtp, setEOtp] = useState();

  const verifyEmialOtp = (e) => {
    let eotp = e.target.value;
    setEOtp(e.target.vlaue)
    let len = eotp.length;
    setEOtp(len);
    if (len == 6) {
      setEmailOtEnter("");
      let data = {
        value: emailData.otps.value,
        form_id: 1,
        otp: eotp,
      };
      axios
        .post(apiCalling.identityManagement +
          `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          if (result.data !== undefined || result.data !== null) {
            console.log(result.data);
            if (result.data.status === true) {
              setEnableSaveBtnEmail(true);
              setShowEmailCheck(true);
              setOtpErrorMessage(false);
              setShowEmailTimer(false);
              setEmailOtpMessage(false);
              //  enableSaveBtn();
            } else if (result.data.status === false) {
              setEmailOtpMessage(true);
              setEmailError(result.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //handling Mobile blur to verify mobile
  // toggling mobile field and otp field


  //verify mobile  number and get otp
  const [mobileAlredyExistMessage, setMobileAlreadyExistMessage] = useState("");
  const verifyMobile = (e) => {
    // axios
    //   .post(apiCalling.identityManagement + `server.php/api/otp`, data)
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.status === true) {
    //       setShowMobileOtp(true);
    //     } else if (res.data.status === false) {
    //       setOtpMobileErrorMessage(true);
    //       setMobileAlreadyExistMessage(res.data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  //// verifying mobile otp
  const [otpMobileErrorMessage, setOtpMobileErrorMessage] = useState(false);
  const [showCheckMobile, setShowCheckMobile] = useState(false);
  const [mobileError, setMobileError] = useState();
  const [mOtp, setMOtp] = useState();
  const verifyMobileOtp = (e) => {

    let motp = e.target.value;
    let len = motp.length;
    setMOtp(len);
    if (len == 6) {
      setMobileOtpEnter("");
      let data = {
        value: mobileData.otps.value,
        form_id: 1,
        otp: motp,
      };
      axios
        .post(apiCalling.identityManagement +
          `server.php/api/verifyOtp`,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.status === true) {
            //setOtpMobileErrorMessage(false);
            setShowMobileTimer(false);
            setOtpMobileErrorMessage(false);
            setShowCheckMobile(true);
            setEnableSaveBtnMobile(true);
            //  enableSaveBtn();
          } else {
            setOtpMobileErrorMessage(true);
            setMobileError(result.data.message)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  /// resend otp for  email
  const resendOtpEmail = () => {
    let data = {
      type: "email",
      value: email,
      form_id: 1
    };
    axios
      .post(apiCalling.identityManagement +
        `server.php/api/resendOtp`,
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //resend otp for mobile
  const redendOtpMobile = () => {
    let data = {
      type: "mobile",
      value: mobile,
      form_id: 1
    };
    axios
      .post(apiCalling.identityManagement +
        `server.php/api/resendOtp`,
        data
      )
      .then((result) => {
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //otp time as per general setting
  const getOtpTimer = () => {
    let data = {
      parameter_name: "OTP TIMER",
    };
    axios
      .post(apiCalling.identityManagement +
        `server.php/api/getGeneralSettingByParameter`,
        data
      )
      .then((result) => {
        console.log(result.data.data[0]);
        let data = result.data.data[0].parameter_value;
        console.log("timer", data);
        convertTimerToInt(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //conversion for Otp timer into
  const convertTimerToInt = (timer) => {
    let newTimer = parseInt(timer);
    var minutes = Math.floor(newTimer / 60);
    var seconds = newTimer - 60 * minutes;
    setOtpMinutes(minutes);
    setOtpSeconds(seconds);
  };

  // Get all nationality
  const [ctCode, setCtCode] = React.useState([]);

  const getAllCountryCode = () => {
    const countryCode = {
      Token: "qwerty1234",
    };
    let countryCodeArray1 = [];
    axios
      .post(apiCalling.identityManagement +
        `server.php/api/getAllnationality`,
        countryCode
      )
      .then((result) => {
        result.data.data.map((data) => {
          countryCodeArray1.push({
            key: data.alpha_2_code,
            value: data.country_code,
            flag: data.alpha_3_code,
            text: data.country,
          });
        });
        setCtCode(countryCodeArray1);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // React.useEffect( ()=>{setSelectedCountry(ctco)},[] );

  const [enableOtpPage, setEnableOtpPage] = useState(false);
  const enableSignUpPage = () => {

    setEnableOtpPage(false);
    setSignUpBtnEnable(true);
    setOtpMobileErrorMessage(false);
    setEmailOtpMessage(false);
  }

  const [emailStatus, setEmailStatus] = useState();
  const [mobileStatus, setMobileStatus] = useState();
  const [emailData, setEmailData] = React.useState([]);
  const [mobileData, setMobileData] = React.useState([]);
  const [cntcode, setCntcode] = React.useState();
  const [panno, setPanno] = React.useState();

  let mobileBool = false;
  let emailBool = false;
  const sendOtp = (result) => {
    emailtOtp(result);
    mobileOtp(result);
  }

  const emailtOtp = async (result) => {
    if (panError === "" && panError.length === 0) {
      setPanno(result.pan);
      setCntcode(result.cntrycode);
      let emaildata = {
        type: "email",
        value: result.email,
        form_id: 1,
      }
      
      await axios
        .post(apiCalling.identityManagement + `server.php/api/otp`, emaildata)
        .then((res) => {

          if (res.data.status === true) {
            console.log("email otp", res.data);
            setEmailStatus(true);
            //   mobileBool(res.data.status );
            emailBool = res.data.status;
            showOtpPage(emailBool, mobileBool);
            setEmailData(res.data.Data);
            setShowEmailOtp(!showEmailOtp);
            setShowEmailExit(false);
            setEmailExistMessage("");
          } else {
            setShowEmailExit(true);
            setEmailExistMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const mobileOtp = async (result) => {

    if (panError === "" && panError.length === 0) {

      let mobdata = {
        type: "mobile",
        value: result.number,
        form_id: 1,
      };
      
      await axios
        .post(apiCalling.identityManagement + `server.php/api/otp`, mobdata)
        .then((res) => {

          if (res.data.status === true) {
            setMobileStatus(true);
            console.log("mobile otp", res.data);
            setMobileData(res.data.Data);
            mobileBool = res.data.status;
            showOtpPage(emailBool, mobileBool);
            setOtpMobileErrorMessage(false);
            setMobileAlreadyExistMessage("");
          } else {
            setOtpMobileErrorMessage(true);
            setMobileAlreadyExistMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }

  const showOtpPage = (emailBool, mobileTrue) => {
    if (emailBool && mobileTrue) {
      setEnableOtpPage(true);
    }

  }

  // signing up the user
  const [mobileOtpEnter, setMobileOtpEnter] = useState("");
  const [emailOtpEnter, setEmailOtEnter] = useState("");
  const signUp = () => {
    //
    if (eOtp !== 6 && mOtp !== 6) {
      setEmailOtEnter("Please enter email otp");
      return;
    } else {
      setEmailOtEnter("")
    }
    if (mOtp !== 6) {
      setMobileOtpEnter("Please enter mobile otp");
      return;
    } else {
      setMobileOtpEnter("");
    }

    if (showEmailOtpMessage === false && otpMobileErrorMessage === false && eOtp === 6 && mOtp === 6) {
      console.log(emailData);
      console.log(mobileData);
      let data = {};
      if (cntcode === "") {
        data = {
          email: emailData.otps.value,
          mobile: mobileData.otps.value,
          pan: panno,
          country_code: 91,
        };
      } else {
        data = {
          email: emailData.otps.value,
          mobile: mobileData.otps.value,
          pan: panno,
          country_code: cntcode,
        };
      }
      localStorage.setItem("signupdata", JSON.stringify(data));
      axios
        .post(apiCalling.identityManagement +
          `server.php/api/saveUser`,
          data
        )
        .then((result) => {
          if (result.data.status === true) {
            console.log(result.data);
            localStorage.setItem("SignUpIdAndData", JSON.stringify(result.data.Data.user));
            props.history.push({ pathname: "/registration" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [karzabusinesname, setkarzabusinesname] = useState();
  //const [panError, setPanError] = useState("");
  //const [setErrorMessage] = ("");

  const gstandpancheckOld = async (pannm) => {
    
    let userid = 904;

    await axios.get(apiCalling.thirdParty+'public/apikaz?app_name=PAN&in_data=' + pannm + '&create=' + userid + '&modify=' + userid + ' ').then(res => {
      ////console.log('karza pan validation name', res.data.result.name);
      ////console.log('karza pan validation', res.data.result);
      if (res.data.status_code == "101") {
        //setkarzabusinesname(res.data.result.name)

        setkarzabusinesname(res.data.result.name);
        setPanError("");

      } else {
        //setErrorMessage("please enter valid PAN ");
        setPanError("Please Enter Valid Pan");

      }
    }).catch(error => {
      console.log(error);
    });
  }


  /// karza post 

  const gstandpancheck = async (pannm) => {
    
    let userid = 1;
    let data =
    {
      "app_name": "PAN",
      "in_data": pannm,
      "create": userid,
      "modify": userid
    }

    await axios.post(apiCalling.thirdParty + 'server.php/apikarz',data).then(res => {
      if (res.data.status_code == "101") {
        //setkarzabusinesname(res.data.result.name)
        setkarzabusinesname(res.data.result.name);
        setPanError("");
      } else {
        //setErrorMessage("please enter valid PAN ");
        setPanError("Please enter Valid ");

      }

    }).catch(error => {
      console.log(error);
    });
  }

  //email and mobile validation
  const handleSubmitemail = async (e) => {
    let email = e.target.value;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //

    if ( !emailRegex.test(email) ) {
      setEmailExistMessage("Please enter valid email id.");
      setShowEmailExit(true);
    } else {
      setEmailExistMessage('');
      setShowEmailExit(false);
      let data = {
        email: e.target.value
      }
      let api_path = apiCalling.identityManagement +'server.php/api/checkemail'
      await axios
        .post(apiCalling.identityManagement +'server.php/api/checkemail', data)
        .then((result) => {
          console.log(result.data);
          if (result.data.status === true) {
            setEmailExistMessage(result.data.message);
            setShowEmailExit(true);
          }
          else {
            setEmailExistMessage('');
            setShowEmailExit(false);
          }
        })
        .catch((error) => {
          ErrorCatch(error, api_path, 'post', data);
          console.log(error);
        });
    }
    

  }
  const handleSubmitmobile = async (e) => {
   // 
   const phoneRegex = /^\d{10}$/;
   let mobile = e.target.value;

   if (!phoneRegex.test(mobile)) {
    setMobileExistMessage("Please enter valid mobile number.");
    setOtpMobileErrorMessage(true);

    } else {
      setMobileExistMessage('');
      setOtpMobileErrorMessage(false);
  
      let data = {
          mobile: e.target.value
        }
        let api_path = apiCalling.identityManagement + 'server.php/api/checkmobile';
        await axios
          .post(apiCalling.identityManagement +'server.php/api/checkmobile', data)
          .then((result) => {
            console.log(result.data);
            if (result.data.status === true) {
              setMobileExistMessage(result.data.message);
              setShowEmailExit(true);
            }
            else {
              setMobileExistMessage('');
              setShowEmailExit(false);
            }
          })
          .catch((error) => {
            ErrorCatch(error, api_path, 'post', data);
            console.log(error);
          });
    }
    

  }


  //console.log(selectedCountry);
  const [showEmailTimer, setShowEmailTimer] = useState(true);
  const [signUpBtnEnable, setSignUpBtnEnable] = useState(true);

  return (
    <div className="login-form">
      <Card className="logincard">
        <CardContent>
          <Formik
            initialValues={{
              pan: "",
              email: "",
              number: "",
              cntrycode: "",
            }}
            onSubmit={sendOtp}
            validationSchema={regiser}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              //handleSubmitemail,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={{ span: 12 }}>
                      <p className="login_header">Client Signup</p>
                    </Col>
                    <Col md={{ span: 12 }}>
                      <p className="login_subheader">Manage your loan application and more!</p>
                    </Col>
                    {enableOtpPage == false && (
                      <>
                        {/* <Col md={{ span: 12 }}>
                          <label className="social_header">Sign in with</label>
                          <img alt ="imag1" src={facebook} className="social_img" />
                          <img alt ="imag1" src={google} className="social_img" />
                          <img alt ="imag1" src={linkedin} className="social_img" />
                        </Col> 
                        <Col md={{ span: 12 }} style={{ display: "flex", padding: "2% 3%" }}>
                          <label className="line"></label>
                          <small className="orr"><label className="orlabel">Or</label></small>
                          <label className="line"></label>
                        </Col> */}
                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <label className="login_txtlabel">PAN Number</label>
                          <p style={{ fontSize: "12px", color: "blue" }}>{karzabusinesname}</p>
                          <TextField
                            name="pan"
                            label={panlabel}
                            onChange={handleChange}
                            onBlur={getNameFromPan}
                            value={values.pan.toUpperCase()}
                            InputProps={{
                              className: values.pan ? classes.manorma : null,
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            error={
                              errors.pan && touched.pan && errors.pan
                                ? true
                                : false
                            }
                            helperText={errors.pan && touched.pan && errors.pan}
                          />
                          <span className="valMsg">{panError}</span>
                        </Col>
                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <label className="login_txtlabel">Email Id</label>
                          <TextField
                            name="email"
                            label="xyz@gmail.com"
                            onChange={handleChange}
                            onBlur={handleSubmitemail}
                            // onBlur={(e) => {
                            //   handleSubmitemail();
                            // }}
                            // onChange={(e) => setEmail(e.target.value)}
                            value={values.email}
                            InputProps={{
                              className: values.email ? classes.manorma : null,
                            }}
                            error={
                              errors.email && touched.email && errors.email
                                ? true
                                : false
                            }
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                          />
                          {showEmailExit && (
                            <span className="valMsg">
                              {emailExistMessage}
                            </span>
                          )}
                        </Col>
                        {/* {showEmailOtp == true && (
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 8 }} style={{ marginTop: "4%" }}>
                            <InputOtp onChange={verifyEmialOtp} />
                            {showEmailOtpMessage == true && (
                              <p className="valMsg">
                                {emailError}
                              </p>
                            )}
                          </Col>
                          <Col md={{ span: 4 }} style={{ marginTop: "7%" }}>
                            <Row>
                              {showEmailTimer === true && (
                                <Col md={{ span: 4 }}>
                                  <OtpTimer
                                    seconds={otpSeconds} //otpSeconds
                                    minutes={otpMinutes} //otpMinutes
                                    style={{
                                      color: "blue",
                                      display: "inline-block",
                                    }}
                                    resend={resendOtpEmail}
                                    text=" "
                                    buttonColor={"white"}
                                    background={"#007bff"}
                                    name="mobileotpbtn"
                                  />
                                </Col>
                              )}
                              <Col md={{ span: 4 }} style={{ marginTop: "-2%" }}>
                                <Button
                                  onClick={() => {
                                    getBackToEmail();
                                  }}
                                  style={{ display: "inline-block" }}
                                >
                                  <span style={{ display: "inline-block" }}>  <EditIcon /></span>
                                </Button>
                              </Col>
                              <Col md={{ span: 2 }}>
                                {showEmailChecK === true && (<span><CheckIcon /></span>)}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )} */}
                        {/* {showMobileOtp == false && ( */}
                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <label className="login_txtlabel">Mobile</label>
                          <Row>
                            <Col md={{ span: 4 }}>
                              <PhoneInput
                                country="in"
                                onChange={(e, { value }) => {
                                  setFieldValue("cntrycode", e);
                                }}
                                onBlur={(e, { name, value }) => {
                                  setFieldTouched("cntrycode", true);
                                }}
                                enableSearch={true}
                                value={values.cntrycode}
                              />
                            </Col>
                            <Col md={{ span: 8 }}>
                              <TextField
                                name="number"
                                label={
                                  values.number === null && undefined
                                    ? values.number
                                    : "9876543210"
                                }

                                onBlur={(e) => {
                                  handleSubmitmobile(e);
                                 // verifyMobile(e);
                                }}
                                onChange={handleChange}
                                value={values.number}
                                InputProps={{
                                  className: values.number ? classes.manorma : null,
                                }}
                                error={
                                  errors.number && touched.number && errors.number
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.number && touched.number && errors.number
                                }
                              />
                              {otpMobileErrorMessage === true && (<span className="valMsg" >{mobileExistMessage}</span>)}
                            </Col>
                          </Row>
                        </Col>
                        {/* )} */}
                        {/* {showMobileOtp == true && (
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 8 }} >
                            <InputOtp onChange={verifyMobileOtp} />
                            {otpMobileErrorMessage == true && (
                              <p className="valMsg">
                                {mobileError}
                              </p>
                            )}
                          </Col>
                          <Col md={{ span: 4 }} style={{ marginTop: "4%" }}>
                            <Row>
                              {showMobileTimer === true && (
                                <Col md={{ span: 4 }}>
                                  <OtpTimer
                                    seconds={otpSeconds} //otpSeconds
                                    minutes={otpMinutes} //otpMinutes
                                    style={{
                                      color: "blue",
                                      display: "inline-block",
                                    }}
                                    resend={redendOtpMobile}
                                    text=" "
                                    buttonColor={"white"}
                                    background={"#007bff"}
                                    name="mobileotpbtn"
                                  />
                                </Col>
                              )}
                              <Col md={{ span: 4 }} style={{ marginTop: "6%" }}>
                                <Button
                                  onClick={getBackToMobile}
                                  style={{ display: "inline-block" }}
                                >
                                  <EditIcon />
                                </Button>
                              </Col>
                              <Col md={{ span: 2 }}> {showCheckMobile === true && (<span><CheckIcon /></span>)}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )} */}
                        <Col sm={{ span: 1 }}>
                          {/* <label htmlFor="checkid" className="checkbox"> */}
                          <Checkbox
                            id="checkid"
                            type="checkbox"
                            color="primary"
                            onChange={(e) => {
                              termChecked(e);
                            }}
                            value={checked}
                          />
                        </Col>
                        <Col sm={{ span: 11 }}>
                          <label style={{ overflowWrap: "break-word", margin: "10px 0px 0px 0px", letterSpacing: "normal" }}>
                            <label>Agree to the Aagey.com</label>{" "}
                            <label style={{ color: "#4183c4", cursor: "pointer" }}>Terms Of Service</label>{" "}and{" "}
                            <label style={{ color: "#4183c4", cursor: "pointer" }}>Privacy Policy</label>
                          </label>
                        </Col>
                        <Col md={{ span: 12 }}>
                          <Button
                            className="signup_btn"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onSubmit={sendOtp}
                           disabled={checked}
                          //onClick={otpPageEnable}
                          >
                            <span className="signup_label">Send OTP</span>
                          </Button>
                        </Col>
                        <Col md={{ span: 12 }} style={{ textAlign: 'center' }}>
                          <span style={{ display: "inline-flex", fontSize: "100%", fontWeight: "600" }}>
                            Already have an account?&nbsp;&nbsp;
                            <Link to="/" style={{ fontSize: "100%", padding: "0", color: "#ff0000" }}>
                              SIGN IN
                            </Link>
                          </span>
                        </Col>
                      </>
                    )}
                    {enableOtpPage == true && (
                      <>
                        <Col md={{ span: 12 }}>
                          <Link onClick={enableSignUpPage} className="backpagelink"><span style={{ color: "#5290d", fontSize: "100%" }}>
                            <i class="fas fa-arrow-left" style={{ width: '7%', fontSize: '18px' }}></i>{" "}Back to Sign Up</span>
                          </Link>
                        </Col>
                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <label className="login_txtlabel">Enter Email OTP</label>
                          <TextField
                            name="Eotp"
                            label="Enter Email OTP"
                            inputProps={{
                              maxLength: 6,
                            }}
                            onBlur={verifyEmialOtp} />
                          <span>{emailOtpEnter}</span>
                          {showEmailOtpMessage == true && (
                            <p className="valMsg" style={{ color: 'red' }}>
                              {emailError}
                            </p>
                          )}
                        </Col>
                        {showEmailTimer === true && (
                          <Col md={{ span: 4 }} style={{ marginTop: "5px" }}>
                            <OtpTimer
                              seconds={otpSeconds} //otpSeconds
                              minutes={otpMinutes} //otpMinutes
                              style={{
                                color: "red",
                                display: "inline-block", float: "left",
                                marginTop: '4%'
                              }}
                              resend={resendOtpEmail}
                              text=" "
                              textColor={"red"}
                              buttonColor={"red"}
                              background={"#fff"}
                              name="mobileotpbtn"
                            />
                          </Col>
                        )}
                        <Col md={{ span: 12 }} className="textfeild_txtbox">
                          <label className="login_txtlabel">Enter Mobile OTP</label>
                          <TextField
                            name="motp"
                            label="Enter Mobile OTP"
                            inputProps={{
                              maxLength: 6,
                            }}
                            onBlur={verifyMobileOtp} />
                          <span>{mobileOtpEnter}</span>
                          {otpMobileErrorMessage == true && (
                            <p className="valMsg" style={{ color: 'red' }}>
                              {mobileError}
                            </p>
                          )}
                        </Col>
                        {showMobileTimer === true && (
                          <Col md={{ span: 4 }} style={{ marginTop: "5px" }}>
                            <OtpTimer
                              seconds={otpSeconds} //otpSeconds
                              minutes={otpMinutes} //otpMinutes
                              style={{
                                color: "red",
                                display: "inline-block", float: "left",
                                marginTop: '4%'
                              }}
                              resend={redendOtpMobile}
                              text=" "
                              textColor={"red"}
                              buttonColor={"red"}
                              background={"#fff"}
                              name="mobileotpbtn"
                            />
                          </Col>
                        )}
                        <Col md={{ span: 12 }}>
                          <Button
                            className="login_btn signup_btn"
                            //type="submit"
                            variant="contained"
                            color="primary"
                            //onSubmit={signIn}
                            onClick={signUp}

                          //disabled={signInBtn}
                          >
                            <span className="signup_label">Sign Up</span>
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </div >
  );
}