import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from 'react-bootstrap/Modal';
import Input from '@material-ui/core/Input';
import {ErrorCatch,apiCalling} from "../../shared/constants";
import { Dropdown, TextArea } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import axios from "axios";
import { Formik } from "formik";
import { bankonboard } from "../../shared/validations";
import { useStyles } from "./styles";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from 'mdbreact';
import {
  mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert, mdiArrowRightBox, mdiArrowLeftBox, mdiPlusBox, mdiCloudUpload
} from "@mdi/js";
import { StyledTableCell } from '../../shared/constants';

export default function OnBoardingBank(props) {
  const classes = useStyles();
  const [panvalue, setpanvalue] = React.useState();
  const [email, setemail] = React.useState('');
  const [emailotp, setemailotp] = React.useState();
  const [mobile, setmobile] = React.useState();
  const [data, setdata] = React.useState([]);
  const [mobotp, setmobotp] = React.useState();
  const [table, setTable] = React.useState(false);
  const [bank, setbank] = React.useState([]);
  const [display, setdisplay] = React.useState(true);
  const [show, setshow] = React.useState(false);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const [assettype, setState] = React.useState('');
  const [datatable, setDatatable] = React.useState({

    columns: [
      {
        label: 'Sl No.',
        field: 'slno',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Representative',
        field: 'representative',
        width: 270,
      },
      {
        label: 'Role',
        field: 'role',
        width: 200,
      },
      {
        label: 'Status',
        field: 'status',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: [
      {
        slno: '1',
        representative: 'Rajesh Kumar',
        role: 'Front Line Staff',
        status: 'Manage',
        action: 'Edit/Delete'
      },
    ],
  });

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    bankcheck();
  }, []);

  var bankarray = []

  const bankcheck = () => {

    const bankdata = {
      token: "qwerty1234",

    }


    axios.post(apiCalling.coderscorner +`server.php/api/getAllbank`, bankdata)
      .then(res => {
        // setdata(loanapplydata)
        // setgender(res.data.data)
				/* var genderobject = {
				  key: "",
				  value: "",
				  flag: "",
				  text: ""
				} */
        res.data.data.map((item) => {
          console.log('item', item)

          bankarray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setbank(bankarray)
        console.log("bankarray", bankarray);

      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }

  const handleSubmited = () => {
    setshow(true)
  };


  const handleNextClick = () => {
    const onboardingbank = localStorage.getItem('onboardingbank'),
      onbank = JSON.parse(onboardingbank)
    console.log('datarjjjjjjjjjjje', onbank)
    setdata(onbank)
    setdisplay(false)
    setTable(true)
    setDatatable({
      columns: [
        {
          label: 'Sl No.',
          field: 'slno',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: 'Representative',
          field: 'representative',
          width: 270,
        },
        {
          label: 'Role',
          field: 'role',
          width: 200,
        },
        {
          label: 'Status',
          field: 'status',
          width: 100,
        },
        {
          label: 'Action',
          field: 'action',
          width: 100,
        },
      ],

      rows: [
        {
          slno: '1',
          representative: onbank.fname + ' ' + onbank.lname,
          role: 'Front Line Staff',
          status: 'Manage',
          action: 'Edit/Delete'
        },
      ],
    });
  }

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {

    props.handleBack();
  };


  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true
    })
  };

  const SaveasDraft = () => {
    alert('Save As Draft')
    props.handlecheck();
  }

  const handleOnBoardingBank = (data) => {
    let bankOnBoardingData = {
      first_name: data.fname,
      last_name: data.lname,
      ifsc: data.ifsc,
      bank_id: data.bank,
      primary_id: "1",
      email: data.email,
      mobile: data.mobile,
      parent_type_id: "1",
    };

    console.log("result", bankOnBoardingData);

    axios
      .post(apiCalling.coderscorner +
        `server.php/api/bankstaffOnboardingadd`,
        bankOnBoardingData
      )
      .then((res) => {
        // setdata(bankOnBoardingData);
        console.log("yeh we have", res.data);
      })
      .catch((er) => {
        console.log("no data sorry ", er);
      });
  };

  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: -30, padding: '15px' }}>
        <Row>
          <Col md={{ span: 12 }}>
            {/* <Typography variant="h4" gutterBottom>Business Information</Typography> */}
            <Formik
              initialValues={{ lname: "", fname: "", bank: "", ifsc: "", email: "", mobile: "", }}
              validationSchema={bankonboard}
              onSubmit={(values) => {
                console.log('kkkkk', values)
                handleOnBoardingBank(values)
                localStorage.setItem('onboardingbank', JSON.stringify(values))
                handleNextClick()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
                /* and other goodies */
              }) => {
                //  console.log("values in formik", values);
                return (
                  <form onSubmit={handleSubmit}>

                    <div className="content-header" style={{ position: 'unset' }}>
                      <Row>
                        <Col md={{ span: 6 }}>

                          <Typography
                            text="OnBoarding - Bank Representative"
                            styleType="C"
                          />

                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className="content-header-button ">


                            <Button
                              className={classes.linkdin}
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              className={classes.button_others}>
                              <Icon path={mdiPlaylistPlus} title="save" size={1} />
                            </Button>


                            <Button
                              className={classes.button_div}
                              variant="outlined"
                              color="primary"
                              className={classes.button_others}

                            ><Icon path={mdiRefresh} title="reset" size={1} /></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {display == true && (
                      <Row style={{ marginTop: '50px' }}>
                        <Col md={{ span: 6 }}>
                          <Typography text="First Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="fname"
                                label="Enter first Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.fname &&
                                    touched.fname &&
                                    errors.fname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.fname ? classes.manorma : null,
                                }}
                                value={values.fname}
                                helperText={
                                  errors.fname &&
                                  touched.fname &&
                                  errors.fname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Last Name" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="lname"
                                label="Enter Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.lname &&
                                    touched.lname &&
                                    errors.lname
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.lname ? classes.manorma : null,
                                }}
                                value={values.lname}
                                helperText={
                                  errors.lname &&
                                  touched.lname &&
                                  errors.lname
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Bank" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <Dropdown
                                placeholder="Select Bank Name"
                                id="bank"
                                name="bank"
                                fluid
                                search
                                selection
                                value={values.bank}
                                onBlur={() => setFieldTouched("bank", true)}
                                onChange={(e, { value }) => setFieldValue("bank", value)}
                                options={bank}
                                className={errors.bank && touched.bank ? classes.required : values.bank == '' ? classes.reqired : classes.man}
                              />
                            </ListItem>
                          </List>
                          {errors.bank && touched.bank && values.bank == '' && (

                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.bank}</div>

                          )}

                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="IFSC" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic"
                                name="ifsc"
                                label="Enter IFSC Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.ifsc &&
                                    touched.ifsc &&
                                    errors.ifsc
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  className: values.ifsc ? classes.manorma : null,
                                }}
                                value={values.ifsc}
                                helperText={
                                  errors.ifsc &&
                                  touched.ifsc &&
                                  errors.ifsc
                                }
                              />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Mobile" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Mobile No"
                                name="mobile"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.mobile ? classes.manorma : null,
                                }}

                                inputProps={{
                                  maxLength: 10,
                                }}
                                error={
                                  errors.mobile &&
                                    touched.mobile &&
                                    errors.mobile
                                    ? true
                                    : false
                                }
                                value={values.mobile}
                                helperText={
                                  errors.mobile &&
                                  touched.mobile &&
                                  errors.mobile
                                } />
                            </ListItem>
                          </List>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Typography text="Email" styleType="B" />
                          <List component="div" disablePadding>
                            <ListItem>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  className: values.email ? classes.manorma : null,
                                }}
                                error={
                                  errors.email &&
                                    touched.email &&
                                    errors.email
                                    ? true
                                    : false
                                }
                                value={values.email}
                                helperText={
                                  errors.email &&
                                  touched.email &&
                                  errors.email
                                }

                              />
                            </ListItem>
                          </List>
                        </Col>
                      </Row>
                    )}

                  </form>
                );
              }}
            </Formik>

            {table == true && (
              <Row style={{ marginBottom: 7, marginTop: 75 }}>
                <Col md={{ span: 12 }}><Typography text="Details" styleType="E" /></Col>
              </Row>
            )}


            {table == true && (
              <div>

                <MDBDataTableV5
                  hover
                  entriesOptions={[5, 10, 20, 30]}
                  entries={5}
                  pagesAmount={4}
                  data={datatable}
                  pagingTop
                  searchBottom={false}
                  barReverse
                  searchTop={datatable.rows.length > 5 ? true : false}
                  paging={datatable.rows.length > 5 ? true : false}
                />



              </div>
            )}

          </Col>
        </Row>
      </div>
    </Container>

  );
}