import React, { useState,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import * as yup from 'yup'

import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '../../base_components/Typography';
import { apiCalling } from '../adminConstants';
import { Formik, ErrorMessage } from "formik";
import axios from "axios";
import { useStyles } from "../admin";
import { MDBDataTableV5 } from "mdbreact";
import Paper from '@material-ui/core/Paper';
import { pinCreationSchema } from '../../shared/validations';
// import {userCreationSchema, pinCreationSchema} from '../../shared/validations'
import DraftNext from '../../image/buttons/draftnext.png';
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import { LoaderColor } from '../../shared/constants';
import Loader from 'react-loader-spinner';
import grayadd from "../../image/buttons/grayadd.png";
import blueadd from "../../image/buttons/add.png";
import grayback from '../../image/buttons/grayback.png';
import manualentry from '../../image/pngicons/manualaddition.png';
import deleterec from '../../image/pngicons/delete.png';
import { Dropdown, TextArea } from 'semantic-ui-react';
import { SnackPosition } from '../../shared/constants';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import $ from 'jquery';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Modal from "react-bootstrap/Modal";
import ReactQuill from 'react-quill';


export default function UserCreation(props) {

    const classes = useStyles();
    const [showWCAddForm, setShowWCAddForm] = React.useState(false);
    const [showList, setShowList] = React.useState(true);
    const [loaderFlag, setLoaderFlag] = React.useState(true);

    const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");

    const [roles, setRoles] = React.useState([]);
    
    const [smReportingManager, setSmReportingManager] = React.useState([]);
    const [bpReportingManager, setBpReportingManager] = React.useState([]);
    const [scaReportingManager, setScaReportingManager] = React.useState([]);
    const [reportingManager, setReportingManager] = React.useState([]);
    const [cityOption, setCityOption] = React.useState([]);
    const [pincode, setPincode] = React.useState([]);
    const [nopincode, setNoPincode] = React.useState([]);

    const [searchName, setSearchNameField] = React.useState(null);
    const [searchPhone, setSearchPhoneField] = React.useState(null);
    const [searchCode, setSearchCodeField] = React.useState(null);
    const [searchRole, setSearchRoleField] = React.useState(null);

    const [setCity, setCityField] = React.useState(null);
    const [setType, setTypeField] = React.useState(null);
    const [setAddPincode, setAddPincodeField] = React.useState(null);
    const [mainPincode, setMainPincode] = React.useState([]);

    const userCreationSchema = yup.object().shape({
        name: yup.string().required('Please enter Name'),
        role_id: yup.number().required('Please select Role'),
        mobile: yup
          .string()
          .required('Please enter mobile number')
          .matches(/^\d{10}$/, 'Please enter valid mobile number'),
        email: yup
          .string()
          .required('Please enter email')
          .email('Please enter a valid email address'),
        pan: yup
          .string()
          .required('Please enter pan')
          .matches(/[a-zA-Z]{3}[a-zA-Z]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/, 'Please enter the valid format of pan'),
        address: yup.string().required('Please enter address'),
        employee_code: yup.string().when("role_id", {
            is: value => value && [12, 3, 1, 16, 9].includes(value),
            then: yup.string().required(
                "Please select Employee code"
            ),
            otherwise: yup.string().nullable(true)
        }),
        analyst_type: yup.string().when("role_id", {
            is: value => value && [3, 1].includes(value),
            then: yup.string().required(
                "Please select Analyst Type"
            ),
            otherwise: yup.string().nullable(true)
        }),
        analyst_privilege: yup.string().when("role_id", {
            is: value => value && [1].includes(value),
            then: yup.string().required(
                "Please select Analyst Privilege"
            ),
            otherwise: yup.string().nullable(true)
        }),
        reporting_manager_id: yup.string().when("role_id", {
            is: value => value && [3].includes(value),
            then: yup.string().required(
                "Please select Reporting Manager"
            ),
            otherwise: yup.string().nullable(true)
        }),
        sca_reporting_manager_id: yup.string().when("role_id", {
            is: value => value && [1, 16, 9].includes(value),
            then: yup.string().required(
                "Please Select Reporting Manager (SCA)"
            ),
            otherwise: yup.string().nullable(true)
        }),
        incentives: yup.string().when("role_id", {
            is: value => value && [16, 9].includes(value),
            then: yup.string().required(
                "Please Select Incentives"
            ),
            otherwise: yup.string().nullable(true)
        }),
        commission: yup.string().when("role_id", {
            is: value => value && [6, 11].includes(value),
            then: yup.string().required(
                "Please Select Commission"
            ),
            otherwise: yup.string().nullable(true)
        }),
        business_code: yup.string().when("role_id", {
            is: value => value && [6, 11].includes(value),
            then: yup.string().required(
                "Please Select Business Code"
            ),
            otherwise: yup.string().nullable(true)
        }),
        sm_reporting_manager_id: yup.string().when("role_id", {
            is: value => value && [6, 11].includes(value),
            then: yup.string().required(
                "Please Select Sales Manager"
            ),
            otherwise: yup.string().nullable(true)
        }),
        bp_reporting_manager_id: yup.string().when("role_id", {
            is: value => value && [6, 11].includes(value),
            then: yup.string().required(
                "Please Select Business Partner"
            ),
            otherwise: yup.string().nullable(true)
        }),
        bank_id: yup.string().when("role_id", {
            is: value => value && [5].includes(value),
            then: yup.string().required(
                "Please Select Bank"
            ),
            otherwise: yup.string().nullable(true)
        }),
        branch_name: yup.string().when("role_id", {
            is: value => value && [5].includes(value),
            then: yup.string().required(
                "Please Select Branch Name"
            ),
            otherwise: yup.string().nullable(true)
        }),
        // pincode: yup.string()
        // .required("Must have invitees")
      })

    const handleMainPincode = event => {
        setMainPincode([{
            key: event.target.value,
            value: event.target.value,
            text: event.target.value
        }]);
    }

    const handleTypeChange = event => {
        setTypeField(event.target.value);
    };    

    const [openStatus, setOpenStatus] = React.useState(false);

    const handleOpenModel = () => {
        setCityField(null)
        // setMainPincode([]);
        setPincode([])
        setTypeField(null)
        setAddPincodeField(null);
        setOpenStatus(true)
    }
    const handleClose = () => {
        setOpenStatus(false);
    };

    const handleNameChange = event => {
        setSearchNameField(event.target.value);
    };    

    const analyst_type_option = [
        {
            key: 'Finance',
            value: 'Finance', 
            text: 'Finance'
        }, {
            key: 'Valuation',
            value: 'Valuation', 
            text: 'Valuation'
        }, {
            key: 'Legal',
            value: 'Legal', 
            text: 'Legal'
        }, {
            key: 'All',
            value: 'All', 
            text: 'All'
        },
    ]

    const analyst_privilege_option = [
        {
            key: 'Pre-Login',
            value: 'Pre-Login', 
            text: 'Pre-Login'
        }, {
            key: 'Post-Login',
            value: 'Post-Login', 
            text: 'Post-Login'
        }, {
            key: 'Both',
            value: 'Both', 
            text: 'Both'
        }
    ] 

    const [typeError, setTypeError] = React.useState(null);
    const [cityError, setCityError] = React.useState(null);
    const [pincodeError, setPincodeError] = React.useState(null);
    const addPincode = async () => {
        // if(!setType) {
        //     setTypeError('Please select type');
        // }
        console.log(setCity, setAddPincode)
        if(!setCity) {
            setCityError('Please select city');
        }
        if(!setAddPincode) {
            setPincodeError('Please select pincode');
        }
        console.log("\n \n Before", mainPincode)
        if(setAddPincode && setCity){
            const pinA = [];
            if(setType == 'Range'){
                if(setAddPincode){
                    nopincode.push({
                        key: setAddPincode.join('-'),
                        value: setAddPincode.join('-'),
                        text: setAddPincode.join('-')
                    })
                    mainPincode.push(setAddPincode.join('-'))
                    setMainPincode(mainPincode);
                }
            } else {
                if(setAddPincode){
                    setAddPincode.map((item) => {
                        nopincode.push({
                            key: item,
                            value: item,
                            text: item
                        })
                        mainPincode.push(item)
                    })
                    
                    setMainPincode(mainPincode);
                }
            }
            if(mainPincode.length > 0){
                console.log(mainPincode.length)
                setpinerr(null)
            }
            // setNoPincode(pinA)
            console.log("[ocpde", mainPincode, nopincode)
            setOpenStatus(false);
        }
        
    }

    const getAllCity = async () => {
        let cityArray = [];
        await axios.post(apiCalling.coderscorner + `server.php/api/getAllCity`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                cityArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.city
                })
            })
            setCityOption(cityArray)
        }).catch(er => {
        });
	}

    const getAllPinCode = async (city_id=null) => {
        let pinArray = [];
        let data = {enable: '1'};
        if (city_id){
            data['city_id'] = city_id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getAllPinCodes`, data).then(res => {
            let checkArr = []
            mainPincode.map((p) => {
                const pincodesRange = p.toString().split('-');
                if(pincodesRange.length >= 2 ){
                    for (let i = parseInt(pincodesRange[0]); i <= parseInt(pincodesRange[1]); i++) {
                        checkArr.push(i.toString())
                    }
                } else {
                    checkArr.push(p)
                }
            })
            res.data.data.map((item) => {
                if (!checkArr.includes(item.pincode)){
                    pinArray.push({
                        key: item.id,
                        value: item.pincode,
                        text: item.pincode
                    })
                }
                
            })
            setPincode(pinArray)
        }).catch(er => {
        });
	}

    const getAllRoles = async () => {
        let rolesArray = [];
        await axios.get(apiCalling.identityManagement + `server.php/api/getAllRole`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                rolesArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            })
            setRoles(rolesArray)
        }).catch(er => {
        });
	}

    const getAllReportingManager = async () => {
        let userArray = [];
        await axios.get(apiCalling.identityManagement + `server.php/api/getAllReportingManager`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                userArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            })
            setReportingManager(userArray)
        }).catch(er => {
        });
	}

    const getAllSCAReportingManager = async () => {
        let userArray = [];
        await axios.get(apiCalling.identityManagement + `server.php/api/getAllSCAReportingManager`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                userArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            })
            setScaReportingManager(userArray)
        }).catch(er => {
        });
	}

    const getAllSMReportingManager = async () => {
        let userArray = [];
        await axios.get(apiCalling.identityManagement + `server.php/api/getAllSMReportingManager`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                userArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            })
            setSmReportingManager(userArray)
        }).catch(er => {
        });
	}

    const getAllBPReportingManager = async () => {
        let userArray = [];
        await axios.get(apiCalling.identityManagement + `server.php/api/getAllBPReportingManager`, {enable: '1'}).then(res => {
            res.data.data.map((item) => {
                userArray.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            })
            setBpReportingManager(userArray)
        }).catch(er => {
        });
	}

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        handleCpaList();
        handleScaList();
        handleAdminList();
        handleBankerList();
        // handleBanker();
        getAllDocumentCollectors();
        getAllDsa();
        loantypecheck();
        bankcheck();
        getAllRoles();
        getAllReportingManager();
        getAllSCAReportingManager();
        getAllSMReportingManager();
        getAllBPReportingManager();
        // getAllPinCode();
        getAllCity();
        getRoleName();
    }, []);

    const [loantype, setloantype] = React.useState([]);
    const [iniLoan, setIniLoan] = React.useState();

    const loantypecheck = async (reqDetails) => {
        const loandata = {
            token: "qwerty1234",
        }
        var loantypearray = []
        await axios.post(apiCalling.coderscorner + 'server.php/api/getAllloanType', loandata)
            .then(res => {
                res.data.data.map((item) => {
                    loantypearray.push({
                        key: item.short_name,
                        value: item.id,
                        flag: item.name,
                        text: item.name
                    })
                })
                setloantype(loantypearray);
                if (reqDetails === undefined || reqDetails === null) {
                    setIniLoan(res.data.data[0].id);

                } else {
                    setIniLoan(reqDetails.business_channel_id)
                }
            })
            .catch(er => {
                console.log("no data  ", er);
            });
    }




    const handleSubmiting = () => {
        props.handleNext();
    };

    const handledata = () => {

        props.handleBack();
        
    };


    const handleselectChange = (event) => {
        event.preventDefault();
        this.setState({
            showOption: true
        })
    };

    const SaveasDraft = () => {
        alert('Save As Draft')
        props.handlecheck();
    }

    let [initialValue, setInitialValue] = React.useState({
        pan: "",
        email: "",
        mobile: "",
        enable: "1",
        id: "",
        name: "",
        ref_code: "",
        country_code: "+91",
        pincode:"",
        type: "",
        area: "",
        employee_code:"",
        role_id: "",
        address: "",
        bank_id: "",
        analyst_type: "",
        analyst_privilege: "",
        reporting_manager_id: "",
        sca_reporting_manager_id: "",
        incentives: "",
        commission: "",
        business_code: "",
        sm_reporting_manager_id: "",
        bp_reporting_manager_id:"",
        branch_name: "",
    })

    let [pinaddInitialValue, setPinaddInitialValue] = React.useState({
        filtered_pincode: "",
        pincode_type:"",
        city_id: "",
    })


    const [cpaList, setCpaList] = React.useState([]);
    const handleCpaList = async (data={enable: true}) => {
        await axios
            .post(apiCalling.identityManagement + 'server.php/api/getAllUsersExceptCustomer', data).then((result) => {
                let empArray = [];
                if(result.data.status){
                  result.data.data.map((item)=>{
                    empArray.push({
                        pan:item.pan,
                        mobile:item.mobile,
                        role_id:item.role_id,
                        employee_code:item.employee_code,
                        created_on:item.created_on,
                        name:item.name,
                        referal_code:item.referal_code

                    }) 
                  })

                    setShowWCAddForm(false);
                    setShowList(true);
                    setLoaderFlag(false);
                    setCpaList(result.data.data);
                } else {
                    setCpaList([]);
                }

                
            }).catch((error) => {
                console.log(error);
            });
    };


    const[adminList,setAdminList]=React.useState([]);
    const adminData = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role Id",
                field: "role_id",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },
        ],

        rows: adminList.map((values, i) => {
            return {
                slno: i + 1,
                email: values.email,
                mobile: values.mobile,
                role_id: values.role_id,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

const handleAdminList=async()=>{
    const data={
        enable :1
    };
    await axios.post(apiCalling.identityManagement + 'server.php/api/getAllAdmin',data).then((result)=>{
        let empArray=[];
        if(result.data.status){
           result.data.data.map((item)=>{
            empArray.push({
                pan:item.pan,
                mobile:item.mobile,
                rile_id:item.role_id,
                country_code:item.country_code,
                name:item.name,
                referal_code:item.referal_code
            })
           })
           setShowWCAddForm(false)
           setShowList(true)
           setAdminList(result.data.data);
        }
    }).catch((error)=>{
        console.log("AdminList Error", error)
    })
}
    const [pinerr, setpinerr] = React.useState(null);
    const [valid, setValid] = React.useState(false);

    const [roleNameArray, setRoleNameArray] = React.useState({});
    const getRoleName = async () => {
        let data = {
            // role_id: role_id
        }
        let empArray = {};
        await axios
            .post(apiCalling.identityManagement + 'server.php/api/getRoleByid', data).then((result) => {
                console.log(result)
                result.data.data.map((item)=>{
                    empArray[item.id] = item.name
                })
                setRoleNameArray(empArray);
                console.log("RoleArray", roleNameArray)
            }).catch((error) => {
                console.log(error);
            });
    }
    const [scaList, setScaList] = React.useState([]);
    const handleScaList = async () => {
        
        const data = {
            enable:true
        };
        await axios
            .post(apiCalling.identityManagement + 'server.php/api/getAllSa', data).then((result) => {
                let empArray = [];
                if(result.data.status){
                    result.data.data.map((item)=>{
                        empArray.push({
                            pan:item.pan,
                            mobile:item.mobile,
                            role_id:item.role_id,
                            country_code:item.country_code,
                            name:item.name,
                            referal_code:item.referal_code

                        }) 
                    })
                    setShowWCAddForm(false);
                    setShowList(true);
                    setLoaderFlag(false);
                    setScaList(result.data.data);
                } 
              
            }).catch((error) => {
                console.log(error);
            });
    };

    const data = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Name",
                field: "name",
                width: 200,
            },
            // {
            //     label: "Employee Code",
            //     field: "employee_code",
            //     width: 200,
            // },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role",
                field: "role_id",
                width: 200,
            },
            {
                label: "Created Date",
                field: "created_on",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },
        ],

        rows: cpaList.map((values, i) => {
            // getRoleName(values.role_id)
            return {
                slno: i + 1,
                name: values.name,
                // employee_code: values.employee_code,
                email: values.email,
                mobile: values.mobile,
                role_id: roleNameArray[values.role_id],
                created_on: values.created_on,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img style={{margin: "0% 4%"}} className="actionBtn svgicon" src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img style={{margin: "0% 4%"}} className="actionBtn svgicon" src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }


    const scadata = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role Id",
                field: "role_id",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },
        ],

        rows: scaList.map((values, i) => {
            return {
                slno: i + 1,
                email: values.email,
                mobile: values.mobile,
                role_id: values.role_id,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" src={deleterec} alt='' onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

    const handleBanker = async (data) => {
        let wcData = {
            email: data.email,
            mobile: data.mobile,
            role_id: data.enable,
            country_code: "91",
            name: data.name,
            loan_type_id: data.type,
            city: data.area,
            bank_id: data.bank_id,
        }
       
        axios.post(apiCalling.identityManagement + 'server.php/api/saveBankerProfile', wcData).then((res) => {
            console.log(res);
        }).catch(error => {
            console.log(error);
        });

    }

    const resetUserSearch = (event) => {
        const data = {
            "enable": true
        }
        setSearchNameField('')
        setSearchRoleField(null)
        handleCpaList(data)
    }

    const searchUser = (event) => {
        event.preventDefault();
        const data = {
            "enable": true
        }
        if(searchName){
            data["name"] = searchName
        }
        if(searchRole){
            data["role_id"] = searchRole
        }
        if(searchPhone){
            data["phone"] = searchPhone
        }
        if(searchCode){
            data["employee_code"] = searchCode
        }
        handleCpaList(data)
    }

    const handlePincodeform = (values, {resetForm}) => {
        console.log(values)
    }

    const handlesubmitform = (values, { form, field, resetForm }) => {
        console.log(mainPincode.length)
        if (mainPincode.length == 0 && values.role_id != 1) {
            setpinerr("Please select pincode")
        }
        else {
            // if(values.role_id ==5  ){
                // handleBanker(values);
                // setShowWCAddForm(false)
            // }else{
                handleNextClick(values);
            // }
          
            resetForm({
                pan: "",
                mobile: "",
                email: "",
                enable: "",
                id: ""
            })
        }
        
    };
    
    const [emailexisterr, setemailexisterr] = React.useState(null);
    const [mobileerr, setmobileerr] = React.useState(null);

    const handleNextClick = async (data) => {
        setShowWCAddForm(false);
        setLoaderFlag(true);
        let wcData = {
            pan: data.pan,
            email: data.email,
            mobile: data.mobile,
            role_id: data.role_id,
            country_code: "91",
            name: data.name,
            // referal_code: data.ref_code,
            // user_type: data.user_type,
            designation: data.designation,
            address: data.address,
            pincode: mainPincode,
            bank_id: data.bank_id,
            employee_code: data.employee_code,
            analyst_type: data.analyst_type,
            analyst_privilege: data.analyst_privilege,
            reporting_manager_id: data.reporting_manager_id,
            sca_reporting_manager_id: data.sca_reporting_manager_id,
            incentives: data.incentives,
            commission: data.commission,
            business_code: data.business_code,
            sm_reporting_manager_id: data.sm_reporting_manager_id,
            bp_reporting_manager_id: data.bp_reporting_manager_id,
            branch_name: data.branch_name,
            id: data.id
        };
        await axios.post(apiCalling.identityManagement + 'server.php/api/saveUserwithRole', wcData).then((res) => {
           if(res.data.status){
            console.log("saved successfully...!!!");
            setShowWCAddForm(false);
            setShowList(true);
            handleCpaList();
            handleScaList();
            handleBankerList();
            // setFormat("success");
            //     setSnackBarMsg(res.data.message);
            //     setsavenextmessage(true);
           } else {
                setShowWCAddForm(true);
                setLoaderFlag(false)
                if(res.data.message == 'Email Already exists') {
                    setemailexisterr('The email id already exists')
                }
                if(res.data.message == 'Mobile Already exists') {
                    setmobileerr('The number already exists')
                }
                setInitialValue({
                    ...data,
                    pan: data.pan,
                    email: data.email,
                    mobile: data.mobile,
                    role_id: data.role_id,
                    country_code: "91",
                    name: data.name,
                    enable: data.enable ? "1" : "0",
                    id: data.id,
                    // referal_code: data.ref_code,
                    // user_type: data.user_types,
                    designation: data.designation,
                    address: data.address,
                    pincode: data.pincode,
                    bank_id: data.bank_id,
                    employee_code: data.employee_code,
                    analyst_type: data.analyst_type,
                    analyst_privilege: data.analyst_privilege,
                    reporting_manager_id: data.reporting_manager_id,
                    sca_reporting_manager_id: data.sca_reporting_manager_id,
                    incentives: data.incentives,
                    commission: data.commission,
                    business_code: data.business_code,
                    sm_reporting_manager_id: data.sm_reporting_manager_id,
                    bp_reporting_manager_id: data.bp_reporting_manager_id,
                    branch_name: data.branch_name
                    // name:data.name,
                    // pan: data.pan,
                    // phone: data.mobile,
                    // email: data.email,
                    // id: data.id,
                    // role_id:data.role_id,
                    // enable: data.enable ? "1" : "0",
                    // ref_code: data.referal_code
                })
           }
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    };

    const handleOpenWCAddForm = () => {
        setAddFormButtonValue("Submit");
        setCityField(null)
        setMainPincode([]);
        setNoPincode([]);
        setShowWCAddForm(true);
        setpinerr(null);
        setemailexisterr(null);
        setmobileerr(null);
        setShowList(false);
        setInitialValue({
            pan: "",
            email: "",
            mobile: "",
            enable: "1",
            id: "",
            name: "",
            ref_code: "",
            country_code: "+91",
            pincode:"",
            type: "",
            area: "",
            employee_code:"",
            role_id: "",
            address: "",
            bank_id: "",
            analyst_type: "",
            analyst_privilege: "",
            reporting_manager_id: "",
            sca_reporting_manager_id: "",
            incentives: "",
            commission: "",
            business_code: "",
            sm_reporting_manager_id: "",
            bp_reporting_manager_id: "",
            branch_name: "",
        })
    }

    const handleEdit = (data) => {
        
        setMainPincode([]);
        if (data.pincode){
            const pinArrays = []
            setMainPincode(data.pincode.toString().split(','))
            data.pincode.toString().split(',').map((item)=> {
                pinArrays.push({
                    key: item,
                    value: item,
                    text: item
                })
            })
            setNoPincode([]);
            setNoPincode(pinArrays);
        } else {
            setNoPincode([]);
        }
        console.log(data);
        setInitialValue({
            ...data,
            pan: data.pan,
            email: data.email,
            mobile: data.mobile,
            role_id: data.role_id,
            country_code: "91",
            name: data.name,
            enable: data.enable ? "1" : "0",
            id: data.id,
            // referal_code: data.ref_code,
            // user_type: data.user_types,
            designation: data.designation,
            address: data.address,
            pincode: data.pincode,
            bank_id: data.bank_id,
            employee_code: data.employee_code,
            analyst_type: data.analyst_type,
            analyst_privilege: data.analyst_privilege,
            reporting_manager_id: data.reporting_manager_id,
            sca_reporting_manager_id: data.sca_reporting_manager_id,
            incentives: data.incentives,
            commission: data.commission,
            business_code: data.business_code,
            sm_reporting_manager_id: data.sm_reporting_manager_id,
            bp_reporting_manager_id: data.bp_reporting_manager_id,
            branch_name: data.branch_name
            // name:data.name,
            // pan: data.pan,
            // phone: data.mobile,
            // email: data.email,
            // id: data.id,
            // role_id:data.role_id,
            // enable: data.enable ? "1" : "0",
            // ref_code: data.referal_code
        })
        setShowWCAddForm(true);
        setAddFormButtonValue("Update");
        setShowList(false);
        console.log("\n \n initialValue", initialValue)
    }

    const handleDelete = (item) => {
        
        let data = {
            id: item.id
        }
        axios.post(apiCalling.identityManagement + 'server.php/api/deleteUser', data).then((res) => {
            setShowWCAddForm(false);
            setShowList(true);
            handleCpaList();
            handleScaList();
        })
            .catch((er) => {
                console.log("no data sorry ", er);
            });
    }

    const [dsaList, setDsaList] = React.useState([]);
    const getAllDsa = async () => {
        let data = {
            enable: 1
        }

        await axios.post(apiCalling.identityManagement + 'server.php/api/getAllDsa', data).then(result => {
           let empArray=[];
           if(result.data.status){
               result.data.data.map((item)=>{
                empArray.push({
                    pan:item.pan,
                    mobile:item.mobile,
                    role_id:item.role_id,
                    country_code:item.country_code,
                    name:item.name,
                    referal_code:item.referal_code
                })
               })
               setDsaList(result.data.data);
               setShowWCAddForm(false)
               setShowList(true);
               setLoaderFlag(false)
           }
            
        }).catch(error => {
            console.log(error);
        })
    }
    
    const dsadata = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role Id",
                field: "role_id",
                width: 200,
            },
            {
                label: "Referral Code",
                field: "ref_code",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },

        ],

        rows: dsaList.map((values, i) => {
            return {
                slno: i + 1,
                email: values.email,
                mobile: values.mobile,
                role_id: values.role_id,
                ref_code: values.referal_code,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

    // const removePin = (pin) => {
    //     console.log("pin", pin.target.value)
    // }

    // const handlePinChange = (selectedOption, actionMeta) => {
    //     if(selectedOption.key == 'Backspace'){
    //         if(mainPincode.length > 0){
    //             mainPincode.pop();
    //             nopincode.pop();
    //             setMainPincode([]);
    //             let pinArr = []
    //             nopincode.map((item) => {
    //                 pinArr.push(item.text);
    //             })
    //             setMainPincode(pinArr);
    //         }
    //     }
    //     console.log(mainPincode)
    //     // selectedOption.persist();
    //     // mainPincode.push(selectedOption.target.textContent)
    //     // handleMainPincode(mainPincode);
    // };

    const [area, setArea] = React.useState([]);
    const getPincodeData = async (data) => {
        let getPincode = {
            pincode: data,
            token: "qwerty1234",
        };
        await axios
            .post(apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
                getPincode
            )
            .then((res) => {
                // console.log("yeh we have pincode", res.data);
                return handleAllAreas(res.data.data);
            })
            .catch((er) => {
                console.log("no pincode sorry ", er);
            });
    };

    const handleAllAreas = (data) => {
        let allArea = [];
        for (let i = 0; i < data.length; i++) {
            allArea.push({
                key: data[i].area,
                value: data[i].id,
                text: data[i].area,
            });
        }
        return setArea(allArea);
    };

    var bankarray = [];
    const [bank, setbank] = React.useState([]);
    const bankcheck = async () => {
        const bankdata = {
            token: "qwerty1234"
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getAllbank`, bankdata)
            .then(res => {
                res.data.data.map((item) => {
                    bankarray.push({
                        key: item.short_name,
                        value: item.id,
                        // flag: item.name,
                        text: item.name
                    })
                })
                setbank(bankarray)
            })
            .catch(er => {
                //console.log("no data  ", er);
            });
    }

const[bankerList,setBankerList]=React.useState([])
const handleBankerList=async()=>{
    
   const data={
    enable:1
   } 
   await axios.post(apiCalling.identityManagement + 'server.php/api/getAllBanker',data).then((result)=>{
let empArray=[];
if(result.data.status){
result.data.data.map((item)=>{
    empArray.push({
        pan:item.pan,
        mobile:item.mobile,
        role_id:item.role_id,
        country_code:item.country_code,
        name:item.name,
        referal_code:item.referal_code
    })
})
setShowWCAddForm(false);
setShowList(true);
setLoaderFlag(false);
setBankerList(result.data.data)
}

   }).catch((error)=>{
  console.log('BankerList is Error', error)

   })
}



    const bankerdata = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role Id",
                field: "role_id",
                width: 200,
            },
            {
                label: "Referral Code",
                field: "ref_code",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },

        ],

        rows: bankerList.map((values, i) => {
            return {
                slno: i + 1,
                email: values.email,
                mobile: values.mobile,
                role_id: values.role_id,
                ref_code: values.referal_code,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

    
const[dcData,setDcData]=React.useState([]);
    const getAllDocumentCollectors = async () => {
        
        let data = {
            enable:1
        }

        await axios.post(apiCalling.identityManagement + 'server.php/api/getAllDocumentTeam', data).then(result => {
           let empArray=[];
          if(result.data.status){
            result.data.data.map((item)=>{
                empArray.push({
                    pan:item.pan,
                    mobile:item.mobile,
                    name:item.name,
                    role_id:item.role_id,
                    referal_code:item.referal_code,
                })
               })
               
               setShowWCAddForm(false)
               setShowList(true);
               setLoaderFlag(false);
               setDcData(result.data.data);
          }

           
           // setAllCollectors( result.data.data);
        }).catch(error => {
            console.log(error);
        });
    }

      const dcdata = {
        columns: [
            {
                label: "#",
                field: "slno",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Name",
                },
            },
            {
                label: "Email",
                field: "email",
                width: 270,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 200,
            },
            {
                label: "Role Id",
                field: "role_id",
                width: 200,
            },
            {
                label: "Referral Code",
                field: "ref_code",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },

        ],

        rows: dcData.map((values, i) => {
            return {
                slno: i + 1,
                email: values.email,
                mobile: values.mobile,
                role_id: values.role_id,
                ref_code: values.referal_code,
                action:
                    <div key={i} style={{ marginLeft: '-5px' }}>
                        <Tooltip title="Edit Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleEdit(values)}>
                                <i className="fas fa-edit" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={manualentry} onClick={() => handleEdit(values)} />

                        </Tooltip>
                        <Tooltip title="Delete Data" placement="bottom">
                            {/* <Button className="actionBtn" onClick={() => handleDelete(values)}>
                                <i className="fas fa-minus" style={{ fontSize: "15px" }} />
                            </Button> */}
                            <img className="actionBtn svgicon" alt='' src={deleterec} onClick={() => handleDelete(values)} />
                        </Tooltip>
                    </div>
            }
        })
    }

    const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [format, setFormat] = React.useState("");
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const closeSuccessToast = () => {
        setsavenextmessage(false)
      }

    const formRef = React.useRef()

    return (
        <Container maxWidth="lg">
          <Snackbar open={savenextmessage} style={{ marginTop: "5px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>
            <div className="mini-container" style={{ marginTop: 0, padding: '0px' }}>
                {loaderFlag == true && (
                    <div style={{ textAlign: 'center', marginTop: '15%' }}>
                        <Loader type={LoaderColor.type} color={LoaderColor.color} />
                    </div>
                )}
                {showWCAddForm == false && (
                    <div>
                        
                        <div className="content-header">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <Typography
                                        text="User Management"
                                        styleType="C"
                                    />
                                </Col>
                                {/* <Col md={{ span: 2 }} >
                                    <button className="btn btn-primary adminAddBtn" onClick={() => handleOpenWCAddForm()} >
                                        Add
                                                 </button>
                                </Col> */}
                                <Col md={{ span: 2 }}>
                                    <span className="image">
                                        <img className='navbutton backbutton' alt='' src={blueadd} onClick={() => handleOpenWCAddForm()} />
                                        <Tooltip title="Add" arrow>
                                            <img className='navgraybutton backbutton' alt='' src={grayadd} onClick={() => handleOpenWCAddForm()} />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <Formik>
                            <form >
                                <Row>
                                    <Col md={{ span: 3 }}>
                                        {/* <Typogr aphy text="Role " styleType="B" /> */}
                                        <List component="div" disablePadding>
                                        <ListItem>
                                            <Dropdown placeholder="Search by Role" 
                                                id="role_id"
                                                name="role_id" 
                                                fluid 
                                                search 
                                                selection
                                                value={searchRole}
                                                onChange={(e, { value }) => { //setvalue(value);
                                                    setSearchRoleField(value);
                                                }}
                                                options={roles}
                                            />
                                        </ListItem>
                                        </List>
                                    </Col>
                                    <Col md={{ span: 3 }}>
                                        <List component="div" disablePadding>
                                            <ListItem>
                                                <TextField
                                                    id="filled-error-helper-text standard-basic"
                                                    name="name"
                                                    label="Search by Name"
                                                    onChange={handleNameChange}
                                                    onBlur={props.handleBlur}
                                                    value={searchName}
                                                />
                                            </ListItem>
                                        </List>
                                    </Col>
                                    {/* <Col md={{ span: 2 }}>
                                        <List component="div" disablePadding>
                                            <ListItem>
                                                <TextField
                                                    id="filled-error-helper-text standard-basic"
                                                    name="employee_code"
                                                    label="Search by Employee Code"
                                                    onChange={handleCodeChange}
                                                    onBlur={props.handleBlur}
                                                    // value={props.values.employee_code}
                                                />
                                            </ListItem>
                                        </List>
                                    </Col>
                                    <Col md={{ span: 2 }}>
                                        <List component="div" disablePadding>
                                            <ListItem>
                                                <TextField
                                                    id="filled-error-helper-text standard-basic"
                                                    name="phone"
                                                    label="Search by Phone"
                                                    onChange={handlePhoneChange}
                                                    onBlur={props.handleBlur}
                                                    // value={props.values.phone}
                                                />
                                            </ListItem>
                                        </List>
                                    </Col> */}
                                    <Col md={{ span: 2 }}>
                                        <Button className="updatebtn" variant="contained" color="primary" type="button" onClick={searchUser}>Search</Button>
                                        <Button style={{marginLeft: "5px"}} className="resetbtn" variant="contained" color="" type="button" onClick={resetUserSearch}>Reset</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Formik>
                        <Row className="datable" style={{ marginTop: '30px', }} >

                        <Col md={{ span: 10 }}>
                                {/* <Typography
                                    text="Users List"
                                    styleType="C"
                                /> */}
                            </Col>
                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                    <MDBDataTableV5
                                        rowHeight={100}
                                        hover
                                        entriesOptions={[5, 10, 20, 25]}
                                        entries={10}
                                        pagesAmount={4}
                                        data={data}
                                        searchTop

                                    />
                                </div>
                            </Col>

                            {/* <Col md={{ span: 10 }}>
                                <Typography
                                    text="SCA(Senior Credit Analyst)"
                                    styleType="C"
                                />
                            </Col>
                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 10, 20, 25]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={scadata}
                                        searchTop

                                    />
                                </div>
                            </Col> */}
                            {/* <Col md={{ span: 10 }}>
                                <Typography
                                    text="Admin List"
                                    styleType="C"
                                />
                            </Col>
                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 10, 20, 25]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={adminData}
                                        searchTop

                                    />
                                </div>
                            </Col> */}
                              

                            {/* <Col md={{ span: 10 }}>
                                <Typography
                                    text="Banker List"
                                    styleType="C"
                                />
                            </Col>
                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 10, 20, 25]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={bankerdata}
                                        searchTop

                                    />
                                </div>
                            </Col> */}
                          
                            {/* <Col md={{ span: 10 }}>
                                <Typography
                                    text="DSA List"
                                    styleType="C"
                                />
                            </Col>
                            <Col md={{ span: 12 }}>
                                <div component={Paper}>
                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 10, 20, 25]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={dsadata}
                                        searchTop

                                    />
                                </div>
                            </Col> */}




                            {/* <Col md={{ span: 10 }}>
                            <Typography
                                text="Document Collectors"
                                styleType="C"
                            />
                        </Col>
                        <Col md={{ span: 12 }}>
                            <div component={Paper}>
                                <MDBDataTableV5
                                    hover
                                    entriesOptions={[5, 10, 20, 25]}
                                    entries={5}
                                    pagesAmount={4}
                                    data={dcdata}
                                    searchTop

                                />
                            </div>
                        </Col> */}

                        </Row>


                        {/* <div className='btn_row_fixd'>
                            <Row>
                                <Col md={{ span: 10 }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                <Col style={{ padding: "0%" }}></Col>
                                <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                        <img className='navbutton' src={Back} onClick={handledata} />
                                    </Tooltip>
                                </Col>

                                <Col style={{ padding: "0%" }}>
                                    <Tooltip title="Save as Draft & Next" arrow>
                                        <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div> */}
                    </div>
                )}
                <Row>
                    <Col md={{ span: 12 }}>
                        {showWCAddForm == true && (
                            <Formik
                                enableReinitialize
                                initialValues={initialValue}
                                validationSchema={userCreationSchema}
                                onSubmit={handlesubmitform}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    resetForm,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldTouched
                                    /* and other goodies */
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="content-header" style={{ position: 'unset' }}>
                                                <Row>
                                                    <Col md={{ span: 10 }} className="mainheader1">
                                                        <Typography text="User Creation" styleType="C" />
                                                    </Col>
                                                    <Col md={{ span: 2 }} className="mainHeaderBack">
                                                        {/* <div className="content-header-button ">
                                                            <Button className="backBtn" variant="outlined" color="primary"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} >
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back</Button>
                                                        </div> */}
                                                        <span className="image">
                                                            <img className='backbutton navbutton' src={Back} onClick={() => {
                                                                resetForm();
                                                                setShowWCAddForm(false);
                                                                setShowList(true);
                                                            }} alt="back"
                                                            />
                                                            <Tooltip title="Back" arrow>
                                                                <img alt="back2" className='backbutton navgraybutton' src={grayback} onClick={() => {
                                                                    resetForm();
                                                                    setShowWCAddForm(false);
                                                                    setShowList(true);
                                                                }} />
                                                            </Tooltip>
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginTop: '30px' }}>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Role " styleType="B" />
                                                        <List component="div" disablePadding>
                                                        <ListItem>
                                                            <Dropdown placeholder="Select Role" 
                                                                id="role_id"
                                                                name="role_id" 
                                                                style={{width: "90%"}}
                                                                fluid 
                                                                search 
                                                                selection
                                                                value={values.role_id}
                                                                onBlur={() => setFieldTouched("sortorder", true) }

                                                                onChange={(e, { value }) => { //setvalue(value);
                                                                    console.log("\n \n values roler0", value)
                                                                    setFieldValue('role_id', value);
                                                                }}
                                                                options={roles}
                                                            />
                                                        </ListItem>
                                                        
                                                        {errors.role_id ? (
                                                                <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.role_id}</p>
                                                            ):''}
                                                        </List>
                                                    </Col>
                                                </Row>


                                                <Row style={{ marginTop: '30px' }}>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="name"
                                                                    label="Enter Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.name &&
                                                                            touched.name &&
                                                                            errors.name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.name ? classes.manorma : null,
                                                                    }}
                                                                    value={values.name}
                                                                    helperText={
                                                                        errors.name &&
                                                                        touched.name &&
                                                                        errors.name
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Phone" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="mobile"
                                                                    label="Enter Phone"
                                                                    onChange={(e) => {
                                                                        setmobileerr(null)
                                                                        handleChange(e)
                                                                    }}
                                                                    // onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.mobile &&
                                                                            touched.mobile &&
                                                                            errors.mobile
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.mobile ? classes.manorma : null,
                                                                    }}
                                                                    value={values.mobile}
                                                                    helperText={
                                                                        errors.mobile &&
                                                                        touched.mobile &&
                                                                        errors.mobile
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{mobileerr}</p>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Email" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="email"
                                                                    label="Enter email"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.email &&
                                                                            touched.email &&
                                                                            errors.email
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.email ? classes.manorma : null,
                                                                    }}
                                                                    value={values.email}
                                                                    helperText={
                                                                        errors.email &&
                                                                        touched.email &&
                                                                        errors.email
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{emailexisterr}</p>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="PAN" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="pan"
                                                                    label="Enter Pan"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.pan &&
                                                                            touched.pan &&
                                                                            errors.pan
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.pan ? classes.manorma : null,
                                                                    }}
                                                                    value={values.pan}
                                                                    helperText={
                                                                        errors.pan &&
                                                                        touched.pan &&
                                                                        errors.pan
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    {/* <Col md={{ span: 6 }}>
                                                        <Typography text="Username for Profile" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="username"
                                                                    label="Enter Username"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.username &&
                                                                            touched.username &&
                                                                            errors.username
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.username ? classes.manorma : null,
                                                                    }}
                                                                    value={values.username}
                                                                    helperText={
                                                                        errors.username &&
                                                                        touched.username &&
                                                                        errors.username
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Designation" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="designation"
                                                                    label="Enter designation"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.designation &&
                                                                            touched.designation &&
                                                                            errors.designation
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.designation ? classes.manorma : null,
                                                                    }}
                                                                    value={values.designation}
                                                                    helperText={
                                                                        errors.designation &&
                                                                        touched.designation &&
                                                                        errors.designation
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col> */}
                                                    <Col md={{ span: 6 }}>
                                                        <Row>
                                                            <Col md={{ span: 11 }}>
                                                                <Typography text="Address" styleType="B" />
                                                                <List component="div" style={{maxWidth: '90% !important'}}>
                                                                    <ListItem >
                                                                        <TextArea
                                                                            id="filled-error-helper-text standard-basic"
                                                                            name="address"
                                                                            rows={4}
                                                                            style={{maxWidth: '90% !important'}}
                                                                            placeholder="Enter Address"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            error={
                                                                                errors.address &&
                                                                                    touched.address &&
                                                                                    errors.address
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputProps={{
                                                                                className: values.address ? classes.manorma : null,
                                                                            }}
                                                                            value={values.address}
                                                                            helperText={
                                                                                errors.address &&
                                                                                touched.address &&
                                                                                errors.address
                                                                            }
                                                                            className={errors.address && touched.address ? classes.required : values.address == '' ? classes.reqired : classes.man}
                                                                        />
                                                                    </ListItem>
                                                                    {errors.address && touched.address? (
                                                                    <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.address}</p>):''}
                                                                </List>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    
                                                    {values.role_id != 1? (<Col md={{ span: 6 }}>
                                                        <Row>
                                                            <Col md={{ span: 11 }}>
                                                                <Typography text="Pincode" styleType="B" />
                                                                <span className="image">
                                                                    <img className='navbutton backbutton' style={{  height: '30px', width:'30px'}} alt='' src={blueadd} onClick={() => handleOpenModel()} />
                                                                    <Tooltip title="Add" arrow>
                                                                        <img className='navgraybutton backbutton' alt='' style={{  height: '30px', width:'30px'}} src={grayadd} onClick={() => handleOpenModel()} />
                                                                    </Tooltip>
                                                                </span>
                                                                <List component="div" disablePadding>
                                                                    <ListItem style={{border: '1px solid #ced4da !important', borderRadius: '0.25rem !important'}}>
                                                                        <Dropdown placeholder="Select Pincode" 
                                                                            id="pincode"
                                                                            style={{height: "134px", border: '1px solid #ced4da !important', borderRadius: '0.25rem !important'}}
                                                                            name="pincode" 
                                                                            fluid 
                                                                            selection
                                                                            multiple={true}
                                                                            required
                                                                            value={mainPincode}
                                                                            onBlur={() => {
                                                                                setFieldTouched('pincode')}
                                                                            }
                                                                            onChange={(e, { value }) => {
                                                                                setFieldValue('pincode', value);
                                                                                setMainPincode(value);
                                                                                const pinArrays = []
                                                                                value.map((item)=> {
                                                                                    pinArrays.push({
                                                                                        key: item,
                                                                                        value: item,
                                                                                        text: item
                                                                                    })
                                                                                })
                                                                                setNoPincode([]);
                                                                                setNoPincode(pinArrays);
                                                                            }}
                                                                            options={nopincode}
                                                                            // className={errors.pincode && touched.pincode ? classes.required : mainPincode.length === 0 ? classes.reqired : classes.man}
                                                                            // className={mainPincode.length === 0? classes.reqired : classes.man}
                                                                            className={!pinerr ? classes.reqired : classes.man}
                                                                        />
                                                                    </ListItem>
                                                                    <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{pinerr}</p>
                                                                </List>
                                                            </Col>
                                                            {/* <Col md={{ span: 3 }} > */}
                                                                
                                                            {/* </Col> */}
                                                        </Row>
                                                        {/* <img style={{ height: "30px", width: "30px", marginTop: "-45px", marginRight: "30px" }} className='navbutton backbutton' alt='' src={blueadd} onClick={() => {
                                                            setOpenPincodeModel(true);
                                                        }} /> */}
                                                    </Col>) : null}
                                                    
                                                    {values.role_id == 12 || values.role_id == 3 || values.role_id == 1 || values.role_id == 16 || values.role_id == 9? (<Col md={{ span: 6 }}>
                                                        <Typography text="Employee Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="employee_code"
                                                                    label="Enter Employee Code"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.employee_code &&
                                                                            touched.employee_code &&
                                                                            errors.employee_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.employee_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.employee_code}
                                                                    helperText={
                                                                        errors.employee_code &&
                                                                        touched.employee_code &&
                                                                        errors.employee_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {values.role_id == 3 || values.role_id == 1 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Analyst Type" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <Dropdown
                                                                        placeholder="Select"
                                                                        id="analyst_type"
                                                                        name="analyst_type"
                                                                        style={{width: "90%"}}
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        value={values.analyst_type}
                                                                        onBlur={() => setFieldTouched("analyst_type", true)}
                                                                        onChange={(e, { value }) => setFieldValue("analyst_type", value)}
                                                                        options={analyst_type_option}
                                                                        className={errors.analyst_type && touched.analyst_type ? classes.required : values.analyst_type == '' ? classes.reqired : classes.man}
                                                                    />
                                                                </ListItem>
                                                                {errors.analyst_type && touched.analyst_type && values.analyst_type === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.analyst_type}</p>
                                                                                        )}
                                                            </List>
                                                    </Col>) : null}

                                                    {values.role_id == 1 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Analyst Privilege" styleType="B" />
                                                            <List component="div" disablePadding>
                                                                <ListItem>
                                                                    <Dropdown
                                                                        placeholder="Select"
                                                                        id="analyst_privilege"
                                                                        style={{width: "90%"}}
                                                                        name="analyst_privilege"
                                                                        fluid
                                                                        search
                                                                        selection
                                                                        value={values.analyst_privilege}
                                                                        onBlur={() => setFieldTouched("analyst_privilege", true)}
                                                                        onChange={(e, { value }) => setFieldValue("analyst_privilege", value)}
                                                                        options={analyst_privilege_option}
                                                                        className={errors.analyst_privilege && touched.analyst_privilege ? classes.required : values.analyst_privilege == '' ? classes.reqired : classes.man}
                                                                    />
                                                                </ListItem>
                                                                {errors.analyst_privilege && touched.analyst_privilege && values.analyst_privilege === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.analyst_privilege}</p>
                                                                                        )}
                                                            </List>
                                                    </Col>) : null}
                                                    
                                                    {values.role_id == 3 ? (
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Reporting Manager" styleType="B" />
                                                        <List component="div" disablePadding>
                                                        <ListItem>
                                                            <Dropdown placeholder="Select Reporting Manager" 
                                                                id="reporting_manager_id"
                                                                name="reporting_manager_id" 
                                                                fluid 
                                                                search 
                                                                style={{width: "90%"}}
                                                                selection
                                                                value={values.reporting_manager_id}
                                                                onBlur={() => setFieldTouched("sortorder", true) }

                                                                onChange={(e, { value }) => { //setvalue(value);
                                                                    setFieldValue('reporting_manager_id', value);
                                                                }}
                                                                options={reportingManager}
                                                            />
                                                        </ListItem>
                                                        {errors.reporting_manager_id && touched.reporting_manager_id && values.reporting_manager_id === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.reporting_manager_id}</p>
                                                                                        )}
                                                        </List>
                                                    </Col> ): null}

                                                    {values.role_id == 1 || values.role_id == 16 || values.role_id == 9 ? (
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="List of SCA" styleType="B" />
                                                        <List component="div" disablePadding>
                                                        <ListItem>
                                                            <Dropdown placeholder="Select Reporting Manager" 
                                                                id="sca_reporting_manager_id"
                                                                name="sca_reporting_manager_id" 
                                                                fluid 
                                                                search 
                                                                style={{width: "90%"}}
                                                                selection
                                                                value={values.sca_reporting_manager_id}
                                                                onBlur={() => setFieldTouched("sortorder", true) }

                                                                onChange={(e, { value }) => { //setvalue(value);
                                                                    setFieldValue('sca_reporting_manager_id', value);
                                                                }}
                                                                options={scaReportingManager}
                                                            />
                                                        </ListItem>
                                                        {errors.sca_reporting_manager_id && touched.sca_reporting_manager_id && values.sca_reporting_manager_id === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.sca_reporting_manager_id}</p>
                                                                                        )}
                                                        </List>
                                                    </Col> ): null}

                                                    {values.role_id == 16 || values.role_id == 9? (<Col md={{ span: 6 }}>
                                                        <Typography text="Incentives" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="incentives"
                                                                    label="Enter Incentives"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.incentives &&
                                                                            touched.incentives &&
                                                                            errors.incentives
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.incentives ? classes.manorma : null,
                                                                    }}
                                                                    value={values.incentives}
                                                                    helperText={
                                                                        errors.incentives &&
                                                                        touched.incentives &&
                                                                        errors.incentives
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {values.role_id == 6 || values.role_id == 11? (<Col md={{ span: 6 }}>
                                                        <Typography text="Commission / Brokerage" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="commission"
                                                                    label="Enter Commission / Brokerage"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.commission &&
                                                                            touched.commission &&
                                                                            errors.commission
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.commission ? classes.manorma : null,
                                                                    }}
                                                                    value={values.commission}
                                                                    helperText={
                                                                        errors.commission &&
                                                                        touched.commission &&
                                                                        errors.commission
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {values.role_id == 6 || values.role_id == 11 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Business Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="business_code"
                                                                    label="Enter Business Code"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.business_code &&
                                                                            touched.business_code &&
                                                                            errors.business_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.business_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.business_code}
                                                                    helperText={
                                                                        errors.business_code &&
                                                                        touched.business_code &&
                                                                        errors.business_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {values.role_id == 6 || values.role_id == 11 ? (
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Mapped to Sales Manager" styleType="B" />
                                                        <List component="div" disablePadding>
                                                        <ListItem>
                                                            <Dropdown placeholder="Select Sales Manager" 
                                                                id="sm_reporting_manager_id"
                                                                name="sm_reporting_manager_id" 
                                                                fluid 
                                                                style={{width: "90%"}}
                                                                search 
                                                                selection
                                                                value={values.sm_reporting_manager_id}
                                                                onBlur={() => setFieldTouched("sortorder", true) }

                                                                onChange={(e, { value }) => { //setvalue(value);
                                                                    setFieldValue('sm_reporting_manager_id', value);
                                                                }}
                                                                options={smReportingManager}
                                                            />
                                                        </ListItem>
                                                        {errors.sm_reporting_manager_id && touched.sm_reporting_manager_id && values.sm_reporting_manager_id === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.sm_reporting_manager_id}</p>
                                                                                        )}
                                                        </List>
                                                    </Col> ): null}

                                                    {values.role_id == 6 || values.role_id == 11 ? (
                                                    <Col md={{ span: 6 }}>
                                                        <Typography text="Referred by Business Partner" styleType="B" />
                                                        <List component="div" disablePadding>
                                                        <ListItem>
                                                            <Dropdown placeholder="Select Business Partner" 
                                                                id="bp_reporting_manager_id"
                                                                name="bp_reporting_manager_id" 
                                                                fluid 
                                                                style={{width: "90%"}}
                                                                search 
                                                                selection
                                                                value={values.bp_reporting_manager_id}
                                                                onBlur={() => setFieldTouched("sortorder", true) }

                                                                onChange={(e, { value }) => { //setvalue(value);
                                                                    setFieldValue('bp_reporting_manager_id', value);
                                                                }}
                                                                options={bpReportingManager}
                                                            />
                                                        </ListItem>
                                                        {errors.bp_reporting_manager_id && touched.bp_reporting_manager_id && values.bp_reporting_manager_id === '' && (
                                                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.bp_reporting_manager_id}</p>
                                                                                        )}
                                                        </List>
                                                    </Col> ): null}

                                                    {values.role_id == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Select Bank Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Bank Name"
                                                                    id="bank_id"
                                                                    style={{width: "90%"}}
                                                                    name="bank_id"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.bank_id}
                                                                    onBlur={() => setFieldTouched("bank_id", true)}
                                                                    onChange={(e, { value }) => {
                                                                        setFieldValue("bank_id", value)
                                                                    }}
                                                                    options={bank}
                                                                    className={errors.bank_id && touched.bank_id ? classes.required : values.bank_id == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.bank_id && touched.bank_id && values.bank_id == '' && (
                                                            <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{errors.bank_id}</p>
                                                        )}
                                                    </Col>
                                                    ) : null}

                                                    {values.role_id == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Branch Name" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="branch_name"
                                                                    label="Enter Branch Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.branch_name &&
                                                                            touched.branch_name &&
                                                                            errors.branch_name
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.branch_name ? classes.manorma : null,
                                                                    }}
                                                                    value={values.branch_name}
                                                                    helperText={
                                                                        errors.branch_name &&
                                                                        touched.branch_name &&
                                                                        errors.branch_name
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {/* {values.enable == 6 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Referral Code" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="ref_code"
                                                                    label="Enter Referral Code"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        errors.ref_code &&
                                                                            touched.ref_code &&
                                                                            errors.ref_code
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.ref_code ? classes.manorma : null,
                                                                    }}
                                                                    value={values.ref_code}
                                                                    helperText={
                                                                        errors.ref_code &&
                                                                        touched.ref_code &&
                                                                        errors.ref_code
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : null}

                                                    {values.enable == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Loan Type" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder='Loan Type'
                                                                    id='type'
                                                                    name='type'
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.loantype}
                                                                    onBlur={() => setFieldTouched("type", true)}
                                                                    onChange={(e, { value }) => {
                                                                        console.log(value);
                                                                        setFieldValue("type", value)
                                                                    }}
                                                                    options={loantype}
                                                                // onChange = {() => loanTypeSelection(value)}
                                                                // className={errors.type && touched.type ? classes.required : values.type == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.banktypes && touched.banktypes && values.banktypes == '' && (
                                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.banktypes}</div>
                                                        )}
                                                    </Col>
                                                    ) : (null)}

                                                    {values.enable == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography
                                                            text="Pincode"
                                                            styleType="B"
                                                        />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <TextField
                                                                    id="filled-error-helper-text standard-basic"
                                                                    name="pincode"
                                                                    type="number"
                                                                    placeholder=""
                                                                    onChange={handleChange}

                                                                    onBlur={(e) => {
                                                                        handleBlur(e);
                                                                        if (!errors.pincode) {
                                                                            getPincodeData(
                                                                                values.pincode
                                                                            );
                                                                        }
                                                                    }}
                                                                    error={
                                                                        errors.pincode &&
                                                                            touched.pincode &&
                                                                            errors.pincode
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    InputProps={{
                                                                        className: values.pincode
                                                                            ? classes.manorma
                                                                            : null,
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength: 6,
                                                                    }}
                                                                    value={values.pincode}
                                                                    helperText={
                                                                        errors.pincode &&
                                                                        touched.pincode &&
                                                                        errors.pincode
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : (null)}


                                                    {values.enable == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Area" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Select Area"
                                                                    id="area"
                                                                    name="area"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    style={{ marginTop: "-8px" }}
                                                                    value={values.area || ""}
                                                                    onBlur={() =>
                                                                        setFieldTouched("area", true)
                                                                    }
                                                                    onChange={(e, { value }) =>
                                                                        setFieldValue("area", value)
                                                                    }
                                                                    options={area}
                                                                    className={
                                                                        errors.area && touched.area
                                                                            ? classes.required
                                                                            : values.area == ""
                                                                                ? classes.reqired
                                                                                : classes.man
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Col>) : (null)}

                                                    {values.enable == 5 ? (<Col md={{ span: 6 }}>
                                                        <Typography text="Select Bank Type" styleType="B" />
                                                        <List component="div" disablePadding>
                                                            <ListItem>
                                                                <Dropdown
                                                                    placeholder="Bank Type"
                                                                    id="bank"
                                                                    name="bank"
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    value={values.bank}
                                                                    onBlur={() => setFieldTouched("bank", true)}
                                                                    onChange={(e, { value }) => {
                                                                        setFieldValue("bank", value)
                                                                    }}
                                                                    options={bank}
                                                                    className={errors.banktypes && touched.banktypes ? classes.required : values.banktypes == '' ? classes.reqired : classes.man}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        {errors.banktypes && touched.banktypes && values.banktypes == '' && (
                                                            <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.banktypes}</div>
                                                        )}
                                                    </Col>
                                                    ) : (null)} */}

                                                    {/* <Col md={{ span: 6 }}>
                                                        <div style={{ marginTop: '20px' }}>
                                                            <Typography text="Role" styleType="B" />

                                                        </div>
                                                        <List component="div" disablePadding>
                                                            <ListItem >

                                                                <label>
                                                                    Cpa
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="1"
                                                                        checked={values.enable == '1'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    SCA
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="3"
                                                                        checked={values.enable == '3'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    Admin
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="4"
                                                                        checked={values.enable == '4'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    Banker
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="5"
                                                                        checked={values.enable == '5'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    DSA/Partner
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="6"
                                                                        checked={values.enable == '6'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>
                                                                <div style={{ width: '18px' }}></div>
                                                                <label>
                                                                    Document Collector
                                                                    <input
                                                                        name="enable"
                                                                        type="radio"
                                                                        value="7"
                                                                        checked={values.enable == '7'}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </label>


                                                            </ListItem>
                                                            {errors.enable && touched.enable && <div style={{ color: "red", marginLeft: 14, fontSize: 12, fontWeight: '600' }}>{errors.enable}</div>}
                                                        </List>
                                                    </Col> */}
                                                   
                                                </Row>
                                                <Col md={{ span: 12 }}>
                                                    <Row><Col md={{ span: 11 }}></Col>
                                                        <Col md={{ span: 1 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>{addFormButtonValue}</Button>
                                                        </Col>
                                                        <Col md={{ span: 5 }}></Col>
                                                    </Row>
                                                </Col>

                                                
                                            </div>
                                            
                                        </form>
                                    );
                                }}
                            </Formik>
                        )}
                    <Modal
                        show={openStatus}
                        animation={false}
                        centered
                        style={{ height: '550px', marginTop: "100px", marginLeft: "auto", marginRight: "auto" }}
                    >
                        <Modal.Header
                        onClick={handleClose}
                        style={{ background: '#7254a3', borderBottom: '1px solid #7254a3' }}
                        ><Modal.Title className="modalHeader cpModal" style={{ color: 'white', padding: "6px"}}>Add Pincode</Modal.Title>
                        
                        <IconButton
                            style={{ color: 'white' }}
                            aria-label='close'
                            className={classes.closeButton}
                            onClick={handleClose}
                        ><CloseIcon />
                            {/* <CloseIcon style={{marginTop:"-10px",color:"white",fontWeight:"800",cursor:"pointer"}}/> */}
                        </IconButton>
                        </Modal.Header>
                        <Modal.Body>
                        {/* <Row> */}
                        <Formik
                            enableReinitialize
                            initialValues={initialValue}
                            validationSchema={pinCreationSchema}
                            onSubmit={handlePincodeform}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                resetForm,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched
                                /* and other goodies */
                            }) => {

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                        <Col md={{ span: 10 }}>
                                            <Typography text="City" styleType="B" />
                                            <List component="div" disablePadding>
                                                <ListItem>
                                                    <Dropdown placeholder="Select City" 
                                                        id="city_id"
                                                        name="city_id" 
                                                        fluid 
                                                        search 
                                                        selection
                                                        value={values.city_id}
                                                        onBlur={() => setFieldTouched("sortorder", true) }

                                                        onChange={(e, { value }) => { //setvalue(value);
                                                            console.log(value)
                                                            setCityField(value);
                                                            getAllPinCode(value);
                                                            setCityError(null)
                                                        }}
                                                        options={cityOption}
                                                    />
                                                </ListItem>
                                                {cityError && (
                                                                                    <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{cityError}</p>
                                                                                )}
                                            </List>
                                        </Col>
                                        <Col md={{ span: 10 }}>
                                            <div>
                                                <Typography text="Type" styleType="B" />

                                            </div>
                                            <List component="div" disablePadding>
                                                <ListItem >

                                                    <div style={{ width: '18px' }}></div>
                                                    <label>
                                                        
                                                        <input
                                                            name="pincode_type"
                                                            type="radio"
                                                            value="Single"
                                                            defaultChecked
                                                            style={{marginRight: "10px"}}
                                                            // checked={setType == 'Single'}
                                                            onChange={(e) => {
                                                                handleTypeChange(e);
                                                                setTypeError(null);
                                                                setAddPincodeField(null);
                                                            }}
                                                            // onChange={handleTypeChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        Single
                                                    </label>
                                                    <div style={{ width: '18px' }}></div>
                                                    <label>
                                                        
                                                        <input
                                                            name="pincode_type"
                                                            type="radio"
                                                            value="Range"
                                                            style={{marginRight: "10px"}}
                                                            checked={setType == 'Range'}
                                                            onChange={(e) => {
                                                                handleTypeChange(e);
                                                                setTypeError(null);
                                                                setAddPincodeField(null);
                                                            }}
                                                            // onChange={handleTypeChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        Range
                                                    </label>
                                                </ListItem>
                                                {typeError && (
                                                    <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{typeError}</p>
                                                )}
                                            </List>
                                        </Col>
                                        <Col md={{ span: 10 }}>
                                            <Typography text="Pincode" styleType="B" />
                                            <List component="div" disablePadding>
                                                <ListItem>
                                                    <Dropdown placeholder="Select Pincode" 
                                                        id="filtered_pincode"
                                                        name="filtered_pincode" 
                                                        fluid 
                                                        search 
                                                        selection
                                                        clearable={true}
                                                        options={pincode}
                                                        multiple={true}
                                                        value={setAddPincode}
                                                        onBlur={() => setFieldTouched("sortorder", true) }

                                                        onChange={(e, { value }) => { //setvalue(value);
                                                            if(!(setType == 'Range' && setAddPincode && setAddPincode.length == 2 )){
                                                                setPincodeError(null);
                                                                setAddPincodeField(value);
                                                            }
                                                            
                                                            
                                                        }}
                                                    />
                                                </ListItem>
                                                {pincodeError && (
                                                    <p style={{color: "red", margin: 0, fontSize: '0.75rem', marginTop: '3px', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', fontWeight: 400, lineHeight: '1.66', letterSpacing: '0.03333em'}}>{pincodeError}</p>
                                                )}
                                            </List>
                                        </Col>
                                        <Col md={{ span: 12 }}>
                                            <Row style={{marginTop: '30px' }}><Col md={{ span: 10 }}></Col>
                                                <Col md={{ span: 2 }}><Button className="pincodeBtn" variant="outlined" color="primary" type="button" onClick={addPincode}>Add</Button>
                                                </Col>
                                                <Col md={{ span: 5 }}></Col>
                                            </Row>
                                        </Col>
                                        </Row>

                                    </form>
                                )
                            }}
                        </Formik>
                        {/* </Row> */}
                        </Modal.Body>
                    </Modal>
                    </Col>

                </Row>
            </div>
        </Container>
    );
}