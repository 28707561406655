
import React from "react";
import { loadCSS } from "fg-loadcss";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TablePagination from '@material-ui/core/TablePagination';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Dropdown } from "semantic-ui-react";
import { useStyle } from "./styles";
import clsx from "clsx";
import { Formik } from "formik";
import { bankFormschema } from "../../shared/validations";
import Modal from "react-bootstrap/Modal";
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "../../base_components/Typography";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Alert, AlertTitle } from '@material-ui/lab';

import editrec from '../../image/pngicons/edit.png';
import uploaddoc from '../../image/pngicons/upload.png';
import addbusiness from '../../image/pngicons/add.png';
import deleterec from '../../image/pngicons/delete.png';
import { withRouter } from 'react-router-dom';
import ProgressBar from "react-bootstrap/ProgressBar";
import { apiCalling } from '../../shared/constants';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';

import Tabs from '@material-ui/core/Tabs';

//import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';


function CpaMainPage(props, { history }) {
    //  //
    const [navTabState, setnavTabState] = React.useState(false);
    const classes = useStyle();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [selectedValue, setSelectedValue] = React.useState("a");
    const [show, setshow] = React.useState(false);
    const [shows, setdigital] = React.useState(false);
    const [disp, setdisp] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openLoan, setOpenLoan] = React.useState(false);
    const [showform, setshowform] = React.useState(false);
    const [startTab, setStartTab] = React.useState();
    const [topform, settopform] = React.useState(true);
    const [tableopen, settableopen] = React.useState(false);
    const [table, settable] = React.useState(false);
    const [data, setdata] = React.useState([]);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [now, setnow] = React.useState(50);
    const [modelbank, setmodelbank] = React.useState(false);
    const [busIdForDel, setbusIdForDel] = React.useState();


    const [openDialog, setOpenDialog] = React.useState(false);


    const [loaderFlag, setLoaderFlag] = React.useState(true);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));

    const BPOptions = [
        'Business',
        'Promoter'
    ];

    const [showmodal, setshowmodal] = React.useState(false);
    const handleclosePdf = () => {
        setshowmodal(false)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);



    const ApplicationOptions = [
        'Loan Application',
        'Check Eligibility'
    ];

    const [appAnchorEl, setAppAnchorEl] = React.useState(null);



    React.useEffect(() => {
        loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            document.querySelector("#font-awesome-css")
        );
        if (localStorage.getItem('businessflag')) {
            props.handleNext();
        }
        getUserId();
        getCPAData();
        getAllBusinessbyuser();
        localStorage.removeItem("rquestIdDetails");
        localStorage.removeItem("RequestType");
        // localStorage.removeItem("handleFlow");
        getLoanStatusPercentage();
        localStorage.setItem("check", JSON.stringify("Loan Application"));
        localStorage.setItem("RequestType", 0);
    }, []);
    const checkuserloggeddata = localStorage.getItem("userdata");
    const [userBusinessList, setUserBusinessList] = React.useState([]);
    let iduser = JSON.parse(checkuserloggeddata);


    ///getBusiness and shareholder details based on user detail


    const getAllBusinessAndPromoter = async () => {
        const checkuserloggeddata = localStorage.getItem("userdata");
        let iduser = JSON.parse(checkuserloggeddata);
        console.log("user logged data of userid", iduser.id);
        // setidofuser(iduser.id);
        let businessArray = [];
        let promoterArray = [];
        let data = {
            user_id: iduser.id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getBusinessAndPromoterByUserId`, data).then(
            (result) => {

                if (result.data.data.businessList.length > 0) {
                    setshowform(false);
                    setStartTab(true);
                    setBsId(result.data.data.businessList[0].id);
                    result.data.data.businessList.map((item, i) => {
                        businessArray.push({
                            key: item.id,
                            id: item.id,
                            value: item.id,
                            text: item.name,
                            name: item.name,
                            entity_type: item.entity_type,
                            bpan: item.bpan,
                            gst: item.gst,
                            state_name: item.state_name,
                            city_name: item.city_name,
                            industry_group_name: item.industry_group_name
                        })
                    });
                } else {
                    setStartTab(false);
                    setshowform(true)

                }
                // setUserPromoterList(promoterArray);
                setUserBusinessList(businessArray);
                // getAllLoansDetailsByUserId(businessArray[0].id)
                console.log(businessArray);
                console.log(promoterArray)
            }
        ).catch((error) => {
            console.log(error);
        })
    };

    /// Business Information data
    // getting user Id from local storage 
    const getUserId = () => {
        const checkuserloggeddata = localStorage.getItem("userdata");
        let iduser = JSON.parse(checkuserloggeddata);
        console.log("main page user details", iduser);
        getAllBusinessAndPromoter(iduser.id);
    }

    const checkeligibiltyBusAndProm = () => {
        localStorage.setItem("check", JSON.stringify("Check Eligibility"));
        props.handleNext();
    }

    const applyforloanBusAndPromo = () => {
        localStorage.setItem("check", JSON.stringify("Loan Application"));
        props.handleNext();
    }





    const handleNextClick = () => {
        const localStorageData = localStorage.getItem("banking"),
            bankdata = JSON.parse(localStorageData);
        setdata(bankdata);
        console.log("shareholder", bankdata);
        settopform(false);
        // setshowform(false)
        settable(true);
        settableopen(true);
        console.log("clicked next");
    };

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };

    // const handleSubmiting = () => {
    //     props.handleNext();
    // };
    // const handleSubmit = () => {
    //     setshow(false);
    //     setdigital(false);
    //     setdisp(true);
    // };
    // const handleSubmitdigital = () => {
    //     setdigital(true);
    //     setmodelbank(false);
    // };

    // const handleSubmited = () => {
    //     setshow(true);
    //     setmodelbank(false);
    // };

    // const handledata = () => {
    //     props.handleBack();
    // };

    // const toggleNavTab = () => {
    //     setnavTabState(!navTabState);
    // };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };



    // const SaveasDraft = () => {
    //     alert("Save As Draft");
    //     props.handleNext();
    // };

    // const hedaer1 = () => {
    //     return (
    //         <h1>hello</h1>
    //     )
    // }

    // const classes1 = useStyles();
    const [value, setValue] = React.useState('1');

    const handleChange1 = (event, newValue) => {
        setEditInitialValue({
            ...setEditInitialValue,
            company: bsid
        })
        setOpenTabBusPro(false);
        setValue(newValue);
    };

    const [value2, setValue2] = React.useState('1');
    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
        setOpenTab(false)
    };

    ///get business of the user based on his id
    // const [bsid, setBsId] = useState();
    const [businesslist, setbusinesslist] = React.useState([]);
    const [idofuser, setidofuser] = React.useState();
    const [userRole, setUserRole] = React.useState();
    const [bsid, setBsId] = React.useState();
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [selected, setSelected] = React.useState([]);
    const setBusinessIdForPro = (iddd) => {
        //  
        // setBusinessIdOfPromoter(iddd);
        getAllPromoterByBusinessId(iddd);
        getBusinessbybusinessId(iddd);
    }
    let checkusercpa = localStorage.getItem("userbycpa");
    const getAllBusinessbyuser = async (checkusercpaparam) => {
        //

        //  let iduser = JSON.parse(checkuserloggeddata);
        //console.log("user logged data of userid", iduser.id);
        const checkuserloggeddata = localStorage.getItem("userdata");
        let iduser = JSON.parse(checkuserloggeddata);

        setidofuser(checkusercpa);
        setUserRole(1);
        let arralen;
        const data = {
            user_id: iduser.id,
        };
        let busArr = [];
        await axios
            .post(
                apiCalling.coderscorner + `server.php/api/getBusinessByCPA`,
                data
            )
            .then((result) => {
                //alert('user')
                arralen = result.data.data.length;
                if (result.data.data.length > 0) {
                    setBsId(result.data.data[0].id);
                    getAllPromoterByBusinessId(result.data.data[0].id);
                    setbusIdForDel(result.data.data[0].id);
                    //  setBusinessIdForDropDown(result.data.data[0].id);
                    result.data.data.map((item) => {
                        busArr.push({
                            key: item.id,
                            id: item.id,
                            value: item.id,
                            text: item.name,
                            name: item.name,
                            entity_type: item.entity_type,
                            bpan: item.bpan,
                            gst: item.gst,
                            state_name: item.state_name,
                            city_name: item.city_name,
                            industry_group_name: item.industry_group_name,
                            user_id: item.user_id
                        });
                    });
                    console.log(result.data.data[0].id);
                    setbusinesslist(busArr);
                    setbusinesslist(busArr);
                    console.log("businessAll by cpa-------", busArr);
                    console.log(businesslist);
                    console.log(bsid);
                } else {
                    alert("please add ");
                    setshowform(true)

                }
            })
            .catch((error) => {
                console.log(error);
            });
        // business tab hidden if role_id = cpa
        getRequestData(arralen)
    };
    // set initialBus id
    const setBusId = (initialBusId) => {
    }

    const [promoterList, setPromoterList] = React.useState([]);
    const getAllPromoterByBusinessId = async (bid) => {
        //
        const data = {
            bus_id: bid,
        };
        let promoArr = [];
        await axios
            .post(
                apiCalling.coderscorner + `server.php/api/getAllpromoterinfobybusid`,
                data
            )
            .then((result) => {
                //
                settopform(true)
                console.log(result.data);
                result.data.data.map((item) => {
                    let typ = "";
                    if (item.type === 1111) {
                        typ = "Promoter";
                    } else if (item.type === 1233) {
                        typ = "Borrower";
                    }
                    promoArr.push({
                        key: item.promoter_id,
                        promoter_id: item.promoter_id,
                        name: item.first_name + " " + item.last_name,
                        type: typ,
                        email: item.email,
                        mobile: item.mobile,
                        share: item.share_percent,
                        value: item.promoter_id,
                        text: item.first_name,
                        eligibility_flag: item.eligibility_flag
                    });
                });
                setPromoterList(promoArr);
                setShowPromoterTable(true);
                //console.log("businessAll by id", promoArr);
               // console.log(promoterList);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get business by businessid
    const getBusinessbybusinessId = (bussinessid) => {
        //
        let getbusinesid = {
            "bus_id": bussinessid.bus_id
        }
        axios.post(apiCalling.coderscorner + `server.php/api/getbusinessInfoByid`, getbusinesid).then((res) => {
            console.log("yeh we have business data by busis", res.data);
            let editdata1 = res.data.data;
            let copdate = moment(editdata1.setup_date).format('YYYY-MM-DD');
        })
            .catch((er) => {
                console.log("no pincode sorry ", er);
            });
        getAllPromoterByBusinessId(bussinessid);
    }

    const getPromoterById = async (id) => {

        let data = {
            promo_or_shareholder_id: id,
        };

        await axios
            .post(
                apiCalling.coderscorner + `server.php/api/getshareholderorPromotorInfoByid`,
                data
            )
            .then((result) => {
                console.log(result.data.data);
                // setArea1(result.data.data[0].landmark)
                //   setloarOrCheck(editdata1.eligibility_flag);
                let editdata1 = result.data.data[0];
                let pdob = moment(editdata1.dob).format('YYYY-MM-DD');
                setPromoterInitialValue({
                    ...promoterInitialValue,
                    id: editdata1.id,
                    firstname: editdata1.first_name,
                    lastname: editdata1.last_name,
                    mobile: editdata1.mobile,
                    email: editdata1.email,
                    share: editdata1.share_percent,
                    type: parseInt(editdata1.type),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const setPromoterForPro = (value) => {
        getPromoterById(value);
    }

    const [initialValue, setEditInitialValue] = React.useState({
        company: bsid,
        businessName: "",
        date: "",
        gst: "",
        businesspan: "",
    });

    const [promoterInitialValue, setPromoterInitialValue] = React.useState({
        promoter: "",
        firstname: "",
        lastname: "",
        middlename: "",
        share: "",
        type: "",
        company: "",
        mobile: "",
        email: "",
    })

    const handleEditData = (businessId1) => {
        // return < BusinessInfo businessId1={businessId1} />;
        let i = 1;
        //  props.handleNext(i, businessId1);
        props.handleNext(businessId1, 0, 1);
    }

    const table_slNo = {
        fontWeight: "600",
        fontSize: "100%"
    }

    const table_header = {
        fontWeight: "600",
        fontSize: "100%",
        width: "auto"
    };

    const getPromoterById1 = (promoterID) => {
        props.handleNext(promoterID, 0, 2)
    }

    //Page Data
    const [page, setPage] = React.useState(0);
    const [buspage, setBusPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [applicationList, setApplicationList] = React.useState([]);
    const getRequestData = async (arralen) => {

        
        let data = {
            user_id: iduser.id
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getRequestdetailsByCPA`, data).then((result) => {
            console.log(result);
            if (result.data.status === true) {
                setApplicationList(result.data.data)
                setLoaderFlag(false);

            } else {
                setLoaderFlag(false);
                if (arralen > 0) {
                    setOpenDialog(true);
                    //setLoaderFlag(false);
                }
                setApplicationList([]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleChangePage = (event, newPage) => { setPage(newPage); };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const busHandleChangePage = (event, newPage) => {
        setBusPage(newPage);
    };
    const handleChangeRowsPerPagebus = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setBusPage(0);
    };


    const goToLetsGetStarted = (item) => {
        //
        localStorage.setItem('bankidforcppa', item.user_id);
        localStorage.setItem("rquestIdDetails", JSON.stringify(item));
        localStorage.setItem("handleFlow", "letget");
        if (item.request_type_id === 0) {
            localStorage.setItem('RequestType', 0);
        } else {
            localStorage.setItem('RequestType', 1);
        }
        props.handleNextLetStart();
    }

    const options = ['Loan Application', 'Check Eligibility'];
    const [openTab, setOpenTab] = React.useState(false);
    const anchorRef = React.useRef(null);
    const anchorRef1 = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    // const handleClickTab = () => {
    //     localStorage.setItem("check", JSON.stringify("Loan Application"));
    //     localStorage.setItem("RequestType", 0);
    //     localStorage.setItem("check", 0);
    //     localStorage.setItem("handleFlow", "letget")
    //     props.handleNext(3);
    //     console.info(`You clicked ${options[selectedIndex]}`);
    // };

    const [loanOrCheck, setLoanOrCheck] = React.useState();
    const handleMenuItemClick = (value) => {
        setLoanOrCheck(value);
        //const {value} = index.target;
        // console.log(event.target.value)
        setModalOpen(true);
        setSelectedIndex(value);
        // if (value === 0) {
        //     localStorage.setItem("check", JSON.stringify("Loan Application"));
        //     localStorage.setItem("RequestType", 0);
        // } else {
        //     localStorage.setItem("check", JSON.stringify("Check Eligibility"));
        //     localStorage.setItem("RequestType", 1);

        // }
        // localStorage.setItem("handleFlow", "letget")
        // props.handleNext(0, 0, 3);
        // setOpenTab(false);
    };


    const goToLetsGetStartedFromCpa = (data) => {
        //
        localStorage.setItem('bankidforcppa', data)
        //localStorage.removeItem('userdata');


        if (loanOrCheck === 0) {
            localStorage.setItem("check", JSON.stringify("Loan Application"));
            localStorage.setItem("RequestType", 0);
        } else {
            localStorage.setItem("check", JSON.stringify("Check Eligibility"));
            localStorage.setItem("RequestType", 1);

        }
        localStorage.setItem('letgetstarted ', JSON.stringify(data));

        props.handleNextLetStart();

    }

    const handleCloseTab = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const anchorRefBusPro = React.useRef(null);
    const options2 = ['Add Business', 'Add Promoter'];
    //    const [selectedIndexBusPro, setSelectedIndexBusPro] = React.useState(0);
    const [openTabBusPro, setOpenTabBusPro] = React.useState(false);

    // const handleClickTabBusPro = () => {
    //     props.handleNext(1, "bus");
    // }

    // const handleToggleBusPro = () => {
    //     setOpenTabBusPro((prev) => !prev)
    // }

    const handleMenuItemClickBusPro = (index) => {
        //
        localStorage.setItem('RequestType', 0);
        if (index == "Business") {
            localStorage.setItem("cpa_reg", "cpa_reg");
            //   window.location = apiCalling.frontCaller+ '/#/registration';
       //     window.location = apiCalling.frontCaller + '/#/registration';

           window.location = apiCalling.frontCaller + '/#/registration';
            //window.location = 'https://platform.aagey.com/app/#/registration';


        } else {
            props.handleNext(undefined, undefined, 2)
        }
        // history.push(apiCalling.frontCaller + '/#/Registration');
        //return  <Redirect to =apiCalling.frontCaller + '/#/Registration' />;
        // //
        // localStorage.setItem("check", JSON.stringify("Loan Application"));
        // setAnchorEl(null);
        // setSelectedIndexBusPro(index);
        // if (index === "Business") {
        //     props.handleNext(undefined, true, 1);
        // } else {
        //     props.handleNext(undefined, true, 2);
        // }

    }

    // const handleCloseTabBusPro = (event) => {
    //     if (anchorRefBusPro.current && anchorRefBusPro.current.contains(event.target)) {
    //         return;
    //     }
    //     setOpenTabBusPro(false);
    // };


    // const handleClickOpeDailog = () => {
    //     setOpen(true);
    // };

    // const handleCloseDialog = () => {
    //     setOpen(false);
    // };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleToggle1 = () => {
        setOpenLoan((prevOpen) => !prevOpen);
    };


    const handleClose1 = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleClose2 = (event) => {
        if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
            return;
        }
        setOpenLoan(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function handleListKeyDown1(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenLoan(false);
        }
    }

    const [statusArr, setStatusArr] = React.useState([]);
    const getLoanStatusPercentage = () => {
        //
        let data = {
            enable: 1
        }
        axios.post(apiCalling.coderscorner + 'server.php/api/getRequestByStatus', data).then(result => {
            console.log(result);
            setStatusArr(result.data.data);
        }).catch(error => {
            console.log(error);
        });
    }
    const getrequestTableByStats = data => {
        //
        let rbody = {
            status: data
        }
        axios.post(apiCalling.coderscorner + 'server.php/api/getRequestTableByStatus', rbody).then(result => {
            console.log(result);
            setApplicationList(result.data.data)
        }).catch(error => {
            console.log(error)
        });
    }

    const [cpaList, setCpaList] = React.useState([]);
    const getCPAData = async () => {
        let data = {
            role_id: 1
        }
        await axios.post(apiCalling.identityManagement + `server.php/api/getUsersByRoleid`, data).then(result => {
            setCpaList(result.data.data);
            let arr = [];
            result.data.data.map(item => {
                arr.push({ text: item.email, value: item.id });
            });
            setCpaList(arr);

        }).catch(error => {

        })
    }

    const deleteBusinessByBusinessId = async (checkusercpa) => {
        let getbusinesid = {
            "bus_id": checkusercpa
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/deletebusinessinfo`, getbusinesid).then((res) => {
            console.log("Deleted business data ", res.data);
            if (res.data.status == true) {
                setcolorMessage("success");
                setShowMessage(res.data.message);
                setsavenextmessage(true);
            }
            getAllBusinessAndPromoter(iduser.id);
        }).catch((er) => {
            console.log("Something went Wrong While Deleting...!!!", er);
        });
    }

    const deletePromoterById = async (deleteId,) => {
        let data = {
            promotor_id: deleteId
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/deletepromotorinfo`, data).then((result) => {
            setcolorMessage("success");
            setShowMessage(result.data.message);
            setsavenextmessage(true);
            getAllPromoterByBusinessId(busIdForDel);
        }).catch((error) => {
            console.log(error);
        });
    };

    const [colorMessage, setcolorMessage] = React.useState();
    const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState();
    const [showPromoterTable, setShowPromoterTable] = React.useState(false);


    const [modalOpen, setModalOpen] = React.useState(false);
    const handleCloseBusiness = () => {
        setModalOpen(false);
    }


    const PurpleSwitch = withStyles({
        switchBase: {
            color: purple[300],
            '&$checked': {
                color: purple[500],
            },
            '&$checked + $track': {
                backgroundColor: purple[500],
            },
        },
        checked: {},
        track: {},
    })(Switch);


    const [state1, setState1] = React.useState({
        checkedA: true,
    });
    const handleRequestType = (event) => {
        setState1({ ...state1, [event.target.name]: event.target.checked });
    };
    return (
        <Container maxWidth='lg'>
            {loaderFlag == true ? (
                <div style={{ textAlign: 'center', marginTop: '15%' }}>
                    <Loader type={LoaderColor.type} color={LoaderColor.color} />
                </div>
            ) :
                (
                    <>
                        <div className="mini-container mg_btm_set" style={{ marginTop: "5%" }}>
                            {/* Process bar code */}
                            <div>
                                <Row>
                                    <Col md={{ span: 8 }}>
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <Typography text="Your Dashboard" styleType="C" />
                                            </Col>
                                            <Col md={{ span: 12 }}>
                                                <div style={{ width: "1100px" }} >
                                                    <Grid component="label" container alignItems="center" spacing={1} style={{
                                                        width: '100%',
                                                        position: 'absolute',
                                                        left: '81%',
                                                        bottom: '100%'
                                                    }}>
                                                        <Grid item className="option_name">Monthly</Grid>
                                                        <Grid item>
                                                            <PurpleSwitch checked={state1.checkedA} onChange={handleRequestType} name="checkedA" />
                                                        </Grid>
                                                        <Grid item className="option_name">Yearly</Grid>
                                                    </Grid>
                                        <Container>
                                            {statusArr.map(item => (
                                                <Row>
                                                    <Col md={{ span: 12 }}><label className="progresslabel">{item.count} / 5L</label></Col>
                                                    <Col md={{ span: 3 }}> <label style={{ fontWeight: 600, float: 'right' }}>{item.key.replaceAll('_', ' ').toUpperCase()}</label> </Col>
                                                    <Col md={{ span: 6 }}> <ProgressBar variant={item.color} onClick={() => getrequestTableByStats(item.key)}
                                                        style={{ color: "black", cursor: "pointer", border: "1px solid #000", height: '20px' }} now={item.percentage === 0 ? "000000" : item.percentage} label={item.count} /></Col>
                                                </Row>
                                            ))}

                                        </Container>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                    <Col md={{ span: 4 }}>
                        <Row>
                            <Col md={{ span: 12 }} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                <div className="dashcard"><p className="dashcardvalue">1000</p>
                                    <p className="dashcardlabel">Application Processed by You</p>
                                </div>
                            </Col>
                            <Col md={{ span: 12 }} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                <div className="dashcard"><p className="dashcardvalue">INR 500 M</p><p className="dashcardlabel">Loan Amount Processed by You</p></div>
                            </Col>
                            <Col md={{ span: 12 }} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                <div className="dashcard"><p className="dashcardvalue">XXX</p><p className="dashcardlabel">Company wide Application Processed</p></div>
                            </Col>
                            <Col md={{ span: 12 }} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                <div className="dashcard"><p className="dashcardvalue">YYY</p><p className="dashcardlabel">Company wide Application Processed</p></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                            </div>
                            {(applicationList.length >= 0) && (
                                <>
                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <IconButton
                                                ref={anchorRef1}
                                                aria-controls={openLoan ? 'menu-list-grow1' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle1}
                                                style={{ float: 'right', marginTop: '1%', marginBottom: '-3%', zIndex: '999' }}>
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                            <Popper open={openLoan} anchorEl={anchorRef1.current} role={undefined} transition disablePortal>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                                        <Paper className="dashboarddd">
                                                            <ClickAwayListener onClickAway={handleClose2}>
                                                                <MenuList autoFocusItem={openLoan} id="menu-list-grow1" onKeyDown={handleListKeyDown1} style={{ padding: '0% 2%', width: '18ch' }}>
                                                                    {ApplicationOptions.map((option, index) => (
                                                                        <MenuItem onClick={handleClose2} key={option} onClick={() => handleMenuItemClick(index)}>
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </Col>
                                    </Row>
                                    <TabContext value={value2}>
                                        <AppBar position="static">
                                            <TabList onChange={handleChange2} aria-label="simple tabs example">
                                                <Tab label="Active Loan" value="1" className="tabSize" />
                                                <Tab label="Archive Loan" value="2" className="tabSize" />
                                            </TabList>
                                        </AppBar>
                                        <TabPanel value="1">
                                            <TableContainer >
                                                <Table aria-label="customized table" size="small" style={{ marginTop: "1%", marginBottom: "1% 0% 3%" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell width="5%" style={table_slNo}>#</TableCell>
                                                            {
                                                                // <TableCell style={table_header}>Reference Number</TableCell>
                                                            }
                                                           
                                                            <TableCell style={table_header}>Business Name</TableCell>
                                                            <TableCell style={table_header}>Business Pan</TableCell>
                                                            <TableCell style={table_header}>Application Type</TableCell>
                                                            <TableCell style={table_header}>Loan Product</TableCell>
                                                            <TableCell style={table_header}>Date Logged</TableCell>
                                                            <TableCell style={table_header}>Financer</TableCell>
                                                            <TableCell style={table_header}>Loan Amount</TableCell>
                                                            <TableCell style={table_header}>Status</TableCell>
                                                            <TableCell style={table_header}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {applicationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                                            return (
                                                                <TableRow hover className="datable">
                                                                    <TableCell>{i + 1}</TableCell>
                                                                    {
                                                                        //   <TableCell>{item.reference_id}</TableCell>
                                                                    }
                                                                 
                                                                    <TableCell>{item.business_name}</TableCell>
                                                                    <TableCell>{item.pan}</TableCell>
                                                                    {item.request_type_id == 0 && (
                                                                        <TableCell>Loan Application</TableCell>
                                                                    )}
                                                                    {item.request_type_id == 1 && (
                                                                        <TableCell>Loan Application</TableCell>
                                                                    )}
                                                                    <TableCell>{item.loan_type}</TableCell>
                                                                    <TableCell>{moment(item.created_on).format('DD-MM-YYYY')}</TableCell>
                                                                    <TableCell>{item.financier_name}</TableCell>
                                                                    <TableCell>{item.loan_amount}</TableCell>
                                                                    <TableCell>{item.status}</TableCell>
                                                                    <TableCell style={{ display: "flex", height: "100%" }}>
                                                                        {<>
                                                                            <Tooltip title="Loan" placement="bottom">
                                                                                <Button className="actionBtn" onClick={(e) => {
                                                                                    goToLetsGetStarted(item);
                                                                                }}>
                                                                                    <i class="fas fa-long-arrow-alt-right" style={{ fontSize: "15px" }}></i>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </>}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 15]}
                                                component="div"
                                                count={applicationList.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />

                                            {(applicationList.length == 0) && (
                                                <Alert severity="info" style={{ marginTop: "7px" }}>
                                                    <AlertTitle><p style={{ fontSize: "16px", marginTop: "6px" }}>You have not created any appliation or Assigned to any application. Please add new business to start
                                                    </p></AlertTitle>
                                                </Alert>
                                            )}
                                        </TabPanel>

                                        <TabPanel value="2">
                                            <TableContainer >
                                                <Table aria-label="customized table" size="small" style={{ marginTop: "1%", marginBottom: "1% 0% 3%" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell width="5%" style={table_slNo}>#</TableCell>
                                                            <TableCell style={table_header}>Reference Number</TableCell>
                                                            <TableCell style={table_header}>Business Name</TableCell>
                                                            <TableCell style={table_header}>Business Pan</TableCell>
                                                            <TableCell style={table_header}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {applicationList.map((item, i) => (
                                                            <TableRow hover className="datable" key={i + 1}>
                                                                <TableCell>{i + 1}</TableCell>
                                                                <TableCell>{item.reference_id}</TableCell>
                                                                <TableCell>{item.business_name}</TableCell>
                                                                <TableCell>{item.pan}</TableCell>
                                                                <TableCell style={{ display: "flex" }}>
                                                                    {
                                                                        <Tooltip title="Loan" placement="bottom">
                                                                            <Button className="actionBtn" onClick={(e) => {
                                                                                goToLetsGetStarted(item.request_type_id);
                                                                            }}>
                                                                                <i class="fas fa-long-arrow-alt-right" style={{ fontSize: "15px" }}></i>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </TabPanel>
                                    </TabContext>
                                </>
                            )}


                            <Row>
                                <Col md={{ span: 12 }}>
                                    <IconButton
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        style={{ float: 'right', marginTop: '1%', marginBottom: '-3%', zIndex: '10' }}>
                                        <img className="actionBtn svgicon" src={addbusiness} />
                                    </IconButton>
                                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper className="dashboarddd">
                                                    <ClickAwayListener onClickAway={handleClose1}>
                                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                            {BPOptions.map((option) => (
                                                                <MenuItem onClick={handleClose1} onClick={(opetion) => handleMenuItemClickBusPro(option)}>{option}</MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                </Col>
                            </Row>


                            {/* {userRole !== 1 && ( */}
                                <TabContext value={value}>
                                    <AppBar position="static">
                                        <TabList onChange={handleChange1} aria-label="simple tabs example">
                                            <Tab label="Business Information" value="1" style={{ width: "200px" }} className="tabSize" />
                                            <Tab label="Promoter Information" value="2" className="tabSize" />
                                        </TabList>
                                    </AppBar>
                                    <TabPanel value="1">


                                        <TableContainer>
                                            <Table aria-label="collapsible table" size="small" style={{ margin: "0% 0% 0%" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="tableSLNoHeading">#</TableCell>
                                                        <TableCell className="tableBusinessNameHeadingMain">Business Name</TableCell>
                                                        <TableCell className="tableGSTHeading">GST</TableCell>
                                                        <TableCell className="tableEntityTypeHeading">Entity Type</TableCell>
                                                        <TableCell className="tablePANHeading">Business PAN</TableCell>
                                                        <TableCell className="tablePANHeading">Industry Group</TableCell>
                                                        <TableCell className="tablePANHeading">City</TableCell>
                                                        <TableCell className="tablePANHeading">State</TableCell>
                                                        <TableCell className="tableActionHeading" colSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {businesslist.slice(buspage * rowsPerPage, buspage * rowsPerPage + rowsPerPage).map((item, i) => (
                                                        <TableRow hover className="datable" key={i}>
                                                            <TableCell>{i + 1}</TableCell>
                                                            <TableCell>{item.name}</TableCell>
                                                            <TableCell>{item.gst}</TableCell>
                                                            <TableCell>{item.entity_type}</TableCell>
                                                            <TableCell>{item.bpan}</TableCell>
                                                            <TableCell>{item.industry_group_name}</TableCell>
                                                            <TableCell>{item.city_name}</TableCell>
                                                            <TableCell>{item.state_name}</TableCell>
                                                            <TableCell className="tableActionHeading">
                                                                <Tooltip title="Edit" placement="bottom">
                                                                    {/* <Button className="actionBtn" onClick={() => handleEditData(1, item.id)}>
                                        <i className="fas fa-user-edit" style={{ fontSize: "15px" }} />
                                        </Button> */}
                                                                    <img className="actionBtn svgicon" src={editrec} onClick={() => handleEditData(item.id, 1, false)} />
                                                                </Tooltip>
                                                                <Tooltip title="delete" placement="bottom">
                                                                    <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
                                                                        deleteBusinessByBusinessId(item.id);
                                                                    }} />
                                                                </Tooltip>
                                                                <Tooltip title="Upload Document" placement="bottom">
                                                                    {/* <Button className="actionBtn" onClick={(e) => {
                                        props.handleNext(item.id, 1, 1);
                                        }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                                                    <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => { props.handleNext(item.id, true, 1); }} />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15]}
                                            component="div"
                                            count={businesslist.length}
                                            rowsPerPage={rowsPerPage}
                                            page={buspage}
                                            onChangePage={busHandleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPagebus}
                                        />
                                        {/* <TablePagination
                                            rowsPerPageOptions={[5, 10, 15]}
                                            component="div"
                                            count={applicationList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage} */}


                                        {businesslist.length === 0 && (
                                            <Alert severity="warning" className="warningMsg1">
                                                <AlertTitle><p style={{ fontSize: "16px" }}>You have Not created or assigned to any Application Please click on add button Add new business
                                                </p></AlertTitle>
                                            </Alert>
                                        )}
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValue} >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                                setFieldValue,
                                                setFieldTouched
                                                /* and other goodies */
                                            }) => {
                                                //console.log("values in formik", values);
                                                return (
                                                    <form onSubmit={handleSubmit} >
                                                        <Row>
                                                            <Col md={{ span: 4 }}>
                                                                <List component="div" disablePadding>
                                                                    <ListItem>
                                                                        <Dropdown
                                                                            placeholder="Select Business Name"
                                                                            id="company"
                                                                            name="company"
                                                                            fluid
                                                                            search
                                                                            selection
                                                                            value={values.company}
                                                                            onBlur={(e, { name, value }) => {
                                                                                setFieldTouched("company", true);
                                                                            }}
                                                                            onChange={(e, { name, value }) => {
                                                                                //

                                                                                setPromoterList([]);
                                                                                setFieldValue("company", value);
                                                                                setbusIdForDel(value);
                                                                                setBusinessIdForPro(value);
                                                                            }}
                                                                            options={businesslist}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Col>
                                                            {/* <Col md={{ span: 5 }}></Col>
                                    <Col md={{ span: 3 }}></Col> */}
                                                        </Row>
                                                    </form>
                                                );
                                            }
                                            }
                                        </Formik>
                                        {showPromoterTable == false && (
                                            <Alert severity="warning" className="warningMsg1">
                                                <AlertTitle><p style={{ fontSize: "16px" }}>Promoters have not been added to this business. Click here to add Promoter details.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </p></AlertTitle>
                                                <Button size="small" className="choicebtn" onClick={() => { props.handleNext(undefined, undefined, 2) }}>Add Promoter</Button>&nbsp;&nbsp;
                                            </Alert>
                                        )}
                                        {true && (
                                            <TableContainer >
                                                <Table aria-label="customized table" size="small" style={{ marginBottom: "0% 0% 0%" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={table_slNo}>#</TableCell>
                                                            <TableCell style={table_header}>Promoter Name</TableCell>
                                                            <TableCell style={table_header}>Email</TableCell>
                                                            <TableCell style={table_header}>Mobile</TableCell>
                                                            <TableCell style={table_header}>Type</TableCell>
                                                            <TableCell style={table_header}>Share %</TableCell>
                                                            <TableCell style={table_header}>Status</TableCell>
                                                            <TableCell style={table_header}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {promoterList.map((item, i) => (
                                                            <TableRow hover className="datable" key={i}>
                                                                <TableCell>{i + 1}</TableCell>
                                                                <TableCell>{item.name}</TableCell>
                                                                <TableCell>{item.email}</TableCell>
                                                                <TableCell>{item.mobile}</TableCell>
                                                                <TableCell>{item.type}</TableCell>
                                                                <TableCell>{item.share}</TableCell>
                                                                <TableCell> <i class="far fa-times-circle" style={{ color: "red" }}></i> </TableCell>
                                                                <TableCell style={{ display: "flex" }}>
                                                                    <Tooltip title="Edit" placement="bottom">
                                                                        {/* <Button className="actionBtn" onClick={(e) => {
                                            getPromoterById1(item.promoter_id);
                                        }}>
                                            <i className="fas fa-user-edit"
                                            style={{ fontSize: "15px" }} />
                                        </Button> */}
                                                                        <img className="actionBtn svgicon" src={editrec} onClick={(e) => {
                                                                            getPromoterById1(item.promoter_id);
                                                                        }} />
                                                                    </Tooltip>
                                                                    <Tooltip title="delete" placement="bottom">
                                                                        {/* <Button className="actionBtn" onClick={(e) => {
                                    deletePromoterById(item.promoter_id);
                                    }}> <i class="fas fa-user-minus" style={{ fontSize: "13px" }}></i></Button> */}
                                                                        <img className="actionBtn svgicon" src={deleterec} onClick={(e) => {
                                                                            deletePromoterById(item.promoter_id);
                                                                        }} />
                                                                    </Tooltip>
                                                                    <Tooltip title="Upload Document" placement="bottom">
                                                                        {/* <Button className="actionBtn" onClick={(e) => {
                                        props.handleNext(item.promoter_id, item.eligibility_flag, 2);
                                    }}> <i class="fa fa-file" style={{ fontSize: "13px" }}></i></Button> */}
                                                                        <img className="actionBtn svgicon" src={uploaddoc} onClick={(e) => {
                                                                            props.handleNext(item.promoter_id, item.eligibility_flag, 2);
                                                                        }} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>)}
                                    </TabPanel>
                                </TabContext>
                            {/* )} */}
                        </div>
                    </>
                )
            }


            <Modal show={showmodal} animation={false} centered style={{ height: "550px" }}>
                <Modal.Header closeButton onClick={handleclosePdf} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                    {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>

                    {/* showCpaPage === true && */(
                        <Formik initialValues={{
                            cpa: ""
                        }}
                            onSubmit={(values) => { console.log("check cpa data table value", values) }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={{ span: 2 }}></Col>
                                            <Col md={{ span: 8 }}>
                                                <Typography text=" CPA" styleType="B" />
                                                <List component="div" disablePadding>
                                                    <ListItem>
                                                        <Dropdown
                                                            placeholder="Select CPA"
                                                            id="cpa"
                                                            name="cpa"
                                                            fluid
                                                            search
                                                            selection
                                                            onBlur={() => setFieldTouched("cpa", true)}
                                                            onChange={(e, { value }) => setFieldValue("cpa", value)}
                                                            value={values.cpa}
                                                            options={cpaList} />
                                                    </ListItem>
                                                </List>
                                            </Col>
                                            <Col md={{ span: 12 }}>
                                                <Row><Col md={{ span: 10 }}></Col>
                                                    <Col md={{ span: 2 }}><Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                                                    </Col>
                                                    <Col md={{ span: 5 }}></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </form>
                                );
                            }}
                        </Formik>
                    )}
                </Modal.Body>
            </Modal>
            <>


                {false && (
                    <Formik
                        initialValues={{
                            bankname: "",
                            account: "",
                            natureacc: "",
                            submission: "",
                            color: "",
                            companyl: "",
                        }}
                        validationSchema={bankFormschema}
                        onSubmit={(values) => {
                            console.log("kkkkk", values);
                            localStorage.setItem("banking", JSON.stringify(values));
                            handleNextClick();
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched,
                            /* and other goodies */
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={{ span: 8 }} style={{ display: "block", margin: "auto", marginTop: "15%" }}>
                                            <div className='border_set_pro'>
                                                <div>
                                                    <h2>Welcome to Aagey.com !</h2>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <h2>Now get loans</h2>
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    <h2>
                                                        Let us get you started with your application
                                                    </h2>
                                                </div>
                                                <div style={{ margin: 40 }}>
                                                    <Button className="choicebtn" onClick={checkeligibiltyBusAndProm}>Check Eligibility</Button>&nbsp;&nbsp;
                                                    <Button className="choicebtn" onClick={applyforloanBusAndPromo}>Loan Application</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            );
                        }}
                    </Formik>
                )}
            </>


            {/* <Dialog onClose={handleCloseBusiness} aria-labelledby="max-width-dialog-title" open={modalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseBusiness}>
                    Select Business
        </DialogTitle>
                <DialogContent className="cpa_dialog" >
                    <Dropdown
                        placeholder="Select Business Name"
                        id="company"
                        name="company"
                        fluid
                        search
                        selection
                        onChange={(e, { name, value }) => {
                            //
                            goToLetsGetStartedFromCpa(value);
                        }}
                        options={businesslist}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseBusiness} color="primary">
                        Save changes
          </Button>
                </DialogActions>
            </Dialog> */}
            <Modal show={modalOpen} animation={false} centered style={{ height: "auto" }}>
                <Modal.Header closeButton onClick={handleCloseBusiness} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                </Modal.Header>
                <Modal.Body>
                    <Typography text=" CPA" styleType="B" />
                    <Row>
                        <Col md={{ span: 2 }}></Col>
                        <Col md={{ span: 8 }}><List component="div" disablePadding>
                            <ListItem>
                                <Dropdown
                                    placeholder="Select Business Name"
                                    id="company"
                                    name="company"
                                    fluid
                                    search
                                    selection
                                    // onBlur={() => setFieldTouched("cpa", true)}
                                    onChange={(e, { name, value }) => { goToLetsGetStartedFromCpa(value); }}
                                    value={value}
                                    options={businesslist} />
                            </ListItem>
                        </List></Col>
                        <Col md={{ span: 2 }}></Col>
                        <Col md={{ span: 10 }}></Col>
                        <Col md={{ span: 2 }}><Button autoFocus onClick={handleCloseBusiness} color="primary">Submit
                        </Button>
                        </Col>
                        <Col md={{ span: 5 }}></Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default withRouter(CpaMainPage);