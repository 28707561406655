import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from '../../base_components/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { TextField, Button } from "@material-ui/core";
import { useStyles } from "../admin";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { loadCSS } from "fg-loadcss";
import { previousMonthConfigrationSchema } from "../../shared/validations";
import axios from "axios";
import { apiCalling } from "../adminConstants";
import Moment from 'react-moment';
import { SnackPosition } from '../../shared/constants'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

const PreviousMonthConfigration = (props) => {
  const classes = useStyles();
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
     
    );
    handleStateList();


    
   // getServices();
    //handleGetApiReports(apiService);
  }, []);

  // const [value, setValue] = React.useState<Date | null>(new Date());
  const [initialvalue,setInitialValue]=React.useState({
    startdate:'',
    enddate:'',
    enable:'',
    monthInterval:'',
    id:''
  })

  const [globArr,setGlobArr] = React.useState([]);
 
 const handleStateList= async ()=>{
   
   const data={
    "parameter_name":["ymf","ymt","loan_duration"]

   }
   await axios.post(apiCalling.thirdParty + 'server.php/api/getGeneralSettingByParameter', data)
   .then((result)=>{
     
     if(result.data.status){
      let idArray = [{id:"",key:"ymf",value:""},{id:"",key:"ymt",value:""}, {id:"", key:"loan_duration", value:""}];
      let resData = result.data.data;
      
      idArray[0].value = resData.ymf[0].parameter_value;
      idArray[0].id = resData.ymf[0].id;

      idArray[1].value =  resData.ymt[0].parameter_value;
      idArray[1].id = resData.ymt[0].id;

      idArray[2].value =  resData.loan_duration[0].parameter_value;
      idArray[2].id = resData.loan_duration[0].id;
      setGlobArr(idArray);

      setInitialValue({
        ...initialvalue,
        startdate: idArray[0].value,
        enddate: idArray[1].value,
        monthInterval:idArray[2].value

      });
     }

   })
   .catch((error)=>{
 console.log(error)
   })
 }
 const [reportArray, setReportArray] = React.useState([]);
 const handleSubmitForm=async(values)=>{
   
  const data={
    "id":[globArr[0].id,globArr[1].id, globArr[2].id],
    "parameter_name":[globArr[0].key,globArr[1].key, globArr[2].key],
  "parameter_value":[values.startdate, values.enddate, values.monthInterval]


  }
  await axios.post(apiCalling.thirdParty + 'server.php/api/saveUpdateGeneralSettings', data)
             .then((res)=>{
               if(res.data.status){
                 setFormat('success')
                 setsavenextmessage(true)
                 setSnackBarMsg(res.data.message)
                console.log(res.data) ;
                //handleStateList();
              
               }
             })
             .catch((err)=>{
              setFormat('error')
              setsavenextmessage(true)
              setSnackBarMsg('something went wrong')
               console.log(err)
             })
 }


  const [addFormButtonValue, setAddFormButtonValue] = React.useState("Submit");
  const [apiService, setApiService] = React.useState("karza");
  
  
  const [rowsPerPage,setRowsPerPage]=React.useState(10);

  const[startDate,setStartDate]=React.useState("");
  const [endDate, setEndDate]=React.useState('')
const andleStartDateChange=(event)=>{
  setStartDate(event.target.value);
}
const EndDateChange=(event)=>{
  setEndDate(event.target.value)
}

  const[open,setOpen]=React.useState('');
  const handleClose=()=>setOpen(false);

  

  const [minimumdate, setminimumdate] = React.useState(); //3 years ago
  const [maximumdate, setmaximumdate] = React.useState();

const handleSubmit=(values)=>{
 
  handleSubmitForm(values)
 
  // handleGetApiReports(apiService, startDate, endDate)
  
}
const [savenextmessage, setsavenextmessage] = React.useState(false);
const [format, setFormat] = React.useState("");
const [snackBarMsg, setSnackBarMsg] = React.useState("");
const closeSuccessToast = () => {
    setsavenextmessage(false)
  }





  const [dateError, setDateError] = React.useState("");
  const [dateValidate, setDateValidate] = React.useState(false);
const handleDate = (e) => {
  
  var date = new Date(e.target.value);
  if (date > new Date("01-01-2022") || date <  new Date("01-06-2022")) {
    console.log("Date not in range");
    setDateError(
      "Date should be in between " + minimumdate + " and " + maximumdate
    );
    setDateValidate(true);
    //  setEnableRegistration(true)
  } else {
    console.log("Date range");
    setDateError("");
  }
};



  return (

    <Container maxWidth='lg'>
          <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
            vertical: SnackPosition.vertical,
            horizontal: SnackPosition.horizontal
          }}>
            <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
              {snackBarMsg}
            </Alert>
          </Snackbar>
    <div
    className='mini-container'
    style={{marginTop:0, padding:'15px' }}
    >
<Row>
   <Col md={{span:10}}>
   <Formik
   enableReinitialize
   initialValues={initialvalue}
   onSubmit={handleSubmit}
   validationSchema={previousMonthConfigrationSchema}
   >
   {
     ({values,
     errors,
     touched,
     handleChange,
     handleBlur,
     handleSubmit,
     resetForm,
     isSubmitting,
     setFieldValue,
     setFieldTouched

     })=>{
       return(
         <form
         onSubmit={handleSubmit}
         >
         <div
         className='content-header'
         style={{position:'unset'}}
         >
         <Row>
         <Col md={{ span: 10 }} className="mainheader1">
           <Typography
            text="Previous Month Configration"
             styleType="C"
               />
           </Col>
         </Row>


              <Row style={{marginTop:'30px'}}>

              <Col sx={4} md={3}>
               <Typography text='Month Interval' styleType='B' />
               <List component='div' disablePadding >
               <ListItem>
                 <TextField
                   id='filled-error-helper-text standard-basic'
                   name='monthInterval'
                   label="Enter Month Interval"
                   type="number"
                   className={classes.textField}
                   onChange={handleChange}

                   error={
                         errors.monthInterval &&
                         touched.monthInterval &&
                         errors.monthInterval
                         ? true
                         : false
                         }
                       InputProps={{
                       className: values.monthInterval ? classes.manorma : null,
                       }}
                       value={values.monthInterval}
                       helperText={
                      errors.monthInterval &&
                       touched.monthInterval &&
                     errors.monthInterval
                     }
                    />
               </ListItem>

               </List>

               </Col>



              <Col sx={4} md={3}>
              <Typography text='Start Date'  styleType='B' />
              <list component='div' disablePadding>
                <ListItem>

                  <TextField
                    id="startdate"
                    name="startdate"
                    type="month"
                    // format="yyyy-dd-mm"
                     
                    className={classes.textField}
                    onChange={handleChange}
                    // onBlur={
                    //   (e)=>{
                    //     setStartDate(e.target.value)
                    //   }
                    // }
                    // onBlur={(value)=>{
                    //   // 
                    //   setFieldValue("startdate",value.target.value);
                      
                    // }}
                     onBlur={handleDate}
                    inputProps={
                      {
                        min:minimumdate, max:maximumdate
                      }
                    }
                    style={{marginTop:"9px"}}
                    InputProps={{
                      inputProps:{
                        min: "1900-01",
                         max: minimumdate,
                      },
                      className:values.startdate
                      ?classes.manorma
                      : null,
                    }}
                    error={
                      errors.startdate &&
                      touched.startdate &&
                      errors.startdate
                      ? true
                      :false
                    }
                    value={values.startdate}
                    helperText={
                      errors.startdate &&
                      touched.startdate &&
                      errors.startdate
                    }
                  />
                </ListItem>
              </list>
              </Col>



               <Col sx={4} md={3}>
              <Typography text='End Date' styleType='B' />
              <list component='div' disablePadding>
                <ListItem>
                  <TextField
                    id="enddate"
                    name="enddate"
                   type='month'
                    className={classes.textField}
                    // onBlur={
                    //   (e)=>{
                    //     console.log(e.target.value)
                    //     setEndDate(e.target.value)

                    //   }
                    // }
                    // onBlur={(value)=>{
                    //   // 
                    //   setFieldValue("enddate",value.target.value);
                    // }}


                     onBlur={handleDate}
                    onChange={handleChange}
                    style={{marginTop:"9px"}}
                    InputProps={{
                      inputProps:{
                        min: "1900-01-01",
                         max: minimumdate,
                      },
                      className:values.enddate
                      ?classes.manorma
                      :null,
                    }}
                    error={
                      errors.enddate &&
                      touched.enddate &&
                      errors.enddate
                      ? true
                      : false
                    }
                    value={values.enddate}
                    helperText={
                    errors.enddate &&
                     touched.enddate &&
                     errors.enddate
                    }
                  />
                </ListItem>
              </list>
               </Col>


               


              <Col md={{span:2}}
              style={{padding:'50px'}}
               >
               <Button 
               className='updatebtn'
               variant='contained'
               color="primary"
               type="submit"
               onClick={(e)=>{
                 console.log('form have been submited',+apiService, values)
               }}
               
               
                >
                {addFormButtonValue}
               </Button>

              </Col>



                </Row>
         


         </div>


         </form>
       )

     }
   }
   </Formik>

   </Col>

   </Row>

    

    </div>

    </Container>
   
    
  )
}

export default PreviousMonthConfigration;