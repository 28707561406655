import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from 'react-bootstrap/Modal';
import Input from '@material-ui/core/Input';
import { Dropdown, TextArea } from 'semantic-ui-react'
import Typography from '../../base_components/Typography'
import { Formik } from "formik";
import { entityoption } from "../../shared/constants";
import { pinchannel } from "../../shared/validations";
import axios from 'axios'
import { useStyles } from "./styles";
import Icon from "@mdi/react";
import { MDBDataTableV5 } from 'mdbreact';
import {
  mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert, mdiArrowRightBox, mdiArrowLeftBox, mdiPlusBox, mdiCloudUpload
} from "@mdi/js";
import {ErrorCatch,apiCalling} from "../../shared/constants";

export default function PinBusinessChannelMap(props) {
  const classes = useStyles();
  const [panvalue, setpanvalue] = React.useState();
  const [email, setemail] = React.useState('');
  const [emailotp, setemailotp] = React.useState();
  const [mobile, setmobile] = React.useState();
  const [mobotp, setmobotp] = React.useState();
  const [show, setshow] = React.useState(false);
  const handleClose = () => setshow(false);
  const [area, setArea] = React.useState([]);
  const handleShow = () => setshow(true);
  const [assettype, setState] = React.useState('');

  const [datatable, setDatatable] = React.useState({

    columns: [
      {
        label: 'Sl No.',
        field: 'slno',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Business Channel',
        field: 'businesschannel',
        width: 270,
      },
      {
        label: 'Type',
        field: 'partner',
        width: 270,
      },
      {
        label: 'Area',
        field: 'area',
        width: 200,
      },
      {
        label: 'City',
        field: 'city',
        width: 100,
      },
      {
        label: 'State',
        field: 'state',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: [
      {
        slno: '1',
        businesschannel: 'Mr ranjit',
        partner: 'partner',
        area: 'MG Road',
        city: 'Bangalore',
        state: 'Karnataka',
        action: 'Manage'
      },
    ],
  });

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);

  const handleSubmited = () => {
    setshow(true)
  };

  const handleNextClick = () => {
    const pinchannelmap = localStorage.getItem('pinchannel'),
      channelmap = JSON.parse(pinchannelmap)
    console.log('datarjjjjjjjjjjje', channelmap)
  }

  const handleSubmiting = () => {
    props.handleNext();
  };

  const handledata = () => {
    props.handleBack();
  };


  const handleselectChange = (event) => {
    event.preventDefault();
    this.setState({
      showOption: true
    })
  };

  const SaveasDraft = () => {
    alert('Save As Draft')
    props.handlecheck();
  }

  const getPincodeData = (data) => {
    let getPincode = {
      pincode: data,
      token: "qwerty1234"
    }

    axios.post(apiCalling.coderscorner +`server.php/api/getDetailsbyPincode`,getPincode).then((res) => {
        console.log("yeh we have pincode", res.data);
        return handleAllAreas(res.data.data)
      })
      .catch((er) => {
        console.log("no pincode sorry ", er);
      });
  }

  const handleAllAreas = (data) => {
    let allArea = []
    for (let i = 0; i < data.length; i++) {
      allArea.push({ key: data[i].area, value: data[i].area, text: data[i].area })
    }
    return setArea(allArea)
  }


  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: -30, padding: '15px' }}>
        <Row>
          <Col md={{ span: 12 }}>

            {/* <Typography variant="h4" gutterBottom>Business Information</Typography> */}
            <Formik
              initialValues={{ businessChanel: "", partner: "", pincode: "", area: "" }}
              validationSchema={pinchannel}
              onSubmit={(values) => {
                console.log('kkkkk', values)
                localStorage.setItem('pinchannel', JSON.stringify(values))
                handleNextClick()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
                /* and other goodies */
              }) => {
                //  console.log("values in formik", values);
                return (
                  <form onSubmit={handleSubmit}>

                    <div className="content-header" style={{ position: 'unset' }}>
                      <Row>
                        <Col md={{ span: 6 }}>

                          <Typography
                            text="Pin - Business Channel Mapping"
                            styleType="C"
                          />

                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className="content-header-button ">


                            <Button
                              className={classes.linkdin}
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              className={classes.button_others}>
                              <Icon path={mdiPlaylistPlus} title="save" size={1} />
                            </Button>


                            <Button
                              className={classes.button_div}
                              variant="outlined"
                              color="primary"
                              className={classes.button_others}

                            ><Icon path={mdiRefresh} title="reset" size={1} /></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row style={{ marginTop: '50px' }}>
                      <Col md={{ span: 6 }}>
                        <Typography text="Business Channel 1" styleType="B" />
                        <List component="div" disablePadding>
                          <ListItem>
                            <TextField
                              id="filled-error-helper-text standard-basic"
                              name="businessChanel"
                              label="Enter Business Channel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.businessChanel &&
                                  touched.businessChanel &&
                                  errors.businessChanel
                                  ? true
                                  : false
                              }
                              InputProps={{
                                className: values.businessChanel ? classes.manorma : null,
                              }}
                              value={values.businessChanel}
                              helperText={
                                errors.businessChanel &&
                                touched.businessChanel &&
                                errors.businessChanel
                              }
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Typography text="Partner / DSA" styleType="B" />
                        <List component="div" disablePadding>
                          <ListItem>
                            <TextField
                              id="filled-error-helper-text standard-basic"
                              name="partner"
                              label="Enter Partner / DSA"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.partner &&
                                  touched.partner &&
                                  errors.partner
                                  ? true
                                  : false
                              }
                              InputProps={{
                                className: values.partner ? classes.manorma : null,
                              }}
                              value={values.partner}
                              helperText={
                                errors.partner &&
                                touched.partner &&
                                errors.partner
                              }
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Typography text="Pincode" styleType="B" />
                        <List component="div" disablePadding>
                          <ListItem>
                            <TextField
                              id="filled-error-helper-text standard-basic"
                              name="pincode"
                              label="999999"
                              onChange={handleChange}
                              onBlur={e => {
                                handleBlur(e)
                                if (!errors.pincode) {
                                  getPincodeData(values.pincode)
                                }
                              }}
                              error={
                                errors.pincode &&
                                  touched.pincode &&
                                  errors.pincode
                                  ? true
                                  : false
                              }
                              InputProps={{
                                className: values.pincode ? classes.manorma : null,
                              }}

                              inputProps={{
                                maxLength: 6,
                              }}

                              value={values.pincode}
                              helperText={
                                errors.pincode &&
                                touched.pincode &&
                                errors.pincode
                              }
                            />
                          </ListItem>
                        </List>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Typography text="Area" styleType="B" />
                        <List component="div" disablePadding>
                          <ListItem>
                            <Dropdown
                              placeholder="Select Area"
                              id="area"
                              name="area"
                              fluid
                              search
                              selection
                              value={values.area}
                              onBlur={() => setFieldTouched("area", true)}
                              onChange={(e, { value }) => setFieldValue("area", value)}
                              options={area}
                              className={errors.area && touched.area ? classes.required : values.area == '' ? classes.reqired : classes.man}
                            />
                          </ListItem>
                        </List>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
            <Row style={{ marginBottom: 7, marginTop: 75 }}>
              <Col md={{ span: 12 }}><Typography text="Details" styleType="E" /></Col>
            </Row>
            <MDBDataTableV5 hover
              entriesOptions={[5, 10, 20, 30]}
              entries={5}
              pagesAmount={4}
              data={datatable}
              pagingTop
              searchTop
              searchBottom={false}
              barReverse
            />
          </Col>
        </Row>
      </div>
    </Container>

  );
}