import { makeStyles } from "@material-ui/core/styles";
    
export const useStyles = makeStyles((theme) => ({
    listitem:{
        color:'black',
        fontSize:'18px',
        fontWeight:'500',
    },
    listitems:{
        color:'black',
        fontSize:'18px',
        fontWeight:'500',
        marginLeft:20,
        
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 230,
        width:10
      },
      table: {
        minWidth: 650,

      },
    Button: {
        showIcon:true,
    },

    textfield: {
        width:'100%'
    },

    linkdin:{
        backgroundColor: '#007bff',
        textTransform: 'capitalize',
        margin: '10px',
        padding: '9px',
        width: '50%',
        fontSize:18,
        align:'center'
    },
    button_div: {
        textTransform: 'capitalize',
        margin: '10px',
        padding: '9px',
        width: '61%',
        fontSize:18
    },
    dtBootstrap4: {
        width: '100%',
    }
  }));
