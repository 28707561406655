import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { loadCSS } from 'fg-loadcss'
import Container from '@material-ui/core/Container'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '../../base_components/Typography'
import { Dropdown } from 'semantic-ui-react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { apiCalling } from '../adminConstants';
import axios from 'axios';
import { FormikTextField } from 'formik-material-fields';
import { TextField, Button, Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'
import { SnackPosition } from '../../../src/shared/constants';


export default function BankingAndProductMaster(props) {
  React.useEffect(() => {
    getLoanTypesPeopPara()
  }, [])

  const [allLoan, setAllLoan] = useState();
  const [loanType, setLoanType] = useState();
  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }
  const getLoanTypesPeopPara = async () => {
    
    const financialdata = {
      token: 'qwerty1234'
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getLoanTypesPeopPara`,
        financialdata
      )
      .then(res => {
        let financialarray = []
        res.data.data.map((item, i) => {
          console.log('item', item)
          financialarray.push({
            key: i,
            value: item.product_category,
            text: item.product_category
          })
        })
        setAllLoan(financialarray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  const [allProduct, setAllProduct] = useState();
  const getFinancierByLoan = async value => {
    
    const financialdata = {
      loan: value
    }
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getFinancierByLoan`,
        financialdata
      )
      .then(res => {
        let temparray = []
        res.data.data.map((item, i) => {
          console.log('item', item)
          temparray.push({
            key: i,
            value: item.financier,
            text: item.financier
          })
        })
        setAllProduct(temparray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }


  const [allProductType, setAllProductType] = useState();
  const [prodType, setProdType] = useState();
  const getProductTypeByLoanTypeAndFin = async (value) => {
    
    const financialdata = {
      loan: loanType,
      product: value
    }
    
    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getProductByLoanAndFinancier`,
        financialdata
      )
      .then(res => {
        let temparray = []
        res.data.data.map((item, i) => {
          console.log('item', item)
          temparray.push({
            key: i,
            value: item.product,
            text: item.product
          })
        })
        setAllProductType(temparray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  const [allparas, setAllPara] = React.useState([]);
  const getAllParamter = (value) => {
    
    let data = {
      loan: loanType,
      financier: financier,
      product: value
    }
    console.log();
    axios.post(apiCalling.coderscorner + `server.php/api/getParaByLoanProdFin`, data).then(result => {
      console.log(result);
      if (result.data.status) {
        setAllPara(result.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }

  let valuesArr = [];
  const [updatedValues,setUpdatedValues] = React.useState([]);

  const updateValue = (i,e,item,limit)=>{
    
    let currentValue = item.target.value;
    let valuesArr = [...allparas];
    // valuesArr
    if(limit == "upper_limit"){
      valuesArr[i].upper_limit = currentValue;
    }else{
      valuesArr[i].lower_limit = currentValue;
    }
    setAllPara(valuesArr);
  }


      const updateProdcuctParameterDetails = async ()=>{
        
          let data ={
            loanType:loanType,
            financier:financier,
            product:prodType,
            arr : allparas
          }

          console.log(data);

          await axios.post(apiCalling.coderscorner + `server.php/api/updateProdcuctParameterDetails`,data).then(result=>{
            console.log(result)
            if(result.data.status){
              setSnackBarMsg(result.data.message)
              setsavenextmessage(true)
            }
          }).catch(error=>{
            console.log(error)
          });
      }

      const [financier,setFinancier] = React.useState("");
      const [product,setProduct] = React.useState();

      const [snackBarMsg, setSnackBarMsg] = React.useState()
      const { vertical, horizontal } = SnackPosition;
      const [showMessage, setShowMessage] = React.useState();

      
      function Alert (props) {
         return <MuiAlert elevation={6} variant='filled' {...props} />
      }



  return (
    <Container maxWidth='lg'>
      <div className='mini-container' style={{ marginTop: 0, padding: '15px' }}>
      <Snackbar
              open={savenextmessage}
              style={{ marginTop: '65px' }}
              autoHideDuration={SnackPosition.duration}
              onClose={closeSuccessToast}
              anchorOrigin={{
                vertical: SnackPosition.vertical,
                horizontal: SnackPosition.horizontal
              }}
            >
              <Alert
                onClose={() => {
                  setsavenextmessage(false)
                }}
                severity='success'
                className='snackBar'
              >
                {snackBarMsg}
              </Alert>
            </Snackbar>
        <div className='content-header'>
          <Row>
            <Col md={{ span: 10 }}>
              <Typography
                text='Master Bank And Product Details'
                styleType='C'
              />
            </Col>

            <Col md={{ span: 6 }}>
              <Typography text='Loan Type' styleType='B' />
              <List component='div' disablePadding>
                <ListItem>
                  <Dropdown
                    placeholder='Select Loan Type'
                    id='loan'
                    name='loan'
                    fluid
                    search
                    selection
                    // value={values.asset}
                    // onBlur={() => setFieldTouched('asset', true)}
                    onChange={(e, { value }) => {
                      setLoanType(value);
                      getFinancierByLoan(value)
                    }}
                    options={allLoan}
                  />
                </ListItem>
              </List>
            </Col>

            <Col md={{ span: 6 }}>
              <Typography text=' Financier' styleType='B' />
              <List component='div' disablePadding>
                <ListItem>
                  <Dropdown
                    placeholder='select Financier'
                    id='fiancier'
                    name='fiancier'
                    fluid
                    search
                    selection
                    // value={values.asset}
                    // onBlur={() => setFieldTouched('asset', true)}
                    onChange={(e, { value }) => {
                      setFinancier(value);
                      getProductTypeByLoanTypeAndFin(value)
                    }}
                    options={allProduct}
                  />
                </ListItem>
              </List>
            </Col>

            <Col md={{ span: 6 }}>
              <Typography text='Product Type' styleType='B' />
              <List component='div' disablePadding>
                <ListItem>
                  <Dropdown
                    placeholder='Select Product Type'
                    id='product'
                    name='product'
                    fluid
                    search
                    selection
                    // value={values.asset}
                    // onBlur={() => setFieldTouched('asset', true)}
                    onChange={(e, { value }) => {
                      setProdType(value);
                      getAllParamter(value);
                    }}
                    options={allProductType}
                  />
                </ListItem>
              </List>
            </Col>
            <Col md={{ span: 4}}></Col>
            <Col md={{ span: 2 }} className ="update_btn_bank"><Button className ="update_btn_bank1" variant="contained"
            onClick= {updateProdcuctParameterDetails}
             color="primary" type="submit" >Submit</Button></Col>
                     


            {
              allparas.length > 0 && (
                <Col md={{ span: 12 }} style={{ padding: '1%' }}>
                  <div class='table_wrapper'>
                    <TableContainer component={Paper}>
                      <Table border='1' className='styled-table'>
                        <TableHead>
                          <TableRow className='master_pro'>
                            <TableCell className='master_pro'>
                              #
                            </TableCell>
                            <TableCell className='master_pro'>
                              Parameter Name
                            </TableCell>
                            <TableCell className='master_pro'>
                              Lower Limit
                            </TableCell>
                            <TableCell className='master_pro'>
                              Upper Limit
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            allparas.map((item, i) => (
                              <>
                                <TableRow key ={1}>
                                  <TableCell className='master_pro'>{i + 1}</TableCell>
                                  <TableCell className='master_pro'>{item.parameter_desc}</TableCell>

                                  <TableCell className='master_pro'>
                                    {
                                      item.lower_limit != null ? (
                                      <TextField disable={true} className="master_banks" value={item.lower_limit ? item.lower_limit :"" }  onChange ={(value)=>updateValue(i,item,value,"lower_limit")}  />) :
                                        (
                                          <span> {item.lower_limit}</span>
                                        )
                                    }
                                  </TableCell>

                                  <TableCell className='master_pro'>
                                    {
                                      item.upper_limit != null ? (<TextField
                                         disable={true} className="master_banks" value={item.upper_limit ? item.upper_limit :"" }  onChange ={(value)=>updateValue(i,item,value,"upper_limit")}   />) :
                                        (
                                          <span> {item.upper_limit}</span>
                                        )
                                    }
                                  </TableCell>
                                </TableRow>

                              </>

                            ))
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
      </div>
    </Container>
  )
}
