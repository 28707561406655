import React from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Eventcalendar, snackbar, setOptions, Popup, Button, Input, Textarea, Switch, Datepicker, SegmentedGroup, SegmentedItem } from '@mobiscroll/react';
import "./index.css";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Height } from '@material-ui/icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {ErrorCatch,apiCalling} from "../../shared/constants";
import { Formik } from "formik";

setOptions({
    theme: 'ios',
    themeVariant: 'light'
});

const now = new Date();

const defaultEvents = 
[{
    id: 1,
    start: new Date(now.getFullYear(), now.getMonth(), 8, 13),
    end: new Date(now.getFullYear(), now.getMonth(), 8, 13, 30),
    title: 'Lunch @ Butcher\'s',
    color: '#26c57d'
}, {
    id: 2,
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16),
    title: 'General orientation',
    color: '#fd966a'
}, {
    id: 3,
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 18),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 22),
    title: 'Dexter BD',
    color: '#37bbe4'
}, {
    id: 4,
    start: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 10, 30),
    end: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 11, 30),
    title: 'Stakeholder mtg.',
    color: '#d00f0f'
}];

const viewSettings = {
    calendar: { labels: true }
};
const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false
    }
};

export default function Calender() {
    const [myEvents, setMyEvents] = React.useState([]);
    const [tempEvent, setTempEvent] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [popupEventTitle, setTitle] = React.useState('');
    const [popupEventDescription, setDescription] = React.useState('');
    const [popupEventAllDay, setAllDay] = React.useState(true);
    const [popupEventDate, setDate] = React.useState([]);
    const [popupEventStatus, setStatus] = React.useState('busy');
    const [mySelectedDate, setSelectedDate] = React.useState(now);
    const [checked, setChecked] = React.useState(true);

    React.useEffect(() => {
        getLabelsData();
        getAllEvents();
      }, []);
    

    const handleChange = (event) => {
        setChecked(event.target.checked);
      };
      const getAllEvents = async () =>{
        const data = {
            request_id:26
        }
        await axios.post(apiCalling.coderscorner + `server.php/api/getAppointmentDetailsByRequestId`,data).then((result) =>{
            setMyEvents(result.data.data)
            
            let eventArray = [];
            result.data.data.map((item) =>{
                const sdt = new Date(item.start_date); 
                const edt = new Date(item.end_date)
                eventArray.push({
                    id: item.id,
                    start: new Date(sdt.getFullYear(), sdt.getMonth(),sdt.getDate()),
                    end: new Date(edt.getFullYear(), edt.getMonth(),edt.getDate()),
                    title: item.title,
                    color: '#26c57d'
                });
            });
            setMyEvents(eventArray);
        })
    }

    const saveEvent = React.useCallback(() => {
        
        var sdate = new Date(popupEventDate[0]);
        var edate = new Date(popupEventDate[1]);
        const newEvent = {
            id: tempEvent.id,
           // title: popupEventTitle,
          //  description: popupEventDescription,
           // start: popupEventDate[0],
            //end: popupEventDate[1],
            allDay: popupEventAllDay,
            status: popupEventStatus,
            color: tempEvent.color,
            title:popupEventTitle,
            start_date:sdate.getFullYear()+'-'+sdate.getMonth()+'-'+sdate.getDate(),
            start_time:sdate.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"),
            end_date:edate.getFullYear()+'-'+edate.getMonth()+'-'+edate.getDate(),
            end_time:edate.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"),
            request_id:26,
            created_by:237,
            reference_document_checklist_id:arrayList
        };
        console.log("book event",newEvent)
        axios.post(apiCalling.coderscorner + `server.php/api/bookAppointment`,newEvent).then((result) =>{
            console.log(result.data.Data);
       })
        if (isEdit) {
            // update the event in the list
            const index = myEvents.findIndex(x => x.id === tempEvent.id);;
            const newEventList = [...myEvents];

            newEventList.splice(index, 1, newEvent);
            setMyEvents(newEventList);
            // here you can update the event in your storage as well
            // ...
        } else {
            // add the new event to the list
            setMyEvents([...myEvents, newEvent]);
            // here you can add the event to your storage as well
            // ...
        }
        setSelectedDate(popupEventDate[0]);
        // close the popup
        setOpen(false);
    }, [isEdit, myEvents, popupEventAllDay, popupEventDate, popupEventDescription, popupEventStatus, popupEventTitle, tempEvent]);

    const deleteEvent = React.useCallback((event) => {
        setMyEvents(myEvents.filter(item => item.id !== event.id));
        setTimeout(() => {
            snackbar({
                button: {
                    action: () => {
                        setMyEvents(prevEvents => [...prevEvents, event]);
                    },
                    text: 'Undo'
                },
                message: 'Event deleted'
            });
        });
    }, [myEvents]);

    const loadPopupForm = React.useCallback((event) => {
        setTitle(event.title);
        setDescription(event.description);
        setDate([event.start, event.end]);
        setAllDay(event.allDay || true);
        setStatus(event.status || 'busy');
    }, []);

    // handle popup form changes

    const titleChange = React.useCallback((ev) => {
        setTitle(ev.target.value);
    }, []);

    const descriptionChange = React.useCallback((ev) => {
        setDescription(ev.target.value);
    }, []);

    const allDayChange = React.useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const dateChange = React.useCallback((args) => {
        setDate(args.value);
    }, []);

    const statusChange = React.useCallback((ev) => {
        setStatus(ev.target.value);
    }, []);

    const onDeleteClick = React.useCallback(() => {
        deleteEvent(tempEvent);
        setOpen(false);
    }, [deleteEvent, tempEvent]);

    // scheduler options

    const onSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    });

    const onEventClick = React.useCallback((args) => {
        
        setEdit(true);
        setTempEvent({ ...args.event });
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);
        setOpen(true);
    }, [loadPopupForm]);

    const onEventCreated = React.useCallback((args) => {
        
        // createNewEvent(args.event, args.target)
        setEdit(false);
        setTempEvent(args.event)
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);
        // open the popup
        setOpen(true);
    }, [loadPopupForm]);

    const onEventDeleted = React.useCallback((args) => {
        deleteEvent(args.event)
    }, [deleteEvent]);

    const onEventUpdated = React.useCallback((args) => {
        // here you can update the event in your storage as well, after drag & drop or resize
        // ...
    }, []);

    // datepicker options
    const controls = React.useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const respSetting = React.useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
            medium: {
                controls: ['calendar', 'time'],
                touchUi: false
            }
        }, [popupEventAllDay]);

    // popup options
    const headerText = React.useMemo(() => isEdit ? 'Edit event' : 'New Event', [isEdit]);
    const popupButtons = React.useMemo(() => {
        if (isEdit) {
            return [
                'cancel',
                {
                    handler: () => {
                        saveEvent();
                    },
                    keyCode: 'enter',
                    text: 'Save',
                    cssClass: 'mbsc-popup-button-primary'
                }
            ];
        }
        else {
            return [
                'cancel',
                {
                    handler: () => {
                        saveEvent();
                    },
                    keyCode: 'enter',
                    text: 'Add',
                    cssClass: 'mbsc-popup-button-primary'
                }
            ];
        }
    }, [isEdit, saveEvent]);

    const onClose = React.useCallback(() => {
        if (!isEdit) {
            // refresh the list, if add popup was canceled, to remove the temporary event
            setMyEvents([...myEvents]);
        }
        setOpen(false);
    }, [isEdit, myEvents]);

    const[labelData, setLabelDataList]=React.useState([]);
    const detailsDocument = async () => {
        const docdata = {
            user_id: 265,
            parent_id: 467,
            parent_type_id: 1,
            form: "1.1.3"
        }
        let api_path=apiCalling.coderscorner +`server.php/api/getDetailsDocument`;
        await axios.post(apiCalling.coderscorner +`server.php/api/getDetailsDocument`, docdata).then((result) => {
          console.log("Document data...!!!", result.data);
          let myArr = [];
          console.log(result.data)
          result.data.data.map((item) => {
            myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
          })
          setLabelDataList(myArr);
        }).catch(error => {
          //ErrorCatch(userId.id,error,api_path,'post',docdata,userId.id,userId.id);
          console.log(error)
        })
      }
    
    const labels = [
        {id:1,name:"PAN"},{id:2,name:"Aadhar Number"},{id:3,name:"Voter Id"},{id:4,name:"Company Profile"},{id:5,name:"Documents"},{id:6,name:"PAN5"},{id:7,name:"PAN6"},
        {id:8,name:"PAN7"},{id:9,name:"PAN8"},{id:10,name:"PAN9"},{id:11,name:"PAN10"}]

    const [arrayList, setArrayList] = React.useState([]);    
    const getData = (id) =>{
        
        setArrayList([...arrayList,id])
        console.log(arrayList);
    }    
    const getLabelsData = async () =>{
        const labelData = {
            user_id: 265,
            parent_id: 467,
            parent_type_id: 1,
            form: "1.1.3"
        }
        let myArr = [];
        await axios.post(apiCalling.coderscorner +'server.php/api/findDocsOfUser', labelData).then((result) =>{
            if (result.data.data.length > 1) {
                result.data.data.map((item) => {
                  myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
                })
                console.log("my document array----->", myArr);
                setLabelDataList(myArr);
              }else {
                detailsDocument();
              }
        })
    }    

    const saveBookAppointment = () =>{
        const data = {
            title:"Collect Book Appointment",
            start_date:"2021-03-29",
            start_time:"12:00 PM",
            end_date:"2021-04-29",
            end_time:"2:00 PM",
            request_id:26,
            created_by:237,
            reference_document_checklist_id:arrayList
        }
        console.log('Book Appointment Save Data',data);
    } 
    return <div>
        <Eventcalendar
            view={viewSettings}
            data={myEvents}
            clickToCreate="double"
            dragToCreate={true}
            dragToMove={true}
            dragToResize={true}
            selectedDate={mySelectedDate}
            onSelectedDateChange={onSelectedDateChange}
            onEventClick={onEventClick}
            onEventCreated={onEventCreated}
            onEventDeleted={onEventDeleted}
            onEventUpdated={onEventUpdated}
        />
        <Popup
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            anchor={anchor}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
        >
            <div className="mbsc-form-group">
                <Input label="Title" value={popupEventTitle} onChange={titleChange} />
                {/* <Textarea label="Description" value={popupEventDescription} onChange={descriptionChange} /> */}
            </div>
            <div className="mbsc-form-group slidebar" >
                {
                    labelData.map((item,i)=>(
                            <div className="label">
                                <FormControlLabel control={<Checkbox name="checkedC" color="primary" 
                                onChange={()=>{getData(item.id)}}
                               // onChange={()=>{setArrayList([...arrayList,item.id])}}
                                />} label={item.name}/>
                            </div>
                    ))
                }
                
            </div>
            <div className="mbsc-form-group">
                <Switch label="All-day" 
                 checked={popupEventAllDay} 
                onChange={allDayChange} />
                <Input ref={startRef} label="Starts" />
                <Input ref={endRef} label="Ends" />
                <Datepicker
                    select="range"
                    controls={controls}
                    touchUi={true}
                    startInput={start}
                    endInput={end}
                    showRangeLabels={true}
                    responsive={respSetting}
                    onChange={dateChange}
                    value={popupEventDate}
                />
                <SegmentedGroup onChange={statusChange}>
                    <SegmentedItem value="busy" checked={popupEventStatus === 'busy'}>Show as busy</SegmentedItem>
                    <SegmentedItem value="free" checked={popupEventStatus === 'free'}>Show as free</SegmentedItem>
                </SegmentedGroup>
                {isEdit ? <div className="mbsc-button-group"><Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Delete event</Button></div> : null}
            </div>
        </Popup>
    </div>
}

