import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 269;

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#3f51b5",
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "white",
    zIndex: 1,
    fontSize: 18,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "green",
  },
});

export const useStyles = makeStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  roots: {
    display: "block",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  /* drawer: {
    width: drawerWidth,
    flexShrink: 0,
  }, */
  // drawerPaper: {
  //   width: drawerWidth,
  // },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));
