import React, { useState } from "react";
import { loadCSS } from "fg-loadcss";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "@material-ui/core/Icon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputLabel, TextField, Button, Checkbox } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { regiser } from "../../shared/validations";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import "./index.css";
import { useStyles } from "./styles";
import logo from "../../image/logo.png";
import background_iamge from "../../image/LoginBG1.svg";
import Navigation from "../../Navigation";
import Icons from "@mdi/react";
//import 'react-phone-number-input/style.css';
//import PhoneInput from 'react-phone-number-input';
//import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
//import LabeledCheckboxMaterialUi from 'labeled-checkbox-material-ui';
//import  { Otp } from 'react-otp-timer';
//import Select from 'react-select';
import "./styles.js";
import OtpTimer from "otp-timer";
import { countryCodes } from "../../shared/constants";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import { SignUpOtpVal } from "../../shared/validations";
import { useAlert } from "react-alert";
import CheckIcon from "@material-ui/icons/Check";
import {ErrorCatch,apiCalling} from "../../shared/constants";
import {
  mdiPlaylistPlus,
  mdiRefresh,
  mdiContentSaveAlert,
  mdiArrowRightBox,
  mdiArrowLeftBox,
  mdiPlusBox,
  mdiCloudUpload,
} from "@mdi/js";
export default function SignUp(props) {
  const classes = useStyles();

  const [mobotp, setmobotp] = React.useState();
  const [otp, setOtp] = React.useState(true);
  const [resendotp, setresendotp] = React.useState(true);
  const [timer, finalTimer] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cntCode, setCtCode] = React.useState([]);
  const [showEmailCheck, setShowEmailCheck] = React.useState(false);
  const [getEmailOtpType,setGetEmailOtpType] =  React.useState(false);
  const [getMobileOtpType,setGetMobileOtpType] =  React.useState(false);
  const [optData,setOptData] = React.useState();

  //Modified by jayesh oct -14
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    getOtp();
    getTimer();
  }, []);

 
  //
  const getOtp = () => {
    var id = localStorage.getItem("otps");
    const emailopt1 = localStorage.getItem("emailopt");
    const mobileotp2 = localStorage.getItem("mobileotp");

    //optData = JSON.parse(id);
    setOptData(JSON.parse(id))
    console.log("opData All-------->", optData);
    console.log("email------>", emailopt1);
    console.log("mobileOtp----->", mobileotp2);
    if (
      emailopt1 != null &&
      emailopt1 != undefined &&
      mobileotp2 != null &&
      mobileotp2 != undefined
    ) {
      setTimeout(() => {
        alert("Email otp" + emailopt1 + " " + "mobile otp" + mobileotp2);
      }, 5000);
    }
  };
  const submitEmailOtp = (e) => {
    
    let data = {};
    if (e.target.name == "eotp") {
      data = {
        value: optData.email,
        form_id: 1,
        otp: e.target.value,
      };
    } else if (e.target.name == "motp") {
      data = {
        value: optData.mobile,
        form_id: 1,
        otp: e.target.value,
      };
    }

    axios
      .post(apiCalling.coderscorner +
        "server.php/api/verifyOtp",
        data
      )
      .then((res) => {
        console.log(res.data);
        console.log("dhdhd",res.data.data[0].value);
        const typeOfData = res.data.data[0].value;
        try{
        if(typeOfData.includes("@")){
          console.log("its email")
          setGetEmailOtpType(!getEmailOtpType);
        }else{
          console.log("its number");
          setGetMobileOtpType(!getMobileOtpType);
        }
      }catch(e){
        console.log(e)
      }
        //console.log(typeof typeOfData);
        // if (res.data == "Otp verified successfully") {
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(data);
  };

  const sigUpclick = (values) => {
    
    let data = {
      id: optData.id,
    }; // console.log("signup values", values);
    axios
      .post(apiCalling.coderscorner +
        "server.php/api/AgreeAndSignUp",
        data
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    localStorage.setItem("register", JSON.stringify(values));
    //handleNextClick()
    props.history.push({ pathname: "/registration" });
  };

  const handleResendOtp = () => {
    
    finalTimer(!timer);
  };

  const getTimer = () => {
    setInterval(() => {
      finalTimer(!timer);
    }, 90000);
  };

  const handleNextClick = () => {
    const signupdata = localStorage.getItem("signup"),
      SignupData = JSON.parse(signupdata);
    // console.log("SignupData", SignupData);
  };

  const handleClick = () => {
    //desire d function to be performed on clicking resend button
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <div className="login-form">
      <div className="login-card" style={{ width: "30%", height: "25pc" }}>
        <Row>
          <Col md={{ span: 12 }}>
            <div className="right-col">
              <h1>Enter Otp</h1>
              <Formik
                initialValues={{
                  motp: "",
                  eotp: "",
                }}
                validationSchema={SignUpOtpVal}
                onSubmit={sigUpclick}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  /* and other goodies */
                }) => {
                  // console.log("values in formik", values);
                  return (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div className="otp_class">
                          <Row>
                            <Col md={{ span: 11 }}>
                              <div>
                                <TextField
                                  id="filled-error-helper-text standard-basic "
                                  label="Enter Email OTP"
                                  name="eotp"
                                  inputProps={{ maxLength: 6 }}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleChange}
                                  onBlur={e=>submitEmailOtp(e)}
                                  InputProps={{
                                    className: values.eotp
                                      ? classes.manorma
                                      : null,
                                  }}
                                  error={
                                    errors.eotp && touched.eotp && errors.eotp
                                      ? true
                                      : false
                                  }
                                  value={values.eotp}
                                  helperText={
                                    errors.eotp && touched.eotp && errors.eotp
                                  }
                                />
                              </div>
                            </Col>
                            { getEmailOtpType== true ?(
                              <Col md={{ span: 1 }} style={{ marginTop: "7%" }}>
                                <p>Verified</p>
                              </Col>) :null
                            }

                            <Col md={{ span: 11 }}>
                              <TextField
                                id="filled-error-helper-text standard-basic "
                                label="Enter Mobile OTP"
                                name="motp"
                                inputProps={{ maxLength: 6 }}
                                onInput={(e) => onlyNumbers(e)}
                                onChange={handleChange}
                                onBlur={submitEmailOtp}
                                InputProps={{
                                  className: values.motp
                                    ? classes.manorma
                                    : null,
                                }}
                                error={
                                  errors.motp && touched.motp && errors.motp
                                    ? true
                                    : false
                                }
                                value={values.motp}
                                helperText={
                                  errors.motp && touched.motp && errors.motp
                                }
                              />
                            </Col>
                            {getMobileOtpType ==true &&(
                            <Col md={{ span: 1 }} style={{ marginTop: "6%" }}>
                              <p>Verified</p>
                            </Col>)}
                          </Row>
                        </div>
                        <div className="footers">
                          <div className="otptimer">
                            {!timer ? (
                              <OtpTimer
                                seconds={30}
                                minutes={1}
                                resend={handleClick()}
                              />
                            ) : null}
                          </div>

                          {timer ? (
                            <Button
                              onClick={handleResendOtp}
                              // disabled={isSubmitting}
                              className="ctm_btn_set footers"
                            >
                              {" "}
                              Resend OTP
                            </Button>
                          ) : null}
                        </div>
                      </div>

                      <div className="footer">
                        <Button
                          onClick={handleSubmit}
                          // disabled={!checked}
                          className="ctm_btn_set footer"
                        >
                          Agree and SignUp
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
