import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from "axios";
import { ErrorCatch, apiCalling } from "../../shared/constants";
import StepButton from '@material-ui/core/StepButton';
import Container from "@material-ui/core/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import grayback from '../../image/buttons/grayback.png';
import Back from '../../image/buttons/back.png';
import Tooltip from '@material-ui/core/Tooltip';
import Graphy from "../../base_components/Typography";
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DraftNext from '../../image/buttons/draftnext.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import moment from 'moment';

export default function BookCalEve(props) {

    React.useEffect(() => {
        getLabelsData();
        getAllEvents();
        //saveAppointment();
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    }));

    function getSteps() {
        return ['Choose an available day for your appointement', 'Choose an available time for your appointement', 'Share your contact information with us and we will send you a reminder'];
    }

    const getStepContent = step => {
        switch (step) {
            case 0:
                return calender();
            case 1:
                return time();
            case 2:
                return informationData();
            default:
                return 'Unknown step';
        }
    }

    const table_slNo = {
        fontWeight: "600",
        fontSize: "100%"
    }

    const table_header = {
        fontWeight: "600",
        fontSize: "100%",
        width: "0%"
    };

    const [labelData, setLabelDataList] = React.useState([]);
    const detailsDocument = async () => {
        const docdata = {
            user_id: 265,
            parent_id: 467,
            parent_type_id: 1,
            form: "1.1.3"
        }
        //let api_path=apiCalling.coderscorner +`server.php/api/getDetailsDocument`;
        await axios.post(apiCalling.coderscorner + `server.php/api/getDetailsDocument`, docdata).then((result) => {
            console.log("Document data...!!!", result.data);
            let myArr = [];
            console.log(result.data)
            result.data.data.map((item) => {
                myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
            })
            setLabelDataList(myArr);
        }).catch(error => {
            //ErrorCatch(userId.id,error,api_path,'post',docdata,userId.id,userId.id);
            console.log(error)
        })
    }

    const getLabelsData = async () => {
        const labelData = {
            user_id: 265,
            parent_id: 467,
            parent_type_id: 1,
            form: "1.1.3"
        }
        let myArr = [];
        await axios.post(apiCalling.coderscorner + 'server.php/api/findDocsOfUser', labelData).then((result) => {
            if (result.data.data.length > 1) {
                result.data.data.map((item) => {
                    myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
                })
                console.log("my document array----->", myArr);
                setLabelDataList(myArr);
            } else {
                detailsDocument();
            }
        })
    }

    const [completed, setCompleted] = React.useState({});
    const handleStep = (i) => () => {
        handleComplete();
        console.log(i);
        
        setActiveStep(step => step = i);
    };

    const handleComplete = (i) => {
        
        const newCompleted = completed;
        newCompleted[i] = true;
        setCompleted(newCompleted);
        //  handleNext();
    };
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [selectedDate, handleDateChange] = React.useState(new Date());
    const [selectedTime, handleTimeChange] = React.useState(new Date());
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const [arrayList, setArrayList] = React.useState([]);
    const getData = (id) => {
        
        setArrayList([...arrayList, id])
        console.log(arrayList);
    }
    const calender = () => {
        setShowButtons(false);
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    clearable
                    autoOk
                    orientation="landscape"
                    // variant="static"
                    id="start_date"
                    name="start_date"
                    value={selectedDate}
                    placeholder="10/10/2018"
                    onChange={date => handleDateChange(date)}
                    minDate={new Date()}
                    format="MM/dd/yyyy"
                // onClick={handleNext}
                />
            </MuiPickersUtilsProvider>
        );
    }

    const handleSubmiting = () => {
        props.handleNext();
      };

    const SaveasDraft = () => {
        handleSubmiting();
      }

    const handledata = () => {
        props.handleBack();
    };

    const addBusiness = () =>{
        setShowTable(false);
    }

    const [showmodal, setshowmodal] = React.useState(false)
    const handleshowhidepdf = () => {
        
		setshowmodal(true)
	}

    const time = () => {
        setShowButtons(false);
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker autoOk label="12 hours" value={selectedTime} onChange={handleTimeChange} />
            </MuiPickersUtilsProvider>
        );
    }

    const [showButtons , setShowButtons] = React.useState(false);
    const informationData = () => {
        setShowButtons(true);
        return (
            <div>
                {labelData.map((item, i) => (
                    <div className="label">
                        <FormControlLabel control={<Checkbox name="checkedC" color="primary"
                            onChange={() => { getData(item.id) }}
                         //onChange={()=>{setArrayList([...arrayList,item.id])}}
                        />} label={item.name} />
                    </div>))
                }
            </div>
        )   
    }

    const [page, setPage] = React.useState(0);  
    const [rowsPerPage, setRowsPerPage] = React.useState(5);  
    const [showTable, setShowTable] = React.useState(false);
    const [myEvents, setMyEvents] = React.useState([]);
    const getAllEvents = async () =>{
        
        const data = {
            request_id:localData.id
        }
        await axios.post(apiCalling.coderscorner+`server.php/api/getAppointmentDetailsByRequestId`,data).then((result) =>{
            setMyEvents(result.data.data);
            if(result.data.status){
                setShowTable(true);
            }else{
                setShowTable(false);
            }
        })
    }
    
    let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));
    let userData = JSON.parse(localStorage.getItem("userdata"));
    const saveAppointment = () =>{
        var sdate = new Date(selectedDate);
        var stime = new Date(selectedTime)
        const data = {
            start_date:sdate.getFullYear()+'-'+sdate.getMonth()+'-'+sdate.getDate(),
            start_time:stime.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"),
            request_id:localData.id,
            created_by:userData.id,
            reference_document_checklist_id:arrayList
        }
        console.log('data to save.........',data)
        axios.post(`server.php/api/bookAppointment`,data).then((result) => {
            console.log('saved Appointment',result.data.message);
            getAllEvents();
            //setShowTable(false);
       });
    }

    const handleEditData = (id) =>{
        
        const data = {
            id:id
        }
        axios.post(apiCalling.coderscorner+`server.php/api/getAppointmentDetailsByAppointmentId`,data).then((result) => {
            console.log("edited successfully",result.data.data);
            let appointmentheader = result.data.data.appointmentheader[0];
            let appointmentdetails = result.data.data.appointmentdetails[0];
            let startDate = moment(appointmentheader.start_date).format('YYYY-MM-DD');
            console.log(startDate)
            setInitialValue({
                ...initialValue,
                id:appointmentheader.id,
                start_date:startDate,
                start_time:appointmentheader.start_time,
                request_id:appointmentheader.request_id,
                created_by:appointmentheader.created_by,
               // reference_document_checklist_id:appointmentdetails.reference_document_checklist_id
            })
            setShowTable(false);
        })
    }

    const deleteAppointmentById = (id) =>{
        console.log(id);
        const data = {
            id:id
        }
        axios.post(apiCalling.coderscorner+`server.php/api/deleteAppointmentById`,data).then((result) => {
            console.log("deleted Successfully",result.data.data);
            getAllEvents();
        })
    }

    const [initialValue, setInitialValue] = React.useState({
        id:"",
        start_date:"",
        start_time:"",
        request_id:"",
        created_by:"",
        reference_document_checklist_id:""
    })

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [selected, setSelected] = React.useState([]);
    const handleClicked = (event, name) => {
        
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
      console.log('newSelected',newSelected);
    };

    return (
        <Container maxWidth="lg">
            { showTable == false && (
            <div className="mg_btm_set">
            <Formik enableReinitialize initialValues={initialValue} onSubmit={saveAppointment}>
                {({ values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="mini-container mg_btm_set">
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <div className="content-header">
                                            <Row>
                                                <Col  md={{ span: 12 }} className="mainheader1">
                                                    <Graphy text="BOOK APPOINTMENT" styleType="C" />
                                                    {/* <p styleType="C">Book Appointment</p> */}
                                                </Col>
                                                <Col md={{ span: 12 }} className="subHeader1">
                                                    <table className="applicationTableDetails1">
                                                    <tr>
                                                        {/* <td>Type</td>
                                                        <td>:</td> */}
                                                        {localData.request_type_id === 0 && (
                                                        <td>Loan Application</td>)}
                                                        {localData.request_type_id === 1 && (
                                                        <td>Check Eligibility</td>)}
                                                        <td>&nbsp;|&nbsp;</td>
                                                        <td>Application Number</td>
                                                        <td>:</td>
                                                        <td>{localData.reference_id}</td>
                                                        <td>&nbsp;|&nbsp;</td>
                                                        <td>Loan Type</td>
                                                        <td>:</td>
                                                        <td>{localData.loan_type}</td>
                                                    </tr>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className={classes.root}>
                                            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                                                {steps.map((label, index) => (
                                                    <Step key={label}>
                                                        <StepButton onClick={handleStep(index)} completed={label.status} >
                                                            <StepLabel>{label}</StepLabel>
                                                        </StepButton>
                                                        <StepContent>
                                                            <Typography>{getStepContent(index)}</Typography>

                                                            {/* <div className={classes.actionsContainer}>
                                                                <div>
                                                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}> Back </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleNext}
                                                                        className={classes.button}
                                                                    >
                                                                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                                                                    </Button>
                                                                </div>
                                                            </div> */}
                                                        </StepContent>

                                                    </Step>
                                                ))}
                                                <Col md={{ span: 12 }}>
                                                    <Row>
                                                        {/* <Col md={{ span: 10 }}></Col> */}
                                                        <Col md={{ span: 2 }}>
                                                        <Button className="updatebtn" variant="contained" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Stepper>
                                            {/* {activeStep === steps.length && ( 
                                            {showButtons == true && ( 
                                                <Paper square elevation={0} className={classes.resetContainer}>
                                                    {/* <Typography>All steps completed - you&apos;re finished</Typography> 
                                                    <Button onClick={handleReset} className={classes.button}>Reset</Button>
                                                    <Button onClick={handleSubmit} className={classes.button}>Submit
                                                    </Button>
                                                </Paper>
                                            )}*/}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    )
                }}
            </Formik>
            </div>
            )}
            { showTable == true && (
                <>
                <div className="mini-container mg_btm_set">
                    <Row>
                        <Col md={{ span: 12 }}>
                <div className="content-header">
                    <Row>
                        <Col md={{ span: 10 }} className="mainheader1">
                            <Graphy text="Book Appointment Details" styleType="C" />
                        </Col>
                        <Col md={{ span: 2 }}>
                            <span className="image">
                                <img className='backbutton navbutton' src={Back} onClick={addBusiness} alt="" />
                                <Tooltip title="Book Appointment" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                                    <img className='backbutton navgraybutton' src={grayback} onClick={addBusiness} alt="" />
                                </Tooltip>
                            </span>
                        </Col>
                        <Col md={{ span: 11 }} className="subHeader1">
                            <table className="applicationTableDetails1">
                            <tr>
                                {/* <td>Type</td>
                                <td>:</td> */}
                                {localData.request_type_id === 0 && (
                                <td>Loan Application</td>)}
                                {localData.request_type_id === 1 && (
                                <td>Check Eligibility</td>)}
                                <td>&nbsp;|&nbsp;</td>
                                <td>Application Number</td>
                                <td>:</td>
                                <td>{localData.reference_id}</td>
                                <td>&nbsp;|&nbsp;</td>
                                <td>Loan Type</td>
                                <td>:</td>
                                <td>{localData.loan_type}</td>
                            </tr>
                            </table>
                        </Col>
                        {/* {selected.length > 1 && ( */}
                                {(
                                <Col md={{span: 1}}>
                                    <Button className="actionBtn" onClick={() => handleshowhidepdf()} style={{float:'right'}} >
                                        <i class="fa fa-user-plus" style={{ fontSize: "15px", zIndex : 9999 }}></i>
                                    </Button>
                                </Col>
                                 )}
                        {/* <Col md={{span:1}}>
                            <p>+</p>
                        </Col> */}
                    </Row>
                </div>
                <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={table_slNo}>#</TableCell>
                                <TableCell style={table_header}>Appointment Date</TableCell>
                                <TableCell style={table_header}>Appointment Time</TableCell>
                                {/* <TableCell style={table_header}>Number of docs To be Collected</TableCell> */}
                                <TableCell style={table_header}>Status</TableCell>
                                <TableCell style={table_header} colSpan={2}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myEvents.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage).map((item,i) =>{
                                const isItemSelected = isSelected(item.id);
                                return(
                                    <TableRow hover 
                                    onClick={(event) => handleClicked(event, item.id)}
                                    role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.id} selected={isItemSelected} className="datable" key={i}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{item.start_date}</TableCell>
                                        <TableCell>{item.start_time}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>
                                            <Row>
                                                <Col md={{ span: 12 }}>
                                                    <Row>
                                                        <Tooltip title="Edit" placement="bottom">
                                                            <Button className="actionBtn" onClick={() => handleEditData(item.id)}>
                                                                <i className="fas fa-user-edit" style={{ fontSize: "15px" }} />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="delete" placement="bottom">
                                                            <Button className="actionBtn" onClick={() => deleteAppointmentById(item.id)}>
                                                                <i class="fas fa-user-minus" style={{ fontSize: "13px" }}></i>
                                                            </Button>
                                                        </Tooltip>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Col>
                </Row>
                </div>
                </>
            )}
            <div className='btn_row_fixd'>
              <Row>
                <Col md={{ span: 10 }}></Col>
                <Col style={{ padding: "0%" }}></Col>
                <Col style={{ padding: "0%" }}></Col>
              
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={Back} />
                    <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      <img className='navgraybutton' src={grayback} onClick={handledata} />
                    </Tooltip>
                  </span>
                </Col>
                <Col style={{ padding: "0%" }}>
                  <span className="image">
                    <img className='navbutton' src={DraftNext} />
                    <Tooltip title="Save & Next" arrow>
                      <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </div>
         
        </Container>
    );
}