import React from "react";
import { Dropdown } from "semantic-ui-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Formik } from "formik";
import {
  TextField,
  TextareaAutosize,
  Button,
  Container,
  Grid,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  List,
  ListItem,
  Tooltip,
  IconButton,
  SvgIcon,
} from "@material-ui/core";
//import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBRow, MDBCardText, MDBCol } from 'mdbreact';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from "../../base_components/Typography";
import Icon from "@mdi/react";
import { loadCSS } from 'fg-loadcss';
import { mdiArrowRightBox, mdiArrowLeftBox } from "@mdi/js";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useStyled } from "./style";
import { letStartFormMenuItems } from "../../shared/constants";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import { mdiPlaylistPlus, mdiRefresh, mdiContentSaveAlert } from "@mdi/js";
import { MDBDataTableV5 } from 'mdbreact';

import { apiCalling } from '../../shared/constants'
// import { ReactComponent as Back } from "../../image/Back.svg";
// import { ReactComponent as Reset } from "../../image/Reset.svg";
// import { ReactComponent as SaveAsDraft } from "../../image/SaveAsDraft.svg";
// import { ReactComponent as Save } from "../../image/Save.svg";
// import { ReactComponent as Next } from "../../image/Next.svg";

export default function LetsGetStarted(props) {
  const classes = useStyled();
  const [value, setvalue] = React.useState();
  const [loantype, setloantype] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const [togglebutton1, settogglebutton1] = React.useState("apply for loan");
  const [togglebutton2, settogglebutton2] = React.useState("self employed");
  const [datatable, setDatatable] = React.useState({

    columns: [
      {
        label: 'Sl No.',
        field: 'slno',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'ApplyForLoan/Check Elgibility',
        field: 'ApplyForLoanCheckElgibility',
        width: 270,
      },
      {
        label: 'SelfEmployeed/Professional',
        field: 'SelfEmployeedProfessional',
        width: 270,
      },
      {
        label: 'Requirements',
        field: 'requirements',
        width: 200,
      },
      {
        label: 'Loan Type',
        field: 'loantype',
        width: 100,
      },
      {
        label: 'About Loan Type',
        field: 'aboutloantype',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: [
      {
        slno: '1',
        ApplyForLoanCheckElgibility: 'Apply For Loan',
        SelfEmployeedProfessional: 'Self Employeed',
        requirements: 'About Loan Requirements',
        loantype: 'Business Loan',
        aboutloantype: 'About Loan Type',
        action: 'Manage'
      },
      {
        slno: '2',
        ApplyForLoanCheckElgibility: 'Check Elgibility',
        SelfEmployeedProfessional: 'Professional',
        requirements: 'About Loan Requirements',
        loantype: 'Mortage Loan',
        aboutloantype: 'About Loan Type',
        action: 'Manage'
      }
    ],
  });

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    loantypecheck();

  }, []);


  var loantypearray = []

  const loantypecheck = () => {

    const loandata = {
      token: "qwerty1234",
    }


    axios.post(apiCalling.coderscorner + `server.php/api/getAllloanType`, loandata)
      .then(res => {
        // setdata(loanapplydata)
        // setgender(res.data.data)
        /* var genderobject = {
          key: "",
          value: "",
          flag: "",
          text: ""
        } */
        res.data.data.map((item) => {
          console.log('item', item)

          loantypearray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setloantype(loantypearray)
        console.log("loantypearray", loantypearray);

      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }


  const handleChange = (event) => {
    //setloanType(event.target.value);
  };

  const handledata = () => {
    props.handleBack();
  };
  const handleSubmiting = () => {
    props.handleNext();
  };

  const handleNextClick = () => {
    // const loancheck = localStorage.getItem('ApplyForLoanCheckElgibility'),
    //   loancheckelgibility = JSON.parse(ApplyForLoanCheckElgibility)
    // console.log('datarjjjjjjjjjjje', loancheckelgibility)
  }

  return (
    <Container maxWidth="lg">
      <div className="mini-container" style={{ marginTop: -30, padding: '15px' }}>
        <Row>
          <Col md={{ span: 12 }}>
            <Formik
              initialValues={{ ApplyForLoanCheckElgibility: "", SelfEmployeedProfessional: "", requirements: "", loantype: "", aboutloantype: "" }}
              // validationSchema={ApplyForLoanCheckElgibility}
              onSubmit={(values) => {
                console.log('kkkkk', values)
                localStorage.setItem('ApplyForLoanCheckElgibility', JSON.stringify(values))
                handleNextClick()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched
                /* and other goodies */
              }) => {
                //  console.log("values in formik", values);
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="content-header" style={{ position: 'unset' }}>
                      <Row>
                        <Col md={{ span: 6 }}>

                          <Typography
                            text="Let's Get Start"
                            styleType="C"
                          />

                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className="content-header-button ">


                            <Button
                              className={classes.linkdin}
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                              className={classes.button_others}>
                              <Icon path={mdiPlaylistPlus} title="save" size={1} />
                            </Button>


                            <Button
                              className={classes.button_div}
                              variant="outlined"
                              color="primary"
                              className={classes.button_others}

                            ><Icon path={mdiRefresh} title="reset" size={1} /></Button>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col md={{ span: 12 }}>
                        <Card>
                          <CardContent>
                            <Row>
                              <Col md={{ span: 12 }}>
                                <Typography styleType='A' text='Make Your Selection' />
                              </Col>

                              <Col md={{ span: 12 }}>
                                <Row>
                                  <Col md={{ span: 4 }}>
                                    <Box my={2}>
                                      <Grid container justify='space-between'>
                                        <Grid item md={10}>
                                          <ToggleButtonGroup
                                            value={togglebutton1}
                                            exclusive >
                                            <ToggleButton
                                              onClick={() => settogglebutton1("apply for loan")}
                                             // value='apply for loan'
                                             value= {values.ApplyForLoanCheckElgibility}
                                              aria-label='apply for loan'
                                              className={
                                                togglebutton1 === "apply for loan"
                                                  ? classes.checkButton
                                                  : null
                                              }>
                                              {togglebutton1 === "apply for loan" ? (
                                                "Apply For Loan" // <CheckCircleOutlineOutlinedIcon />
                                              ) : (
                                                  "Apply For Loan"  // <CancelOutlinedIcon />
                                                )}
                                            </ToggleButton>
                                            <ToggleButton
                                              onClick={() => settogglebutton1("check eligibility")}
                                             // value='check eligibility'
                                             value= {values.ApplyForLoanCheckElgibility}
                                              aria-label='check eligibility'
                                              className={
                                                togglebutton1 === "check eligibility"
                                                  ? classes.checkButton
                                                  : null
                                              }>
                                              {togglebutton1 === "check eligibility" ? (
                                                "check eligibility" // <CheckCircleOutlineOutlinedIcon />
                                              ) : (
                                                  "check eligibility"   // <CancelOutlinedIcon />
                                                )}
                                            </ToggleButton>
                                          </ToggleButtonGroup>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Col>
                                  <Col md={{ span: 4 }}>
                                    <Box my={2}>
                                      <Grid container justify='space-between'>
                                        <Grid item md={10}>
                                          <ToggleButtonGroup
                                            value={togglebutton2}
                                            exclusive
                                          // onChange={handletoggle2}
                                          >
                                            <ToggleButton
                                              onClick={() => settogglebutton2("self employed")}
                                             // value='self employed'
                                             value= {values.SelfEmployeedProfessional}
                                              aria-label='self employed'
                                              className={
                                                togglebutton2 === "self employed"
                                                  ? classes.checkButton
                                                  : null
                                              }>
                                              {togglebutton2 === "self employed" ? (
                                                "Self Employed"  //<CheckCircleOutlineOutlinedIcon />
                                              ) : (
                                                  "Self Employed" //<CancelOutlinedIcon />
                                                )}
                                            </ToggleButton>
                                            <ToggleButton
                                              onClick={() => settogglebutton2("professional")}
                                              //value='professional'
                                              value= {values.SelfEmployeedProfessional}
                                              aria-label='professional'
                                              className={
                                                togglebutton2 === "professional"
                                                  ? classes.checkButton
                                                  : null
                                              }>
                                              {togglebutton2 === "professional" ? (
                                                "Professional"   //<CheckCircleOutlineOutlinedIcon />
                                              ) : (
                                                  "Professional"   //<CancelOutlinedIcon />
                                                )}
                                            </ToggleButton>
                                          </ToggleButtonGroup>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={{ span: 12 }}>
                                {/* <Grid	>
                          <Box pl={2}>
                            <Typography styleType='B' text='Requirements' />
                            <TextField
                              id='outlined-multiline-static'
                              multiline
                              className='text_start'
                              rows={6}
                              variant='outlined'
                            />
                          </Box>
                        </Grid> */}

                                <Grid>
                                  <Typography styleType='B' text='Requirements' />
                                  <Box pl={2}>
                                    {/* <TextareaAutosize
                              id='outlined-textarea'
                              multiline
                              rows={6}
                              variant='outlined'
                            // value={loantype.description}
                            /> */}
                                    <CKEditor
                                      editor={ClassicEditor}
                                      onInit={editor => { }}
                                      value = {values.requirements}
                                    />                          </Box>
                                </Grid>

                                <Grid container justify='' direction='column'>
                                  <Grid style={{ marginTop: "1%" }}>
                                    <Typography text='Loan Type' styleType='B' />
                                  </Grid>
                                  <Grid>
                                    <List component='div' disablePadding>
                                      <ListItem>
                                        <Dropdown
                                          placeholder='Loan Type'
                                          id='type'
                                          name='type'
                                          fluid
                                          search
                                          selection
                                          // value={values.type}
                                          // onBlur={() => setFieldTouched("type", true)}
                                          onChange={(e, { value }) => setvalue(value)}
                                          options={loantype}
                                        // className={errors.type && touched.type ? classes.required : values.type == '' ? classes.reqired : classes.man}
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                </Grid>

                                <Grid>
                                  <Typography styleType='B' text='About Loan Type' />
                                  <Box pl={2}>
                                    {/* <TextareaAutosize 
                                id='outlined-textarea'
                                multiline
                                rows={6}
                                variant='outlined'
                              // value={loantype.description}
                              /> */}

                                    <CKEditor
                                      editor={ClassicEditor}
                                      onInit={editor => { }}
                                      value = {values.aboutloantype}
                                      //value={loantype.description}
                                      //onChange={handleChange}
                                      onChange={(e, { value }) => setvalue(value)}
                                    />
                                  </Box>
                                </Grid>
                              </Col>
                            </Row>

                            <Row style={{ marginBottom: 7, marginTop: 75 }}>
                              <Col md={{ span: 12 }}><Typography text="Details" styleType="E" /></Col>
                            </Row>
                            <MDBDataTableV5 hover
                              entriesOptions={[5, 10, 20, 30]}
                              entries={5}
                              pagesAmount={4}
                              data={datatable}
                              pagingTop
                              searchTop
                              searchBottom={false}
                              barReverse
                            />

                          </CardContent>
                        </Card>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </Container>
  );

}
