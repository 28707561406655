import React from 'react';
import { Route, Link, HashRouter } from 'react-router-dom'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import Forget from './components/Forget'
import ProfileSteper from './components/ProfileSteper'
import Registeration from './components/Registration'
import PinBankMapping from './components/PinBankMapping'
import PinBusinessMap from './components/PinBusinessChannelMap'
import Onboarding from './components/Onboarding'
import OnBoardingBank from './components/OnBoardingBank'
import MasterLevelB from './components/MasterLevelB'
import MasterTables from './components/MasterTables'
import MasterTables1 from './components/MasterTables1'
import ApprovalWorkflow from './components/Approvalworkflow'
import ApprovalWorkflow1 from './components/ApprovalWorkflow1'
import Test from './components/Test'
import Testdatatable from './components/Testdatatable'
import LetsGetStartedAdminLinkForm from './components/LetsGetStartedAdminLinkForm'
import FinancialBackend from './components/FinancialBackend'
import BankBackend from './components/BankBackend'
//import Registeration from './components/Registration'
import SignUpOtp from './components/SignUpOtp/index';
import RegisterationLoader from './components/RegisterationLoader/index.js';
import AssetsForm from './components/AssetsForm';
import Loan from './components/Loan';
import BankingForm from './components/BankingForm';
import PersonalInfo from './components/FinanceForm';
import WorkingCapitalForm from './components/WorkingCapitalForm';
import GST from './components/GSTForm';
import Banking from './components/MainPage2';
import ApplicationFatCheck from './components/ApplicationFatCheck';
import ApplicationFinScoring from './components/ApplicationFinScoring';
import ApplicationFinScoring2 from './components/ApplicationFinScoring2';
import ApplicationLoanEligibility from './components/ApplicationLoanEligibility';
import DocumentUpload from './components/DocumentUpload';
import AberratonQuastionarrie from './components/AberratonQuastionarrie';
import CrifApiLoanRequest from './components/CrifApiLoanRequest';
import FatCheck2 from './components/FatCheck2';
import bankDataPullResponse from './components/PerfiosCallBack';
import CpaMainPage from './components/CpaMainPage';
import BankingCriffCallBAckUrl from './components/BankingCriffCallBAckUrl';
import ApplicationSummaryView from './components/ApplicationSummaryView';
import Calender from './components/Calender';
import Status from './components/Status';
import BookAppointment from './components/BookAppointment'
import BookCalEve from './components/BookCalEve';
import CrifResult from './components/CrifResult';
import AssetLAP from './components/AssetLAP';
import InternalLogin from './components/InternalLogin/InternalLogin';
import BankerMainPage from './components/BankerPage';
import DsaMainPage from './components/DsaMainPage';
import DocsCollected from './components/DocsCollected';
import DocumentsCollected from './components/DocumentsCollected';
import BankDocAndBookApt from './components/BankDocAndBookApt';
import DocumentsCollector from './components/DocumentsCollector';
import BankerRoleBookAppointment from './components/BankerRoleBookAppointment';
import PerfiosPull from './components/PerfiosStandAlone';
import AnalystMainPage from './components/AnalystMainPage';
import CrifLogin from './components/CrifLogin';
import CrifLoanRequestPost from './components/CrifLoanRequestPost';
import  AdminDashboard from './AdminComponents/AdminDashboard';
import TestComponent from './components/TestComponent';
import AdminQuestionsAdd from './components/AdminQuestionsAdd';
import AppSummaryPreview from './components/AppSummaryPreview';


export default function Navigation(props) {
    return (
        <HashRouter>
            <div>
                <Route history={props.history} path='/' component={SignIn} strict exact ></Route>
                <Route history={props.history} path='/SignUp' component={SignUp} strict exact ></Route>
                <Route history={props.history} path='/ForgetPass' component={Forget} strict exact ></Route>
                <Route history={props.history} path='/ProfileSteper' component={ProfileSteper} strict exact ></Route>
                <Route history={props.history} path='/Registration' component={Registeration} strict exact ></Route>
                <Route history={props.history} path='/PinBankMapping' component={PinBankMapping} strict exact ></Route>
                <Route history={props.history} path='/PinBusinessMap' component={PinBusinessMap} strict exact ></Route>
                <Route history={props.history} path='/Onboarding' component={Onboarding} strict exact ></Route>
                <Route history={props.history} path='/OnBoardingBank' component={OnBoardingBank} strict exact ></Route>
                <Route history={props.history} path='/MasterLevelB' component={MasterLevelB} strict exact ></Route>
                <Route history={props.history} path='/MasterTables' component={MasterTables} strict exact ></Route>
                <Route history={props.history} path='/MasterTables1' component={MasterTables1} strict exact ></Route>
                <Route history={props.history} path='/ApprovalWorkflow' component={ApprovalWorkflow} strict exact ></Route>
                <Route history={props.history} path='/ApprovalWorkflow1' component={ApprovalWorkflow1} strict exact ></Route>
                <Route history={props.history} path='/LetsGetStartedAdminLinkForm' component={LetsGetStartedAdminLinkForm} strict exact></Route>
                <Route history={props.history} path='/BankBackend' component={BankBackend} strict exact></Route>
                <Route history={props.history} path='/FinancialBackend' component={FinancialBackend} strict exact></Route>
                <Route path='/Test' component={Test} strict exact></Route>
                <Route path='/Testdatatable' component={Testdatatable} strict exact></Route>
                <Route history={props.history} path='/SignupVerification' component={SignUpOtp} strict exact></Route>
                <Route history={props.history} path='/loading' component={RegisterationLoader} strict exact></Route>
                <Route history={props.history} path='/asset' component={AssetsForm} strict exact></Route>
                <Route history={props.history} path='/bankingform' component={BankingForm} strict exact></Route>
                <Route history={props.history} path='/loanForm' component={Loan} strict exact></Route>
                <Route history={props.history} path='/workingCapital' component={WorkingCapitalForm} strict exact></Route>
                <Route history={props.history} path='/gst' component={GST} strict exact></Route>
                <Route history={props.history} path='/loan' component={Loan} strict exact></Route>
                <Route history={props.history} path='/finance' component={PersonalInfo} strict exact ></Route>
                <Route history={props.history} path='/dashboard' component={Banking} strict exact></Route>
                <Route history={props.history} path='/ApplicationFatCheck' component={ApplicationFatCheck} strict exact></Route>
                <Route history={props.history} path='/ApplicationFinScoring' component={ApplicationFinScoring} strict exact></Route>
                <Route history={props.history} path='/ApplicationLoanEligibility' component={ApplicationLoanEligibility} strict exact></Route>
                <Route history={props.history} path='/DocumentUpload' component={DocumentUpload} strict exact></Route>
                <Route history={props.history} path='/AberratonQuastionarrie' component={AberratonQuastionarrie} strict exact></Route>
                <Route history={props.history} path='/header' component={AberratonQuastionarrie} strict exact></Route>
                <Route history={props.history} path='/LoanRequestOld' component={CrifApiLoanRequest} strict exact></Route>
                <Route history={props.history} path='/ApplicationFinScoring2' component={ApplicationFinScoring2} strict exact></Route>
                <Route history={props.history} path='/FatCheck2' component={FatCheck2} strict exact></Route>
                <Route history={props.history} path='/bankDataPullResponse' component={bankDataPullResponse} strict exact></Route>
                <Route history={props.history} path='/CpaMainPage' component={CpaMainPage} strict exact></Route>
                <Route history={props.history} path='/BankingCriffCallBAckUrl' component={BankingCriffCallBAckUrl} strict exact></Route>
                <Route history={props.history} path='/ApplicationSummaryView' component={ApplicationSummaryView} strict exact></Route>
                <Route history={props.history} path='/Calender' component={Calender} strict exact></Route>
                <Route history={props.history} path='/BookCalEve' component={BookCalEve} strict exact></Route>
                <Route history={props.history} path='/Status' component={Status} strict exact></Route>
                <Route history={props.history} path='/BookAppointment' component={BookAppointment} strict exact></Route>
                <Route history={props.history} path='/CrifResult' component={CrifResult} strict exact></Route>
                <Route history={props.history} path='/AssetLAP' component={AssetLAP} strict exact></Route>
                <Route history={props.history} path='/InternalLogin' component={InternalLogin} strict exact></Route>
                <Route history={props.history} path='/BankerMainPage' component={BankerMainPage} strict exact></Route>
                <Route history={props.history} path='/DsaMainPage' component={DsaMainPage} strict exact></Route>
                <Route history={props.history} path='/DocsCollected' component={DocsCollected} strict exact></Route>
                <Route history={props.history} path='/DocumentsCollected' component={DocumentsCollected} strict exact></Route>
                <Route history={props.history} path='/BankDocAndBookApt' component={BankDocAndBookApt} strict exact></Route>
                <Route history={props.history} path='/DocumentsCollector' component={DocumentsCollector} strict exact></Route>
                <Route history={props.history} path='/BankerRoleBookAppointment' component={BankerRoleBookAppointment} strict exact></Route>
                <Route history={props.history} path='/perfiosPull' component={PerfiosPull} strict exact></Route>
                <Route history={props.history} path='/AnalystMainPage' component={AnalystMainPage} strict exact></Route>
                <Route history={props.history} path='/CrifLogin' component={CrifLogin} strict exact></Route>
                <Route history={props.history} path='/LoanRequest' component={CrifLoanRequestPost} strict exact></Route>
                <Route history={props.history} path='/AdminDashboard' component={AdminDashboard} strict exact></Route>
                <Route history={props.history} path='/TestComponent' component={TestComponent} strict exact></Route>
                <Route history={props.history} path='/CrifResult' component={CrifResult} strict exact></Route>
                <Route history={props.history} path='/AdminQuestionsAdd' component={AdminQuestionsAdd} strict exact></Route>
                
                <Route history={props.history} path='/AppSummaryPreview' component={AppSummaryPreview} strict exact></Route>
            </div>
        </HashRouter>
    );

}
