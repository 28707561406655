import React, { useState } from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStyles1 } from "./style";
import Typography1 from "../../base_components/Typography";
import axios from "axios";
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Reset from '../../image/buttons/reset.png';
import TCIcon from '../../image/icons/tc.png';

import Warning from '../../image/icons/warning.png';
import IDFC from '../../image/icons/IDFC.jpg';
import IDF from '../../image/icons/idf.png';
import FileCheck from '../../image/icons/file.png';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import grayback from '../../image/buttons/grayback.png';
import grayreset from '../../image/buttons/grayreset.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from "react-bootstrap/Modal";
import { red } from '@material-ui/core/colors';
import MuiAlert from '@material-ui/lab/Alert';
import { SnackPosition } from "../../shared/constants";
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import pdfimage from '../../image/pngicons/pdf.jpeg';
import imageimg from '../../image/pngicons/image.jpeg';
import excelimg from '../../image/pngicons/excel.jpeg';
import view from '../../image/pngicons/view.jpeg';
import addbutton from '../../image/pngicons/plussign.png';
import deleterec from '../../image/pngicons/delete.png';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BankDocAndBookApt from '../BankDocAndBookApt/index';

import DialogContentText from '@material-ui/core/DialogContentText';
import { textAlign } from '@mui/system';

const GreenCheckbox = withStyles({
  root: {
    color: "black",
    fontSize: "20px",
    '&$checked': {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



const ApplicationLoanEligibility = (props) => {
  const classes = useStyles1();
  const classes1 = useStyles();
  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    checkIfRequest();
    getAllBankList();
    getAllSavedLoan();
    getValidationConfiguration();
    getAllOverRuleList();
  }, []);
  const [requestDetails, setRequestDetails] = React.useState();
  const [appEligibleFlag, setAppElegibleFlag] = React.useState(false);
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }


  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));

  const tableCellWidth = {
    width: "20%",
    border: "0px"
  };

  const handledata = () => {
    props.handleBack();
  };
  const SaveasDraft = () => {
    props.handleNext();
  }

  const [localStore, setLocalStore] = useState();
  const [bankArray, setBanksArray] = useState([]);
  const [overRuleArray, setOverRulesArray] = useState([]);

  const getAllOverRuleList = async () => {
    
    let lData = JSON.parse(localStorage.getItem('rquestIdDetails'));
    setLocalStore(lData);
    let folder_num = lData.folder_no;
    let data = {
      "folder_no": folder_num
    }

    await axios.post(apiCalling.coderscorner + 'server.php/api/getOverRule', data).then(result => {
      //   
      if (result.data.status) {
        setOverRulesArray(result.data.data);
        // setAppElegibleFlag(true);
      }else{
        // setAppElegibleFlag(false);
      }

    }).catch(error => {
      console.log(error);
    })
  }

  const getAllBankList = async () => {
    
    let lData = JSON.parse(localStorage.getItem('rquestIdDetails'));
    setLocalStore(lData);
    let folder_num = lData.folder_no;
    let data = {
      "folder_no": folder_num
    }

    await axios.post(apiCalling.coderscorner + 'server.php/api/getLoanEligibility', data).then(result => {
      //   
      if (result.data.status) {
        console.log(result);
        setBanksArray(result.data.data);
        setAppElegibleFlag(true);
      }else{
        setAppElegibleFlag(false);
      }

    }).catch(error => {
      console.log(error);
    })
  }

  const [submitValues, setSubmitValues] = useState(true);

  const [banker_Details, setBanker_Details] = React.useState([]);
  const cpaid = JSON.parse(localStorage.getItem("userdata"));

  const applicationDetails = JSON.parse(localStorage.getItem("rquestIdDetails"));


  const [currentSelected, setCurrentSelected] = React.useState([]);
  const [slectedBank, setSelectedBank] = React.useState([]);

  const sendNotificationToClient = async () => {
    const checkuserloggeddata = localStorage.getItem("userdata");
    const req_id = JSON.parse(localStorage.getItem("rquestIdDetails"));
    let userId = JSON.parse(checkuserloggeddata);
    let data = {
      loan: currentSelected,
      user_id: userId.id,
      email: userId.email,
      mobile: userId.mobile,
      request: req_id.id
    }
    let url = apiCalling.coderscorner + 'server.php/api/sendNotificationToClient';
    await axios.post(url, data).then(result => {
      console.log("Success", result)
    }).catch(error => {
      console.log(error)
    });
  }

  const sendNotification = async () => {
    console.log(currentSelected)
    const checkuserloggeddata = localStorage.getItem("userdata");
    const req_id = JSON.parse(localStorage.getItem("rquestIdDetails"));
    let userId = JSON.parse(checkuserloggeddata);
    let data = {
      loan: currentSelected,
      user_id: userId.id,
      email: userId.email,
      mobile: userId.mobile,
      requestId: req_id.id
    }
    let url = apiCalling.coderscorner + 'server.php/api/sendNotification';
    await axios.post(url, data).then(result => {
      console.log("Success", result)
     // detailsDocumentCheck();
    }).catch(error => {
      console.log(error)
    });
  }

  const selectBank = (item) => {

    
    setCurrentSelected(item);
    console.log(item);
    let date11 = new Date();
    let pdob = moment(date11).format('YYYY-MM-DD');
    let obj = {
      financial_institution_id: item.financier_id,
      submission_date: pdob,
      submitted_by: cpaid.id,
      loan_type_id: item.loan_type_id,
      location:560001,
      //location: applicationDetails.pin_id,
      product_id: item.product_id
    }
    console.log("location obj",obj);
    let arr = [...banker_Details];
 

    setBanker_Details(arr);

    let fArr = [...slectedBank];

    if (fArr.includes(item.product_id)) {
      fArr.splice(fArr.indexOf(item.product_id), 1)
    } else {
      fArr.push(item.product_id);
      arr.push(obj);
    }
    setSelectedBank(fArr);
    console.log(banker_Details);
  }


  const saveBanks = async () => {
    let data = {
      application_ref_no: applicationDetails.folder_no,
      user_id: applicationDetails.user_id,
      banker_Details: banker_Details
    }
    console.log(data);

    let url = apiCalling.coderscorner + 'server.php/api/saveBankersDetails';
    await axios.post(url, data).then(result => {
      console.log(result);
      setcolorMessage("success");
      setShowMessage(result.data.message);
      setsavenextmessage(true);
     // detailsDocumentCheck();
    }).catch(error => {
      console.log(error)
    });

  }


  const [savenextmessage, setsavenextmessage] = React.useState(false);

  const [colorMessage, setcolorMessage] = React.useState();
  const [showMessage, setShowMessage] = React.useState();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const [bankAvailable, setbankAvailable] = React.useState(false);
  const [allBanksData, setAllBanksData] = React.useState([]);

  const [showSeletecedBanks, setShowSelectedBanks] = React.useState(true);

  //get slected banks

  const getAllSavedLoan = () => {
    
    let data = {
      "application_ref_no": applicationDetails.folder_no
    }

    axios.post( apiCalling.coderscorner + "server.php/api/getBankerdetails", data).then(result => {
      if (result.data.status == true) {
        setbankAvailable(true);
        setAllBanksData(result.data.data);
        if (result.data.data.length > 0) {
          setShowSelectedBanks(true);
        }
        let productArr = [];
        if (result.data.data.length > 0) {
          let resultArr = result.data.data;
          for (let i = 0; i < resultArr.length; i++) {
            productArr.push(resultArr[i].product_id);

          }
          setSelectedBank(productArr);
        }

        console.log(result);
      }
    }).catch(error => {
      console.log(error);
    });
  }



  /// doc upload codes
  const [docBase64, setDocBase64] = React.useState();
  const [showmodal, setshowmodal] = React.useState(false);

  const handleshowhidepdf = (base64, fileType) => {
    setDocBase64("https://interoperability.blob.core.windows.net/files/MS-DOC/%5bMS-DOC%5d-150316.docx");
    let fileExt = fileType.split('.');
    //if(fileExt[fileExt.length-1]=="sheet"){
    setshowmodal(true);
    //  }
  }


  const handleclosePdf = () => {
    setshowmodal(false);
  }

  const [maxFileSize, setMaxFileSize] = React.useState([]);
  const [maxFileSizeMsg, setMaxFileSizeMsg] = React.useState([]);
  const [allowedFileType, setAllowedFileType] = React.useState([]);

  const getValidationConfiguration = async () => {
    let data = {
      parameter_name: ['max_upload_size', 'allowed_file_type']
    }

    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getGeneralSettingForMultiple',
        data
      )
      .then(result => {
        const res = result.data.data;
        setMaxFileSize(res.max_upload_size[0].parameter_value * 1048576);
        setMaxFileSizeMsg(`File size exceeds maximum limit of 2${res.max_upload_size[0].parameter_value}MB.`)
        setAllowedFileType(res.allowed_file_type[0].parameter_value.split(','));
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [dropFileText, setDropFileText] = React.useState("Drag & Drop file or Browse");

  const getUploadeDocOfUser = async (item) => {
    
    console.log(item);
    const docdata = {
      "user_id": applicationDetails.user_id,
      "parent_id": applicationDetails.business_id,
      "parent_type_id": 1
    }
    let myArr = [];
    let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser';
    await axios.post(apiCalling.coderscorner + 'server.php/api/getAllDocumentsByUserforBank', docdata).then((result) => {
      console.log("rsutl-----------------", result);
      if (result.data.data.length > 1) {
        result.data.data.map((item) => {
          myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
        })
        console.log("my document array----->", myArr);
        setFilelist(myArr);

      } else {
        detailsDocument(item);
      }
    }).catch(error => {
      ErrorCatch(userId.id, error, api_path, 'post', docdata, userId, userId.id);
      console.log(error)
    })
  }

  const [fileList, setFilelist] = React.useState([]);
  const checkuserloggeddata = localStorage.getItem("userdata");
  let userId = JSON.parse(checkuserloggeddata);
  const detailsDocument = async (item) => {
    
    const docdata = {
      // user_id: userId.id,
      // parent_id: props.formdetails.parentId,
      // parent_type_id: props.formdetails.parentTypeId,
      // form: props.formdetails.formId,
      // required_phase: "172"
    }

    let data = {

      user_id: applicationDetails.user_id,
      required_phase: 172,
      bank_id: item.financier_id
    }

    let api_path = apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`;

    await axios.post(apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`, data).then((result) => {
      console.log("Document data...!!!", result.data);
      let myArr = [];
      console.log(result.data)
      result.data.data.map((item) => {
        myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
      })
      setFilelist(myArr);
    }).catch(error => {
      ErrorCatch(userId.id, error, api_path, 'post', docdata, userId.id, userId.id);
      console.log(error)
    })
  }

  const fileSetInArray = async (e, index, kIndex, checkListId) => {

    let temp_fileList = [...fileList];
    if (e.length > 0) {
      let temp_file = { ...temp_fileList[index] };
      var date = new Date();
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var modifiedDate = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
      let reader = new FileReader();
      reader.readAsDataURL(e[0]);
      reader.onload = function () {
        temp_file.documentArray[kIndex].file_name = e[0].name;
        temp_file.documentArray[kIndex].file_type = e[0].type;
        temp_file.documentArray[kIndex].modified_date = modifiedDate;
        temp_file.documentArray[kIndex].document_upload_id = "";
        temp_file.documentArray[kIndex].base64String = reader.result;
        temp_fileList[index] = temp_file;
        setFilelist(temp_fileList);
        uploadDoc(e[0].name, e[0].type, checkListId, reader.result);
      };

      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  const callFileDeleteOption = (index) => {
    let temp_fileList = [...fileList];
    let temp_file = { ...temp_fileList[index] };
    temp_file.file_name = "";
    temp_file.file_type = "";
    temp_file.modified_date = "";
    temp_file.document_upload_id = "";
    temp_fileList[index] = temp_file;
    setFilelist(temp_fileList);
  }
  // add doc based on fileList array index value from table
  const [arrOne, setArrOne] = useState([1]);
  const [checkList, setCheckList] = useState();
  const addDoc = (id) => {
    let newArr = [...fileList];
    for (let i = 0; i < newArr.length; i++) {
      if (id == i) {
        fileList[id].documentArray.push({
          file_name: "",
          base64String: "",
          file_type: "",
          modified_date: ""
        });
        break;
      }
    }
    setFilelist(newArr)
    console.log("list2", fileList)
  }
  // remove document  where mainId is fileList[] array id and subId is docuMentArray index valuex
  const removeDoc = async (mainId, subId, document_uplaod_id) => {
    let aaaa;
    let newArr = [...fileList];
    for (let i = 0; i < newArr.length; i++) {
      if (mainId == i) {
        newArr[mainId].documentArray.splice(subId, 1);
        break;
      }
    }
    if (subId === 0) {
      newArr[mainId].documentArray.push({
        file_name: "",
        base64String: "",
        file_type: "",
        modified_date: ""
      });
    }
    let data = {
      id: document_uplaod_id
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/deleteDocById';
    await axios.post(apiCalling.coderscorner + 'server.php/api/deleteDocById', data).then((result) => {
      console.log(result)
    }).catch(error => {
      ErrorCatch(document_uplaod_id, error, api_path, 'post', data, document_uplaod_id, document_uplaod_id);
      console.log("error", error)
    })

    setFilelist(newArr)
    console.log("list3", fileList)
    // 
    // const list = [...arrOne];
    // list.splice(index, 1);
    // setArrOne(list);
  }

  const uploadDoc = (name, filetype, checkListId, docbase64) => {
    let data = {
      user_id: applicationDetails.user_id,
      parent_id: applicationDetails.business_id,
      parent_type_id: 1,
      document_checklist_id: checkListId,
      name: name,
      file_type: filetype,
      document: docbase64
    }
    let api_path = apiCalling.coderscorner + `server.php/api/savedocuments`;
    axios.post(apiCalling.coderscorner + `server.php/api/savedocuments`, data).then(
      result => {
        console.log(result);
        setsavenextmessage(true);
      }
    ).catch((error) => {
      ErrorCatch(userId.id, error, api_path, 'post', data, userId.id, userId.id);
      console.log(error)
    })

  }


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState("");


  const [docCheckList, setDocCheckList] = React.useState([]);
  const getBankRelatedDocs = async () => {

    
    let data = {

      "user_id": applicationDetails.user_id,
      "required_phase": 172,
      "bank_id": 166
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getBankDocuments', data).then(result => {
      console.log(result);
      setDocCheckList(result.data.data);

    }
    ).catch(error => {
      console.log(error);
    })
  }

  const [showNextPage, setShowNextPage] = React.useState(false);

  const detailsDocumentCheck = async (item) => {
    

    let data = {
      "user_id": localData.user_id,
      "required_phase": 173,
      "bank_id": [166, 174]
    }

    let api_path = apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`;

    await axios.post(apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`, data).then((result) => {
      if (result.data.status == true) {
        setShowNextPage(true);
      }
    }).catch(error => {
      ErrorCatch(userId.id, error, api_path, 'post', data, userId.id, userId.id);
      console.log(error)
    })
  }


  let  image_url = apiCalling.coderscorner + "/public/partner_financier_images/";
  


  return (
    <Container maxWidth="lg">


      {showNextPage === false ? (

        <div className="mini-container mg_btm_set">

          {appEligibleFlag == false ? 
            
            (<div  style ={{margnTop:"10%"}}>

            <div className="row" style ={{margnTop:"10%"}}>
              <div className="col-md-12">
                <div className="error-template">
                  <h1>
                    Oops!</h1>
                  <h2>
                   Sorry, You are not eligible</h2>
                  <div className="error-details">
                   Please go back to Questionarrie page and submit valid Answers
                  </div>
                  <div className="error-actions">
                    <button  className="btn btn-primary btn-lg" onClick = {()=>{
                      props.handleBack(2);    
                    }}>
                       Questionarrie </button>
                      <a href="http://www.jquery2dotnet.com" className="btn btn-default btn-lg"><span className="glyphicon glyphicon-envelope"></span> Contact Support </a>
                  </div>
                </div>
              </div>
            </div>

          </div>) : (<div>

            <Row>
              <Col md={{ span: 12 }}>
                <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
                  onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                    vertical: SnackPosition.vertical,
                    horizontal: SnackPosition.horizontal
                  }}>
                  <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                    {showMessage}
                  </Alert>
                </Snackbar>
                <div className="content-header">

                  <Row>
                    <Col md={{ span: 12 }} className="mainheader1">
                      <Typography1 text="Eligibility List" styleType="C" />
                    </Col>
                    <Col md={{ span: 12 }} className="subHeader1">
                      <table className="applicationTableDetails1">
                        <tr>
                          {/* <td>Type</td>
                      <td>:</td> */}
                          {localData.request_type_id === 0 && (
                            <td>Loan Application</td>)}
                          {localData.request_type_id === 1 && (
                            <td>Check Eligibility</td>)}
                          <td>&nbsp;|&nbsp;</td>
                          <td>Application Number</td>
                          <td>:</td>
                          <td>{localData.reference_id}</td>
                          <td>&nbsp;|&nbsp;</td>
                          <td>Loan Type</td>
                          <td>:</td>
                          <td>{localData.loan_type}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div>

              {showSeletecedBanks ? (<>
                <Col md={{ span: 12 }} className="systemClass">
                  <h4>System Recommendation</h4>
                </Col>
                {bankArray.map((item) => (
                  <Row style={{marginTop :"15px"}}>
                    <GreenCheckbox
                      // icon={<CircleUnchecked />} 
                      onChange={() => {
                        selectBank(item);
                      }}
                      checked={slectedBank.find((id) => id == item.product_id) == undefined ? false : true}
                    //  checkedIcon={<CircleChecked />}
                    />
                    <TableContainer style={{ marginTop: "-3%" }}>
                      <Table size="small" style={{ marginLeft: "3%", width: "97%" }}>
                        <TableBody style={{ border: "1px solid #eee" }}>
                          <TableRow>
                            <TableCell style={tableCellWidth}>
                              Bank Name
                              {/* <img alt="bank_bank"  style={{width:"35%",height:"30%",padding:"3px"}} src={image_url+item.path}></img> */}
                            </TableCell>
                            <TableCell style={tableCellWidth}>Product Name</TableCell>
                            <TableCell style={tableCellWidth}>Approved Amount</TableCell>
                            <TableCell style={tableCellWidth}>Rate of Interest</TableCell>
                            <TableCell style={tableCellWidth}>EMI</TableCell>
                            <TableCell rowSpan="2" style={tableCellWidth}>
                              <img alt="bank_bank" width="10%" src={Warning}
                                onHover
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Product Information");
                                // }}

                                onMouseOver={() => {
                                  console.log("hello");
                                  // setShowSelectedBanks(!showSeletecedBanks);
                                  // getUploadeDocOfUser(item);
                                  handleClickOpen();
                                  setModalContent(item.description);
                                  setModalTitle("Product Information");
                                  setDocCheckList([])
                                }}

                              //  onMouseOut ={ ()=>{handleClose()}}

                              ></img>&nbsp;
                              <img alt="bank_bank" width="10%" src={TCIcon}
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Terms and Conditions");
                                // }}

                                onMouseEnter={() => {
                                  handleClickOpen();
                                  setModalContent(item.description);
                                  setModalTitle("Terms and Conditions");
                                  setDocCheckList([]);
                                }}

                              ></img>&nbsp;
                              <img alt="bank_bank" width="10%" src={FileCheck}
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Document CheckList");
                                // }}


                                onMouseEnter={() => {
                                  handleClickOpen();
                                  setModalContent("");
                                  getBankRelatedDocs(item);
                                  setModalTitle("Required Documents");
                                }}

                              ></img>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={tableCellWidth}><b style={{fontSize:"14px",padding:"2px"}}>{item.financiername}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>{item.productname}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>INR {item.appln_approved_amt}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>{item.interest_rate}</b></TableCell>
                            <TableCell style={tableCellWidth}><b></b></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Row>

                ))}

                <br/><br/><br/>
                <Col md={{ span: 12 }} className="overRuleClass">
                  <h4>Overrule System Recommendation</h4>
                </Col>
                {overRuleArray.length == 0? (<center><h4 style={{textAlign: 'center'}}>No Data Found!!</h4></center>) :null}
                {overRuleArray.length > 0? (<Row style={{marginTop :"15px"}}>
                <TableContainer style={{ marginTop: "3%" }}>
                      <Table size="small" style={{ marginLeft: "3%", width: "97%" }}>
                        <TableBody>
                          <TableRow>
                            <TableCell style={tableCellWidth}>
                              Bank Name
                              {/* <img alt="bank_bank"  style={{width:"35%",height:"30%",padding:"3px"}} src={image_url+item.path}></img> */}
                            </TableCell>
                            <TableCell style={tableCellWidth}>Product Name</TableCell>
                            <TableCell style={tableCellWidth}>Approved Amount</TableCell>
                            <TableCell style={tableCellWidth}>Rate of Interest</TableCell>
                            <TableCell style={tableCellWidth}>EMI</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer></Row>) :null}
                {overRuleArray.map((item) => (
                  <Row style={{marginTop :"15px"}}>
                    
                    <GreenCheckbox
                      // icon={<CircleUnchecked />} 
                      onChange={() => {
                        selectBank(item);
                      }}
                      checked={slectedBank.find((id) => id == item.product_id) == undefined ? false : true}
                    //  checkedIcon={<CircleChecked />}
                    />
                    <TableContainer style={{ marginTop: "-3%" }}>
                      <Table size="small" style={{ marginLeft: "3%", width: "97%" }}>
                        <TableBody style={{ border: "1px solid #eee" }}>
                          <TableRow>
                            {/* <TableCell style={tableCellWidth}>
                              Bank Name
                            </TableCell>
                            <TableCell style={tableCellWidth}>Product Name</TableCell>
                            <TableCell style={tableCellWidth}>Approved Amount</TableCell>
                            <TableCell style={tableCellWidth}>Rate of Interest</TableCell>
                            <TableCell style={tableCellWidth}>EMI</TableCell> */}
                            
                          </TableRow>
                          <TableRow>
                            <TableCell style={tableCellWidth}><b style={{fontSize:"14px",padding:"2px"}}>{item.financiername}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>{item.productname}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>INR {item.appln_approved_amt}</b></TableCell>
                            <TableCell style={tableCellWidth}><b>{item.interest_rate}</b></TableCell>
                            <TableCell rowSpan="2" style={tableCellWidth}>
                              <img alt="bank_bank" width="10%" src={Warning}
                                onHover
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Product Information");
                                // }}

                                onMouseOver={() => {
                                  console.log("hello");
                                  // setShowSelectedBanks(!showSeletecedBanks);
                                  // getUploadeDocOfUser(item);
                                  handleClickOpen();
                                  setModalContent(item.description);
                                  setModalTitle("Product Information");
                                  setDocCheckList([])
                                }}

                              //  onMouseOut ={ ()=>{handleClose()}}

                              ></img>&nbsp;
                              <img alt="bank_bank" width="10%" src={TCIcon}
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Terms and Conditions");
                                // }}

                                onMouseEnter={() => {
                                  handleClickOpen();
                                  setModalContent(item.description);
                                  setModalTitle("Terms and Conditions");
                                  setDocCheckList([]);
                                }}

                              ></img>&nbsp;
                              <img alt="bank_bank" width="10%" src={FileCheck}
                                // onClick={() => {
                                //   console.log("hello");
                                //   // setShowSelectedBanks(!showSeletecedBanks);
                                //   // getUploadeDocOfUser(item);
                                //   handleClickOpen();
                                //   setModalContent(item.description);
                                //   setModalTitle("Document CheckList");
                                // }}


                                onMouseEnter={() => {
                                  handleClickOpen();
                                  setModalContent("");
                                  getBankRelatedDocs(item);
                                  setModalTitle("Required Documents");
                                }}

                              ></img>
                            </TableCell>

                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Row>

                ))}

                <br></br>
                <div className=''>
                  <Row>

                    <Col md={{ span: 9 }}>
                    </Col>
                    <Col md={{ span: 3 }}>
                      <Button className="updatebtn" variant="contained" color="primary"
                        onClick={() => {
                          sendNotificationToClient();
                        }} style={{ marginLeft: "2%" }} >
                        Notify Bank
                      </Button>&nbsp;&nbsp;&nbsp;
                      <Button className="updatebtn" variant="contained" color="primary"
                        onClick={() => {
                          sendNotification();
                        }} style={{ marginLeft: "2%" }} >
                        Notify Client
                      </Button>&nbsp;&nbsp;&nbsp;
                      {submitValues && (
                        <Button className="updatebtn" variant="contained" color="primary"
                          onClick={() => {
                            saveBanks();
                            //   setShowNextPage(true);
                          }} style={{ marginLeft: "2%" }} >
                          Next
                        </Button>
                      )}
                      {!submitValues && (
                        <Button className="updatebtn" variant="contained" style={{ marginRight: "2%" }} disabled>
                          Next
                        </Button>
                      )}
                    </Col>
                    <Col md={{ span: 10 }}></Col>

                    {/* className="btnspace" */}

                    {
                      //       <Col style={{ padding: "0%" }}>
                      //         {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      //   <img className='navbutton' src={Back} onClick={handledata} />
                      // </Tooltip> */}
                      //         <span className="image">
                      //           <img className='navbutton' alt="bank_bank" src={Back} onClick={handledata} />
                      //           <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                      //             <img className='navgraybutton' alt="bank_bank" src={grayback} onClick={handledata} />
                      //           </Tooltip>
                      //         </span>
                      //       </Col>
                      //       <Col style={{ padding: "0%" }}>
                      //         {/* <Tooltip title="Reset" arrow>
                      //   <img className='navbutton' src={Reset} />
                      // </Tooltip> */}
                      //         <span className="image">
                      //           <img className='navbutton' alt="bank_bank" src={Reset} />
                      //           <Tooltip title="Reset" arrow>
                      //             <img className='navgraybutton' alt="bank_bank" src={grayreset} />
                      //           </Tooltip>
                      //         </span>
                      //       </Col>
                      //       {/* <Col style={{ padding: "0%" }}>
                      //   <Tooltip title="Save & Next" arrow>
                      //     <img className='navbutton' src={SaveNext} />
                      //   </Tooltip>
                      // </Col> */}
                      //       <Col style={{ padding: "0%" }}>
                      //         {/* <Tooltip title="Save as Draft & Next" arrow>
                      //   <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                      // </Tooltip> */}
                      //         <span className="image">
                      //           <img alt="bank_bank" className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                      //           <Tooltip title="Save & Next" arrow>
                      //             <img alt="bank_bank" className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                      //           </Tooltip>
                      //         </span>
                      //       </Col>
                    }
                  </Row>
                </div>
              </>
              ) : (

                <>
                  <Row>
                    <Col md={{ span: 10 }} className="mainHeaderBack" style={{ textAlign: "left" }}></Col>
                    <Col md={{ span: 2 }} className="mainHeaderBack" style={{ textAlign: "left" }}>
                      <span className="image">
                        <img className='backbutton navbutton' alt="img" src={Back} onClick={() => { setShowSelectedBanks(!showSeletecedBanks) }} />
                        <Tooltip title="Back" arrow>
                          <img className='backbutton navgraybutton' alt="img" src={grayback} onClick={() => { setShowSelectedBanks(!showSeletecedBanks) }} />
                        </Tooltip>
                      </span>
                    </Col>
                    <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
                      onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                        vertical: SnackPosition.vertical,
                        horizontal: SnackPosition.horizontal
                      }}>
                      <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                        Uploaded Successfully
                      </Alert>
                    </Snackbar>
                    <Col md={{ span: 12 }} className="documentuploadHeader">
                      <Typography text="Document Upload" styleType="C" />
                    </Col>
                    {/* </Row> */}
                    {/* </div> */}
                    {/* <div> */}
                    {/* <Row style={{ border: '1px solid', textAlign: 'center' }}> */}
                    <span className="docUplHeader">
                      <Col md={{ span: 4 }}>
                        <Typography1 text="Document Category" />
                      </Col>
                      <Col md={{ span: 10 }}>
                        <Typography1 text="Document Uploaded" />
                      </Col>
                    </span>
                  </Row>

                  {fileList.map((item, k) => (
                    <Row className="docUploadRow">
                      <Col md={{ span: 4 }} style={{ padding: '18px' }}>
                        <Typography1 text={item.name} styleType="B" />
                      </Col>
                      {item.documentArray.map((item1, i) => (
                        <>
                          {i >= 1 && (
                            <Col md={{ span: 4 }}></Col>
                          )}
                          <Col md={{ span: 8 }} style={{ display: "flex" }}>
                            <>
                              {item1.file_name == '' && (
                                <DropzoneArea
                                  key={i}
                                  onChange={(files) => { fileSetInArray(files, k, i, item.id) }}
                                  Icon={AttachFile}
                                  style={{ margin: "5px 0px" }}
                                  maxFileSize={maxFileSize}
                                  acceptedFiles={allowedFileType}
                                  dropzoneText={dropFileText}
                                  // maxFileSize={2000000}
                                  // acceptedFiles={[
                                  //   'image/*',
                                  //   'application/pdf',
                                  //   // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                  //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  //   // 'text/csv'
                                  // ]}
                                  alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                  showPreviews={false}
                                  showPreviewsInDropzone={false} />
                              )}
                              {item1.file_name !== '' && (
                                <Col md={{ span: 10 }}>
                                  <Row>
                                    <Col md={{ span: 1 }}>
                                      {item1.file_type == 'application/pdf' && (
                                        // <i class="far fa-file-pdf" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                        <img alt="tool" src={pdfimage} className="uploadedImageView" />
                                      )}
                                      {item1.file_type == 'image/jpeg' && (
                                        // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                        <img alt="tool" src={imageimg} className="uploadedImageView" />
                                      )}
                                      {item1.file_type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                                        // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                        <img alt="tool" src={excelimg} className="uploadedImageView" />
                                      )}
                                    </Col>
                                    <Col md={{ span: 9 }}>
                                      <p className="docName">{item1.file_name}</p>
                                      <p className="docUplDate">{moment(item1.modifiedDate).format('DD-MMMM-YYYY')}</p>
                                    </Col>
                                    <Col md={{ span: 2 }} className="fileUploadAction">
                                      <div style={{ marginTop: "20px" }}>
                                        {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> &nbsp;
                                   <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => removeDoc(k, i, item1.id)}></i> */}
                                        <Tooltip title="View" placement="bottom">
                                          {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> */}
                                          <img alt="tool" className="actionBtn svgicon" src={view} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)} />
                                        </Tooltip>
                                        <Tooltip title="Delete" placement="bottom">
                                          <img alt="tool" className="actionBtn svgicon" src={deleterec} onClick={() => removeDoc(k, i, item1.id)} />
                                        </Tooltip>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              )}

                              {
                                // <Col md={{ span: 2 }}>
                                //   {i == item.documentArray.length - 1 && (
                                //     // <i class="fas fa-plus" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => {  addDoc(k); }} ></i>
                                //     <Tooltip title="Add" placement="bottom">
                                //       <img alt="tool" className="actionBtn svgicon docAddBtn" src={addbutton} onClick={() => { addDoc(k); }} />
                                //     </Tooltip>
                                //   )}
                                //   {item.documentArray.length > 1 && item1.file_name == '' && (
                                //     // <i class="fas fa-trash-alt" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { removeDoc(k, i) }} ></i>
                                //     <Tooltip title="Delete" placement="bottom">
                                //       <img className="actionBtn svgicon docDelBtn" alt="g" src={deleterec} onClick={() => { removeDoc(k, i) }} />
                                //     </Tooltip>
                                //   )}
                                // </Col>

                              }
                            </>
                          </Col>
                        </>
                      ))}

                    </Row>
                  ))}


                  <Dialog
                    open={true}
                    //  TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>{"Use Google's location service?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose}>Agree</Button>
                    </DialogActions>
                  </Dialog>

                  <Modal show={showmodal} animation={false} centered style={{ height: "550px" }}>
                    <Modal.Header closeButton onClick={handleclosePdf} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                      {/* <Modal.Title className="modalHeader">Digital Pull</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={{ span: 12 }}>
                          {  // <embed src={docBase64} type="application/pdf" width="100%" style={{ height: "460px" }}></embed>
                          }
                          <iframe src="https://file-examples-com.github.io/uploads/2017/02/file_example_XLS_100.xls&embedded=true" title="sns" width='1366px' height='623px'></iframe>

                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </>
              )}


              <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                  <DialogTitle id="customized-dialog-title" onClose={handleClose} >
                    <span style={{ fontWeight: "bold" }}>{modalTitle}</span>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      {modalContent}
                    </Typography>
                    <ul>

                      {
                        docCheckList.map((item) => (
                          <li>{item.name}</li>
                        ))
                      }
                    </ul>

                    {        // <Typography gutterBottom>
                      //   Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
                      //   lacus vel augue laoreet rutrum faucibus dolor auctor.
                      // </Typography>
                      // <Typography gutterBottom>
                      //   Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
                      //   scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
                      //   auctor fringilla.
                      // </Typography>
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

            </div >


          </div>)}






        </div >
      ) :
        (
          <>
            <BankDocAndBookApt />
          </>
        )

      }


    </Container >
  );
}
export default ApplicationLoanEligibility;