import React from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typography from '../../base_components/Typography'
import { useStylegst } from "./styles";
import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle,mdiCloseCircle,mdiCheckCircle } from "@mdi/js";
import Tooltip from '@material-ui/core/Tooltip';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import moment from 'moment';

import axios from 'axios';

import { apiCalling } from "../../shared/constants";

export default function Status(props) {
  
  const classes = useStylegst();

  const [localData,setLocalData] = React.useState({});

  React.useEffect(() => {
    
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    let data =JSON.parse(localStorage.getItem('rquestIdDetails'));
    setLocalData(data);

    getStatus(data);
  }, []);



  const handledata = () => {
    props.handleBack();
  };


  const [statusArray, setStatusArray] = React.useState({});

  const [show, setShow] = React.useState(false);

  const getStatus = async () => {
    
   // const localData = JSON.parse(localStorage.getItem('rquestIdDetails'));
    let data = {
      "request_id": "1453",
      "reference_id": "BL220101000174",
      "folder_no": "ACJPG2257L865",
      "created_by": 1218
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/status', data).then(result => {
      console.log(result.data.data);
      

      setStatusArray(result.data.data[0]);
      setShow(true);
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <Container maxWidth="lg">
      {show && (<div className="mini-container mg_btm_set">

        <Row>
          <Col md={{ span: 12 }} className="mainheader1">
            <div className='status_page_heading'>
              <Typography text={`You are viewing details of application ${localData.reference_id} `} styleType="C" />
            </div>
          </Col>
          <Col md={{ span: 12 }} className="subHeader1">
            <div className='status_page_heading'>
              <Typography text="Application Status" styleType="B" />
            </div>
          </Col>
        </Row>
        <div className='status_page'>
          {/* <div className='status_page_heading'>
      <div style={{ marginBottom: 35 }}>
        <Typography text="You are viewing details of application XYZ1234AB" styleType="C" />
      </div>
      <div>
        <Typography text="Application Status" styleType="B" />
      </div>
    </div> */}
          <div className='status_page_body'>
            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text={statusArray.requests.length !=0 ? moment(statusArray.requests[0].created_on).format("DD/MM/YYYY") : ""} styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCheckCircle} size={1} style={{ color: "green" }} />
              </div>
              <div className='right-text'>
                <Typography text="Application Created" styleType="B" />
              </div>
            </div>

            <div className='line_set'></div>

            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text={statusArray.summary.length !=0 ? moment(statusArray.summary[0].created_on).format("DD/MM/YYYY") : ""} styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCheckCircle} size={1} style={{ color: "green" }} />
              </div>
              <div className='right-text'>
                <Typography text=" Application Submission" styleType="B" />
              </div>
            </div>
            <div className='line_set'></div>

            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text={statusArray.doc.length !=0? moment(statusArray.doc[0].created_on).format("DD/MM/YYYY") : ""} styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCheckCircle} size={1}  style={{ color: "green" }}/>
              </div>
              <div className='right-text'>
                <Typography text="Document Collection" styleType="B" />
              </div>
            </div>

            <div className='line_set'></div>

            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text={statusArray.banker.length !=0 ? moment(statusArray.banker[0].created_on).format("DD/MM/YYYY") : ""} styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCloseCircle} size={1} style={{ color: "red" }} />
              </div>
              <div className='right-text'>
                <Typography text="Submission to Bank" styleType="B" />
              </div>
            </div>

            <div className='line_set'></div>
            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text="" styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCloseCircle} size={1} style={{ color: "red" }} />
              </div>
              <div className='right-text'>
                <Typography text="Approval by Bank" styleType="B" />
              </div>
            </div>

            <div className='line_set'></div>

            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text="" styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCloseCircle} size={1} style={{ color: "red" }} />
              </div>
              <div className='right-text'>
                <Typography text="Additional Documentation" styleType="B" />
              </div>
            </div>

            <div className='line_set'></div>

            <div className='status_page_body_part'>
              <div className='left_text'>
                <Typography text="" styleType="B" />
              </div>
              <div className='center_icon'>
                <Icon path={mdiCloseCircle} size={1} style={{ color: "red" }} />
              </div>
              <div className='right-text'>
                <Typography text="Loan Disbursal" styleType="B" />
              </div>
            </div>
          </div>
          <div className='btn_row_fixd'>
            <Row>
              <Col md={{ span: 10 }}></Col>
              {/* className="btnspace" */}
              <Col style={{ padding: "0%" }}></Col>
              <Col style={{ padding: "0%" }}></Col>
              <Col style={{ padding: "0%" }}></Col>
              <Col style={{ padding: "0%" }}>
                {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
              <img className='navbutton' src={Back} onClick={handledata} />
            </Tooltip> */}
                <span className="image">
                  <img className='navbutton' src={Back} onClick={handledata} alt ="photo1" />
                  <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                    <img className='navgraybutton' src={grayback} onClick={handledata} alt ="photo1" />
                  </Tooltip>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </div>)}

    </Container>
  );
}