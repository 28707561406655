import React from "react";
import { loadCSS } from "fg-loadcss";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "@material-ui/core/Icon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputLabel, TextField, Button, Checkbox } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "../../base_components/Typography";
import { Formik } from "formik";
import { regiser } from "../../shared/validations";
import "./index.css";
import { useStyles } from "./styles";
import logo from "../../image/logo.png";
import background_iamge from "../../image/LoginBG1.svg";
import axios from "axios";
import Icons from "@mdi/react";
import {
  mdiPlaylistPlus,
  mdiRefresh,
  mdiContentSaveAlert,
  mdiArrowRightBox,
  mdiArrowLeftBox,
  mdiPlusBox,
  mdiCloudUpload,
} from "@mdi/js";
import {ErrorCatch,apiCalling} from "../../shared/constants";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Route, Link, HashRouter } from 'react-router-dom';

export default function DocumentsCollected(props) {
  const classes = useStyles();
  const [panvalue, setpanvalue] = React.useState();
  const [email, setemail] = React.useState("");
  const [emailotp, setemailotp] = React.useState();
  const [mobile, setmobile] = React.useState();
  const [mobotp, setmobotp] = React.useState();
  const [fetchOtpButton, setFetchOtpButton] = React.useState(true);
  const [retriveOrUnclock, setRetriveOrUnlock] = React.useState();
  const [mobileemail, setmobileemail] = React.useState();
  const [panResponse, setPanResponse] = React.useState();

  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css"));
  }, []);

  React.useEffect(() => {
    checkUnlockForgot();
    setRetriveOrUnlock(JSON.parse(localStorage.getItem("retriveOrUnlock")));

  }, []);

const [headerTopName, setHeaderTopName] = React.useState("Forgot ID");
const checkUnlockForgot=()=>{
  let headerName = props.location.state;
  if(headerName == 'unlock'){
    setHeaderTopName("Unlock ID")
  }
  else if(headerName == 'forgot'){
    setHeaderTopName("Forgot ID")
  }
}

  const handleNextClick = () => {
    const signupdata = localStorage.getItem("signup"),
      SignupData = JSON.parse(signupdata);
    console.log("SignupData", SignupData);
  };

  const loginClick = () => { props.history.push({ pathname: "/" }); }

  const handleChange = (e) => {
    console.log(e.target.value);
    setmobileemail(e.target.value);
  }

  // var getMobAndEmail;
  const [panError, setPanError] = React.useState(false);

  const getDataOnPan = () => {
    const mobemaildata = { pan: mobileemail }
    axios.post(apiCalling.identityManagement +'server.php/api/retrieveMobileOrEmail', mobemaildata).then(res => {
      console.log(res.data.Data);
      setPanResponse(res.data.Data);
      if (res.data.status) {
        setPanError(false)
      } else {
        setPanError(true)
      }
      //setmobile(res.data.Data.mobile);
      // setemail(res.data.Data.email);
      maskEmail(res.data.Data.email);
      maskMobile(res.data.Data.mobile);
      setFetchOtpButton(!fetchOtpButton);
    }).catch((error) => {
      console.log(error)
    });
  }
  const maskEmail = (memail) => {
    let mail = memail;
    mail = mail.split('');
    let maskedArray = [];
    let length = mail.indexOf('@');
    mail.map((item, i) => {
      (i >= 1 && i < length - 1) ? maskedArray.push("*") : maskedArray.push(mail[i]);
    })
    let finalEmail = maskedArray.join('');
    setemail(finalEmail);
    console.log(email);
  }
  const maskMobile = (mmobile) => {
    let mbl = mmobile;
    let mblArray = mbl.split('');
    let maskedArray = [];
    let len = mblArray.length;
    mblArray.map((item, i) => {
      (i == 1 || i == 0 || i == len - 1) ? maskedArray.push(mblArray[i]) : maskedArray.push('*');
    })
    let finalMobile = maskedArray.join('');
    setmobile(finalMobile);
  }

  var data;

  return (
    <div className="login-form">
      <Card className="logincard">
        <CardContent>
          <Row>
            <Col md={{ span: 12 }} style={{ padding: '6% 0%' }}>
              <Link to='/'><span style={{ color: "#5290d", fontSize: "15px",marginLeft:"14px"  }}>
                <i class="fas fa-arrow-left" style={{ width: '7%', fontSize: '18px' }}></i>{" "}Back to Login</span>
                </Link>
            </Col>
            <Col md={{ span: 12 }}>
              <p className="login_header">{headerTopName}</p>
            </Col>
            <Col md={{ span: 12 }}>
            <h4>{retriveOrUnclock}</h4>
            <TextField id='filled-error-helper-text standard-basic' name='pan' onChange={handleChange} label='Enter PAN' />
            {panError === true && (<span style={{color:"red"}}>PAN doesn't exist </span>)  }
            {!fetchOtpButton ? (
              <div className="validUser">
              <p>Your Email is : {email}</p>
              <p>Your Mobile Number is : {mobile}</p></div>) : null}
            <div className='footer'>
              {fetchOtpButton ? (
                <Button className='forgotbtn' onClick={getDataOnPan}>Fetch Email/Mobile</Button>) :
                (<Button className='forgotbtn' onClick={loginClick}>Login</Button>)}
            </div>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div>
  );
}
