import React, { useState } from "react";
import { loadCSS } from "fg-loadcss";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../image/logo.png";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
//import { useStyles } from "./styles";
import "../../index.css";
import Paper from '@material-ui/core/Paper';

import {
  TextField,
  Button,
} from "@material-ui/core";

const BankingCriffCallBackUrl = () => {
  // const classes = useStyles();
  //console.log(window.location.pathname);
  return (
    <>
      <Row>
        <Col md={{ span: 2 }}>
          <div >
            <img src={logo} width='100%' height="auto" style={{ padding: '15px', marginRight: '12px' }} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }}>
          <Divider
            style={{ backgroundColor: "rgb(114, 84, 1)" }}
          />
        </Col>
        <div className="login-form1">
          <Paper elevation={3} style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            // border: "1px solid blue",
            padding: "50px"
          }}>
            <p style={{ fontSize: "20px" }}>
              Thank You!!
            </p>
            <p style={{ fontSize: "15px" }}>
              You will be redirected to the main page.
            </p>
          </Paper>
        </div>
      </Row>
    </>
  )
}
export default BankingCriffCallBackUrl;