import React from "react";
import { Dropdown } from "semantic-ui-react";
import Typography1 from "../../base_components/Typography";
import { Button, Grid, Box, List, ListItem, Tooltip, Typography } from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import { useStyled } from "./style";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import Back from '../../image/buttons/back.png';
import grayback from '../../image/buttons/grayback.png';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { purple } from "@material-ui/core/colors";
import DraftNext from '../../image/buttons/draftnext.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import "./index.css";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SnackPosition } from "../../shared/constants";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';


import AccountCircle from '@material-ui/icons/AccountCircle';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function LetsGetStarted(props) {
  const classes = useStyled();
  const [value, setvalue] = React.useState();
  const [values, setValues] = React.useState(0);
  const [loantype, setloantype] = React.useState([]);
  const [prof, setProf] = React.useState("");
  const theme = useTheme();

  const [iniLoan, setIniLoan] = React.useState();
  const [loanAmount, setLoanAmount] = React.useState();
  const [referredBy, setReferredBy] = React.useState();
  const [referalCode, setReferalCode] = React.useState();
  // const [open, setOpen] = React.useState(false);
  // const [togglebutton1, settogglebutton1] = React.useState("apply for loan");
  // const [togglebutton2, settogglebutton2] = React.useState("self employed");

  React.useEffect(() => {

    //

    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    console.log("\n \n reqDetails", localStorage.getItem("rquestIdDetails"))
    if (localStorage.getItem("rquestIdDetails")) {
      let reqDetails = JSON.parse(localStorage.getItem("rquestIdDetails"));
      if (reqDetails != null && reqDetails != undefined) {
        //  setIniLoan(reqDetails.business_channel_id);
        getUserIdByBusinessId(reqDetails.business_id);
        setProf(reqDetails.profession_id);
        getLoanAmount(reqDetails.loan_type_id, reqDetails.user_id);
        getAllReferredList();
        if(reqDetails.sales_mgr_id != null){
          setReferredBy(reqDetails.sales_mgr_id)
        } else if(reqDetails.sub_agent_id != null){
          setReferredBy(reqDetails.sub_agent_id)
        }
        console.log("\n \n referredByssss", referredBy)
        setReferenceId(reqDetails.referal_code)
      }

    }
    checkIfRequest();
    types_of_profession();
    getRequestdetailsByUser();
    if (localStorage.getItem('bankidforcppa')) {
      getUserIdByBusinessId(localStorage.getItem('bankidforcppa'));
    }
    loantypecheck();
    // intialLoanTypeSelection();
    ///  console.clear();
    getDocumentListByLoanType();
    //


    localStorage.removeItem("stepperdata");
    getUserByPan();
    console.clear();
  }, [])

  const [referedList, setreferedList] = React.useState([]);
  const getAllReferredList = async () => {
    let data = {enable: 1}
    await axios.get(apiCalling.identityManagement + 'server.php/api/getAllUsers', data).then(result => {
      if (result.data.status) {
        let empArray = [];
        result.data.data.map((item) =>{
          empArray.push({
            value: item.id,
            text: item.name,
            key: item.name             
          })
        })
        setreferedList(empArray);
      }
    }).catch();
  }

  const getLoanAmount = async (loan_type_id, user_id) => {
    let userData = JSON.parse(checkuserloggeddata);
    let data = {
      loan_type_id: loan_type_id,
      user_id: user_id
    }
    console.log("\n \ndata", data)
    await axios.post(apiCalling.coderscorner + 'server.php/api/getLoanAmount', data).then(result => {
      if (result.data.status) {
        setLoanAmount(result.data.data.Loan_amount);
      }
    }).catch();
  }

  // const getReferalDetails = async (user_id) 

  const [userIdByPan, setUserIdByaPan] = React.useState();
  const getUserByPan = async () => {
    //
    let pan ;

    if(localStorage.getItem("rquestIdDetails")){
        pan = JSON.parse(localStorage.getItem("rquestIdDetails")).user_id;
    }else{
      pan = JSON.parse(localStorage.getItem('logindata')).pan;
    }
    let data = {
      pan: pan
    }
    await axios.post(apiCalling.identityManagement + 'server.php/api/getUserIdByPan', data).then(result => {
      console.log(result);
      if (result.data.message != "Data not available") {
        setUserIdByaPan(result.data.data[0].id);
      }
    }).catch();

  }

  JSON.parse(localStorage.getItem("rquestIdDetails"));

  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    //
    if (localStorage.getItem("rquestIdDetails")) {
      setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
      let reqDetails = JSON.parse(localStorage.getItem("rquestIdDetails"));
      setProf(reqDetails.profession_id);

      loantypecheck(reqDetails);
      if (reqDetails) {
        setState1({
          ...state1,
          //checkeligility: !reqDetails.request_type_id
          checkeligility: 0
        });

        setState2({
          ...state2,
          selfemp: reqDetails.business_type_id
        });
      }
    }

  }
  let checkuserloggeddata = localStorage.getItem('userdata')
  let pan = JSON.parse(checkuserloggeddata);


  const getRequestdetailsByUser = async () => {
    let pan = '';
    if (localStorage.getItem('rquestIdDetails')) {
      let checkuserloggeddata = localStorage.getItem('rquestIdDetails')
      pan = JSON.parse(checkuserloggeddata).user_id;
      setProf(pan.profession_id);
    } else {
      let checkuserloggeddata1 = localStorage.getItem('userdata')
      pan = JSON.parse(checkuserloggeddata1).id;
    }

    let data = {
      user_id: pan,
      Token: "qwerty1234"
    }
    //
    let api_path = apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`;
    await axios.post(apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`, data).then((result) => {
      //
      console.log(data);
      console.log(result);
      //
      // setApplicationList(result.data.data);
      setReferenceId(result.data.data[0].referal_code);


    }).catch(error => {
      ErrorCatch(pan.id, error, api_path, 'post', data, pan.id, pan.id);
      console.log(error);
    })
  }

  var loantypearray = []

  const loantypecheck = async (reqDetails) => {
    const loandata = {
      token: "qwerty1234",
      enable: 1,
    }
    await axios.post(apiCalling.coderscorner + `server.php/api/getAllloanType`, loandata)
      .then(res => {
        console.log(res.data.data);
        res.data.data.map((item) => {
          loantypearray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setloantype(loantypearray);
        let reqDetails1 = JSON.parse(localStorage.getItem("rquestIdDetails"));
        if (reqDetails1 === undefined || reqDetails1 === null) {
          setIniLoan(res.data.data[0].id);
          loanTypeSelection(res.data.data[0].id);
        } else {
          setIniLoan(reqDetails1.business_channel_id);
        }
      })
      .catch(er => {
        console.log("no data  ", er);
      });
  }

  const handledata = () => {
    let checkFlag = localStorage.getItem("handleFlow");
    if (props.NewPromo === true) {
      props.handleBack(3);
    } else if (checkFlag === "letget") {
      props.handleBack(3);
    } else {
      props.handleBack(3);
    }

  };

  const [state1, setState1] = React.useState({
    checkeligility: false,
  });
  const [state2, setState2] = React.useState({
    selfemp: false,
  });
  const handleRequestType = (event) => {
    if (event.target.checked == false) {
      localStorage.setItem("RequestType", 0);
    } else {
      localStorage.setItem("RequestType", 1);
    }

    setState1({ ...state1, [event.target.name]: event.target.checked });
  };

  const handleBusinessType = (event) => {
    //
    setTypeofprofession(event.target.checked);
    setState2({ ...state2, [event.target.name]: event.target.checked });
  };

  const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[300],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChanged = (event, newValue) => {
    setValues(newValue);
  };

  const handleChangeIndex = (index) => {
    setValues(index);
  };

  const [loandesc, setLoandesc] = React.useState({
    id: "",
    description: "",
    requirements: ""
  });

  const loanTypeSelection = async (ltypeid) => {
    console.log(".....", ltypeid);
    localStorage.setItem('loantypeId', JSON.stringify(ltypeid));
    if (ltypeid != null && ltypeid != undefined) {
      setMsg("");
    }
    let data = {
      id: ltypeid
    }
    setIniLoan(ltypeid)
    await axios.post(apiCalling.coderscorner + 'server.php/api/getLoandetailsbyloantypeid', data).then((result) => {
      if (result.data.data != null && result.data.data != undefined) {
        let allDesc = result.data.data;
        setLoandesc({
          ...loandesc,
          id: allDesc.id,
          description: allDesc.description,
          requirements: allDesc.requirements
        });
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const [typeProf, setTypeProf] = React.useState();
  const types_of_profession = async () => {
    //

    let data = {
      type: "types_of_profession"
    }
    //setTypeProf(type);
    let profArray = [];
    await axios.post(apiCalling.coderscorner + 'server.php/api/getMastertableDetails', data).then((result) => {
      if (result.data.data != null && result.data.data != undefined) {
        result.data.data.map((item) => {
          profArray.push({
            key: item.id,
            value: item.id,
            //flag: item.name,
            text: item.name
          })

        })
        setTypeProf(profArray);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const [typeofprofession, setTypeofprofession] = React.useState(false);
  const [loanType, setLoanType] = React.useState("");


  const openModal = async () => {
    
    let checkForOpnModal
    let checkForUpate;
    if (requestDetails !== undefined && requestDetails !== null) {
      checkForUpate = (requestDetails && requestDetails.request_type_id == !state1.checkeligility && requestDetails.business_type_id ===
        state2.selfemp && requestDetails.business_channel_id === iniLoan && prof == "" && (prof != requestDetails.profession_id || prof == ""));
      checkForOpnModal = requestDetails.request_type_id == !state1.checkeligility && requestDetails.business_type_id ===
        state2.selfemp && requestDetails.business_channel_id === iniLoan && prof == "";
    }

    if (checkForUpate) {
      let id;
      if (requestDetails != undefined) {
        id = requestDetails.user_id;
      } else {
        id = pan.id
      }
      //
      // props.handleCount(id,requestDetails.business_channel_id);
      // props.handleNext();
    } else {
      let checkuserloggeddata = localStorage.getItem('userdata')
      let pan = JSON.parse(checkuserloggeddata);

      let userid;
      if (userIdByPan != undefined) {
        userid = userIdByPan
      } else {
        userid = pan.id
      }

      let data = {
        user_id: userid,
        Token: "qwerty1234"
      }
      let api_path = apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`;
      await axios.post(apiCalling.coderscorner + `server.php/api/getRequestdetailsByUser`, data).then((result) => {
        if (checkForOpnModal === false) {
          if (requestDetails != undefined) {
            saveLetsGetStarted(requestDetails.business_channel_id);
          } else {
            saveLetsGetStarted(iniLoan)
          }

        }
        else if (result.data.status === true) {
          setOpen(true);
        } else {
          if (requestDetails != undefined) {
            saveLetsGetStarted(requestDetails.business_channel_id);
          } else {
            saveLetsGetStarted(iniLoan)
          }

        }
      }).catch(error => {
        ErrorCatch(pan.id, error, api_path, 'post', data, pan.id, pan.id);
        console.log(error);
      })
    }
  }

  // const addNewApplication = () => {
  //   saveLetsGetStarted();
  // }

  const [cpaId, setCpaId] = React.useState(undefined);
  const getUserIdByBusinessId = async (bid) => {
    //
    let resultUserId;
    let data = {
      id: bid
    }
    let api_path = apiCalling.coderscorner + 'server.php/api/getuserByBusiness';
    await axios.post(apiCalling.coderscorner + 'server.php/api/getuserByBusiness', data).then(result => {
      console.log(result);
      setCpaId(result.data.data[0].user_id);
    }).catch(error => {
      ErrorCatch(pan.id, error, api_path, 'post', data, pan.id, pan.id);
      console.log(error);
    });
    return resultUserId;
  }


  // adding new RequestType
  const [msg, setMsg] = React.useState();

  const saveLetsGetStarted = async (loantypeid) => {

    
    let reqType;
    if (state1.checkeligility !== null && state1.checkeligility !== undefined) {
      if (state1.checkeligility == false) {
        reqType = 0
      } else if (state1.checkeligility == true) {
        reqType = 1
      }
    }
    let busType;
    if (state2.selfemp !== null && state2.selfemp !== undefined) {
      if (state2.selfemp === true) {
        busType = 2
      } else if (state2.selfemp === false) {
        busType = 1
      }
    }else{
      busType = 1
    }

    /// const[bsidCpa,sebsIdCpa] = React.useState();


    const checkuserloggeddata = localStorage.getItem('userdata')
    let pan = JSON.parse(checkuserloggeddata);

    let localStorage1 =JSON.parse(localStorage.getItem("rquestIdDetails"));

    let createdById = undefined;
    let userIdCpaOrNormal = undefined;
    let user_id_temp= userIdByPan != undefined ?  userIdByPan : localStorage1.user_id;
    if (pan.role_id == 1 || pan.role_id == 7) {
      userIdCpaOrNormal = user_id_temp;
      createdById = pan.id;
    } else {
      userIdCpaOrNormal = user_id_temp;
      createdById = user_id_temp;
    }

    let id = "";

    if (localStorage.getItem("rquestIdDetails")) {

      let jdata = localStorage1;
      id = jdata.id

    }else{
      id = "";
    }


    let data = {
      id: id,
      user_id: userIdCpaOrNormal,
      request_type_id: reqType,
      bus_type_id: busType,
      bus_channel_id: iniLoan,
      created_by: createdById,
      referal_code: referenceId,
      //  profession_id: prof
    }

    console.log( "dsta------", data );

    let data1 = {
      loantypeid: iniLoan,
      id: id
    }
    let loantype = localStorage.getItem("loantypeId");

    localStorage.setItem("stepperdata", JSON.stringify(data1));
    //
    localStorage.setItem("stepperdata", JSON.stringify(data1));
    props.handleCount(id, loantype);
    //  props.SidePanelByLoan(3,   loantypeid, id);
    //comments
   // props.handleNext();

   

    let api_path = apiCalling.coderscorner + 'server.php/api/request_table';
    await axios.post(apiCalling.coderscorner + 'server.php/api/request_table', data).then((result) => {
      if (result.data.status === true) {
        setsavenextmessage(true);
        localStorage.setItem("rquestIdDetails", JSON.stringify(result.data.Data))
        setSnackBarMsg(result.data.message);
        let id;
        if (userIdByPan != null || userIdByPan != undefined) {
          id = userIdByPan;
        } else {
          id = pan.id
        }
        props.handleCount(id, iniLoan);
        
     //   let loantype = localStorage.getItem("loantypeId");
        props.SidePanelByLoan(3, loantype, id);
        if (result.data.Data != null && result.data.Data !== undefined) {
          setOpen(false);
          localStorage.setItem('RequestType', JSON.stringify(result.data.Data.request_type_id));
          // setTimeout(function () { props.handleNext(); }, 100);
          props.handleNext();
        }
      }
      else if (result.data.status === false) {
        let msgg = result.data.message;
        setMsg(msgg);
        setSnackBarMsg(result.data.message)
        setsavenextmessage(true);
      }

    }).catch((err) => {
      ErrorCatch(userIdCpaOrNormal, err, api_path, 'post', data, userIdCpaOrNormal, userIdCpaOrNormal);
      console.log(err);
    })
  }

  const [referenceId, setReferenceId] = React.useState("");
  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    // props.handleBack();
    setOpen(false);
  };
  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [docList, setDocList] = React.useState({});
  const getDocumentListByLoanType = async () => {
    //
    let data =
    {
      loan_type_id: 1,
      req_type_id: 1,
      business_type: 1
    }
    await axios.post(apiCalling.coderscorner + 'server.php/api/getBusinessdocumentByLoantype', data).then(result => {
      if (result.data.status == true) {
        setDocList(result.data.data);
        console.log(result.data.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }



  return (
    <div className="mini-container mg_btm_set">
      <div className="content-header">
        <Row>
          <Col md={{ span: 12 }} className="mainheader1">
            {/* <p className="letgetsHeader">Lets Get Started</p> */}
            <Typography1 text="Lets Get Started" styleType="C" />
          </Col>
          {requestDetails && (
            <Col md={{ span: 12 }} className="subHeader1">
              <table className="applicationTableDetails1">
                <tr>
                  <td>Loan Application</td>
                  <td>&nbsp;|&nbsp;</td>
                  <td>Application Number</td>
                  <td>:</td>
                  <td>BL202101280000056</td>
                  <td>&nbsp;|&nbsp;</td>
                  <td>Loan Type</td>
                  <td>:</td>
                  <td>Business Loan</td>
                </tr>
              </table>
            </Col>
          )}

          <Col md={{ span: 3 }} className="rightline">
            <div className="content-header">
              <Row>
                <Col md={{ span: 12 }}>
                  <p className="lgs_header">Loan Type</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <Grid>
                    <List component='div' disablePadding>
                      <ListItem>
                        <Dropdown
                          placeholder='Loan Type'
                          id='type'
                          name='type'
                          fluid
                          search
                          selection
                          value={iniLoan}
                          onChange={(e, { value }) => {
                            setLoanType(value);
                            setIniLoan(value);

                            setvalue(value); loanTypeSelection(value)
                          }}
                          options={loantype}

                        />
                      </ListItem>
                    </List>
                    <p className="valMsg">{msg}</p>
                  </Grid>
                </Col>
              </Row>


              <div className="content-header" style={{ marginTop: "0px" }}>
                <Row>
                  <Col md={{ span: 12 }}>
                    <p className="lgs_header">Requested Loan Amount</p>
                  </Col>
                  <Col md={{ span: 12 }}>
                    <Typography component="div"  style={{ width: "100%" }}>
                      <Grid component="label" container alignItems="center">
                        <FormControl className={classes.margin}>

                          <Input
                            id=""
                            style={{ width: "100%" }}
                            name="requestedId"
                            value={loanAmount}
                            onChange={{}}
                            type='number'
                          />
                        </FormControl>
                      </Grid>
                    </Typography>
                  </Col>
                </Row>
              </div>


            {
              (pan.role_id != 6) === true ? (
              <div className="content-header" style={{ marginTop: "0px" }}>
                <Row>
                  <Col md={{ span: 12 }}>
                    <p className="lgs_header">Referral By</p>
                  </Col>
                  <Col md={{ span: 12 }}>
                    <Typography component="div"  style={{ width: "100%" }}>
                      <Grid component="label" container alignItems="center">
                        <FormControl className={classes.margin}>

                          <Input
                            id="input-with-icon-adornment"
                            style={{ width: "100%" }}
                            name="referenceId"
                            value={referenceId}
                            onChange={(event) => {
                              //
                              setReferenceId(event.target.value);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Typography>
                  </Col>
                </Row>
              </div>

              ) : (null)
            }

            <div className="content-header" style={{ marginTop: "0px" }}>

            <Row>
              <Col md={{ span: 12 }}>
                <p className="lgs_header">Business Type</p>
              </Col>
              <Col md={{ span: 12 }}>
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item className="option_name">Self Employed</Grid>
                    <Grid item>
                      <PurpleSwitch checked={state2.selfemp} onChange={handleBusinessType} name="selfemp" />
                    </Grid>
                    <Grid item className="option_name">Professional</Grid>
                  </Grid>
                </Typography>
              </Col>
            </Row>

            </div>

            {typeofprofession == true ? (
            <Row>
              <Col md={{ span: 12 }}>
                <p className="lgs_header">Type of Profession</p>
              </Col>
              <Col md={{ span: 12 }}>
                <Grid>
                  <List component='div' disablePadding>
                    <ListItem>
                      <Dropdown
                        placeholder='Type of Profession'
                        id='type'
                        name='type'
                        fluid
                        search
                        selection
                        onChange={(e, { value }) => { setProf(value) }}
                        options={typeProf}
                        value={prof}
                      />
                    </ListItem>
                  </List>
                  <p className="valMsg">{msg}</p>
                </Grid>
              </Col>


            </Row>
            ) : (null)
            }

            </div>


            <div className="content-header" style={{ marginTop: "0px" }}>
              <Row>
                <Col md={{ span: 12 }}>
                  <p className="lgs_header">Request Type</p>
                </Col>
                <Col md={{ span: 12 }}>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item className="option_name">Apply For Loan</Grid>
                      <Grid item>
                        <PurpleSwitch checked={state1.checkeligility} onChange={handleRequestType} name="checkeligility" />
                      </Grid>
                      <Grid item className="option_name">Check Eligibility</Grid>
                    </Grid>
                  </Typography>
                </Col>
              </Row>
            </div>

            <div></div>
            <div className="content-header">

            </div>
          </Col>
          <Col md={{ span: 8 }} style={{ marginTop: "3%" }}>
            <div className={[ classes.root , 'lgs_tab']}>
              <AppBar position="static" color="default">
                <Tabs
                  value={values}
                  onChange={handleChanged}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example">
                  <Tab className="tabSwitch" label="Requirements" {...a11yProps(0)} />
                  <Tab className="tabSwitch" label="About Loan Type" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={values}
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={values} index={0} dir={theme.direction}>

                  <Row>

                    <Col md={{ span: 12 }}>
                      <h2 style={{ color: "#3f51b5", marginBottom: "2%" }}>Here are a few things you will need for the application process</h2>
                    </Col>
                    <Col md={{ span: 12 }}>
                      <div>
                        <ul>
                          <li>Your phone for the OTP</li>
                          <li>PAN CARD</li>
                          <li>Recent passport sized photo</li>
                          <li>IT Return/form 16 for last year</li>
                          <li>Bank Statements for the last 6 months</li>
                          <li>Salary slip for the last 3 months</li>
                          <li>Masked e-Aadhaar or any proof of current address </li>
                        </ul>
                      </div>
                    </Col>
                    {Object.keys(docList).map((key, index) => (
                      <>
                        <Col md={{ span: 6 }}>
                          <h3>{key}</h3>
                          <div>
                            {docList[key].map((item) => (
                              <ul>
                                <li style={{ lineHeight: "1" }}>{item}</li>
                              </ul>
                            ))}
                          </div>

                        </Col>
                      </>
                    ))
                    }
                  </Row>
                </TabPanel>
                <TabPanel value={values} index={1} dir={theme.direction}>
                  {loandesc.description}
                </TabPanel>
              </SwipeableViews>
            </div>
          </Col>
        </Row>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
        >
          <MuiDialogTitle style={{ marginTop: "-4px", padding: "13px 2px" }}>
            <DialogTitle id="alert-dialog-title" style={{ padding: "3px 4px" }}><p className="dialogHeader"> Loan Request </p></DialogTitle>
            {handleClose ? (
              <IconButton style={{ top: "-4px", color: "#fff", fontWeight: "bold" }} aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>) : null}
          </MuiDialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="modaltext">
              <p className="modaltext">
                You Already have One Application Do You Want to Add One More?
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { saveLetsGetStarted() }} className="backBtn"
              variant="outlined" color="primary" autoFocus>
              Yes
            </Button>

            <Button onClick={handleClose} className="backBtn" color="primary"
              variant="outlined">
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast} anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal
        }}>
          <Alert onClose={() => { setsavenextmessage(false) }} severity="success" className="snackBar">
            {snackBarMsg}
          </Alert>
        </Snackbar>
      </div>

      <div className='btn_row_fixd'>
        <Row>
          <Col md={{ span: 10 }}></Col>
          <Col style={{ padding: "0%" }}></Col>
          <Col style={{ padding: "0%" }}></Col>
          <Col style={{ padding: "0%" }}>
            <span className="image">
              <img className='navbutton' src={Back} onClick={handledata} />
              <Tooltip title="Back" arrow>
                <img className='navgraybutton' src={grayback} onClick={handledata} />
              </Tooltip>
            </span>
          </Col>
          <Col style={{ padding: "0%" }}>
            <span className="image">
              <img className='navbutton' src={DraftNext} onClick={openModal} />
              <Tooltip title="Save & Next" arrow>
                <img className='navgraybutton' src={graydraftnext} onClick={openModal} />
              </Tooltip>
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}