import * as yup from 'yup'

export const businessInfoFormSchema = yup.object().shape({
  businessName: yup
    .string()
    .trim()
    .required('Business name is required'),
  // pincode: yup.number().min().required("Enter valid pincode."),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is Required'),
  date: yup.string().required('Date is Required'),
  area: yup
    .string()
    .trim()
    .required('Area is Required'),
  // businesspan: yup
  //   .string()
  //   .trim()
  //   .matches(
  //     /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
  //     "Must Match this Business PAN format"
  //   )
  //   .required("Business PAN is Required"),
  gst: yup
    .string()
    .trim()
    .matches(
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z,0-9]{1})([A-Z]{1})([0-9,A-Z]{1})?$/,
      'Must Match this GST Number format'
    )
    .required('Gst Number is Required'),
  bparea: yup
    .string()
    .trim()
    .required('Business Premesis Area is Required'),

  industry: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Industry Type is Required'),
  industrygrp: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Industry Group is Required'),
  industryname: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Industry Name is Required'),
  business: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Business Premesis is Required'),
  govt: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Govt. pvt. is Required'),

  descrip: yup.string().required('Bussiness Description is Required'),
  entity: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Entity Type is required')
})

export const businessinfoschema = yup.object().shape({
  businessName: yup
    .string()
    .trim()
    .required('Business name is required'),
  // pincode: yup.number().min().required("Enter valid pincode."),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  date: yup.string().required('date is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required'),
  businesspan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required'),
  gst: yup
    .string()
    .trim()
    .matches(
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z,0-9]{1})([A-Z]{1})([0-9,A-z]{1})?$/,
      'Must Match this GST Number format'
    )
    .required('Gst Number is Required'),

  entity: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Entity is required'),
  industry: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Industry Type is Required'),
  industrygrp: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Industry Group is Required'),
  business: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Business Premesis is Required')
})

export const letsgetStartedForm = yup.object().shape({
  loantype: yup.string().required('Select loan type')
})

export const personalinfoFormschema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First Name  is Required')
    .nullable(),
  lastname: yup
    .string()
    .trim()
    .required('Last Name  is Required')
    .nullable(),
  // grcno: yup.string().trim().required("grcno is Required"),
  panvalue: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')
    .nullable(),
  panname: yup
    .string()
    .trim()
    .required('PAN Name is Required')
    .nullable(),
  fathername: yup
    .string()
    .trim()
    .required('Father Name is Required')
    .nullable(),
  mothername: yup
    .string()
    .trim()
    .required('Mother Name is Required')
    .nullable(),
  nationality: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Nationality Field is Required'),
  //spousename: yup.string().trim().required("Spouse Name  is Required").nullable(),
  address1: yup
    .string()
    .trim()
    .required('Address Line 1 is Required')
    .nullable(),
  address2: yup
    .string()
    .trim()
    .required('Address Line 2 is Required')
    .nullable(),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required')
    .nullable(),
  gender: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Gender Field is Required'),
  education: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Education Qualification Field is Required'),
  addressproof: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Address Proof Field is Required'),

  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile Number is Required')
    .nullable(),
  email: yup
    .string()
    .email('Enter a Valid Email Address')
    .required('Email is Required')
    .nullable(),
  date: yup.string().required('Date is Required'),
  area: yup
    .string()
    .trim()
    .required('Area is Required'),
  govt: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Govt. pvt. is Required'),
  grcno: yup
    .string()
    .trim()
    .required('Government Registration Certificate Number is Required')
    .nullable()
})

export const personalinfoschema = yup.object().shape({
  panvalue: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')
    .nullable(),
  date: yup.string().required('Date is Required'),
  firstname: yup
    .string()
    .trim()
    .required('Firstname  is Required')
    .nullable(),
  nationality: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Nationality Field is Required'),
  lastname: yup
    .string()
    .trim()
    .required('Lastname  is Required')
    .nullable(),
  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile Number is Required')
    .nullable(),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is Required')
    .nullable()
  // share: yup
  //   .string()
  //   .required("Share is Required").nullable()
})

export const shareholderFormschema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First Name  is Required'),
  lastname: yup
    .string()
    .trim()
    .required('Last Name  is Required'),
  // middlename: yup.string().trim().required("Middle Name is Required"),
  //share: yup.string().trim().required("Share  is Required"),
  type: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Type is Required'),
  // company: yup
  //   .string()
  //   .min(1, "Pick at least 1 tags")
  //   .required("company business is required"),
  mobile: yup
    .number()
    .required('Mobile Number is Required')
    .nullable(),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is required')
})

export const bankFormschema = yup.object().shape({
  bankname: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('bankname field is required'),
  natureacc: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('nature account field is required'),
  /* submission: yup
    .string()
    .min(1, "Pick at least 1 tags")
    .required("submission field is required"), */
  color: yup
    .string()
    .trim()
    .required('select one is required'),
  account: yup.number().required('accountno is required'),
  // companyl: yup
  //   .string()
  //   .min(1, "Pick at least 1 tags")
  //   .required("company business is required"),
})

export const bankschema = yup.object().shape({
  bankname: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('bankname field is required'),
  natureacc: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('nature account field is required'),
  /* submission: yup
    .string()
    .min(1, "Pick at least 1 tags")
    .required("submission field is required"), */
  color: yup
    .string()
    .trim()
    .required('select one is required'),
  account: yup.number().required('accountno is required')
})

export const loanFormSchema = yup.object().shape({
  edate: yup.string().required('EMI Start Date is required'),
  emi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid EMI')
    .required('EMI is Required'),
  noemi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid Number of EMI')
    .required('Number of EMI is required'),
  accno: yup.string().required('Account No is Required'),
  remainemi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid Remaining EMI')
    .required('Remaining EMI is Required'),
  financer: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Financer is Required'),
  ltype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan Type is Required'),
  originalte: yup.string().required('Original Tenure is Required'),
  lamount: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid Loan Amount')
    .required('Loan Amount is Required'),
  loanopen: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan still open is Required'),
  companyle: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('company business is required'),
  emibounce3: yup.string().required('EMI Bounce in 3 Months is Required'),
  emibounce6: yup.string().required('EMI Bounce in 6= Months is Required')
  // obgconsidered: yup.string().required("Obligation Considered is Required"),
  // obgconsidered6: yup.string().required("Obligation Considered(>6) is Required"),
  // obgconsidered12: yup.string().required("Obligation Considered(<12) Months is Required"),
  // obgconsidered12gr: yup.string().required("Obligation Considered(>12) Months is Required"),
})

export const loanSchema = yup.object().shape({
  edate: yup.string().required('EMI Start Date is required'),
  emi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid EMI')
    .required('EMI is Required'),
  noemi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid Number of EMI')
    .required('Number of EMI is required'),
  accno: yup
    .string()
    .trim()
    .matches(/^[0-9A-Za-z]*$/, 'Enter valid Account No')
    .required('Account No is Required'),
  remainemi: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Enter valid Remaining EMI')
    .required('Remaining EMI is Required'),
  financer: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Financer is Required'),
  ltype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan Type is Required'),
  originalte: yup.string().required('Original Tenure is Required'),
  lamount: yup
    .string()
    .matches(/^[0-9]*$/, 'Enter valid Loan Amount')
    .min(1, 'Pick at least 1 tags')
    .required('Loan Amount is Required'),
  loanopen: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan still open is Required'),
  emibounce3: yup.string().required('EMI Bounce in 3 Months is Required'),
  emibounce6: yup.string().required('EMI Bounce in 6= Months is Required')
  // obgconsidered: yup.string().required("Obligation Considered is Required"),
  // obgconsidered6: yup.string().required("Obligation Considered(>6) is Required"),
  // obgconsidered12: yup.string().required("Obligation Considered(<12) Months is Required"),
  // obgconsidered12gr: yup.string().required("Obligation Considered(>12) Months is Required"),
})

//#region  check elegiblity

export const loanformapplyschema = yup.object().shape({
  financer: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Financer is Required'),
  //ltype: yup.string().min(1, "Pick at least 1 tags").required("Loan Type is Required"),
  originalte: yup.string().required('Original Tenure is Required'),
  lamount: yup
    .string()
    .matches(/^[0-9]*$/, 'Enter valid Loan Amount')
    .min(1, 'Pick at least 1 tags')
    .required('Loan Amount is Required'),
  loanopen: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan still open is Required'),
  companyle: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('company business is required'),
  emi: yup.string().required('EMI is Required'),
  emibounce3: yup.string().required('EMI Bounce in 3 Months is Required'),
  emibounce6: yup.string().required('EMI Bounce in 6= Months is Required')
})

export const loanapplyschema = yup.object().shape({
  financer: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Financer is Required'),
  //  ltype: yup.string().min(1, "Pick at least 1 tags").required("Loan Type is Required"),
  originalte: yup.string().required('Original Tenure is Required'),
  emi: yup.string().required('EMI is Required'),
  lamount: yup
    .string()
    .matches(/^[0-9]*$/, 'Enter valid Loan Amount')
    .min(1, 'Pick at least 1 tags')
    .required('Loan Amount is Required'),
  loanopen: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan still open is Required'),
  emibounce3: yup.string().required('EMI Bounce in 3 Months is Required'),
  emibounce6: yup.string().required('EMI Bounce in 6= Months is Required')
})

//#endregion

export const FinanceFormSchema = yup.object().shape({
  year1: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  year2: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  year3: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  year4: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  year5: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  year6: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  year7: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  year8: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  year9: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  years: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yeart: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yearu: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearv: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yearw: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearx: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yeary: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearz: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yeara: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearb: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yearc: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yeard: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yeare: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearf: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yearg: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  yearh: yup
    .string()
    .trim()
    .required('Year 1 is Required'),
  yeari: yup
    .string()
    .trim()
    .required('Year 2 is Required'),
  yearj: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 1')
    .required('Year 1 is Required'),
  yeark: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 2')
    .required('Year 2 is Required'),
  year01: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year02: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year03: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year04: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year05: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year06: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year07: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year08: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year09: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year10: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year11: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year12: yup
    .string()
    .trim()
    .required('Year 3 is Required'),
  year13: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required'),
  year14: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid Year 3')
    .required('Year 3 is Required')
})

export const WorkingFormSchema = yup.object().shape({
  credit: yup
    .string()
    .trim()
    .required('credit is required'),
  bank: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('bank is Required'),
  lastsanction: yup
    .string()
    .trim()
    .required('Last Sanction is required'),
  pincode: yup
    .number()
    .min()
    .required('Enter valid pincode.'),
  lastsanc: yup.string().required('lastsanction number is required'),
  type: yup.string().required('type is Required'),
  roi: yup.string().required('rate of interest is required'),
  comment: yup.string().required('comment is Required'),
  type: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Type is Required')
})

export const GstFormSchema = yup.object().shape({
  Gst: yup
    .string()
    .trim()
    .matches(
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([0-9]{1})([A-Z]{1})([0-9,A-z]{1})?$/,
      'Must Match this GST Number format'
    )
    .required('Gst Number Details is Required'),
  //   pincode: yup.number().min().required("Enter valid pincode."),
  State: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('State is required')
})

export const GstFormotherSchema = yup.object().shape({
  quater1: yup.string().required('quater 1 is Required'),
  quater2: yup.string().required('quater 2 is Required'),
  quater3: yup.string().required('quater 3 is Required'),
  quater4: yup.string().required('quater 4 is Required'),
  quater5: yup.string().required('quater 5 is Required'),
  quater6: yup.string().required('quater 6 is Required')
})

export const AssetFormPropertyLoanSchema = yup.object().shape({
  assetstype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('assest type is Required'),
  subasset: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('sub assets type is Required'),
  purpose: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  category: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup.string().required('area is required'),
  provalue: yup.string().required('property number is required'),
  doorsite: yup.string().required('door site no. is Required'),
  usagetype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Loan Amount is Required'),
  plotsize: yup.string().required('plot size is Required'),
  city: yup.string().required('city is Required'),
  state: yup.string().required('state is required'),
  units: yup.string().required('units is required'),
  measure: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('measurement is Required'),
  construct: yup.string().required('constructor is Required'),
  floors: yup.string().required('floors is required'),
  khata: yup.string().required('khata no is Required'),
  rooftype: yup.string().required('rooftype is Required')
})

export const AssetFormMotorVehicleLoanSchema = yup.object().shape({
  assetstype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('assest type is Required'),
  subasset: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('sub assets type is Required'),
  purpose: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  category: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  value: yup
    .string()
    .trim()
    .required('value name is required'),
  vehicle: yup.string().required('vehicle number is required'),
  insurance: yup.string().required('insurance is Required')
})

export const AssetFormMotorVehicleCheckSchema = yup.object().shape({
  assetstype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('assest type is Required'),
  subasset: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('sub assets type is Required'),
  purpose: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  category: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  value: yup
    .string()
    .trim()
    .required('value name is required')
})

export const AssetFormPropertyCheckSchema = yup.object().shape({
  assetstype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('assest type is Required'),
  subasset: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('sub assets type is Required'),
  purpose: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  category: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup.string().required('area is required'),
  provalue: yup.string().required('property number is required')
})

export const Employeeonboard = yup.object().shape({
  mname: yup
    .string()
    .trim()
    .required('Middle name  is Required'),
  lname: yup
    .string()
    .trim()
    .required('Last name  is Required'),
  fname: yup
    .string()
    .trim()
    .required('First name  is Required'),
  pan: yup
    .string()
    .trim()
    .matches(/^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/, 'Must Match this  PAN format')
    .required('PAN is Required'),
  fathername: yup
    .string()
    .trim()
    .required('Father name is Required'),
  enationality: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('nationality field is required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  dob: yup.string().required('Date of birth is required')
})
export const MasterLevel = yup.object().shape({
  master: yup
    .string()
    .trim()
    .required('Master  is Required'),
  fname: yup
    .string()
    .trim()
    .required('Name is Required'),
  sname: yup
    .string()
    .trim()
    .required('Short name  is Required'),
  sortorder: yup
    .string()
    .trim()
    .required('Short order  is Required'),
  descrip: yup
    .string()
    .trim()
    .required('Description  is Required'),
  enable: yup
    .string()
    .trim()
    .required('select one is required')
})

export const Mastertable = yup.object().shape({
  fname: yup
    .string()
    .trim()
    .required('Name is Required'),

  descrip: yup
    .string()
    .trim()
    .required('Description  is Required'),

  group: yup
    .string()
    .trim()
    .required('Group is required')
})

export const Mastertable1 = yup.object().shape({
  master: yup
    .string()
    .trim()
    .required('Master  is Required'),
  fname: yup
    .string()
    .trim()
    .required('Name is Required'),
  sname: yup
    .string()
    .trim()
    .required('Short name  is Required'),
  sortorder: yup
    .string()
    .trim()
    .required('Short order  is Required'),
  descrip: yup
    .string()
    .trim()
    .required('Description  is Required'),
  enable: yup
    .string()
    .trim()
    .required('select one is required')
})

export const bankonboard = yup.object().shape({
  lname: yup
    .string()
    .trim()
    .required('Last name  is Required'),
  fname: yup
    .string()
    .trim()
    .required('First name  is Required'),
  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  bank: yup.string().required('Bank is required'),
  ifsc: yup.string().required('IFSC is required')
})
export const pinmapping = yup.object().shape({
  bank: yup.string().required('Bank is required'),
  ifsc: yup.string().required('IFSC is required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required')
})
export const pinchannel = yup.object().shape({
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required'),
  businessChanel: yup
    .string()
    .trim()
    .required('Area is required'),
  partner: yup
    .string()
    .trim()
    .required('Area is required')
})
export const onboardingval = yup.object().shape({
  lname: yup
    .string()
    .trim()
    .required('Last name  is Required'),
  fname: yup
    .string()
    .trim()
    .required('First name  is Required'),
  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  onationality: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('nationality field is required'),
  Address1: yup
    .string()
    .trim()
    .required('address line 1 is Required'),
  Address2: yup
    .string()
    .trim()
    .required('address line 2 is Required'),
  lang: yup
    .string()
    .trim()
    .required('Language is Required'),
  city: yup
    .string()
    .trim()
    .required('City is Required'),
  state: yup
    .string()
    .trim()
    .required('State is Required'),
  gender: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Gender field is required'),
  edu: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('education qualification field is required'),
  addproof: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('addressproof field is required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required'),
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')
})
export const regiser = yup.object().shape({
  number: yup
    .number()
    // .test(
    //   "len",
    //   "Must be exactly 10 characters",
    //   (val) => !val || (val && val.toString().length === 10)
    // )
    .required('Mobile number is required'),
  email: yup
    .string()
    //.email("Enter a valid Email address")
    .required('Email is required'),
  pan: yup
    .string()
    .trim()
    .matches(
      /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')

  // cntrycode: yup
  //   .string()
  //   .min(1, "Pick at least 1 tags")
  //   .required(" field is required"),
})

export const test = yup.object().shape({
  name: yup.string().required('Name is Required'),
  email: yup
    .string()
    .email('Enter a valid Email Id')
    .required('Email Id is Required'),
  number: yup
    .string()
    .test(
      'len',
      'Must be exactly 10 numbers',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile Number is Required')
})

export const register = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .required('Pan is Required'),
  fname: yup
    .string()
    .trim()
    .required('Business name is Required'),
  dob: yup
    .string()
    .trim()
    .required('Date of birth is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required'),
  mobile: yup
    .string()
    .trim()
    .required('Mobile is required'),
  email: yup
    .string()
    .trim()
    .required('Email is required')
})

export const registerCpa = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .required('Pan is Required'),
  fname: yup
    .string()
    .trim()
    .required('Business name is Required'),
  dob: yup
    .string()
    .trim()
    .required('Date of birth is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required'),
  mobile: yup
    .string()
    .trim()
    .required('Mobile is required'),
  email: yup
    .string()
    .trim()
    .required('Email is required'),
  usermobile: yup
    .string()
    .trim()
    .required('Mobile is required'),
  useremail: yup
    .string()
    .trim()
    .required('Email is required')
})

export const registerWithMobile = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required'),
  mobile: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile number is required'),
  fname: yup
    .string()
    .trim()
    .required('First name  is Required'),
  dob: yup
    .string()
    .trim()
    .required('Date of birth is Required'),
  promoter: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required(' field is required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required')
})

export const registerWithEmail = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required'),
  email: yup
    .string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  // pan: yup
  //   .string()
  //   .trim()
  //   .matches(
  //     /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
  //     "Must Match this Business PAN format"
  //   )
  //   .required("Business PAN is Required"),
  fname: yup
    .string()
    .trim()
    .required('First name  is Required'),
  dob: yup
    .string()
    .trim()
    .required('Date of birth is Required'),
  promoter: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required(' field is required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  area: yup
    .string()
    .trim()
    .required('Area is required')
})

export const SignUpOtpVal = yup.object().shape({
  motp: yup.string().required('mobile otp is Required'),
  eotp: yup.string().required('email otp is Required')
})

export const login = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required'),

  email: yup
    .string()
    .email('Enter a valid Email address')
    .required('Email is required'),
  otp: yup
    .string()
    .trim()
    .required('OTP is required')
})

export const forget = yup.object().shape({
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')
})

export const loanthirdpartyschema = yup.object().shape({
  fname: yup.string().required('First name is required'),
  lname: yup.string().required('Last name is required'),
  dob: yup.string().required('Date is Required'),
  phone1: yup
    .number()
    .test(
      'len',
      'Must be exactly 10 characters',
      val => !val || (val && val.toString().length === 10)
    )
    .required('Mobile Number is Required')
    .nullable(),
  email1: yup
    .string()
    .email('Enter a Valid Email Address')
    .required('Email is Required')
    .nullable(),
  pan: yup
    .string()
    .trim()
    .required('Please Enter Pan')
    .matches(/^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/, 'Must Match this PAN format'),
  father_spou_name: yup.string().required('Father or Spouse name is Required'),
  address1: yup.string().required('Address is Required'),
  village1: yup.string().required('Village name is Required'),
  // city1: yup.string().required("City is Required"),
  // state1: yup.string().required("State is Required"),
  pin1: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is Required')
  //country1: yup.string().required("Country is Required"),
})
export const Parameterval = yup.object().shape({})
export const validationSchema = yup.object().shape({
  startdate: yup.string().required('Must enter start date'),

  enddate: yup
    .string()
    //.min(
    //  yup.ref('startDate'),
    //  "end date can't be before start date"
    // )
    .required('Must enter end date')
  //type: yup.string().required("Type is required")
})

export const entityTypeValidationSchema = yup.object().shape({
  entity: yup.string().required('Must enter entity')
})
export const  CRIFThirdPartyConfigurationSchema=yup.object().shape({
  username:yup.string().required("must be enter username"),
  password:yup.string()
  .required('must be enter password') 
  .min(8, 'Password is too short - should be 8 chars minimum.')
  .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  productid:yup.string().required('must be enter Crif ProductId'),
  merchantid:yup.string().required('must be enter Crif MerchantId'),
  
})

export const pinCreationSchema = yup.object().shape({
  city_id: yup.number().required('Please select city')
})
export const userCreationSchema = yup.object().shape({
  name: yup.string().required('Please enter Name'),
  role_id: yup.number().required('Please select Role'),
  mobile: yup
    .string()
    .required('Please enter mobile number')
    .matches(/^\d{10}$/, 'Please enter valid mobile number'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter email'),
  pan: yup
    .string()
    .required('Please enter pan')
    .matches(/^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/, 'Please enter the valid format of pan'),
  address: yup.string().required('Please enter address'),
  // pincode: yup.string().required("Please enter pincode").oneOf(nopincode),
})

export const industryTypeValidationSchema=yup.object().shape({
  industrytype:yup.string().required('must enter industry type')
})

export const industryGroupSchema = yup.object().shape({
  name: yup.string().required('Must enter Name'),
  industry_type_id: yup.string().required('Must enter Industry Type ID')
})

export const industryNamesSchema = yup.object().shape({
  name: yup.string().required('Must enter Name'),
  industry_group_id: yup.string().required('Must enter Industry Group ID')
})
export const addressProffSchema=yup.object().shape({
  addressproof:yup.string().required('must be enter address proof')
})
export const propertyproofSchema=yup.object().shape({
  name:yup.string().required('must be enter name'),
  short_name:yup.string().required('must be enter short name')
})
export const propertyusesSchema=yup.object().shape({
  short_name:yup.string().required('must be enter property uses')
})
export const nationalitySchema = yup.object().shape({
  country: yup.string().required('Must enter country'),
  alpha_2_code: yup.string().required('Must enter Alpha 2 Code'),
  alpha_3_code: yup.string().required('Must enter Alpha 3 Code'),
  country_code: yup.string().required('Must enter Country Code'),
  currency: yup.string().required('Must enter Currency')
})

export const stateLinkFormSchema = yup.object().shape({
  name: yup.string().required('Must enter State Name'),
  state_id: yup.string().required('Must enter State ID')
})
export const typeofConstructionSchema=yup.object().shape({
  name:yup.string().required('must be enter name'),
  shortname:yup.string().required('must be enter short name')
})
export const pincodesSchema = yup.object().shape({
  country: yup.string().required('Must enter Country name'),
  alpha_2_code: yup.string().required('Must enter Alpha 2 Code')
})

export const bankOfStaffOnboardingSchema = yup.object().shape({
  first_name: yup.string().required('Must entr First Name'),
  last_name: yup.string().required('Must enter Last Name'),
  bank_id: yup.string().required('Must enter Bank ID'),
  ifsc: yup.string().required('Must enter IFSC code')
})

export const employeeOnBoardSchema = yup.object().shape({
  fname: yup.string().required('Must enter First Name'),
  lname: yup.string().required('Must enter Last Name'),
  mname: yup.string().required('Must enter Middle Name'),
  fathername: yup.string().required("Must enter Father's Name"),
  mobile: yup.string().required('Mobile Number is Required'),
  email: yup
    .string()
    .email('Enter a Valid Email Address')
    .required('Email is Required'),
  dob: yup.string().required('Date of birth is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is Required'),
  pan: yup
    .string()
    .required('Please Enter Pan')
    .matches(/^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/, 'Must Match this PAN format'),
  enationality: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('nationality field is required'),
  area: yup.string().required('Area is required')
})

export const AssetCategorySchema = yup.object().shape({
  fname: yup.string().required('Must enter Name'),
  sname: yup.string().required('Must enter Short Name'),
  descrip: yup.string().required('Must enter Description')
})
export const educationalqualificationschema=yup.object().shape({
  educationalqualification:yup.string().required('Must be enter qualification')
})
export const AssetSubTypeLinkFormSchema = yup.object().shape({
  fname: yup.string().required('Must enter Name'),
  sname: yup.string().required('Must enter Short Name'),
  descrip: yup.string().required('Must enter Description')
})

export const AssetUsageTypeLinkFormsSchema = yup.object().shape({
  fname: yup.string().required('Must enter Name'),
  sname: yup.string().required('Must enter Short Name'),
  descrip: yup.string().required('Must enter Description')
})

export const CityLinkFormSchema = yup.object().shape({
  city: yup.string().required('Must enter City Name'),
  gst: yup.string().required('Must enter State Name'),
  tier: yup.string().required('Must enter Tier')
})

export const IdentityManagementSchema = yup.object().shape({
  name: yup.string().required('Must enter Name'),
  email: yup
    .string()
    .email('Enter a Valid Email Address')
    .required('Email is Required'),
  mobile: yup
    .string()
    //.test(
    //"len",
    //"Must be exactly 10 characters",
    // (val) => !val || (val && val.toString().length === 10)
    //)
    .required('Mobile Number is Required'),
  pan: yup
    .string()
    .trim()
    .matches(
      /^([A-Z]{5})([0-9]{4})([A-Z]{1})?$/,
      'Must Match this Business PAN format'
    )
    .required('Business PAN is Required')
    .nullable()
})

export const KARZAThirdPartyConfigurationSchema=yup.object().shape({
  xkarzakey:yup.string().required("must be enter x karza Key")
})
export const documentCheckListSchema = yup.object().shape({
  name: yup.string().required('Must enter Document Name'),
  shortname: yup.string().required('Must enter Short Name'),
  description: yup.string().required('Must enter Description'),
  timebound: yup.string().required('Must enter Timebound'),
  sortorder: yup.number().required('Must enter Sort Order')
})

export const documentAuthorityMasterSchema = yup.object().shape({
  name: yup.string().required('Must enter Document Name'),
  shortname: yup.string().required('Must enter Short Name'),
  description: yup.string().required('Must enter Description'),
  pincode: yup.number().required('Must enter Pincode')
})

export const GeneralSettingsSchema = yup.object().shape({
  parameter_name: yup.string().required('Must enter Parameter Name'),
  parameter_value: yup.string().required('Parameter Value is required')
})

export const validationSchemaRental = () => {
  return yup.object().shape({
    gross_monthly_rent: yup.string().required('gross monthly rent is required'),
    agreement_maturity_date: yup
      .string()
      .required('agreement maturity date is required'),
    inception_date: yup.string().required('inception date is required'),
    loi_available_for: yup.string().required('loi available for is required'),
    escallation_in_3_years: yup
      .string()
      .required('escallation in 3 years is required'),
    escrow_possibility: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('escrow possibility open is Required'),
    rent_mode: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('rent mode still open is Required'),

    // username: yup.string()
    //   .required('Username is required')
    //   .min(6, 'Username must be at least 6 characters')
    //   .max(20, 'Username must not exceed 20 characters'),
    // email: yup.string()
    //   .required('Email is required')
    //   .email('Email is invalid'),
    // password: yup.string()
    //   .required('Password is required')
    //   .min(6, 'Password must be at least 6 characters')
    //   .max(40, 'Password must not exceed 40 characters'),
    // confirmPassword: yup.string()
    //   .required('Confirm Password is required')
    //   .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required'),
  })
}
export const previousMonthConfigrationSchema=yup.object().shape({
  startdate:yup.date(),
              enddate: yup.date().min(
                  yup.ref('startdate'),
                  "end date can't be before start date"
                ),
  // startdate:yup.string().required('start Date must be enter'),
  // enddate:yup.string().required('End date must be enter'),
  monthInterval:yup.number()
  .positive("Month Interval can't start with a minus")
  .integer("Month Interval can't include a decimal point")
  .min(1)
  .max(12)
  .required('Month interval is required'),
  
})
export const itrForm = yup.object().shape({
  userName: yup.string().required('Please Enter Username'),
  password: yup.string().required('Please Enter Password')
})

export const gstPull = yup.object().shape({
  userName: yup.string().required('Please Enter Username'),
  password: yup.string().required('Please Enter Password')
})
export const EmailConfigrationSchema=yup.object().shape({
  server:yup.string().required("must be enter SMTP Server"),
  serverport:yup.string().required("must be enter SMTP Server Port"),
  username:yup.string().required("must be enter SMTP username"),
  password:yup.string()
  .required('must be enter password') 
  .min(8, 'Password is too short - should be 8 chars minimum.')
  .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),

})
export const EQUIFAXThirdPartyConfigurationSchema=yup.object().shape({
  customerid:yup.string().required('must be enter customerid'),
  
    userid:yup.string().required('must be enter userid'),
  
  password:yup.string()
    .required('must be enter password') 
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  
  membernumber:yup.number().required('must be enter memberid'),
  
  securitycode:yup.string().required('must be enter security code'),
  
  xkarzakey:yup.string().required('must be enter x karza key')
  
  })
export const AssetAndLap = yup.object().shape({
  assetstype: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('assest type is Required'),
  typeOfOwnership: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Type Of Ownership is Required'),
  conversionStatus: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Conversion Status is Required'),
  area: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('Area is Required'),
  numberOfUnits: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('sub assets type is Required'),
  deviation: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  propertyCategory: yup
    .string()
    .min(1, 'Pick at least 1 tags')
    .required('category is Required'),
  pincode: yup
    .number()
    .test(
      'len',
      'Must be exactly 6 characters',
      val => !val || (val && val.toString().length === 6)
    )
    .required('Pincode is required'),
  address: yup.string().required('area is required'),
  propertyMarketValue: yup.string().required('property number is required')
  // doorsite: yup.string().required("door site no. is Required"),
  // usagetype: yup.string().min(1, "Pick at least 1 tags").required("Loan Amount is Required"),
  // plotsize: yup.string().required("plot size is Required"),
  //  city: yup.string().required("city is Required"),
  //  state: yup.string().required("state is required"),
  // units: yup.string().required("units is required"),
  // measure: yup.string().min(1, "Pick at least 1 tags").required("measurement is Required"),
  // construct: yup.string().required("constructor is Required"),
  // floors: yup.string().required("floors is required"),
  // khata: yup.string().required("khata no is Required"),
  // rooftype: yup.string().required("rooftype is Required"),
});

export const bookAppointment = yup.object().shape({
  title: yup.string().required('Please Enter Title'),
  date: yup.string().required('Please Enter Date'),
  time: yup.string().required('Please Enter Time'),
  phone: yup.string().required('Please Enter Phone'),
  address: yup.string().required('Please Enter address'),
  pincode: yup.string().required('Please Enter pincode')
})

