
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyled = makeStyles((theme) => ({
  listitem: {
    color: '#7254A3',
    fontSize: '18px',
    fontWeight: '500',
  },
  // listitems: {
  //   color: 'black',
  //   fontSize: '14px',
  //   fontWeight: '500',
  //   marginLeft: 20,
  // },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 230,
    width: 10
  },
  pad_set: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  Button: {
    showIcon: true,
  },

  textfield: {
    width: '100%'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },


  linkdin: {
    width: "100%",
    margin: "10px 0px",
    //backgroundColor: "#007bff",
    //textTransform: "capitalize",
    //padding: "0px 0px !important",
    //fontSize: "14px",
    // padding: "9px",
    //width: "61%",

  },
  button_div: {
    // background:"#e4f2ff",
    margin: "10px 0px",
    padding: "0px 0px !important",
    //fontSize: "14px",
    // padding: "9px",
    //width: "61%",
    width: "100%"
  },
  button_others: {
    //background:"#e4f2ff",
    //fontSize: "14px",
    margin: "10px 0px",
    padding: "0px 0px !important",
    width: "100%"
  },
  uploadB: {
    backgroundColor: "#ffb307f2",
    textTransform: "uppercase",
    boxShadow: "1px 3px 7px 4px #b0dbeb6b",
  },
  input: {
    // display: 'none',
    marginBottom: 40,
    // marginLeft:10
    margin: 15,
    width: '10%',
  },
  table: {
    minWidth: 700,
  },
  personalBox: {
    display: 'block',
    padding: '50px',
    boxShadow: '-1px 1px 16px 11px #f8f9fe',
    height: 'auto',
    marginTop: '18px',
  },
  manorma: {
    '&:before': {
      borderBottom: "1.5px solid #00ff00 !important",
    }
  },
  button_up: {
    textTransform: "capitalize",
    fontSize: "14px",
    padding: "9px",
  },
  header: {
    color: "#fff !important",
    width: "100%",
    /* height: 80px; */
    /* padding: 15px; */
    fontSize: "18px",
    fontWeight: "600",
    backgroundColor: "#2a2b71"
  },
}));




export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);




