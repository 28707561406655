import React, { useState, useCallback } from "react";
import { loadCSS } from "fg-loadcss";
import Snackbar from '@material-ui/core/Snackbar';
import Container from "@material-ui/core/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Grid } from "@material-ui/core";
import ProgressBar from "react-bootstrap/ProgressBar";

import Typography from "../../base_components/Typography";

import axios from "axios";
import { apiCalling } from '../../shared/constants';
import 'bootstrap/dist/css/bootstrap.min.css'; 

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Switch from '@material-ui/core/Switch';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Loader from 'react-loader-spinner';
import { LoaderColor } from '../../shared/constants';


const ApplicationReport = (props) => {
   
    const [loaderFlag, setLoaderFlag] = useState(true);
    const [statusArr, setStatusArr] = useState([]);
    const [applicationList, setApplicationList] = useState([]);
    const [cpaList, setCpaList] = useState([]);
    const [startTab, setStartTab] = useState();
    const [showform, setshowform] = useState(false);
    const [bsid, setBsId] = useState();
    const [userBusinessList, setUserBusinessList] = useState([]);
    const [idofuser, setidofuser] = useState();
    const [userRole, setUserRole] = useState();
    const [promoterList, setPromoterList] = useState([]);
    const [showPromoterTable, setShowPromoterTable] = useState(false);
    const [busIdForDel, setbusIdForDel] = useState();
    const [businesslist, setbusinesslist] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [topform, settopform] = useState(true);

    React.useEffect(() => {
        loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            document.querySelector("#font-awesome-css")
        );
        if (localStorage.getItem('businessflag')) {
            props.handleNext();
        }
        //getUserId();
        //getCPAData();
        //getAllBusinessbyuser();
        localStorage.removeItem("rquestIdDetails");
        localStorage.removeItem("RequestType");
        // localStorage.removeItem("handleFlow");
        getLoanStatusPercentage();
        localStorage.setItem("check", JSON.stringify("Loan Application"));
        localStorage.setItem("RequestType", 0);
    }, []);

    const checkuserloggeddata = localStorage.getItem("userdata");
    
    let iduser = JSON.parse(checkuserloggeddata);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));

    /// Business Information data
    // getting user Id from local storage 
    


    const PurpleSwitch = withStyles({
        switchBase: {
            color: purple[300],
            '&$checked': {
                color: purple[500],
            },
            '&$checked + $track': {
                backgroundColor: purple[500],
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const getLoanStatusPercentage = async () => {
        
        let data = {
            enable: 1
        }
       await axios.post(apiCalling.coderscorner + 'server.php/api/getRequestByStatus', data).then(result => {
            console.log(result);
            setLoaderFlag(false);
            setStatusArr(result.data.data);
        }).catch(error => {
            setLoaderFlag(false);
            console.log(error);
        });
    }
    

    const [state1, setState1] = useState({
        checkedA: true,
    });
    const handleRequestType = (event) => {
        setState1({ ...state1, [event.target.name]: event.target.checked });
    };

    const getrequestTableByStats = data => {
        //
        let rbody = {
            status: data
        }
        axios.post(apiCalling.coderscorner + 'server.php/api/getRequestTableByStatus', rbody).then(result => {
            console.log(result);
            setApplicationList(result.data.data)
        }).catch(error => {
            console.log(error)
        });
    }


    return (
        <Container maxWidth='lg'>
            {loaderFlag == true ? (
                <div style={{ textAlign: 'center', marginTop: '15%' }}>
                    <Loader type={LoaderColor.type} color={LoaderColor.color} />
                </div>
            ) :
            (
                <div className="mini-container mg_btm_set" style={{ marginTop: "5%" }}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Typography text="Application Report" styleType="C" />
                        </Col>
                        <Col md={{ span: 12 }}>
                            <div style={{ width: 1300 }} >
                                <Grid component="label" container alignItems="center" spacing={1} style={{
                                    width: '100%',
                                    position: 'absolute',
                                    left: '81%',
                                    bottom: '100%'
                                }}>
                                <Grid item className="option_name">Monthly</Grid>
                                <Grid item>
                                    <PurpleSwitch checked={state1.checkedA} onChange={handleRequestType} name="checkedA" />
                                </Grid>
                                <Grid item className="option_name">Yearly</Grid>
                                </Grid>
                                <Container>
                                    {statusArr.map(item => (
                                        <Row>
                                            <Col md={{ span: 12 }}><label className="progresslabel">{item.count} / 5L</label></Col>
                                            <Col md={{ span: 3 }}> <label style={{ fontWeight: 600, float: 'right' }}>{item.key.replaceAll('_', ' ').toUpperCase()}</label> </Col>
                                            <Col md={{ span: 6 }}> <ProgressBar variant={item.color} 
                                                style={{ color: "black", cursor: "pointer", border: "1px solid #000" }} now={item.percentage === 0 ? "000000" : item.percentage} label={item.count} /></Col>
                                        </Row>
                                    ))}

                                </Container>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Container>
    )
}

export default ApplicationReport;