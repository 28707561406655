import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStyles1 } from "./styles";
import Typography1 from "../../base_components/Typography";
import axios from "axios";
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Reset from '../../image/buttons/reset.png';
import TCIcon from '../../image/icons/tc.png';
import Warning from '../../image/icons/warning.png';
import IDFC from '../../image/icons/IDFC.jpg';
import FileCheck from '../../image/icons/file.png';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import grayback from '../../image/buttons/grayback.png';
import grayreset from '../../image/buttons/grayreset.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import { ErrorCatch, apiCalling } from "../../shared/constants";
import Modal from "react-bootstrap/Modal";
import { red } from '@material-ui/core/colors';

import { SnackPosition } from "../../shared/constants";
import Snackbar from '@material-ui/core/Snackbar';
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import pdfimage from '../../image/pngicons/pdf.jpeg';
import imageimg from '../../image/pngicons/image.jpeg';
import excelimg from '../../image/pngicons/excel.jpeg';
import view from '../../image/pngicons/view.jpeg';
import addbutton from '../../image/pngicons/plussign.png';
import deleterec from '../../image/pngicons/delete.png';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

import MuiAlert from '@material-ui/lab/Alert';
import BooksAppoitmentBank from '../BooksAppoitmentBank/index';


const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

function getSteps() {
    return ['Upload Documents', 'Book Appointment', 'Finish'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Select campaign settings...';
        case 1:
            return 'What is an ad group anyways?';
        case 2:
            return 'This is the bit I really care about!';
        default:
            return 'Unknown step';
    }
}

function BankDocAndBookApt() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const [colorMessage, setcolorMessage] = React.useState();

    const [showMessage, setShowMessage] = React.useState();
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    React.useEffect(() => {
        //detailsDocument();
        getUploadeDocOfUser();
        getValidationConfiguration();

        //saveAppointment();
    }, []);




    /// doc upload codes
    const [docBase64, setDocBase64] = React.useState();
    const [showmodal, setshowmodal] = React.useState(false);

    const handleshowhidepdf = (base64, fileType) => {
        setDocBase64("https://interoperability.blob.core.windows.net/files/MS-DOC/%5bMS-DOC%5d-150316.docx");
        let fileExt = fileType.split('.');
        //if(fileExt[fileExt.length-1]=="sheet"){
        setshowmodal(true);
        //  }
    }


    const handleclosePdf = () => {
        setshowmodal(false);
    }

    const [maxFileSize, setMaxFileSize] = React.useState([]);
    const [maxFileSizeMsg, setMaxFileSizeMsg] = React.useState([]);
    const [allowedFileType, setAllowedFileType] = React.useState([]);

    const getValidationConfiguration = async () => {
        let data = {
        parameter_name: ['max_upload_size', 'allowed_file_type']
        }

        await axios
        .post(
            apiCalling.coderscorner + 'server.php/api/getGeneralSettingForMultiple',
            data
        )
        .then(result => {
            const res = result.data.data;
            setMaxFileSize(res.max_upload_size[0].parameter_value * 1048576);
            setMaxFileSizeMsg(`File size exceeds maximum limit of 2${res.max_upload_size[0].parameter_value}MB.`)
            setAllowedFileType(res.allowed_file_type[0].parameter_value.split(','));
        })
        .catch(error => {
            console.log(error)
        })
    }

    const [dropFileText, setDropFileText] = React.useState("Drag & Drop file or Browse");
    const applicationDetails = JSON.parse(localStorage.getItem("rquestIdDetails"));
    const [savenextmessage, setsavenextmessage] = React.useState(false);
    const [showSeletecedBanks, setShowSelectedBanks] = React.useState(false);

        let localdata = JSON.parse(localStorage.getItem("rquestIdDetails"));
    const getUploadeDocOfUser = async (item) => {
        
        console.log(item);
        const docdata = {
            "user_id": localdata.user_id,
            "parent_id": localdata.business_id,
            "parent_type_id": 1,
            "form": "1.1.10",
            "required_phase": "172"
        }
        let myArr = [];
        let api_path = apiCalling.coderscorner + 'server.php/api/findDocsOfUser';
        await axios.post(apiCalling.coderscorner + 'server.php/api/findDocsOfUserforBank', docdata).then((result) => {
            console.log("rsutl-----------------", result);
            if (result.data.data.length > 1) {
                result.data.data.map((item) => {
                    myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
                })
                console.log("my document array----->", myArr);
                setFilelist(myArr);

            } else {
                detailsDocument(item);
            }
        }).catch(error => {
            ErrorCatch(userId.id, error, api_path, 'post', docdata, userId, userId.id);
            console.log(error)
        })
    }

    const [fileList, setFilelist] = React.useState([]);
    const checkuserloggeddata = localStorage.getItem("userdata");
    let userId = JSON.parse(checkuserloggeddata);

    const detailsDocument = async (item) => {
        
        const docdata = {
            // user_id: userId.id,
            // parent_id: props.formdetails.parentId,
            // parent_type_id: props.formdetails.parentTypeId,
            // form: props.formdetails.formId,
            // required_phase: "172"
        }

        let data = {
            "user_id": localdata.user_id,
            "required_phase": 172,
            "bank_id": [166, 174]
        }

        let api_path = apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`;

        await axios.post(apiCalling.coderscorner + `server.php/api/getDocumentsDetailsByBank`, data).then((result) => {
            console.log("Document data...!!!", result.data);
            let myArr = [];
            console.log(result.data)
            result.data.data.map((item) => {
                myArr.push({ id: item.id, name: item.name, documentArray: item.documentArray });
            })
            setFilelist(myArr);
        }).catch(error => {
            ErrorCatch(userId.id, error, api_path, 'post', docdata, userId.id, userId.id);
            console.log(error)
        })
    }

    const fileSetInArray = async (e, index, kIndex, checkListId) => {

        let temp_fileList = [...fileList];
        if (e.length > 0) {
            let temp_file = { ...temp_fileList[index] };
            var date = new Date();
            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var modifiedDate = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
            let reader = new FileReader();
            reader.readAsDataURL(e[0]);
            reader.onload = function () {
                temp_file.documentArray[kIndex].file_name = e[0].name;
                temp_file.documentArray[kIndex].file_type = e[0].type;
                temp_file.documentArray[kIndex].modified_date = modifiedDate;
                temp_file.documentArray[kIndex].document_upload_id = "";
                temp_file.documentArray[kIndex].base64String = reader.result;
                temp_fileList[index] = temp_file;
                setFilelist(temp_fileList);
                uploadDoc(e[0].name, e[0].type, checkListId, reader.result);
            };

            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    const callFileDeleteOption = (index) => {
        let temp_fileList = [...fileList];
        let temp_file = { ...temp_fileList[index] };
        temp_file.file_name = "";
        temp_file.file_type = "";
        temp_file.modified_date = "";
        temp_file.document_upload_id = "";
        temp_fileList[index] = temp_file;
        setFilelist(temp_fileList);
    }
    // add doc based on fileList array index value from table
    const [arrOne, setArrOne] = useState([1]);
    const [checkList, setCheckList] = useState();
    const addDoc = (id) => {
        let newArr = [...fileList];
        for (let i = 0; i < newArr.length; i++) {
            if (id == i) {
                fileList[id].documentArray.push({
                    file_name: "",
                    base64String: "",
                    file_type: "",
                    modified_date: ""
                });
                break;
            }
        }
        setFilelist(newArr)
        console.log("list4", fileList)
    }
    // remove document  where mainId is fileList[] array id and subId is docuMentArray index valuex
    const removeDoc = async (mainId, subId, document_uplaod_id) => {
        let aaaa;
        let newArr = [...fileList];
        for (let i = 0; i < newArr.length; i++) {
            if (mainId == i) {
                newArr[mainId].documentArray.splice(subId, 1);
                break;
            }
        }
        if (subId === 0) {
            newArr[mainId].documentArray.push({
                file_name: "",
                base64String: "",
                file_type: "",
                modified_date: ""
            });
        }
        let data = {
            id: document_uplaod_id
        }
        let api_path = apiCalling.coderscorner + 'server.php/api/deleteDocById';
        await axios.post(apiCalling.coderscorner + 'server.php/api/deleteDocById', data).then((result) => {
            console.log(result)
        }).catch(error => {
            ErrorCatch(document_uplaod_id, error, api_path, 'post', data, document_uplaod_id, document_uplaod_id);
            console.log(error)
        })

        setFilelist(newArr)
        console.log("list1", fileList)
        // 
        // const list = [...arrOne];
        // list.splice(index, 1);
        // setArrOne(list);
    }

    const uploadDoc = (name, filetype, checkListId, docbase64) => {
        
        let data = {
            user_id: applicationDetails.user_id,
            parent_id: applicationDetails.business_id,
            parent_type_id: 1,
            document_checklist_id: checkListId,
            name: name,
            file_type: filetype,
            document: docbase64
        }
        let api_path = apiCalling.coderscorner + `server.php/api/savedocuments`;
        axios.post(apiCalling.coderscorner + `server.php/api/savedocuments`, data).then(
            result => {
                console.log(result);
                setsavenextmessage(true);
            }
        ).catch((error) => {
            ErrorCatch(userId.id, error, api_path, 'post', data, userId.id, userId.id);
            console.log(error)
        })

    }


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [modalTitle, setModalTitle] = React.useState("");
    const [modalContent, setModalContent] = React.useState("");


    const [docCheckList, setDocCheckList] = React.useState([]);

    const getBankRelatedDocs = async () => {

        
        let data = {

            "user_id": 640,
            "required_phase": 172,
            "bank_id": 166
        }
        await axios.post(apiCalling.coderscorner +'server.php/api/getBankDocuments', data).then(result => {
            console.log(result);
            setDocCheckList(result.data.data);

        }
        ).catch(error => {
            console.log(error);
        })
    }



    const [props, setProps] = React.useState({});


    return (

        <Container maxWidth="lg">
            <div className="mini-container mg_btm_set">

                <div className={classes.root}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <div style={{ marginTop: "-40%", marginLeft: "1%" }}>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div style={{ textAlign: "center" }}>
                                {
                                    // <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                }

                                <div>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        )}


                        {
                            activeStep == 0 && (
                                <>
                                    <Row>
                                        <Col md={{ span: 10 }} className="mainHeaderBack" style={{ textAlign: "left" }}></Col>
                                        <Col md={{ span: 2 }} className="mainHeaderBack" style={{ textAlign: "left" }}>

                                            {
                                                //     <span className="image">
                                                //     <img className='backbutton navbutton' alt="img" src={Back} onClick={() => { setShowSelectedBanks(!showSeletecedBanks) }} />
                                                //     <Tooltip title="Back" arrow>
                                                //       <img className='backbutton navgraybutton' alt="img" src={grayback} onClick={() => { setShowSelectedBanks(!showSeletecedBanks) }} />
                                                //     </Tooltip>
                                                //   </span>
                                            }



                                        </Col>
                                        <Snackbar open={savenextmessage} style={{ marginTop: "65px" }} autoHideDuration={SnackPosition.duration}
                                            onClose={() => { setsavenextmessage(false) }} anchorOrigin={{
                                                vertical: SnackPosition.vertical,
                                                horizontal: SnackPosition.horizontal
                                            }}>
                                            <Alert onClose={() => { setsavenextmessage(false) }} severity={colorMessage}>
                                                Uploaded Successfully
                                            </Alert>
                                        </Snackbar>
                                        <Col md={{ span: 12 }} className="documentuploadHeader">
                                            <Typography text="Document Upload" styleType="C" />
                                        </Col>
                                        {/* </Row> */}
                                        {/* </div> */}
                                        {/* <div> */}
                                        {/* <Row style={{ border: '1px solid', textAlign: 'center' }}> */}
                                        <span className="docUplHeader">
                                            <Col md={{ span: 4 }}>
                                                <Typography1 text="Document Category" />
                                            </Col>
                                            <Col md={{ span: 10 }}>
                                                <Typography1 text="Document Uploaded" />
                                            </Col>
                                        </span>
                                    </Row>

                                    {fileList.map((item, k) => (
                                        <Row className="docUploadRow">
                                            <Col md={{ span: 4 }} style={{ padding: '18px' }}>
                                                <Typography1 text={item.name} styleType="B" />
                                            </Col>
                                            {item.documentArray.map((item1, i) => (
                                                <>
                                                    {i >= 1 && (
                                                        <Col md={{ span: 4 }}></Col>
                                                    )}
                                                    <Col md={{ span: 8 }} style={{ display: "flex" }}>
                                                        <>
                                                            {item1.file_name == '' && (
                                                                <DropzoneArea
                                                                    key={i}
                                                                    onChange={(files) => { fileSetInArray(files, k, i, item.id) }}
                                                                    Icon={AttachFile}
                                                                    style={{ margin: "5px 0px" }}
                                                                    maxFileSize={maxFileSize}
                                                                    acceptedFiles={allowedFileType}                                  
                                                                    dropzoneText={dropFileText}
                                                                    alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                                                    showPreviews={false}
                                                                    showPreviewsInDropzone={false} />
                                                            )}
                                                            {item1.file_name !== '' && (
                                                                <Col md={{ span: 10 }}>
                                                                    <Row>
                                                                        <Col md={{ span: 1 }}>
                                                                            {item1.file_type == 'application/pdf' && (
                                                                                // <i class="far fa-file-pdf" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                                                                <img alt="tool" src={pdfimage} className="uploadedImageView" />
                                                                            )}
                                                                            {item1.file_type == 'image/jpeg' && (
                                                                                // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                                                                <img alt="tool" src={imageimg} className="uploadedImageView" />
                                                                            )}
                                                                            {item1.file_type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                                                                                // <i class="fas fa-images" style={{ color: '#ff0000', fontSize: '50px' }}></i>
                                                                                <img alt="tool" src={excelimg} className="uploadedImageView" />
                                                                            )}
                                                                        </Col>
                                                                        <Col md={{ span: 9 }}>
                                                                            <p className="docName">{item1.file_name}</p>
                                                                            <p className="docUplDate">{moment(item1.modifiedDate).format('DD-MMMM-YYYY')}</p>
                                                                        </Col>
                                                                        <Col md={{ span: 2 }} className="fileUploadAction">
                                                                            <div style={{ marginTop: "20px" }}>
                                                                                {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> &nbsp;
                                    <i className="fas fa-trash-alt" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => removeDoc(k, i, item1.id)}></i> */}
                                                                                <Tooltip title="View" placement="bottom">
                                                                                    {/* <i className="fas fa-eye" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)}></i> */}
                                                                                    <img alt="tool" className="actionBtn svgicon" src={view} onClick={() => handleshowhidepdf(item1.base64String, item1.file_type)} />
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete" placement="bottom">
                                                                                    <img alt="tool" className="actionBtn svgicon" src={deleterec} onClick={() => removeDoc(k, i, item1.id)} />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            )}
                                                            <Col md={{ span: 2 }}>
                                                                {i == item.documentArray.length - 1 && (
                                                                    // <i class="fas fa-plus" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => {  addDoc(k); }} ></i>
                                                                    <Tooltip title="Add" placement="bottom">
                                                                        <img alt="tool" className="actionBtn svgicon docAddBtn" src={addbutton} onClick={() => { addDoc(k); }} />
                                                                    </Tooltip>
                                                                )}
                                                                {item.documentArray.length > 1 && item1.file_name == '' && (
                                                                    // <i class="fas fa-trash-alt" style={{ fontSize: "20px", marginTop: "20px", marginLeft: "3px" }} onClick={() => { removeDoc(k, i) }} ></i>
                                                                    <Tooltip title="Delete" placement="bottom">
                                                                        <img className="actionBtn svgicon docDelBtn" alt="g" src={deleterec} onClick={() => { removeDoc(k, i) }} />
                                                                    </Tooltip>
                                                                )}
                                                            </Col>
                                                        </>
                                                    </Col>
                                                </>
                                            ))}

                                        </Row>
                                    ))}

                                </>

                            )
                        }
                        {
                            activeStep == 1 && (
                                <BooksAppoitmentBank />
                            )
                        }
                        {
                            activeStep == 2 && (
                                <><h1>helo4</h1></>
                            )
                        }



                    </div>
                </div>
            </div>
        </Container>









    );
}


export default BankDocAndBookApt;