import React, { useState, useCallback } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Container from '@material-ui/core/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button, Grid } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import { Dropdown, TextArea } from 'semantic-ui-react'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './style'
import Typography from '../../base_components/Typography'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { loadCSS } from 'fg-loadcss'
import { apiCalling } from '../../shared/constants'
import 'bootstrap/dist/css/bootstrap.min.css'
import Back from '../../image/buttons/back.png'
import grayback from '../../image/buttons/grayback.png'
import Checkbox from '@material-ui/core/Checkbox'
import editrec from '../../image/pngicons/edit.png'
import deleterec from '../../image/pngicons/delete.png'
import DraftNext from '../../image/buttons/draftnext.png'
import graydraftnext from '../../image/buttons/graydraftnext.png'
import Loader from 'react-loader-spinner'
import { LoaderColor } from '../../shared/constants'
import { TrendingUpRounded } from '@material-ui/icons'
import 'react-comments-section/dist/index.css'
import SearchBar from 'material-ui-search-bar'
import uploaddoc from '../../image/pngicons/upload.png'
import DocumentUpload from '../../components/DocumentUpload'
import { SnackPosition } from '../../shared/constants'
import { Alert } from '@material-ui/lab'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Modal from 'react-bootstrap/Modal'

import { validationSchemaRental, AssetAndLap } from '../../shared/validations'
//import {}

import DialogContentText from '@material-ui/core/DialogContentText'

import DialogActions from '@material-ui/core/DialogActions'
import { differenceInCalendarISOWeeks } from 'date-fns'

const AssetLAP = props => {
  const classes = useStyles()

  const table_slNo = {
    fontWeight: '600',
    fontSize: '100%'
  }

  const handleSubmiting = () => {
    props.handleNext()
  }

  const SaveasDraft = () => {
    handleSubmiting()
  }

  const table_header = {
    fontWeight: '600',
    fontSize: '100%',
    width: '0%'
  }

  //ganashree

  const [colorMessage, setcolorMessage] = React.useState()
  const [parentId1, setParentId1] = useState()
  const [parentTypeId1, setParentTypeId1] = useState()

  let docDetails = {
    formId: '1.2.9',
    parentId: parentId1,
    parentTypeId: parentTypeId1
  }

  //------

  const [loaderFlag, setLoaderFlag] = React.useState(true)

  const [searched, setSearched] = React.useState('')

  const requestSearch = searchedVal => {
    //
    //let busArr = businessList;
    let tempArr = []
    // busArr.filter((row) => {
    //   if (row.name.toLowerCase().includes(searchedVal.toLowerCase())) {
    //     // if (tempArr.length >= 1) {
    //     //   tempArr.map((item2) => {
    //     //     if (item2.id != row.id)
    //     //       tempArr.push(row);
    //     //   })
    //     // } else {
    //     //   tempArr.push(row);
    //     // }
    //     if (tempArr.length > 0) {
    //       let found = undefined;
    //       found = tempArr.find(item => item.id == row.id);
    //       if (found == undefined) {
    //         tempArr.push(row);
    //       }
    //     }
    //   } else {
    //     tempArr.push(row);
    //   }
    // });
    // busArr.filter((row) => {
    //   row.bankingHeader.filter((item) => {
    //     if (item.bank_name.toLowerCase().includes(searchedVal.toLowerCase())) {
    //       if (tempArr.length >= 1) {
    //         tempArr.map((item2) => {
    //           if (item2.id != row.id)
    //             tempArr.push(row);
    //         })
    //       } else {
    //         tempArr.push(row);
    //       }
    //     }
    //   })
    // });
    // setRows(filteredRows);
    // setBusinessList(tempArr);
  }

  const cancelSearch = () => {
    //
    setSearched('')
    // setBusinessList(newBus)
  }

  const handledata = () => {
    props.handleBack()
  }

  const handleBack = () => {
    
    getUnitDetailsDataByUserId()
    getHlAndLapHeader()
  }
  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
    checkassettypedata()
    checkTypeOfOwnershipData()
    checkcategorydata()
    checkConversionStatusData()
    checkDeviationData()
    checkrooftypedata()
    checkNumberofUnitsData()
    checkPropertyStatusData()
    checkPropertyTypeData()
    checkPropertyUsageData()
    checkPropertyAreaData()
    getUnitDetailsDataByUserId()
    loantypecheck()
    getHlAndLapHeader()
    console.clear()
  }, [])

  const [loanTypeArray, setLoanTypeArray] = React.useState([])
  const loantypecheck = async reqDetails => {
    let loantypearray = []
    const loandata = {
      token: 'qwerty1234'
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getAllloanType`, loandata)
      .then(res => {
        res.data.data.map(item => {
          loantypearray.push({
            key: item.short_name,
            value: item.id,
            flag: item.name,
            text: item.name
          })
        })
        setLoanTypeArray(loantypearray)
        if (reqDetails === undefined || reqDetails === null) {
          //   setIniLoan(res.data.data[0].id);
          //  loanTypeSelection(res.data.data[0].id);
        }
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  let checkuserid = JSON.parse(localStorage.getItem('rquestIdDetails'))

  const [assettype, setassettype] = React.useState([])
  var assettypearray = []
  const checkassettypedata = async () => {
    const assettypedata = {
      token: 'qwerty1234',
      type: 'asset_type'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        assettypedata
      )
      .then(res => {
        res.data.data.map(item => {
          assettypearray.push({
            key: item.short_name,
            value: item.id,
            //flag: item.name,
            text: item.name
          })
        })
        setassettype(assettypearray)
        console.log('asset type')
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //Type of Ownership List Data
  const [typeOfOwnershipDataList, setTypeOfOwnershipDataList] = React.useState(
    []
  )
  var typeOfOwnershipArray = []
  const checkTypeOfOwnershipData = async () => {
    //
    const data = {
      type: 'type_of_ownership'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          console.log(item)
          typeOfOwnershipArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setTypeOfOwnershipDataList(typeOfOwnershipArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //Conversion Status List
  const [
    conversionStatusDataList,
    setConversionStatusDataList
  ] = React.useState([])
  var conversionStatusArray = []
  const checkConversionStatusData = async () => {
    //
    const data = {
      type: 'conversion_status'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          conversionStatusArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setConversionStatusDataList(conversionStatusArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //Deviation from SanctionPlan
  const [deviationDataList, setDeviationDataList] = React.useState([])
  var deviationArray = []
  const checkDeviationData = async () => {
    const data = {
      type: 'deviation_from_sanction_plan'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          deviationArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setDeviationDataList(deviationArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  const [onAssetTypeId, setOnAssetTypeId] = React.useState()
  const [numberOfUnitsId, setNumberOfUnitsId] = React.useState()
  let [initialValue, setInitialValue] = React.useState({
    id: '',
    unitDetailsId: '',
    assetstype: '',
    typeOfOwnership: '',
    address: '',
    pincode: '',
    area: '',
    conversionStatus: '',
    propertyCategory: '',
    deviation: '',
    typeOfConstruction: '',
    propertyMarketValue: '',
    applyToAllUnits: '',
    numberOfUnits: '',
    propertyStatus: '',
    propertyType: '',
    propertyUsage: '',
    propertyArea: '',
    ageOfConstruction: '',
    property_status_id: '',
    property_usage_id: '',
    age_of_construction_id: '',
    no_of_units_id: '',
    property_type_id: '',
    property_area_id: ''
  })

  const [area, setArea] = React.useState([])
  const getPincodeData = async data => {
    ////
    let getPincode = {
      pincode: data,
      token: 'qwerty1234'
    }

    await axios
      .post(
        apiCalling.coderscorner + `server.php/api/getDetailsbyPincode`,
        getPincode
      )
      .then(res => {
        return handleAllAreas(res.data.data)
      })
      .catch(er => {
        console.log('no pincode sorry ', er)
      })
  }
  const handleAllAreas = data => {
    //
    let allArea = []
    for (let i = 0; i < data.length; i++) {
      allArea.push({ key: data[i].area, value: data[i].id, text: data[i].area })
    }
    return setArea(allArea)
  }

  const [categoryvalue, setcategoryvalue] = React.useState([])
  var categoryarray = []
  const checkcategorydata = async () => {
    const categorydata = {
      token: 'qwerty1234',
      type: 'asset_category'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        categorydata
      )
      .then(res => {
        res.data.data.map(item => {
          categoryarray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setcategoryvalue(categoryarray)
        // console.log('purpose', purposearray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  const [propertyValue, setPropertyValue] = React.useState()
  const [usageValue, setusageValue] = React.useState()
  const setProperty = async value => {
    setPropertyValue(value)
  }
  //   const setusage= async(value) => {
  //   setusageValue(value);
  // }

  //for type of construction value
  const [rooftypevalue, setrooftypevalue] = React.useState([])

  var rooftypearray = []
  const checkrooftypedata = async (propType, value) => {
    let property
    if (propType != undefined) {
      property = propType
    } else {
      property = propertyValue
    }

    
    const rooftypedata = {
      property_type_id: property,
      asset_purpose_id: Number(value)
    }
    setusageValue(value)
    console.log(rooftypedata)
    await axios
      .post(
        apiCalling.coderscorner +
          'server.php/api/getAllConstructionBasedOnAssetProperty',
        rooftypedata
      )
      .then(res => {
        res.data.data.map(item => {
          rooftypearray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setrooftypevalue(rooftypearray)
        // console.log('cityarray',cityarray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //number of units List Data
  const [numberOfUnitsListData, setNumberOfUnitsListData] = React.useState([])
  const [numberOfUnitsListDataV2, setNumberOfUnitsListDataV2] = React.useState(
    []
  )

  var numberOfUnitsArray = []
  const checkNumberofUnitsData = async () => {
    const data = {
      type: 'no_of_units'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          numberOfUnitsArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setNumberOfUnitsListData(numberOfUnitsArray)
        setNumberOfUnitsListDataV2(numberOfUnitsArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //List of Property Status Data
  const [propertyStatusListData, setPropertyStatusListData] = React.useState([])
  var propertyStatusArray = []
  const checkPropertyStatusData = async () => {
    const data = {
      type: 'property_status'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          console.log(item)
          propertyStatusArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setPropertyStatusListData(propertyStatusArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //List of Property Type Data
  const [propertyTypeListData, setPropertyTypeListData] = React.useState([])
  var propertyTypeArray = []
  const checkPropertyTypeData = async () => {
    const data = {
      is_active: 1
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getAllPropertyAssets',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          propertyTypeArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setPropertyTypeListData(propertyTypeArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))

  //List of Property Usage Data
  const [propertyUsageListData, setPropertyUsageListData] = React.useState([])
  var propertyUsageArray = []
  const checkPropertyUsageData = async value => {
    //
    const data = {
      property_type_id: Number(value)
    }
    await axios
      .post(
        apiCalling.coderscorner +
          'server.php/api/getAllPropertyTypeBasedOnPropertyAssets',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          console.log(res)
          propertyUsageArray.push({
            key: item.assetname,
            value: item.purposeid,
            text: item.assetname
          })
        })
        setPropertyUsageListData(propertyUsageArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  const [unitDetailsValue, setUnitDetailsValue] = React.useState()
  const getMasterUnitDetailsById = id => {
    //
    const data = {
      id: numberOfUnitsId
    }
    axios
      .post(apiCalling.coderscorner + `server.php/api/getUnitDetailsbyId`, data)
      .then(res => {
        console.log(res)
        setUnitDetailsValue(res.data.data.name)
      })
  }
  //List of Property Usage Data
  const [propertyAreaListData, setPropertyAreaListData] = React.useState([])
  var propertyAreaArray = []
  const checkPropertyAreaData = async () => {
    const data = {
      type: 'area_of_the_land'
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getMastertableDetails',
        data
      )
      .then(res => {
        res.data.data.map(item => {
          propertyAreaArray.push({
            key: item.short_name,
            value: item.id,
            text: item.name
          })
        })
        setPropertyAreaListData(propertyAreaArray)
      })
      .catch(er => {
        console.log('no data  ', er)
      })
  }

  //getUnitDetails based on UserId
  const [unitList, setUnitList] = React.useState([])
  const getUnitDetailsData = async headerId => {
    
    const data = {
      hl_lap_header_id: headerId
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getHL_LAP_details`, data)
      .then(result => {
        console.log(result.data.status)
        setUnitList(result.data.data)
        setLoaderFlag(false)
      })
  }

  /// get hlHeader by business id
  const getHlAndLapHeader = async () => {
    

    let data = {
      parent_id: localData.business_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getHL_LAPHeaderDetailsdV2',
        data
      )
      .then(result => {
        const { data, status } = result.data
        if (status) {
          setUnitList(data)
          setLoaderFlag(false)
          setAssetAdd(false)
          setShowTableHeader(true)
          setShowTable(true)
          setShowUnitDetails(false)
          setShowAssetForm(false)
          setShowButtons(false)
          setShowNextButton(true)
        } else {
          setShowNextButton(false)
          setAssetAdd(true)
          setShowTableHeader(false)
          setShowTable(false)
          setShowUnitDetails(false)
          setShowAssetForm(true)
          setShowButtons(true)
        }
      })
  }

  const [showNextButton, setShowNextButton] = React.useState(true)
  const [showTableHeader, setShowTableHeader] = React.useState(true)
  const [showUnitDetailsIcon, setShowUnitDetailsIcon] = React.useState(false)
  const [headerPrimaryKey, setHeaderPrimaryKey] = React.useState()
  const [showButtons, setShowButtons] = React.useState(false)
  const [showUnitDetails, setShowUnitDetails] = React.useState(false)
  const getUnitDetailsDataByUserId = async () => {
    
    const data = {
      user_id: localData.business_id
    }
    // await axios.post(apiCalling.coderscorner + `server.php/api/getHL_LAPByUserId`, data).then(result => {
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getHL_LAPByUserId`, data)
      .then(result => {
        //console.log('size',result.data.data.size());
        // console.log('length',result.data.data.length);

        if (result.data.status) {
          if (result.data.data.length > 0) {
            if (result.data.data.length == result.data.data[0].no_of_units) {
              setShowUnitDetailsIcon(true)
            } else {
              setShowUnitDetailsIcon(true)
            }
          }
          console.log('from getall ', result.data.data[0].hl_lap_header_id)
          ///  setUnitList(result.data.data);
          // setLoaderFlag(false);
          // setAssetAdd(false);
          // setShowTableHeader(true)
          // setShowTable(true);
          // setShowUnitDetails(false);
          // setShowAssetForm(false)
          // setShowButtons(false)
          // setShowNextButton(true)
          setHeaderPrimaryKey(result.data.data[0].hl_lap_header_id)
        } else {
          // setShowNextButton(false)
          // setAssetAdd(true);
          // setShowTableHeader(false)
          // setShowTable(false);
          // setShowUnitDetails(false);
          // setShowAssetForm(true)
          // setShowButtons(true)
        }
      })
  }

  const addUnitDetails = () => {
    console.log('cmngggg')
    //setShowUnitDetails(!showUnitDetails)
    setShowTableHeader(false)
    setShowTable(false)
    setShowUnitDetails(false)
    setShowAssetForm(true)
    setShowButtons(true)
  }

  const getValues = (values, { resetForm }) => {
    //
    console.log('headerPrimaryKey', values)

    //return

    saveAssetLAP(values)
  }

  const [savenextmessage, setsavenextmessage] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState()
  const [assetAdd, setAssetAdd] = React.useState(TrendingUpRounded)
  const [showAssetForm, setShowAssetForm] = React.useState(false)
  const [showTable, setShowTable] = React.useState(false)
  const saveAssetLAP = values => {
    
    let localData = JSON.parse(localStorage.getItem('rquestIdDetails'))
    console.log('values', values)

    const data = {
      hl_lap_id: hlAndLapHeaderId,
      parent_id: localData.business_id,
      parent_type_id: 1,
      asset_type_id: values.assetstype,
      type_of_ownership_id: values.typeOfOwnership,
      pin_id: values.area,
      conversion_status_id: values.conversionStatus,
      deviation_from_sanction_plan_id: values.deviation,
      property_market_value: values.propertyMarketValue,
      address: values.address,
      area: values.pincode,
      property_category_id: values.propertyCategory,
      type_of_construction_id: values.typeOfConstruction,
      number_of_units_id: values.numberOfUnits,
      created_by: checkuserid.user_id,
      modified_by: checkuserid.user_id,
      no_of_units_id: values.numberOfUnits
    }

    console.log('save asset header', data)
    axios
      .post(apiCalling.coderscorner + `server.php/api/saveHLandLAPHeader`, data)
      .then(result => {
        console.log(result)
        setHeaderPrimaryKey(result.data.Data.primary_id)
        if (result.data.status) {
          saveUnitDetialsArray(values, result.data.Data.primary_id)
          getMasterUnitDetailsById(values.numberOfUnits)
        }
      })
  }

  const saveUnitDetialsSingle = (data, id) => {
    //
    const unitData = {
      hl_lap_details_id: data.unitDetailsId,
      hl_lap_header_id: id,
      property_status_id: data.propertyStatus,
      property_usage_id: data.propertyUsage,
      age_of_construction_id: data.ageOfConstruction,
      no_of_units_id: data.numberOfUnits,
      property_type_id: data.propertyType,
      property_area_id: data.propertyArea
    }
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/saveHLandLAPDetails`,
        unitData
      )
      .then(result => {
        console.log('number of units id saved', result)
        // getUnitDetailsData(id);
        setShowTable(true)
        setShowTableHeader(true)
        setShowButtons(false)
        setShowAssetForm(false)
        setShowUnitDetails(false)
        getUnitDetailsDataByUserId()
      })
  }

  const saveUnitDetialsArray = (data, id) => {
    //

    let length
    const unitdataid = {
      id: data.numberOfUnits
    }
    axios
      .post(
        apiCalling.coderscorner + `server.php/api/getUnitDetailsbyId`,
        unitdataid
      )
      .then(res => {
        console.log(res)
        length = res.data.data[0].name
        let a = []
        var i = 0
        while (++i <= length) {
          a.push(unitArr[0])
        }
        let dataArr = []
        if (data.applyToAllUnits) {
          dataArr = a
        } else {
          dataArr = unitArr
        }
        if (dataArr.length > 0) {
          console.log('length ', length)
          const unitData = {
            hl_lap_header_id: id,
            HL_LAP_Details: dataArr,
            folder_no: 'AAADTRP'
          }
          //console.log('applyToAllUnits array data',unitData);;
          let api =
            apiCalling.frontCaller + '/server/aagey-lending-platform-be_old/aagey-lending-platform-be/coderscorner/server.php/api/saveHLandLAPDetails'
          let api2 =
            apiCalling.coderscorner + `server.php/api/saveHLandLAPDetails`
          axios.post(api2, unitData).then(result => {
            console.log('number of units id saved', result)
            //  getUnitDetailsData(id);
            getUnitDetailsDataByUserId()
            getHlAndLapHeader()
            setShowTable(true)
            setShowTableHeader(true)
            setShowButtons(false)
            setShowAssetForm(false)
            setShowUnitDetails(false)
            getHlAndLapHeader()
            props.handleCount(checkuserid.user_id)
          })
        } else {
          getHlAndLapHeader()
          setShowTable(true)
          setShowTableHeader(true)
          setShowButtons(false)
          setShowAssetForm(false)
          setShowUnitDetails(false)
          getHlAndLapHeader()
          props.handleCount(checkuserid.user_id)
        }
      })
  }

  const handleAssetEditData = async detailsId => {
    
    setHlAndLapHeaderId(detailsId)
    console.log('asseteditdata', detailsId)
    const data = {
      hl_lap_header_id: detailsId
    }
    //  await axios.post(apiCalling.coderscorner + `server.php/api/getHL_LAP_details`,data).then(result =>{
    //not using
    //await axios.post(apiCalling.coderscorner + `server.php/api/getHL_LAP`, data).then(result => {
    await axios
      .post(apiCalling.coderscorner + `server.php/api/getHL_LAP`, data)
      .then(result => {
        console.log('editing by getheaderid', result)
        let editdata = {}
        if (result.data.data.BasicDetails.length > 0) {
          editdata = result.data.data.BasicDetails[0]
          getPincodeData(result.data.data.BasicDetails[0].area)
          // set (result.data.data.BasicDetails[0].no_of_unit_id);
          setNumberOfUnitsId(result.data.data.BasicDetails[0].no_of_unit_id)
          setNumberOfUnits(Number(result.data.data.BasicDetails[0].no_of_units))
        }

        setHideBtnLen(Number(result.data.data.UnitDetails.length))
        setAssetAdd(false)
        setShowAssetForm(true)
        setShowTable(false)
        setShowTableHeader(false)
        setShowButtons(true)
        setShowUnitDetails(false)
        setUnitArray(result.data.data.UnitDetails)
        setInitialValue({
          ...initialValue,
          id: editdata.hl_lap_header_id,
          assetstype: editdata.asset_type_id,
          typeOfOwnership: editdata.type_of_ownership_id,
          address: editdata.address,
          pincode: editdata.area,
          area: Number(editdata.pin_id),
          conversionStatus: editdata.conversion_status_id,
          propertyCategory: editdata.property_category_id,
          deviation: editdata.deviation_from_sanction_plan_id,
          typeOfConstruction: editdata.type_of_construction_id,
          propertyMarketValue: editdata.property_market_value,
          // applyToAllUnits:data.asset_type,
          numberOfUnits: editdata.no_of_units_id,
          propertyStatus: editdata.property_status_id,
          propertyType: editdata.property_type_id,
          propertyUsage: editdata.property_usage_id,
          propertyArea: editdata.property_area_id,
          ageOfConstruction: editdata.age_of_construction_id,
          unitDetailsId: editdata.no_of_units_id
        })
      })
    // let arr  = editdata.UnitDetails.length
  }

  const handleAssetUnitEditData = item => {
    
    checkPropertyUsageData(item.property_type_id)
    checkrooftypedata(item.property_type_id, item.property_usage_id)
    setPropertyStatusId(item.property_status_id)
    setObject({
      id: item.id,
      property_status_id: item.property_status_id,
      property_usage_id: item.property_usage_id,
      age_of_construction_id: item.age_of_construction_id,
      no_of_units: item.no_of_units_id,
      property_type_id: item.property_type_id,
      property_area_id: item.property_area_id,
      type_of_construction: item.type_of_construction
    })

    // if (item.property_status_id == 159) {

    //   let data = item.rentalDetailsObject[0];
    //   setInitialValueRental({
    //     ...initialValueRental,
    //     id: data.id,
    //     gross_monthly_rent: data.gross_monthly_rent,
    //     agreement_maturity_date: data.agreement_maturity_date,
    //     inception_date: data.inception,
    //     loi_available_for: data.loi_available_for,
    //     escallation_in_3_years: data.escallationin_3years,
    //     escrow_possibility: data.escrow_possibility,
    //     rent_mode: data.rent_mode
    //   })
    // }
  }

  const editRentalDetails = item => {
    
    let data = item.rentalDetailsObject
    let incept_date = data.id ? data.inception : data.inception_date
    let escallationin3years = data.id
      ? data.escallationin_3years
      : data.escallation_in_3_years
    setInitialValueRental({
      ...initialValueRental,
      id: data.id,
      gross_monthly_rent: data.gross_monthly_rent,
      agreement_maturity_date: data.agreement_maturity_date,
      inception_date: incept_date,
      loi_available_for: data.loi_available_for,
      escallation_in_3_years: escallationin3years,
      escrow_possibility: data.escrow_possibility,
      rent_mode: data.rent_mode
    })
  }

  const deleteassetdatabyid = async id => {
    console.log('deleteHEaderId', id)
    const data = {
      id: id
    }
    await axios
      .post(apiCalling.coderscorner + `server.php/api/deleteHL_LAP`, data)
      .then(result => {
        console.log('deleted', result)
        getUnitDetailsDataByUserId()
        getHlAndLapHeader()
      })
  }

  // Comment Section Code

  const [showDocScreen, setShowDocScreen] = useState(false)
  const [equiOrCriff, setequiOrCriff] = useState(false)
  const [commentArr, setCommentsArr] = useState([])

  const [comments, setComments] = useState('')
  const sendComments = async () => {
    const user_id = JSON.parse(localStorage.getItem('userdata'))
    //
    let data = {
      user_id: user_id.id,
      application_ref_no: localData.reference_id,
      step: 'Loans',
      sequence_no: '1',
      comments: comments,
      commented_by: user_id.id
    }
    let url = apiCalling.coderscorner + 'server.php/api/verificationComments'
    await axios
      .post(url, data)
      .then(result => {
        console.log(result)
        if (result.data.status === true) {
          setShowMessage('Comment Saved')
          setsavenextmessage(true)

          setComments('')
          getverficationComments()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getverficationComments = async () => {
    //
    let data = {
      application_ref_no: localData.reference_id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        if (result.data.status === true) {
          console.log('a-------------------------------', result)
          setCommentsArr(result.data.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const deleteComment = async id => {
    let data = {
      id: id
    }
    await axios
      .post(
        apiCalling.coderscorner + 'server.php/api/getverficationComments',
        data
      )
      .then(result => {
        console.log(result)
        setCommentsArr(result.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [commentBox, setCommentBox] = React.useState({
    width: '500px',
    zIndex: '9999',
    marginTop: '-110%'
    // display: "none"
  })

  const [opencommentbox, setOpenComment] = React.useState(false)
  const changeCommentBox = e => {
    e.preventDefault()
    //
    // setCommentBox({
    //   ...commentBox,
    //   // display: "block"
    // })
    if (opencommentbox) {
      setOpenComment(false)
    } else {
      setOpenComment(true)
    }
  }
  const role_id = JSON.parse(localStorage.getItem('userdata'))

  /// end comment section

  const [unitArr, setUnitArray] = React.useState([])
  const [unitTimes, setUnitTimes] = React.useState([])

  const generateUnitArray = N => {
    // let object = {
    // }
    // let arr = [...unitArr];
    // for (let i = 0; i < N; i++) {
    //   object = {
    //     index: i,
    //     "property_status_id": "",
    //     "property_usage_id": "",
    //     "age_of_construction_id": "",
    //     "no_of_units_id": "",
    //     "property_type_id": "",
    //     "property_area_id": ""
    //   }
    //   arr.push(object);
    // }
    // setUnitArray(arr);
  }

  let i = 0
  let unitTempArr = []

  const [object, setObject] = React.useState({
    id: '',
    property_status_id: '',
    property_usage_id: '',
    age_of_construction_id: '',
    typeOfConstruction: '',
    no_of_units: '',
    property_type_id: '',
    property_area_id: '',
    type_of_construction: '',
    number_of_units: ''
  })

  const generateSubmitArray = (name, value) => {
    
    console.log('entered if')
    if (object.id == '') {
      console.log(name, value, i)
      let object1 = { ...object }
      object1[name] = value
      setObject(object1)
    } else {
      console.log('entered else')
      let object1 = { ...object }
      object1[name] = value
      setObject(object1)
      let unitTempAr = [...unitArr]
      unitTempAr.map(item => {
        if (object.id != '' || object.id != undefined) {
          if (item.id == object.id) {
            console.log('item id', item.id)
            item[name] = value
          }
        }
      })
      setUnitArray(unitTempAr)
    }
  }

  const [actualObject, setActualObject] = React.useState({})
  const generateActualValueArray = (name, value) => {
    let object2 = { ...actualObject }
  }

  const [hideBtnLen, setHideBtnLen] = React.useState(0)
  const [numberOfUnit, setNumberOfUnits] = React.useState(0)
  let incrementValue = 0

  const [applyToAllUnits, setApplyToAllUnits] = React.useState(false)
  const ref = React.useRef(null)

  const updateUnitArray = rentalDetailsObject => {
    
    console.log(initialValueRental)
    console.log(ref.current.values)
    // incrementValue++;
    
    let inc = hideBtnLen
    if (applyToAllUnits === true) {
      let tempArr = [...unitArr]
      for (let i = 0; i < numberOfUnit; i++) {
        tempArr.push(object)
      }
      setUnitArray(tempArr)
    } else {
      // inc++;

      numberOfUnitsListData.map(item => {
        if (item.value == object.no_of_units) {
          if (item.name === 'More than 10 Units') {
            inc = Number(inc) + 11
            setHideBtnLen(Number(inc))
          } else {
            inc = Number(inc) + Number(item.text)
            setHideBtnLen(Number(inc))
          }
        }
      })

      //
      let tempArr = [...unitArr]
      let tempObj = { ...object }
      //  tempObj['rentalDetailsObject'] = rentalDetailsObject;
      tempArr.push(tempObj)
      setUnitArray(tempArr)
    }
    setObject({
      id: '',
      property_status_id: '',
      property_usage_id: '',
      age_of_construction_id: '',
      no_of_units: '',
      property_type_id: '',
      property_area_id: '',
      type_of_construction: ''
    })
  }

  const updateRentalArray = rentalDetailsObject => {
    
    let tempObj = { ...object }
    tempObj['rentalDetailsObject'] = rentalDetailsObject
    setObject(tempObj)
    setOpenHDDialogBox1(false)
  }

  const [propertyOrMotor, setPropertOrMotor] = React.useState(21)
  const [hlAndLapHeaderId, setHlAndLapHeaderId] = React.useState(0)

  const [initialValueRental, setInitialValueRental] = useState({
    id: '',
    gross_monthly_rent: '',
    agreement_maturity_date: '',
    inception_date: '',
    loi_available_for: '',
    escallation_in_3_years: '',
    escrow_possibility: '',
    rent_mode: ''
  })

  const rentalHandleSubmit = values => {
    
    console.log(values)
    updateUnitArray(values)
  }

  const [rentalShow, setRentalShow] = React.useState(false)
  const [typeOfCons, setTypeOfCons] = React.useState(Number)
  const [propertyStatusId, setPropertyStatusId] = React.useState(Number)

  const paymentMethod = [
    { name: 'Cash', text: 'Cash', value: 'Cash' },
    { name: 'Bank', text: 'Bank', value: 'Bank' }
  ]

  const escowPossiblilityArray = [
    { name: 'Yes', text: 'Yes', value: 'Yes' },
    { name: 'No', text: 'No', value: 'No' }
  ]

  const [propertyId, setPropertyId] = React.useState(Number)

  const getRentalDetails = async () => {
    
    let data = {
      property_id: propertyId
    }

    await axios
      .post(apiCalling.coderscorner + 'server.php/api/getRental', data)
      .then(result => {
        console.log(result)
        const { data, status, message } = result.data
        if (status) {
          setInitialValueRental({
            ...initialValueRental,
            id: data.id,
            gross_monthly_rent: data.gross_monthly_rent,
            agreement_maturity_date: data.agreement_maturity_date,
            inception_date: data.inception,
            loi_available_for: data.loi_available_for,
            escallation_in_3_years: data.escallationin_3years,
            escrow_possibility: data.escrow_possibility,
            rent_mode: data.rent_mode
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [openHDDialogBox1, setOpenHDDialogBox1] = React.useState(false)
  const handleCloseOpenHDDialogBox1 = () => {
    setOpenHDDialogBox1(false)
  }

  const switchValue = id => {
    switch (id) {
      case 1:
        return 'Special Property'
      case 2:
        return 'Industrial'
      case 3:
        return 'Residential'
      case 4:
        return 'Commercial'
      default:
        return ''
    }
  }

  const switchPropStatus = id => {
    switch (id) {
      case 160:
        return 'Vacant'
      case 159:
        return 'Let Out'
      case 158:
        return 'Self Occupied'
      default:
        return ''
    }
  }

  const [unitErrorMessage, setunitErrorMessage] = React.useState('')
  const checkForTotalUnit = value => {
    
    console.log(value)
    let total = Number(value) + hideBtnLen
    if (total > numberOfUnit) {
      setunitErrorMessage('Unit allotted sould not be greater than Total Units')
    } else {
      setunitErrorMessage('')
    }
  }

  return (
    <Container maxWidth='lg'>
      <div className='mini-container mg_btm_set'>
        <>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={getValues}
            validationSchema={AssetAndLap}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {showDocScreen === true && (
                    <>
                      <Col md={{ span: 12 }}>
                        <Typography text='Colateral DETAILS ' styleType='C' />
                      </Col>
                      <Row>
                        <Col md={{ span: 12 }} className='subHeader1'>
                          <table className='applicationTableDetails1'>
                            <tr>
                              {/* <td>Type</td>
                                        <td>:</td> */}
                              {checkuserid.request_type_id === 0 && (
                                <td>Loan Application</td>
                              )}
                              {checkuserid.request_type_id === 1 && (
                                <td>Check Eligibility</td>
                              )}
                              <td>&nbsp;|&nbsp;</td>
                              <td>Application Number</td>
                              <td>:</td>
                              <td>{checkuserid.reference_id}</td>
                              <td>&nbsp;|&nbsp;</td>
                              <td>Loan Type</td>
                              <td>:</td>
                              <td>{checkuserid.loan_type}</td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </>
                  )}

                  {showAssetForm == true && (
                    <>
                      <div className='content-header'>
                        <Row>
                          {assetAdd && (
                            <Col md={{ span: 12 }}>
                              <Typography
                                text='Add Colateral Details '
                                styleType='C'
                              />
                            </Col>
                          )}
                          {assetAdd == false && (
                            <>
                              <Col md={{ span: 10 }}>
                                <Typography
                                  text='Edit Colateral Details '
                                  styleType='C'
                                />
                              </Col>
                              <Col md={{ span: 2 }} className='mainHeaderBack'>
                                <span className='image'>
                                  <img
                                    alt='img'
                                    className='backbutton navbutton'
                                    src={Back}
                                    onClick={handleBack}
                                  />
                                  <Tooltip title='Go Back' arrow>
                                    <img
                                      alt='img'
                                      className='backbutton navgraybutton'
                                      src={grayback}
                                      onClick={handleBack}
                                    />
                                  </Tooltip>
                                </span>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                  {showDocScreen === true ? (
                    <>
                      <Col md={{ span: 10 }}></Col>
                      <Col md={{ span: 2 }}>
                        <span className='image'>
                          <img
                            alt='img'
                            className='backbutton navbutton docbackbtn'
                            src={Back}
                            onClick={handledata}
                            style={{ marginRight: '-568%', marginTop: '0%' }}
                          />
                          <Tooltip
                            title='Back'
                            classes={{
                              tooltip: classes.customTooltip,
                              arrow: classes.customArrow
                            }}
                            arrow
                          >
                            <img
                              alt='img'
                              className='backbutton navgraybutton docbackbtn'
                              src={grayback}
                              onClick={handledata}
                            />
                          </Tooltip>
                        </span>
                      </Col>
                    </>
                  ) : null}
                  {/* <Button onClick={() => { setbusinesstable(true); setpromotable(true); }} variant="primary" style={{ float: "right" }}>Back</Button> */}
                  <Col md={{ span: 12 }}>
                    {' '}
                    {showDocScreen === true ? (
                      <DocumentUpload formdetails={docDetails} />
                    ) : null}
                  </Col>
                  {/* <div style={{ textAlign: 'center', marginTop: '15%' }}>
                                <Loader type={LoaderColor.type} color={LoaderColor.color} />
                            </div> */}
                  <Row>
                    <Col md={{ span: 12 }}>
                      <div>
                        <Snackbar
                          open={savenextmessage}
                          style={{ marginTop: '65px' }}
                          autoHideDuration={SnackPosition.duration}
                          onClose={() => {
                            setsavenextmessage(false)
                          }}
                          anchorOrigin={{
                            vertical: SnackPosition.vertical,
                            horizontal: SnackPosition.horizontal
                          }}
                        >
                          <Alert
                            onClose={() => {
                              setsavenextmessage(false)
                            }}
                            severity={colorMessage}
                          >
                            {showMessage}
                          </Alert>
                        </Snackbar>
                        <Row>
                          {showAssetForm == true && (
                            <>
                              <Col md={{ span: 6 }}>
                                <Grid>
                                  <List component='div' disablePadding>
                                    <ListItem>
                                      <Dropdown
                                        placeholder='Select Asset Type'
                                        id='assetstype'
                                        name='assetstype'
                                        fluid
                                        search
                                        selection
                                        value={values.assetstype || ''}
                                        onBlur={e =>
                                          setFieldTouched('assetstype', true)
                                        }
                                        onChange={(e, { name, value }) => {
                                          //
                                          setFieldValue('assetstype', value)
                                          setOnAssetTypeId(value)
                                          setPropertOrMotor(value)
                                        }}
                                        options={loanTypeArray}
                                        className={
                                          errors.assetstype &&
                                          touched.assetstype
                                            ? classes.required
                                            : values.assetstype == ''
                                            ? classes.reqired
                                            : classes.man
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                  {errors.assetstype &&
                                    touched.assetstype &&
                                    values.assetstype == '' && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '0.75rem',
                                          marginTop: '-7px'
                                        }}
                                      >
                                        {errors.assetstype}
                                      </div>
                                    )}
                                </Grid>
                              </Col>

                              <Col md={{ span: 6 }}></Col>
                              <Col md={{ span: 12 }}>
                                {propertyOrMotor != 3 ? (
                                  <Row>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Type of Ownership'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Type of Ownership'
                                            id='typeOfOwnership'
                                            name='typeOfOwnership'
                                            fluid
                                            search
                                            selection
                                            value={values.typeOfOwnership || ''}
                                            onBlur={() =>
                                              setFieldTouched(
                                                'typeOfOwnership',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'typeOfOwnership',
                                                value
                                              )
                                            }
                                            options={typeOfOwnershipDataList}
                                            className={
                                              errors.typeOfOwnership &&
                                              touched.typeOfOwnership
                                                ? classes.required
                                                : values.typeOfOwnership == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.typeOfOwnership &&
                                        touched.typeOfOwnership &&
                                        values.typeOfOwnership == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.typeOfOwnership}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Address'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic '
                                            label='address'
                                            name='address'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.address
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.address &&
                                              touched.address &&
                                              errors.address
                                                ? true
                                                : false
                                            }
                                            value={values.address || ''}
                                            helperText={
                                              errors.address &&
                                              touched.address &&
                                              errors.address
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Pincode'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='pincode'
                                            label='999999'
                                            onChange={handleChange}
                                            onBlur={e => {
                                              handleBlur(e)
                                              if (!errors.pincode) {
                                                getPincodeData(values.pincode)
                                              }
                                            }}
                                            error={
                                              errors.pincode &&
                                              touched.pincode &&
                                              errors.pincode
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              className: values.pincode
                                                ? classes.manorma
                                                : null
                                            }}
                                            inputProps={{ maxLength: 6 }}
                                            value={values.pincode || ''}
                                            helperText={
                                              errors.pincode &&
                                              touched.pincode &&
                                              errors.pincode
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography text='Area' styleType='B' />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Area'
                                            id='area'
                                            name='area'
                                            fluid
                                            search
                                            selection
                                            value={values.area || ''}
                                            onBlur={() =>
                                              setFieldTouched('area', true)
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue('area', value)
                                            }
                                            options={area}
                                            className={
                                              errors.area && touched.area
                                                ? classes.required
                                                : values.area == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.area &&
                                        touched.area &&
                                        values.area == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.area}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Conversion Status'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Conversion Status'
                                            id='conversionStatus'
                                            name='conversionStatus'
                                            fluid
                                            search
                                            selection
                                            value={
                                              values.conversionStatus || ''
                                            }
                                            onBlur={() =>
                                              setFieldTouched(
                                                'conversionStatus',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'conversionStatus',
                                                value
                                              )
                                            }
                                            options={conversionStatusDataList}
                                            className={
                                              errors.conversionStatus &&
                                              touched.conversionStatus
                                                ? classes.required
                                                : values.conversionStatus == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.conversionStatus &&
                                        touched.conversionStatus &&
                                        values.conversionStatus == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.conversionStatus}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Property Category'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Property Category'
                                            id='propertyCategory'
                                            name='propertyCategory'
                                            fluid
                                            search
                                            selection
                                            value={
                                              values.propertyCategory || ''
                                            }
                                            onBlur={() =>
                                              setFieldTouched(
                                                'propertyCategory',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'propertyCategory',
                                                value
                                              )
                                            }
                                            options={categoryvalue}
                                            className={
                                              errors.propertyCategory &&
                                              touched.propertyCategory
                                                ? classes.required
                                                : values.propertyCategory == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.propertyCategory &&
                                        touched.propertyCategory &&
                                        values.propertyCategory == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.propertyCategory}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Deviation from Sanction Plan'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Deviation from Sanction Plan'
                                            id='deviation'
                                            name='deviation'
                                            fluid
                                            search
                                            selection
                                            value={values.deviation || ''}
                                            onBlur={() =>
                                              setFieldTouched('deviation', true)
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue('deviation', value)
                                            }
                                            options={deviationDataList}
                                            className={
                                              errors.deviation &&
                                              touched.deviation
                                                ? classes.required
                                                : values.deviation == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.deviation &&
                                        touched.deviation &&
                                        values.deviation == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.deviation}
                                          </div>
                                        )}
                                    </Col>

                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Property Market Value'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic '
                                            label='Property Market Value'
                                            name='propertyMarketValue'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.propertyMarketValue
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.propertyMarketValue &&
                                              touched.propertyMarketValue &&
                                              errors.propertyMarketValue
                                                ? true
                                                : false
                                            }
                                            value={
                                              values.propertyMarketValue || ''
                                            }
                                            helperText={
                                              errors.propertyMarketValue &&
                                              touched.propertyMarketValue &&
                                              errors.propertyMarketValue
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Number of Units'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Number of Units'
                                            id='numberOfUnits'
                                            name='numberOfUnits'
                                            fluid
                                            search
                                            selection
                                            value={values.numberOfUnits || ''}
                                            onBlur={() =>
                                              setFieldTouched(
                                                'numberOfUnits',
                                                true
                                              )
                                            }
                                            onChange={(
                                              e,
                                              { name, value, options }
                                            ) => {
                                              
                                              let number = 0
                                              options.map(item => {
                                                if (item.value == value) {
                                                  number = item.text
                                                }
                                              })
                                              

                                              if (value == 161) {
                                                setObject({
                                                  ...object,
                                                  no_of_units: value,
                                                  no_of_units_id: value
                                                })
                                              }
                                              //setUnitTimes(Number(number));
                                              setFieldValue(
                                                'numberOfUnits',
                                                value
                                              )
                                              setNumberOfUnitsId(value)
                                              console.log(
                                                'numberOfUnits',
                                                options
                                              )
                                              //generateUnitArray(Number(number));
                                              setNumberOfUnits(Number(number))
                                            }}
                                            options={numberOfUnitsListData}
                                            className={
                                              errors.numberOfUnits &&
                                              touched.numberOfUnits
                                                ? classes.required
                                                : values.numberOfUnits == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.numberOfUnits &&
                                        touched.numberOfUnits &&
                                        values.numberOfUnits == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.numberOfUnits}
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Type of Ownership'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Type of Ownership'
                                            id='typeOfOwnership'
                                            name='typeOfOwnership'
                                            fluid
                                            search
                                            selection
                                            value={values.typeOfOwnership || ''}
                                            onBlur={() =>
                                              setFieldTouched(
                                                'typeOfOwnership',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'typeOfOwnership',
                                                value
                                              )
                                            }
                                            options={typeOfOwnershipDataList}
                                            className={
                                              errors.typeOfOwnership &&
                                              touched.typeOfOwnership
                                                ? classes.required
                                                : values.typeOfOwnership == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.typeOfOwnership &&
                                        touched.typeOfOwnership &&
                                        values.typeOfOwnership == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.typeOfOwnership}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Address'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic '
                                            label='address'
                                            name='address'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                              className: values.address
                                                ? classes.manorma
                                                : null
                                            }}
                                            error={
                                              errors.address &&
                                              touched.address &&
                                              errors.address
                                                ? true
                                                : false
                                            }
                                            value={values.address || ''}
                                            helperText={
                                              errors.address &&
                                              touched.address &&
                                              errors.address
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>

                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Driving Licence No.'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <TextField
                                            id='filled-error-helper-text standard-basic'
                                            name='pincode'
                                            label='999999'
                                            onChange={handleChange}
                                            onBlur={e => {
                                              handleBlur(e)
                                              if (!errors.pincode) {
                                                getPincodeData(values.pincode)
                                              }
                                            }}
                                            error={
                                              errors.pincode &&
                                              touched.pincode &&
                                              errors.pincode
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              className: values.pincode
                                                ? classes.manorma
                                                : null
                                            }}
                                            inputProps={{ maxLength: 6 }}
                                            value={values.pincode || ''}
                                            helperText={
                                              errors.pincode &&
                                              touched.pincode &&
                                              errors.pincode
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                    </Col>

                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Vechile Use'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Conversion Status'
                                            id='conversionStatus'
                                            name='conversionStatus'
                                            fluid
                                            search
                                            selection
                                            value={
                                              values.conversionStatus || ''
                                            }
                                            onBlur={() =>
                                              setFieldTouched(
                                                'conversionStatus',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'conversionStatus',
                                                value
                                              )
                                            }
                                            options={conversionStatusDataList}
                                            className={
                                              errors.conversionStatus &&
                                              touched.conversionStatus
                                                ? classes.required
                                                : values.conversionStatus == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.conversionStatus &&
                                        touched.conversionStatus &&
                                        values.conversionStatus == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.conversionStatus}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Type Of Vechile'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Property Category'
                                            id='propertyCategory'
                                            name='propertyCategory'
                                            fluid
                                            search
                                            selection
                                            value={
                                              values.propertyCategory || ''
                                            }
                                            onBlur={() =>
                                              setFieldTouched(
                                                'propertyCategory',
                                                true
                                              )
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue(
                                                'propertyCategory',
                                                value
                                              )
                                            }
                                            options={categoryvalue}
                                            className={
                                              errors.propertyCategory &&
                                              touched.propertyCategory
                                                ? classes.required
                                                : values.propertyCategory == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.propertyCategory &&
                                        touched.propertyCategory &&
                                        values.propertyCategory == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.propertyCategory}
                                          </div>
                                        )}
                                    </Col>
                                    <Col md={{ span: 6 }}>
                                      <Typography
                                        text='Deviation from Sanction Plan'
                                        styleType='B'
                                      />
                                      <List component='div' disablePadding>
                                        <ListItem>
                                          <Dropdown
                                            placeholder='Select Deviation from Sanction Plan'
                                            id='deviation'
                                            name='deviation'
                                            fluid
                                            search
                                            selection
                                            value={values.deviation || ''}
                                            onBlur={() =>
                                              setFieldTouched('deviation', true)
                                            }
                                            onChange={(e, { value }) =>
                                              setFieldValue('deviation', value)
                                            }
                                            options={deviationDataList}
                                            className={
                                              errors.deviation &&
                                              touched.deviation
                                                ? classes.required
                                                : values.deviation == ''
                                                ? classes.reqired
                                                : classes.man
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      {errors.deviation &&
                                        touched.deviation &&
                                        values.deviation == '' && (
                                          <div
                                            style={{
                                              color: 'red',
                                              fontSize: 11,
                                              marginTop: '-7px'
                                            }}
                                          >
                                            {errors.deviation}
                                          </div>
                                        )}
                                    </Col>
                                  </Row>
                                )}
                              </Col>

                              {numberOfUnitsId >= 161 && (
                                <Col md={{ span: 2 }}>
                                  <Typography
                                    text='Unit Details'
                                    styleType='A'
                                  />{' '}
                                </Col>
                              )}
                              {numberOfUnitsId > 161 && (
                                <Col md={{ span: 3 }}>
                                  <Typography
                                    text='Apply to all Units'
                                    styleType='A'
                                    style={{ marginLeft: '20px' }}
                                  />
                                  <Checkbox
                                    checked={
                                      values.applyToAllUnits ? true : false
                                    }
                                    onChange={e => {
                                      setFieldValue(
                                        'applyToAllUnits',
                                        !values.applyToAllUnits
                                      )
                                      setApplyToAllUnits(
                                        !values.applyToAllUnits
                                      )
                                    }}
                                    name='applyToAllUnits'
                                    color='primary'
                                    inputProps={{
                                      'aria-label': 'uncontrolled-checkbox'
                                    }}
                                    style={{
                                      marginBottom: '5px',
                                      marginLeft: '20px'
                                    }}
                                  />
                                </Col>
                              )}
                              <Col md={{ span: 6 }}></Col>

                              {numberOfUnitsId >= 161 ? (
                                <>
                                  <div
                                    md={{ span: 12 }}
                                    style={{
                                      display:
                                        rentalShow === false ? 'block' : 'none'
                                    }}
                                  >
                                    <Row>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Type1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Type'
                                              id='propertyType'
                                              name='property_type_id'
                                              fluid
                                              search
                                              selection
                                              value={object.property_type_id}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'property_type_id',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) => {
                                                setFieldValue(
                                                  'property_type_id',
                                                  value
                                                )
                                                generateSubmitArray(
                                                  'property_type_id',
                                                  value
                                                )
                                                checkPropertyUsageData(value)
                                                setProperty(value)
                                              }}
                                              options={propertyTypeListData}
                                              // className={errors.propertyType && touched.propertyType ? classes.required : values.propertyType == '' ? classes.reqired : classes.man}
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyType &&
                                          touched.propertyType &&
                                          values.propertyType == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyType}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Usage'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Usage'
                                              id='propertyUsage'
                                              name='property_usage_id'
                                              fluid
                                              search
                                              selection
                                              value={
                                                object.property_usage_id || ''
                                              }
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'property_usage_id',
                                                  true
                                                )
                                              }
                                              onChange={(
                                                e,
                                                { value, options }
                                              ) => {
                                                
                                                setFieldValue(
                                                  'property_usage_id',
                                                  value
                                                )
                                                generateSubmitArray(
                                                  'property_usage_id',
                                                  value
                                                )
                                                checkrooftypedata(
                                                  object.property_type_id,
                                                  value
                                                )
                                              }}
                                              options={propertyUsageListData}
                                              // className={errors.propertyUsage && touched.propertyUsage ? classes.required : values.propertyUsage == '' ? classes.reqired : classes.man}
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyUsage &&
                                          touched.propertyUsage &&
                                          values.propertyUsage == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyUsage}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Status'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Status'
                                              id='property_status_id'
                                              name='property_status_id'
                                              fluid
                                              search
                                              selection
                                              value={
                                                object.property_status_id || ''
                                              }
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'property_status_id',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) => {
                                                setFieldValue(
                                                  'property_status_id',
                                                  value
                                                )
                                                generateSubmitArray(
                                                  'property_status_id',
                                                  value
                                                )
                                                setPropertyStatusId(value)
                                                if (value == 159) {
                                                  setOpenHDDialogBox1(true)

                                                  // setRentalShow(true);
                                                }
                                              }}
                                              options={propertyStatusListData}
                                              // className={errors.propertyStatus && touched.propertyStatus ? classes.required : values.propertyStatus == '' ? classes.reqired : classes.man}
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyStatus &&
                                          touched.propertyStatus &&
                                          values.propertyStatus == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyStatus}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Type of Construction'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Type of Construction'
                                              id='type_of_construction'
                                              name='type_of_construction'
                                              fluid
                                              search
                                              selection
                                              value={
                                                object.type_of_construction ||
                                                ''
                                              }
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'type_of_construction',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) => {
                                                
                                                setFieldValue(
                                                  'type_of_construction',
                                                  value
                                                )
                                                generateSubmitArray(
                                                  'type_of_construction',
                                                  value
                                                )
                                                //   setTypeOfCons(value);

                                                // if(value == 159){
                                                //     setRentalShow(true);

                                                // }
                                              }}
                                              options={rooftypevalue}
                                              // className={errors.type_of_construction && touched.type_of_construction ? classes.required : values.type_of_construction == '' ? classes.reqired : classes.man}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      {usageValue != 14 && (
                                        <>
                                          <Col md={{ span: 6 }}>
                                            <Typography
                                              text='Dimensions of Unit in sqrft'
                                              styleType='B'
                                            />
                                            <List
                                              component='div'
                                              disablePadding
                                            >
                                              <ListItem>
                                                <Dropdown
                                                  placeholder='Select Dimensions'
                                                  id='propertyArea'
                                                  name='property_area_id'
                                                  fluid
                                                  search
                                                  selection
                                                  value={
                                                    object.property_area_id ||
                                                    ''
                                                  }
                                                  onBlur={() =>
                                                    setFieldTouched(
                                                      'property_area_id',
                                                      true
                                                    )
                                                  }
                                                  onChange={(e, { value }) => {
                                                    setFieldValue(
                                                      'property_area_id',
                                                      value
                                                    )
                                                    generateSubmitArray(
                                                      'property_area_id',
                                                      value
                                                    )
                                                  }}
                                                  options={propertyAreaListData}
                                                  // className={errors.propertyArea && touched.propertyArea ? classes.required : values.propertyArea == '' ? classes.reqired : classes.man}
                                                />
                                              </ListItem>
                                            </List>
                                          </Col>
                                        </>
                                      )}

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Age of Construction'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic '
                                              label='Age of Construction'
                                              name='age_of_construction_id'
                                              onChange={e => {
                                                //
                                                generateSubmitArray(
                                                  'age_of_construction_id',
                                                  e.target.value
                                                )
                                              }}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.age_of_construction_id
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              //   error={errors.ageOfConstruction && touched.ageOfConstruction && errors.ageOfConstruction ? true : false}
                                              value={
                                                object.age_of_construction_id ||
                                                ''
                                              }
                                              //  helperText={errors.ageOfConstruction && touched.ageOfConstruction && errors.ageOfConstruction}
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      {numberOfUnitsId > 161 && (
                                        <Col md={{ span: 6 }}>
                                          <Typography
                                            text=' add Alloted Units'
                                            styleType='B'
                                          />
                                          <List component='div' disablePadding>
                                            <ListItem>
                                              <Dropdown
                                                placeholder='Select Number of Units'
                                                id='Number of Units'
                                                name='no_of_units'
                                                fluid
                                                search
                                                selection
                                                value={object.no_of_units || ''}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    'no_of_units',
                                                    true
                                                  )
                                                }
                                                onChange={(
                                                  e,
                                                  { name, value, options }
                                                ) => {
                                                  
                                                  let number = 0
                                                  options.map(item => {
                                                    if (item.value == value) {
                                                      number = item.text
                                                    }
                                                  })
                                                  
                                                  checkForTotalUnit(number)
                                                  generateSubmitArray(
                                                    'no_of_units',
                                                    value
                                                  )
                                                }}
                                                options={numberOfUnitsListData}
                                                className={
                                                  errors.numberOfUnits &&
                                                  touched.numberOfUnits
                                                    ? classes.required
                                                    : values.numberOfUnits == ''
                                                    ? classes.reqired
                                                    : classes.man
                                                }
                                              />
                                            </ListItem>
                                            <p style={{ color: 'red' }}>
                                              {unitErrorMessage}
                                            </p>
                                          </List>
                                        </Col>
                                      )}
                                    </Row>
                                  </div>

                                  <>
                                    <div
                                      style={{
                                        display:
                                          rentalShow === true ? 'block' : 'none'
                                      }}
                                    >
                                      <span className='rental_details'>
                                        <Typography
                                          text='Rental Details'
                                          styleType='c'
                                        />
                                      </span>

                                      <Formik
                                        innerRef={ref}
                                        enableReinitialize
                                        initialValues={initialValueRental}
                                        validationSchema={
                                          validationSchemaRental
                                        }
                                        onSubmit={updateUnitArray}
                                      >
                                        {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          resetForm,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                          setFieldTouched,
                                          validateForm
                                        }) => (
                                          <Form style={{ padding: '15px' }}>
                                            <Row>
                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='Gross Monthly Rent'
                                                    styleType='B'
                                                  />
                                                  <TextField
                                                    name='gross_monthly_rent'
                                                    type='text'
                                                    value={
                                                      values.gross_monthly_rent
                                                    }
                                                    className='form-control'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                  />
                                                </div>
                                              </Col>
                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    type='date'
                                                    text='Agreement Maturity Date'
                                                    styleType='B'
                                                  />
                                                  <TextField
                                                    name='agreement_maturity_date'
                                                    type='date'
                                                    value={
                                                      values.agreement_maturity_date
                                                    }
                                                    className='form-control'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                      errors.agreement_maturity_date &&
                                                      touched.agreement_maturity_date &&
                                                      errors.agreement_maturity_date
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.agreement_maturity_date &&
                                                      touched.agreement_maturity_date &&
                                                      errors.agreement_maturity_date
                                                    }
                                                  />
                                                </div>
                                              </Col>

                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='inception date'
                                                    styleType='B'
                                                  />
                                                  <TextField
                                                    type='date'
                                                    name='inception_date'
                                                    value={
                                                      values.inception_date
                                                    }
                                                    className='form-control'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}

                                                    error={
                                                      errors.inception_date &&
                                                      touched.inception_date &&
                                                      errors.inception_date
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.inception_date &&
                                                      touched.inception_date &&
                                                      errors.inception_date
                                                    }
                                                  />
                                                </div>
                                              </Col>

                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='LoI Available for'
                                                    styleType='B'
                                                  />
                                                  <TextField
                                                    name='loi_available_for'
                                                    type='text'
                                                    value={
                                                      values.loi_available_for
                                                    }
                                                    className='form-control'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                      errors.loi_available_for &&
                                                      touched.loi_available_for &&
                                                      errors.loi_available_for
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.loi_available_for &&
                                                      touched.loi_available_for &&
                                                      errors.loi_available_for
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='Escallation in 3 years'
                                                    styleType='B'
                                                  />
                                                  <TextField
                                                    name='escallation_in_3_years'
                                                    type='text'
                                                    value={
                                                      values.escallation_in_3_years
                                                    }
                                                    className='form-control'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                      errors.escallation_in_3_years &&
                                                      touched.escallation_in_3_years &&
                                                      errors.escallation_in_3_years
                                                        ? true
                                                        : false
                                                    }
                                                    helperText={
                                                      errors.escallation_in_3_years &&
                                                      touched.escallation_in_3_years &&
                                                      errors.escallation_in_3_years
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='Escrow Possibility'
                                                    styleType='B'
                                                  />
                                                  <Dropdown
                                                    placeholder='escrow possibility'
                                                    id='escrow_possibility'
                                                    name='escrow_possibility'
                                                    fluid
                                                    search
                                                    selection
                                                    value={
                                                      values.escrow_possibility ||
                                                      ''
                                                    }
                                                    onBlur={() =>
                                                      setFieldTouched(
                                                        'escrow_possibility',
                                                        true
                                                      )
                                                    }
                                                    onChange={(
                                                      e,
                                                      { name, value, options }
                                                    ) => {
                                                      //
                                                      setFieldValue(
                                                        'escrow_possibility',
                                                        value
                                                      )
                                                    }}
                                                    options={
                                                      escowPossiblilityArray
                                                    }
                                                    className={
                                                      errors.escrow_possibility &&
                                                      touched.escrow_possibility
                                                        ? classes.required
                                                        : values.escrow_possibility == ''
                                                        ? classes.reqired
                                                        : classes.man
                                                    }
                                                  />
                                                  {errors.escrow_possibility &&
                                                    touched.escrow_possibility &&
                                                    values.escrow_possibility == '' && (
                                                      <div
                                                        style={{
                                                          color: 'red',
                                                          fontSize: '0.75rem',
                                                          marginTop: '-7px'
                                                        }}
                                                      >
                                                        {errors.escrow_possibility}
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  <Typography
                                                    text='Rent Mode '
                                                    styleType='B'
                                                  />

                                                  <Dropdown
                                                    placeholder='Rent Mode'
                                                    id='rent_mode'
                                                    name='rent_mode'
                                                    fluid
                                                    search
                                                    selection
                                                    value={
                                                      values.rent_mode || ''
                                                    }
                                                    onBlur={() =>
                                                      setFieldTouched(
                                                        'rent_mode',
                                                        true
                                                      )
                                                    }
                                                    onChange={(
                                                      e,
                                                      { name, value, options }
                                                    ) => {
                                                      //
                                                      setFieldValue(
                                                        'rent_mode',
                                                        value
                                                      )
                                                    }}
                                                    options={paymentMethod}

                                                    className={
                                                      errors.rent_mode &&
                                                      touched.rent_mode
                                                        ? classes.required
                                                        : values.rent_mode == ''
                                                        ? classes.reqired
                                                        : classes.man
                                                    }
                                                  />
                                                  {errors.rent_mode &&
                                                    touched.rent_mode &&
                                                    values.rent_mode == '' && (
                                                      <div
                                                        style={{
                                                          color: 'red',
                                                          fontSize: '0.75rem',
                                                          marginTop: '-7px'
                                                        }}
                                                      >
                                                        {errors.rent_mode}
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>

                                              <Col md={{ span: 6 }}>
                                                <div className='form-group'>
                                                  {/*
                                                      <button
                                                      type="button"
                                                      onClick={resetForm}
                                                      className="btn btn-warning float-right"
                                                    >
                                                      Reset
                                                    </button>
                                                     <button type="submit" className="btn btn-primary">
                                                      Add
                                                    </button>
                                                       */}
                                                </div>
                                              </Col>
                                            </Row>
                                          </Form>
                                        )}
                                      </Formik>
                                    </div>
                                  </>

                                  <Col md={{ span: 12 }}>
                                    {hideBtnLen < numberOfUnit ? (
                                      <>
                                        <Button
                                          className='backBtn'
                                          variant='outlined'
                                          color='primary'
                                          onClick={() => {
                                            
                                            updateUnitArray()
                                          }}
                                        >
                                          Add
                                        </Button>
                                      </>
                                    ) : null}

                                    {
                                      // <Button className="backBtn"
                                      // variant="outlined"
                                      // color="primary" onClick={() => {
                                      //   setRentalShow(false);
                                      //   //  getRentalDetails();
                                      //   //updateUnitArray();
                                      // }}>Back</Button>
                                    }

                                    {
                                      // propertyStatusId == 159 ? (
                                      //   <Button className="backBtn"
                                      //     variant="outlined"
                                      //     color="primary" onClick={() => {
                                      //       setRentalShow(true);
                                      //       //   getRentalDetails();
                                      //       //updateUnitArray();
                                      //     }}>Next</Button>) : (null)
                                    }
                                  </Col>

                                  {unitArr.length > 0 && (
                                    <Col
                                      md={{ span: 12 }}
                                      style={{
                                        marginTop: '3%',
                                        marginBottom: '3%'
                                      }}
                                    >
                                      <Typography
                                        text='Unit Table'
                                        styleType='C'
                                      />

                                      <TableContainer>
                                        <Table
                                          id='emp'
                                          aria-label='collapsible table'
                                          size='small'
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  table_slNo,
                                                  width: '5%'
                                                }}
                                              >
                                                #
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  table_header,
                                                  width: '17%',
                                                  padding:
                                                    '0px 0px 8px 0px !important'
                                                }}
                                              >
                                                Property Type
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  table_header,
                                                  width: '17%',
                                                  padding:
                                                    '0px 23px 8px 0px !important'
                                                }}
                                              >
                                                Property Status
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  table_header,
                                                  width: '8%!important'
                                                }}
                                              >
                                                Age of Construction
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  table_header,
                                                  width: '0%!important'
                                                }}
                                              >
                                                Action
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {unitArr.map((item, i) => {
                                              return (
                                                <TableRow
                                                  hover
                                                  className='datable'
                                                  key={i}
                                                >
                                                  <TableCell>{i + 1}</TableCell>
                                                  <TableCell>
                                                    {switchValue(
                                                      item.property_type_id
                                                    )}
                                                  </TableCell>

                                                  <TableCell>
                                                    {switchPropStatus(
                                                      item.property_status_id
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {
                                                      item.age_of_construction_id
                                                    }
                                                  </TableCell>
                                                  <TableCell className='tableAction Heading'>
                                                    {/*
                                                      <Tooltip title="Upload Document" placement="bottom">
                                                        <img className="actionBtn svgicon" src={uploaddoc} alt="action" onClick={(e) => {
                                                          setShowDocScreen(true);
                                                          setParentId1(1);
                                                          setParentTypeId1(item.id);
                                                          // setbusinesstable(false);
                                                          // setpromotable(false);
                                                        }} />
                                                      </Tooltip>
                                                      */}
                                                    <Tooltip
                                                      title='Edit Details'
                                                      placement='bottom'
                                                    >
                                                      <img
                                                        className='actionBtn svgicon'
                                                        src={editrec}
                                                        alt='action'
                                                        onClick={() => {
                                                          handleAssetUnitEditData(
                                                            item
                                                          )
                                                          setPropertyId(item.id)
                                                        }}
                                                      />
                                                    </Tooltip>

                                                    {item.property_status_id ==
                                                      159 && (
                                                      <Tooltip
                                                        title='Edit Rental Details'
                                                        placement='bottom'
                                                      >
                                                        <img
                                                          className='actionBtn svgicon'
                                                          style={{
                                                            color: 'black'
                                                          }}
                                                          src={editrec}
                                                          alt='action'
                                                          onClick={() => {
                                                            editRentalDetails(
                                                              item
                                                            )

                                                            setOpenHDDialogBox1(
                                                              true
                                                            )
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    )}

                                                    {/*
                                                       <Tooltip title="Delete Data" placement="bottom">
                                                      <img className="actionBtn svgicon" src={deleterec} alt="action" onClick={() => {  }} />
                                                    </Tooltip>
                                                       */}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Col>
                                  )}
                                </>
                              ) : null}
                            </>
                          )}

                          {showUnitDetails && (
                            <>
                              <div className='content-header'>
                                <Row>
                                  <Col md={{ span: 12 }}>
                                    <Typography
                                      text='Add Unit Details'
                                      styleType='A'
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <Row>
                                <Col md={{ span: 12 }}>
                                  <div>
                                    <Row>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Alloted Units'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Number of Units'
                                              id='numberOfUnits'
                                              name='numberOfUnits'
                                              fluid
                                              search
                                              selection
                                              value={values.numberOfUnits || ''}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'numberOfUnits',
                                                  true
                                                )
                                              }
                                              onChange={(
                                                e,
                                                { value, options }
                                              ) => {
                                                setFieldValue(
                                                  'numberOfUnits',
                                                  value
                                                )

                                                let number = 0
                                                options.map(item => {
                                                  if (item.value == value) {
                                                    number = item.text
                                                  }
                                                })
                                                setNumberOfUnitsId(value)
                                                

                                                // if(value == 161){
                                                //   setObject({
                                                //     ...object,
                                                //     no_of_units:value
                                                //   })

                                                // }
                                                console.log(
                                                  'numberOfUnits',
                                                  options
                                                )
                                                //generateUnitArray(Number(number));
                                                setNumberOfUnits(Number(number))
                                              }}
                                              options={numberOfUnitsListData}
                                              className={
                                                errors.numberOfUnits &&
                                                touched.numberOfUnits
                                                  ? classes.required
                                                  : values.numberOfUnits == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Type1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Type'
                                              id='propertyType'
                                              name='propertyType'
                                              fluid
                                              search
                                              selection
                                              value={values.propertyType}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'propertyType',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue(
                                                  'propertyType',
                                                  value
                                                )
                                              }
                                              options={propertyTypeListData}
                                              className={
                                                errors.propertyType &&
                                                touched.propertyType
                                                  ? classes.required
                                                  : values.propertyType == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyType &&
                                          touched.propertyType &&
                                          values.propertyType == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyType}
                                            </div>
                                          )}
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Usage1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Usage'
                                              id='propertyUsage'
                                              name='propertyUsage'
                                              fluid
                                              search
                                              selection
                                              value={values.propertyUsage || ''}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'propertyUsage',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue(
                                                  'propertyUsage',
                                                  value
                                                )
                                              }
                                              options={propertyUsageListData}
                                              className={
                                                errors.propertyUsage &&
                                                touched.propertyUsage
                                                  ? classes.required
                                                  : values.propertyUsage == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyUsage &&
                                          touched.propertyUsage &&
                                          values.propertyUsage == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyUsage}
                                            </div>
                                          )}
                                      </Col>
                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Status1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Status'
                                              id='propertyStatus'
                                              name='propertyStatus'
                                              fluid
                                              search
                                              selection
                                              value={
                                                values.propertyStatus || ''
                                              }
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'propertyStatus',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue(
                                                  'propertyStatus',
                                                  value
                                                )
                                              }
                                              options={propertyStatusListData}
                                              className={
                                                errors.propertyStatus &&
                                                touched.propertyStatus
                                                  ? classes.required
                                                  : values.propertyStatus == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.propertyStatus &&
                                          touched.propertyStatus &&
                                          values.propertyStatus == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.propertyStatus}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Type of Construction1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Type of Construction'
                                              id='typeOfConstruction'
                                              name='typeOfConstruction'
                                              fluid
                                              search
                                              selection
                                              value={
                                                values.typeOfConstruction || ''
                                              }
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'typeOfConstruction',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) => {
                                                

                                                setFieldValue(
                                                  'typeOfConstruction',
                                                  value
                                                )
                                              }}
                                              options={rooftypevalue}
                                              className={
                                                errors.typeOfConstruction &&
                                                touched.typeOfConstruction
                                                  ? classes.required
                                                  : values.typeOfConstruction ==
                                                    ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                        {errors.typeOfConstruction &&
                                          touched.typeOfConstruction &&
                                          values.typeOfConstruction == '' && (
                                            <div
                                              style={{
                                                color: 'red',
                                                fontSize: 11,
                                                marginTop: '-7px'
                                              }}
                                            >
                                              {errors.typeOfConstruction}
                                            </div>
                                          )}
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Property Dimension in sqrft'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <Dropdown
                                              placeholder='Select Property Usage1'
                                              id='propertyArea'
                                              name='propertyArea'
                                              fluid
                                              search
                                              selection
                                              value={values.propertyArea || ''}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  'propertyArea',
                                                  true
                                                )
                                              }
                                              onChange={(e, { value }) =>
                                                setFieldValue(
                                                  'propertyArea',
                                                  value
                                                )
                                              }
                                              options={propertyAreaListData}
                                              className={
                                                errors.propertyArea &&
                                                touched.propertyArea
                                                  ? classes.required
                                                  : values.propertyArea == ''
                                                  ? classes.reqired
                                                  : classes.man
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>

                                      <Col md={{ span: 6 }}>
                                        <Typography
                                          text='Age of Construction1'
                                          styleType='B'
                                        />
                                        <List component='div' disablePadding>
                                          <ListItem>
                                            <TextField
                                              id='filled-error-helper-text standard-basic '
                                              label='Age of Construction'
                                              name='ageOfConstruction'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              InputProps={{
                                                className: values.ageOfConstruction
                                                  ? classes.manorma
                                                  : null
                                              }}
                                              error={
                                                errors.ageOfConstruction &&
                                                touched.ageOfConstruction &&
                                                errors.ageOfConstruction
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                values.ageOfConstruction || ''
                                              }
                                              helperText={
                                                errors.ageOfConstruction &&
                                                touched.ageOfConstruction &&
                                                errors.ageOfConstruction
                                              }
                                            />
                                          </ListItem>
                                        </List>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Row>
                      </div>
                    </Col>
                    {showButtons && (
                      <Col md={{ span: 12 }}>
                        <Row>
                          <Col md={{ span: 10 }}></Col>

                          {
                            /// floating
                          }
                          {role_id.role_id == 1 || role_id.role_id == 1 ? (
                            <div id='mySidenav' class='sidenav'>
                              <a
                                href='/#'
                                id='about'
                                className='mySidenav'
                                style={{ marginLeft: '-84px' }}
                                onClick={changeCommentBox}
                              >
                                Comments
                              </a>
                            </div>
                          ) : null}

                          <Col md={{ span: 2 }}>
                            <Button
                              className='updatebtn'
                              variant='contained'
                              color='primary'
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col md={{ span: 5 }}></Col>
                        </Row>
                        {opencommentbox && (
                          <Row>
                            <Col
                              md={{ span: 6 }}
                              style={{ marginTop: '2%' }}
                            ></Col>
                            <Col md={{ span: 6 }} style={{ marginTop: '2%' }}>
                              <div style={commentBox} className='comment-box'>
                                <h2 style={{ color: 'rgb(114, 84, 163)' }}>
                                  Verification Comments
                                </h2>
                                <div className='comment-form'>
                                  <div className='comment-form-fields'>
                                    <textarea
                                      className='comment_textfield'
                                      placeholder='Comment'
                                      rows='4'
                                      required
                                      value={comments}
                                      onChange={e => {
                                        //
                                        setComments(e.target.value)
                                      }}
                                    ></textarea>
                                  </div>
                                  <div className='comment-form-actions'>
                                    <Button
                                      type='button'
                                      variant='contained'
                                      color='primary'
                                      className='x'
                                      onClick={e => {
                                        sendComments()
                                      }}
                                    >
                                      Post Comment
                                    </Button>
                                  </div>
                                </div>

                                {commentArr.map(item => (
                                  <div className='comment'>
                                    <p className='comment-header'>
                                      {item.email}
                                    </p>
                                    <p className='comment-body'>
                                      - {item.comments}
                                    </p>
                                    <div className='comment-footer'>
                                      <a
                                        href='/#'
                                        className='comment-footer-delete'
                                        onClick={() => {
                                          deleteComment(item.id)
                                        }}
                                      >
                                        Delete Comment
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    )}
                    <div className='btn_row_fixd'>
                      <Row>
                        <Col md={{ span: 10 }}></Col>
                        {/* className="btnspace" */}
                        {showNextButton == true && (
                          <>
                            <Col style={{ padding: '0%' }}></Col>
                            <Col style={{ padding: '0%' }}></Col>
                            <Col style={{ padding: '0%' }}>
                              <span className='image'>
                                <img
                                  alt='im'
                                  className='navbutton'
                                  src={Back}
                                  onClick={handledata}
                                />
                                <Tooltip
                                  title='Back'
                                  classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                  }}
                                  arrow
                                >
                                  <img
                                    alt='im'
                                    className='navgraybutton'
                                    src={grayback}
                                    onClick={handledata}
                                  />
                                </Tooltip>
                              </span>
                            </Col>
                            <Col style={{ padding: '0%' }}>
                              <span className='image'>
                                <img
                                  alt='im'
                                  className='navbutton'
                                  src={DraftNext}
                                  onClick={SaveasDraft}
                                />
                                <Tooltip title='Save & Next' arrow>
                                  <img
                                    alt='im'
                                    className='navgraybutton'
                                    src={graydraftnext}
                                    onClick={SaveasDraft}
                                  />
                                </Tooltip>
                              </span>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </Row>
                </form>
              )
            }}
          </Formik>
        </>

        {showTableHeader && showDocScreen == false && (
          <>
            <div className='content-header'>
              <Row>
                <Col md={{ span: 6 }}>
                  <Typography text='YOUR Colateral DETAILS ' styleType='C' />
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 10 }} className='subHeader1'>
                  <table className='applicationTableDetails1'>
                    <tr>
                      {checkuserid.request_type_id === 0 && (
                        <td>Loan Application</td>
                      )}
                      {checkuserid.request_type_id === 1 && (
                        <td>Check Eligibility</td>
                      )}
                      <td>&nbsp;|&nbsp;</td>
                      <td>Application Number</td>
                      <td>:</td>
                      <td>{checkuserid.reference_id}</td>
                      <td>&nbsp;|&nbsp;</td>
                      <td>Loan Type</td>
                      <td>:</td>
                      <td>{checkuserid.loan_type}</td>
                    </tr>
                  </table>
                </Col>
                <Col md={{ span: 2 }}>
                  {true && (
                    <Button className='actionBtn' style={{ float: 'right' }}>
                      <i
                        class='fa fa-user-plus'
                        style={{ fontSize: '15px', zIndex: 9999 }}
                        onClick={addUnitDetails}
                      ></i>
                    </Button>
                  )}
                </Col>
              </Row>
              {loaderFlag == true && (
                <div style={{ textAlign: 'center', marginTop: '15%' }}>
                  <Loader type={LoaderColor.type} color={LoaderColor.color} />
                </div>
              )}
            </div>
          </>
        )}
        {showTable == true && showDocScreen == false && (
          <>
            <SearchBar
              className='search_bar'
              value={searched}
              onChange={searchVal => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />

            <TableContainer>
              <Table id='emp' aria-label='collapsible table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ table_slNo, width: '5%' }}>#</TableCell>
                    <TableCell style={{ table_header, width: '17%!important' }}>
                      Type of Ownership
                    </TableCell>
                    <TableCell style={{ table_header, width: '14%!important' }}>
                      Conversion Status
                    </TableCell>
                    <TableCell
                      style={{
                        table_header,
                        width: '17%',
                        padding: '0px 0px 8px 0px !important'
                      }}
                    >
                      Property Category
                    </TableCell>

                    {
                      // <TableCell
                      //   style={{
                      //     table_header,
                      //     width: '17%',
                      //     padding: '0px 0px 8px 0px !important'
                      //   }}
                      // >
                      //   Khata Type
                    }
                    <TableCell
                      style={{
                        table_header,
                        width: '17%',
                        padding: '0px 23px 8px 0px !important'
                      }}
                    >
                      Area
                    </TableCell>
                    <TableCell style={{ table_header, width: '0%!important' }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitList.map((item, i) => {
                    return (
                      <TableRow hover className='datable' key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell
                          style={{ padding: '0px 0px 0px 40px!important' }}
                        >
                          {item.type_of_ownership}
                        </TableCell>
                        <TableCell>{item.conversion_status}</TableCell>
                        <TableCell>{item.property_category}</TableCell>

                        <TableCell>{item.area}</TableCell>
                        <TableCell className='tableAction Heading'>
                          {/*
                               <Tooltip title="Upload Document" placement="bottom">
                              <img className="actionBtn svgicon" src={uploaddoc} alt="action" onClick={(e) => {
                                setShowDocScreen(true);
                                setParentId1(1);
                                setParentTypeId1(item.id);
                                // setbusinesstable(false);
                                // setpromotable(false);
                              }} />
                            </Tooltip>
                               */}

                          <Tooltip title='Edit Details' placement='bottom'>
                            <img
                              className='actionBtn svgicon'
                              src={editrec}
                              alt='action'
                              onClick={() => {
                                handleAssetEditData(item.id)
                                // setPropertyId(item.id);
                              }}
                            />
                          </Tooltip>

                          <Tooltip title='Delete Data' placement='bottom'>
                            <img
                              className='actionBtn svgicon'
                              src={deleterec}
                              alt='action'
                              onClick={() => {
                                deleteassetdatabyid(item.id)
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Dialog
          disableBackdropClick
          open={openHDDialogBox1}
          onClose={handleCloseOpenHDDialogBox1}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          PaperProps={{
            style: {
              border: '1px solid'
            }
          }}
        >
          <Modal.Header
            className='modalHead'
            closeButton
            onClick={handleCloseOpenHDDialogBox1}
            style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}
          >
            <Modal.Title className='modalHeader cpModal'>
              Rental Details
            </Modal.Title>
          </Modal.Header>
          <DialogContent>
            <>
              <div>
                <Formik
                  // innerRef={ref}
                  enableReinitialize
                  initialValues={initialValueRental}
                  validationSchema={validationSchemaRental}
                  onSubmit={updateRentalArray}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    resetForm,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    validateForm
                  }) => (
                    <Form style={{ padding: '15px' }}>
                      <Row>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography
                              text='Gross Monthly Rent'
                              styleType='B'
                            />
                            <TextField
                              name='gross_monthly_rent'
                              type='text'
                              value={values.gross_monthly_rent}
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.gross_monthly_rent &&
                                touched.gross_monthly_rent &&
                                errors.gross_monthly_rent
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.gross_monthly_rent &&
                                touched.gross_monthly_rent &&
                                errors.gross_monthly_rent
                              }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography
                              type='date'
                              text='Agreement Maturity Date'
                              styleType='B'
                            />
                            <TextField
                              name='agreement_maturity_date'
                              type='date'
                              value={values.agreement_maturity_date}
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}

                              error={
                                errors.agreement_maturity_date &&
                                touched.agreement_maturity_date &&
                                errors.agreement_maturity_date
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.agreement_maturity_date &&
                                touched.agreement_maturity_date &&
                                errors.agreement_maturity_date
                              }
                            />
                          </div>
                        </Col>

                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography text='inception date' styleType='B' />
                            <TextField
                              type='date'
                              name='inception_date'
                              value={values.inception_date}
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}

                              error={
                                errors.inception_date &&
                                touched.inception_date &&
                                errors.inception_date
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.inception_date &&
                                touched.inception_date &&
                                errors.inception_date
                              }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography
                              text='LoI Available for'
                              styleType='B'
                            />
                            <TextField
                              name='loi_available_for'
                              type='text'
                              value={values.loi_available_for}
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.loi_available_for &&
                                touched.loi_available_for &&
                                errors.loi_available_for
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.loi_available_for &&
                                touched.loi_available_for &&
                                errors.loi_available_for
                              }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography
                              text='Escallation in 3 years'
                              styleType='B'
                            />
                            <TextField
                              name='escallation_in_3_years'
                              type='text'
                              value={values.escallation_in_3_years}
                              className='form-control'
                              onChange={handleChange}
                              onBlur={handleBlur}

                              error={
                                errors.escallation_in_3_years &&
                                touched.escallation_in_3_years &&
                                errors.escallation_in_3_years
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.escallation_in_3_years &&
                                touched.escallation_in_3_years &&
                                errors.escallation_in_3_years
                              }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography
                              text='Escrow Possibility'
                              styleType='B'
                            />
                            <Dropdown
                              placeholder='escrow possibility'
                              id='escrow_possibility'
                              name='escrow_possibility'
                              fluid
                              search
                              selection
                              value={values.escrow_possibility || ''}
                              onBlur={() =>
                                setFieldTouched('escrow_possibility', true)
                              }
                              onChange={(e, { name, value, options }) => {
                                //
                                setFieldValue('escrow_possibility', value)
                              }}
                              options={escowPossiblilityArray}
                              className={
                                errors.escrow_possibility &&
                                touched.escrow_possibility
                                  ? classes.required
                                  : values.escrow_possibility == ''
                                  ? classes.reqired
                                  : classes.man
                              }
                            />
                            {errors.escrow_possibility &&
                              touched.escrow_possibility &&
                              values.escrow_possibility == '' && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '0.75rem',
                                    marginTop: '-7px'
                                  }}
                                >
                                  {errors.escrow_possibility}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            <Typography text='Rent Mode ' styleType='B' />
                            <Dropdown
                              placeholder='Rent Mode'
                              id='rent_mode'
                              name='rent_mode'
                              fluid
                              search
                              selection
                              value={values.rent_mode || ''}
                              onBlur={() => setFieldTouched('rent_mode', true)}
                              onChange={(e, { name, value, options }) => {
                                //
                                setFieldValue('rent_mode', value)
                              }}
                              options={paymentMethod}
                              className={
                                errors.rent_mode &&
                                touched.rent_mode
                                  ? classes.required
                                  : values.rent_mode == ''
                                  ? classes.reqired
                                  : classes.man
                              }
                            />
                            {errors.rent_mode &&
                              touched.rent_mode &&
                              values.rent_mode == '' && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '0.75rem',
                                    marginTop: '-7px'
                                  }}
                                >
                                  {errors.rent_mode}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col md={{ span: 6 }}>
                          <div className='form-group'>
                            {/*
                                <button
                                type="button"
                                onClick={resetForm}
                                className="btn btn-warning float-right"
                              >
                              Reset
                              </button>
                               */}

                            <button style ={{marginTop: "30%",float:"right"}}
                              type='submit'
                              className='btn btn-primary'
                              onClick={e => {
                                
                                e.preventDefault()
                                handleSubmit()
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          </DialogContent>

          {
            // <DialogActions>
            //   <Button onClick={handleCloseOpenHDDialogBox1} className="backBtn"
            //     variant="outlined"
            //     color="primary">No</Button>
            //   <Button onClick={()=>console.log(1)} className="backBtn"
            //     variant="outlined"
            //     color="primary" style={{ marginRight: "2%" }}>Continue</Button>
            // </DialogActions>
          }
        </Dialog>
      </div>
    </Container>
  )
}
export default AssetLAP
