import React, { useState, useRef } from 'react';
import { loadCSS } from 'fg-loadcss';
import Container from '@material-ui/core/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useStyles } from "./style";
import Typography from "../../base_components/Typography";
import DraftNext from '../../image/buttons/draftnext.png';
import Back from '../../image/buttons/back.png';
import Reset from '../../image/buttons/reset.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import grayback from '../../image/buttons/grayback.png';
import grayreset from '../../image/buttons/grayreset.png';
import graydraftnext from '../../image/buttons/graydraftnext.png';
import axios from 'axios';
import AberratonQuastionarrie from '../AberratonQuastionarrie';
import SearchBar from "material-ui-search-bar";
import { Checkbox } from '@material-ui/core';
import edit from '../../image/pngicons/edit.png';
import { ErrorCatch, apiCalling } from "../../shared/constants";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Modal from "react-bootstrap/Modal";
import ReactQuill from 'react-quill';

import { Button } from "@material-ui/core";

const ApplicationFinScoring2 = (props) => {
  const classes = useStyles();

  const table_header = {
    fontWeight: "600",
    fontSize: "100%"
  };
  const table_center_header = {
    fontWeight: "600",
    fontSize: "100%",
    textAlign: "center"
  };
  const table_center_body = {
    textAlign: "center"
  };
  const table_slNo = {
    fontWeight: "600",
    fontSize: "100%",
    width: "1%"
  }


  React.useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    checkIfRequest();
    getFinChechkValue();
  }, []);
  const [requestDetails, setRequestDetails] = React.useState();
  const checkIfRequest = () => {
    setRequestDetails(JSON.parse(localStorage.getItem("rquestIdDetails")));
  }

  let localData = JSON.parse(localStorage.getItem("rquestIdDetails"));

  const handledata = () => {
    props.handleBack();
  };
  const SaveasDraft = () => {
    props.handleNext();
  }


  const [dataList, setDataList] = React.useState([]);
  const [originalDataList, setOriginalDataList] = React.useState([]);
  const getFinChechkValue = async () => {
    
    let appReq = JSON.parse(localStorage.getItem('rquestIdDetails'));

    let data = {
      folder_no: appReq.folder_no,
      parameter_category: "Financial Scoring"
    };

    // await axios.post(apiCalling.coderscorner+'server.php/api/getFinCheckFatCheckDataHeaderWise', data).then(
    await axios.post(apiCalling.coderscorner + 'server.php/api/getFinCheckFatCheckDataHeaderWise', data).then(
      result => {
        if (result.data.status) {
          console.log(result);
          let finCheckArray = [];
          result.data.data.headerDetails.map((item) => {
            finCheckArray.push({
              headerName: item.headerName,
              headerDetails: item.headerDetails
            });
          });
          setDataList(finCheckArray);
          setOriginalDataList(finCheckArray);
          console.log("dataaaaaaaaaa", finCheckArray);

          let redStatusArray = [];
          if (finCheckArray.length > 0) {
            finCheckArray.map((item1) => {
              if (item1.headerDetails.length > 0) {
                item1.headerDetails.map(item2 => {
                  if (item2.status == "RED") {
                    redStatusArray.push(item2.parameter_id);
                  }
                });
              }
            })
          }

          localStorage.setItem('finscoreArray', JSON.stringify(redStatusArray));

        }

      }
    ).catch(error => {
      console.log(error);
    })
  }
  const [showquestionaries, setShowquestionaries] = React.useState(false);
  const [parameter_id, setParameter_id] = React.useState();

  const hideQuestionScreen = () => {
    setShowquestionaries(false);
  }

  ///  stickyHeader for table header fix

  // search bar 
  const [searched, setSearched] = React.useState("");

  // search bar code
  const requestSearch = (searchedVal) => {
    
    // let busArr = businessList;
    let tempArr = [];
    if (searchedVal.length != 0 && searchedVal != "") {
      originalDataList.map(item => {
        if (item.headerName.toLowerCase().includes(searchedVal.toLowerCase())) {
          tempArr.push(item);
        }
        item.headerDetails.map(item2 => {
          if (item2.parameter_name.toLowerCase().includes(searchedVal.toLowerCase())) {
            let flag = tempArr.map(item3 => {
              if (item3.headerName == item.headerName) {
                return true;
              }
            });
            if (flag == false || flag == undefined) {
              tempArr.push(item);
            }
          }
        })
      });
      let finCheckArray = [];
      tempArr.map(data => {
        finCheckArray.push({
          headerName: data.headerName,
          headerDetails: data.headerDetails
        })
      });
      setDataList(finCheckArray);
    } else {
      setDataList(originalDataList);
    }
  };
  const cancelSearch = () => {
    
    setSearched("");
    //setBusinessList(newBus);
    setDataList(originalDataList);
  }

  const [finacialYear, setFinancialyear] = React.useState("");

  //09/03
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const [openStatus, setOpenStatus] = React.useState(false);
  const handleClose = () => {
    setOpenStatus(false);
  };

  const editor = useRef(null);

  let modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  let formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const [comments, setComments] = useState("");
  const [parameter, setParameter] = React.useState(Number);
  const [year,setYear]  = React.useState("");

  const saveComments = async () => {
    

    let data = {
      "parameter_id": parameter,
      "year":year,
      "folder_no": localData.folder_no,
      "cpa_comments": comments
    }
    await axios.post(apiCalling.coderscorner + `server.php/api/updateSaveComments`, data).then(result => {
      console.log(result);
      setParameter(Number);
      setComments("");
      handleClose();
    }).catch(error => {

    });
  }

  const getById = async (parameter_id,year_para) => {
    

    let data = {

      "folder_no": localData.folder_no,
      "parameter_id":parameter_id,
      "year":year_para

    }
    await axios.post(apiCalling.coderscorner + `server.php/api/getByParameterIdComments`, data).then(result => {
      console.log(result);
      if(result.data){
        if(result.data.data.length>0){
          setComments(result.data.data[0].cpa_comments);
        }
        
      }

    }).catch(error => {

    });
  }


  return (
    <>
      {showquestionaries == true && (
        <AberratonQuastionarrie ParameterId={parameter_id} FinancialYear={finacialYear} ScreenFlag={hideQuestionScreen} Screen={"fin"} />
      )}

      {(showquestionaries !== true && (
        <Container maxWidth="lg">
          <div className="mini-container mg_btm_set">
            <Row>
              <Col md={{ span: 12 }}>
                <div className="content-header">
                  <Row>
                    {/* { requestDetails && (
            <Col md={{ span: 12 }} style ={{marginBottom:"3%",textAlign:"center"}}>
              <p className="letgetsHeader">You are viewing details of application : <u>{requestDetails.reference_id}</u></p>
            </Col>
          )} */}
                  </Row>
                  <Row>
                    <Col md={{ span: 12 }} className="mainheader1">
                      <Typography text="Application Financial Health Check - Part B" styleType="C" />
                    </Col>
                    <Col md={{ span: 12 }} className="subHeader1">
                      <table className="applicationTableDetails1">
                        <tr>
                          {/* <td style={{ padding: "0px 5px" }}>Type</td>
                        <td>:</td> */}
                          {localData.request_type_id === 0 && (
                            <td>Loan Application</td>)}
                          {localData.request_type_id === 1 && (
                            <td>Check Eligibility</td>)}
                          <td>&nbsp;|&nbsp;</td>
                          <td>Application Number</td>
                          <td>:</td>
                          <td>{localData.reference_id}</td>
                          <td>&nbsp;|&nbsp;</td>
                          <td>Loan Type</td>
                          <td>:</td>
                          <td>{localData.loan_type}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div>
              <SearchBar
                className="search_bar"
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
              <TableContainer component={Paper} className="tableFixHeader">
                <Table aria-label="collapsible table" size="small"  >
                  <TableHead>
                    <TableRow>
                      <TableCell className="sticky_header" style={table_slNo}>#</TableCell>

                      <TableCell className="sticky_header" style={table_header}>Financial Scoring Prameters</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Year</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Value Change</TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Health Indicator&nbsp;<i className="fas fa-exclamation-circle" style={{ fontSize: '15px', color: 'blue' }}></i></TableCell>

                      <TableCell className="sticky_header" style={table_center_header}>Questionnaire&nbsp;<i className="fas fa-external-link-alt" style={{ fontSize: '13px', color: '#a3a3c4' }}></i></TableCell>

                      <TableCell className="sticky_header" style={table_header}>Assessed By</TableCell>
                      <TableCell className="sticky_header" style={table_header}>Assessor Remarks</TableCell>
                      {/* <TableCell className="sticky_header" style={table_header}>Check</TableCell> */}
                    </TableRow>
                  </TableHead>

                  {dataList.map((item) => (
                    <>
                      <TableHead>
                        <TableRow hover className="datable">
                          <TableCell style={table_header} colSpan="7">{item.headerName}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          item.headerDetails.map((item1, i) => (
                            <TableRow hover className="datable">
                              <TableCell>{i + 1}</TableCell>

                              <TableCell>{item1.parameter_name}</TableCell>

                              <TableCell style={table_center_body}>{item1.year}</TableCell>

                              <TableCell style={table_center_body}>{item1.parameter_value}</TableCell>

                              <TableCell style={table_center_body}><i className="fas fa-circle" style={item1.parameter_evaluation_status == "sorry" ? { fontSize: '15px', color: 'red' } : { fontSize: '15px', color: 'green' }}></i></TableCell>

                              {(item1.parameter_evaluation_status == "sorry") ? (
                                <TableCell style={table_center_body}><i className="fas fa-times-circle" onClick={() => {
                                  setShowquestionaries(true); setParameter_id(item1.parameter_id);


                                }} style={{ fontSize: '15px', color: 'red' }} ></i></TableCell>
                              ) : (<TableCell></TableCell>)}

                              <TableCell style={table_center_body}>Cpa</TableCell>
                              <TableCell style={table_center_body}>
                                <img alt="img" className="actionBtn svgicon" src={edit} onClick={() => {
                                  
                                  setYear(item1.year);
                                  setParameter(item1.parameter_id);
                                  getById(item1.parameter_id,item1.year);
                                  setOpenStatus(true);
                                }} />&nbsp;
                              </TableCell>
                              {/* <TableCell>
                                {item1.parameter_evaluation_status == "sorry" ? (
                                  <Checkbox defaultChecked />
                                ) : (null)}

                              </TableCell> */}
                            </TableRow>))}
                      </TableBody>
                    </>
                  ))}
                </Table>
              </TableContainer>

              <div className='btn_row_fixd'>
                <Row>
                  <Col md={{ span: 10 }}></Col>
                  <Col style={{ padding: "0%" }}></Col>
                  {/* className="btnspace" */}
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
              <img className='navbutton' src={Back} onClick={handledata} />
            </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' src={Back} onClick={handledata} />
                      <Tooltip title="Back" classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} arrow>
                        <img className='navgraybutton' src={grayback} onClick={handledata} />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Reset" arrow>
              <img className='navbutton' src={Reset} />
            </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' src={Reset} />
                      <Tooltip title="Reset" arrow>
                        <img className='navgraybutton' src={grayreset} />
                      </Tooltip>
                    </span>
                  </Col>
                  {/* <Col style={{ padding: "0%" }}>
              <Tooltip title="Save & Next" arrow>
                <img className='navbutton' src={SaveNext} />
              </Tooltip>
            </Col> */}
                  <Col style={{ padding: "0%" }}>
                    {/* <Tooltip title="Save as Draft & Next" arrow>
              <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
            </Tooltip> */}
                    <span className="image">
                      <img className='navbutton' src={DraftNext} onClick={SaveasDraft} />
                      <Tooltip title="Save & Next" arrow>
                        <img className='navgraybutton' src={graydraftnext} onClick={SaveasDraft} />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openStatus}>
              <Modal.Header className="modalHead" closeButton onClick={handleClose} style={{ color: '#212529', borderBottom: '1px solid #7254a3' }}>
                <Modal.Title className="modalHeader cpModal">Analyst Comments</Modal.Title>
              </Modal.Header>
              <DialogContent className="custom_modal" dividers style={{ height: "120px" }}>
                <div className="bank_modal_type">

                  <Typography text="Comments" className="bank_modal_type" styleType="B" />
                  <ReactQuill
                    onRef={editor}
                    ////value={bindingArray.arrayBindData['level3' + level.id] || ''}
                    onChange={(html) => {
                      let data = comments;
                      data = html;

                      setComments(data);
                    }
                    }

                    value={comments}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    style={{ width: "100%" }}
                  // onFocus={() => {
                  //    generateToolArray(level.id);
                  //    // showToolBar1();
                  //    console.log("hello");
                  // }}
                  />
                </div>


              </DialogContent>
              <DialogActions>
                <Button  color="primary" type="submit" onClick={saveComments}>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Container>))}

    </>
  );
}
export default ApplicationFinScoring2;